import { ITax } from 'containers/product-catalog/taxes/taxes.model';
import { InvoiceLineItem } from '../InvoicesSlice';
import { IInvoiceServiceCharge } from '../invoices.model';
import { GlobalServiceChargeTax } from 'containers/product-catalog/catalog.model';

export interface InvoiceValidationError {
  type: string;
  errorMsg: string;
}

export interface ISaveLineItem {
  saveToCatalog: boolean;
  invoiceLineItem: InvoiceLineItem;
}

export interface IInvoiceSaveServiceCharge {
  saveToCatalog: boolean;
  invoiceServiceCharge: IInvoiceServiceCharge;
}

export enum EInvoiceCatalogElementType {
  ITEM = 'ITEM',
  SERVICE_CHARGE = 'SERVICE_CHARGE'
}

export enum EItemModalType {
  ONE_TIME_ADD_ITEM_MODAL = 'ONE_TIME_ADD_ITEM_MODAL',
  CATALOG_ADD_ITEM_MODAL = 'CATALOG_ADD_ITEM_MODAL',
  EDIT_ITEM_MODAL = 'EDIT_ITEM_MODAL'
}

export enum EServiceChargeModalType {
  ONE_TIME_ADD_SERVICE_CHARGE_MODAL = 'ONE_TIME_ADD_SERVICE_CHARGE_MODAL',
  CATALOG_SERVICE_CHARGE_MODAL = 'CATALOG_SERVICE_CHARGE_MODAL',
  EDIT_SERVICE_CHARGE_MODAL = 'EDIT_SERVICE_CHARGE_MODAL'
}

export enum EInvoiceModalType {
  ONE_TIME_ADD_ITEM_MODAL = 'ONE_TIME_ADD_ITEM_MODAL',
  CATALOG_ADD_ITEM_MODAL = 'CATALOG_ADD_ITEM_MODAL',
  EDIT_ITEM_MODAL = 'EDIT_ITEM_MODAL',
  ONE_TIME_ADD_SERVICE_CHARGE_MODAL = 'ONE_TIME_ADD_SERVICE_CHARGE_MODAL',
  CATALOG_SERVICE_CHARGE_MODAL = 'CATALOG_SERVICE_CHARGE_MODAL',
  EDIT_SERVICE_CHARGE_MODAL = 'EDIT_SERVICE_CHARGE_MODAL'
}

export interface InvoiceDraftModalData {
  invoiceModalType: EInvoiceModalType;
  invoiceCatalogElementType: EInvoiceCatalogElementType;
  modalConfig: any; //TODO type define?
}

export interface InvoiceGlobalTaxGroups {
  globalLineItemTaxes: ITax[];
  globalServiceChargeTaxes: GlobalServiceChargeTax[];
}
