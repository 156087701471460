import { NavCloseIcon } from 'assets/icons';
import If from 'components/If/If';
import React, { FC } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { VoidFn } from 'types/baseTypes';
import { PopoverArrowTypes } from 'constants/common';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';

interface CustomPopoverProps {
  anchorComponent: FC<{ isPanelOpen: boolean }>;
  show: boolean;
  highlightAnchor?: boolean;
  onTogglePopover: VoidFn;
  onClosePopover: VoidFn;
  children: React.ReactNode;
  offset?: string;
  margin?: string;
  padding?: string;
  width?: string;
  height?: string;
  showDismissTitle?: boolean;
  title?: string;
  zIndex?: string;
  showArrow?: boolean;
  arrowType?: string;
  arrowOffset?: string;
  minWidth?: string;
  popoverBodyStyle?: string;
  anchorComponentId?: string;
}

const CustomPopover: FC<CustomPopoverProps> = ({
  anchorComponent,
  children,
  show,
  highlightAnchor = true,
  onTogglePopover,
  onClosePopover,
  offset = '-right-4',
  margin = 'mt-6',
  padding = 'p-4',
  width = '200px',
  height,
  zIndex = 'z-40',
  showDismissTitle = false,
  title,
  minWidth = 'min-w-[200px]',
  showArrow = false,
  arrowType = PopoverArrowTypes.TOP,
  arrowOffset = 'right-1',
  popoverBodyStyle,
  anchorComponentId
}) => {
  const getArrowStyles = () => {
    switch (arrowType) {
      case PopoverArrowTypes.TOP:
        return '-top-px rotate-45';
      case PopoverArrowTypes.RIGHT:
        return '-right-[13px] rotate-[135deg]';
      case PopoverArrowTypes.BOTTOM:
        return ' -bottom-[13px] -rotate-[135deg]';
      case PopoverArrowTypes.LEFT:
        return '-left-px -rotate-45';
    }
  };
  return (
    <OutsideClickHandler onOutsideClick={onClosePopover}>
      <div className="relative w-fit">
        <div
          id={anchorComponentId}
          className={`rounded-full ${show && highlightAnchor && 'fill-current text-secondary'}`}
          onClick={e => {
            e.stopPropagation();
            onTogglePopover();
          }}>
          {anchorComponent({ isPanelOpen: show })}
        </div>

        <If condition={show}>
          <div
            className={twMerge(
              classnames(
                `absolute mt-6 rounded-md border border-borderGray
               bg-primary p-4 shadow-[0px_20px_60px_rgba(9,26,70,0.1)]`,
                {
                  [offset]: offset,
                  [margin]: margin,
                  [width]: width,
                  [height]: height,
                  [zIndex]: zIndex,
                  [minWidth]: minWidth,
                  [padding]: padding,
                  [popoverBodyStyle]: popoverBodyStyle
                }
              )
            )}>
            <If condition={showArrow}>
              <div
                className={`-translate-x-1/2 -translate-y-1/2  border-l border-t
                  ${getArrowStyles()} ${arrowOffset}
                absolute h-3 w-3 skew-x-6 skew-y-6 border-borderGray bg-primary`}></div>
            </If>
            <If condition={showDismissTitle}>
              <div className="mb-7 flex flex-row items-center gap-2">
                <NavCloseIcon onClick={onClosePopover} className="shrink-0" />
                <div className="font-bold text-[#333333]">{title}</div>
              </div>
            </If>
            {children}
          </div>
        </If>
      </div>
    </OutsideClickHandler>
  );
};

export default CustomPopover;
