import Divider from '@material-ui/core/Divider';
import { CheckboxTickIcon, CircleCloseIcon, SelectionCircleBlueIcon, SelectionCircleIcon } from 'assets/icons';
import { CustomButton, CustomCheckbox, CustomModal, ErrorScreenWithRetry, If, SearchBar } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import React, { useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { PaginatedListKeyType } from '../taxes/type';
import { checkIsAccessTokenExpiry } from 'utils/apiUtils';
import { PaginatedListItemNames } from '../constants';

interface ISearchInputConfig {
  enable: boolean;
  placeholder: string;
}

interface IListItem {
  id: string | number;
}

interface PaginatedListSearchModalProps<T extends IListItem> {
  open: boolean;
  title: string;
  itemType: PaginatedListItemNames;
  searchInputConfig: ISearchInputConfig;
  onClose: VoidFn;
  onSecondaryBtnClick: VoidFn;
  onPrimaryBtnClick: ({ selectedItems }: { selectedItems: T[] }) => void;
  secondaryBtnLabel: string;
  primaryBtnLabel: string;
  showSelectedItemCount?: boolean;
  list: Array<T>;
  nonEditableList?: Array<T>;
  isLoadingList: boolean;
  hasMoreListItems: boolean;
  setIntersectionElement: React.Dispatch<React.SetStateAction<HTMLElement>>;
  handleSearch: (string) => void;
  getSelectedItems?: () => T[];
  onUnselectItem?: (item: T) => void;
  onSelectItem?: (item: T) => void;
  isMultiItemSelection?: boolean;
  renderActionButton?: () => JSX.Element;
  keyName?: string;
  renderEmptyState?: () => JSX.Element;
  isLoadingSuccess: boolean;
  handleReload: VoidFn;
  apiErrorCode: string;
  isError: boolean;
  noneItem?: T;
  showNoneOption?: boolean;
  handleClearSearch?: VoidFn;
  isSearchResultEmpty?: boolean;
  renderSearchEmptyState?: JSX.Element;
  isFetchResultEmpty: boolean;
  isTaxList?: boolean;
}

// separate the generic parameter from the rest of the type signature with a comma.
const PaginatedListSearchModal = <T extends IListItem>({
  showSelectedItemCount = true,
  open,
  title,
  itemType = PaginatedListItemNames.ITEM,
  searchInputConfig,
  onClose,
  onPrimaryBtnClick,
  list,
  nonEditableList = [],
  onSecondaryBtnClick,
  secondaryBtnLabel,
  primaryBtnLabel,
  isLoadingList,
  hasMoreListItems,
  setIntersectionElement,
  handleSearch,
  getSelectedItems = undefined,
  onUnselectItem = undefined,
  onSelectItem = undefined,
  isMultiItemSelection = true,
  renderActionButton = undefined,
  keyName = '',
  renderEmptyState = undefined,
  isLoadingSuccess,
  handleReload,
  apiErrorCode,
  isError,
  noneItem,
  showNoneOption = false,
  handleClearSearch = undefined,
  isSearchResultEmpty = false,
  renderSearchEmptyState = undefined,
  isFetchResultEmpty = false,
  isTaxList = false
}: PaginatedListSearchModalProps<T>) => {
  const [selectedItems, setSelectedItems] = useState<Array<T>>([]);
  const [searchText, setSearchText] = useState<string>('');
  const [isNoneClicked, setIsNoneClicked] = useState(false);

  useEffect(() => {
    if (getSelectedItems && getSelectedItems().length > 0) {
      setSelectedItems(getSelectedItems());
    }
  }, []);

  const selectedItemCount = selectedItems.length;

  const onSecondaryClick = (): void => {
    onSecondaryBtnClick();
  };

  const onPrimaryClick = (): void => {
    onPrimaryBtnClick({ selectedItems: selectedItems });
  };

  const getKeyTypeText = (item: T): string => {
    if (keyName === PaginatedListKeyType.PERCENTAGE) return item[keyName] + '%';
    return item[keyName];
  };

  const getSelectedItemCountText = (count, itemText): string => {
    switch (itemText) {
      case PaginatedListItemNames.TAX:
        if (count === 0 || count > 1) {
          return `${count} ${itemText}es selected`;
        } else if (count === 1) {
          return `${count} ${itemText} selected`;
        }
        break;
      default:
        if (count === 0 || count > 1) {
          return `${count} ${itemText}s selected`;
        } else if (count === 1) {
          return `${count} ${itemText} selected`;
        }
    }
  };

  const isSelectedItem = (item: T): boolean => {
    return Boolean(selectedItems.find(selectedListItem => selectedListItem.id === item.id));
  };

  const onClearSearch = () => {
    setSearchText('');
    if (handleClearSearch) {
      handleClearSearch();
    } else {
      handleSearch(null);
    }
  };

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    handleSearch(searchTerm);
  };

  const onItemClick = (item: T): void => {
    if (isMultiItemSelection) {
      if (selectedItems.find(selectedListItem => selectedListItem.id === item.id)) {
        setSelectedItems(selectedItems.filter(selectedListItem => selectedListItem.id !== item.id));
        if (onUnselectItem) {
          onUnselectItem(item);
        }
      } else {
        setSelectedItems(prev => [...prev, item]);
        if (onSelectItem) {
          onSelectItem(item);
        }
      }
    } else {
      setIsNoneClicked(false);
      setSelectedItems([item]);
      if (onSelectItem) {
        onSelectItem(item);
      }
    }
  };

  const handleNoneClick = () => {
    setIsNoneClicked(true);
    setSelectedItems([noneItem]);
  };

  const getEditableListItems = () => {
    if (list?.length > 0) {
      const listData = list.filter(item => !nonEditableList.includes(item));
      return listData;
    }
    return [];
  };

  const renderItem = (item, index, isEditable) => {
    return (
      <div key={item.id}>
        <Divider className="bg-secondaryBtn" />
        <div
          onClick={() => {
            if (isEditable) {
              onItemClick(item);
            }
          }}
          className={`flex min-h-[37px] cursor-pointer flex-row items-center justify-between py-5 
        hover:bg-secondaryBtn ${isEditable ? '' : 'cursor-not-allowed'}`}>
          <div className="max-w-[50%] break-words text-primaryText">{item?.['name']}</div>
          <div className="flex">
            <If condition={Boolean(keyName)}>
              <div className="break-words pr-5 text-primaryText">{getKeyTypeText(item)}</div>
            </If>
            <If condition={isMultiItemSelection}>
              <CustomCheckbox
                className={`${isSelectedItem(item) ? '' : 'border-accentText bg-secondaryBtn'} ${
                  isEditable ? '' : 'cursor-not-allowed border-accentText bg-border'
                }`}
                isChecked={isSelectedItem(item)}
                onClick={() => onItemClick(item)}
                id={item.id.toString()}
                disabled={!isEditable}>
                <If condition={isSelectedItem(item)}>
                  <CheckboxTickIcon />
                </If>
              </CustomCheckbox>
            </If>

            <If condition={!isMultiItemSelection}>
              <If condition={!isSelectedItem(item)}>
                <SelectionCircleIcon className="h-[18px] w-[18px] shrink-0 cursor-pointer" />
              </If>
              <If condition={isSelectedItem(item)}>
                <SelectionCircleBlueIcon className="h-[18px] w-[18px] shrink-0" />
              </If>
            </If>
          </div>
        </div>

        <If condition={index === list.length - 1}>
          <Divider className="bg-secondaryBtn" />
        </If>
      </div>
    );
  };

  return (
    <CustomModal
      closeModal={onClose}
      open={open}
      backgroundColor="bg-primary"
      width="md:w-[611px]"
      height="max-h-[632px] h-[65%]">
      <div className="absolute flex h-full w-full grow flex-col py-10">
        <CircleCloseIcon className="absolute top-2 right-2 shrink-0 cursor-pointer" onClick={onClose} />
        <div className="flex flex-row gap-5">
          <div className="pl-10 text-xl font-semibold text-heading">{title}</div>
          <If condition={!isFetchResultEmpty && !isLoadingList}>{renderActionButton && renderActionButton()}</If>
        </div>

        <If condition={isFetchResultEmpty}>
          {/* empty state */}
          {renderEmptyState()}
        </If>

        <If condition={!isLoadingSuccess && !isLoadingList && isError && !checkIsAccessTokenExpiry(+apiErrorCode)}>
          <ErrorScreenWithRetry handleReload={handleReload} />
        </If>

        <If condition={!isFetchResultEmpty && !isError && !isLoadingList}>
          <div className="mt-5 mb-[15px] px-10">
            <SearchBar
              id="search-bar"
              handleClear={onClearSearch}
              handleChange={onSearchTermChange}
              value={searchText}
              placeholder={searchInputConfig.placeholder}
              wrapperStyle="w-full h-10 border border-borderGray rounded"
              showSearchIcon={true}
              autoFocus={true}
              showCrossIcon={true}
            />
          </div>

          <If condition={showSelectedItemCount && Boolean(itemType) && !isFetchResultEmpty}>
            <div className="mb-[10px] px-10 text-sbase font-normal text-accentText">
              {getSelectedItemCountText(selectedItemCount, itemType)}
            </div>
          </If>
        </If>

        {/* Search empty state */}
        <If condition={isSearchResultEmpty}>{renderSearchEmptyState}</If>

        <If condition={!isFetchResultEmpty && !isSearchResultEmpty && !isError}>
          <div className="customNormalScroll mb-5 h-full max-h-[632px] overflow-y-auto  px-10">
            <If condition={list?.length > 0}>
              <If condition={showNoneOption}>
                <Divider className="bg-secondaryBtn" />
                <div
                  onClick={handleNoneClick}
                  className="flex min-h-[37px] cursor-pointer flex-row 
                  items-center justify-between py-5 hover:bg-secondaryBtn">
                  <div className="text-primaryText">None</div>
                  {!isNoneClicked ? <SelectionCircleIcon /> : <SelectionCircleBlueIcon />}
                </div>
              </If>
              {(searchText || !isTaxList) && (
                <>
                  {list?.map((item, index) => {
                    const isEditable = !nonEditableList?.includes(item);
                    return renderItem(item, index, isEditable);
                  })}
                </>
              )}
              {!searchText && isTaxList && (
                <>
                  {nonEditableList?.length > 0 && (
                    <>
                      <div className="pb-5 pt-2.5">
                        <div className="text-sbase font-bold text-primaryText">Default applied taxes</div>
                        <div className="text-px13 text-primaryText">
                          The following taxes are automatically applied by the tax settings.
                        </div>
                      </div>
                      {nonEditableList.map((item, index) => {
                        return renderItem(item, index, false);
                      })}
                    </>
                  )}
                  {getEditableListItems()?.length > 0 && (
                    <>
                      <div className="py-5">
                        <div className="text-sbase font-bold text-primaryText">Manually applied taxes</div>
                        <div className="text-px13 text-primaryText">You can select multiple taxes from the list.</div>
                      </div>
                      {getEditableListItems().map((item, index) => {
                        return renderItem(item, index, true);
                      })}
                    </>
                  )}
                </>
              )}
            </If>
            <If condition={!isLoadingList && hasMoreListItems}>
              <div className="h-2" ref={setIntersectionElement}></div>
            </If>
            <If condition={isLoadingList}>
              <div className={`${list.length === 0 ? 'pt-10' : 'pt-0'}`}>
                <InfinteLoadingSpinner />
              </div>
            </If>
          </div>

          <div className="mt-auto flex flex-row items-center  justify-end px-10">
            <CustomButton
              className="mr-5 w-[100px]"
              type={ButtonType.SECONDARY}
              onClick={onSecondaryClick}
              id={`${title}-cancel-btn`}>
              {secondaryBtnLabel}
            </CustomButton>

            <CustomButton className="w-[100px]" onClick={onPrimaryClick} id={`${title}-save-btn`}>
              {primaryBtnLabel}
            </CustomButton>
          </div>
        </If>
      </div>
    </CustomModal>
  );
};

export default PaginatedListSearchModal;
