import { ICategory } from './categories.model';

export const emptyCategory: ICategory = {
  id: null,
  name: null,
  lineItemsCount: null,
  lineItems: null
};

export enum CatalogTopBarCategoryButton {
  CreateCategorySaveButton = 'create-category-save-button',
  CreateCategoryBackButton = 'create-category-back-button',
  EditCategorySaveButton = 'edit-category-save-button',
  EditCategoryBackButton = 'edit-category-back-button',
  EditCategoryDeleteButton = 'edit-category-delete-button'
}
