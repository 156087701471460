/* eslint-disable indent */
import { ArrowRightIcon, InfoIcon, NavCloseIcon, ScanPayIcon } from 'assets/icons';
import { TxnCardIconTypes, TxnCardTypes } from 'constants/common';
import { selectCurrentTeam, selectCurrentTeamInvoicePermissions, selectUserDetails } from 'containers/app/appSlice';
import { TransactionCard } from 'containers/invoices/components';
import { useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import DateUtils from 'utils/dateUtils';
import { useLazyGetPayoutByIdQuery } from '../api';
import { PayoutStatus } from '../payouts.model';
import GenericError from 'components/genric-error/GenericError';
import TransactionFeeToolTip from './TransactionFeeToolTip';
import { logAnalyticEvent } from 'utils/analytics';
import SidePanel from 'components/side-panel/SidePanel';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE, FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import NoPermissions from 'components/no-permissions/NoPermissions';
import { TransactionStatus } from 'constants/invoicesConstants';

interface PayoutDetailsProps {
  onSidePanelClose?: VoidFn;
  payoutId?: string;
  isIdFromProp?: boolean;
}

const PayoutDetails: FC<PayoutDetailsProps> = ({ onSidePanelClose, payoutId = '', isIdFromProp }) => {
  const [getPayoutDetailsById, payoutDetailsState] = useLazyGetPayoutByIdQuery();
  const {
    data: payoutDetails,
    isSuccess: payoutDetailsSuccess,
    isFetching: fetchingPayoutDetails
  } = payoutDetailsState;
  const {
    amount,
    status,
    ID,
    createdAt,
    grossAmount,
    serviceFee,
    transactions,
    destination,
    creditsApplied,
    pastRefundAmount,
    pastChargebackAmount
  } = payoutDetails ?? {};
  const currentTeam = useAppSelector(selectCurrentTeam);
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const currentUser = useAppSelector(selectUserDetails);

  const {
    payoutId: payoutIdFromURL,
    rootInvoiceId,
    rootReceiptId,
    rootPage,
    rootCustomerId,
    rootDisputeId
  } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSidePanelClose } = useOutletContext<any>();

  const [isRootState, setIsRootState] = useState(true);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);

  useEffect(() => {
    if (payoutId || payoutIdFromURL) {
      getPayoutDetailsById({
        payoutId: payoutId || payoutIdFromURL,
        teamId: currentTeam?.id
      });
    }
  }, [payoutId]);

  useEffect(() => {
    if (payoutDetailsSuccess) {
      if (isCreatedBySelf()) {
        if (!payoutPermissions.viewPayoutsByTeam && !payoutPermissions.viewPayoutsBySelf) {
          setHasPermissionsToView(false);
        }
      } else if (!payoutPermissions.viewPayoutsByTeam) {
        setHasPermissionsToView(false);
      }
    }
  }, [payoutPermissions.viewPayoutsBySelf, payoutPermissions.viewPayoutsByTeam, payoutDetails]);

  const isCreatedBySelf = () => {
    return payoutDetails.merchantId === currentUser.id;
  };

  useEffect(() => {
    let routeStateTimeout;
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const handleSupportClick = () => {
    window.location.href = 'mailto:support@scanpay.tech';
  };

  const generalInfo = payoutDetailsSuccess
    ? [
        { title: 'Payout ID', value: ID ?? '-' },
        {
          title: 'Date',
          value: DateUtils.getDateInFormat({ date: new Date(createdAt), dateFormat: 'MMMM dd yyyy' }) ?? '-'
        },
        {
          title: 'Amount settled to',
          value: destination ? (
            <div className="flex flex-col gap-1">
              <div className="ml-auto flex gap-1 justify-self-end">
                <ScanPayIcon />
                <div>ScanPay {destination}</div>
              </div>
              <div className="ml-auto max-w-[99%] justify-self-end truncate">{currentTeam?.name}</div>
            </div>
          ) : (
            '-'
          )
        }
      ]
    : [];

  const payoutAmountInfo = payoutDetailsSuccess
    ? [
        { title: 'Gross Amount', value: getAmountWithCurrency(grossAmount) },
        {
          title: (
            <div className="flex gap-1">
              <div>Transaction Fee</div>
              <TransactionFeeToolTip />
            </div>
          ),
          value: `${+serviceFee > 0 ? '-' : ''} ${getAmountWithCurrency(serviceFee)}`
        },
        ...(+creditsApplied > 0
          ? [
              {
                title: 'Credits Applied',
                value: `+${getAmountWithCurrency(creditsApplied)}`
              }
            ]
          : []),
        ...(+pastRefundAmount > 0
          ? [
              {
                title: 'Past Refunds',
                value: `-${getAmountWithCurrency(pastRefundAmount)}`
              }
            ]
          : []),
        ...(+pastChargebackAmount > 0
          ? [
              {
                title: 'Past Chargebacks',
                value: `-${getAmountWithCurrency(pastChargebackAmount)}`
              }
            ]
          : [])
      ]
    : [];

  const getPayoutStatus = payoutStatus => {
    switch (payoutStatus) {
      case PayoutStatus.PENDING:
        return <div className="rounded bg-fallingBackground px-5 text-px13 text-tertiaryText">In transit</div>;
      case PayoutStatus.SUCCESS:
        return <div className="rounded bg-success px-5 text-px13 text-tertiaryText">Success</div>;
      case PayoutStatus.ON_HOLD:
      case PayoutStatus.FAILED:
        return (
          <div className="rounded border border-pending-orange bg-fallingBackground px-5 text-px13 text-tertiaryText">
            On hold
          </div>
        );
    }
  };

  const getPayoutInfo = payoutStatus => {
    switch (payoutStatus) {
      case PayoutStatus.PENDING:
        return (
          <div className="mt-2 rounded bg-secondaryBtn p-2 text-sbase">
            <div className="text-primaryText">
              The payout will be processed by end of day, subject to EST business hours and bank holidays.
            </div>
          </div>
        );
      case PayoutStatus.SUCCESS:
        return null;
      case PayoutStatus.ON_HOLD:
      case PayoutStatus.FAILED:
        return (
          <div className="mt-2 flex gap-2 rounded bg-fallingBackground p-2 text-sbase">
            <div className="mt-1">
              <InfoIcon />
            </div>
            <div className="text-primaryText">
              We need some additional details before processing the payout. Please look for an email from Scanpay or
              reach out to{' '}
              <span className="cursor-pointer text-primaryBtn" onClick={handleSupportClick}>
                support@scanpay.tech
              </span>
              .
            </div>
          </div>
        );
    }
  };

  const handleTxnClick = (txnId: string, transactionStatus = '') => {
    logAnalyticEvent(clevertapEvents.WebPayoutsListDetailsTxnDetail);
    if (transactionStatus === TransactionStatus.REFUNDED) {
      if (rootInvoiceId) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/refund-receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootPage) {
        navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/refund-receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootReceiptId) {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/refund-receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootDisputeId) {
        navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/refund-receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else {
      if (rootInvoiceId) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootPage) {
        navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootReceiptId) {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootDisputeId) {
        navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/receipt/${txnId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    }
  };

  const handleDisputeClick = (dsId: string) => {
    if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleSidePanelBack = () => {
    if (isRootState) {
      isIdFromProp ? onSidePanelClose() : handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  const handleOutsideClick = () => {
    isIdFromProp ? onSidePanelClose() : handleSidePanelClose();
  };

  return (
    <SidePanel isOpen={true} onClose={handleOutsideClick} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="h-full w-96">
        {fetchingPayoutDetails ? (
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          payoutDetailsSuccess && (
            <div className="flex h-full w-full flex-col gap-4 p-5 pr-0">
              <div className="flex w-full items-center gap-2">
                <div onClick={handleSidePanelBack} className="cursor-pointer">
                  {isRootState ? (
                    <NavCloseIcon id="payout-panel-close" />
                  ) : (
                    <ArrowRightIcon className="rotate-180" id="payout-panel-back-arrow" />
                  )}
                </div>
                <div id="payout-panel-head" className="text-17px font-semibold text-headingGray">
                  Payout details
                </div>
              </div>
              {hasPermissionsToView ? (
                <div className="customNormalScroll flex grow flex-col gap-4 overflow-y-auto pr-5">
                  <div className="w-full rounded-md border border-dark-gray">
                    <div className="m-auto flex flex-col items-center justify-center gap-2 px-4 py-10">
                      <div className="text-sbase font-semibold text-accentText">Payout amount</div>
                      <div id="payout-sidepanel-payout-amount" className="text-3xl font-bold text-tertiaryText">
                        {getAmountWithCurrency(amount)}
                      </div>
                      <div>{getPayoutStatus(status)}</div>
                      <div>{getPayoutInfo(status)}</div>
                    </div>
                    <div className="flex flex-col gap-3 border-t border-secondaryBtn p-4">
                      {generalInfo.map(data => (
                        <div className="flex justify-between" key={data.title}>
                          <div
                            id={`payout-sidepanel-${data.title}`}
                            className="w-1/2 text-sbase font-semibold text-accentText">
                            {data.title}
                          </div>
                          <div
                            id={`payout-sidepanel-${data.value}`}
                            className="max-w-[50%] text-sbase font-semibold text-tertiaryText">
                            {data.value}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="w-full rounded-md border border-dark-gray p-4">
                    <div className="flex flex-col gap-4">
                      {payoutAmountInfo.map((data, index) => (
                        <div className="flex justify-between" key={data.value + index}>
                          <div
                            id={`payout-sidepanel-${data.title}`}
                            className="text-sbase font-semibold text-accentText">
                            {data.title}
                          </div>
                          <div
                            id={`payout-sidepanel-${data.value}`}
                            className="text-sbase font-semibold text-tertiaryText">
                            {data.value}
                          </div>
                        </div>
                      ))}
                      <div className="flex items-center justify-between border-t border-borderGray pt-3">
                        <div className="text-sbase font-semibold text-primaryText">Payout Amount</div>
                        <div
                          id="payout-sidepanel-payout-total-amount"
                          className="text-sbase font-semibold text-tertiaryText">
                          {getAmountWithCurrency(amount)}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-full">
                    <div className="text-17px font-semibold text-headingGray">Transactions</div>
                    <div className="my-4 rounded-md border border-dark-gray">
                      {transactions?.map(item => (
                        <div
                          id="payouts-transactions-card"
                          key={item.id}
                          className="mx-4 border-b border-borderGray py-4 last:border-b-0 only:border-b-0">
                          <TransactionCard
                            data={item}
                            currencySymbol="$"
                            type={TxnCardTypes.PAYOUT_DETAILS}
                            showAvatar={false}
                            iconType={TxnCardIconTypes.PAYMENT_METHOD}
                            onTxnClick={handleTxnClick}
                            onDisputeClick={handleDisputeClick}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <NoPermissions />
              )}
            </div>
          )
        )}
        {!fetchingPayoutDetails && !payoutDetailsSuccess && <GenericError handleErrorBack={handleSidePanelBack} />}
      </div>
    </SidePanel>
  );
};

export default PayoutDetails;
