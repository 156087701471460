/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { BackIcon, ContractQRIcon } from 'assets/icons';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam, selectCurrentTeamCustomersPermissions } from 'containers/app/appSlice';
import { useLazyGetInvoiceByIdQuery } from 'containers/invoices/api';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { ShareReceiptMainSection } from 'containers/invoices/components/ShareReceiptMainSection';
import { ShareReceiptPageState } from 'containers/invoices/invoice.model';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { logAnalyticEvent } from 'utils/analytics';
import { constructArrayObjectAsString } from 'utils/commonUtils';
import routesPath from 'routes/RoutesPath';
import SidePanel from 'components/side-panel/SidePanel';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import { SharePanelType } from '../types';
import { useLazyGetReceiptByIdQuery } from 'containers/receipts/api';

interface NotificationDrawerProps {
  resetInvoicesQuery?: VoidFn;
  resetReceiptsQuery?: VoidFn;
  onClose?: VoidFn;
  type?: 'INVOICE' | 'RECEIPT';
}

const ShareReceiptPanel: React.FC<NotificationDrawerProps> = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [pageState, setPageState] = useState<ShareReceiptPageState>(ShareReceiptPageState.MAIN);
  const [isRootState, setIsRootState] = useState(true);

  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const getPanelType = () => {
    if (location?.pathname?.includes('share-invoice')) return SharePanelType.Invoice;
    if (location?.pathname?.includes('share-refund-receipt')) return SharePanelType.Refund;
    return SharePanelType.Receipt;
  };
  const [getInvoiceByIdQuery, getInvoiceByIdState] = useLazyGetInvoiceByIdQuery();
  const { data: invoiceData, isSuccess: invoiceDataSuccess, isFetching: fetchingInvoiceData } = getInvoiceByIdState;

  const [getReceiptByIdQuery, getReceiptByIdState] = useLazyGetReceiptByIdQuery();
  const { data: receiptData, isSuccess: receiptDataSuccess, isFetching: fetchingReceiptData } = getReceiptByIdState;

  const { rootInvoiceId, invoiceId: invoiceIdFromUrl, receiptId, rootReceiptId } = useParams();
  const { handleSidePanelClose, resetInvoicesQuery, resetReceiptsQuery } = useOutletContext<any>();

  useEffect(() => {
    if ((invoiceIdFromUrl || rootInvoiceId) && getPanelType() === SharePanelType.Invoice && currentTeam?.id) {
      getInvoiceByIdQuery({ invoiceId: invoiceIdFromUrl ? invoiceIdFromUrl : rootInvoiceId, teamId: currentTeam.id });
    }
  }, [invoiceIdFromUrl, rootInvoiceId, currentTeam]);

  useEffect(() => {
    if (
      (receiptId || rootReceiptId) &&
      (getPanelType() === SharePanelType.Refund || getPanelType() === SharePanelType.Receipt) &&
      currentTeam?.id
    ) {
      getReceiptByIdQuery({ receiptId: receiptId, teamId: currentTeam.id });
    }
  }, [receiptId, currentTeam, rootReceiptId]);

  useEffect(() => {
    let routeStateTimeout;
    const routeState: SidePanelRouteState = location.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const getEventName = () => {
    if (location.pathname === routesPath.RECEIPTS) return clevertapEvents.webReceiptsListSharePanelOpen;
    return clevertapEvents.WebInvoicesListSharePanelOpen;
  };

  useEffect(() => {
    if (invoiceDataSuccess) {
      logAnalyticEvent(getEventName(), {
        'Line Items': constructArrayObjectAsString(invoiceData.lineItems, 'Line Item'),
        Page: getPanelType()
      });
    }
  }, [invoiceDataSuccess]);

  useEffect(() => {
    if (
      !customerPermissions.editCustomersCreatedByTeam &&
      !customerPermissions.editExistingCustomers &&
      !customerPermissions.viewCustomersCreatedByTeam
    ) {
      handleSidePanelClose();
    }
  }, [customerPermissions]);

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  const getQRData = () => {
    if (getPanelType() === SharePanelType.Invoice) return invoiceData.qr;
    return receiptData.qr;
  };

  const getAmount = () => {
    if (getPanelType() === SharePanelType.Refund) return getAmountWithCurrency(receiptData.amount);
    return getAmountWithCurrency(invoiceData.isPaid ? invoiceData.amount : invoiceData.payableAmount);
  };

  const ExpandedQRCode = () => {
    return (
      <div className="flex h-full flex-col">
        <div className="flex flex-row items-center p-5">
          <div onClick={() => setPageState(ShareReceiptPageState.MAIN)} className="cursor-pointer">
            <BackIcon />
          </div>
          <div className="pl-2 text-base font-bold text-primaryText">{'Back'}</div>
        </div>
        <div className="flex h-full w-full flex-1 flex-col items-center justify-center">
          <div className={'text-base font-semibold'}>Scan with camera</div>
          <div className={'text-xs font-normal'}>Or use a QR code scanning app</div>
          <div className="flex flex-col items-center">
            <img
              src={getQRData()}
              className={'w-60 cursor-pointer'}
              alt={'qr-code'}
              onClick={() => setPageState(ShareReceiptPageState.MAIN)}
            />
            <div className={'mb-7 text-3xl font-bold text-secondary'}>{getAmount()}</div>
            <div className="flex flex-row items-center">
              <ContractQRIcon />
              <div className={'ml-2 text-sm font-semibold text-tertiaryText'}>Tap the QR to shrink</div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="h-full w-96">
        {fetchingInvoiceData || fetchingReceiptData ? (
          <div className="flex h-full w-full items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          (invoiceDataSuccess || receiptDataSuccess) && (
            <section
              className={`delay-400 customNormalScroll right-0 h-full w-full overflow-auto bg-white 
           shadow-xl transition-all duration-500 ease-in-out`}>
              {pageState === ShareReceiptPageState.MAIN ? (
                <ShareReceiptMainSection
                  onClose={handleSidePanelBack}
                  invoiceData={invoiceData}
                  receiptData={receiptData}
                  type={getPanelType()}
                  setPageState={setPageState}
                  isRootState={isRootState}
                  resetReceiptsQuery={resetReceiptsQuery}
                  resetInvoicesQuery={resetInvoicesQuery}
                  receiptId={receiptId ? receiptId : rootReceiptId}
                  qrData={getQRData()}
                />
              ) : (
                <ExpandedQRCode />
              )}
            </section>
          )
        )}
      </div>
    </SidePanel>
  );
};

export default ShareReceiptPanel;
