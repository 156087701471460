import { PaymentSystemTransitionType } from 'containers/home/constants';
import {
  ConnectBankStatuses,
  EnablePayoutStatuses,
  InternalBankStatuses,
  PaymentSystem,
  PayoutScheduleType,
  PayoutType
} from 'containers/payouts/constants';

export enum VerificationStatusEnum {
  InReview = 'IN_REVIEW',
  NotVerified = 'NOT_VERIFIED',
  Verified = 'VERIFIED',
  MerchantOnHold = 'ON_HOLD',
  Rejected = 'REJECTED',
  AwaitingDocs = 'WAITING_FOR_DOCUMENTS',
  Declined = 'DECLINED'
}
export interface UserDetails {
  id: string;
  name: string;
  email: string;
  phone: string;
  deviceToken: string;
  isMerchantBlocked: boolean;
  earnedCredits: string;
  remainingCredits: string;
  verificationStatus: VerificationStatusEnum;
  isCustomerChampion: boolean;
  isSalesFunnelMerchant: boolean;
  deviceInfoRequired: boolean;
  preCreditFeature: boolean;
  defaultLandingPage: string;
  referAndEarnEnabled: boolean;
  isDeviceAuthenticated: boolean;
  isPasswordActive: boolean;
  isPasswordCreated: boolean;
  isEmailVerified: boolean;
  isEmailAdded: boolean;
  showEmailPrompt: boolean;
  showVerificationPrompt: boolean;
  skipIamFlow: boolean;
  skippedAuthentication: boolean; // This becomes true if once the user logs in
  brbClosureDate: string;
  faqMigrationSafeDate: string;
  faqMigrationMonthAndYear: string;
}

export interface GetMerchantTeamsResponse {
  merchantTeams: MerchantTeam[];
}

export interface MerchantTeam {
  id: number;
  name: string;
  role: TeamRole;
  profileImageUrl: string;
  maximumAmount: string;
  minimumAmount: string;
  isOnboardingCompleted: boolean;
  isUnitEnabled: boolean;
  unitProvisionStatus: string;
  isAutoPayoutEnabled: boolean;
  personaEnquiryStatus: string;
  isBankAdded: boolean;
  permission: Permission;
  storeName: string;
  profession: string;
  integrationsEnabled: boolean;
  quickBooksIntegrationEnabled: boolean;
  connectIntegrationsEnabled: boolean;
  connectApplicationStatus: EnablePayoutStatuses;
  connectBankingStatus: ConnectBankStatuses;
  internalBankingStatus: InternalBankStatuses;
  paymentSystemTransitionType: PaymentSystemTransitionType;
  teamMerchantName: string;
}

export enum TeamRole {
  OWNER = 'owner',
  ADMIN = 'admin',
  MEMBER = 'member',
  CUSTOM = 'custom',
  SUPER_ADMIN = 'super-admin'
}

export enum PermissionLevels {
  EDIT_ALL = 'Edit all',
  EDIT_SELF_ONLY = 'Edit self only',
  VIEW_ONLY = 'View only',
  FULL_ACCESS = 'Full access',
  NO_ACCESS = 'No access',
  VIEW_ACCOUNT_ONLY = 'View account only',
  CUSTOM = 'Custom',
  MANAGE_FUNDS = 'Manage Funds'
}

export interface Permission {
  role: TeamRole;
  permissions: Permissions;
}

export interface Permissions {
  teamSettings: TeamSettings;
  invoice: InvoiceSettings;
  fees: FeesSettings;
  customers: CustomersSettings;
  banking: BankingSettings;
  refund: RefundSettings;
}

export interface TeamSettings {
  level: PermissionLevels;
  teamSettingPermissionList: TeamSettingPermissionList;
}

export interface TeamSettingPermissionList {
  viewTeamProfile: boolean;
  editTeamProfile: boolean;
  inviteNewMember: boolean;
  editExistingMember: boolean;
  viewExistingMember: boolean;
}

export interface InvoiceSettings {
  level: PermissionLevels;
  invoicePermissionList: InvoicePermissionList;
}

export interface InvoicePermissionList {
  createInvoice: boolean;
  viewReceiptsByTeam: boolean;
  viewReceiptsBySelf: boolean;
  viewInvoicesByTeam: boolean;
  viewInvoicesBySelf: boolean;
  viewPayoutsBySelf: boolean;
  viewPayoutsByTeam: boolean;
}

export interface FeesSettings {
  level: PermissionLevels;
  feesPermissionList: FeesPermissionList;
}

export interface FeesPermissionList {
  manageItemsAndCategories: boolean;
  manageTaxes: boolean;
  manageServiceCharges: boolean;
  viewServiceFeeSettings: boolean;
  editServiceFeeSettings: boolean;
  viewTipsSettings: boolean;
  editTipsSettings: boolean;
  viewDiscount: boolean;
  editDiscount: boolean;
  viewTnc: boolean;
  editTnc: boolean;
  viewSignatureSettings: boolean;
  editSignatureSettings: boolean;
}

export interface CustomersSettings {
  level: PermissionLevels;
  customersPermissionList: CustomersPermissionList;
}

export interface CustomersPermissionList {
  createNewCustomers: boolean;
  editExistingCustomers: boolean;
  viewCustomersCreatedByTeam: boolean;
  editCustomersCreatedByTeam: boolean;
}

export interface BankingSettings {
  level: PermissionLevels;
  bankingPermissionList: BankingPermissionList;
}

export interface BankingPermissionList {
  viewAccountDetails: boolean;
  createACHTransfer: boolean;
  createPayee: boolean;
  editExistingPayee: boolean;
  downloadStatement: boolean;
  viewCardDetails: boolean;
  manageCards: boolean;
  viewCashbackDetails: boolean;
}

export interface RefundSettings {
  level: PermissionLevels;
  refundPermissionList: RefundPermissionList;
}

export interface RefundPermissionList {
  createRefundsBySelf: boolean;
  createRefundsByTeam: boolean;
}

export interface ToastNotification {
  message: ToastMessage;
  //TODO: change type to NotifierTypes
  type: string;
  duration?: number;
  id?: string;
  showClose?: boolean;
  bgStyle?: string;
  fontStyle?: string;
  shouldAnimate?: boolean;
}

interface ToastMessage {
  primaryMessage: string;
  secondaryMessage?: string;
  iconInitials?: string;
  iconSrc?: string;
  isMessageHtml?: boolean;
}

interface ErrorData {
  code: number;
  details: string;
  message: string;
}

export interface ErrorResponse {
  status: number;
  data: ErrorData;
}

export const memberRolePermissions = {
  teamSettings: {
    level: PermissionLevels.VIEW_ONLY,
    teamSettingPermissionList: {
      viewTeamProfile: true,
      viewExistingMember: true,
      inviteNewMember: false,
      editTeamProfile: false,
      editExistingMember: false
    }
  },
  invoice: {
    level: PermissionLevels.EDIT_SELF_ONLY,
    invoicePermissionList: {
      createInvoice: true,
      viewReceiptsByTeam: false,
      viewReceiptsBySelf: true,
      viewInvoicesByTeam: false,
      viewInvoicesBySelf: true,
      viewPayoutsBySelf: true,
      viewPayoutsByTeam: false
    }
  },
  fees: {
    level: PermissionLevels.VIEW_ONLY,
    feesPermissionList: {
      manageItemsAndCategories: false,
      manageTaxes: false,
      manageServiceCharges: false,
      editServiceFeeSettings: false,
      editTipsSettings: false,
      viewTipsSettings: true,
      viewServiceFeeSettings: true,
      viewDiscount: true,
      editDiscount: false,
      viewTnc: true,
      editTnc: false,
      viewSignatureSettings: true,
      editSignatureSettings: false
    }
  },
  customers: {
    level: PermissionLevels.EDIT_SELF_ONLY,
    customersPermissionList: {
      createNewCustomers: true,
      editCustomersCreatedByTeam: false,
      editExistingCustomers: true,
      viewCustomersCreatedByTeam: true
    }
  },
  banking: {
    level: PermissionLevels.NO_ACCESS,
    bankingPermissionList: {
      createPayee: false,
      createACHTransfer: false,
      viewCashbackDetails: false,
      viewCardDetails: false,
      viewAccountDetails: false,
      editExistingPayee: false,
      downloadStatement: false,
      manageCards: false
    }
  },
  refund: {
    level: PermissionLevels.EDIT_SELF_ONLY,
    refundPermissionList: {
      createRefundsByTeam: false,
      createRefundsBySelf: true
    }
  }
};

export interface PaymentSystemConfigResponse {
  paymentSystem: PaymentSystem;
  payoutType: PayoutType;
  payoutSchedule: PayoutScheduleType;
  bankingEnabled: boolean;
}

export enum PermissionTypeEnum {
  TEAMSETTINGS = 'teamSettings',
  INVOICE = 'invoice',
  FEES = 'fees',
  CUSTOMERS = 'customers',
  BANKING = 'banking',
  REFUND = 'refund'
}

export interface TeamBankAccountDetails {
  bankName: string;
  bankIcon: string;
  accountNumber: string;
  routingNumber: string;
  isScanpayAccount: boolean;
  paymentMethod: string;
}

export interface Banner {
  id: string;
  title: string;
  sub_title: string;
  cta_text: string;
  cta_type: string;
  cta_action: string;
  webview_title: string;
  webview_source: string;
  image_url: string;
  colours: string[];
  cta_text_colour: string;
  cta_bg_colour: string;
}

export interface GetTeamBannersResponse {
  data: {
    payout_banners: Banner[];
    home_banners: Banner[];
  };
}

export interface TeamBanners {
  payoutBanners: Banner[];
  homeBanners: Banner[];
}
