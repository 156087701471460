import { LoadingSpinnerAnimation } from 'assets/animations';
import { CrossBoundaryIcon, DocUploadSuccessIcon, RedCloseIcon } from 'assets/icons';
import classnames from 'classnames';
import { If } from 'components';
import Lottie from 'lottie-react';
import { FC, useCallback, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { useAppDispatch } from 'hooks';
import { showNotifier } from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { FileUploadType } from 'containers/disputes/disputes.model';
import { FILE_TYPE, FILE_UPLOAD_SIZE } from 'constants/common';
import { CompressionLibraries, handleImageCompression } from './compression';

interface FileUploadProps {
  className?: string;
  filePreview: string;
  setFile: React.Dispatch<React.SetStateAction<File>>;
  setPreview: React.Dispatch<React.SetStateAction<string>>;
  removePreview: VoidFn;
  prefill: boolean;
  isUploadInProgress?: boolean;
  id: string;
  height?: string;
  width?: string;
  icon?: string;
  allowMultiple?: boolean;
  subText?: string;
  maxFileSize?: number;
  FileUploadIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }> | null;
  supportedFileTypes: FileUploadType;
  showPreview?: boolean;
  file?: File;
  errorMessage?: string;
  allowImageCompression?: boolean;
  onFileLoadEnd?: ({ file }: { file: File }) => void;
}

// TODO Refactor to support different file types
const FileUpload: FC<FileUploadProps> = ({
  removePreview,
  className = '',
  filePreview,
  setPreview,
  setFile,
  isUploadInProgress = false,
  id,
  height = 'h-[295px]',
  width = 'w-full',
  icon = 'RED_CLOSED_ICON',
  allowMultiple = true,
  subText = 'PNG, JPG, or WEBP format files up to 8MB',
  maxFileSize = FILE_UPLOAD_SIZE.SIZE_8_MB_BYTES,
  FileUploadIcon,
  supportedFileTypes,
  showPreview = true,
  file,
  errorMessage = 'Image size should not exceed 8 MB',
  allowImageCompression = false,
  onFileLoadEnd = undefined
}) => {
  const [droppedFile, setDroppedFile] = useState(null);
  const [imageLoading, setImageLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();

  const onCompressionInProgress = (progress: number) => {
    if (progress === 100) {
      setImageLoading(false);
    } else {
      setImageLoading(true);
    }
  };

  const handleDrop = useCallback(async (acceptedFiles, rejectedFiles: FileRejection[]) => {
    await setImageLoading(true);
    if (rejectedFiles?.[0]?.file.size > maxFileSize) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: errorMessage,
            secondaryMessage: ''
          },
          type: NotifierTypes.INFO,
          showClose: false,
          fontStyle: 'text-primary font-normal'
        })
      );
      setImageLoading(false);
      return;
    }
    const currentFile: File = acceptedFiles[0];
    let compressedFile = currentFile;

    if (currentFile?.size > FILE_UPLOAD_SIZE.SIZE_1_MB && allowImageCompression) {
      if (currentFile?.type === FILE_TYPE.PNG) {
        compressedFile = await handleImageCompression({
          library: CompressionLibraries.COMPRESSOR_JS,
          file: currentFile
        });
      } else {
        compressedFile = await handleImageCompression({
          library: CompressionLibraries.BROWSER_IMAGE_COMPRESSION,
          file: currentFile,
          onCompressionInProgress
        });
      }
    }
    // We use file reader to extract a string out of an image file
    const reader = new FileReader();

    // reader.onabort = () => {}
    // reader.onerror = () => {}
    // reader.onload = () => {};
    reader.onloadend = () => {
      if (reader.result) {
        setPreview(reader.result as string);
        onFileLoadEnd && onFileLoadEnd({ file: compressedFile });
        setImageLoading(false);
      } else {
        setPreview(null);
      }
    };

    reader.readAsDataURL(compressedFile);
    setDroppedFile(compressedFile);
    setFile(compressedFile);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    open: openFileDialog
  } = useDropzone({
    onDrop: handleDrop,
    accept: {
      // file mime types
      ...supportedFileTypes
    },
    maxSize: maxFileSize,
    multiple: allowMultiple
  });

  const onCloseClick = () => {
    if (isUploadInProgress) {
      return;
    }
    removePreview();
  };

  return (
    <div
      {...getRootProps({
        className: twMerge(
          `${width} flex flex-col justify-center relative items-center
           border border-dashed ${height} rounded-md border-border`,
          className
        ),
        onClick: e => e.stopPropagation()
      })}>
      <If condition={Boolean(filePreview)}>
        <div className="h-full w-full">
          {icon === 'RED_CLOSED_ICON' ? (
            <RedCloseIcon
              id="invoice-remove-photo-red-close-icon"
              onClick={onCloseClick}
              className={twMerge(
                'z-[1] shrink-0 absolute top-1 right-1 cursor-pointer',
                classnames({ 'opacity-10': isUploadInProgress })
              )}
            />
          ) : (
            <CrossBoundaryIcon
              id="invoice-remove-photo-red-close-icon"
              onClick={onCloseClick}
              className={twMerge(
                'z-[1] shrink-0 absolute top-1 right-1 cursor-pointer',
                classnames({ 'opacity-10': isUploadInProgress })
              )}
            />
          )}
          <If condition={showPreview}>
            <img
              className={twMerge(
                'object-contain select-none absolute h-full left-1/2 -translate-x-1/2',
                classnames({ 'opacity-10': isUploadInProgress })
              )}
              src={filePreview}
            />
          </If>
          <If condition={!showPreview}>
            <div className="flex h-full w-full flex-col items-center justify-center">
              <DocUploadSuccessIcon />
              <div className="mt-0.5 text-px13 font-normal text-primaryText">{file?.name}</div>
            </div>
          </If>
          <If condition={isUploadInProgress}>
            <div className="flex h-full w-full items-center justify-center">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          </If>
        </div>
      </If>
      <If condition={!filePreview}>
        <div className="h-9 w-9">
          <If condition={imageLoading}>
            <Lottie className="" animationData={LoadingSpinnerAnimation} loop={true} />
          </If>
          <If condition={!imageLoading}>
            <FileUploadIcon onClick={openFileDialog} className="shrink-0 cursor-pointer" />
          </If>
        </div>
        <div className="mb-1 mt-3 text-sbase font-semibold text-primaryText">
          <span
            id={`${id}-upload-a-file`}
            onClick={openFileDialog}
            className="cursor-pointer text-sbase font-semibold text-secondary">
            Upload a file
          </span>{' '}
          or drag and drop
        </div>
        <div className="text-px13 font-semibold text-accentText">{subText}</div>
        <input {...getInputProps()} />
      </If>
    </div>
  );
};

export default FileUpload;
