import { ChevronArrowRightIcon, ManualBankAccountIcon } from 'assets/icons';
import { FC } from 'react';

const AddManualBankAccountCard: FC = () => {
  return (
    <div className={'flex w-full items-center rounded-md border-2 border-secondary bg-[#A4D7FA66] p-[15px]'}>
      <ManualBankAccountIcon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-semibold text-primaryText">Manually enter your bank details</div>
        <div className="text-sbase text-primaryText">Additional verification may be required</div>
      </div>
      <ChevronArrowRightIcon className="ml-auto shrink-0" />
    </div>
  );
};

export default AddManualBankAccountCard;
