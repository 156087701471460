import { TeamRole } from 'containers/app/app.model';
import { ITeamMember, TeamMemberStatus } from './manageTeam.model';

export const TeamMemberStatusFilter = [
  {
    key: TeamMemberStatus.ACTIVE,
    value: 'Active'
  },
  {
    key: TeamMemberStatus.INACTIVE,
    value: 'Inactive'
  },
  {
    key: TeamMemberStatus.INVITED,
    value: 'Invited'
  }
];

export const TeamMemberRoleFilter = [
  {
    key: TeamRole.ADMIN,
    value: 'Admin'
  },
  {
    key: TeamRole.OWNER,
    value: 'Owner'
  },
  {
    key: TeamRole.MEMBER,
    value: 'Member'
  },
  {
    key: TeamRole.CUSTOM,
    value: 'Custom'
  }
];

export const emptyTeamMember: ITeamMember = {
  inviteId: null,
  id: null,
  merchantId: null,
  roleId: null,
  firstName: null,
  middleName: null,
  lastName: null,
  email: null,
  phone: null,
  role: null,
  createdAt: null,
  status: null,
  isLoggedInMember: null,
  profileImageUrl: null,
  isActive: null,
  inviteExpiry: null,
  isExpired: null,
  activeSince: null,
  inactiveSince: null,
  inviteLink: null
};
