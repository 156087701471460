import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';

interface HomeState {
  value: number;
}

const initialState = {
  value: 1
} as HomeState;

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    increment: state => {
      state.value += 1;
    },
    incrementByAmount: (state, { payload }: PayloadAction<number>) => {
      state.value += payload;
    }
  }
});

export const { increment, incrementByAmount } = homeSlice.actions;

export const selectHomeCount = (state: RootState) =>
  state.rootReducer.home.value;

export default homeSlice.reducer;
