import { FC, useCallback, useContext, useEffect, useRef, useState } from 'react';
import AddLineItemSection from './AddLineItemSection';
import AddItemModal, { IAddItemModalConfig, IAddItemModalData } from './AddItemModal';
import {
  InvoiceLineItem,
  incrementCatalogLineItemQty,
  resetInvoiceDraftData,
  selectInvoiceDraftDataInInvoiceSlice,
  selectInvoiceLineItems,
  selectIsBackFromCreateTaxFromInvoiceSlice,
  updateIsBackFromCreateTaxInInvoiceSlice
} from 'containers/invoices/InvoicesSlice';
import { EInvoiceCatalogElementType, EInvoiceModalType, EItemModalType, ISaveLineItem } from '../types';
import AddFromCatalogModal from './AddFromCatalogModal';
import { ILineItemsSectionContext, LineItemsSectionContext } from './LineItemsSection';
import { debounce } from 'utils/commonUtils';
import { usePaginatedCategoryItemListQuery } from 'containers/invoices/hooks/usePaginatedCategoryItemListQuery';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { IInvoiceListingItem } from 'containers/invoices/invoices.model';
import { EditInvoiceLineItemsSectionContext } from 'containers/invoices/edit-invoice/components/LineItemsSectionEdit';
import { selectCurrentTeam, selectCurrentTeamFeePermissions, showNotifier } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { NotifierTypes } from 'constants/notifierConstants';
import { IInvoiceSuggestedItem, IItem, LineItemByIdPayload } from 'containers/product-catalog/items/items.model';
import { useLazyGetLineItemByIdQuery } from 'containers/product-catalog/items/api';
import Logger from 'utils/logger';
import {
  InvoiceLineItemEditPage,
  incrementCatalogLineItemQtyInEditSlice,
  resetInvoiceDraftDataInEditSlice,
  selectInvoiceDraftDataFromInvoiceEditSlice,
  selectInvoiceLineItemsEditPage,
  selectIsBackFromCreateTaxInEditSlice,
  updateIsBackFromCreateTaxInEditSlice
} from 'containers/invoices/edit-invoice/invoicesEditSlice';
import { hasSameTaxes } from 'containers/invoices/utils';

interface AddLineItemSectionWrapper {
  className?: string;
  onSaveLineItemClick: (arg0: ISaveLineItem) => void;
  onModifyLineItemClick: (lineItem: InvoiceLineItem) => void;
  isEditInvoiceFlow?: boolean;
}

const itemModalDataInit: IAddItemModalData = {
  id: null,
  catalogueLineItemId: 0,
  name: '',
  unitPrice: '',
  sku: '',
  description: '',
  selectedTaxes: [],
  quantity: '1'
};

const addItemModalConfigInitState: IAddItemModalConfig = {
  open: false,
  data: itemModalDataInit,
  editMode: false
};

const AddLineItemSectionWrapper: FC<AddLineItemSectionWrapper> = ({
  onSaveLineItemClick,
  onModifyLineItemClick,
  className = '',
  isEditInvoiceFlow = false
}) => {
  const dispatch = useAppDispatch();
  const isBackFromCreateTaxRoute = useAppSelector(
    isEditInvoiceFlow ? selectIsBackFromCreateTaxInEditSlice : selectIsBackFromCreateTaxFromInvoiceSlice
  );
  const selectedInvoiceDraftData = useAppSelector(
    isEditInvoiceFlow ? selectInvoiceDraftDataFromInvoiceEditSlice : selectInvoiceDraftDataInInvoiceSlice
  );
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const invoiceLineItems: InvoiceLineItemEditPage[] | InvoiceLineItem = useAppSelector(
    isEditInvoiceFlow ? selectInvoiceLineItemsEditPage : selectInvoiceLineItems
  );

  const { showAddFromCatalogModal, setShowAddFromCatalogModal } = useContext<ILineItemsSectionContext>(
    isEditInvoiceFlow ? EditInvoiceLineItemsSectionContext : LineItemsSectionContext
  );
  const [lineItemSearchText, setLineItemSearchText] = useState<string>('');
  const [addOneTimeItemModalConfig, setAddOneTimeItemModalConfig] =
    useState<IAddItemModalConfig>(addItemModalConfigInitState);
  const [addItemModalConfig, setAddItemModalConfig] = useState<IAddItemModalConfig>(addItemModalConfigInitState);
  const {
    categoryItemListData,
    setQueryParams: setCategoryItemListQueryParams,
    queryParams: categoryItemListQueryParams,
    loadMore: loadMoreCategoriesAndItems,
    isLoading: isLoadingCategoryItemList,
    isLoadingSuccess: isLoadingCategoryItemListSuccess,
    hasMore: hasMoreCategoriesOrItems,
    resetQuery: resetCategoryItemListQuery,
    resetRecallQuery: resetRecallCategoryItemListQuery,
    apiErrorCode: categoryItemListApiErrorCode,
    totalCategoryListCount,
    totalLineItemListCount,
    isError: isCategoryItemListError
  } = usePaginatedCategoryItemListQuery();
  const [getLineItemsByIdQuery, { isFetching: isFetchingItem }] = useLazyGetLineItemByIdQuery();

  const showDraftModal = () => {
    const { modalConfig } = selectedInvoiceDraftData ?? {};
    const itemModalConfig: IAddItemModalConfig = {
      open: true,
      data: modalConfig?.data ?? addItemModalConfigInitState,
      editMode: modalConfig?.editMode ?? false,
      draftFlow: modalConfig?.draftFlow
    };
    if (selectedInvoiceDraftData.invoiceModalType === EInvoiceModalType.CATALOG_ADD_ITEM_MODAL) {
      setAddItemModalConfig(prev => ({
        ...prev,
        data: itemModalConfig.data,
        open: true,
        draftFlow: { isDraftFlow: true, saveToCatalog: false }
      }));
    } else if (selectedInvoiceDraftData.invoiceModalType === EInvoiceModalType.ONE_TIME_ADD_ITEM_MODAL) {
      setAddOneTimeItemModalConfig(prev => ({
        ...prev,
        data: itemModalConfig.data,
        open: true,
        draftFlow: { isDraftFlow: true, saveToCatalog: itemModalConfig?.draftFlow?.saveToCatalog ?? false }
      }));
    }
    // Edit modal is rendered in line item section and line item section edit

    if (isBackFromCreateTaxRoute) {
      if (isEditInvoiceFlow) {
        dispatch(resetInvoiceDraftDataInEditSlice());
        dispatch(updateIsBackFromCreateTaxInEditSlice(false));
      } else {
        dispatch(resetInvoiceDraftData());
        dispatch(updateIsBackFromCreateTaxInInvoiceSlice(false));
      }
    }
  };

  useEffect(() => {
    if (isBackFromCreateTaxRoute) {
      if (
        selectedInvoiceDraftData &&
        selectedInvoiceDraftData.invoiceCatalogElementType === EInvoiceCatalogElementType.ITEM &&
        selectedInvoiceDraftData.invoiceModalType !== EInvoiceModalType.EDIT_ITEM_MODAL
      ) {
        showDraftModal();
      }
    }
  }, []);

  useEffect(() => {
    if (!isLoadingCategoryItemList) {
      if (isLoadingCategoryItemListSuccess) {
        if (!showAddFromCatalogModal && !addOneTimeItemModalConfig.open) {
          if (categoryItemListData?.lineItems?.length > 0 || categoryItemListData?.categories?.length > 0) {
            setShowAddFromCatalogModal(true);
          } else {
            setAddOneTimeItemModalConfig(prev => ({ ...prev, open: true }));
          }
        }
      } else if (isCategoryItemListError) {
        dispatch(
          showNotifier({
            duration: 1000,
            type: NotifierTypes.ERROR,
            message: {
              primaryMessage: 'Something went wrong, please try again after some time',
              isMessageHtml: false
            }
          })
        );
      }
    }
  }, [isCategoryItemListError, isLoadingCategoryItemListSuccess, isLoadingCategoryItemList]);

  const categoryItemListLoader = useRef(loadMoreCategoriesAndItems);

  const { setIntersectionElement: setCategoryItemIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: categoryItemListLoader,
    threshold: 0.5
  });

  const onSearchInputChange = (text: string): void => {
    setLineItemSearchText(text);
  };

  const onClearSearchInput = (): void => {
    setLineItemSearchText('');
  };

  const onCreateOneTimeItem = (): void => {
    setAddOneTimeItemModalConfig(prev => ({ ...prev, data: { ...prev.data, name: lineItemSearchText }, open: true }));
  };

  const onCreateOneTimeItemFromCatalogPicker = (itemName: string): void => {
    setAddOneTimeItemModalConfig(prev => ({ ...prev, data: { ...prev.data, name: itemName }, open: true }));
    onCloseAddFromCatalogModal();
  };

  const onCloseAddItemModal = (): void => {
    if (addOneTimeItemModalConfig.open) {
      setAddOneTimeItemModalConfig(addItemModalConfigInitState);
    }
    if (addItemModalConfig.open) {
      setAddItemModalConfig(addItemModalConfigInitState);
    }
  };

  const onSaveItemClick = (invoiceLineItemData: ISaveLineItem): void => {
    onSaveLineItemClick(invoiceLineItemData);
    if (!invoiceLineItemData.saveToCatalog) {
      setLineItemSearchText('');
      onCloseAddItemModal();
    }
  };

  const onModifyItemClick = (invoiceLineItem: InvoiceLineItem): void => {
    onModifyLineItemClick(invoiceLineItem);
    onCloseAddItemModal();
  };

  const onCloseAddFromCatalogModal = (): void => {
    resetCategoryItemListQuery();
    setShowAddFromCatalogModal(false);
  };

  const initiateCategoryItemSearch = (searchTerm?: string) => {
    setCategoryItemListQueryParams(prevQueryParams => ({
      ...prevQueryParams,
      search: searchTerm === '' ? null : searchTerm
    }));
  };

  const debouncedCategoryItemSearchHandler = useCallback(debounce(initiateCategoryItemSearch, 500), [
    categoryItemListQueryParams
  ]);

  const isLineItemPresentInInvoice = ({
    item = {
      catalogueLineItemId: 0,
      id: null,
      itemDescription: '',
      unitCost: '0',
      quantity: '1',
      total: 0,
      additionalDetails: '',
      taxes: [],
      sku: ''
    },
    invoiceLineItemList = []
  }: {
    item: InvoiceLineItem | InvoiceLineItemEditPage;
    invoiceLineItemList: InvoiceLineItem[] | InvoiceLineItemEditPage[];
  }): InvoiceLineItem | InvoiceLineItemEditPage => {
    if (invoiceLineItemList.length === 0) {
      return null;
    }
    const itemTaxIDSet = new Set<number>(item?.taxes?.map(tax => tax.id));

    return invoiceLineItemList.find(invoiceLineItem => {
      const invoiceLineItemTaxIDSet = new Set<number>(invoiceLineItem?.taxes?.map(tax => tax.id));

      if (
        invoiceLineItem.catalogueLineItemId === item.catalogueLineItemId &&
        invoiceLineItem.itemDescription === item.itemDescription &&
        +invoiceLineItem.unitCost === +item.unitCost &&
        invoiceLineItem.additionalDetails === item.additionalDetails &&
        hasSameTaxes({ primaryIDSet: itemTaxIDSet, secondaryIDSet: invoiceLineItemTaxIDSet }) &&
        invoiceLineItem.sku === item.sku
      ) {
        return invoiceLineItem;
      }
    });
  };

  const onCatalogItemClick = (item: IInvoiceListingItem): void => {
    const itemToBeAdded: InvoiceLineItem | InvoiceLineItemEditPage = {
      id: null,
      catalogueLineItemId: item.id,
      itemDescription: item.name,
      additionalDetails: item.description,
      quantity: '1',
      total: 0,
      unitCost: item.unitPrice,
      taxes: item.taxes,
      sku: item?.sku ?? ''
    };
    if (isLineItemPresentInInvoice({ item: itemToBeAdded, invoiceLineItemList: invoiceLineItems })) {
      if (isEditInvoiceFlow) {
        dispatch(incrementCatalogLineItemQtyInEditSlice(itemToBeAdded));
      } else {
        dispatch(incrementCatalogLineItemQty(itemToBeAdded));
      }
      dispatch(
        showNotifier({
          type: NotifierTypes.SUCCESS,
          message: {
            primaryMessage: 'Item successfully added',
            isMessageHtml: false
          }
        })
      );
    } else {
      const addItemModalInitData: IAddItemModalData = {
        id: null,
        catalogueLineItemId: item.id,
        name: item.name,
        unitPrice: item.unitPrice,
        description: item.description,
        selectedTaxes: item.taxes ?? [],
        quantity: '1',
        sku: item?.sku ?? ''
      };

      setAddItemModalConfig(prev => ({ ...prev, data: addItemModalInitData, open: true }));
    }
    setShowAddFromCatalogModal(false);
  };

  const onSuggestedItemClick = async (item: IInvoiceSuggestedItem) => {
    const payload: LineItemByIdPayload = {
      shouldInitialiseState: false,
      teamId: currentTeam?.id,
      id: item.id.toString()
    };
    try {
      const itemData: IItem = await getLineItemsByIdQuery(payload).unwrap();

      const itemToBeAdded: InvoiceLineItem | InvoiceLineItemEditPage = {
        id: null,
        catalogueLineItemId: itemData.id,
        itemDescription: itemData.name,
        additionalDetails: itemData.description,
        quantity: '1',
        total: 0,
        unitCost: itemData.unitPrice,
        taxes: itemData.taxes,
        sku: itemData?.sku ?? ''
      };

      if (isLineItemPresentInInvoice({ item: itemToBeAdded, invoiceLineItemList: invoiceLineItems })) {
        if (isEditInvoiceFlow) {
          dispatch(incrementCatalogLineItemQtyInEditSlice(itemToBeAdded));
        } else {
          dispatch(incrementCatalogLineItemQty(itemToBeAdded));
        }
        dispatch(
          showNotifier({
            type: NotifierTypes.SUCCESS,
            message: {
              primaryMessage: 'Item successfully added',
              isMessageHtml: false
            }
          })
        );
      } else {
        const addItemModalInitData: IAddItemModalData = {
          id: null,
          catalogueLineItemId: itemData.id,
          name: itemData.name,
          unitPrice: itemData.unitPrice,
          description: itemData.description,
          selectedTaxes: itemData.taxes ?? [],
          quantity: '1',
          sku: itemData?.sku
        };
        setAddItemModalConfig(prev => ({ ...prev, data: addItemModalInitData, open: true }));
      }
    } catch (error) {
      Logger.logException(error);
    }
  };

  const reloadGetCategoryItemListData = (): void => {
    resetRecallCategoryItemListQuery();
  };

  return (
    <>
      <div className={`${className}`}>
        <AddLineItemSection
          onSuggestedItemClick={onSuggestedItemClick}
          isEditInvoiceFlow={isEditInvoiceFlow}
          onClearSearchInput={onClearSearchInput}
          onSearchInputChange={onSearchInputChange}
          searchId="add-line-item"
          searchInputvalue={lineItemSearchText}
          showSuggestionList={true}
          onCreateOneTimeItem={onCreateOneTimeItem}
          handleAddFromCatalogClick={reloadGetCategoryItemListData}
        />
      </div>
      {addOneTimeItemModalConfig.open && (
        <AddItemModal
          title="Create one-time item"
          onSaveLineItemClick={onSaveItemClick}
          onModifyLineItemClick={onModifyItemClick}
          config={addOneTimeItemModalConfig}
          onClose={onCloseAddItemModal}
          enableSaveToCatalog={true}
          resetItemModalConfig={onCloseAddItemModal}
          initNameValue={addOneTimeItemModalConfig.data.name}
          modalType={EItemModalType.ONE_TIME_ADD_ITEM_MODAL}
          isEditInvoiceFlow={isEditInvoiceFlow}
        />
      )}

      {addItemModalConfig.open && (
        <AddItemModal
          title="Add Item"
          onSaveLineItemClick={onSaveItemClick}
          onModifyLineItemClick={onModifyItemClick}
          config={addItemModalConfig}
          onClose={onCloseAddItemModal}
          resetItemModalConfig={onCloseAddItemModal}
          initNameValue={addItemModalConfig.data.name}
          shouldInitializeData={true}
          modalType={EItemModalType.CATALOG_ADD_ITEM_MODAL}
          isEditInvoiceFlow={isEditInvoiceFlow}
        />
      )}

      {showAddFromCatalogModal && (
        <AddFromCatalogModal
          onCatalogItemClick={onCatalogItemClick}
          isLoadingList={isLoadingCategoryItemList}
          isLoadingListSuccess={isLoadingCategoryItemListSuccess}
          apiErrorCode={categoryItemListApiErrorCode}
          handleSearch={debouncedCategoryItemSearchHandler}
          hasMoreListItems={hasMoreCategoriesOrItems}
          listData={categoryItemListData}
          totalCategoryListCount={totalCategoryListCount}
          totalLineItemListCount={totalLineItemListCount}
          onClose={onCloseAddFromCatalogModal}
          setIntersectionElement={setCategoryItemIntersectionElement}
          open={showAddFromCatalogModal}
          title="Items"
          onCreateOneTimeItemFromCatalogPicker={onCreateOneTimeItemFromCatalogPicker}
          handleReload={reloadGetCategoryItemListData}
        />
      )}
    </>
  );
};

export default AddLineItemSectionWrapper;
