import React, { FC } from 'react';
import { FailedIcon, TopBarIcon } from 'assets/icons';
import { SUPPORT_MAIL } from 'config/index';
import TestimonialSection from 'containers/auth/components/TestimonialSection';

const DeclinedPage: FC = () => {
  return (
    <div className="flex w-screen">
      <div className="customNormalScroll h-screen w-full overflow-hidden sm:w-1/2">
        <div className="flex h-full flex-col">
          <TopBarIcon className="mt-12 ml-[75px] shrink-0" />
          <div
            className="mx-auto flex max-w-[375px] grow flex-col items-center justify-center
                       px-2.5 text-center sm:w-[375px]">
            <FailedIcon className="mb-5 shrink-0" />
            <div className="mb-4 text-xl font-bold text-headingGray">Application Declined</div>
            <div className="flex flex-col gap-[18px]">
              <div className="text-sbase text-primaryText">Thank you for your interest in ScanPay.</div>
              <div className="text-sbase text-primaryText">
                After careful consideration, we are unable to proceed with your application.
              </div>
              <div className="text-sbase text-primaryText">
                Unfortunatley, due to high demand, we are unable to accomodate every application. We'll reach back out
                if we are able to accomodate you in the future.
              </div>
            </div>
            <div className="mt-[120px] text-sbase text-primaryText">
              For further queries, please contact us at{' '}
              <a className="cursor-pointer" href={`mailto:${SUPPORT_MAIL}`}>
                {SUPPORT_MAIL}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden h-screen w-1/2 sm:flex">
        <TestimonialSection />
      </div>
    </div>
  );
};

export default DeclinedPage;
