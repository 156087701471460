import Divider from '@material-ui/core/Divider';
import {
  CaretRightIcon,
  OpenNewTabIcon,
  PlusIconWhite,
  SelectionCircleBlueIcon,
  SelectionCircleIcon,
  ServiceChargeTabIcon
} from 'assets/icons';
import { CustomButton, If } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useCallback, useEffect, useState } from 'react';
import {
  selectInitializingTaxSliceInProgress,
  selectTaxState,
  updateApplyToAll,
  updateDeselectedItems,
  updateSelectedItems,
  updateSelectedServiceCharges
} from '../taxSlice';
import PaginatedListSearchModal from 'containers/product-catalog/components/PaginatedListSearchModal';
import { debounce, openInNewTab } from 'utils/commonUtils';
import { IItemBasic } from 'containers/product-catalog/items/items.model';
import { TaxApplyToType } from '../type';
import { IServiceChargeBasic } from 'containers/product-catalog/service-charges/serviceCharge.model';
// eslint-disable-next-line max-len
import { initialQueryParamsState as serviceChargeInitialQueryParamsState } from '../hooks/usePaginatedFetchServiceChargeQuery';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { resetServiceChargeSlice } from 'containers/product-catalog/service-charges/serviceChargeSlice';
import { resetItemSlice } from 'containers/product-catalog/items/itemsSlice';
import { selectCurrentTeam, selectCurrentTeamFeePermissions } from 'containers/app/appSlice';
import { useLazyGetCatalogFetchItemsQuery } from 'containers/product-catalog/items/api';
import { initialQueryParamsState } from 'containers/product-catalog/taxes/hooks/usePaginatedFetchItemsBasicQuery';
import { useLazyGetCatalogFetchServiceChargeQuery } from 'containers/product-catalog/service-charges/api';
import EmptySearchResultState from 'containers/product-catalog/components/EmptySearchResultState';
import { ItemsRouteState, ServiceChargeRouteState } from 'routes/types';
import { sortSelectedItemsToStart } from 'containers/product-catalog/items/utils';
import { sortSelectedServiceChargesToStart } from 'containers/product-catalog/service-charges/utils';
import ItemsTabReactIcon from 'assets/react-icons/ItemsTabReactIcon';
import { CATALOG_FAQ_LINK } from 'config/index';
import { PaginatedListItemNames } from 'containers/product-catalog/constants';

interface ApplyTaxSectionProps {
  className?: string;
}

const ApplyTaxSection: FC<ApplyTaxSectionProps> = ({ className = '' }) => {
  const dispatch = useAppDispatch();
  const selectedTaxState = useAppSelector(selectTaxState);
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);

  const navigate = useNavigate();

  const currentTeam = useAppSelector(selectCurrentTeam);
  const initializingTaxSliceInProgress = useAppSelector(selectInitializingTaxSliceInProgress);
  const [applyToAllItems, setApplyToAllItems] = useState<boolean>(true);
  const [applyToAllServiceCharges, setApplyToAllServiceCharges] = useState<boolean>(true);

  const [showItemSelectionModal, setShowItemSelectionModal] = useState<boolean>(false);
  const [showServiceChargeSelectionModal, setShowServiceChargeSelectionModal] = useState<boolean>(false);
  const [itemSearchTerm, setItemSearchTerm] = useState<string>(null);
  const [serviceChargeSearchTerm, setServiceChargeSearchTerm] = useState<string>(null);

  const [fetchItemsBasicData, itemListQueryState] = useLazyGetCatalogFetchItemsQuery();

  const [fetchServiceChargeBasicData, serviceChargeListQueryState] = useLazyGetCatalogFetchServiceChargeQuery();

  const { manageItemsAndCategories, manageServiceCharges } = catalogSettingsPermissions ?? {};

  const {
    data: itemList,
    isSuccess: isItemListLoadingSuccess,
    isError: isItemListLoadingError,
    isFetching: isLoadingItems,
    error: itemsError
  } = itemListQueryState;

  const {
    data: serviceChargeList,
    isSuccess: isServiceChargeListLoadingSuccess,
    isError: isServiceChargeListLoadingError,
    isFetching: isLoadingServiceChargeList,
    error: serviceChargeError
  } = serviceChargeListQueryState;

  const fetchListItems = () => {
    fetchItemsBasicData({
      ...initialQueryParamsState,
      teamId: currentTeam?.id,
      limit: 1000,
      page: 0,
      taxId: selectedTaxState.taxId
    });
  };

  const fetchServiceChargesList = () => {
    fetchServiceChargeBasicData({
      ...serviceChargeInitialQueryParamsState,
      teamId: currentTeam?.id,
      limit: 1000,
      page: 0,
      taxId: selectedTaxState.taxId
    });
  };

  useEffect(() => {
    if (!initializingTaxSliceInProgress) {
      setApplyToAllItems(selectedTaxState.applyToAllItems);
      setApplyToAllServiceCharges(selectedTaxState.applyToAllServiceCharges);
    }
  }, [selectedTaxState]);

  const getSelectedItemsText = (count: number): string => {
    if (count === 0) {
      return '0 selected';
    } else if (count > 1) {
      return `${count} items`;
    } else if (count === 1) {
      return '1 item';
    }
    return '';
  };

  const onToggleItemSelection = (): void => {
    fetchListItems();
    setShowItemSelectionModal(prev => !prev);
  };

  const onToggleServiceChargeSelection = (): void => {
    fetchServiceChargesList();
    setShowServiceChargeSelectionModal(prev => !prev);
  };

  const onCloseItemSelection = (): void => {
    onClearItemSearch();
    setShowItemSelectionModal(false);
  };

  const onCloseServiceChargeSelection = (): void => {
    onClearServiceChargeSearch();
    setShowServiceChargeSelectionModal(false);
  };

  const initiateItemSearch = (searchTerm?: string) => {
    setItemSearchTerm(searchTerm);
  };

  const initiateServiceChargeSearch = (searchTerm?: string) => {
    setServiceChargeSearchTerm(searchTerm);
  };

  const debouncedItemSearchHandler = useCallback(debounce(initiateItemSearch, 500), []);
  const debouncedServiceChargeSearchHandler = useCallback(debounce(initiateServiceChargeSearch, 500), []);

  const findSelectedItems = (): IItemBasic[] => {
    return selectedTaxState.items;
  };

  const onSaveSelectedItems = ({ selectedItems }: { selectedItems: IItemBasic[] }): void => {
    const unselectedItemIds = itemList?.data
      .filter(item => !selectedItems.some(selectedItem => selectedItem.id === item.id))
      .map(item => item.id);
    dispatch(updateDeselectedItems(unselectedItemIds));
    dispatch(updateSelectedItems(selectedItems));
    onCloseItemSelection();
  };

  const onSaveSelectedServiceCharges = ({ selectedItems }: { selectedItems: IServiceChargeBasic[] }): void => {
    dispatch(updateSelectedServiceCharges(selectedItems));
    onCloseServiceChargeSelection();
  };

  const onApplyToAllItems = (): void => {
    setApplyToAllItems(true);
    dispatch(updateApplyToAll({ type: TaxApplyToType.ITEM, value: true }));
  };

  const onApplyToSelectItems = (): void => {
    setApplyToAllItems(false);
    dispatch(updateApplyToAll({ type: TaxApplyToType.ITEM, value: false }));
  };

  const onApplyToAllServiceCharges = (): void => {
    setApplyToAllServiceCharges(true);
    dispatch(updateApplyToAll({ type: TaxApplyToType.SERVICE_CHARGE, value: true }));
  };

  const onApplyToSelectServiceCharges = (): void => {
    setApplyToAllServiceCharges(false);
    dispatch(updateApplyToAll({ type: TaxApplyToType.SERVICE_CHARGE, value: false }));
  };

  const findSelectedServiceCharges = (): IServiceChargeBasic[] => {
    return selectedTaxState.serviceCharges;
  };

  const onCreateItem = (): void => {
    dispatch(resetItemSlice);
    const createItemRouteState: ItemsRouteState = {
      callbackURL: location?.pathname
    };
    navigate(routesPath.ITEMS_CREATE, { replace: false, state: createItemRouteState });
  };

  const getListItems = () => {
    if (isItemListLoadingSuccess && !isLoadingItems) {
      const itemsListData = sortSelectedItemsToStart({
        itemsList: itemList.data,
        currentlySelectedItems: findSelectedItems()
      });
      if (itemSearchTerm) {
        return itemsListData.filter(item => item.name.toLowerCase().includes(itemSearchTerm.toLowerCase()));
      }
      return itemsListData;
    }
    return [];
  };

  const onClearItemSearch = () => {
    setItemSearchTerm(null);
  };

  const onClearServiceChargeSearch = () => {
    setServiceChargeSearchTerm(null);
  };

  const isItemSearchResultEmpty =
    getListItems().length === 0 && !isLoadingItems && itemSearchTerm !== null && itemSearchTerm !== '';

  const isFetchItemsResultEmpty =
    getListItems().length === 0 && !isLoadingItems && !itemSearchTerm && isItemListLoadingSuccess;

  const getServiceChargeList = () => {
    if (!isLoadingServiceChargeList && isServiceChargeListLoadingSuccess) {
      const serviceChargesListData = sortSelectedServiceChargesToStart({
        serviceChargesList: serviceChargeList.data,
        currentlySelectedServiceCharges: findSelectedServiceCharges()
      });
      if (serviceChargeSearchTerm) {
        return serviceChargesListData.filter(item => {
          return item.name.toLowerCase().includes(serviceChargeSearchTerm.toLowerCase());
        });
      }
      return serviceChargesListData;
    }
    return [];
  };

  const isServiceChargeSearchResultEmpty =
    getServiceChargeList().length === 0 &&
    !isLoadingServiceChargeList &&
    serviceChargeSearchTerm !== null &&
    serviceChargeSearchTerm !== '';

  const isFetchServiceChargesResultEmpty =
    getServiceChargeList().length === 0 &&
    !isLoadingServiceChargeList &&
    !serviceChargeSearchTerm &&
    isServiceChargeListLoadingSuccess;

  const renderEmptyItemState = (): JSX.Element => {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-10">
        <div className="flex flex-col items-center gap-5">
          <ItemsTabReactIcon
            id="apply-tax-empty-state"
            className="path-fill-current h-16 w-16 shrink-0 text-borderGray"
          />
          <div className="text-center text-sbase font-semibold tracking-[-0.3px] text-primaryText">
            <div className="max-w-[220px]">
              {'Create Items that team members can add to invoices for a faster checkout. '}
              <span className="cursor-pointer text-secondary" onClick={() => openInNewTab(CATALOG_FAQ_LINK)}>
                Learn more <OpenNewTabIcon className="mb-1 inline" />
              </span>
            </div>
          </div>
        </div>
        {manageItemsAndCategories && (
          <CustomButton
            id="select-item-create-item-button"
            onClick={onCreateItem}
            className="h-[38px] text-primaryBtnText"
            StartIcon={PlusIconWhite}
            childrenContainerStyles="mr-1">
            <div className="text-sbase font-semibold">Create item</div>
          </CustomButton>
        )}
      </div>
    );
  };

  const onCreateServiceCharge = (): void => {
    dispatch(resetServiceChargeSlice());
    const createServiceChargeRouteState: ServiceChargeRouteState = {
      callbackURL: location?.pathname
    };
    navigate(routesPath.SERVICE_CHARGES_CREATE, { replace: false, state: createServiceChargeRouteState });
  };

  const renderEmptyServiceChargeState = (): JSX.Element => {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-10">
        <div className="flex flex-col items-center gap-5">
          <ServiceChargeTabIcon className="path-stroke-current h-16 w-16 text-gray-1" />
          <div className="text-center text-sbase font-semibold tracking-[-0.3px] text-primaryText">
            <div className="max-w-[220px]">
              {'Create Service Charges to cover your additional expenses, like after-hours travel fees. '}
              <span className="cursor-pointer text-secondary" onClick={() => openInNewTab(CATALOG_FAQ_LINK)}>
                Learn more <OpenNewTabIcon className="mb-1 inline" />
              </span>
            </div>
          </div>
        </div>
        {manageServiceCharges && (
          <CustomButton
            id="select-item-create-item-button"
            onClick={onCreateServiceCharge}
            className="h-[38px] text-primaryBtnText"
            StartIcon={PlusIconWhite}
            childrenContainerStyles="mr-1">
            <div className="text-sbase font-semibold">Create service charge</div>
          </CustomButton>
        )}
      </div>
    );
  };

  const reloadGetItemsData = (): void => {
    fetchListItems();
  };

  const reloadGetServiceChargeData = (): void => {
    fetchServiceChargesList();
  };

  return (
    <>
      <div className={`${className} flex flex-col`}>
        <div className="mb-7 flex flex-col">
          <div className="mb-2 text-17px font-semibold text-heading">Apply tax to</div>
          <Divider className="bg-borderGray" />
          <div className="mt-6 mb-2 flex flex-row items-center">
            <ItemsTabReactIcon id="apply-tax" />
            <div className="ml-1.5 text-17px font-semibold text-heading">Items</div>
          </div>

          <Divider className="bg-secondaryBtn" />
          <div onClick={onApplyToAllItems} className="flex cursor-pointer flex-row py-5">
            <div className="mt-[2px]">
              <If condition={!applyToAllItems}>
                <SelectionCircleIcon className="h-[18px] w-[18px] shrink-0 cursor-pointer" />
              </If>
              <If condition={applyToAllItems}>
                <SelectionCircleBlueIcon className="h-[18px] w-[18px] shrink-0" />
              </If>
            </div>
            <div className="ml-5 flex flex-col text-primaryText">
              <div className="text-sbase font-semibold">All items</div>
              <div className="text-px13 font-normal ">Includes all future items.</div>
            </div>
          </div>
          <Divider className="bg-secondaryBtn" />
          <div onClick={onApplyToSelectItems} className="flex cursor-pointer flex-row py-5">
            <div className="">
              <If condition={applyToAllItems}>
                <SelectionCircleIcon className="h-[18px] w-[18px] shrink-0 cursor-pointer" />
              </If>
              <If condition={!applyToAllItems}>
                <SelectionCircleBlueIcon className="h-[18px] w-[18px] shrink-0" />
              </If>
            </div>
            <div className="ml-4 text-sbase font-semibold text-primaryText">Select items</div>

            <div onClick={onToggleItemSelection} className="ml-auto flex cursor-pointer flex-row justify-between">
              <div className="flex flex-row items-center gap-[6px]">
                <div className={`text-sbase font-semibold ${applyToAllItems ? 'text-accentText' : 'text-secondary'}`}>
                  {getSelectedItemsText(selectedTaxState.lineItemsCount)}
                </div>
                <CaretRightIcon className="shrink-0" />
              </div>
            </div>
          </div>
          <Divider className="bg-secondaryBtn" />

          <Divider className="bg-secondaryBtn" />
        </div>

        <div className="flex flex-col">
          <div className="mb-2 flex items-center text-17px font-semibold text-heading">
            <ServiceChargeTabIcon className="h-5 w-5" />
            <div className="ml-1.5 text-17px font-semibold text-heading">Service charges</div>
          </div>
          <Divider className="bg-secondaryBtn" />
          <div onClick={onApplyToAllServiceCharges} className="flex cursor-pointer flex-row py-5">
            <div className="mt-[2px]">
              <If condition={!applyToAllServiceCharges}>
                <SelectionCircleIcon className="h-[18px] w-[18px] shrink-0 cursor-pointer" />
              </If>
              <If condition={applyToAllServiceCharges}>
                <SelectionCircleBlueIcon className="h-[18px] w-[18px] shrink-0" />
              </If>
            </div>
            <div className="ml-5 flex flex-col text-primaryText">
              <div className="text-sbase font-semibold">All service charges</div>
              <div className="text-px13 font-normal ">Includes all future service charges.</div>
            </div>
          </div>
          <Divider className="bg-secondaryBtn" />
          <div onClick={onApplyToSelectServiceCharges} className="flex cursor-pointer flex-row py-5">
            <div className="">
              <If condition={applyToAllServiceCharges}>
                <SelectionCircleIcon className="h-[18px] w-[18px] shrink-0 cursor-pointer" />
              </If>
              <If condition={!applyToAllServiceCharges}>
                <SelectionCircleBlueIcon className="h-[18px] w-[18px] shrink-0" />
              </If>
            </div>
            <div className="ml-4 text-sbase font-semibold text-primaryText">Select service charges</div>

            <div
              onClick={onToggleServiceChargeSelection}
              className="ml-auto flex cursor-pointer flex-row justify-between">
              <div className="flex flex-row items-center gap-[6px]">
                <div
                  className={`text-sbase font-semibold 
                ${applyToAllServiceCharges ? 'text-accentText' : 'text-secondary'}`}>
                  {getSelectedItemsText(selectedTaxState.serviceChargesCount)}
                </div>
                <CaretRightIcon className="shrink-0" />
              </div>
            </div>
          </div>
          <Divider className="bg-secondaryBtn" />

          <Divider className="bg-secondaryBtn" />
        </div>
      </div>
      {showItemSelectionModal && (
        <PaginatedListSearchModal
          getSelectedItems={findSelectedItems}
          itemType={PaginatedListItemNames.ITEM}
          onClose={onCloseItemSelection}
          onPrimaryBtnClick={onSaveSelectedItems}
          onSecondaryBtnClick={onCloseItemSelection}
          searchInputConfig={{ enable: true, placeholder: 'Search items to add' }}
          title="Select items"
          open={showItemSelectionModal}
          primaryBtnLabel="Done"
          secondaryBtnLabel="Cancel"
          list={getListItems()}
          hasMoreListItems={false}
          isLoadingList={isLoadingItems}
          setIntersectionElement={null}
          handleSearch={debouncedItemSearchHandler}
          handleClearSearch={onClearItemSearch}
          renderEmptyState={renderEmptyItemState}
          isLoadingSuccess={isItemListLoadingSuccess}
          handleReload={reloadGetItemsData}
          apiErrorCode={isItemListLoadingError ? itemsError.code : null}
          isError={isItemListLoadingError}
          isSearchResultEmpty={isItemSearchResultEmpty}
          isFetchResultEmpty={isFetchItemsResultEmpty}
          renderSearchEmptyState={
            <EmptySearchResultState
              keyword="item"
              onCreateButtonClick={onCreateItem}
              hasCreatePermissions={manageItemsAndCategories}
            />
          }
        />
      )}

      {showServiceChargeSelectionModal && (
        <PaginatedListSearchModal
          // TODO need this?
          getSelectedItems={findSelectedServiceCharges}
          itemType={PaginatedListItemNames.SERVICE_CHARGE}
          onClose={onCloseServiceChargeSelection}
          onPrimaryBtnClick={onSaveSelectedServiceCharges}
          onSecondaryBtnClick={onCloseServiceChargeSelection}
          searchInputConfig={{ enable: true, placeholder: 'Search service charges to add' }}
          title="Select service charges"
          open={showServiceChargeSelectionModal}
          primaryBtnLabel="Done"
          secondaryBtnLabel="Cancel"
          list={getServiceChargeList()}
          hasMoreListItems={false}
          isLoadingList={isLoadingServiceChargeList}
          setIntersectionElement={null}
          handleSearch={debouncedServiceChargeSearchHandler}
          handleClearSearch={onClearServiceChargeSearch}
          renderEmptyState={renderEmptyServiceChargeState}
          isLoadingSuccess={isServiceChargeListLoadingSuccess}
          handleReload={reloadGetServiceChargeData}
          apiErrorCode={isServiceChargeListLoadingError ? serviceChargeError.code : null}
          isError={isServiceChargeListLoadingError}
          isSearchResultEmpty={isServiceChargeSearchResultEmpty}
          renderSearchEmptyState={
            <EmptySearchResultState
              keyword="service charge"
              onCreateButtonClick={onCreateServiceCharge}
              hasCreatePermissions={manageServiceCharges}
            />
          }
          isFetchResultEmpty={isFetchServiceChargesResultEmpty}
        />
      )}
    </>
  );
};

export default ApplyTaxSection;
