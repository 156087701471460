export enum VerificationTypes {
  TEXT = 'text',
  EMAIL = 'email'
}

export enum AccountSettingsFlowTypes {
  UPDATE_EMAIL = 'Update email',
  RESET_PASSWORD = 'Reset password',
  VERIFY_EMAIL = 'Verify email'
}

export enum EmailErrorMessages {
  ALREADY_EXISTS = 'Account already present. This email address is in use with another team member or ScanPay account.',
  ALREADY_ADDED = `The new email you entered matches the old email. 
  Please enter a different email if you'd like to update it.`
}
