import { NoSearchResultsIcon, OpenNewTabIcon, PlusIconWhite, SearchIcon, TaxEmptyIcon } from 'assets/icons';
import { CustomButton, GenericDeleteModal, If, SearchBar } from 'components';
import { debounce } from 'lodash';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { initialTaxesQueryParamsState, usePaginatedGetTaxesQuery } from './hooks/usePaginatedGetTaxesQuery';
import isEqual from 'lodash/isEqual';
import TaxTable from './components/TaxTable';
import { ITax } from './taxes.model';
import { TaxActionType } from './type';
import { Divider } from '@material-ui/core';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useAppSelector } from 'hooks/typedHooks';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamFeePermissions,
  showNotifier
} from 'containers/app/appSlice';
import routesPath from 'routes/RoutesPath';
import { useNavigate } from 'react-router-dom';
import { emptyTax } from './constants';
import { useDeleteCatalogTaxMutation } from './api';
import { useDispatch } from 'react-redux';
import { resetTaxSlice } from './taxSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { InfoMesssages } from 'types/infoMessages';
import { CleverTapEventsCatalogTaxes } from './events';
import { logAnalyticEvent } from 'utils/analytics';
import { openInNewTab } from 'utils/commonUtils';
import { CATALOG_FAQ_LINK } from 'config/index';

interface ITaxDeleteModalState {
  open: boolean;
  tax: ITax;
}

const Taxes: FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const notifications = useAppSelector(getNotifications);

  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);

  const hasManageTaxesPermissions = catalogSettingsPermissions.manageTaxes;

  const {
    list: taxList,
    isLoading: isLoadingTaxes,
    loadMore,
    resetQuery: resetTaxesQuery,
    resetRecallQuery: resetRecallTaxesQuery,
    hasMore: hasMoreTaxes,
    setQueryParams: setTaxesQueryParams,
    queryParams: taxesQueryParams,
    totalListCount: taxesCount,
    isLoadingSuccess: isTaxesListLoadingSuccess
  } = usePaginatedGetTaxesQuery();

  const [deleteTaxModalState, setDeleteTaxModalState] = useState<ITaxDeleteModalState>({
    open: false,
    tax: emptyTax
  });

  const [
    deleteTaxMutation,
    { isSuccess: isDeleteTaxSuccess, isLoading: isDeleteTaxMutationInProgress, isError: isDeleteTaxError }
  ] = useDeleteCatalogTaxMutation();

  useEffect(() => {
    if (hasManageTaxesPermissions) {
      resetRecallTaxesQuery();
      setShowSearchBar(false);
    }
  }, [currentTeam?.id, hasManageTaxesPermissions]);

  useEffect(() => {
    if (isDeleteTaxSuccess) {
      resetRecallTaxesQuery();
      toggleDeleteTaxModal();
    }
  }, [isDeleteTaxSuccess]);

  useEffect(() => {
    if (!isLoadingTaxes && taxList?.length > 0) {
      setShowSearchBar(true);
    }
    if (taxList?.length === 0 && !isInitialQueryParamsState()) {
      setShowSearchBar(true);
    }
    if (taxList?.length === 0 && isInitialQueryParamsState()) {
      setShowSearchBar(false);
    }
  }, [isLoadingTaxes]);

  const taxesListLoader = useRef(loadMore);

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: taxesListLoader,
    threshold: 0.5
  });

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    debouncedSearchHandler(searchTerm);
  };

  const initiateSearch = (searchTerm: string) => {
    setTaxesQueryParams(prevQueryParams => ({ ...prevQueryParams, search: searchTerm?.trim() || null }));
    if (searchTerm?.trim()) {
      logAnalyticEvent(CleverTapEventsCatalogTaxes.webCatalogTaxesSearch, { searchTerm: searchTerm?.trim() });
    }
  };

  const onClearSearch = () => {
    setSearchText('');
    initiateSearch(null);
  };

  const debouncedSearchHandler = useCallback(debounce(initiateSearch, 500), [taxesQueryParams]);

  const handleSearchIconClick = () => {
    setSearchIconClicked(true);
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: false
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handleCreateTax = () => {
    if (hasManageTaxesPermissions) {
      dispatch(resetTaxSlice());
      navigate(routesPath.TAXES_CREATE);
      logAnalyticEvent(CleverTapEventsCatalogTaxes.webCatalogTaxesCreateTax);
    } else {
      onInfoClick('create-tax', InfoMesssages.createTaxesNoPermissionMessage);
    }
  };

  const isInitialQueryParamsState = () => {
    return isEqual(taxesQueryParams, initialTaxesQueryParamsState);
  };

  const deleteTaxHandler = () => {
    deleteTaxMutation({
      id: deleteTaxModalState.tax.id,
      teamId: currentTeam.id
    });
    logAnalyticEvent(CleverTapEventsCatalogTaxes.webCatalogTaxesDeleteTax, {
      name: deleteTaxModalState.tax.name,
      id: deleteTaxModalState.tax.id
    });
  };

  const toggleDeleteTaxModal = (tax?: ITax) => {
    if (deleteTaxModalState.open) {
      setDeleteTaxModalState({ open: false, tax: emptyTax });
      return;
    }
    setDeleteTaxModalState({ open: true, tax: tax });
  };

  const handleMoreActionsClick = ({ taxAction, tax }: { taxAction: TaxActionType; tax: ITax }) => {
    switch (taxAction) {
      case TaxActionType.EDIT:
        // eslint-disable-next-line no-case-declarations
        const url = routesPath.TAXES_EDIT.replace(':taxId', tax.id.toString());
        navigate(url);
        logAnalyticEvent(CleverTapEventsCatalogTaxes.webCatalogTaxesEditTax);
        break;
      case TaxActionType.DELETE:
        toggleDeleteTaxModal(tax);
        break;
      default:
        break;
    }
  };

  const isTaxResultEmpty =
    taxList?.length === 0 &&
    !isLoadingTaxes &&
    taxesQueryParams.search === null &&
    isInitialQueryParamsState() &&
    isTaxesListLoadingSuccess;

  const isSearchResultEmpty =
    taxList?.length === 0 && !isLoadingTaxes && taxesQueryParams.search !== null && taxesQueryParams.search !== '';

  const renderCreateButton = ({ className = '' }: { className?: string } = {}): JSX.Element => (
    <CustomButton
      id="create-tax-button"
      onClick={handleCreateTax}
      className={`h-[38px] text-primaryBtnText ${className}`}
      StartIcon={PlusIconWhite}
      childrenContainerStyles="mr-1"
      showDisabledReason={!hasManageTaxesPermissions}>
      <div className="text-sbase font-semibold">Create Tax</div>
    </CustomButton>
  );

  return (
    <div className="table h-full px-6 pb-20 pt-6">
      <div className="mb-7 flex flex-row items-center gap-3">
        <div className="mr-2.5 text-3xl font-bold text-primaryText">Taxes</div>
        <If condition={!searchIconClicked && showSearchBar && hasManageTaxesPermissions}>
          <div onClick={handleSearchIconClick}>
            <SearchIcon className="mx-2 flex h-10 cursor-pointer lg:hidden" />
          </div>
        </If>
        {showSearchBar && hasManageTaxesPermissions && (
          <SearchBar
            id="catalog-taxes-search"
            handleClear={onClearSearch}
            handleChange={onSearchTermChange}
            value={searchText}
            placeholder="Search taxes"
            wrapperStyle={`sm:w-48 h-10 border border-borderGray rounded ${!searchIconClicked ? 'lg:flex hidden' : ''}
          ${!hasManageTaxesPermissions ? 'pointer-events-none' : ''}`}
            showSearchIcon={true}
            showCrossIcon={true}
            autoFocus={false}
          />
        )}
        <If condition={!isTaxResultEmpty}> {renderCreateButton({ className: 'ml-auto' })} </If>
      </div>
      <div className="customNormalScroll table-row h-full grow align-middle">
        {hasManageTaxesPermissions ? (
          <>
            {/* empty state */}
            <If condition={isTaxResultEmpty}>
              <div className="flex h-full flex-col items-center justify-center gap-10">
                <div className="flex flex-col items-center gap-5">
                  <TaxEmptyIcon />
                  <div className="text-center text-sbase font-semibold text-primaryText">
                    Setup Taxes to auto-calculate <br />
                    and apply taxes to be collected in <br /> your invoices.{' '}
                    <span className="cursor-pointer text-secondary" onClick={() => openInNewTab(CATALOG_FAQ_LINK)}>
                      Learn more <OpenNewTabIcon className="mb-1 inline" />
                    </span>
                  </div>
                </div>
                {renderCreateButton()}
              </div>
            </If>

            {!isLoadingTaxes && (
              <div>
                <If condition={taxesQueryParams.search !== null && searchText !== ''}>
                  <div className="py-5 text-sbase text-accentText">{`${taxesCount} ${
                    taxesCount === 1 ? 'result' : 'results'
                  }`}</div>
                </If>
                <If condition={searchText === '' && !isTaxResultEmpty}>
                  <div className="pt-5 pb-4 text-sbase text-accentText">{` Total ${taxesCount} ${
                    taxesCount === 1 ? 'tax' : 'taxes'
                  } `}</div>
                </If>
              </div>
            )}

            <If condition={isSearchResultEmpty}>
              <div className="flex h-full flex-col items-center justify-center gap-5">
                <div className="flex flex-col items-center gap-5">
                  <NoSearchResultsIcon />
                  <div className="text-sbase text-primaryText">No results found!</div>
                </div>
              </div>
            </If>
            <If condition={taxList?.length !== 0}>
              <>
                <Divider className="w-full bg-gray-100" />
                <TaxTable taxList={taxList} handleMoreActionsClick={handleMoreActionsClick} />
              </>
            </If>

            <If condition={!isLoadingTaxes && hasMoreTaxes}>
              <div className="h-2" ref={setIntersectionElement}></div>
            </If>
            <If condition={isLoadingTaxes}>
              <InfinteLoadingSpinner />
            </If>
          </>
        ) : (
          <div className="flex h-full flex-col items-center justify-center gap-5">
            <TaxEmptyIcon />
            <div className="text-center text-sbase text-primaryText">You do not have permissions to view taxes</div>
          </div>
        )}
      </div>
      <GenericDeleteModal
        primaryBtnId="tax-yes-delete-button"
        secondaryBtnId="tax-no-cancel-button"
        showModal={deleteTaxModalState.open}
        isActionInProgress={isDeleteTaxMutationInProgress}
        onPrimaryBtnClick={deleteTaxHandler}
        onSecondaryBtnClick={toggleDeleteTaxModal}
        title="Are you sure you want to delete this Tax?"
        subTitle="Deleting a tax only affects future invoices not past or pending ones."
        primaryBtnLabel="Yes, Delete"
        secondaryBtnLabel="No, Cancel"
      />
    </div>
  );
};

export default Taxes;
