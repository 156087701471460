import React, { FC, useEffect } from 'react';
import { CustomCarousel } from 'components';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { ChevronArrowRightIcon } from 'assets/icons';
import { useLazyGetTeamBannerQuery } from 'containers/app/api';
import { Banner } from 'containers/app/app.model';

interface BannerCarouselProps {
  handleCarouselButtonClick: (action: string, type: string) => void;
  banners: Banner[];
}

const BannerCarousel: FC<BannerCarouselProps> = ({ handleCarouselButtonClick, banners }) => {
  const currentTeam = useAppSelector(selectCurrentTeam);

  const [getPayoutsBannerQuery, { isFetching: isFetchingBanners }] = useLazyGetTeamBannerQuery();

  const isSingleSlide = banners?.length === 1;

  useEffect(() => {
    if (currentTeam?.id && !banners?.length) {
      getPayoutsBannerQuery(currentTeam.id);
    }
  }, [currentTeam?.id]);

  const getBackgroundStyle = (colours: string[]) => {
    let coloursString = colours.join(', ');
    coloursString = coloursString.trim() ? coloursString : '#DAE3F7, #C2D1F2';
    return `linear-gradient(109deg, ${coloursString})`;
  };

  return (
    <>
      {!isFetchingBanners && banners && banners.length > 0 && (
        <CustomCarousel
          className="relative"
          showArrows={!isSingleSlide}
          centerSlidePercentage={isSingleSlide ? 100 : 95}>
          {banners?.map((item, index) => (
            <div key={`payouts-carousel-item-${index}`} className="w-full bg-transparent pb-[26px] pr-5 text-left">
              <div
                className="flex max-h-[184px] w-full rounded-md pl-[46px]"
                style={{ background: getBackgroundStyle(item.colours) }}>
                <div className="w-1/2 py-9">
                  <div className="mb-4 max-w-[99%] truncate text-xl font-bold text-primary">{item.title}</div>
                  <div className="mb-4 max-w-[99%] truncate text-sbase font-normal text-primary">{item.sub_title}</div>
                  <button
                    id={`payouts-carousel-item-${index}-cta`}
                    onClick={() => handleCarouselButtonClick(item.cta_action, item.cta_type)}
                    className="flex h-8 items-center gap-2 rounded-md px-2.5 text-xs font-normal md:text-sbase"
                    type={null}
                    style={{ color: item.cta_text_colour, backgroundColor: item.cta_bg_colour }}>
                    {item.cta_text}
                    <ChevronArrowRightIcon
                      className="path-fill-current path mt-0.5"
                      style={{ color: item.cta_text_colour }}
                    />
                  </button>
                </div>
                <div className="flex w-1/2 shrink-0">
                  <img src={item.image_url} alt="" className="ml-auto mr-[10%] h-[184px] w-auto justify-items-end" />
                </div>
              </div>
            </div>
          ))}
        </CustomCarousel>
      )}
    </>
  );
};

export default BannerCarousel;
