import { If } from 'components';
import React, { FC } from 'react';
import ServiceFeeToolTip from './ServiceFeeToolTip';
import { InvoiceSurchargeState } from 'containers/invoices/InvoicesSlice';
import { FormEditIcon } from 'assets/icons';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { FeatureToggleState } from 'containers/discount-tips-fees/constants';

interface AddServiceFeeSectionProps {
  serviceFee: InvoiceSurchargeState;
  isLoadingTaxesAndFees: boolean;
  handleEditClick: VoidFn;
  isServiceFeeEnabled: boolean;
}

const AddServiceFeeSection: FC<AddServiceFeeSectionProps> = ({
  serviceFee,
  isLoadingTaxesAndFees,
  handleEditClick,
  isServiceFeeEnabled
}) => {
  const { percentage, basePoints, valueInCurrencyWithBps } = serviceFee;

  const getServiceFeePercentage = () => {
    if (isServiceFeeEnabled)
      return `${(+percentage ?? 0).toFixed(2)}%${+basePoints > 0 ? ` + ${+basePoints} cents` : ''}`;
    return 'Off';
  };

  const getServiceFeeValue = (value: number) => {
    if (isServiceFeeEnabled) return getAmountWithCurrency(value);
    return '-';
  };

  const isAlwaysOn = serviceFee.config === FeatureToggleState.ALWAYS_ON;

  const onEditClick = () => {
    if (isAlwaysOn) return;
    handleEditClick();
  };

  return (
    <div className="relative flex flex-col border-b border-borderGray p-5">
      {/* Service fee row */}
      <div onClick={onEditClick} className={`w-fullflex-row flex ${!isAlwaysOn && ' cursor-pointer '}`}>
        <div className="flex w-1/3 flex-row gap-2">
          <div className={`text-17px font-semibold ${!isAlwaysOn ? ' text-primaryBtn' : 'text-headingGray'}`}>
            Service fee
          </div>
          <ServiceFeeToolTip />
        </div>
        <div className="w-2/5 text-right  text-17px font-semibold text-accentText">
          <If condition={!isLoadingTaxesAndFees}>{getServiceFeePercentage()}</If>
        </div>
        <div className="w-0 grow break-words text-right text-17px font-semibold text-heading">
          <If condition={!isLoadingTaxesAndFees}>{getServiceFeeValue(valueInCurrencyWithBps ?? 0)}</If>
        </div>
      </div>
      {!isAlwaysOn && (
        <FormEditIcon
          id="create-invoice-service-fee-edit-icon"
          onClick={onEditClick}
          className="absolute right-[-25px] shrink-0 
                            cursor-pointer 1439px:right-[-30px]"
        />
      )}
    </div>
  );
};

export default AddServiceFeeSection;
