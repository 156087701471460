import { FC } from 'react';
import { formatAmount, getAmountWithCurrency } from 'utils/amountUtils';
import { getInitials } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import { CaretDownIcon, SubItemIcon } from 'assets/icons';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { Transaction } from '../invoices.model';
import { TransactionStatus } from 'constants/invoicesConstants';
import { PayoutTransaction } from 'containers/payouts/payouts.model';
import { TxnCardIconTypes, TxnCardTypes } from 'constants/common';
import { If } from 'components';
import { getPaymentMethodIcons, getTxnStatusIcons } from 'utils/transactionUtils';
import { getDisputeStyles } from '../utils';
import { DISPUTE_STATUS } from 'containers/disputes/constants';

interface TransactionCardProps {
  data: Transaction | PayoutTransaction;
  currencySymbol: string;
  type?: TxnCardTypes;
  showAvatar?: boolean;
  iconType?: TxnCardIconTypes;
  onTxnClick?: (id: string, status?: TransactionStatus) => void;
  onDisputeClick?: (id: string) => void;
}

const TransactionCard: FC<TransactionCardProps> = props => {
  const {
    data,
    currencySymbol,
    type = TxnCardTypes.INVOICE,
    showAvatar = true,
    iconType = TxnCardIconTypes.STATUS,
    onTxnClick,
    onDisputeClick = () => {
      return ' ';
    }
  } = props;

  const getIcon = () => {
    if (iconType === TxnCardIconTypes.STATUS) {
      return getTxnStatusIcons(data.status);
    } else if (iconType === TxnCardIconTypes.PAYMENT_METHOD) {
      return getPaymentMethodIcons(data.paymentMethod);
    }
  };

  const getText = status => {
    switch (status) {
      case TransactionStatus.SUCCESS:
        return 'Success';
      case TransactionStatus.PENDING:
        return 'Pending';
      case TransactionStatus.INITIATED:
        return 'Initiated';
      case TransactionStatus.BLOCKED:
        return 'Blocked';
      case TransactionStatus.TIMED_OUT:
        return 'Timed out';
      case TransactionStatus.REFUNDED:
        return 'Refunded';
      case TransactionStatus.REFUND_INITIATED:
        return 'Refund Initiated';
      case TransactionStatus.CANCELLED:
        return 'Cancelled';
      case TransactionStatus.FAILED:
        return 'Failed';
    }
  };

  const getStatus = () => {
    const text = getText(data.status);
    switch (data.status) {
      case TransactionStatus.SUCCESS:
        return <div className="rounded bg-success px-1 text-center text-tertiaryText">{text}</div>;
      case TransactionStatus.PENDING:
        return <div className="rounded bg-fallingBackground px-2 text-center text-tertiaryText">{text}</div>;
      case TransactionStatus.INITIATED:
      case TransactionStatus.REFUNDED:
      case TransactionStatus.REFUND_INITIATED:
        return <div className="rounded bg-[#A4D7FA] px-2 text-center text-tertiaryText">{text}</div>;
      case TransactionStatus.BLOCKED:
      case TransactionStatus.TIMED_OUT:
      case TransactionStatus.CANCELLED:
      case TransactionStatus.FAILED:
        return <div className="rounded bg-red-200 px-2 text-center text-tertiaryText">{text}</div>;
    }
  };

  const getDate = (dateString, dateFormat = 'dd MMM yyyy') => {
    const date = DateUtils.getDateInFormat({ date: new Date(dateString), dateFormat: dateFormat });
    return date;
  };

  const getName = () => {
    if (type === TxnCardTypes.INVOICE) {
      return data['ID'];
    }
    return data['id'];
  };

  const handleRefundTxnClick = (reTxnId: string) => {
    if (reTxnId) {
      onTxnClick(reTxnId, TransactionStatus.REFUNDED);
    }
  };

  return (
    <div className="flex flex-col gap-3">
      <div
        className="flex cursor-pointer items-center justify-between"
        onClick={e => {
          e.stopPropagation();
          onTxnClick(getName());
        }}>
        <div className="flex gap-2">
          <div>{getIcon()}</div>
          <div className="flex flex-col gap-1">
            <div className="text-sbase font-semibold text-tertiaryText">{getName()}</div>
            <div className="flex items-center gap-2">
              <div className="text-px13 text-accentText">{getDate(data.createdAt)}</div>
              <If condition={showAvatar}>
                <div className="mt-1 h-1 w-1 rounded-full bg-dark-gray"></div>
                <div>
                  <CustomAvatar
                    src={data.profilePicture}
                    label={getInitials(data.merchantName)}
                    width="w-4"
                    height="h-4"
                    fontStyle="text-[10px] text-primaryText"
                  />
                </div>
              </If>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2.5 text-right">
          <div className="text-sbase">
            <div className="mb-1 font-bold text-primaryText">
              {currencySymbol}
              {formatAmount(data.amount)}
            </div>
            <div className="text-px13">{getStatus()}</div>
          </div>
          <div className="cursor-pointer">
            <CaretDownIcon className="-rotate-90" />
          </div>
        </div>
      </div>
      <If condition={Boolean(data['refundTransactions'])}>
        <div className="flex flex-col gap-2">
          {data['refundTransactions']?.map(reTxn => (
            <div className="flex items-center gap-2" key={reTxn.ID}>
              <div className="ml-2.5">
                <SubItemIcon />
              </div>
              <div
                className="flex grow cursor-pointer items-center justify-between gap-2 rounded-md bg-secondaryBtn p-2.5"
                onClick={() => handleRefundTxnClick(reTxn.parentID ? reTxn.parentID : data.id)}>
                <div>
                  <div className="text-sbase font-semibold text-primaryText">
                    {getAmountWithCurrency(reTxn.amount)} Refund
                  </div>
                  <div className="mt-0.5 shrink-0 text-px13 text-accentText">
                    {getDate(reTxn.createdAt, 'dd-MM-yyyy')}
                  </div>
                </div>
                <div className="text-right">
                  <div
                    className="ml-auto flex w-fit cursor-pointer items-center justify-between gap-1 justify-self-end
                   rounded-2xl bg-primaryBtn px-1 py-0.5">
                    <div className="truncate px-1 text-xs font-semibold text-primary">{getText(reTxn.status)}</div>
                    <div>
                      <CaretDownIcon className="path-stroke-current h-2 w-2 -rotate-90 text-primary" />
                    </div>
                  </div>
                  <div className="mt-0.5 shrink-0 text-px13 text-accentText">{reTxn.ID || reTxn.id}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </If>
      <If condition={Boolean(data['disputeTransactions'])}>
        <div className="flex flex-col gap-2">
          {data['disputeTransactions']?.map(dispute => (
            <div className="flex items-center gap-2" key={dispute.disputeId}>
              <div className="ml-2.5">
                <SubItemIcon />
              </div>
              <div
                className="flex grow cursor-pointer items-center justify-between gap-2 rounded-md bg-[#FBF1DF] p-2.5"
                onClick={() => onDisputeClick(dispute.disputeId)}>
                <div>
                  <div className="text-sbase font-semibold text-primaryText">${dispute.disputeAmount} Dispute</div>
                  <div className="mt-0.5 shrink-0 text-px13 text-accentText">
                    {getDate(dispute.createdAt, 'dd-MM-yyyy')}
                  </div>
                </div>
                <div className="text-right">
                  <div
                    className="ml-auto flex w-fit cursor-pointer items-center gap-1 justify-self-end
                   rounded-2xl px-1 py-0.5 text-primary"
                    style={getDisputeStyles(dispute.status)}>
                    <div className="truncate px-1 text-xs font-semibold">{dispute.status}</div>
                    <div>
                      <CaretDownIcon
                        className={`path-stroke-current h-2 w-2 -rotate-90 
                    ${
                      dispute.status !== DISPUTE_STATUS.EVIDENCE_SUBMITTED && dispute.status !== DISPUTE_STATUS.PAST_DUE
                        ? 'text-primary'
                        : 'text-headingGray'
                    }`}
                      />
                    </div>
                  </div>
                  <div className="mt-0.5 shrink-0 text-px13 text-accentText">{dispute.disputeId}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </If>
    </div>
  );
};

export default TransactionCard;
