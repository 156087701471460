import { ArrowRightIcon, LinkedIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { getBrbClosureDate } from 'containers/home/utils';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface ConnectGetStartedModalProps {
  onBack: VoidFn;
  onGetStarted: VoidFn;
}

const ConnectGetStartedModal: FC<ConnectGetStartedModalProps> = ({ onBack, onGetStarted }) => {
  return (
    <>
      <div className="w-full">
        <div className="flex p-5 pb-2.5">
          <ArrowRightIcon className="rotate-180 cursor-pointer" onClick={onBack} width={20} height={20} />
        </div>
        <div className="p-[30px] pt-0">
          <div className="space-y-5 px-8 text-center">
            <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-secondaryBtn">
              <LinkedIcon />
            </div>
            <div className="text-xl font-bold text-headingGray">Let&apos;s connect your bank account </div>
          </div>
          <div className="py-5 text-sbase text-primaryText">
            The next few steps will take you through connecting another bank account to route your payouts to.
          </div>

          <div className="text-sbase text-primaryText">
            We will close your existing ScanPay account on {getBrbClosureDate()}. You can transfer your balance to your
            new account before the closure.
          </div>

          <div className="mt-60 mb-28">
            <CustomButton id="connect-get-started" className="w-full" onClick={onGetStarted}>
              Get started
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConnectGetStartedModal;
