import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam, selectPaymentSystemConfig } from 'containers/app/appSlice';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CrossIconvector } from 'assets/icons';
import { LocalStorage } from 'services/storage';
import routesPath from 'routes/RoutesPath';
import { selectAuthState } from 'containers/auth/authSlice';
import { Loader } from 'components';
import { ONBOARDING_URL_PARAMS, ONBOARDING_URL_SOURCES, WebviewFunctions } from 'constants/common';
import { MIGRATION_ROUTE_STATE, PaymentSystemTransitionType } from 'containers/home/constants';

const ConnectOnboarding = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { idToken, refreshToken } = useAppSelector(selectAuthState) ?? {};
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLoading, setIsLoading] = React.useState(true);
  const [iframeUrl, setIframeUrl] = React.useState('');

  const getFrameURL = async () => {
    let url = process.env.REACT_APP_ONBOARDING_URL;
    const currentIdToken = idToken || LocalStorage.get('idToken');
    const currentRefreshToken = refreshToken || LocalStorage.get('refreshToken');
    const teamId = currentTeam?.id;
    // eslint-disable-next-line max-len
    url = `${url}/#/?isIframe=true&idToken=${currentIdToken}&refreshToken=${currentRefreshToken}&teamId=${teamId}&paymentSystem=${paymentSystem}`;
    setIframeUrl(url);
    setIsLoading(false);
  };

  useEffect(() => {
    const handleReadMessage = event => {
      if (event.data === WebviewFunctions.CLOSE_WEBVIEW) {
        handleExitClick();
      }
    };
    window.addEventListener('message', handleReadMessage, false);
    return () => {
      window.removeEventListener('message', handleReadMessage, false);
    };
  }, []);

  useEffect(() => {
    getFrameURL();
  }, []);

  const handleExitClick = () => {
    const migrationRouteState: MIGRATION_ROUTE_STATE =
      currentTeam.paymentSystemTransitionType === PaymentSystemTransitionType.LIPConnectMigration
        ? { openConnectBankModal: true }
        : {};
    if (searchParams.get(ONBOARDING_URL_PARAMS.FROM) === ONBOARDING_URL_SOURCES.HOME) {
      navigate(routesPath.HOME, { state: migrationRouteState });
    } else {
      navigate(routesPath.PAYOUTS_SETTINGS, { state: migrationRouteState });
    }
  };

  return (
    <div className="flex h-full w-full">
      {isLoading ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          <div className="h-full w-11/12">
            <iframe src={iframeUrl} className="h-full w-full" id="connect-onboarding-frame"></iframe>
          </div>

          <div className="w-1/12 cursor-pointer" onClick={handleExitClick}>
            <div className="mx-auto mt-[20px] w-fit">
              <CrossIconvector />
              <div className="text-sm text-accentText">Exit</div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ConnectOnboarding;
