import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface SearchSuggestionsPanelProps {
  suggestionsList: string[];
  onSelectListItem: (string) => void;
}

const SearchSuggestionsPanel: FC<SearchSuggestionsPanelProps> = ({ suggestionsList, onSelectListItem }) => {
  return (
    <div className={twMerge('flex flex-col gap-2')}>
      <div className="text-accentText font-semibold px-5 pt-4">Recent</div>
      <div className="flex flex-col space-y-2">
        {suggestionsList?.map((suggestion: string, index: number) => {
          return (
            <div
              className="text-heading font-semibold py-1
              hover:bg-secondaryBtn active:bg-secondaryBtn active:opacity-75"
              key={suggestion + index}
              onClick={() => onSelectListItem(suggestion)}>
              <div className="break-words px-5">{suggestion}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchSuggestionsPanel;
