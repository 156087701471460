import { CustomButton, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getFormattedNumWithCountryCode, isNonUsPhoneNumber } from 'utils/phoneNumberUtils';
import { SignInMethodEnum } from '../constants';
import { SignInMethod } from '../types';

interface AccountNotFoundSectionProps {
  className?: string;
  handleSignInWithDiffNum: VoidFn;
  handleSignUp: VoidFn;
  signInMethod: SignInMethod;
}

const AccountNotFoundSection: FC<AccountNotFoundSectionProps> = ({
  className = '',
  handleSignInWithDiffNum,
  handleSignUp,
  signInMethod
}) => {
  const getFormattedNumber = () => {
    if (isNonUsPhoneNumber(signInMethod.value)) {
      return signInMethod.value;
    }
    return getFormattedNumWithCountryCode(signInMethod.value, '+1 ');
  };

  return (
    <div className={`my-auto flex max-w-[375px] flex-col self-center 2xl:w-[375px] ${className}`}>
      <div className="mb-4 mt-12 text-xl font-bold text-heading lg:text-4xl">Account not found</div>
      <If condition={signInMethod.id === SignInMethodEnum.PHONE_NUMBER}>
        <div className="mb-8 text-17px font-normal text-primaryText">
          Please check the number you have entered is correct or not or if you are a new customer, please sign up for a
          new account
        </div>
        <div className="mb-12 text-xl font-bold text-headingGray">{getFormattedNumber()}</div>
      </If>
      <If condition={signInMethod.id === SignInMethodEnum.EMAIL}>
        <div className="mb-8 text-17px font-normal tracking-[0.3px] text-primaryText">
          Please check the email you have entered is correct. If you are a new customer, please sign up for a new
          account
        </div>
        <div className="mb-12 text-xl font-bold text-headingGray">{signInMethod.value}</div>
      </If>
      <CustomButton
        id="account-not-found-sign-up-instead"
        onClick={signInMethod.id === SignInMethodEnum.PHONE_NUMBER ? handleSignUp : handleSignInWithDiffNum}
        className={`mt-1 mb-6 h-[38px] w-full
          shadow-none hover:bg-primaryButtonHover`}>
        {signInMethod.id === SignInMethodEnum.PHONE_NUMBER ? 'Sign up instead' : 'Sign in with phone number'}
      </CustomButton>
      <If condition={signInMethod.id === SignInMethodEnum.PHONE_NUMBER}>
        <CustomButton
          id="account-not-found-sign-in-with-other"
          className="mb-2 h-[38px] w-full"
          onClick={handleSignInWithDiffNum}
          type={ButtonType.SECONDARY}>
          Sign in with a different account
        </CustomButton>
      </If>
      <If condition={signInMethod.id === SignInMethodEnum.EMAIL}>
        <div className="flex justify-center text-sbase font-normal">
          <p className="pr-1 text-primaryText">Don’t have an account? </p>
          <div onClick={handleSignUp} className="cursor-pointer text-secondary">
            Sign up
          </div>
        </div>
      </If>
    </div>
  );
};

export default AccountNotFoundSection;
