import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { twMerge } from 'tailwind-merge';

import { VoidFn } from 'types/baseTypes';
import { useScreenDimensions } from 'hooks';

interface CustomModalProps {
  children: React.ReactNode;
  open: boolean;
  closeModal?: VoidFn;
  width?: string;
  height?: string;
  borderRadius?: string;
  backgroundColor?: string;
  overlayColor?: string;
  widthInPx?: string;
  className?: string;
  enableOutsideClick?: boolean;
  onOutsideClick?: VoidFn;
}

const CustomModal: FC<CustomModalProps> = ({
  backgroundColor = 'bg-primary',
  overlayColor = 'bg-[#00132480]',
  children,
  closeModal,
  open,
  borderRadius = 'rounded-md',
  width = '',
  height = '',
  widthInPx = '',
  className = '',
  enableOutsideClick = true,
  onOutsideClick
}) => {
  if (!open) return null;

  let styles = {};
  if (widthInPx) {
    styles = { ...styles, width: widthInPx };
  }

  const handleOutsideClick = (): void => {
    if (enableOutsideClick) {
      if (onOutsideClick) {
        onOutsideClick();
        return;
      }
      closeModal();
    }
  };

  const { viewportHeightInPx } = useScreenDimensions();

  return ReactDOM.createPortal(
    // 'root' is added as an id inorder to access tailwind css from react portals
    <div id="root">
      <div style={{ height: viewportHeightInPx }} className={`fixed inset-0 z-[999] flex w-screen ${overlayColor} `}>
        <div
          className={twMerge(
            'relative flex z-[999] m-auto min-h-[200px] md:min-w-[341px] w-11/12 md:w-[341px] bg-primary',
            `${backgroundColor} ${borderRadius} ${width} ${height} ${className}`
          )}
          style={styles}>
          <div className="h-full w-full">
            <OutsideClickHandler onOutsideClick={handleOutsideClick}>{children}</OutsideClickHandler>
          </div>
        </div>
      </div>
    </div>,
    document.getElementById('portal-root')
  );
};

export default CustomModal;
