import { FC, useState } from 'react';
import { SelectionCircleBlueIcon, SelectionCircleIcon } from 'assets/icons';
import { CustomButton, CustomModal, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import { useAppSelector } from 'hooks';
import { selectMyAccountState } from '../myAccountSlice';
import { VerificationTypes } from '../types';
import { CleverTapEventsMyAccount } from '../events';
import { logAnalyticEvent } from 'utils/analytics';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface VerificationMethodModalProps {
  className?: string;
  handleSave?: (method: VerificationTypes) => void;
  isSaveInProgress?: boolean;
  open?: boolean;
  toggleOpen?: VoidFn;
}
const VerificationMethodModal: FC<VerificationMethodModalProps> = ({
  isSaveInProgress,
  handleSave,
  open,
  toggleOpen
}) => {
  const [selectedMethodId, setSelectedMethodId] = useState<VerificationTypes>(VerificationTypes.TEXT);
  const selectedMyAccountState = useAppSelector(selectMyAccountState);

  const onCancelClick = () => {
    setSelectedMethodId(VerificationTypes.TEXT);
    toggleOpen();
    logAnalyticEvent(CleverTapEventsMyAccount.webVerificationMethodBackClick, {});
  };

  const verificationMethods = [
    {
      id: VerificationTypes.TEXT,
      value: `Text - ${selectedMyAccountState.phoneNumber}`
    },
    {
      id: VerificationTypes.EMAIL,
      value: `Email - ${selectedMyAccountState.email}`
    }
  ];

  const onSaveClick = () => {
    handleSave(selectedMethodId);
    logAnalyticEvent(CleverTapEventsMyAccount.webVerificationMethodContinueClick, {
      verificationMethod: selectedMethodId
    });
  };

  return (
    <CustomModal width="sm:w-[477px] md:w-[477px] min-h-[223px]" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded p-10">
        <div className="flex flex-col items-center justify-center">
          <FormCloseReactIcon
            id="verfication-method-modal-close"
            height="24"
            width="24"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onCancelClick}
          />
          <div className="flex w-full flex-col">
            <div className="text-xl font-bold leading-6 text-heading">Choose verification method</div>
            <div className="mt-3 text-sbase text-primaryText">{`Please choose a method to receive a verification code. 
            This will help us confirm you are the account holder.`}</div>
            <div className="mt-7">
              {verificationMethods?.map(verificationMethod => {
                return (
                  <div
                    key={verificationMethod.id}
                    className="mb-6 flex flex-row items-center"
                    onClick={() => setSelectedMethodId(verificationMethod.id)}>
                    <If condition={selectedMethodId !== verificationMethod.id}>
                      <SelectionCircleIcon className=" mr-3 shrink-0 cursor-pointer" />
                    </If>
                    <If condition={selectedMethodId === verificationMethod.id}>
                      <SelectionCircleBlueIcon className="mr-3 shrink-0" />
                    </If>
                    <div className=" text-sbase font-semibold text-primaryText">{verificationMethod.value}</div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-row items-end justify-end gap-5">
          <CustomButton
            id="set-password-modal-cancel-button"
            className="mt-5 h-[38px] w-[100px]"
            type={ButtonType.SECONDARY}
            onClick={onCancelClick}>
            Cancel
          </CustomButton>
          <CustomButton
            id="set-passowrd-modal-save-button"
            isLoading={isSaveInProgress}
            onClick={onSaveClick}
            disabled={selectedMethodId === null}
            className={`text-semibold mt-5 h-[38px] w-[150px] rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
            Send code
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
export default VerificationMethodModal;
