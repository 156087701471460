import { useCallback, useEffect, useRef, useState } from 'react';
import { ITax } from '../taxes.model';
import { useLazyGetTaxesQuery } from '../api';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks/typedHooks';

export interface TaxesQueryParams {
  search: string;
}

export const initialTaxesQueryParamsState: TaxesQueryParams = {
  search: null
};

export const usePaginatedGetTaxesQuery = () => {
  const [list, setList] = useState<ITax[]>([]);
  const [queryParams, setQueryParams] = useState<TaxesQueryParams>(initialTaxesQueryParamsState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const page = useRef(0);
  const [getTaxesQuery, taxesState] = useLazyGetTaxesQuery();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [isMounted, setIsMounted] = useState(false);

  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!taxesState?.isFetching && taxesState.isSuccess) {
      const totalCount = taxesState?.data?.meta?.totalCount;
      const taxes = taxesState?.data?.data ?? [];
      const updatedList = isInitListCall ? taxes : [...list, ...taxes];
      const taxesLeftToFetch = totalCount ? +totalCount - updatedList.length : 0;

      setHasMore(taxesLeftToFetch > 0);
      setList(updatedList);
      if (page.current === 1) {
        setTotalListCount(totalCount ?? 0);
      }
      setIsLoading(false);
    }
  }, [taxesState?.isFetching]);

  useEffect(() => {
    if (!taxesState?.isFetching && taxesState?.isError) {
      setIsLoading(false);
      setHasMore(false);
    }
  }, [taxesState?.isError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setList([]);
    loadMore();
  };

  useEffect(() => {
    if (isMounted) {
      resetListAndLoadMore();
    } else {
      setIsMounted(true);
    }
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    if (currentTeam?.id) {
      const remainingParams = queryParams;

      try {
        getTaxesQuery({
          teamId: currentTeam?.id,
          limit: 10,
          page: page.current,
          ...remainingParams
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetRecallQuery = () => {
    resetListAndLoadMore();
  };

  const resetQuery = () => {
    page.current = 0;
    setList([]);
    setQueryParams(initialTaxesQueryParamsState);
    setIsMounted(false);
    setIsError(false);
    setHasMore(true);
    setTotalListCount(0);
  };

  return {
    isLoading: isLoading,
    isFetching: taxesState?.isFetching,
    totalListCount,
    list,
    isError,
    loadMore,
    resetQuery,
    resetRecallQuery,
    hasMore,
    setQueryParams,
    queryParams,
    isInitListCall,
    isLoadingSuccess: taxesState.isSuccess
  };
};
