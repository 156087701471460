import {
  initialDiscountFormData,
  removeSelectedDiscountFormDataEditPage,
  updateSelectedDiscountFormDataEditPage
} from 'containers/invoices/edit-invoice/invoicesEditSlice';
import AddDiscountSection from './AddDiscountSection';
import { FC, useState } from 'react';
import AddInvoiceDiscountModal, {
  IAddInvoiceDiscountModalConfig,
  addInvoiceDiscountModalConfigInitState
} from './AddInvoiceDiscountModal';
import { useAppDispatch } from 'hooks';
import { removeSelectedDiscountFormData, updateSelectedDiscountFormData } from 'containers/invoices/InvoicesSlice';
import { DiscountFormData } from 'containers/invoices/types';

export interface AddInvoiceDiscountWrapperProps {
  className?: string;
  isAddDiscountDisabled: boolean;
  // TODO use the same model in create and edit invoice for discount
  selectedDiscount?: DiscountFormData;
  isEditInvoiceFlow?: boolean;
  lineItemsTotalCost: number;
}

const AddInvoiceDiscountWrapper: FC<AddInvoiceDiscountWrapperProps> = ({
  className = '',
  selectedDiscount = initialDiscountFormData,
  isEditInvoiceFlow = false,
  isAddDiscountDisabled = true,
  lineItemsTotalCost = 0
}) => {
  const dispatch = useAppDispatch();
  const [addInvoiceDiscountModalConfig, setAddInvoiceDiscountModalConfig] = useState<IAddInvoiceDiscountModalConfig>(
    addInvoiceDiscountModalConfigInitState
  );

  const onAddDiscount = (): void => {
    const config: IAddInvoiceDiscountModalConfig = {
      title: 'Add a discount',
      open: true,
      shouldInitializeFields: false,
      data: {
        amount: '',
        percent: '',
        isDiscountAmountSelected: selectedDiscount?.isDiscountAmountSelected ?? false
      },
      editMode: false
    };

    setAddInvoiceDiscountModalConfig(config);
  };

  const onEditDiscount = (): void => {
    const config: IAddInvoiceDiscountModalConfig = {
      title: 'Edit discount',
      open: true,
      shouldInitializeFields: true,
      data: {
        amount: selectedDiscount?.discountInCurrency ?? '',
        percent: selectedDiscount?.discountPercent ?? '',
        isDiscountAmountSelected: selectedDiscount?.isDiscountAmountSelected ?? false
      },
      editMode: false
    };

    setAddInvoiceDiscountModalConfig(config);
  };

  const onRemoveDiscount = (): void => {
    if (isEditInvoiceFlow) {
      dispatch(removeSelectedDiscountFormDataEditPage());
    } else {
      dispatch(removeSelectedDiscountFormData());
    }
  };

  const onCloseAddDiscountModal = (): void => {
    setAddInvoiceDiscountModalConfig(addInvoiceDiscountModalConfigInitState);
  };

  const onSaveDiscountClick = (discountData: DiscountFormData): void => {
    if (isEditInvoiceFlow) {
      dispatch(updateSelectedDiscountFormDataEditPage(discountData));
    } else {
      dispatch(updateSelectedDiscountFormData(discountData));
    }
    onCloseAddDiscountModal();
  };

  return (
    <div className={`${className}`}>
      <AddDiscountSection
        isAddDiscountDisabled={isAddDiscountDisabled}
        isEditInvoiceFlow={isEditInvoiceFlow}
        onAddDiscountClick={!isAddDiscountDisabled && onAddDiscount}
        onEditDiscount={!isAddDiscountDisabled && onEditDiscount}
        onClearSelectedDiscount={onRemoveDiscount}
        selectedDiscount={selectedDiscount}
      />
      {addInvoiceDiscountModalConfig.open && (
        <AddInvoiceDiscountModal
          config={addInvoiceDiscountModalConfig}
          lineItemsTotalCost={lineItemsTotalCost}
          onClose={onCloseAddDiscountModal}
          onModifyDiscountClick={onSaveDiscountClick}
          onSaveDiscountClick={onSaveDiscountClick}
        />
      )}
    </div>
  );
};

export default AddInvoiceDiscountWrapper;
