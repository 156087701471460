import React, { FC, useEffect, useState } from 'react';
import { useOutletContext } from 'react-router';
import { useLocation, useNavigate } from 'react-router-dom';

import Lottie from 'lottie-react';
import SidePanel from 'components/side-panel/SidePanel';
import {
  InfoIcon,
  NavCloseIcon,
  OrangeInfoIcon,
  RedirectIcon,
  UpcomingPayoutsBankIcon,
  WhiteRightArrowSmall
} from 'assets/icons';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { Divider } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  selectCurrentTeam,
  selectCurrentTeamInvoicePermissions,
  selectPaymentSystemConfig,
  selectTeamBankDetails,
  showNotifier
} from 'containers/app/appSlice';
import { useLazyGetUpcomingPayoutsSummaryQuery } from 'containers/payouts/api';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { PaymentSystem, PayoutRootPage, PayoutScheduleType } from 'containers/payouts/constants';
import { CustomButton, If } from 'components';
import { PayoutType } from 'containers/payouts/constants';
import { NotifierTypes } from 'constants/notifierConstants';
import NoPermissions from 'components/no-permissions/NoPermissions';
import { checkPermissions } from 'containers/payouts/utils';
import { PayoutsCleverTapEvents } from 'containers/payouts/events';
import { logAnalyticEvent } from 'utils/analytics';
import { openScanpayFAQInNewTab } from 'utils/urlUtils';
import routesPath from 'routes/RoutesPath';

const UpComingPayoutDetailsPanel: FC = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { handleSidePanelClose } = useOutletContext<any>();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const currentTeamBank = useAppSelector(selectTeamBankDetails);
  const navigate = useNavigate();
  const [getUpcomingPayoutsSummaryQuery, getUpcomingPayoutsSummaryState] = useLazyGetUpcomingPayoutsSummaryQuery();
  const {
    data: upcomingPayoutsSummaryData,
    isSuccess: upcomingPayoutsSummarySuccess,
    isFetching: fetchingUpcomingPayoutsSummary
  } = getUpcomingPayoutsSummaryState;

  const { paymentsCollected, feesCollected, refunds, adjustments, creditsApplied, inFutureAmount } =
    upcomingPayoutsSummaryData ?? {};
  const { payoutSchedule, bankingEnabled, payoutType, paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const { bankName, accountNumber, paymentMethod } = currentTeamBank;

  useEffect(() => {
    if (currentTeam.id && paymentSystem) {
      setHasPermissionsToView(
        checkPermissions({
          paymentSystem,
          viewPayoutsBySelf: payoutPermissions.viewPayoutsBySelf,
          viewPayoutsByTeam: payoutPermissions.viewPayoutsByTeam
        })
      );
    }
  }, [currentTeam.id, paymentSystem]);

  useEffect(() => {
    if (currentTeam.id && hasPermissionsToView) getUpcomingPayoutsSummaryQuery(currentTeam.id);
  }, [currentTeam.id, hasPermissionsToView]);

  useEffect(() => {
    setHasPermissionsToView(
      checkPermissions({
        paymentSystem,
        viewPayoutsBySelf: payoutPermissions.viewPayoutsBySelf,
        viewPayoutsByTeam: payoutPermissions.viewPayoutsByTeam
      })
    );
  }, [payoutPermissions.viewPayoutsBySelf, payoutPermissions.viewPayoutsByTeam]);

  const amountBreakdownItems = [
    {
      id: '1',
      title: 'Collected',
      value: paymentsCollected
    },
    {
      id: '2',
      title: 'Fees',
      value: feesCollected
    },
    {
      id: '3',
      title: 'Refunds',
      value: refunds
    },
    {
      id: '4',
      title: 'Adjustments',
      value: adjustments
    },
    {
      id: '5',
      title: 'Credits',
      value: creditsApplied
    }
  ];

  const viewAllTransactions = () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Collected & Refunds filter applied',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    logAnalyticEvent(PayoutsCleverTapEvents.webUpcomingPayoutsViewAllTransactionsClick);
    navigate(`/payouts/balance/${currentTeam.id}/upcoming-payouts/${PayoutRootPage.BALANCE}/transactions`);
  };

  const handleAddBankAccount = () => {
    if (paymentSystem === PaymentSystem.CA) {
      navigate(routesPath.PAYOUTS_SETTINGS);
    } else {
      navigate(routesPath.BANKING);
    }
  };

  const getPayoutSchedule = () => {
    if (bankingEnabled) {
      if (payoutSchedule === PayoutScheduleType.OFF || payoutType === PayoutType.MANUAL) {
        return (
          <div className="mb-2.5 flex gap-3 rounded-md border border-pending-orange bg-[#F7E2C0]/20 py-4 pl-3 pr-5">
            <OrangeInfoIcon className="shrink-0" />
            <div className="text-sbase font-normal text-primaryText">
              Your payouts are currently paused. Please contact customer support.
            </div>
          </div>
        );
      }
      if (paymentSystem === PaymentSystem.LIP) {
        return '';
      }
      if (payoutSchedule === PayoutScheduleType.DAILY) {
        return (
          <>
            <InfoIcon className="shrink-0 pr-2" height={24} width={24} />
            <div className="text-sbase font-normal text-primaryText">
              Your payouts are scheduled every business day for all transactions up to 7 pm ET.
            </div>
          </>
        );
      }
      return '';
    }

    return (
      <div className="flex flex-col items-center gap-3 rounded-md border border-pending-orange p-4 pl-3">
        <div className="flex gap-3">
          <UpcomingPayoutsBankIcon className="shrink-0" />
          <div className="text-sbase font-normal text-primaryText">
            Your payouts will be scheduled once your have linked your bank account.
          </div>
        </div>
        <CustomButton
          id="upcoming-payouts-panel-add-bank-btn"
          onClick={handleAddBankAccount}
          className="h-7 text-sbase font-semibold text-white"
          EndIcon={WhiteRightArrowSmall}>
          Add a bank account
        </CustomButton>
      </div>
    );
  };

  const onLearnMoreClick = () => {
    openScanpayFAQInNewTab(PayoutsCleverTapEvents.webUpcomingPayoutsDetailsLearnMoreClick);
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="flex h-full w-96 flex-col">
        <If condition={fetchingUpcomingPayoutsSummary && hasPermissionsToView}>
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        </If>
        <If condition={(upcomingPayoutsSummarySuccess && !fetchingUpcomingPayoutsSummary) || !hasPermissionsToView}>
          <div className="flex h-full w-full flex-col gap-5 pt-5">
            <div className="flex  w-full items-center gap-2 pl-[14px]">
              <div onClick={handleSidePanelClose} className="cursor-pointer">
                <NavCloseIcon id="upcoming-payouts-details-panel-close" />
              </div>
              <div className="text-17px font-semibold text-heading">Upcoming payouts</div>
            </div>
            {hasPermissionsToView ? (
              <div className="customNormalScroll h-full overflow-auto">
                <div
                  className="flex cursor-pointer items-center justify-center bg-secondaryBtn py-2"
                  role="presentation"
                  onClick={onLearnMoreClick}>
                  <RedirectIcon className="shrink-0 pr-2" />
                  <div className="text-sbase font-semibold text-primaryBtn">
                    Learn about getting paid through ScanPay
                  </div>
                </div>
                <div className="flex flex-col gap-5 py-[30px]">
                  <div className="flex flex-col gap-2.5 text-center">
                    <div className="text-sbase font-normal text-accentText">Estimated to be paid out</div>
                    <div className="text-3xl font-bold text-tertiaryText">{getAmountWithCurrency(+inFutureAmount)}</div>
                    {bankingEnabled && (
                      <div className="text-sbase font-normal text-accentText">
                        To {bankName} {accountNumber}
                      </div>
                    )}
                  </div>
                  {getPayoutSchedule() && <div className="flex px-[30px]">{getPayoutSchedule()}</div>}
                </div>
                <Divider className="mb-5 w-full bg-borderGray" />
                <div className="mt-5 flex flex-col gap-5">
                  <div className="flex flex-col px-[30px]">
                    <div className="text-sbase font-normal text-accentText">Amount breakdown</div>
                    <div className="mt-5 flex flex-col gap-[15px]">
                      {amountBreakdownItems.map(amountBreakdownItem => {
                        return (
                          <div key={amountBreakdownItem.id} className="flex items-center justify-between">
                            <div className="text-17px font-semibold text-primaryText">{amountBreakdownItem.title}</div>
                            <div className="text-17px font-semibold text-tertiaryText">
                              {getAmountWithCurrency(+amountBreakdownItem.value)}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <Divider className="my-5 w-full bg-borderGray" />
                    <div className="flex items-center justify-between">
                      <div className="text-17px font-bold text-primaryText">Payout</div>
                      <div className="text-17px font-bold text-tertiaryText">
                        {getAmountWithCurrency(+inFutureAmount)}
                      </div>
                    </div>
                  </div>
                  {bankingEnabled && (
                    <>
                      <Divider className="w-full bg-borderGray" />
                      <div className="flex flex-col px-[30px]">
                        <div className="text-sbase font-normal text-accentText">Overview</div>
                        <div className="mt-5 flex flex-col gap-[15px]">
                          <div className="flex items-start justify-between">
                            <div className="text-17px font-normal text-primaryText">Sending to</div>
                            <div className="text-right text-17px font-normal text-tertiaryText">
                              {bankName && accountNumber ? (
                                <>
                                  <div>{bankName}</div>
                                  <div>{accountNumber}</div>
                                </>
                              ) : (
                                <div>-</div>
                              )}
                            </div>
                          </div>
                          <div className="flex items-center justify-between">
                            <div className="text-17px font-normal text-primaryText">Sending via</div>
                            <div className="text-17px font-normal text-tertiaryText">{paymentMethod || '-'}</div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <Divider className="w-full bg-borderGray" />
                  {paymentSystem !== PaymentSystem.LIP && (
                    <div
                      className="mx-[30px] flex h-12 cursor-pointer items-center justify-center rounded-md
                      border border-gray-1 text-17px font-normal text-primaryBtn"
                      role="presentation"
                      onClick={viewAllTransactions}>
                      View all transactions
                    </div>
                  )}
                </div>
              </div>
            ) : (
              <NoPermissions />
            )}
          </div>
        </If>
      </div>
    </SidePanel>
  );
};

export default UpComingPayoutDetailsPanel;
