import React from 'react';
import { CircularStepper, CustomButton, CustomInput } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useUpdateBasicDetailsMutation } from '../api';
import { getCurrentData, getUserData, setUserData, updateCurrentData } from '../digitalOnboardingSlice';
import routesPath from 'routes/RoutesPath';
import { constructBasicDetails } from '../utils';

const ReferralSourceSection = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const currentData = useAppSelector(getCurrentData);
  const [updateBasicDetailsMutation] = useUpdateBasicDetailsMutation();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { referralSource } = currentData;

  const onReferralSourceChange = value => {
    dispatch(updateCurrentData({ key: 'referralSource', value }));
  };

  const onContinue = () => {
    if (referralSource?.trim()) {
      const updatedData = {
        ...userData,
        referralSource: referralSource?.trim()
      };
      dispatch(setUserData(updatedData));
      updateBasicDetailsMutation({
        payload: constructBasicDetails({
          userData: updatedData,
          teamId: currentTeam.id.toString()
        })
      });
      navigate(routesPath.DIGITAL_ONBOARDING_WEBSITE, { replace: true });
    }
  };

  const onBack = () => {
    navigate(routesPath.DIGITAL_ONBOARDING_EMPLOYEES, { replace: true });
  };

  return (
    <div className="flex flex-col self-center lg:min-w-[375px]">
      <CircularStepper currentStep={6} totalSteps={7} />
      <div className="mb-[30px] flex max-w-[375px] flex-col gap-2.5">
        <div className="w-[90%] text-4xl font-bold leading-[43px] text-headingGray">
          Where did you hear about ScanPay?
        </div>
      </div>
      <CustomInput
        id="aboutScanpay"
        onChange={onReferralSourceChange}
        value={referralSource}
        placeholder="Enter your answer"
        className="px-5 py-4 outline-none placeholder:font-semibold placeholder:tracking-[-0.3px]"
      />
      <div className="mt-10 flex flex-col gap-5">
        <CustomButton
          id="continue-sign-up"
          className="h-10 w-full"
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onContinue}
          disabled={!referralSource?.trim()}>
          Continue
        </CustomButton>
        <CustomButton
          id="back-button-sign-up"
          className="h-10 w-full border-none shadow-none hover:bg-primary active:shadow-none"
          type={ButtonType.SECONDARY}
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onBack}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default ReferralSourceSection;
