import { MerchantTeam, PermissionLevels, TeamRole, TeamSettingPermissionList } from 'containers/app/app.model';
import { dropDownGenericRoles } from './edit-permissions/constants';
import { TeamMemberRoleItems } from './edit-permissions/types';

export const getRolesInDropdown = (
  currentTeam: MerchantTeam,
  selectedCurrentTeamTeamSettingsPermissions: TeamSettingPermissionList,
  currentBankingPermissionsLevel?: PermissionLevels
): TeamMemberRoleItems[] => {
  if (currentTeam.role === TeamRole.OWNER) {
    if (currentBankingPermissionsLevel === PermissionLevels.MANAGE_FUNDS) {
      return [{ name: 'Super Admin', role: TeamRole.SUPER_ADMIN, id: TeamRole.SUPER_ADMIN }] as TeamMemberRoleItems[];
    }
    return dropDownGenericRoles;
  } else if (currentTeam.role === TeamRole.ADMIN) {
    return [{ name: 'Member', role: TeamRole.MEMBER, id: TeamRole.MEMBER }] as TeamMemberRoleItems[];
  } else if (
    currentTeam.role === TeamRole.CUSTOM &&
    (selectedCurrentTeamTeamSettingsPermissions.inviteNewMember ||
      selectedCurrentTeamTeamSettingsPermissions.editExistingMember)
  ) {
    return [{ name: 'Member', role: TeamRole.MEMBER, id: TeamRole.MEMBER }] as TeamMemberRoleItems[];
  } else if (currentTeam.role === TeamRole.SUPER_ADMIN) {
    return [{ name: 'Member', role: TeamRole.MEMBER, id: TeamRole.MEMBER }] as TeamMemberRoleItems[];
  }

  return [];
};
