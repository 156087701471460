export enum SalesDetailsTypes {
  BY_TEAM_MEMBERS = 'team_members',
  BY_PAYMENT_METHODS = 'payment_methods'
}

export const ManualPaymentMethods = [
  {
    key: 'm-cash',
    value: 'Cash'
  },
  {
    key: 'm-check',
    value: 'Check'
  },
  {
    key: 'm-direct-transfer',
    value: 'Direct Transfer'
  },
  {
    key: 'm-other',
    value: 'Other'
  }
];

export const PaymentMethodsForInvoiceFilter = [
  {
    key: 'card',
    value: 'Credit / Debit Card'
  },
  {
    key: 'gpay',
    value: 'Google Pay'
  },
  {
    key: 'applepay',
    value: 'Apple Pay'
  },
  {
    key: 'klarna',
    value: 'Klarna'
  },
  {
    key: 'ach',
    value: 'ACH'
  },
  {
    key: 'm-cash',
    value: 'Manual-Cash'
  },
  {
    key: 'm-check',
    value: 'Manual-Check'
  },
  {
    key: 'm-direct-transfer',
    value: 'Manual-Direct Transfer'
  },
  {
    key: 'm-other',
    value: 'Manual-Other'
  }
];

export const TipInvoiceFilter = [
  {
    key: 'tip',
    value: 'Only with Tips'
  }
];

export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=tech.scanpay.merchant&hl=en_IN&gl=US';
export const APP_STORE_URL = 'https://apps.apple.com/sg/app/scanpay-accept-payments/id1572936786';

export const validEmailRegex = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
export const validPhoneNumberRegex = new RegExp('^(\\+\\d{1,2}\\s)?\\(?\\d{3}\\)?[\\s.-]?\\d{3}[\\s.-]?\\d{4}$');
