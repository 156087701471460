import { add, differenceInCalendarDays, format, subDays } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

class DateUtils {
  static getTomorrow(dateFormat?: string): Date | string {
    const tomorrow = add(new Date(), { days: 1 });
    if (dateFormat) {
      return this.getDateInFormat({
        date: tomorrow,
        dateFormat
      });
    }
    return tomorrow;
  }

  static getToday(dateFormat?: string): Date | string {
    const today = new Date();
    if (dateFormat) {
      return format(today, dateFormat);
    }
    return today;
  }

  static getYesterday(dateFormat?: string): Date | string {
    const yesterday = subDays(new Date(), 1);
    if (dateFormat) {
      return this.getDateInFormat({
        date: yesterday,
        dateFormat
      });
    }
    return yesterday;
  }

  static getDateInFormat({ date, dateFormat = 'yyyy-MM-dd' }: { date: Date; dateFormat: string }): string {
    return format(date, dateFormat);
  }

  static getDateInFormatWtTimeZone({
    date,
    dateFormat = 'yyyy-MM-dd',
    timeZone
  }: {
    date: Date;
    dateFormat: string;
    timeZone: string;
  }) {
    return formatInTimeZone(date, timeZone, dateFormat);
  }
  static getMonthInStringFormat(monthNumber: number): string {
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    return monthNames[monthNumber - 1];
  }

  static getDifferenceInDays({
    primaryDate = new Date(),
    secondaryDate
  }: {
    primaryDate?: Date;
    secondaryDate: Date;
  }): number {
    const days = differenceInCalendarDays(primaryDate, secondaryDate);
    return days;
  }

  static fromNowRelativeString(
    date,
    nowDate = Date.now(),
    rft = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' })
  ) {
    const SECOND = 1000;
    const MINUTE = 60 * SECOND;
    const HOUR = 60 * MINUTE;
    const DAY = 24 * HOUR;
    const WEEK = 7 * DAY;
    const MONTH = 30 * DAY;
    const YEAR = 365 * DAY;
    const intervals = [
      { ge: YEAR, divisor: YEAR, unit: 'year' },
      { ge: MONTH, divisor: MONTH, unit: 'month' },
      { ge: WEEK, divisor: WEEK, unit: 'week' },
      { ge: DAY, divisor: DAY, unit: 'day' },
      { ge: HOUR, divisor: HOUR, unit: 'hour' },
      { ge: MINUTE, divisor: MINUTE, unit: 'minute' },
      { ge: 30 * SECOND, divisor: SECOND, unit: 'seconds' },
      { ge: 0, divisor: 1, text: 'just now' }
    ];
    // @ts-ignore
    const now = typeof nowDate === 'object' ? nowDate?.getTime() : new Date(nowDate).getTime();
    const diff = now - (typeof date === 'object' ? date : new Date(date)).getTime();
    const diffAbs = Math.abs(diff);
    for (const interval of intervals) {
      if (diffAbs >= interval.ge) {
        const x = Math.round(Math.abs(diff) / interval.divisor);
        const isFuture = diff < 0;
        // @ts-ignore
        return !interval.unit ? interval.text : rft.format(isFuture ? x : -x, interval.unit);
      }
    }
  }

  static startDateToUTCString(date: string): string {
    const originalDate = new Date(date + 'T00:00:00');
    if (date) {
      return originalDate.toISOString();
    }
    return null;
  }

  static endDateToUTCString(date: string): string {
    const originalDate = new Date(date + 'T23:59:59');
    if (date) {
      return originalDate.toISOString();
    }
    return null;
  }

  static getTimeZone(): string {
    const userTimeZone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
    const userTimeZoneAbbreviation = userTimeZone
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');

    return userTimeZoneAbbreviation;
  }

  static getDateInFormatwithTimezone({ date, dateFormat = 'yyyy-MM-dd' }: { date: Date; dateFormat: string }): string {
    const userTimeZone = new Date().toString().match(/\(([A-Za-z\s].*)\)/)[1];
    const userTimeZoneAbbreviation = userTimeZone
      .split(' ')
      .map(word => word.charAt(0).toUpperCase())
      .join('');

    return `${format(date, dateFormat)} ${userTimeZoneAbbreviation}`;
  }
}

export default DateUtils;
