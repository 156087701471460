import React, { FC } from 'react';
import { Customer } from '../invoices.model';

interface BillToSectionProps {
  customer: Customer;
}

const BillToSection: FC<BillToSectionProps> = ({ customer }) => {
  const { name, lastName, email, phoneNumber, billingAddress, street, cityStateCountry, zipCode } = customer ?? {};

  const getAddress = () => {
    let addressString = '';
    if (street && street !== '') {
      addressString += ` ${street}`;
    }

    if (cityStateCountry && cityStateCountry?.length > 0) {
      addressString += ` ${cityStateCountry}`;
    }
    if (zipCode && zipCode?.length > 0) {
      addressString += ` ${zipCode}`;
    }
    return addressString;
  };

  return (
    <div className="rounded-md border border-borderGray bg-white p-10">
      <div className="text-sbase font-semibold text-headingBlack">BILLED TO</div>
      <div className="mt-5 flex flex-col gap-y-2 text-sbase font-normal text-primaryText">
        {name && <div>{`${name} ${lastName}`}</div>}
        {phoneNumber && <div>{phoneNumber}</div>}
        {email && <div>{email}</div>}
        {billingAddress ? (
          <div className="max-w-[170px] whitespace-pre-line break-words">{billingAddress}</div>
        ) : (
          getAddress() != '' && <div className="max-w-[170px] whitespace-pre-line break-words">{getAddress()}</div>
        )}
      </div>
    </div>
  );
};

export default BillToSection;
