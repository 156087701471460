/* eslint-disable react/no-multi-comp */
import React, { useEffect, useState } from 'react';

import { FirebaseStorage } from 'services/firebase';
import { CrossIconvector } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectMerchantTeams, selectUserDetails, toggleChatIcon } from 'containers/app/appSlice';
import { MerchantTeam } from 'containers/app/app.model';
import { FirebaseNotification } from 'types/notification';
import { NotificationsList } from 'containers/notification-drawer/components/NotificationsList';
import { Transition } from '@headlessui/react';

interface NotificationDrawerProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}

const NotificationsDrawer: React.FC<NotificationDrawerProps> = ({ isOpen, setIsOpen }) => {
  const [notificationsData, setNotificationsData] = useState<FirebaseNotification[]>([]);
  const [teamInvites, setTeamInvites] = useState([]);
  const [activeTab, setActiveTab] = useState('Inbox');
  const dispatch = useAppDispatch();

  const merchantTeams = useAppSelector(selectMerchantTeams);
  const currentUser = useAppSelector(selectUserDetails);

  const getFilteredNotification = (teamId_: string) => {
    if (teamId_ === 'Inbox') return notificationsData;
    if (teamId_ === 'General') return teamInvites;
    return notificationsData.filter(_ => _.teamId === teamId_);
  };

  const getUnreadNotificationCount = (teamId_: string) => {
    if (teamId_ === 'General') return teamInvites.length;
    return getFilteredNotification(teamId_).filter(_ => _.isRead === 'false').length;
  };

  useEffect(() => {
    if (currentUser?.id) {
      FirebaseStorage.getNotifsByMerchant(currentUser.id, setNotificationsData);
      FirebaseStorage.getTeamInvitesByMerchant(currentUser.phone, setTeamInvites);
    }
  }, [currentUser]);

  useEffect(() => {
    if (isOpen) {
      dispatch(toggleChatIcon(false));
    } else {
      dispatch(toggleChatIcon(true));
    }
  }, [isOpen]);

  const closeNotificationPanel = () => setIsOpen(false);

  const countBackgrondColor = tab => {
    const unreadCount = getUnreadNotificationCount(tab);
    if (unreadCount === 0 && activeTab !== tab) return 'bg-borderGray';
    else if (activeTab !== tab && unreadCount > 0) return 'bg-tertiaryText';
    else if (activeTab === tab) return 'bg-secondary';
  };

  const hanldeNotificationsRead = () => {
    const teamNotifications = getFilteredNotification(activeTab);
    teamNotifications.forEach(notif => {
      if (notif.isRead === 'false') {
        FirebaseStorage.markMessageAsRead(notif.id);
      }
    });
  };

  return (
    <Transition
      show={isOpen}
      enter="transition-opacity duration-500"
      enterFrom="translate-x-full opacity-90 bg-opacity-[0.55]"
      enterTo="translate-x-0 opacity-100 bg-opacity-[0.55]"
      leave="transition-all duration-500"
      leaveFrom="bg-opacity-[0.55]"
      leaveTo="bg-opacity-0"
      as="div"
      className={'fixed inset-0 z-[52] overflow-hidden bg-gray-900'}>
      <section
        className={
          ' w-[375px] max-w-lg right-0 ' +
          'absolute bg-white h-full shadow-xl delay-400 duration-500 ease-in-out transition-all transform  ' +
          (isOpen ? ' transition-transform delay-500 translate-x-0 ' : ' translate-x-full ')
        }>
        <div className="flex flex-row items-center px-4 pt-3 pb-2">
          <CrossIconvector className="cursor-pointer" onClick={() => setIsOpen(false)} />
          <div className="pl-2.5 text-17px font-semibold text-heading">
            {`Notifications (${getUnreadNotificationCount('Inbox') + getUnreadNotificationCount('General')})`}
          </div>
        </div>
        <div
          className="customNormalScroll -m-px flex w-full flex-row items-center overflow-auto
        rounded p-2 pb-0	">
          <div
            className={`${activeTab === 'Inbox' ? 'border-secondary' : 'border-bg-[#ACB5BD]'}
               mx-5 flex w-full flex-1 flex-row items-center border-b pb-2`}>
            <div
              className={`${activeTab === 'Inbox' ? 'text-secondary' : 'text-tertiaryText'}
              cursor-pointer text-sbase font-semibold`}
              onClick={() => setActiveTab('Inbox')}>
              Inbox
            </div>
            <div
              className={`text-primary ${countBackgrondColor('Inbox')}
              	 ml-2.5 flex h-4 items-center justify-center rounded px-1 text-xs font-semibold`}>
              {getUnreadNotificationCount('Inbox')}
            </div>
          </div>

          <div
            className={`${activeTab === 'General' ? 'border-secondary' : 'border-bg-[#ACB5BD]'}
               mx-5 flex w-full flex-1 flex-row items-center border-b pb-2`}>
            <div
              className={`${activeTab === 'General' ? 'text-secondary' : 'text-tertiaryText'}
              cursor-pointer text-sbase font-semibold`}
              onClick={() => setActiveTab('General')}>
              General
            </div>
            <div
              className={`text-primary ${countBackgrondColor('General')}
              	 ml-2.5 flex h-4 w-4 items-center justify-center rounded text-xs font-semibold`}>
              {getUnreadNotificationCount('General')}
            </div>
          </div>

          {merchantTeams?.map((team: MerchantTeam) => {
            return (
              <div
                className={`${activeTab === team.id.toString() ? 'border-secondary' : 'border-bg-[#ACB5BD]'}
               mx-5 flex w-full flex-1 flex-row items-center border-b pb-2 text-sbase`}
                style={{ whiteSpace: 'nowrap' }}
                key={team.id}>
                <div
                  className={`${activeTab === team.id.toString() ? 'text-secondary' : 'text-tertiaryText'}
                   cursor-pointer font-semibold `}
                  style={{ flexShrink: 0, whiteSpace: 'nowrap' }}
                  onClick={() => setActiveTab(team.id.toString())}>
                  {team.name}
                </div>
                <div
                  className={`text-primary 
                  ${countBackgrondColor(team.id.toString())} ml-2.5	flex h-4 w-4
                  items-center justify-center rounded p-1 text-xs font-semibold`}>
                  {getUnreadNotificationCount(team.id.toString())}
                </div>
              </div>
            );
          })}
        </div>
        <NotificationsList
          notifications={getFilteredNotification(activeTab)}
          onCloseNotificationPanel={closeNotificationPanel}
          activeTab={activeTab}
        />

        <div>
          <div
            className="fixed bottom-0 left-0 flex w-full
            cursor-pointer items-center justify-end bg-primary py-2.5 px-4 font-semibold text-secondary"
            style={{ boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.25)' }}
            onClick={hanldeNotificationsRead}>
            Mark all as read
          </div>
        </div>
      </section>
      <section
        className=" h-full w-screen cursor-pointer "
        onClick={() => {
          setIsOpen(false);
        }}></section>
    </Transition>
  );
};

export default NotificationsDrawer;
