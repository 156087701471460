import React, { useEffect, useState } from 'react';
import {
  ArrowRightIcon,
  AttachmentIcon,
  CrossIconvector,
  DownloadIcon,
  ExpandIcon,
  IosStoreIcon,
  MailIcon,
  MessageIcon,
  MobileVectorIcon,
  PlayStoreIcon,
  TTPIcon
} from 'assets/icons';
import { APP_STORE_URL, PLAY_STORE_URL } from 'containers/home/components/constants';
import { removeUSCountryCode } from 'utils/commonUtils';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCurrentTeam, selectCurrentTeamCustomersPermissions, showNotifier } from 'containers/app/appSlice';
import { useLazyDownloadInvoiceAsPDFQuery, useShareInvoiceReceiptMutation } from 'containers/invoices/api';
import { InvoiceByIdResponse } from 'containers/invoices/invoices.model';
import { ForwardFileType, VoidFn } from 'types/baseTypes';
import { ShareReceiptPageState } from 'containers/invoices/invoice.model';
import { NotifierTypes } from 'constants/notifierConstants';
import { REGEX } from 'constants/common';
import { twMerge } from 'tailwind-merge';
import { CustomButton, CustomInput } from 'components';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsInvoices } from '../events';
import { SharePanelType } from '../types';
import {
  useLazyDownloadReceiptAsPDFQuery,
  useLazyDownloadRefundReceiptAsPDFQuery,
  useShareReceiptMutation
} from 'containers/receipts/api';
import { ReceiptByIdResponse } from 'containers/receipts/receipt.model';

interface ShareReceiptMainSectionProps {
  invoiceData: InvoiceByIdResponse | any;
  receiptData: ReceiptByIdResponse;
  receiptId: string;
  resetInvoicesQuery: VoidFn;
  resetReceiptsQuery: VoidFn;
  onClose: VoidFn;
  setPageState: (pageState: ShareReceiptPageState) => void;
  type: SharePanelType;
  isRootState?: boolean;
  qrData: string;
}

export const ShareReceiptMainSection: React.FC<ShareReceiptMainSectionProps> = ({
  invoiceData,
  receiptData,
  receiptId,
  onClose,
  setPageState,
  type,
  isRootState = true,
  resetInvoicesQuery,
  resetReceiptsQuery,
  qrData
}) => {
  const currentTeam = useAppSelector(selectCurrentTeam);

  const [shareInvoiceReceipt, { isSuccess: isInvoiceShareSuccess }] = useShareInvoiceReceiptMutation();
  const [shareReceipt, { isSuccess: isReceiptShareSuccess }] = useShareReceiptMutation();
  const [downloadReceiptAsPDF] = useLazyDownloadReceiptAsPDFQuery();
  const [downloadInvoice] = useLazyDownloadInvoiceAsPDFQuery();
  const [downloadRefundReceipt] = useLazyDownloadRefundReceiptAsPDFQuery();

  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [emailUpdateDisabled, setEmailUpdateDisabled] = useState(false);
  const [phoneUpdateDisabled, setPhoneUpdateDisabled] = useState(false);
  const [emailCheckClicked, setEmailCheckClicked] = useState(false);
  const [phoneCheckClicked, setPhoneCheckClicked] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const [communicationMedium, setCommunicationMedium] = useState(null);

  useEffect(() => {
    const existingEmail =
      type === SharePanelType.Invoice ? invoiceData.customer?.email === email : receiptData?.customerEmail === email;
    const existingPhoneNumber =
      type === SharePanelType.Invoice
        ? invoiceData.customer?.phoneNumber === phoneNumber
        : receiptData.customerPhone === phoneNumber;
    setPhoneUpdateDisabled(existingPhoneNumber);
    if (!phoneCheckClicked) setUpdatePhoneNumber(!existingPhoneNumber);
    if (!emailCheckClicked) setUpdateEmail(!existingEmail);
    setEmailUpdateDisabled(existingEmail);
  }, [email, phoneNumber]);

  useEffect(() => {
    if (type) {
      if (type === SharePanelType.Invoice) {
        setPhoneNumber(removeUSCountryCode(invoiceData.customer?.phoneNumber));
        setEmail(invoiceData.customer?.email);
      } else {
        setPhoneNumber(removeUSCountryCode(receiptData.customerPhone));
        setEmail(receiptData.customerEmail);
      }
    }
  }, [type]);

  useEffect(() => {
    if (isInvoiceShareSuccess) {
      showShareSuccessNotifier();
      if (resetInvoicesQuery) resetInvoicesQuery();
    }
  }, [isInvoiceShareSuccess]);

  useEffect(() => {
    if (isReceiptShareSuccess) {
      showShareSuccessNotifier();
      if (resetReceiptsQuery) resetReceiptsQuery();
    }
  }, [isReceiptShareSuccess]);

  const dispatch = useAppDispatch();
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);

  const hasEditCustomerPermissions =
    customerPermissions.editCustomersCreatedByTeam || customerPermissions.editExistingCustomers;

  const getPanelTitle = () => {
    switch (type) {
      case SharePanelType.Invoice:
        return 'invoice';
      case SharePanelType.Refund:
      case SharePanelType.Receipt:
        return 'receipt';
      default:
        return 'receipt';
    }
  };

  const getFileType = () => {
    if (type === SharePanelType.Invoice) return ForwardFileType.INVOICE;
    else if (type === SharePanelType.Receipt) return ForwardFileType.RECEIPT;
  };

  const getPrimaryMessage = (communicationType: string) => {
    let primaryMessage = '';
    if (type === SharePanelType.Invoice) primaryMessage += 'Invoice';
    else if (type === SharePanelType.Receipt) primaryMessage += 'Receipt';
    else primaryMessage += 'Refund receipt';
    primaryMessage += ` shared successfully on 
    ${communicationType === 'EMAIL' ? 'email' : 'phone'} `;
    return primaryMessage;
  };

  const shareInvoice = (communicationType: string) => {
    setCommunicationMedium(communicationType);
    let phoneNumberPayload = '';
    let emailPayload = '';
    const shareViaSMS = communicationType === 'SMS';
    let shouldUpdateCustomer = hasEditCustomerPermissions;
    if (shareViaSMS) {
      phoneNumberPayload = phoneNumber.startsWith('+') ? phoneNumber : `+1${phoneNumber}`;
      shouldUpdateCustomer = hasEditCustomerPermissions && updatePhoneNumber;
    } else {
      emailPayload = email;
      shouldUpdateCustomer = hasEditCustomerPermissions && updateEmail;
    }
    const payload = {
      phoneNumber: phoneNumberPayload,
      email: emailPayload,
      updateCustomer: shouldUpdateCustomer,
      teamId: currentTeam.id.toString()
    };

    if (type === SharePanelType.Invoice) {
      shareInvoiceReceipt({ ...payload, invoiceId: invoiceData.invoiceId, type: getFileType() });
    } else {
      shareReceipt({ receiptId, ...payload });
    }
  };

  const showShareSuccessNotifier = () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: getPrimaryMessage(communicationMedium),
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    if (communicationMedium === 'SMS') {
      logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailShareReceiptSendSMS, {
        'Phone Number': phoneNumber.startsWith('+') ? phoneNumber : `+1${phoneNumber}`
      });
    }
    if (communicationMedium === 'EMAIL') {
      logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailShareReceiptSendEmail, {
        'Email ID': email
      });
    }
  };

  const downloadFile = async () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    if (type === SharePanelType.Refund) {
      await downloadRefundReceipt({ receiptId });
    } else if (type === SharePanelType.Receipt) {
      downloadReceiptAsPDF({ receiptId, teamId: currentTeam.id });
    } else {
      await downloadInvoice({ invoiceId: invoiceData.invoiceId, teamId: currentTeam.id });
    }
  };

  const checkEmailError = () => {
    if (!REGEX.email.test(email) || email.length === 0) {
      let isValid = true;
      const inValidDoamins = ['..', '.con', '.comn', '.cok', '.don', '.cim', 'gnail.com', 'gmal.com'];
      inValidDoamins.map(_ => {
        if (email.endsWith(_)) {
          isValid = false;
        }
      });
      return isValid;
    }
    return false;
  };

  const copyLinkHandler = () => {
    const url = type === SharePanelType.Invoice ? invoiceData?.receiptUrl : receiptData?.receiptUrl;
    setIsLinkCopied(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => setIsLinkCopied(false), 1000);
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailShareReceiptCopy);
  };

  const isPhoneUpdateDisabled = () => {
    if (hasEditCustomerPermissions) {
      return phoneUpdateDisabled;
    }
    return !hasEditCustomerPermissions;
  };

  const isEmailUpdateDisabled = () => {
    if (hasEditCustomerPermissions) {
      return emailUpdateDisabled;
    }
    return !hasEditCustomerPermissions;
  };

  return (
    <>
      <div className="flex h-full flex-col">
        <div className="flex cursor-pointer flex-row items-center bg-white p-4" onClick={onClose}>
          {isRootState ? (
            <CrossIconvector className="shrink-0" id={`share-${type}-side-panel-close-button`} />
          ) : (
            <ArrowRightIcon className="rotate-180" id={`share-${type}-side-panel-back-button`} />
          )}
          <div className="pl-2 text-17px font-semibold capitalize text-primaryText">{`Share ${getPanelTitle()}`}</div>
        </div>
        <div className="customNormalScroll flex w-full grow flex-col overflow-y-auto rounded py-2 px-4	pb-0">
          <div className="text-px13 font-normal text-heading">
            {`Choose a method to share the ${getPanelTitle()} with the customer`}
          </div>
          <div
            className="mt-5 flex w-full flex-col items-center rounded-md	border-borderGray pt-4 pb-2"
            style={{ borderWidth: '1px' }}>
            <div className={'text-sbase font-semibold text-headingGray'}>Scan with camera</div>
            <div className="flex flex-col items-center">
              <img
                src={qrData}
                className={'w-60 cursor-pointer'}
                onClick={() => setPageState(ShareReceiptPageState.SHARE_QR)}
              />
              <div className="flex flex-row items-center">
                <ExpandIcon />
                <div className={'ml-2 text-px13 font-semibold text-tertiaryText'}>Tap the QR to expand</div>
              </div>
            </div>
          </div>

          {type === SharePanelType.Invoice && (
            <>
              <CustomButton id="share-invoice-sidepanel-tap-to-pay" disabled className="relative mt-5 h-12 w-full py-4">
                <div className="flex w-full">
                  <TTPIcon className="absolute left-4 top-2.5" />
                  <span className="justify-center text-17px font-normal text-white">Tap to Pay</span>
                </div>
              </CustomButton>

              <div className="mt-2 flex flex-row items-center">
                <MobileVectorIcon />
                <div className="pl-3 text-px13 font-normal text-primaryText">
                  {' '}
                  Only available with the ScanPay mobile app.
                </div>
              </div>

              <div className="mt-3 flex flex-row items-center">
                <div className="text-sbase font-semibold text-primaryText">Visit:-</div>

                <div
                  className="ml-3 flex h-7 cursor-pointer flex-col items-center rounded-md border-borderGray pr-2"
                  style={{ borderWidth: '1px' }}>
                  <div className="flex flex-row items-center pt-1">
                    <IosStoreIcon className={'ml-1'} />
                    <div
                      className={'ml-2 text-sbase font-normal text-secondary'}
                      onClick={() => window.open(APP_STORE_URL, '_blank')}>
                      App store
                    </div>
                  </div>
                </div>

                <div
                  className="ml-3 flex h-7 cursor-pointer flex-col items-center rounded-md border-borderGray pr-2"
                  style={{ borderWidth: '1px' }}
                  onClick={() => window.open(PLAY_STORE_URL, '_blank')}>
                  <div className="flex flex-row items-center pt-1">
                    <PlayStoreIcon className={'ml-1'} />
                    <div className={'ml-1 text-sbase font-normal text-secondary'}>Play store</div>
                  </div>
                </div>
              </div>
            </>
          )}

          <div
            className="my-5 flex w-full flex-col rounded-md	
        border-borderGray px-4 pt-4"
            style={{ borderWidth: '1px' }}>
            <div className="flex flex-row items-center">
              <MessageIcon />
              <div className={'ml-2 text-sbase font-semibold text-primaryText'}>Text Message</div>
            </div>

            <div>
              <div
                className={`mt-4 flex w-full
                     flex-row rounded-lg border ${isPhoneNumberValid ? 'border-borderGray' : 'border-error'}
                     bg-primary text-sm font-semibold text-gray-900 
                      focus:border-primaryBtn focus:ring-blue-500`}>
                <div className={'mt-0.5 mr-1 flex items-center justify-center pl-2.5 text-17px text-accentText'}>
                  +1
                </div>
                <CustomInput
                  placeholder="000-000-0000"
                  id="share-invoice/receipt-phone-number"
                  inputStyle="w-full text-17px"
                  type={InputType.TEXT}
                  className="h-10 rounded-lg bg-primary p-0 text-primaryText 
                  placeholder:text-sbase placeholder:text-accentText focus:outline-none"
                  value={phoneNumber}
                  onChange={value => {
                    if (REGEX.phoneNumberInput.test(value)) {
                      setIsPhoneNumberValid(true);
                      setPhoneNumber(value);
                    }
                  }}
                />
              </div>
              {!isPhoneNumberValid && <div className="mt-1 text-red-400">Please enter a valid phone number</div>}

              <CustomButton
                id={
                  type === SharePanelType.Invoice
                    ? 'share-invoice-sidepanel-send-sms-button'
                    : 'share-receipt-sidepanel-send-sms-button'
                }
                className="path-stroke-current mt-4 h-[38px] w-full
         bg-primaryBtn px-4 py-2 font-lato normal-case text-primaryBtnText disabled:opacity-70 "
                onClick={() => {
                  if (REGEX.usIndianNumber.test(phoneNumber)) {
                    shareInvoice('SMS');
                  } else setIsPhoneNumberValid(false);
                }}
                StartIcon={MessageIcon}>
                <span className="text-sbase font-semibold">Send SMS</span>
              </CustomButton>

              <div
                className={`mb-4 mt-2 flex items-center ${
                  isPhoneUpdateDisabled() && 'pointer-events-none opacity-50'
                }`}>
                <input
                  id={
                    type === SharePanelType.Invoice
                      ? 'share-invoice-sidepanel-update-cust-info-phno-checkbox'
                      : 'share-receipt-sidepanel-update-cust-info-phno-checkbox'
                  }
                  type="checkbox"
                  onChange={() => {
                    setPhoneCheckClicked(true);
                    setUpdatePhoneNumber(!updatePhoneNumber);
                  }}
                  checked={hasEditCustomerPermissions && updatePhoneNumber}
                  className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
                />

                <label
                  htmlFor={
                    type === SharePanelType.Invoice
                      ? 'share-invoice-sidepanel-update-cust-info-phno-checkbox'
                      : 'share-receipt-sidepanel-update-cust-info-phno-checkbox'
                  }
                  className="ml-1 text-sbase font-medium text-primaryText">
                  Update customer information
                </label>
              </div>
            </div>
          </div>

          <div
            className="flex w-full flex-col rounded-md border-borderGray
        px-4 pt-4"
            style={{ borderWidth: '1px' }}>
            <div className="flex flex-row items-center">
              <MailIcon />
              <div className={'ml-2 text-sbase font-semibold text-primaryText'}>Email</div>
            </div>

            <div className=" text-gray-600">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <button type="submit" className="focus:shadow-outline p-1 focus:outline-none"></button>
              </span>
              <CustomInput
                id="share-invoice/receipt-email"
                inputStyle="w-full text-17px"
                type={InputType.TEXT}
                onChange={value => {
                  setIsEmailValid(true);
                  setEmail(value);
                }}
                value={email}
                className={`mt-4 block h-10 rounded-lg border
               placeholder:text-sbase placeholder:text-accentText ${isEmailValid ? 'border-borderGray' : 'border-error'}
                bg-primary  p-2.5 font-semibold text-primaryText focus:outline-none`}
                placeholder="Enter email id"
              />
              {!isEmailValid && <div className="mt-1 text-red-400">Please enter a valid email</div>}

              <CustomButton
                id={
                  type === SharePanelType.Invoice
                    ? 'share-invoice-sidepanel-email-button'
                    : 'share-receipt-sidepanel-email-button'
                }
                className="path-stroke-current mt-4 h-[38px] w-full
         bg-primaryBtn px-4 py-2 font-lato normal-case text-primaryBtnText disabled:opacity-70"
                onClick={() => {
                  if (!checkEmailError()) {
                    shareInvoice('EMAIL');
                  } else setIsEmailValid(false);
                }}
                StartIcon={MailIcon}>
                <div className="font-semibold">Send Email</div>
              </CustomButton>

              <div
                className={`mb-4 mt-2 flex items-center ${
                  isEmailUpdateDisabled() && 'pointer-events-none opacity-50'
                }`}>
                <input
                  id={
                    type === SharePanelType.Invoice
                      ? 'share-invoice-sidepanel-update-cust-info-email-checkbox'
                      : 'share-receipt-sidepanel-update-cust-info-email-checkbox'
                  }
                  type="checkbox"
                  onChange={() => {
                    setEmailCheckClicked(true);
                    setUpdateEmail(!updateEmail);
                  }}
                  checked={hasEditCustomerPermissions && updateEmail}
                  className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
                />
                <label
                  htmlFor={
                    type === SharePanelType.Invoice
                      ? 'share-invoice-sidepanel-update-cust-info-email-checkbox'
                      : 'share-receipt-sidepanel-update-cust-info-email-checkbox'
                  }
                  className="ml-1 text-sbase font-medium text-primaryText">
                  Update customer information
                </label>
              </div>
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col rounded-md	border-borderGray p-4" style={{ borderWidth: '1px' }}>
            <div className="flex flex-row items-center">
              <AttachmentIcon />
              <div className={'ml-2 text-sbase font-semibold text-primaryText'}>Copy Link</div>
            </div>
            <div
              className="mt-4 flex rounded-lg 
              border border-borderGray text-gray-600 focus-within:text-gray-400 focus:border-primaryBtn">
              <div
                className="block w-full  truncate rounded-l-lg bg-primary p-2.5 
                     text-sbase text-primaryText focus:ring-blue-500">
                {type === SharePanelType.Invoice ? invoiceData?.receiptUrl : receiptData?.receiptUrl}
              </div>
              <div>
                <button
                  onClick={copyLinkHandler}
                  className={twMerge(`flex h-full flex-1 flex-row items-center justify-center rounded-r-lg border-l
                  border-borderGray bg-secondaryBtn font-semibold
                   px-4 text-sbase text-primaryBtn ${isLinkCopied && 'text-primaryText'}`)}
                  style={{ flexShrink: 0, whiteSpace: 'nowrap' }}>
                  {isLinkCopied ? 'Link copied' : 'Copy'}
                </button>
              </div>
            </div>
          </div>

          <div className={'mt-7 mb-12 flex cursor-pointer flex-col items-center'}>
            <div id={`download-${type}-button`} className="flex flex-row items-center" onClick={downloadFile}>
              <DownloadIcon />
              <div className={'ml-2 text-17px font-semibold text-secondary'}>Download {getPanelTitle()}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
