import { If } from 'components';
import { FC } from 'react';
import { formatDecimalThreePlaces, formattedLineItemQty, getAmountWithCurrency } from 'utils/amountUtils';
import { InvoiceByIdResponse } from '../invoices.model';

interface LineItemProps {
  invoiceData: InvoiceByIdResponse;
  marginX?: string;
  paddingX?: string;
  showTotalAmount?: boolean;
  borderStyle?: string;
}

const LineItems: FC<LineItemProps> = props => {
  const { invoiceData, marginX = 'mx-4', paddingX = 'px-3', showTotalAmount = true, borderStyle = '' } = props;

  return (
    <>
      <div
        className={`flex items-center justify-between border-b border-borderGray py-4
            text-sbase font-semibold text-primaryText ${marginX}`}>
        <div>Items</div>
        <div>Price</div>
      </div>
      <div>
        {invoiceData.lineItems?.map((lineItem, index) => (
          <div
            className={`flex justify-between border-b border-secondaryBtn py-4 last:border-b-0 ${marginX}`}
            key={lineItem.name + lineItem.qty}>
            <div className="flex w-2/3 flex-col gap-2">
              <div
                id={`line-item-${index + 1}`}
                className="line-clamp-8 break-words text-sbase font-semibold text-headingGray">
                {lineItem.name}
              </div>
              <If condition={Boolean(lineItem.additionalDetails)}>
                <div
                  id={`${lineItem.name}-${index}-additional-details`}
                  className="whitespace-pre-line text-px13 text-primaryText line-clamp-3">
                  {lineItem.additionalDetails}
                </div>
              </If>
            </div>
            <div className="flex flex-col gap-2 text-right">
              <div id={`${lineItem.name}-${index}-amount`} className="text-sbase font-semibold text-headingGray">
                {getAmountWithCurrency(lineItem.amount)}
              </div>
              <div id={`${lineItem.name}-${index}-price-qnty-splitup`} className="text-sbase text-accentText">
                {`(${formattedLineItemQty({ qtyString: lineItem.qty })} x ${getAmountWithCurrency(
                  lineItem.unitPrice
                )})`}
              </div>
            </div>
          </div>
        ))}
        <If condition={Boolean(+invoiceData.discount)}>
          <div className={`flex justify-between py-4 ${marginX} text-sbase font-semibold text-tertiaryText`}>
            <div>Discount</div>
            <div id="line-item-discount" className="flex gap-4 text-right">
              <div className="text-accentText">{formatDecimalThreePlaces(+invoiceData.discountPercentage)}%</div>
              <div>-{getAmountWithCurrency(invoiceData.discount)}</div>
            </div>
          </div>
        </If>
        <div
          className={`bg-secondaryBtn ${paddingX} flex flex-col
          gap-3 py-5 text-sbase text-tertiaryText ${borderStyle}`}>
          <div className="text-px13 font-semibold text-headingGray">Summary</div>
          <div className="flex justify-between">
            <div>Subtotal</div>
            <div className="font-semibold">{getAmountWithCurrency(+invoiceData.billAmount)}</div>
          </div>
          <If condition={invoiceData.surcharge.enabled}>
            <div className="flex justify-between">
              <div>Service fee</div>
              <div id="line-items-service-fee" className="flex gap-4 font-semibold">
                {/* <div className="text-accentText">{invoiceData.surcharge?.percentage}</div> */}
                <div>{getAmountWithCurrency(+invoiceData.surchargeAmount)}</div>
              </div>
            </div>
          </If>
          {invoiceData?.serviceCharges?.length > 0 &&
            invoiceData.serviceCharges.map((serviceCharge, index) => (
              <div className="flex justify-between" key={`${serviceCharge.name + index}`}>
                <div className="break-words line-clamp-3">{serviceCharge.name}</div>
                <div id="invoice-line-items-service-fee-percent" className="flex gap-4 font-semibold">
                  {/* <div className="text-accentText">{formatDecimalThreePlaces(+scharge.percentage)}%</div> */}
                  <div>{getAmountWithCurrency(+serviceCharge.totalAmount)}</div>
                </div>
              </div>
            ))}
          {invoiceData?.taxes?.length > 0 &&
            invoiceData.taxes.map((tax, index) => (
              <div className="flex justify-between" key={`${tax.name + index}`}>
                <div className="break-words line-clamp-3">{tax.name}</div>
                <div id="line-items-additional-tax-percent" className="flex gap-4 font-semibold">
                  {/* <div className="text-accentText">{formatDecimalThreePlaces(+tax.percentage)}%</div> */}
                  <div>{getAmountWithCurrency(+tax.amount)}</div>
                </div>
              </div>
            ))}
          <If condition={invoiceData.hasTip && +invoiceData.tipAmount > 0}>
            <div className="flex justify-between">
              <div>Tip</div>
              <div id="line-items-tip-amount" className="flex gap-4 font-semibold">
                {/* <div className="text-accentText">{formatDecimalThreePlaces(+invoiceData.tipPercentage)}%</div> */}
                <div className="font-semibold">{getAmountWithCurrency(+invoiceData.tipAmount)}</div>
              </div>
            </div>
          </If>
          <If condition={showTotalAmount}>
            <div className="flex justify-between border-t border-dark-gray pt-2 font-bold text-headingGray">
              <div>Total Amount</div>
              <div id="line-items-total-amount">
                {getAmountWithCurrency(invoiceData.isPaid ? +invoiceData.amount : +invoiceData.payableAmount)}
              </div>
            </div>
          </If>
        </div>
      </div>
    </>
  );
};

export default LineItems;
