import { InfoIcon } from 'assets/icons';
import { CustomPopover } from 'components';
import { PopoverArrowTypes } from 'constants/common';
import { FC, useState } from 'react';

const PermissionsToolTip: FC = () => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleShowToolTip = () => {
    setShowToolTip(true);
  };

  const handleCloseToolTip = () => {
    setShowToolTip(false);
  };

  const toolTipElement = () => (
    <div onMouseEnter={handleShowToolTip} onMouseLeave={handleCloseToolTip} className="cursor-pointer">
      <InfoIcon className="shrink-0" />
    </div>
  );
  return (
    <CustomPopover
      show={showToolTip}
      onTogglePopover={handleShowToolTip}
      onClosePopover={handleCloseToolTip}
      anchorComponent={toolTipElement}
      highlightAnchor={false}
      offset="-left-20"
      showArrow={true}
      arrowType={PopoverArrowTypes.TOP}
      arrowOffset="right-2 left-[89px]"
      margin="mt-3"
      width="w-[267px]"
      popoverBodyStyle="px-4 py-2">
      <div className="text-px13 font-normal text-primaryText">
        Each member can have different levels of access. Owners and admins can update the permissions at any time.
        <br />
        <br />
        Questions? Check out the “Help” section reach out to support@scanpay.tech
      </div>
    </CustomPopover>
  );
};

export default PermissionsToolTip;
