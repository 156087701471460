import { REGEX } from 'constants/common';

export const formatPhoneNumber = phoneNumberString => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(REGEX.formatNumber);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

export const isValidPhoneNumber = (number: string) => {
  if (REGEX.phoneNumber.test(number)) {
    return true;
  }
  false;
};

export const appendCountryCode = (number: string, code: string) => {
  if (number.startsWith('+')) {
    return number;
  }
  return code + number;
};

export const removeCountryCode = (phoneNumber: string, code = '+1') => {
  if (phoneNumber.startsWith(code)) {
    return phoneNumber.replace(code, '');
  }
  return phoneNumber;
};

export const isNonUsPhoneNumber = (phoneNumber: string) =>
  phoneNumber.startsWith('+91') || phoneNumber.startsWith('+63');

export const getFormattedNumWithCountryCode = (phoneNumber: string, code = '+1') => {
  if (phoneNumber) {
    let number = formatPhoneNumber(phoneNumber);
    number = appendCountryCode(number, code);
    return number;
  }
  return phoneNumber;
};

export const isValidPhoneNumber2 = (number: string) => {
  if (REGEX.phoneNumberInput.test(number)) {
    return true;
  }
  return false;
};
