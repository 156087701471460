import React, { FC } from 'react';
import { CircularStepper, CustomButton, RadioWithLabel } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useUpdateBasicDetailsMutation } from '../api';
import { getCurrentData, getUserData, setUserData, updateCurrentData } from '../digitalOnboardingSlice';
import routesPath from 'routes/RoutesPath';
import { constructBasicDetails } from '../utils';

const EmployeeCountOptions = [
  {
    label: '1 (only myself)',
    value: '1'
  },
  {
    label: '2-5',
    value: '2-5'
  },
  {
    label: '6-10',
    value: '6-10'
  },
  {
    label: '11-20',
    value: '11-20'
  },
  {
    label: '21+',
    value: '21+'
  }
];

const EmployeeSection: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const currentData = useAppSelector(getCurrentData);
  const [updateBasicDetailsMutation] = useUpdateBasicDetailsMutation();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { numberOfEmployees } = currentData;

  const onEmployeeCountChange = value => {
    dispatch(updateCurrentData({ key: 'numberOfEmployees', value }));
  };

  const onContinue = () => {
    if (numberOfEmployees) {
      const updatedData = {
        ...userData,
        numberOfEmployees
      };
      dispatch(setUserData(updatedData));
      updateBasicDetailsMutation({
        payload: constructBasicDetails({
          userData: updatedData,
          teamId: currentTeam.id.toString()
        })
      });
      navigate(routesPath.DIGITAL_ONBOARDING_REFERRAL_SOURCE, { replace: true });
    }
  };

  const onBack = () => {
    navigate(routesPath.DIGITAL_ONBOARDING_BUSINESS_NAME, { replace: true });
  };

  return (
    <div className="flex flex-col self-center lg:min-w-[375px]">
      <CircularStepper currentStep={5} totalSteps={7} />
      <div className="mb-[30px] flex max-w-[375px] flex-col gap-2.5">
        <div className="text-4xl font-bold leading-[43px] text-headingGray">
          How many employees are at your company?
        </div>
      </div>
      <div className="flex flex-col gap-5">
        {EmployeeCountOptions.map(option => (
          <RadioWithLabel
            key={option.value}
            isSelected={option.value === numberOfEmployees}
            label={option.label}
            onClick={() => onEmployeeCountChange(option.value)}
          />
        ))}
      </div>
      <div className="mt-10 flex flex-col gap-5">
        <CustomButton
          id="continue-sign-up"
          className="h-10 w-full"
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onContinue}
          disabled={!numberOfEmployees}>
          Continue
        </CustomButton>
        <CustomButton
          id="back-button-sign-up"
          className="h-10 w-full border-none shadow-none hover:bg-primary active:shadow-none"
          type={ButtonType.SECONDARY}
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onBack}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default EmployeeSection;
