import { Listbox, Transition } from '@headlessui/react';
import { DropDownChevronDownIcon } from 'assets/icons';
import classnames from 'classnames';
import { FC, Fragment } from 'react';
import { twMerge } from 'tailwind-merge';

interface ListDropDownProps {
  className?: string;
  selectedItem: any;
  items: any;
  setSelectedItem: any;
  hideDropDownIcon?: boolean;
  buttonStyles?: string;
  optionStyles?: string;
  wrapperStyles?: string;
  disabled?: boolean;
  onClick?: () => void;
  spanStyles?: string;
  optionsHeight?: string;
  placeholder?: string;
  id: string;
}

const ListDropDown: FC<ListDropDownProps> = ({
  wrapperStyles,
  optionStyles,
  buttonStyles,
  selectedItem,
  setSelectedItem,
  items,
  disabled = false,
  onClick = null,
  spanStyles = '',
  optionsHeight = '',
  hideDropDownIcon = false,
  placeholder = '',
  id = 'list-dropdown'
}) => {
  return (
    <div className={`w-full ${wrapperStyles}`}>
      <Listbox value={selectedItem} onChange={setSelectedItem}>
        <div className="relative mt-1">
          <Listbox.Button
            className={twMerge(
              ` 
              relative w-full cursor-default rounded-[4px] bg-white 
              py-4 px-5 text-left focus:outline-none 
            focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white
              focus-visible:ring-offset-2 
              `,
              classnames({ [buttonStyles]: buttonStyles })
            )}
            onClick={e => {
              if (disabled && onClick) {
                e.preventDefault();
                onClick();
              }
            }}
            id={id}>
            <span className={`block truncate ${disabled ? 'text-accentText' : 'text-primaryText'} ${spanStyles}`}>
              {selectedItem ? selectedItem.name : placeholder}
            </span>
            {!hideDropDownIcon && (
              <span className="pointer-events-none absolute inset-y-0 right-5 flex items-center pr-2">
                <DropDownChevronDownIcon className="h-[8px] w-[14px] text-gray-400" aria-hidden="true" />
              </span>
            )}
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={twMerge(
                `
                absolute z-[60] mt-[2px] ${optionsHeight !== '' ? `${optionsHeight} overflow-auto` : ' h-fit'}
                w-full min-w-[200px] rounded-md border
                border-borderGray bg-primary
                px-4 shadow-md sm:px-0

              `,
                classnames({ [optionStyles]: optionStyles })
              )}>
              {items.map(item => (
                <Listbox.Option
                  key={item.id}
                  className={({ active }) =>
                    `text-primaryText font-semibold text-sbase relative cursor-pointer select-none pl-5 py-2 pr-4 ${
                      active ? '' : ''
                    }`
                  }
                  value={item}>
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? '' : ''}`}>{item.name}</span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default ListDropDown;
