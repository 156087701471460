import { ChevronArrowRightIcon, FCBankAccountIcon } from 'assets/icons';
import { FC } from 'react';

const AddFCAccountCard: FC = () => {
  return (
    <div className={'flex w-full items-center rounded-md border-2 border-secondary bg-[#A4D7FA66] p-[15px]'}>
      <FCBankAccountIcon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-semibold text-primaryText">Connect to your bank securely</div>
        <div className="text-sbase text-primaryText">Securely login with your bank details in seconds</div>
      </div>
      <ChevronArrowRightIcon className="ml-auto shrink-0" />
    </div>
  );
};

export default AddFCAccountCard;
