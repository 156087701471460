import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';
import LineItemPlusReactIcon from 'assets/react-icons/LineItemPlusReactIcon';
import { DiscountFormData } from 'containers/invoices/types';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency, roundToTwoDecimals } from 'utils/amountUtils';

interface AddDiscountSectionProps {
  className?: string;
  selectedDiscount: DiscountFormData;
  onClearSelectedDiscount: VoidFn;
  onEditDiscount: VoidFn;
  isEditInvoiceFlow: boolean;
  isAddDiscountDisabled: boolean;
  onAddDiscountClick: VoidFn;
}

const AddDiscountSection: FC<AddDiscountSectionProps> = ({
  className = '',
  selectedDiscount,
  onClearSelectedDiscount,
  onEditDiscount,
  isEditInvoiceFlow = false,
  isAddDiscountDisabled = true,
  onAddDiscountClick
}) => {
  const hasSelectedDiscount: boolean = +selectedDiscount.discountPercent > 0 || +selectedDiscount.discountAmount > 0;

  return (
    <div
      className={twMerge(
        `
         border-b border-borderGray p-5
        `,
        className
      )}>
      {hasSelectedDiscount ? (
        <div className="relative flex flex-row text-17px">
          <div onClick={onEditDiscount} className="w-1/3  cursor-pointer text-left font-semibold text-secondary">
            Discount
          </div>
          <div className="w-2/5 text-right font-semibold text-accentText">
            {roundToTwoDecimals(selectedDiscount?.discountPercent)}%
          </div>
          <div className="w-0 grow break-words text-right font-semibold text-heading">
            -{getAmountWithCurrency(selectedDiscount?.discountInCurrency)}
          </div>

          <FormCloseReactIcon
            id="edit-invoice-remove-discount-close-icon"
            onClick={onClearSelectedDiscount}
            // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
            className="absolute -right-[45px] shrink-0 cursor-pointer 1439px:-right-[50px]"
          />
        </div>
      ) : (
        <div
          id={isEditInvoiceFlow ? 'edit-invoice-add-discount' : 'create-invoice-add-discount'}
          className={twMerge(
            `text-17px text-secondary font-semibold flex items-center gap-2 ${
              isAddDiscountDisabled ? 'opacity-80 cursor-not-allowed' : 'cursor-pointer opacity-100'
            }`
          )}
          onClick={onAddDiscountClick}>
          <LineItemPlusReactIcon id="edit-invoice-add-discount-icon" className="shrink-0" />
          <div className="text-17px font-semibold text-secondary">Add a discount</div>
        </div>
      )}
    </div>
  );
};

export default AddDiscountSection;
