import { MailIIcon, PhoneIcon } from 'assets/icons';
import { CustomButton, CustomInput, If } from 'components';
import { REGEX } from 'constants/common';
import React, { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { checkMarkEmailAsValid } from 'utils/commonUtils';
import {
  appendCountryCode,
  formatPhoneNumber,
  isNonUsPhoneNumber,
  isValidPhoneNumber,
  removeCountryCode
} from 'utils/phoneNumberUtils';
import { SignInMethodEnum, signInMethods } from '../constants';
import { SignInMethod } from '../types';

interface SignInSectionProps {
  selectedSignInMethod: SignInMethod;
  setSelectedSignInMethod: React.Dispatch<React.SetStateAction<SignInMethod>>;
  onContinue: ({ value }: { value: string }) => void;
  className?: string;
  handleSignUp: VoidFn;
  userPhoneNumber: string;
  isLoading: boolean;
}

const initialEmailErrorState = {
  msg: 'Please enter a valid email',
  isPresent: false
};

const SignInSection: FC<SignInSectionProps> = ({
  selectedSignInMethod,
  setSelectedSignInMethod,
  onContinue,
  handleSignUp,
  userPhoneNumber,
  isLoading
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<{ msg: string; isPresent: boolean }>(initialEmailErrorState);
  const [isContinueClicked, setIsContinueClicked] = useState(false);

  useEffect(() => {
    if (userPhoneNumber) {
      if (isNonUsPhoneNumber(userPhoneNumber)) {
        setPhoneNumber(userPhoneNumber);
      } else {
        setPhoneNumber(formatPhoneNumber(userPhoneNumber));
      }
    }
  }, [userPhoneNumber]);

  const getPhoneNumberDigits = phoneNumberString => {
    let phoneNo = phoneNumberString;

    if (isNonUsPhoneNumber(phoneNo)) {
      phoneNo = `+${phoneNo.replaceAll(REGEX.nonNumber, '')}`;
    } else {
      phoneNo = removeCountryCode(phoneNo, '+1');
      phoneNo = phoneNo.replaceAll(REGEX.nonNumber, '');
      phoneNo = appendCountryCode(phoneNo, '+1');
    }
    return phoneNo;
  };

  const handleContinueBtnClick = () => {
    setIsContinueClicked(true);
    if (selectedSignInMethod.id === SignInMethodEnum.PHONE_NUMBER) {
      const phoneNo = getPhoneNumberDigits(phoneNumber);
      if (isValidPhoneNumber(phoneNo)) {
        onContinue({ value: phoneNo });
      } else {
        setPhoneNumberError('Enter valid phone number.');
      }
    } else {
      if (REGEX.email.test(email)) {
        onContinue({ value: email });
      } else {
        setEmailError(prev => ({ ...prev, isPresent: true }));
      }
    }
  };

  const handlePhoneNumberInputChange = value => {
    let num = value;
    if (REGEX.phoneNumberInput.test(num)) {
      if (isNonUsPhoneNumber(num)) {
        setPhoneNumber(num);
      } else {
        num = formatPhoneNumber(num);
        setPhoneNumber(num);
      }
      if (isContinueClicked) {
        const phoneNo = getPhoneNumberDigits(num);
        if (isValidPhoneNumber(phoneNo)) {
          setPhoneNumberError('');
        } else {
          setPhoneNumberError('Enter valid phone number.');
        }
      }
    }
  };

  const markEmailAsValidCallback = (isValid: boolean) => {
    if (!isValid) {
      setEmailError(prev => ({ ...prev, isPresent: true }));
    }
  };

  const handleEmailChange = value => {
    if (emailError.isPresent) {
      setEmailError(prev => ({ ...prev, isPresent: false }));
    }
    setEmail(value);
    if (isContinueClicked) checkMarkEmailAsValid({ value, markEmailAsValid: markEmailAsValidCallback });
  };

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleContinueBtnClick();
    }
  };

  const renderPhoneNumberPrefix = () => {
    return (
      <div className="flex flex-row">
        <PhoneIcon className="path-fill-current mr-2.5 h-5 w-5 shrink-0 text-[#B8C2CE]" />
        <div className="text-17px font-semibold text-accentText">+1</div>
      </div>
    );
  };

  const isContinueDisabled = (currentSignInMethod: SignInMethod) => {
    if (currentSignInMethod === signInMethods.email) {
      return email === '' && !isContinueClicked;
    }
    if (currentSignInMethod === signInMethods.phoneNumber) {
      return phoneNumber === '' && !isContinueClicked;
    }
  };

  const getSelectedMethodStyle = (method: SignInMethod, selectedMethod: SignInMethod): string => {
    if (method === selectedMethod) {
      return 'bg-primary';
    }
    return '';
  };

  const onChangeSignMethod = (signInMethod: SignInMethod) => {
    setSelectedSignInMethod(signInMethod);
    if (signInMethod.id === SignInMethodEnum.EMAIL) {
      setPhoneNumber('');
      setPhoneNumberError('');
    } else {
      setEmail('');
      setEmailError(initialEmailErrorState);
    }
  };

  return (
    <div className="my-auto flex flex-col self-center lg:min-w-[375px]">
      <div className="mb-2 mt-12 text-xl font-bold text-heading lg:text-4xl">Sign in to ScanPay</div>
      <div className="mb-10 text-17px font-normal text-primaryText">
        Don't have an account?
        <span id="signup-on-app" className="cursor-pointer text-secondary" onClick={handleSignUp}>
          {' '}
          Sign up
        </span>
      </div>

      <div className="mb-5 flex h-11 w-full flex-row items-center justify-center rounded-md bg-secondaryBtn p-1">
        <div
          onClick={() => {
            setIsContinueClicked(false);
            onChangeSignMethod(signInMethods.phoneNumber);
          }}
          className={`flex h-full w-1/2 cursor-pointer items-center justify-center rounded-md text-center
           text-sbase font-semibold text-primaryText 
           ${getSelectedMethodStyle(signInMethods.phoneNumber, selectedSignInMethod)}`}>
          <div>{signInMethods.phoneNumber.label}</div>
        </div>
        <div
          onClick={() => {
            setIsContinueClicked(false);
            onChangeSignMethod(signInMethods.email);
          }}
          className={`flex h-full w-1/2 cursor-pointer items-center justify-center rounded-md
           text-center text-sbase font-semibold text-primaryText
            ${getSelectedMethodStyle(signInMethods.email, selectedSignInMethod)}`}>
          <div>{signInMethods.email.label}</div>
        </div>
      </div>

      <div className="relative">
        <div className={selectedSignInMethod === signInMethods.phoneNumber ? 'visible' : 'hidden'}>
          <CustomInput
            id="signin-number-input"
            placeholder="000 000 0000"
            value={phoneNumber}
            className={`border border-borderGray pl-[72px] pt-[15px]
             text-primaryText transition duration-300 
              placeholder:font-semibold
             focus:border-none focus:outline-none
            focus:ring-1 focus:ring-secondary
            ${phoneNumberError ? 'border-error focus:ring-1 focus:ring-error' : ''}
            `}
            prefixWrapperStyle="left-5"
            prefix={renderPhoneNumberPrefix()}
            onChange={handlePhoneNumberInputChange}
            onKeyPress={handleKeyDown}
          />
          <If condition={Boolean(phoneNumberError)}>
            <div id="sign-in-phone-number-error" className="absolute text-px13 text-error">
              {phoneNumberError}
            </div>
          </If>
        </div>

        <div className={selectedSignInMethod === signInMethods.email ? 'visible' : 'hidden'}>
          <CustomInput
            id="signin-email-input"
            placeholder="Email address"
            value={email}
            className={`border border-borderGray pl-[50px] text-primaryText transition duration-300
             placeholder:font-semibold
             focus:border-none focus:outline-none
             focus:ring-1 focus:ring-secondary
             ${emailError.isPresent ? 'border-error focus:ring-1 focus:ring-error' : ''}
             `}
            prefixWrapperStyle="left-5"
            prefix={<MailIIcon className="path-fill-current h-5 w-5 shrink-0 text-[#B8C2CE]" />}
            onChange={handleEmailChange}
            onKeyPress={handleKeyDown}
          />
          <If condition={emailError.isPresent && isContinueClicked}>
            <div id="sign-in-phone-number-error" className="absolute text-px13 text-error">
              {emailError.msg}
            </div>
          </If>
        </div>
      </div>
      <CustomButton
        disabled={isContinueDisabled(selectedSignInMethod)}
        id="sign-in-continue-btn"
        onClick={handleContinueBtnClick}
        isLoading={isLoading}
        className="mt-9 w-full">
        Continue
      </CustomButton>
    </div>
  );
};

export default SignInSection;
