import { InfoIcon } from 'assets/icons';
import { CustomPopover } from 'components';
import { PopoverArrowTypes } from 'constants/common';
import { FC, useState } from 'react';

const TransactionFeeToolTip: FC = () => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleShowToolTip = () => {
    setShowToolTip(true);
  };

  const handleCloseToolTip = () => {
    setShowToolTip(false);
  };

  const toolTipElement = () => (
    <div onMouseEnter={handleShowToolTip} onMouseLeave={handleCloseToolTip} className="cursor-pointer">
      <InfoIcon className="shrink-0" />
    </div>
  );
  return (
    <CustomPopover
      show={showToolTip}
      onTogglePopover={handleShowToolTip}
      onClosePopover={handleCloseToolTip}
      anchorComponent={toolTipElement}
      highlightAnchor={false}
      offset="-left-20"
      showArrow={true}
      arrowType={PopoverArrowTypes.TOP}
      arrowOffset="right-2 left-[89px]"
      margin="mt-3"
      width="w-[206px]"
      popoverBodyStyle="p-2.5">
      <div className="text-px13 text-primaryText">Transaction fee charged = 2.5%</div>
    </CustomPopover>
  );
};

export default TransactionFeeToolTip;
