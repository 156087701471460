import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import {
  DeleteReviewLinkPayload,
  GetInvoicesSummaryPayload,
  GetInvoicesSummaryResponse,
  GetMerchantCustomizationsPayload,
  GetMerchantCustomizationsResponse,
  GetMerchantTermsSuggestionsPayload,
  UpdateMerchantCustomizationsPayload,
  UpdateMerchantCustomizationsResponse,
  UpdateReviewLinkPayload,
  UpdateTNCStatusPayload,
  ValidateReviewLinkPayload,
  ValidateReviewLinkResponse,
  updateTNCPayload
} from './discountTipsFees.model';

const DiscountTipsTaxesBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMerchantCustomizations: builder.query<GetMerchantCustomizationsResponse, GetMerchantCustomizationsPayload>({
      query: ({ teamId }) => {
        const url = `${API_VERSION.V4}/merchants/customizations?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch merchant customizations',
        showToast: true
      }
    }),
    updateMerchantCustomizations: builder.mutation<
      UpdateMerchantCustomizationsResponse,
      UpdateMerchantCustomizationsPayload
    >({
      query: data => {
        const url = `${API_VERSION.V2}/merchants/customizations`;
        if (data.paymentModes) delete data['paymentModes'];

        return {
          url: url,
          method: 'POST',
          body: data
        };
      }
    }),
    getTermsSuggestions: builder.query<any, GetMerchantTermsSuggestionsPayload>({
      query: ({ teamId }) => {
        const url = `${API_VERSION.V1}/team/tnc-suggestions?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      }
    }),
    updateTNC: builder.mutation<any, updateTNCPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/team/tnc`,
          method: 'POST',
          body: payload,
          cache: 'no-cache'
        };
      },

      extraOptions: {
        failure: 'Failed to update terms and conditions',
        showToast: false,
        success: 'Updated terms and conditions successfully'
      }
    }),
    updateTNCStatus: builder.mutation<any, UpdateTNCStatusPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/team/tnc/status`,
          method: 'PUT',
          body: payload
        };
      }
    }),
    getInvoiceSummary: builder.query<GetInvoicesSummaryResponse, GetInvoicesSummaryPayload>({
      query: ({ teamId }) => {
        const url = `${API_VERSION.V1}/merchants/invoices-summary?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      }
    }),
    validateReviewLink: builder.mutation<ValidateReviewLinkResponse, ValidateReviewLinkPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/merchants/website/validate`,
          method: 'POST',
          body: payload
        };
      }
    }),
    updateReviewLink: builder.mutation<any, UpdateReviewLinkPayload>({
      query: ({ teamId, payload }) => {
        const url = `${API_VERSION.V1}/merchants/teams/${teamId}/review-link`;
        return {
          url: url,
          method: 'POST',
          body: payload
        };
      },
      extraOptions: {
        failure: 'Failed to update review link',
        showToast: true
      }
    }),
    deleteReviewLink: builder.mutation<any, DeleteReviewLinkPayload>({
      query: ({ teamId }) => {
        const url = `${API_VERSION.V1}/merchants/teams/${teamId}/review-link`;
        return {
          url: url,
          method: 'POST',
          body: { websiteURL: '', isRatingEnabled: false }
        };
      },
      extraOptions: {
        failure: 'Failed to delete review link',
        showToast: true,
        success: 'Review link deleted successfully'
      }
    })
  })
});

export const {
  useLazyGetMerchantCustomizationsQuery,
  useUpdateMerchantCustomizationsMutation,
  useUpdateTNCMutation,
  useUpdateTNCStatusMutation,
  useLazyGetTermsSuggestionsQuery,
  useLazyGetInvoiceSummaryQuery,
  useGetMerchantCustomizationsQuery,
  useValidateReviewLinkMutation,
  useUpdateReviewLinkMutation,
  useDeleteReviewLinkMutation
} = DiscountTipsTaxesBaseApi;
