import { CircleCloseIcon } from 'assets/icons';
import { CustomModal } from 'components';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface FAQModalProps {
  onClose: VoidFn;
  FAQList: Record<string, string>[];
  title: string;
}

const FAQModal: FC<FAQModalProps> = ({ onClose, FAQList, title }) => {
  const renderFAQElem = (faq: Record<string, string>) => {
    return (
      <div>
        <div className="text-sbase font-semibold text-primaryText">{faq.question}</div>
        <div className="text-sbase text-primaryText">{faq.answer}</div>
      </div>
    );
  };

  return (
    <>
      <CustomModal open width="md:min-w-[375px]" onOutsideClick={onClose}>
        <div className="w-full py-7">
          <div className="flex items-center justify-between px-[30px] pr-[15px]">
            <div className="text-17px font-semibold text-headingBlack">{title}</div>
            <CircleCloseIcon className="cursor-pointer" onClick={onClose} />
          </div>
          <div className="px-[30px] py-5">
            <div className="space-y-5">{FAQList.map(item => renderFAQElem(item))}</div>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default FAQModal;
