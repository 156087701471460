import {
  ChevronArrowRightIcon,
  EnablePayouts75ProgressIcon,
  OrangeStrokeInfoIcon,
  RedStrokeInfoIcon
} from 'assets/icons';
import classnames from 'classnames';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { PaymentSystemTransitionType } from 'containers/home/constants';
import { ConnectBankStatuses, EnablePayoutStatuses, InternalBankStatuses } from 'containers/payouts/constants';
import { getEnablePayoutsIcon } from 'containers/payouts/utils';
import { useAppSelector } from 'hooks';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

const EnablePayoutStatusCard: FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { connectApplicationStatus, connectBankingStatus, internalBankingStatus, paymentSystemTransitionType } =
    currentTeam ?? {};

  const Icon =
    connectBankingStatus === ConnectBankStatuses.SUBMITTED ||
    internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_SUBMITTED
      ? EnablePayouts75ProgressIcon
      : getEnablePayoutsIcon(connectApplicationStatus);

  const actionNeededStatuses = [
    EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED,
    EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED
  ];

  const { titleText, subTitleText } =
    paymentSystemTransitionType === PaymentSystemTransitionType.LIPConnectMigration
      ? { titleText: 'Re-enable payouts', subTitleText: ' Connect another bank account' }
      : {
          titleText: 'Enable Payouts',
          subTitleText: 'Get verified and confirm your bank account.'
        };

  return (
    <div
      className={twMerge(
        classnames('flex w-full items-center rounded-md border-2 border-secondary bg-[#DBEFFD] p-[15px]', {
          'border-error bg-[#FDF9F2]': connectApplicationStatus === EnablePayoutStatuses.REJECTED,
          'border-2 border-pending-orange bg-[#FDF9F2]': actionNeededStatuses.includes(
            connectApplicationStatus as EnablePayoutStatuses
          )
        })
      )}>
      <Icon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-bold text-heading">{titleText}</div>
        <div className="text-sbase text-primaryText">{subTitleText}</div>
        {actionNeededStatuses.includes(connectApplicationStatus as EnablePayoutStatuses) && (
          <div className="flex items-center gap-1.5 text-sbase text-primaryText">
            <OrangeStrokeInfoIcon />
            Action needed
          </div>
        )}
        {connectApplicationStatus === EnablePayoutStatuses.REJECTED && (
          <div className="flex items-center gap-1.5 text-sbase text-error">
            <RedStrokeInfoIcon />
            Application denied
          </div>
        )}
      </div>
      <ChevronArrowRightIcon className="ml-auto shrink-0" />
    </div>
  );
};

export default EnablePayoutStatusCard;
