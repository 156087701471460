import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import {
  DisputeRelatedPayoutsResponse,
  DownloadDisputesPayload,
  DownloadEvidencePayload,
  GetDisputeByIdPayload,
  GetDisputeByIdResponse,
  GetDisputesPayload,
  GetDisputesResponse,
  GetEvidencesPayload,
  SubmitDisputeEvidencesPayload,
  UpdateDisputeStatusPayload,
  UploadEvidencePayload,
  UploadEvidenceResponse
} from './disputes.model';
import { getUrlSearchParams } from 'utils/apiUtils';
import { updateDisputeDetail, updateUploadedDocList } from './disputeSlice';
import { downloadFile } from 'utils/commonUtils';

const DisputesBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMerchantDisputes: builder.query<GetDisputesResponse, GetDisputesPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/disputes?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch merchant disputes',
        showToast: true
      }
    }),
    downloadDisputes: builder.query<GetDisputesResponse, DownloadDisputesPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/disputes/export/${params.type}?`;
        const urlParams = getUrlSearchParams(params.urlParams);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            if (response.status === 200) {
              await downloadFile({ response, fileType: params.type, fileName: 'disputes' });
            }
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'Something went wrong',
        success: 'Disputes exported successfully!',
        showToast: true
      }
    }),
    getMerchantDisputeById: builder.query<GetDisputeByIdResponse, GetDisputeByIdPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/disputes/${params.disputeId}?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(updateDisputeDetail(data.data.disputeDetail));
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('get dispute detail by id failed');
        }
      },
      extraOptions: {
        failure: 'Failed to fetch merchant dispute',
        showToast: true
      }
    }),
    uploadDisputeEvidenceFile: builder.mutation<UploadEvidenceResponse, UploadEvidencePayload>({
      query: ({ teamId, file, disputeId, type }) => {
        const formData = new FormData();
        formData.append('teamId', teamId.toString());
        formData.append('file', file);
        formData.append('disputeId', disputeId);
        formData.append('type', type);

        return {
          url: `${API_VERSION.V1}/disputes/evidences/upload`,
          method: 'POST',
          body: formData,
          headers: {
            // make sure not to set the Content-Type header.
            // The browser will set it for you, including the boundary parameter.
            // 'Content-Type': 'multipart/form-data'
          }
        };
      },

      extraOptions: {
        failure: 'We are unable to upload your image. Try again after sometime.',
        showToast: true
      }
    }),
    getEvidences: builder.query<any, GetEvidencesPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/disputes/${params.disputeId}/evidences?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(updateUploadedDocList(data.data));
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('get dispute detail by id failed');
        }
      }
    }),
    downloadEvidence: builder.query<any, DownloadEvidencePayload>({
      query: params => {
        const url = params.url;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response: response, fileName: params.fileName, isFileNameWithExtension: true });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'Failed to download document',
        success: 'Document downloaded successfully',
        showToast: true
      }
    }),
    updateDisputeStatus: builder.mutation<any, UpdateDisputeStatusPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/disputes/${params.disputeId}`;
        return {
          url: url,
          method: 'PATCH',
          body: params.body
        };
      },
      extraOptions: {
        failure: 'Failed to update dispute status',
        showToast: true
      }
    }),
    submitDisputeEvidences: builder.mutation<any, SubmitDisputeEvidencesPayload>({
      query: data => {
        const url = `${API_VERSION.V1}/disputes/evidences/submit`;
        return {
          url: url,
          method: 'POST',
          body: data
        };
      },
      extraOptions: {
        failure: 'Failed to submit evidences',
        showToast: true
      }
    }),
    getDisputeRelatedPayouts: builder.query<DisputeRelatedPayoutsResponse, GetDisputeByIdPayload>({
      query: ({ teamId, disputeId }) => {
        const url = `${API_VERSION.V1}/team/${teamId}/dispute/${disputeId}/payouts`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch dispute related payouts',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetMerchantDisputesQuery,
  useLazyDownloadDisputesQuery,
  useLazyGetMerchantDisputeByIdQuery,
  useUploadDisputeEvidenceFileMutation,
  useLazyGetEvidencesQuery,
  useLazyDownloadEvidenceQuery,
  useUpdateDisputeStatusMutation,
  useGetEvidencesQuery,
  useGetMerchantDisputeByIdQuery,
  useSubmitDisputeEvidencesMutation,
  useLazyGetDisputeRelatedPayoutsQuery
} = DisputesBaseApi;
