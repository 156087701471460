import { FC } from 'react';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';

interface LoaderProps {
  lottieClassName?: string;
}

const Loader: FC<LoaderProps> = ({ lottieClassName = 'h-28 w-28' }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <Lottie className={lottieClassName} animationData={LoadingSpinnerAnimation} loop={true} />
    </div>
  );
};

export default Loader;
