import { ArrowRightIcon, SearchWithBgIcon } from 'assets/icons';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface NoPermissionsProps {
  handleErrorBack?: VoidFn;
}

const NoPermissions: FC<NoPermissionsProps> = ({ handleErrorBack }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex w-full flex-col items-center justify-center gap-3 text-primaryText">
        <div>
          <SearchWithBgIcon />
        </div>
        <div className="text-sbase font-semibold">Required Permissions not found</div>
        <div className="text-center text-px13">
          Some permissions required to view this page are missing. <br />
          Please contact team owner or admin for more details.
        </div>
        {handleErrorBack && (
          <div className="flex cursor-pointer items-center gap-2" onClick={handleErrorBack}>
            <div className="rotate-180">
              <ArrowRightIcon />
            </div>
            <div className="text-sm text-primaryText">Go back</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NoPermissions;
