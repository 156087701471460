import { FC } from 'react';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface SaveChangesModalProps {
  className?: string;
  open: boolean;
  handleSave: VoidFn;
  cancelSave: VoidFn;
  isSaveInProgress?: boolean;
}
const SaveChangesModal: FC<SaveChangesModalProps> = ({ cancelSave, isSaveInProgress, open, handleSave }) => {
  return (
    <CustomModal width="md:w-96" open={open} closeModal={cancelSave}>
      <div className="w-full rounded-md p-10">
        <div className="flex flex-col items-center justify-center">
          <FormCloseReactIcon
            id="manage-team-save-modal-close"
            height="24"
            width="24"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={cancelSave}
          />
          <div className="flex w-full flex-col items-center justify-center">
            <p className="text-xl font-bold leading-6 text-heading">Save Changes?</p>
            <p className="mt-4 text-17px font-normal leading-5 text-primaryText">
              Do you want to save the latest changes?
            </p>
          </div>
          <CustomButton
            isLoading={isSaveInProgress}
            onClick={handleSave}
            id="save-changes-modal-save-button"
            className={`text-semibold mt-5 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
            Save
          </CustomButton>
          <CustomButton
            id="save-changes-modal-cancel-button"
            className="mt-6 h-[38px] w-full"
            type={ButtonType.SECONDARY}
            onClick={cancelSave}>
            Cancel
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
export default SaveChangesModal;
