import { ITaxBasic } from '../taxes/taxes.model';
import { AbstractIServiceChargePayload, IServiceCharge, IServiceChargeBasic } from './serviceCharge.model';
import { ServiceChargeSliceState } from './serviceChargeSlice';
import { ServiceChargeEnum } from './types';

export const prepareServiceChargePayload = (
  serviceChargeSlice: ServiceChargeSliceState,
  teamId: string
): AbstractIServiceChargePayload => {
  if (
    teamId &&
    serviceChargeSlice.serviceChargeName &&
    (serviceChargeSlice.serviceChargeAmount || serviceChargeSlice.serviceChargePercentage)
  ) {
    const payload: AbstractIServiceChargePayload = {
      teamId: teamId,
      name: serviceChargeSlice.serviceChargeName.trim(),
      ...(serviceChargeSlice.serviceChargeType === ServiceChargeEnum.CURRENCY
        ? { amount: serviceChargeSlice.serviceChargeAmount, percentage: null }
        : { percentage: serviceChargeSlice.serviceChargePercentage, amount: null }),
      taxes: serviceChargeSlice.taxes.length > 0 ? serviceChargeSlice.taxes.map(tax => tax.id) : []
    };
    return payload;
  }

  return null;
};

export const prepareTaxArrayForEvent = (serviceChargeTaxes: ITaxBasic[]) => {
  if (serviceChargeTaxes?.length > 0) {
    const taxes = serviceChargeTaxes.map(tax => {
      return {
        name: tax.name,
        percentage: tax.percentage,
        taxId: tax.id
      };
    });
    return taxes;
  }
  return [];
};

const getTaxIds = taxArray => {
  return taxArray.map(tax => tax.id);
};

const isTaxArrayEqual = (initialTaxArray: ITaxBasic[], updatedTaxArray: ITaxBasic[]) => {
  if (updatedTaxArray.length !== initialTaxArray.length) return false;
  const initialTaxIds = getTaxIds(initialTaxArray);
  const updatedTaxIds = getTaxIds(updatedTaxArray);
  if (JSON.stringify(initialTaxIds) === JSON.stringify(updatedTaxIds)) {
    return true;
  }
};

export const isServiceChargeDataUpdated = (
  initialServiceChargeData: ServiceChargeSliceState,
  updatedServiceChargeData: IServiceCharge = {
    id: null,
    name: '',
    amount: '',
    percentage: '',
    taxes: []
  }
) => {
  if (
    initialServiceChargeData.serviceChargeName === updatedServiceChargeData.name &&
    initialServiceChargeData.serviceChargePercentage === updatedServiceChargeData.percentage &&
    initialServiceChargeData.serviceChargeAmount === updatedServiceChargeData.amount &&
    isTaxArrayEqual(initialServiceChargeData.taxes, updatedServiceChargeData.taxes ?? [])
  )
    return false;
  return true;
};

export const sortServiceChargesAlphabetically = (serviceChargesList: IServiceChargeBasic[]): IServiceChargeBasic[] => {
  return serviceChargesList
    .slice()
    .sort((a, b) => a.name.trim().localeCompare(b.name.trim(), undefined, { sensitivity: 'base' }));
};

export const sortSelectedServiceChargesToStart = ({
  serviceChargesList = [],
  currentlySelectedServiceCharges = []
}: {
  serviceChargesList: IServiceChargeBasic[];
  currentlySelectedServiceCharges: IServiceChargeBasic[];
}) => {
  let sortedServiceChargesList = serviceChargesList;
  if (serviceChargesList.length > 0) {
    const selectedServiceChargesList = sortedServiceChargesList.filter(serviceCharge => {
      return currentlySelectedServiceCharges.some(
        selectedServiceCharge => selectedServiceCharge.id === serviceCharge.id
      );
    });
    const alphabeticallySortedSelectedTaxes = sortServiceChargesAlphabetically(selectedServiceChargesList);
    const unSelectedCatalogServiceCharges = sortServiceChargesAlphabetically(
      sortedServiceChargesList.filter(
        serviceCharge =>
          !currentlySelectedServiceCharges.some(selectedServiceCharge => selectedServiceCharge.id === serviceCharge.id)
      )
    );
    sortedServiceChargesList = [...alphabeticallySortedSelectedTaxes, ...unSelectedCatalogServiceCharges];
  }
  return sortedServiceChargesList;
};
