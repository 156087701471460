import React, { FC, createContext, useCallback, useEffect, useState } from 'react';

import { InvoiceTabEnum, minimumLineItemsError } from 'containers/invoices/create-invoice/constants';
import { GenericError, Loader } from 'components';
import { useCreateInvoiceMutation, useLazyGetInvoicePDFPreviewQuery } from 'containers/invoices/api';
import DateUtils from 'utils/dateUtils';
import { useAppDispatch, useAppSelector } from 'hooks';

import { useNavigate } from 'react-router-dom';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import {
  getAttachmentArray,
  getLineItemsArrayForEvent,
  getServiceChargeEventPayload,
  prepareDiscountRelatedPayload,
  prepareLineItemList,
  prepareServiceChargeListRequestPayload,
  prepareSummatedUniqueTaxList
} from 'containers/invoices/utils';
import { InvoicePayload, TaxesAndFeesResponse } from 'containers/invoices/invoices.model';
import { ErrorResponse } from 'containers/app/app.model';
import { APIErrorMsg, APIErrorStatuses } from 'services/type';
import { NotifierTypes } from 'constants/notifierConstants';
import { constructArrayObjectAsString, throttle } from 'utils/commonUtils';
import { InvoicesRouteState } from 'routes/types';
import routesPath from 'routes/RoutesPath';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsInvoices } from '../events';
import { InvoiceSourceEnum, InvoiceVersion } from '../constants';
import { invoiceMinimumAmount } from 'constants/common';
import BillToSection from '../components/BillToSection';
import InvoiceLineItems from '../components/InvoiceLineItems';
import NotesSection from '../components/NotesSection';
import TermsSection from '../components/TermsSection';
import AttachmentsSection from '../components/AttachmentsSection';
import TotalSection from '../components/TotalSection';
import { formatAmount } from 'utils/amountUtils';
import TeamDetailsSection from '../components/TeamDetailsSection';
import InvoiceHeader from '../components/InvoiceHeader';
import {
  addInvoiceValidationError,
  enableCustomerNotSelectedError,
  resetInvoiceState,
  selectAppliedLineItemLevelTaxes,
  selectDiscountFormData,
  selectDiscountSettings,
  selectInvoice,
  selectInvoiceAttachments,
  selectInvoiceCustomer,
  selectInvoiceFinalAmount,
  selectInvoiceLineItems,
  selectInvoiceNotes,
  selectInvoiceSubtotal,
  selectIsServiceFeeEnabled,
  selectIsSignatureEnabled,
  selectSelectedServiceCharge,
  updateInvoiceCreatedResponse
} from '../InvoicesSlice';
import { GetTeamProfileApiResponse } from 'containers/team-profile/teamProfile.model';

interface ICreateInvoiceContext {
  selectedInvoiceTab: InvoiceTabEnum;
  setSelectedInvoiceTab: React.Dispatch<React.SetStateAction<InvoiceTabEnum>>;
}

export const CreateInvoiceContext = createContext<ICreateInvoiceContext>({
  selectedInvoiceTab: InvoiceTabEnum.BILL_TO,
  setSelectedInvoiceTab: null
});

enum PageState {
  BUYER_VIEW = 'BUYER_VIEW',
  PDF_VIEW = 'PDF_VIEW'
}

interface BuyersPreviewProps {
  goBack: () => void;
  getTeamProfileData: GetTeamProfileApiResponse;
  taxesAndFees: TaxesAndFeesResponse;
}

const BuyersPreview: FC<BuyersPreviewProps> = ({ goBack, getTeamProfileData, taxesAndFees }) => {
  const [pageState, setPageState] = useState<PageState>(PageState.BUYER_VIEW);

  const [selectedInvoiceTab, setSelectedInvoiceTab] = useState(InvoiceTabEnum.BILL_TO);

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const [getInvoicePDFPreview, getInvoicePDFPreviewQueryState] = useLazyGetInvoicePDFPreviewQuery();
  const {
    data: getInvoicePDFPreviewResponse,
    isSuccess: getPreviewSuccess,
    isFetching: isPDFPreviewFetching,
    isError: isPDFPreviewError
  } = getInvoicePDFPreviewQueryState;

  const selectedServiceCharge = useAppSelector(selectSelectedServiceCharge);
  const selectedCustomerForInvoice = useAppSelector(selectInvoiceCustomer);
  const selectedLineItemsForInvoice = useAppSelector(selectInvoiceLineItems);
  const selectDiscountSettingsForInvoice = useAppSelector(selectDiscountSettings);
  const selectDiscountFormDataForInvoice = useAppSelector(selectDiscountFormData);
  const selectInvoiceAttachmentsForInvoice = useAppSelector(selectInvoiceAttachments);
  const selectInvoiceNotesForInvoice = useAppSelector(selectInvoiceNotes);
  const isServiceFeeEnabled = useAppSelector(selectIsServiceFeeEnabled);
  const selectedAppliedLineItemLevelTaxes = useAppSelector(selectAppliedLineItemLevelTaxes);
  const invoiceNumber = 'IN-XXXXX';

  const selectInvoiceSubtotalForInvoice = useAppSelector(selectInvoiceSubtotal);
  const selectInvoiceFinalAmountForInvoice = useAppSelector(selectInvoiceFinalAmount);
  const selectedInvoice = useAppSelector(selectInvoice);
  const selectedIsSignatureEnabled = useAppSelector(selectIsSignatureEnabled);

  const currentTeam = useAppSelector(selectCurrentTeam);

  const dispatch = useAppDispatch();

  const [
    createInvoiceMutation,
    {
      isSuccess: isCreateInvoiceSuccess,
      data: invoiceCreatedResponse,
      isError: isCreateInvoiceError,
      error: createInvoiceError
    }
  ] = useCreateInvoiceMutation();

  useEffect(() => {
    if (isCreateInvoiceSuccess) {
      dispatch(resetInvoiceState());
      dispatch(updateInvoiceCreatedResponse(invoiceCreatedResponse));
      const invoiceRouteState: InvoicesRouteState = {
        showShareModal: true,
        isEditInvoiceShare: false,
        showSidePanel: false
      };
      navigate(routesPath.INVOICES, { replace: true, state: invoiceRouteState });
    }
  }, [isCreateInvoiceSuccess]);

  useEffect(() => {
    if (isCreateInvoiceError) {
      const error = createInvoiceError as ErrorResponse;

      if (error.status === APIErrorStatuses.STATUS_422) {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: error.data.message,
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      } else if (error.status === APIErrorStatuses.STATUS_429) {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: APIErrorMsg.LIMIT_EXCEEDED,
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      } else {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: 'Failed to create invoice',
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      }
    }
  }, [isCreateInvoiceError]);

  const onCreateAndShare = () => {
    let isPageIncomplete = false;
    if (selectedInvoice.invoiceLineItems.length === 0) {
      isPageIncomplete = true;
      dispatch(addInvoiceValidationError(minimumLineItemsError));
    }
    if (selectedInvoice.invoiceCustomer === null) {
      isPageIncomplete = true;
      dispatch(enableCustomerNotSelectedError());
    }
    if (selectedInvoice.invoiceSubTotal < +currentTeam?.minimumAmount ?? invoiceMinimumAmount) {
      isPageIncomplete = true;
      dispatch(
        showNotifier({
          message: {
            primaryMessage: `Total amount must be at least $${currentTeam?.minimumAmount ?? invoiceMinimumAmount}`
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
    }

    if (isPageIncomplete) {
      return;
    }

    const serviceChargeListPayload = prepareServiceChargeListRequestPayload(selectedInvoice.selectedServiceCharge);
    const invoicePayload: InvoicePayload = {
      version: InvoiceVersion.THREE_POINT_ZERO,
      teamId: currentTeam.id.toString(),
      storeName: currentTeam.storeName,
      amount: selectedInvoice?.invoiceSubTotal.toString(),
      comment: selectedInvoice.comment,
      customerId: selectedInvoice?.invoiceCustomer?.ID,
      phoneNumber: selectedInvoice?.invoiceCustomer?.phoneNumber,
      email: selectedInvoice?.invoiceCustomer?.email,
      customerName: selectedInvoice?.invoiceCustomer?.name,
      billingAddress: selectedInvoice?.invoiceCustomer?.billingAddress,
      lineItems: prepareLineItemList(selectedInvoice.invoiceLineItems),
      ...prepareDiscountRelatedPayload(selectedInvoice.discountFormData),
      attachments: getAttachmentArray(selectedInvoice),
      tipEnabled: selectedInvoice.tipEnabled,
      surchargePercentage: selectedInvoice.isServiceFeeEnabled
        ? selectedInvoice.currentSurcharge.percentageWithBps ?? '0'
        : '0',
      isServiceFeeEnabled: selectedInvoice.isServiceFeeEnabled,
      tncId: taxesAndFees.tnc.tncId,
      ...prepareSummatedUniqueTaxList({
        appliedLineItemLevelTaxes: selectedInvoice.appliedLineItemLevelTaxes,
        invoiceLineItems: selectedInvoice.invoiceLineItems,
        selectedServiceCharge: selectedInvoice.selectedServiceCharge
      }),
      serviceCharges: serviceChargeListPayload,
      serviceChargeAmount: serviceChargeListPayload?.length > 0 ? serviceChargeListPayload[0].totalAmount : '0',
      source: InvoiceSourceEnum.WEB,
      isSignatureEnabled: selectedIsSignatureEnabled
    };

    const serviceChargeEventPayload = getServiceChargeEventPayload(serviceChargeListPayload);
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesCreateInvoiceSave, {
      customerName: selectedInvoice?.invoiceCustomer?.name,
      customerId: selectedInvoice?.invoiceCustomer?.ID,
      teamId: currentTeam.id.toString(),
      storeName: currentTeam.storeName,
      amount: selectedInvoice.invoiceSubTotal.toString(),
      'Line Items': constructArrayObjectAsString(
        getLineItemsArrayForEvent(selectedInvoice.invoiceLineItems),
        'Line item'
      ),
      ...prepareDiscountRelatedPayload(selectedInvoice.discountFormData),
      surchargePercentage: selectedInvoice.isServiceFeeEnabled
        ? selectedInvoice.currentSurcharge.percentageWithBps ?? '0'
        : '0',
      ...(serviceChargeEventPayload?.length > 0 ? { serviceCharges: serviceChargeEventPayload } : {})
    });
    createInvoiceMutation(invoicePayload);
  };

  const createAndShareHandler = useCallback(throttle(onCreateAndShare, 800), [selectedInvoice]);

  useEffect(() => {
    if (getPreviewSuccess) {
      const pdf = new Blob([getInvoicePDFPreviewResponse], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdf);
      setPreviewUrl(url);
    }
  }, [getPreviewSuccess]);

  const renderDate = () => {
    let date = DateUtils.getToday();
    date = DateUtils.getDateInFormat({ date: new Date(date), dateFormat: 'dd MMM yyyy' });
    return date;
  };

  const navigate = useNavigate();

  const onPDFPreviewClick = () => {
    if (!previewUrl) {
      const serviceChargeListPayload = prepareServiceChargeListRequestPayload(selectedInvoice.selectedServiceCharge);
      const invoicePayloadPreview: any = {
        version: InvoiceVersion.THREE_POINT_ZERO,
        invoiceNumber: invoiceNumber,
        teamId: currentTeam.id.toString(),
        storeName: currentTeam.storeName,
        amount: selectedInvoice.invoiceSubTotal.toString(),
        comment: selectedInvoice.comment,
        customerId: selectedInvoice?.invoiceCustomer?.ID,
        lineItems: prepareLineItemList(selectedInvoice.invoiceLineItems),
        discount:
          selectedInvoice.discountFormData.discountInCurrency === '0'
            ? null
            : selectedInvoice.discountFormData.discountInCurrency,
        discountPercentage: selectedInvoice.discountFormData.discountPercent,
        attachments: getAttachmentArray(selectedInvoice),
        tipEnabled: selectedInvoice.tipEnabled,
        surchargePercentage: selectedInvoice.isServiceFeeEnabled
          ? selectedInvoice.currentSurcharge.percentageWithBps ?? '0'
          : '0',
        timeStamp: new Date(),
        tncID: taxesAndFees.tnc.enabled ? taxesAndFees.tnc.tncId : 0,
        createdDate: new Date(),
        ...prepareSummatedUniqueTaxList({
          appliedLineItemLevelTaxes: selectedInvoice.appliedLineItemLevelTaxes,
          invoiceLineItems: selectedInvoice.invoiceLineItems,
          selectedServiceCharge: selectedInvoice.selectedServiceCharge
        }),
        serviceCharges: serviceChargeListPayload,
        serviceChargeAmount: serviceChargeListPayload?.length > 0 ? serviceChargeListPayload[0].totalAmount : '0',
        isInvoiceSignatureEnabled: selectedIsSignatureEnabled
      };
      getInvoicePDFPreview(invoicePayloadPreview);
    }
    setPageState(PageState.PDF_VIEW);
  };

  return (
    <CreateInvoiceContext.Provider value={{ selectedInvoiceTab, setSelectedInvoiceTab }}>
      <div className="flex h-full w-full flex-col border">
        <InvoiceHeader
          title="Create & Share"
          pageState={pageState}
          setPageState={setPageState}
          onPDFPreviewClick={onPDFPreviewClick}
          editAndShareHandler={createAndShareHandler}
          goBack={goBack}
        />

        {pageState === PageState.BUYER_VIEW && (
          <div className="flex grow gap-8 bg-borderGray px-[149px] pt-8 pb-[102px]">
            <div className="flex w-[675px] flex-col gap-[30px]">
              <BillToSection customer={selectedCustomerForInvoice} />

              <InvoiceLineItems
                items={selectedLineItemsForInvoice}
                billAmount={selectInvoiceSubtotalForInvoice}
                surChargeAmount={+selectedInvoice.currentSurcharge?.valueInCurrencyWithBps ?? 0}
                serviceFeeEnabled={isServiceFeeEnabled}
                serviceCharges={selectedServiceCharge}
                taxes={selectedAppliedLineItemLevelTaxes}
                discountData={selectDiscountFormDataForInvoice}
                totalAmount={selectInvoiceFinalAmountForInvoice}
                selectDiscountSettingsForInvoice={selectDiscountSettingsForInvoice}
              />

              {selectInvoiceNotesForInvoice && <NotesSection notes={selectInvoiceNotesForInvoice} />}

              {taxesAndFees.tnc.enabled && <TermsSection terms={taxesAndFees.tnc.tnc} />}

              {selectInvoiceAttachmentsForInvoice && selectInvoiceAttachmentsForInvoice.length > 0 && (
                <AttachmentsSection attachments={selectInvoiceAttachmentsForInvoice} />
              )}
            </div>
            <div className="flex w-[435px] flex-col gap-[30px]">
              <TotalSection
                total={formatAmount(selectInvoiceFinalAmountForInvoice)}
                dueDate={renderDate()}
                invoiceDate={renderDate()}
              />
              <TeamDetailsSection
                teamName={currentTeam.name}
                teamPhone={getTeamProfileData.phone}
                teamEmail={getTeamProfileData.email}
                teamBillingAddress={getTeamProfileData.address}
                teamWebsite={getTeamProfileData.website}
              />
            </div>
          </div>
        )}

        {pageState === PageState.PDF_VIEW && (
          <>
            {previewUrl && (
              <div>
                <object
                  style={{
                    height: window.innerHeight,
                    width: window.innerWidth
                  }}
                  data={`${previewUrl}#toolbar=0`}
                  type="application/pdf"></object>
              </div>
            )}
            {isPDFPreviewFetching && <Loader />}
            {isPDFPreviewError && <GenericError handleErrorBack={() => setPageState(PageState.BUYER_VIEW)} />}
          </>
        )}
      </div>
    </CreateInvoiceContext.Provider>
  );
};

export default BuyersPreview;
