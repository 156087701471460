import React, { useEffect, useState } from 'react';
import {
  DefaultBankIcon,
  InstantPaymentIcon,
  OrangeInfoIcon,
  RedInfoIcon,
  UpcomingPayoutsBankIcon
} from 'assets/icons';
import { Divider } from '@material-ui/core';
import {
  EnablePayoutStatuses,
  InternalBankStatuses,
  PaymentSystem,
  PayoutScheduleType,
  PayoutType
} from '../constants';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamInvoicePermissions,
  selectPaymentSystemConfig,
  selectTeamBankDetails,
  showNotifier
} from 'containers/app/appSlice';
import NoPermissions from 'components/no-permissions/NoPermissions';
import EnablePayoutsPopup from './components/EnablePayoutsPopup';
import EnablePayoutStatusCard from './components/EnablePayoutStatusCard';
import StripeFCModal from './components/StripeFCModal';
import { useLocation, useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { logAnalyticEvent } from 'utils/analytics';
import { PayoutsCleverTapEvents } from '../events';
import {
  useLazyGetMerchantTeamsQuery,
  useLazyGetPaymentSystemConfigQuery,
  useLazyGetTeamBankAccountQuery
} from 'containers/app/api';
import { Loader } from 'components';
import { TeamRole } from 'containers/app/app.model';
import { NotifierTypes } from 'constants/notifierConstants';
import { InfoMesssages } from 'types/infoMessages';
import CollectBankStatement from './components/CollectBankStatementPopup';
import ChevronArrowRightReactIcon from 'assets/react-icons/ChevronArrowRightReactIcon';
import AddBankAccountPopup from './components/AddBankAccountPopup';
import AddManualBankAccount from './components/AddManualBankAccountModal';
import ActionNeededPopup from './components/ActionNeededPopup';
import MigrationModal from 'containers/home/bank-migration/components/MigrationModal';
import { MIGRATION_ROUTE_STATE, PaymentSystemTransitionType } from 'containers/home/constants';
import { MigrationRoutes } from 'containers/home/bank-migration/constants';
import { ONBOARDING_URL_SOURCES } from 'constants/common';
import { openScanpayFAQInNewTab } from 'utils/urlUtils';

const PayoutSettingsPage = () => {
  const { payoutSchedule, bankingEnabled, payoutType, paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const currentTeamBankAccount = useAppSelector(selectTeamBankDetails);
  const { accountNumber, bankIcon, bankName, isScanpayAccount, routingNumber } = currentTeamBankAccount ?? {};
  const { connectApplicationStatus } = useAppSelector(selectCurrentTeam) ?? {};
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const [isPayoutStatusPopupOpen, setIsPayoutStatusPopupOpen] = useState(false);
  const [openFCModal, setOpenFCModal] = useState(false);
  const [openBankStatementModal, setOpenBankStatementModal] = useState(false);
  const [openAddBankAccountModal, setOpenAddBankAccountModal] = useState(false);
  const [openManualAccountModal, setOpenManualAccountModal] = useState(false);
  const [openActionNeededPopup, setOpenActionNeededPopup] = useState(false);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [getPaymentSystemConfigQuery, getPaymentSystemConfigState] = useLazyGetPaymentSystemConfigQuery();
  const { isFetching: isFetchingPaymentSystemConfig } = getPaymentSystemConfigState;
  const [getMerchantTeamsQuery, getMerchantTeamsState] = useLazyGetMerchantTeamsQuery();
  const { isFetching: isFetchingTeams } = getMerchantTeamsState;
  const [getTeamBankAccountQuery, getTeamBankAccountState] = useLazyGetTeamBankAccountQuery();
  const { isFetching: isFetchingBankData } = getTeamBankAccountState;
  const [isFromFCModal, setIsFromFCModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const notifications = useAppSelector(getNotifications);

  useEffect(() => {
    const routeState = (location.state as MIGRATION_ROUTE_STATE) ?? {};
    if (routeState.openConnectBankModal) {
      setIsPayoutStatusPopupOpen(true);
      navigate(routesPath.PAYOUTS_SETTINGS, { replace: true, state: {} });
    }
  }, [location]);

  useEffect(() => {
    getMerchantTeamsQuery();
    if (currentTeam.id) {
      getPaymentSystemConfigQuery(currentTeam.id);
      getTeamBankAccountQuery(currentTeam.id);
    }
  }, []);

  useEffect(() => {
    if (payoutPermissions.viewPayoutsByTeam) {
      setHasPermissionsToView(true);
    } else {
      setHasPermissionsToView(false);
    }
  }, [payoutPermissions.viewPayoutsBySelf, payoutPermissions.viewPayoutsByTeam]);

  const handleEnablePayoutsClick = () => {
    if (currentTeam.role === TeamRole.OWNER) {
      if (paymentSystem === PaymentSystem.CA) {
        toggleEnablePayoutsPopup();
      } else if (paymentSystem === PaymentSystem.LIP) {
        navigate(routesPath.BANKING);
      }
      logAnalyticEvent(PayoutsCleverTapEvents.webPayoutsEnablePayoutsClick, {
        paymentSystem: paymentSystem,
        ...(connectApplicationStatus ? { connectApplicationStatus } : {})
      });
    } else {
      const notifId = 'disable-onboarding';
      const existingNotification = notifications.find(notification => notification.id === notifId);
      if (existingNotification) return;
      dispatch(
        showNotifier({
          id: notifId,
          message: {
            primaryMessage: InfoMesssages.genericNoPermissionMessage,
            secondaryMessage: '',
            isMessageHtml: true
          },
          type: NotifierTypes.WARNING,
          showClose: true,
          fontStyle: 'text-primary font-normal'
        })
      );
    }
  };

  const toggleEnablePayoutsPopup = () => {
    setIsPayoutStatusPopupOpen(prev => !prev);
  };

  const handleBackAccountClick = () => {
    logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsAddBankAccountClick, {
      ...(connectApplicationStatus ? { connectApplicationStatus } : {})
    });
    setOpenAddBankAccountModal(true);
  };

  const handleGetStartedClick = () => {
    if (currentTeam?.internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_REJECTED) {
      setOpenManualAccountModal(true);
    } else if (currentTeam?.internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_NOT_SUBMITTED) {
      setOpenBankStatementModal(true);
    }
    setOpenActionNeededPopup(false);
  };

  const handleActionNeededClick = () => {
    setIsPayoutStatusPopupOpen(false);
    setOpenActionNeededPopup(true);
  };

  const onBankAccountModalBackClick = () => {
    closeAddBankAccountModal();
    setIsPayoutStatusPopupOpen(true);
  };

  const closeAddBankAccountModal = () => {
    setOpenAddBankAccountModal(false);
  };

  const handleOpenFCModal = () => {
    setOpenFCModal(true);
    closeAddBankAccountModal();
  };

  const handleOpenManualModal = () => {
    setOpenManualAccountModal(true);
    closeAddBankAccountModal();
  };

  const handleCloseFCModal = () => {
    setOpenFCModal(false);
    setIsFromFCModal(true);
  };

  const onManualAccountBack = () => {
    setOpenManualAccountModal(false);
    if (currentTeam?.internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_REJECTED) {
      setOpenActionNeededPopup(true);
    } else setOpenAddBankAccountModal(true);
  };

  const handleFAQClick = () => {
    openScanpayFAQInNewTab(PayoutsCleverTapEvents.webPayoutsSettingsFAQClick);
  };

  const getAboutSectionText = () => {
    if (payoutSchedule === PayoutScheduleType.OFF)
      return {
        text: 'Your payout schedule is how often ScanPay sends money to your linked bank account.',
        subText: ''
      };

    if (payoutType === PayoutType.MANUAL)
      return {
        text: `Your payout schedule is how often ScanPay sends money to your linked bank account.
       You are currently receiving payouts on a manual schedule.`,
        subText: 'For additional information, please contact support@goscanpay.com'
      };

    if (payoutSchedule === PayoutScheduleType.INSTANT)
      return {
        text: 'Your payout schedule is how often ScanPay sends money to your linked bank account.',
        subText: 'You are currently receiving instant payouts after every payment.'
      };

    if (payoutSchedule === PayoutScheduleType.DAILY)
      return {
        text: 'Your payout schedule is how often ScanPay sends money to your linked bank account.',
        subText: `For automatic daily payouts, ScanPay sends a payout for all transactions
        occurred before 7pm ET for the day. Payouts are sent via standard ACH which reaches 
        your bank account within 2 business days.`
      };
  };

  const getPayoutScheduleText = () => {
    if (payoutType === PayoutType.MANUAL)
      return (
        <>
          <span className="font-normal text-primaryText">Your bank account will receive</span>
          &nbsp;
          <span className="font-bold text-headingGray">manually</span>
          &nbsp;
          <span className="font-normal text-primaryText">scheduled payouts.</span>
        </>
      );
    if (payoutSchedule === PayoutScheduleType.INSTANT)
      return (
        <>
          <span className="font-normal text-primaryText">Your bank account will receive payouts</span>
          &nbsp;
          <span className="font-bold text-headingGray">instantly.</span>
        </>
      );
    if (payoutSchedule === PayoutScheduleType.DAILY)
      return (
        <>
          <span className="font-normal text-primaryText">Your bank account will receive payouts</span>
          &nbsp;
          <span className="font-bold text-headingGray">automatically.</span>
        </>
      );
  };

  const { subText: aboutSectionSubText, text: aboutSectionText } = getAboutSectionText() ?? {};

  const isFetching = isFetchingBankData && isFetchingPaymentSystemConfig && isFetchingTeams;

  const hasPendingStepsinKyc =
    currentTeam?.connectApplicationStatus === EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED ||
    currentTeam?.connectApplicationStatus === EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED ||
    currentTeam?.connectApplicationStatus === EnablePayoutStatuses.SUBMITTED_IN_REVIEW;

  return (
    <div className="flex h-full w-full flex-col overflow-auto pt-6 pb-10 pl-7">
      <div className="flex w-full flex-1 flex-col gap-5">
        <div className="text-3xl font-bold text-primaryText">Payout settings</div>

        {isFetching ? (
          <Loader />
        ) : (
          <>
            {hasPermissionsToView ? (
              <>
                <div className="text-sbase">
                  The settings page allows you to manage the preferences of below features.
                </div>
                <Divider className="w-full bg-borderGray" />
                <div className="flex flex-col gap-[30px] pr-20 lg:w-1/2 lg:pr-0">
                  <div className="flex flex-col gap-5">
                    <div className="text-xl font-bold text-primaryText">Manage bank account</div>
                    {bankingEnabled ? (
                      <>
                        <div className="text-sbase font-normal text-primaryText">
                          We'll send your payouts to this bank account.
                        </div>
                        <div className="flex max-w-[315px] flex-col gap-2.5">
                          <div className="flex items-center gap-2.5">
                            <div className="h-[30px] w-[30px] rounded-[4px] border border-borderGray object-cover">
                              {bankIcon ? <img src={bankIcon} height={30} width={30} /> : <DefaultBankIcon />}
                            </div>
                            {isScanpayAccount ? (
                              <div className="flex flex-col">
                                <div className="text-sbase font-semibold text-tertiaryText">ScanPay</div>
                                <div className="text-px13 uppercase text-accentText">{bankName}</div>
                              </div>
                            ) : (
                              <div className="text-sbase font-semibold text-tertiaryText">{bankName}</div>
                            )}
                          </div>
                          <div className="flex w-full justify-between">
                            <div className="text-sbase font-semibold text-accentText">Account number</div>
                            <div className="fot-semibold text-sbase text-tertiaryText">{accountNumber}</div>
                          </div>
                          <div className="flex w-full justify-between">
                            <div className="text-sbase font-semibold text-accentText">Routing number</div>
                            <div className="fot-semibold text-sbase text-tertiaryText">{routingNumber}</div>
                          </div>
                        </div>
                        {hasPendingStepsinKyc && (
                          <div
                            className="flex max-w-[315px] cursor-pointer items-center justify-between gap-2
                    rounded-md bg-fallingBackground px-4 py-2.5"
                            role="presentation"
                            onClick={toggleEnablePayoutsPopup}>
                            <div className="flex items-center gap-2 text-sbase text-primaryText">
                              <RedInfoIcon />
                              Action required
                            </div>
                            <div className="flex items-center gap-1 text-px13 text-primaryText">
                              View details
                              <ChevronArrowRightReactIcon id="action-needed" />
                            </div>
                          </div>
                        )}
                      </>
                    ) : (
                      <>
                        <div className="max-w-[315px] cursor-pointer" onClick={handleEnablePayoutsClick}>
                          <EnablePayoutStatusCard />
                        </div>
                      </>
                    )}
                  </div>
                  <Divider className="w-full bg-borderGray" />
                  <div className="text-xl font-bold text-primaryText">Payout schedule</div>
                  {bankingEnabled ? (
                    <>
                      {payoutSchedule === PayoutScheduleType.OFF ? (
                        <div
                          className="bg-pending-ornage/20 flex max-w-[315px] gap-3 
                                  rounded-md border border-pending-orange py-4 pl-3">
                          <OrangeInfoIcon className="h-9 w-9 shrink-0" />
                          <div className="text-sbase text-primaryText">
                            Your payouts are currently paused. Please contact customer support for more details.
                          </div>
                        </div>
                      ) : (
                        <div className="flex flex-col gap-5">
                          <div className="text-sbase">{getPayoutScheduleText()}</div>
                          <div className="flex flex-col gap-2.5">
                            <div className="text-sbase font-semibold text-primaryText ">
                              {payoutType === PayoutType.AUTO ? 'Automatic' : 'Manual'}
                            </div>
                            <div className="flex w-full max-w-[315px] rounded-md border border-borderGray p-3">
                              {payoutSchedule === PayoutScheduleType.INSTANT ? (
                                <>
                                  <InstantPaymentIcon className="shrink-0" />
                                  <div className="ml-2.5 text-sbase font-semibold text-accentText">Instant</div>
                                </>
                              ) : (
                                <div className="text-sbas font-normal text-accentText">Every business day</div>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex max-w-[315px] gap-3 rounded-md border border-pending-orange py-4 px-3">
                      <UpcomingPayoutsBankIcon className="shrink-0" />
                      <div className="text-sbase text-primaryText">
                        Your payouts will be scheduled once you have linked your bank account.
                      </div>
                    </div>
                  )}
                  <div className="flex flex-col gap-5">
                    <div className="flex flex-col gap-2.5 pr-5">
                      <div className="text-17px font-semibold text-primaryText">About your Payout Schedule</div>
                      <div className="text-sbase font-normal text-primaryText">{aboutSectionText}</div>
                      <div className="text-sbase font-normal text-primaryText">{aboutSectionSubText}</div>
                    </div>
                    <Divider className="w-full bg-borderGray lg:max-w-[315px]" />
                    <div className="cursor-pointer text-sbase font-semibold text-primaryBtn" onClick={handleFAQClick}>
                      View FAQs on our website →
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <NoPermissions />
            )}
          </>
        )}
      </div>
      {isPayoutStatusPopupOpen &&
        (currentTeam.paymentSystemTransitionType === PaymentSystemTransitionType.LIPConnectMigration ? (
          <MigrationModal
            onClose={toggleEnablePayoutsPopup}
            defaultPage={MigrationRoutes.MIGRATION_CA}
            onOpenFCModal={handleOpenFCModal}
            handleFaqClick={() => null}
            isFromFCModal={isFromFCModal}
            from={ONBOARDING_URL_SOURCES.PAYOUT_SETTINGS}
          />
        ) : (
          <EnablePayoutsPopup
            onClose={toggleEnablePayoutsPopup}
            onLinkBankAccount={handleBackAccountClick}
            onActionNeededClick={handleActionNeededClick}
          />
        ))}
      {openFCModal && <StripeFCModal onClose={handleCloseFCModal} />}
      {openBankStatementModal && <CollectBankStatement onClose={() => setOpenBankStatementModal(false)} />}
      {openManualAccountModal && (
        <AddManualBankAccount onClose={() => setOpenManualAccountModal(false)} handleBackClick={onManualAccountBack} />
      )}
      {openAddBankAccountModal && (
        <AddBankAccountPopup
          onClose={closeAddBankAccountModal}
          openFCModal={handleOpenFCModal}
          openManualModal={handleOpenManualModal}
          handleBackClick={onBankAccountModalBackClick}
        />
      )}
      {openActionNeededPopup && (
        <ActionNeededPopup onClose={() => setOpenActionNeededPopup(false)} onGetStartedClick={handleGetStartedClick} />
      )}
    </div>
  );
};

export default PayoutSettingsPage;
