import Divider from '@material-ui/core/Divider';
import { CheckboxTickIcon } from 'assets/icons';
import classnames from 'classnames';
import { CustomCheckbox, If } from 'components';
import { PaymentSystem } from 'containers/payouts/constants';
import { ConnectPayout } from 'containers/payouts/payouts.model';
import { getPayoutStatusData, getSubText } from 'containers/payouts/utils';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BoolFn, VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import DateUtils from 'utils/dateUtils';

interface PayoutsTableProps {
  payoutList: ConnectPayout[];
  onToggleSelectAllLoadedPayouts?: VoidFn;
  areAllLoadedPayoutsSelected?: BoolFn;
  isSelectedPayout?: (payout: ConnectPayout) => string;
  onTogglePayoutSelection?: (payout: ConnectPayout, isCheckdPayout: boolean) => void;
  onPayoutClick: (id: string, type: PaymentSystem) => void;
  currentSelectedId?: string;
}

const tableHeaders: Record<string, Record<string, string>> = {
  date: { title: 'Date', width: 'w-1/4' },
  payoutId: { title: 'Payout ID', width: 'w-[30%]' },
  status: { title: 'Status', width: 'w-1/4' },
  amount: { title: 'Amount', width: 'w-[17.5%]' },
  expectedBy: { title: 'Expected by', width: 'w-1/4' }
};

const PayoutsListTable: FC<PayoutsTableProps> = ({
  payoutList,
  onToggleSelectAllLoadedPayouts,
  areAllLoadedPayoutsSelected,
  onTogglePayoutSelection,
  isSelectedPayout,
  onPayoutClick,
  currentSelectedId
}) => {
  const { rootPayoutId } = useParams();

  return (
    <div className="relative">
      <table className="w-full table-auto">
        <thead className="sticky top-[-4px] z-30 bg-primary">
          <Divider className="absolute top-[3.7rem] w-full bg-borderGray" />
          <tr className="text-slateGrey text-left text-xs lg:text-base [&>*]:py-5">
            <th className="w-[2.5%]">
              <CustomCheckbox
                id="select-all-payouts-checkbox"
                onClick={onToggleSelectAllLoadedPayouts}
                isChecked={areAllLoadedPayoutsSelected()}>
                <If condition={areAllLoadedPayoutsSelected()}>
                  <CheckboxTickIcon />
                </If>
              </CustomCheckbox>
            </th>
            {Object.values(tableHeaders).map((header, index) => {
              return (
                <th
                  id={`payout-table-${header.title}-head`}
                  className={`max-w-[120px] text-sbase font-semibold text-primaryText ${header.width}`}
                  key={header.title + index}>
                  {header.title}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {payoutList?.map((payout, index) => (
            <tr
              key={payout.id}
              id={`payout-table-row-${index + 1}`}
              className={`remove-highlight cursor-pointer border-b border-secondaryBtn [&>*]:py-5 [&>*]:pr-2
              ${currentSelectedId === payout.id || rootPayoutId === payout.id ? 'bg-secondaryBtn' : ''}`}
              onClick={() => onPayoutClick(payout.id, payout.paymentSystem as PaymentSystem)}>
              {/* checkbox */}
              <td className="w-8">
                <CustomCheckbox
                  id={`${payout.id}-checkbox`}
                  onClick={() => onTogglePayoutSelection(payout, Boolean(isSelectedPayout(payout)))}
                  isChecked={Boolean(isSelectedPayout(payout))}>
                  <If condition={Boolean(isSelectedPayout(payout))}>
                    <CheckboxTickIcon />
                  </If>
                </CustomCheckbox>
              </td>
              {/* createdAt */}
              <td className="max-w-[120px]">
                <div id={`payout-table-row-${index + 1}-date`} className="truncate text-sbase text-primaryText">
                  <div>
                    {DateUtils.getDateInFormat({ date: new Date(payout.createdAt), dateFormat: 'dd MMM yyyy' })}
                  </div>
                </div>
              </td>
              <td className="max-w-[120px]">
                <div className="text-sbase font-semibold text-primaryText">
                  <div className="truncate">{payout.id}</div>
                </div>
              </td>
              {/* payout status */}
              <td className="max-w-[120px]">
                <div id={`payout-table-row-${index + 1}-status`} className="flex flex-col gap-2">
                  <div
                    id={`status-value-row-${index + 1}`}
                    className={twMerge(classnames('text-sbase font-bold'))}
                    style={{ color: getPayoutStatusData({ payoutStatus: payout.status }).textColor }}>
                    {getPayoutStatusData({ payoutStatus: payout.status }).text}
                  </div>
                  <div id={`status-day-row-${index + 1}`} className="text-px13 font-normal text-primaryText">
                    {getSubText({
                      payoutStatus: payout.status,
                      isLIP: payout.paymentSystem === PaymentSystem.LIP,
                      arrivalAt: DateUtils.getDateInFormatWtTimeZone({
                        date: new Date(payout.arrivalAt),
                        timeZone: 'UTC',
                        dateFormat: 'dd MMM yyyy'
                      }),
                      createdAt: DateUtils.getDateInFormat({
                        date: new Date(payout.createdAt),
                        dateFormat: 'dd MMM yyyy'
                      }),
                      updatedAt: DateUtils.getDateInFormat({
                        date: new Date(payout.updatedAt),
                        dateFormat: 'dd MMM yyyy'
                      })
                    })}
                  </div>
                </div>
              </td>
              {/* amount */}
              <td className="max-w-[120px]">
                <div className="flex flex-col gap-2">
                  <div
                    className="truncate text-sbase font-semibold
                    text-primaryText">
                    {getAmountWithCurrency(payout.amount)}
                  </div>
                </div>
              </td>
              {/* expectedBY */}
              <td className="max-w-[120px]">
                <div id={`payout-table-row-${index + 1}-date`} className="truncate text-sbase text-primaryText">
                  <div>
                    {DateUtils.getDateInFormatWtTimeZone({
                      date: new Date(payout.arrivalAt),
                      dateFormat: 'dd MMM yyyy',
                      timeZone: 'UTC'
                    })}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PayoutsListTable;
