export enum InvoiceSharePageState {
  ShowSharePage = 'ShowSharePage',
  ShowQrOnlyPage = 'ShowQrOnlyPage',
  ShareStatusPage = 'ShareStatusPage',
  ShareMainPage = 'ShareMainPage'
}

export enum SharePanelType {
  Receipt = 'receipt',
  Invoice = 'invoice',
  Refund = 'refund receipt'
}

export interface LineItemAdditionalTax {
  id: number;
  name: string;
  percentage: string;
  amount?: number;
}

export enum DiscountEnum {
  CURRENCY = '$',
  PERCENTAGE = '%'
}

export enum InvoiceDiscountInputId {
  invoiceDiscountAmount = 'invoice-discount-amount',
  invoiceDiscountPercentage = 'invoice-discount-percent'
}

export interface DiscountEntity {
  type: DiscountEnum;
  value: string;
  id: InvoiceDiscountInputId;
  amount?: string;
  percentage?: string;
}

export enum DiscountInputErrors {
  Amount_Error = 'Please enter a valid amount',
  Percentage_Error = 'Please enter a valid percentage'
}

export interface DiscountFormData {
  discountPercent: string;
  discountAmount: string;
  isDiscountAmountSelected: boolean;
  discountInCurrency: string; // final discount value
  amountAfterDiscount: string;
}
