import Lottie from 'lottie-react';
import { FC } from 'react';
import Divider from '@material-ui/core/Divider';

import { LoadingSpinnerAnimation } from 'assets/animations';
import { DeleteIcon, ExportIcon, InviteSentIcon } from 'assets/icons';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { ConfettyBg } from 'assets/images';
import { ITeamMember } from '../manageTeam.model';
import { VoidFn } from 'types/baseTypes';
import DateUtils from 'utils/dateUtils';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface InviteSentDetailsModalProps {
  showLoader: boolean;
  memberDetails: ITeamMember;
  open: boolean;
  onCancelClick: VoidFn;
  handleResendInvite: (teamMember: ITeamMember) => void;
  handleDeleteInvite: (teamMember: ITeamMember) => void;
}

const InviteSentDetailsModal: FC<InviteSentDetailsModalProps> = ({
  showLoader,
  memberDetails,
  open,
  onCancelClick,
  handleDeleteInvite,
  handleResendInvite
}) => {
  const getTeamMemberName = () => {
    if (memberDetails.firstName || memberDetails.lastName) {
      return `${memberDetails.firstName} ${memberDetails.lastName}`;
    }
    return memberDetails.phone;
  };

  const onShareInviteClick = () => {
    handleResendInvite(memberDetails);
  };

  const onDeleteInviteClick = () => {
    handleDeleteInvite(memberDetails);
  };

  return (
    <CustomModal width="md:w-96" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded-md p-8">
        <div className="flex flex-col items-center justify-center">
          {showLoader ? (
            <div className="flex w-full flex-col items-center justify-center p-16">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          ) : (
            <>
              <div
                className="flex w-full flex-col items-center justify-center "
                style={{ backgroundImage: `url(${ConfettyBg})` }}>
                <FormCloseReactIcon
                  id="invite-sent-details-modal-close"
                  height="24"
                  width="24"
                  className="absolute top-2 right-2 shrink-0 cursor-pointer"
                  onClick={onCancelClick}
                />
                <InviteSentIcon />
                <p className="mt-5 text-xl font-bold leading-6 text-tertiaryText">Invite Sent</p>
              </div>
              <p className="mt-3 text-center text-sbase font-normal leading-5 text-primaryText">
                {`Please ask your team member to join the team 
                before the invite expires on 
                ${DateUtils.getDateInFormat({
                  date: new Date(memberDetails.inviteExpiry),
                  dateFormat: 'dd MMM, yyyy'
                })}`}
              </p>
              <Divider variant="fullWidth" className="mt-4 w-full bg-secondaryBtn" />
              <div className="flex w-full flex-row">
                <div className="flex w-full flex-col justify-between text-sbase">
                  <div>
                    <div className="mt-4 text-accentText">Name</div>
                    <div className="mt-1 font-semibold text-primaryText">{getTeamMemberName() || '-'}</div>
                  </div>
                  <div>
                    <div className="mt-5 text-accentText">Email</div>
                    <div className="mt-1 font-semibold text-primaryText">{`${memberDetails.email || '-'}`}</div>
                  </div>
                </div>
                <div className="ml-2 flex w-full flex-col justify-between text-sbase">
                  <div>
                    <div className="mt-4 text-accentText">Phone number</div>
                    <div className="mt-1 font-semibold text-primaryText">{`${memberDetails.phone || '-'}`}</div>
                  </div>
                  <div>
                    <div className="mt-5 text-accentText">Role</div>
                    <div className="mt-1 font-semibold capitalize text-primaryText">{`${memberDetails.role}`}</div>
                  </div>
                </div>
              </div>
              <CustomButton
                childrenContainerStyles="items-center path-fill-current path"
                StartIcon={ExportIcon}
                id="new-invite-sent-modal-invite-more-button"
                className={`text-semibold mt-7 h-[38px] rounded-md bg-primaryBtn 
             text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}
                onClick={onShareInviteClick}>
                Share Invite Link
              </CustomButton>
              <CustomButton
                id="new-invite-sent-modal-cancel-button"
                className="text-semibold mt-6 h-[38px] w-full border-none 
                text-primaryText shadow-none hover:bg-transparent"
                StartIcon={DeleteIcon}
                childrenContainerStyles="items-center text-17px"
                type={ButtonType.SECONDARY}
                onClick={onDeleteInviteClick}>
                Delete Invite
              </CustomButton>
            </>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default InviteSentDetailsModal;
