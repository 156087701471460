import { searchTypes } from 'constants/common';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useLazyGetInvoicesQuery } from 'containers/invoices/api';
import { IInvoice, InvoiceStatus } from 'containers/invoices/invoices.model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FileTypes } from 'types/baseTypes';
import { isPermissionDenied } from 'utils/apiUtils';
import { useAppSelector } from '../../../hooks/typedHooks';

export interface InvoiceQueryParams {
  status: InvoiceStatus[];
  tipAmountNE: number;
  merchantId: string[];
  customerId: string[];
  // TODO change to enum
  paymentMethod: string[];
  start: string;
  end: string;
  search: string;
  type: FileTypes;
}

export const initialQueryParamsState = {
  status: [],
  tipAmountNE: null,
  merchantId: [],
  customerId: [],
  paymentMethod: [],
  start: null,
  end: null,
  search: null,
  type: null
};

export const usePaginatedGetInvoicesQuery = () => {
  const [list, setList] = useState<IInvoice[]>([]);
  const [queryParams, setQueryParams] = useState<InvoiceQueryParams>(initialQueryParamsState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const page = useRef(0);
  const [getInvoicesData, invoicesState] = useLazyGetInvoicesQuery();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [searchType, setSearchType] = useState(searchTypes[0]);

  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!invoicesState?.isFetching && invoicesState.isSuccess) {
      setHasPermissionsToView(true);
      const invoices = invoicesState?.data?.invoices ?? [];
      const updatedList = isInitListCall ? invoices : [...list, ...invoices];
      const invoicesLeftToFetch = invoicesState?.data?.totalCount
        ? +invoicesState?.data?.totalCount - updatedList.length
        : 0;

      setList(updatedList);

      if (invoicesLeftToFetch > 0 && (!invoicesState?.data?.invoices || invoicesState?.data?.invoices?.length === 0)) {
        setHasMore(false);
      } else {
        setHasMore(invoicesLeftToFetch > 0);
      }
      if (page.current === 1) {
        setTotalListCount(invoicesState?.data?.totalCount ?? 0);
      }
      setIsLoading(false);
    }
  }, [invoicesState?.isFetching]);

  useEffect(() => {
    if (!invoicesState?.isFetching && invoicesState?.isError) {
      setIsLoading(false);
      setHasMore(false);
      if (isPermissionDenied(invoicesState.error?.['data']?.code, invoicesState.error?.['data']?.message)) {
        setHasPermissionsToView(false);
      }
    }
  }, [invoicesState?.isError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setList([]);
    loadMore();
  };

  useEffect(() => {
    resetListAndLoadMore();
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    const isSearchPresent = queryParams.search !== null && queryParams.search !== '';
    if (currentTeam?.id) {
      try {
        getInvoicesData({
          teamId: currentTeam?.id,
          limit: 10,
          page: page.current,
          ...queryParams,
          selectedIds: [],
          search: null,
          [searchType.type]: isSearchPresent ? queryParams.search.trim() : null
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetQuery = () => {
    resetListAndLoadMore();
  };

  return {
    isLoading: isLoading,
    isFetching: invoicesState?.isFetching,
    totalListCount,
    list,
    isError,
    loadMore,
    resetQuery,
    hasMore,
    setQueryParams,
    queryParams,
    isInitListCall,
    isLoadingSuccess: invoicesState.isSuccess,
    hasPermissionsToView,
    searchType,
    setSearchType
  };
};
