import { Divider } from '@material-ui/core';
import classnames from 'classnames';
import {
  DeactivateIcon,
  DeleteIcon,
  EditIcon,
  ListActionsIcon,
  ReactivateIcon,
  ShareIcon,
  VerticalListActionsIcon
} from 'assets/icons';
import { CustomPopover } from 'components';
import {
  selectCurrentTeam,
  selectCurrentTeamTeamSettingsPermissions,
  selectUserDetails
} from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import React, { FC, useState } from 'react';
import { ITeamMember, TeamMemberStatus } from '../manageTeam.model';
import { TeamMemberActionType } from '../type';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { getInitials } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import { TeamRole } from 'containers/app/app.model';
import { useParams } from 'react-router-dom';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsManageTeam } from '../events';
import { bgColorSelectorWithASCII } from 'utils/styleUtils';
import { twMerge } from 'tailwind-merge';
import { teamMemberRoles } from '../edit-permissions/constants';

interface TeamMembersTableProps {
  teamMemberList: ITeamMember[];
  isSelectedTeamMember: (teamMember: ITeamMember) => string;
  onTeamMemberClick: (teamMember: ITeamMember) => void;
  currentSelectedId: string;
  handleMoreActionsClick: ({
    teamMemberAction,
    teamMember
  }: {
    teamMemberAction: TeamMemberActionType;
    teamMember: ITeamMember;
  }) => void;
}

const tableHeaders: Record<string, string> = {
  name: 'Name',
  role: 'User Role',
  status: 'Status',
  phone: 'Phone Number',
  email: 'Email'
};

const isHiddenHeader = (header: string): boolean => {
  if (header === 'Email') return true;
  return false;
};

const TeamMembersTable: FC<TeamMembersTableProps> = ({
  teamMemberList,
  isSelectedTeamMember,
  onTeamMemberClick,
  currentSelectedId,
  handleMoreActionsClick
}) => {
  const [currentActionsPopoverId, setCurrentActionsPopoverId] = useState<string>(null);
  const { memberId } = useParams();
  const currentUser = useAppSelector(selectUserDetails);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const selectedCurrentTeamTeamSettingsPermissions = useAppSelector(selectCurrentTeamTeamSettingsPermissions);

  const isOwnerOrSelf = (teamMember: ITeamMember) => {
    return teamMember.role === TeamRole.OWNER || teamMember.merchantId === currentUser?.id;
  };

  const hasLowRoleHeirarchyRank = (teamMember: ITeamMember) => {
    if ([TeamRole.CUSTOM, TeamRole.MEMBER].includes(currentTeam.role)) {
      return teamMember.role === TeamRole.ADMIN;
    }
  };

  const isTeamMemberActionsDisabled = (teamMember: ITeamMember) => {
    return (
      isOwnerOrSelf(teamMember) ||
      hasLowRoleHeirarchyRank(teamMember) ||
      !selectedCurrentTeamTeamSettingsPermissions?.editExistingMember ||
      (currentTeam?.role !== TeamRole.OWNER && teamMember.role === TeamRole.SUPER_ADMIN)
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  const ListActionsAnchor: React.FC<{ isPanelOpen: boolean }> = ({ isPanelOpen }) => {
    return (
      <div id="team-members-table-more-options" className="py-2">
        <ListActionsIcon
          className={twMerge(
            'hidden shrink-0 lg:flex',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
        <VerticalListActionsIcon
          className={twMerge(
            'mx-2 flex shrink-0 lg:hidden',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
      </div>
    );
  };

  const onToggleListItemActions = (teamMember: ITeamMember) => {
    if (isTeamMemberActionsDisabled(teamMember)) {
      return;
    }
    if (teamMember.id === currentActionsPopoverId) {
      setCurrentActionsPopoverId(null);
    } else {
      setCurrentActionsPopoverId(teamMember.id);
    }
  };

  const isListItemActionsVisible = (receiptId: string) => {
    return receiptId === currentActionsPopoverId;
  };

  const onCloseListItemActionsPopover = (receiptId: string) => {
    if (currentActionsPopoverId === receiptId) {
      setCurrentActionsPopoverId(null);
    }
  };

  const getTeamStatus = (teamMember: ITeamMember) => {
    switch (teamMember.status) {
      case TeamMemberStatus.INVITED:
        return <div className={`${teamMember.isExpired ? 'text-accentText' : 'text-pending-orange'}`}>Invited</div>;
      case TeamMemberStatus.ACTIVE:
        return <div className="text-successText">Active</div>;
      case TeamMemberStatus.INACTIVE:
        return <div className="text-error">Inactive</div>;
    }
  };

  const getCurrentUserIdentityText = (teamMember: ITeamMember) => {
    if (teamMember?.merchantId === currentUser?.id) {
      return ' (You)';
    }
    return '';
  };

  const getTeamMemberName = (teamMember: ITeamMember) => {
    if (teamMember?.firstName || teamMember?.middleName || teamMember?.lastName) {
      return `${teamMember?.firstName} ${teamMember?.middleName} ${teamMember?.lastName}`;
    }
    return teamMember.phone;
  };

  const getInviteDaysText = (teamMember: ITeamMember) => {
    let statusDate: Date;
    if (teamMember.status === TeamMemberStatus.INVITED && teamMember.isExpired) {
      return 'Invite Expired';
    }
    if (teamMember.status === TeamMemberStatus.INACTIVE) {
      statusDate = new Date(teamMember.inactiveSince);
      const days = DateUtils.getDifferenceInDays({ secondaryDate: statusDate });
      if (days === 0) {
        return 'Since today';
      }
      return `Since ${days} ${days > 1 ? 'Days' : 'Day'}`;
    }
    if (teamMember.status === TeamMemberStatus.ACTIVE) {
      statusDate = new Date(teamMember.activeSince);
      const days = DateUtils.getDifferenceInDays({ secondaryDate: statusDate });
      if (days === 0) {
        return 'Today';
      }
      return `${days} ${days > 1 ? 'Days' : 'Day'} ago`;
    }
    statusDate = new Date(teamMember.createdAt);
    const days = DateUtils.getDifferenceInDays({ secondaryDate: statusDate });
    if (days === 0) {
      return 'Today';
    }
    return DateUtils.getDateInFormat({
      date: new Date(teamMember.createdAt),
      dateFormat: 'dd MMM yyyy'
    });
  };

  // eslint-disable-next-line react/no-multi-comp
  const ListItemActionsMenu: FC<any> = ({
    id,
    teamMember,
    enableRefund
  }: {
    id: string;
    teamMember: ITeamMember;
    isModifiable: boolean;
    isManualPayment: boolean;
    isAlreadyRefunded: boolean;
    enableRefund: boolean;
  }) => {
    const handleEditPermission = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(teamMember.id);
      logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListMMEditPermissions, teamMember);
      handleMoreActionsClick({ teamMemberAction: TeamMemberActionType.EDIT_PERMISSIONS, teamMember });
    };

    const handleDeactivate = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(teamMember.id);
      logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListMMDeactivate, teamMember);
      handleMoreActionsClick({ teamMemberAction: TeamMemberActionType.DEACTIVATE, teamMember });
    };

    const handleReactivate = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(teamMember.id);
      logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListMMReactivate, teamMember);
      handleMoreActionsClick({ teamMemberAction: TeamMemberActionType.REACTIVATE, teamMember });
    };

    const handleInviteLink = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(teamMember.id);
      handleMoreActionsClick({
        teamMemberAction: teamMember.isExpired
          ? TeamMemberActionType.SEND_NEW_INVITE
          : TeamMemberActionType.SHARE_INVITE_LINK,
        teamMember
      });
      logAnalyticEvent(
        teamMember.isExpired
          ? CleverTapEventsManageTeam.webManageTeamListMMSendNewInviteLink
          : CleverTapEventsManageTeam.webManageTeamListMMShareInviteLink,
        teamMember
      );
      setCurrentActionsPopoverId(null);
    };

    const handleDeleteInvite = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(teamMember.id);
      logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListMMDeleteInvite, teamMember);
      handleMoreActionsClick({ teamMemberAction: TeamMemberActionType.DELETE_INVITE, teamMember });
    };

    return (
      <div className="space-y-3 text-sbase font-semibold text-primaryText">
        {teamMember.status === TeamMemberStatus.ACTIVE && (
          <>
            <div
              id="team-members-table-3dot-edit-permissions"
              className={'flex flex-row items-center gap-2'}
              onClick={handleEditPermission}>
              <EditIcon className="path-fill-current path text-secondary" />
              <div>Edit Permissions</div>
            </div>
            <div
              id="team-members-table-3dot-deactivate"
              onClick={handleDeactivate}
              className={'flex flex-row items-center gap-2'}>
              <DeactivateIcon className="mx-0.5" />
              <div className="ml-0.5">Deactivate</div>
            </div>
          </>
        )}
        {teamMember.status === TeamMemberStatus.INACTIVE && (
          <div
            id="team-members-table-3dot-deactivate"
            onClick={handleReactivate}
            className={'flex flex-row items-center gap-2'}>
            <ReactivateIcon />
            <div>Re-activate</div>
          </div>
        )}
        {teamMember.status === TeamMemberStatus.INVITED && (
          <>
            <div
              id="team-members-table-3dot-deactivate"
              onClick={handleInviteLink}
              className={'flex flex-row items-center gap-2'}>
              <ShareIcon />
              <div className="ml-2">{teamMember.isExpired ? 'Send New Invite' : 'Share Invite Link'}</div>
            </div>
            <div
              id="team-members-table-3dot-deactivate"
              onClick={handleDeleteInvite}
              className={'flex flex-row items-center gap-2'}>
              <DeleteIcon />
              <div>Delete Invite</div>
            </div>
          </>
        )}
      </div>
    );
  };

  const shouldHighlightLineItem = (teamMember: ITeamMember) => {
    if (teamMember.status !== TeamMemberStatus.INVITED) {
      if (memberId) {
        return teamMember.merchantId === memberId;
      }
      return isSelectedTeamMember(teamMember) || currentSelectedId === teamMember.merchantId;
    }
    return false;
  };

  return (
    <table className="relative w-full table-auto">
      <thead className="sticky top-[-4px] z-30 border-borderGray bg-primary">
        <Divider className="absolute top-[3.7rem] w-full bg-borderGray" />
        <tr className="text-slateGrey text-left text-xs lg:text-base [&>*]:py-5">
          {Object.values(tableHeaders).map((header, index) => {
            return (
              <th
                className={`max-w-[120px] text-sbase font-semibold text-primaryText ${
                  isHiddenHeader(header) && 'hidden lg:flex'
                }`}
                key={header + index}>
                {header}
              </th>
            );
          })}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {teamMemberList?.map(teamMember => (
          <tr
            key={teamMember.id}
            id="receipts-table-row"
            className={`remove-highlight align-center cursor-pointer border-b 
            border-secondaryBtn [&>*]:py-5 [&>*]:pr-2 ${shouldHighlightLineItem(teamMember) ? 'bg-secondaryBtn' : ''}`}
            onClick={() => {
              onTeamMemberClick(teamMember);
            }}>
            {/* createdAt */}
            <td className="max-w-[120px]">
              <div className={'flex items-center'}>
                <CustomAvatar
                  src={teamMember.profileImageUrl}
                  label={getInitials(teamMember.firstName + ' ' + teamMember.lastName)}
                  width="w-[50px]"
                  height="h-[50px]"
                  fontStyle="text-[17px] text-primaryText font-semibold leading-5 font-lato"
                  background={bgColorSelectorWithASCII(getTeamMemberName(teamMember))}
                />
                <div className={'ml-2 truncate font-lato text-sbase leading-5 text-primaryText'}>
                  {getTeamMemberName(teamMember) + getCurrentUserIdentityText(teamMember)}
                </div>
              </div>
            </td>
            {/* teamMember role */}
            <td className="max-w-[120px]">
              <div className="text-sbase text-primaryText">
                <div className="truncate capitalize">
                  {teamMember.role === TeamRole.SUPER_ADMIN ? teamMemberRoles[4].name : teamMember.role}
                </div>
              </div>
            </td>
            {/* status */}
            <td className="max-w-[120px]">
              <div className="text-sbase text-primaryText">
                <div className="mt-5 max-w-[120px] truncate font-semibold">{getTeamStatus(teamMember)}</div>
                <div className={`truncate text-px13 ${teamMember.isExpired && 'text-[#FF7A7A]'}`}>
                  {getInviteDaysText(teamMember)}
                </div>
              </div>
            </td>
            {/* phone number */}
            <td className="max-w-[120px] ">
              <div className="truncate text-sbase text-primaryText">
                <div>{teamMember.phone}</div>
              </div>
            </td>
            {/* email */}
            <td className="hidden max-w-[150px] lg:mt-5 lg:flex">
              <div className="text-sbase text-primaryText">
                <div className="truncate lg:max-w-[180px] xl:max-w-[190px] 2xl:max-w-[200px]">{teamMember.email}</div>
              </div>
            </td>
            <td
              className="w-14 pr-2"
              onClick={e => {
                e.stopPropagation();
              }}>
              <div
                className={
                  isTeamMemberActionsDisabled(teamMember) ? 'remove-highlight cursor-not-allowed opacity-60' : ''
                }>
                <CustomPopover
                  showArrow={true}
                  arrowOffset="right-6"
                  highlightAnchor={false}
                  anchorComponent={ListActionsAnchor}
                  zIndex="z-[101]"
                  offset="-right-6"
                  margin="mt-1"
                  onClosePopover={() => onCloseListItemActionsPopover(teamMember.id)}
                  show={isListItemActionsVisible(teamMember.id)}
                  onTogglePopover={() => onToggleListItemActions(teamMember)}>
                  {<ListItemActionsMenu id={teamMember.id} teamMember={teamMember} />}
                </CustomPopover>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TeamMembersTable;
