import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import { getUrlSearchParams } from 'utils/apiUtils';
import {
  CreateTaxAPIPayload,
  CreateTaxResponse,
  DeleteTaxPayload,
  EditTaxAPIPayload,
  GetCatalogFetchTaxesPayload,
  GetTaxesPayload,
  ITax
} from './taxes.model';
import { API_RESPONSES, GenericPaginationResponse } from 'types/baseTypes';
import { initializeTaxSliceFromAPI, updateInitalizingTaxSliceInProgress } from './taxSlice';

const TaxesBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTaxes: builder.query<GenericPaginationResponse<ITax>, GetTaxesPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/taxes?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch taxes',
        showToast: true
      }
    }),

    getTaxById: builder.query<ITax, { id: number; isEditFlow?: boolean; teamId: number }>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/taxes/${params.id}?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },

      async onQueryStarted({ isEditFlow }, { dispatch, queryFulfilled }) {
        dispatch(updateInitalizingTaxSliceInProgress(true));
        const { data } = await queryFulfilled;
        try {
          if (data && isEditFlow) {
            dispatch(initializeTaxSliceFromAPI(data));
          }
        } catch (err) {
          dispatch(updateInitalizingTaxSliceInProgress(false));
        }
      },

      extraOptions: {
        failure: 'Failed to fetch tax details',
        showToast: true
      }
    }),

    createTax: builder.mutation<CreateTaxResponse, CreateTaxAPIPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/taxes`;
        return {
          url: url,
          method: 'POST',
          body: params
        };
      },

      extraOptions: {
        showToast: true,
        failure: 'Failed to create tax'
      }
    }),

    editTax: builder.mutation<API_RESPONSES, EditTaxAPIPayload>({
      query: ({ id, ...rest }) => {
        const url = `${API_VERSION.V1}/catalogue/taxes/${id}`;
        return {
          url: url,
          method: 'PUT',
          body: rest
        };
      },

      extraOptions: {
        failure: 'Failed to edit tax',
        showToast: true
      }
    }),

    deleteCatalogTax: builder.mutation<API_RESPONSES, DeleteTaxPayload>({
      query: ({ id, teamId }) => {
        return {
          url: `${API_VERSION.V1}/catalogue/taxes/${id}?teamId=${teamId}`,
          method: 'DELETE'
        };
      },
      extraOptions: {
        failure: 'Failed to delete tax',
        success: 'Tax successfully deleted',
        showToast: true
      }
    }),

    getCatalogFetchTaxes: builder.query<GenericPaginationResponse<ITax>, GetCatalogFetchTaxesPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/fetch-taxes?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch taxes to add',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetTaxesQuery,
  useGetTaxByIdQuery,
  useCreateTaxMutation,
  useEditTaxMutation,
  useDeleteCatalogTaxMutation,
  useLazyGetCatalogFetchTaxesQuery
} = TaxesBaseApi;
