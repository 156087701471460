import clevertap from 'clevertap-web-sdk';
import { isProduction } from 'utils/envUtils';

import { store } from 'store/store';

export const initializeClevertap = () => {
  clevertap.spa = true;
  clevertap.privacy.push({ optOut: false });
  clevertap.privacy.push({ useIP: false });
  clevertap.init(process.env.REACT_APP_CLEVER_TAP_ACCOUNT_ID);
};

export const logAnalyticEvent = (eventName: string, params = {}, hasCommonParam = true) => {
  const { rootReducer } = store.getState();

  const { app } = rootReducer;
  const { role, name: teamName, id: teamId } = app.currentTeam;
  const { name, phone, id: merchantId } = app.userDetails ?? {};

  const commonParameters = {
    Phone: phone,
    Role: role,
    'Team Id': teamId,
    'Team Name': teamName,
    'Merchant Id': merchantId,
    'Merchant Name': name
  };
  clevertap.event.push(eventName, {
    ...(hasCommonParam && commonParameters),
    ...params
  });
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.log(eventName, commonParameters, params);
  }
};

export const clevertapCreateProfile = profile => {
  clevertap.onUserLogin.push(profile);
};

export const clevertapPushProfile = profile => {
  clevertap.profile.push(profile);
};
