import { InvoiceSurchargeState, selectIsServiceFeeEnabled } from 'containers/invoices/InvoicesSlice';
import React, { FC, useState } from 'react';
import AddServiceFeeSection from './AddServiceFeeSection';
import AddServiceFeeModal from './AddServiceFeeModal';
import { useAppSelector } from 'hooks';
import { selectIsServiceFeeEnabledEditPage } from 'containers/invoices/edit-invoice/invoicesEditSlice';

interface AddServiceFeeWrapperProps {
  serviceFee: InvoiceSurchargeState;
  isLoadingTaxesAndFees: boolean;
  isEdit: boolean;
}

const AddServiceFeeWrapper: FC<AddServiceFeeWrapperProps> = ({ serviceFee, isLoadingTaxesAndFees, isEdit }) => {
  const [showServiceFeeModal, toggleServiceFeeModal] = useState(false);

  const isServiceFeeEnabled = isEdit
    ? useAppSelector(selectIsServiceFeeEnabledEditPage)
    : useAppSelector(selectIsServiceFeeEnabled);

  const handleEditClick = () => {
    toggleServiceFeeModal(true);
  };

  const closeServiceFeeModal = () => {
    toggleServiceFeeModal(false);
  };

  return (
    <>
      <AddServiceFeeSection
        serviceFee={serviceFee}
        isLoadingTaxesAndFees={isLoadingTaxesAndFees}
        handleEditClick={handleEditClick}
        isServiceFeeEnabled={isServiceFeeEnabled}
      />
      <AddServiceFeeModal
        isOpen={showServiceFeeModal}
        onClose={closeServiceFeeModal}
        serviceFee={serviceFee}
        isServiceFeeEnabled={isServiceFeeEnabled}
        isEdit={isEdit}
      />
    </>
  );
};

export default AddServiceFeeWrapper;
