import React, { FC, Fragment, useState } from 'react';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';

import { ArrowRightIcon, CaretDownIcon, FetchingErrorIcon, LineGraphIcon, ReloadIcon } from 'assets/icons';
import { If } from 'components';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { MonthlySaleResponse } from 'containers/home/home.model';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { pluckIntValuesFromArrayWithSpecificKey, pluckValuesFromArrayWithSpecificKey } from 'utils/formatters';
import { useNavigate } from 'react-router-dom';
import { SaleTypes } from 'containers/home/types';
import OutsideClickHandler from 'react-outside-click-handler';
import { twMerge } from 'tailwind-merge';
import { Popover, Transition } from '@headlessui/react';
import { formatAmount } from 'utils/amountUtils';
import classnames from 'classnames';
import { selectCurrentTeamCustomersPermissions, selectCurrentTeamInvoicePermissions } from 'containers/app/appSlice';
import { logAnalyticEvent } from 'utils/analytics';
import { useAppDispatch, useAppSelector } from 'hooks';
import PermissionError from './PermissionError';
import { resetInvoiceState } from 'containers/invoices/InvoicesSlice';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface SalesProps {
  data: MonthlySaleResponse | null;
  isSuccess: boolean;
  handleReload?: VoidFn;
  isFetching?: boolean;
  selectedSalesTypeMonthlyBreakdown: string;
  setSelectedSalesTypeMonthlyBreakdown: (value: SaleTypes) => void;
  isPermissionError: boolean;
}

const MonthlyBreakdownChart: FC<SalesProps> = props => {
  const {
    data,
    isSuccess,
    handleReload,
    isFetching,
    selectedSalesTypeMonthlyBreakdown,
    setSelectedSalesTypeMonthlyBreakdown,
    isPermissionError
  } = props;
  const dispatch = useAppDispatch();
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      tooltip: {
        displayColors: false,
        backgroundColor: '#ECF1F7',
        bodyColor: '#3E556D',
        paddingTop: 5,
        paddingLeft: 9,
        titleFont: { weight: 'bold' },
        labelFont: { weight: 'bold' },
        callbacks: {
          label: function (context) {
            return `$${formatAmount(context.parsed.y)}`;
          },
          title: function (context) {
            return null;
          }
        }
      }
    },
    scales: {
      y: {
        display: false,
        grid: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    }
  };

  const navigate = useNavigate();

  const labels = pluckValuesFromArrayWithSpecificKey(data?.monthlyAllSales || [], 'month');

  const chartData = {
    labels,
    datasets: [
      {
        label: 'Sales',
        data: pluckIntValuesFromArrayWithSpecificKey(data?.monthlyAllSales || [], 'sales'),
        backgroundColor: '#A4D7FA',
        borderRadius: 4
      }
    ]
  };

  const isChartEmpty = () => {
    let response = true;
    data?.monthlyAllSales?.forEach(item => {
      if (parseInt(item.sales) !== 0) {
        response = false;
      }
    });
    return response;
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const onOutSideClick = () => {
    setIsDropdownOpen(false);
  };

  const handleCreateInvoiceClick = () => {
    dispatch(resetInvoiceState());
    navigate('/invoices/create');
    logAnalyticEvent(clevertapEvents.webAllSalesThisYearCreateInvoice);
  };

  return (
    <div
      onMouseEnter={() => {
        logAnalyticEvent(clevertapEvents.webAllSalesThisYear);
      }}
      className="flex h-full w-full flex-col justify-between rounded border border-borderGray">
      <div className="flex w-full shrink-0 flex-col px-5 py-2.5">
        <div className="mb-3 flex items-center">
          <OutsideClickHandler onOutsideClick={onOutSideClick}>
            <div className={twMerge('w-full')}>
              <Popover className="relative">
                {() => (
                  <>
                    <div
                      className="flex cursor-pointer rounded border border-borderGray px-1"
                      onClick={() => setIsDropdownOpen(true)}>
                      <div className="text-xl font-bold text-primaryText">{selectedSalesTypeMonthlyBreakdown}</div>
                      <div className="flex items-center justify-center px-2.5 pr-0">
                        <CaretDownIcon />
                      </div>
                    </div>
                    <Transition
                      show={isDropdownOpen}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel
                        className="customNormalScroll absolute
                           z-40 w-full overflow-y-auto rounded-md
                            border border-borderGray bg-primary shadow-md">
                        <div className={'pb-1'}>
                          <div
                            id="all-sales-graph-filter-this-year"
                            className={'cursor-pointer pl-2 pt-3 text-base font-semibold leading-5 text-primaryText'}
                            onClick={() => {
                              setSelectedSalesTypeMonthlyBreakdown(SaleTypes.ALL_SALES);
                              setIsDropdownOpen(false);
                            }}>
                            {SaleTypes.ALL_SALES}
                          </div>
                          <div
                            id="digital-sales-graph-filter-this-year"
                            className={
                              'cursor-pointer pl-2 pt-3 pb-2 text-base font-semibold leading-5 text-primaryText'
                            }
                            onClick={() => {
                              setSelectedSalesTypeMonthlyBreakdown(SaleTypes.DIGITAL_SALES);
                              setIsDropdownOpen(false);
                            }}>
                            {SaleTypes.DIGITAL_SALES}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </OutsideClickHandler>

          <div className="ml-2 mt-1 text-xl font-bold text-primaryText">this year - {new Date().getFullYear()}</div>
          {/* to be changed when filter design is provided */}

          {/* // */}
        </div>
        <If condition={isSuccess && !isFetching && Boolean(data)}>
          <div className="align-center mt-2.5 flex flex-row items-center">
            <div className="flex flex-col">
              <div id="monthy-breakdown-card-amount" className="flex flex-row items-center">
                {parseInt(data?.totalSales) !== 0 ? (
                  <div id="monthy-breakdown-amount" className="text-xl font-bold text-tertiaryText">
                    {`$${formatAmount(data?.totalSales)}`}
                  </div>
                ) : (
                  <div id="monthy-breakdown-amount-empty" className="text-xl font-bold text-tertiaryText">
                    $0.00
                  </div>
                )}
              </div>
            </div>
          </div>
        </If>
      </div>
      <div className="w-full p-5">
        <div
          className="customNormalScroll box-border flex max-h-60
         w-full flex-col gap-2 overflow-y-auto overflow-x-hidden">
          <If condition={isSuccess && !isFetching && Boolean(data) && !isChartEmpty()}>
            <Bar options={options} data={chartData} />
          </If>
          <If condition={!isSuccess && !isFetching && !isPermissionError}>
            <div className="rotateChild flex flex-col items-center justify-center gap-2 p-16">
              <FetchingErrorIcon />
              <div className="text-sbase text-accentText">Something went wrong!</div>
              <div className="flex items-center gap-2">
                <div className="cursor-pointer" onClick={handleReload}>
                  <ReloadIcon />
                </div>
                <div className="text-px13 text-accentText">Please try refreshing</div>
              </div>
            </div>
          </If>
          <If condition={!isSuccess && !isFetching && isPermissionError}>
            <div className="p-14">
              <PermissionError />
            </div>
          </If>
          <If condition={isSuccess && isChartEmpty() && !isFetching}>
            <div className="rotateChild flex flex-col items-center justify-center gap-2 p-16">
              <LineGraphIcon />
              <div className="text-sbase font-semibold text-accentText">No sales in this year</div>
              <div
                onClick={handleCreateInvoiceClick}
                className={twMerge(
                  'flex cursor-pointer items-center gap-2',
                  classnames({
                    'pointer-events-none opacity-50':
                      !invoicePermissions.createInvoice || !customerPermissions.createNewCustomers
                  })
                )}>
                <div id="create-new-invoice-this-year-graph" className="text-px13 font-semibold text-secondary">
                  Create new invoice
                </div>
                <ArrowRightIcon />
              </div>
            </div>
          </If>
          <If condition={isFetching}>
            <div className="m-auto flex h-60 items-center justify-center">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

export default MonthlyBreakdownChart;
