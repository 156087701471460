import React, { FC } from 'react';
import ReactDOM from 'react-dom';
import { getNotifications, hideNotifier } from 'containers/app/appSlice';
import Notifier from './components/Notifier';
import { useAppSelector } from 'hooks';

const NotifierStack: FC = () => {
  const toastNotifications = useAppSelector(getNotifications);

  return ReactDOM.createPortal(
    <div id="root">
      <div className="pointer-events-none fixed inset-x-0 bottom-0">
        {toastNotifications?.map(message => (
          <Notifier key={message.id} notification={message} hideNotifier={hideNotifier} />
        ))}
      </div>
    </div>,
    document.getElementById('notifier-root')
  );
};

export default NotifierStack;
