export enum CleverTapEventsMyAccount {
  webAddEmailClick = 'Web_Add_Email_From_My_Account',
  webUpdateEmailClick = 'Web_Update_Email_From_My_Account',
  webResetPasswordClick = 'Web_Reset_Password_From_My_Account',
  webVerificationMethodContinueClick = 'Web_Verification_Method_Continue_Click',
  webVerificationMethodBackClick = 'Web_Verification_Method_Back_Click',
  webUpdateEmailContinueClick = 'Web_Update_Email_Continue_Click',
  webUpdateEmailCancelClick = 'Web_Update_Email_Cancel_Click',
  webMyAccVerificationContinueClick = 'Web_My_Account_Verification_Continue_Click',
  webMyAccVerificationBackClick = 'Web_My_Account_Verification_Back_Click',
  webMyAccEmailVerificationEditClick = 'Web_My_Account_Email_Verification_Edit_Click',
  webMyAccResetPasswordContinueClick = 'Web_My_Acc_Reset_Password_Continue_Click',
  webMyAccResetPasswordBackClick = 'Web_My_Acc_Reset_Password_Back_Click',
  webMyAccPasswordError = 'Web_My_Acc_Password_Error',
  webMyAccUpdateNameClick = 'Web_My_Acc_Update_Name_Click',
  webAddNameSaveClick = 'Web_Add_Name_Save_Click',
  webAddNameBackClick = 'Web_Add_Name_Back_Click',
  webMyAccEmailVerificationContinueClick = 'Web_My_Account_Email_Verification_Continue_Click',
  webMyAccEmailVerificationBackClick = 'Web_My_Account_Email_Verification_Back_Click',
  webMyAccSendVerificationEmailClick = 'Web_My_Account_Send_Verification_Email_Click'
}
