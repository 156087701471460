import { FC, useEffect, useState } from 'react';
import { CustomButton, CustomModal, If, ListDropDown } from 'components';
import { useEditPermissionsMutation, useGetTeamMemberPermissionsQuery } from '../api';
import { EditTeamMemberPermissionsPayload, ITeamMember } from '../manageTeam.model';
import { dropDownGenericRoles, teamMemberRoles } from './constants';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  changeRole,
  initializeCurrentPermissionWithSavedPermission,
  resetEditPermissionsSlice,
  selectEditSlicePermissions,
  selectEditedPermission,
  selectInitialPermission,
  selectSavedPermission,
  selectTeamMemberRole,
  updateSelectedPermission
} from './editPermissionsSlice';
import { TeamMemberRoleItems } from './types';
import { LoadingSpinnerAnimation } from 'assets/animations';
import Lottie from 'lottie-react';
import PermissionDisclosures from './components/PermissionDisclosures';
import { TeamRole } from 'containers/app/app.model';
import { selectCurrentTeam, selectCurrentTeamTeamSettingsPermissions } from 'containers/app/appSlice';
import SaveChangesModal from '../components/SaveChangesModal';
import { VoidFn } from 'types/baseTypes';
import { isEqual } from 'lodash';
import { getRolesInDropdown } from '../utils';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface EditPermissionsModalProps {
  className?: string;
  open: boolean;
  toggleShowEditPermissionsModal: (teamMember?: ITeamMember) => void;
  teamMemberData: ITeamMember;
  withoutPermissionsAPICall?: boolean;
  editSuccessCallback?: VoidFn;
  isNewInvitePage?: boolean;
}

export interface PermissionItem {
  title: string;
  key: string;
  parentKey: string;
  rootKey: string;
  enabled: boolean;
}

const EditPermissionsModal: FC<EditPermissionsModalProps> = ({
  open,
  toggleShowEditPermissionsModal,
  teamMemberData,
  withoutPermissionsAPICall = false,
  editSuccessCallback = () => null,
  isNewInvitePage = false
}) => {
  const selectedTeamRole = useAppSelector(selectTeamMemberRole);
  const selectedInitialPermission = useAppSelector(selectInitialPermission);
  const selectedEditedPermission = useAppSelector(selectEditedPermission);
  const selectedCurrentTeamTeamSettingsPermissions = useAppSelector(selectCurrentTeamTeamSettingsPermissions);

  const selectedSavedPermission = useAppSelector(selectSavedPermission);

  const selectedEditSlicePermissions = useAppSelector(selectEditSlicePermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const dispatch = useAppDispatch();
  const [
    editPermissionsMutation,
    { isSuccess: isEditedPermissionsSuccess, isLoading: isPermissionsEditMutationInProgress }
  ] = useEditPermissionsMutation();
  const [isLoadingCall, setIsLoadingCall] = useState(true);
  const { isFetching, isSuccess: isGetTeamMemberPermissionsSuccess } = useGetTeamMemberPermissionsQuery(
    { roleId: teamMemberData?.roleId, teamId: currentTeam.id },
    { skip: withoutPermissionsAPICall, refetchOnMountOrArgChange: true }
  );

  const [showSaveChangesModal, setShowSaveChangesModal] = useState(false);
  const [selectedRole, setSelectedRole] = useState<TeamMemberRoleItems>(teamMemberRoles[2]);

  useEffect(() => {
    if (isGetTeamMemberPermissionsSuccess) {
      const currentRole = teamMemberRoles.find(teamRole => teamRole.role === selectedTeamRole);
      setSelectedRole(currentRole);
      setIsLoadingCall(false);
    }
    if (withoutPermissionsAPICall) {
      setIsLoadingCall(false);
    }
  }, [isFetching, isGetTeamMemberPermissionsSuccess]);

  useEffect(() => {
    if (isEditedPermissionsSuccess) {
      dispatch(resetEditPermissionsSlice());
      toggleShowEditPermissionsModal();
      setShowSaveChangesModal(false);
      editSuccessCallback();
    }
  }, [isEditedPermissionsSuccess]);

  useEffect(() => {
    if (selectedTeamRole === TeamRole.CUSTOM) {
      // set to custom role
      setSelectedRole(teamMemberRoles[3]);
    } else if (selectedTeamRole === TeamRole.MEMBER) {
      setSelectedRole(dropDownGenericRoles[1]);
    } else if (selectedTeamRole === TeamRole.ADMIN) {
      setSelectedRole(dropDownGenericRoles[0]);
    } else if (selectedTeamRole === TeamRole.SUPER_ADMIN) {
      setSelectedRole(teamMemberRoles[4]);
    }
  }, [selectedTeamRole]);

  const onCancelClick = () => {
    if (isNewInvitePage) {
      if (isEqual(selectedEditedPermission, selectedSavedPermission)) {
        toggleShowEditPermissionsModal();
        return;
      }
      setShowSaveChangesModal(true);
      return;
    }
    if (isEqual(selectedEditedPermission, selectedInitialPermission)) {
      dispatch(resetEditPermissionsSlice());
      toggleShowEditPermissionsModal();
      return;
    }
    setShowSaveChangesModal(true);
  };

  const onRoleChangeClick = (teamMemberRoleItem: TeamMemberRoleItems) => {
    setSelectedRole(teamMemberRoleItem);
    dispatch(changeRole(teamMemberRoleItem.role));
  };

  const savePermissionsWithoutAPICall = () => {
    dispatch(updateSelectedPermission());
    toggleShowEditPermissionsModal();
    setShowSaveChangesModal(false);
  };

  const savePermissionsWithAPICall = teamMember => {
    if (teamMember.role === TeamRole.OWNER) {
      toggleShowEditPermissionsModal();
      return;
    }
    const editPermissionsPayload: EditTeamMemberPermissionsPayload = {
      role: selectedTeamRole,
      teamId: currentTeam?.id,
      merchantId: teamMember.merchantId ?? teamMember.id,
      permissions: selectedEditSlicePermissions
    };
    editPermissionsMutation(editPermissionsPayload);
  };

  const handleSave = (teamMember: ITeamMember) => {
    if (isNewInvitePage) {
      savePermissionsWithoutAPICall();
      return;
    }
    savePermissionsWithAPICall(teamMember);
  };

  const onCancelSaveChanges = () => {
    if (!isNewInvitePage) {
      dispatch(resetEditPermissionsSlice());
      toggleShowEditPermissionsModal();
      return;
    }
    dispatch(initializeCurrentPermissionWithSavedPermission());
    toggleShowEditPermissionsModal();
  };

  const onSaveChangesModalSave = (teamMember: ITeamMember) => {
    handleSave(teamMember);
  };

  return (
    <>
      <CustomModal className="md:w-[423px]" open={open} closeModal={onCancelClick}>
        <div className="relative flex max-h-[95vh] flex-col rounded-md">
          <If condition={isFetching}>
            <div className="absolute flex h-full w-full flex-col items-center justify-center rounded-md ">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          </If>
          <div
            className={`${
              isFetching || isLoadingCall ? 'invisible' : 'visible'
            } flex max-h-[95vh] flex-col rounded-md`}>
            <div className="sticky top-0 z-[60] rounded-md bg-primary px-10 pt-[36px]">
              <FormCloseReactIcon
                id="edit-permissions-modal-close"
                height="24"
                width="24"
                className="absolute top-2 right-2 shrink-0 cursor-pointer"
                onClick={onCancelClick}
              />
              <div className="mb-[21px] text-17px font-semibold text-heading">Edit Permissions</div>

              <ListDropDown
                buttonStyles="bg-secondaryBtn h-[50px] text-17px font-semibold text-primaryText"
                optionStyles="py-2.5"
                items={getRolesInDropdown(
                  currentTeam,
                  selectedCurrentTeamTeamSettingsPermissions,
                  selectedEditedPermission.permissions.banking.level
                )}
                selectedItem={selectedRole}
                setSelectedItem={onRoleChangeClick}
                wrapperStyles="mb-5 bg-primary"
                id="edit-permissions-select-role-dropdown"
              />
            </div>

            <div className="customNormalScroll overflow-y-auto px-10">
              <PermissionDisclosures />
            </div>
            <div className="w-full px-10 pb-11">
              <CustomButton
                isLoading={isPermissionsEditMutationInProgress && !showSaveChangesModal}
                onClick={() => handleSave(teamMemberData)}
                className="mb-6 w-full"
                id="edit-permissions-save">
                Save
              </CustomButton>
              <CustomButton
                onClick={onCancelClick}
                className="w-full"
                id="edit-permissions-cancel"
                type={ButtonType.SECONDARY}>
                Cancel
              </CustomButton>
            </div>
          </div>
        </div>

        {showSaveChangesModal && (
          <SaveChangesModal
            open={showSaveChangesModal}
            cancelSave={onCancelSaveChanges}
            handleSave={() => onSaveChangesModalSave(teamMemberData)}
            isSaveInProgress={isPermissionsEditMutationInProgress}
          />
        )}
      </CustomModal>
    </>
  );
};
export default EditPermissionsModal;
