import { createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';

export interface IntegrationsState {
  intuitLoginUrl: string;
  isInitialSetup: boolean;
}

const initialState: IntegrationsState = {
  intuitLoginUrl: '',
  isInitialSetup: false
};

export const integrationsSlice = createSlice({
  name: 'integrations',
  initialState,
  reducers: {
    resetIntegrationsState: () => initialState,
    updateIntuitLoginUrl: (state, { payload }) => {
      state.intuitLoginUrl = payload;
    },
    toggleIsInitialSetup: (state, { payload }) => {
      state.isInitialSetup = payload;
    }
  }
});

export const { updateIntuitLoginUrl, toggleIsInitialSetup } = integrationsSlice.actions;

export const selectIntuitLoginUrl = (state: RootState) => state.rootReducer.integrations.intuitLoginUrl;
export const selectIsInitialSetup = (state: RootState) => state.rootReducer.integrations.isInitialSetup;

export default integrationsSlice.reducer;
