import { If } from 'components';
import { FC } from 'react';
import { formatAmount, formattedLineItemQty, getAmountWithCurrency } from 'utils/amountUtils';
import { ReceiptByIdResponse } from '../receipt.model';

interface ReceiptLineItemProps {
  receiptData: ReceiptByIdResponse;
  marginX?: string;
  paddingX?: string;
  showTotalAmount?: boolean;
  borderStyle?: string;
}

const ReceiptLineItems: FC<ReceiptLineItemProps> = props => {
  const { receiptData, marginX = 'mx-4', paddingX = 'px-3', showTotalAmount = true, borderStyle = '' } = props;

  return (
    <>
      <div
        className={`flex items-center justify-between border-b border-borderGray py-4
            text-sbase font-semibold text-primaryText ${marginX}`}>
        <div>Items</div>
        <div>Price</div>
      </div>
      <div>
        {receiptData.lineItems?.map(lineItem => (
          <div
            className={`flex justify-between border-b border-secondaryBtn py-4 last:border-b-0 ${marginX}`}
            key={lineItem.name + lineItem.qty}>
            <div className="flex flex-col gap-2">
              <div className="text-sbase font-semibold text-headingGray">{lineItem.name}</div>
              <If condition={Boolean(lineItem.additionalDetails)}>
                <div className="text-px13 text-primaryText">{lineItem.additionalDetails}</div>
              </If>
            </div>
            <div className="flex flex-col gap-2 text-right">
              <div className="text-sbase font-semibold text-headingGray">{getAmountWithCurrency(lineItem.amount)}</div>
              <div className="text-sbase text-accentText">
                {`(${formattedLineItemQty({ qtyString: lineItem.qty })} x ${getAmountWithCurrency(
                  lineItem.unitPrice
                )})`}
              </div>
            </div>
          </div>
        ))}
        <If condition={Boolean(+receiptData.discount)}>
          <div className={`flex justify-between py-4 ${marginX} text-sbase font-semibold text-tertiaryText`}>
            <div>Discount</div>
            <div className="flex gap-4 text-right">
              <div className="text-accentText">{formatAmount(+receiptData.discountPercentage)}%</div>
              <div>-{getAmountWithCurrency(receiptData.discount)}</div>
            </div>
          </div>
        </If>
        <div
          className={`bg-secondaryBtn ${paddingX} flex flex-col
          gap-3 py-5 text-sbase text-tertiaryText ${borderStyle}`}>
          <div className="text-px13 font-semibold text-headingGray">Summary</div>
          <div className="flex justify-between">
            <div>Subtotal</div>
            <div className="font-semibold">{getAmountWithCurrency(+receiptData.billAmount)}</div>
          </div>
          <If condition={receiptData.hasSurcharge}>
            <div className="flex justify-between">
              <div>Service fee</div>
              <div className="flex gap-4 font-semibold">
                {/* <div className="text-accentText">{formatDecimalThreePlaces(+receiptData.surchargePercentage)}%
                </div> */}
                <div>{getAmountWithCurrency(+receiptData.surchargeAmount)}</div>
              </div>
            </div>
          </If>
          {receiptData?.serviceCharges?.length > 0 &&
            receiptData.serviceCharges.map((serviceCharge, index) => (
              <div className="flex justify-between" key={`${serviceCharge.name + index}`}>
                <div className="break-words line-clamp-3">{serviceCharge.name}</div>
                <div id="receipt-line-items-service-fee-percent" className="flex gap-4 font-semibold">
                  {/* <div className="text-accentText">{formatDecimalThreePlaces(+scharge.percentage)}%</div> */}
                  <div>{getAmountWithCurrency(+serviceCharge.totalAmount)}</div>
                </div>
              </div>
            ))}
          {receiptData?.taxes?.length > 0 &&
            receiptData.taxes?.map((tax, index) => (
              <div className="flex justify-between" key={`${tax.name + index}`}>
                <div className="break-words line-clamp-3">{tax.name}</div>
                <div id="line-items-additional-tax-percent" className="flex gap-4 font-semibold">
                  {/* <div className="text-accentText">{formatDecimalThreePlaces(+tax.percentage)}%</div> */}
                  <div>{getAmountWithCurrency(+tax.amount)}</div>
                </div>
              </div>
            ))}
          <If condition={receiptData.hasTip}>
            <div className="flex justify-between">
              <div>Tip</div>
              <div className="font-semibold">{getAmountWithCurrency(+receiptData.tipAmount)}</div>
            </div>
          </If>
          <If condition={showTotalAmount}>
            <div className="flex justify-between border-t border-dark-gray pt-2 font-bold text-headingGray">
              <div>Total Amount</div>
              <div>{getAmountWithCurrency(+receiptData.amount)}</div>
            </div>
          </If>
        </div>
      </div>
    </>
  );
};

export default ReceiptLineItems;
