import {
  BlueHourGlassIcon,
  CaretDownGreyIcon,
  CaretRightIcon,
  GreenFilledTickIcon,
  OrangeHourGlassIcon,
  PaymentProcessingIcon,
  RefundedBlueIcon
} from 'assets/icons';
import { FC, useState } from 'react';
import { InvoiceStatus, InvoiceTimelineEvent } from '../invoices.model';
import { VoidFn } from 'types/baseTypes';
import { InvoiceTimelineCTAActions } from '../constants';

interface InvoiceTimelineProps {
  invoiceStatus: InvoiceStatus;
  timeline: InvoiceTimelineEvent[];
  handleShare: VoidFn;
}

const InvoiceTimeline: FC<InvoiceTimelineProps> = ({ invoiceStatus, timeline, handleShare }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleTimeline = () => {
    setIsExpanded(prev => !prev);
  };

  const handleCTAClick = action => {
    if (action === InvoiceTimelineCTAActions.SHARE_INVOICE) {
      handleShare();
    }
  };

  const getTimelineStyles = () => {
    switch (invoiceStatus) {
      case InvoiceStatus.PAID:
      case InvoiceStatus.PAID_QR:
        return { className: 'bg-[#CBF4C980]', Icon: GreenFilledTickIcon, status: 'Paid' };
      case InvoiceStatus.PENDING:
        return { className: 'bg-[#F7E2C080]', Icon: OrangeHourGlassIcon, status: 'Pending' };
      case InvoiceStatus.PROCESSING:
        return { className: 'bg-[#F7E2C080]', Icon: PaymentProcessingIcon, status: 'Payment processing' };
      case InvoiceStatus.REFUNDED:
        return { className: 'bg-[#D8DFE580]', Icon: RefundedBlueIcon, status: 'Payment refunded' };
      case InvoiceStatus.REFUND_INITIATED:
        return { className: 'bg-[#D8DFE580]', Icon: BlueHourGlassIcon, status: 'Refund initiated' };
      default:
        return { className: 'bg-[#F7E2C080]', Icon: OrangeHourGlassIcon, status: invoiceStatus };
    }
  };

  const { className, Icon, status } = getTimelineStyles() ?? {};

  return (
    <div className="rounded-md border border-border">
      <div className={`${className} flex items-center rounded-tr-md py-3.5 px-4`}>
        <Icon className="mr-2.5 shrink-0" width={18} height={18} />
        <div className="text-17px font-semibold text-primaryText">{status}</div>
        <div
          className="ml-auto flex cursor-pointer items-center gap-2 
          justify-self-end text-sbase font-semibold text-primaryText"
          onClick={toggleTimeline}>
          {isExpanded ? 'Show less' : 'Show more'}
          <CaretDownGreyIcon className={isExpanded ? 'rotate-180' : ''} />
        </div>
      </div>
      <div className="p-5 pb-0">
        {timeline.map(
          (event, index) =>
            (isExpanded || (!isExpanded && event.webInfo.summary)) && (
              <div className="flex gap-3.5" key={event.title + index}>
                <div className="flex flex-col items-center">
                  <div
                    className="h-[18px] w-[18px] shrink-0 rounded-full"
                    style={{ backgroundColor: event.colourCode }}></div>
                  {index !== timeline.length - 1 && <div className="h-full w-px bg-border"></div>}
                </div>
                <div className="flex flex-col gap-1 pb-5">
                  {event.title && <div className="text-17px font-semibold text-primaryText">{event.title}</div>}
                  {event.subTitle && <div className="text-sbase font-medium text-primaryText">{event.subTitle}</div>}
                  {event.reason && <div className="text-sbase text-primaryText">Reason: {event.reason}</div>}
                  {event.timestamp && (
                    <div className="text-px13 font-normal text-accentText">
                      {event.timestamp} {event.doneBy ? `by ${event.doneBy}` : ''}
                    </div>
                  )}
                  {event.webInfo.cta.text && (
                    <div
                      className={`flex w-fit items-center justify-between gap-2
                  rounded-md bg-secondaryBtn py-1.5 px-3.5 text-sbase text-secondary 
                  ${event.webInfo.cta.action ? 'cursor-pointer' : ''}`}
                      onClick={() => handleCTAClick(event.webInfo.cta.action)}>
                      {event.webInfo.cta.text}
                      {event.webInfo.cta.action && (
                        <CaretRightIcon className="path-fill-current path text-border" width={18} />
                      )}
                    </div>
                  )}
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default InvoiceTimeline;
