import React, { FC, Fragment, useEffect, useState } from 'react';
import {
  BlueEqualToIcon,
  BlueGreaterThanIcon,
  BlueInBetweenIcon,
  BlueLessThanIcon,
  BlueTickWithBlueBorderIcon,
  CrossIcon,
  DownArrowIcon,
  EqualToIcon,
  EqualToIconWithGreyBG,
  GreaterThanIcon,
  GreaterThanIconWithGreyBG,
  InBetweenIcon,
  InBetweenIconWithGreyBG,
  LessThanIcon,
  LessThanIconWithGreyBG,
  PlusIcon,
  SelectionCircleBlueIcon,
  SelectionCircleIcon,
  TickWithBlueBorderIcon
} from 'assets/icons';
import { AmountFilterOperation, REGEX } from 'constants/common';
import CustomInput from 'components/custom-input.tsx/CustomInput';
import { Popover, Transition } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';

export interface AmountFilter {
  filterOperation: AmountFilterOperation;
  minAmount?: number;
  maxAmount?: number;
}

interface CustomAmountFilterProps {
  amountFilter: AmountFilter;
  setAmountFilter: (amountFilter: AmountFilter) => void;
}

interface AmountFilterItem {
  key: AmountFilterOperation;
  value: string;
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  SelectedIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  SummaryIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
}

const CustomAmountFilter: FC<CustomAmountFilterProps> = ({ amountFilter, setAmountFilter }) => {
  const { filterOperation, minAmount, maxAmount } = amountFilter ?? {};

  const [operation, setOperation] = useState<AmountFilterOperation>();
  const [startValue, setStartValue] = useState<number>();
  const [endValue, setEndValue] = useState<number>();

  useEffect(() => {
    setOperation(filterOperation);
    setStartValue(minAmount);
    setEndValue(maxAmount);
  }, [filterOperation, minAmount, maxAmount]);

  const handleOperationChange = value => {
    setOperation(value);
  };

  const handleStartValueChange = value => {
    if (REGEX.amount.test(value)) setStartValue(value);
  };

  const handleEndValueChange = value => {
    if (REGEX.amount.test(value)) setEndValue(value);
  };

  const amountFilters: AmountFilterItem[] = [
    {
      key: AmountFilterOperation.ALL,
      value: 'All',
      Icon: TickWithBlueBorderIcon,
      SelectedIcon: BlueTickWithBlueBorderIcon
    },
    {
      key: AmountFilterOperation.GT,
      value: 'Greater than',
      Icon: GreaterThanIcon,
      SelectedIcon: BlueGreaterThanIcon,
      SummaryIcon: GreaterThanIconWithGreyBG
    },
    {
      key: AmountFilterOperation.LT,
      value: 'Less than',
      Icon: LessThanIcon,
      SelectedIcon: BlueLessThanIcon,
      SummaryIcon: LessThanIconWithGreyBG
    },
    {
      key: AmountFilterOperation.BW,
      value: 'In between',
      Icon: InBetweenIcon,
      SelectedIcon: BlueInBetweenIcon,
      SummaryIcon: InBetweenIconWithGreyBG
    },
    {
      key: AmountFilterOperation.EQ,
      value: 'Equal to',
      Icon: EqualToIcon,
      SelectedIcon: BlueEqualToIcon,
      SummaryIcon: EqualToIconWithGreyBG
    }
  ];

  const isSelectedItem = (item: AmountFilterItem) => item.key === operation;

  const getSecondaryText = () => {
    const item = amountFilters.find(filter => filter.key === filterOperation);
    const { key, SummaryIcon, value } = item ?? {};
    let filterText = '';
    if (key === AmountFilterOperation.BW) {
      filterText = `${value}: $${minAmount}-$${maxAmount}`;
    } else {
      filterText = `${value}: $${minAmount}`;
    }

    return (
      <div className="flex gap-1">
        {SummaryIcon && <SummaryIcon className="shrink-0" />}
        <div className="text-sbase font-semibold text-primaryBtn">{filterText}</div>
      </div>
    );
  };

  const renderFilterItem = (item: AmountFilterItem) => {
    const { key, Icon, value, SelectedIcon } = item ?? {};
    return (
      <div
        className={`flex flex-col gap-2  px-[30px]
      ${isSelectedItem(item) && key !== AmountFilterOperation.ALL && 'bg-secondaryBtn/50 pt-1.5 pb-2.5'}`}>
        <div className="flex cursor-pointer gap-4" role="presentation" onClick={() => handleOperationChange(key)}>
          {isSelectedItem(item) ? (
            <SelectionCircleBlueIcon className="shrink-0" />
          ) : (
            <SelectionCircleIcon className="shrink-0" />
          )}
          {isSelectedItem(item) ? <SelectedIcon className="shrink-0" /> : <Icon className="shrink-0" />}
          <div className="whitespace-nowrap text-sbase font-normal text-heading">{value}</div>
        </div>
        {isSelectedItem(item) && key !== AmountFilterOperation.ALL && (
          <div className="pl-8">
            {key === AmountFilterOperation.BW ? (
              <div className="flex gap-2.5">
                <div>
                  <div className="text-sbase font-semibold text-primaryText">Min</div>
                  <CustomInput
                    id="amount-filter-min-amount"
                    value={startValue}
                    className="h-[30px] w-[128px] rounded-md border border-primaryBtn bg-white outline-none"
                    onChange={handleStartValueChange}
                    prefix="$"
                    prefixWrapperStyle="left-1.5"
                    placeholder="0.00"
                  />
                </div>
                <div>
                  <div className="text-sbase font-semibold text-primaryText">Max</div>
                  <CustomInput
                    id="amount-filter-max-amount"
                    value={endValue}
                    className="h-[30px] w-[128px] rounded-md border border-primaryBtn bg-white outline-none"
                    onChange={handleEndValueChange}
                    prefix="$"
                    prefixWrapperStyle="left-1.5"
                    placeholder="0.00"
                  />
                </div>
              </div>
            ) : (
              <CustomInput
                id="amount-filter-min-amount"
                value={startValue}
                className="h-[30px] w-[128px] rounded-md border border-primaryBtn bg-white outline-none"
                onChange={handleStartValueChange}
                prefix="$"
                prefixWrapperStyle="left-1.5"
                placeholder="0.00"
              />
            )}
          </div>
        )}
      </div>
    );
  };

  const applyFilter = () => {
    let filter = {
      filterOperation: operation,
      minAmount: startValue,
      maxAmount: endValue
    };
    if (operation === AmountFilterOperation.ALL) {
      filter = {
        ...filter,
        minAmount: null,
        maxAmount: null
      };
    } else if (operation !== AmountFilterOperation.BW) {
      filter = {
        ...filter,
        maxAmount: null
      };
    }
    setAmountFilter(filter);
  };

  const applyBtnDisabled = () => {
    if (operation === filterOperation && startValue === minAmount && endValue === maxAmount) return true;
    switch (operation) {
      case AmountFilterOperation.EQ:
      case AmountFilterOperation.GT:
      case AmountFilterOperation.LT:
        if (startValue && REGEX.amount.test(startValue.toString())) return false;
        return true;
      case AmountFilterOperation.BW:
        if (
          startValue &&
          REGEX.amount.test(startValue.toString()) &&
          endValue &&
          REGEX.amount.test(endValue.toString()) &&
          startValue < endValue
        )
          return false;
        return true;
      case AmountFilterOperation.ALL:
        return false;
      default:
        return true;
    }
  };

  return (
    <div>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={twMerge(
                `mr-1 mb-1  shrink-0 rounded-md border 
              bg-secondaryBtn px-3 py-2 text-base font-semibold text-primaryText  
              shadow outline-none transition-all  duration-150 ease-linear focus:outline-none 
              ${filterOperation === AmountFilterOperation.ALL ? ' border-solid' : 'border-dashed'} `
              )}
              id="amount-filter"
              style={{ borderColor: filterOperation === AmountFilterOperation.ALL ? '#3876BB' : '#ACB5BD' }}
              type="button">
              <div className={'flex shrink-0 flex-row items-center'}>
                <div>
                  {filterOperation !== AmountFilterOperation.ALL ? (
                    <CrossIcon
                      onClick={e => {
                        e.stopPropagation();
                        setAmountFilter({
                          filterOperation: AmountFilterOperation.ALL,
                          maxAmount: null,
                          minAmount: null
                        });
                      }}
                      className="fill-current text-[#A4D7FA]"
                    />
                  ) : (
                    <PlusIcon />
                  )}
                </div>
                <div className={'flex shrink-0 gap-0.5 px-3 text-sbase'}>
                  Amount
                  {filterOperation !== AmountFilterOperation.ALL && (
                    <div className="hidden gap-0.5 lg:flex">
                      <span> {' | '} </span>
                      {getSecondaryText()}
                    </div>
                  )}
                </div>
                <div>
                  <DownArrowIcon className={open ? 'rotate-180' : ''} />
                </div>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute z-40 mt-0.5 min-w-[200px] bg-primary px-4 shadow-md sm:px-0">
                <div
                  className={
                    (open ? 'block ' : 'hidden ') + 'z-50  pt-7  list-none rounded-md border border-[#ACB5BD] w-fit'
                  }
                  style={{
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                  }}>
                  <div className="mb-4 flex flex-col gap-4">
                    <div className="px-[30px] text-sbase font-semibold  text-primaryText">Filter by amount</div>
                    <div className="flex flex-col gap-[15px] ">
                      {amountFilters.map(filter => renderFilterItem(filter))}
                    </div>
                  </div>

                  <div className="mt-2 flex w-full justify-end rounded-b-md bg-secondaryBtn px-4 py-2.5">
                    <div onClick={close} className="cursor-pointer text-sbase font-semibold text-accentText">
                      Cancel
                    </div>
                    <div
                      id="amount-filter-apply-button"
                      onClick={() => {
                        if (applyBtnDisabled()) return;
                        applyFilter();
                        close();
                      }}
                      className={`ml-5 cursor-pointer text-sbase font-semibold text-secondary
                       ${applyBtnDisabled() && 'opacity-50'}`}>
                      Apply
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default CustomAmountFilter;
