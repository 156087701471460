import { SearchWithBgIcon } from 'assets/icons';
import { FC } from 'react';

const PermissionError: FC = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-3 text-primaryText">
      <div>
        <SearchWithBgIcon />
      </div>
      <div className="text-sbase font-semibold">Required Permissions not found</div>
      <div className="text-center text-px13">
        Some permissions required to view this graph are missing. <br />
        Please contact team owner or admin for more details.
      </div>
    </div>
  );
};

export default PermissionError;
