import { SearchCloseIcon } from 'assets/icons';
import ItemsTabReactIcon from 'assets/react-icons/ItemsTabReactIcon';
import classnames from 'classnames';
import If from 'components/If/If';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

interface AddItemInputProps {
  value: string;
  handleChange: (text: string) => void;
  placeholder?: string;
  wrapperStyle?: string;
  showSearchIcon?: boolean;
  handleClear: VoidFn;
  autoFocus: boolean;
  showCrossIcon?: boolean;
  id: string;
  onFocusHandler?: VoidFn;
  alignRight?: boolean;
  inputWrapperStyle?: string;
  inputStyle?: string;
  isEditInvoiceFlow?: boolean;
  handleAddFromCatalogClick: () => void;
  handleKeyDown?: (event) => void;
  onClickInput?: () => void;
}

const AddItemInput: FC<AddItemInputProps> = props => {
  const {
    value,
    placeholder = '',
    handleChange,
    wrapperStyle = 'border border-borderGray rounded',
    showSearchIcon = true,
    handleClear,
    autoFocus = false,
    showCrossIcon = false,
    id,
    onFocusHandler = () => null,
    inputWrapperStyle = '',
    inputStyle = '',
    handleAddFromCatalogClick,
    handleKeyDown,
    onClickInput
  } = props;

  const onAddFromCatalogClick = (): void => {
    handleAddFromCatalogClick();
  };

  return (
    <div className={twMerge('w-full h-full', wrapperStyle)}>
      <div className={twMerge('flex h-full  items-center gap-2 px-5 py-[7px] text-[#212429]', inputWrapperStyle)}>
        <input
          id={id}
          onFocus={onFocusHandler}
          autoComplete="off"
          autoFocus={autoFocus}
          spellCheck="false"
          className={twMerge(
            'outline-none basis-3/4 bg-secondaryBtn placeholder-accentText text-sbase placeholder:font-semibold',
            classnames({
              'w-full': !showSearchIcon,
              'w-11/12': showSearchIcon
            }),
            inputStyle
          )}
          type="text"
          placeholder={placeholder}
          value={value ? value : ''}
          onChange={e => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
          onClick={onClickInput}
          maxLength={50}
        />
        <If condition={Boolean(value) && showCrossIcon}>
          <SearchCloseIcon
            className="path-stroke-current ml-auto mr-1 shrink-0 cursor-pointer stroke-[0.5px] text-accentText"
            onClick={handleClear}
          />
        </If>
        <div
          onClick={onAddFromCatalogClick}
          className="ml-auto flex min-w-[160px] cursor-pointer flex-row items-center">
          <ItemsTabReactIcon id="add-item" className="path-fill-current rect-stroke-current mr-2 text-secondary" />
          <div className="text-17px font-semibold text-secondary">Add from catalog</div>
        </div>
      </div>
    </div>
  );
};

export default AddItemInput;
