import React, { FC, Fragment } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { CrossIcon, DownArrowIcon, PlusIcon } from 'assets/icons';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';

interface DropdownWithSearchProps {
  data: any[];
  dataKey: string;
  selectedDraftArray: string[];
  setSelectedDraftArray: (selectedDraftArray: string[]) => void;
  selectedArray: string[];
  setSelectedArray: (selectedArray: string[]) => void;
  searchText?: string;
  setSearchText?: (searchText: string) => void;
  dataIdKey: string;
  buttonPrimaryText: string;
  buttonSecondaryText: string;
  filterPlaceholderText: string;
  searchPlaceHolderText?: string;
  allowSearch: boolean;
  showResultsCount?: boolean;
  customClassName?: string;
  filterId: string;
  secondaryDataKey?: string;
  searchFilterId?: string;
}

const DropdownWithSearch: FC<DropdownWithSearchProps> = props => {
  const {
    data,
    dataKey,
    selectedDraftArray,
    setSelectedDraftArray,
    selectedArray,
    setSelectedArray,
    searchText = '',
    setSearchText = () => ({}),
    dataIdKey,
    buttonPrimaryText,
    buttonSecondaryText,
    filterPlaceholderText,
    searchPlaceHolderText = '',
    allowSearch,
    showResultsCount = true,
    customClassName,
    filterId,
    secondaryDataKey = '',
    searchFilterId
  } = props;

  const applyFilter = () => {
    setSelectedArray([...selectedDraftArray]);
  };

  const handleChange = id => {
    const isChecked = selectedDraftArray.includes(id);
    if (isChecked) {
      setSelectedDraftArray(selectedDraftArray.filter(customerId => customerId !== id));
    } else {
      setSelectedDraftArray([...selectedDraftArray, id]);
    }
  };

  const filterData = () => {
    const tempFilterData = data.filter(_ => {
      const name = `${_[dataKey]} ${secondaryDataKey ? _[secondaryDataKey] : ''}`;
      return name.toLowerCase().includes(searchText.toLowerCase());
    });
    return tempFilterData;
  };

  const filteredData = filterData();

  const bulkSelectAction = () => {
    if (selectedDraftArray.length === filteredData.length) {
      setSelectedDraftArray([]);
    } else {
      setSelectedDraftArray(filteredData.map(_ => _[dataIdKey]));
    }
  };

  return (
    <div>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={twMerge(
                `mr-1 mb-1  shrink-0 rounded-md border 
                bg-secondaryBtn px-3 py-2 text-base font-semibold text-primaryText  
                shadow outline-none transition-all  duration-150 ease-linear focus:outline-none 
                ${selectedArray.length > 0 ? ' border-solid' : 'border-dashed'} `,
                classNames({ [customClassName]: customClassName })
              )}
              id={filterId}
              style={{ borderColor: selectedArray.length > 0 ? '#3876BB' : '#ACB5BD' }}
              onClick={() => {
                setSearchText('');
                setSelectedDraftArray(selectedArray);
              }}
              type="button">
              <div className={'flex shrink-0 flex-row items-center'}>
                <div>
                  {selectedArray.length ? (
                    <CrossIcon
                      onClick={e => {
                        e.stopPropagation();
                        setSelectedArray([]);
                        setSelectedDraftArray([]);
                      }}
                      className="fill-current text-[#A4D7FA]"
                    />
                  ) : (
                    <PlusIcon />
                  )}
                </div>
                <div id={`${filterId}-text`} className={'shrink-0 px-3 text-sbase'}>
                  {buttonPrimaryText}
                  {buttonSecondaryText !== '' && (
                    <div className="hidden lg:inline">
                      <span> {' | '} </span>
                      <span className={'text-secondary'}> {buttonSecondaryText} </span>
                    </div>
                  )}
                </div>
                <div>
                  <DownArrowIcon className={open ? 'rotate-180' : ''} />
                </div>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute z-40 mt-0.5 min-w-[200px] bg-primary px-4 shadow-md sm:px-0">
                <div
                  className={
                    (open ? 'block ' : 'hidden ') + 'z-50 pt-4  list-none rounded-md border border-[#ACB5BD] w-52'
                  }
                  style={{
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                  }}>
                  <div className="px-3 text-sbase font-normal text-primaryText">{filterPlaceholderText}</div>
                  {allowSearch && (
                    <div className="px-3 pb-1 pt-2.5">
                      <input
                        type="text"
                        id={searchFilterId}
                        onChange={e => setSearchText(e.target.value)}
                        className="block h-8 w-full
                             rounded-md border border-gray-1 px-2 py-1 text-sbase text-gray-900"
                        placeholder={searchPlaceHolderText}
                        required
                      />
                    </div>
                  )}
                  {showResultsCount ? (
                    <div className="flex justify-between px-3">
                      <div className=" pb-4 pt-1 text-px13 text-primaryText">
                        Showing {filteredData.length} of {filteredData.length}
                      </div>
                      {filteredData.length > 0 && (
                        <div
                          className="ml-4 cursor-pointer pb-4 pt-1 text-px13 text-secondary"
                          onClick={bulkSelectAction}>
                          {selectedDraftArray.length === filteredData.length ? 'Deselect all' : 'Select all'}
                        </div>
                      )}
                    </div>
                  ) : (
                    <div className="pb-4 pt-1" />
                  )}
                  <div
                    className="customNormalScroll overflow-y-auto px-3"
                    style={{ maxHeight: window.innerHeight * 0.33 }}>
                    {filteredData?.map((item, index) => {
                      return (
                        <div className="mb-4 flex items-center" key={`${item[dataKey]}--${item[dataIdKey]}`}>
                          <input
                            id={`default-checkbox-${item[dataIdKey]}`}
                            type="checkbox"
                            value=""
                            onChange={e => {
                              e.stopPropagation();
                              handleChange(item[dataIdKey]);
                            }}
                            checked={selectedDraftArray.includes(item[dataIdKey])}
                            className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
                          />
                          <label
                            htmlFor={`default-checkbox-${item[dataIdKey]}`}
                            className="ml-4 break-words text-sbase font-medium line-clamp-2">
                            {`${item[dataKey]} ${secondaryDataKey ? item[secondaryDataKey] : ''}`}
                          </label>
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-2 flex w-full justify-end rounded-b-md bg-secondaryBtn px-4 py-2.5">
                    <div onClick={close} className="cursor-pointer text-sbase font-semibold text-accentText">
                      Cancel
                    </div>
                    <div
                      id={`${filterId}-apply-button`}
                      onClick={() => {
                        applyFilter();
                        close();
                      }}
                      className="ml-5 cursor-pointer text-sbase font-semibold text-secondary">
                      Apply
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default DropdownWithSearch;
