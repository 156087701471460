import { BankSubmittedIcon, ChevronArrowRightIcon, RoundBankIcon } from 'assets/icons';
import { BankAccountCardType } from 'containers/payouts/constants';
import { FC } from 'react';

interface AddBankAccountCardProps {
  isEnabled?: boolean;
  type: BankAccountCardType;
  showArrow?: boolean;
}

const AddBankAccountCard: FC<AddBankAccountCardProps> = ({ isEnabled, type, showArrow = true }) => {
  const getText = () => {
    switch (type) {
      case BankAccountCardType.ADD_BANK_ACCOUNT:
        return {
          text: 'Add your bank account',
          subText: isEnabled
            ? 'Securely connect to your bank account to receive payouts'
            : 'You need to submit your payments application before connecting your bank account',
          Icon: RoundBankIcon
        };
      case BankAccountCardType.VERIFICATION_IN_PROGRESS:
        return { text: 'Bank account submitted', subText: 'Verifying your details...', Icon: BankSubmittedIcon };
      default:
    }
  };

  const { text = '', subText = '', Icon } = getText() ?? {};

  return (
    <div
      className={`flex w-full items-center rounded-md ${
        isEnabled ? 'border-2 border-secondary bg-[#DBEFFD]' : 'border border-secondaryBtn bg-primary'
      }  p-[15px]`}>
      <Icon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-semibold text-primaryText">{text}</div>
        <div className="text-sbase text-primaryText">{subText}</div>
      </div>
      {showArrow && <ChevronArrowRightIcon className="ml-auto shrink-0" />}
    </div>
  );
};

export default AddBankAccountCard;
