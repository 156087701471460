import Lottie from 'lottie-react';
import { Dispatch, FC } from 'react';
import { useNavigate } from 'react-router-dom';

import { LoadingSpinnerAnimation } from 'assets/animations';
import { InviteSentIcon, PlusIconWhite } from 'assets/icons';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import routesPath from 'routes/RoutesPath';
import { CleverTapEventsManageTeam } from 'containers/manage-team/events';
import { logAnalyticEvent } from 'utils/analytics';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface NewInviteSentModalProps {
  showLoader?: boolean;
  open: boolean;
  toggleOpen: Dispatch<React.SetStateAction<boolean>>;
}

const NewInviteSentModal: FC<NewInviteSentModalProps> = ({ showLoader, open, toggleOpen }) => {
  const navigate = useNavigate();

  const handleCreateNewInviteClick = () => {
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamInviteMore);
    navigate(routesPath.CREATE_INVITE);
  };

  const onCancelClick = () => {
    toggleOpen(false);
  };

  return (
    <CustomModal width="md:w-96" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded-md p-10">
        <div className="flex flex-col items-center justify-center">
          {showLoader ? (
            <div className="flex w-full flex-col items-center justify-center p-16">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          ) : (
            <>
              <FormCloseReactIcon
                id="new-invite-sent-modal-close"
                height="24"
                width="24"
                className="absolute top-2 right-2 shrink-0 cursor-pointer"
                onClick={onCancelClick}
              />
              <div className="flex w-full flex-col items-center justify-center">
                <InviteSentIcon />
                <p className="mt-5 text-xl font-bold leading-6 text-heading">Invite Sent</p>
                <p className="mt-3 text-17px font-normal leading-5 text-primaryText">
                  The invite link will be valid for 1 day.
                </p>
              </div>
              <CustomButton
                childrenContainerStyles="items-center"
                StartIcon={PlusIconWhite}
                id="new-invite-sent-modal-invite-more-button"
                onClick={handleCreateNewInviteClick}
                className={`text-semibold mt-6 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
                Invite more
              </CustomButton>
              <CustomButton
                id="new-invite-sent-modal-cancel-button"
                className="mt-6 h-[38px]  w-full"
                type={ButtonType.SECONDARY}
                onClick={onCancelClick}>
                Done
              </CustomButton>
            </>
          )}
        </div>
      </div>
    </CustomModal>
  );
};

export default NewInviteSentModal;
