export enum ServiceChargeActionType {
  EDIT = 'Edit',
  DELETE = 'Delete'
}

export enum ServiceChargeEnum {
  CURRENCY = '$',
  PERCENTAGE = '%'
}

export enum ServiceChargeInputErrors {
  Name_Error = 'Please enter a valid name',
  Amount_Error = 'Please enter a valid amount',
  Percentage_Error = 'Please enter a valid percentage'
}

export enum ServiceChargeInputId {
  serviceChargeAmount = 'service-charge-amount',
  serviceChargePercentage = 'service-charge-percentage'
}

export enum ServiceChargeFromInvoiceInputId {
  serviceChargeAmount = 'service-charge-from-invoice-amount',
  serviceChargePercentage = 'service-charge-from-invoice-percentage'
}

export interface ServiceChargeEntity {
  type: ServiceChargeEnum;
  value: string;
  id: ServiceChargeInputId | ServiceChargeFromInvoiceInputId;
  amount?: string;
  percentage?: string;
}
