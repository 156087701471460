import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

export const FireBaseCollections = {
  Merchants: {
    collectionName: 'Merchants',
    fields: {
      seenWhatsNewWeb: 'seenWhatsNewWeb',
      permissionUpdatedWeb: 'PermissionUpdatedWeb',
      permissionLastUpdatedAt: 'PermissionLastUpdatedAt',
      seenNewSignatureModalWeb: 'seenNewSignatureModalWeb'
    }
  },
  Notifications: {
    collectionName: 'Notifications'
  },
  Teams: {
    collectionName: 'Teams'
  }
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
export { db };
