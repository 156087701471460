import { useCallback, useEffect, useRef, useState } from 'react';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks/typedHooks';
import { useLazyGetCategoryItemListQuery } from '../api';
import { IInvoiceListingCategory, IInvoiceListingItem } from '../invoices.model';

export interface CategoryItemListQueryParams {
  search: string;
}

export const initialQueryParamsState: CategoryItemListQueryParams = {
  search: null
};

export interface ICategoryItemListData {
  categories: IInvoiceListingCategory[];
  lineItems: IInvoiceListingItem[];
}

const initCategoryItemListData: ICategoryItemListData = {
  categories: [],
  lineItems: []
};

export const usePaginatedCategoryItemListQuery = () => {
  const [categoryItemListData, setCategoryItemListData] = useState<ICategoryItemListData>(initCategoryItemListData);
  const [isMounted, setIsMounted] = useState(false);
  const [queryParams, setQueryParams] = useState<CategoryItemListQueryParams>({ ...initialQueryParamsState });
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [totalCategoryListCount, setTotalCategoryListCount] = useState<number>(0);
  const [totalLineItemListCount, setTotalLineItemListCount] = useState<number>(0);

  const page = useRef(0);
  const [GetCategoryItemListingsQuery, listQueryState] = useLazyGetCategoryItemListQuery();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!listQueryState?.isFetching && listQueryState.isSuccess) {
      const totalCategoryCount = listQueryState?.data?.categories?.meta?.totalCount;
      const totalItemCount = listQueryState?.data?.lineItems?.meta?.totalCount;
      const totalCount = totalCategoryCount + totalItemCount;
      const currentCategoryList: IInvoiceListingCategory[] = listQueryState?.data?.categories?.data ?? [];
      const currentItemList: IInvoiceListingItem[] = listQueryState?.data?.lineItems?.data ?? [];

      const listData: ICategoryItemListData = {
        categories: currentCategoryList,
        lineItems: currentItemList
      };

      const updatedListData: ICategoryItemListData = isInitListCall
        ? listData
        : ({
            categories: [...categoryItemListData.categories, ...listData.categories],
            lineItems: [...categoryItemListData.lineItems, ...listData.lineItems]
          } as ICategoryItemListData);

      const listItemsLeftToFetch = totalCount
        ? +totalCount - (updatedListData.categories.length + updatedListData.lineItems.length)
        : 0;

      setHasMore(listItemsLeftToFetch > 0);
      setCategoryItemListData(updatedListData);
      if (page.current === 1) {
        setTotalListCount(totalCount ?? 0);
        setTotalCategoryListCount(totalCategoryCount);
        setTotalLineItemListCount(totalItemCount);
      }
      setIsLoading(false);
    }
  }, [listQueryState?.isFetching]);

  useEffect(() => {
    if (!listQueryState?.isFetching && listQueryState?.isError) {
      setIsLoading(false);
      setHasMore(false);
    }
  }, [listQueryState?.isError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setCategoryItemListData(initCategoryItemListData);
    loadMore();
  };

  useEffect(() => {
    if (isMounted) {
      resetListAndLoadMore();
    } else {
      setIsMounted(true);
    }
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    if (currentTeam?.id) {
      const remainingParams = queryParams;

      try {
        GetCategoryItemListingsQuery({
          teamId: currentTeam?.id,
          limit: 10,
          page: page.current,
          ...remainingParams
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetRecallQuery = () => {
    resetListAndLoadMore();
  };

  const resetQuery = () => {
    page.current = 0;
    setCategoryItemListData(initCategoryItemListData);
    setQueryParams(initialQueryParamsState);
    setIsMounted(false);
    setIsError(false);
    setHasMore(true);
    setTotalListCount(0);
    setTotalCategoryListCount(0);
    setTotalLineItemListCount(0);
  };

  return {
    isLoading: isLoading,
    isFetching: listQueryState?.isFetching,
    totalListCount,
    totalCategoryListCount,
    totalLineItemListCount,
    categoryItemListData,
    isError: listQueryState.isError,
    loadMore,
    resetRecallQuery,
    resetQuery,
    hasMore,
    setQueryParams,
    queryParams,
    isInitListCall,
    isLoadingSuccess: listQueryState.isSuccess,
    apiErrorCode: listQueryState?.error?.code
  };
};
