import { EditIconBlue } from 'assets/icons';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface TipChoicesProps {
  handleEditClick: VoidFn;
  tipChoices: number[];
  isTipEnabled: boolean;
}

const TipChoices: FC<TipChoicesProps> = ({ handleEditClick, tipChoices, isTipEnabled }) => {
  const TipLabels = ['First choice', 'Second choice', 'Third choice'];

  const renderTipChoice = (label, tip) => {
    return (
      <div className="mb-5 flex items-center justify-between">
        <div className="w-1/2 text-sbase font-semibold text-primaryText">{label}</div>
        <div className="w-1/2 text-sbase font-semibold text-primaryText">{tip}%</div>
      </div>
    );
  };
  return (
    <div className={isTipEnabled ? '' : 'pointer-events-none opacity-70'}>
      <div className="rounded-md border border-borderGray p-5 lg:w-3/5">
        <div className="mb-2.5 flex items-center justify-between">
          <div className="text-17px font-semibold text-primaryText">Tip Choices</div>
          <div className="cursor-pointer" onClick={handleEditClick} role="presentation">
            <EditIconBlue width={18} />
          </div>
        </div>
        <div className="mb-5 text-sbase text-primaryText">The options created here are displays for customers.</div>
        {tipChoices.map((tip, index) => renderTipChoice(TipLabels[index], tip))}
      </div>
    </div>
  );
};

export default TipChoices;
