import React, { FC, Fragment, useEffect, useState } from 'react';
import { Popover, Transition } from '@headlessui/react';
import { CrossIcon, DownArrowIcon, PlusIcon } from 'assets/icons';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import CustomInput from 'components/custom-input.tsx/CustomInput';
import { REGEX } from 'constants/common';

interface DropdownWithSearchProps {
  minValue: string;
  maxValue: string;
  setValues: (val: Record<string, string>) => void;
  customClassName?: string;
  filterId: string;
  buttonPrimaryText: string;
  buttonSecondaryText: string;
  filterPlaceholderText: string;
}

const DropdownWithSearch: FC<DropdownWithSearchProps> = props => {
  const {
    customClassName,
    filterId,
    setValues,
    buttonPrimaryText,
    buttonSecondaryText,
    filterPlaceholderText,
    minValue,
    maxValue
  } = props;

  const [min, setMin] = useState('');
  const [max, setMax] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    setMin(minValue);
    setMax(maxValue);
  }, [minValue, maxValue]);

  const applyFilter = () => {
    setValues({ min, max });
  };

  const onChangeMinValue = value => {
    if (REGEX.amount.test(value)) {
      setError('');
      setMin(value);
    }
  };

  const onChangeMaxValue = value => {
    if (REGEX.amount.test(value)) {
      setError('');
      setMax(value);
    }
  };

  return (
    <div>
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={twMerge(
                `mr-1 mb-1  shrink-0 rounded-md border 
                bg-secondaryBtn px-3 py-2 text-base font-semibold text-primaryText  
                shadow outline-none transition-all  duration-150 ease-linear focus:outline-none 
                ${buttonSecondaryText ? ' border-solid' : 'border-dashed'} `,
                classNames({ [customClassName]: customClassName })
              )}
              id={filterId}
              style={{ borderColor: buttonSecondaryText ? '#3876BB' : '#ACB5BD' }}
              onClick={() => {
                setMax(maxValue);
                setMin(minValue);
              }}
              type="button">
              <div className={'flex shrink-0 flex-row items-center'}>
                <div>
                  {buttonSecondaryText ? (
                    <CrossIcon
                      onClick={e => {
                        e.stopPropagation();
                        setValues({ min: '', max: '' });
                        close();
                      }}
                      className="fill-current text-[#A4D7FA]"
                    />
                  ) : (
                    <PlusIcon />
                  )}
                </div>
                <div id={`${filterId}-text`} className={'shrink-0 px-3 text-sbase'}>
                  {buttonPrimaryText}
                  {buttonSecondaryText !== '' && (
                    <div className="hidden lg:inline">
                      <span> {' | '} </span>
                      <span className={'text-secondary'}> {buttonSecondaryText} </span>
                    </div>
                  )}
                </div>
                <div>
                  <DownArrowIcon className={open ? 'rotate-180' : ''} />
                </div>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel className="absolute z-40 mt-0.5 min-w-[200px] bg-primary px-4 shadow-md sm:px-0">
                <div
                  className={
                    (open ? 'block ' : 'hidden ') + 'z-50 pt-4  list-none rounded-md border border-[#ACB5BD] w-52'
                  }
                  style={{
                    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)'
                  }}>
                  <div className="px-3 text-sbase font-normal text-primaryText">{filterPlaceholderText}</div>
                  <div
                    className="customNormalScroll overflow-y-auto px-3"
                    style={{ maxHeight: window.innerHeight * 0.33 }}>
                    <div className="my-4 flex items-center justify-between gap-3.5">
                      <CustomInput
                        placeholder="Min"
                        value={min}
                        id="range-filter-min-val"
                        onChange={onChangeMinValue}
                        className={`h-[30px] border border-borderGray bg-primary  p-1.5
                        text-px13 placeholder:text-[13px] placeholder:text-accentText`}
                      />
                      <div className="text-px13 text-accentText">to</div>
                      <CustomInput
                        placeholder="Max"
                        value={max}
                        id="range-filter-max-val"
                        onChange={onChangeMaxValue}
                        className={`h-[30px] border border-borderGray bg-primary p-1.5
                        text-px13 placeholder:text-[13px] placeholder:text-accentText`}
                      />
                    </div>
                    {error && <div className="text-px13 text-error">{error}</div>}
                  </div>
                  <div className="flex w-full justify-end rounded-b-md bg-secondaryBtn px-4 py-2.5">
                    <div
                      onClick={() => {
                        close();
                        setError('');
                      }}
                      className="cursor-pointer text-sbase font-semibold text-accentText">
                      Cancel
                    </div>
                    <div
                      id={`${filterId}-apply-button`}
                      onClick={() => {
                        if (+max > +min) {
                          setError('');
                          applyFilter();
                          close();
                        } else {
                          setError('Max amount should be greater than min amount');
                        }
                      }}
                      className="ml-5 cursor-pointer text-sbase font-semibold text-secondary">
                      Apply
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default DropdownWithSearch;
