export enum CatalogTopBarItemsButton {
  CreateItemSaveButton = 'create-item-save-button',
  CreateItemBackButton = 'create-item-back-button',
  EditItemSaveButton = 'edit-item-save-button',
  EditItemDuplicateButton = 'edit-item-duplicate-button',
  EditItemDeleteButton = 'edit-item-delete-button',
  EditItemBackButton = 'edit-item-back-button',
  DuplicateItemSaveButton = 'duplicate-item-save-button',
  DuplicateItemBackButton = 'duplicate-item-back-button'
}

export const itemsButtonTargetArray = [
  CatalogTopBarItemsButton.CreateItemSaveButton,
  CatalogTopBarItemsButton.CreateItemBackButton,
  CatalogTopBarItemsButton.EditItemSaveButton,
  CatalogTopBarItemsButton.EditItemDuplicateButton,
  CatalogTopBarItemsButton.EditItemDeleteButton,
  CatalogTopBarItemsButton.EditItemBackButton,
  CatalogTopBarItemsButton.DuplicateItemSaveButton,
  CatalogTopBarItemsButton.DuplicateItemBackButton
];

export enum ItemsExportPopover {
  TAB_VIEW = 'items-export-popover-tab',
  WEB_VIEW = 'items-export-popover-web'
}
