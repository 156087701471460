import { FC } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { CarouselNextIcon, CarouselPrevIcon } from 'assets/icons';

import './styles.css';

interface CustomCarouselProps {
  children: JSX.Element[];
  showStatus?: boolean;
  className?: string;
  showArrows?: boolean;
  showThumbs?: boolean;
  autoPlay?: boolean;
  interval?: number;
  PrevIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  NextIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  prevIconStyles?: string;
  nextIconStyles?: string;
  centerSlidePercentage?: number;
}

const CustomCarousel: FC<CustomCarouselProps> = ({
  children,
  showStatus = false,
  className,
  showArrows = true,
  showThumbs = false,
  autoPlay = true,
  interval = 5000,
  PrevIcon = CarouselPrevIcon,
  NextIcon = CarouselNextIcon,
  prevIconStyles = 'left-0 top-[72px] h-10 w-10',
  nextIconStyles = 'right-0 top-[72px] h-10 w-10',
  centerSlidePercentage = 95
}) => {
  const renderArrowPrev = (onClickHandler, hasPrev = false) => {
    return showArrows && hasPrev ? (
      <div
        className={`absolute z-10 cursor-pointer rounded-full bg-white ${prevIconStyles}`}
        role="presentation"
        onClick={onClickHandler}>
        <PrevIcon />
      </div>
    ) : null;
  };

  //TODO: update next arrow position based on figma update
  const renderArrowNext = (onClickHandler, hasNext = false) => {
    return showArrows && hasNext ? (
      <div
        className={`absolute  z-10 cursor-pointer rounded-full bg-white ${nextIconStyles}`}
        role="presentation"
        onClick={onClickHandler}>
        <NextIcon />
      </div>
    ) : null;
  };

  const renderIndicator = (onClickHandler, isSelected) => {
    if (showArrows) {
      if (isSelected) {
        return (
          <li
            role="button"
            className="mr-1.5 inline-block h-2 w-5 cursor-pointer rounded-full bg-primaryBtn"
            onClick={onClickHandler}
          />
        );
      }
      return (
        <li
          role="button"
          className="mr-1.5 inline-block h-2 w-2 cursor-pointer rounded-full bg-border"
          onClick={onClickHandler}
        />
      );
    }
    return null;
  };

  return (
    <Carousel
      showArrows={showArrows}
      showStatus={showStatus}
      className={className}
      centerSlidePercentage={centerSlidePercentage}
      centerMode={true}
      showThumbs={showThumbs}
      renderArrowPrev={renderArrowPrev}
      autoPlay={autoPlay}
      interval={interval}
      renderArrowNext={renderArrowNext}
      renderIndicator={renderIndicator}>
      {children}
    </Carousel>
  );
};

export default CustomCarousel;
