import { CustomInput, CustomSwitch, ListDropDown } from 'components';
import { FC } from 'react';
import { dropDownServiceFeeBasePoint } from '../constants';
import { GreyStrokeInfoIcon } from 'assets/icons';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { FeatureToggleOption, ServiceFeeBasePoint } from '../type';
import { VoidFn } from 'types/baseTypes';
import ServiceFeeDropDown from './ServiceFeeDropDown';

interface ServiceFeeSectionProps {
  className?: string;
  handleServiceFeeChange: (value: string) => void;
  serviceFeeError: string;
  selectedServiceFeeOption: FeatureToggleOption;
  onServiceFeeOptionClick: (option: FeatureToggleOption) => void;
  surcharge: Record<string, string | boolean>;
  updateServiceFeePercentage: VoidFn;
  selectedBasePoint: ServiceFeeBasePoint;
  onBasePointClick: (option: ServiceFeeBasePoint) => void;
  isServiceFeeEnabled: boolean;
  toggleServiceFee: VoidFn;
}

const ServiceFeeSection: FC<ServiceFeeSectionProps> = ({
  handleServiceFeeChange,
  serviceFeeError,
  selectedServiceFeeOption,
  onServiceFeeOptionClick,
  surcharge,
  updateServiceFeePercentage,
  selectedBasePoint,
  onBasePointClick,
  isServiceFeeEnabled,
  toggleServiceFee
}) => {
  return (
    <div>
      <div className="mb-6 flex items-center justify-between lg:w-3/4">
        <div className="text-xl font-bold text-heading">Service fee</div>
        <CustomSwitch id="service-fee-toggle-switch" enabled={isServiceFeeEnabled} toggleSwitch={toggleServiceFee} />
      </div>
      <div className="mb-6 text-17px text-primaryText">
        Service fee helps to streamline invoice creation for maximum efficiency.
      </div>
      {isServiceFeeEnabled && (
        <>
          <div className="flex w-full items-center justify-between gap-2">
            <div className="w-3/4 text-17px text-primaryText">
              Customize your Service Fee method to reflect accurately on your invoices
            </div>
            <ServiceFeeDropDown selectedItem={selectedServiceFeeOption} setSelectedItem={onServiceFeeOptionClick} />
          </div>

          <div className="my-6 rounded-md border border-borderGray px-8 py-4 lg:w-3/5">
            <div className="text-17px font-semibold text-[#212429]">Set your desired service fee</div>
            <div className="mt-5 flex items-center">
              <CustomInput
                id="fee-section-service-fee-percentage"
                value={surcharge.percentage}
                placeholder="0%"
                hasSuffix={true}
                suffix="%"
                onChange={handleServiceFeeChange}
                onBlur={updateServiceFeePercentage}
                suffixStyle="right-4 text-accentText text-[17px]"
                className={twMerge(
                  'w-[150px] pl-5 text-[17px] text-primaryText focus:outline-none',
                  classnames({
                    'border border-error': serviceFeeError
                  })
                )}
              />
              <div className="mx-3.5 text-xl font-bold text-accentText">+</div>
              <ListDropDown
                buttonStyles="bg-secondaryBtn h-[50px] text-[17px] font-semibold text-primaryText"
                optionStyles="py-2.5 min-w-[150px]"
                items={dropDownServiceFeeBasePoint}
                selectedItem={selectedBasePoint}
                setSelectedItem={onBasePointClick}
                wrapperStyles="mb-1 bg-primary w-[150px] focus:outline-none"
                id="service-fee-base-point-dropdown"
              />
            </div>
          </div>
          {serviceFeeError && <div className="mt-2 text-17px font-semibold text-error">{serviceFeeError}</div>}
          <div className="mt-5 flex items-center">
            <GreyStrokeInfoIcon height={18} width={18} />
            <div className="ml-1.5 text-sbase text-tertiaryText">
              Service fee is charged to the invoice subtotal including line items and discounts.
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default ServiceFeeSection;
