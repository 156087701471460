import { InvitePlusIcon, NoSearchResultsIcon, SearchIcon } from 'assets/icons';
import { CustomButton, If, SearchBar } from 'components';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { debounce } from 'utils/commonUtils';
import TeamMemberFilterSection from './components/TeamMemberFilterSection';
import TeamMembersTable from './components/TeamMembersTable';
import {
  initialTeamMembersQueryParamsState,
  usePaginatedGetTeamMembersQuery
} from './hooks/usePaginatedGetTeamMembersQuery';
import { ITeamMember, TeamMemberStatus } from './manageTeam.model';
import isEqual from 'lodash/isEqual';
import Divider from '@material-ui/core/Divider/Divider';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { IEditPermissionModalState, TeamMemberActionType } from './type';
import EditPermissionsModal from './edit-permissions/EditPermissionsModal';
import { Outlet, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import NewInviteSentModal from './create-invite/components/NewInviteSentModal';
import { useAppDispatch, useAppSelector } from 'hooks';
import DeactivateTeamMemberModal from './components/DeactivateTeamMemberModal';
import DeleteInviteModal from './components/DeleteInviteModal';
import ReactivateMemberModal from './components/ReactivateMemberModal';
import {
  useDeactivateMemberMutation,
  useDeleteInviteMutation,
  useReactivateMemberMutation,
  useShareInviteLinkMutation
} from 'containers/manage-team/api';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamTeamSettingsPermissions,
  selectMerchantTeams,
  setCurrentTeam,
  showNotifier
} from 'containers/app/appSlice';
import InviteSentDetailsModal from './components/InviteSentDetailsModal';
import { TeamRole } from 'containers/app/app.model';
import { ManageTeamRouteState } from 'routes/types';
import { emptyTeamMember } from './constants';
import { NotifierTypes } from 'constants/notifierConstants';
import { InfoMesssages } from 'types/infoMessages';
import classnames from 'classnames';
import SendInviteSidePanel from './components/SendInviteSidePanel';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsManageTeam } from './events';

interface ManageTeamProps {
  className?: string;
}

interface IMemberActivationModalState {
  open: boolean;
  teamMember: ITeamMember;
}

const ManageTeam: FC<ManageTeamProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const currentTeam = useAppSelector(selectCurrentTeam);
  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showFilters, setShowFilters] = useState(false);
  const [editPermissionsModalState, setEditPermissionsModalState] = useState<IEditPermissionModalState>({
    open: false,
    teamMember: emptyTeamMember
  });
  const [deactivateMemberModalState, setDeactivateMemberModalState] = useState<IMemberActivationModalState>({
    open: false,
    teamMember: emptyTeamMember
  });
  const [reactivateMemberModalState, setReactivateMemberModalState] = useState<IMemberActivationModalState>({
    open: false,
    teamMember: emptyTeamMember
  });
  const [openSendInviteSidePanel, setOpenSendInviteSidePanel] = useState<IMemberActivationModalState>({
    open: false,
    teamMember: emptyTeamMember
  });
  const [deleteInviteModalState, setDeleteInviteModalState] = useState<IMemberActivationModalState>({
    open: false,
    teamMember: emptyTeamMember
  });
  const [selectedStatuses, setSelectedStatuses] = useState<TeamMemberStatus[]>([]);
  const [selectedMemberRoles, setSelectedMemberRoles] = useState<TeamRole[]>([]);
  const [currentTeamMemberId, setCurrentTeamMemberId] = useState('');
  const [selectedTeamMembers, setSelectedTeamMembers] = useState<Array<string>>([]);
  const [openInviteSentModal, setOpenInviteSentModal] = useState(false);
  const [deactivateMemberMutation, { isSuccess: isDeactivateSuccess, isError: isDeactivateError }] =
    useDeactivateMemberMutation();
  const [reactivateMemberMutation, { isSuccess: isReactivateSuccess, isError: isReactivateError }] =
    useReactivateMemberMutation();
  const [shareInviteLinkMutation] = useShareInviteLinkMutation();
  const [
    deleteInviteMutation,
    { isSuccess: isDeleteInviteSuccess, isLoading: isDeleteInviteMutationInProgress, isError: isDeleteInviteError }
  ] = useDeleteInviteMutation();
  const [openInvitedModal, setOpenInvitedModal] = useState<IMemberActivationModalState>({
    open: false,
    teamMember: emptyTeamMember
  });
  const currentTeamTeamSettingsPermissions = useAppSelector(selectCurrentTeamTeamSettingsPermissions);
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(getNotifications);
  const merchantTeams = useAppSelector(selectMerchantTeams);
  const [searchParams] = useSearchParams();
  const teamId = searchParams.get('teamId');

  const {
    list: teamMemberList,
    isLoading: isLoadingTeamMembers,
    loadMore,
    resetQuery: resetTeamMembersQuery,
    hasMore: hasMoreTeamMembers,
    setQueryParams: setTeamMembersQueryParams,
    queryParams: teamMembersQueryParams,
    totalListCount: teamMembersCount,
    isLoadingSuccess: isTeamMemberListLoadingSuccess
  } = usePaginatedGetTeamMembersQuery();

  const teamMemberListLoader = useRef(loadMore);

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: teamMemberListLoader,
    threshold: 0.5
  });

  useEffect(() => {
    resetTeamMembersQuery();
    setShowFilters(false);
    setSelectedMemberRoles([]);
    setSelectedStatuses([]);
  }, [currentTeam?.id]);

  useEffect(() => {
    if (teamId) {
      if (+teamId !== currentTeam?.id) {
        const merchantTeam = merchantTeams.find(team => team.id === +teamId);
        if (merchantTeam) {
          dispatch(setCurrentTeam(merchantTeam));
          navigate(routesPath.MANAGE_TEAM, { replace: true });
        }
      }
    }
  }, [teamId]);

  useEffect(() => {
    if (!isLoadingTeamMembers && teamMemberList.length > 0) {
      setShowFilters(true);
    }
  }, [isLoadingTeamMembers]);

  useEffect(() => {
    const routeState = location.state as ManageTeamRouteState;
    if (routeState?.showInviteSentModal) {
      setOpenInviteSentModal(true);
      navigate(routesPath.MANAGE_TEAM, { replace: true, state: { showInviteSentModal: false } });
    }
    if (routeState?.reloadList) {
      resetTeamMembersQuery();
      navigate(routesPath.MANAGE_TEAM, { replace: true, state: { reloadList: false } });
    }
  }, [location]);

  useEffect(() => {
    setSelectedTeamMembers([]);
  }, [teamMembersQueryParams]);

  useEffect(() => {
    let updatedParams = {
      ...teamMembersQueryParams,
      status: selectedStatuses,
      role: selectedMemberRoles
    };
    updatedParams = { ...updatedParams };
    setTeamMembersQueryParams(prevQueryParams => ({
      ...prevQueryParams,
      ...updatedParams
    }));
  }, [selectedStatuses, selectedMemberRoles]);

  useEffect(() => {
    teamMemberListLoader.current = loadMore;
  }, [loadMore]);

  const handleSearchIconClick = () => {
    setSearchIconClicked(true);
  };

  const initiateSearch = (searchTerm?: string) => {
    setTeamMembersQueryParams(prevQueryParams => ({ ...prevQueryParams, search: searchTerm?.trim() || null }));
  };

  const onClearSearch = () => {
    setSearchText('');
    initiateSearch(null);
  };

  const debouncedSearchHandler = useCallback(debounce(initiateSearch, 500), [teamMembersQueryParams]);

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    debouncedSearchHandler(searchTerm);
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handleInviteTeamMember = (id: string) => {
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamCreateInvite);
    if (!currentTeamTeamSettingsPermissions?.inviteNewMember) {
      onInfoClick(id, InfoMesssages.inviteTeamMemberNoPermissionMessage);
      return;
    }
    navigate(routesPath.CREATE_INVITE);
  };

  const isInitialQueryParamsState = () => {
    return isEqual(teamMembersQueryParams, initialTeamMembersQueryParamsState);
  };

  const isTeamMemberResultEmpty =
    teamMemberList?.length === 0 &&
    !isLoadingTeamMembers &&
    teamMembersQueryParams.search === null &&
    isInitialQueryParamsState() &&
    isTeamMemberListLoadingSuccess;

  const isFilterResultEmpty =
    teamMemberList?.length === 0 &&
    !isLoadingTeamMembers &&
    !isInitialQueryParamsState() &&
    teamMembersQueryParams.search === null;

  const isSearchResultEmpty =
    teamMemberList?.length === 0 &&
    !isLoadingTeamMembers &&
    teamMembersQueryParams.search !== null &&
    teamMembersQueryParams.search !== '';

  const isSelectedTeamMember = (teamMember: ITeamMember) => {
    return selectedTeamMembers.find((id: string) => teamMember.id === id);
  };

  const onTeamMemberClick = (teamMemberId: string) => {
    navigate(`${currentTeam.id}/${teamMemberId}/member`);
  };

  const handleTeamMemberClick = (teamMember: ITeamMember) => {
    if (teamMember.isExpired) {
      if (currentTeamTeamSettingsPermissions?.inviteNewMember) {
        handleSendInvitePanelOpen(teamMember);
      }
    } else if (teamMember.status === TeamMemberStatus.INVITED) {
      if (currentTeamTeamSettingsPermissions?.inviteNewMember) {
        setOpenInvitedModal({ open: true, teamMember });
      }
    } else if (currentTeamMemberId !== teamMember.merchantId) {
      if (currentTeamTeamSettingsPermissions?.viewExistingMember) {
        onTeamMemberClick(teamMember.merchantId);
      }
    }
  };

  const handleSendInvitePanelOpen = (teamMember: ITeamMember) => {
    setOpenSendInviteSidePanel({ open: true, teamMember: teamMember });
  };

  const handleSendInvitePanelClose = () => {
    setOpenSendInviteSidePanel({ open: false, teamMember: emptyTeamMember });
  };

  const handleInvitedModalClose = () => {
    setOpenInvitedModal({ open: false, teamMember: emptyTeamMember });
  };

  const handleCloseSidePanel = () => {
    setCurrentTeamMemberId('');
  };

  const handleMoreActionsClick = ({
    teamMemberAction,
    teamMember
  }: {
    teamMemberAction: TeamMemberActionType;
    teamMember: ITeamMember;
  }) => {
    switch (teamMemberAction) {
      case TeamMemberActionType.EDIT_PERMISSIONS:
        toggleEditPermissionsModal(teamMember);
        return;
      case TeamMemberActionType.DEACTIVATE:
        toggleDeactivateMemberModal(teamMember);
        return;
      case TeamMemberActionType.REACTIVATE:
        toggleReactivateMemberModal(teamMember);
        return;
      case TeamMemberActionType.SEND_NEW_INVITE:
        handleSendInvitePanelOpen(teamMember);
        return;
      case TeamMemberActionType.SHARE_INVITE_LINK:
        shareLinkHandler(teamMember);
        return;
      case TeamMemberActionType.DELETE_INVITE:
        toggleDeleteInviteModal(teamMember);
    }
  };

  const deactivateMemberHandler = (teamMember: ITeamMember) => {
    deactivateMemberMutation({
      merchantId: teamMember.merchantId,
      teamId: currentTeam.id
    });
    toggleDeactivateMemberModal(teamMember);
  };

  useEffect(() => {
    if (isDeactivateSuccess || isReactivateSuccess || isDeleteInviteSuccess) {
      resetTeamMembersQuery();
      if (isDeleteInviteSuccess) toggleDeleteInviteModal();
    }
    if (openInvitedModal.open && isDeleteInviteSuccess) {
      handleInvitedModalClose();
    }
  }, [isDeactivateSuccess, isReactivateSuccess, isDeleteInviteSuccess]);

  const reactivateMemberHandler = (teamMember: ITeamMember) => {
    reactivateMemberMutation({
      merchantId: teamMember.merchantId,
      teamId: currentTeam.id
    });
    toggleReactivateMemberModal(teamMember);
  };

  const shareLinkHandler = (teamMember: ITeamMember) => {
    shareInviteLinkMutation({
      inviteId: teamMember.inviteId,
      teamId: currentTeam.id?.toString()
    });
  };

  const deleteInviteHandler = (teamMember: ITeamMember) => {
    deleteInviteMutation({
      inviteId: teamMember.inviteId,
      teamId: currentTeam.id?.toString()
    });
  };

  const inviteModalDeleteInviteHandler = (teamMember: ITeamMember) => {
    toggleDeleteInviteModal(teamMember);
  };

  const toggleDeactivateMemberModal = (teamMember?: ITeamMember) => {
    if (deactivateMemberModalState.open) {
      setDeactivateMemberModalState({ open: false, teamMember: emptyTeamMember });
      return;
    }
    setDeactivateMemberModalState({ open: true, teamMember: teamMember });
  };

  const toggleDeleteInviteModal = (teamMember?: ITeamMember) => {
    if (openInvitedModal.open) {
      setOpenInvitedModal({ open: false, teamMember: emptyTeamMember });
    }
    if (deleteInviteModalState.open) {
      setDeleteInviteModalState({ open: false, teamMember: emptyTeamMember });
      return;
    }
    setDeleteInviteModalState({ open: true, teamMember: teamMember });
  };

  const toggleReactivateMemberModal = (teamMember?: ITeamMember) => {
    if (reactivateMemberModalState.open) {
      setReactivateMemberModalState({ open: false, teamMember: emptyTeamMember });
      return;
    }

    setReactivateMemberModalState({ open: true, teamMember: teamMember });
  };

  const toggleEditPermissionsModal = (teamMember?: ITeamMember) => {
    if (editPermissionsModalState.open) {
      setEditPermissionsModalState({ open: false, teamMember: emptyTeamMember });
      return;
    }

    setEditPermissionsModalState({ open: true, teamMember: teamMember });
  };

  const onPermissionEditSuccess = () => {
    resetTeamMembersQuery();
  };

  const onSearchFocus = () => {
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamSearch);
  };

  return (
    <div className="overflow-y flex h-full flex-col px-6 pb-20 pt-6 sm:p-6">
      <div className="mb-5 flex flex-row gap-3">
        <div className="shrink-0 text-3xl font-bold text-primaryText">Manage Team</div>
        <If condition={!searchIconClicked}>
          <div onClick={handleSearchIconClick}>
            <SearchIcon className="mx-2 flex lg:hidden" />
          </div>
        </If>
        <SearchBar
          onFocusHandler={onSearchFocus}
          id="manage-team-search-bar"
          handleClear={onClearSearch}
          handleChange={onSearchTermChange}
          value={searchText}
          placeholder="Search Members"
          wrapperStyle={`sm:w-48 h-10 border border-borderGray rounded 
         ${!searchIconClicked ? 'lg:flex hidden' : ''}`}
          showSearchIcon={true}
          showCrossIcon={true}
          autoFocus={false}
        />
        <div className="ml-auto self-end">
          <CustomButton
            id="invite-team-member-button"
            onClick={() => handleInviteTeamMember('invite-team-member-button')}
            showDisabledReason={!currentTeamTeamSettingsPermissions?.inviteNewMember}
            className={twMerge(
              'h-[38px] w-[200px] px-0 text-primaryBtnText',
              classnames({ 'w-52': !currentTeamTeamSettingsPermissions?.inviteNewMember })
            )}
            StartIcon={InvitePlusIcon}
            childrenContainerStyles="mr-1">
            <div className="text-sbase font-semibold">Invite Team Member</div>
          </CustomButton>
        </div>
      </div>

      <If condition={showFilters}>
        <div>
          <TeamMemberFilterSection
            selectedTeamMemberStatuses={selectedStatuses}
            setSelectedTeamMemberStatuses={setSelectedStatuses}
            selectedMemberRoles={selectedMemberRoles}
            setSelectedMemberRoles={setSelectedMemberRoles}
          />
        </div>
      </If>

      <div>
        <If condition={teamMemberList.length !== 0}>
          <If condition={teamMembersQueryParams.search !== null && searchText !== ''}>
            <div className="py-5 text-sbase text-accentText">{`${teamMembersCount} ${
              teamMembersCount <= 1 ? 'result' : 'results'
            }`}</div>
          </If>
          <If condition={searchText === ''}>
            <div className="pt-5 pb-4 text-sbase text-accentText">{` Total ${teamMembersCount} ${
              teamMembersCount === 1 ? 'member' : 'members'
            } `}</div>
          </If>
          <Divider className="w-full bg-gray-100" />
        </If>
      </div>
      <div
        className={`mt-top customNormalScroll grow ${isLoadingTeamMembers && hasMoreTeamMembers ? 'pb-0' : 'pb-20'}`}>
        <If condition={isTeamMemberResultEmpty}>
          <div className="flex h-full flex-col items-center justify-center gap-5">
            <div className="flex flex-col items-center gap-5">
              <div className="text-sbase text-primaryText">No team members present</div>
            </div>
            <div>invite new member</div>
          </div>
        </If>

        <If condition={isFilterResultEmpty}>
          <div className="flex h-full flex-col items-center justify-center gap-5">
            <div className="flex flex-col items-center gap-5">
              <NoSearchResultsIcon />
              <div className="text-sbase text-primaryText">No Team members found for selected filters</div>
            </div>
          </div>
        </If>

        <If condition={isSearchResultEmpty}>
          <div className="flex h-full flex-col items-center justify-center gap-5">
            <div className="flex flex-col items-center gap-5">
              <NoSearchResultsIcon />
              <div className="text-sbase text-primaryText">No results found!</div>
            </div>
          </div>
        </If>
        <If condition={teamMemberList?.length !== 0}>
          <TeamMembersTable
            currentSelectedId={currentTeamMemberId}
            isSelectedTeamMember={isSelectedTeamMember}
            onTeamMemberClick={handleTeamMemberClick}
            teamMemberList={teamMemberList}
            handleMoreActionsClick={handleMoreActionsClick}
          />
        </If>

        <If condition={!isLoadingTeamMembers && hasMoreTeamMembers}>
          <div className="h-2" ref={setIntersectionElement}></div>
        </If>
        <If condition={isLoadingTeamMembers}>
          <InfinteLoadingSpinner />
        </If>
        <If condition={openInviteSentModal}>
          <NewInviteSentModal open={openInviteSentModal} toggleOpen={setOpenInviteSentModal} />
        </If>
      </div>
      {editPermissionsModalState.open && (
        <EditPermissionsModal
          open={editPermissionsModalState.open}
          teamMemberData={editPermissionsModalState.teamMember}
          toggleShowEditPermissionsModal={toggleEditPermissionsModal}
          editSuccessCallback={onPermissionEditSuccess}
        />
      )}

      {deactivateMemberModalState.open && (
        <DeactivateTeamMemberModal
          deactivateMemberHandler={deactivateMemberHandler}
          showLoader={false}
          open={deactivateMemberModalState.open}
          teamMember={deactivateMemberModalState.teamMember}
          toggleOpen={toggleDeactivateMemberModal}
        />
      )}
      {reactivateMemberModalState.open && (
        <ReactivateMemberModal
          reactivateMemberHandler={reactivateMemberHandler}
          showLoader={false}
          open={reactivateMemberModalState.open}
          teamMember={reactivateMemberModalState.teamMember}
          toggleOpen={toggleReactivateMemberModal}
        />
      )}
      {openInvitedModal.open && (
        <InviteSentDetailsModal
          showLoader={false}
          memberDetails={openInvitedModal.teamMember}
          open={openInvitedModal.open}
          onCancelClick={handleInvitedModalClose}
          handleResendInvite={shareLinkHandler}
          handleDeleteInvite={inviteModalDeleteInviteHandler}
        />
      )}
      {deleteInviteModalState.open && (
        <DeleteInviteModal
          deleteInviteHandler={deleteInviteHandler}
          isDeleteInviteMutationInProgress={isDeleteInviteMutationInProgress}
          open={deleteInviteModalState.open}
          teamMember={deleteInviteModalState.teamMember}
          toggleOpen={toggleDeleteInviteModal}
        />
      )}
      {openSendInviteSidePanel.open && (
        <SendInviteSidePanel
          teamMember={openSendInviteSidePanel.teamMember}
          isOpen={openSendInviteSidePanel.open}
          onClose={handleSendInvitePanelClose}
          resetTeamMembersQuery={resetTeamMembersQuery}
        />
      )}
      <Outlet context={[handleCloseSidePanel, resetTeamMembersQuery]} />
    </div>
  );
};

export default ManageTeam;
