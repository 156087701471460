import { BaseQueryFn, FetchArgs, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { API_VERSION } from 'constants/common';
import { loggedOut, updateSTLTokens } from 'containers/auth/authSlice';
import { RootState } from 'store/reducers';
import { LocalStorage } from './storage';
import { showNotifier } from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { APIErrorMsg, APIErrorStatuses } from './type';
import { isPermissionDenied } from 'utils/apiUtils';
import routesPath from 'routes/RoutesPath';

export type ExtraOptions = {
  showToast?: boolean;
  failure?: string;
  success?: string;
  showCustomMessage?: boolean;
};

interface RefreshMutationResponse {
  idToken: string;
  accessToken: string;
  tokenType: string;
  validToken: boolean;
}

const openEndpoints = ['refresh', 'login', 'downloadEvidence'];

const handleNotifier = (apiResult, baseApi, apiExtraOptions: ExtraOptions) => {
  const { showToast = false, failure = '', success = '' } = apiExtraOptions ?? {};
  if (apiResult.error) {
    if (
      apiResult.error.status === APIErrorStatuses.STATUS_403 &&
      apiResult.error?.data?.message === APIErrorMsg.MERCHANT_BLOCKED
    ) {
      window.location.replace(routesPath.BLOCKED);
    } else if (showToast) {
      if (!isPermissionDenied(apiResult.error.status, apiResult.error?.data?.message)) {
        baseApi.dispatch(
          showNotifier({
            message: {
              primaryMessage: failure || 'Something went wrong'
            },
            type: NotifierTypes.ERROR,
            showClose: true
          })
        );
      }
    }
  }
  // !TODO change status to enum
  else if (apiResult?.meta?.response?.status === 200 && success) {
    baseApi.dispatch(
      showNotifier({
        message: {
          primaryMessage: success || 'Success'
        },
        type: NotifierTypes.SUCCESS,
        showClose: true
      })
    );
  }
};

const baseQuery = fetchBaseQuery({
  baseUrl: `${process.env.REACT_APP_API_ENDPOINT}/`,
  prepareHeaders: (headers, { getState, endpoint }) => {
    const authState = (getState() as RootState).rootReducer.auth;
    const idToken = authState.idToken ?? LocalStorage.get('idToken');
    const isAuthenticated = authState.isAuthenticated ?? LocalStorage.get('isAuthenticated');
    const hasInitiatedSignIn = authState.hasInitiatedSignIn ?? LocalStorage.get('hasInitiatedSignIn');
    const hasInitiatedSignUp = authState.hasInitiatedSignUp ?? LocalStorage.get('hasInitiatedSignUp');
    const refreshToken = authState.refreshToken ?? LocalStorage.get('refreshToken');

    if (
      !openEndpoints.includes(endpoint) &&
      refreshToken &&
      idToken &&
      (isAuthenticated || hasInitiatedSignIn || hasInitiatedSignUp)
    ) {
      // IdToken token plays the role of an access token
      headers.set('Authorization', `Bearer ${idToken}`);
    }
    return headers;
  }
  // credentials: 'include' // allows the server to set cookies
});

// !TODO use async mutex to prevent multiple refresh calls
// !TODO use generic toast to show service related erros
// We wrap baseQuery with this fn
const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown, //Result
  any, //Error
  ExtraOptions,
  object //Meta
> = async (args, api, extraOptions) => {
  try {
    let result = await baseQuery(args, api, extraOptions);
    const resultErrorData = result?.error?.data as any;
    if (result?.error && result?.error?.status === 401 && resultErrorData?.message !== 'WRONG_PASSWORD') {
      // Try to get new token
      const refreshResult = await baseQuery(
        {
          url: `${API_VERSION.V1}/auth/refresh`,
          method: 'POST',
          body: { refreshToken: LocalStorage.get('refreshToken') }
        },
        { ...api, endpoint: 'refresh' },
        extraOptions
      );
      const refreshResponse = refreshResult.data as RefreshMutationResponse;
      if (refreshResult.data) {
        // Update the access token in authSlice and storage
        LocalStorage.set('idToken', refreshResponse.idToken);
        LocalStorage.set('accessToken', refreshResponse.accessToken);
        api.dispatch(updateSTLTokens(refreshResponse));
        // Retry the initial query
        result = await baseQuery(args, api, extraOptions);
      } else {
        // !TODO clear redux store: dispatch a global reset action
        api.dispatch(loggedOut());
      }
    }
    handleNotifier(result, api, extraOptions);
    return result;
  } catch (error) {
    // !TODO how do we handle errors?
    // eslint-disable-next-line no-console
    console.log(error);
  }
};

export const baseApi = createApi({
  reducerPath: 'baseApi',
  tagTypes: ['GetTileData', 'GetSalesByPaymentMethods'],
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  refetchOnMountOrArgChange: true,
  refetchOnReconnect: true
});
