import { FC, useContext } from 'react';
import { InvoiceTabEnum } from '../constants';
import { CustomSwitch } from 'components';

interface SignatureOptionsSectionProps {
  isDisabled: boolean;
  className?: string;
  context: React.Context<any>;
  signatureState: boolean;
  updateSignatureState: (value: boolean) => void;
  id: string;
}

const SignatureOptionsSection: FC<SignatureOptionsSectionProps> = ({
  isDisabled,
  context,
  signatureState,
  updateSignatureState,
  id
}) => {
  const { selectedInvoiceTab, setSelectedInvoiceTab } = useContext<any>(context);

  const isTabSelected = selectedInvoiceTab === InvoiceTabEnum.SIGNATURES;

  const onSignatureConfigChange = () => {
    setSelectedInvoiceTab(InvoiceTabEnum.SIGNATURES);
    updateSignatureState(!signatureState);
  };

  return (
    <div>
      <div className={`mb-2 text-xl font-bold ${isTabSelected ? 'text-primaryBtn' : 'text-heading'}`}>Signatures</div>
      <div
        className={`flex flex-col rounded-md border px-[30px] py-10 1439px:p-10 
                    ${isTabSelected ? 'border-primaryBtn' : 'border-borderGray'}`}>
        <div className="mb-4 text-sbase text-heading">
          Request the customer to provide their signature on this invoice, as it helps ensure the authenticity and
          acknowledgment of the transaction.
        </div>

        <div
          className={`relative flex flex-row items-center gap-3 rounded border
           border-x-borderGray  p-5 ${isDisabled ? 'pointer-events-none' : ''}`}>
          {isDisabled && <div className="slant-lines absolute left-0 z-50 h-full w-full rounded opacity-40" />}
          <CustomSwitch enabled={signatureState} toggleSwitch={onSignatureConfigChange} id={id} />
          <div className="text-17px font-semibold text-primaryText">Request a customer signature</div>
        </div>
      </div>
    </div>
  );
};

export default SignatureOptionsSection;
