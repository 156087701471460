import imageCompression from 'browser-image-compression';
import Compressor from 'compressorjs';
import { FILE_UPLOAD_SIZE } from 'constants/common';
import Logger from 'utils/logger';

// TODO: Configure abort controller
// 2 Libraries are used to handle compression to support different file formats, browsers and other edge cases

export enum CompressionLibraries {
  BROWSER_IMAGE_COMPRESSION,
  COMPRESSOR_JS
}

export const handleImageCompression = async ({
  library,
  file,
  onCompressionInProgress = undefined
}: {
  library: CompressionLibraries;
  file: File;
  onCompressionInProgress?: (progress: number) => void;
}): Promise<File> => {
  if (library === CompressionLibraries.BROWSER_IMAGE_COMPRESSION) {
    return useBrowserImageCompression({ file, onCompressionInProgress });
  } else if (library === CompressionLibraries.COMPRESSOR_JS) {
    return useCompressorJS({ file });
  }
};

export const useBrowserImageCompression = async ({
  file,
  onCompressionInProgress = undefined
}: {
  file: File;
  onCompressionInProgress?: (progress: number) => void;
}): Promise<File> => {
  const imageFile = file;
  const options = {
    maxSizeMB: 1,
    alwaysKeepResolution: false,
    useWebWorker: true,
    maxIteration: 10,
    onProgress: onCompressionInProgress && onCompressionInProgress // the callback gets progress from 0 - 100
  };
  try {
    const compressedBlob = await imageCompression(imageFile, options);
    const compressedFile = new File([compressedBlob], compressedBlob.name, { type: compressedBlob.type });

    return compressedFile;
  } catch (error) {
    Logger.logException(error);
  }
};

// NOTE: Used to compress png files
export const useCompressorJS = async ({ file }: { file: File }): Promise<File> => {
  const imageFile = file;
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      quality: 0.6, // 0.6 can also be used, but it's not recommended to go below.
      convertSize: FILE_UPLOAD_SIZE.SIZE_1_MB,
      success: compressedBlob => {
        const compressedFile = new File([compressedBlob], imageFile.name, { type: compressedBlob.type });
        resolve(compressedFile);
      },
      error: err => {
        reject(err);
      }
    });
  });
};
