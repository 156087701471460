import React, { useEffect } from 'react';
import routesPath from 'routes/RoutesPath';
import { getPageName } from 'utils/commonUtils';
import TestimonialSection from 'containers/auth/components/TestimonialSection';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useLazyInitDigitalOnboardingQuery } from './api';
import { LoadingSpinnerAnimation } from 'assets/animations';
import Lottie from 'lottie-react';
import { TopBarIcon } from 'assets/icons';
import { useLazyGetMerchantTeamsQuery } from 'containers/app/api';
import MerchantNameSection from './components/MerchantNameSection';
import EmailSection from './components/EmailSection';
import ProfessionSection from './components/ProfessionSection';
import BusinessNameSection from './components/BusinessNameSection';
import EmployeeSection from './components/EmployeeSection';
import ReferralSourceSection from './components/ReferralSourceSection';
import WebsiteSection from './components/WebsiteSection';
import { setCurrentData, setIsDigitalOnboardingEnabled, setUserData } from './digitalOnboardingSlice';
import WaitingListPage from './components/WaitingListPage';
import { useNavigate } from 'react-router-dom';

const DigitalOnboarding = () => {
  const navigate = useNavigate();
  const page = `/${getPageName(location.pathname)}`;
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [getMerchantTeamsQuery] = useLazyGetMerchantTeamsQuery();
  const [initDigitalOnboardingQuery, initDigitalOnboardingState] = useLazyInitDigitalOnboardingQuery();
  const dispatch = useAppDispatch();

  const {
    data: initDigitalOnboardingData,
    isLoading: isInitDigitalOnboardingLoading,
    isSuccess: isInitDigitalOnboardingSucccess
  } = initDigitalOnboardingState;
  const { professions } = initDigitalOnboardingData ?? {};

  useEffect(() => {
    if (currentTeam.id) {
      initDigitalOnboardingQuery({ teamId: currentTeam.id });
    } else {
      getMerchantTeamsQuery();
    }
  }, [currentTeam.id]);

  useEffect(() => {
    if (isInitDigitalOnboardingSucccess && initDigitalOnboardingData.userData && !isInitDigitalOnboardingLoading) {
      dispatch(setUserData(initDigitalOnboardingData.userData));
      dispatch(setCurrentData(initDigitalOnboardingData.userData));
      dispatch(setIsDigitalOnboardingEnabled(initDigitalOnboardingData.isDigitalOnboardingEnabled));
      if (initDigitalOnboardingData.userData.waitingListRank > 0) navigate(routesPath.WAITING_LIST);
    }
  }, [isInitDigitalOnboardingSucccess, initDigitalOnboardingData, isInitDigitalOnboardingLoading]);

  return (
    <div className="flex w-screen">
      <div className="customNormalScroll h-screen w-full overflow-auto sm:w-1/2">
        {isInitDigitalOnboardingLoading ? (
          <div className="flex h-full w-full items-center justify-center">
            <Lottie className="absolute top-[35%] h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          isInitDigitalOnboardingSucccess &&
          initDigitalOnboardingData && (
            <div>
              <TopBarIcon className="mt-12 ml-[75px] shrink-0" />

              <div className="mx-auto mt-[100px] max-w-[375px] px-2.5 sm:w-[375px]">
                {page === routesPath.DIGITAL_ONBOARDING_NAME && <MerchantNameSection />}
                {page === routesPath.DIGITAL_ONBOARDING_EMAIL && <EmailSection />}
                {page === routesPath.DIGITAL_ONBOARDING_PROFESSION && <ProfessionSection professions={professions} />}
                {page === routesPath.DIGITAL_ONBOARDING_BUSINESS_NAME && <BusinessNameSection />}
                {page === routesPath.DIGITAL_ONBOARDING_EMPLOYEES && <EmployeeSection />}
                {page === routesPath.DIGITAL_ONBOARDING_REFERRAL_SOURCE && <ReferralSourceSection />}
                {page === routesPath.DIGITAL_ONBOARDING_WEBSITE && <WebsiteSection />}
                {page === routesPath.WAITING_LIST && <WaitingListPage />}
              </div>
            </div>
          )
        )}
      </div>

      <div className="hidden h-screen w-1/2 sm:flex">
        <TestimonialSection />
      </div>
    </div>
  );
};

export default DigitalOnboarding;
