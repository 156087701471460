import { FC } from 'react';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { ITeamMember } from '../manageTeam.model';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface DeleteInviteModalProps {
  open: boolean;
  isDeleteInviteMutationInProgress?: boolean;
  toggleOpen: (teamMember: ITeamMember) => void;
  teamMember: ITeamMember;
  deleteInviteHandler: (teamMember: ITeamMember) => void;
}
const DeleteInviteModal: FC<DeleteInviteModalProps> = ({
  deleteInviteHandler,
  teamMember,
  open,
  toggleOpen,
  isDeleteInviteMutationInProgress
}) => {
  const onCancelClick = () => {
    toggleOpen(teamMember);
  };

  const handleDeleteInviteButtonClick = () => {
    deleteInviteHandler(teamMember);
  };

  return (
    <CustomModal width="md:w-96" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded-md p-10">
        <div className="flex flex-col items-center justify-center">
          <FormCloseReactIcon
            id="delete-invite-modal-close"
            height="24"
            width="24"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onCancelClick}
          />
          <div className="flex w-full flex-col items-center justify-center">
            <p className="text-xl font-bold leading-6 text-error">Delete Invite?</p>
            <p className="mt-4 text-center text-17px font-normal leading-5 text-primaryText">
              {`${teamMember?.firstName} ${teamMember?.lastName} 
                  will not be able to join your team. Any links or past invites will expire. 
                You will still be able to create and send them a new invite.`}
            </p>
          </div>
          <CustomButton
            isLoading={isDeleteInviteMutationInProgress}
            onClick={handleDeleteInviteButtonClick}
            id="delete-invite-modal-yes-button"
            className={`text-semibold mt-5 h-[38px] w-full rounded-md bg-error
            text-px13 text-primaryBtnText shadow-none hover:bg-redButtonHover`}>
            Yes
          </CustomButton>
          <CustomButton
            id="delete-invite-modal-no-button"
            className="mt-6 h-[38px] w-full border-accentText text-primaryText"
            type={ButtonType.SECONDARY}
            onClick={onCancelClick}>
            No
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
export default DeleteInviteModal;
