export type VoidFn = () => void;

export type BoolFn = () => boolean;

export type GenericApiResponse<Result> = {
  result?: Result;
  error?: ErrorObject;
  status: string;
};

type ErrorObject = {
  code: number;
  details: string[];
};

export enum navTabKeys {
  home = 'home',
  invoices = 'invoices',
  receipt = 'receipt',
  receiptsSection = 'receipts-section',
  payouts = 'payouts',
  payoutsBalance = 'payouts-balance',
  payoutsList = 'payouts-list',
  payoutsSettings = 'payouts-settings',
  customers = 'customers',
  invoiceSettings = 'invoice_settings',
  discounts = 'discounts',
  disputes = 'disputes',
  paymentLimits = 'payment_limits',
  teamProfile = 'team_profile',
  manageTeam = 'manage_team',
  banking = 'banking',
  debitCards = 'debit_cards',
  accounts = 'accounts',
  myAccount = 'my_account',
  creditsRewards = 'credits_rewards',
  catalog = 'catalog',
  catalogItems = 'catalog_items',
  catalogCategories = 'catalog_categories',
  catalogTaxes = 'catalog_taxes',
  catalogServiceCharge = 'catalog_service_charge',
  integrations = 'integrations'
}

export enum clevertapEvents {
  webHomeView = 'Web_Home_View',
  webTopBarFaqClick = 'Web_Top_Bar_Faq_Click',
  webTopBarChatClick = 'Web_Top_Bar_Chat_Click',
  webBottomBarFaqClick = 'Web_Bottom_Bar_Faq_Click',
  webBottomBarChatClick = 'Web_Bottom_Bar_Chat_Click',
  webManageTeamView = 'Web_ManageTeam_View',
  webInvoicesListView = 'Web_Invoices_List_View',
  webReceiptsListView = 'Web_Receipts_List_View',
  webDisputesListView = 'Web_Disputes_List_View',
  webPayoutsListView = 'Web_Payouts_List_View',
  webCustomersListView = 'Web_Customers_List_View',
  webTodaysSalesView = 'Web_Todays_Sales_View',
  webPendingInvoicesView = 'Web_Pending_Invoices_View',
  webSalesByTeamMemberCreateInvoice = 'Web_Sales_By_Team_Member_Create_Invoice',
  webSalesByPaymentMethodCreateInvoice = 'Web_Sales_By_Payment_method_Create_Invoice',
  webAllSalesThisMonthCreateInvoice = 'Web_All_Sales_This_Month_Create_Invoice',
  webAllSalesThisYearCreateInvoice = 'Web_All_Sales_This_Year_Create_Invoice',
  webRecentPayoutsListCreateInvoice = 'Web_Recent_Payouts_List_Create_Invoice',
  webReceiptsListExport = 'Web_Receipts_List_Export',
  webReceiptsListMMRefund = 'Web_Receipts_List_MM_Refund',
  webReceiptsListMMPrint = 'Web_Receipts_List_MM_Print',
  webReceiptsListMMShare = 'Web_Receipts_List_MM_Share',
  webReceiptsListDetails = 'Web_Receipts_List_Details',
  webReceiptsListDetailShare = 'Web_Receipts_List_Detail_Share',
  webReceiptsListDetailRefund = 'Web_Receipts_List_Detail_Refund',
  webReceiptsListDetailMoreExportPDF = 'Web_Receipts_List_Detail_More_ExportPDF',
  webReceiptsListDetailMorePrint = 'Web_Receipts_List_Detail_More_Print',
  webReceiptsListDetailsGoToCustomer = 'Web_Receipts_List_Details_Go_To_Customer',
  webReceiptsListDetailsGoToPayoutDetail = 'Web_Receipts_List_Details_Go_To_Payout_Detail',
  webReceiptsListDetailsGoToInvoiceDetail = 'Web_Receipts_List_Details_Go_To_Invoice_Detail',
  webReceiptsListDetailsPanelOpen = 'Web_Receipts_List_Details_Panel_Open',
  webReceiptsListRefundPanelOpen = 'Web_Receipts_List_Refund_Panel_Open',
  webReceiptsListSharePanelOpen = 'Web_Receipts_List_Share_Panel_Open',
  webCustomersCreateCustomer = 'Web_Customers_Create_Customer',
  webCustomersListDetails = 'Web_Customers_List_Details',
  webCustomersListDetailsCreateInvoice = 'Web_Customers_List_Details_Create_Invoice',
  webCustomersListDetailsEdit = 'Web_Customers_List_Details_Edit',
  webCustomersListDetailsPersonalInformationEdit = 'Web_Customers_List_Details_Personal_Information_Edit',
  webCustomersListDetailsInvoiceLink = 'Web_Customers_List_Details_Invoice_Link',
  webCustomersListMMEdit = 'Web_Customers_List_MM_Edit',
  webCustomersListMMNewInvoice = 'Web_Customers_List_MM_New Invoice',
  webAllSalesThisMonth = 'Web_All_Sales_This_Month',
  webAllSalesThisYear = 'Web_All_Sales_This_Year',
  webSalesByPaymentMethod = 'Web_Sales_By_Payment_method',
  webSalesByTeamMember = 'Web_Sales_By_Team_Member',
  WebLoginUsingOtp = 'Web_Login_Using_Otp',
  WebPayoutsListDetails = 'Web_Payouts_List_Details',
  webPayoutsListExport = 'Web_Payouts_List_Export',
  WebPayoutsListDetailsTxnDetail = 'Web_Payouts_List_Details_Txn_Detail',
  WebInvoicesListSharePanelOpen = 'Web_Invoices_List_Share_Panel_Open',
  WebHomeCreateCustomer = 'Web_Home_Create_Customer',
  WebHomeCreateInvoice = 'Web_Home_Create_Invoice',
  WebInvoicesListMarkAsPaidPanelOpen = 'Web_Invoices_List_Mark_As_Paid_Panel_Open',
  WebInvoicesListDeletePanelOpen = 'Web_Invoices_List_Delete_Panel_Open',
  WebInvoicesListMarkAsPaidPaymentMethod = 'Web_Invoices_List_Mark_As_Paid_Payment_Method',
  WebDiscountTipsFeesDiscountEnabled = 'Web_Discount_Tips_Fees_Discount_Enabled',
  WebDiscountTipsFeesDiscountDisabled = 'Web_Discount_Tips_Fees_Discount_Disabled',
  WebDiscountTipsFeesTipsEnabled = 'Web_Discount_Tips_Fees_Tips_Enabled',
  WebDiscountTipsFeesTipsDisabled = 'Web_Discount_Tips_Fees_Tips_Disabled',
  WebDiscountTipsFeesServiceFeeEnabled = 'Web_Discount_Tips_Fees_ServiceFee_Enabled',
  WebDiscountTipsFeesServiceFeeDisabled = 'Web_Discount_Tips_Fees_ServiceFee_Disabled',
  WebDiscountTipsFeesServiceFeeChange = 'Web_Discount_Tips_Fees_ServiceFee_Change',
  WebDiscountTipsFeesCreateNewTax = 'Web_Discount_Tips_Fees_Create_New_Tax',
  WebDiscountTipsFeesEditTax = 'Web_Discount_Tips_Fees_Edit_Tax',
  WebDiscountTipsFeesDeleteTax = 'Web_Discount_Tips_Fees_Delete_Tax',
  WebDiscountTipsFeesPreview = 'Web_Discount_Tips_Fees_Preview',
  WebDiscountTipsFees = 'Web_Discount_Tips_Fees',
  WebPaymentLimits = 'Web_Payment_Limits',
  webCatalogMenu = 'Web_Catalog_Menu',
  webCatalogItemsListView = 'Web_Catalog_Items_List_View',
  webCatalogCategoryListView = 'Web_Catalog_Category_List_View',
  webCatalogTaxesListView = 'Web_Catalog_Taxes_List_View',
  webCatalogServiceChargesListView = 'Web_Catalog_ServiceCharge_List_View',
  webIntegrationsView = 'Web_Integrations_View',
  webQuickbooksFaqClick = 'Web_Quickbooks_Faq_Click',
  webPayoutsBalanceView = 'Web_Payouts_Balance_View',
  webPayoutsSettings = 'Web_Payouts_Settings',
  webHomePageBannerClick = 'Web_Home_Page_Banner_Click'
}

export interface NavTab {
  id: string;
  key: navTabKeys;
  title: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  path: string;
  clevertapEvent?: clevertapEvents;
  hasChildTabs?: boolean;
  childTabs?: NavTab[];
}

export enum FileTypes {
  PDF = 'pdf',
  CSV = 'csv'
}

export interface SearchItemType {
  id: string;
  text: string;
  searchText: string;
  type: string;
}

export interface ReactIconType extends React.SVGProps<SVGSVGElement> {
  id: string;
}

export interface GenericPaginationResponse<T> {
  data: Array<T>;
  meta: PaginationMetaInfo;
}

export interface PaginationMetaInfo {
  limit: number;
  page: number;
  count: number;
  totalCount: number;
  next: string;
  previous: string;
}

export enum API_RESPONSES {
  SUCCESS = 'SUCCESS'
}

export interface SKUCheckResponse<T> {
  conflictingItems: Array<T>;
}

export interface OutletContextType {
  handleSidePanelClose: VoidFn;
}

export enum ForwardFileType {
  INVOICE = 'invoice',
  RECEIPT = 'receipt'
}
