const routesPath = {
  ALL: '*',
  DEFAULT: '/',
  HOME: '/home',
  INVOICE: '/invoice',
  INVOICES: '/invoices',
  INVOICE_BY_ID: '/invoices/:teamId/:rootInvoiceId/invoice',
  INVOICE_BY_ID_DETAILS: '/invoices/:teamId/:rootInvoiceId/invoice-details',
  INVOICE_CUSTOMER_BY_ID: '/invoices/:teamId/:rootInvoiceId/customer/:customerId',
  INVOICE_INVOICE_BY_ID: '/invoices/:teamId/:rootInvoiceId/invoice/:invoiceId',
  INVOICE_INVOICE_DETAILS: '/invoices/:teamId/:rootInvoiceId/invoice-details/:invoiceId',

  INVOICE_SHARE: '/invoices/:teamId/:rootInvoiceId/share-invoice',
  INVOICE_PENDING_SHARE: '/invoices/:teamId/:rootInvoiceId/share-pending-invoice',
  INVOICE_PENDING_SHARE_QR: '/invoices/:teamId/:rootInvoiceId/share-pending-invoice/share-qr',
  INVOICE_PENDING_SHARE_URL: '/invoices/:teamId/:rootInvoiceId/share-pending-invoice/share-url',
  INVOICE_RECEIPT_SHARE: '/invoices/:teamId/:rootInvoiceId/share-receipt/:receiptId',
  INVOICE_SHARE_INVOICE: '/invoices/:teamId/:rootInvoiceId/share-invoice/:invoiceId',
  INVOICE_SHARE_PENDING_INVOICE: '/invoices/:teamId/:rootInvoiceId/share-pending-invoice/:invoiceId',
  INVOICE_SHARE_PENDING_INVOICE_QR: '/invoices/:teamId/:rootInvoiceId/share-pending-invoice/:invoiceId/share-qr',
  INVOICE_SHARE_PENDING_INVOICE_URL: '/invoices/:teamId/:rootInvoiceId/share-pending-invoice/:invoiceId/share-url',
  INVOICE_REFUND_RECEIPT_SHARE: '/invoices/:teamId/:rootInvoiceId/share-refund-receipt/:receiptId',
  INVOICE_RECEIPT_SHARE_INVOICE: '/invoices/:teamId/:rootInvoiceId/share-receipt/:receiptId',
  INVOICE_DELETE: '/invoices/:teamId/:rootInvoiceId/delete-invoice',
  INVOICE_DELETE_INVOICE: '/invoices/:teamId/:rootInvoiceId/delete-invoice/:invoiceId',
  INVOICE_MARK_PAID: '/invoices/:teamId/:rootInvoiceId/mark-as-paid',
  INVOICE_MARK_PAID_INVOICE: '/invoices/:teamId/:rootInvoiceId/mark-as-paid/:invoiceId',
  INVOICE_TXN_DETAIL: '/invoices/:teamId/:rootInvoiceId/receipt/:receiptId',
  INVOICE_RECEIPT_CREATE_REFUND: '/invoices/:teamId/:rootInvoiceId/create-refund/:receiptId',
  INVOICE_RECEIPT_INITIATE_REFUND: '/invoices/:teamId/:rootInvoiceId/initiate-refund/:receiptId',
  INVOICE_RECEIPT_REFUND_SUCCESS: '/invoices/:teamId/:rootInvoiceId/refund-success/:receiptId',
  INVOICE_RECEIPT_REFUND_FAILED: '/invoices/:teamId/:rootInvoiceId/refund-failed/:receiptId',
  INVOICE_RECEIPT_REFUND: '/invoices/:teamId/:rootInvoiceId/refund-receipt/:receiptId',
  INVOICE_PAYOUT: '/invoices/:teamId/:rootInvoiceId/payout/:payoutId',
  INVOICE_PAYOUT_TXNS: '/invoices/:teamId/:rootInvoiceId/payout/:payoutId/transactions',
  INVOICE_DISPUTE_BY_ID: '/invoices/:teamId/:rootInvoiceId/dispute/:disputeId',

  INVOICES_CREATE: '/invoices/create',
  INVOICES_EDIT: '/invoices/edit/:invoiceId',
  INVOICES_PREVIEW: '/invoices/preview',
  INVOICES_EDIT_PREVIEW: '/invoices/edit/:invoiceId/preview',
  CARDS: '/cards',
  BANKING_HOME: '/bankHome',
  LIMITS: '/paymentLimits',
  TEAMS_HOME: 'manageTeam',
  ACCOUNT_SETTINGS: '/account-settings',
  ACCOUNT_SETTINGS_MY_ACCOUNT: '/account-settings/my-account',
  ACCOUNT_SETTINGS_CREDITS_REWARDS: '/account-settings/credits-rewards',
  RECEIPTS: '/payments/receipts',
  RECEIPT_BY_ID: '/payments/receipts/:teamId/:rootReceiptId/receipt',
  RECEIPT_RECEIPT_BY_ID: '/payments/receipts/:teamId/:rootReceiptId/receipt/:receiptId',
  RECEIPT_SHARE: '/payments/receipts/:teamId/:rootReceiptId/share-receipt/:receiptId',
  RECEIPT_REFUND_RECEIPT_SHARE: '/payments/receipts/:teamId/:rootReceiptId/share-refund-receipt/:receiptId',
  RECEIPT_INVOICE_SHARE: '/payments/receipts/:teamId/:rootReceiptId/share-invoice/:invoiceId',
  RECEIPT_PENDING_INVOICE_SHARE: '/payments/receipts/:teamId/:rootReceiptId/share-pending-invoice/:invoiceId',
  RECEIPT_PENDING_INVOICE_SHARE_QR:
    '/payments/receipts/:teamId/:rootReceiptId/share-pending-invoice/:invoiceId/share-qr',
  RECEIPT_PENDING_INVOICE_SHARE_URL:
    '/payments/receipts/:teamId/:rootReceiptId/share-pending-invoice/:invoiceId/share-url',
  RECEIPT_REFUND: '/payments/receipts/:teamId/:rootReceiptId/refund',
  RECEIPT_CREATE_REFUND: '/payments/receipts/:teamId/:rootReceiptId/create-refund',
  RECEIPT_INITIATE_REFUND: '/payments/receipts/:teamId/:rootReceiptId/initiate-refund',
  RECEIPT_REFUND_SUCCESS: '/payments/receipts/:teamId/:rootReceiptId/refund-success',
  RECEIPT_REFUND_FAILED: '/payments/receipts/:teamId/:rootReceiptId/refund-failed',
  RECEIPT_RECEIPT_CREATE_REFUND: '/payments/receipts/:teamId/:rootReceiptId/create-refund/:receiptId',
  RECEIPT_RECEIPT_INITIATE_REFUND: '/payments/receipts/:teamId/:rootReceiptId/initiate-refund/:receiptId',
  RECEIPT_RECEIPT_REFUND_SUCCESS: '/payments/receipts/:teamId/:rootReceiptId/refund-success/:receiptId',
  RECEIPT_RECEIPT_REFUND_FAILED: '/payments/receipts/:teamId/:rootReceiptId/refund-failed/:receiptId',
  RECEIPT_RECEIPT_REFUND: '/payments/receipts/:teamId/:rootReceiptId/refund/:receiptId',
  RECEIPT_PAYOUT_BY_ID: '/payments/receipts/:teamId/:rootReceiptId/payout/:payoutId',
  RECEIPT_PAYOUT_BY_ID_TXNS: '/payments/receipts/:teamId/:rootReceiptId/payout/:payoutId/transactions',
  RECEIPT_INVOICE: '/payments/receipts/:teamId/:rootReceiptId/invoice/:invoiceId',
  RECEIPT_CUSTOMER_BY_ID: '/payments/receipts/:teamId/:rootReceiptId/customer/:customerId',
  RECEIPT_INVOICE_DETAILS: '/payments/receipts/:teamId/:rootReceiptId/invoice-details/:invoiceId',
  RECEIPT_INVOICE_DELETE: '/payments/receipts/:teamId/:rootReceiptId/delete-invoice/:invoiceId',
  RECEIPT_INVOICE_MARK_PAID: '/payments/receipts/:teamId/:rootReceiptId/mark-as-paid/:invoiceId',
  TRANSACTIONS: '/transactions',
  RECEIPT_DISPUTE_BY_ID: '/payments/receipts/:teamId/:rootReceiptId/dispute/:disputeId',

  PAYOUTS: '/payouts',
  PAYOUTS_BALANCE: '/payouts/balance',
  PAYOUTS_LIST: '/payouts/list',
  PAYOUT_LIST_TXNS: '/payouts/list/:teamId/:rootPage/:payoutId/transactions',
  PAYOUT_BALANCE_TXNS: '/payouts/balance/:teamId/:rootPage/:payoutId/transactions',
  PAYOUTS_SETTINGS: '/payouts/settings',
  PAYOUTS_BALANCE_TOTAL_DETALS: '/payouts/balance/total-details',
  PAYOUTS_BALANCE_UPCOMING_PAYOUTS: '/payouts/balance/upcoming-payouts',
  PAYOUTS_BALANCE_IN_TRANSIT_PAYOUTS: '/payouts/balance/in-transit-payouts/:rootPage',
  PAYOUT_BALANCE_RECEIPT_DETAIL: '/payouts/balance/:teamId/:rootPage/receipt/:receiptId',
  PAYOUT_BALANCE_RECEIPT_SHARE: '/payouts/balance/:teamId/:rootPage/share-receipt/:receiptId',
  PAYOUT_BALANCE_INVOICE_SHARE: '/payouts/balance/:teamId/:rootPage/share-invoice/:invoiceId',
  PAYOUT_BALANCE_PENDING_INVOICE_SHARE: '/payouts/balance/:teamId/:rootPage/share-pending-invoice/:invoiceId',
  PAYOUT_BALANCE_PENDING_INVOICE_SHARE_QR:
    '/payouts/balance/:teamId/:rootPage/share-pending-invoice/:invoiceId/share-qr',
  PAYOUT_BALANCE_PENDING_INVOICE_SHARE_URL:
    '/payouts/balance/:teamId/:rootPage/share-pending-invoice/:invoiceId/share-url',
  PAYOUT_BALANCE_REFUND_RECEIPT_SHARE: '/payouts/balance/:teamId/:rootPage/share-refund-receipt/:receiptId',
  PAYOUT_BALANCE_RECEIPT_CREATE_REFUND: '/payouts/balance/:teamId/:rootPage/create-refund/:receiptId',
  PAYOUT_BALANCE_RECEIPT_INITIATE_REFUND: '/payouts/balance/:teamId/:rootPage/initiate-refund/:receiptId',
  PAYOUT_BALANCE_RECEIPT_REFUND_SUCCESS: '/payouts/balance/:teamId/:rootPage/refund-success/:receiptId',
  PAYOUT_BALANCE_RECEIPT_REFUND_FAILED: '/payouts/balance/:teamId/:rootPage/refund-failed/:receiptId',
  PAYOUT_BALANCE_PAYOUT_BY_ID: '/payouts/balance/:teamId/:rootPage/payout/:payoutId',
  PAYOUT_BALANCE_INVOICE: '/payouts/balance/:teamId/:rootPage/invoice/:invoiceId',
  PAYOUT_BALANCE_CUSTOMER_BY_ID: '/payouts/balance/:teamId/:rootPage/customer/:customerId',
  PAYOUT_BALANCE_INVOICE_DETAILS: '/payouts/balance/:teamId/:rootPage/invoice-details/:invoiceId',
  PAYOUT_BALANCE_RECEIPT_REFUND: '/payouts/balance/:teamId/:rootPage/refund-receipt/:receiptId',
  PAYOUT_BALANCE_DISPUTE_BY_ID: '/payouts/balance/:teamId/:rootPage/dispute/:disputeId',
  PAYOUT_BALANCE_INVOICE_DELETE: '/payouts/balance/:teamId/:rootPage/delete-invoice/:invoiceId',
  PAYOUT_BALANCE_INVOICE_MARK_PAID: '/payouts/balance/:teamId/:rootPage/mark-as-paid/:invoiceId',
  PAYOUT_LIST_PAYOUT_BY_ID: '/payouts/list/:teamId/:rootPage/payout/:payoutId',
  PAYOUT_LIST_RECEIPT: '/payouts/list/:teamId/:rootPage/receipt/:receiptId',
  PAYOUT_LIST_CUSTOMER: '/payouts/list/:teamId/:rootPage/customer/:customerId',
  PAYOUT_LIST_INVOICE: '/payouts/list/:teamId/:rootPage/invoice/:invoiceId',
  PAYOUT_LIST_INVOICE_DETAILS: '/payouts/list/:teamId/:rootPage/invoice-details/:invoiceId',
  PAYOUT_LIST_RECEIPT_CREATE_REFUND: '/payouts/list/:teamId/:rootPage/create-refund/:receiptId',
  PAYOUT_LIST_RECEIPT_INITIATE_REFUND: '/payouts/list/:teamId/:rootPage/initiate-refund/:receiptId',
  PAYOUT_LIST_RECEIPT_REFUND_SUCCESS: '/payouts/list/:teamId/:rootPage/refund-success/:receiptId',
  PAYOUT_LIST_RECEIPT_REFUND_FAILED: '/payouts/list/:teamId/:rootPage/refund-failed/:receiptId',
  PAYOUT_LIST_RECEIPT_SHARE: '/payouts/list/:teamId/:rootPage/share-receipt/:receiptId',
  PAYOUT_LIST_INVOICE_SHARE: '/payouts/list/:teamId/:rootPage/share-invoice/:invoiceId',
  PAYOUT_LIST_PENDING_INVOICE_SHARE: '/payouts/list/:teamId/:rootPage/share-pending-invoice/:invoiceId',
  PAYOUT_LIST_PENDING_INVOICE_SHARE_QR: '/payouts/list/:teamId/:rootPage/share-pending-invoice/:invoiceId/share-qr',
  PAYOUT_LIST_PENDING_INVOICE_SHARE_URL: '/payouts/list/:teamId/:rootPage/share-pending-invoice/:invoiceId/share-url',
  PAYOUT_LIST_REFUND_RECEIPT_SHARE: '/payouts/list/:teamId/:rootPage/share-refund-receipt/:receiptId',
  PAYOUT_LIST_INVOICE_DELETE: '/payouts/list/:teamId/:rootPage/delete-invoice/:invoiceId',
  PAYOUT_LIST_INVOICE_MARK_PAID: '/payouts/list/:teamId/:rootPage/mark-as-paid/:invoiceId',
  PAYOUT_LIST_RECEIPT_REFUND: '/payouts/list/:teamId/:rootPage/refund-receipt/:receiptId',
  PAYOUT_LIST_DISPUTE: '/payouts/list/:teamId/:rootPage/dispute/:disputeId',
  PAYOUTS_BALANCE_UPCOMING_PAYOUTS_TXNS: '/payouts/balance/:teamId/upcoming-payouts/:rootPage/transactions',

  BANK_APPLICATION_STATUS: '/payouts/application-status',
  CONNECT_ONBOARDING: '/payouts/connect-onboarding',

  CUSTOMERS: '/customers',
  CUSTOMER_BY_ID: '/customers/:teamId/:rootCustomerId/customer',
  CUSTOMER_INVOICE: '/customers/:teamId/:rootCustomerId/invoice/:invoiceId',
  CUSTOMER_INVOICE_DETAILS: '/customers/:teamId/:rootCustomerId/invoice-details/:invoiceId',
  CUSTOMER_RECEIPT: '/customers/:teamId/:rootCustomerId/receipt/:receiptId',
  CUSTOMER_RECEIPT_SHARE: '/customers/:teamId/:rootCustomerId/share-receipt/:receiptId',
  CUSTOMER_INVOICE_SHARE: '/customers/:teamId/:rootCustomerId/share-invoice/:invoiceId',
  CUSTOMER_PENDING_INVOICE_SHARE: '/customers/:teamId/:rootCustomerId/share-pending-invoice/:invoiceId',
  CUSTOMER_PENDING_INVOICE_SHARE_QR: '/customers/:teamId/:rootCustomerId/share-pending-invoice/:invoiceId/share-qr',
  CUSTOMER_PENDING_INVOICE_SHARE_URL: '/customers/:teamId/:rootCustomerId/share-pending-invoice/:invoiceId/share-url',
  CUSTOMER_REFUND_RECEIPT_SHARE: '/customers/:teamId/:rootCustomerId/share-refund-receipt/:receiptId',
  CUSTOMER_INVOICE_DELETE: '/customers/:teamId/:rootCustomerId/delete-invoice/:invoiceId',
  CUSTOMER_INVOICE_MARK_PAID: '/customers/:teamId/:rootCustomerId/mark-as-paid/:invoiceId',
  CUSTOMER_CREATE_REFUND: '/customers/:teamId/:rootCustomerId/create-refund/:receiptId',
  CUSTOMER_INITIATE_REFUND: '/customers/:teamId/:rootCustomerId/initiate-refund/:receiptId',
  CUSTOMER_REFUND_SUCCESS: '/customers/:teamId/:rootCustomerId/refund-success/:receiptId',
  CUSTOMER_REFUND_FAILED: '/customers/:teamId/:rootCustomerId/refund-failed/:receiptId',
  CUSTOMER_PAYOUT: '/customers/:teamId/:rootCustomerId/payout/:payoutId',
  CUSTOMER_PAYOUT_TXNS: '/customers/:teamId/:rootCustomerId/payout/:payoutId/transactions',
  CUSTOMER_RECEIPT_REFUND: '/customers/:teamId/:rootCustomerId/refund-receipt/:receiptId',
  CUSTOMER_DISPUTE_BY_ID: '/customers/:teamId/:rootCustomerId/dispute/:disputeId',

  DISCOUNTS_TIPS_FEES: '/discounts-tips-fees',
  PAYMENT_LIMITS: '/payment-limits',
  TEAM_PROFILE: '/team-profile',
  MANAGE_TEAM: '/manage-team',
  CREATE_INVITE: '/manage-team/invite',
  TEAM_MEMBER_BY_ID: '/manage-team/:teamId/:memberId/member',
  BANKING: '/banking',
  BANKING_ONBOARDING: '/banking-onboarding',
  DEBIT_CARDS: '/debit-cards',
  ACCOUNTS: '/accounts',
  AWAITING_DOCUMENTS: '/awaitingDocuments',
  CREDITS_HISTORY: '/preCredits',

  CATALOG_ITEMS: '/catalog/items',
  ITEMS_CREATE: '/items/create',
  ITEMS_EDIT: '/items/edit/:itemId',
  ITEMS_DUPLICATE: '/items/duplicate/:itemId',

  CATALOG_CATEGORIES: '/catalog/categories',
  CATEGORIES_CREATE: '/categories/create',
  CATEGORIES_EDIT: '/categories/edit/:categoryId',

  CATALOG_TAXES: '/catalog/taxes',
  TAXES_CREATE: '/taxes/create',
  TAXES_EDIT: '/taxes/edit/:taxId',

  CATALOG_SERVICE_CHARGES: '/catalog/service-charges',
  SERVICE_CHARGES_CREATE: '/service-charges/create',
  SERVICE_CHARGES_EDIT: '/service-charges/edit/:id',

  DISPUTES: '/payments/disputes',
  DISPUTE_BY_ID: '/payments/disputes/:teamId/:rootDisputeId/dispute',
  DISPUTE_RECEIPT_DETAILS: '/payments/disputes/:teamId/:rootDisputeId/receipt/:receiptId',
  RESPOND_TO_DIPUTES: '/payments/disputes/:teamId/respond/:rootDisputeId',
  DISPUTES_UPDATE_EVIDENCE: '/payments/disputes/:teamId/update-evidence/:rootDisputeId',
  DISPUTES_REVIEW_SUBMIT: '/payments/disputes/:teamId/review-and-submit/:rootDisputeId',
  DISPUTES_VIEW_EVIDENCE: '/payments/disputes/:teamId/view-evidence/:rootDisputeId',

  DISPUTE_DISPUTE_BY_ID: '/payments/disputes/:teamId/:rootDisputeId/dispute/:disputeId',
  DISPUTE_CUSTOMER: '/payments/disputes/:teamId/:rootDisputeId/customer/:customerId',
  DISPUTE_INVOICE: '/payments/disputes/:teamId/:rootDisputeId/invoice/:invoiceId',
  DISPUTE_INVOICE_DETAILS: '/payments/disputes/:teamId/:rootDisputeId/invoice-details/:invoiceId',
  DISPUTE_RECEIPT_SHARE: '/payments/disputes/:teamId/:rootDisputeId/share-receipt/:receiptId',
  DISPUTE_INVOICE_SHARE: '/payments/disputes/:teamId/:rootDisputeId/share-invoice/:invoiceId',
  DISPUTE_PENDING_INVOICE_SHARE: '/payments/disputes/:teamId/:rootDisputeId/share-pending-invoice/:invoiceId',
  DISPUTE_PENDING_INVOICE_SHARE_QR:
    '/payments/disputes/:teamId/:rootDisputeId/share-pending-invoice/:invoiceId/share-qr',
  DISPUTE_PENDING_INVOICE_SHARE_URL:
    '/payments/disputes/:teamId/:rootDisputeId/share-pending-invoice/:invoiceId/share-url',
  DISPUTE_REFUND_RECEIPT_SHARE: '/payments/disputes/:teamId/:rootDisputeId//share-refund-receipt/:receiptId',
  DISPUTE_INVOICE_DELETE: '/payments/disputes/:teamId/:rootDisputeId/delete-invoice/:invoiceId',
  DISPUTE_INVOICE_MARK_PAID: '/payments/disputes/:teamId/:rootDisputeId/mark-as-paid/:invoiceId',
  DISPUTE_CREATE_REFUND: '/payments/disputes/:teamId/:rootDisputeId/create-refund/:receiptId',
  DISPUTE_INITIATE_REFUND: '/payments/disputes/:teamId/:rootDisputeId/initiate-refund/:receiptId',
  DISPUTE_REFUND_SUCCESS: '/payments/disputes/:teamId/:rootDisputeId/refund-success/:receiptId',
  DISPUTE_REFUND_FAILED: '/payments/disputes/:teamId/:rootDisputeId/refund-failed/:receiptId',
  DISPUTE_PAYOUT: '/payments/disputes/:teamId/:rootDisputeId/payout/:payoutId',
  DISPUTE_PAYOUT_TXNS: '/payments/disputes/:teamId/:rootDisputeId/payout/:payoutId/transactions',
  DISPUTE_RECEIPT_REFUND: '/payments/disputes/:teamId/:rootDisputeId/refund-receipt/:receiptId',

  INTEGRATIONS: '/integrations',
  DEVELOPER_API: '/integrations/developer-api',
  WEBHOOKS: '/integrations/webhooks',
  QUICKBOOKS: '/integrations/quickbooks',
  QUICKBOOKS_LOGIN: '/integrations/quickbooks/login',
  QUICKBOOKS_SETTINGS: '/integrations/quickbooks/settings',

  SIGNIN: '/sign-in',
  SIGN_UP_QR: '/sign-up-qr',
  SIGN_UP: '/sign-up',
  RESET: '/reset',

  REDIRECT: '/redirect',

  DIGITAL_ONBOARDING_NAME: '/digital-onboarding-name',
  DIGITAL_ONBOARDING_EMAIL: '/digital-onboarding-email',
  DIGITAL_ONBOARDING_PROFESSION: '/digital-onboarding-profession',
  DIGITAL_ONBOARDING_BUSINESS_NAME: '/digital-onboarding-business-name',
  DIGITAL_ONBOARDING_EMPLOYEES: '/digital-onboarding-employees',
  DIGITAL_ONBOARDING_REFERRAL_SOURCE: '/digital-onboarding-referral-source',
  DIGITAL_ONBOARDING_WEBSITE: '/digital-onboarding-website',
  DIGITAL_ONBOARDING_EMAIL_ERROR: '/digital-onboarding-email-error',

  DECLINED: '/declined',
  BLOCKED: '/blocked',
  WAITING_LIST: '/waiting-list'
};

export default routesPath;
