import React, { FC } from 'react';
import { Switch } from '@headlessui/react';

interface SwitchProps {
  enabled: boolean;
  toggleSwitch: () => void;
  className?: string;
  id: string;
}

const CustomSwitch: FC<SwitchProps> = ({ enabled, toggleSwitch, className, id }) => {
  return (
    <Switch
      className={`focus-visible:ring-opacity-/75 remove-highlight relative inline-flex h-3.5
          w-9 shrink-0 cursor-pointer items-center rounded-full bg-borderGray transition-colors
          duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white ${className}`}
      checked={enabled}
      onChange={toggleSwitch}
      id={id}>
      <span
        aria-hidden="true"
        className={`${enabled ? 'translate-x-4 bg-secondary' : 'translate-x-0 bg-accentText'}
                pointer-events-none inline-block h-5 w-5 rounded-full shadow-lg
              ring-0 transition duration-200 ease-in-out`}
      />
    </Switch>
  );
};

export default CustomSwitch;
