import { useEffect, useMemo, useState } from 'react';
import { isChrome, isIOS } from 'react-device-detect';

interface screenDimensions {
  screenWidth: number;
  screenHeight: number;
}

interface UseScreenDimensionsType extends screenDimensions {
  isMobileView: boolean;
  viewportHeightInPx: string;
}

function getScreenDimensions(): screenDimensions {
  const { innerWidth: innerScreenWidth, innerHeight: screenHeight, outerWidth: outerScreenWidth } = window ?? {};
  const screenWidth = outerScreenWidth && innerScreenWidth > outerScreenWidth ? outerScreenWidth : innerScreenWidth;

  return {
    screenWidth,
    screenHeight
  };
}

export default function useScreenDimensions(): UseScreenDimensionsType {
  const [screenDimensions, setScreenDimensions] = useState(getScreenDimensions());

  useEffect(() => {
    function handleResize() {
      setScreenDimensions(getScreenDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobileView = screenDimensions?.screenWidth < 640;

  const viewportHeightInPx = useMemo(
    () =>
      screenDimensions?.screenWidth > 1024 || (isIOS && isChrome) ? '100vh' : `${screenDimensions?.screenHeight}px`,
    [screenDimensions?.screenHeight, isIOS, isChrome]
  );

  return { ...screenDimensions, isMobileView, viewportHeightInPx };
}
