import { CircleCloseIcon, VerificationPageIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { SUPPORT_MAIL } from 'config/index';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { replaceURL } from 'utils/commonUtils';

interface VerifyingStateProps {
  onDone: VoidFn;
  className?: string;
}

const VerifyingState: FC<VerifyingStateProps> = ({ onDone, className = '' }) => {
  const handleEmailClick = () => {
    replaceURL(`mailto:${SUPPORT_MAIL}`);
  };

  return (
    <div className={twMerge('relative flex flex-col items-center justify-center gap-5 p-14 text-center', className)}>
      <CircleCloseIcon className="absolute right-2.5 top-2.5 cursor-pointer" onClick={onDone} />
      <VerificationPageIcon className="shrink-0" />
      <div className="text-xl font-bold text-heading">Verification in progress</div>
      <div className="text-sbase text-primaryText">
        Your documents have been received. We will review your details and notify you within 24 working hours.
      </div>
      <div className="text-sbase text-primaryText">
        For further queries, please contact us at{' '}
        <span className="cursor-pointer text-secondary" onClick={handleEmailClick}>
          support@goscanpay.com
        </span>
      </div>
      <CustomButton
        id="application-status-continue-button"
        type={ButtonType.SECONDARY}
        onClick={onDone}
        className="mt-5 h-10 px-10">
        Done
      </CustomButton>
    </div>
  );
};

export default VerifyingState;
