import { DecrementIcon, IncrementIcon } from 'assets/icons';
import CustomInput from 'components/custom-input.tsx/CustomInput';
import { REGEX } from 'constants/common';
import { FC, useEffect, useState } from 'react';
import { roundToTwoDecimals } from 'utils/amountUtils';

interface DecrementIncrementButtonProps {
  className?: string;
  changeValueBy?: number;
  setValueCallback?: (arg0: string) => void;
  overridingValue?: string;
}

const DecrementIncrementButton: FC<DecrementIncrementButtonProps> = ({
  setValueCallback = undefined,
  changeValueBy = 1,
  className = '',
  overridingValue = undefined
}) => {
  const [value, setValue] = useState<string>('1.00');

  const [isMounted, setIsMounted] = useState<boolean>(false);

  useEffect(() => {
    if (overridingValue) {
      if (!isMounted) {
        setValue((+overridingValue).toFixed(2).toString());
        setIsMounted(true);
      } else {
        setValue(overridingValue);
      }
    }
  }, [overridingValue]);

  const onIncrementClick = (): void => {
    const newValue: number = roundToTwoDecimals(+value + changeValueBy);
    if (newValue < 0 || !REGEX.maximumTwoDecimals.test(newValue.toString())) {
      return;
    }
    setValue(newValue.toFixed(2).toString());
    setValueCallback && setValueCallback(newValue.toFixed(2).toString());
  };

  const onDecrementClick = (): void => {
    if (+value === 0) {
      return;
    }
    const newValue: number = roundToTwoDecimals(+value - changeValueBy);
    if (newValue < 0 || !REGEX.maximumTwoDecimals.test(newValue.toString())) {
      return;
    }
    setValue(newValue.toFixed(2).toString());
    setValueCallback && setValueCallback(newValue.toFixed(2).toString());
  };

  const onChangeQuantity = (quantityValue: string): void => {
    if (!REGEX.maximumTwoDecimals.test(quantityValue)) {
      return;
    }
    setValue(quantityValue);
    setValueCallback && setValueCallback(quantityValue);
  };

  return (
    <div className={`${className} flex h-[50px] items-center rounded-md border border-borderGray bg-primary`}>
      <div
        onClick={onDecrementClick}
        className="flex h-full w-[50px] shrink-0 cursor-pointer items-center justify-center">
        <DecrementIcon className="h-6 w-6 shrink-0" />
      </div>
      <div className="flex h-full min-w-[45px] border-x border-borderGray">
        <CustomInput
          className="h-full rounded-none text-center text-sbase font-semibold text-primaryText outline-none"
          value={value}
          id="create-item-qty"
          onChange={onChangeQuantity}
          placeholder="0.00"
        />
      </div>

      <div
        onClick={onIncrementClick}
        className="flex h-full w-[50px] shrink-0 cursor-pointer items-center justify-center">
        <IncrementIcon className="h-6 w-6 shrink-0" />
      </div>
    </div>
  );
};

export default DecrementIncrementButton;
