import { FC, useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseNavTabs, getNavSectionList } from 'constants/navConstants';
import { NavTab } from 'types/baseTypes';
import { findActiveTab } from 'utils/commonUtils';
import { twMerge } from 'tailwind-merge';
import classnames from 'classnames';
import TeamSwitcher from 'containers/side-nav/components/TeamSwitcher';
import OverlayedNavSection from './OverlayedNavSection';
import { HamburgerIcon } from 'assets/icons';
import { AccountSettingsContext, IAccountSettingsContext } from '../MyAccount';
import { selectCurrentTeam, selectPaymentSystemConfig } from 'containers/app/appSlice';
import { PaymentSystem } from 'containers/payouts/constants';
import { useAppSelector } from 'hooks';
import { PaymentSystemTransitionType } from 'containers/home/constants';

const OverlayedSideNav: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(findActiveTab(location?.pathname) ?? baseNavTabs[0]);
  const { setShowOverlayedSideNav, showOverlayedSideNav } = useContext(
    AccountSettingsContext
  ) as IAccountSettingsContext;
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig) ?? {};
  const currentTeam = useAppSelector(selectCurrentTeam);

  const navSectionList = getNavSectionList(
    paymentSystem === PaymentSystem.LIP,
    currentTeam.paymentSystemTransitionType === PaymentSystemTransitionType.LIPConnectMigration
  );

  useEffect(() => {
    setActiveTab(findActiveTab(location?.pathname) ?? baseNavTabs[0]);
  }, [location]);

  const onTabClick = (tab: NavTab) => {
    setActiveTab(tab);
    navigate(tab.path);
  };

  const onSubTabClick = (tab: NavTab) => {
    navigate(tab.path);
  };

  const lastIndex = navSectionList.length - 1;

  const toggleshowOverlayedSideNav = () => {
    setShowOverlayedSideNav(prev => !prev);
  };

  return (
    <>
      <div className="fixed top-0 z-[101] h-full">
        <section
          className={twMerge(
            `z-[51] h-full fixed bg-primary hidden flex-col overflow-auto border-r 
        text-sbase sm:flex w-[100px] min-w-[235px] ease-in-out duration-300
        ${showOverlayedSideNav ? 'translate-x-0' : '-translate-x-full'}
        `
          )}>
          <div className="flex h-[60px] flex-row items-center gap-2 border-b">
            <HamburgerIcon
              onClick={toggleshowOverlayedSideNav}
              className="path-fill-current ml-7 shrink-0 cursor-pointer text-accentText"
            />
            <div className="text-sbase font-semibold text-primaryText">{activeTab.title}</div>
          </div>
          <div className="overflow-y-auto overflow-x-hidden">
            {navSectionList.map((navList, index) => {
              return (
                <OverlayedNavSection
                  className={twMerge(
                    'px-7',
                    classnames({
                      'border-b border-secondaryBtn': index !== lastIndex
                    })
                  )}
                  key={navList.toString() + navList[0].key}
                  activeTab={activeTab}
                  navList={navList}
                  onNavTabClick={onTabClick}
                  onSubTabClick={onSubTabClick}
                />
              );
            })}
          </div>
          <TeamSwitcher
            isOverlayedSideNavLayout={true}
            showDetails={true}
            className="mt-auto h-[80px] min-h-[80px] overflow-hidden border-t border-borderGray pl-4 pr-3"
          />
        </section>
        <section
          onClick={toggleshowOverlayedSideNav}
          className={`pointer-events-none fixed
           h-full w-screen
            transition-opacity duration-500 ease-in-out 
            ${showOverlayedSideNav ? 'pointer-events-auto z-50 bg-gray-900/50' : 'opacity-0'}`}></section>
      </div>
    </>
  );
};

export default OverlayedSideNav;
