import {
  AchIcon,
  ApplePayIcon,
  CashAppIcon,
  CreditDebitCardIcon,
  GooglePayIcon,
  GrabPayIcon,
  InTransitIcon,
  KlarnaIcon,
  PaymentIcon,
  PaypalIcon,
  ScheduledIcon,
  SplititIcon,
  SrcIcon,
  TinkIcon,
  TrueLayerIcon,
  TxnBlockedIcon,
  TxnFailedIcon,
  TxnPendingIcon,
  TxnRefundIcon,
  TxnSuccessIcon,
  TxnTimedOutIcon,
  VenmoIcon
} from 'assets/icons';
import { PaymentMethods } from 'constants/common';
import { TransactionStatus } from 'constants/invoicesConstants';

export const getPaymentMethodIcons = (paymentMethod: string) => {
  switch (paymentMethod) {
    case PaymentMethods.ACH:
    case PaymentMethods.ACHBETA:
      return <AchIcon />;
    case PaymentMethods.APPLEPAY:
      return <ApplePayIcon />;
    case PaymentMethods.CARD:
      return <CreditDebitCardIcon />;
    case PaymentMethods.GOOGLEPAY:
      return <GooglePayIcon />;
    case PaymentMethods.GRABPAY:
      return <GrabPayIcon />;
    case PaymentMethods.KLARNA:
      return <KlarnaIcon />;
    case PaymentMethods.PAYPAL:
    case PaymentMethods.PAYPAL_SCAN:
      return <PaypalIcon />;
    case PaymentMethods.SPLITIT:
      return <SplititIcon />;
    case PaymentMethods.TINK:
      return <TinkIcon />;
    case PaymentMethods.TRUELAYER:
      return <TrueLayerIcon />;
    case PaymentMethods.VENMO:
    case PaymentMethods.VENMO_SCAN:
      return <VenmoIcon />;
    case PaymentMethods.SGQR:
      return <SrcIcon />;
    case PaymentMethods.CASHAPP:
      return <CashAppIcon />;
    case PaymentMethods.CLICKTOPAY:
    default:
      return <PaymentIcon />;
  }
};

export const getTxnStatusIcons = (status: string) => {
  switch (status) {
    case TransactionStatus.SUCCESS:
      return <TxnSuccessIcon />;
    case TransactionStatus.PENDING:
    case TransactionStatus.INITIATED:
      return <TxnPendingIcon />;
    case TransactionStatus.BLOCKED:
      return <TxnBlockedIcon />;
    case TransactionStatus.TIMED_OUT:
      return <TxnTimedOutIcon />;
    case TransactionStatus.REFUNDED:
    case TransactionStatus.REFUND_INITIATED:
      return <TxnRefundIcon />;
    case TransactionStatus.CANCELLED:
    case TransactionStatus.FAILED:
      return <TxnFailedIcon />;
    case TransactionStatus.IN_TRANSIT:
      return <InTransitIcon />;
    case TransactionStatus.SCHEDULED:
      return <ScheduledIcon />;
  }
};
