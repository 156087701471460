import { FC, useEffect, useState } from 'react';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam, selectCurrentTeamInvoicePermissions } from 'containers/app/appSlice';
import { useLazyGetConnectPayoutByIdQuery, useUpdatePayoutNotesMutation } from '../api';
import { VoidFn } from 'types/baseTypes';
import { PAYOUT_PANEL_PAGES } from '../constants';
import PayoutNotesPanel from './PayoutNotesPanel';
import ConnectPayoutDetails from './ConnectPayoutDetails';
import { useParams } from 'react-router-dom';

interface ConnectPayoutDetailsPanelProps {
  handleSidePanelClose: VoidFn;
  payoutId?: string;
  isIdFromProp?: boolean;
}

const ConnectPayoutDetailsPanel: FC<ConnectPayoutDetailsPanelProps> = ({
  handleSidePanelClose,
  payoutId = '',
  isIdFromProp = false
}) => {
  const { payoutId: payoutIdFromURL } = useParams();

  const currentTeam = useAppSelector(selectCurrentTeam);

  const [page, setPage] = useState<PAYOUT_PANEL_PAGES>(PAYOUT_PANEL_PAGES.CONNECT_PAYOUT);

  const [updateNotesMutation, { isSuccess: isNotesUpdateSuccess }] = useUpdatePayoutNotesMutation();

  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);

  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);

  const [
    getConnectPayoutByIdQuery,
    { data: connectPayoutByIdData, isFetching: isFetchingPayoutData, isSuccess: isPayoutDataFetchSuccess }
  ] = useLazyGetConnectPayoutByIdQuery();

  const getConnectPayoutById = () => {
    if (currentTeam?.id && (payoutIdFromURL || payoutId)) {
      const id = isIdFromProp ? payoutId : payoutIdFromURL;
      getConnectPayoutByIdQuery({ teamId: currentTeam.id, payoutId: id });
    }
  };

  useEffect(() => {
    if (hasPermissionsToView) getConnectPayoutById();
  }, [currentTeam?.id, hasPermissionsToView]);

  useEffect(() => {
    if (!payoutPermissions.viewPayoutsByTeam) {
      setHasPermissionsToView(false);
    }
  }, [payoutPermissions.viewPayoutsByTeam]);

  useEffect(() => {
    if (isNotesUpdateSuccess) {
      setPage(PAYOUT_PANEL_PAGES.CONNECT_PAYOUT);
      getConnectPayoutById();
    }
  }, [isNotesUpdateSuccess]);

  const handleNotesClick = () => {
    setPage(PAYOUT_PANEL_PAGES.NOTES);
  };

  const addPayoutNotes = (notes: string) => {
    updateNotesMutation({ payoutId: connectPayoutByIdData.data.ID, teamId: currentTeam.id, body: { notes } });
  };

  const onPayoutsNotesClose = () => {
    setPage(PAYOUT_PANEL_PAGES.CONNECT_PAYOUT);
  };

  return (
    <>
      {page === PAYOUT_PANEL_PAGES.CONNECT_PAYOUT && (
        <ConnectPayoutDetails
          handleSidePanelClose={handleSidePanelClose}
          connectPayoutData={connectPayoutByIdData}
          isFetchingPayoutData={isFetchingPayoutData}
          isPayoutDataFetchSuccess={isPayoutDataFetchSuccess}
          onNotesClick={handleNotesClick}
          hasPermission={hasPermissionsToView}
        />
      )}
      {page === PAYOUT_PANEL_PAGES.NOTES && (
        <PayoutNotesPanel
          onClose={onPayoutsNotesClose}
          onSave={addPayoutNotes}
          payoutNotes={connectPayoutByIdData.data.notes}
        />
      )}
    </>
  );
};

export default ConnectPayoutDetailsPanel;
