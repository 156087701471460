import { ChatWithUsIcon, FAQIcon } from 'assets/icons';
import React, { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface HelpMenuProps {
  onChat: VoidFn;
  onFAQ: VoidFn;
}

const HelpMenu: FC<HelpMenuProps> = ({ onChat, onFAQ }) => {
  return (
    <div className="flex flex-col space-y-6 text-sbase font-normal text-primaryText">
      <div onClick={onFAQ} className="flex flex-row items-center gap-4 cursor-pointer">
        <FAQIcon className="shrink-0" />
        <div>FAQs</div>
      </div>
      <div onClick={onChat} className="flex flex-row items-center gap-4 cursor-pointer">
        <ChatWithUsIcon className="shrink-0" />
        <div>Chat with us</div>
      </div>
    </div>
  );
};

export default HelpMenu;
