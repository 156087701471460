import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import { GetCustomersResponse } from './customers.model';

//TODO move to customer model
export interface GetCustomersPayload {
  teamId: number;
  search: string;
  limit: number;
  page: number;
}

export interface GetRecentCustomersPayload {
  teamId: number;
}

export interface CreateCustomerPayload {
  teamId: number;
  name: string;
  phoneNumber: string;
  email: string;
  lastName: string;
  street: string;
  cityStateCountry: string;
  zipCode: string;
}

export interface UpdateCustomerPayload {
  teamId: number;
  name: string;
  phoneNumber: string;
  email: string;
  lastName: string;
  street: string;
  cityStateCountry: string;
  zipCode: string;
  customerId: string;
}

const CustomersBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCustomersList: builder.query<GetCustomersResponse, GetCustomersPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/customers?`;
        const urlParams = new URLSearchParams();
        Object.keys(params).map(key => {
          if (Array.isArray(params[key])) {
            params[key].map(item => {
              urlParams.append(key, item);
            });
          } else {
            if (params[key] !== null) {
              urlParams.append(key, params[key]);
            }
          }
        });
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch customers data',
        showToast: true
      }
    }),
    createCustomer: builder.mutation<any, CreateCustomerPayload>({
      query: ({ teamId, email, phoneNumber, name, lastName, zipCode, street, cityStateCountry }) => {
        return {
          url: `${API_VERSION.V3}/merchants/customers`,
          method: 'POST',
          body: { teamId: teamId.toString(), email, phoneNumber, name, lastName, zipCode, street, cityStateCountry }
        };
      }
    }),
    updateCustomer: builder.mutation<any, UpdateCustomerPayload>({
      query: ({ teamId, email, phoneNumber, name, lastName, zipCode, street, cityStateCountry, customerId }) => {
        return {
          url: `${API_VERSION.V3}/merchants/customers/${customerId}`,
          method: 'PUT',
          body: {
            teamId: teamId.toString(),
            email,
            phoneNumber,
            name,
            lastName,
            zipCode,
            street,
            cityStateCountry,
            keepExisting: false
          }
        };
      }
    }),
    getRecentSearchedCustomers: builder.query<GetCustomersResponse, GetRecentCustomersPayload>({
      query: ({ teamId }) => {
        const url = `${API_VERSION.V1}/merchants/recent-customers?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch recent customers ',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetCustomersListQuery,
  useCreateCustomerMutation,
  useUpdateCustomerMutation,
  useLazyGetRecentSearchedCustomersQuery
} = CustomersBaseApi;
