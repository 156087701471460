import React, { FC, Fragment, useState } from 'react';

import { ArrowRightIcon, CaretDownIcon, FetchingErrorIcon, LineGraphIcon, ReloadIcon } from 'assets/icons';
import Avatar from '@material-ui/core/Avatar';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { PaymentMethod, TeamMember } from 'containers/home/home.model';
import { getInitials } from 'utils/commonUtils';
import { SalesDetailsTypes } from './constants';
import { If } from 'components';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import PaymentBreakdownDoughnut from 'containers/home/components/PaymentBreakdownDoughnut';
import { formatAmount } from 'utils/amountUtils';
import { useNavigate } from 'react-router-dom';
import { selectCurrentTeamCustomersPermissions, selectCurrentTeamInvoicePermissions } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { SalesTypeByDuration } from 'containers/home/types';
import OutsideClickHandler from 'react-outside-click-handler';
import { logAnalyticEvent } from 'utils/analytics';
import { Popover, Transition } from '@headlessui/react';
import PermissionError from './PermissionError';
import { resetInvoiceState } from 'containers/invoices/InvoicesSlice';

interface SalesProps {
  details: PaymentMethod[] | TeamMember[] | null;
  title: string;
  type: string;
  isSuccess: boolean;
  handleReload?: VoidFn;
  isFetching?: boolean;
  totalSales?: string;
  selectedDurationForSales: SalesTypeByDuration;
  setSelectedDurationForSales: (type: SalesTypeByDuration) => void;
  isPermissionError: boolean;
}

const SalesDetails: FC<SalesProps> = props => {
  const {
    details,
    title,
    type,
    isSuccess,
    handleReload,
    isFetching,
    totalSales,
    isPermissionError,
    selectedDurationForSales,
    setSelectedDurationForSales
  } = props;
  const dispatch = useAppDispatch();
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const onOutSideClick = () => {
    setIsDropdownOpen(false);
  };

  const handleCreateInvoiceClick = () => {
    dispatch(resetInvoiceState());
    navigate('/invoices/create');
    logAnalyticEvent(
      type === SalesDetailsTypes.BY_TEAM_MEMBERS
        ? clevertapEvents.webSalesByTeamMemberCreateInvoice
        : clevertapEvents.webSalesByPaymentMethodCreateInvoice
    );
  };

  const renderPaymentMethodSales = () => {
    return details?.map((item, index) => (
      <div
        id={`sales-by-payment-method-${item.paymentMethod}`}
        className="flex w-full items-center gap-4 border-b border-secondaryBtn py-3 text-primaryText last:border-b-0"
        key={item.paymentMethod + index}>
        <div className={'h-[9px] w-[9px] shrink-0 rounded-sm'} style={{ backgroundColor: `${item.colorCode}` }}></div>
        <div
          className={`text-sbase font-semibold leading-5 ${
            index === selectedIndex ? 'font-extrabold' : 'font-semibold'
          }`}>
          {item.paymentMethod}
        </div>
        <div className={`ml-auto justify-self-end text-right ${index === selectedIndex ? 'font-extrabold' : ''}`}>
          <div>{`$${formatAmount(item.sales)}`}</div>
        </div>
      </div>
    ));
  };

  const displayProgressBar = percentage => {
    return (
      <div className="relative h-3 w-full pt-2">
        <div className="absolute h-[1px] w-full bg-[#ECF1F7]">
          <div
            className="absolute bottom-[-1px] h-1 rounded-md bg-[#A4D7FA]"
            style={{ width: `${percentage || 0}%` }}></div>
        </div>
      </div>
    );
  };

  const displayAvatar = teamMemberData => {
    const avtName = getInitials(teamMemberData.displayName);
    return (
      <Avatar src={teamMemberData?.profilePictureUrl} className="flex h-10 w-10 bg-secondaryBtn text-[#3E556D]">
        {avtName}
      </Avatar>
    );
  };

  const renderTeamMemberSales = () => {
    return details?.map(item => (
      <div className="flex items-center gap-4 pr-5" key={item.merchantId + item.displayName}>
        <div>{displayAvatar(item)}</div>
        <div className="w-full">
          <div
            className="overflow-hidden text-ellipsis whitespace-nowrap text-sbase font-semibold
          leading-5 text-primaryText">
            {item.displayName}
          </div>
          <div className="w-full">{displayProgressBar(item.percentage)}</div>
        </div>
        <div className="ml-auto min-w-[85px] justify-self-end text-right">
          <div className="font-semibold text-primaryText">{`$${formatAmount(item.sales)}`}</div>
          <div className="text-px13 text-accentText">{`+$${formatAmount(item.tip)} tip`}</div>
        </div>
      </div>
    ));
  };

  const handleFilterSelectEmptyData = () => {
    switch (selectedDurationForSales) {
      case 'Today':
        return 'No sales yet today';
      case 'This Month':
        return 'No sales in this month';
      case 'Last Month':
        return 'No sales in last month';
      case 'This Year':
        return 'No sales in this year';
      default:
        return 'No sales yet today';
    }
  };

  const getTotalSailsText = () => {
    if (selectedIndex !== null) {
      return details[selectedIndex].sales;
    }
    return totalSales;
  };
  return (
    <div
      onMouseEnter={() => {
        logAnalyticEvent(
          type === SalesDetailsTypes.BY_PAYMENT_METHODS
            ? clevertapEvents.webSalesByPaymentMethod
            : clevertapEvents.webSalesByTeamMember
        );
      }}
      className="flex h-full w-full flex-col rounded border border-borderGray">
      <div className="flex w-full shrink-0 flex-col px-5 py-2.5">
        <div className="flex items-center justify-between">
          <div className="text-xl font-bold text-primaryText">{title}</div>

          {/*/!* to be changed when filter design is provided *!/*/}
          {/*<div className="flex h-[30px] items-center gap-2 rounded-[20px] border border-borderGray px-4">*/}
          {/*  <div className="text-px13 text-accentText">Today</div>*/}
          {/*  <div className="flex items-center justify-center">*/}
          {/*    <CaretDownIcon />*/}
          {/*  </div>*/}
          {/*</div>*/}
          {/*/!* // *!/*/}

          <OutsideClickHandler onOutsideClick={onOutSideClick}>
            <div className={twMerge('w-28')}>
              <Popover className="relative">
                {() => (
                  <>
                    <div
                      id="home-graph-filter-dropdown-default"
                      className="flex cursor-pointer flex-row justify-between rounded border border-borderGray
                       px-2 py-1"
                      onClick={() => setIsDropdownOpen(true)}>
                      <div className="rounded	text-sbase font-normal text-heading">{selectedDurationForSales}</div>
                      <div className="flex items-center justify-center">
                        <CaretDownIcon />
                      </div>
                    </div>
                    <Transition
                      show={isDropdownOpen}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel
                        className="customNormalScroll absolute
                           z-40 w-full overflow-y-auto rounded-md
                           border border-borderGray bg-primary shadow-md">
                        <div className={'pb-1'}>
                          <div
                            id="today-graph-filter"
                            className={'cursor-pointer pl-2 pt-3 text-sbase font-normal leading-5 text-heading'}
                            onClick={() => {
                              setSelectedDurationForSales(SalesTypeByDuration.TODAY);
                              setIsDropdownOpen(false);
                            }}>
                            {SalesTypeByDuration.TODAY}
                          </div>
                          <div
                            id="this-month-graph-filter"
                            className={'cursor-pointer pl-2 pt-3 text-sbase font-normal leading-5 text-heading'}
                            onClick={() => {
                              setSelectedDurationForSales(SalesTypeByDuration.THIS_MONTH);
                              setIsDropdownOpen(false);
                            }}>
                            {SalesTypeByDuration.THIS_MONTH}
                          </div>

                          <div
                            id="last-month-graph-filter"
                            className={'cursor-pointer pl-2 pt-3 text-sbase font-normal leading-5 text-heading'}
                            onClick={() => {
                              setSelectedDurationForSales(SalesTypeByDuration.LAST_MONTH);
                              setIsDropdownOpen(false);
                            }}>
                            {SalesTypeByDuration.LAST_MONTH}
                          </div>

                          <div
                            id="this-year-graph-filter"
                            className={'cursor-pointer pl-2 pt-3 pb-2 text-sbase font-normal leading-5 text-heading'}
                            onClick={() => {
                              setSelectedDurationForSales(SalesTypeByDuration.THIS_YEAR);
                              setIsDropdownOpen(false);
                            }}>
                            {SalesTypeByDuration.THIS_YEAR}
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </OutsideClickHandler>
        </div>
        <If condition={Boolean(+totalSales)}>
          <div
            id={`${
              type === SalesDetailsTypes.BY_TEAM_MEMBERS
                ? 'sales-by-team-member-amount'
                : 'sales-by-payment-method-amount'
            }`}
            className="flex flex-row items-center">
            <div className="text-xl font-bold text-secondary">{`$${formatAmount(getTotalSailsText())}`}</div>
            {selectedIndex !== null && (
              <div className={'font-primaryText ml-1 text-base font-semibold'}>
                {'(' + details[selectedIndex].percentage + '%)'}
              </div>
            )}
          </div>
        </If>
      </div>
      <div className="w-full overflow-y-auto p-5">
        <div
          id="this-year-bar-graph-click"
          className={`${
            type === SalesDetailsTypes.BY_TEAM_MEMBERS
              ? 'customNormalScroll box-border flex max-h-60 w-full flex-col gap-2 overflow-y-auto overflow-x-hidden'
              : ''
          }`}>
          <If condition={isSuccess && !isFetching && Boolean(details)}>
            <If condition={type === SalesDetailsTypes.BY_TEAM_MEMBERS}>{renderTeamMemberSales()}</If>
            <If condition={type === SalesDetailsTypes.BY_PAYMENT_METHODS}>
              <div className="flex w-full flex-col-reverse items-center gap-2 md:flex-row">
                <div
                  className="customLeftScroll box-border
                flex max-h-60 w-full flex-col gap-2 self-start overflow-y-auto overflow-x-hidden md:w-3/5">
                  <div className="rotateChild w-full px-5">{renderPaymentMethodSales()}</div>
                </div>
                <div
                  id="payment-method-doughnut-graph-click"
                  className="flex items-center justify-center md:w-2/5"
                  style={{ userSelect: 'none' }}>
                  <PaymentBreakdownDoughnut
                    data={details as unknown as PaymentMethod[]}
                    setSelectedIndex={setSelectedIndex}
                  />
                </div>
              </div>
            </If>
          </If>
          <If condition={!isSuccess && !isFetching && !isPermissionError}>
            <div className="rotateChild flex flex-col items-center justify-center gap-2 p-16">
              <FetchingErrorIcon />
              <div className="text-sbase text-accentText">Something went wrong!</div>
              <div className="flex items-center gap-2">
                <div className="cursor-pointer" onClick={handleReload}>
                  <ReloadIcon />
                </div>
                <div className="text-px13 text-accentText">Please try refreshing</div>
              </div>
            </div>
          </If>
          <If condition={!isSuccess && !isFetching && isPermissionError}>
            <div className="p-14">
              <PermissionError />
            </div>
          </If>
          <If condition={isSuccess && !details && !isFetching}>
            <div className="rotateChild flex flex-col items-center justify-center gap-2 p-16">
              <LineGraphIcon />
              <div className="text-sbase font-semibold text-accentText">{handleFilterSelectEmptyData()}</div>
              <div
                onClick={handleCreateInvoiceClick}
                className={twMerge(
                  'flex cursor-pointer items-center gap-2',
                  classnames({
                    'pointer-events-none opacity-50':
                      !invoicePermissions?.createInvoice || !customerPermissions.createNewCustomers
                  })
                )}>
                <div
                  id="create-new-invoice-team-member/payment-method-graph"
                  className="text-px13 font-semibold text-secondary">
                  Create new invoice
                </div>
                <ArrowRightIcon />
              </div>
            </div>
          </If>
          <If condition={isFetching}>
            <div className="m-auto flex items-center justify-center p-[3.7rem]">
              <Lottie className="h-20 w-20" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

export default SalesDetails;
