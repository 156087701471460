import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { DisputeDetail, DisputeEvidence, DisputeReason } from './disputes.model';
import { DISPUTE_RESPONSE, DISPUTE_STATUS } from './constants';
import { DISPUTE_TAB_TITLE } from './constants';

const initialDisputeDetail: DisputeDetail = {
  id: '',
  statusDetail: '',
  merchantStatus: '',
  reasonForDispute: '',
  messageForMerchant: '',
  transactionId: '',
  transactionAmount: '',
  customerName: '',
  collectedBy: '',
  invoiceNumber: '',
  paymentMethod: '',
  paymentMethodDetail: '',
  deductionStatus: '',
  deductionDetail: '',
  amount: '',
  disputeFee: '',
  respondBy: '',
  resultDate: '',
  transactionDate: '',
  collectedByProfileUrl: '',
  deductBy: '',
  deductedBy: '',
  internalStatus: '',
  isCBP: false,
  toolTipContent: '',
  invoiceId: '',
  deductionState: '',
  merchantComment: '',
  merchantReason: '',
  teamEmail: '',
  teamName: '',
  updatedAt: '',
  claimDocumentUrl: '',
  issuingBankResponseUrl: '',
  closedAt: '',
  deductionOption: '',
  isPartialRecovery: false,
  merchantTimezone: '',
  recoveryAmount: '',
  teamId: '',
  claimDocumentId: '',
  formattedRespondBy: '',
  formattedTransactionDate: '',
  formattedUpdatedAt: ''
};

const initialState = {
  uploadedDocList: [],
  disputeDetail: initialDisputeDetail,
  disputeResponse: '',
  disputeReason: null,
  otherDisputeReason: '',
  supportingComments: '',
  disputeReasonsFilter: [],
  disputedStartDate: null,
  disputedEndDate: null,
  respondByStartDate: null,
  respondByEndDate: null,
  amount: { min: '', max: '' },
  statuses: [DISPUTE_STATUS.NEEDS_RESPONSE],
  currentTab: DISPUTE_TAB_TITLE.NEEDS_RESPONSE,
  previousRoute: ''
};

export const disputesSlice = createSlice({
  name: 'disputes',
  initialState,
  reducers: {
    resetDisputeState: () => initialState,
    updateUploadedDocList: (state, { payload }: PayloadAction<DisputeEvidence[]>) => {
      state.uploadedDocList = payload;
    },
    updateDisputeDetail: (state, { payload }: PayloadAction<DisputeDetail>) => {
      state.disputeDetail = payload;
    },
    setDisputeResponse: (state, { payload }: PayloadAction<DISPUTE_RESPONSE>) => {
      state.disputeResponse = payload;
    },
    setDisputeReason: (state, { payload }: PayloadAction<DisputeReason | string>) => {
      state.disputeReason = payload;
    },
    setOtherDisputeReason: (state, { payload }: PayloadAction<string>) => {
      state.otherDisputeReason = payload;
    },
    setSupportingComments: (state, { payload }: PayloadAction<string>) => {
      state.supportingComments = payload;
    },
    setCurrentTab: (state, { payload }: PayloadAction<DISPUTE_TAB_TITLE>) => {
      state.currentTab = payload;
    },
    setDisputeReasonsFilter: (state, { payload }: PayloadAction<string[]>) => {
      state.disputeReasonsFilter = payload;
    },
    setDisputedStartDate: (state, { payload }: PayloadAction<string>) => {
      state.disputedStartDate = payload;
    },
    setDisputedEndDate: (state, { payload }: PayloadAction<string>) => {
      state.disputedEndDate = payload;
    },
    setRespondByStartDate: (state, { payload }: PayloadAction<string>) => {
      state.respondByStartDate = payload;
    },
    setRespondByEndDate: (state, { payload }: PayloadAction<string>) => {
      state.respondByEndDate = payload;
    },
    setDisputeStatuses: (state, { payload }: PayloadAction<DISPUTE_STATUS[]>) => {
      state.statuses = payload;
    },
    setAmountFilterValue: (state, { payload }: PayloadAction<{ min: string; max: string }>) => {
      state.amount = payload;
    },
    updatePreviousRoute: (state, { payload }: PayloadAction<string>) => {
      state.previousRoute = payload;
    }
  }
});

export const {
  resetDisputeState,
  updateDisputeDetail,
  updateUploadedDocList,
  setDisputeResponse,
  setDisputeReason,
  setOtherDisputeReason,
  setSupportingComments,
  setCurrentTab,
  setDisputeReasonsFilter,
  setDisputedEndDate,
  setDisputedStartDate,
  setRespondByEndDate,
  setRespondByStartDate,
  setDisputeStatuses,
  setAmountFilterValue,
  updatePreviousRoute
} = disputesSlice.actions;

export const selectUploadedDocumentList = (state: RootState) => state.rootReducer.disputes.uploadedDocList;
export const selectDisputeDetail = (state: RootState) => state.rootReducer.disputes.disputeDetail;
export const selectDisputeResponse = (state: RootState) => state.rootReducer.disputes.disputeResponse;
export const selectDisputeReason = (state: RootState) => state.rootReducer.disputes.disputeReason;
export const selectOtherDisputeReason = (state: RootState) => state.rootReducer.disputes.otherDisputeReason;
export const selectSupportingComments = (state: RootState) => state.rootReducer.disputes.supportingComments;
export const selectCurrentTab = (state: RootState) => state.rootReducer.disputes.currentTab;
export const selectDisputeReasonsFilter = (state: RootState) => state.rootReducer.disputes.disputeReasonsFilter;
export const selectDisputedEndDate = (state: RootState) => state.rootReducer.disputes.disputedEndDate;
export const selectDisputedStartDate = (state: RootState) => state.rootReducer.disputes.disputedStartDate;
export const selectRespondByStartDate = (state: RootState) => state.rootReducer.disputes.respondByStartDate;
export const selectRespondByEndDate = (state: RootState) => state.rootReducer.disputes.respondByEndDate;
export const selectDisputeStatuses = (state: RootState) => state.rootReducer.disputes.statuses;
export const selectAmount = (state: RootState) => state.rootReducer.disputes.amount;
export const selectPreviousRoute = (state: RootState) => state.rootReducer.disputes.previousRoute;

export default disputesSlice.reducer;
