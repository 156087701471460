import React, { FC } from 'react';
import { CircularStepper, CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { Profession } from '../digitalOnboarding.model';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { useUpdateBasicDetailsMutation } from '../api';
import { getCurrentData, getUserData, setUserData, updateCurrentData } from '../digitalOnboardingSlice';
import ListDropDownWithSearch from 'components/list-dropdown-with-search/ListDropDownWithSearch';
import routesPath from 'routes/RoutesPath';
import { constructBasicDetails } from '../utils';
interface ProfessionSectionProps {
  professions: Profession[];
}

const ProfessionSection: FC<ProfessionSectionProps> = ({ professions }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const currentData = useAppSelector(getCurrentData);
  const [updateBasicDetailsMutation] = useUpdateBasicDetailsMutation();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { profession } = currentData;
  const currentProfession = professions.find(merchantProfession => merchantProfession.profession === profession);

  const onProfessionChange = (value: Profession) => {
    dispatch(updateCurrentData({ key: 'profession', value: value.profession }));
  };

  const onContinue = () => {
    if (profession) {
      const updatedData = {
        ...userData,
        profession
      };
      dispatch(setUserData(updatedData));
      updateBasicDetailsMutation({
        payload: constructBasicDetails({
          userData: updatedData,
          teamId: currentTeam.id.toString()
        })
      });
      navigate(routesPath.DIGITAL_ONBOARDING_BUSINESS_NAME, { replace: true });
    }
  };

  const onBack = () => {
    navigate(routesPath.DIGITAL_ONBOARDING_EMAIL, { replace: true });
  };

  return (
    <div className="flex flex-col self-center lg:min-w-[375px]">
      <CircularStepper currentStep={3} totalSteps={7} />
      <div className="mb-[30px] flex max-w-[375px] flex-col gap-2.5">
        <div className="text-4xl font-bold leading-[43px] text-headingGray">
          What profession or industry is your business in?
        </div>
        <div className="text-17px text-primaryText">Select the profession/industry that you represent.</div>
      </div>
      <ListDropDownWithSearch
        items={professions}
        selectedItem={currentProfession}
        setSelectedItem={onProfessionChange}
        inputPlaceholder="Select profession/industry"
        buttonStyles="bg-highlightGray cursor-pointer"
        listKey="profession"
        optionsHeight="max-h-[300px]"
        emptyText="No profession found"
      />
      <div className="mt-10 flex flex-col gap-5">
        <CustomButton
          id="continue-sign-up"
          className="h-10 w-full"
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onContinue}
          disabled={!profession}>
          Continue
        </CustomButton>
        <CustomButton
          id="back-button-sign-up"
          className="h-10 w-full border-none shadow-none hover:bg-primary active:shadow-none"
          type={ButtonType.SECONDARY}
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onBack}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default ProfessionSection;
