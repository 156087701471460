import { Divider } from '@material-ui/core';
import Lottie from 'lottie-react';
import {
  ArrowRightIcon,
  CaretDownIcon,
  DisputesTabIcon,
  ExportIcon,
  NavCloseIcon,
  ReceiptInvoiceIcon,
  RefundIcon,
  RefundTransactionIcon,
  WhiteInfoIcon
} from 'assets/icons';
import classNames from 'classnames';
import { CustomPopover, GenericError, If } from 'components';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { getPaymentData, getRefundDisabledReason, isEmpty, shouldEnableRefund } from '../util';
import { FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { constructArrayObjectAsString, getInitials, getPageName } from 'utils/commonUtils';
import {
  useDeleteReceiptMutation,
  useLazyDownloadPrintReceiptAsPDFQuery,
  useLazyDownloadReceiptAsPDFQuery,
  useLazyGetReceiptByIdQuery
} from '../api';
import { ReceiptStatus } from '../receipts.model';
import { getReceiptDetailStatusColor, getReceiptStatus, getReceiptStatusBackgroundColor } from '../util';
import DataListItem from './DataListComponent';
import LineItems from './ReceiptLineItem';
import MoreMenu from './MoreMenu';
import { selectReceiptData, updateRefundAmount, updateRefundReason } from '../receiptsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamCustomersPermissions,
  selectCurrentTeamInvoicePermissions,
  selectCurrentTeamRefundPermissions,
  selectUserDetails,
  showNotifier
} from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { PopoverArrowTypes } from 'constants/common';
import { logAnalyticEvent } from 'utils/analytics';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { InfoMesssages } from 'types/infoMessages';
import SidePanel from 'components/side-panel/SidePanel';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE, FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import NoPermissions from 'components/no-permissions/NoPermissions';
import DateUtils from 'utils/dateUtils';
import { PaymentSystem } from 'containers/payouts/constants';
import { TeamRole } from 'containers/app/app.model';

interface ReceiptDetailsPanelProps {
  routeToNextPage?: ({ type }: { type: SidePanelTypes; id: string }) => void;
  onClose?: VoidFn;
}

const ReceiptDetailsPanel: FC<ReceiptDetailsPanelProps> = () => {
  const location = useLocation();
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [isRootState, setIsRootState] = useState(true);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const [downloadReceiptAsPDF] = useLazyDownloadReceiptAsPDFQuery();
  const [downloadPrintReceiptAsPDF] = useLazyDownloadPrintReceiptAsPDFQuery();
  const [deleteReceipt, { isSuccess: isDeleteReceiptSuccess }] = useDeleteReceiptMutation();
  const dispatch = useAppDispatch();
  const [getReceiptByIdQuery, getReceiptByIdState] = useLazyGetReceiptByIdQuery();
  const {
    isSuccess: receiptDataSuccess,
    isFetching: fetchingReceiptData,
    isError: receiptDataError
  } = getReceiptByIdState;
  const receiptData = useAppSelector(selectReceiptData);
  const currentRefundPermissions = useAppSelector(selectCurrentTeamRefundPermissions);
  const currentUser = useAppSelector(selectUserDetails);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const receiptPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const notifications = useAppSelector(getNotifications);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { rootReceiptId, receiptId, rootInvoiceId, rootPage, rootCustomerId, rootDisputeId } = useParams();
  const navigate = useNavigate();
  const { handleSidePanelClose, resetReceiptsQuery } = useOutletContext<any>();

  const isShareEnabled = () => {
    return (
      customerPermissions.viewCustomersCreatedByTeam ||
      customerPermissions.editCustomersCreatedByTeam ||
      customerPermissions.editExistingCustomers
    );
  };

  useEffect(() => {
    if ((receiptId || rootReceiptId) && currentTeam?.id) {
      getReceiptByIdQuery({ receiptId: receiptId ? receiptId : rootReceiptId, teamId: currentTeam.id });
    }
  }, [rootReceiptId, receiptId, currentTeam]);

  useEffect(() => {
    if (isDeleteReceiptSuccess) {
      handleSidePanelBack();
      if (resetReceiptsQuery) {
        resetReceiptsQuery();
      }
    }
  }, [isDeleteReceiptSuccess]);

  useEffect(() => {
    let routeStateTimeout;
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  useEffect(() => {
    if (receiptDataSuccess) {
      logAnalyticEvent(clevertapEvents.webReceiptsListDetailsPanelOpen, {
        'Line Items': constructArrayObjectAsString(receiptData.lineItems, 'Line Item'),
        Page: getPageName(location.pathname)
      });
    }
  }, [receiptDataSuccess]);

  useEffect(() => {
    if (receiptDataSuccess) {
      if (isCreatedBySelf()) {
        if (!receiptPermissions.viewReceiptsByTeam && !receiptPermissions.viewReceiptsBySelf) {
          setHasPermissionsToView(false);
        }
      } else if (!receiptPermissions.viewReceiptsByTeam) {
        setHasPermissionsToView(false);
      }
    }
  }, [receiptPermissions.viewReceiptsBySelf, receiptPermissions.viewReceiptsBySelf, receiptData]);

  const isCreatedBySelf = () => {
    return receiptData?.merchantId === currentUser?.id;
  };

  const isOwnerOrAdmin = () => {
    return currentTeam?.role === TeamRole.OWNER || currentTeam?.role === TeamRole.ADMIN;
  };

  const isAlreadyRefunded = () => {
    return !isEmpty(receiptData?.refundTransaction?.status);
  };

  const SuccessReceiptDetails = [
    {
      title: 'ScanPay ID',
      data: receiptData?.ID
    },
    {
      title: 'Customer',
      data: receiptData?.customerName || '-'
    },
    {
      title: 'Transaction Date',
      data: receiptData?.date
    },
    {
      title: 'Collected By',
      data: (
        <div className="flex items-center gap-1">
          <CustomAvatar
            src={receiptData?.profilePicture}
            width="w-4"
            height="h-4"
            fontStyle="text-[8px] text-tertiaryText">
            {getInitials(receiptData?.createdBy)}
          </CustomAvatar>
          <div className="truncate">{receiptData?.createdBy}</div>
        </div>
      )
    },
    {
      title: 'Invoice Number',
      data: receiptData?.invoiceNumber
    },
    {
      title: 'Payment Method',
      data: getPaymentData(receiptData)
    }
  ];

  const ReceiptDetails = [
    {
      title: 'Invoice ID',
      data: receiptData?.invoiceNumber || '-'
    },
    {
      title: 'Customer',
      data: receiptData?.customerName || '-'
    },
    {
      title: 'Transaction Date',
      data: receiptData?.date
    },
    {
      title: 'ScanPay ID',
      data: receiptData?.ID
    },
    {
      title: 'Created By',
      data: (
        <div className="flex items-center gap-1">
          <CustomAvatar
            src={receiptData?.profilePicture}
            width="w-4"
            height="h-4"
            fontStyle="text-[8px] text-tertiaryText">
            {getInitials(receiptData?.createdBy)}
          </CustomAvatar>
          <div className="truncate">{receiptData?.createdBy}</div>
        </div>
      )
    },
    {
      title: 'Payment Method',
      data: getPaymentData(receiptData)
    }
  ];

  const handleReceiptShare = () => {
    if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-receipt/${receiptData?.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-receipt/${receiptData?.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-receipt/${receiptData?.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-receipt/${receiptData?.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-receipt/${receiptData.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleRefund = () => {
    if (rootReceiptId) {
      if (receiptId) {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/create-refund/${receiptId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/create-refund`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/create-refund/${receiptData.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/create-refund/${receiptData.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/create-refund/${receiptData.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/create-refund/${receiptData.ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const bottomBar =
    receiptData.status === ReceiptStatus.SUCCESS
      ? [
          {
            id: 'receipts-bottom-bar-share-button',
            isDisabled: !isShareEnabled(),
            icon: (
              <div className="path-fill-current path">
                <ExportIcon className="h-5" />
              </div>
            ),
            disabledReason: InfoMesssages.shareReceiptNoPermissionMessage,
            btnText: 'Share',
            onClickHandler: () => {
              handleReceiptShare();
              logAnalyticEvent(clevertapEvents.webReceiptsListDetailShare, { Page: getPageName(location.pathname) });
            }
          },
          {
            id: 'receipts-bottom-bar-refund-button',
            icon: (
              <div>
                <RefundIcon className="h-5" />
              </div>
            ),
            isDisabled: !shouldEnableRefund(
              receiptData.isManualPayment,
              currentRefundPermissions,
              isCreatedBySelf(),
              isAlreadyRefunded()
            ),
            disabledReason: getRefundDisabledReason(
              receiptData.isManualPayment,
              currentRefundPermissions,
              isCreatedBySelf(),
              isAlreadyRefunded()
            ),
            btnText: 'Refund',
            onClickHandler: () => {
              dispatch(updateRefundReason(''));
              dispatch(updateRefundAmount(''));
              handleRefund();
              logAnalyticEvent(clevertapEvents.webReceiptsListDetailRefund, { Page: getPageName(location.pathname) });
            }
          }
        ]
      : [
          {
            id: 'receipts-bottom-bar-share-button',
            isDisabled: !isShareEnabled(),
            icon: (
              <div className="path-fill-current path">
                <ExportIcon className="h-5" />
              </div>
            ),
            disabledReason: InfoMesssages.shareReceiptNoPermissionMessage,
            btnText: 'Share',
            onClickHandler: () => {
              handleReceiptShare();
              logAnalyticEvent(clevertapEvents.webReceiptsListDetailShare, { Page: getPageName(location.pathname) });
            }
          }
        ];

  const toggleIsMoreOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const closeMoreOption = () => {
    setIsMoreOpen(false);
  };

  const moreAnchorElem = () => (
    <div
      id="receipts-bottom-bar-more-button"
      className="flex shrink grow cursor-pointer items-center justify-center gap-2
      bg-secondary py-3.5 font-semibold text-primary hover:opacity-95">
      <div>More</div>
      <div className="path-stroke-current path">
        <CaretDownIcon className="rotate-180" />
      </div>
    </div>
  );

  const onExportAsPdf = () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    downloadReceiptAsPDF({ receiptId: receiptData?.ID, teamId: currentTeam.id });
    logAnalyticEvent(clevertapEvents.webReceiptsListDetailMoreExportPDF, { Page: getPageName(location.pathname) });
  };

  const onPrintPdf = () => {
    downloadPrintReceiptAsPDF({ receiptId: receiptData?.ID, teamId: currentTeam.id });
    logAnalyticEvent(clevertapEvents.webReceiptsListDetailMorePrint, { Page: getPageName(location.pathname) });
  };

  const handleDeleteReceipt = () => {
    deleteReceipt({ receiptId: receiptData?.ID, teamId: currentTeam.id });
  };

  const getPayoutStatus = (status: string) => {
    if (status === ReceiptStatus.SUCCESS) {
      return 'Paid';
    }
    return 'Pending';
  };

  const onInfoClick = (id, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handlePayoutClick = payoutId => {
    const type = receiptData?.paymentSystem || PaymentSystem.LIP;
    if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/payout/${payoutId}?type=${type}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/payout/${payoutId}?type=${type}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/payout/${payoutId}?type=${type}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/payout/${payoutId}?type=${type}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/payout/${payoutId}?type=${type}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleCustomerClick = () => {
    if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/customer/${receiptData.customerId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/customer/${receiptData.customerId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/customer/${receiptData.customerId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${receiptData.customerId}/customer`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/customer/${receiptData.customerId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleInvoiceClick = () => {
    if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/invoice/${receiptData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/invoice/${receiptData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/invoice/${receiptData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/invoice/${receiptData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/invoice/${receiptData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleRefundReceiptClick = () => {
    if (rootReceiptId) {
      if (receiptId) {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund/${receiptId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleDisputeClick = (disputeId: string) => {
    if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/dispute/${disputeId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/dispute/${disputeId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/dispute/${disputeId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/dispute/${disputeId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/dispute/${disputeId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleRefundReceiptShare = () => {
    if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-refund-receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
        <div className="h-full w-96">
          <If condition={fetchingReceiptData}>
            <div className="flex h-full w-96 items-center justify-center">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          </If>
          {receiptDataSuccess && !fetchingReceiptData && (
            <div className="flex h-full flex-col">
              <div className="flex items-center gap-2 py-5 px-4">
                <div onClick={handleSidePanelBack} className="cursor-pointer">
                  {isRootState ? (
                    <NavCloseIcon id="receipt-details-panel-close" />
                  ) : (
                    <ArrowRightIcon className="rotate-180" id="receipt-details-panel-back-arrow" />
                  )}
                </div>
                <div id="reciept-details-panel-head" className="text-17px font-semibold text-headingGray">
                  Receipt - {receiptData?.customerName ? receiptData?.customerName : receiptData?.ID}
                </div>
              </div>
              {hasPermissionsToView ? (
                <>
                  <div className="customNormalScroll flex grow flex-col gap-5 overflow-y-auto px-4">
                    <div className="rounded-md border border-dark-gray bg-primary">
                      <div
                        className="flex flex-col items-center justify-center
                        gap-2 border-b border-secondaryBtn pb-8">
                        <div className="pt-8 text-center">
                          <div className="text-sbase font-semibold text-accentText">Total amount</div>
                          <div id="receipt-panel-total-amount" className="mt-1 text-[30px] font-bold text-tertiaryText">
                            {getAmountWithCurrency(receiptData.amount)}
                          </div>
                          <div
                            id="receipt-panel-payment-status"
                            className="mt-2 rounded-md px-6 py-1 text-px13 text-tertiaryText"
                            style={{
                              backgroundColor: getReceiptStatusBackgroundColor(receiptData.status),
                              color: getReceiptDetailStatusColor(receiptData.status)
                            }}>
                            {getReceiptStatus(receiptData.status)}
                          </div>
                        </div>
                        <If condition={Boolean(receiptData.status === ReceiptStatus.FAILED)}>
                          <div className="my-2 mx-3 rounded-md bg-secondaryBtn p-2 text-primaryText">
                            Reason for failure: {receiptData.failureMessage}
                          </div>
                        </If>
                      </div>
                      <div className="flex w-full flex-col gap-4 p-4">
                        <If condition={receiptData.status === ReceiptStatus.SUCCESS}>
                          {SuccessReceiptDetails.map(detail => (
                            <DataListItem
                              key={detail.title}
                              title={detail.title}
                              data={detail.data}
                              itemTitleStyle="shrink-0"
                              containerStyle="items-start"
                            />
                          ))}
                        </If>
                        <If condition={receiptData.status !== ReceiptStatus.SUCCESS}>
                          {ReceiptDetails.map(detail => (
                            <DataListItem key={detail.title} title={detail.title} data={detail.data} />
                          ))}
                        </If>
                      </div>
                    </div>
                    <div className="rounded-md border border-dark-gray bg-primary">
                      <div className="flex w-full flex-col gap-4 p-4">
                        <DataListItem title="Invoice Amount" data={getAmountWithCurrency(receiptData.payableAmount)} />
                        <DataListItem title="Tip" data={receiptData.tipAmount ? `$${receiptData.tipAmount}` : '-'} />
                      </div>
                      <Divider />
                      <div className="flex items-center justify-between p-4 text-sbase font-semibold leading-5">
                        <div className="text-primaryText">Total Amount Paid</div>
                        <div className="max-w-[75%] truncate text-tertiaryText">
                          {getAmountWithCurrency(receiptData.amount)}
                        </div>
                      </div>
                    </div>
                    <If
                      condition={
                        Boolean(receiptData.status === ReceiptStatus.SUCCESS) && !isEmpty(receiptData.payoutId)
                      }>
                      <div className="rounded-md border border-dark-gray bg-primary">
                        <div id="payout-details-card-click" className="flex w-full flex-col gap-4 p-4">
                          <DataListItem title="Payout Status" data={getPayoutStatus(receiptData.payoutStatus)} />
                          <DataListItem
                            title="Payout ID"
                            itemDataStyle={`text-sbase text-secondary decoration-secondary underline 
                            underline-offset-2 truncate cursor-pointer`}
                            data={receiptData.payoutId}
                            onClick={payoutId => {
                              handlePayoutClick(payoutId);
                              logAnalyticEvent(clevertapEvents.webReceiptsListDetailsGoToPayoutDetail, {
                                Page: getPageName(location.pathname)
                              });
                            }}
                          />
                        </div>
                      </div>
                    </If>
                    <If condition={Boolean(receiptData.refundTransaction?.ID)}>
                      <div
                        className="cursor-pointer rounded-md border border-dark-gray bg-primary"
                        onClick={handleRefundReceiptClick}>
                        <div id="refund-txn-details-card-click" className="flex w-full gap-4 p-4">
                          <div>
                            <RefundTransactionIcon />
                          </div>
                          <div className="flex max-w-[95%] grow flex-col space-y-[5px] text-sbase font-semibold">
                            <div className="truncate text-tertiaryText">
                              {`Refund ${receiptData.refundTransaction.ID}` || '-'}
                            </div>
                            <div className="truncate text-accentText">
                              {getAmountWithCurrency(receiptData.refundTransaction.amount) || '-'}
                            </div>
                            <div className="truncate text-accentText">{receiptData.refundTransaction.date || '-'}</div>
                          </div>
                          <div className="flex self-center">
                            <CaretDownIcon className="-rotate-90" />
                          </div>
                        </div>
                      </div>
                    </If>
                    <If condition={Boolean(receiptData.disputeTransactions)}>
                      {receiptData.disputeTransactions?.map(dispute => (
                        <div
                          className={`cursor-pointer rounded-md border border-dark-gray
                           bg-fallingBackground bg-opacity-20`}
                          onClick={() => handleDisputeClick(dispute.disputeId)}
                          key={dispute.disputeId}>
                          <div id="refund-txn-details-card-click" className="flex w-full gap-4 p-4">
                            <div>
                              <DisputesTabIcon className="opacity-60" />
                            </div>
                            <div className="flex max-w-[95%] grow flex-col space-y-[5px] text-sbase font-semibold">
                              <div className="truncate text-tertiaryText">{`Dispute ${dispute.disputeId}` || '-'}</div>
                              <div className="truncate text-accentText">{receiptData.customerName || '-'}</div>
                              <div className="truncate text-accentText">
                                {DateUtils.getDateInFormat({
                                  date: new Date(dispute.createdAt),
                                  dateFormat: 'dd MMM yyyy'
                                }) || '-'}
                              </div>
                            </div>
                            <div className="flex self-center">
                              <CaretDownIcon className="-rotate-90" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </If>
                    <If condition={Boolean(receiptData.customerId)}>
                      <div
                        className={`flex cursor-pointer items-center justify-between rounded-md
                        border border-dark-gray bg-primary p-4`}
                        onClick={() => {
                          handleCustomerClick();
                          logAnalyticEvent(clevertapEvents.webReceiptsListDetailsGoToCustomer, {
                            Page: getPageName(location.pathname)
                          });
                        }}>
                        <div
                          id="receipt-customer-info-card-click"
                          className="max-w-[95%] space-y-[5px] text-sbase font-semibold">
                          <div className="truncate text-headingGray">{receiptData.customerName || '-'}</div>
                          <div className="truncate text-accentText">{receiptData.customerPhone || '-'}</div>
                          <div className="truncate text-accentText">{receiptData.customerEmail || '-'}</div>
                        </div>
                        <div>
                          <CaretDownIcon className="-rotate-90" />
                        </div>
                      </div>
                    </If>
                    <If condition={Boolean(receiptData.status === ReceiptStatus.SUCCESS)}>
                      <div className="rounded-md border border-dark-gray bg-primary">
                        <LineItems receiptData={receiptData} borderStyle="rounded-b-md" />
                      </div>
                    </If>
                    <If condition={!isEmpty(receiptData.invoiceId)}>
                      <div
                        className={`mb-5 flex cursor-pointer items-center justify-between rounded-md
                        border border-dark-gray bg-primary p-4`}
                        onClick={() => {
                          handleInvoiceClick();
                          logAnalyticEvent(clevertapEvents.webReceiptsListDetailsGoToInvoiceDetail, {
                            Page: getPageName(location.pathname)
                          });
                        }}>
                        <div className="flex">
                          <ReceiptInvoiceIcon />
                          <div className="pl-4 text-sbase font-semibold">
                            <div className="pb-0.5 text-tertiaryText">{`Invoice ${receiptData.invoiceNumber}`}</div>
                            <div className="pb-0.5 text-accentText line-clamp-1">{receiptData.customerName}</div>
                            <div className="text-accentText">{receiptData.date}</div>
                          </div>
                        </div>
                        <div>
                          <CaretDownIcon className="-rotate-90" />
                        </div>
                      </div>
                    </If>
                  </div>
                  <If
                    condition={Boolean(
                      receiptData.status === ReceiptStatus.SUCCESS || receiptData.status === ReceiptStatus.PENDING
                    )}>
                    <div className="mt-auto flex justify-between gap-px justify-self-end">
                      {bottomBar.map(item => (
                        <div
                          id={`receipt-side-panel-${item.btnText}-button`}
                          onClick={() => {
                            if (item.isDisabled) {
                              onInfoClick(item.id, item.disabledReason);
                            } else {
                              item.onClickHandler();
                            }
                          }}
                          key={item.id + item.btnText}
                          className={twMerge(
                            classNames(
                              `text-primary bg-secondary flex gap-2 py-3.5 grow
                       justify-center items-center font-semibold cursor-pointer hover:opacity-95`,
                              item.isDisabled ? 'bg-[#9BBADD] hover:opacity-100' : 'shrink items-center'
                            )
                          )}>
                          <div className={`${item.isDisabled && 'opacity-50'}`}>{item.icon}</div>
                          <div className={`text-sbase ${item.isDisabled && 'opacity-50'}`}>{item.btnText}</div>
                          {item.isDisabled && <WhiteInfoIcon />}
                        </div>
                      ))}
                      <div
                        className="flex shrink grow cursor-pointer items-center justify-center
                           gap-2 bg-secondary hover:opacity-95"
                        onClick={toggleIsMoreOpen}>
                        <CustomPopover
                          show={isMoreOpen}
                          anchorComponent={moreAnchorElem}
                          onTogglePopover={toggleIsMoreOpen}
                          onClosePopover={closeMoreOption}
                          offset="-right-3 bottom-14"
                          minWidth="min-w-[160px]"
                          showArrow={true}
                          arrowType={PopoverArrowTypes.BOTTOM}
                          highlightAnchor={false}>
                          {
                            <MoreMenu
                              onPrintPdf={onPrintPdf}
                              onExportPdf={onExportAsPdf}
                              onDelete={handleDeleteReceipt}
                              showDelete={
                                receiptData.status === ReceiptStatus.SUCCESS &&
                                (isCreatedBySelf() || isOwnerOrAdmin()) &&
                                receiptData?.isManualPayment
                              }
                            />
                          }
                        </CustomPopover>
                      </div>
                    </div>
                  </If>
                  <If
                    condition={Boolean(
                      receiptData.status === ReceiptStatus.REFUND_INITIATED ||
                        receiptData.status === ReceiptStatus.REFUNDED
                    )}>
                    <div className="mt-auto flex justify-between gap-px justify-self-end">
                      <div
                        id="refund-receipt-side-panel-share-receipt-button"
                        onClick={handleRefundReceiptShare}
                        className="flex shrink grow cursor-pointer items-center justify-center
                       gap-2 bg-secondary py-3.5 font-semibold text-primary hover:opacity-95">
                        <div className="path-fill-current path">
                          <ExportIcon className="h-5" />
                        </div>
                        <div className="text-sbase">Share Receipt</div>
                      </div>
                    </div>
                  </If>
                </>
              ) : (
                <NoPermissions />
              )}
            </div>
          )}
          {receiptDataError && <GenericError handleErrorBack={handleSidePanelBack} />}
        </div>
      </SidePanel>
    </>
  );
};

export default ReceiptDetailsPanel;
