import { FC } from 'react';
import { ReactIconType } from 'types/baseTypes';

const FormCloseReactIcon: FC<ReactIconType> = props => (
  <svg
    onClick={props.onClick}
    id={props.id}
    className={props.className}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M9 0.75C4.425 0.75 0.75 4.425 0.75 9C0.75 13.575 4.425 17.25 9 17.25C13.575 17.25 17.25 13.575 17.25 9C17.25 4.425 13.575 0.75 9 0.75ZM9 15.75C5.25 15.75 2.25 12.75 2.25 9C2.25 5.25 5.25 2.25 9 2.25C12.75 2.25 15.75 5.25 15.75 9C15.75 12.75 12.75 15.75 9 15.75ZM10.05 9L11.775 7.275C12.075 6.975 12.075 6.525 11.775 6.225C11.475 5.925 11.025 5.925 10.725 6.225L9 7.95L7.275 6.225C6.975 5.925 6.525 5.925 6.225 6.225C5.925 6.525 5.925 6.975 6.225 7.275L7.95 9L6.225 10.725C5.925 11.025 5.925 11.475 6.225 11.775C6.375 11.925 6.525 12 6.75 12C6.975 12 7.125 11.925 7.275 11.775L9 10.05L10.725 11.775C10.875 11.925 11.025 12 11.25 12C11.475 12 11.625 11.925 11.775 11.775C12.075 11.475 12.075 11.025 11.775 10.725L10.05 9Z"
      fill="#828282"
    />
    <mask
      id={`form-close-icon-mask-${props.id}`}
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="18"
      height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M9 0.75C4.425 0.75 0.75 4.425 0.75 9C0.75 13.575 4.425 17.25 9 17.25C13.575 17.25 17.25 13.575 17.25 9C17.25 4.425 13.575 0.75 9 0.75ZM9 15.75C5.25 15.75 2.25 12.75 2.25 9C2.25 5.25 5.25 2.25 9 2.25C12.75 2.25 15.75 5.25 15.75 9C15.75 12.75 12.75 15.75 9 15.75ZM10.05 9L11.775 7.275C12.075 6.975 12.075 6.525 11.775 6.225C11.475 5.925 11.025 5.925 10.725 6.225L9 7.95L7.275 6.225C6.975 5.925 6.525 5.925 6.225 6.225C5.925 6.525 5.925 6.975 6.225 7.275L7.95 9L6.225 10.725C5.925 11.025 5.925 11.475 6.225 11.775C6.375 11.925 6.525 12 6.75 12C6.975 12 7.125 11.925 7.275 11.775L9 10.05L10.725 11.775C10.875 11.925 11.025 12 11.25 12C11.475 12 11.625 11.925 11.775 11.775C12.075 11.475 12.075 11.025 11.775 10.725L10.05 9Z"
        fill="white"
      />
    </mask>
    <g mask={`url(#form-close-icon-mask-${props.id})`}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H18V18H0V0Z" fill="#828282" />
    </g>
  </svg>
);

export default FormCloseReactIcon;
