import { Popover, Transition } from '@headlessui/react';
import { FC, Fragment, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { twMerge } from 'tailwind-merge';
import { SearchItemType, VoidFn } from 'types/baseTypes';
import SearchBar from '../search/SearchBar';
import { DownArrowIcon } from 'assets/icons';

import SearchSuggestionsPanel from '../search/SearchSuggestionsPanel';

interface SearchWithDropdownProps {
  suggestionsList?: string[];
  searchInputvalue: string;
  onSearchInputChange: (text: string) => void;
  onClearSearchInput: VoidFn;
  wrapperStyle?: string;
  autoFocus?: boolean;
  searchId: string;
  menuItems: SearchItemType[];
  selectedOption: SearchItemType;
  setSelectedOption: (SearchItemType) => void;
  showSuggestionList?: boolean;
  dropDownStyle?: string;
}

// TODO change to combobox
const SearchWithDropdown: FC<SearchWithDropdownProps> = ({
  searchId,
  searchInputvalue,
  suggestionsList,
  onSearchInputChange,
  onClearSearchInput,
  wrapperStyle,
  autoFocus = true,
  menuItems,
  selectedOption,
  setSelectedOption,
  showSuggestionList = false,
  dropDownStyle
}) => {
  const [isSuggestionsListVisible, setIsSuggestionsListVisible] = useState(false);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);

  const getFilteredSuggestionList = () => {
    const suggestions = suggestionsList?.filter((suggestion: string) => {
      return suggestion.toLowerCase().includes(searchInputvalue.toLowerCase());
    });
    return suggestions;
  };

  useEffect(() => {
    const filteredList = getFilteredSuggestionList();
    if (filteredList?.length === 0) {
      setIsSuggestionsListVisible(false);
    }
    setFilteredSuggestions(filteredList);
  }, [searchInputvalue, suggestionsList]);

  const handleSearchInputChange = (text: string) => {
    if (!isSuggestionsListVisible && showSuggestionList) {
      setIsSuggestionsListVisible(true);
    }
    onSearchInputChange(text);
  };

  const onSelectSuggestedItem = text => {
    handleSearchInputChange(text);
    setIsSuggestionsListVisible(false);
  };

  const onOutSideClick = () => {
    setIsSuggestionsListVisible(false);
    setDropdownOpen(false);
  };

  const onClearSearch = () => {
    setIsSuggestionsListVisible(false);
    onClearSearchInput();
  };

  const [dropdownOpen, setDropdownOpen] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={onOutSideClick}>
      <div className={twMerge('w-full', wrapperStyle)}>
        <Popover className="relative">
          {() => (
            <>
              <div className="flex flex-row">
                <div
                  className={`h-[38px] w-[122px] rounded-l-lg bg-secondaryBtn ${dropDownStyle}`}
                  onClick={() => setDropdownOpen(!dropdownOpen)}>
                  <div id="selected-search-option" className="flex items-center justify-between py-2.5 px-3">
                    <div className="text-sbase font-semibold text-primaryText">{selectedOption.text}</div>
                    <div>
                      {' '}
                      <DownArrowIcon className={dropdownOpen ? 'rotate-180' : ''} />
                    </div>
                  </div>
                  {dropdownOpen && (
                    <div className="relative z-50 h-fit rounded bg-white p-3 shadow-xl">
                      {menuItems.map(menu => (
                        <div
                          id={menu.id}
                          onClick={() => setSelectedOption(menu)}
                          className="py-1.5 text-sbase font-semibold text-primaryText"
                          key={menu.id}>
                          <div>{menu.text}</div>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <SearchBar
                  alignRight={true}
                  id={searchId}
                  autoFocus={autoFocus}
                  value={searchInputvalue}
                  handleClear={onClearSearch}
                  placeholder={selectedOption.searchText}
                  showSearchIcon={true}
                  handleChange={handleSearchInputChange}
                  wrapperStyle="w-[275px] h-[38px] border border-[#ACB5BD] rounded-r-lg"
                  showCrossIcon={true}
                  inputWrapperStyle="gap-0"
                  inputStyle="placeholder:text-gray-1"
                />
                <Transition
                  show={isSuggestionsListVisible}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1">
                  <Popover.Panel
                    className="customNormalScroll absolute top-10 left-[122px] z-40 max-h-36 w-[276px]
                 overflow-y-auto rounded-md border border-borderGray bg-primary shadow-md">
                    <SearchSuggestionsPanel
                      onSelectListItem={onSelectSuggestedItem}
                      suggestionsList={filteredSuggestions}
                    />
                  </Popover.Panel>
                </Transition>
              </div>
            </>
          )}
        </Popover>
      </div>
    </OutsideClickHandler>
  );
};

export default SearchWithDropdown;
