import { useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';

const useCountDownInterval = ({
  countDownCompletedCallback,
  countDownTime
}: {
  countDownCompletedCallback: VoidFn;
  countDownTime: number;
}) => {
  const [seconds, setSeconds] = useState(countDownTime);

  const resetTimer = () => {
    setSeconds(countDownTime);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 1) {
        setSeconds(seconds - 1);
      }

      if (seconds === 1) {
        countDownCompletedCallback();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  return { seconds, resetTimer };
};

export default useCountDownInterval;
