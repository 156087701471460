import React, { FC, Fragment, useState } from 'react';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { ArrowRightIcon, BulletIcon, CaretDownIcon, FetchingErrorIcon, LineGraphIcon, ReloadIcon } from 'assets/icons';
import { If } from 'components';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { DailyBreakDownSalesResponse } from 'containers/home/home.model';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip
} from 'chart.js';
import { Popover, Transition } from '@headlessui/react';

import DateUtils from 'utils/dateUtils';
import { Line } from 'react-chartjs-2';
import { subMonths } from 'date-fns';
import { pluckIntValuesFromArrayWithSpecificKey } from 'utils/formatters';
import { formatAmount, roundToTwoDecimals } from 'utils/amountUtils';
import { useNavigate } from 'react-router-dom';

import OutsideClickHandler from 'react-outside-click-handler';
import { twMerge } from 'tailwind-merge';
import { SaleTypes } from 'containers/home/types';
import annotationPlugin from 'chartjs-plugin-annotation';
import { useAppDispatch, useAppSelector } from 'hooks/typedHooks';
import { selectCurrentTeamCustomersPermissions, selectCurrentTeamInvoicePermissions } from 'containers/app/appSlice';
import { logAnalyticEvent } from 'utils/analytics';
import classnames from 'classnames';
import PermissionError from './PermissionError';
import { resetInvoiceState } from 'containers/invoices/InvoicesSlice';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, annotationPlugin);

interface SalesProps {
  data: DailyBreakDownSalesResponse | null;
  isSuccess: boolean;
  handleReload?: VoidFn;
  isFetching?: boolean;
  selectedSalesTypeDailyBreakdown: string;
  setSelectedSalesTypeDailyBreakdown: (value: SaleTypes) => void;
  isPermissionError: boolean;
}

const DailyBreakdownChart: FC<SalesProps> = props => {
  const {
    data,
    isSuccess,
    handleReload,
    isFetching,
    setSelectedSalesTypeDailyBreakdown,
    selectedSalesTypeDailyBreakdown,
    isPermissionError
  } = props;

  const dispatch = useAppDispatch();
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);

  const dateToday = parseInt(DateUtils.getDateInFormat({ date: new Date(), dateFormat: 'dd' }));

  const annotationLineOnPointHover = {
    id: 'annotationLine',
    beforeDraw: chart => {
      if (chart?.tooltip?._active && chart?.tooltip?._active?.length) {
        const activePoints = chart.tooltip._active;
        showAnnotationsOnHover(activePoints, chart);
      }
    }
  };

  const xAxisValuesForLabel = [];
  for (let i = 1; i <= 31; i++) {
    if (i % 5 === 0) {
      xAxisValuesForLabel.push(i);
    } else if (i === new Date().getDate()) {
      xAxisValuesForLabel.push(i);
    } else {
      xAxisValuesForLabel.push('');
    }
  }

  const showAnnotationsOnHover = (activeElements, chart) => {
    if (activeElements[0]?.index < dateToday) {
      setSelectedIndex(activeElements[0]?.index);
      const activePoint = activeElements[0];
      const context = chart.ctx;
      const x = activePoint?.element?.x;
      let topY = activePoint?.element?.y;
      if (activeElements?.length > 1) {
        if (activeElements[1].element.$context.raw > activePoint.element.$context.raw) {
          topY = activeElements[1]?.element?.y;
        }
      }
      const bottomY = chart.chartArea.bottom;
      context.save();
      context.beginPath();
      context.moveTo(x, topY);
      context.lineTo(x, bottomY);
      context.lineWidth = 1;
      context.strokeStyle = '#66B0DD';
      context.stroke();
      context.restore();
    } else {
      resetSelectedIndex();
    }
  };
  const xAxisValuesForAnnotation = data?.currentMonth.filter(item => item.date % 5 === 0 || item.date === dateToday);

  const annotationsFromXAxisValues = xAxisValuesForAnnotation?.map(label => ({
    type: 'line' as const,
    borderColor: '#ACB5BD',
    borderWidth: 1,
    borderDash: [2, 2],
    yMin: 0,
    yMax: label.sales,
    xMin: label.date - 1,
    xMax: label.date - 1,
    value: label.date - 1
  }));

  const options = {
    responsive: true,
    hover: {
      mode: 'index' as const,
      intersect: false
    },
    interaction: {
      mode: 'index' as const,
      intersect: false
    },
    stacked: false,
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false // <-- this option disables tooltips
      },
      annotation: {
        annotations: {
          ...annotationsFromXAxisValues
        }
      }
    },
    maintainAspectRatio: false,
    scales: {
      y: {
        type: 'linear' as const,
        display: false,
        position: 'left' as const,
        grid: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        },
        ticks: {
          autoSkip: true,
          maxRotation: 0,
          minRotation: 0
        }
      }
    },
    elements: {
      point: {
        radius: 0
      },
      line: {
        tension: 0.4,
        borderJoinStyle: 'round' as const,
        borderCapStyle: 'round' as const
      }
    },
    onHover: (event, activeElements, chart) => {
      // to find the area under the line graph
      // const yVal = chart.scales.y.getValueForPixel(event.y);
      // const index = chart.scales.x.getValueForPixel(event.x);
      // const maxFilledArea = chart.data.datasets.reduce((acc, curr) => acc + curr.data[index], 0);

      if (activeElements.length && activeElements[0]?.index !== undefined) {
        showAnnotationsOnHover(activeElements, chart);
      } else {
        setSelectedIndex(null);
      }
    }
  };

  const lineData = {
    labels: xAxisValuesForLabel,
    datasets: [
      {
        label: DateUtils.getMonthInStringFormat(data?.currentMonth[0].month) || 'Month',
        data: pluckIntValuesFromArrayWithSpecificKey(data?.currentMonth || [], 'sales'),
        borderColor: '#3876BB',
        backgroundColor: '#3876BB',
        yAxisID: 'y',
        pointHoverBorderColor: 'rgba(164,215,250, 0.85)',
        pointHoverBorderWidth: 3,
        pointHoverRadius: 5,
        borderWidth: 2
      },
      {
        label: DateUtils.getMonthInStringFormat(data?.previousMonth[0].month) || 'Month',
        data: pluckIntValuesFromArrayWithSpecificKey(data?.previousMonth || [], 'sales'),
        borderColor: '#BDBDBD',
        backgroundColor: '#BDBDBD',
        yAxisID: 'y',
        borderWidth: 1,
        pointHitRadius: 1
      }
    ]
  };

  const percentageBackGroundColor = (percentage: string | null) => {
    if (percentage === null || percentage === '-') {
      return 'success';
    }
    const numberPercentage = parseInt(percentage);
    if (numberPercentage === 0) {
      return 'secondaryBtn';
    } else if (numberPercentage > 0) {
      return 'success';
    }
    return 'fallingBackground';
  };

  const getPercentageTextColor = (percentage: string | null) => {
    if (percentage === null || percentage === '-') {
      return 'success';
    }
    const numberPercentage = parseInt(percentage);
    if (numberPercentage >= 0) {
      return 'text-successText';
    }
    return 'text-[#D95945]';
  };

  const percentageFormatter = (percentage: string | null) => {
    if (percentage === null || percentage === '-') {
      return '+0.0%';
    }
    const numberPercentage = roundToTwoDecimals(percentage);

    if (numberPercentage === 0) {
      return '+0.0%';
    } else if (numberPercentage > 0) {
      return `+${numberPercentage}%`;
    }
    return `${numberPercentage}%`;
  };

  const getPastMonthSalesNumber = () => {
    if (selectedIndex !== null) return `$${formatAmount(data?.previousMonth[selectedIndex].sales)}` || '-';
    return `$${formatAmount(data?.previousMonthTotalSales)}`;
  };

  const getCurrentMonthSalesNumber = () => {
    if (selectedIndex !== null) {
      if (data?.currentMonth.length - 1 >= selectedIndex) {
        return `$${formatAmount(data?.currentMonth[selectedIndex].sales)}` || '-';
      }
      return '-';
    }
    return `$${formatAmount(data?.currentMonthTotalSales)}`;
  };

  const pastMonthString = () => {
    return DateUtils.getDateInFormat({
      date: subMonths(new Date(), 1),
      dateFormat: 'MMM yyyy'
    });
  };

  const getPastMonthDate = () => {
    if (selectedIndex !== null) {
      if (data?.previousMonth.length - 1 >= selectedIndex) {
        return data?.previousMonth[selectedIndex].dateString || '-';
      }
      return '-';
    }
    return pastMonthString();
  };

  const currentMonthString = () => {
    return DateUtils.getDateInFormat({
      date: new Date(),
      dateFormat: 'MMM yyyy'
    });
  };

  const currentMonthDate = () => {
    if (selectedIndex !== null) {
      if (data?.currentMonth.length - 1 >= selectedIndex) {
        return data?.currentMonth[selectedIndex].dateString || '-';
      }
      return '-';
    }
    return currentMonthString();
  };

  const getPastMonthPercentage = () => {
    if (selectedIndex !== null) {
      if (data?.previousMonth.length - 1 >= selectedIndex) {
        return data?.previousMonth[selectedIndex]?.percentageChange.toString() || '-';
      }
      return '-';
    }
    return data?.percentageChange.toString();
  };

  const isDataEmpty = () => {
    let isEmpty = true;
    data?.currentMonth.map(item => {
      if (parseInt(item.sales) !== 0) {
        isEmpty = false;
      }
    });

    data?.previousMonth.map(item => {
      if (parseInt(item.sales) !== 0) {
        isEmpty = false;
      }
    });
    return isEmpty;
  };

  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const onOutSideClick = () => {
    setIsDropdownOpen(false);
  };

  const showPrecentageChange =
    (selectedIndex === null && +data?.previousMonthTotalSales > 0) ||
    (selectedIndex !== null && +data?.previousMonth[selectedIndex].sales > 0);

  const handleCreateInvoiceClick = () => {
    dispatch(resetInvoiceState());
    navigate('/invoices/create');
    logAnalyticEvent(clevertapEvents.webAllSalesThisMonthCreateInvoice);
  };

  const resetSelectedIndex = () => {
    setSelectedIndex(null);
  };

  return (
    <div
      onMouseEnter={() => logAnalyticEvent(clevertapEvents.webAllSalesThisMonth)}
      onMouseLeave={resetSelectedIndex}
      className="flex h-full w-full flex-col rounded border border-borderGray">
      <div className="flex w-full shrink-0 flex-col px-5 py-2.5">
        <div className={'flex flex-row items-center justify-between'}>
          <div className="mb-3 flex items-center">
            <OutsideClickHandler onOutsideClick={onOutSideClick}>
              <div className={twMerge('w-full')}>
                <Popover className="relative">
                  {() => (
                    <>
                      <div
                        className="flex cursor-pointer rounded border border-borderGray px-1"
                        onClick={() => setIsDropdownOpen(true)}>
                        <div className="rounded	 text-xl font-bold text-primaryText">
                          {selectedSalesTypeDailyBreakdown}
                        </div>
                        <div className="flex items-center justify-center px-2.5 pr-0">
                          <CaretDownIcon />
                        </div>
                      </div>
                      <Transition
                        show={isDropdownOpen}
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="opacity-0 translate-y-1"
                        enterTo="opacity-100 translate-y-0"
                        leave="transition ease-in duration-150"
                        leaveFrom="opacity-100 translate-y-0"
                        leaveTo="opacity-0 translate-y-1">
                        <Popover.Panel
                          className="customNormalScroll absolute
                           z-40 w-full overflow-y-auto rounded-md
                           border border-borderGray bg-primary shadow-md">
                          <div className={'pb-1'}>
                            <div
                              id="all-sales-graph-filter-this-month"
                              className={'cursor-pointer pl-2 pt-3 text-base font-semibold leading-5 text-primaryText'}
                              onClick={() => {
                                setSelectedSalesTypeDailyBreakdown(SaleTypes.ALL_SALES);
                                setIsDropdownOpen(false);
                              }}>
                              {SaleTypes.ALL_SALES}
                            </div>
                            <div
                              id="digital-sales-graph-filter-this-month"
                              className={
                                'cursor-pointer pl-2 pt-3 pb-2 text-base font-semibold leading-5 text-primaryText'
                              }
                              onClick={() => {
                                setSelectedSalesTypeDailyBreakdown(SaleTypes.DIGITAL_SALES);
                                setIsDropdownOpen(false);
                              }}>
                              {SaleTypes.DIGITAL_SALES}
                            </div>
                          </div>
                        </Popover.Panel>
                      </Transition>
                    </>
                  )}
                </Popover>
              </div>
            </OutsideClickHandler>
            <div className="ml-2 mt-1 text-xl font-bold text-primaryText">this month</div>
          </div>
          <If condition={Boolean(data) && isDataEmpty()}>
            <div className={'mt-2 flex flex-col'}>
              <div className={'flex flex-row items-center'}>
                <BulletIcon />
                <div className={'ml-2 text-sm text-accentText'}>{`${currentMonthString()}- $0.00`}</div>
              </div>

              <div className={'mt-1 flex flex-row items-center'}>
                <BulletIcon />
                <div className={'ml-2 text-sm text-accentText'}>{`${pastMonthString()}- $0.00`}</div>
              </div>
            </div>
          </If>
        </div>
        <If condition={Boolean(data) && !isDataEmpty() && isSuccess}>
          <div className="align-center mt-2.5 flex flex-row items-center">
            <div className="flex flex-col">
              <div id="daily-breakdown-card-amount" className="flex flex-row items-center">
                <div id="daily-breakdown-amount" className="text-xl font-bold text-tertiaryText">
                  {`${getCurrentMonthSalesNumber()}`}
                </div>
                <If condition={showPrecentageChange}>
                  <div className={`bg-${percentageBackGroundColor(getPastMonthPercentage())} ml-2 rounded px-1 py-0.5`}>
                    <div
                      className={`text-px13 ${getPercentageTextColor(getPastMonthPercentage())}
                     font-semibold`}>{`${percentageFormatter(getPastMonthPercentage())}`}</div>
                  </div>
                </If>
              </div>
              <div className="primaryText mt-1 text-px13 font-normal text-primaryText">
                <span>{currentMonthDate()}</span>
              </div>
            </div>
            <div className="mx-2.5 h-10 w-px bg-borderGray" />
            <div>
              <div className="text-sbase font-semibold text-accentText">{`${getPastMonthSalesNumber()}`}</div>
              <div className="mt-1 text-px13 font-normal text-accentText">{getPastMonthDate()}</div>
            </div>
          </div>
        </If>
      </div>
      <div className="w-full p-5" onMouseLeave={resetSelectedIndex}>
        <div
          id="this-month-line-graph-click"
          className={
            'customNormalScroll box-border flex max-h-60 w-full flex-col gap-2 overflow-y-auto overflow-x-hidden'
          }
          onMouseLeave={resetSelectedIndex}>
          <If condition={isSuccess && !isFetching && Boolean(data) && !isDataEmpty()}>
            <Line options={options} data={lineData} plugins={[annotationLineOnPointHover]} />
          </If>
          <If condition={isSuccess && !isFetching && Boolean(data) && isDataEmpty()}>
            <div className="rotateChild flex flex-col items-center justify-center gap-2 p-16">
              <LineGraphIcon />
              <div className="text-sbase font-semibold text-accentText">No sales in last 2 months</div>
              <div
                className={twMerge(
                  'flex cursor-pointer items-center gap-2',
                  classnames({
                    'pointer-events-none opacity-50':
                      !invoicePermissions.createInvoice || !customerPermissions.createNewCustomers
                  })
                )}
                onClick={handleCreateInvoiceClick}>
                <div id="create-new-invoice-this-month-graph" className="text-px13 font-semibold text-secondary">
                  Create new invoice
                </div>
                <ArrowRightIcon />
              </div>
            </div>
          </If>
          <If condition={!isSuccess && !isFetching && !isPermissionError}>
            <div className="rotateChild flex flex-col items-center justify-center gap-2 p-16">
              <FetchingErrorIcon />
              <div className="text-sbase text-accentText">Something went wrong!</div>
              <div className="flex items-center gap-2">
                <div className="cursor-pointer" onClick={handleReload}>
                  <ReloadIcon />
                </div>
                <div className="text-px13 text-accentText">Please try refreshing</div>
              </div>
            </div>
          </If>
          <If condition={!isSuccess && !isFetching && isPermissionError}>
            <div className="p-14">
              <PermissionError />
            </div>
          </If>
          <If condition={isFetching}>
            <div className="m-auto flex h-60 items-center justify-center">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          </If>
        </div>
      </div>
    </div>
  );
};

export default DailyBreakdownChart;
