import React, { FC } from 'react';
import { CircularStepper, CustomButton, CustomInput } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getCurrentData,
  getUserData,
  resetDigitalOnboardingState,
  setUserData,
  updateCurrentData
} from '../digitalOnboardingSlice';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { useUpdateBasicDetailsMutation } from '../api';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { loggedOut, selectAuthState } from 'containers/auth/authSlice';
import { constructBasicDetails } from '../utils';

const MerchantNameSection: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const currentData = useAppSelector(getCurrentData);
  const { firstName, middleName, lastName } = currentData ?? {};
  const authState = useAppSelector(selectAuthState);
  const { hasInitiatedSignIn, hasInitiatedSignUp } = authState ?? {};
  const [updateBasicDetailsMutation] = useUpdateBasicDetailsMutation();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const onFirstNameChange = (value: string) => {
    dispatch(updateCurrentData({ key: 'firstName', value }));
  };

  const onMiddleNameChange = (value: string) => {
    dispatch(updateCurrentData({ key: 'middleName', value }));
  };

  const onLastNameChange = (value: string) => {
    dispatch(updateCurrentData({ key: 'lastName', value }));
  };

  const onContinue = () => {
    if (firstName?.trim() && lastName?.trim()) {
      const updatedData = {
        ...userData,
        firstName: firstName.trim(),
        middleName: middleName?.trim(),
        lastName: lastName?.trim()
      };
      dispatch(setUserData(updatedData));
      updateBasicDetailsMutation({
        payload: constructBasicDetails({
          userData: updatedData,
          teamId: currentTeam.id.toString()
        })
      });
      navigate(routesPath.DIGITAL_ONBOARDING_EMAIL, { replace: true });
    }
  };

  const onBack = () => {
    dispatch(loggedOut());
    dispatch(resetDigitalOnboardingState());
    if (hasInitiatedSignUp) {
      navigate(routesPath.SIGN_UP, { replace: true });
    } else if (hasInitiatedSignIn) {
      navigate(routesPath.SIGNIN, { replace: true });
    }
  };

  return (
    <div className="flex flex-col self-center lg:min-w-[375px]">
      <CircularStepper currentStep={1} totalSteps={7} />
      <div className="mb-[30px] flex flex-col gap-2.5">
        <div className="text-4xl font-bold leading-[43px] text-headingGray">What's your name?</div>
        <div className="text-17px text-primaryText">Let's start with some basic info</div>
      </div>
      <div className="flex flex-col gap-5">
        <CustomInput
          id="first-name"
          onChange={onFirstNameChange}
          value={firstName}
          label="First Name"
          isMandatory
          placeholder="Enter first name"
          className="px-5 py-4 outline-none placeholder:font-semibold placeholder:tracking-[-0.3px]"
        />
        <CustomInput
          id="middle-name"
          onChange={onMiddleNameChange}
          value={middleName}
          label="Middle Name"
          placeholder="Enter middle name"
          className="px-5 py-4 outline-none placeholder:font-semibold placeholder:tracking-[-0.3px]"
        />
        <CustomInput
          id="last-name"
          onChange={onLastNameChange}
          value={lastName}
          label="Last Name"
          isMandatory
          placeholder="Enter last name"
          className="px-5 py-4 outline-none placeholder:font-semibold placeholder:tracking-[-0.3px]"
        />
      </div>
      <div className="mt-10 flex flex-col gap-5">
        <CustomButton
          id="continue-sign-up"
          className="h-10 w-full"
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onContinue}
          disabled={!firstName?.trim() || !lastName.trim()}>
          Continue
        </CustomButton>
        <CustomButton
          id="back-button-sign-up"
          className="h-10 w-full border-none shadow-none hover:bg-primary active:shadow-none"
          type={ButtonType.SECONDARY}
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onBack}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default MerchantNameSection;
