import { useCallback, useEffect, useRef, useState } from 'react';
import { ICategory } from '../categories.model';
import { useLazyGetCategoriesQuery } from '../api';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks/typedHooks';
import { initialQueryParamsState } from 'containers/payouts/hooks/usePaginatedGetPayoutsQuery';

export interface CategoriesQueryParams {
  search: string;
}

export const initialCategoryQueryParamsState: CategoriesQueryParams = {
  search: null
};

export const usePaginatedGetCategoriesQuery = () => {
  const [list, setList] = useState<ICategory[]>([]);
  const [isMounted, setIsMounted] = useState(false);
  const [queryParams, setQueryParams] = useState<CategoriesQueryParams>(initialCategoryQueryParamsState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const page = useRef(0);
  const [getCategoriesQuery, listQueryState] = useLazyGetCategoriesQuery();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!listQueryState?.isFetching && listQueryState.isSuccess) {
      const totalCount = listQueryState?.data?.meta?.totalCount;
      const listItems = listQueryState?.data?.data ?? [];
      const updatedList = isInitListCall ? listItems : [...list, ...listItems];
      const listItemsLeftToFetch = totalCount ? +totalCount - updatedList.length : 0;

      setHasMore(listItemsLeftToFetch > 0);
      setList(updatedList);
      if (page.current === 1) {
        setTotalListCount(totalCount ?? 0);
      }
      setIsLoading(false);
    }
  }, [listQueryState?.isFetching]);

  useEffect(() => {
    if (!listQueryState?.isFetching && listQueryState?.isError) {
      setIsError(true);
      setIsLoading(false);
      setHasMore(false);
    }
  }, [listQueryState?.isError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setList([]);
    loadMore();
  };

  useEffect(() => {
    if (isMounted) {
      resetListAndLoadMore();
    } else {
      setIsMounted(true);
    }
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    if (currentTeam?.id) {
      const remainingParams = queryParams;

      try {
        getCategoriesQuery({
          teamId: currentTeam?.id,
          limit: 10,
          page: page.current,
          ...remainingParams
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetRecallQuery = () => {
    resetListAndLoadMore();
  };

  const resetQuery = () => {
    page.current = 0;
    setList([]);
    setQueryParams(initialQueryParamsState);
    setIsMounted(false);
    setIsError(false);
    setHasMore(true);
    setTotalListCount(0);
  };

  return {
    isLoading: isLoading,
    isFetching: listQueryState?.isFetching,
    totalListCount,
    list,
    isError,
    loadMore,
    resetRecallQuery,
    resetQuery,
    hasMore,
    setQueryParams,
    queryParams,
    isInitListCall,
    isLoadingSuccess: listQueryState.isSuccess,
    apiErrorCode: listQueryState?.error?.code
  };
};
