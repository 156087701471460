import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { PaymentMode, TNC } from './discountTipsFees.model';
import { FeatureToggleState, defaultTipChoices } from './constants';
import { WebsiteConfig } from 'containers/receipts/receipt.model';

export interface CustomizationsSurchargeState {
  enabled: boolean;
  basePoints: string;
  percentage: string;
}

export interface Discount {
  enabled: boolean;
}

export interface Tip {
  enabled: boolean;
  percentage: string;
}
export interface surcharge {
  enabled: boolean;
  percentage: string;
  basePoints: string;
  config: FeatureToggleState;
}
export interface Customizations {
  discount: Discount;
  tip: Tip;
  surcharge: surcharge;
  tnc: TNC;
  teamTips: number[];
  customerSignatureConfig: string;
  isAutoPayoutEnabled: boolean;
  maximumAmount: string;
  minimumAmount: string;
  websiteConfig: WebsiteConfig;
  manualKeyInTransactionLimit: string;
  paymentModes: PaymentMode[];
}

const initialState = {
  customizations: {
    discount: {
      enabled: false
    },
    tip: {
      enabled: false,
      percentage: ''
    },
    surcharge: {
      enabled: false,
      percentage: '',
      basePoints: '',
      config: null
    },
    applyToPendingInvoices: false,
    tnc: {
      tncId: 0,
      enabled: false,
      tnc: ''
    },
    teamTips: defaultTipChoices,
    customerSignatureConfig: null,
    isAutoPayoutEnabled: false,
    maximumAmount: '',
    minimumAmount: '',
    serviceFeeConfig: null,
    manualKeyInTransactionLimit: '',
    paymentModes: [],
    websiteConfig: null
  }
};

export const discountTipsSlice = createSlice({
  name: 'disountTips',
  initialState,
  reducers: {
    updateCustomizations: (state, { payload }: PayloadAction<Customizations>) => {
      state.customizations.discount = payload.discount;
      state.customizations.tip = payload.tip;
      state.customizations.surcharge = payload.surcharge;
      state.customizations.tnc = payload.tnc;
      state.customizations.teamTips = payload.teamTips;
      state.customizations.customerSignatureConfig = payload.customerSignatureConfig;
      state.customizations.isAutoPayoutEnabled = payload.isAutoPayoutEnabled;
      state.customizations.maximumAmount = payload.maximumAmount;
      state.customizations.minimumAmount = payload.minimumAmount;
      state.customizations.manualKeyInTransactionLimit = payload.manualKeyInTransactionLimit;
      state.customizations.paymentModes = payload.paymentModes;
      state.customizations.websiteConfig = payload.websiteConfig;
    },
    updateSurcharge: (state, { payload }: PayloadAction<surcharge>) => {
      state.customizations.surcharge = payload;
    },
    updateSignatureRequestOption: (state, { payload }: PayloadAction<string>) => {
      state.customizations.customerSignatureConfig = payload;
    }
  }
});

export const { updateCustomizations, updateSurcharge, updateSignatureRequestOption } = discountTipsSlice.actions;

export const selectCustomizations = (state: RootState) => state.rootReducer.discountTips.customizations;

export default discountTipsSlice.reducer;
