import classnames from 'classnames';
import { accountSettingsNavTabs } from 'constants/navConstants';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { NavTab } from 'types/baseTypes';
import { findActiveTab } from 'utils/commonUtils';

interface AccountSettingsNavProps {
  className?: string;
}

const AccountSettingsNav: FC<AccountSettingsNavProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(findActiveTab(location?.pathname) ?? accountSettingsNavTabs[0]);

  useEffect(() => {
    setActiveTab(findActiveTab(location?.pathname) ?? accountSettingsNavTabs[0]);
  }, [location]);

  const isActiveTab = (tab: NavTab) => activeTab.key === tab.key;
  // TODO: Integrate Credits and Rewards once feature is made available in web
  // const isCreditsRewardsTab = (tab: NavTab) => accountSettingsNavTabs[1].key === tab.key;

  const onTabClick = (tab: NavTab) => {
    navigate(tab.path);
  };

  return (
    <div className="hidden h-full !w-[235px] shrink-0 flex-col rounded-md bg-secondaryBtn py-[17px] sm:flex">
      <div className="mb-[20px] px-[31px] text-sbase font-semibold text-primaryText">Accounts & Settings</div>

      {/* nav tabs */}

      <div>
        {accountSettingsNavTabs.map(tab => {
          return (
            <div
              onClick={() => onTabClick(tab)}
              className={twMerge(
                classnames(
                  `
                    flex
                    cursor-pointer flex-row items-center px-[31px] py-[10px] text-[0.938rem] font-semibold
                    leading-[1.25rem] text-primaryText
                  `,
                  {
                    'bg-borderGray font-bold text-heading': isActiveTab(tab)
                    // 'pointer-events-none cursor-not-allowed': isCreditsRewardsTab(tab)
                  }
                )
              )}
              key={tab.key}>
              <div className="mr-[15px]">
                <tab.icon className="shrink-0"></tab.icon>
              </div>
              <div className="">{tab.title}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AccountSettingsNav;
