import React, { FC, useState } from 'react';
import { CustomPopover } from 'components';
import Divider from '@material-ui/core/Divider';
import { twMerge } from 'tailwind-merge';
import classnames from 'classnames';
import { DeleteIcon, EditIcon, ListActionsIcon, VerticalListActionsIcon } from 'assets/icons';
import { ICategory } from '../categories.model';
import { CategoryActionType } from '../type';

interface CategoryTableProps {
  categoryList: ICategory[];
  handleMoreActionsClick: ({
    categoryAction,
    category
  }: {
    categoryAction: CategoryActionType;
    category: ICategory;
  }) => void;
}

const tableHeaders: Record<string, string> = {
  name: 'Name',
  items: 'No. of items'
};

const CategoryTable: FC<CategoryTableProps> = ({ categoryList, handleMoreActionsClick }) => {
  const [currentActionsPopoverId, setCurrentActionsPopoverId] = useState<number>(null);

  // eslint-disable-next-line react/no-multi-comp
  const ListActionsAnchor: React.FC<{ isPanelOpen: boolean }> = ({ isPanelOpen }) => {
    return (
      <div id="category-table-more-options" className="cursor-pointer py-2">
        <ListActionsIcon
          className={twMerge(
            'hidden shrink-0 lg:flex',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
        <VerticalListActionsIcon
          className={twMerge(
            'mx-2 flex shrink-0 lg:hidden',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
      </div>
    );
  };

  const onToggleListItemActions = (category: ICategory) => {
    if (category.id === currentActionsPopoverId) {
      setCurrentActionsPopoverId(null);
    } else {
      setCurrentActionsPopoverId(category.id);
    }
  };

  const isListItemActionsVisible = (categoryId: number) => {
    return categoryId === currentActionsPopoverId;
  };

  const onCloseListItemActionsPopover = (categoryId: number) => {
    if (currentActionsPopoverId === categoryId) {
      setCurrentActionsPopoverId(null);
    }
  };

  const getCategoryItemNoText = (category: ICategory) => {
    if (category.lineItemsCount === 0) return '0 items';
    if (category.lineItemsCount === 1) return '1 item';
    return `${category.lineItemsCount} items`;
  };

  // eslint-disable-next-line react/no-multi-comp
  const ListItemActionsMenu: FC<any> = ({
    id,
    category
  }: {
    id: string;
    category: ICategory;
    isModifiable: boolean;
  }) => {
    const handleEdit = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(category.id);
      handleMoreActionsClick({ categoryAction: CategoryActionType.EDIT, category });
    };

    const handleDelete = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(category.id);
      handleMoreActionsClick({ categoryAction: CategoryActionType.DELETE, category });
    };

    return (
      <div className="space-y-3 text-sbase font-semibold text-primaryText">
        <>
          <div
            id="category-table-table-3dot-edit"
            onClick={handleEdit}
            className={'flex cursor-pointer flex-row items-center gap-2'}>
            <EditIcon className="path-fill-current path text-secondary" />
            <div>Edit</div>
          </div>
          <div
            id="category-table-3dot-delete"
            onClick={handleDelete}
            className={'flex cursor-pointer flex-row items-center gap-2'}>
            <DeleteIcon />
            <div className="ml-0.5">Delete</div>
          </div>
        </>
      </div>
    );
  };

  return (
    <table className="relative w-full table-auto">
      <thead className="sticky top-[-4px] z-30 border-borderGray bg-primary">
        <Divider className="absolute top-[3.7rem] w-full bg-borderGray" />
        <tr className="text-slateGrey text-left text-xs lg:text-base [&>*]:py-5">
          {Object.values(tableHeaders).map((header, index) => {
            return (
              <th
                className={`max-w-[120px] text-sbase font-semibold text-primaryText 
                `}
                key={header + index}>
                {header}
              </th>
            );
          })}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {categoryList?.map((category, index) => (
          <tr
            key={category.id}
            id={`categories-table-row-${index + 1}`}
            className={`remove-highlight align-center border-b 
            border-secondaryBtn [&>*]:py-5 [&>*]:pr-2 `}>
            {/* Name */}
            <td className="w-3/5">
              <td>
                <div className="text-sbase text-primaryText">
                  <div className="truncate sm:max-w-[260px] lg:max-w-none">{category.name}</div>
                </div>
              </td>
            </td>
            {/* No. of items */}
            <td className="w-2/6 max-w-[120px]">
              <div className="text-sbase text-primaryText">
                <div>{getCategoryItemNoText(category)}</div>
              </div>
            </td>
            <td
              className="w-14 pr-2"
              onClick={e => {
                e.stopPropagation();
              }}>
              <div>
                <CustomPopover
                  showArrow={true}
                  minWidth="min-w-[113px]"
                  arrowOffset="right-6"
                  highlightAnchor={false}
                  anchorComponent={ListActionsAnchor}
                  zIndex="z-[101]"
                  offset="-right-6"
                  margin="mt-1"
                  onClosePopover={() => onCloseListItemActionsPopover(category.id)}
                  show={isListItemActionsVisible(category.id)}
                  onTogglePopover={() => onToggleListItemActions(category)}>
                  {<ListItemActionsMenu id={category.id} category={category} />}
                </CustomPopover>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default CategoryTable;
