export enum INVOICE_TAB_TITLE {
  ALL_INVOICES = 'All Invoices',
  PAID = 'Paid',
  PENDING = 'Pending'
}

export const TAB_HEADS = [
  {
    id: 1,
    title: INVOICE_TAB_TITLE.ALL_INVOICES
  },
  {
    id: 2,
    title: INVOICE_TAB_TITLE.PAID
  },
  {
    id: 3,
    title: INVOICE_TAB_TITLE.PENDING
  }
];

export enum TransactionStatus {
  SCANNED = 'SCANNED',
  INITIATED = 'INITIATED',
  GENERATED = 'GENERATED',
  PROCESSING = 'PROCESSING',
  SUCCESS = 'SUCCESS',
  CANCELLED = 'CANCELLED',
  TIMED_OUT = 'TIMED_OUT',
  PENDING = 'PENDING',
  FAILED = 'FAILED',
  BLOCKED = 'BLOCKED',
  REFUNDED = 'REFUNDED',
  REFUND_INITIATED = 'REFUND_INITIATED',
  SCHEDULED = 'SCHEDULED',
  IN_TRANSIT = 'IN_TRANSIT'
}

export enum CreateInvoiceHeaderType {
  CREATE = 'Create',
  PREVIEW = 'Preview'
}
