import { InvoiceLineItem, InvoicesState } from 'containers/invoices/InvoicesSlice';
import {
  AdditionalTax,
  IInvoiceAttachmentRequest,
  IInvoiceServiceCharge,
  IInvoiceServiceChargeRequest,
  IInvoiceServiceChargeTaxRequest,
  ILineItemRequest,
  ILineItemTaxRequest,
  IUniqueTaxRequest
} from 'containers/invoices/invoices.model';
import {
  formatDecimalThreePlaces,
  getAmountWithCurrency,
  getPercentageAmount,
  reverseCalculatePercentage,
  roundToTwoDecimals
} from 'utils/amountUtils';
import { InvoiceLineItemEditPage, initialDiscountFormData } from './edit-invoice/invoicesEditSlice';
import { DiscountFormData, LineItemAdditionalTax } from './types';
import { DISPUTE_STATUS } from 'containers/disputes/constants';
import { constructArrayObjectAsString } from 'utils/commonUtils';
import { IServiceCharge } from 'containers/product-catalog/service-charges/serviceCharge.model';
import { initServiceCharge } from './create-invoice/constants';
import { AbstractITax, ITax } from 'containers/product-catalog/taxes/taxes.model';
import { GlobalServiceChargeTax } from 'containers/product-catalog/catalog.model';
import { FeatureToggleState } from 'containers/discount-tips-fees/constants';
import { InvoiceStatus } from 'containers/invoices/invoices.model';

export const getAttachmentArray = (selectedInvoice: InvoicesState) => {
  const attachmentArray: IInvoiceAttachmentRequest[] = selectedInvoice.selectedInvoiceAttachments.map(attachment => {
    return {
      title: attachment.title,
      url: attachment.url,
      description: attachment.description
    };
  });
  return attachmentArray;
};

const prepareLineItemLevelTaxArray = (lineItemTaxes: LineItemAdditionalTax[]) => {
  if (lineItemTaxes?.length > 0) {
    const taxes = lineItemTaxes.map(tax => {
      return {
        name: tax.name,
        percentage: tax.percentage,
        amount: tax.amount.toString(),
        catalogueTaxId: tax.id
      } as ILineItemTaxRequest;
    });

    return { taxes: taxes };
  }
  return {};
};

export const getLineItemsArray = (selectedInvoice: InvoicesState) => {
  const lineItemRequestArray: ILineItemRequest[] = selectedInvoice?.invoiceLineItems?.map(lineItem => {
    return {
      catalogueLineItemId: lineItem.catalogueLineItemId,
      name: lineItem.itemDescription,
      amount: lineItem.total.toString(),
      qty: lineItem.quantity,
      unitPrice: lineItem.unitCost,
      additionalDetails: lineItem.additionalDetails,
      ...prepareLineItemLevelTaxArray(lineItem?.taxes)
    };
  });

  return lineItemRequestArray;
};

export const getFormattedAdditionalTaxPercent = (additionalTaxPercent: string) => {
  return formatDecimalThreePlaces(+additionalTaxPercent);
};

export const getServiceChargeEntityValue = (serviceCharge: IServiceCharge | IInvoiceServiceCharge): string => {
  if (+serviceCharge.percentage > 0) {
    return `${formatDecimalThreePlaces(serviceCharge.percentage)}%`;
  } else if (+serviceCharge.amount > 0) {
    return getAmountWithCurrency(serviceCharge.amount);
  }
  return '_';
};

export const calculateLineItemAdditionalTaxInCurrency = (additionalTaxPercent: string, totalLineItemCost: number) => {
  return roundToTwoDecimals((+additionalTaxPercent * +totalLineItemCost) / 100);
};

export const getAggregatedTaxInCurrencyWithSymbol = (
  lineItems: InvoiceLineItem[] | InvoiceLineItemEditPage[],
  additionalTax: LineItemAdditionalTax,
  selectedServiceCharge?: IInvoiceServiceCharge
): string => {
  let aggregatedTaxSum = 0;
  const selectedServiceChargeTaxes = selectedServiceCharge?.taxes ?? [];
  aggregatedTaxSum = lineItems.reduce((taxSum, currentLineItem) => {
    const additionaltaxInCurrentItem = currentLineItem?.taxes?.find(tax => tax.id === additionalTax.id);
    if (additionaltaxInCurrentItem) {
      return taxSum + additionaltaxInCurrentItem?.amount ?? 0;
    }
    return taxSum;
  }, 0);

  const isTaxInServiceCharge = selectedServiceChargeTaxes.find(tax => tax.id === additionalTax.id);
  if (isTaxInServiceCharge) {
    const serviceChargeTaxAmount = getPercentageAmount(
      selectedServiceCharge?.totalAmount ?? 0,
      isTaxInServiceCharge.percentage
    );
    aggregatedTaxSum = aggregatedTaxSum + serviceChargeTaxAmount;
  }

  return getAmountWithCurrency(aggregatedTaxSum.toString());
};

export const getAggregatedTaxInCurrency = (
  lineItems: InvoiceLineItem[] | InvoiceLineItemEditPage[],
  additionalTax: LineItemAdditionalTax,
  selectedServiceCharge?: IInvoiceServiceCharge
): string => {
  let aggregatedTaxSum = 0;
  const selectedServiceChargeTaxes = selectedServiceCharge?.taxes ?? [];
  aggregatedTaxSum = lineItems.reduce((taxSum, currentLineItem) => {
    const additionaltaxInCurrentItem = currentLineItem?.taxes?.find(tax => tax.id === additionalTax.id);
    if (additionaltaxInCurrentItem) {
      return taxSum + additionaltaxInCurrentItem.amount;
    }
    return taxSum;
  }, 0);

  const isTaxInServiceCharge = selectedServiceChargeTaxes.find(tax => tax.id === additionalTax.id);
  if (isTaxInServiceCharge) {
    const serviceChargeTaxAmount = getPercentageAmount(
      selectedServiceCharge?.totalAmount ?? 0,
      isTaxInServiceCharge.percentage
    );
    aggregatedTaxSum = aggregatedTaxSum + serviceChargeTaxAmount;
  }
  return roundToTwoDecimals(aggregatedTaxSum).toString();
};

export const getDisputeStyles = (status: DISPUTE_STATUS) => {
  switch (status) {
    case DISPUTE_STATUS.LOST:
      return { backgroundColor: '#FF7A7A' };
    case DISPUTE_STATUS.WON:
      return { backgroundColor: '#27AE60' };
    case DISPUTE_STATUS.NEEDS_ATTENTION:
    case DISPUTE_STATUS.NEEDS_RESPONSE:
      return { backgroundColor: '#F2994A' };
    case DISPUTE_STATUS.PAST_DUE:
      return { backgroundColor: '#F2B85C', color: '#333333' };
    case DISPUTE_STATUS.EVIDENCE_SUBMITTED:
      return { backgroundColor: '#A4D7FA', color: '#333333' };
    default:
      return { backgroundColor: '#5783BD' };
  }
};

export const getLineItemsArrayForEvent = (lineItems: InvoiceLineItem[] | InvoiceLineItemEditPage[]) => {
  const lineItemRequestArray: ILineItemRequest[] = lineItems.map(lineItem => {
    return {
      catalogueLineItemId: lineItem.catalogueLineItemId,
      name: lineItem.itemDescription,
      amount: lineItem.total.toString(),
      qty: lineItem.quantity,
      unitPrice: lineItem.unitCost,
      additionalDetails: lineItem.additionalDetails,
      lineItemTax: constructArrayObjectAsString(prepareLineItemLevelTaxArray(lineItem?.taxes)?.taxes, 'line item tax')
    };
  });
  return lineItemRequestArray;
};

export const getServiceChargeEventPayload = (serviceChargeList: IInvoiceServiceChargeRequest[]) => {
  return serviceChargeList.map(serviceCharge => ({
    ...serviceCharge,
    taxes: constructArrayObjectAsString(serviceCharge.taxes, 'taxes')
  }));
};

export const calculateServiceChargeBaseAmount = ({
  subtotal,
  serviceCharge
}: {
  subtotal: number;
  serviceCharge: IInvoiceServiceCharge;
}): number => {
  let serviceChargeBaseAmount = 0;
  if (+serviceCharge.percentage > 0) {
    serviceChargeBaseAmount = getPercentageAmount(subtotal, serviceCharge.percentage);
  } else if (+serviceCharge.amount > 0) {
    serviceChargeBaseAmount = +serviceCharge.amount;
  }
  return roundToTwoDecimals(serviceChargeBaseAmount);
};

export const calculateServiceChargeTotal = ({
  subtotal,
  serviceCharge
}: {
  subtotal: number;
  serviceCharge: IInvoiceServiceCharge;
}): number => {
  let serviceChargeBaseAmount = 0;
  let serviceChargeTaxesAmount = 0;
  let serviceChargeTotalAmount = 0;
  if (+serviceCharge.percentage > 0) {
    serviceChargeBaseAmount = getPercentageAmount(subtotal, serviceCharge.percentage);
    serviceChargeTaxesAmount = serviceCharge.taxes.reduce((sumOfServiceChargeTaxesAmount, taxOnServiceCharge) => {
      const serviceChargeTaxAmount = getPercentageAmount(serviceChargeBaseAmount, taxOnServiceCharge.percentage);
      return sumOfServiceChargeTaxesAmount + roundToTwoDecimals(serviceChargeTaxAmount);
    }, 0);
    serviceChargeTotalAmount = serviceChargeBaseAmount + serviceChargeTaxesAmount;
  } else if (+serviceCharge.amount > 0) {
    serviceChargeBaseAmount = +serviceCharge.amount;
    serviceChargeTaxesAmount = serviceCharge.taxes.reduce((sumOfServiceChargeTaxesAmount, taxOnServiceCharge) => {
      const serviceChargeTaxAmount = getPercentageAmount(serviceChargeBaseAmount, taxOnServiceCharge.percentage);
      return sumOfServiceChargeTaxesAmount + roundToTwoDecimals(serviceChargeTaxAmount);
    }, 0);
    serviceChargeTotalAmount = serviceChargeBaseAmount + serviceChargeTaxesAmount;
  }
  return roundToTwoDecimals(serviceChargeTotalAmount);
};

export const calculateInvoiceFinalAmount = ({
  subtotal,
  surcharge = 0,
  // TODO change key to service charge baseAmount
  totalServiceChargeInCurrency = 0,
  summatedLineItemLevelTaxesCurrency = 0
}: {
  subtotal: number;
  surcharge: number;
  totalServiceChargeInCurrency: number;
  summatedLineItemLevelTaxesCurrency?: number;
}) => {
  const final = subtotal + surcharge + totalServiceChargeInCurrency + summatedLineItemLevelTaxesCurrency;
  return roundToTwoDecimals(final);
};

// TODO change fn name as it also includes the taxes in service charge
export const calculateSummatedLineItemLevelTaxesCurrency = ({
  lineItems,
  selectedServiceCharge = initServiceCharge
}: {
  lineItems: InvoiceLineItem[];
  selectedServiceCharge?: IInvoiceServiceCharge;
}): number => {
  let summatedServiceChargeTaxesCurrency = 0;
  const summatedLineItemLevelTaxesCurrency = lineItems.reduce((sumOfAllItemLevelTaxes, currentLineItem) => {
    const sumOfTaxesAppliedOnSingleLineItem = currentLineItem?.taxes?.reduce(
      (sumOfItemRootLevelTaxes, currentItemLevelTax) => {
        return sumOfItemRootLevelTaxes + currentItemLevelTax?.amount;
      },
      0
    );

    if (sumOfTaxesAppliedOnSingleLineItem) {
      return sumOfAllItemLevelTaxes + sumOfTaxesAppliedOnSingleLineItem;
    }
    return sumOfAllItemLevelTaxes;
  }, 0);

  if (selectedServiceCharge?.taxes?.length > 0) {
    summatedServiceChargeTaxesCurrency = selectedServiceCharge?.taxes?.reduce(
      (sumOfAllServiceChargeTaxes, currentTax) => {
        const serviceChargeTaxAmount = getPercentageAmount(selectedServiceCharge?.totalAmount, currentTax.percentage);
        return sumOfAllServiceChargeTaxes + serviceChargeTaxAmount;
      },
      0
    );
  }

  return roundToTwoDecimals(summatedLineItemLevelTaxesCurrency + summatedServiceChargeTaxesCurrency) ?? 0;
};

export const isInvalidServiceCharge = ({
  serviceCharge = initServiceCharge
}: {
  serviceCharge: IInvoiceServiceCharge;
}) => {
  if (serviceCharge === initServiceCharge) {
    return true;
  }
  const { percentage = '0', amount = '0', name = '', totalAmount = '0' } = serviceCharge;
  if (!name || (+amount <= 0 && +percentage <= 0) || +totalAmount <= 0) {
    return true;
  }
  return false;
};

export const prepareServiceChargeListRequestPayload = (
  serviceCharge: IInvoiceServiceCharge
): IInvoiceServiceChargeRequest[] => {
  if (
    serviceCharge === initServiceCharge ||
    serviceCharge === null ||
    isInvalidServiceCharge({ serviceCharge: serviceCharge })
  ) {
    return [];
  }
  let serviceChargeTaxList: IInvoiceServiceChargeTaxRequest[] = [];
  let serviceChargeBaseAmount = +serviceCharge.totalAmount;
  if (serviceCharge?.taxes?.length > 0) {
    if (+serviceCharge.percentage > 0) {
      serviceChargeBaseAmount = +serviceCharge.totalAmount;
      serviceChargeTaxList = serviceCharge?.taxes?.map(tax => ({
        catalogueTaxId: tax.id,
        name: tax.name,
        percentage: tax.percentage,
        amount: roundToTwoDecimals(getPercentageAmount(serviceChargeBaseAmount, tax.percentage)).toString()
      }));
    } else if (+serviceCharge.amount > 0) {
      serviceChargeBaseAmount = +serviceCharge.amount;
      serviceChargeTaxList = serviceCharge?.taxes?.map(tax => ({
        catalogueTaxId: tax.id,
        name: tax.name,
        percentage: tax.percentage,
        amount: roundToTwoDecimals(getPercentageAmount(serviceChargeBaseAmount, tax.percentage)).toString()
      }));
    }
  }
  return [
    {
      name: serviceCharge.name,
      amount: serviceCharge.amount,
      percentage: serviceCharge.percentage,
      totalAmount: serviceChargeBaseAmount.toString(),
      taxes: serviceChargeTaxList
    }
  ];
};

export const prepareSummatedUniqueTaxList = ({
  appliedLineItemLevelTaxes = [],
  invoiceLineItems = [],
  selectedServiceCharge = initServiceCharge
}: {
  appliedLineItemLevelTaxes: AdditionalTax[];
  invoiceLineItems: InvoiceLineItem[] | InvoiceLineItemEditPage[];
  selectedServiceCharge: IInvoiceServiceCharge;
}) => {
  if (appliedLineItemLevelTaxes.length > 0) {
    const summatedUniqueTaxes = appliedLineItemLevelTaxes.map(tax => {
      return {
        catalogueTaxId: tax?.id,
        name: tax.name,
        percentage: tax.percentage,
        amount: getAggregatedTaxInCurrency(invoiceLineItems, tax, selectedServiceCharge)
      } as IUniqueTaxRequest;
    });
    return { taxes: summatedUniqueTaxes };
  }
  return {};
};

export const prepareLineItemTaxArray = (lineItemTaxes: LineItemAdditionalTax[]): { taxes: ILineItemTaxRequest[] } => {
  if (lineItemTaxes?.length > 0) {
    const taxes = lineItemTaxes.map(tax => {
      return {
        name: tax.name,
        percentage: tax.percentage,
        amount: tax.amount.toString(),
        catalogueTaxId: tax.id
      } as ILineItemTaxRequest;
    });

    return { taxes: taxes };
  }
  return { taxes: [] };
};

export const prepareLineItemList = (invoiceLineItems: InvoiceLineItem[] | InvoiceLineItemEditPage[] = []) => {
  if (invoiceLineItems?.length > 0) {
    const lineItemRequestArray: ILineItemRequest[] = invoiceLineItems.map(lineItem => {
      return {
        ...(lineItem?.catalogueLineItemId ? { catalogueLineItemId: lineItem?.catalogueLineItemId } : {}),
        name: lineItem.itemDescription,
        amount: lineItem.total.toString(),
        qty: lineItem.quantity,
        unitPrice: lineItem.unitCost,
        additionalDetails: lineItem.additionalDetails,
        sku: lineItem.sku ?? '',
        ...prepareLineItemTaxArray(lineItem?.taxes)
      };
    });
    return lineItemRequestArray;
  }
  return [];
};

export const isValidCatalogueLineItemId = (id: number) => {
  return id !== undefined && id !== null && id !== 0;
};

export const hasValidCatalogueServiceChargeId = (id: number) => {
  return id !== undefined && id !== null && id !== 0;
};

export const isValidSku = (sku: string) => {
  return sku !== undefined && sku !== null && sku !== '';
};

export const calculateInvoiceSubtotalAndNewDiscount = ({
  totalCostOfItems,
  discountData
}: {
  totalCostOfItems: number;
  discountData: DiscountFormData;
}): { subtotal: number; discountData: DiscountFormData } => {
  if (totalCostOfItems && isValidDiscountData(discountData)) {
    if (discountData.isDiscountAmountSelected) {
      const discountAmount = +discountData.discountAmount;
      const subtotal = totalCostOfItems - +discountData.discountAmount;
      const reverseCalculatedDiscountPercent = reverseCalculatePercentage({
        value: discountAmount,
        total: totalCostOfItems
      });
      const newDiscountData: DiscountFormData = {
        discountPercent: reverseCalculatedDiscountPercent.toString(),
        discountAmount: discountData.discountAmount,
        isDiscountAmountSelected: true,
        discountInCurrency: discountData.discountAmount,
        amountAfterDiscount: subtotal.toString()
      };
      return { subtotal: subtotal, discountData: newDiscountData };
    }
    // discount is of percentage type
    const finalDiscountInCurrency = getPercentageAmount(totalCostOfItems, discountData.discountPercent);
    const subtotal = totalCostOfItems - finalDiscountInCurrency;
    const newDiscountData: DiscountFormData = {
      discountPercent: discountData.discountPercent,
      discountAmount: '',
      isDiscountAmountSelected: false,
      discountInCurrency: finalDiscountInCurrency.toString(),
      amountAfterDiscount: subtotal.toString()
    };
    return { subtotal, discountData: newDiscountData };
  }
  return { subtotal: totalCostOfItems ?? 0, discountData };
};

export const isValidDiscountData = (discountData: DiscountFormData) => {
  return (
    discountData !== undefined &&
    discountData !== null &&
    discountData !== initialDiscountFormData &&
    (+discountData.discountAmount > 0 || +discountData.discountPercent > 0)
  );
};

export const prepareDiscountRelatedPayload = (
  discountData: DiscountFormData
): { discount: string; discountPercentage: string; isDiscountAmountSelected: boolean } | Record<string, never> => {
  if (isValidDiscountData(discountData)) {
    return {
      discount: discountData.discountInCurrency,
      discountPercentage: discountData.discountPercent,
      isDiscountAmountSelected: discountData.isDiscountAmountSelected ?? false
    };
  }
  return {};
};

export const hasSameTaxes = ({
  primaryIDSet = new Set<number>(),
  secondaryIDSet = new Set<number>()
}: {
  primaryIDSet: Set<number>;
  secondaryIDSet: Set<number>;
}): boolean => {
  if (primaryIDSet.size !== secondaryIDSet.size) {
    return false;
  }
  for (const id of primaryIDSet) {
    if (!secondaryIDSet.has(id)) return false;
  }
  return true;
};

export const isSameCatalogLineItem = ({
  primaryItem,
  secondaryItem
}: {
  primaryItem: InvoiceLineItem;
  secondaryItem: InvoiceLineItem;
}) => {
  if (primaryItem?.catalogueLineItemId === secondaryItem?.catalogueLineItemId) {
    const primaryItemTaxIDSet = new Set<number>(primaryItem?.taxes?.map(tax => tax.id));
    const secondaryItemTaxIDSet = new Set<number>(secondaryItem?.taxes?.map(tax => tax.id));

    if (
      secondaryItem.catalogueLineItemId === primaryItem.catalogueLineItemId &&
      secondaryItem.itemDescription === primaryItem.itemDescription &&
      +secondaryItem.unitCost === +primaryItem.unitCost &&
      secondaryItem.additionalDetails === primaryItem.additionalDetails &&
      hasSameTaxes({ primaryIDSet: primaryItemTaxIDSet, secondaryIDSet: secondaryItemTaxIDSet }) &&
      secondaryItem.sku === primaryItem.sku
    ) {
      return true;
    }
  }
  return false;
};

export const prepareTaxListWithAllGlobalTaxes = ({
  selectedTaxList = [],
  globalTaxes = []
}: {
  selectedTaxList: AbstractITax[];
  globalTaxes: GlobalServiceChargeTax[] | ITax[];
}): number[] => {
  const deselectedGlobalTaxList = globalTaxes.filter(
    globalTax => !selectedTaxList.some(selectedTax => selectedTax.id === globalTax.id)
  );
  const taxListWithAllGlobalTaxes = [...selectedTaxList, ...deselectedGlobalTaxList];
  return taxListWithAllGlobalTaxes.map(tax => tax.id) ?? [];
};

export const getIsSignatureEnabledState = (signatureConfig: FeatureToggleState): boolean => {
  switch (signatureConfig) {
    case FeatureToggleState.ALWAYS_ON:
    case FeatureToggleState.ON_BY_DEFAULT:
      return true;
    case FeatureToggleState.OFF_BY_DEFAULT:
      return false;
    default:
      return false;
  }
};

export const getInvoiceStatusBackgroundColor = status => {
  switch (status) {
    case InvoiceStatus.PAID:
      return '#CBF4C9';
    case InvoiceStatus.PENDING:
    case InvoiceStatus.PROCESSING:
      return '#F7E2C0';
    case InvoiceStatus.REFUNDED:
    case InvoiceStatus.REFUND_INITIATED:
      return '#A4D7FA';
    default:
      return '#CBF4C9';
  }
};
