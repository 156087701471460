import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { baseNavTabs, getNavSectionList } from 'constants/navConstants';
import { NavTab } from 'types/baseTypes';
import TeamSwitcher from './components/TeamSwitcher';
import { findActiveTab } from 'utils/commonUtils';
import NavSection from './components/NavSection';
import classNames from 'classnames';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
import {
  selectCurrentTeam,
  selectHoverState,
  selectMenuState,
  selectPaymentSystemConfig,
  toggleHoverState
} from 'containers/app/appSlice';
import { useDispatch } from 'react-redux';
import { useAppSelector, useScreenDimensions } from 'hooks';
import { logAnalyticEvent } from 'utils/analytics';
import { isTablet } from 'react-device-detect';
import { PaymentSystem } from 'containers/payouts/constants';
import { PaymentSystemTransitionType } from 'containers/home/constants';

const SideNav: FC = () => {
  const isMenuActive = useAppSelector(selectMenuState);
  const isHoverActive = useAppSelector(selectHoverState);
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig) ?? {};
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { screenWidth } = useScreenDimensions();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(findActiveTab(location?.pathname) ?? baseNavTabs[0]);

  const navSectionList = getNavSectionList(
    paymentSystem === PaymentSystem.LIP,
    currentTeam.paymentSystemTransitionType === PaymentSystemTransitionType.LIPConnectMigration,
    currentTeam.integrationsEnabled
  );

  useEffect(() => {
    setActiveTab(findActiveTab(location?.pathname) ?? baseNavTabs[0]);
  }, [location]);

  const onTabClick = (tab: NavTab) => {
    setActiveTab(tab);
    navigate(tab.path);
    if (tab.clevertapEvent) {
      logAnalyticEvent(tab.clevertapEvent);
    }
  };

  const onSubTabClick = (tab: NavTab) => {
    navigate(tab.path);
    if (tab.clevertapEvent) {
      logAnalyticEvent(tab.clevertapEvent);
    }
  };

  const lastIndex = navSectionList.length - 1;

  const toggleSideNavPositioning = () => {
    if (isMenuActive && screenWidth < 1024) {
      return;
    }
    dispatch(toggleHoverState());
  };

  const urlRoute = location.pathname;

  if (urlRoute === '/banking-onboarding') {
    return null;
  }

  return (
    <div
      className={twMerge(
        `z-[51] bg-primary hidden flex-col overflow-auto border-r 
        pt-2 text-sbase sm:flex w-[100px] ${!isMenuActive ? 'h-[calc(100%_-_60px)]' : ''}`,
        classNames({
          'min-w-[235px]': isMenuActive,
          'hover:fixed lg:hover:w-[235px]  transition-[width] fixed': !isMenuActive
        })
      )}
      onMouseEnter={toggleSideNavPositioning}
      onMouseLeave={toggleSideNavPositioning}>
      <div className="overflow-y-auto overflow-x-hidden">
        {navSectionList.map((navList, index) => {
          return (
            <NavSection
              className={twMerge(
                '',
                classnames({
                  'border-b border-secondaryBtn': index !== lastIndex,
                  'px-7': isMenuActive || (isHoverActive && !isTablet)
                })
              )}
              key={navList.toString() + navList[0].key}
              activeTab={activeTab}
              navList={navList}
              onNavTabClick={onTabClick}
              onSubTabClick={onSubTabClick}
            />
          );
        })}
      </div>
      <TeamSwitcher
        showDetails={true}
        className="mt-auto h-[80px] min-h-[80px] overflow-hidden border-t border-borderGray pl-4 pr-3"
      />
    </div>
  );
};

export default SideNav;
