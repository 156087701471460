import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { RouteLayout } from 'routes';
import ErrorBoundary from 'containers/error-boundary/ErrorBoundary';

import 'styles/global.css';
import 'styles/reset.css';

const App: React.FC = () => {
  return (
    <Router>
      <ErrorBoundary>
        <RouteLayout />
      </ErrorBoundary>
    </Router>
  );
};

export default App;
