import { FC, useEffect } from 'react';
import { CustomInput } from 'components';
import { useController, useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCategoryState, selectInitializingCategorySliceInProgress, updateCategoryName } from '../categorySlice';
import { CategoryInputErrors } from '../type';

interface CategoryNameSectionProps {
  className?: string;
  id: string;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
}

const CategoryNameSection: FC<CategoryNameSectionProps> = ({ className = '', id, error, setError }) => {
  const { control, reset } = useForm({
    defaultValues: {
      categoryName: ''
    }
  });

  const { field: categoryNameField } = useController({ name: 'categoryName', control });
  const selectedCategoryState = useAppSelector(selectCategoryState);
  const initializingCategorySliceInProgress = useAppSelector(selectInitializingCategorySliceInProgress);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!selectedCategoryState?.isEditCategoryFlow) {
      reset({
        categoryName: selectedCategoryState.categoryName
      });
    }
  }, []);

  useEffect(() => {
    if (selectedCategoryState?.isEditCategoryFlow && !initializingCategorySliceInProgress) {
      reset({
        categoryName: selectedCategoryState.categoryName
      });
    }
  }, [selectedCategoryState]);

  const handleCategoryNameChange = value => {
    setError(null);
    categoryNameField.onChange(value);
    if (value.length === 0) setError(CategoryInputErrors.Category_Name_Error);
    dispatch(updateCategoryName({ name: value }));
  };

  return (
    <div className="flex flex-col">
      <div className="mb-2 text-17px font-semibold text-headingGray">Category name*</div>
      <CustomInput
        value={categoryNameField.value}
        onChange={handleCategoryNameChange}
        id={id}
        name="firstName"
        maxLength={50}
        placeholder="Enter name"
        className={`text-sbase font-normal placeholder:text-accentText ${error ? 'border border-error' : ''}`}
      />
      {error && (
        <div id="category-name-error" className="mt-1 text-sbase text-error">
          {error}
        </div>
      )}
    </div>
  );
};

export default CategoryNameSection;
