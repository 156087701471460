import { ProfileImageEditIcon, ProfileImagePlaceHolder } from 'assets/icons';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { NotifierTypes } from 'constants/notifierConstants';
import { showNotifier } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { selectMyAccountState } from '../myAccountSlice';
import AddProfileImageModal from './AddProfileImageModal';

interface ProfileImageSectionProps {
  className?: string;
  isFetchingMerchantProfileQuery: boolean;
  refetchMerchantProfile: () => void;
}

const ProfileImageSection: FC<ProfileImageSectionProps> = ({
  refetchMerchantProfile,
  className,
  isFetchingMerchantProfileQuery
}) => {
  const selectedMyAccountState = useAppSelector(selectMyAccountState);
  const dispatch = useAppDispatch();
  const [showProfileImageModal, setShowProfileImageModal] = useState<{
    open: boolean;
    attachment: any;
    editMode: boolean;
  }>({
    open: false,
    attachment: { url: null, imagePreview: null },
    editMode: false
  });

  const resetProfileImageModalState = () => {
    setShowProfileImageModal(prevModalState => ({ ...prevModalState, open: false, editMode: false, attachment: null }));
  };

  const onSaveAttachmentClick = attachment => {
    resetProfileImageModalState();

    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Profile image updated successfully',
          isMessageHtml: true
        },
        type: NotifierTypes.SUCCESS,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
    refetchMerchantProfile();
  };

  const onModifyAttachmentClick = attachment => {
    resetProfileImageModalState();

    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Profile image updated successfully',
          isMessageHtml: true
        },
        type: NotifierTypes.SUCCESS,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
    refetchMerchantProfile();
  };

  const onEditProfileImage = attachment => {
    setShowProfileImageModal({ attachment: attachment, open: true, editMode: true });
  };

  const handleAddProfileImage = () => {
    setShowProfileImageModal(prev => ({ ...prev, editMode: false, open: !prev.open }));
  };

  return (
    <div className={twMerge('', className)}>
      <div className={isFetchingMerchantProfileQuery ? 'invisible' : 'visible'}>
        {selectedMyAccountState.profileImageUrl !== '' ? (
          <div
            className="relative ml-[156px] w-fit cursor-pointer"
            onClick={() =>
              onEditProfileImage({
                url: selectedMyAccountState.profileImageUrl,
                imagePreview: selectedMyAccountState.profileImageUrl
              })
            }>
            <CustomAvatar
              src={selectedMyAccountState.profileImageUrl}
              className="outline-boundary  h-[120px] w-[120px] ring-4 ring-secondary"
            />
            <ProfileImageEditIcon className="absolute right-0 bottom-2 shrink-0" />
          </div>
        ) : (
          <div className="ml-[156px]">
            <ProfileImagePlaceHolder onClick={handleAddProfileImage} className="shrink-0 cursor-pointer" />
          </div>
        )}
      </div>
      <AddProfileImageModal
        isEdit={showProfileImageModal.editMode}
        open={showProfileImageModal.open}
        toggleOpen={resetProfileImageModalState}
        imageData={showProfileImageModal.attachment}
        onSaveImageClick={onSaveAttachmentClick}
        onModifyImageClick={onModifyAttachmentClick}
      />
    </div>
  );
};

export default ProfileImageSection;
