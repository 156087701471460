import { ArrowRightIcon } from 'assets/icons';
import { Attachment } from 'containers/invoices/invoices.model';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface AttachmentsPanelProps {
  attachments: Attachment[];
  onClose: VoidFn;
}

const AttachmentsPanel: FC<AttachmentsPanelProps> = props => {
  const { attachments, onClose } = props;
  return (
    <div className="w-full h-full flex flex-col gap-5 py-5">
      <div className="flex items-center gap-2 w-full px-4">
        <div className="cursor-pointer" onClick={onClose}>
          <ArrowRightIcon className="rotate-180" />
        </div>
        <div className="font-semibold text-headingGray text-lg truncate">Attachments</div>
      </div>
      <div className="customNormalScroll overflow-y-auto px-4">
        <div className="border border-dark-gray rounded-md flex flex-col">
          <div className="text-headingGray font-semibold text-sbase border-b border-b-borderGray px-5 py-3">
            Attachments
          </div>
          <div>
            {attachments.map(file => (
              <div
                className="border-b border-b-borderGray p-5 only:border-b-0 last:border-b-0"
                key={file.title + file.url}>
                <div className="m-auto w-[300px] max-h-[297px] rounded-md border border-borderGray mb-4">
                  <img
                    src={file.urlLink}
                    alt={file.title}
                    className="object-contain w-[300px] max-h-[295px] rounded-md"
                  />
                </div>
                <div className="text-sbase text-headingGray">{file.title}</div>
                <div className="text-px13 text-primaryText whitespace-pre-line">{file.description}</div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttachmentsPanel;
