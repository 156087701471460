import { FC } from 'react';
import { formatAmount } from 'utils/amountUtils';
import { getInitials } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import {
  CaretDownIcon,
  InvoiceProcessingIcon,
  InvoiceRefundedIcon,
  TxnPendingIcon,
  TxnSuccessIcon
} from 'assets/icons';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { IInvoice, InvoiceStatus } from '../invoices.model';
import { getInvoiceStatusBackgroundColor } from '../utils';
interface InvoiceCardProps {
  data: IInvoice;
  currencySymbol?: string;
  onInvoiceClick: (invoiceId: string) => void;
}

const InvoiceCard: FC<InvoiceCardProps> = props => {
  const { data, currencySymbol = '$', onInvoiceClick } = props;

  const getIcon = () => {
    switch (data.status) {
      case InvoiceStatus.PENDING:
        return <TxnPendingIcon />;
      case InvoiceStatus.PAID:
      case InvoiceStatus.PAID_QR:
        return <TxnSuccessIcon />;
      case InvoiceStatus.REFUNDED:
      case InvoiceStatus.REFUND_INITIATED:
        return <InvoiceRefundedIcon />;
      case InvoiceStatus.PROCESSING:
        return <InvoiceProcessingIcon />;
      default:
        return <TxnPendingIcon />;
    }
  };

  const getInvoiceStatusText = () => {
    switch (data.status) {
      case InvoiceStatus.PAID:
      case InvoiceStatus.PAID_QR:
        return 'Paid';
      case InvoiceStatus.PENDING:
        return 'Pending';
      case InvoiceStatus.PROCESSING:
        return 'Processing';
      case InvoiceStatus.REFUNDED:
        return 'Refunded';
      case InvoiceStatus.REFUND_INITIATED:
        return 'Refund Initiated';
      default:
        return data.status;
    }
  };

  const getDate = () => {
    const date = DateUtils.getDateInFormat({ date: new Date(data.createdAt), dateFormat: 'dd MMM yyyy' });
    return date;
  };

  return (
    <div
      id="invoice-details-card-click"
      className="flex cursor-pointer items-center justify-between "
      onClick={() => onInvoiceClick(data.invoiceId)}>
      <div className="flex gap-2">
        <div>{getIcon()}</div>
        <div className="flex flex-col gap-1">
          <div className="text-sbase font-semibold text-secondary">{data.invoiceNumber}</div>
          <div className="flex items-center gap-2">
            <div className="text-sm text-accentText">{getDate()}</div>
            <div className="mt-1 h-1 w-1 rounded-full bg-[#ACB5BD]"></div>
            <div>
              <CustomAvatar
                src={data.merchantProfilePicureUrl}
                label={getInitials(data.merchantName)}
                width="w-4"
                height="h-4"
                fontStyle="text-[10px]"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center gap-1 text-right">
        <div className="flex flex-col gap-1">
          <div className="text-sbase font-bold text-primaryText">
            {currencySymbol}
            {formatAmount(data.amount)}
          </div>
          <div
            className="min-w-[55px] rounded px-2 text-center text-px13 text-tertiaryText"
            style={{
              backgroundColor: getInvoiceStatusBackgroundColor(data.status)
            }}>
            {getInvoiceStatusText()}
          </div>
        </div>
        <div className="cursor-pointer">
          <CaretDownIcon className="-rotate-90" />
        </div>
      </div>
    </div>
  );
};

export default InvoiceCard;
