import { FC, useState } from 'react';
import { TIMELINE_EVENTS } from '../constants';
import {
  ArrowRightIcon,
  BlueTickIcon,
  CrossIconvector,
  DownArrowIcon,
  GreenFilledTickIcon,
  OrangePausedIcon,
  RedDotIcon,
  RedTickIcon
} from 'assets/icons';
import DateUtils from 'utils/dateUtils';
import PayoutSuccessToolTip from './PayoutSuccessToolTip';
import { PayoutTimeline } from '../payouts.model';

interface PayoutsTimelineProps {
  timelineData: PayoutTimeline[];
  arrivalDate: string;
  destinationBank: string;
  destination: string;
}

const PayoutsTimeline: FC<PayoutsTimelineProps> = ({ timelineData, arrivalDate, destination, destinationBank }) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const toggleShowAll = () => {
    setShowAll(prev => !prev);
  };

  const getBgColor = (event: TIMELINE_EVENTS) => {
    switch (event) {
      case TIMELINE_EVENTS.CANCELLED:
      case TIMELINE_EVENTS.FAILED:
        return '#F7C0C0';
      case TIMELINE_EVENTS.INITIATED:
      case TIMELINE_EVENTS.RETRYING:
        return '#D8DFE5';
      case TIMELINE_EVENTS.IN_TRANSIT:
        return '#A4D7FA';
      case TIMELINE_EVENTS.PAUSED:
        return '#F7E2C0';
      case TIMELINE_EVENTS.SUCCESS:
        return 'rgba(203, 244, 201, 0.50)';
      default:
        return '#A4D7FA';
    }
  };

  const getTimeLineIcon = (event: TIMELINE_EVENTS) => {
    switch (event) {
      case TIMELINE_EVENTS.CANCELLED:
        return <CrossIconvector className="path-stroke-current path text-error" />;
      case TIMELINE_EVENTS.FAILED:
        return <RedDotIcon />;
      case TIMELINE_EVENTS.INITIATED:
      case TIMELINE_EVENTS.IN_TRANSIT:
        return <BlueTickIcon />;
      case TIMELINE_EVENTS.RETRYING:
        return <RedTickIcon />;
      case TIMELINE_EVENTS.PAUSED:
        return <OrangePausedIcon />;
      case TIMELINE_EVENTS.SUCCESS:
        return <GreenFilledTickIcon width={18} height={18} />;
      default:
        return <BlueTickIcon />;
    }
  };

  const getTimeLineStatusText = (event: TIMELINE_EVENTS) => {
    switch (event) {
      case TIMELINE_EVENTS.CANCELLED:
        return 'Cancelled';
      case TIMELINE_EVENTS.FAILED:
        return 'Failed';
      case TIMELINE_EVENTS.INITIATED:
        return 'Initiated';
      case TIMELINE_EVENTS.IN_TRANSIT:
      case TIMELINE_EVENTS.EXPECTED_TO_ARRIVE:
        return 'In transit';
      case TIMELINE_EVENTS.RETRYING:
        return 'Retry in progress';
      case TIMELINE_EVENTS.PAUSED:
        return 'Paused';
      case TIMELINE_EVENTS.SUCCESS:
        return 'Success';
      default:
        return 'Scheduled';
    }
  };

  const getTimeLineText = (event: TIMELINE_EVENTS) => {
    switch (event) {
      case TIMELINE_EVENTS.CANCELLED:
        return 'Cancelled';
      case TIMELINE_EVENTS.FAILED:
        return 'Failed';
      case TIMELINE_EVENTS.INITIATED:
        return 'Payout initiated';
      case TIMELINE_EVENTS.IN_TRANSIT:
        return 'Payout in transit';
      case TIMELINE_EVENTS.RETRYING:
        return 'Retrying';
      case TIMELINE_EVENTS.PAUSED:
        return 'Paused';
      case TIMELINE_EVENTS.SUCCESS:
        return 'Payout expected to have arrived';
      case TIMELINE_EVENTS.EXPECTED_TO_ARRIVE:
        return 'Payout expected to arrive';
      default:
        return 'Scheduled';
    }
  };

  const hasYearChanged = (firstDate: string, secondDate: string) => {
    const firstYear = new Date(firstDate).getFullYear();
    const secondYear = new Date(secondDate).getFullYear();
    return firstYear !== secondYear;
  };

  const getTimeLineSubText = (event: TIMELINE_EVENTS, time: string, actor: string) => {
    switch (event) {
      case TIMELINE_EVENTS.CANCELLED:
        return `${DateUtils.getDateInFormat({ date: new Date(time), dateFormat: 'dd MMM yyyy' })}${
          actor ? ` by ${actor}` : ''
        }`;
      case TIMELINE_EVENTS.FAILED:
        return '';
      case TIMELINE_EVENTS.INITIATED:
        return `${DateUtils.getDateInFormat({ date: new Date(time), dateFormat: 'dd MMM yyyy, p' })}${
          actor ? ` by ${actor}` : ''
        }`;
      case TIMELINE_EVENTS.IN_TRANSIT:
        return `${DateUtils.getDateInFormat({
          date: new Date(time),
          dateFormat: hasYearChanged(time, arrivalDate) ? 'dd MMM yyyy' : 'dd MMM'
        })} - ${DateUtils.getDateInFormatWtTimeZone({
          date: new Date(arrivalDate),
          timeZone: 'UTC',
          dateFormat: 'dd MMM yyyy'
        })}`;
      case TIMELINE_EVENTS.RETRYING:
        return `${DateUtils.getDateInFormat({ date: new Date(time), dateFormat: 'dd MMM yyyy' })}`;
      case TIMELINE_EVENTS.PAUSED:
        return `${DateUtils.getDateInFormat({ date: new Date(time), dateFormat: 'dd MMM yyyy' })}${
          actor ? ` by ${actor}` : ''
        }`;
      case TIMELINE_EVENTS.SUCCESS:
        return (
          <div className="flex items-center gap-2">
            {DateUtils.getDateInFormatWtTimeZone({
              date: new Date(arrivalDate),
              timeZone: 'UTC',
              dateFormat: 'dd MMM yyyy'
            })}{' '}
            by 7pm ET
            <PayoutSuccessToolTip />
          </div>
        );
      case TIMELINE_EVENTS.EXPECTED_TO_ARRIVE:
        return DateUtils.getDateInFormatWtTimeZone({
          date: new Date(arrivalDate),
          timeZone: 'UTC',
          dateFormat: 'dd MMM yyyy'
        });
      default:
        return '';
    }
  };

  const getBorderStyle = (primaryEvent: TIMELINE_EVENTS, secondaryEvent: TIMELINE_EVENTS, index: number) => {
    if (primaryEvent === TIMELINE_EVENTS.IN_TRANSIT && secondaryEvent !== TIMELINE_EVENTS.SUCCESS) {
      return 'bg-secondary';
    } else if (index === 0 && primaryEvent !== TIMELINE_EVENTS.EXPECTED_TO_ARRIVE) {
      switch (primaryEvent) {
        case TIMELINE_EVENTS.CANCELLED:
        case TIMELINE_EVENTS.FAILED:
          return 'bg-error';
        case TIMELINE_EVENTS.INITIATED:
          return 'bg-secondary';
        case TIMELINE_EVENTS.RETRYING:
          return 'bg-deep-red';
        case TIMELINE_EVENTS.PAUSED:
          return 'bg-[#F2B85C]';
        case TIMELINE_EVENTS.SUCCESS:
          return 'bg-successHighlight';
        default:
          return 'bg-secondary';
      }
    }
    return '';
  };

  const getSingleTimelineContent = () => {
    if (timelineData[1]?.event === TIMELINE_EVENTS.IN_TRANSIT && timelineData[0]?.event !== TIMELINE_EVENTS.SUCCESS) {
      return timelineData[1];
    }
    return timelineData[0];
  };

  const renderTimelineContent = (timeline, index) => {
    return (
      <div key={timeline.timestamp} className="flex gap-3.5">
        <div className="flex h-full shrink-0 flex-col items-center">
          <div
            className={`h-[18px] w-[18px] rounded-full bg-border ${getBorderStyle(
              timeline.event,
              timelineData[0]?.event as TIMELINE_EVENTS,
              index
            )}`}></div>
          {index < timelineData.length - 1 && (
            <div className="w-px grow bg-borderGray" style={{ height: 'calc(100% - 18px)' }}></div>
          )}
        </div>
        <div className="space-y-1 pb-3">
          <div
            className={`${
              timeline.event === TIMELINE_EVENTS.EXPECTED_TO_ARRIVE ? 'text-accentText' : 'text-primaryText'
            } text-17px font-semibold`}>
            {getTimeLineText(timeline.event)}
          </div>

          {timeline.timestamp && (
            <div className="text-sbase text-accentText">
              {getTimeLineSubText(timeline.event, timeline.timestamp, timeline.performedBy)}
            </div>
          )}

          {timeline.reason && <div className="text-sbase text-primaryText">Reason: {timeline.reason}</div>}

          {timeline.event === TIMELINE_EVENTS.IN_TRANSIT && (destinationBank || destination) && (
            <div className="!mt-2.5 space-y-1.5 rounded-md bg-secondaryBtn p-2.5 pr-6">
              <div className="text-sbase text-primaryText">{destinationBank}</div>
              <div className="text-sbase text-accentText">Bank account {destination}</div>
            </div>
          )}

          {timeline.event === TIMELINE_EVENTS.FAILED && (
            // TODO add onClick
            <div className="flex cursor-pointer items-center gap-1" onClick={null}>
              <div className="text-sbase font-semibold text-secondary">Learn more</div>
              <div className="mt-0.5">
                <ArrowRightIcon height={10} />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="h-full rounded-md border border-borderGray">
      <div
        className="flex w-full items-center gap-2.5 rounded-t-md px-[15px] py-2.5"
        style={{ backgroundColor: getBgColor(timelineData[0].event as TIMELINE_EVENTS) }}>
        <div>{getTimeLineIcon(timelineData[0].event as TIMELINE_EVENTS)}</div>
        <div className="text-17px font-semibold text-primaryText">
          {getTimeLineStatusText(timelineData[0].event as TIMELINE_EVENTS)}
        </div>
        {timelineData?.length > 1 && (
          <div className="ml-auto flex cursor-pointer items-center gap-1 justify-self-end" onClick={toggleShowAll}>
            <div className="text-sbase font-semibold text-primaryText">{showAll ? 'Show less' : 'Show all'}</div>
            <DownArrowIcon className={showAll ? 'rotate-180' : ''} />
          </div>
        )}
      </div>
      <div className="flex flex-col px-4 pt-5 pb-2.5" style={{ height: 'calc(100% - 40px)' }}>
        {showAll
          ? timelineData.map((timeline, index) => renderTimelineContent(timeline, index))
          : renderTimelineContent(getSingleTimelineContent(), 0)}
      </div>
    </div>
  );
};

export default PayoutsTimeline;
