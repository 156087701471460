import { FC } from 'react';
import { ReactIconType } from 'types/baseTypes';

const ChevronArrowRightReactIcon: FC<ReactIconType> = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      // eslint-disable-next-line max-len
      d="M11.775 9.69492L7.275 14.1949C7.125 14.3449 6.975 14.4199 6.75 14.4199C6.525 14.4199 6.375 14.3449 6.225 14.1949C5.925 13.8949 5.925 13.4449 6.225 13.1449L10.2 9.16992L6.225 5.19492C5.925 4.89492 5.925 4.44492 6.225 4.14492C6.525 3.84492 6.975 3.84492 7.275 4.14492L11.775 8.64492C12.075 8.94492 12.075 9.39492 11.775 9.69492Z"
      fill="#4F4F4F"
    />
    <mask
      id={`mask0_8194_57956-${props.id}`}
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="6"
      y="3"
      width="6"
      height="12">
      <path
        // eslint-disable-next-line max-len
        d="M11.775 9.69492L7.275 14.1949C7.125 14.3449 6.975 14.4199 6.75 14.4199C6.525 14.4199 6.375 14.3449 6.225 14.1949C5.925 13.8949 5.925 13.4449 6.225 13.1449L10.2 9.16992L6.225 5.19492C5.925 4.89492 5.925 4.44492 6.225 4.14492C6.525 3.84492 6.975 3.84492 7.275 4.14492L11.775 8.64492C12.075 8.94492 12.075 9.39492 11.775 9.69492Z"
        fill="white"
      />
    </mask>
    <g mask={`url(#mask0_8194_57956-${props.id})`}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0.169922H18V18.1699H0V0.169922Z" fill="#4F4F4F" />
    </g>
  </svg>
);

export default ChevronArrowRightReactIcon;
