import { FC, useEffect, useState } from 'react';
import { EditNumberIcon } from 'assets/icons';
import { CustomButton, CustomModal, GenericOTP, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import { useAppSelector, useCountDownInterval } from 'hooks';
import { selectMyAccountState } from '../myAccountSlice';
import { RequestOTPPayload } from 'containers/auth/auth.model';
import { useRequestOTPMutation } from 'containers/auth/api';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsMyAccount } from '../events';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface VerifyEmailModalProps {
  className?: string;
  open: boolean;
  toggleOpen?: VoidFn;
  otpError?: string | null;
  onVerifyOTP?: (otp: string) => void;
  handleEditEmail?: VoidFn; //for edit icon click
  isLoading?: boolean;
  setOtpError: React.Dispatch<React.SetStateAction<string>>;
}

const VerifyEmailModal: FC<VerifyEmailModalProps> = ({
  toggleOpen,
  open,
  otpError = '',
  onVerifyOTP,
  handleEditEmail,
  isLoading,
  setOtpError
}) => {
  const selectedMyAccountState = useAppSelector(selectMyAccountState);
  const [otp, setOtp] = useState('');
  const [resendEnabled, setResendEnabled] = useState(false);

  const { seconds, resetTimer } = useCountDownInterval({
    countDownCompletedCallback: () => setResendEnabled(true),
    countDownTime: 30
  });

  const onCancelClick = () => {
    toggleOpen();
    logAnalyticEvent(CleverTapEventsMyAccount.webMyAccEmailVerificationBackClick, {});
  };

  const [requestOTPMutation, { isSuccess: isRequestOTPSuccess }] = useRequestOTPMutation();

  const resendOTP = () => {
    let payload: RequestOTPPayload = null;
    payload = {
      updateEmail: true,
      email: selectedMyAccountState.email
    };
    requestOTPMutation(payload);
  };

  useEffect(() => {
    if (isRequestOTPSuccess) {
      setResendEnabled(false);
      resetTimer();
    }
  }, [isRequestOTPSuccess]);

  const handleOTPChange = otpString => {
    setOtpError('');
    setOtp(otpString);
    if (otpString.length === 4) {
      onVerifyOTP(otpString);
      logAnalyticEvent(CleverTapEventsMyAccount.webMyAccEmailVerificationContinueClick, {
        email: selectedMyAccountState.email
      });
    }
  };

  const handleVerifyClick = () => {
    onVerifyOTP(otp);
    logAnalyticEvent(CleverTapEventsMyAccount.webMyAccEmailVerificationContinueClick, {
      email: selectedMyAccountState.email
    });
  };

  const handleEditEmailIconClick = () => {
    handleEditEmail();
    logAnalyticEvent(CleverTapEventsMyAccount.webMyAccEmailVerificationEditClick, {});
  };

  return (
    <CustomModal width="rounded md:w-[452px] min-h-[390px]" open={open} closeModal={onCancelClick}>
      <div className="w-full p-10">
        <div className="flex flex-col items-center justify-center">
          <FormCloseReactIcon
            id="my-account-verify-email-modal-close"
            height="24"
            width="24"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onCancelClick}
          />
          <div className="flex w-full flex-col">
            <div className="text-xl font-bold leading-6 text-heading">Verify your email</div>
            <div className="my-3 text-17px font-normal text-primaryText">Enter the code we just sent to your email</div>
            <div className="mb-11 flex flex-row items-center gap-2">
              <div id="verify-code-entered-email" className="text-xl font-bold text-primaryText">
                {selectedMyAccountState.email}
              </div>
              <EditNumberIcon
                id="edit-email-icon"
                height={14.5}
                width={14.5}
                className="shrink-0 cursor-pointer"
                onClick={handleEditEmailIconClick}
              />
            </div>
            <div className={`relative flex flex-col gap-10 ${otpError ? 'mb-9' : 'mb-5'}`}>
              <GenericOTP
                separator={<span className="w-11"></span>}
                otpInputStyle={`emailOtpStyle !w-[60px] border-none focus:border-none 
                focus:outline-none placeholder:text-accentText placeholder:font-bold placeholder:text-xl
                text-primaryText text-xl font-bold focus-visible:placeholder:text-transparent
                ${otpError ? '!border-error !border-[1px] !border-solid focus:border-error' : ''}`}
                onChange={handleOTPChange}
                value={otp}
              />
              <If condition={Boolean(otpError)}>
                <div className="absolute top-16 text-sbase text-error">{otpError}</div>
              </If>
            </div>
            <div className="flex">
              <If condition={resendEnabled}>
                <div className="text-sbase text-primaryText">Trouble receiving the code?</div>
                <div
                  onClick={resendOTP}
                  className={`ml-3 cursor-pointer text-sbase text-secondary 
              underline underline-offset-2`}>
                  Resend code
                </div>
              </If>
              <If condition={!resendEnabled}>
                <div className="text-sbase text-primaryText">
                  {`You can resend the code in 
                ${seconds < 10 ? `0${seconds}` : seconds} seconds`}
                </div>
              </If>
            </div>
          </div>
        </div>
        <div className="flex flex-row items-end justify-end gap-5">
          <CustomButton
            id="verify-email-modal-back-button"
            className="mt-8 h-[38px] w-[100px]"
            type={ButtonType.SECONDARY}
            onClick={onCancelClick}>
            Back
          </CustomButton>
          <CustomButton
            id="verify-email-modal-continue-button"
            isLoading={isLoading}
            onClick={handleVerifyClick}
            disabled={!otp || otp.length !== 4}
            className={`text-semibold mt-5 h-[38px] w-[150px] rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
            Continue
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default VerifyEmailModal;
