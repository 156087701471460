import { FC, useEffect, useState } from 'react';
import { CustomButton, GenericOTP, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useAppSelector, useCountDownInterval } from 'hooks';
import { VoidFn } from 'types/baseTypes';
import { RequestOTPPayload, VerifyOTPPayload } from '../auth.model';
import { useRequestOTPMutation } from '../api';
import { selectUserDetails } from 'containers/app/appSlice';

interface EmailVerificationSectionProps {
  className?: string;
  otpError: string;
  onVerifyOTP: (otp: VerifyOTPPayload) => void;
  isLoading?: boolean;
  handleVerifyLater: VoidFn;
  setOtpError: React.Dispatch<React.SetStateAction<string>>;
}

const EmailVerificationSection: FC<EmailVerificationSectionProps> = ({
  className,
  otpError = '',
  onVerifyOTP,
  isLoading,
  handleVerifyLater,
  setOtpError
}) => {
  const [otp, setOtp] = useState('');
  const [otpInputError, setOtpInputError] = useState('');
  const [resendEnabled, setResendEnabled] = useState(false);

  const [requestOTPMutation, { isLoading: isLoadingRequestOTPMutation, isSuccess: isRequestOTPSuccess }] =
    useRequestOTPMutation();

  const currentUser = useAppSelector(selectUserDetails);

  const { seconds, resetTimer } = useCountDownInterval({
    countDownCompletedCallback: () => setResendEnabled(true),
    countDownTime: 30
  });

  useEffect(() => {
    if (isRequestOTPSuccess) {
      setResendEnabled(false);
      resetTimer();
    }
  }, [isLoadingRequestOTPMutation, isRequestOTPSuccess]);

  useEffect(() => {
    if (otpError) {
      setOtpInputError(otpError);
    }
  }, [otpError]);

  const resendOTP = () => {
    const payload: RequestOTPPayload = { email: currentUser.email, verifyEmail: true };
    requestOTPMutation(payload);
  };

  const handleOTPChange = otpString => {
    setOtpInputError('');
    setOtpError('');
    setOtp(otpString);
    if (otpString.length === 4) {
      handleContinueClick(otpString);
    }
  };

  const handleContinueClick = (otpVal = otp) => {
    if (otpVal.length === 4) {
      const payload: VerifyOTPPayload = {
        otp: otpVal,
        email: currentUser.email,
        verifyEmail: true
      };
      onVerifyOTP(payload);
    }
  };

  return (
    <div className={`my-auto flex max-w-[375px] flex-col self-center 2xl:w-[375px] ${className}`}>
      <div className="mt-12 text-2xl font-bold text-heading lg:text-4xl">Verify your email</div>
      <div>
        <div className="mt-4 text-17px font-normal text-primaryText">Enter the code we just sent to your email</div>
        <div id="verify-code-entered-email" className="mt-1 mb-9 text-xl font-bold text-primaryText">
          {currentUser?.email}
        </div>
        {/* <div className="mb-9 text-xl font-bold text-primaryText">{currentSignedInEmail}</div> */}
      </div>
      <div className={`relative flex flex-col gap-10 ${otpInputError ? 'mb-9' : 'mb-5'}`}>
        <GenericOTP
          separator={<span className="w-11"></span>}
          otpInputStyle={`emailOtpStyle !w-[60px] border-none focus:border-none 
                focus:outline-none placeholder:text-accentText placeholder:font-bold placeholder:text-xl
                text-primaryText text-xl font-bold focus-visible:placeholder:text-transparent
                ${otpInputError ? '!border-error !border-[1px] !border-solid focus:border-error' : ''}`}
          onChange={handleOTPChange}
          value={otp}
        />
        <If condition={Boolean(otpInputError)}>
          <div className="absolute top-16 text-sbase text-error">{otpInputError}</div>
        </If>
      </div>
      <div className="flex">
        <If condition={resendEnabled}>
          <div className="text-sbase text-primaryText">Trouble receiving the code?</div>
          <div
            onClick={resendOTP}
            className={`ml-3 cursor-pointer text-sbase text-secondary 
              underline underline-offset-2`}>
            Resend code
          </div>
        </If>
        <If condition={!resendEnabled}>
          <div className="text-sbase text-primaryText">
            {`Resend the code in 
                ${seconds < 10 ? `0${seconds}` : seconds} seconds`}
          </div>
        </If>
      </div>

      <div>
        <CustomButton
          id="verification-method-section-send-code-button"
          onClick={handleContinueClick}
          disabled={!otp || otp.length !== 4}
          className={`text-semibold mt-10 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}
          isLoading={isLoading}>
          Continue
        </CustomButton>
        <CustomButton
          id="verification-method-section-back-button"
          className="mt-4 h-[38px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
          type={ButtonType.SECONDARY}
          onClick={handleVerifyLater}>
          Verify later
        </CustomButton>
      </div>
    </div>
  );
};

export default EmailVerificationSection;
