import { MagnifierIcon, SearchCloseIcon } from 'assets/icons';
import classnames from 'classnames';
import If from 'components/If/If';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

interface SearchBarProps {
  value: string;
  handleChange: (text: string) => void;
  placeholder?: string;
  wrapperStyle?: string;
  showSearchIcon?: boolean;
  handleClear: VoidFn;
  autoFocus: boolean;
  showCrossIcon?: boolean;
  id: string;
  onFocusHandler?: VoidFn;
  alignRight?: boolean;
  inputWrapperStyle?: string;
  inputStyle?: string;
}

const SearchBar: FC<SearchBarProps> = props => {
  const {
    value,
    placeholder = 'Search',
    handleChange,
    wrapperStyle = 'border border-borderGray rounded',
    showSearchIcon = true,
    handleClear,
    autoFocus = false,
    showCrossIcon = false,
    id,
    onFocusHandler = () => null,
    alignRight = false,
    inputWrapperStyle = '',
    inputStyle = ''
  } = props;
  return (
    <div className={twMerge('w-full h-full', wrapperStyle)}>
      <div className={twMerge('flex h-full  items-center gap-2 px-2 py-[7px] text-[#212429]', inputWrapperStyle)}>
        <If condition={showSearchIcon && !alignRight}>
          <MagnifierIcon className="shrink-0" />
        </If>
        <input
          id={id}
          onFocus={onFocusHandler}
          autoFocus={autoFocus}
          spellCheck="false"
          className={twMerge(
            'outline-none placeholder-accentText text-sbase placeholder:font-semibold',
            classnames({
              'w-full': !showSearchIcon,
              'w-11/12': showSearchIcon
            }),
            inputStyle
          )}
          type="text"
          placeholder={placeholder}
          value={value ? value : ''}
          onChange={e => handleChange(e.target.value)}
          autoComplete="off"
        />
        <If condition={showSearchIcon && alignRight && Boolean(!value)}>
          <div className="flex justify-end ">
            {' '}
            <MagnifierIcon className="shrink-0" />
          </div>
        </If>
        <If condition={Boolean(value) && showCrossIcon}>
          <SearchCloseIcon
            className="path-stroke-current ml-auto shrink-0 cursor-pointer stroke-[0.5px] text-accentText"
            onClick={handleClear}
          />
        </If>
      </div>
    </div>
  );
};

export default SearchBar;
