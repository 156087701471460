import { AccountDeniedIcon, ChevronArrowRightIcon, RedStrokeInfoIcon } from 'assets/icons';
import { FC } from 'react';

const ApplicationDeniedCard: FC = () => {
  return (
    <div className="flex w-full items-center rounded-md border-2 border-error bg-[#FDF9F2] p-[15px]">
      <AccountDeniedIcon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-bold text-heading">Review complete</div>
        <div className="flex items-center gap-1.5 text-sbase text-error">
          <RedStrokeInfoIcon />
          Application denied
        </div>
      </div>
      <ChevronArrowRightIcon className="ml-auto shrink-0" />
    </div>
  );
};

export default ApplicationDeniedCard;
