import { FileTypes } from 'types/baseTypes';
import { ConnectPayoutStatus, PAYOUT_TXNS_TABS, PAYOUT_TXNS_TYPE_FILTERS } from './constants';

export interface PayoutsPayload {
  teamId: number;
  params: {
    status: ConnectPayoutStatus[];
    fromTimestamp: string;
    toTimestamp: string;
    search: string;
    limit: number;
    page: number;
    type: FileTypes;
    selectedIds: string[];
    amount: string;
  };
}

export interface Metadata {
  limit: number;
  page: number;
  total: number;
}
export interface PayoutsResponse {
  data: ConnectPayout[];
  metadata: Metadata;
}

export interface Payout {
  id: string;
  paymentMethod: string;
  currency: string;
  description: string;
  amount: number;
  grossAmount: number;
  serviceFee: number;
  pastRefundAmount: number;
  pastChargebackAmount: number;
  creditsApplied: number;
  status: string;
  merchantID: string;
  destination: string;
  createdAt: string;
  teamID: number;
}

export interface PayoutSummaryResponse {
  payoutSummary: PayoutSummary;
}

export interface PayoutSummary {
  payoutSettlement: payoutSettlement;
  payoutOnHold: PayoutOnHold;
  payoutBalance: PayoutBalance;
  isUnitEnabled: boolean;
}

export interface payoutSettlement {
  grossAmount: number;
  Amount: number;
  serviceFee: number;
  refunds: number;
  chargebacks: number;
  totalTrnxCount: number;
  creditsApplied: number;
}

export interface PayoutOnHold {
  grossAmount: number;
  Amount: number;
  serviceFee: number;
  refunds: number;
  chargebacks: number;
  totalTrnxCount: number;
}

export interface PayoutBalance {
  grossAmount: number;
  Amount: number;
  refunds: number;
  chargebacks: number;
  onHold: number;
  totalTrnxCount: number;
}

export interface PayoutsSummaryTransactions {
  transactions: PayoutTransaction[];
  totalCount: number;
}

export interface PayoutTransaction {
  id: string;
  paymentMethod: string;
  amount: number;
  status: string;
  createdAt: string;
  reason: string;
  source: string;
  customerName: string;
  profilePicture: string;
  merchantName: string;
  merchantId: string;
  parentId: string;
  refundTransactions: any;
}

export interface PayoutsSummaryTxnPayload {
  teamId: number;
  transactionType: PayoutTransactionTypes;
  status: PayoutStatus[];
  start: string;
  end: string;
  search: string;
  limit: number;
  page: number;
}

export interface PayoutByIdResponse {
  ID: string;
  paymentMethod: string;
  pgTransactionId: string;
  currency: string;
  descrption: string;
  amount: string;
  grossAmount: string;
  pastRefundAmount: string;
  pastChargebackAmount: string;
  creditsApplied: string;
  serviceFee: string;
  status: string;
  merchantId: string;
  destination: string;
  createdAt: string;
  updatedAt: string;
  createdBy: string;
  updatedBy: string;
  transactions: PayoutTransaction[];
}

export interface PayoutByIdPayload {
  payoutId: string;
  teamId: number;
}
export interface PayoutSummaryPayload {
  teamId: number;
  status: PayoutStatus[];
  start: string;
  end: string;
  search: string;
}

export interface ConnectPayout {
  id: string;
  amount: string;
  status: string;
  paymentSystem: string;
  createdAt: string;
  updatedAt: string;
  arrivalAt: string;
}

export interface InTransitPayoutsResponse {
  data: ConnectPayout[];
}

export interface InTransitPayoutsPayload {
  teamId: number;
}

export interface UpcomingPayoutsSummary {
  paymentsCollected: string;
  feesCollected: string;
  refunds: string;
  adjustments: string;
  creditsApplied: string;
  inFutureAmount: string;
}
export interface PayoutsAccountSummary {
  balance: string;
  inFuture: string;
  inTransit: string;
  inReserve: string;
  hasUpcomingPayouts: boolean;
  hasInTransitPayouts: boolean;
  hasInReservePayouts: boolean;
}

export enum PayoutStatus {
  SUCCESS = 'SUCCESS',
  PENDING = 'PENDING',
  ON_HOLD = 'ON_HOLD',
  FAILED = 'FAILED',
  SCHEDULED = 'SCHEDULED'
}

export enum PayoutTransactionTypes {
  ON_HOLD = 'onhold',
  SETTLED = 'settled',
  BALANCE = 'balance'
}

export enum PayoutSummaryTypes {
  ON_HOLD = 'On hold',
  SETTLED = 'Total Settled',
  BALANCE = 'Balance'
}

export enum PayoutsSummaryTypes {
  HELD_IN_RESERVE = 'held-in-reserve',
  UPCOMING_PAYOUTS = 'upcoming-payouts',
  ON_THE_WAY = 'in-transit-payouts'
}

export interface ConnectPayoutByIdPayload {
  teamId: number;
  payoutId: string;
}

export interface ConnectPayoutByIdResponse {
  data: PayoutData;
  timeline: PayoutTimeline[];
}

export interface PayoutData {
  ID: string;
  paymentMethod: string;
  paymentTraceId: string;
  currency: string;
  description: string;
  amount: string;
  grossAmount: string;
  serviceFee: string;
  pastRefundAmount: string;
  pastChargebackAmount: string;
  creditsApplied: string;
  status: string;
  merchantID: string;
  destination: string;
  destinationBank: string;
  createdAt: string;
  updatedAt: string;
  arrivalAt: string;
  teamID: number;
  transactionFeePercentage: string;
  transactionFeeBasePoints: string;
  createdBy: string;
  notes: string;
}

export interface UpdateNotesPayload extends ConnectPayoutByIdPayload {
  body: {
    notes: string;
  };
}

export interface PayoutTimeline {
  event: string;
  timestamp: string;
  performedBy: string;
  reason: string;
}

export interface TransactionsByPayoutIdPayload {
  amount: string;
  status: string;
  fromTimestamp: string;
  toTimestamp: string;
  page: number;
  limit: number;
  payoutId: string;
  teamId: number;
  search: string;
}

export interface UpcomingPayoutTransactionsPayload {
  amount: string;
  status: string;
  fromTimestamp: string;
  toTimestamp: string;
  page: number;
  limit: number;
  teamId: number;
  search: string;
}

export interface ConnectPayoutTransactionsResponse {
  data: PayoutTxnsData[];
  metadata: Metadata;
}

export interface PayoutTxnsData {
  ledgerId: number;
  amount: string;
  direction: string;
  createdAt: string;
  title: string;
  subTitle: string;
  description: string;
  ctaName: string;
  ctaType: string;
  ctaAction: string;
}

export interface Metadata {
  page: number;
  limit: number;
  total: number;
}

export interface PayoutTxnsPageState {
  tab: PAYOUT_TXNS_TABS;
  amountFilter: { title: string; min: string; max: string };
  typeFilter: PAYOUT_TXNS_TYPE_FILTERS[];
  search: string;
}
