import { FC, useEffect } from 'react';
import { Divider } from '@material-ui/core';
import Lottie from 'lottie-react';
import { ArrowRightIcon } from 'assets/icons';
import { CustomButton, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { useInitiateRefundMutation } from '../api';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { selectRefundAmount, selectRefundData, selectRefundReason } from '../receiptsSlice';
import { useAppSelector } from 'hooks';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import SidePanel from 'components/side-panel/SidePanel';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';

interface InitiateRefundPanelProps {
  onClose?: VoidFn;
  routeToNextPage?: ({ type }: { type: SidePanelTypes; id: string }) => void;
  currentTeamId?: number;
  resetReceiptsQuery?: VoidFn;
}

const InitiateRefundPanel: FC<InitiateRefundPanelProps> = () => {
  const { receiptId, rootInvoiceId, rootReceiptId, rootCustomerId, rootPage, rootDisputeId } = useParams();
  const navigate = useNavigate();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const refundData = useAppSelector(selectRefundData);
  const { teamBankingBalance, futurePayouts, pendingPayouts } = refundData;
  const refundAmount = useAppSelector(selectRefundAmount);
  const refundReason = useAppSelector(selectRefundReason);

  const [
    initiateRefundQuery,
    { isLoading: initiateRefundLoading, isSuccess: isInitiateRefundSuccess, isError: isInitiateRefundError }
  ] = useInitiateRefundMutation();

  const { handleSidePanelClose, resetReceiptsQuery } = useOutletContext<any>();

  const onInitiateRefundClick = () => {
    initiateRefundQuery({
      teamId: currentTeam?.id.toString(),
      parentID: receiptId || rootReceiptId,
      amount: refundAmount,
      reason: refundReason,
      refundFlow: true,
      autoDebitFlow: true
    });
  };

  useEffect(() => {
    if (!refundAmount) {
      if (rootReceiptId) {
        if (receiptId) {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/create-refund/${receiptId}`);
        } else {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/create-refund`);
        }
      } else if (rootInvoiceId) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/create-refund/${receiptId}`);
      } else if (rootPage) {
        navigate(`/payouts/${rootPage}/${currentTeam?.id}/create-refund/${receiptId}`);
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/create-refund/${receiptId}`);
      } else if (rootDisputeId) {
        navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/create-refund/${receiptId}`, {
          replace: true
        });
      }
    }
  }, [refundAmount]);

  useEffect(() => {
    if (isInitiateRefundSuccess) {
      if (rootReceiptId) {
        if (receiptId) {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund-success/${receiptId}`, {
            replace: true
          });
        } else {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund-success`, { replace: true });
        }
      } else if (rootInvoiceId) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/refund-success/${receiptId}`, { replace: true });
      } else if (rootPage) {
        navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/refund-success/${receiptId}`, { replace: true });
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/refund-success/${receiptId}`, { replace: true });
      } else if (rootDisputeId) {
        navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/refund-success/${receiptId}`, {
          replace: true
        });
      }
      if (resetReceiptsQuery) {
        resetReceiptsQuery();
      }
    }
  }, [isInitiateRefundSuccess]);

  useEffect(() => {
    if (isInitiateRefundError) {
      if (rootReceiptId) {
        if (receiptId) {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund-failed/${receiptId}`);
        } else {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund-failed`);
        }
      } else if (rootInvoiceId) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/refund-failed/${receiptId}`);
      } else if (rootPage) {
        navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/refund-failed/${receiptId}`);
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/refund-failed/${receiptId}`);
      } else if (rootDisputeId) {
        navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/refund-failed/${receiptId}`, {
          replace: true
        });
      }
    }
  }, [isInitiateRefundError]);

  const getRefundText = () => {
    if (+teamBankingBalance > 0 && +futurePayouts > 0)
      return `I agree to allow scanpay to debit from my team's \n banking account & future 
      payouts for \n the above mentioned amount`;
    else if (+teamBankingBalance > 0)
      return `I agree to allow scanpay to debit from my  
      team's banking account for the above \n mentioned amount`;
    else if (+pendingPayouts > 0)
      return 'I agree to allow scanpay to  debit from \n my pending payouts for the  above \n mentioned amount';
    return `Refund amount will be deducted from the 
    team's future payouts.`;
  };

  const handleClose = () => {
    if (rootReceiptId) {
      if (receiptId) {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/create-refund/${receiptId}`, {
          replace: true,
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/create-refund`, {
          replace: true,
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/create-refund/${receiptId}`, {
        replace: true,
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/create-refund/${receiptId}`, {
        replace: true,
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/create-refund/${receiptId}`, {
        replace: true,
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/create-refund/${receiptId}`, {
        replace: true,
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  return (
    <SidePanel onClose={handleSidePanelClose} isOpen={true}>
      <div className="h-full w-96">
        <>
          <If condition={initiateRefundLoading}>
            <div className="flex h-full w-full items-center justify-center">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          </If>
          <div className="flex h-full w-full flex-col">
            <div className="flex items-center gap-2 border-b-[1px] py-5 px-4">
              <div onClick={handleClose} className="cursor-pointer">
                <ArrowRightIcon className="rotate-180" />
              </div>
              <div className="text-17px font-semibold text-headingGray">Create refund</div>
            </div>
            <div className="px-4">
              <div className="flex w-full flex-col gap-4 py-9">
                <div className="flex items-center justify-between text-sbase  leading-5">
                  <div className="font-semibold text-primaryText">Refund Amount</div>
                  <div className="max-w-[75%] truncate text-primaryText">{getAmountWithCurrency(refundAmount)}</div>
                </div>
                <div className="flex items-center justify-between text-sbase leading-5">
                  <div className="font-semibold text-primaryText">Refund for</div>
                  <div className="max-w-[75%] truncate text-primaryText">{receiptId || rootReceiptId}</div>
                </div>
                <div className="flex items-center justify-between text-sbase  leading-5">
                  <div className="font-semibold text-primaryText">Reason</div>
                  <div className="max-w-[75%] truncate text-primaryText">{refundReason}</div>
                </div>
              </div>
              <Divider />
            </div>
            <div className="flex flex-col p-4">
              <div className="mb-4 text-sbase font-semibold text-primaryText">Source of funds</div>
              <If condition={Boolean(+teamBankingBalance > 0)}>
                <div className="mb-4 flex items-center justify-between text-sbase leading-5">
                  <div className="text-primaryText">Team&apos;s banking Balance</div>
                  <div className="max-w-[75%] truncate text-primaryText">
                    {getAmountWithCurrency(teamBankingBalance)}
                  </div>
                </div>
              </If>
              <If condition={Boolean(+futurePayouts > 0)}>
                <div className="mb-4 flex items-center justify-between text-sbase leading-5">
                  <div className="text-primaryText">Future Payouts</div>
                  <div className="max-w-[75%] truncate text-primaryText">{getAmountWithCurrency(futurePayouts)}</div>
                </div>
              </If>
              <If condition={Boolean(+pendingPayouts > 0)}>
                <div className="mb-4 flex items-center justify-between text-sbase leading-5">
                  <div className="text-primaryText">Pending Payouts</div>
                  <div className="max-w-[75%] truncate text-primaryText">{getAmountWithCurrency(pendingPayouts)}</div>
                </div>
              </If>
            </div>
            <div className="mt-10 flex flex-col items-center justify-center">
              <div className="mb-2 text-17px font-semibold text-primaryText">Initiate refund?</div>
              <div className="whitespace-pre-line text-center text-sbase	text-primaryText">{getRefundText()}</div>
            </div>
            <div className="mt-12 flex w-full flex-col justify-end gap-5 px-4">
              <CustomButton id="initiate-refund-continue" onClick={onInitiateRefundClick} className="w-full">
                Continue
              </CustomButton>
              <CustomButton
                id="initiate-refund-cancel"
                className="w-full"
                type={ButtonType.SECONDARY}
                onClick={handleClose}>
                Cancel
              </CustomButton>
            </div>
          </div>
        </>
      </div>
    </SidePanel>
  );
};

export default InitiateRefundPanel;
