import React from 'react';
import ScanPayLogoPlaceholder from '../../../assets/icons/ScanPayPlaceholder.svg';
import Divider from '@material-ui/core/Divider';
import { getInitials } from 'utils/commonUtils';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { TeamInviteDataType } from 'types/notification';
import DateUtils from 'utils/dateUtils';

interface TeamInviteCardProps {
  onAcceptInvite: (inviteData: TeamInviteDataType) => void;
  onDeclineInvite: (token: string) => void;
  teamInviteData: TeamInviteDataType;
}

export const TeamInviteCard = ({ onAcceptInvite, onDeclineInvite, teamInviteData }: TeamInviteCardProps) => {
  return (
    <>
      <div className="remove-highlight flex flex-row bg-secondaryBtn py-4 pl-4 pr-3">
        <div className="mr-6 flex flex-row">
          {teamInviteData.profilePictureSignedUrl ? (
            <img
              src={teamInviteData.profilePictureSignedUrl || ScanPayLogoPlaceholder}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = ScanPayLogoPlaceholder;
              }}
              alt="..."
              className="h-10 w-10 max-w-none"
            />
          ) : (
            <CustomAvatar
              src={teamInviteData.profilePictureSignedUrl}
              label={getInitials(teamInviteData.teamName)}
              width="w-10"
              height="h-10"
              fontStyle="text-[13px] text-primaryText"
            />
          )}
        </div>

        <div className="flex-8 just mr-2 flex grow flex-col justify-start">
          <div className={'flex flex-row justify-between'}>
            <div className="mr-1.5 text-sbase font-semibold text-tertiaryText">
              {`You have been invited to the team '${teamInviteData.teamName}'.`}
            </div>
          </div>

          <div className="mt-2 text-px13 font-normal text-accentText">
            {DateUtils.getDateInFormat({
              date: new Date(teamInviteData.inviteDate),
              // eslint-disable-next-line quotes
              dateFormat: "MMM dd, yyyy 'at' hh:mm a"
            })}
          </div>

          <div className="mt-2.5 flex text-px13 font-semibold">
            <div
              className="cursor-pointer text-secondary focus:opacity-75"
              onClick={() => onAcceptInvite(teamInviteData)}>
              Accept
            </div>
            <div
              className="ml-5 cursor-pointer text-[#FF7A7A] focus:opacity-75"
              onClick={() => onDeclineInvite(teamInviteData.inviteToken)}>
              Decline
            </div>
          </div>
        </div>
      </div>
      <Divider variant="fullWidth" />
    </>
  );
};
