import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { IGetMerchantProfile, MerchantAddress, MerchantName, TeamDetails } from './myAccount.model';

export interface MyAccountSliceState {
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  merchantAddress: MerchantAddress;
  mailingAddress: MerchantAddress;
  profileImageUrl: string;
  teamDetails: TeamDetails;
  formattedMerchantAddress: string;
  formattedMailingAddress: string;
  isEmailVerified: boolean;
  isEmailAdded: boolean;
}

const initialState: MyAccountSliceState = {
  firstName: '',
  middleName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  merchantAddress: {
    street: '',
    apartment: '',
    city: '',
    state: '',
    country: '',
    zipCode: ''
  },
  mailingAddress: {
    street: '',
    apartment: '',
    city: '',
    state: '',
    country: '',
    zipCode: ''
  },
  profileImageUrl: '',
  teamDetails: {
    merchantTeams: []
  },
  formattedMerchantAddress: '',
  formattedMailingAddress: '',
  isEmailVerified: false,
  isEmailAdded: false
};

export const myAccountSlice = createSlice({
  name: 'myAccount',
  initialState,
  reducers: {
    resetMyAccountSlice: () => initialState,

    initializeMyAcountSliceFromAPI: (state, { payload }: PayloadAction<IGetMerchantProfile>) => {
      state.firstName = payload.firstName;
      state.middleName = payload.middleName;
      state.lastName = payload.lastName;
      state.email = payload.email;
      state.phoneNumber = payload.phoneNumber;
      state.merchantAddress = payload.merchantAddress;
      state.mailingAddress = payload.mailingAddress;
      state.profileImageUrl = payload.profileImageUrl;
      state.teamDetails = payload.teamDetails;
      state.isEmailVerified = payload.isEmailVerified;
      state.isEmailAdded = payload.isEmailAdded;
      state.formattedMerchantAddress = payload.formattedMerchantAddress;
      state.formattedMailingAddress = payload.formattedMailingAddress;
    },

    updateProfileEmail: (state, { payload }: PayloadAction<string>) => {
      state.email = payload;
    },
    updateMerchantName: (state, { payload }: PayloadAction<MerchantName>) => {
      state.firstName = payload.firstName;
      state.middleName = payload.middleName;
      state.lastName = payload.lastName;
    }
  }
});

export const { resetMyAccountSlice, initializeMyAcountSliceFromAPI, updateProfileEmail, updateMerchantName } =
  myAccountSlice.actions;

export const selectMyAccountState = (state: RootState) => state.rootReducer.myAccount;

export default myAccountSlice.reducer;
