import { DownArrowIcon } from 'assets/icons';
import { FC, useState } from 'react';
import { DisputeTimelineData } from '../disputes.model';
import { getDisputeTimelineColor } from '../utils';

interface DisputeTimelineProps {
  disputeStatus: string;
  timelineData: DisputeTimelineData[];
}

const DisputeTimeline: FC<DisputeTimelineProps> = ({ disputeStatus, timelineData }) => {
  const [showAll, setShowAll] = useState<boolean>(false);

  const toggleShowAll = () => {
    setShowAll(prev => !prev);
  };

  return (
    <div className="flex h-full flex-col">
      {timelineData.map((step, index) => {
        if (!showAll && index > 3) {
          return null;
        }
        return (
          <div key={step.heading} className="flex h-full items-start gap-3">
            <div className="flex h-full w-[18px] shrink-0 flex-col items-center">
              <div className="">
                {index === 0 ? (
                  <div
                    className={'h-[18px] w-[18px] rounded-full'}
                    style={getDisputeTimelineColor(disputeStatus)}></div>
                ) : (
                  <div className="h-3.5 w-3.5 rounded-full bg-border"></div>
                )}
              </div>
              {index < timelineData.length - 1 && <div className="h-full w-px bg-borderGray"></div>}
            </div>
            <div className="mb-5 flex flex-col gap-0.5">
              <div className="text-sbase font-semibold text-primaryText">{step.heading}</div>
              {step.subtext.map((text, ind) => (
                <div className="text-sbase font-normal text-accentText" key={ind + text}>
                  {text}
                </div>
              ))}
            </div>
          </div>
        );
      })}
      {timelineData.length > 4 && (
        <div className="ml-7 flex cursor-pointer items-center gap-3 text-secondary" onClick={toggleShowAll}>
          <div className="text-sbase font-semibold">{showAll ? 'Show less' : 'Show all'}</div>
          <DownArrowIcon className={`${showAll ? 'rotate-180' : ''} path-stroke-current path`} />
        </div>
      )}
    </div>
  );
};

export default DisputeTimeline;
