import classNames from 'classnames';
import React from 'react';

interface InvoicePaymentMethodTileProps {
  label: string;
  icon: JSX.Element;
  isDisabled: boolean;
  onClick: () => void;
}

export const InvoicePaymentMethodTile: React.FC<InvoicePaymentMethodTileProps> = ({
  label,
  icon,
  isDisabled,
  onClick
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col items-center gap-3 rounded-md p-4 text-center text-17px font-semibold leading-[22px]',
        { 'bg-highlightGray text-gray-1 pointer-events-none': isDisabled },
        { 'bg-primaryBtn text-white cursor-pointer': !isDisabled }
      )}
      onClick={onClick}>
      {icon}
      {label}
    </div>
  );
};
