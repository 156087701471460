import { FileTypes } from 'types/baseTypes';
import { Receipt } from './receipt.model';

export interface GetReceiptsResponse {
  transactions: Receipt[];
  totalCount: number;
}

export interface GetReceiptsPayload {
  teamId: number;
  limit: number;
  page: number;
  type: FileTypes;
  status: ReceiptStatus[];
  tipAmountNE: number;
  merchantId: string[];
  customerId: string[];
  // TODO change to enum
  paymentMethod: string[];
  start: string;
  end: string;
  search: string;
  sourceType: string[];
  selectedIds?: string[];
}
export interface PostConfirmRefundPayload {
  teamId: string;
  parentID: string;
  amount: string;
}

export interface PostConfirmRefundResponse {
  futurePayouts: string;
  pendingPayouts: string;
  teamBankingBalance: string;
}

export interface PostInitiateRefundPayload {
  teamId: string;
  parentID: string;
  amount: string;
  reason: string;
  refundFlow: boolean;
  autoDebitFlow: boolean;
}

export enum ReceiptStatus {
  CANCELLED = 'CANCELLED',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
  REFUNDED = 'REFUNDED',
  BLOCKED = 'BLOCKED',
  TIMED_OUT = 'TIMED_OUT',
  INITIATED = 'INITIATED',
  PENDING = 'PENDING',
  REFUND_INITIATED = 'REFUND_INITIATED'
}
