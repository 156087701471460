import { CustomButton, GenericOTP, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useCountDownInterval } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getFormattedNumWithCountryCode, isNonUsPhoneNumber } from 'utils/phoneNumberUtils';
import { useRequestOTPMutation } from '../api';
import { RequestOTPPayload, VerifyOTPPayload } from '../auth.model';
import { SignInMethodEnum } from '../constants';
import { SignInMethod } from '../types';

interface DeviceAuthSectionProps {
  selectedSignInMethod: SignInMethod;
  otpError: string;
  handleContinueClick: (payload: VerifyOTPPayload) => void;
  handleBackClick: VoidFn;
  isVerifyOTPAPILoading: boolean;
}

const DeviceAuthSection: FC<DeviceAuthSectionProps> = ({
  selectedSignInMethod,
  otpError,
  handleContinueClick,
  handleBackClick,
  isVerifyOTPAPILoading = false
}) => {
  const [otp, setOtp] = useState(null);
  const [otpInputError, setOtpInputError] = useState('');
  const [resendEnabled, setResendEnabled] = useState(false);
  const [rememberDevice, setRememberDevice] = useState(false);

  const [requestOTPMutation, { isLoading: isLoadingRequestOTPMutation, isSuccess: isRequestOTPSuccess }] =
    useRequestOTPMutation();

  const isEmailVerify = selectedSignInMethod.id === SignInMethodEnum.EMAIL;

  const { seconds, resetTimer } = useCountDownInterval({
    countDownCompletedCallback: () => setResendEnabled(true),
    countDownTime: 30
  });

  const resendOTP = () => {
    const payload: RequestOTPPayload =
      selectedSignInMethod?.id === SignInMethodEnum.EMAIL
        ? { email: selectedSignInMethod.value, authenticateDevice: true }
        : { phone: selectedSignInMethod.value, authenticateDevice: true };
    requestOTPMutation(payload);
  };

  useEffect(() => {
    if (isRequestOTPSuccess) {
      setResendEnabled(false);
      resetTimer();
    }
  }, [isLoadingRequestOTPMutation, isRequestOTPSuccess]);

  useEffect(() => {
    if (otpError) {
      setOtpInputError(otpError);
    }
  }, [otpError]);

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (otp.length === 4) onContinueClick();
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const handleOTPChange = otpString => {
    setOtpInputError('');
    setOtp(otpString);
  };

  const getFormattedNumber = () => {
    if (isNonUsPhoneNumber(selectedSignInMethod?.value)) {
      return selectedSignInMethod.value;
    }
    return getFormattedNumWithCountryCode(selectedSignInMethod?.value, '+1 ');
  };

  const onRememberDevice = () => {
    setRememberDevice(prev => !prev);
  };

  const onContinueClick = () => {
    if (otp) {
      let payload: VerifyOTPPayload = null;
      if (selectedSignInMethod.id === SignInMethodEnum.EMAIL) {
        payload = {
          otp,
          authenticateDevice: rememberDevice,
          skippedAuthentication: !rememberDevice,
          email: selectedSignInMethod.value
        };
      } else {
        payload = {
          otp,
          authenticateDevice: rememberDevice,
          skippedAuthentication: !rememberDevice,
          phone: selectedSignInMethod.value
        };
      }
      handleContinueClick(payload);
    }
  };

  return (
    <div className="my-auto flex max-w-[375px] flex-col self-center">
      <div className="mt-12 text-xl font-bold text-heading lg:text-4xl">
        {`${isEmailVerify ? 'Verify your email' : 'Verify your phone number'}`}
      </div>
      <If condition={isEmailVerify}>
        <div className="my-2 text-17px font-normal text-primaryText">Enter the code we just sent to your email</div>
        <div className="mb-11 flex flex-row items-center gap-2">
          <div className="text-xl font-bold text-primaryText">{selectedSignInMethod.value}</div>
        </div>
      </If>
      <If condition={!isEmailVerify}>
        <div className="mb-8 flex flex-row items-center gap-4">
          <div className="my-3 text-17px font-normal text-primaryText">Enter code sent to</div>
          <div className="text-xl font-bold text-primaryText">{getFormattedNumber()}</div>
        </div>
      </If>
      <div className={`relative flex flex-col gap-10 ${otpInputError ? 'mb-9' : 'mb-2'}`}>
        <GenericOTP
          separator={<span className="w-11"></span>}
          otpInputStyle={`emailOtpStyle !w-[60px] border-none focus:border-none 
            focus:outline-none placeholder:text-accentText placeholder:font-bold placeholder:text-xl
            text-primaryText text-xl font-bold focus-visible:placeholder:text-transparent
            ${otpInputError ? '!border-error !border-[1px] !border-solid focus:border-error' : ''}`}
          onChange={handleOTPChange}
          value={otp}
        />
        <If condition={Boolean(otpInputError)}>
          <div className="absolute top-16 mb-3 text-sbase text-error">{otpInputError}</div>
        </If>
      </div>
      <div className="flex">
        <If condition={resendEnabled}>
          <div className="text-sbase text-primaryText">Trouble receiving the code?</div>
          <div
            onClick={resendOTP}
            className={`ml-3 cursor-pointer text-sbase text-secondary
              underline underline-offset-2`}>
            Resend code
          </div>
        </If>
        <If condition={!resendEnabled}>
          <div className="text-sbase text-primaryText">
            {`You can resend the code in 
                ${seconds < 10 ? `0${seconds}` : seconds} seconds`}
          </div>
        </If>
      </div>
      <div className={'mt-10 mb-3 flex items-center'}>
        <input
          id="remember-device-checkbox"
          type="checkbox"
          onChange={onRememberDevice}
          defaultChecked={rememberDevice}
          className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
        />
        <label
          htmlFor="remember-device-checkbox"
          className="ml-2 cursor-pointer text-sbase font-medium text-primaryText">
          Remember this device for 30 days
        </label>
      </div>

      <div>
        <CustomButton
          id="set-passowrd-modal-save-button"
          onClick={onContinueClick}
          disabled={!otp || otp.length !== 4}
          className={`text-semibold h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}
          isLoading={isVerifyOTPAPILoading}>
          Continue
        </CustomButton>
        <CustomButton
          id="update-email-modal-cancel-button"
          className="mt-6 h-[38px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
          type={ButtonType.SECONDARY}
          onClick={handleBackClick}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default DeviceAuthSection;
