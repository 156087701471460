import { FormEditIcon } from 'assets/icons';
import { CustomTextArea } from 'components';
import { InvoiceTabEnum } from 'containers/invoices/create-invoice/constants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useContext, useState } from 'react';
import {
  InvoiceAttachmentEditPage,
  addInvoiceAttachmentEditPage,
  modifyInvoiceAttachmentEditPage,
  removeInvoiceAttachmentEditPage,
  selectInvoiceAttachmentsEditPage,
  selectInvoiceNotesEditPage,
  selectTNCEditPage,
  updateInvoiceNotesEditPage
} from '../invoicesEditSlice';
import AddAttachmentModal from './AddAttachmentModalEdit';
import LineItemPlusReactIcon from 'assets/react-icons/LineItemPlusReactIcon';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface NotesAttachmentsSectionEditProps {
  className?: string;
  context: React.Context<any>;
  isLoadingTaxesAndFees: boolean;
}

const NotesAttachmentsSectionEdit: FC<NotesAttachmentsSectionEditProps> = ({ context, isLoadingTaxesAndFees }) => {
  const selectedInvoiceAttachments = useAppSelector(selectInvoiceAttachmentsEditPage);
  const selectedInvoiceComment = useAppSelector(selectInvoiceNotesEditPage);
  const selectedInvoiceTNC = useAppSelector(selectTNCEditPage);

  const dispatch = useAppDispatch();
  const { selectedInvoiceTab, setSelectedInvoiceTab } = useContext<any>(context);

  const isTabSelected = selectedInvoiceTab === InvoiceTabEnum.NOTES_ATTACHMENTS;

  const [showAttachmentModal, setShowAttachmentModal] = useState<{
    open: boolean;
    attachment: InvoiceAttachmentEditPage;
    editMode: boolean;
  }>({
    open: false,
    attachment: { id: null, url: null, title: null, imagePreview: null, description: null },
    editMode: false
  });

  const onNotesChange = (value: string) => {
    dispatch(updateInvoiceNotesEditPage(value));
  };

  const onToggleAddAttachmentModal = ({ isEdit = false }: { isEdit: boolean }) => {
    if (!isEdit) {
      setShowAttachmentModal(prevModalState => ({ ...prevModalState, open: !prevModalState.open, editMode: false }));
      return;
    }
    setShowAttachmentModal(prevModalState => ({ ...prevModalState, open: !prevModalState.open }));
  };

  const handleAddPhotoClick = () => {
    updateSelectedInvoiceTab();
    onToggleAddAttachmentModal({ isEdit: false });
  };

  const resetAttachmentModalState = () => {
    setShowAttachmentModal(prevModalState => ({ ...prevModalState, open: false, editMode: false, attachment: null }));
  };

  const onSaveAttachmentClick = (attachment: InvoiceAttachmentEditPage) => {
    dispatch(addInvoiceAttachmentEditPage(attachment));
    resetAttachmentModalState();
  };

  const onModifyAttachmentClick = (attachment: InvoiceAttachmentEditPage) => {
    dispatch(modifyInvoiceAttachmentEditPage(attachment));
    resetAttachmentModalState();
  };

  const onRemoveAttachment = (attachment: InvoiceAttachmentEditPage) => {
    dispatch(removeInvoiceAttachmentEditPage(attachment));
  };

  const onEditAttachment = (attachment: InvoiceAttachmentEditPage) => {
    setShowAttachmentModal({ attachment: attachment, open: true, editMode: true });
  };

  const getAddText = () => {
    const title = selectedInvoiceAttachments?.length > 0 ? 'Add another photo' : 'Add photo';
    return title;
  };

  const updateSelectedInvoiceTab = () => {
    setSelectedInvoiceTab(InvoiceTabEnum.NOTES_ATTACHMENTS);
  };

  return (
    <>
      <div>
        <div className={`mb-2 text-xl font-bold ${isTabSelected ? 'text-primaryBtn' : 'text-heading'}`}>
          Notes & Attachments
        </div>
        <div
          className={`flex flex-col rounded-md  border px-[30px] py-5 1439px:px-10 1439px:pt-9 1439px:pb-10 ${
            isTabSelected ? 'border-primaryBtn' : 'border-borderGray'
          }`}>
          <div className="mb-2 text-17px font-semibold text-heading">Notes</div>
          <CustomTextArea
            resizeTextArea={true}
            id="edit-invoice-notes"
            name="notes"
            maxLength={1000}
            placeholder="Enter notes here"
            value={selectedInvoiceComment}
            onChange={onNotesChange}
            className="mb-4 py-[15px]"
            onFocus={updateSelectedInvoiceTab}
          />

          {!isLoadingTaxesAndFees && selectedInvoiceTNC.tnc && (
            <div className="mt-6 mb-4">
              <div className="mb-4 text-17px font-semibold text-headingGray">Terms & Conditions</div>
              <div
                className=" h-[100px] overflow-y-hidden rounded border border-borderGray 
            pt-2.5 text-17px font-normal text-primaryText">
                <div className="customNormalScroll max-h-[80px] overflow-y-auto whitespace-pre-line break-words px-5">
                  {selectedInvoiceTNC.tnc}
                </div>
              </div>
            </div>
          )}
          <div className="mb-2 text-17px font-semibold text-heading">Attachments</div>

          <div className="mb-5 flex flex-col gap-5">
            {selectedInvoiceAttachments?.map(attachment => {
              return (
                <div
                  onClick={() => onEditAttachment(attachment)}
                  key={attachment.url}
                  className="relative flex min-h-[100px] w-full flex-row rounded border border-borderGray py-4 px-5">
                  <div className="relative mr-4 h-16 w-16 rounded-md">
                    <img
                      className="absolute left-1/2 h-full -translate-x-1/2 rounded-md object-cover"
                      src={attachment.imagePreview}
                    />
                  </div>

                  <div className="mt-[2px] flex w-1/2 flex-col">
                    <div className="mb-[6px] break-words text-17px font-semibold text-heading line-clamp-2">
                      {attachment.title}
                    </div>
                    <div className="whitespace-pre-line text-sbase font-normal text-heading line-clamp-3">
                      {attachment?.description}
                    </div>
                  </div>
                  <FormCloseReactIcon
                    id="edit-invoice-remove-attachment-close-icon"
                    onClick={e => {
                      e.stopPropagation();
                      onRemoveAttachment(attachment);
                    }}
                    className="absolute top-3 right-[-25px] shrink-0 cursor-pointer 1439px:right-[-30px]"
                  />
                  <FormEditIcon
                    id="edit-invoice-attachment-edit-icon"
                    onClick={() => onEditAttachment(attachment)}
                    className="absolute bottom-3 right-[-25px] mt-3 shrink-0 cursor-pointer 1439px:right-[-30px]"
                  />
                </div>
              );
            })}
          </div>

          <div
            onClick={handleAddPhotoClick}
            className="flex w-full cursor-pointer flex-row rounded border border-borderGray py-4 px-5">
            <div className="flex flex-row gap-2">
              <LineItemPlusReactIcon id="edit-invoice-add-photo-icon" className="shrink-0" />
              <div id="edit-invoice-add-photo" className="text-17px font-semibold text-secondary">
                {getAddText()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddAttachmentModal
        onModifyAttachmentClick={onModifyAttachmentClick}
        onSaveAttachmentClick={onSaveAttachmentClick}
        toggleOpen={resetAttachmentModalState}
        open={showAttachmentModal.open}
        isEdit={showAttachmentModal.editMode}
        attachmentData={showAttachmentModal.attachment}
      />
    </>
  );
};

export default NotesAttachmentsSectionEdit;
