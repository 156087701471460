import {
  AttentionNeededPageIcon,
  BlueBubblingTickIcon,
  ChevronArrowRightIcon,
  CrossIconvector,
  GreenFilledTickIcon,
  VerificationPageIcon
} from 'assets/icons';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { SUPPORT_MAIL } from 'config/index';
import { selectCurrentTeam, selectMerchantTeams, setCurrentTeam } from 'containers/app/appSlice';
import { ApplicationStatusPages, EnablePayoutStatuses, InternalBankStatuses } from 'containers/payouts/constants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { replaceURL } from 'utils/commonUtils';
import CollectPendingDocuments from './CollectPendingDocuments';
import { logAnalyticEvent } from 'utils/analytics';
import { PayoutsCleverTapEvents } from 'containers/payouts/events';
import { resetPayoutState } from 'containers/payouts/payoutsSlice';
import { ONBOARDING_URL_PARAMS, ONBOARDING_URL_SOURCES } from 'constants/common';
import { MIGRATION_ROUTE_STATE, PaymentSystemTransitionType } from 'containers/home/constants';

const ApplicationStatusPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const currentTeam = useAppSelector(selectCurrentTeam);
  const merchantTeams = useAppSelector(selectMerchantTeams);

  const [page, setPage] = useState<ApplicationStatusPages>(ApplicationStatusPages.APPLICATION_STATUS);

  const isFromHome = searchParams.get(ONBOARDING_URL_PARAMS.FROM) === ONBOARDING_URL_SOURCES.HOME;

  useEffect(() => {
    const teamIdVal = searchParams.get('teamId');
    if (teamIdVal && currentTeam?.id !== +teamIdVal) {
      const merchantTeam = merchantTeams.find(team => team.id === +teamIdVal);
      if (merchantTeam) {
        dispatch(setCurrentTeam(merchantTeam));
        dispatch(resetPayoutState());
      } else {
        navigate(routesPath.PAYOUTS_SETTINGS, { replace: true });
      }
    }
  }, [searchParams]);

  const applicationPageEnabledStatuses = [
    EnablePayoutStatuses.SUBMITTED_IN_REVIEW,
    EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED,
    EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED,
    EnablePayoutStatuses.REJECTED,
    InternalBankStatuses.FINANCIAL_DATA_SUBMITTED
  ];

  useEffect(() => {
    const showApplicationStatus =
      applicationPageEnabledStatuses.includes(currentTeam?.connectApplicationStatus as EnablePayoutStatuses) ||
      applicationPageEnabledStatuses.includes(currentTeam?.internalBankingStatus);
    if (!showApplicationStatus) {
      if (isFromHome) {
        navigate(routesPath.HOME, { replace: true });
      } else {
        navigate(routesPath.PAYOUTS_SETTINGS, { replace: true });
      }
    }
  }, [currentTeam?.connectApplicationStatus]);

  const handleEmailClick = () => {
    replaceURL(`mailto:${SUPPORT_MAIL}`);
  };

  const handlePendingDocsCollection = () => {
    setPage(ApplicationStatusPages.STRIPE_PENDING_DOC);
    logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsAddPendingDocsClick, {
      connectApplicationStatus: currentTeam?.connectApplicationStatus
    });
  };

  const handleExitFromPendingDoc = () => {
    const migrationRouteState: MIGRATION_ROUTE_STATE =
      currentTeam.paymentSystemTransitionType === PaymentSystemTransitionType.LIPConnectMigration
        ? { openConnectBankModal: true }
        : {};
    if (isFromHome) {
      navigate(routesPath.HOME, { state: migrationRouteState });
    } else {
      navigate(routesPath.PAYOUTS_SETTINGS, { state: migrationRouteState });
    }
  };

  const getCardIconAndText = status => {
    switch (status) {
      case EnablePayoutStatuses.SUBMITTED_IN_REVIEW:
      case InternalBankStatuses.FINANCIAL_DATA_SUBMITTED:
        return {
          Icon: VerificationPageIcon,
          text: 'Verification in progress',
          subtext:
            'Your documents have been received. We will review your details and notify you within 24 working hours.',
          buttonText: 'Done',
          clickHandler: handleExitFromPendingDoc
        };
      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED:
      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED:
        return {
          Icon: AttentionNeededPageIcon,
          text: 'Attention Needed: Additional data required to complete setup',
          subtext: 'We need just a few more data to complete verification.',
          buttonText: 'Get Started',
          clickHandler: handlePendingDocsCollection
        };
      case EnablePayoutStatuses.REJECTED:
        return {
          Icon: VerificationPageIcon,
          text: 'Application denied',
          subtext: `Our risk and compliance has denied your payments application. 
          Please note any payments already collected will be refunded back to your 
          customers within 7-10 business days.`,
          buttonText: 'Done',
          clickHandler: handleExitFromPendingDoc
        };
    }
  };

  const { Icon, buttonText, subtext, text, clickHandler } = location?.state?.isBank
    ? getCardIconAndText(currentTeam?.internalBankingStatus)
    : getCardIconAndText(currentTeam?.connectApplicationStatus) ?? {};

  return (
    <div className="flex h-full w-full">
      <div className="bg-[#F5F8FB] px-[22px] pt-24 lg:px-[75px]">
        <div className="relative hidden sm:flex sm:flex-col">
          <div className="flex items-center gap-5 pl-2">
            <GreenFilledTickIcon
              className="circle-fill-current circle shrink-0 text-secondary"
              width={24}
              height={24}
            />
            <div className="truncate text-px13 uppercase text-primaryText">Application Submitted</div>
          </div>
          <div className="z-10 ml-[19px] h-[50px] w-0.5 bg-border"></div>
          <div className="absolute -bottom-8 flex items-center gap-3">
            <BlueBubblingTickIcon className="shrink-0" />
            <div className="text-px13 font-bold uppercase text-tertiaryText">Verification</div>
          </div>
        </div>
      </div>
      <div className="relative grow">
        <div className="absolute right-4 top-4 cursor-pointer" onClick={handleExitFromPendingDoc}>
          <CrossIconvector />
          <div className={'text-sm text-accentText'}>Exit</div>
        </div>
        <div className="flex h-full w-full p-2 px-20 sm:pt-24 lg:w-3/4 lg:pl-36">
          {page === ApplicationStatusPages.APPLICATION_STATUS && (
            <div className="space-y-7">
              {Icon && <Icon className="shrink-0" />}
              <div className="text-3xl font-bold text-heading">{text}</div>
              <div className="text-sbase text-primaryText">{subtext}</div>
              <div className="ext-sbase text-primaryText">
                For further queries, please contact us at{' '}
                <span className="cursor-pointer text-secondary" onClick={handleEmailClick}>
                  support@goscanpay.com
                </span>{' '}
              </div>
              <div className="flex items-center justify-between gap-5">
                <CustomButton
                  id="application-status-back-button"
                  type={ButtonType.SECONDARY}
                  StartIcon={ChevronArrowRightIcon}
                  startIconStyle="rotate-180"
                  onClick={handleExitFromPendingDoc}
                  className="h-10">
                  Back
                </CustomButton>
                <CustomButton
                  id="application-status-continue-button"
                  type={ButtonType.PRIMARY}
                  onClick={clickHandler}
                  className="h-10">
                  {buttonText}
                </CustomButton>
              </div>
            </div>
          )}
          {page === ApplicationStatusPages.STRIPE_PENDING_DOC && (
            <div className="h-full grow">
              <CollectPendingDocuments handleExit={handleExitFromPendingDoc} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplicationStatusPage;
