import { UserData } from './digitalOnboarding.model';

export const constructBasicDetails = ({
  userData,
  submitRoute,
  teamId
}: {
  userData: UserData;
  submitRoute?: string;
  teamId: string;
}) => {
  const data = {
    profession: userData.profession,
    businessName: userData.businessName,
    firstName: userData.firstName,
    middleName: userData.middleName,
    lastName: userData.lastName,
    email: userData.email,
    numberOfEmployees: userData.numberOfEmployees,
    website: userData.website,
    phone: userData.phone,
    yelp: userData.yelp,
    facebook: userData.facebook,
    otherOnlineReferrence: userData.otherOnlineReferrence,
    referralSource: userData.referralSource,
    teamId,
    submitRoute: submitRoute ?? ''
  };
  return data;
};
