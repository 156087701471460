import EditInvoice from 'containers/invoices/edit-invoice/EditInvoice';
import React, { FC, useEffect, useState } from 'react';
import { If } from 'components';
import { useLazyGetTeamProfileDataDataQuery } from 'containers/team-profile/api';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import BuyersPreviewEdit from 'containers/invoices/buyers-preview-edit/BuyersPreviewEdit';
import { useGetTaxesAndFeesQuery } from 'containers/invoices/api';

enum EditPageState {
  EDIT,
  PREVIEW
}

const EditInvoicePage: FC = () => {
  const [editPageState, setEditPageState] = useState<EditPageState>(EditPageState.EDIT);

  const currentTeam = useAppSelector(selectCurrentTeam);

  const [getTeamProfileDataDataQuery, getTeamProfileDataDataQueryState] = useLazyGetTeamProfileDataDataQuery();

  useEffect(() => {
    if (currentTeam?.id) {
      getTeamProfileDataDataQuery({ teamId: currentTeam?.id.toString() });
    }
  }, [currentTeam]);

  const {
    data: getTeamProfileData,
    isSuccess: getTeamDataSuccess,
    isFetching: isFetchingTeamProfileData
  } = getTeamProfileDataDataQueryState;

  const { data: taxesAndFeesData, isLoading: isLoadingTaxesAndFees } = useGetTaxesAndFeesQuery({
    teamId: currentTeam?.id
  });

  return (
    <>
      <If condition={editPageState === EditPageState.EDIT}>
        <EditInvoice
          goToPreviewPage={() => setEditPageState(EditPageState.PREVIEW)}
          taxesAndFeesData={taxesAndFeesData}
          isLoadingTaxesAndFees={isLoadingTaxesAndFees}
        />
      </If>
      <If condition={editPageState === EditPageState.PREVIEW}>
        <BuyersPreviewEdit
          getTeamProfileData={getTeamProfileData}
          goBack={() => {
            setEditPageState(EditPageState.EDIT);
          }}
        />
      </If>
    </>
  );
};

export default EditInvoicePage;
