import { LoadingSpinnerAnimation } from 'assets/animations';
import { ArrowRightIcon, EditIcon, InvoicesTabIcon, NavCloseIcon, WhiteInfoIcon } from 'assets/icons';
import { GenericError, If } from 'components';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamCustomersPermissions,
  selectCurrentTeamInvoicePermissions,
  selectUserDetails,
  showNotifier
} from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import Lottie from 'lottie-react';
import { FC, useEffect, useState } from 'react';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { useLazyGetCustomerByIdQuery } from '../api';
import { CustomerDetails } from '../invoices.model';
import InvoiceCard from './InvoiceCard';
import { useLocation, useNavigate, useOutletContext } from 'react-router-dom';
import CreateCustomer from 'containers/create-customer/CreateCustomer';
import { CreateCustomerModalTypes } from 'constants/CustomerConstants';
import { ICustomer } from 'containers/customers/customers.model';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { twMerge } from 'tailwind-merge';
import classnames from 'classnames';
import { logAnalyticEvent } from 'utils/analytics';
import { NotifierTypes } from 'constants/notifierConstants';
import { InfoMesssages } from 'types/infoMessages';
import { useParams } from 'react-router-dom';
import SidePanel from 'components/side-panel/SidePanel';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE, FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import NoPermissions from 'components/no-permissions/NoPermissions';
import { resetInvoiceState } from '../InvoicesSlice';

interface CustomerPanelProps {
  customerData?: CustomerDetails;
  onClose?: VoidFn;
  onInvoiceClick?: (invoiceId: string) => void;
  openEditModal?: () => void;
  onCustomerEditSuccess?: (customer: ICustomer) => void;
  routeToNextPage?: ({ type, id }: { type: SidePanelTypes; id: string }) => void;
}

const CustomerPanel: FC<CustomerPanelProps> = props => {
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const userDetails = useAppSelector(selectUserDetails);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { onCustomerEditSuccess = null } = props;
  const [getCustomerByIdQuery, getCustomerByIdState] = useLazyGetCustomerByIdQuery();
  const { data: customerData, isSuccess: customerDataSuccess, isFetching: fetchingCustomerData } = getCustomerByIdState;
  const { customer, invoices, invoiceSummary, totalInvoices } = customerData ?? {};
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(getNotifications);

  const { customerId, rootInvoiceId, rootReceiptId, rootPage, rootCustomerId, rootDisputeId } = useParams();
  const location = useLocation();
  const { handleSidePanelClose, resetCustomersQuery } = useOutletContext<any>();

  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useState(false);
  const [showBackArrow, setShowBackArrow] = useState(false);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);

  useEffect(() => {
    if (customerId || rootCustomerId) {
      getCustomerByIdQuery({ customerId: customerId ? customerId : rootCustomerId, teamId: currentTeam?.id });
      logAnalyticEvent(clevertapEvents.webCustomersListDetails);
    }
  }, [customerId, currentTeam?.id, rootCustomerId]);

  useEffect(() => {
    let routeStateTimeout;
    const routeState = location.state as SidePanelRouteState;
    if (routeState?.fromSidePanel) {
      setShowBackArrow(true);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const isSelfCreatedCustomer = customerData?.customer?.merchantId === userDetails?.id;

  useEffect(() => {
    if (!customerPermissions.viewCustomersCreatedByTeam) {
      setHasPermissionsToView(false);
    }
  }, [customerPermissions.viewCustomersCreatedByTeam]);

  const closeCreateCustomerModal = () => {
    setOpenCreateCustomerModal(false);
  };

  const createOrEditCustomerCallBack = (customerProp: ICustomer) => {
    setOpenCreateCustomerModal(false);
    getCustomerByIdQuery({ customerId: customerProp.ID, teamId: currentTeam?.id });
    if (onCustomerEditSuccess) onCustomerEditSuccess(customerProp);
    if (resetCustomersQuery) {
      resetCustomersQuery();
    }
  };

  const navigate = useNavigate();
  const getAddresswithZip = () => {
    if (customer.cityStateCountry || customer.street) {
      return `${customer.street} ${customer.cityStateCountry} ${customer.zipCode ? `- ${customer.zipCode}` : ''}`;
    }
    if (customer.billingAddress) {
      return `${customer.billingAddress}`;
    }
    if (customer.zipCode) {
      return `${customer.zipCode}`;
    }
    return '-';
  };

  const personalInfo = customerDataSuccess
    ? [
        {
          id: 'customer-side-panel-phone',
          title: 'Phone',
          data: customer.phoneNumber || '-'
        },
        {
          id: 'customer-side-panel-email',
          title: 'Email',
          data: customer.email || '-'
        },
        {
          id: 'customer-side-panel-billing-address',
          title: 'Billing Address',
          data: getAddresswithZip()
        }
      ]
    : [];

  const summary = customerDataSuccess
    ? [
        {
          id: 'customer-side-panel-summary-first-payment',
          title: 'First Payment',
          data: invoiceSummary.firstPaymentDate || '-'
        },
        {
          id: 'customer-side-panel-summary-last-payment',
          title: 'Last Payment',
          data: invoiceSummary.lastPaymetDate || '-'
        },
        {
          id: 'customer-side-panel-summary-paid-invoices-amount',
          title: `Paid Invoices ${invoiceSummary.paidInvoicesCount ? `(${invoiceSummary.paidInvoicesCount})` : ''}`,
          data: getAmountWithCurrency(invoiceSummary.amountCollected)
        },
        {
          id: 'customer-side-panel-summary-pending-invoices-amount',
          title: `Pending Invoices ${
            invoiceSummary.pendingInvoicesCount ? `(${invoiceSummary.pendingInvoicesCount})` : ''
          }`,
          data: getAmountWithCurrency(invoiceSummary.pendingAmount)
        }
      ]
    : [];

  const isEditCustomerAllowed = () => {
    if (customerPermissions.editCustomersCreatedByTeam) {
      return true;
    }
    if (customerPermissions.editExistingCustomers && isSelfCreatedCustomer) {
      return true;
    }
    return false;
  };

  const bottomBar = [
    {
      id: 'customer-sidepanel-bottom-create-invoice-button',
      icon: (
        <div className="path-stroke-current path">
          <InvoicesTabIcon className="h-5" />
        </div>
      ),
      btnText: 'Create Invoice',
      disabledReason: InfoMesssages.createInvoiceNoPermissionMessage,
      isDisabled: !invoicePermissions.createInvoice || !customerPermissions.createNewCustomers,
      onClickHandler: () => {
        logAnalyticEvent(clevertapEvents.webCustomersListDetailsCreateInvoice, {
          'Customer Id': customerData.customer.ID
        });
        if (invoicePermissions.createInvoice && customerPermissions.createNewCustomers) {
          dispatch(resetInvoiceState());
          navigate('/invoices/create?customerId=' + customerData.customer.ID);
        }
      }
    },
    {
      id: 'customer-sidepanel-bottom-edit-button',
      icon: (
        <div>
          <EditIcon className="h-5" />
        </div>
      ),
      onClickHandler: () => {
        logAnalyticEvent(clevertapEvents.webCustomersListDetailsEdit, { 'Customer Id': customerData.customer.ID });
        setOpenCreateCustomerModal(true);
      },
      btnText: 'Edit',
      disabledReason: InfoMesssages.editCustomerNoPermissionMessage,
      isDisabled: !isEditCustomerAllowed()
    }
  ];

  const onInfoClick = (id, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handleInvoiceClick = invoice => {
    logAnalyticEvent(clevertapEvents.webCustomersListDetailsInvoiceLink, {
      'Customer Id': customerData.customer.ID,
      'Invoice Id': invoice.invoiceId
    });
    if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/invoice/${invoice.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/invoice/${invoice.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/invoice/${invoice.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/invoice/${invoice.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/invoice/${invoice.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const onSidePanelClose = () => {
    if (showBackArrow) {
      navigate(-1);
    } else {
      handleSidePanelClose();
    }
  };

  const getCustomerName = () => {
    if (customer.name || customer.lastName) {
      return `${customer.name} ${customer.lastName}`;
    } else if (customer.phoneNumber) {
      return customer.phoneNumber;
    }
    return '-';
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="flex h-full w-96 flex-col">
        {fetchingCustomerData ? (
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          customerDataSuccess &&
          !fetchingCustomerData && (
            <>
              <div className="flex w-full items-center gap-2 p-5 pl-3">
                <div className="cursor-pointer" onClick={onSidePanelClose}>
                  {showBackArrow ? (
                    <ArrowRightIcon className="rotate-180" id="customer-panel-back-arrow" />
                  ) : (
                    <NavCloseIcon id="customer-panel-close" />
                  )}
                </div>
                <div id="customer-panel-head" className="text-17px font-semibold text-headingGray">
                  {getCustomerName()}
                </div>
              </div>
              {hasPermissionsToView ? (
                <>
                  <div className="customNormalScroll flex grow flex-col gap-5 overflow-y-auto">
                    <div className="mx-4 rounded-md border border-dark-gray p-4">
                      <div className="flex items-center justify-between">
                        <div className="text-17px font-bold text-primaryText">Personal Information</div>
                        <div
                          id="customer-details-edit-customer-button"
                          className={twMerge(
                            `
                      flex cursor-pointer items-center gap-3 text-sbase font-semibold text-secondary
                      `,
                            classnames({ 'opacity-50': !isEditCustomerAllowed() })
                          )}
                          onClick={() => {
                            if (isEditCustomerAllowed()) {
                              logAnalyticEvent(clevertapEvents.webCustomersListDetailsPersonalInformationEdit, {
                                'Customer Id': customerData.customer.ID
                              });
                              setOpenCreateCustomerModal(true);
                            } else {
                              onInfoClick(
                                'customer-details-edit-customer-button',
                                InfoMesssages.editCustomerNoPermissionMessage
                              );
                            }
                          }}>
                          <EditIcon className="path-fill-current path" />
                          Edit
                        </div>
                      </div>
                      <div className="mt-6 flex flex-col gap-5 text-sbase leading-4">
                        {personalInfo.map((info, index) => (
                          <div key={info.title + index} className="flex flex-col gap-1.5">
                            <div className="text-accentText">{info.title}</div>
                            <div id={info.id} className="break-words font-semibold text-primaryText">
                              {info.data}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <div className="mx-4 rounded-md border border-dark-gray p-4">
                      <div
                        className="border-b border-borderGray pb-2 text-sbase
                      font-semibold leading-4 text-primaryText">
                        Summary
                      </div>
                      <div className="grid grid-cols-2 gap-6 pt-5 pb-2.5 font-semibold">
                        {summary.map((info, index) => (
                          <div key={info.title + index} className="flex flex-col gap-1.5">
                            <div className="text-px13 text-accentText">{info.title}</div>
                            <div id={info.id} className="truncate text-sbase text-headingGray">
                              {info.data}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                    <If condition={Boolean(totalInvoices)}>
                      <div className="mx-4 rounded-md border border-dark-gray p-4">
                        <div className="text-sbase font-semibold text-primaryText">Invoices {`(${totalInvoices})`}</div>
                        <div>
                          {invoices?.map(invoice => (
                            <div
                              id="customer-panel-invoice-details-card"
                              key={invoice.invoiceId}
                              className="border-b border-[#F2F2F2] py-5 last:border-b-0 only:border-b-0">
                              <InvoiceCard
                                data={invoice}
                                onInvoiceClick={() => {
                                  handleInvoiceClick(invoice);
                                }}
                              />
                            </div>
                          ))}
                        </div>
                      </div>
                    </If>
                  </div>
                  <div className="mt-auto flex justify-between gap-px pt-2">
                    {bottomBar?.map((item, index) => (
                      <div
                        id={`customer-bottom-bar-${item.btnText}-button`}
                        key={item.btnText + index}
                        onClick={() => {
                          if (item.isDisabled) {
                            onInfoClick(item.id, item.disabledReason);
                          } else {
                            item.onClickHandler();
                          }
                        }}
                        className={`flex w-1/2 shrink grow cursor-pointer items-center justify-center gap-2
                  bg-secondary py-3.5 font-semibold text-primary hover:opacity-95
                  ${item.isDisabled ? 'bg-[#9BBADD] hover:opacity-100' : ''}
                `}>
                        <div className={`${item.isDisabled && 'opacity-50'}`}>{item.icon}</div>
                        <div className={`text-sbase ${item.isDisabled && 'opacity-50'}`}>{item.btnText}</div>
                        {item.isDisabled && <WhiteInfoIcon />}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <NoPermissions />
              )}
              <If condition={Boolean(openCreateCustomerModal)}>
                <CreateCustomer
                  open={openCreateCustomerModal}
                  onClose={closeCreateCustomerModal}
                  panelType={CreateCustomerModalTypes.EDIT_CUSTOMER}
                  customerData={customerData.customer}
                  customerDataFetchSuccess={true}
                  customerDataFetchLoading={false}
                  createOrEditCustomerCallBack={createOrEditCustomerCallBack}
                />
              </If>
            </>
          )
        )}
        {!fetchingCustomerData && !customerDataSuccess && hasPermissionsToView && (
          <GenericError handleErrorBack={onSidePanelClose} />
        )}
      </div>
    </SidePanel>
  );
};

export default CustomerPanel;
