import React, { useEffect } from 'react';
import { ContactIcon, NoteIcon, ProcessingYellowIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getUserData } from '../digitalOnboardingSlice';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from 'utils/firebase';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { useLazyGetUserDetailsQuery } from 'containers/app/api';
import { VerificationStatusEnum } from 'containers/app/app.model';
import { setHasPendingEmailSteps, setIsAuthenticated, setIsPasswordExpired } from 'containers/auth/authSlice';

const WaitingListPage = () => {
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const teamId = currentTeam.id?.toString();
  const dispatch = useAppDispatch();
  const { waitingListRank, email, phone } = userData;
  const [getUserDetailsQuery, { data: userDetails, isSuccess: isInitAPISuccess, isFetching }] =
    useLazyGetUserDetailsQuery();
  const { verificationStatus } = userDetails ?? {};

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'Teams', teamId), document => {
      if (document) {
        if (document.data()?.OnboardingStatus === 'APPROVED') getUserDetailsQuery({});
        else if (document.data()?.OnboardingStatus === 'DECLINED') navigate(routesPath.DECLINED);
      }
    });

    return unsubscribe;
  }, []);

  useEffect(() => {
    if (isInitAPISuccess && verificationStatus === VerificationStatusEnum.Verified) {
      dispatch(
        setIsAuthenticated({
          isAuthenticated: true,
          writeToLocalStorage: true
        })
      );
      dispatch(setIsPasswordExpired(false));
      dispatch(setHasPendingEmailSteps(false));
      navigate(routesPath.HOME);
    }
  }, [isInitAPISuccess, verificationStatus]);

  return (
    <div
      className="mx-auto flex max-w-[375px] grow flex-col items-center justify-center
                       gap-5 px-2.5 text-center sm:w-[375px]">
      <ProcessingYellowIcon className="shrink-0" />
      <div className="flex flex-col gap-3">
        <div className="text-xl font-bold leading-6 text-headingGray">You are {waitingListRank} on the list</div>
        <div className="text-center text-sbase leading-[18px] text-primaryText">
          We will review your application in the next 4-5 business days.
        </div>
      </div>
      <div
        className="flex w-full items-center gap-[18px]
                   rounded-md border border-highlightGray px-4 py-[18px]">
        <ContactIcon className="shrink-0" />
        <div className="text-left text-sbase leading-[18px] text-primaryText">
          We will contact you on
          <span className="font-bold"> {email}</span> or
          <span className="font-bold">{phone}</span> for more details or next steps
        </div>
      </div>
      <div className="flex items-center gap-6">
        <NoteIcon className="shrink-0" />
        <div className="text-left text-sbase leading-[18px] text-primaryText">
          Please note, due to high demand, we may be unable to accommodate every application.
        </div>
      </div>
      <div className="mt-28 flex flex-col gap-3 text-primaryText">
        <div className="text-17px font-semibold">Thank You</div>
        <div className="text-[13px] leading-[17px]">
          Thank you for your patience as we strive to give the best payments experience to every Home Service business
          owner.
        </div>
      </div>
    </div>
  );
};

export default WaitingListPage;
