import { FormCloseIcon } from 'assets/icons';
import { CustomButton, CustomInput, CustomModal, If } from 'components';
import { ButtonType, FormButtonType } from 'components/custom-button/CustomButton';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency, getPercentageAmount, reverseCalculatePercentage } from 'utils/amountUtils';
import { REGEX } from 'constants/common';
import {
  DiscountEntity,
  DiscountEnum,
  DiscountFormData,
  DiscountInputErrors,
  InvoiceDiscountInputId
} from 'containers/invoices/types';

interface AddInvoiceDiscountModalProps {
  className?: string;
  config: IAddInvoiceDiscountModalConfig;
  onClose: VoidFn;
  onSaveDiscountClick: (discountData: DiscountFormData) => void;
  onModifyDiscountClick: (discountData: DiscountFormData) => void;
  lineItemsTotalCost: number;
}

const discountEntityInitState: DiscountEntity = {
  type: DiscountEnum.CURRENCY,
  value: '',
  amount: '',
  percentage: '',
  id: InvoiceDiscountInputId.invoiceDiscountAmount
};

export interface IAddInvoiceDiscountModalData {
  percent: string;
  amount: string;
  isDiscountAmountSelected: boolean;
}

export const discountModalDataInit: IAddInvoiceDiscountModalData = {
  percent: '',
  amount: '',
  isDiscountAmountSelected: false
};

export const addInvoiceDiscountModalConfigInitState: IAddInvoiceDiscountModalConfig = {
  title: 'Add a discount',
  open: false,
  shouldInitializeFields: false,
  data: discountModalDataInit,
  editMode: false
};

export interface IAddInvoiceDiscountModalConfig {
  title: string;
  open: boolean;
  shouldInitializeFields: boolean;
  data: IAddInvoiceDiscountModalData;
  editMode: boolean;
}

const AddInvoiceDiscountModal: FC<AddInvoiceDiscountModalProps> = ({
  lineItemsTotalCost,
  onSaveDiscountClick,
  config,
  onClose
}) => {
  const [discountEntity, setDiscountEntity] = useState<DiscountEntity>(discountEntityInitState);
  const [shouldFocusInput, setShouldFocusInput] = useState<boolean>(false);

  const errorDefaultState = {
    amountError: null
  };
  const [errors, setErrors] = useState<Record<string, DiscountInputErrors>>(errorDefaultState);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const isPercentageDiscountSelected = discountEntity.type === DiscountEnum.PERCENTAGE;
  const isCurrencyDiscountSelected = discountEntity.type === DiscountEnum.CURRENCY;

  useEffect(() => {
    if (config.shouldInitializeFields) {
      if (config?.data?.isDiscountAmountSelected) {
        setDiscountEntity({
          type: DiscountEnum.CURRENCY,
          id: InvoiceDiscountInputId.invoiceDiscountAmount,
          amount: config?.data?.amount,
          percentage: '',
          value: config?.data?.amount
        });
      } else {
        setDiscountEntity({
          type: DiscountEnum.PERCENTAGE,
          id: InvoiceDiscountInputId.invoiceDiscountPercentage,
          amount: '',
          percentage: config?.data?.percent,
          value: config?.data?.percent
        });
      }
    }
  }, []);

  useEffect(() => {
    if (discountEntity.id && shouldFocusInput) {
      const discountInputElement = document.getElementById(discountEntity.id);
      if (discountInputElement) {
        discountInputElement.focus();
        setShouldFocusInput(false);
      }
    }
  }, [discountEntity.id]);

  const findDiscountFinalCurrencyValue = ({
    currentDiscountEntity,
    totalCostOfLineItems
  }: {
    currentDiscountEntity: DiscountEntity;
    totalCostOfLineItems: number;
  }): number => {
    if (currentDiscountEntity.type === DiscountEnum.CURRENCY) {
      return +currentDiscountEntity.amount;
    }

    return (+currentDiscountEntity.percentage * totalCostOfLineItems) / 100;
  };

  const calculateSubTotal = () => {
    if (REGEX.decimalPoint.test(discountEntity.value) || discountEntity === discountEntityInitState) {
      return getAmountWithCurrency(lineItemsTotalCost);
    }
    const discountFinalValue = findDiscountFinalCurrencyValue({
      currentDiscountEntity: discountEntity,
      totalCostOfLineItems: lineItemsTotalCost
    });

    const subtotal = lineItemsTotalCost - discountFinalValue;
    return getAmountWithCurrency(subtotal);
  };

  const onCancelClick = () => {
    onClose();
  };

  const disableSaveBtn =
    (discountEntity.type === DiscountEnum.CURRENCY &&
      (!discountEntity.amount || REGEX.decimalPoint.test(discountEntity.amount))) ||
    (discountEntity.type === DiscountEnum.PERCENTAGE &&
      (!discountEntity.percentage || REGEX.decimalPoint.test(discountEntity.percentage)));

  const onChangeDiscountType = (type: DiscountEnum): void => {
    setShouldFocusInput(true);
    if (discountEntity.type === type) {
      return;
    }
    setErrors({ ...errors, amountError: null });
    if (type === DiscountEnum.CURRENCY) {
      if (+discountEntity.amount <= 0 && isEdited)
        setErrors({ ...errors, amountError: DiscountInputErrors.Amount_Error });
      const currencyDiscount = {
        ...discountEntity,
        type: DiscountEnum.CURRENCY,
        amount: discountEntity.amount,
        value: discountEntity.amount,
        id: InvoiceDiscountInputId.invoiceDiscountAmount
      };
      setDiscountEntity(currencyDiscount);
    } else {
      if (+discountEntity.percentage <= 0 && isEdited)
        setErrors({ ...errors, amountError: DiscountInputErrors.Percentage_Error });
      const percentageDiscount = {
        ...discountEntity,
        type: DiscountEnum.PERCENTAGE,
        percentage: discountEntity.percentage,
        value: discountEntity.percentage,
        id: InvoiceDiscountInputId.invoiceDiscountPercentage
      };
      setDiscountEntity(percentageDiscount);
    }
  };

  const handleAmountChange = (value: string, type: DiscountEnum): void => {
    if (!isEdited) {
      setIsEdited(true);
    }
    setErrors({ ...errors, amountError: null });
    if (type === DiscountEnum.CURRENCY) {
      const currencyValueWithoutSymbol = value.replace(REGEX.nonNumberRegExp, '');
      if (
        !REGEX.maximumTwoDecimals.test(currencyValueWithoutSymbol) ||
        +currencyValueWithoutSymbol > lineItemsTotalCost
      ) {
        return;
      }
      if (currencyValueWithoutSymbol.length === 0) {
        setErrors({ ...errors, amountError: DiscountInputErrors.Amount_Error });
      }
      setDiscountEntity(prev => ({ ...prev, value: currencyValueWithoutSymbol, amount: currencyValueWithoutSymbol }));
    }

    if (type === DiscountEnum.PERCENTAGE) {
      if (!REGEX.percentage.test(value)) {
        return;
      }
      if (value.length === 0) {
        setErrors({ ...errors, amountError: DiscountInputErrors.Percentage_Error });
      }
      setDiscountEntity(prev => ({ ...prev, value: value, percentage: value }));
    }
  };

  const handleSaveDiscount = (): void => {
    let discountData: DiscountFormData;
    if (isCurrencyDiscountSelected) {
      const finalDiscountInCurrency = lineItemsTotalCost - +discountEntity.amount;
      const reverseCalculatedDiscountPercentage = reverseCalculatePercentage({
        value: +discountEntity.amount,
        total: lineItemsTotalCost
      });
      discountData = {
        discountPercent: reverseCalculatedDiscountPercentage.toString(),
        discountAmount: discountEntity.amount,
        isDiscountAmountSelected: true,
        discountInCurrency: discountEntity.amount,
        amountAfterDiscount: finalDiscountInCurrency.toString()
      };
    } else {
      const finalDiscountInCurrency = getPercentageAmount(lineItemsTotalCost, discountEntity.percentage);
      const amountAfterDiscount = lineItemsTotalCost - finalDiscountInCurrency;
      discountData = {
        discountPercent: discountEntity.percentage,
        discountAmount: '',
        isDiscountAmountSelected: false,
        discountInCurrency: finalDiscountInCurrency.toString(),
        amountAfterDiscount: amountAfterDiscount.toString()
      };
    }
    onSaveDiscountClick(discountData);
  };

  return (
    <div>
      <CustomModal width="md:w-[610px]" height="min-h-[237px]" open={config.open} closeModal={onClose}>
        <div className="relative flex w-full flex-col py-8 px-10">
          <FormCloseIcon
            id="create-invoice-add-discount-close"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onCancelClick}
          />
          <div className="mb-[26px] text-xl font-bold text-heading">{config.title}</div>

          {/* Discount box */}
          <div className="mb-10 flex w-full flex-col">
            <div className="flex w-full flex-row">
              <div
                className="flex w-[100px] shrink-0 flex-row items-center rounded-md 
            text-sbase font-semibold text-primaryText">
                <div
                  onClick={() => onChangeDiscountType(DiscountEnum.CURRENCY)}
                  className={`${
                    isCurrencyDiscountSelected ? 'bg-[#A4D7FA]' : 'border border-borderGray bg-primary'
                  } flex h-full w-1/2 cursor-pointer items-center justify-center rounded-l-md`}>
                  <div className="">$</div>
                </div>

                <div
                  onClick={() => onChangeDiscountType(DiscountEnum.PERCENTAGE)}
                  className={`${
                    isPercentageDiscountSelected ? 'bg-[#A4D7FA]' : 'border border-borderGray bg-primary'
                  } flex h-full w-1/2 cursor-pointer items-center justify-center rounded-r-md`}>
                  <div className="">%</div>
                </div>
              </div>
              <If condition={isCurrencyDiscountSelected}>
                <CustomInput
                  value={discountEntity.value ? `$ ${discountEntity.value}` : ''}
                  onChange={value => handleAmountChange(value, DiscountEnum.CURRENCY)}
                  id={InvoiceDiscountInputId.invoiceDiscountAmount}
                  placeholder="$ 0.00"
                  className="ml-5 text-sbase font-normal placeholder:text-accentText"
                  inputStyle="w-full"
                />
              </If>

              <If condition={isPercentageDiscountSelected}>
                <CustomInput
                  value={discountEntity.value}
                  onChange={value => handleAmountChange(value, DiscountEnum.PERCENTAGE)}
                  id={InvoiceDiscountInputId.invoiceDiscountPercentage}
                  placeholder="0.00%"
                  className="ml-5 text-sbase font-normal placeholder:text-accentText"
                  inputStyle="w-full"
                  hasSuffix={true}
                  suffix="%"
                  suffixStyle="text-heading text-sbase mr-4"
                />
              </If>
            </div>
            {errors.amountError && <div className="mt-2 text-sbase text-error">{errors.amountError}</div>}
          </div>

          <div className="flex flex-row items-center justify-between">
            <div className="text-sbase font-semibold text-primaryText">{`Total amount ${calculateSubTotal()}`}</div>
            <div className="flex flex-row justify-end gap-5">
              <CustomButton
                id="create-invoice-add-discount-cancel"
                className="w-[100px]"
                type={ButtonType.SECONDARY}
                onClick={onCancelClick}>
                Cancel
              </CustomButton>
              <CustomButton
                id="create-invoice-add-discount-save"
                onClick={handleSaveDiscount}
                disabled={disableSaveBtn}
                formType={FormButtonType.submit}
                className="w-[100px]">
                Save
              </CustomButton>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default AddInvoiceDiscountModal;
