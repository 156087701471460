import { ArrowRightIcon, FetchingErrorIcon } from 'assets/icons';
import { Component, ReactElement } from 'react';
import { Link } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';

interface ErrorBoundaryProps {
  children: ReactElement;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  handleGoBack = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    if (hasError) {
      return (
        <div className="w-full h-full flex items-center justify-center">
          <div className="flex flex-col items-center justify-center gap-2">
            <FetchingErrorIcon />
            <div className="text-xl text-accentText">Something went wrong!</div>
            <Link to={routesPath.HOME}>
              <div className="flex gap-2 items-center cursor-pointer" onClick={this.handleGoBack} role="presentation">
                <div className="rotate-180">
                  <ArrowRightIcon />
                </div>
                <div className="text-lg text-accentText">Go back to Home</div>
              </div>
            </Link>
          </div>
        </div>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
