import { EditIcon } from 'assets/icons';
import { PermissionLevels, Permissions, TeamRole } from 'containers/app/app.model';
import { selectCurrentTeam, selectCurrentTeamTeamSettingsPermissions } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { PermissionSettingType } from '../edit-permissions/constants';
import PermissionsToolTip from './PermissionsToolTip';

interface PermissionsSectionProps {
  permissions: Permissions;
  onEditClick: VoidFn;
  allowEdit?: boolean;
}

const PermissionsSection: FC<PermissionsSectionProps> = ({ permissions, onEditClick, allowEdit = false }) => {
  const currentTeamSettingPermissions = useAppSelector(selectCurrentTeamTeamSettingsPermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const hasPermissionsToEdit =
    (currentTeamSettingPermissions?.editExistingMember &&
      permissions?.banking.level !== PermissionLevels.MANAGE_FUNDS) ||
    currentTeam?.role === TeamRole.OWNER;

  return (
    <div>
      <div className="flex w-full items-center justify-between border-b border-borderGray p-[15px]">
        <div className="flex items-center gap-2 text-sbase font-semibold text-heading">
          Permissions
          <PermissionsToolTip />
        </div>
        {allowEdit && (
          <div
            className={`flex items-center gap-2 ${
              hasPermissionsToEdit ? 'cursor-pointer' : 'pointer-events-none opacity-50'
            }`}
            onClick={onEditClick}>
            <EditIcon className="path-fill-current path text-primaryBtn" />
            <div className="text-17px font-semibold text-primaryBtn">Edit</div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-2 gap-6 p-[15px]">
        <div className="flex flex-col gap-1">
          <div className="text-sbase text-accentText">{PermissionSettingType.TEAM_SETTINGS}</div>
          <div className="text-sbase font-semibold text-primaryText">{permissions.teamSettings.level}</div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-sbase text-accentText">{PermissionSettingType.INVOICES_AND_RECEIPTS}</div>
          <div className="text-sbase font-semibold text-primaryText">{permissions.invoice.level}</div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-sbase text-accentText">{PermissionSettingType.TIPS_AND_FEES}</div>
          <div className="text-sbase font-semibold text-primaryText">{permissions.fees.level}</div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-sbase text-accentText">{PermissionSettingType.REFUNDS}</div>
          <div className="text-sbase font-semibold text-primaryText">{permissions.refund.level}</div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-sbase text-accentText">{PermissionSettingType.CUSTOMERS}</div>
          <div className="text-sbase font-semibold text-primaryText">{permissions.customers.level}</div>
        </div>
        <div className="flex flex-col gap-1">
          <div className="text-sbase text-accentText">{PermissionSettingType.BANKING}</div>
          <div className="text-sbase font-semibold text-primaryText">{permissions.banking.level}</div>
        </div>
      </div>
    </div>
  );
};

export default PermissionsSection;
