import React, { FC, useEffect, useRef } from 'react';
import { ToastNotification } from 'containers/app/app.model';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useAppDispatch } from 'hooks';
import { Avatar } from '@material-ui/core';
import { getInitials } from 'utils/commonUtils';
import { NotifierTypes } from 'constants/notifierConstants';
import { InfoIcon, LockIcon, NavCloseIcon, NotifyErrorIcon, NotifySuccessIcon, NotifyWarningIcon } from 'assets/icons';
import { If } from 'components';

interface NotifierProps {
  notification: ToastNotification;
  hideNotifier?: ActionCreatorWithPayload<string, string>;
}

const Notifier: FC<NotifierProps> = ({ notification, hideNotifier }) => {
  const dispatch = useAppDispatch();

  enum classNameProperties {
    BACKGROUND = 'container-background',
    FONT = 'font',
    MARGIN = 'margin'
  }

  const timeoutRef = useRef(null);

  const dispatchHideNotifier = () => {
    dispatch(hideNotifier(notification.id));
  };

  useEffect(() => {
    timeoutRef.current = setTimeout(() => {
      dispatchHideNotifier();
    }, duration);
  }, []);

  const handleNotificationClose = () => {
    dispatchHideNotifier();
    clearTimeout(timeoutRef.current);
  };

  const {
    message,
    type,
    duration = 5000,
    showClose = true,
    bgStyle = 'bg-[#3E556D] rounded-md',
    fontStyle = 'text-primary font-normal',
    shouldAnimate = false
  } = notification;
  const { primaryMessage = '', secondaryMessage = '', iconInitials = '', iconSrc, isMessageHtml = false } = message;

  const getIcon = () => {
    let msgInitials = '';
    if (!isMessageHtml) {
      msgInitials = getInitials(primaryMessage);
    }
    if (type) {
      switch (type) {
        case NotifierTypes.SUCCESS:
          return <NotifySuccessIcon />;
        case NotifierTypes.ERROR:
          return <NotifyErrorIcon />;
        case NotifierTypes.INFO:
          return <InfoIcon />;
        case NotifierTypes.WARNING:
          return <NotifyWarningIcon />;
        case NotifierTypes.NOTIFYWARNING:
          return <LockIcon />;
      }
    }
    return (
      <Avatar className="border border-primary bg-[#A4D7FA] text-primaryText" src={iconSrc}>
        {iconInitials || msgInitials}
      </Avatar>
    );
  };

  const getNotifierClassName = property => {
    switch (property) {
      case classNameProperties.BACKGROUND:
        if (type === NotifierTypes.CUSTOM) return bgStyle;
        if (type === NotifierTypes.SUCCESS) return 'bg-[#cbf4c9e6] border border-[#27AE60] rounded-[10px]';
        if (type === NotifierTypes.ERROR) return 'bg-[#f7c0c0e6] border border-[#FF7A7A] rounded-[10px]';
        if (type === NotifierTypes.WARNING) return 'bg-[#F7E2C0] border border-[#F2994A] rounded-[10px]';
        if (type === NotifierTypes.NOTIFYWARNING) return 'bg-[#f7c0c0e6] border border-[#FF7A7A] rounded-[10px]';
        return 'bg-[#a4d7fae6] border border-[#3876BB] rounded-[10px]';
      case classNameProperties.FONT:
        if (type === NotifierTypes.CUSTOM) return fontStyle;
        return 'text-[#08153B] font-medium';
      case classNameProperties.MARGIN:
        if (type === NotifierTypes.CUSTOM) return 'mx-4';
        return 'ml-6 mr-2 mt-2';
    }
  };

  const displayMessage = () => {
    if (isMessageHtml) {
      return <div dangerouslySetInnerHTML={{ __html: primaryMessage }}></div>;
    }
    return primaryMessage;
  };

  return (
    <div
      id="notifier-toast"
      className={`pointer-events-auto relative my-3 mx-auto flex min-h-[60px]
      w-fit min-w-[250px] max-w-full items-center py-3 shadow-md
      ${shouldAnimate ? 'animate-slideup' : ''}
      ${getNotifierClassName(classNameProperties.BACKGROUND)}`}>
      <If condition={showClose}>
        <div onClick={handleNotificationClose} className="absolute right-2 top-2 flex h-[10px] w-[10px] cursor-pointer">
          <NavCloseIcon />
        </div>
      </If>
      <div className={`${getNotifierClassName(classNameProperties.MARGIN)} self-start`}>{getIcon()}</div>
      <div className="w-[80%] grow pr-14 leading-4">
        <div
          className={`max-w-full break-words 
          ${type === NotifierTypes.NOTIFYWARNING ? 'text-xl font-bold' : 'text-sbase'}
           leading-6
            ${getNotifierClassName(classNameProperties.FONT)}`}>
          {displayMessage()}
        </div>
        <If condition={Boolean(secondaryMessage)}>
          <div
            className={`max-w-full break-words leading-4 ${
              type === NotifierTypes.NOTIFYWARNING ? 'mt-[2px] text-17px text-primaryText' : 'text-px13 text-dark-gray'
            }`}>
            {secondaryMessage}
          </div>
        </If>
      </div>
    </div>
  );
};

export default Notifier;
