import { ArrowRightIcon, FetchingErrorIcon, ReloadIcon, SearchWithBgIcon } from 'assets/icons';
import { VoidFn } from 'types/baseTypes';
import React from 'react';
import { If } from 'components';
import { formatAmount } from 'utils/amountUtils';
import { LoadingSpinnerAnimation } from 'assets/animations';
import Lottie from 'lottie-react';

interface PaymentTileProps {
  TileIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  title: string;
  amount: number | string;
  onClick: VoidFn;
  count?: number;
  className?: string;
  isGrossSaleTile: boolean;
  isFetchingTileData: boolean;
  isFetchSuccess: boolean;
  handleReload: VoidFn;
  isPermissionError: boolean;
  id: string;
}

const PaymentTile: React.FC<PaymentTileProps> = ({
  TileIcon,
  onClick,
  title,
  amount,
  count,
  className = '',
  isGrossSaleTile,
  isFetchingTileData,
  isFetchSuccess,
  handleReload,
  isPermissionError,
  id
}) => {
  const getEmptyStateTitle = () => {
    if (isGrossSaleTile) {
      return 'No sales yet today';
    }
    return 'No pending invoices';
  };

  return (
    <div
      className={`${className} relative 
      h-[150px] w-[265px] min-w-[265px] overflow-hidden
      rounded-xl bg-primary shadow-[2px_2px_12px_rgba(9,26,70,0.19)]`}>
      <div className="paymentTileEllipse pointer-events-none absolute top-[-44px] right-[-50px]"></div>
      <div className="paymentTileEllipse pointer-events-none absolute top-[85px] right-[-65px]"></div>
      <div className="flex h-full flex-col space-y-2">
        <If condition={isFetchingTileData}>
          <div className="flex h-[150px] items-center justify-center">
            <Lottie className="h-20 w-20" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        </If>
        <If condition={isFetchSuccess && !isFetchingTileData}>
          <div id={id} className="flex cursor-pointer flex-col gap-1.5 py-9 pl-5" onClick={onClick}>
            <div className="flex flex-row items-center gap-1">
              <TileIcon className="shrink-0" />
              <div className="text-sm font-semibold text-accentText">
                {title}
                <If condition={count && count >= 1}> ({count})</If>
              </div>
            </div>
            <div id={`${id}-amount`} className="text-2xl font-bold text-[#212429]">
              <If condition={amount !== null}>${formatAmount(amount)}</If>
              <If condition={!amount}>{getEmptyStateTitle()}</If>
            </div>
            <div
              id={`${id}-view-button`}
              onClick={onClick}
              className="flex flex-row items-center gap-2 text-secondaryBtnText hover:cursor-pointer">
              <div className="text-px13 font-semibold">View</div>
              <ArrowRightIcon className="shrink-0" />
            </div>
          </div>
        </If>
        <If condition={!isFetchSuccess && !isFetchingTileData && !isPermissionError}>
          <div className="flex h-full flex-col items-center justify-center gap-2">
            <FetchingErrorIcon />
            <div className="text-sbase text-accentText">Something went wrong!</div>
            <div className="flex items-center gap-2">
              <div className="cursor-pointer" onClick={handleReload}>
                <ReloadIcon />
              </div>
              <div className="text-px13 text-accentText">Please try refreshing</div>
            </div>
          </div>
        </If>
        <If condition={!isFetchSuccess && !isFetchingTileData && isPermissionError}>
          <div className="flex h-full flex-col justify-center gap-2 p-5">
            <div className="flex flex-row items-center gap-1">
              <TileIcon className="shrink-0" />
              <div className="text-sm font-semibold text-accentText">{title}</div>
            </div>
            <div className="flex items-center gap-2">
              <div>
                <SearchWithBgIcon />
              </div>
              <div className="text-sbase font-semibold text-primaryText">Required Permissions not found</div>
            </div>
          </div>
        </If>
      </div>
    </div>
  );
};
export default PaymentTile;
