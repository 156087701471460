import { ITax } from './taxes.model';

export const emptyTax: ITax = {
  id: null,
  name: null,
  percentage: null,
  lineItemsCount: null,
  applyToAllItems: null,
  applyToAllServiceCharges: null,
  serviceChargesCount: null,
  serviceCharges: [],
  lineItems: []
};

export enum CatalogTopBarTaxesButton {
  CreateTaxSaveButton = 'create-tax-save-button',
  CreateTaxBackButton = 'create-tax-back-button',
  EditTaxSaveButton = 'edit-tax-save-button',
  EditTaxBackButton = 'edit-tax-back-button',
  EditTaxDeleteButton = 'edit-tax-delete-button'
}
