import { AttachmentIcon, MailIcon, MessageIcon, NavCloseIcon } from 'assets/icons';
import { CustomButton, CustomInput, InputWithPrefix } from 'components';
import SidePanel from 'components/side-panel/SidePanel';
import { FC, useCallback, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { copyToClipBoard } from 'utils/commonUtils';
import { isValidEmail } from 'utils/stringUtils';
import { useShareInviteLinkMutation } from '../api';
import { ITeamMember } from '../manageTeam.model';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';

interface SendInviteSidePanelProps {
  teamMember: ITeamMember;
  onClose: VoidFn;
  isOpen: boolean;
  resetTeamMembersQuery: VoidFn;
}

const SendInviteSidePanel: FC<SendInviteSidePanelProps> = ({ teamMember, onClose, isOpen, resetTeamMembersQuery }) => {
  const [textCopied, setTextCopied] = useState<boolean>(false);
  const [memberEmail, setMemberEmail] = useState<string>('');
  const [isUpdateChecked, setIsUpdateChecked] = useState<boolean>(true);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [shareInviteLinkMutation, { isSuccess: isSendInviteSuccess }] = useShareInviteLinkMutation();

  useEffect(() => {
    setMemberEmail(teamMember.email);
  }, [teamMember]);

  useEffect(() => {
    if (isSendInviteSuccess) {
      resetTeamMembersQuery();
    }
  }, [isSendInviteSuccess]);

  // eslint-disable-next-line react/no-multi-comp
  const UpdateCustomerInformation = ({
    isChecked,
    handleChangeIsChecked,
    disabled
  }: {
    isChecked: boolean;
    handleChangeIsChecked: VoidFn;
    disabled: boolean;
  }) => (
    // eslint-disable-next-line quotes
    <div className={`flex w-full items-center ${disabled ? 'cursor-not-allowed opacity-50' : ''}`}>
      <input
        id="default-checkbox"
        type="checkbox"
        onChange={handleChangeIsChecked}
        checked={isChecked}
        disabled={disabled}
        className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
      />
      <span
        id="send-invite-panel-update-merchant-info-checkbox"
        className="ml-1 text-sbase font-normal text-primaryText">
        Update member information
      </span>
    </div>
  );

  const handleEmailChange = (email: string) => {
    setMemberEmail(email);
  };

  const onUpdateCheck = () => {
    setIsUpdateChecked(prev => !prev);
  };

  const handleCopyInviteLink = useCallback(() => {
    setTextCopied(true);
    copyToClipBoard(teamMember?.inviteLink);
    setTimeout(() => setTextCopied(false), 1000);
  }, []);

  const onSendSMS = () => {
    shareInviteLinkMutation({
      inviteId: teamMember.inviteId,
      phoneNumber: teamMember.phone,
      teamId: currentTeam.id?.toString()
    });
  };

  const onSendEmail = () => {
    shareInviteLinkMutation({
      inviteId: teamMember.inviteId,
      email: memberEmail,
      updateMerchantInformation: !isUpdateCheckDisabled(),
      teamId: currentTeam.id?.toString()
    });
  };

  const isUpdateCheckDisabled = () => !memberEmail || (memberEmail && memberEmail === teamMember.email);

  return (
    <SidePanel isOpen={isOpen} onClose={onClose}>
      <div className="flex h-full w-96 flex-col">
        <div className="flex w-full cursor-pointer items-center gap-2 p-6" onClick={onClose}>
          <NavCloseIcon />
          <div className="text-17px font-semibold text-primaryText">Send Invite</div>
        </div>
        <div>
          <div className="flex flex-col px-6">
            <div className="rounded-md border border-borderGray p-[17px]">
              <div className="mb-3.5 flex items-center">
                <MessageIcon />
                <span className="ml-1.5 text-sbase font-semibold text-primaryText">Text message</span>
              </div>
              <InputWithPrefix
                id="send-invite-panel-phone-number"
                placeholder="000-000-0000"
                containerStyles={
                  'p-[11px] text-sbase placeholder:text-sbase bg-primary border rounded border-borderGray'
                }
                prefixString="+1"
                value={teamMember.phone}
                onChange={null}
                className="bg-primary text-sbase text-accentText placeholder:font-normal placeholder:text-accentText"
              />
              <CustomButton
                id="send-invite-panel-send-sms-button"
                onClick={onSendSMS}
                className="mt-[15px] mb-2.5 h-[38px] w-full"
                StartIcon={MessageIcon}
                startIconStyle="path-stroke-current">
                Send SMS
              </CustomButton>
            </div>
            <div className="mt-4 rounded-md border border-borderGray p-[17px]">
              <div className="mb-3.5 flex items-center">
                <MailIcon />
                <span className="ml-1.5 text-sbase font-semibold text-primaryText">Email</span>
              </div>
              <CustomInput
                id="send-invite-panel-email-input"
                inputStyle="text-sbase"
                value={memberEmail}
                onChange={handleEmailChange}
                placeholder="Enter email id"
                className={`h-10 py-[11px] text-primaryText outline-none 
                placeholder:text-sbase focus-within:ring-1 
                focus-within:ring-blue-500 
                ${!isValidEmail(memberEmail) ? 'border border-error' : ''}`}
              />
              {!isValidEmail(memberEmail) && <div className="mt-1 text-red-400">Please enter a valid email</div>}
              <CustomButton
                id="send-invite-panel-email-button"
                disabled={!isValidEmail(memberEmail) || !memberEmail}
                onClick={onSendEmail}
                className="mt-[15px] mb-2.5 h-[38px] w-full"
                StartIcon={MailIcon}
                startIconStyle="path-stroke-current">
                Send Email
              </CustomButton>
              <UpdateCustomerInformation
                isChecked={isUpdateChecked}
                handleChangeIsChecked={onUpdateCheck}
                disabled={isUpdateCheckDisabled()}
              />
            </div>
            <div className="mt-4 flex w-full flex-col	rounded-md border border-borderGray p-4">
              <div className="flex flex-row items-center">
                <AttachmentIcon />
                <div className={'ml-1.5 text-sbase font-semibold text-primaryText'}>Copy Link</div>
              </div>
              <div className="mt-4 flex rounded-md border border-borderGray">
                <div
                  id="send-invite-panel-copy-link-field"
                  className="block w-full  truncate rounded-l-lg bg-primary py-2.5 px-3
                  text-sbase font-semibold text-primaryText">
                  {teamMember.inviteLink}
                </div>
                <div>
                  <button
                    id="send-invite-panel-copy-link"
                    onClick={handleCopyInviteLink}
                    className={twMerge(`flex h-full flex-1 flex-row items-center justify-center rounded-r-md border-l
                    border-borderGray bg-secondaryBtn font-semibold
                    px-4 text-sbase text-primaryBtn ${textCopied && 'text-primaryText'}`)}
                    style={{ flexShrink: 0, whiteSpace: 'nowrap' }}>
                    {textCopied ? 'Link copied' : 'Copy'}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SidePanel>
  );
};

export default SendInviteSidePanel;
