export enum CleverTapEventsIAM {
  webInitiateSignInContinueClick = 'Web_Initiate_Sign_In_Continue_Click',
  webPasswordContinueClick = 'Web_Login_Password_Continue_Click',
  webPasswordBackClick = 'Web_Login_Password_Back_Click',
  webForgotPasswordClick = 'Web_Login_Forgot_Password_Click',
  webVerifyAccountForPasswordResetContinueClick = 'Web_Verify_Account_For_Password_Reset_Continue_Click',
  webVerifyAccountForPasswordResetBackClick = 'Web_Verify_Account_For_Password_Reset_Back_Click',
  webPassordPageError = 'Web_Password_Page_Error',
  webCreateANewPasswordContinueClick = 'Web_Login_Create_A_New_Password_After_Expiry_Continue_Click',
  webCreateANewPasswordBackClick = 'Web_Login_Create_A_New_Password_After_Expiry_Back_Click',
  webEveryDayOpenCreateANewPasswordContinueClick = 'Web_Everyday_Open_Create_A_New_Password_Expiry_Continue_Click',
  webEveryDayOpenCreateANewPasswordBackClick = 'Web_Everyday_Open_Create_A_New_Password_After_Expiry_Back_Click',
  webRelatedAccountsClick = 'Web_Related_Accounts_Click',
  webRelatedAccountsBackClick = 'Web_Related_Accounts_Back_Click',
  webCreatePasswordContinueClick = 'Web_Create_First_Password_Continue_Click',
  webCreatePasswordBackClick = 'Web_Create_First_Password_Back_Click',
  webDeviceAuthenticationPage = 'Web_Device_Authentication_Page',
  webDeviceAuthenticationContinueClick = 'Web_Device_Authentication_Continue_Click',
  webDeviceAuthenticationBackClick = 'Web_Device_Authentication_Back_Click',
  webEmailVerificationContinueClick = 'Web_Email_Verification_Continue_Click',
  webEmailVerificationVerifyLater = 'Web_Email_Verification_Verify_Later',
  webAccountNotFoundPage = 'Web_Account_Not_Found_Page',
  webResetPasswordContinueClick = 'Web_Reset_Password_Continue_Click',
  webResetPasswordBackClick = 'Web_Reset_Password_Back_Click',
  webAddEmail = 'Web_Everyday_Open_Add_Email',
  webSkipEmail = 'Web_Everyday_Open_Skip_Email',
  webSignInWithDiffNumberClick = 'Web_Sign_In_With_Different_Number_Click',
  webSignInWithPhoneNumberClick = 'Web_Sign_In_With_Phone_Number_Click',
  webSignUpClick = 'Web_Sign_Up_Click',
  webSignupParameters = 'Web_Sign_Up_Parameters',
  webSignUpSuccess = 'Web_Sign_Up_Success',
  webSignUpExpiredInviteToken = 'Web_Sign_Up_Expired_Invite_Token'
}
