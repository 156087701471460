import React, { FC } from 'react';
import AttachmentCard from './AttachmentCard';
import { InvoiceAttachmentEditPage } from '../edit-invoice/invoicesEditSlice';

interface AttachmentsSectionProps {
  attachments: InvoiceAttachmentEditPage[];
}

const AttachmentsSection: FC<AttachmentsSectionProps> = ({ attachments }) => (
  <div className="flex flex-col gap-y-[30px] rounded-md border border-borderGray bg-white p-10">
    <div className="text-gray-4 text-sbase font-semibold uppercase">Attachments</div>
    <div className="flex flex-col gap-5">
      {attachments.map((attachment, index) => (
        <AttachmentCard key={index + 1} attachment={attachment} />
      ))}
    </div>
  </div>
);

export default AttachmentsSection;
