import {
  ArrowRightIcon,
  CaretDownIcon,
  InfoIcon,
  LinkIcon,
  NavCloseIcon,
  ReceiptInvoiceIcon,
  RedirectIcon
} from 'assets/icons';
import {
  selectCurrentTeam,
  selectCurrentTeamInvoicePermissions,
  selectPaymentSystemConfig,
  showNotifier
} from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import GenericError from 'components/genric-error/GenericError';
import SidePanel from 'components/side-panel/SidePanel';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE, FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { getInitials } from 'utils/commonUtils';
import {
  useLazyDownloadEvidenceQuery,
  useLazyGetDisputeRelatedPayoutsQuery,
  useLazyGetMerchantDisputeByIdQuery
} from '../api';
import { OutletContextType } from 'types/baseTypes';
import Timeline from './DisputeTimeline';
import { getDisputeStatusInfoIcon, getDisputeStatusStyle, onHowToRespondToDisputeClick } from '../utils';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { DISPUTE_STATUS } from '../constants';
import { setDisputeResponse, setSupportingComments, updatePreviousRoute, updateUploadedDocList } from '../disputeSlice';
import DisputeStatusToolTip from './DisputeStatusToolTip';
import { getCardIcon } from 'containers/receipts/util';
import { setDisputeReason } from '../disputeSlice';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsDisputes } from '../events';
import NoPermissions from 'components/no-permissions/NoPermissions';
import { NotifierTypes } from 'constants/notifierConstants';
import { isPermissionDenied } from 'utils/apiUtils';
import DateUtils from 'utils/dateUtils';
import { PaymentSystem } from 'containers/payouts/constants';
import { checkPermissions } from 'containers/payouts/utils';

const DisputeDetailsPanel: FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const currentReceiptPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const dispatch = useAppDispatch();

  const [
    getMerchantDisputeById,
    {
      data: disputeData,
      isSuccess: isDisputeDataSuccess,
      isError: isDisputeDataError,
      error: disputeError,
      isFetching: isFetchingDisputeData
    }
  ] = useLazyGetMerchantDisputeByIdQuery();
  const [downloadEvidenceQuery] = useLazyDownloadEvidenceQuery();
  const [
    getDisputeRelatedPayoutsQuery,
    { data: disputeRelatedPayouts, isFetching: isFetchingPayouts, isSuccess: isPayoutsFetchingSuccess }
  ] = useLazyGetDisputeRelatedPayoutsQuery();

  const { disputeDetail, disputeTimeline } = isDisputeDataSuccess && disputeData.data;

  const { disputeId, rootDisputeId, rootInvoiceId, rootReceiptId, rootCustomerId, rootPage } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSidePanelClose } = useOutletContext<OutletContextType>();
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const [isRootState, setIsRootState] = useState(true);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const [hasPayoutsPermissions, setHasPayoutsPermissions] = useState(true);
  const [showStatusToolTip, setShowStatusToolTip] = useState(false);
  const [showFeeToolTip, setShowFeeToolTip] = useState(false);

  useEffect(() => {
    if (currentTeam.id) {
      setHasPayoutsPermissions(
        checkPermissions({
          paymentSystem,
          viewPayoutsBySelf: payoutPermissions.viewPayoutsBySelf,
          viewPayoutsByTeam: payoutPermissions.viewPayoutsByTeam
        })
      );
    }
  }, [currentTeam.id, paymentSystem]);

  useEffect(() => {
    if (disputeId || rootDisputeId) {
      if (currentReceiptPermissions.viewReceiptsBySelf || currentReceiptPermissions.viewReceiptsByTeam) {
        getMerchantDisputeById({ disputeId: disputeId ? disputeId : rootDisputeId, teamId: currentTeam.id });
        getDisputeRelatedPayoutsQuery({ disputeId: disputeId ? disputeId : rootDisputeId, teamId: currentTeam.id });
      } else {
        setHasPermissionsToView(false);
      }
    }
  }, [
    disputeId,
    rootDisputeId,
    currentReceiptPermissions.viewReceiptsBySelf,
    currentReceiptPermissions.viewReceiptsByTeam
  ]);

  useEffect(() => {
    let routeStateTimeout;
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  useEffect(() => {
    if (isDisputeDataError) {
      if (isPermissionDenied(disputeError?.['status'] || '', disputeError?.['data']?.message) || '') {
        setHasPermissionsToView(false);
      }
    }
  }, [disputeError, isDisputeDataError]);

  useEffect(() => {
    if (isDisputeDataSuccess) {
      logAnalyticEvent(CleverTapEventsDisputes.webDisputesListDisputeDetails, {
        disputeId: disputeId ? disputeId : rootDisputeId
      });
      setHasPermissionsToView(true);
    }
  }, [isDisputeDataSuccess, isFetchingDisputeData]);

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  const handleReceiptClick = receiptId => {
    if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/receipt/${receiptId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handlePayoutClick = (payoutId: string, type: PaymentSystem) => {
    const payoutType = type || PaymentSystem.CA;
    if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/payout/${payoutId}?type=${payoutType}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/payout/${payoutId}?type=${payoutType}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/payout/${payoutId}?type=${type}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/payout/${payoutId}?type=${payoutType}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/payout/${payoutId}?type=${payoutType}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleViewEvidenceClick = () => {
    logAnalyticEvent(CleverTapEventsDisputes.webDisputesDetailViewSubmittedEvidence, { disputeId: disputeDetail.id });
    navigate(`/payments/disputes/${currentTeam.id}/view-evidence/${disputeDetail.id}`);
  };

  const handleDocumentDownload = (url: string, fileName: string) => {
    downloadEvidenceQuery({
      url,
      fileName
    });
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const onRespondToDispute = () => {
    dispatch(setDisputeResponse(null));
    dispatch(setSupportingComments(null));
    dispatch(setDisputeReason(null));
    dispatch(updateUploadedDocList([]));
    dispatch(updatePreviousRoute(location?.pathname));
    navigate(`/payments/disputes/${currentTeam.id}/respond/${disputeDetail.id}`);
    logAnalyticEvent(CleverTapEventsDisputes.webDisputesDetailRespondToDisputeClick, {
      disputeId: disputeDetail.id
    });
  };

  const handleShowStatusToolTip = () => {
    setShowStatusToolTip(true);
  };

  const handleCloseStatusToolTip = () => {
    setShowStatusToolTip(false);
  };

  const handleShowFeeToolTip = () => {
    setShowFeeToolTip(true);
  };

  const handleCloseFeeToolTip = () => {
    setShowFeeToolTip(false);
  };

  const handleViewRelatedPayoutsClick = () => {
    const payoutsElement = document.getElementById('dispute-related-payouts');
    if (payoutsElement) {
      payoutsElement.scrollIntoView();
    }
  };

  const showDownloadBankResponse = isDisputeDataSuccess
    ? (disputeDetail.merchantStatus === DISPUTE_STATUS.WON || disputeDetail.merchantStatus === DISPUTE_STATUS.LOST) &&
      disputeDetail.issuingBankResponseUrl
    : false;

  const showViewSubmittedEvidence = isDisputeDataSuccess ? disputeDetail.merchantReason : false;

  const showRelatedDocuments = isDisputeDataSuccess
    ? showDownloadBankResponse || showViewSubmittedEvidence || disputeDetail.claimDocumentUrl
    : false;

  const disputeInfo = isDisputeDataSuccess
    ? [
        {
          id: 'dispute-side-panel-days',
          title: disputeDetail.merchantStatus === DISPUTE_STATUS.NEEDS_RESPONSE ? 'Response due' : 'Last update',
          data:
            disputeDetail.merchantStatus === DISPUTE_STATUS.NEEDS_RESPONSE
              ? disputeDetail.formattedRespondBy
              : disputeDetail.formattedUpdatedAt
        },
        {
          id: 'dispute-side-panel-reason',
          title: 'Reason for Dispute',
          data: disputeDetail.reasonForDispute || '-'
        },
        ...(disputeDetail.messageForMerchant
          ? [
              {
                id: 'dispute-side-panel-message',
                title: 'Message from Disputes team',
                data: disputeDetail.messageForMerchant || '-'
              }
            ]
          : [])
      ]
    : [];

  const getPaymentData = () => {
    if (disputeDetail.paymentMethodDetail) {
      return (
        <div className="flex w-[90%] items-start gap-1">
          <img src={getCardIcon(disputeDetail.paymentMethodDetail.split(' ')?.[0].toLowerCase())} alt="" />
          <div className="capitalize">{disputeDetail.paymentMethodDetail}</div>
        </div>
      );
    } else if (disputeDetail.paymentMethod) {
      return (
        <div className="flex items-center">
          <div className="ml-1 truncate capitalize">{disputeDetail.paymentMethod}</div>
        </div>
      );
    }
    return '-';
  };

  const TxnDetails = isDisputeDataSuccess
    ? [
        {
          title: 'ScanPay ID',
          data: disputeDetail.transactionId || '-'
        },
        {
          title: 'Customer',
          data: disputeDetail.customerName || '-'
        },
        {
          title: 'Transaction Date',
          data: disputeDetail.formattedTransactionDate || '-'
        },
        {
          title: 'Collected By',
          data: disputeDetail.collectedBy ? (
            <div className="flex items-center gap-1">
              <CustomAvatar
                src={disputeDetail.collectedByProfileUrl}
                width="w-4"
                height="h-4"
                fontStyle="text-[8px] text-tertiaryText">
                {getInitials(disputeDetail.collectedBy)}
              </CustomAvatar>
              <div className="truncate">{disputeDetail.collectedBy}</div>
            </div>
          ) : (
            '-'
          )
        },
        {
          title: 'Invoice Number',
          data: disputeDetail.invoiceNumber || '-'
        },
        {
          title: 'Payment Method',
          data: getPaymentData(),
          containerStyle: 'whitespace-normal'
        }
      ]
    : [];

  const showHowToRespondLink = isDisputeDataSuccess
    ? !(
        disputeDetail.merchantStatus === DISPUTE_STATUS.LOST ||
        disputeDetail.merchantStatus === DISPUTE_STATUS.WON ||
        disputeDetail.merchantStatus === DISPUTE_STATUS.PAST_DUE
      )
    : false;

  const renderDataCard = (primaryTitle, secondaryTitle = '', tertiaryTitle = '') => {
    return (
      <div className="flex w-full items-center justify-between">
        <div className="flex">
          <ReceiptInvoiceIcon />
          <div className="pl-4 text-sbase font-semibold">
            <div className="pb-0.5 text-tertiaryText">{primaryTitle}</div>
            {secondaryTitle && <div className="pb-0.5 text-accentText line-clamp-1">{secondaryTitle}</div>}
            {tertiaryTitle && <div className="text-accentText">{tertiaryTitle}</div>}
          </div>
        </div>
        <div>
          <CaretDownIcon className="-rotate-90" />
        </div>
      </div>
    );
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="h-full w-96">
        {isFetchingDisputeData ? (
          <div className="flex h-full w-full items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          isDisputeDataSuccess &&
          hasPermissionsToView && (
            <div className="flex h-full w-full flex-col">
              <div className="flex w-full items-center gap-2 border-b border-borderGray p-5">
                <div onClick={handleSidePanelBack} className="cursor-pointer">
                  {isRootState ? (
                    <NavCloseIcon id="dispute-panel-close" />
                  ) : (
                    <ArrowRightIcon className="rotate-180" id="dispute-panel-back-arrow" />
                  )}
                </div>
                <div id="dispute-panel-head" className="text-17px font-semibold text-headingGray">
                  Dispute - {disputeDetail.id}
                </div>
              </div>
              <>
                <div
                  className="customNormalScroll flex w-full grow 
                  flex-col overflow-y-auto overflow-x-hidden pb-[30px]">
                  {showHowToRespondLink && (
                    <div
                      className="flex cursor-pointer gap-2 bg-secondaryBtn px-7 py-2"
                      onClick={onHowToRespondToDisputeClick}>
                      <RedirectIcon />
                      <div className="text-sbase font-semibold text-primaryBtn">Learn how to respond to disputes</div>
                    </div>
                  )}
                  <div className="mx-[30px] flex flex-col items-center gap-2 border-b border-borderGray py-12">
                    <div className="text-px13 font-semibold text-accentText">Dispute Amount </div>
                    <div className="text-xl font-bold text-primaryText">
                      {getAmountWithCurrency(disputeDetail.amount)}
                    </div>
                    <div
                      className={'flex items-center gap-1.5 rounded-md px-4 py-0.5'}
                      style={getDisputeStatusStyle(disputeDetail.merchantStatus)}>
                      <div className="text-px13 font-semibold">{disputeDetail.statusDetail}</div>
                      <DisputeStatusToolTip
                        InfoIcon={getDisputeStatusInfoIcon(disputeDetail.merchantStatus)}
                        InfoText={disputeDetail.toolTipContent}
                        handleCloseToolTip={handleCloseStatusToolTip}
                        handleShowToolTip={handleShowStatusToolTip}
                        showToolTip={showStatusToolTip}
                        widthStyle="max-w-[260px]"
                      />
                    </div>
                  </div>
                  <div className="mx-[30px] flex flex-col gap-5 border-b border-borderGray py-7">
                    {disputeInfo.map(info => (
                      <div key={info.id} className="flex flex-col gap-1">
                        <div className="text-sbase text-accentText">{info.title}</div>
                        <div className="break-words text-sbase font-semibold text-primaryText line-clamp-3">
                          {info.data}
                        </div>
                      </div>
                    ))}
                    {showRelatedDocuments && (
                      <div className="flex flex-col gap-1">
                        <div className="text-sbase text-accentText">Related documents</div>
                        {showDownloadBankResponse && (
                          <div
                            className="cursor-pointer text-sbase font-semibold text-secondary"
                            onClick={() => {
                              logAnalyticEvent(CleverTapEventsDisputes.webDisputesDetailIssuingBankResponseDownload, {
                                disputeId: disputeDetail.id
                              });
                              handleDocumentDownload(
                                disputeDetail.issuingBankResponseUrl,
                                `${disputeDetail.id} - issuing bank response`
                              );
                            }}>
                            Download issuing bank response
                          </div>
                        )}
                        {showViewSubmittedEvidence && (
                          <div
                            className="cursor-pointer text-sbase font-semibold text-secondary"
                            onClick={handleViewEvidenceClick}>
                            View your submitted evidence
                          </div>
                        )}
                        {disputeDetail.claimDocumentUrl && (
                          <div
                            className="cursor-pointer text-sbase font-semibold text-secondary"
                            onClick={() => {
                              logAnalyticEvent(CleverTapEventsDisputes.webDisputesDetailClaimDocumentDownload, {
                                disputeId: disputeDetail.id
                              });
                              handleDocumentDownload(
                                disputeDetail.claimDocumentUrl,
                                `${disputeDetail.id} - claim document`
                              );
                            }}>
                            Download dispute claim document
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="mx-[30px] flex flex-col gap-1.5 border-b border-borderGray py-7">
                    <div className="text-sbase font-semibold text-primaryText">{disputeDetail.deductionStatus}</div>
                    <div className="text-sbase text-accentText">{disputeDetail.deductionDetail}</div>
                    {Boolean(+disputeDetail.disputeFee) && (
                      <div className="flex items-center gap-2 text-sbase font-normal text-primaryText">
                        <div>+{getAmountWithCurrency(disputeDetail.disputeFee)} dispute fees</div>
                        <DisputeStatusToolTip
                          InfoIcon={InfoIcon}
                          InfoText={`Dispute fees are charged by the customer's card networks or payment 
                            processors regardless of the outcome. We will be debiting the amount from your
                            banking account or future payouts.`}
                          handleCloseToolTip={handleCloseFeeToolTip}
                          handleShowToolTip={handleShowFeeToolTip}
                          showToolTip={showFeeToolTip}
                        />
                      </div>
                    )}
                    {hasPayoutsPermissions && disputeRelatedPayouts.data.length > 0 && (
                      <div
                        className="flex cursor-pointer items-center gap-1 text-sbase font-semibold text-secondary"
                        onClick={handleViewRelatedPayoutsClick}>
                        View related payouts
                        <LinkIcon className="mt-0.5" />
                      </div>
                    )}
                  </div>
                  <div className="mx-[30px] pt-6 text-17px font-semibold text-headingGray">Timeline</div>
                  <div className="mx-[30px] pt-2.5 pb-7">
                    <Timeline disputeStatus={disputeDetail.merchantStatus} timelineData={disputeTimeline} />
                  </div>
                  <div className="mx-4 flex flex-col rounded-md border border-borderGray">
                    <div className="border-b border-borderGray p-5 text-sbase font-semibold text-primaryText">
                      Transaction Details
                    </div>
                    <div className="grid grid-cols-[1.5fr_2fr] gap-4 p-5 font-semibold">
                      {TxnDetails.map(info => (
                        <>
                          <div className="text-sbase text-accentText">{info.title}</div>
                          <div className={`truncate text-sbase text-primaryText ${info.containerStyle}`}>
                            {info.data}
                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                  <div
                    className={`mx-4 mt-[30px] flex cursor-pointer items-center justify-between rounded-md
                        border border-borderGray bg-primary p-4`}
                    onClick={() => {
                      handleReceiptClick(disputeDetail.transactionId);
                    }}>
                    {renderDataCard(
                      `Receipt - ${disputeDetail.transactionId}`,
                      disputeDetail.customerName,
                      disputeDetail.formattedTransactionDate
                        ? disputeDetail.formattedTransactionDate.split(' ').slice(0, 3).join(' ')
                        : '-'
                    )}
                  </div>
                  {hasPayoutsPermissions &&
                    !isFetchingPayouts &&
                    isPayoutsFetchingSuccess &&
                    disputeRelatedPayouts.data.length > 0 && (
                      <div className="mx-4 mt-[30px]" id="dispute-related-payouts">
                        <div className="text-17px font-semibold text-heading">Related payouts</div>
                        {disputeRelatedPayouts.data.map(item => (
                          <div
                            key={item.payoutId}
                            className={`mt-2.5 flex cursor-pointer items-center justify-between
                        rounded-md border border-borderGray bg-primary p-4`}
                            onClick={() => {
                              handlePayoutClick(item.payoutId, item.paymentSystem as PaymentSystem);
                            }}>
                            {renderDataCard(
                              `Payout - ${item.payoutId}`,
                              DateUtils.getDateInFormat({ date: new Date(item.createdAt), dateFormat: 'dd MMM yyyy' })
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                </div>
                {disputeDetail.merchantStatus === DISPUTE_STATUS.NEEDS_RESPONSE && (
                  <div
                    className="cursor-pointer bg-primaryBtn py-4 text-center text-sbase font-semibold text-primary"
                    onClick={onRespondToDispute}>
                    Respond to Dispute
                  </div>
                )}
              </>
            </div>
          )
        )}
        {!hasPermissionsToView && <NoPermissions handleErrorBack={handleSidePanelBack} />}
        {isDisputeDataError && hasPermissionsToView && <GenericError handleErrorBack={handleSidePanelBack} />}
      </div>
    </SidePanel>
  );
};

export default DisputeDetailsPanel;
