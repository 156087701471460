import React, { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { NavTab } from 'types/baseTypes';
import { findActiveSubTab } from 'utils/commonUtils';
import SideNavTab from './NavTab';

interface NavSectionProps {
  isBottomBar?: boolean;
  navList: NavTab[];
  onNavTabClick: (tab: NavTab) => void;
  activeTab: NavTab;
  className?: string;
  onSubTabClick: (tab: NavTab) => void;
}

const NavSection: FC<NavSectionProps> = ({
  isBottomBar = false,
  navList,
  onNavTabClick,
  activeTab,
  className,
  onSubTabClick
}) => {
  const location = useLocation();
  const activeSubTab = activeTab.hasChildTabs && findActiveSubTab(location.pathname);

  const isActiveSubTab = (subtab: NavTab) => {
    if (!activeSubTab) {
      return false;
    }
    return activeSubTab.path === subtab.path;
  };

  return (
    <div className={`${className} space-y-2 pt-5 pb-7`}>
      {navList.map(tab => {
        return (
          <div key={tab.key}>
            <SideNavTab
              tab={tab}
              onNavTabClick={onNavTabClick}
              isBottomBar={isBottomBar}
              activeTab={activeTab}
              key={tab.key}
            />
            {tab?.hasChildTabs &&
              activeTab.key === tab.key &&
              tab.childTabs.map((subTab, index) => (
                <div key={subTab.key + index}>
                  <SideNavTab
                    tab={subTab}
                    onNavTabClick={onSubTabClick}
                    isBottomBar={isBottomBar}
                    activeTab={activeTab}
                    isActiveSubTab={isActiveSubTab(subTab)}
                    subTabClassname="pl-10"
                  />
                </div>
              ))}
          </div>
        );
      })}
    </div>
  );
};

export default NavSection;
