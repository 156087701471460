import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import { useCallback, useEffect, useRef, useState } from 'react';
import { isPermissionDenied } from 'utils/apiUtils';
import { useLazyGetCatalogItemsQuery } from '../api';
import { IPaginatedItem } from '../items.model';

export interface CatalogItemsQueryParams {
  search: string;
  categoryId: string[];
}

export const initialItemsQueryParamsState = {
  search: null,
  categoryId: [],
  category: []
};

export const usePaginatedGetItemsQuery = () => {
  const [list, setList] = useState<IPaginatedItem[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const [queryParams, setQueryParams] = useState<CatalogItemsQueryParams>(initialItemsQueryParamsState);
  const [isMounted, setIsMounted] = useState(false);

  const [getItemsQuery, getItemsState] = useLazyGetCatalogItemsQuery();
  const {
    isFetching: isGetItemsFetching,
    isSuccess: isGetItemsSuccess,
    isError: isGetItemsError,
    error: getItemsErrorState,
    data: itemsData
  } = getItemsState;

  const currentTeam = useAppSelector(selectCurrentTeam);

  const page = useRef(0);
  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!isGetItemsFetching && isGetItemsSuccess) {
      const items = itemsData?.data ?? [];
      const updatedList = isInitListCall ? items : [...list, ...items];
      const invoicesLeftToFetch = itemsData?.meta?.totalCount ? +itemsData?.meta?.totalCount - updatedList.length : 0;

      setHasMore(invoicesLeftToFetch > 0);
      setList(updatedList);
      if (page.current === 1) {
        setTotalListCount(itemsData?.meta?.totalCount ?? 0);
      }
      setIsLoading(false);
    }
  }, [isGetItemsFetching]);

  useEffect(() => {
    if (!isGetItemsFetching && isGetItemsError) {
      setIsError(true);
      setIsLoading(false);
      setHasMore(false);
      if (isPermissionDenied(getItemsErrorState?.['data']?.code, getItemsErrorState?.['data']?.message)) {
        setHasPermissionsToView(false);
      }
    }
  }, [isGetItemsError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setList([]);
    loadMore();
  };

  useEffect(() => {
    if (isMounted) {
      resetListAndLoadMore();
    } else {
      setIsMounted(true);
    }
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    if (currentTeam?.id) {
      try {
        getItemsQuery({
          teamId: currentTeam?.id,
          limit: 10,
          page: page.current,
          ...queryParams
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetRecallQuery = () => {
    resetListAndLoadMore();
  };

  const resetQuery = () => {
    page.current = 0;
    setList([]);
    setQueryParams(initialItemsQueryParamsState);
    setIsMounted(false);
    setIsError(false);
    setHasMore(true);
    setTotalListCount(0);
  };

  return {
    isLoading: isLoading,
    isFetching: isGetItemsFetching,
    totalListCount,
    list,
    isError,
    loadMore,
    resetQuery,
    resetRecallQuery,
    hasMore,
    isLoadingSuccess: isGetItemsSuccess,
    hasPermissionsToView,
    setQueryParams,
    queryParams
  };
};
