import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Permission, Permissions, TeamRole, memberRolePermissions } from 'containers/app/app.model';
import { getPermissions, getTeamRole } from 'containers/app/util';
import { RootState } from 'store/reducers';
import { PermissionItem } from './EditPermissionsModal';
import { getPermissionLevel } from './utils';
import { PaymentSystem } from 'containers/payouts/constants';

export interface EditPermissionsSliceState {
  role: TeamRole;
  permissions: Permissions;
  savedPermission: Permission;
  initialPermission: Permission;
}

const initialState: EditPermissionsSliceState = {
  role: TeamRole.MEMBER,
  permissions: memberRolePermissions,
  savedPermission: {
    role: TeamRole.MEMBER,
    permissions: memberRolePermissions
  },
  initialPermission: {
    role: TeamRole.MEMBER,
    permissions: memberRolePermissions
  }
};

export const editPermissionsSlice = createSlice({
  name: 'editPermissions',
  initialState,
  reducers: {
    resetEditPermissionsSlice: () => initialState,
    initializeEditPermissionsSliceFromAPI: (state, { payload }: PayloadAction<Permission>) => {
      state.role = payload.role;
      state.permissions = payload.permissions;
      state.initialPermission = {
        role: payload.role,
        permissions: payload.permissions
      };
    },

    changeRole: (state, { payload }: PayloadAction<TeamRole>) => {
      state.role = payload;
      state.permissions = getPermissions(payload).permissions;
    },

    changePermission: (
      state,
      { payload }: PayloadAction<{ permission: PermissionItem; paymentSystem: PaymentSystem }>
    ) => {
      const permissionToUpdate = state.permissions[payload.permission.rootKey];

      const updatedPermissionObject = {
        ...permissionToUpdate[payload.permission.parentKey],
        [payload.permission.key]: !payload.permission.enabled
      };

      const updatedLevel: string = getPermissionLevel(
        payload.permission.rootKey,
        updatedPermissionObject,
        payload.paymentSystem
      );

      const updatedPermission = {
        level: updatedLevel,
        [payload.permission.parentKey]: updatedPermissionObject
      };

      state.permissions[payload.permission.rootKey] = updatedPermission;
      const newPermissions = { ...state.permissions, [payload.permission.rootKey]: updatedPermission };
      state.role = getTeamRole(newPermissions, payload.paymentSystem);
    },

    updateSelectedPermission: state => {
      state.savedPermission = { role: state.role, permissions: state.permissions };
    },
    initializeCurrentPermissionWithSavedPermission: state => {
      state.role = state.savedPermission.role;
      state.permissions = state.savedPermission.permissions;
    }
  }
});

export const {
  initializeCurrentPermissionWithSavedPermission,
  initializeEditPermissionsSliceFromAPI,
  resetEditPermissionsSlice,
  changeRole,
  changePermission,
  updateSelectedPermission
} = editPermissionsSlice.actions;

export const selectTeamMemberRole = (state: RootState) => state.rootReducer.editPermissions.role;
export const selectSavedPermission = (state: RootState) => state.rootReducer.editPermissions.savedPermission;

export const selectInitialPermission = (state: RootState) => state.rootReducer.editPermissions.initialPermission;
export const selectEditSlicePermissions = (state: RootState) => state.rootReducer.editPermissions.permissions;
export const selectEditedPermission = (state: RootState) => {
  return { role: state.rootReducer.editPermissions.role, permissions: state.rootReducer.editPermissions.permissions };
};

export const selectTeamSettingsPermissions = (state: RootState) =>
  state.rootReducer.editPermissions.permissions.teamSettings;

export const selectInvoiceSettingsPermissions = (state: RootState) =>
  state.rootReducer.editPermissions.permissions.invoice;

export const selectFeesSettingsPermissions = (state: RootState) => state.rootReducer.editPermissions.permissions.fees;

export const selectRefundSettingsPermissions = (state: RootState) =>
  state.rootReducer.editPermissions.permissions.refund;

export const selectCustomersSettingsPermissions = (state: RootState) =>
  state.rootReducer.editPermissions.permissions.customers;

export const selectBankingSettingsPermissions = (state: RootState) =>
  state.rootReducer.editPermissions.permissions.banking;

export default editPermissionsSlice.reducer;
