export enum SidePanelTypes {
  PAYOUT_SUMMARY = 'PAYOUT_SUMMARY',
  PAYOUT_DETAILS = 'PAYOUT_DETAILS',
  RECEIPT_DETAILS = 'ReceiptDetails',
  RECEIPT_CREATE_REFUND = 'ReceiptCreateRefund',
  INVOICE_SHARE = 'InvoiceShare',
  REFUND_SUCCESS = 'RefundSuccess',
  INVOICE = 'Invoice',
  INVOICE_DETAILS = 'InvoiceDetails',
  REFUND = 'refund',
  REFUND_RECEIPT = 'RefundReceipt',
  RECEIPT_INITIATE_REFUND = 'ReceiptInitiateRefund',
  REFUND_FAILED = 'RefundFailed',
  DEFAULT = 'Default',
  INVOICE_DELETE = 'InvoiceDelete',
  INVOICE_RECEIPT_SHARE = 'InvoiceReceiptShare',
  INVOICE_MARK_PAID = 'InvoiceMarkPaid',
  CUSTOMER = 'Customer details',
  INVOICE_ATTACHMENTS = 'Invoice attachments'
}
