import DropdownWithSearch from 'components/dropdown-with-search/DropdownWithSearch';
import CustomDateRangePicker from 'components/react-date-range-picker/ReactDateRangePicker';
import { FC } from 'react';
import { joinTextArray } from 'utils/commonUtils';
import { pluckValuesFromArrayWithSpecificKey } from 'utils/formatters';
import { connectPayoutStatusFilter } from '../constants';
import CustomAmountFilter, { AmountFilter } from 'components/custom-amount-filter/CustomAmountFilter';

interface FilterSectionProps {
  startDate: string | null;
  setStartDate: (startDate: string | null) => void;
  endDate: string | null;
  setEndDate: (endDate: string | null) => void;
  payoutStatusDraft: string[];
  setPayoutStatusDraft: (selectedDraftArray: string[]) => void;
  selectedPayoutStatuses: string[];
  setSelectedPayoutStatuses: (selectedArray: string[]) => void;
  amountFilter: AmountFilter;
  setAmountFilter: (amountFilter: AmountFilter) => void;
}

const FilterSection: FC<FilterSectionProps> = ({
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  payoutStatusDraft,
  setPayoutStatusDraft,
  selectedPayoutStatuses,
  setSelectedPayoutStatuses,
  amountFilter,
  setAmountFilter
}) => {
  const getSelectedFilterText = () => {
    return joinTextArray(
      pluckValuesFromArrayWithSpecificKey(
        connectPayoutStatusFilter.filter(item => selectedPayoutStatuses.includes(item.key)),
        'value'
      )
    );
  };
  return (
    <div className="relative flex items-center gap-2.5">
      <CustomAmountFilter amountFilter={amountFilter} setAmountFilter={setAmountFilter} />
      <DropdownWithSearch
        filterId="payout-status-filter"
        data={connectPayoutStatusFilter}
        selectedDraftArray={payoutStatusDraft}
        setSelectedDraftArray={setPayoutStatusDraft}
        selectedArray={selectedPayoutStatuses}
        setSelectedArray={setSelectedPayoutStatuses}
        dataKey="value"
        dataIdKey="key"
        buttonPrimaryText="Status"
        buttonSecondaryText={getSelectedFilterText()}
        filterPlaceholderText="Filter by Payout status"
        allowSearch={false}
        showResultsCount={false}
        customClassName="mb-0"
      />
      <CustomDateRangePicker
        filterId="payout-date-filter"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        customClassName="mb-0"
      />
    </div>
  );
};

export default FilterSection;
