import { CaretRightIcon } from 'assets/icons';
import { PaymentSystem } from 'containers/payouts/constants';
import { ConnectPayout } from 'containers/payouts/payouts.model';
import { getPayoutStatusData, getSubText } from 'containers/payouts/utils';
import { FC } from 'react';
import { getAmountWithCurrency } from 'utils/amountUtils';
import DateUtils from 'utils/dateUtils';

interface PayoutCardProps {
  payoutData: ConnectPayout;
  onCardClick: (id: string, type: PaymentSystem) => void;
}

const PayoutCard: FC<PayoutCardProps> = ({ payoutData, onCardClick }) => {
  const { id, amount, status, arrivalAt, createdAt, updatedAt, paymentSystem } = payoutData;
  const { Icon, text, textColor } = getPayoutStatusData({ payoutStatus: status });

  return (
    <div
      className="flex w-full cursor-pointer items-center"
      onClick={() => onCardClick(id, paymentSystem as PaymentSystem)}>
      <Icon className="mr-2.5 shrink-0" />
      <div className="w-1/2 space-y-2">
        <div className="text-17px text-primaryText">{id}</div>
        <div className="max-w-[99%] text-sbase text-accentText">
          {getSubText({
            payoutStatus: status,
            isLIP: paymentSystem === PaymentSystem.LIP,
            arrivalAt: DateUtils.getDateInFormatWtTimeZone({
              date: new Date(arrivalAt),
              timeZone: 'UTC',
              dateFormat: 'dd MMM yyyy'
            }),
            createdAt: DateUtils.getDateInFormat({ date: new Date(createdAt), dateFormat: 'dd MMM yyyy' }),
            updatedAt: DateUtils.getDateInFormat({ date: new Date(updatedAt), dateFormat: 'dd MMM yyyy' })
          })}
        </div>
      </div>
      <div className="ml-auto space-y-2 justify-self-end text-right">
        <div className="text-17px text-tertiaryText">{getAmountWithCurrency(amount)}</div>
        <div className="text-sbase font-normal" style={{ color: textColor }}>
          {text}
        </div>
      </div>
      <CaretRightIcon className="justify-self-end" />
    </div>
  );
};

export default PayoutCard;
