import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import CatalogTopBar from 'containers/product-catalog/components/CatalogTopBar';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetCategorySlice, selectCategoryState } from '../categorySlice';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { useCreateCategoryMutation } from '../api';
import { isCategoryDataUpdated, prepareCategoryPayload } from '../util';
import { CategoryAPIPayload } from '../categories.model';
import { NotifierTypes } from 'constants/notifierConstants';
import ItemsSection from '../components/ItemsSection';
import CategoryNameSection from '../components/CategoryNameSection';
import { CategoryRouteState } from 'routes/types';
import { updateItemDetails } from 'containers/product-catalog/items/itemsSlice';
import { ItemFieldNames } from 'containers/product-catalog/items/types';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsCatalogCategory } from '../events';
import { constructArrayObjectAsString } from 'utils/commonUtils';
import { CatalogTopBarCategoryButton } from '../constants';
import { SaveChangesConfirmationPopup } from 'components';
import { CategoryInputErrors } from '../type';

const CreateCategory: FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [
    createCategoryMutation,
    { data: createCategorySuccessData, isSuccess: isCreateCategorySuccess, isLoading: isCreateCategoryLoading, isError }
  ] = useCreateCategoryMutation();
  const selectedCategoryState = useAppSelector(selectCategoryState);

  const [categoryNameError, setCategoryNameError] = useState('');
  const [showSaveChangesConfirmation, setShowSaveChangesConfirmation] = useState(false);
  const disableSaveBtn = !selectedCategoryState.categoryName;

  const location = useLocation();
  const routeState = location.state as CategoryRouteState;

  useEffect(() => {
    if (isCreateCategorySuccess) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Category successfully created'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
      dispatch(resetCategorySlice());

      if (routeState?.callbackURL) {
        dispatch(
          updateItemDetails({
            fieldName: ItemFieldNames.itemCategory,
            value: {
              id: createCategorySuccessData?.id,
              name: createCategorySuccessData?.name
            }
          })
        );
      }
      navigate(-1);
    }
  }, [isCreateCategorySuccess]);

  const handleSave = () => {
    setShowSaveChangesConfirmation(false);
    if (!selectedCategoryState.categoryName) {
      setCategoryNameError(CategoryInputErrors.Category_Name_Error);
      return;
    }
    const payload: CategoryAPIPayload = prepareCategoryPayload(selectedCategoryState, currentTeam?.id.toString());
    if (payload) {
      createCategoryMutation(payload);
    }
    logAnalyticEvent(CleverTapEventsCatalogCategory.webCatalogCategoryCreateCategorySave, {
      name: selectedCategoryState.categoryName,
      item: constructArrayObjectAsString(selectedCategoryState.lineItems, 'item')
    });
  };

  const handleExit = () => {
    if (isCategoryDataUpdated(selectedCategoryState)) {
      setShowSaveChangesConfirmation(true);
    } else {
      dispatch(resetCategorySlice());
      navigate(-1);
    }
  };

  const discardSaveChangesConfirmation = () => {
    setShowSaveChangesConfirmation(false);
    navigate(-1);
  };

  return (
    <div className="flex h-full w-full flex-col">
      <CatalogTopBar
        className="border border-borderGray"
        title="Create category"
        onPrimaryClick={handleSave}
        onReturn={handleExit}
        primaryBtnTitle="Save"
        disablePrimaryBtn={disableSaveBtn}
        primaryBtnId={CatalogTopBarCategoryButton.CreateCategorySaveButton}
        backButtonId={CatalogTopBarCategoryButton.CreateCategoryBackButton}
        isPrimaryButtonLoading={isCreateCategoryLoading}
      />
      <div className="flex h-full w-full flex-col items-center p-8">
        <div className="flex w-full max-w-[720px] basis-[50%] flex-col">
          <CategoryNameSection id={'edit-category-name'} error={categoryNameError} setError={setCategoryNameError} />
          <ItemsSection />
        </div>
      </div>
      {showSaveChangesConfirmation && (
        <SaveChangesConfirmationPopup
          onSaveChanges={handleSave}
          closeSaveConfirmation={discardSaveChangesConfirmation}
          onCloseIconClick={() => setShowSaveChangesConfirmation(false)}
        />
      )}
    </div>
  );
};

export default CreateCategory;
