export enum InvoiceVersion {
  THREE_POINT_ZERO = '3.0'
}

export enum InvoiceSourceEnum {
  WEB = 'Web',
  MOBILE = 'Mobile'
}

export enum InvoiceTimelineCTAActions {
  SHARE_INVOICE = 'share-invoice'
}
