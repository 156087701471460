/* eslint-disable indent */
import { FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { constructArrayObjectAsString, getInitials, getPageName } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { ArrowRightIcon, CaretDownIcon, EditIcon, NavCloseIcon, SendIcon, WhiteInfoIcon } from 'assets/icons';
import { CustomPopover, If } from 'components';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { LineItems, MoreMenu, TransactionCard } from './index';
import { VoidFn } from 'types/baseTypes';
import {
  useLazyDownloadInvoiceAsPDFQuery,
  useLazyDownloadPrintInvoiceAsPDFQuery,
  useLazyGetInvoiceByIdQuery
} from '../api';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamCustomersPermissions,
  selectCurrentTeamInvoicePermissions,
  selectUserDetails,
  showNotifier
} from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { useAppDispatch, useAppSelector } from 'hooks';

import { SidePanelTypes } from 'constants/sidePanelConstants';
import GenericError from 'components/genric-error/GenericError';
import { toggleIsFromPreview } from '../edit-invoice/invoicesEditSlice';
import { PopoverArrowTypes } from 'constants/common';
import { CleverTapEventsInvoices } from '../events';
import { logAnalyticEvent } from 'utils/analytics';
import { InfoMesssages } from 'types/infoMessages';
import SidePanel from 'components/side-panel/SidePanel';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE, FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import NoPermissions from 'components/no-permissions/NoPermissions';
import InvoiceTimeline from './InvoiceTimeline';
import { InvoiceStatus } from '../invoices.model';
import { getInvoiceStatusBackgroundColor } from '../utils';
import { TransactionStatus } from 'constants/invoicesConstants';

interface InvoicePanelProps {
  onClose?: VoidFn;
  routeToNextPage?: ({ type }: { type: SidePanelTypes; id: string }) => void;
}

const InvoicePanel: FC<InvoicePanelProps> = props => {
  const navigate = useNavigate();
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const notifications = useAppSelector(getNotifications);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const currentUser = useAppSelector(selectUserDetails);

  const {
    rootInvoiceId,
    invoiceId: invoiceIdFromUrl,
    rootReceiptId,
    rootPage,
    rootCustomerId,
    rootDisputeId
  } = useParams();
  const location = useLocation();

  const { handleSidePanelClose } = useOutletContext<any>();

  const [transactionsList, setTransactionsList] = useState([]);
  const [isViewAll, setIsViewAll] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [showBackArrow, setShowBackArrow] = useState(false);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const [downloadInvoiceAsPDF] = useLazyDownloadInvoiceAsPDFQuery();
  const [downloadPrintInvoiceAsPDF] = useLazyDownloadPrintInvoiceAsPDFQuery();

  const [getInvoiceByIdQuery, getInvoiceByIdState] = useLazyGetInvoiceByIdQuery();
  const { data: invoiceData, isSuccess: invoiceDataSuccess, isFetching: fetchingInvoiceData } = getInvoiceByIdState;

  useEffect(() => {
    return () => {
      getInvoiceByIdQuery({ invoiceId: invoiceIdFromUrl ? invoiceIdFromUrl : rootInvoiceId, teamId: currentTeam.id });
    };
  }, []);

  useEffect(() => {
    if (invoiceDataSuccess) {
      if (isCreatedBySelf()) {
        if (!invoicePermissions.viewInvoicesByTeam && !invoicePermissions.viewInvoicesBySelf) {
          setHasPermissionsToView(false);
        }
      } else if (!invoicePermissions.viewInvoicesByTeam) {
        setHasPermissionsToView(false);
      }
    }
  }, [invoicePermissions.viewInvoicesByTeam, invoicePermissions.viewInvoicesBySelf, invoiceData]);

  useEffect(() => {
    if (invoiceDataSuccess) {
      logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailsPanelOpen, {
        'Line Items': constructArrayObjectAsString(invoiceData.lineItems, 'Line Item'),
        Page: getPageName(location.pathname)
      });
    }
  }, [invoiceDataSuccess]);

  useEffect(() => {
    if (currentTeam?.id) {
      if (invoiceIdFromUrl) {
        getInvoiceByIdQuery({ invoiceId: invoiceIdFromUrl, teamId: currentTeam.id });
      } else if (rootInvoiceId) {
        getInvoiceByIdQuery({ invoiceId: rootInvoiceId, teamId: currentTeam.id });
      }
    }
  }, [invoiceIdFromUrl, rootInvoiceId, currentTeam]);

  useEffect(() => {
    let routeStateTimeout;
    const routeState = location.state as SidePanelRouteState;
    if (routeState?.fromSidePanel) {
      setShowBackArrow(true);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const { customer, currencySymbol, transactionDetails, invoiceId, isModifiable } = invoiceData ?? {};
  const { transactions, totalCount } = transactionDetails ?? {};

  const TXNLIMIT = 3;

  const dispatch = useAppDispatch();

  const alterTxnList = (endIndex: number) => {
    const transactionsArray = transactions.slice(0, endIndex);
    setTransactionsList(transactionsArray);
  };

  const isCreatedBySelf = () => {
    return invoiceData.merchantId === currentUser.id;
  };

  useEffect(() => {
    if (transactionDetails && transactionDetails.transactions) {
      const sliceEndIndex = totalCount > TXNLIMIT ? TXNLIMIT : totalCount;
      alterTxnList(sliceEndIndex);
    }
  }, [transactionDetails]);

  const isShareEnabled = () => {
    return (
      customerPermissions.viewCustomersCreatedByTeam ||
      customerPermissions.editCustomersCreatedByTeam ||
      customerPermissions.editExistingCustomers
    );
  };

  const invoiceDetails = invoiceDataSuccess
    ? [
        {
          title: 'Invoice Number',
          data: invoiceData.invoiceNumber,
          id: 1
        },
        {
          title: 'Customer',
          data: `${customer.name} ${customer.lastName}`,
          id: 2
        },
        {
          title: 'Invoice Date',
          data: invoiceData.createdAt,
          id: 3
        },
        {
          title: 'Created by',
          data: (
            <div className="flex items-center gap-1">
              <CustomAvatar
                src={invoiceData.merchantProfilePicture}
                width="w-4"
                height="h-4"
                fontStyle="text-[8px] text-tertiaryText">
                {getInitials(invoiceData.createdBy)}
              </CustomAvatar>
              <div className="truncate">{invoiceData.createdBy}</div>
            </div>
          ),
          id: 4
        }
      ]
    : [];

  const handleInvoiceShare = () => {
    if (invoiceData.invoiceStatus === InvoiceStatus.PENDING) {
      if (rootInvoiceId) {
        if (invoiceIdFromUrl) {
          navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice/${invoiceData?.invoiceId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else {
          navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        }
      } else if (rootPage) {
        navigate(
          `/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-pending-invoice/${invoiceData?.invoiceId}`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      } else if (rootReceiptId) {
        navigate(
          `/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-pending-invoice/${invoiceData?.invoiceId}`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-pending-invoice/${invoiceData?.invoiceId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootDisputeId) {
        navigate(
          `/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-pending-invoice/${invoiceData?.invoiceId}`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      }
    } else if (rootInvoiceId) {
      if (invoiceIdFromUrl) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-invoice/${invoiceData?.invoiceId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-invoice`, { state: FROM_SIDE_PANEL_ROUTE_STATE });
      }
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const bottomBar =
    invoiceData?.invoiceStatus !== InvoiceStatus.PENDING
      ? [
          {
            id: 'invoice-sidepanel-bottom-share-button',
            icon: (
              <div>
                <SendIcon className="h-5" />
              </div>
            ),
            disabledReason: InfoMesssages.shareReceiptNoPermissionMessage,
            btnText: 'Share Invoice',
            isDisabled: !isShareEnabled(),
            onClickHandler: () => {
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailReShare, {
                'Invoice Id': invoiceData?.invoiceId
              });
              handleInvoiceShare();
            }
          }
        ]
      : [
          {
            id: 'invoice-sidepanel-bottom-reshare-button',
            isDisabled: !isShareEnabled(),
            btnText: 'Share Invoice',
            disabledReason: InfoMesssages.shareInvoiceNoPermissionMessage,
            onClickHandler: () => {
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailReShare);
              handleInvoiceShare();
            }
          },
          {
            id: 'invoice-sidepanel-bottom-edit-button',
            icon: (
              <div>
                <EditIcon className="h-5" />
              </div>
            ),
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            btnText: 'Edit',
            disabledReason: InfoMesssages.editInvoiceNoPermissionMessage,
            isDisabled: !isModifiable,
            onClickHandler: () => {
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailEdit);
              dispatch(toggleIsFromPreview(false));
              navigate(`/invoices/edit/${invoiceId}`);
            }
          }
        ];

  const moreAnchorElem = () => (
    <div
      id="invoice-sidepanel-bottom-more-button"
      className="flex shrink grow cursor-pointer items-center justify-center gap-2 bg-secondary
    py-3.5 text-sbase font-semibold text-primary hover:opacity-95">
      <div>More</div>
      <div className="path-stroke-current path">
        <CaretDownIcon className="rotate-180" />
      </div>
    </div>
  );

  const toggleIsMoreOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const closeMoreOption = () => {
    setIsMoreOpen(false);
  };

  const toggleTxnViewAll = () => {
    alterTxnList(isViewAll ? TXNLIMIT : totalCount);
    setIsViewAll(prev => !prev);
  };

  const onSidePanelClose = () => {
    if (showBackArrow) {
      navigate(-1);
    } else {
      handleSidePanelClose();
    }
  };

  const getPendingDays = () => {
    const days = DateUtils.getDifferenceInDays({ secondaryDate: new Date(invoiceData.createdAt) });
    if (days === 0) {
      return 'Pending today';
    }
    return `Pending ${days} day${days === 1 ? '' : 's'}`;
  };

  const displayAmount = () => {
    if (invoiceData.isPaid) {
      let amountText = `${getAmountWithCurrency(invoiceData.payableAmount)} Payable amount`;
      if (+invoiceData.tipAmount > 0) {
        amountText = `${amountText} + ${getAmountWithCurrency(invoiceData.tipAmount)} Tip`;
      }
      return amountText;
    }
    return 'Total Amount';
  };

  const onExportAsPdf = () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    downloadInvoiceAsPDF({ invoiceId, teamId: currentTeam.id });

    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailMoreExportPDF, {
      Page: getPageName(location.pathname)
    });
  };

  const onPrintPdf = () => {
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailMorePrint, {
      Page: getPageName(location.pathname)
    });
    downloadPrintInvoiceAsPDF({ invoiceId, teamId: currentTeam.id });
  };

  const handleTxnClick = (txnId: string, status = '') => {
    if (invoicePermissions.viewReceiptsByTeam || invoicePermissions.viewReceiptsBySelf) {
      if (status === TransactionStatus.REFUNDED) {
        if (rootInvoiceId) {
          navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/refund-receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootPage) {
          navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/refund-receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootReceiptId) {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootCustomerId) {
          navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/refund-receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootDisputeId) {
          navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/refund-receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        }
      } else {
        if (rootInvoiceId) {
          navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootPage) {
          navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootReceiptId) {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootCustomerId) {
          navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else if (rootDisputeId) {
          navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/receipt/${txnId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        }
      }
    }
  };

  const handleDisputeClick = (dsId: string) => {
    if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/dispute/${dsId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const getInvoiceName = () => {
    if (customer.name || customer.lastName) {
      return `${customer.name} ${customer.lastName}`;
    }
    return invoiceData.invoiceNumber;
  };
  const onInfoClick = (id, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;

    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const onInvoiceDetailsClick = () => {
    if (rootInvoiceId) {
      if (invoiceIdFromUrl) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/invoice-details/${invoiceIdFromUrl}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/invoice-details`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else if (rootPage) {
      navigate(
        `/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/invoice-details/
        ${invoiceData.invoiceId}`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/invoice-details/${invoiceData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/invoice-details/${invoiceData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/invoice-details/${invoiceData.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleCustomerClick = () => {
    if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/customer/${customer.id}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/customer/${customer.id}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/customer/${customer.id}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/customer`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/customer/${customer.id}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const getSignatureText = () => {
    if (invoiceData.isPaid) {
      if (invoiceData.isInvoiceSigned) {
        return 'Signed';
      } else if (invoiceData.isManualPayment) {
        return '-';
      }
    }
    return 'Pending';
  };

  const getInvoiceStatusText = status => {
    switch (status) {
      case InvoiceStatus.PAID:
      case InvoiceStatus.PAID_QR:
        return 'Paid';
      case InvoiceStatus.PENDING:
        return getPendingDays();
      case InvoiceStatus.PROCESSING:
        return 'Processing';
      case InvoiceStatus.REFUNDED:
        return 'Refunded';
      case InvoiceStatus.REFUND_INITIATED:
        return 'Refund Initiated';
      default:
        return status;
    }
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="flex h-full w-96 flex-col">
        {fetchingInvoiceData ? (
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          invoiceDataSuccess && (
            <>
              <div className="flex items-center gap-2 py-5 px-4">
                <div onClick={onSidePanelClose} className="cursor-pointer">
                  {showBackArrow ? (
                    <ArrowRightIcon className="rotate-180" id="invoice-panel-back-arrow" />
                  ) : (
                    <NavCloseIcon id="invoice-panel-close" />
                  )}
                </div>
                <div id="invoice-panel-head" className="text-17px font-semibold text-headingGray">
                  Invoice - {getInvoiceName()}
                </div>
              </div>
              {hasPermissionsToView ? (
                <>
                  <div className="customNormalScroll flex grow flex-col gap-5 overflow-y-auto px-4">
                    <div className="rounded-md border border-dark-gray bg-primary">
                      <div className="flex flex-col items-center justify-center gap-2 border-b border-secondaryBtn p-8">
                        <div className="text-sbase font-semibold text-accentText">{displayAmount()}</div>
                        <div id="invoice-panel-total-amount" className="text-[30px] font-bold text-tertiaryText">
                          {getAmountWithCurrency(invoiceData.isPaid ? invoiceData.amount : invoiceData.payableAmount)}
                        </div>
                        <div
                          id="invoice-panel-payment-status"
                          className="rounded-md px-2 py-1 text-px13 leading-[17px] text-tertiaryText"
                          style={{
                            backgroundColor: getInvoiceStatusBackgroundColor(invoiceData.invoiceStatus)
                          }}>
                          {getInvoiceStatusText(invoiceData.invoiceStatus)}
                        </div>
                      </div>
                      <div id="receipts-invoice-details-card" className="flex flex-col gap-4 p-4">
                        {invoiceDetails.map(detail => (
                          <div
                            className="flex items-center justify-between text-sbase font-semibold leading-5"
                            key={detail.id + detail.title}>
                            <div className="text-accentText">{detail.title}</div>
                            <div id={`${detail.title}-id`} className="max-w-[75%] truncate text-tertiaryText">
                              {detail.data}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>

                    {/* signature section */}
                    <If condition={invoiceData.isSignatureEnabled}>
                      <div
                        id="invoice-customer-signature-card-click"
                        className={`flex cursor-pointer flex-col gap-5
                      rounded-md border border-dark-gray bg-primary p-4`}>
                        <div className="flex justify-between gap-1 text-sbase font-semibold">
                          <div id="invoice-panel-customer-signature" className="truncate text-accentText">
                            Customer signature
                          </div>
                          <div id="invoice-panel-customer-sign-status" className="truncate text-tertiaryText">
                            {getSignatureText()}
                          </div>
                        </div>
                        <If condition={invoiceData.isInvoiceSigned}>
                          <div className="flex justify-between gap-1 text-sbase font-semibold text-tertiaryText">
                            <div id="invoice-panel-customer-signature-cust-name" className="truncate">
                              {invoiceData.signatureCustomerName}
                            </div>
                            <div id="invoice-panel-customer-signature-date" className="shrink-0">
                              {DateUtils.getDateInFormatwithTimezone({
                                date: new Date(invoiceData.signedAt),
                                dateFormat: 'dd MMM yyyy, h:mm a'
                              })}
                            </div>
                          </div>
                        </If>
                      </div>
                    </If>

                    <If condition={Boolean(customer.id)}>
                      <div
                        id="invoice-customer-info-card-click"
                        onClick={handleCustomerClick}
                        className={`flex cursor-pointer items-center justify-between rounded-md
                    border border-dark-gray bg-primary p-4`}>
                        <div className="max-w-[95%] space-y-[5px] text-sbase font-semibold">
                          <div id="invoice-panel-customer-name" className="truncate text-headingGray">
                            {`${customer.name} ${customer.lastName}` || '-'}
                          </div>
                          <div id="invoice-panel-customer-phone-number" className="truncate text-accentText">
                            {customer.phoneNumber || '-'}
                          </div>
                          <div id="invoice-panel-customer-email" className="truncate text-accentText">
                            {customer.email || '-'}
                          </div>
                        </div>
                        <div>
                          <CaretDownIcon className="-rotate-90" />
                        </div>
                      </div>
                    </If>

                    <InvoiceTimeline
                      invoiceStatus={invoiceData.invoiceStatus}
                      handleShare={handleInvoiceShare}
                      timeline={invoiceData.timeline}
                    />
                    <div className="rounded-md border border-dark-gray bg-primary">
                      <LineItems invoiceData={invoiceData} borderStyle={invoiceData.isPaid ? 'rounded-b-md' : ''} />
                      <div
                        id="view-detailed-invoice-click"
                        onClick={onInvoiceDetailsClick}
                        className="flex cursor-pointer items-center justify-between p-4">
                        <div className="text-sbase font-semibold text-secondary">View detailed invoice</div>
                        <div>
                          <CaretDownIcon className="-rotate-90" />
                        </div>
                      </div>
                    </div>
                    <If condition={totalCount > 0}>
                      <div className="mb-5 rounded-md border border-dark-gray bg-primary p-4">
                        <div
                          className="border-b border-borderGray pb-3
                    text-sbase font-semibold text-primaryText">
                          Transactions
                        </div>
                        <div>
                          {transactionsList?.map(transaction => (
                            <div
                              key={transaction.ID}
                              className="border-b border-borderGray py-4 last:border-b-0 only:border-b-0">
                              <TransactionCard
                                data={transaction}
                                currencySymbol={currencySymbol}
                                onTxnClick={handleTxnClick}
                                onDisputeClick={handleDisputeClick}
                              />
                            </div>
                          ))}
                          <If condition={totalCount > TXNLIMIT}>
                            <div
                              className="leading 5 cursor-pointer pt-3 text-sbase font-semibold text-secondary"
                              onClick={toggleTxnViewAll}>
                              {isViewAll ? 'View less' : 'View all'}
                            </div>
                          </If>
                        </div>
                      </div>
                    </If>
                  </div>
                  <div className="mt-auto flex justify-between gap-px justify-self-end">
                    {bottomBar.map(item => (
                      <button
                        onClick={() => {
                          if (item.isDisabled) {
                            onInfoClick(item.id, item.disabledReason);
                          } else {
                            item.onClickHandler();
                          }
                        }}
                        id={`invoice-side-panel-${item.btnText}-button`}
                        key={item.id + item.btnText}
                        className={twMerge(`flex shrink grow cursor-pointer items-center justify-center gap-2
                  bg-secondary py-3.5 font-semibold text-primary hover:opacity-95            
                  ${item.isDisabled ? 'bg-[#9BBADD] hover:opacity-100' : ''}
                  }`)}>
                        <div className={`${item.isDisabled && 'opacity-50'}`}>{item.icon}</div>
                        <div className={`text-sbase ${item.isDisabled && 'opacity-50'}`}>{item.btnText}</div>
                        {item.isDisabled && <WhiteInfoIcon />}
                      </button>
                    ))}
                    <div
                      className="flex shrink grow cursor-pointer items-center justify-center
                gap-2 bg-secondary hover:opacity-95"
                      onClick={toggleIsMoreOpen}>
                      <CustomPopover
                        show={isMoreOpen}
                        anchorComponent={moreAnchorElem}
                        onTogglePopover={toggleIsMoreOpen}
                        onClosePopover={closeMoreOption}
                        offset="-right-3 bottom-14"
                        minWidth="min-w-[160px]"
                        showArrow={true}
                        arrowType={PopoverArrowTypes.BOTTOM}
                        highlightAnchor={false}>
                        {
                          <MoreMenu
                            onPrintPdf={onPrintPdf}
                            onExportPdf={onExportAsPdf}
                            type={invoiceData.invoiceStatus === InvoiceStatus.PENDING ? 'unpaid' : 'paid'}
                            isModifiable={invoiceData.isModifiable}
                          />
                        }
                      </CustomPopover>
                    </div>
                  </div>
                </>
              ) : (
                <NoPermissions />
              )}
            </>
          )
        )}
        {!fetchingInvoiceData && !invoiceDataSuccess && <GenericError handleErrorBack={onSidePanelClose} />}
      </div>
    </SidePanel>
  );
};

export default InvoicePanel;
