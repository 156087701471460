import { FC } from 'react';
import { ReactIconType } from 'types/baseTypes';

const LineItemPlusReactIcon: FC<ReactIconType> = props => (
  <svg
    onClick={props.onClick}
    id={props.id}
    className={props.className}
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21ZM16 13C16.6 13 17 12.6 17 12C17 11.4 16.6 11 16 11H13V8C13 7.4 12.6 7 12 7C11.4 7 11 7.4 11 8V11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H11V16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V13H16Z"
      fill="#4F4F4F"
    />
    <mask
      id={`line-item-plus-icon-mask-${props.id}`}
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="22"
      height="22">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M12 1C5.9 1 1 5.9 1 12C1 18.1 5.9 23 12 23C18.1 23 23 18.1 23 12C23 5.9 18.1 1 12 1ZM12 21C7 21 3 17 3 12C3 7 7 3 12 3C17 3 21 7 21 12C21 17 17 21 12 21ZM16 13C16.6 13 17 12.6 17 12C17 11.4 16.6 11 16 11H13V8C13 7.4 12.6 7 12 7C11.4 7 11 7.4 11 8V11H8C7.4 11 7 11.4 7 12C7 12.6 7.4 13 8 13H11V16C11 16.6 11.4 17 12 17C12.6 17 13 16.6 13 16V13H16Z"
        fill="white"
      />
    </mask>
    <g mask={`url(#line-item-plus-icon-mask-${props.id})`}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H24V24H0V0Z" fill="#3876BB" />
    </g>
  </svg>
);

export default LineItemPlusReactIcon;
