// TODO Move interfaces to invoices.model.ts and delete this file
export interface GetCustomersPayload {
  teamId: number;
}

export interface GetMerchantsPayload {
  teamId: number;
}

export interface GetCustomersResponse {
  customers: Customer[];
  totalCount: number;
}

export interface Customer {
  id?: string;
  ID: string;
  name: string;
  phoneNumber: string;
  email: string;
  merchantId: string;
  billingAddress: string;
  lastName: string;
  street: string;
  cityStateCountry: string;
  zipCode: string;
}

export interface Merchant {
  userId: number;
  id: number;
  title: string;
  completed: boolean;
}

export enum ShareReceiptPageState {
  MAIN = 'Main',
  SHARE_QR = 'shareQR'
}
