import { BackIcon, CircleCloseIcon, PlusIconBlue } from 'assets/icons';
import { ErrorScreenWithRetry, If, SearchBar } from 'components';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import { usePaginatedGetCatalogItemsQuery } from 'containers/invoices/hooks/usePaginatedGetCatalogItemsQuery';
import { IInvoiceListingCategory } from 'containers/invoices/invoices.model';
import { IPaginatedItem } from 'containers/product-catalog/items/items.model';
// eslint-disable-next-line max-len
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { FC, useCallback, useRef, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { checkIsAccessTokenExpiry } from 'utils/apiUtils';
import { debounce } from 'utils/commonUtils';

interface AddItemFromCategoryBody {
  className?: string;
  onGoBack: VoidFn;
  category: IInvoiceListingCategory;
  onClose: VoidFn;
  onItemClick: (catalogItem: IPaginatedItem) => void;
  onCreateItem: VoidFn;
}

const AddItemFromCategoryBody: FC<AddItemFromCategoryBody> = ({
  onItemClick,
  category,
  className = '',
  onGoBack,
  onClose,
  onCreateItem
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const {
    list: itemList,
    isLoading: isLoadingItems,
    loadMore: loadMoreItems,
    hasMore: hasMoreItems,
    setQueryParams: setItemsQueryParams,
    queryParams: itemsQueryParams,
    isError: isLoadingItemsError,
    resetRecallQuery: resetRecallItemsQuery,
    apiErrorCode: getItemsApiErrorCode
  } = usePaginatedGetCatalogItemsQuery({ categoryId: category.id, includeTax: true });

  const categoryItemListLoader = useRef(loadMoreItems);

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: categoryItemListLoader,
    threshold: 0.5
  });

  const initiateItemSearch = (searchTerm?: string) => {
    setItemsQueryParams(prevQueryParams => ({
      ...prevQueryParams,
      search: searchTerm === '' ? null : searchTerm
    }));
  };

  const debouncedItemSearchHandler = useCallback(debounce(initiateItemSearch, 500), [itemsQueryParams]);

  const handleSearch = (value): void => {
    debouncedItemSearchHandler(value);
  };

  const onClearSearch = () => {
    setSearchText('');
    handleSearch(null);
  };

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    handleSearch(searchTerm);
  };

  const handleItemClick = (item: IPaginatedItem): void => {
    onItemClick(item);
  };

  const handleReload = (): void => {
    resetRecallItemsQuery();
  };

  return (
    <div className={`absolute flex h-full w-full grow flex-col py-10 ${className}`}>
      <CircleCloseIcon className="absolute top-2 right-2 shrink-0 cursor-pointer" onClick={onClose} />
      <div className="flex flex-row items-center gap-2">
        <div className="mr-[22px] pl-10 text-xl font-semibold text-heading">Items</div>

        <div onClick={onCreateItem} className="flex cursor-pointer items-center">
          <div className="mr-2 h-6 w-6 shrink-0">
            <PlusIconBlue />
          </div>
          <div className="text-17px font-semibold text-secondary">Create item</div>
        </div>
      </div>

      <div className="mt-5 px-10">
        <SearchBar
          id="search-bar"
          handleClear={onClearSearch}
          handleChange={onSearchTermChange}
          value={searchText}
          placeholder="Search"
          wrapperStyle="w-full h-10 border border-borderGray rounded"
          showSearchIcon={true}
          autoFocus={true}
          showCrossIcon={true}
        />
      </div>

      <div className="px-10">
        <div className="mt-[10px] flex h-[50px] flex-row items-center bg-secondaryBtn px-7 py-4">
          <BackIcon
            id={'add-item-from-category-back-button'}
            className="mr-2 shrink-0 cursor-pointer"
            onClick={onGoBack}
          />
          <div className="text-sbase font-semibold text-heading">{category.name}</div>
        </div>
      </div>

      <div className="customNormalScroll mb-5 h-full max-h-[800px] overflow-y-auto px-10">
        <If condition={isLoadingItemsError && !isLoadingItems && !checkIsAccessTokenExpiry(+getItemsApiErrorCode)}>
          <ErrorScreenWithRetry handleReload={handleReload} />
        </If>

        <If condition={itemList?.length != 0}>
          {itemList.map(item => (
            <div
              onClick={() => handleItemClick(item)}
              className="flex  min-h-[77px] w-full cursor-pointer flex-row  items-center
               border-b border-secondaryBtn py-5 pl-7 pr-6 hover:bg-highlightGray"
              key={item.id}>
              <div className="max-w-[50%] basis-1/2 break-words text-sbase font-semibold text-primaryText">
                {item.name}
              </div>
              <div className="ml-auto basis-1/2 text-right text-sbase font-semibold text-primaryText">
                {getAmountWithCurrency(item.unitPrice)}
              </div>
            </div>
          ))}
        </If>

        <If condition={!isLoadingItems && hasMoreItems}>
          <div className="h-2" ref={setIntersectionElement}></div>
        </If>
        <If condition={isLoadingItems}>
          <div className={`${itemList.length === 0 ? 'pt-10' : 'pt-0'}`}>
            <InfinteLoadingSpinner />
          </div>
        </If>
      </div>
    </div>
  );
};

export default AddItemFromCategoryBody;
