import { Divider } from '@material-ui/core';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { TeamRole } from 'containers/app/app.model';
import { teamMemberRoles } from 'containers/manage-team/edit-permissions/constants';
import { useAppSelector } from 'hooks';
import { FC } from 'react';
import { getInitials } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import { bgColorSelectorWithASCII } from 'utils/styleUtils';
import { selectMyAccountState } from '../myAccountSlice';

interface MyTeamsSectionProps {
  className?: string;
}

const tableHeaders: Record<string, Record<string, string>> = {
  teamName: { title: 'Team Name', width: 'w-1/4' },
  role: { title: 'Role type', width: 'w-[15%]' },
  joinedDate: { title: 'Joined on', width: 'w-[30%]' }
};

const MyTeamsSection: FC<MyTeamsSectionProps> = () => {
  const selectedMyAccountState = useAppSelector(selectMyAccountState);
  const { merchantTeams } = selectedMyAccountState.teamDetails;
  return (
    <div className="flex flex-col xl:w-[1000px] 2xl:w-[1200px]">
      <div className="mt-10 text-xl font-bold text-primaryText">My teams</div>
      <div>
        <Divider className="mt-3" />
        <table className="mb-10 w-full table-auto">
          <thead className="sticky top-[-20px] z-30 bg-primary">
            <Divider className="absolute top-[3.7rem] w-full bg-borderGray" />
            <tr className="text-slateGrey text-left text-xs lg:text-base [&>*]:py-5">
              {Object.values(tableHeaders).map((header, index) => {
                return (
                  <th
                    className={`max-w-[120px] text-sbase font-semibold text-primaryText ${header.width}`}
                    key={header.title + index}>
                    {header.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {merchantTeams.map((merchantTeam, index) => (
              <tr
                key={merchantTeam.id}
                id={`my-team-table-row-${index + 1}`}
                className={'cursor-pointer border-b border-secondaryBtn align-top [&>*]:py-5 [&>*]:pr-2'}>
                {/* team name */}
                <td className="max-w-[180px]">
                  <div className={'flex items-center'}>
                    <CustomAvatar
                      src={merchantTeam.profileImageUrl}
                      label={getInitials(merchantTeam.name)}
                      width="w-[40px]"
                      height="h-[40px]"
                      fontStyle="text-[17px] text-primaryText font-semibold leading-5 font-lato"
                      background={bgColorSelectorWithASCII(merchantTeam.name)}
                    />
                    <div className="ml-5 flex flex-col gap-1 text-sbase text-primaryText ">
                      <div
                        className="max-w-[80px] truncate font-semibold md:max-w-[120px] 
                      lg:max-w-[180px] xl:max-w-[220px] 2xl:max-w-[250px]">
                        {merchantTeam.name}
                      </div>
                      <div className="font-normal">{merchantTeam.profession}</div>
                    </div>
                  </div>
                </td>
                {/* role type */}
                <td className="max-w-[120px]">
                  <div className="text-sbase text-primaryText">
                    <div className="truncate capitalize">
                      {merchantTeam.role === TeamRole.SUPER_ADMIN ? teamMemberRoles[4].name : merchantTeam.role}
                    </div>
                  </div>
                </td>
                {/* joined on */}
                <td className="max-w-[120px]">
                  <div className="flex flex-col gap-2">
                    <div className="truncate text-sbase text-primaryText">
                      {DateUtils.getDateInFormat({
                        date: new Date(merchantTeam.dateOfJoining),
                        dateFormat: 'dd MMM yyyy'
                      })}
                    </div>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default MyTeamsSection;
