import { REGEX } from 'constants/common';

export const getStringFiller = (text: string | null, filler = '-') => {
  if (!text || text.length === 0) {
    return filler;
  }
  return text;
};

export const camelCaseToTitleCase = (text: string) => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isValidEmail = (email: string) => {
  if (REGEX.email.test(email) || email.length === 0) {
    let isValid = true;
    const inValidDomains = ['..', '.con', '.comn', '.cok', '.don', '.cim', 'gnail.com', 'gmal.com'];
    inValidDomains.map(_ => {
      if (email.endsWith(_)) {
        isValid = false;
      }
    });
    return isValid;
  }
  return false;
};

export const isFacebookLinkValid = (facebookLink: string) => {
  return REGEX.facebookLinkRegex.test(facebookLink) || facebookLink.length === 0;
};

export const isYelpLinkValid = (yelpLink: string) => {
  return REGEX.yelpLinkRegex.test(yelpLink) || yelpLink.length === 0;
};

export const isWebsiteValid = (website: string) => {
  return REGEX.websiteRegex.test(website) || website.length === 0;
};

export const isPhoneNumberValid = (phoneNumber: string) => {
  return REGEX.phoneNumber.test(phoneNumber) || phoneNumber.length === 0;
};
