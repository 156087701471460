export enum CleverTapEventsDisputes {
  webDisputesDetailRespondToDisputeClick = 'Web_Disputes_Detail_Respond_To_Dispute_Click',
  webDisputesListExport = 'Web_Disputes_List_Export',
  webDisputesListSearch = 'Web_Disputes_List_Search',
  webDisputesListFilter = 'Web_Disputes_List_Filter',
  webDisputesListDisputeDetails = 'Web_Disputes_List_Dispute_Details',
  webDisputesDetailClaimDocumentDownload = 'Web_Disputes_Detail_Claim_Document_Download',
  webDisputesDetailIssuingBankResponseDownload = 'Web_Disputes_Detail_Issuing_Bank_Response_Download',
  webDisputesDetailViewSubmittedEvidence = 'Web_Disputes_Detail_View_Submitted_Evidence',
  webDisputesRespondToDispute = 'Web_Disputes_Respond_To_Dispute',
  webDisputesUploadEvidence = 'Web_Disputes_Upload_Evidence',
  webDisputesUploadEvidenceSucsess = 'Web_Disputes_Upload_Evidence_Success',
  webDisputesUploadEvidenceFailed = 'Web_Disputes_Upload_Evidence_Failed',
  webDisputesSubmitEvidence = 'Web_Disputes_Submit_Evidence',
  webDisputesLostAccepted = 'Web_Disputes_Lost_Accepted'
}
