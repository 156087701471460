import React, { FC, Fragment, useCallback, useContext, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import OutsideClickHandler from 'react-outside-click-handler';
import { Popover, Transition } from '@headlessui/react';
import { debounce } from 'utils/commonUtils';
import { ICustomer } from 'containers/customers/customers.model';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useLazyGetCustomersListQuery, useLazyGetRecentSearchedCustomersQuery } from 'containers/customers/api';
import {
  AccountGreyIcon,
  CircularDotIcon,
  MailIIcon,
  MapIcon,
  PencilIcon,
  PhoneIcon,
  PlusIconBlue
} from 'assets/icons';
import { If } from 'components';
import CreateCustomer from 'containers/create-customer/CreateCustomer';
import { CreateCustomerModalTypes } from 'constants/CustomerConstants';
import { Divider } from '@material-ui/core';
import { InvoiceTabEnum } from '../constants';
import {
  disableCustomerNotSelectedError,
  enableCustomerNotSelectedError,
  removeInvoiceCustomer,
  selectInvoiceCustomer,
  selectShowCustomerNotSelectedError,
  updateInvoiceCustomer
} from 'containers/invoices/InvoicesSlice';
import { Customer } from 'containers/invoices/invoice.model';
import CrossIconImage from 'assets/icons/CrossBoundaryIcon.png';

interface BillToSectionProps {
  className?: string;
  context: React.Context<any>;
}

interface SearchSuggestionsPanelProps {
  onSelectListItem: (customer: ICustomer) => void;
}

const BillToSection: FC<BillToSectionProps> = ({ context }) => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { selectedInvoiceTab, setSelectedInvoiceTab } = useContext<any>(context);
  const dispatch = useAppDispatch();
  const selectedCustomerReducter = useAppSelector(selectInvoiceCustomer);
  const showCustomerNotSelectedError = useAppSelector(selectShowCustomerNotSelectedError);

  const [isSuggestionsListVisible, setIsSuggestionsListVisible] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    selectedCustomerReducter as unknown as ICustomer
  );

  const [typeOfCustomerModal, setTypeOfCustomerModal] = useState<
    CreateCustomerModalTypes.EDIT_CUSTOMER | CreateCustomerModalTypes.CREATE_CUSTOMER
  >(CreateCustomerModalTypes.CREATE_CUSTOMER);
  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useState(false);

  const [getCustomersList, getCustomersListQueryState] = useLazyGetCustomersListQuery();
  const [getRecentSearchedCustomers, getRecentSearchedCustomersQueryState] = useLazyGetRecentSearchedCustomersQuery();

  const { data: getCustomersResponse, isSuccess: getCustomersSuccess } = getCustomersListQueryState;

  const { data: getRecentSearchedCustomersResponse, isSuccess: getRecentSearchedCustomersSuccess } =
    getRecentSearchedCustomersQueryState;

  const isTabSelected = selectedInvoiceTab === InvoiceTabEnum.BILL_TO;

  useEffect(() => {
    getRecentSearchedCustomers({ teamId: currentTeam?.id });
  }, []);

  const initiateSearch = (searchTerm?: string) => {
    getCustomersList({ search: searchTerm, teamId: currentTeam?.id, page: 0, limit: 20 });
  };

  const debouncedSearchHandler = useCallback(debounce(initiateSearch, 500), []);

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    debouncedSearchHandler(searchTerm);
  };

  const onSelectSuggestedItem = customerSelected => {
    setSelectedCustomer(customerSelected);
    dispatch(updateInvoiceCustomer({ ...customerSelected, id: null }));
    dispatch(disableCustomerNotSelectedError());
    setIsSuggestionsListVisible(false);
  };

  const onOutSideClick = () => {
    setIsSuggestionsListVisible(false);
    if (searchText !== '') {
      setSearchText('');
      dispatch(enableCustomerNotSelectedError());
    }
  };

  const onCustomerCreate = (customer: ICustomer) => {
    setOpenCreateCustomerModal(false);
    dispatch(updateInvoiceCustomer({ ...customer, id: null }));
    dispatch(disableCustomerNotSelectedError());
    setSelectedCustomer(customer);
  };

  useEffect(() => {
    setSelectedCustomer(selectedCustomerReducter);
  }, [selectedCustomerReducter]);

  const openEditCustomerModal = () => {
    setSelectedInvoiceTab(InvoiceTabEnum.BILL_TO);
    setTypeOfCustomerModal(CreateCustomerModalTypes.EDIT_CUSTOMER);
    setOpenCreateCustomerModal(true);
  };

  const onFocusOutOfTextField = () => {
    // setSearchText('');
    // dispatch(enableCustomerNotSelectedError());
  };

  const CustomersListDropdown = () => {
    const customersList =
      searchText.length > 2 ? getCustomersResponse?.customers : getRecentSearchedCustomersResponse?.customers;
    return (
      <>
        {customersList?.map((customer: ICustomer, index: number) => {
          return (
            <div
              onClick={e => {
                e.preventDefault();
                onSelectSuggestedItem(customer);
              }}
              className={'flex flex-row py-2 px-5 hover:bg-borderGray'}
              key={customer.ID}>
              <div className="text-lg font-semibold leading-5 text-headingGray" key={customer.ID}>
                {`${customer.name} ${customer.lastName}`}
              </div>

              {customer.email.length > 0 && (
                <div className={'flex flex-row items-center pl-2'}>
                  <CircularDotIcon />
                  <div className={' pl-3 leading-5 text-primaryText'}>{customer.email}</div>
                </div>
              )}
              {customer.phoneNumber.length > 0 && (
                <div className={'flex flex-row items-center pl-2'}>
                  <CircularDotIcon />
                  <div className={' pl-3 leading-5 text-primaryText'}>{customer.phoneNumber}</div>
                </div>
              )}
            </div>
          );
        })}
      </>
    );
  };

  const SearchSuggestionsPanel = () => {
    return (
      <div className={twMerge('flex flex-col cursor-pointer')}>
        {searchText !== '' && (
          <div
            id="bill-to-section-create-new-customer-button-with-suggestion"
            className="flex items-center p-5 text-lg font-semibold
        	leading-5 text-primaryBtn"
            onClick={() => {
              setTypeOfCustomerModal(CreateCustomerModalTypes.CREATE_CUSTOMER);
              setOpenCreateCustomerModal(true);
            }}>
            <PlusIconBlue />
            <p id="bill-to-section-create-new-customer-with-suggestion" className="ml-2">
              Create New Customer - “{searchText}”
            </p>
          </div>
        )}
        <CustomersListDropdown />
      </div>
    );
  };

  const EmptySearchSuggestionPanel = () => {
    return (
      <div className={twMerge('flex flex-col cursor-pointer')}>
        <div
          id="bill-to-section-create-new-customer-button"
          className="flex items-center px-5 pt-5 pb-4 text-lg font-semibold
        	leading-5 text-primaryBtn"
          onClick={() => setOpenCreateCustomerModal(true)}>
          <PlusIconBlue />
          <p id="bill-to-section-create-new-customer" className="ml-2">
            Create New Customer
          </p>
        </div>
        <Divider variant="fullWidth" className="bg-secondaryBtn" />
        {getRecentSearchedCustomersResponse && getRecentSearchedCustomersResponse?.customers?.length > 0 && (
          <div className={'px-5 pt-4 pb-2 text-base font-semibold leading-5 text-accentText'}>Recent contacts</div>
        )}
        <CustomersListDropdown />
      </div>
    );
  };

  const onClickInput = () => {
    setSelectedInvoiceTab(InvoiceTabEnum.BILL_TO);
    setIsSuggestionsListVisible(true);
    if (selectedCustomer) {
      setSearchText(selectedCustomer.name);
      dispatch(removeInvoiceCustomer());
      setSelectedCustomer(null);
    }
  };

  const removeSelectedCustomer = () => {
    setSelectedCustomer(null);
    dispatch(removeInvoiceCustomer());
    getRecentSearchedCustomers({ teamId: currentTeam?.id });
    getCustomersList({ search: '', teamId: currentTeam?.id, page: 0, limit: 20 });
    setSearchText('');
  };

  const getAddress = () => {
    let addressString = '';
    if (selectedCustomer?.street && selectedCustomer?.street !== '') {
      addressString += ` ${selectedCustomer?.street}`;
    }

    if (selectedCustomer?.cityStateCountry && selectedCustomer?.cityStateCountry?.length > 0) {
      addressString += ` ${selectedCustomer?.cityStateCountry}`;
    }
    if (selectedCustomer?.zipCode && selectedCustomer?.zipCode?.length > 0) {
      addressString += ` ${selectedCustomer?.zipCode}`;
    }
    return addressString;
  };

  return (
    <div>
      <div className={`mb-2 text-xl font-bold ${isTabSelected ? 'text-primaryBtn' : 'text-heading'}`}>Bill To</div>
      <div
        className={`relative rounded-md border py-5 px-[30px] 1439px:p-10 ${
          isTabSelected ? 'border-primaryBtn' : 'border-borderGray'
        }`}
        style={{ borderWidth: '1px' }}>
        {selectedCustomer ? (
          <>
            <div
              className="1439:top-6 absolute right-3 top-3 cursor-pointer xl:right-[6px] 1439px:right-3"
              onClick={removeSelectedCustomer}>
              <img src={CrossIconImage} className={'h-[18px] w-[18px]'} />
            </div>
            <div className="rounded-md border border-borderGray px-5 py-4">
              <div className="text-sbase font-semibold text-primaryText ">
                {selectedCustomer.name} {selectedCustomer.lastName}
              </div>
              <div
                className={'mt-2.5 flex  cursor-pointer flex-row'}
                onClick={() => {
                  if (getAddress() == '') openEditCustomerModal();
                }}>
                <MapIcon />

                <div className={'ml-3 w-40 break-words'}>
                  {getAddress() !== '' ? (
                    <div className="text-sbase font-normal text-primaryText">{getAddress()}</div>
                  ) : (
                    <div className="text-sbase font-normal italic text-accentText">Add address</div>
                  )}
                </div>
              </div>
              <div
                className={'mt-5 flex cursor-pointer flex-row'}
                onClick={() => {
                  if (selectedCustomer.phoneNumber == '') openEditCustomerModal();
                }}>
                <PhoneIcon />
                <div className={'ml-3 w-40 break-words'}>
                  {selectedCustomer.phoneNumber !== '' ? (
                    <div className="text-sbase font-normal text-primaryText">{selectedCustomer.phoneNumber}</div>
                  ) : (
                    <div className="text-sbase font-normal italic text-accentText">Add Phone Number</div>
                  )}
                </div>
              </div>
              <div
                className={'mt-3 flex  cursor-pointer flex-row justify-between'}
                onClick={() => {
                  if (selectedCustomer.email == '') openEditCustomerModal();
                }}>
                <div className={'flex flex-row '}>
                  <MailIIcon />
                  <div className={'ml-3 w-40 break-words'}>
                    {selectedCustomer.email !== '' ? (
                      <div className="text-sbase font-normal text-primaryText">{selectedCustomer.email}</div>
                    ) : (
                      <div className="text-sbase font-normal italic text-accentText">Add Email Id</div>
                    )}
                  </div>
                </div>
                <div
                  className={'flex flex-row items-center rounded bg-secondaryBtn px-2 py-1'}
                  onClick={openEditCustomerModal}>
                  <PencilIcon />
                  <div
                    id="new-invoice-edit-customer-contact"
                    className="ml-2 cursor-pointer text-sbase font-normal text-primaryBtn">
                    Edit customer contact
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <OutsideClickHandler onOutsideClick={onOutSideClick}>
            <div className="w-full">
              <Popover className="relative">
                {() => (
                  <>
                    <div
                      className="flex h-14 w-full
                     flex-row rounded-lg border border-borderGray
                     bg-secondaryBtn
                      py-4 px-5 text-sm font-semibold text-gray-900">
                      <input
                        type="text"
                        id="create-invoice-customer-name-input"
                        className="w-full cursor-pointer bg-secondaryBtn text-base
                      font-semibold leading-5 text-heading focus:outline-none"
                        value={searchText}
                        onChange={e => onSearchTermChange(e.target.value)}
                        required
                        placeholder={'Type customer name'}
                        onClick={onClickInput}
                        onKeyPress={event => {
                          if (event.key === 'Enter') {
                            setOpenCreateCustomerModal(true);
                          }
                        }}
                        onBlur={() => onFocusOutOfTextField()}
                      />
                      <AccountGreyIcon />
                    </div>
                    {showCustomerNotSelectedError && <div className="mt-1 text-red-400">Customer is missing</div>}

                    <Transition
                      show={isSuggestionsListVisible}
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1">
                      <Popover.Panel
                        className="customNormalScroll absolute z-40 mt-[2px] max-h-36 w-full overflow-y-auto rounded-md
                 border border-borderGray bg-primary shadow-md">
                        {searchText.length > 2 ? <SearchSuggestionsPanel /> : <EmptySearchSuggestionPanel />}
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </OutsideClickHandler>
        )}
      </div>

      <If condition={Boolean(openCreateCustomerModal)}>
        <CreateCustomer
          open={openCreateCustomerModal}
          onClose={() => setOpenCreateCustomerModal(false)}
          panelType={typeOfCustomerModal}
          customerData={selectedCustomer}
          customerDataFetchSuccess={true}
          customerDataFetchLoading={false}
          createOrEditCustomerCallBack={onCustomerCreate}
          defaultName={searchText}
        />
      </If>
    </div>
  );
};

export default BillToSection;
