import { CheckboxTickIcon, CrossBoundaryIcon } from 'assets/icons';
import { CustomButton, CustomCheckbox, CustomModal, CustomTextArea } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { debounce } from 'utils/commonUtils';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { TermsSuggestion } from '../discountTipsFees.model';
import OutsideClickHandle from 'components/outside-click-handler/OutsideClickHandler';
import { defaultTerms, termsMaxCount } from '../constants';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface CreateEditTermsProps {
  suggestions: TermsSuggestion[];
  open: boolean;
  tncText: string;
  isEdit: boolean;
  toggleOpen: VoidFn;
  onSave: (terms: string, applyToPendingInvoices: boolean) => void;
  numberOfInvoices: number;
}

const CreateEditTermsModal: FC<CreateEditTermsProps> = ({
  open,
  toggleOpen,
  suggestions,
  onSave,
  tncText,
  isEdit,
  numberOfInvoices
}) => {
  const [showSuggestions, toggleSuggestionsView] = useState(true);
  const [terms, setTerms] = useState<string>();
  const [updatePendingInvoices, setUpdatePendingInvoices] = useState(false);
  const [updatedSuggestionsList, setUpdatedSuggestionsList] = useState<TermsSuggestion[]>([]);
  const [popupContent, setPopupContent] = useState('');
  const [currentSuggestionsPopOverId, setCurrentSuggestionsPopOverId] = useState('');
  const suggestionsContainer = useRef<HTMLDivElement>();
  const termsContainer = useRef<HTMLDivElement>();
  const popOverAnchorEl = useRef<HTMLDivElement[]>([]);

  const getUpdatedList = searchText => {
    const updatedList = suggestions.filter(suggestion => !searchText.includes(suggestion.text));
    setUpdatedSuggestionsList(updatedList);
  };

  const debouncedTermsHandler = useCallback(debounce(getUpdatedList, 500), []);

  const onTermsChange = termsText => {
    setTerms(termsText);
    debouncedTermsHandler(terms);
  };

  const getRemCharacterCount = () => termsMaxCount - terms?.trim().length;

  const isClearButtonEnabled = () => {
    if (terms?.trim() === defaultTerms) return false;
    return getRemCharacterCount() < termsMaxCount;
  };

  const resetTerms = () => {
    if (isEdit) {
      setTerms(tncText);
      getUpdatedList(tncText);
    } else {
      setTerms(`${defaultTerms}\n`);
      setUpdatedSuggestionsList(suggestions);
    }
    setUpdatePendingInvoices(false);
  };

  useEffect(() => {
    resetTerms();
  }, [isEdit, tncText]);

  const onCancelClick = () => {
    toggleOpen();
    resetTerms();
  };

  const copyTerms = suggestionText => {
    if (suggestionText) {
      const updatedList = updatedSuggestionsList.filter(suggestion => suggestionText !== suggestion.text);
      setUpdatedSuggestionsList(updatedList);
      const inputArea = document.getElementById('terms-and-conditions-text-area') as HTMLTextAreaElement;
      if (terms?.trim() === defaultTerms) {
        setTerms(`${defaultTerms}\n${suggestionText}\n`);
        inputArea.focus();
      } else {
        const currentSelectionStart = inputArea.selectionStart;
        const currentSelectionEnd = inputArea.selectionEnd;
        const cursorOffset = `${suggestionText}\n`.length;

        inputArea.setRangeText(`${suggestionText}\n`, currentSelectionStart, currentSelectionEnd, 'select');

        inputArea.selectionStart = currentSelectionStart + cursorOffset;
        inputArea.selectionEnd = currentSelectionEnd + cursorOffset;
        inputArea.focus();
        setTerms(inputArea.value);
      }
    }
  };

  const isSaveDisabled = () => {
    if (terms?.trim() === defaultTerms) return true;
    return (
      getRemCharacterCount() < 0 ||
      getRemCharacterCount() === termsMaxCount ||
      (terms === tncText && !updatePendingInvoices)
    );
  };

  const isUpdateTermsDisabled = () => getRemCharacterCount() === termsMaxCount;

  const clearText = () => {
    setTerms('');
    setUpdatedSuggestionsList(suggestions);
  };

  const onInsertClick = () => {
    copyTerms(popupContent);
    setPopupContent('');
  };

  return (
    <div>
      <CustomModal width="md:w-[610px]" height="min-h-[237px]" open={open} closeModal={onCancelClick}>
        <div className="h-fit w-full">
          <div className="relative flex w-full flex-col py-8">
            <FormCloseReactIcon
              id="create-edit-terms-close"
              className="absolute top-2 right-2.5 shrink-0"
              onClick={onCancelClick}
              height={24}
              width={24}
            />
            <div className="mb-2 px-10 text-xl font-bold text-heading"> Terms & Conditions </div>
            <div className="relative">
              <div
                className="customNormalScroll overflow-y-auto"
                style={{ maxHeight: '80vh' }}
                ref={termsContainer}
                onScroll={() => setPopupContent('')}>
                <div className="mt-2 mb-7 flex w-full flex-col gap-3 px-10">
                  <CustomTextArea
                    id="terms-and-conditions-text-area"
                    name="terms-and-conditions"
                    value={terms}
                    onChange={onTermsChange}
                    resizeTextArea={true}
                    className="customNormalScroll max-h-[400px] min-h-[100px] w-full overflow-visible
                whitespace-pre-line text-[15px] font-normal leading-5 text-primaryText placeholder:text-[15px] 
                focus:outline-none"
                    placeholder="Write a simple text to start the prompt or insert multiple text box from the 
                below suggestions..."
                    maxHeight={350}
                    maxLength={termsMaxCount}
                  />
                  <div className="flex items-center justify-between">
                    <div
                      className={`text-px13 font-normal
                ${getRemCharacterCount() < 0 ? 'text-error' : 'text-accentText'}
                `}>
                      <span>{getRemCharacterCount()}</span>
                      <span> {`/ ${termsMaxCount} characters left`}</span>
                    </div>
                    {isClearButtonEnabled() && (
                      <div className="flex items-center" role="presentation" onClick={clearText}>
                        <CrossBoundaryIcon width={14} />
                        <div className="ml-1 cursor-pointer text-px13 font-normal text-[#FF7A7A]">Clear all</div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="bg-[#ECF1F74D] pt-4 pb-2">
                  <div className="flex items-center justify-between px-10">
                    <div>
                      <span className="mr-1 text-17px font-semibold text-primaryText">Suggestions</span>
                      <span className="text-sbase font-normal text-accentText">Click all to insert</span>
                    </div>
                    <div
                      className={'cursor-pointer text-px13 font-semibold text-secondary'}
                      role="presentation"
                      onClick={() => toggleSuggestionsView(!showSuggestions)}>
                      {showSuggestions ? 'Hide suggestions' : 'Show suggestions'}
                    </div>
                  </div>
                  {showSuggestions && (
                    <div>
                      <div>
                        <div
                          id="suggestion-list-container"
                          className="customNormalScroll 
                      mt-4 flex gap-x-2.5 overflow-x-auto overflow-y-hidden px-10 pb-2"
                          ref={suggestionsContainer}
                          onScroll={() => setPopupContent('')}>
                          {updatedSuggestionsList.map((suggestion, index) => {
                            return (
                              <div
                                id={`suggestion-item-${index}`}
                                key={suggestion.id}
                                role="presentation"
                                ref={el => (popOverAnchorEl.current[index] = el)}
                                onClick={e => {
                                  e.stopPropagation();
                                  copyTerms(suggestion.text);
                                }}>
                                <div
                                  className="cursor-pointer whitespace-pre-line rounded-[10px] 
                                        border  border-secondary px-4 pt-3 text-secondary">
                                  <div
                                    className="mb-0.5 w-[200px] text-px13 font-normal 
                                            leading-4 line-clamp-4">
                                    {suggestion.text}
                                  </div>
                                  <div className="w-full">
                                    <div
                                      className="my-2 cursor-pointer text-right text-px13 
                                               font-semibold text-tertiaryText"
                                      role="presentation"
                                      onClick={e => {
                                        e.stopPropagation();
                                        setPopupContent(suggestion.text);
                                        setCurrentSuggestionsPopOverId(index.toString());
                                      }}>
                                      View more
                                    </div>
                                  </div>
                                </div>
                                <div className="my-1 text-px13 font-normal text-accentText">
                                  {suggestion.helperText}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                        {popupContent && (
                          <OutsideClickHandle onOutsideClick={() => setPopupContent('')}>
                            <div
                              className="absolute z-[1000] -mt-7 h-fit w-[465px] rounded-md
                                border border-borderGray bg-white p-4 shadow-[0px_20px_60px_rgba(9,26,70,0.1)]"
                              style={{
                                left:
                                  popOverAnchorEl?.current[currentSuggestionsPopOverId]?.offsetLeft -
                                  suggestionsContainer?.current?.scrollLeft,
                                top: suggestionsContainer?.current?.offsetTop - termsContainer?.current?.scrollTop + 145
                              }}>
                              <div
                                className={`absolute -top-px left-48 h-3 w-3 -translate-x-1/2 -translate-y-1/2
                       rotate-45 skew-x-6 skew-y-6 border-l border-t border-borderGray bg-primary`}></div>
                              <div className="text-px13 font-normal leading-4 text-primaryText">{popupContent}</div>
                              <div
                                className="mt-2 cursor-pointer text-sbase font-semibold text-secondary"
                                role="presentation"
                                onClick={onInsertClick}>
                                + Insert
                              </div>
                            </div>
                          </OutsideClickHandle>
                        )}
                      </div>
                    </div>
                  )}
                </div>

                <div className="mt-12 flex w-full flex-row items-center justify-between px-10">
                  {numberOfInvoices > 0 && (
                    <div
                      className={`flex w-full items-center 
                  ${isUpdateTermsDisabled() && 'pointer-events-none opacity-50'}`}>
                      <CustomCheckbox
                        id="terms-and-conditions-update-checkbox"
                        className="border-border"
                        onClick={() => setUpdatePendingInvoices(!updatePendingInvoices)}
                        isChecked={updatePendingInvoices}>
                        {updatePendingInvoices && <CheckboxTickIcon />}
                      </CustomCheckbox>
                      <div
                        className="ml-2 cursor-pointer text-sbase font-normal text-primaryText"
                        role="presentation"
                        onClick={() => setUpdatePendingInvoices(!updatePendingInvoices)}>
                        {`Update pending invoices (${numberOfInvoices})`}
                      </div>
                    </div>
                  )}
                  <div className="flex w-full flex-row justify-end gap-5">
                    <CustomButton
                      id="create-invoice-add-discount-cancel"
                      className="h-[38px] w-[100px]"
                      type={ButtonType.SECONDARY}
                      onClick={onCancelClick}>
                      Cancel
                    </CustomButton>
                    <CustomButton
                      id="create-invoice-add-discount-save"
                      onClick={() => onSave(terms, updatePendingInvoices)}
                      disabled={isSaveDisabled()}
                      type={ButtonType.PRIMARY}
                      className="h-[38px] w-[100px]">
                      Save
                    </CustomButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default CreateEditTermsModal;
