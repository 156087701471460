import SidePanel from 'components/side-panel/SidePanel';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import { SidePanelRouteState } from 'routes/types';
import { OutletContextType } from 'types/baseTypes';
import { PAYOUT_PANEL_PAGES, PaymentSystem } from '../constants';
import PayoutDetails from './PayoutDetails';
import ConnectPayoutDetailsPanel from './ConnectPayoutDetailsPanel';
import { selectCurrentTeam, selectMerchantTeams, setCurrentTeam } from 'containers/app/appSlice';
import routesPath from 'routes/RoutesPath';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetPayoutState } from '../payoutsSlice';

const PayoutDetailsPanel: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { teamId } = useParams();

  const dispatch = useAppDispatch();

  const currentTeam = useAppSelector(selectCurrentTeam);
  const merchantTeams = useAppSelector(selectMerchantTeams);

  const [page, setPage] = useState<PAYOUT_PANEL_PAGES>(PAYOUT_PANEL_PAGES.CONNECT_PAYOUT);

  const { handleSidePanelClose } = useOutletContext<OutletContextType>();

  const [isRootState, setIsRootState] = useState(true);

  useEffect(() => {
    if (teamId && currentTeam?.id !== +teamId) {
      const merchantTeam = merchantTeams.find(team => team.id === +teamId);
      if (merchantTeam) {
        dispatch(setCurrentTeam(merchantTeam));
        dispatch(resetPayoutState());
      } else {
        navigate(routesPath.PAYOUTS_LIST, { replace: true });
      }
    }
  }, [teamId]);

  useEffect(() => {
    const type = searchParams.get('type');
    if (type) {
      if (type === PaymentSystem.LIP) {
        setPage(PAYOUT_PANEL_PAGES.INSTANT_PAYOUT);
      } else if (type === PaymentSystem.CA) {
        setPage(PAYOUT_PANEL_PAGES.CONNECT_PAYOUT);
      }
    }
  }, [searchParams]);

  useEffect(() => {
    let routeStateTimeout;
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(`${location.pathname}${location.search}`, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      {page === PAYOUT_PANEL_PAGES.CONNECT_PAYOUT && (
        <ConnectPayoutDetailsPanel handleSidePanelClose={handleSidePanelBack} />
      )}
      {page === PAYOUT_PANEL_PAGES.INSTANT_PAYOUT && <PayoutDetails isIdFromProp={false} />}
    </SidePanel>
  );
};

export default PayoutDetailsPanel;
