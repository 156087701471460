import { TopBarIcon } from 'assets/icons';
import { If, Loader } from 'components';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import {
  useCancelEmailPromptMutation,
  useCreatePasswordMutation,
  useInitiateSignInMutation,
  useLogoutMutation,
  useRequestOTPMutation,
  useSignInMutation,
  useSkipEmailVerificationMutation,
  useUpdateEmailMutation,
  useVerifyOTPMutation
} from './api';
import {
  AccountNotFoundSection,
  AddEmailPromptSection,
  CreateNewPasswordSection,
  CreatePasswordSection,
  EmailVerificationSection,
  EnterPasswordSection,
  RelatedAccountsSection,
  SignInSection,
  VerifyAccountSection
} from './components';
import {
  AppsFlyerParams,
  CampaignValues,
  InviteLinkStatuses,
  SignInMethodEnum,
  SignInPages,
  signInMethods
} from './constants';
import TestimonialSection from './components/TestimonialSection';
import { APIErrorMsg, APIErrorStatuses } from 'services/type';
import { isMobile, isTablet } from 'react-device-detect';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  selectAuthState,
  selectCurrentSignInMethod,
  selectTeamInviteTokens,
  setCurrentSignInMethod,
  setHasPendingEmailSteps,
  setIsPasswordExpired,
  updateDownloadLinkToken,
  updateLogoutActionInProgress,
  updateSignUpSearchParams,
  updateTeamInviteTokens
} from './authSlice';
import {
  CreatePasswordPayload,
  DownloadInviteLinkPayload,
  EmailSignInPayload,
  InitiateEmailSignInPayload,
  InitiatePhoneSignInPayload,
  PhoneSignInPayload,
  RequestOTPPayload,
  VerifyOTPPayload
} from './auth.model';
import {
  selectIsLoadingUserDetailsSlice,
  selectUserDetails,
  showNotifier,
  updateEmailAndVerificationStatus
} from 'containers/app/appSlice';
import DeviceAuthSection from './components/DeviceAuthSection';
import { SignInMethod } from './types';
import { ErrorResponse, VerificationStatusEnum } from 'containers/app/app.model';
import { useLazyGetUserDetailsQuery } from 'containers/app/api';
import { NotifierTypes } from 'constants/notifierConstants';
import { EmailErrorMessages } from 'containers/my-account/types';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsIAM } from './events';
import { handleBackClickEventLog, handleCreatePasswordEventLog, handleOTPVerificationEventLog } from './utils';

const SignIn: FC = () => {
  const [
    inititateSignInMutation,
    { isSuccess: isInitiateSignInSuccess, isLoading: isInitiateSignInLoading, error: initiateSignInError }
  ] = useInitiateSignInMutation();

  const [
    updateEmailMutation,
    {
      isSuccess: isUpdateEmailSuccess,
      isLoading: isUpdateEmailLoading,
      error: updateEmailError,
      isError: isUpdateEmailError
    }
  ] = useUpdateEmailMutation();

  const [
    createPasswordMutation,
    {
      originalArgs: createPasswordAPIPayload,
      isSuccess: isCreatePasswordSuccess,
      isLoading: isCreatePasswordLoading,
      error: createPasswordError
    }
  ] = useCreatePasswordMutation();

  const [cancelEmailPromptMutation, { isSuccess: isCancelEmailPromptSuccess, isLoading: isCancelEmailPromptLoading }] =
    useCancelEmailPromptMutation();

  const [
    skipEmailVerificationMutation,
    { isSuccess: isSkipEmailVerificationSuccess, isLoading: isSkipEmailVerificationLoading }
  ] = useSkipEmailVerificationMutation();

  const [
    signInMutation,
    { isSuccess: isSignInSuccess, error: signInError, isLoading: isSignInLoading, data: signInData }
  ] = useSignInMutation();

  const [
    verifyOTPMutation,
    { isLoading: isLoadingVerifyOTPMutation, error: verifyOtpError, isSuccess: isSuccessVerifyOTP }
  ] = useVerifyOTPMutation();

  const [requestOTPMutation, { isLoading: isLoadingRequestOTPMutation, isSuccess: isRequestOTPSuccess }] =
    useRequestOTPMutation();

  const [
    getUserDetailsQuery,
    { isFetching: isFetchingUserDetails, isSuccess: isUserDetailsSuccess, isError: isUserDetailsError }
  ] = useLazyGetUserDetailsQuery();

  const [logout, { isLoading: isLogoutInProgress, isSuccess: isLogoutSuccess, isError: logoutError }] =
    useLogoutMutation();

  const [page, setPage] = useState<SignInPages>(SignInPages.SIGNIN);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const [phoneNumber, setPhoneNumber] = useState(null);
  const [otpError, setOtpError] = useState(null);

  const [passwordError, setPasswordError] = useState(null);
  const [selectedSignInMethod, setSelectedSignInMethod] = useState<SignInMethod>(signInMethods.phoneNumber);
  const [selectedVerificationMethod, setSelectedVerificationMethod] = useState<SignInMethod>(signInMethods.phoneNumber);
  const [isHistoricPassword, setIsHistoricPassword] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<EmailErrorMessages>(null);

  const currentUser = useAppSelector(selectUserDetails);

  const isLoadingUserDetailsSlice = useAppSelector(selectIsLoadingUserDetailsSlice);
  const {
    idToken,
    isAuthenticated,
    refreshToken,
    isPasswordExpired,
    hasPendingEmailSteps,
    hasInitiatedSignIn,
    relatedAccounts,
    isEmailVerified,
    logoutActionInProgress
  } = useAppSelector(selectAuthState);

  const currentSignInMethod = useAppSelector(selectCurrentSignInMethod);
  const isLoggedIn = idToken && refreshToken && isAuthenticated;
  const selectedInviteTokens = useAppSelector(selectTeamInviteTokens);

  useEffect(() => {
    if (!isAuthenticated && logoutActionInProgress) {
      setPage(SignInPages.SIGNIN);
      dispatch(updateLogoutActionInProgress(false));
    }
  }, [logoutActionInProgress]);

  useEffect(() => {
    if (isUserDetailsError) {
      setPage(SignInPages.SIGNIN);
    }
  }, [isUserDetailsError, isFetchingUserDetails]);

  useEffect(() => {
    // Every day app open/ site reload
    if (isAuthenticated) {
      if (currentUser.verificationStatus === VerificationStatusEnum.Declined) navigate(routesPath.DECLINED);
      else if (!currentUser.isDeviceAuthenticated && !currentUser.skippedAuthentication) {
        setPage(SignInPages.DEVICE_AUTH);
        handleRequestOtpForDeviceAuth();
      } else if (!currentUser.isPasswordCreated && currentUser.verificationStatus === VerificationStatusEnum.Verified) {
        setPage(SignInPages.CREATE_PASSWORD);
      } else if (currentUser.verificationStatus === VerificationStatusEnum.NotVerified) {
        navigate(routesPath.DIGITAL_ONBOARDING_NAME, { replace: true });
      } else if (!currentUser.isPasswordActive) {
        setPage(SignInPages.CREATE_NEW_PASSWORD);
      } else if (hasPendingEmailSteps) {
        if (!currentUser.isEmailAdded && currentUser.showEmailPrompt) {
          setPage(SignInPages.ADD_EMAIL_PROMPT);
        } else if (!currentUser.isEmailVerified && currentUser.isEmailAdded && currentUser.showVerificationPrompt) {
          if (currentUser?.email) {
            const payload: RequestOTPPayload = { email: currentUser.email, verifyEmail: true };
            requestOTPMutation(payload);
          }
          setPage(SignInPages.EMAIL_VERIFICATION);
        }
      } else {
        setPage(SignInPages.SIGNIN);
      }
    }
  }, []);

  useEffect(() => {
    if (isUserDetailsSuccess && !isFetchingUserDetails) {
      if (currentUser.verificationStatus === VerificationStatusEnum.Declined) navigate(routesPath.DECLINED);
      else if (
        !currentUser.isDeviceAuthenticated &&
        !currentUser.skippedAuthentication &&
        (isLoggedIn || hasInitiatedSignIn) &&
        !isLoadingUserDetailsSlice &&
        (!currentUser.isPasswordCreated || isAuthenticated) &&
        relatedAccounts?.length === 0 &&
        currentUser.isPasswordActive
      ) {
        setPage(SignInPages.DEVICE_AUTH);
        handleRequestOtpForDeviceAuth();
      } else if (!currentUser.isPasswordCreated && currentUser.verificationStatus === VerificationStatusEnum.Verified) {
        setPage(SignInPages.CREATE_PASSWORD);
      } else if (currentUser.verificationStatus === VerificationStatusEnum.NotVerified) {
        navigate(routesPath.DIGITAL_ONBOARDING_NAME, { replace: true });
      } else if (!currentUser.isPasswordActive && page === SignInPages.ENTER_PASSWORD) {
        // This part will only run during login process flow post ENTER PASSWORD
        setPage(SignInPages.EXPIRED_PASSWORD_ON_LOGIN);
      } else if (!currentUser.isPasswordActive && page === SignInPages.DEVICE_AUTH) {
        // Runs during everyday open post device auth
        setPage(SignInPages.CREATE_NEW_PASSWORD);
      } else if (hasPendingEmailSteps) {
        dispatch(setHasPendingEmailSteps(false));
      } else {
        setPage(SignInPages.SIGNIN);
      }
    }
  }, [isFetchingUserDetails, isUserDetailsSuccess]);

  useEffect(() => {
    if (isCancelEmailPromptSuccess) {
      dispatch(setHasPendingEmailSteps(false));
    }
  }, [isCancelEmailPromptLoading, isCancelEmailPromptSuccess]);

  useEffect(() => {
    if (isSkipEmailVerificationSuccess) {
      dispatch(setHasPendingEmailSteps(false));
    }
  }, [isSkipEmailVerificationLoading, isSkipEmailVerificationSuccess]);

  useEffect(() => {
    if (isSignInSuccess) {
      getUserDetailsQuery({ onSignInSuccess: true });
      if (
        signInData.inviteStatus === InviteLinkStatuses.INVALID ||
        signInData.inviteStatus === InviteLinkStatuses.EXPIRED
      ) {
        dispatch(
          showNotifier({
            type: NotifierTypes.ERROR,
            message: {
              primaryMessage: 'Invalid invite link and you are redirected to your previous logged in account',
              isMessageHtml: false
            }
          })
        );
      }
    }
  }, [isSignInLoading, isSignInSuccess]);

  useEffect(() => {
    if (isInitiateSignInSuccess) {
      if (!isEmailVerified && relatedAccounts?.length > 0) {
        setPage(SignInPages.RELATED_ACCOUNTS);
      } else if (!currentUser.isPasswordCreated) {
        setPage(SignInPages.DEVICE_AUTH);
        handleRequestOtpForDeviceAuth();
      } else if (currentUser.isPasswordCreated) {
        setPage(SignInPages.ENTER_PASSWORD);
      }
    }
  }, [isInitiateSignInLoading, isInitiateSignInSuccess]);

  useEffect(() => {
    if (initiateSignInError) {
      const error = initiateSignInError as ErrorResponse;
      const errorMsg = error?.data?.message as APIErrorMsg;
      handleValidationError(error.status, errorMsg);
    }
  }, [initiateSignInError]);

  useEffect(() => {
    const linkType = searchParams.get(AppsFlyerParams.CAMPAIGN) as CampaignValues;
    if (linkType) {
      const token = searchParams.get(AppsFlyerParams.CUSTOMER_ID);
      const downloadLinkType: DownloadInviteLinkPayload = { token: token, type: linkType };
      dispatch(updateDownloadLinkToken(downloadLinkType));
      const inviteToken = searchParams.get(AppsFlyerParams.INVITE_TOKEN);
      const referrerId = searchParams.get(AppsFlyerParams.CUSTOMER_ID);
      dispatch(updateTeamInviteTokens({ inviteToken, referrerId }));
      navigate(routesPath.SIGN_UP);
      if (inviteToken || referrerId) {
        dispatch(updateSignUpSearchParams(Object.fromEntries(searchParams.entries())));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isUpdateEmailError) {
      if (updateEmailError) {
        const emailErrorResponse = updateEmailError as ErrorResponse;
        const errormsg = (emailErrorResponse.data.message as APIErrorMsg) ?? null;
        handleValidationError(emailErrorResponse.status, errormsg);
      } else {
        handleValidationError(null, null);
      }
    }
  }, [isUpdateEmailError]);

  const logAccountNotFoundEvent = () => {
    logAnalyticEvent(CleverTapEventsIAM.webAccountNotFoundPage, { login: currentSignInMethod?.value }, false);
  };

  const handleValidationError = (status: APIErrorStatuses, message: APIErrorMsg) => {
    if (status === APIErrorStatuses.STATUS_400) {
      if (message === APIErrorMsg.NO_ACTIVE_TEAMS) {
        logAccountNotFoundEvent();
        setPage(SignInPages.ACCOUNT_NOT_FOUND);
      }
    } else if (status === APIErrorStatuses.STATUS_403) {
      if (message === APIErrorMsg.INVALID_LOGIN) {
        setOtpError('Invalid code');
      } else if (message === APIErrorMsg.NO_ACTIVE_USER_FOUND || message === APIErrorMsg.MERCHANT_BLOCKED) {
        logAccountNotFoundEvent();
        setPage(SignInPages.ACCOUNT_NOT_FOUND);
      } else if (message === APIErrorMsg.ACCOUNT_TEMPORARILY_BLOCKED) {
        dispatch(
          showNotifier({
            type: NotifierTypes.ERROR,
            message: {
              primaryMessage: 'Too many attempts. Account temporarily blocked. Please try again after some time',
              isMessageHtml: false
            }
          })
        );
      } else if (message === APIErrorMsg.EMAIL_CONFLICT) {
        setEmailError(EmailErrorMessages.ALREADY_EXISTS);
      }
    } else if (status === APIErrorStatuses.STATUS_404) {
      if (message === APIErrorMsg.NOT_FOUND) {
        logAccountNotFoundEvent();
        setPage(SignInPages.ACCOUNT_NOT_FOUND);
      }
    } else if (status === APIErrorStatuses.STATUS_401) {
      if (message === APIErrorMsg.WRONG_PASSWORD) {
        setPasswordError('Username / password did not match our records');
      }
    } else if (status === APIErrorStatuses.STATUS_500) {
      if (message === APIErrorMsg.INVALID_OTP) {
        setOtpError('Invalid code');
      }
    } else {
      dispatch(
        showNotifier({
          type: NotifierTypes.ERROR,
          message: {
            primaryMessage: 'Something went wrong, please try again after some time',
            isMessageHtml: false
          }
        })
      );
    }
  };

  useEffect(() => {
    if (isUpdateEmailSuccess) {
      dispatch(setHasPendingEmailSteps(false));
    }
  }, [isUpdateEmailSuccess, isUpdateEmailLoading]);

  useEffect(() => {
    if (signInError) {
      handleValidationError(signInError?.['status'], signInError?.['data']?.['message']);
    }
  }, [signInError]);

  useEffect(() => {
    if (verifyOtpError) {
      handleValidationError(verifyOtpError?.['status'], verifyOtpError?.['data']?.['message']);
    }
  }, [verifyOtpError]);

  useEffect(() => {
    if (isSuccessVerifyOTP) {
      if (page === SignInPages.VERIFY_CODE) {
        setPage(SignInPages.RESET_PASSWORD);
      } else {
        getUserDetailsQuery({});
        if (page === SignInPages.EMAIL_VERIFICATION) {
          dispatch(updateEmailAndVerificationStatus({ isEmailVerified: true }));
          dispatch(setHasPendingEmailSteps(false));
        }
      }
    }
  }, [isLoadingVerifyOTPMutation, isSuccessVerifyOTP]);

  useEffect(() => {
    if (isCreatePasswordSuccess) {
      if (page === SignInPages.EXPIRED_PASSWORD_ON_LOGIN) {
        setPage(SignInPages.DEVICE_AUTH);
        handleRequestOtpForDeviceAuth();
      } else if (page === SignInPages.RESET_PASSWORD) {
        setPage(SignInPages.ENTER_PASSWORD);
        showPasswordCreatedNotifier();
      } else if (
        page === SignInPages.CREATE_NEW_PASSWORD ||
        (page === SignInPages.CREATE_PASSWORD && createPasswordAPIPayload.includeToken === false)
      ) {
        // This part runs only during everyday open/reload flow
        if (isPasswordExpired) {
          dispatch(setIsPasswordExpired(false));
          showPasswordCreatedNotifier();
          // Proceed to pending email steps after create a new password (expired password)
          if (hasPendingEmailSteps) {
            if (!currentUser.isEmailAdded && currentUser.showEmailPrompt) {
              setPage(SignInPages.ADD_EMAIL_PROMPT);
            } else if (!currentUser.isEmailVerified && currentUser.isEmailAdded && currentUser.showVerificationPrompt) {
              if (currentUser?.email) {
                const payload: RequestOTPPayload = { email: currentUser.email, verifyEmail: true };
                requestOTPMutation(payload);
              }
              setPage(SignInPages.EMAIL_VERIFICATION);
            }
          }
        }
      } else if (page === SignInPages.CREATE_PASSWORD && createPasswordAPIPayload.includeToken === true) {
        dispatch(setIsPasswordExpired(false));
        dispatch(setHasPendingEmailSteps(false));
      }
    }
  }, [isCreatePasswordLoading, isCreatePasswordSuccess]);

  useEffect(() => {
    if (!isCreatePasswordSuccess && createPasswordError) {
      const passwordErrorObj = createPasswordError as ErrorResponse;
      if (passwordErrorObj.status === APIErrorStatuses.STATUS_403) {
        setIsHistoricPassword(true);
        logAnalyticEvent(CleverTapEventsIAM.webPassordPageError, { error: passwordErrorObj?.data?.details }, false);
      } else handleValidationError(null, null);
    }
  }, [createPasswordError, isCreatePasswordSuccess]);

  const showPasswordCreatedNotifier = () => {
    dispatch(
      showNotifier({
        type: NotifierTypes.SUCCESS,
        message: {
          primaryMessage: 'Password successfully changed',
          isMessageHtml: false
        }
      })
    );
  };

  const handleSignInWithDiffNum = () => {
    if (currentSignInMethod?.id === SignInMethodEnum.EMAIL) {
      logAnalyticEvent(CleverTapEventsIAM.webSignInWithPhoneNumberClick, { login: currentSignInMethod?.value }, false);
    } else {
      logAnalyticEvent(CleverTapEventsIAM.webSignInWithDiffNumberClick, { login: currentSignInMethod?.value }, false);
    }
    setPhoneNumber('');
    setPage(SignInPages.SIGNIN);
    setSelectedSignInMethod(signInMethods.phoneNumber);
  };

  const handleSignUpClick = () => {
    if (page !== SignInPages.ACCOUNT_NOT_FOUND) {
      dispatch(setCurrentSignInMethod(signInMethods.phoneNumber));
    }
    logAnalyticEvent(CleverTapEventsIAM.webSignUpClick, { page: page, login: currentSignInMethod?.value }, false);
    navigate(routesPath.SIGN_UP);
  };

  const onInitiateSignin = ({ value }: { value: string }) => {
    const signInMethod =
      selectedSignInMethod.id === SignInMethodEnum.EMAIL
        ? { ...signInMethods.email, value }
        : { ...signInMethods.phoneNumber, value };
    let payload: InitiateEmailSignInPayload | InitiatePhoneSignInPayload = null;
    if (selectedSignInMethod.id === SignInMethodEnum.PHONE_NUMBER) {
      payload = { body: { phone: value }, signInMethod };
    } else {
      payload = { body: { email: value.toLowerCase() }, signInMethod };
    }
    dispatch(setCurrentSignInMethod(signInMethod));
    handleInitiateSignIn(payload);
    logAnalyticEvent(CleverTapEventsIAM.webInitiateSignInContinueClick, { login: signInMethod.value }, false);
  };

  const handleInitiateSignIn = (payload: InitiateEmailSignInPayload | InitiatePhoneSignInPayload) => {
    inititateSignInMutation(payload);
  };

  const handleRequestOtpForDeviceAuth = () => {
    let payload: RequestOTPPayload = { authenticateDevice: true };
    if (currentSignInMethod?.id) {
      payload =
        currentSignInMethod.id === SignInMethodEnum.EMAIL
          ? { ...payload, email: currentSignInMethod.value }
          : { ...payload, phone: currentSignInMethod.value };
    } else if (selectedSignInMethod?.id) {
      payload =
        selectedSignInMethod.id === SignInMethodEnum.EMAIL
          ? { ...payload, email: selectedSignInMethod.value }
          : { ...payload, phone: selectedSignInMethod.value };
    }
    if (payload.email || payload.phone) {
      handleRequestOTP(payload);
    }
  };

  const onRelatedAccountClick = ({ value }: { value: string }) => {
    logAnalyticEvent(
      CleverTapEventsIAM.webRelatedAccountsClick,
      {
        login: currentSignInMethod?.value,
        relatedAccountPhoneNumber: value
      },
      false
    );
    const signInMethod = { ...signInMethods.phoneNumber, value };
    setSelectedSignInMethod(signInMethod);
    const payload: InitiatePhoneSignInPayload = { body: { phone: value }, signInMethod };
    handleInitiateSignIn(payload);
  };

  const onRelatedAccountsBackClick = () => {
    setPage(SignInPages.SIGNIN);
    logAnalyticEvent(CleverTapEventsIAM.webRelatedAccountsBackClick, { login: currentSignInMethod?.value }, false);
    setSelectedSignInMethod(signInMethods.phoneNumber);
  };

  const onContinueCreatePassword = (password: string) => {
    let payload: CreatePasswordPayload = null;

    if (password) {
      if (isAuthenticated) {
        payload = { password, includeToken: false };
      } else {
        payload = { password, includeToken: true };
      }
      const { inviteToken, referrerId } = selectedInviteTokens ?? {};
      if (inviteToken && referrerId) {
        payload = { ...payload, inviteToken, referrerId };
      }
      createPasswordMutation(payload);
    }
  };

  const handleEnterPassword = (password: string) => {
    setPasswordError('');
    let payload: EmailSignInPayload | PhoneSignInPayload = null;
    if (currentSignInMethod?.id === SignInMethodEnum.EMAIL) {
      payload = {
        email: currentSignInMethod?.value,
        password
      };
    } else {
      payload = {
        phone: currentSignInMethod?.value,
        password
      };
    }
    const { inviteToken, referrerId } = selectedInviteTokens ?? {};
    if (inviteToken && referrerId) {
      payload = { ...payload, inviteToken, referrerId };
    }
    signInMutation(payload);
    logAnalyticEvent(CleverTapEventsIAM.webPasswordContinueClick, { login: currentSignInMethod?.value }, false);
  };

  const onDeviceAuthBackClick = async () => {
    setOtpError('');
    logAnalyticEvent(
      CleverTapEventsIAM.webDeviceAuthenticationBackClick,
      isAuthenticated ? { isEveryDayAppOpen: true } : { isLoginFlow: true, login: currentSignInMethod?.value },
      isAuthenticated
    );
    if (isAuthenticated) {
      try {
        const logoutAPIResponse = await logout({
          idToken: idToken,
          refreshToken: refreshToken,
          userName: currentUser.name ?? currentUser.phone
        }).unwrap();
        if (logoutAPIResponse) {
          setSelectedSignInMethod(signInMethods.phoneNumber);
          setPage(SignInPages.SIGNIN);
        }
      } catch (err) {
        /* empty */
      }
    } else {
      setSelectedSignInMethod(signInMethods.phoneNumber);
      setPage(SignInPages.SIGNIN);
    }
  };

  const onCreatePasswordBackClick = () => {
    logAnalyticEvent(CleverTapEventsIAM.webCreatePasswordBackClick, { login: currentSignInMethod?.value }, false);
    setPage(SignInPages.SIGNIN);
    setSelectedSignInMethod(signInMethods.phoneNumber);
  };

  const handleOTPVerification = (payload: VerifyOTPPayload) => {
    setOtpError('');
    handleOTPVerificationEventLog(page, currentSignInMethod);
    verifyOTPMutation(payload);
  };

  const handleSkipAddEmail = () => {
    cancelEmailPromptMutation();
    logAnalyticEvent(CleverTapEventsIAM.webSkipEmail, {}, true);
  };

  const handleAddEmail = (email: string) => {
    if (email) {
      updateEmailMutation({ email: email.toLowerCase() });
      logAnalyticEvent(CleverTapEventsIAM.webAddEmail, { email }, true);
    }
  };

  const onForgotPassword = () => {
    setPasswordError('');
    setSelectedVerificationMethod({ ...currentSignInMethod });
    setPage(SignInPages.VERIFY_CODE);
    let payload: RequestOTPPayload = { resetPassword: true };
    if (currentSignInMethod.id === SignInMethodEnum.PHONE_NUMBER) {
      payload = { ...payload, phone: currentSignInMethod.value };
    } else {
      payload = { ...payload, email: currentSignInMethod.value };
    }
    handleRequestOTP(payload);
    logAnalyticEvent(CleverTapEventsIAM.webForgotPasswordClick, { login: currentSignInMethod?.value });
  };

  const handleRequestOTP = (payload: RequestOTPPayload) => {
    requestOTPMutation(payload);
  };

  const goToEnterPasswordPage = () => {
    setOtpError('');
    handleBackClickEventLog(page, currentSignInMethod);
    setPage(SignInPages.ENTER_PASSWORD);
  };

  const handleCreateNewPassword = (password: string) => {
    setIsHistoricPassword(false);
    handleCreatePasswordEventLog(page, currentSignInMethod);
    const payload: CreatePasswordPayload = { password, includeToken: false };
    if (password) {
      createPasswordMutation(payload);
    }
  };

  const onEnterPasswordBackClick = () => {
    setPage(SignInPages.SIGNIN);
    logAnalyticEvent(CleverTapEventsIAM.webPasswordBackClick, {}, false);
  };

  const onHandleVerifyLater = () => {
    logAnalyticEvent(CleverTapEventsIAM.webEmailVerificationVerifyLater, {});
    skipEmailVerificationMutation();
  };

  const handleEmailVerification = (payload: VerifyOTPPayload) => {
    handleOTPVerificationEventLog(page, { email: payload.email, login: currentSignInMethod?.value });
    verifyOTPMutation(payload);
  };

  return (
    <div className="flex h-full w-full flex-row">
      <div className="relative flex w-full flex-col gap-16 bg-primary p-12 pt-16 sm:w-1/2 sm:gap-0 sm:pt-12">
        <TopBarIcon className={`shrink-0 ${isMobile && !isTablet ? 'self-center' : 'absolute'}`} />

        <If condition={isLoadingUserDetailsSlice}>
          <Loader />
        </If>
        <If condition={!isLoadingUserDetailsSlice}>
          <If condition={page === SignInPages.SIGNIN}>
            <SignInSection
              selectedSignInMethod={selectedSignInMethod}
              setSelectedSignInMethod={setSelectedSignInMethod}
              onContinue={onInitiateSignin}
              handleSignUp={handleSignUpClick}
              userPhoneNumber={phoneNumber}
              isLoading={isInitiateSignInLoading}
            />
          </If>
          <If condition={page === SignInPages.DEVICE_AUTH}>
            <DeviceAuthSection
              otpError={otpError}
              selectedSignInMethod={currentSignInMethod}
              handleBackClick={onDeviceAuthBackClick}
              handleContinueClick={handleOTPVerification}
              isVerifyOTPAPILoading={isLoadingVerifyOTPMutation}
            />
          </If>

          <If condition={page === SignInPages.ENTER_PASSWORD}>
            <EnterPasswordSection
              passwordError={passwordError}
              handleContinue={handleEnterPassword}
              handleForgotPassword={onForgotPassword}
              handleBackClick={onEnterPasswordBackClick}
              isSignInLoading={isSignInLoading}
              setPasswordError={setPasswordError}
            />
          </If>
          <If condition={page === SignInPages.VERIFY_CODE}>
            <VerifyAccountSection
              selectedSignInMethod={selectedVerificationMethod}
              onVerifyOTP={handleOTPVerification}
              otpError={otpError}
              isLoading={isLoadingVerifyOTPMutation}
              handleBackClick={goToEnterPasswordPage}
            />
          </If>
          <If condition={page === SignInPages.CREATE_PASSWORD}>
            <CreatePasswordSection
              handleBack={onCreatePasswordBackClick}
              handleContinue={onContinueCreatePassword}
              oldPasswordMatchError={null}
              isCreatePasswordAPILoading={isCreatePasswordLoading}
            />
          </If>
          <If condition={page === SignInPages.ACCOUNT_NOT_FOUND}>
            <AccountNotFoundSection
              className="max-w-sm"
              handleSignInWithDiffNum={handleSignInWithDiffNum}
              handleSignUp={handleSignUpClick}
              signInMethod={currentSignInMethod}
            />
          </If>
          <If condition={page === SignInPages.RELATED_ACCOUNTS}>
            <RelatedAccountsSection
              className="max-w-sm"
              handleSignUp={handleSignUpClick}
              accounts={relatedAccounts}
              handleBackClick={onRelatedAccountsBackClick}
              handleAccountClick={onRelatedAccountClick}
            />
          </If>

          <If condition={page === SignInPages.ADD_EMAIL_PROMPT}>
            <AddEmailPromptSection
              handleSkip={handleSkipAddEmail}
              handleContinue={handleAddEmail}
              updateEmailError={emailError}
              setUpdateEmailError={setEmailError}
            />
          </If>
          <If condition={page === SignInPages.RESET_PASSWORD || page === SignInPages.CREATE_NEW_PASSWORD}>
            <CreateNewPasswordSection
              handleContinue={handleCreateNewPassword}
              oldPasswordMatchError={isHistoricPassword}
              setIsHistoricPassword={setIsHistoricPassword}
              isCreateAPILoading={isCreatePasswordLoading}
              handleBackClick={goToEnterPasswordPage}
              isExpiredPassword={page === SignInPages.CREATE_NEW_PASSWORD}
            />
          </If>

          <If condition={page === SignInPages.EXPIRED_PASSWORD_ON_LOGIN}>
            <CreateNewPasswordSection
              handleContinue={handleCreateNewPassword}
              oldPasswordMatchError={isHistoricPassword}
              setIsHistoricPassword={setIsHistoricPassword}
              isCreateAPILoading={isCreatePasswordLoading}
              handleBackClick={goToEnterPasswordPage}
              isExpiredPassword={true}
            />
          </If>

          <If condition={page === SignInPages.EMAIL_VERIFICATION}>
            <EmailVerificationSection
              otpError={otpError}
              setOtpError={setOtpError}
              onVerifyOTP={handleEmailVerification}
              handleVerifyLater={onHandleVerifyLater}
            />
          </If>
        </If>
      </div>
      <div className="hidden h-full w-1/2 sm:flex">
        <TestimonialSection />
      </div>
    </div>
  );
};

export default SignIn;
