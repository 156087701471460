import React, { useEffect } from 'react';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam, selectPaymentSystemConfig } from 'containers/app/appSlice';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { CrossIconvector } from 'assets/icons';
import { getOnboardingStatus } from 'utils/banking';
import { LocalStorage } from 'services/storage';
import routesPath from 'routes/RoutesPath';
import { PaymentSystem } from 'containers/payouts/constants';
import { WebviewFunctions } from 'constants/common';
import { Loader } from 'components';
import { PaymentSystemTransitionType } from 'containers/home/constants';

const BankingOnboarding = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig) ?? {};

  const navigate = useNavigate();

  const [loading, setLoading] = React.useState(true);
  const [iframeUrl, setIframeUrl] = React.useState('');

  useEffect(() => {
    const handleReadMessage = event => {
      if (event.data === WebviewFunctions.CLOSE_WEBVIEW) {
        handleExitClick();
      }
    };
    window.addEventListener('message', handleReadMessage, false);
    return () => {
      window.removeEventListener('message', handleReadMessage, false);
    };
  }, []);

  const getFrameURL = async () => {
    let url = process.env.REACT_APP_ONBOARDING_URL;
    const idToken = LocalStorage.get('idToken');
    const refreshToken = LocalStorage.get('refreshToken');
    const teamId = currentTeam?.id;
    // eslint-disable-next-line max-len
    url = `${url}/#/?isIframe=true&idToken=${idToken}&refreshToken=${refreshToken}&teamId=${teamId}&paymentSystem=${paymentSystem}`;
    setIframeUrl(url);
    setLoading(false);
  };

  const onboardingStatus = getOnboardingStatus(currentTeam);
  if (!(onboardingStatus === 'IN_PROGRESS' || onboardingStatus === 'NOT_APPROVED')) {
    navigate('/banking');
  }

  useEffect(() => {
    setLoading(true);
  }, [currentTeam?.id]);

  useEffect(() => {
    if (
      paymentSystem === PaymentSystem.CA &&
      currentTeam.paymentSystemTransitionType !== PaymentSystemTransitionType.LIPConnectMigration
    ) {
      navigate(routesPath.HOME, { replace: true });
    } else {
      getFrameURL();
    }
  }, [paymentSystem, currentTeam?.id]);

  const handleExitClick = () => {
    navigate(routesPath.BANKING);
  };

  if (loading) {
    return <Loader />;
  }

  if (isMobile) {
    return <iframe src={iframeUrl} className={'h-full w-full'} id="onboarding-frame"></iframe>;
  }
  return (
    <div className={'flex h-full w-full flex-row'}>
      <div className={'flex h-full'} style={{ flex: 27 }}>
        <iframe src={iframeUrl} className={'h-full w-full'} id="onboarding-frame"></iframe>
      </div>

      <div className={'flex h-full cursor-pointer'} onClick={handleExitClick} style={{ flex: 1 }}>
        <div className={'mt-[20px] flex flex-col'}>
          <CrossIconvector />
          <div className={'text-sm text-accentText'}>Exit</div>
        </div>
      </div>
    </div>
  );
};

export default BankingOnboarding;
