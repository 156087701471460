import { BankActionNeededIcon, ChevronArrowRightIcon, OrangeStrokeInfoIcon, VerifyingDetailsIcon } from 'assets/icons';
import { ActionNeededTypes } from 'containers/payouts/constants';
import { FC } from 'react';

interface ActionNeededCardProps {
  type: ActionNeededTypes;
}

const ActionNeededCard: FC<ActionNeededCardProps> = ({ type = ActionNeededTypes.KYC }) => {
  const getIconAndText = () => {
    switch (type) {
      case ActionNeededTypes.BANK:
        return { text: 'Bank account submitted', Icon: BankActionNeededIcon };
      case ActionNeededTypes.KYC:
        return { text: 'Verifying your details', Icon: VerifyingDetailsIcon };
      default:
    }
  };

  const { text, Icon } = getIconAndText();

  return (
    <div
      className="flex w-full items-center rounded-md
      border-2 border-pending-orange bg-[#FDF9F2] p-[15px]">
      <Icon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-bold text-heading">{text}</div>
        <div className="flex items-center gap-1.5 text-sbase text-primaryText">
          <OrangeStrokeInfoIcon />
          Action needed
        </div>
      </div>
      <ChevronArrowRightIcon className="ml-auto shrink-0" />
    </div>
  );
};

export default ActionNeededCard;
