import { CircleCloseIcon } from 'assets/icons';
import { GenericModal } from 'components';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface SaveChangesConfirmationPopupProps {
  onSaveChanges: VoidFn;
  closeSaveConfirmation: VoidFn;
  onCloseIconClick: VoidFn;
}

const SaveChangesConfirmationPopup: FC<SaveChangesConfirmationPopupProps> = ({
  onSaveChanges,
  closeSaveConfirmation,
  onCloseIconClick
}) => {
  return (
    <GenericModal
      CloseIcon={CircleCloseIcon}
      isActionInProgress={false}
      onPrimaryBtnClick={onSaveChanges}
      onSecondaryBtnClick={closeSaveConfirmation}
      onCloseIconClick={onCloseIconClick}
      primaryBtnId="save-changes-confirm"
      primaryBtnLabel="Save"
      secondaryBtnId="save-changes-cancel"
      secondaryBtnLabel="Discard"
      showModal={true}
      subTitle=""
      title="Do you want to save the changes?"
      height="min-h-[244px] h-[244px]"
      titleStyle="mt-3 mb-5"
    />
  );
};

export default SaveChangesConfirmationPopup;
