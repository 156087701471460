// export all reducers in your app from this file to combine all the reducers
import home from 'containers/home/homeSlice';
import auth from 'containers/auth/authSlice';
import app from 'containers/app/appSlice';
import invoices from 'containers/invoices/InvoicesSlice';
import invoicesEdit from 'containers/invoices/edit-invoice/invoicesEditSlice';
import receipts from 'containers/receipts/receiptsSlice';
import sidePanel from 'containers/payments-side-panel/sidePanelSlice';
import payouts from 'containers/payouts/payoutsSlice';
import discountTips from 'containers/discount-tips-fees/discountTipsSlice';
import createInvite from 'containers/manage-team/create-invite/createInviteSlice';
import editPermissions from 'containers/manage-team/edit-permissions/editPermissionsSlice';
import myAccount from 'containers/my-account/myAccountSlice';
import teamProfile from 'containers/team-profile/teamProfileSlice';
import tax from 'containers/product-catalog/taxes/taxSlice';
import item from 'containers/product-catalog/items/itemsSlice';
import category from 'containers/product-catalog/categories/categorySlice';
import serviceCharge from 'containers/product-catalog/service-charges/serviceChargeSlice';
import disputes from 'containers/disputes/disputeSlice';
import integrations from 'containers/integrations/integrationsSlice';
import digitalOnboarding from 'containers/digital-onboarding/digitalOnboardingSlice';

export default {
  home,
  auth,
  app,
  invoices,
  invoicesEdit,
  receipts,
  sidePanel,
  payouts,
  createInvite,
  editPermissions,
  myAccount,
  teamProfile,
  discountTips,
  tax,
  item,
  category,
  serviceCharge,
  disputes,
  integrations,
  digitalOnboarding
};
