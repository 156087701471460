import React, { FC } from 'react';

interface TotalSectionProps {
  total: string;
  dueDate: string;
  invoiceDate: string;
}

const TotalSection: FC<TotalSectionProps> = ({ total, dueDate, invoiceDate }) => (
  <div className="flex flex-col gap-2 rounded-md border border-borderGray bg-white p-10">
    <div className="flex items-center">
      <div className="text-[40px] font-bold text-headingGray">{`$${total}`}</div>
    </div>
    <div
      className="w-fit rounded-md border border-transparent bg-secondaryBtn px-2.5
    py-[5px] text-sbase font-semibold text-secondary">
      {`Balance due by ${dueDate}`}
    </div>
    <div className="flex gap-x-2.5">
      <div className="text-sbase font-semibold text-accentText">Invoice date</div>
      <div className="text-sbase font-semibold text-primaryText">{invoiceDate}</div>
    </div>
  </div>
);

export default TotalSection;
