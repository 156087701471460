import React, { FC, useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router-dom';
import Lottie from 'lottie-react';
import SidePanel from 'components/side-panel/SidePanel';
import { NavCloseIcon } from 'assets/icons';
import { ConnectPayout } from 'containers/payouts/payouts.model';
import DateUtils from 'utils/dateUtils';
import { Divider } from '@material-ui/core';
import { ConnectPayoutStatus, PaymentSystem } from 'containers/payouts/constants';
import { useLazyGetInTransitPayoutsQuery } from 'containers/payouts/api';
import { useAppSelector } from 'hooks';
import {
  selectCurrentTeam,
  selectCurrentTeamInvoicePermissions,
  selectPaymentSystemConfig
} from 'containers/app/appSlice';
import { LoadingSpinnerAnimation } from 'assets/animations';
import ConnectPayoutDetailsPanel from 'containers/payouts/components/ConnectPayoutDetailsPanel';
import OnTheWayPayoutsList from './OnTheWayPayoutsList';
import { checkPermissions } from 'containers/payouts/utils';
import NoPermissions from 'components/no-permissions/NoPermissions';
import PayoutDetails from 'containers/payouts/components/PayoutDetails';

const OnTheWayPayoutsListPanel: FC = () => {
  const location = useLocation();
  const { handleSidePanelClose } = useOutletContext<any>();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [getIntransitPayouts, inTransitPayoutsState] = useLazyGetInTransitPayoutsQuery();
  const [inTransitPayoutsGroupedData, setInTransitPayoutsGroupedData] = useState({
    expectedToday: [],
    expectedTomorrow: [],
    expectedInFuture: [],
    processing: []
  });

  const { expectedToday, expectedTomorrow, expectedInFuture, processing } = inTransitPayoutsGroupedData;

  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const {
    data: inTransitPayoutsData,
    isLoading: isInTransitPayoutsLoading,
    isSuccess: isInTransitPayoutsSuccess
  } = inTransitPayoutsState;

  useEffect(() => {
    if (currentTeam.id)
      getIntransitPayouts({
        teamId: currentTeam.id
      });
  }, [currentTeam.id]);

  useEffect(() => {
    if (currentTeam.id && paymentSystem) {
      setHasPermissionsToView(
        checkPermissions({
          paymentSystem,
          viewPayoutsBySelf: payoutPermissions.viewPayoutsBySelf,
          viewPayoutsByTeam: payoutPermissions.viewPayoutsByTeam
        })
      );
    }
  }, [currentTeam.id, paymentSystem]);

  useEffect(() => {
    if (currentTeam.id && hasPermissionsToView)
      getIntransitPayouts({
        teamId: currentTeam.id
      });
  }, [currentTeam.id, hasPermissionsToView]);

  useEffect(() => {
    setHasPermissionsToView(
      checkPermissions({
        paymentSystem,
        viewPayoutsBySelf: payoutPermissions.viewPayoutsBySelf,
        viewPayoutsByTeam: payoutPermissions.viewPayoutsByTeam
      })
    );
  }, [payoutPermissions.viewPayoutsBySelf, payoutPermissions.viewPayoutsByTeam]);

  useEffect(() => {
    if (isInTransitPayoutsSuccess && hasPermissionsToView) getDateGroupedPayouts(inTransitPayoutsData.data);
  }, [isInTransitPayoutsSuccess]);

  const getDateGroupedPayouts = (payouts: ConnectPayout[]) => {
    const todaysPayouts = [];
    const tomorrowsPayouts = [];
    const futurePayouts = [];
    const processingPayouts = [];
    payouts?.forEach(payout => {
      if (payout.status === ConnectPayoutStatus.SUCCESS || payout.status === ConnectPayoutStatus.IN_TRANSIT) {
        const payoutArrivalAt = DateUtils.getDateInFormatWtTimeZone({
          date: new Date(payout.arrivalAt),
          timeZone: 'UTC',
          dateFormat: 'dd MMM yyyy'
        });

        if (payoutArrivalAt === DateUtils.getToday('dd MMM yyyy')) {
          todaysPayouts.push(payout);
        } else if (payoutArrivalAt === DateUtils.getTomorrow('dd MMM yyyy')) {
          tomorrowsPayouts.push(payout);
        } else {
          futurePayouts.push(payout);
        }
      } else {
        processingPayouts.push(payout);
      }
    });
    setInTransitPayoutsGroupedData({
      expectedToday: todaysPayouts,
      expectedTomorrow: tomorrowsPayouts,
      expectedInFuture: futurePayouts,
      processing: processingPayouts
    });
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="flex h-full w-96 flex-col">
        {isInTransitPayoutsLoading ? (
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          <>
            {isInTransitPayoutsSuccess && inTransitPayoutsData.data.length > 1 && (
              <div className="flex h-full w-full flex-col gap-5 pt-5">
                <div className="flex  w-full items-center gap-2 pl-[14px]">
                  <div onClick={handleSidePanelClose} className="cursor-pointer">
                    <NavCloseIcon id="upcoming-payouts-details-panel-close" />
                  </div>
                  <div className="text-17px font-semibold text-heading">Payouts on the way</div>
                </div>
                {hasPermissionsToView ? (
                  <div className="customNormalScroll h-full overflow-auto">
                    {expectedToday.length > 0 && (
                      <OnTheWayPayoutsList payoutsList={expectedToday} headerText="Expected today" />
                    )}
                    {expectedTomorrow.length > 0 && (
                      <OnTheWayPayoutsList payoutsList={expectedTomorrow} headerText="Expected tomorrow" />
                    )}
                    {expectedInFuture.length > 0 && (
                      <OnTheWayPayoutsList payoutsList={expectedInFuture} headerText="Expected in the future" />
                    )}
                    {processing.length > 0 && (
                      <>
                        <OnTheWayPayoutsList payoutsList={processing} headerText="Processing" />
                        <Divider className="mx-[30px] bg-borderGray" />
                      </>
                    )}
                  </div>
                ) : (
                  <NoPermissions />
                )}
              </div>
            )}
            {isInTransitPayoutsSuccess &&
              inTransitPayoutsData.data.length === 1 &&
              (paymentSystem === PaymentSystem.CA ? (
                <ConnectPayoutDetailsPanel
                  handleSidePanelClose={handleSidePanelClose}
                  isIdFromProp={true}
                  payoutId={inTransitPayoutsData.data[0].id}
                />
              ) : (
                <PayoutDetails
                  onSidePanelClose={handleSidePanelClose}
                  isIdFromProp={true}
                  payoutId={inTransitPayoutsData.data[0].id}
                />
              ))}
          </>
        )}
      </div>
    </SidePanel>
  );
};

export default OnTheWayPayoutsListPanel;
