import { DraggableItemIcon, FormEditIcon } from 'assets/icons';
import { If } from 'components';
import { selectCurrentTeamFeePermissions } from 'containers/app/appSlice';
import {
  InvoiceLineItem,
  addInvoiceLineItem,
  modifyLineItem,
  removeInvoiceLineItem,
  removeInvoiceValidationError,
  resetInvoiceDraftData,
  selectAppliedLineItemLevelTaxes,
  selectCurrentSurcharge,
  selectDiscountFormData,
  selectDiscountSettings,
  selectInvoiceDraftDataInInvoiceSlice,
  selectInvoiceFinalAmount,
  selectInvoiceLineItems,
  selectInvoiceSubtotal,
  selectInvoiceValidationErrors,
  selectIsBackFromCreateTaxFromInvoiceSlice,
  selectSelectedServiceCharge,
  selecteLineItemsTotal,
  updateIsBackFromCreateTaxInInvoiceSlice,
  updateLineItemsOrderInInvoiceSlice
} from 'containers/invoices/InvoicesSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, createContext, useContext, useEffect, useState } from 'react';
import { formattedLineItemQty, getAmountWithCurrency } from 'utils/amountUtils';
import { InvoiceTabEnum, InvoiceValidationErrorType, minimumLineItemsError } from '../constants';
import AddLineItemSectionWrapper from './AddLineItemSectionWrapper';
import { EInvoiceCatalogElementType, EInvoiceModalType, EItemModalType, ISaveLineItem } from '../types';
import AddItemModal, { IAddItemModalConfig } from './AddItemModal';
import { getAggregatedTaxInCurrencyWithSymbol } from 'containers/invoices/utils';
import AddServiceChargeWrapper from './AddServiceChargeWrapper';
import { LineItemAdditionalTax } from 'containers/invoices/types';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';
import AddInvoiceDiscountWrapper from './AddInvoiceDiscountWrapper';
import Draggable from 'react-draggable';
import AddServiceFeeWrapper from './AddServiceFeeWrapper';

interface LineItemsSectionProps {
  className?: string;
  isLoadingTaxesAndFees: boolean;
  context: React.Context<any>;
}

const addItemModalConfigInitState: IAddItemModalConfig = {
  open: false,
  data: { id: 0, name: '', unitPrice: '', sku: '', description: '', selectedTaxes: [], quantity: '1' },
  editMode: false
};

export interface ILineItemsSectionContext {
  showAddFromCatalogModal: boolean;
  setShowAddFromCatalogModal: React.Dispatch<React.SetStateAction<boolean>>;
}

export const LineItemsSectionContext = createContext<ILineItemsSectionContext>({
  showAddFromCatalogModal: false,
  setShowAddFromCatalogModal: undefined
});

const LineItemsSection: FC<LineItemsSectionProps> = ({ isLoadingTaxesAndFees, context }) => {
  const isBackFromCreateTaxRoute = useAppSelector(selectIsBackFromCreateTaxFromInvoiceSlice);
  const selectedInvoiceDraftData = useAppSelector(selectInvoiceDraftDataInInvoiceSlice);
  const currentInvoiceValidationErrors = useAppSelector(selectInvoiceValidationErrors);
  const currentSurchargeState = useAppSelector(selectCurrentSurcharge);
  const discountSettingsState = useAppSelector(selectDiscountSettings);
  const selectedServiceCharge = useAppSelector(selectSelectedServiceCharge);
  const lineItemsTotalState = useAppSelector(selecteLineItemsTotal);
  const invoiceLineItemsState = useAppSelector(selectInvoiceLineItems);
  const discountFormDataState = useAppSelector(selectDiscountFormData);
  const invoiceFinalAmountState = useAppSelector(selectInvoiceFinalAmount);
  const invoiceSubtotalState = useAppSelector(selectInvoiceSubtotal);
  const invoiceFeePermissions = useAppSelector(selectCurrentTeamFeePermissions);

  const selectedAppliedLineItemLevelTaxes = useAppSelector(selectAppliedLineItemLevelTaxes);
  const [showAddFromCatalogModal, setShowAddFromCatalogModal] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { selectedInvoiceTab } = useContext<any>(context);
  const isTabSelected =
    selectedInvoiceTab === InvoiceTabEnum.LINE_ITEMS || selectedInvoiceTab === InvoiceTabEnum.LINE_ITEMS_INPUT;
  const [addItemModalConfig, setAddItemModalConfig] = useState<IAddItemModalConfig>(addItemModalConfigInitState);
  const [items, setItems] = useState([]);

  useEffect(() => {
    setItems(invoiceLineItemsState); // while navigating & returning, item order resets
  }, [invoiceLineItemsState]);

  const showDraftEditItemModal = () => {
    const { modalConfig } = selectedInvoiceDraftData ?? {};
    const itemModalConfig: IAddItemModalConfig = {
      open: true,
      data: modalConfig?.data ?? addItemModalConfigInitState,
      editMode: modalConfig?.editMode ?? false,
      draftFlow: modalConfig?.draftFlow
    };
    if (selectedInvoiceDraftData.invoiceModalType === EInvoiceModalType.EDIT_ITEM_MODAL) {
      setAddItemModalConfig({
        data: itemModalConfig.data,
        open: true,
        editMode: true,
        draftFlow: { isDraftFlow: true, saveToCatalog: false }
      });

      // Add, one-time modals are rendered in addlineitemsection wrapper
      if (isBackFromCreateTaxRoute) {
        dispatch(resetInvoiceDraftData());
        dispatch(updateIsBackFromCreateTaxInInvoiceSlice(false));
      }
    }
  };

  useEffect(() => {
    if (isBackFromCreateTaxRoute) {
      if (
        selectedInvoiceDraftData &&
        selectedInvoiceDraftData?.invoiceCatalogElementType === EInvoiceCatalogElementType.ITEM &&
        selectedInvoiceDraftData?.invoiceModalType === EInvoiceModalType.EDIT_ITEM_MODAL
      ) {
        showDraftEditItemModal();
      }
    }
  }, []);

  const resetItemModalConfig = () => {
    setAddItemModalConfig(addItemModalConfigInitState);
  };

  const onRemoveLineItem = (lineItem: InvoiceLineItem) => {
    dispatch(removeInvoiceLineItem(lineItem));
  };

  const onEditLineItem = (lineItem: InvoiceLineItem) => {
    const itemData = {
      id: +lineItem.id,
      catalogueLineItemId: lineItem.catalogueLineItemId,
      name: lineItem.itemDescription,
      unitPrice: lineItem.unitCost,
      sku: lineItem.sku,
      description: lineItem.additionalDetails,
      selectedTaxes: lineItem?.taxes ?? [],
      quantity: lineItem.quantity
    };
    setAddItemModalConfig({ open: true, data: itemData, editMode: true });
  };

  const onSaveLineItemsClick = (lineItemData: ISaveLineItem) => {
    dispatch(addInvoiceLineItem(lineItemData.invoiceLineItem));
    dispatch(removeInvoiceValidationError(minimumLineItemsError));
    resetItemModalConfig();
  };

  const onModifyLineItemClick = (lineItem: InvoiceLineItem) => {
    dispatch(modifyLineItem(lineItem));
    resetItemModalConfig();
  };

  const isAddDiscountDisabled =
    invoiceLineItemsState?.length === 0 || invoiceLineItemsState === null || !invoiceFeePermissions.viewDiscount;

  const isAddServiceChargeDisabled = invoiceLineItemsState?.length === 0 || invoiceLineItemsState === null;

  const getFormattedSubtotalCurrency = () => {
    if (!invoiceSubtotalState) {
      return getAmountWithCurrency(0);
    }
    const subTotal = invoiceSubtotalState;
    return getAmountWithCurrency(subTotal);
  };

  const getCurrencyFormattedFinalAmount = () => {
    return getAmountWithCurrency(invoiceFinalAmountState);
  };

  const getLineItemErrorMsg = () => {
    const lineItemError = currentInvoiceValidationErrors.find(error => {
      if (error.type === InvoiceValidationErrorType.MIN_LINE_ITEM) {
        return error;
      }
    });
    return lineItemError?.errorMsg;
  };

  const onCloseAddItemModal = (): void => {
    setAddItemModalConfig(addItemModalConfigInitState);
  };

  const getLineItemTaxesText = (lineItemTaxes: LineItemAdditionalTax[]) => {
    if (lineItemTaxes?.length === 1) return '+1 tax';
    return `+${lineItemTaxes?.length} taxes`;
  };

  const handleDragStart = (e, data, index) => {
    const draggedItemDiv = document.getElementById(`draggable-line-item-div-${index}`);
    if (draggedItemDiv) {
      draggedItemDiv.style.zIndex = '10';
    }
  };

  const handleDrag = (e, data, index) => {
    const itemDivHeight = getHeightOfDiv(index);
    let newIndex = index + Math.round(data.lastY / itemDivHeight);

    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= items.length) {
      newIndex = items.length - 1;
    }

    if (index !== newIndex) {
      const newItems = [...items];
      const movedItem = newItems.splice(index, 1)[0];
      newItems.splice(newIndex, 0, movedItem);

      setItems(newItems);
      dispatch(updateLineItemsOrderInInvoiceSlice(newItems));
    }

    // Dynamically adjust the z-index while dragging
    const draggedItemDiv = document.getElementById(`draggable-line-item-div-${index}`);
    if (draggedItemDiv) {
      const zIndex = items.length - newIndex;
      draggedItemDiv.style.zIndex = zIndex.toString();
    }
  };

  const getHeightOfDiv = (index: number) => {
    const div = document.querySelector(`#draggable-line-item-div-${index}`);
    if (div) {
      const elementRect = div.getBoundingClientRect();
      return elementRect.height;
    }
    return 0;
  };

  return (
    <LineItemsSectionContext.Provider value={{ showAddFromCatalogModal, setShowAddFromCatalogModal }}>
      <>
        <div>
          <div className={`mb-2 text-xl font-bold ${isTabSelected ? 'text-primaryBtn' : 'text-heading'}`}>
            Line Items
          </div>
          <div
            className={`flex flex-col rounded-md border px-[30px] py-5 1439px:p-10 ${
              isTabSelected ? 'border-primaryBtn' : 'border-borderGray'
            }`}>
            {/* lint item headers */}
            <If condition={invoiceLineItemsState?.length > 0}>
              <div className="mb-4 flex flex-row text-sbase font-semibold text-primaryText">
                <div className="w-1/2 min-w-[60px] whitespace-nowrap">Item</div>
                <div className="w-[15%] min-w-[60px] whitespace-nowrap">Unit Cost</div>
                <div className="w-[15%] min-w-[60px] whitespace-nowrap text-center">Quantity</div>
                <div className="w-[20%] min-w-[60px] whitespace-nowrap pr-5 text-right">Total</div>
              </div>
            </If>
            <div className="space-y-5">
              <div className="relative space-y-5">
                {items?.map((lineItem, index) => {
                  {
                    return (
                      /* LineItemCard */
                      <Draggable
                        key={index}
                        handle=".draggableContainer"
                        axis="y"
                        position={{ x: 0, y: 0 }}
                        onStart={(e, data) => handleDragStart(e, data, index)}
                        onStop={(e, data) => handleDrag(e, data, index)}
                        bounds={'parent'}>
                        <div
                          id={`draggable-line-item-div-${index}`}
                          key={lineItem?.itemDescription + index}
                          className={`draggableContainer relative flex min-h-[85px] w-full cursor-move flex-row
                          rounded border border-borderGray bg-white py-4`}>
                          <div className="flex w-1/2 min-w-[60px] flex-col gap-3 pl-5 pr-2">
                            <div
                              onClick={() => onEditLineItem(lineItem)}
                              className="line-clamp-8 cursor-pointer break-words 
                              text-17px font-semibold text-secondary">
                              {lineItem?.itemDescription}
                            </div>
                            <div className="whitespace-pre-line text-sbase font-normal text-primaryText line-clamp-3">
                              {lineItem?.additionalDetails}
                            </div>
                          </div>
                          <div className="w-[15%] min-w-[60px] truncate text-17px font-semibold text-heading">
                            {getAmountWithCurrency(lineItem?.unitCost, '$')}
                          </div>
                          <div
                            className="flex w-[15%] min-w-[60px]
                     justify-center truncate text-17px font-semibold text-primaryText">
                            <div className="truncate">{formattedLineItemQty({ qtyString: lineItem?.quantity })}</div>
                          </div>
                          <div className="flex w-[20%] min-w-[60px] flex-col gap-3 pr-5">
                            <div
                              className="truncate text-right
                              text-17px font-semibold text-heading">
                              {getAmountWithCurrency(lineItem?.total)}
                            </div>
                            <If condition={lineItem?.taxes?.length > 0}>
                              <div className="text-right text-sbase font-normal text-primaryText">
                                {getLineItemTaxesText(lineItem?.taxes)}
                              </div>
                            </If>
                          </div>
                          <FormCloseReactIcon
                            id="create-invoice-remove-line-item-close-icon"
                            onClick={() => onRemoveLineItem(lineItem)}
                            className="absolute top-3 right-[-25px] shrink-0 cursor-pointer 1439px:right-[-30px]"
                          />
                          <FormEditIcon
                            id="create-invoice-line-item-edit-icon"
                            onClick={() => onEditLineItem(lineItem)}
                            className="absolute bottom-3 right-[-25px] mt-3 shrink-0 
                            cursor-pointer 1439px:right-[-30px]"
                          />
                          <DraggableItemIcon
                            className="absolute -left-5 top-1/2 shrink-0 
                          -translate-y-2/4 cursor-move 1439px:-left-4"
                          />
                        </div>
                      </Draggable>
                    );
                  }
                })}
              </div>

              <div id={InvoiceTabEnum.LINE_ITEMS_INPUT}>
                <AddLineItemSectionWrapper
                  onSaveLineItemClick={onSaveLineItemsClick}
                  onModifyLineItemClick={onModifyLineItemClick}
                />
              </div>

              <div className="-mt-2 mb-2 text-error">{getLineItemErrorMsg()}</div>

              {/* LineItemsTotal */}
              <div className="ml-auto w-1/2 min-w-[405px] rounded-md border border-x-borderGray">
                <If condition={discountSettingsState.enabled}>
                  <AddInvoiceDiscountWrapper
                    selectedDiscount={discountFormDataState}
                    isAddDiscountDisabled={isAddDiscountDisabled}
                    isEditInvoiceFlow={false}
                    lineItemsTotalCost={lineItemsTotalState}
                  />
                </If>

                <div
                  className="flex flex-row justify-between
                      border-b border-borderGray p-5 text-17px font-semibold text-heading">
                  <div>Subtotal</div>
                  <div id="create-invoice-line-items-subtotal-amount" className="w-0 grow break-words text-right">
                    {getFormattedSubtotalCurrency()}
                  </div>
                </div>
                <If condition={currentSurchargeState.enabled}>
                  <AddServiceFeeWrapper
                    serviceFee={currentSurchargeState}
                    isLoadingTaxesAndFees={isLoadingTaxesAndFees}
                    isEdit={false}
                  />
                </If>
                <AddServiceChargeWrapper
                  isAddServiceChargeDisabled={isAddServiceChargeDisabled}
                  selectedServiceCharge={selectedServiceCharge}
                />

                <If condition={selectedAppliedLineItemLevelTaxes?.length > 0}>
                  <div className="flex flex-col border-b border-borderGray p-5">
                    <div className="flex flex-col gap-[13px]">
                      {selectedAppliedLineItemLevelTaxes.map(aggregatedItemTax => (
                        <div className="flex flex-row items-start" key={aggregatedItemTax.id}>
                          <div className="w-1/2 break-words text-17px text-heading ">{aggregatedItemTax.name}</div>
                          {/* <div className="basis-1/4 text-right text-17px text-accentText">
                            {getFormattedAdditionalTaxPercent(aggregatedItemTax.percentage)}%
                          </div> */}
                          <div className="w-1/2 break-words text-right text-17px font-normal text-heading">
                            {getAggregatedTaxInCurrencyWithSymbol(
                              invoiceLineItemsState,
                              aggregatedItemTax,
                              selectedServiceCharge
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </If>
                <div
                  className="flex h-[50px] flex-row items-center justify-between bg-borderGray
                 px-[22px] text-xl font-bold text-heading">
                  <div>Total Amount</div>
                  <div id="create-invoice-line-items-total-amount" className="w-0 grow break-words text-right">
                    {getCurrencyFormattedFinalAmount()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {addItemModalConfig.open && (
          <AddItemModal
            title="Edit Item"
            isEdit={true}
            onSaveLineItemClick={onSaveLineItemsClick}
            onModifyLineItemClick={onModifyLineItemClick}
            config={addItemModalConfig}
            onClose={onCloseAddItemModal}
            resetItemModalConfig={onCloseAddItemModal}
            isEditInvoiceFlow={false}
            modalType={EItemModalType.EDIT_ITEM_MODAL}
          />
        )}
      </>
    </LineItemsSectionContext.Provider>
  );
};

export default LineItemsSection;
