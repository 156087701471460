import Lottie from 'lottie-react';
import { FC } from 'react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { ITeamMember } from '../manageTeam.model';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface ReactivateMemberModalProps {
  open: boolean;
  toggleOpen: (teamMember: ITeamMember) => void;
  showLoader: boolean;
  teamMember: ITeamMember;
  reactivateMemberHandler: (teamMember: any) => void;
}
const ReactivateMemberModal: FC<ReactivateMemberModalProps> = ({
  reactivateMemberHandler,
  showLoader,
  teamMember,
  open,
  toggleOpen
}) => {
  const onCancelClick = () => {
    toggleOpen(teamMember);
  };

  const handleReactivateMemberButtonClick = () => {
    reactivateMemberHandler(teamMember);
  };

  return (
    <CustomModal width="md:w-96" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded-md p-10">
        <div className="flex flex-col items-center justify-center">
          {showLoader ? (
            <div className="flex w-full flex-col items-center justify-center p-10">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          ) : (
            <>
              <FormCloseReactIcon
                id="reactivate-member-modal-close"
                height="24"
                width="24"
                className="absolute top-2 right-2 shrink-0 cursor-pointer"
                onClick={onCancelClick}
              />
              <div className="flex w-full flex-col items-center justify-center">
                <p className="text-xl font-bold leading-6 text-heading">Reactivate Member?</p>
                <p className="mt-4 text-center text-17px font-normal leading-5 text-primaryText">
                  User will re-gain access to the team, including their historical data.
                </p>
              </div>
              <CustomButton
                onClick={handleReactivateMemberButtonClick}
                id="reactivate-member-modal-yes-button"
                className={`text-semibold mt-5 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
                Yes
              </CustomButton>
              <CustomButton
                id="reactivate-member-modal-no-button"
                className="mt-6 h-[38px] w-full"
                type={ButtonType.SECONDARY}
                onClick={onCancelClick}>
                No
              </CustomButton>
            </>
          )}
        </div>
      </div>
    </CustomModal>
  );
};
export default ReactivateMemberModal;
