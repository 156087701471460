import { GenericDeleteModal } from 'components';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface DeleteItemPopupProps {
  isDeleteItemLoading: boolean;
  onDeleteItem: VoidFn;
  closeDeleteConfirmation: VoidFn;
}

const DeleteItemConfirmationPopup: FC<DeleteItemPopupProps> = ({
  isDeleteItemLoading,
  onDeleteItem,
  closeDeleteConfirmation
}) => {
  return (
    <GenericDeleteModal
      isActionInProgress={isDeleteItemLoading}
      onPrimaryBtnClick={onDeleteItem}
      onSecondaryBtnClick={closeDeleteConfirmation}
      primaryBtnId="delete-item-confirm"
      primaryBtnLabel="Yes, Delete"
      secondaryBtnId="delete-item-cancel"
      secondaryBtnLabel="No, Cancel"
      showModal={true}
      subTitle="Deleting an item only affects future invoices not past or pending ones."
      title="Are you sure you want to delete this Item?"
    />
  );
};

export default DeleteItemConfirmationPopup;
