import { FirebaseNotification, TeamInviteDataType } from 'types/notification';
import DateUtils from 'utils/dateUtils';
import React, { useEffect, useState } from 'react';
import { If } from 'components';
import { NotificationItem } from 'containers/notification-drawer/components/NotificationItem';
import { VoidFn } from 'types/baseTypes';
import { TeamInviteCard } from './TeamInviteCard';
import { useAcceptTeamInviteMutation, useDeclineTeamInviteMutation } from 'containers/manage-team/api';
import { useLazyGetMerchantTeamsQuery } from 'containers/app/api';
import { useAppDispatch } from 'hooks';
import { NotifierTypes } from 'constants/notifierConstants';
import {
  initialBannerState,
  setCurrentTeamAfterInviteAccept,
  showNotifier,
  updateBannersData
} from 'containers/app/appSlice';
import { getInitials } from 'utils/commonUtils';

interface NotificationListProps {
  notifications: FirebaseNotification[];
  onCloseNotificationPanel: VoidFn;
  activeTab: string;
}

export const NotificationsList = ({ notifications, onCloseNotificationPanel, activeTab }: NotificationListProps) => {
  const [invitedTeam, setInvitedTeam] = useState<TeamInviteDataType>(null);

  const dispatch = useAppDispatch();

  // eslint-disable-next-line react/no-multi-comp
  const dateGroupedNotifications =
    activeTab === 'General'
      ? []
      : notifications.reduce((record: any, notification: FirebaseNotification) => {
          let date = new Date(parseInt(notification.date)).toLocaleDateString();
          const today = new Date().toLocaleDateString();
          const yesterday = new Date(DateUtils.getYesterday()).toLocaleDateString();
          const formattedDate = DateUtils.getDateInFormat({
            date: new Date(parseInt(notification.date)),
            dateFormat: 'dd-MMM-yyyy'
          });

          if (date === today) date = 'Today';
          if (date === yesterday) date = 'Yesterday';

          const oldRecord = record.find((_: any) => _.date === formattedDate);
          if (!oldRecord) {
            record.push({
              date: formattedDate,
              records: [{ ...notification }]
            });
          } else {
            oldRecord.records.push(notification);
            oldRecord.records.sort((a, b) => b.date - a.date);
          }

          return record;
        }, []);

  const [acceptTeamInviteMutation, { isSuccess: teamInviteSuccess }] = useAcceptTeamInviteMutation();
  const [declineTeamInviteMutation] = useDeclineTeamInviteMutation();
  const [getMerchantTeamsQuery] = useLazyGetMerchantTeamsQuery();

  useEffect(() => {
    if (teamInviteSuccess) {
      getMerchantTeamsQuery();
      onCloseNotificationPanel();
      dispatch(setCurrentTeamAfterInviteAccept(invitedTeam.teamId));
      const message = `<div>
        Viewing team <b>'${invitedTeam?.teamName}'</b>
      </div>`;
      dispatch(
        showNotifier({
          message: {
            primaryMessage: message,
            secondaryMessage: '',
            iconSrc: invitedTeam?.profilePictureSignedUrl,
            iconInitials: getInitials(invitedTeam?.teamName),
            isMessageHtml: true
          },
          type: NotifierTypes.CUSTOM,
          showClose: false,
          bgStyle: 'bg-[#3E556D] rounded-md',
          fontStyle: 'text-primary font-normal',
          shouldAnimate: true
        })
      );
      dispatch(updateBannersData(initialBannerState));
    }
  }, [teamInviteSuccess]);

  const handleAcceptTeamInvite = (invitedTeamData: TeamInviteDataType) => {
    setInvitedTeam(invitedTeamData);
    acceptTeamInviteMutation({ inviteToken: invitedTeamData.inviteToken });
  };

  const handleDeclineTeamInvite = (inviteToken: string) => {
    declineTeamInviteMutation({ inviteToken });
  };

  const isNotificationsEmpty = () => {
    if (activeTab === 'General') {
      return notifications.length === 0;
    }
    return dateGroupedNotifications && dateGroupedNotifications.length === 0;
  };

  return (
    <div className="customNormalScroll notification-panel-list h-full overflow-auto">
      <If condition={isNotificationsEmpty()}>
        <div className="flex h-full flex-col items-center justify-center">No Notifications</div>
      </If>

      <If condition={!isNotificationsEmpty()}>
        <div>
          <>
            {activeTab === 'General' ? (
              <>
                {notifications.map((invite: any) => (
                  <TeamInviteCard
                    teamInviteData={invite}
                    onAcceptInvite={handleAcceptTeamInvite}
                    onDeclineInvite={handleDeclineTeamInvite}
                    key={invite.id}
                  />
                ))}{' '}
              </>
            ) : (
              <>
                {dateGroupedNotifications.reverse().map((group: any) => {
                  if (group.records.length === 0) {
                    return;
                  }
                  return (
                    <>
                      <div className="bg-borderGray py-2 px-4 font-bold text-tertiaryText">
                        {DateUtils.getDateInFormat({ date: new Date(group?.date), dateFormat: 'dd-MMM-yy' })}
                      </div>

                      {group?.records.map((item: FirebaseNotification) => {
                        return (
                          <NotificationItem
                            notification={item}
                            key={`${item.teamId}-${item.date}`}
                            onCloseNotificationPanel={onCloseNotificationPanel}
                          />
                        );
                      })}
                    </>
                  );
                })}
              </>
            )}
          </>
        </div>
      </If>
    </div>
  );
};
