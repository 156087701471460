import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { IItemBasic } from '../items/items.model';
import { ICategory } from './categories.model';

export interface CategorySliceState {
  isEditCategoryFlow: boolean;
  categoryId: number;
  categoryName: string;
  lineItemsCount: number;
  initializingCategorySliceInProgress: boolean;
  lineItems: IItemBasic[];
  deselectedLineItemList: number[]; // this includes all the unselected line items from the whole lineItemList
}

const initialState: CategorySliceState = {
  isEditCategoryFlow: false,
  categoryId: null,
  categoryName: '',
  lineItemsCount: 0,
  initializingCategorySliceInProgress: false,
  lineItems: [],
  deselectedLineItemList: []
};

const isDeselectedLineItem = ({
  initialLineItem,
  deselectedLineItemList
}: {
  initialLineItem: IItemBasic;
  deselectedLineItemList: number[];
}) => {
  return deselectedLineItemList.includes(initialLineItem.id);
};

const isAlreadyInSliceLineItemList = ({
  initialLineItem,
  currentSliceLineItemList
}: {
  initialLineItem: IItemBasic;
  currentSliceLineItemList: IItemBasic[];
}) => {
  return currentSliceLineItemList.some(currentLineItem => currentLineItem.id === initialLineItem.id);
};

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    resetCategorySlice: () => initialState,

    initializeCategorySliceFromAPI: (state, { payload }: PayloadAction<ICategory>) => {
      state.categoryId = payload.id;
      state.categoryName = payload.name;
      state.isEditCategoryFlow = true;
      if (payload.lineItems) {
        const filteredLineItems = payload.lineItems.filter(
          initialLineItem =>
            !isDeselectedLineItem({ initialLineItem, deselectedLineItemList: state.deselectedLineItemList }) &&
            !isAlreadyInSliceLineItemList({ initialLineItem, currentSliceLineItemList: state.lineItems })
        );
        state.lineItems = [...state.lineItems, ...filteredLineItems];
        state.lineItemsCount = state.lineItems.length;
      }
      state.initializingCategorySliceInProgress = false;
    },

    updateCategoryName: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.categoryName = payload.name;
    },

    updateInitalizingCategorySliceInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.initializingCategorySliceInProgress = payload;
    },

    updateSelectedItems: (state, { payload }: PayloadAction<IItemBasic[]>) => {
      state.lineItems = payload;
      state.lineItemsCount = payload.length;
    },

    updateDeselectedItems: (state, { payload }: PayloadAction<number[]>) => {
      state.deselectedLineItemList = payload;
    },

    addNewlyCreatedLineItemInCategorySlice: (state, { payload }: PayloadAction<IItemBasic>) => {
      state.lineItems = [...state.lineItems, payload];
      state.lineItemsCount = state.lineItemsCount + 1;
    }
  }
});

export const {
  resetCategorySlice,
  updateInitalizingCategorySliceInProgress,
  initializeCategorySliceFromAPI,
  updateCategoryName,
  updateSelectedItems,
  updateDeselectedItems,
  addNewlyCreatedLineItemInCategorySlice
} = categorySlice.actions;

export const selectCategoryState = (state: RootState) => state.rootReducer.category;
export const selectInitializingCategorySliceInProgress = (state: RootState) =>
  state.rootReducer.category.initializingCategorySliceInProgress;
export const selectCategoryLineItemsCount = (state: RootState) => state.rootReducer.category.lineItemsCount;

export default categorySlice.reducer;
