/* eslint-disable max-len */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { CrossIconvector, GreenTickAnimatedIcon, SendBlueIcon } from 'assets/icons';
import { VoidFn } from 'types/baseTypes';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { useAppSelector } from 'hooks';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import SidePanel from 'components/side-panel/SidePanel';
import { selectCurrentTeam } from 'containers/app/appSlice';

interface SuccessReceiptProps {
  onClose?: VoidFn;
  routeToNextPage?: ({ type }: { type: SidePanelTypes; id: string }) => void;
}

const RefundSuccessPanel: React.FC<SuccessReceiptProps> = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { receiptId, rootInvoiceId, rootReceiptId, rootCustomerId, rootPage, rootDisputeId } = useParams();
  const navigate = useNavigate();

  const { handleSidePanelClose } = useOutletContext<any>();

  const handleReceiptShare = () => {
    if (rootReceiptId) {
      if (receiptId) {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund/${receiptId}`);
      } else {
        navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/refund`);
      }
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/refund-receipt/${receiptId}`);
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/refund-receipt/${receiptId}`);
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/refund-receipt/${receiptId}`);
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/refund-receipt/${receiptId}`, {
        replace: true
      });
    }
  };

  const MainSection = () => {
    return (
      <div className="flex h-full flex-col">
        <div className="flex flex-row items-center border-b-[1px] py-4 pl-4 " onClick={handleSidePanelClose}>
          <CrossIconvector className="shrink-0 cursor-pointer" />
          <div className="pl-2 text-17px font-bold text-primaryText">Create refund</div>
        </div>

        <div className="flex h-full flex-1 flex-col justify-center px-4">
          <div className="flex flex-col items-center">
            <GreenTickAnimatedIcon />
            <div className={'mt-4 text-17px font-semibold text-headingGray'}>Refund Initiated</div>
            <div
              className={
                'mx-4 mt-6 whitespace-pre-line text-center text-[15px] font-normal leading-5 text-primaryText'
              }>
              {'Customer will receive the \n  refund in their source of payment \n in 5 to 10 business days.'}
            </div>
          </div>
          <button
            className="mt-8 flex min-h-[42px] w-full justify-center space-x-2.5
              rounded-md border-primaryBtn bg-primaryBtnText py-3 align-middle font-lato text-primaryBtn "
            style={{ borderWidth: 1 }}
            onClick={handleReceiptShare}>
            <SendBlueIcon />
            <div className="font-semibold">Share</div>
          </button>
        </div>
      </div>
    );
  };
  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose}>
      <section
        className={
          'w-96 right-0 ' +
          'absolute bg-white h-full shadow-xl ' +
          'delay-400 duration-500 ease-in-out transition-all transform overflow-auto'
        }>
        <MainSection />
      </section>
    </SidePanel>
  );
};

export default RefundSuccessPanel;
