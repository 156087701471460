import { ArrowRightIcon, BadgeIcon, PayoutsFlowIcon, PayoutsRouteIcon, PayoutsSecureIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface ConfirmBankAccountModalProps {
  onFAQClick: VoidFn;
  openConfirmBankAccount: VoidFn;
  onBack: VoidFn;
}

const ConfirmBankAccountModal: FC<ConfirmBankAccountModalProps> = ({ onBack, onFAQClick, openConfirmBankAccount }) => {
  const newFeatureInfoList = [
    {
      text: 'Better protections with payment upgrades',
      subtext: 'Our behind-the-scene changes will help reduce payment failures and chances of disputes in some cases.',
      Icon: BadgeIcon
    },
    {
      text: 'Easier accounting with daily payouts',
      subtext: `Your payments will now be collated and sent as a single payout as an 
                ACH to the account of your choice. Track your payouts accurately.`,
      Icon: PayoutsFlowIcon
    },
    {
      text: 'Lower fees on ACH payments (upcoming)',
      subtext: 'We will also be make it easier and cheaper to collect ACH payments for your invoices.',
      Icon: PayoutsRouteIcon
    }
  ];

  const renderPointElem = ({ Icon, text, subtext }) => {
    return (
      <div className="flex gap-5">
        <div className="mx-auto flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-[#3876BB1A]">
          {Icon && <Icon />}
        </div>
        <div>
          <div className="text-17px font-semibold text-primaryText">{text}</div>
          <div className="text-sbase text-primaryText">{subtext}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full">
        <div className="flex p-5 pb-2.5">
          <ArrowRightIcon className="rotate-180 cursor-pointer" onClick={onBack} width={20} height={20} />
        </div>
        <div className="py-[30px] px-[22px]">
          <div className="space-y-5 px-7 text-center">
            <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-secondaryBtn">
              <PayoutsSecureIcon />
            </div>
            <div className="text-xl font-bold text-headingGray">Payments & payouts just got more secure</div>
          </div>
          <div className="py-5 text-sbase text-primaryText">
            We're making some significant upgrades to our core payments and payouts technology to better serve our
            community.
          </div>
          <div className="space-y-[30px]">{newFeatureInfoList.map(item => renderPointElem(item))}</div>
          <div className="mt-9 space-y-2.5">
            <CustomButton
              id="upgrade-confirm-button"
              className="w-full"
              onClick={openConfirmBankAccount}
              childrenContainerStyles="font-normal text-17px">
              Next: Confirm bank account
            </CustomButton>
            <CustomButton
              id="upgrade-Faq-button"
              className="w-full text-sbase font-semibold text-secondary active:shadow-none"
              type={null}
              onClick={onFAQClick}>
              How does this work?
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmBankAccountModal;
