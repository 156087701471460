import { GreenFilledTickIcon, TopBarIcon } from 'assets/icons';
import { FC } from 'react';
import TestimonialSection from './components/TestimonialSection';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useNavigate } from 'react-router-dom';
import { RoutePath } from 'routes/index';

const LandingPage: FC = () => {
  const navigate = useNavigate();

  const handleSignInClick = () => {
    navigate(RoutePath.SIGNIN);
  };

  const handleSignUpClick = () => {
    navigate(RoutePath.SIGN_UP);
  };

  const content = [
    'No Card Reader Required',
    'Free Professional Invoicing',
    '100% Safe & Secure',
    '2.5% Processing Fee'
  ];

  return (
    <div className="flex h-screen w-screen flex-row">
      <div className="relative flex w-full flex-col bg-primary p-12 lg:w-1/2">
        <TopBarIcon className="shrink-0 sm:absolute" />
        <div className="m-auto">
          <div className="mb-7 text-center text-3xl font-bold text-secondary">
            The simplest way to <br />
            accept payments
          </div>
          <div className="mb-10 space-y-3">
            {content.map(item => (
              <div className="flex items-center gap-2" key={item}>
                <GreenFilledTickIcon />
                <div className="text-17px font-semibold text-primaryText">{item}</div>
              </div>
            ))}
          </div>
          <div className="space-y-8">
            <CustomButton type={ButtonType.PRIMARY} id="sign-in-button" className="w-full" onClick={handleSignInClick}>
              Sign In
            </CustomButton>
            <CustomButton
              type={ButtonType.SECONDARY}
              id="sign-up-button"
              className="w-full"
              onClick={handleSignUpClick}>
              Sign Up
            </CustomButton>
          </div>
        </div>
      </div>
      <div className="hidden w-1/2 bg-secondary lg:flex">
        <TestimonialSection />
      </div>
    </div>
  );
};

export default LandingPage;
