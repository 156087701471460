import { ITeamMember } from './manageTeam.model';

export enum TeamMemberActionType {
  EDIT_PERMISSIONS = 'Edit Permissions',
  SHARE_INVITE_LINK = 'Share Invite Link',
  DELETE_INVITE = 'Delete Invite',
  DEACTIVATE = 'Deactivate',
  REACTIVATE = 'Re-activate',
  SEND_NEW_INVITE = 'Send New Invite'
}

export interface IEditPermissionModalState {
  open: boolean;
  teamMember: ITeamMember;
}
