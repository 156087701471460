import { LoadingSpinnerAnimation } from 'assets/animations';
import { CustomButton, CustomModal, If, ListDropDown } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { Permission, TeamRole } from 'containers/app/app.model';
import { selectCurrentTeam, selectCurrentTeamTeamSettingsPermissions } from 'containers/app/appSlice';
import { getPermissions } from 'containers/app/util';
import { useGetTeamMemberPermissionsQuery } from 'containers/manage-team/api';
import PermissionsSection from 'containers/manage-team/components/PermissionsSection';
import { ITeamMember, ITeamMemberDetails, RoleIdEnum } from 'containers/manage-team/manageTeam.model';
import { getRolesInDropdown } from 'containers/manage-team/utils';
import { useAppDispatch, useAppSelector } from 'hooks';
import Lottie from 'lottie-react';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { teamMemberRoles } from '../constants';
import {
  changeRole,
  selectEditedPermission,
  selectSavedPermission,
  selectTeamMemberRole
} from '../editPermissionsSlice';
import { TeamMemberRoleItems } from '../types';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface TeamRoleModalProps {
  open: boolean;
  onCancel: VoidFn;
  teamMemberData?: ITeamMember | ITeamMemberDetails;
  handlePermissionEdit?: VoidFn;
  onUpdateClick: VoidFn;
  isUpdating?: boolean;
  withoutPermissionsAPICall?: boolean;
  allowEdit?: boolean;
}

const TeamRoleModal: FC<TeamRoleModalProps> = ({
  open,
  onCancel,
  teamMemberData = {
    roleId: RoleIdEnum.MEMBER,
    id: null,
    firstName: null,
    middleName: null,
    lastName: null,
    profilePicture: null,
    createdAt: null,
    email: null,
    phone: null,
    status: null,
    isActive: null,
    activeSince: null,
    inactiveSince: null
  },
  handlePermissionEdit,
  onUpdateClick,
  isUpdating,
  withoutPermissionsAPICall = false,
  allowEdit = false
}) => {
  const dispatch = useAppDispatch();
  const selectedCurrentTeamTeamSettingsPermissions = useAppSelector(selectCurrentTeamTeamSettingsPermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const selectedSavedPermission = useAppSelector(selectSavedPermission);
  const selectedCurrentPermissions = useAppSelector(selectEditedPermission);
  const selectedTeamRole = useAppSelector(selectTeamMemberRole);
  const [selectedRole, setSelectedRole] = useState<TeamMemberRoleItems>(teamMemberRoles[2]);
  const [currentPermission, setCurrentPermission] = useState<Permission>(getPermissions(TeamRole.MEMBER));
  const [isLoadingCall, setIsLoadingCall] = useState(true);

  const { isFetching, isSuccess: isGetTeamMemberRoleSuccess } = useGetTeamMemberPermissionsQuery(
    {
      roleId: teamMemberData.roleId,
      teamId: currentTeam.id
    },
    { skip: withoutPermissionsAPICall, refetchOnMountOrArgChange: true }
  );

  useEffect(() => {
    if (isGetTeamMemberRoleSuccess) {
      const currentRole = teamMemberRoles.find(teamRole => teamRole.role === selectedTeamRole);
      setSelectedRole(currentRole);
      setIsLoadingCall(false);
      setCurrentPermission(selectedCurrentPermissions);
    }
  }, [isGetTeamMemberRoleSuccess, isFetching]);

  useEffect(() => {
    if (withoutPermissionsAPICall) {
      const currentRole = teamMemberRoles.find(teamRole => teamRole.role === selectedSavedPermission.role);
      setSelectedRole(currentRole);
      setCurrentPermission(selectedSavedPermission);
    }
  }, [selectedSavedPermission]);

  const onRoleChangeClick = (teamMemberRoleItem: TeamMemberRoleItems) => {
    setSelectedRole(teamMemberRoleItem);
    setCurrentPermission(getPermissions(teamMemberRoleItem.role));
    dispatch(changeRole(teamMemberRoleItem.role));
  };

  const getHeader = () => {
    if (teamMemberData?.firstName) {
      return `${teamMemberData?.firstName} ${teamMemberData?.lastName} - Team Role`;
    }
    return 'Team Role';
  };

  return (
    <CustomModal className="md:w-[423px]" open={open} closeModal={onCancel}>
      <div className="relative flex max-h-[95vh] flex-col rounded-md">
        <If condition={isFetching || isLoadingCall}>
          <div className="absolute flex h-full w-full flex-col items-center justify-center rounded-md ">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        </If>
        <div
          className={`${isFetching || isLoadingCall ? 'invisible' : 'visible'} flex max-h-[95vh] flex-col rounded-md`}>
          <div className="flex w-full cursor-pointer justify-end p-2">
            <FormCloseReactIcon id="team-role-modal-close" onClick={onCancel} height="24" width="24" />
          </div>
          <div className="sticky top-0 z-[60] rounded-md bg-primary px-10">
            <div className="mb-[21px] text-xl font-bold text-heading">{getHeader()}</div>
            <ListDropDown
              buttonStyles="bg-secondaryBtn h-[50px] text-17px font-semibold text-primaryText cursor-pointer"
              items={getRolesInDropdown(
                currentTeam,
                selectedCurrentTeamTeamSettingsPermissions,
                currentPermission.permissions.banking.level
              )}
              selectedItem={selectedRole}
              setSelectedItem={onRoleChangeClick}
              wrapperStyles="mb-5 bg-primary"
              optionStyles="py-2.5"
              id="edit-permissions-select-team-role-dropdown"
            />
          </div>
          <div className="mx-10 mb-5 rounded-md border border-border pb-[25px]">
            <PermissionsSection
              permissions={currentPermission.permissions}
              onEditClick={handlePermissionEdit}
              allowEdit={allowEdit}
            />
          </div>
          <div className="w-full px-10 pb-11">
            <CustomButton
              onClick={onUpdateClick}
              className="mb-6 w-full"
              id="edit-team-role-update"
              isLoading={isUpdating}>
              Update
            </CustomButton>
            <CustomButton
              onClick={onCancel}
              className="w-full"
              id="edit-permissions-cancel"
              type={ButtonType.SECONDARY}>
              Cancel
            </CustomButton>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default TeamRoleModal;
