import React, { FC } from 'react';
import { ConnectPayout } from 'containers/payouts/payouts.model';
import { Divider } from '@material-ui/core';
import PayoutCard from './PayoutCard';
import { useNavigate } from 'react-router-dom';
import { PaymentSystem, PayoutRootPage } from 'containers/payouts/constants';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import { FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';

interface OnTheWayPayoutsListProps {
  payoutsList: ConnectPayout[];
  headerText: string;
}

const OnTheWayPayoutsList: FC<OnTheWayPayoutsListProps> = ({ payoutsList, headerText }) => {
  const navigate = useNavigate();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const handlePayoutCardClick = (payoutId, type: PaymentSystem) => {
    const payoutType = type || PaymentSystem.CA;
    navigate(`/payouts/balance/${currentTeam?.id}/${PayoutRootPage.BALANCE}/payout/${payoutId}?type=${payoutType}`, {
      state: FROM_SIDE_PANEL_ROUTE_STATE
    });
  };

  return (
    <div className="flex flex-col gap-5">
      <div className="w-full bg-secondaryBtn py-2.5 pl-[30px] text-sbase font-normal text-accentText">{headerText}</div>
      {payoutsList.map((payout, index) => (
        <div className="mx-[30px]" key={payout.id}>
          <PayoutCard payoutData={payout} onCardClick={handlePayoutCardClick} />
          {index < payoutsList.length - 1 && <Divider className="mt-[18px] bg-borderGray" />}
        </div>
      ))}
    </div>
  );
};

export default OnTheWayPayoutsList;
