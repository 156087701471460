import { MutableRefObject, useEffect, useRef, useState } from 'react';

const useIntersectionObserver = ({
  intersectionCallBack,
  threshold
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intersectionCallBack: MutableRefObject<any>;
  threshold: number;
}) => {
  const [intersectionElement, setIntersectionElement] = useState<HTMLElement>(null);

  const intersectionObserver = useRef(
    new IntersectionObserver(
      entries => {
        const first = entries[0];

        //if (first.isIntersecting && Math.floor(first.intersectionRatio) === 1) {
        if (first.isIntersecting) {
          intersectionCallBack.current();
        }
      },
      { threshold: threshold }
    )
  );

  useEffect(() => {
    const currentElement = intersectionElement;
    const currentObserver = intersectionObserver.current;

    // currentElement is null initially
    if (currentElement) {
      currentObserver.observe(currentElement);
    }

    return () => {
      if (currentElement) {
        currentObserver.unobserve(currentElement);
      }
    };
  }, [intersectionElement]);

  return { intersectionObserver, intersectionElement, setIntersectionElement };
};

export default useIntersectionObserver;
