import React, { FC } from 'react';

interface NotesSectionProps {
  notes: string;
}

const NotesSection: FC<NotesSectionProps> = ({ notes }) => (
  <div className="rounded-md border border-borderGray bg-white p-10">
    <div className="text-sbase font-semibold uppercase text-headingGray">Notes</div>
    <div className="mt-2.5 whitespace-pre-line break-words text-sbase font-normal text-primaryText">{notes}</div>
  </div>
);

export default NotesSection;
