import { Listbox, Transition } from '@headlessui/react';
import { DropDownChevronDownIcon, GreyStrokeInfoIcon } from 'assets/icons';
import { FC, Fragment, useState } from 'react';
import { FeatureToggleState, dropDownServiceFeeOption } from '../constants';
import { CustomPopover } from 'components';
import { PopoverArrowTypes } from 'constants/common';
import { FeatureToggleOption } from '../type';

interface ServiceFeeDropDownProps {
  selectedItem: FeatureToggleOption;
  setSelectedItem: (value: FeatureToggleOption) => void;
}

const ServiceFeeDropDown: FC<ServiceFeeDropDownProps> = ({ selectedItem, setSelectedItem }) => {
  const tooltipContent = {
    [FeatureToggleState.ALWAYS_ON]: 'Cannot be turned off while creating invoice',
    [FeatureToggleState.ON_BY_DEFAULT]: 'Can be turned on or off while creating invoice',
    [FeatureToggleState.OFF_BY_DEFAULT]: 'Can be turned on or off while creating invoice'
  };
  const [showToolTip, setShowToolTip] = useState<{ [x: string]: boolean }>({});

  const handleShowToolTip = (key: FeatureToggleState) => {
    setShowToolTip({ [key]: true });
  };

  const handleCloseToolTip = () => {
    setShowToolTip({});
  };

  const toolTipElement = (key: FeatureToggleState) => (
    <div onMouseEnter={() => handleShowToolTip(key)} onMouseLeave={handleCloseToolTip} className="cursor-pointer">
      <GreyStrokeInfoIcon className="shrink-0" />
    </div>
  );
  return (
    <div className="w-[180px] bg-primary focus:outline-none">
      <Listbox value={selectedItem} onChange={setSelectedItem}>
        <div className="relative mt-1">
          <Listbox.Button
            className="relative h-[50px] w-full cursor-pointer rounded-[4px] bg-secondaryBtn py-4 px-5 text-left
            text-[15px] text-headingGray"
            id="service-fee-dropdown">
            <span className="block truncate">{selectedItem.name}</span>
            <span className="pointer-events-none absolute inset-y-0 right-5 flex items-center pr-2">
              <DropDownChevronDownIcon className="h-[8px] w-[14px] text-gray-400" aria-hidden="true" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className="absolute z-[60] mt-0.5 h-fit w-full min-w-[180px] rounded-md border border-borderGray
                bg-primary text-sbase font-normal leading-7 tracking-[-0.3px] text-primaryText shadow-md sm:px-0">
              {dropDownServiceFeeOption.map(item => (
                <Listbox.Option
                  key={item.value}
                  className={({ active }) => `relative cursor-pointer select-none px-3 py-2 ${active ? '' : ''}`}
                  value={item}>
                  {({ selected }) => (
                    <div className="flex items-center justify-between gap-1">
                      <span className={`block truncate ${selected ? '' : ''}`}>{item.name}</span>
                      <CustomPopover
                        show={showToolTip[item.value]}
                        onTogglePopover={() => handleShowToolTip(item.value)}
                        onClosePopover={handleCloseToolTip}
                        anchorComponent={() => toolTipElement(item.value)}
                        highlightAnchor={false}
                        offset="left-1/2 transform -translate-x-1/2"
                        showArrow={true}
                        arrowType={PopoverArrowTypes.TOP}
                        arrowOffset="left-1/2 transform -translate-x-1/2"
                        margin="mt-3"
                        width="w-[295px]">
                        <div className="text-px13 font-normal text-primaryText">{tooltipContent[item.value]}</div>
                      </CustomPopover>
                    </div>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default ServiceFeeDropDown;
