import { Combobox, Transition } from '@headlessui/react';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { DropDownChevronDownIcon } from 'assets/icons';
import { twMerge } from 'tailwind-merge';
import classnames from 'classnames';

interface ListDropDownProps {
  className?: string;
  selectedItem: any;
  items: any;
  setSelectedItem: any;
  buttonStyles?: string;
  optionStyles?: string;
  wrapperStyles?: string;
  isLongList?: boolean;
  inputPlaceholder?: string;
  listKey?: string;
  optionsHeight?: string;
  emptyText?: string;
}

const ListDropDownWithSearch: FC<ListDropDownProps> = ({
  wrapperStyles,
  optionStyles,
  buttonStyles,
  selectedItem,
  setSelectedItem,
  items: itemsList,
  isLongList = false,
  inputPlaceholder = '',
  listKey = 'name',
  optionsHeight,
  emptyText = 'Nothing found.'
}) => {
  const [items, setItems] = useState(itemsList);
  const [query, setQuery] = useState('');

  useEffect(() => {
    const queryValuePattern = query?.toLowerCase().replace(/\s+/g, '');
    setItems(
      itemsList.filter(itr => {
        return itr?.[listKey]?.toLowerCase().replace(/\s+/g, '').includes(queryValuePattern);
      })
    );
  }, [query]);

  return (
    <div className={`w-full ${wrapperStyles}`}>
      <Combobox value={selectedItem} onChange={setSelectedItem}>
        <div className="relative mt-1">
          <div
            className="relative w-full cursor-default rounded-[4px] bg-white
               text-left focus:outline-none
            focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white
              focus-visible:ring-offset-2 ">
            <Combobox.Button className="relative w-full">
              <Combobox.Input
                className={twMerge(
                  classnames(
                    `h-[50px] w-full rounded bg-secondaryBtn
            p-4 text-17px font-semibold text-primaryText  outline-none placeholder:text-17px
            placeholder:font-normal placeholder:text-accentText`
                  )
                )} // @ts-ignore
                displayValue={person => person?.[listKey] || query}
                onChange={event => setQuery(event.target.value)}
                placeholder={inputPlaceholder}
              />
              <DropDownChevronDownIcon className="absolute right-5 top-[22px]" />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}>
            <Combobox.Options
              className={twMerge(
                `
                absolute z-[60] mt-[2px] h-fit w-full min-w-[200px] rounded-md border
                border-borderGray bg-primary px-4 shadow-md sm:px-0
              ${optionsHeight !== '' && `${optionsHeight} overflow-auto customNormalScroll`}
              `
              )}>
              {items.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none py-2 px-4 text-gray-700">{emptyText}</div>
              ) : (
                items.map(person => (
                  <Combobox.Option
                    key={person.id}
                    className={({ active }) =>
                      `text-primaryText font-semibold text-sbase relative cursor-pointer select-none pl-5 py-2 pr-4
                     ${active ? '' : ''}`
                    }
                    value={person}>
                    {({ selected: isSelected_, active }) => (
                      <>
                        <span
                          className={`block truncate text-17px 
                        font-semibold ${selectedItem ? '' : ''}`}>
                          {person?.[listKey]}
                        </span>
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  );
};

export default ListDropDownWithSearch;
