import { InfoIcon, OrangeInfoIcon, RedInfoIcon } from 'assets/icons';
import {
  DISPUTE_RECOMMENDED_DOCUMENT_NAME,
  DISPUTE_RECOMMENDED_DOCUMENT_TYPE,
  DISPUTE_STATUS,
  EVIDENCE_DOCUMENT_TYPE
} from './constants';
import { openInNewTab } from 'utils/commonUtils';
import { HOW_TO_RESPOND_TO_DISPUTE_LINK } from 'config/index';
import DateUtils from 'utils/dateUtils';

export const getDisputeStatusColor = (status: string) => {
  switch (status) {
    case DISPUTE_STATUS.IN_REVIEW:
    case DISPUTE_STATUS.EVIDENCE_SUBMITTED:
      return '#3876BB';
    case DISPUTE_STATUS.LOST:
    case DISPUTE_STATUS.NEEDS_RESPONSE:
      return '#D95945';
    case DISPUTE_STATUS.WON:
      return '#419364';
    case DISPUTE_STATUS.PAST_DUE:
      return '#3E556D';
    case DISPUTE_STATUS.NEEDS_ATTENTION:
      return '#F2994A';
    default:
      return '#3876BB';
  }
};

export const getDisputeStatusStyle = (status: string) => {
  switch (status) {
    case DISPUTE_STATUS.IN_REVIEW:
      return { backgroundColor: '#A4D7FA', color: '#333333' };
    case DISPUTE_STATUS.LOST:
      return { backgroundColor: '#F7C0C0', color: '#4F4F4F' };
    case DISPUTE_STATUS.NEEDS_RESPONSE:
      return { backgroundColor: '#F7E2C0', color: '#9D2D00' };
    case DISPUTE_STATUS.NEEDS_ATTENTION:
      return { backgroundColor: '#F7E2C0', color: '#333333' };
    case DISPUTE_STATUS.PAST_DUE:
      return { backgroundColor: '#F7E2C0', color: '#3E556D' };
    case DISPUTE_STATUS.WON:
      return { backgroundColor: '#CBF4C9', color: '#4F4F4F' };
    default:
      return { backgroundColor: '#A4D7FA', color: '#333333' };
  }
};

export const getDisputeTimelineColor = (status: string) => {
  switch (status) {
    case DISPUTE_STATUS.LOST:
      return { backgroundColor: '#FF7A7A' };
    case DISPUTE_STATUS.WON:
      return { backgroundColor: '#27AE60' };
    default:
      return { backgroundColor: '#F2994A' };
  }
};

export const getDisputeStatusInfoIcon = (status: string) => {
  switch (status) {
    case DISPUTE_STATUS.LOST:
    case DISPUTE_STATUS.NEEDS_RESPONSE:
    case DISPUTE_STATUS.NEEDS_ATTENTION:
      return RedInfoIcon;
    default:
      return InfoIcon;
  }
};

export const getDisputeStatusInfoIconForTable = (status: string) => {
  switch (status) {
    case DISPUTE_STATUS.NEEDS_ATTENTION:
    case DISPUTE_STATUS.NEEDS_RESPONSE:
      return OrangeInfoIcon;
    default:
      return InfoIcon;
  }
};

export const getRecommendedDocName = (type: DISPUTE_RECOMMENDED_DOCUMENT_TYPE | EVIDENCE_DOCUMENT_TYPE | string) => {
  switch (type) {
    case DISPUTE_RECOMMENDED_DOCUMENT_TYPE.CUSTOMER_COMMUNICATION:
      return DISPUTE_RECOMMENDED_DOCUMENT_NAME.CUSTOMER_COMMUNICATION;

    case DISPUTE_RECOMMENDED_DOCUMENT_TYPE.CUSTOMER_SIGNATURE:
      return DISPUTE_RECOMMENDED_DOCUMENT_NAME.CUSTOMER_SIGNATURE;

    case DISPUTE_RECOMMENDED_DOCUMENT_TYPE.SERVICE_DOCUMENTATION:
      return DISPUTE_RECOMMENDED_DOCUMENT_NAME.SERVICE_DOCUMENTATION;

    case DISPUTE_RECOMMENDED_DOCUMENT_TYPE.INVOICE:
      return DISPUTE_RECOMMENDED_DOCUMENT_NAME.INVOICE;

    case DISPUTE_RECOMMENDED_DOCUMENT_TYPE.OTHER:
      return DISPUTE_RECOMMENDED_DOCUMENT_NAME.OTHER;

    case DISPUTE_RECOMMENDED_DOCUMENT_TYPE.RECEIPT:
      return DISPUTE_RECOMMENDED_DOCUMENT_NAME.RECEIPT;

    default:
      return DISPUTE_RECOMMENDED_DOCUMENT_NAME.OTHER;
  }
};

export const onHowToRespondToDisputeClick = () => {
  openInNewTab(HOW_TO_RESPOND_TO_DISPUTE_LINK);
};

export const getDaysToRespond = respondBy => {
  const days = Math.abs(DateUtils.getDifferenceInDays({ primaryDate: new Date(respondBy), secondaryDate: new Date() }));
  if (days === 0) {
    return 'Last day to respond';
  }
  return `${days} day${days === 1 ? '' : 's'} to respond`;
};

export const getStatusTooltip = respondBy => {
  const days = Math.abs(DateUtils.getDifferenceInDays({ primaryDate: new Date(respondBy), secondaryDate: new Date() }));
  if (days === 0) {
    return `Today is the last day to respond to this dispute.
    If you don't respond, your customer's payment provider will 
    automatically resolve this dispute in favor of the customer.`;
  }
  return `You have ${days} day${days === 1 ? '' : 's'} to respond to respond to this dispute. 
  If you don't respond, your customer's payment provider will 
  automatically resolve this dispute in favor of the customer.`;
};
