import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { PayoutTxnsPageState } from './payouts.model';
import { PAYOUT_TXNS_TABS, PAYOUT_TXNS_TYPE_FILTERS } from './constants';

interface PayoutsSliceState {
  payoutTxnsFilters: PayoutTxnsPageState;
}

export const initialPayoutTxnsPageState = {
  amountFilter: { title: '', min: '', max: '' },
  search: '',
  typeFilter: [PAYOUT_TXNS_TYPE_FILTERS.COLLECTED, PAYOUT_TXNS_TYPE_FILTERS.REFUNDS],
  tab: PAYOUT_TXNS_TABS.PAYMENTS
};

const initialState: PayoutsSliceState = {
  payoutTxnsFilters: initialPayoutTxnsPageState
};

export const payoutsSlice = createSlice({
  name: 'payouts',
  initialState,
  reducers: {
    resetPayoutState: () => initialState,
    setPayoutTransactionFilters: (state, { payload }: PayloadAction<PayoutTxnsPageState>) => {
      state.payoutTxnsFilters = { ...payload };
    }
  }
});

export const { setPayoutTransactionFilters, resetPayoutState } = payoutsSlice.actions;

export const selectPayoutTxnsFilters = (state: RootState) => state.rootReducer.payouts.payoutTxnsFilters;

export default payoutsSlice.reducer;
