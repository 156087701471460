import { IInvoiceServiceCharge } from 'containers/invoices/invoices.model';
import { FC, useEffect, useState } from 'react';
import { initServiceCharge } from '../constants';
import AddServiceChargeSection from './AddServiceChargeSection';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  removeSelectedServiceCharge,
  resetInvoiceDraftData,
  selectInvoiceDraftDataInInvoiceSlice,
  selectIsBackFromCreateTaxFromInvoiceSlice,
  updateIsBackFromCreateTaxInInvoiceSlice,
  updateSelectedServiceCharge
} from 'containers/invoices/InvoicesSlice';
import AddFromServiceChargeListModal from './AddFromServiceChargeListModal';
import { IServiceCharge } from 'containers/product-catalog/service-charges/serviceCharge.model';
import AddServiceChargeModal, {
  IAddServiceChargeModalConfig,
  addServiceChargeModalConfigInitState,
  serviceChargeModalDataInit
} from './AddServiceChargeModal';
import { EInvoiceCatalogElementType, EServiceChargeModalType, IInvoiceSaveServiceCharge } from '../types';
import {
  removeSelectedServiceChargeFromEditSlice,
  resetInvoiceDraftDataInEditSlice,
  selectInvoiceDraftDataFromInvoiceEditSlice,
  selectIsBackFromCreateTaxInEditSlice,
  updateIsBackFromCreateTaxInEditSlice,
  updateSelectedServiceChargeInEditSlice
} from 'containers/invoices/edit-invoice/invoicesEditSlice';
import { useLazyGetServiceChargesQuery } from 'containers/product-catalog/service-charges/api';
import { selectCurrentTeam, selectCurrentTeamFeePermissions, showNotifier } from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';

interface AddServiceChargeWrapperProps {
  className?: string;
  isAddServiceChargeDisabled?: boolean;
  selectedServiceCharge?: IInvoiceServiceCharge;
  isEditInvoiceFlow?: boolean;
}

const AddServiceChargeWrapper: FC<AddServiceChargeWrapperProps> = ({
  className = '',
  isAddServiceChargeDisabled = false,
  selectedServiceCharge = initServiceCharge,
  isEditInvoiceFlow = false
}) => {
  const dispatch = useAppDispatch();
  const isBackFromCreateTaxRoute = useAppSelector(
    isEditInvoiceFlow ? selectIsBackFromCreateTaxInEditSlice : selectIsBackFromCreateTaxFromInvoiceSlice
  );
  const selectedInvoiceDraftData = useAppSelector(
    isEditInvoiceFlow ? selectInvoiceDraftDataFromInvoiceEditSlice : selectInvoiceDraftDataInInvoiceSlice
  );
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const [showAddFromServiceChargeListModal, setShowAddFromServiceChargeListModal] = useState<boolean>(false);

  const [addServiceChargeModalConfig, setAddServiceChargeModalConfig] = useState<IAddServiceChargeModalConfig>(
    addServiceChargeModalConfigInitState
  );

  const [getServiceChargeQuery, serviceChargeState] = useLazyGetServiceChargesQuery();

  const showDraftModal = () => {
    const { modalConfig } = selectedInvoiceDraftData ?? {};
    const serviceChargeModalConfig: IAddServiceChargeModalConfig = {
      title: modalConfig?.title,
      open: true,
      shouldInitializeFields: true,
      enableDeleteFromInvoice: modalConfig?.enableDeleteFromInvoice ?? false,
      enableSaveToCatalog: modalConfig?.enableSaveToCatalog ?? false,
      saveToCatalog: modalConfig.saveToCatalog ?? false,
      data: modalConfig?.data,
      editMode: modalConfig?.editMode ?? false,
      draftFlow: modalConfig?.draftFlow,
      modalType: modalConfig?.modalType
    };
    setAddServiceChargeModalConfig(serviceChargeModalConfig);
    if (isBackFromCreateTaxRoute) {
      if (isEditInvoiceFlow) {
        dispatch(resetInvoiceDraftDataInEditSlice());
        dispatch(updateIsBackFromCreateTaxInEditSlice(false));
      } else {
        dispatch(resetInvoiceDraftData());
        dispatch(updateIsBackFromCreateTaxInInvoiceSlice(false));
      }
    }
  };

  useEffect(() => {
    if (isBackFromCreateTaxRoute) {
      if (
        selectedInvoiceDraftData &&
        selectedInvoiceDraftData.invoiceCatalogElementType === EInvoiceCatalogElementType.SERVICE_CHARGE
      ) {
        showDraftModal();
      }
    }
  }, []);

  useEffect(() => {
    if (!serviceChargeState.isFetching) {
      if (serviceChargeState.isSuccess) {
        if (serviceChargeState?.data?.data?.length > 0) {
          setShowAddFromServiceChargeListModal(true);
        } else {
          handleCreateOneTimeServiceCharge();
        }
      } else if (serviceChargeState.isError) {
        dispatch(
          showNotifier({
            duration: 1000,
            type: NotifierTypes.ERROR,
            message: {
              primaryMessage: 'Something went wrong, please try again after some time',
              isMessageHtml: false
            }
          })
        );
      }
    }
  }, [serviceChargeState.isFetching, serviceChargeState.isSuccess, serviceChargeState.isError]);

  const onAddServiceCharge = (): void => {
    getServiceChargeQuery({ limit: 1, page: 0, search: null, teamId: currentTeam?.id });
  };

  const onEditServiceCharge = (): void => {
    const config: IAddServiceChargeModalConfig = {
      title: 'Edit service charge',
      editMode: true,
      open: true,
      shouldInitializeFields: true,
      enableDeleteFromInvoice: true,
      enableSaveToCatalog: false,
      data: {
        amount: selectedServiceCharge.amount,
        id: selectedServiceCharge.id,
        name: selectedServiceCharge.name,
        percentage: selectedServiceCharge.percentage,
        taxes: selectedServiceCharge.taxes
      },
      modalType: EServiceChargeModalType.EDIT_SERVICE_CHARGE_MODAL
    };

    setAddServiceChargeModalConfig(config);
  };

  const onRemoveServiceCharge = (): void => {
    if (isEditInvoiceFlow) {
      dispatch(removeSelectedServiceChargeFromEditSlice());
    } else {
      dispatch(removeSelectedServiceCharge());
    }
  };

  const onCloseAddFromServiceChargeListModal = (): void => {
    setShowAddFromServiceChargeListModal(false);
  };

  const onServiceChargeItemClick = (serviceCharge: IServiceCharge) => {
    setShowAddFromServiceChargeListModal(false);
    const config: IAddServiceChargeModalConfig = {
      title: 'Add service charge',
      editMode: false,
      open: true,
      shouldInitializeFields: true,
      enableDeleteFromInvoice: false,
      enableSaveToCatalog: false,
      data: {
        amount: serviceCharge.amount,
        id: serviceCharge.id,
        name: serviceCharge.name,
        percentage: serviceCharge.percentage,
        taxes: serviceCharge.taxes
      },
      modalType: EServiceChargeModalType.CATALOG_SERVICE_CHARGE_MODAL
    };
    setAddServiceChargeModalConfig(config);
  };

  const onCloseAddServiceChargeModal = (): void => {
    setAddServiceChargeModalConfig(addServiceChargeModalConfigInitState);
  };

  const onSaveServiceChargeClick = (serviceChargeData: IInvoiceSaveServiceCharge): void => {
    if (isEditInvoiceFlow) {
      dispatch(updateSelectedServiceChargeInEditSlice(serviceChargeData.invoiceServiceCharge));
    } else {
      dispatch(updateSelectedServiceCharge(serviceChargeData.invoiceServiceCharge));
    }
    onCloseAddServiceChargeModal();
  };

  const onModifyServiceChargeClick = (serviceCharge: IInvoiceServiceCharge): void => {
    if (isEditInvoiceFlow) {
      dispatch(updateSelectedServiceChargeInEditSlice(serviceCharge));
    } else {
      dispatch(updateSelectedServiceCharge(serviceCharge));
    }

    onCloseAddServiceChargeModal();
  };

  const handleCreateOneTimeServiceCharge = (): void => {
    setShowAddFromServiceChargeListModal(false);
    const config: IAddServiceChargeModalConfig = {
      title: 'Create one-time service charge',
      editMode: false,
      open: true,
      shouldInitializeFields: false,
      enableDeleteFromInvoice: false,
      enableSaveToCatalog: true,
      modalType: EServiceChargeModalType.ONE_TIME_ADD_SERVICE_CHARGE_MODAL,
      data: serviceChargeModalDataInit
    };
    setAddServiceChargeModalConfig(config);
  };

  const onRemoveServiceChargeFromInvoice = (): void => {
    onCloseAddServiceChargeModal();
    onRemoveServiceCharge();
  };

  return (
    <div className={`${className}`}>
      <AddServiceChargeSection
        onAddServiceCharge={onAddServiceCharge}
        onEditServiceCharge={onEditServiceCharge}
        onRemoveServiceCharge={onRemoveServiceCharge}
        selectedServiceCharge={selectedServiceCharge}
        isAddServiceChargeDisabled={isAddServiceChargeDisabled}
      />
      {showAddFromServiceChargeListModal && (
        <AddFromServiceChargeListModal
          onServiceChargeClick={onServiceChargeItemClick}
          onClose={onCloseAddFromServiceChargeListModal}
          open={showAddFromServiceChargeListModal}
          onCreateOneTimeServiceCharge={handleCreateOneTimeServiceCharge}
        />
      )}
      {addServiceChargeModalConfig.open && (
        <AddServiceChargeModal
          isEditInvoiceFlow={isEditInvoiceFlow}
          onSaveServiceChargeClick={onSaveServiceChargeClick}
          onModifyServiceChargeClick={onModifyServiceChargeClick}
          onClose={onCloseAddServiceChargeModal}
          config={addServiceChargeModalConfig}
          onDeleteFromInvoice={onRemoveServiceChargeFromInvoice}
        />
      )}
    </div>
  );
};

export default AddServiceChargeWrapper;
