import { FC, ReactNode, useEffect, useRef } from 'react';
import { Transition } from '@headlessui/react';

import { VoidFn } from 'types/baseTypes';
import { useAppDispatch, useScreenDimensions } from 'hooks';
import { toggleChatIcon } from 'containers/app/appSlice';

interface SidePanelProps {
  children: ReactNode;
  isOpen: boolean;
  onClose: VoidFn;
  shouldAnimate?: boolean;
}

const SidePanel: FC<SidePanelProps> = props => {
  const { children, isOpen, onClose, shouldAnimate = false } = props;
  const sidePanelRef = useRef<HTMLDivElement>();
  const modalElement = document.getElementById('portal-root');
  const dispatch = useAppDispatch();

  const handleOutsideClick = event => {
    const notifierElement = document.getElementById('notifier-toast');

    if (
      sidePanelRef &&
      !sidePanelRef?.current?.contains(event.target) &&
      !modalElement?.contains(event.target) &&
      !notifierElement?.contains(event.target)
    ) {
      onClose();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleOutsideClick, true);
    return () => {
      document.removeEventListener('click', handleOutsideClick, true);
    };
  }, []);

  useEffect(() => {
    if (isOpen) dispatch(toggleChatIcon(false));
    return () => {
      dispatch(toggleChatIcon(true));
    };
  }, []);

  const { viewportHeightInPx } = useScreenDimensions();

  return (
    <Transition
      appear={shouldAnimate}
      show={isOpen}
      enter="transition-transform duration-500"
      enterFrom="translate-x-full"
      enterTo="translate-x-0"
      leave="transition-transform duration-500"
      leaveFrom="translate-x-0"
      leaveTo="translate-x-full"
      as="div"
      ref={sidePanelRef}
      className={`fixed top-0 right-0 z-50
       bg-primary shadow-md`}
      style={{ height: viewportHeightInPx }}>
      {children}
    </Transition>
  );
};

export default SidePanel;
