/* eslint-disable react/no-multi-comp */
import classnames from 'classnames';
import { InvoiceTabEnum } from 'containers/invoices/create-invoice/constants';
import { FC, useContext } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

interface InvoiceSectionTabProps {
  title: string;
  isSelected: boolean;
  className?: string;
  onClick: VoidFn;
  id: string;
}

const InvoiceSectionTab: FC<InvoiceSectionTabProps> = ({ title, isSelected, className, onClick, id }) => {
  return (
    <div
      id={id}
      onClick={onClick}
      className={twMerge(
        'cursor-pointer rounded h-50 py-4 pl-6 font-semibold text-[#3E556D]',
        classnames({ 'text-secondary ring-1 ring-secondary': isSelected })
      )}>
      <div className="text-17px">{title}</div>
    </div>
  );
};

interface InvoicesTabSideNavProps {
  context: React.Context<any>;
  className?: string;
}

const InvoicesTabSideNav: FC<InvoicesTabSideNavProps> = ({ className, context }) => {
  const { selectedInvoiceTab, setSelectedInvoiceTab } = useContext<any>(context);

  const isselectedInvoiceTab = (tab: InvoiceTabEnum) => {
    return (
      tab === selectedInvoiceTab ||
      (tab === InvoiceTabEnum.LINE_ITEMS && selectedInvoiceTab === InvoiceTabEnum.LINE_ITEMS_INPUT)
    );
  };

  return (
    <div className="flex w-64 flex-col 1439px:space-y-5">
      <InvoiceSectionTab
        id="edit-invoice-side-nav-bill-to"
        title={InvoiceTabEnum.BILL_TO}
        isSelected={isselectedInvoiceTab(InvoiceTabEnum.BILL_TO)}
        onClick={() => setSelectedInvoiceTab(InvoiceTabEnum.BILL_TO)}
      />
      <InvoiceSectionTab
        id="edit-invoice-side-nav-invoice-options"
        title={InvoiceTabEnum.INVOICE_OPTIONS}
        isSelected={isselectedInvoiceTab(InvoiceTabEnum.INVOICE_OPTIONS)}
        onClick={() => setSelectedInvoiceTab(InvoiceTabEnum.INVOICE_OPTIONS)}
      />
      <InvoiceSectionTab
        id="edit-invoice-side-nav-line-items"
        title={InvoiceTabEnum.LINE_ITEMS}
        isSelected={isselectedInvoiceTab(InvoiceTabEnum.LINE_ITEMS)}
        onClick={() => setSelectedInvoiceTab(InvoiceTabEnum.LINE_ITEMS)}
      />
      <InvoiceSectionTab
        id="edit-invoice-side-nav-notes-and-attachments"
        title={InvoiceTabEnum.NOTES_ATTACHMENTS}
        isSelected={isselectedInvoiceTab(InvoiceTabEnum.NOTES_ATTACHMENTS)}
        onClick={() => setSelectedInvoiceTab(InvoiceTabEnum.NOTES_ATTACHMENTS)}
      />
      <InvoiceSectionTab
        id="edit-invoice-side-nav-payment-options"
        title={InvoiceTabEnum.PAYMENT_OPTIONS}
        isSelected={isselectedInvoiceTab(InvoiceTabEnum.PAYMENT_OPTIONS)}
        onClick={() => setSelectedInvoiceTab(InvoiceTabEnum.PAYMENT_OPTIONS)}
      />
    </div>
  );
};

export default InvoicesTabSideNav;
