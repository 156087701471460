import { FC } from 'react';
import { CustomSwitch } from 'components';

interface PermissionListItemProp {
  className?: string;
  enabled: boolean;
  title: string;
  onTogglePemission: () => void;
}

const PermissionListItem: FC<PermissionListItemProp> = ({ onTogglePemission, className, enabled, title }) => {
  return (
    <div
      className="flex min-h-[64px] flex-row items-center justify-between gap-2 border-t border-secondaryBtn
      py-5 pl-4 pr-[30px]">
      <div className="text-sbase font-normal text-primaryText">{title}</div>
      <CustomSwitch enabled={enabled} toggleSwitch={onTogglePemission} id={`permission-${title}-switch`} />
    </div>
  );
};

export default PermissionListItem;
