export enum BASE_URL {
  DEFAULT_APP = 'scanpay://scanpay.tech'
}

export enum REDIRECTION_PAGES {
  DISPUTE = 'dispute',
  RECEIPT = 'receipt',
  PAYOUT = 'payout',
  ADDITIONAL_DATA = 'additional_data'
}

export enum RedirectionSearchParams {
  PAGE = 'page',
  ID = 'id',
  TEAM_ID = 'teamId',
  PAYMENT_SYSTEM = 'paymentSystem'
}
