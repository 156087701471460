export enum ItemActionType {
  EDIT = 'Edit',
  DUPLICATE = 'Duplicate',
  DELETE = 'Delete'
}

export enum ItemFieldNames {
  itemName = 'itemName',
  itemCategory = 'itemCategory',
  unitPrice = 'unitPrice',
  itemTaxes = 'itemTaxes',
  itemDescription = 'itemDescription',
  itemSku = 'itemSku'
}

export enum ItemInputErrors {
  Item_Name_Error = 'Please enter a valid name',
  Unit_Price_Error = 'Please enter a valid price'
}
