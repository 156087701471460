import { FC, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { ArrowRightIcon, CaretDownIcon, EditIcon, NavCloseIcon, SendIcon, WhiteInfoIcon } from 'assets/icons';
import { InvoiceByIdResponse, InvoiceStatus } from 'containers/invoices/invoices.model';
import DateUtils from 'utils/dateUtils';
import { LineItems, MoreMenu } from 'containers/invoices/components';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { CustomPopover, If } from 'components';
import { getInitials } from 'utils/commonUtils';
import { toggleIsFromPreview } from 'containers/invoices/edit-invoice/invoicesEditSlice';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamCustomersPermissions,
  showNotifier
} from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useNavigate, useParams } from 'react-router-dom';
import { PopoverArrowTypes } from 'constants/common';
import { twMerge } from 'tailwind-merge';
import { useLazyDownloadInvoiceAsPDFQuery, useLazyDownloadPrintInvoiceAsPDFQuery } from 'containers/invoices/api';
import { NotifierTypes } from 'constants/notifierConstants';
import { InfoMesssages } from 'types/infoMessages';
import { FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import { calculateFontSize } from 'utils/styleUtils';

interface InvoiceDetailsProps {
  invoiceData: InvoiceByIdResponse;
  onClose: (navigateBack: boolean) => void;
  onAttachmentsClick: VoidFn;
  showBackArrow?: boolean;
}

const InvoiceDetails: FC<InvoiceDetailsProps> = props => {
  const currentTeam = useAppSelector(selectCurrentTeam);

  const {
    rootInvoiceId,
    invoiceId: invoiceIdFromUrl,
    rootDisputeId,
    rootReceiptId,
    rootCustomerId,
    rootPage
  } = useParams();

  const { invoiceData, onClose, onAttachmentsClick, showBackArrow = false } = props;
  const { customer, isModifiable } = invoiceData ?? {};
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isMoreOpen, setIsMoreOpen] = useState(false);
  const [downloadInvoiceAsPDF] = useLazyDownloadInvoiceAsPDFQuery();
  const [downloadPrintInvoiceAsPDF] = useLazyDownloadPrintInvoiceAsPDFQuery();
  const notifications = useAppSelector(getNotifications);

  const isShareEnabled = () => {
    return (
      customerPermissions.viewCustomersCreatedByTeam ||
      customerPermissions.editCustomersCreatedByTeam ||
      customerPermissions.editExistingCustomers
    );
  };

  const toggleIsMoreOpen = () => {
    setIsMoreOpen(!isMoreOpen);
  };

  const closeMoreOption = () => {
    setIsMoreOpen(false);
  };

  const moreAnchorElem = () => (
    <div
      id="invoice-bottom-bar-more-button"
      className="flex shrink grow cursor-pointer items-center justify-center gap-2 bg-secondary
    py-3.5 text-sbase font-semibold text-primary hover:opacity-95">
      <div>More</div>
      <div className="path-stroke-current path">
        <CaretDownIcon className="rotate-180" />
      </div>
    </div>
  );

  const onExportAsPdf = () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    downloadInvoiceAsPDF({ invoiceId: invoiceData.invoiceId, teamId: currentTeam.id });
  };

  const onPrintPdf = () => {
    downloadPrintInvoiceAsPDF({ invoiceId: invoiceData.invoiceId, teamId: currentTeam.id });
  };

  const handleInvoiceShare = () => {
    if (invoiceData.invoiceStatus === InvoiceStatus.PENDING) {
      if (rootInvoiceId) {
        if (invoiceIdFromUrl) {
          navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice/${invoiceData?.invoiceId}`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        } else {
          navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice`, {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          });
        }
      } else if (rootPage) {
        navigate(
          `/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-pending-invoice/${invoiceData?.invoiceId}`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      } else if (rootReceiptId) {
        navigate(
          `/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-pending-invoice/${invoiceData?.invoiceId}`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-pending-invoice/${invoiceData?.invoiceId}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else if (rootDisputeId) {
        navigate(
          `/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-pending-invoice/${invoiceData?.invoiceId}`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      }
    } else if (rootInvoiceId) {
      if (invoiceIdFromUrl) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-invoice/${invoiceIdFromUrl}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-invoice`, { state: FROM_SIDE_PANEL_ROUTE_STATE });
      }
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-invoice/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-invoice/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const bottomBar = invoiceData?.isPaid
    ? [
        {
          id: 'invoice-bottom-bar-share-button',
          icon: (
            <div>
              <SendIcon className="h-5" />
            </div>
          ),
          btnText: 'Share Invoice',
          isDisabled: !isShareEnabled(),
          disabledReason: InfoMesssages.shareReceiptNoPermissionMessage,
          onClickHandler: () => {
            handleInvoiceShare();
          }
        }
      ]
    : [
        {
          id: 'invoice-bottom-bar-reshare-button',
          isDisabled: !isShareEnabled(),
          btnText: 'Share Invoice',
          disabledReason: InfoMesssages.shareInvoiceNoPermissionMessage,
          onClickHandler: () => {
            handleInvoiceShare();
          }
        },
        {
          id: 'invoice-bottom-bar-edit-button',
          icon: (
            <div>
              <EditIcon className="h-5" />
            </div>
          ),
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          btnText: 'Edit',
          disabledReason: InfoMesssages.editInvoiceNoPermissionMessage,
          isDisabled: !isModifiable,
          onClickHandler: () => {
            dispatch(toggleIsFromPreview(false));
            navigate(`/invoices/edit/${invoiceData.invoiceId}`);
          }
        }
      ];

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handleClose = () => {
    onClose(showBackArrow);
  };

  return (
    <div className="flex h-full w-full flex-col">
      <div className="flex w-full items-center gap-2 py-5 px-4">
        <div className="cursor-pointer" onClick={handleClose}>
          {showBackArrow ? <ArrowRightIcon className="rotate-180" /> : <NavCloseIcon />}
        </div>
        <div className="truncate text-17px font-semibold leading-5 text-headingGray">Invoice details</div>
      </div>
      <div className="customNormalScroll overflow-y-auto px-4">
        <div className="mb-5 rounded-md border border-dark-gray">
          <div
            className="flex items-center justify-between border-b border-borderGray
            py-4 px-3 text-sbase text-headingGray">
            <div>Invoice Due Date</div>
            <div>{DateUtils.getDateInFormat({ date: new Date(invoiceData.dueDate), dateFormat: 'dd MMM yyyy' })}</div>
          </div>
          <div className="mx-3 border-b border-borderGray py-6">
            <div className="m-auto h-16 w-16 rounded">
              {invoiceData.teamIcon ? (
                <img
                  src={invoiceData.teamIcon}
                  alt=""
                  className="h-16 w-16 rounded border border-borderGray object-contain"
                />
              ) : (
                <div
                  className="flex h-16 w-16 items-center justify-center
                  rounded border border-borderGray bg-gradient-to-r from-[#D8DFE5] to-[#A4D7FA]
                  font-bold text-tertiaryText">
                  {getInitials(invoiceData.merchantName)}
                </div>
              )}
            </div>
            <div className="truncate pt-2 text-center text-sbase font-semibold text-tertiaryText">
              {invoiceData.merchantName}
            </div>
          </div>
          <div className="mx-3 border-b border-borderGray py-5 text-px13 text-primaryText">
            <div className="mb-3 text-sbase font-semibold">Billed to</div>
            <div className="truncate font-semibold">{`${customer.name} ${customer.lastName}`}</div>
            <If condition={Boolean(customer.billingAddress)}>
              <div className="mb-4 truncate">Billing adress: {customer.billingAddress || ' -'}</div>
            </If>
            <If condition={Boolean(customer.email)}>
              <div className="truncate">Email: {customer.email || ' -'}</div>
            </If>
            <If condition={Boolean(customer.phoneNumber)}>
              <div className="truncate">Phone number: {customer.phoneNumber || ' -'}</div>
            </If>
          </div>
          <div className="mx-3 border-b border-borderGray py-5">
            <div className="mb-2.5 flex items-center justify-between text-px13 font-semibold">
              <div className="text-accentText">Invoice number</div>
              <div className="text-primaryText">{invoiceData.invoiceNumber}</div>
            </div>
            <div className="flex items-center justify-between text-px13 font-semibold">
              <div className="text-accentText">Date</div>
              <div className="text-primaryText">{invoiceData.createdAt}</div>
            </div>
          </div>
          <div>
            <LineItems invoiceData={invoiceData} marginX="mx-3" paddingX="px-3" showTotalAmount={false} />
            <div className="mb-4 flex flex-col items-center bg-tertiaryText py-4 text-primary">
              <div className="text-17px font-semibold">Total Amount</div>
              <div className="text-5xl font-bold">
                {getAmountWithCurrency(invoiceData.isPaid ? +invoiceData.amount : +invoiceData.payableAmount)}
              </div>
            </div>
          </div>
          <If condition={Boolean(invoiceData.comment)}>
            <div className="px-3 pb-4 text-headingGray">
              <div className="mb-2 text-px13 font-semibold">Notes</div>
              <div id="invoice-details-panel-notes" className="whitespace-pre-line break-words text-sbase">
                {invoiceData.comment}
              </div>
            </div>
          </If>
          <If condition={Boolean(invoiceData.comment && invoiceData.tnc.tnc)}>
            <div className="px-3 ">
              <div className="border-t border-borderGray "></div>
            </div>
          </If>

          <If condition={Boolean(invoiceData.tnc.tnc)}>
            <div className="px-3 pb-4">
              <div className="pt-3 pb-2 text-px13 font-semibold text-headingGray">Terms & Conditions</div>
              <div
                className="whitespace-pre-line break-words text-sbase 
              font-normal text-primaryText">
                {invoiceData.tnc.tnc}
              </div>
            </div>
          </If>

          {/* signature section */}
          <If condition={invoiceData.isSignatureEnabled}>
            <div className="px-3 ">
              <div className="border-t border-borderGray "></div>
            </div>
            <div className="px-3 pb-4 text-headingGray">
              <div className="pt-4 text-sbase font-semibold text-headingGray">Signatures</div>
              <If condition={invoiceData.isInvoiceSigned}>
                <div className="text-sbase text-primaryText">
                  {invoiceData.signaturePresignedUrl ? (
                    <img src={invoiceData.signaturePresignedUrl} alt="" className="w-15 mt-2 h-10 object-contain" />
                  ) : (
                    <div
                      className="my-5 text-xl"
                      style={{
                        fontFamily: 'Babylonica',
                        fontSize: calculateFontSize(invoiceData.signatureCustomerName.length, 40, 20)
                      }}>
                      {invoiceData.signatureCustomerName}
                    </div>
                  )}

                  <div className="mt-2.5">
                    {DateUtils.getDateInFormatwithTimezone({
                      date: new Date(invoiceData.signedAt),
                      dateFormat: 'dd MMM yyyy, h:mm a'
                    })}
                  </div>
                  <div className="mt-2 truncate">{invoiceData.signatureCustomerName}</div>
                </div>
              </If>

              <If condition={!invoiceData.isInvoiceSigned}>
                <div className="w-[142px] border-b border-borderGray py-6 text-center">
                  {invoiceData.isPaid && invoiceData.isManualPayment ? '-' : ''}
                </div>
                <div className="mt-2 text-px13 text-primaryText">Customer signature</div>
              </If>
            </div>
          </If>
        </div>
        <If condition={Boolean(invoiceData.attachments)}>
          <div className="my-2 rounded-md border border-dark-gray">
            <div
              className="flex cursor-pointer items-center justify-between border-b border-borderGray px-3 pb-2.5 pt-4"
              onClick={onAttachmentsClick}>
              <div className="text-sbase font-semibold text-headingGray">Attachments</div>
              <div className="cursor-pointer" onClick={onAttachmentsClick}>
                <CaretDownIcon className="-rotate-90" />
              </div>
            </div>
            <div>
              {invoiceData.attachments?.map(attachment => (
                <div
                  className="flex items-center border-b border-borderGray px-3 py-4 last:border-b-0"
                  key={attachment.title + attachment.url}>
                  <div className="h-16 w-16 shrink-0 rounded">
                    <img
                      src={attachment.urlLink}
                      alt=""
                      className="h-16 w-16 rounded border border-borderGray object-contain"
                    />
                  </div>
                  <div className="ml-3 max-w-[80%]">
                    <div id="invoice-details-attachments-title" className="truncate text-sbase text-headingGray">
                      {attachment.title}
                    </div>
                    <div
                      id="invoice-details-attachments-description"
                      className="whitespace-pre-line text-px13 text-accentText line-clamp-2">
                      {attachment.description}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </If>
      </div>
      <div className="mt-auto flex justify-between gap-px justify-self-end pl-0.5">
        {bottomBar.map(item => (
          <button
            onClick={() => {
              if (item.isDisabled) {
                onInfoClick(item.id, item.disabledReason);
              } else {
                item.onClickHandler();
              }
            }}
            key={item.id + item.btnText}
            className={twMerge(`flex shrink grow cursor-pointer items-center justify-center gap-2
                  bg-secondary py-3.5 font-semibold text-primary hover:opacity-95 
                  ${item.isDisabled ? 'bg-[#9BBADD] hover:opacity-100' : ''}`)}>
            <div className={`${item.isDisabled && 'opacity-50'}`}>{item.icon}</div>
            <div className={`text-sbase ${item.isDisabled && 'opacity-50'}`}>{item.btnText}</div>
            {item.isDisabled && <WhiteInfoIcon />}
          </button>
        ))}
        <div
          className="flex shrink grow cursor-pointer items-center justify-center
                gap-2 bg-secondary hover:opacity-95"
          onClick={toggleIsMoreOpen}>
          <CustomPopover
            show={isMoreOpen}
            anchorComponent={moreAnchorElem}
            onTogglePopover={toggleIsMoreOpen}
            onClosePopover={closeMoreOption}
            offset="-right-3 bottom-14"
            minWidth="min-w-[160px]"
            showArrow={true}
            arrowType={PopoverArrowTypes.BOTTOM}
            highlightAnchor={false}>
            {
              <MoreMenu
                onPrintPdf={onPrintPdf}
                onExportPdf={onExportAsPdf}
                type={invoiceData.isPaid ? 'paid' : 'unpaid'}
                isModifiable={invoiceData.isModifiable}
              />
            }
          </CustomPopover>
        </div>
      </div>
    </div>
  );
};

export default InvoiceDetails;
