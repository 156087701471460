import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import {
  CityObject,
  GetAddressByZipcodeAPIRequestData,
  GetAddressByZipcodeAPIResponseData,
  GetTeamProfileApiResponse,
  GetTeamProfileDataPayload,
  Professions,
  StateAPIResponse,
  UpdateTeamProfileRequestData
} from 'containers/team-profile/teamProfile.model';
import { ImageUploadResponse } from 'containers/invoices/invoices.model';
import { initializeTeamProfileSliceFromAPI, updateIsLoadedTeamProfile } from './teamProfileSlice';

const TeamProfileAPI = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTeamProfileDataData: builder.query<GetTeamProfileApiResponse, GetTeamProfileDataPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.V1}/team-settings/${teamId}?teamId=${teamId}`,
          method: 'GET'
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        dispatch(updateIsLoadedTeamProfile(false));
        const { data } = await queryFulfilled;
        dispatch(initializeTeamProfileSliceFromAPI(data));
      },

      extraOptions: {
        failure: 'failed to payment team profile data'
      }
    }),
    getProfessionsList: builder.query<Professions[], any>({
      query: () => {
        return {
          url: `${API_VERSION.V1}/merchant/professions`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to payment limit data'
      }
    }),
    uploadFileForTeamProfile: builder.mutation<ImageUploadResponse, { file: any; teamId: number }>({
      query: ({ teamId, file }) => {
        const formData = new FormData();
        formData.append('teamId', teamId.toString());
        formData.append('image', file);

        return {
          url: `${API_VERSION.V2}/teams/${teamId}/profile-picture`,
          method: 'POST',
          body: formData,
          headers: {
            // make sure not to set the Content-Type header.
            // The browser will set it for you, including the boundary parameter.
            // 'Content-Type': 'multipart/form-data'
          }
        };
      },
      extraOptions: {
        failure: 'We are unable to upload your image. Try again after sometime.',
        showToast: true
      }
    }),
    getStatesList: builder.query<StateAPIResponse, any>({
      query: () => {
        return {
          url: `${API_VERSION.V1}/merchants/states`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch states list'
      }
    }),
    getCitiesList: builder.query<CityObject[], any>({
      query: () => {
        return {
          url: `${API_VERSION.V1}/merchants/all-cities`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch cities list'
      }
    }),
    getAddressObjectByZipcode: builder.query<GetAddressByZipcodeAPIResponseData, GetAddressByZipcodeAPIRequestData>({
      query: ({ zipCode }) => {
        return {
          url: `${API_VERSION.V1}/merchants/address/${zipCode}`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch address list'
      }
    }),
    updateTeamProfileData: builder.mutation<GetTeamProfileApiResponse, UpdateTeamProfileRequestData>({
      query: ({ teamProfileData, teamId }) => {
        return {
          url: `${API_VERSION.V2}/team-profile/${teamId}`,
          method: 'POST',
          body: teamProfileData,
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to fetch profile data'
      }
    }),
    deleteTeamProfileImage: builder.mutation<any, GetTeamProfileDataPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.V1}/teams/${teamId}/profile-picture`,
          method: 'DELETE',
          body: {},
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to fetch profile data'
      }
    })
    // getCitiesList: builder.query<string[], GetCitiesApIPayload>({
    //   query: ({ stateCode }) => {
    //     return {
    //       url: `${API_VERSION.V1}/merchants/state/${stateCode}/cities`,
    //       method: 'GET'
    //     };
    //   },
    //   extraOptions: {
    //     failure: 'failed to fetch cities list'
    //   }
    // })
  })
});
export const {
  useLazyGetTeamProfileDataDataQuery,
  useLazyGetProfessionsListQuery,
  useUploadFileForTeamProfileMutation,
  useLazyGetCitiesListQuery,
  useLazyGetStatesListQuery,
  useLazyGetAddressObjectByZipcodeQuery,
  useUpdateTeamProfileDataMutation,
  useDeleteTeamProfileImageMutation
} = TeamProfileAPI;
