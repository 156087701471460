export enum APIErrorStatuses {
  STATUS_403 = 403,
  STATUS_400 = 400,
  STATUS_422 = 422,
  STATUS_429 = 429,
  STATUS_404 = 404,
  STATUS_401 = 401,
  STATUS_500 = 500
}

export enum APIErrorMsg {
  MERCHANT_BLOCKED = 'MERCHANT_BLOCKED',
  NO_ACTIVE_TEAMS = 'NO_ACTIVE_TEAMS_FOUND',
  INVALID_LOGIN = 'INVALID_LOGIN',
  NO_ACTIVE_USER_FOUND = 'NO_ACTIVE_USER_FOUND',
  LIMIT_EXCEEDED = 'Check your payment limits',
  PERMISSION_MISSING = 'PERMISSION_MISSING',
  NOT_FOUND = 'NOT_FOUND',
  WRONG_PASSWORD = 'WRONG_PASSWORD',
  ACCOUNT_TEMPORARILY_BLOCKED = 'ACCOUNT_TEMPORARILY_BLOCKED',
  INVALID_OTP = 'INVALID_OTP',
  EMAIL_CONFLICT = 'EMAIL_CONFLICT',
  SKU_CONFLICT = 'SKU_CONFLICT',
  ACCESS_FORBIDDEN = 'ACCESS_FORBIDDEN',
  ACCOUNT_EXISTS = 'Merchant with phone already exists',
  PHONE_NUMBER_NOT_MATCHING = 'Invited phone number is not matching.',
  USER_NOT_ALLOWED_TO_ACCESS = 'User is not allowed to access this resource'
}
