import { store } from 'store/store';

const { rootReducer } = store.getState();

const { app } = rootReducer;
const { brbClosureDate, faqMigrationMonthAndYear, faqMigrationSafeDate } = app.userDetails ?? {};

export const getBrbClosureDate = () => {
  return brbClosureDate || 'May 3rd, 2024';
};

export const getFaqMigrationSafeDate = () => {
  return faqMigrationSafeDate || 'April 30th, 2024';
};

export const getFaqMigrationMonthAndYear = () => {
  return faqMigrationMonthAndYear || 'May 2024';
};
