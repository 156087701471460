import { useCallback, useEffect, useRef, useState } from 'react';
import { IServiceCharge } from '../serviceCharge.model';
import { useLazyGetServiceChargesQuery } from '../api';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks/typedHooks';

export interface ServiceChargesQueryParams {
  search: string;
}

export const initialServiceChargeQueryParamsState: ServiceChargesQueryParams = {
  search: null
};

export const usePaginatedGetServiceChargeQuery = () => {
  const [list, setList] = useState<IServiceCharge[]>([]);
  const [queryParams, setQueryParams] = useState<ServiceChargesQueryParams>(initialServiceChargeQueryParamsState);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const page = useRef(0);
  const [getServiceChargeQuery, serviceChargeState] = useLazyGetServiceChargesQuery();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [isMounted, setIsMounted] = useState(false);

  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!serviceChargeState?.isFetching && serviceChargeState.isSuccess) {
      const totalCount = serviceChargeState?.data?.meta?.totalCount;
      const serviceCharges = serviceChargeState?.data?.data ?? [];
      const updatedList = isInitListCall ? serviceCharges : [...list, ...serviceCharges];
      const serviceChargeLeftToFetch = totalCount ? +totalCount - updatedList.length : 0;

      setHasMore(serviceChargeLeftToFetch > 0);
      setList(updatedList);
      if (page.current === 1) {
        setTotalListCount(totalCount ?? 0);
      }
      setIsLoading(false);
    }
  }, [serviceChargeState?.isFetching]);

  useEffect(() => {
    if (!serviceChargeState?.isFetching && serviceChargeState?.isError) {
      setIsLoading(false);
      setHasMore(false);
    }
  }, [serviceChargeState?.isError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setList([]);
    loadMore();
  };

  useEffect(() => {
    if (isMounted) {
      resetListAndLoadMore();
    } else {
      setIsMounted(true);
    }
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    if (currentTeam?.id) {
      const remainingParams = queryParams;

      try {
        getServiceChargeQuery({
          teamId: currentTeam?.id,
          limit: 10,
          page: page.current,
          ...remainingParams
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetRecallQuery = () => {
    resetListAndLoadMore();
  };

  const resetQuery = () => {
    page.current = 0;
    setList([]);
    setQueryParams(initialServiceChargeQueryParamsState);
    setIsMounted(false);
    setIsError(false);
    setHasMore(true);
    setTotalListCount(0);
  };

  return {
    isLoading: isLoading,
    isFetching: serviceChargeState?.isFetching,
    totalListCount,
    list,
    isError,
    loadMore,
    resetRecallQuery,
    resetQuery,
    hasMore,
    setQueryParams,
    queryParams,
    isInitListCall,
    isLoadingSuccess: serviceChargeState.isSuccess,
    apiErrorCode: serviceChargeState?.error?.code
  };
};
