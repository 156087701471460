import React from 'react';
import { VoidFn } from 'types/baseTypes';

interface RadioWithLabelProps {
  isSelected: boolean;
  label: string;
  onClick: VoidFn;
}

const RadioWithLabel: React.FC<RadioWithLabelProps> = ({ isSelected, label, onClick }) => {
  return (
    <div className="flex cursor-pointer items-center gap-2.5" onClick={onClick}>
      <div className="flex h-[18px] w-[18px] items-center justify-center rounded-full border-[1.5px] border-border">
        <div className={`h-2.5 w-2.5 rounded-full ${isSelected ? 'bg-secondary' : 'bg-highlightGray'} `} />
      </div>
      <div className="text-sbase text-primaryText">{label}</div>
    </div>
  );
};

export default RadioWithLabel;
