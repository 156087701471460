import React, { FC } from 'react';
import { FetchingErrorIcon, ReloadIcon } from 'assets/icons';
import { VoidFn } from 'types/baseTypes';

interface ErrorScreenWithRetryProps {
  handleReload: VoidFn;
}

const ErrorScreenWithRetry: FC<ErrorScreenWithRetryProps> = ({ handleReload }) => {
  return (
    <div className="flex h-full items-center justify-center">
      <div className="flex h-full flex-col items-center justify-center gap-2">
        <FetchingErrorIcon />
        <div className="text-sbase text-accentText">Something went wrong!</div>
        <div className="flex cursor-pointer items-center gap-2" onClick={handleReload}>
          <ReloadIcon />
          <div className="text-px13 text-accentText">Please try refreshing</div>
        </div>
      </div>
    </div>
  );
};

export default ErrorScreenWithRetry;
