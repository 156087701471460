import { DownArrowIcon } from 'assets/icons';
import classnames from 'classnames';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { NavTab, navTabKeys } from 'types/baseTypes';
import { getNavSvgHighightStyle } from 'utils/styleUtils';

interface OverlayedNavTabProps {
  tab: NavTab;
  activeTab: NavTab;
  isActiveSubTab?: boolean;
  onNavTabClick: (tab: NavTab) => void;
  isBottomBar?: boolean;
  subTabClassname?: string;
}

const OverlayedNavTab: FC<OverlayedNavTabProps> = ({ tab, activeTab, onNavTabClick, isBottomBar, subTabClassname }) => {
  const isActiveTab = (navTab: NavTab) => activeTab.key === navTab.key;

  return (
    <div
      onClick={() => onNavTabClick(tab)}
      key={tab.key}
      className={twMerge(
        'hover:cursor-pointer rounded-md flex flex-row gap-4 py-3  px-4 item-center justify-start',
        classnames({
          'bg-secondaryBtn': isActiveTab(tab),
          'justify-center': !isBottomBar,
          [subTabClassname]: subTabClassname
        })
      )}>
      <div className={twMerge('flex flex-row items-center gap-4 ')}>
        <tab.icon
          className={classnames('shrink-0', {
            [`${getNavSvgHighightStyle(tab)} text-secondaryBtnText`]: isActiveTab(tab),
            'mt-1 self-start': tab.key === navTabKeys.discounts
          })}
          width={20}
        />
        <div
          id={tab.id}
          className={twMerge(
            classnames('max-w-[150px] font-bold text-primaryText duration-300 ease-in-out', {
              'text-secondaryBtnText': isActiveTab(tab),
              'min-w-[130px]': !tab.hasChildTabs,
              'min-w-[100px]': tab.hasChildTabs
            })
          )}>
          {tab.title}
        </div>
        {tab.hasChildTabs && <DownArrowIcon className={`${activeTab.key === tab.key ? 'rotate-180' : ''}`} />}
      </div>
    </div>
  );
};

export default OverlayedNavTab;
