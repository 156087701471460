import { FC } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/typedHooks';
import {
  getBankingPermissionLevel,
  getCustomerPermissionLevel,
  getInvoicePermissionLevel,
  getRefundPermissionLevel,
  getTeamSettingPermissionLevel,
  getTipAndServicePermissionLevel
} from 'containers/app/util';
import { PermissionSettingKeys, PermissionSettingType } from '../constants';

import { PermissionItem } from '../EditPermissionsModal';
import {
  changePermission,
  selectBankingSettingsPermissions,
  selectCustomersSettingsPermissions,
  selectFeesSettingsPermissions,
  selectInvoiceSettingsPermissions,
  selectRefundSettingsPermissions,
  selectTeamSettingsPermissions
} from '../editPermissionsSlice';
import PermissionDisclosure from './PermissionDisclosure';
import { selectPaymentSystemConfig } from 'containers/app/appSlice';
import { PaymentSystem } from 'containers/payouts/constants';

interface PermissionDisclosureProps {
  className?: string;
}

const PermissionDisclosures: FC<PermissionDisclosureProps> = () => {
  const selectedTeamSettingsPermissions = useAppSelector(selectTeamSettingsPermissions);
  const selectedInvoiceSettingsPermissions = useAppSelector(selectInvoiceSettingsPermissions);
  const selectedFeesSettingsPermissions = useAppSelector(selectFeesSettingsPermissions);
  const selectedRefundSettingsPermissions = useAppSelector(selectRefundSettingsPermissions);
  const selectedCustomersSettingsPermissions = useAppSelector(selectCustomersSettingsPermissions);
  const selectedBankingSettingsPermissions = useAppSelector(selectBankingSettingsPermissions);
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig);

  const dispatch = useAppDispatch();

  const teamSettingsPermissions: PermissionItem[] = [
    {
      title: 'View team profile details',
      key: 'viewTeamProfile',
      parentKey: 'teamSettingPermissionList',
      rootKey: 'teamSettings',
      enabled: selectedTeamSettingsPermissions?.teamSettingPermissionList.viewTeamProfile
    },
    {
      title: 'Edit team profile details',
      parentKey: 'teamSettingPermissionList',
      rootKey: 'teamSettings',
      key: 'editTeamProfile',
      enabled: selectedTeamSettingsPermissions?.teamSettingPermissionList.editTeamProfile
    },
    {
      title: 'Invite a new member',
      parentKey: 'teamSettingPermissionList',
      rootKey: 'teamSettings',
      key: 'inviteNewMember',
      enabled: selectedTeamSettingsPermissions?.teamSettingPermissionList.inviteNewMember
    },

    {
      title: 'Edit existing members',
      parentKey: 'teamSettingPermissionList',
      rootKey: 'teamSettings',
      key: 'editExistingMember',
      enabled: selectedTeamSettingsPermissions?.teamSettingPermissionList.editExistingMember
    },

    {
      title: 'View existing members',
      parentKey: 'teamSettingPermissionList',
      rootKey: 'teamSettings',
      key: 'viewExistingMember',
      enabled: selectedTeamSettingsPermissions?.teamSettingPermissionList.viewExistingMember
    }
  ];

  // Invoices and Receipts related permissions
  const invoicePermissionsCA: PermissionItem[] = [
    {
      title: 'Create invoice',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'createInvoice',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.createInvoice
    },

    {
      title: 'View receipts created by self',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewReceiptsBySelf',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewReceiptsBySelf
    },
    {
      title: 'View receipts created by team',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewReceiptsByTeam',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewReceiptsByTeam
    },
    {
      title: 'View invoices by self',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewInvoicesBySelf',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewInvoicesBySelf
    },

    {
      title: 'View invoices by team',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewInvoicesByTeam',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewInvoicesByTeam
    },

    {
      title: 'View payouts by team',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewPayoutsByTeam',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewPayoutsByTeam
    }
  ];

  // Invoices and Receipts related permissions
  const invoicePermissionsLIP: PermissionItem[] = [
    {
      title: 'Create invoice',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'createInvoice',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.createInvoice
    },

    {
      title: 'View receipts created by self',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewReceiptsBySelf',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewReceiptsBySelf
    },
    {
      title: 'View receipts created by team',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewReceiptsByTeam',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewReceiptsByTeam
    },
    {
      title: 'View invoices by self',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewInvoicesBySelf',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewInvoicesBySelf
    },

    {
      title: 'View invoices by team',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewInvoicesByTeam',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewInvoicesByTeam
    },
    {
      title: 'View payouts by self',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewPayoutsBySelf',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewPayoutsBySelf
    },
    {
      title: 'View payouts by team',
      rootKey: 'invoice',
      parentKey: 'invoicePermissionList',
      key: 'viewPayoutsByTeam',
      enabled: selectedInvoiceSettingsPermissions?.invoicePermissionList.viewPayoutsByTeam
    }
  ];

  // permissions related to tips and fees
  const feesPermissions: PermissionItem[] = [
    {
      title: 'Manage items and categories',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'manageItemsAndCategories',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.manageItemsAndCategories
    },

    {
      title: 'Manage taxes',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'manageTaxes',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.manageTaxes
    },
    {
      title: 'Manage service charges',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'manageServiceCharges',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.manageServiceCharges
    },

    {
      title: 'View service fee settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'viewServiceFeeSettings',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.viewServiceFeeSettings
    },

    {
      title: 'Edit service fee settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'editServiceFeeSettings',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.editServiceFeeSettings
    },
    {
      title: 'View tips settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'viewTipsSettings',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.viewTipsSettings
    },
    {
      title: 'Edit tips settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'editTipsSettings',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.editTipsSettings
    },

    {
      title: 'View discount',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'viewDiscount',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.viewDiscount
    },

    {
      title: 'Edit discount',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'editDiscount',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.editDiscount
    },
    {
      title: 'View Terms & Conditions settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'viewTnc',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.viewTnc
    },
    {
      title: 'Edit Terms & Conditions settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'editTnc',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.editTnc
    },
    {
      title: 'View Signature settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'viewSignatureSettings',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.viewSignatureSettings
    },
    {
      title: 'Edit Signature settings',
      rootKey: 'fees',
      parentKey: 'feesPermissionList',
      key: 'editSignatureSettings',
      enabled: selectedFeesSettingsPermissions?.feesPermissionList.editSignatureSettings
    }
  ];

  // permissions related to refunds
  const refundPermissions: PermissionItem[] = [
    {
      title: 'Create refunds on transactions created by self',
      rootKey: 'refund',
      parentKey: 'refundPermissionList',
      key: 'createRefundsBySelf',
      enabled: selectedRefundSettingsPermissions?.refundPermissionList.createRefundsBySelf
    },

    {
      title: 'Create refunds on transactions created by team',
      rootKey: 'refund',
      parentKey: 'refundPermissionList',
      key: 'createRefundsByTeam',
      enabled: selectedRefundSettingsPermissions?.refundPermissionList.createRefundsByTeam
    }
  ];

  // permissions related to customers
  const customersPermissions: PermissionItem[] = [
    {
      title: 'Create new customers',
      rootKey: 'customers',
      parentKey: 'customersPermissionList',
      key: 'createNewCustomers',
      enabled: selectedCustomersSettingsPermissions?.customersPermissionList.createNewCustomers
    },

    {
      title: 'Edit existing customers',
      rootKey: 'customers',
      parentKey: 'customersPermissionList',
      key: 'editExistingCustomers',
      enabled: selectedCustomersSettingsPermissions?.customersPermissionList.editExistingCustomers
    },
    {
      title: 'View customers created by team',
      rootKey: 'customers',
      parentKey: 'customersPermissionList',
      key: 'viewCustomersCreatedByTeam',
      enabled: selectedCustomersSettingsPermissions?.customersPermissionList.viewCustomersCreatedByTeam
    },
    {
      title: 'Edit customers created by team',
      rootKey: 'customers',
      parentKey: 'customersPermissionList',
      key: 'editCustomersCreatedByTeam',
      enabled: selectedCustomersSettingsPermissions?.customersPermissionList.editCustomersCreatedByTeam
    }
  ];

  // permissions related to banking
  const bankingPermissions: PermissionItem[] = [
    {
      title: 'View account details',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'viewAccountDetails',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.viewAccountDetails
    },

    {
      title: 'Create ACH transfer',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'createACHTransfer',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.createACHTransfer
    },
    {
      title: 'Create payee',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'createPayee',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.createPayee
    },
    {
      title: 'Edit existing payee',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'editExistingPayee',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.editExistingPayee
    },

    {
      title: 'Download statement',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'downloadStatement',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.downloadStatement
    },

    {
      title: 'View card details',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'viewCardDetails',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.viewCardDetails
    },

    {
      title: 'Manage cards',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'manageCards',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.manageCards
    },

    {
      title: 'View cashback details',
      rootKey: 'banking',
      parentKey: 'bankingPermissionList',
      key: 'viewCashbackDetails',
      enabled: selectedBankingSettingsPermissions?.bankingPermissionList.viewCashbackDetails
    }
  ];

  const onTogglePermission = (permissionItem: PermissionItem) => {
    if (permissionItem.rootKey === PermissionSettingKeys.BANKING) {
      return;
    }
    dispatch(changePermission({ permission: permissionItem, paymentSystem }));
  };

  return (
    <>
      <PermissionDisclosure
        title={PermissionSettingType.TEAM_SETTINGS}
        onTogglePermission={onTogglePermission}
        permissionsSettingsList={teamSettingsPermissions}
        secondaryTitle={getTeamSettingPermissionLevel(selectedTeamSettingsPermissions.teamSettingPermissionList)}
      />

      <PermissionDisclosure
        title={PermissionSettingType.INVOICES_AND_RECEIPTS}
        onTogglePermission={onTogglePermission}
        permissionsSettingsList={paymentSystem === PaymentSystem.CA ? invoicePermissionsCA : invoicePermissionsLIP}
        secondaryTitle={getInvoicePermissionLevel(
          selectedInvoiceSettingsPermissions.invoicePermissionList,
          paymentSystem
        )}
      />

      <PermissionDisclosure
        title={PermissionSettingType.TIPS_AND_FEES}
        onTogglePermission={onTogglePermission}
        permissionsSettingsList={feesPermissions}
        secondaryTitle={getTipAndServicePermissionLevel(selectedFeesSettingsPermissions.feesPermissionList)}
      />

      <PermissionDisclosure
        title={PermissionSettingType.REFUNDS}
        onTogglePermission={onTogglePermission}
        permissionsSettingsList={refundPermissions}
        secondaryTitle={getRefundPermissionLevel(selectedRefundSettingsPermissions.refundPermissionList)}
      />
      <PermissionDisclosure
        title={PermissionSettingType.CUSTOMERS}
        onTogglePermission={onTogglePermission}
        permissionsSettingsList={customersPermissions}
        secondaryTitle={getCustomerPermissionLevel(selectedCustomersSettingsPermissions.customersPermissionList)}
      />
      <PermissionDisclosure
        className="cursor-not-allowed opacity-50"
        title={PermissionSettingType.BANKING}
        onTogglePermission={onTogglePermission}
        permissionsSettingsList={bankingPermissions}
        secondaryTitle={getBankingPermissionLevel(selectedBankingSettingsPermissions.bankingPermissionList)}
      />
    </>
  );
};

export default PermissionDisclosures;
