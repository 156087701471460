import React, { useEffect } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import SidePanel from 'components/side-panel/SidePanel';
import { ShareQRSection } from './ShareQRSection';
import { useLazyGetInvoiceByIdQuery } from '../api';

export const ShareQRPanel: React.FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { handleSidePanelClose } = useOutletContext<any>();
  const { invoiceId, rootInvoiceId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const [getInvoiceByIdQuery, getInvoiceByIdResponse] = useLazyGetInvoiceByIdQuery();
  const { data: invoiceData, isSuccess: invoiceDataSuccess, isFetching: fetchingInvoiceData } = getInvoiceByIdResponse;

  useEffect(() => {
    if (invoiceId || rootInvoiceId)
      getInvoiceByIdQuery({ invoiceId: invoiceId ? invoiceId : rootInvoiceId, teamId: currentTeam?.id });
  }, []);

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="h-full w-96 px-[26px] pt-[30px]">
        {fetchingInvoiceData ? (
          <div className="flex h-full w-full items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          invoiceDataSuccess && (
            <ShareQRSection
              qrData={invoiceData?.qr}
              payableAmount={invoiceData?.payableAmount}
              onBackClick={handleBackClick}
            />
          )
        )}
      </div>
    </SidePanel>
  );
};
