import { baseApi } from 'services/api';
import { API_SOURCE, API_VERSION } from 'constants/common';
import {
  ConnectPayoutByIdPayload,
  ConnectPayoutByIdResponse,
  ConnectPayoutTransactionsResponse,
  InTransitPayoutsPayload,
  InTransitPayoutsResponse,
  PayoutByIdPayload,
  PayoutByIdResponse,
  PayoutSummaryPayload,
  PayoutSummaryResponse,
  PayoutsAccountSummary,
  PayoutsPayload,
  PayoutsResponse,
  PayoutsSummaryTransactions,
  PayoutsSummaryTxnPayload,
  TransactionsByPayoutIdPayload,
  UpcomingPayoutTransactionsPayload,
  UpcomingPayoutsSummary,
  UpdateNotesPayload
} from './payouts.model';
import { downloadFile } from 'utils/commonUtils';
import { getUrlSearchParams } from 'utils/apiUtils';
import { FileTypes } from 'types/baseTypes';

const PayoutsBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getPayouts: builder.query<PayoutsResponse, PayoutsPayload>({
      query: payload => {
        let url = `${API_VERSION.V1}/team/${payload.teamId}/payouts?`;
        const urlParams = getUrlSearchParams(payload.params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch payouts data',
        showToast: true
      }
    }),
    downloadPayouts: builder.query<any, PayoutsPayload>({
      query: ({ params, teamId }) => {
        let url = `${API_VERSION.V1}/team/${teamId}/payouts/export?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.CSV, fileName: 'payouts' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'Failed to download payouts list',
        success: 'Payouts exported successfully!',
        showToast: true
      }
    }),
    getPayoutSummary: builder.query<PayoutSummaryResponse, PayoutSummaryPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/payouts-summary?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to download payouts summary',
        showToast: true
      }
    }),
    getPayoutTransactions: builder.query<PayoutsSummaryTransactions, PayoutsSummaryTxnPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/payouts/transactions?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch payouts summary data',
        showToast: true
      }
    }),
    getPayoutById: builder.query<PayoutByIdResponse, PayoutByIdPayload>({
      query: params => {
        const url = `${API_VERSION.WEB_V1}/payouts/${params.payoutId}?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to get payouts data',
        showToast: true
      }
    }),
    getInTransitPayouts: builder.query<InTransitPayoutsResponse, InTransitPayoutsPayload>({
      query: ({ teamId }) => {
        const url = `${API_VERSION.V1}/team/${teamId}/intransit-payouts`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to get payouts data',
        showToast: true
      }
    }),
    getUpcomingPayoutsSummary: builder.query<UpcomingPayoutsSummary, number>({
      query: teamId => {
        const url = `${API_VERSION.V1}/team/${teamId}/upcoming-payouts/summary`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to get payouts summary data',
        showToast: true
      }
    }),
    updatePayoutNotes: builder.mutation<ConnectPayoutByIdResponse, UpdateNotesPayload>({
      query: ({ payoutId, teamId, body }) => {
        const url = `${API_VERSION.V1}/team/${teamId}/payouts/${payoutId}/notes`;
        return {
          url: url,
          method: 'PATCH',
          body
        };
      },
      extraOptions: {
        failure: 'Something went wrong',
        showToast: true
      }
    }),
    getConnectPayoutById: builder.query<ConnectPayoutByIdResponse, ConnectPayoutByIdPayload>({
      query: ({ payoutId, teamId }) => {
        const url = `${API_VERSION.V1}/team/${teamId}/payouts/${payoutId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to get payouts data',
        showToast: true
      }
    }),
    getTransactionsByPayoutId: builder.query<ConnectPayoutTransactionsResponse, TransactionsByPayoutIdPayload>({
      query: ({ payoutId, teamId, status, page, limit, amount, search }) => {
        let url = `${API_VERSION.V1}/team/${teamId}/payouts/${payoutId}/transactions/${API_SOURCE.WEB}?`;
        const urlSearchParams = getUrlSearchParams({ status, page, limit, amount, search });
        url = url + urlSearchParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to get payouts data',
        showToast: true
      }
    }),
    getPayoutsAccountSummary: builder.query<PayoutsAccountSummary, number>({
      query: teamId => {
        const url = `${API_VERSION.V1}/team/${teamId}/account/summary`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to get account summary data',
        showToast: true
      }
    }),
    getUpcomingPayoutsTransactions: builder.query<ConnectPayoutTransactionsResponse, UpcomingPayoutTransactionsPayload>(
      {
        query: ({ teamId, status, page, limit, amount, search }) => {
          let url = `${API_VERSION.V1}/team/${teamId}/upcoming-payouts/transactions/${API_SOURCE.WEB}?`;
          const urlSearchParams = getUrlSearchParams({ status, page, limit, amount, search });
          url = url + urlSearchParams;

          return {
            url: url,
            method: 'GET'
          };
        },
        extraOptions: {
          failure: 'Failed to get payouts data',
          showToast: true
        }
      }
    ),
    downloadUpcomingPayoutsTransactions: builder.query<
      ConnectPayoutTransactionsResponse,
      UpcomingPayoutTransactionsPayload
    >({
      query: ({ teamId, status, page, limit, amount, search }) => {
        let url = `${API_VERSION.V1}/team/${teamId}/upcoming-payouts/transactions/export?`;
        const urlSearchParams = getUrlSearchParams({ status, page, limit, amount, search });
        url = url + urlSearchParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.CSV, fileName: 'payouts-transactions' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'Failed to get payouts data',
        showToast: true
      }
    }),
    downloadTransactionsByPayoutId: builder.query<ConnectPayoutTransactionsResponse, TransactionsByPayoutIdPayload>({
      query: ({ payoutId, teamId, status, page, limit, amount, search }) => {
        let url = `${API_VERSION.V1}/team/${teamId}/payouts/${payoutId}/transactions/export?`;
        const urlSearchParams = getUrlSearchParams({ status, page, limit, amount, search });
        url = url + urlSearchParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.CSV, fileName: 'payouts-transactions' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'Failed to get payouts data',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetPayoutsQuery,
  useLazyDownloadPayoutsQuery,
  useLazyGetPayoutSummaryQuery,
  useLazyGetPayoutTransactionsQuery,
  useLazyGetPayoutByIdQuery,
  useLazyGetInTransitPayoutsQuery,
  useLazyGetUpcomingPayoutsSummaryQuery,
  useLazyGetConnectPayoutByIdQuery,
  useLazyGetTransactionsByPayoutIdQuery,
  useUpdatePayoutNotesMutation,
  useLazyGetPayoutsAccountSummaryQuery,
  useLazyGetUpcomingPayoutsTransactionsQuery,
  useLazyDownloadTransactionsByPayoutIdQuery,
  useLazyDownloadUpcomingPayoutsTransactionsQuery
} = PayoutsBaseApi;
