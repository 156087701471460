import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { GetTeamProfileApiResponse, Listing, TeamProfileAddress } from 'containers/team-profile/teamProfile.model';
import { RootState } from 'store/reducers';

export interface EditTeamProfileSlice {
  id: number;
  name: string;
  description: string;
  statementDescriptor: string;
  phone: string;
  email: string;
  website: string;
  profileImageUrl: string;
  profession: string;
  professionObject: { id: number; name: string } | null;
  address: TeamProfileAddress;
  listings: Listing;
  profileImageState: 'unchanged' | 'new' | 'deleted';
}

export interface EditTeamProfileSliceState {
  originalData: EditTeamProfileSlice;
  currentData: EditTeamProfileSlice;
  isTeamProfileLoaded: boolean;
}

const initialStateDataa: EditTeamProfileSlice = {
  id: 0,
  name: '',
  description: '',
  statementDescriptor: '',
  phone: '',
  email: '',
  website: '',
  profileImageUrl: '',
  profession: '',
  professionObject: null,
  address: {
    zipCode: '',
    street: '',
    city: '',
    state: '',
    apartment: ''
  },
  listings: {
    yelp: '',
    facebook: '',
    otherOnlineReferrence: '',
    website: ''
  },
  profileImageState: 'unchanged'
};

const initialState: EditTeamProfileSliceState = {
  originalData: initialStateDataa,
  currentData: initialStateDataa,
  isTeamProfileLoaded: false
};

export const teamProfileSlice = createSlice({
  name: 'teamProfile',
  initialState,
  reducers: {
    resetTeamProfileSlice: () => initialState,
    initializeTeamProfileSliceFromAPI: (state, { payload }: PayloadAction<GetTeamProfileApiResponse>) => {
      const data1 = payload;
      state.originalData = {
        ...data1,
        professionObject: { id: 1, name: data1.profession },
        profileImageState: 'unchanged'
      };
      state.currentData = {
        ...data1,
        professionObject: { id: 1, name: data1.profession },
        profileImageState: 'unchanged'
      };
      state.isTeamProfileLoaded = true;
    },

    modifyTeamProfileName: (state, { payload }: PayloadAction<string>) => {
      state.currentData.name = payload;
    },
    modifyTeamProfileDescription: (state, { payload }: PayloadAction<string>) => {
      state.currentData.description = payload;
    },
    modifyTeamProfilePhone: (state, { payload }: PayloadAction<string>) => {
      state.currentData.phone = payload;
    },
    modifyTeamProfileEmail: (state, { payload }: PayloadAction<string>) => {
      state.currentData.email = payload;
    },
    modifyTeamProfileWebsite: (state, { payload }: PayloadAction<string>) => {
      state.currentData.website = payload;
    },
    modifyTeamProfileProfileImageUrl: (state, { payload }: PayloadAction<string>) => {
      state.currentData.profileImageUrl = payload;
    },
    modifyTeamProfileProfession: (state, { payload }: PayloadAction<string>) => {
      state.currentData.profession = payload;
    },
    modifyTeamProfileProfessionObject: (state, { payload }: PayloadAction<{ id: number; name: string }>) => {
      state.currentData.professionObject = payload;
    },
    modifyTeamProfileProfessionObjectForOriginalState: (
      state,
      { payload }: PayloadAction<{ id: number; name: string }>
    ) => {
      state.originalData.professionObject = payload;
    },
    modifyTeamProfileAddress: (state, { payload }: PayloadAction<TeamProfileAddress>) => {
      state.currentData.address = payload;
    },
    modifyTeamProfileYelp: (state, { payload }: PayloadAction<string>) => {
      state.currentData.listings.yelp = payload;
    },
    modifyTeamProfileFacebook: (state, { payload }: PayloadAction<string>) => {
      state.currentData.listings.facebook = payload;
    },
    modifyTeamProfileOtherOnlineReferrence: (state, { payload }: PayloadAction<string>) => {
      state.currentData.listings.otherOnlineReferrence = payload;
    },
    revertCurrentData: (state, { payload }: PayloadAction<EditTeamProfileSlice>) => {
      state.currentData = { ...payload };
    },
    setOriginalDataInTeamProfile: (state, { payload }: PayloadAction<EditTeamProfileSlice>) => {
      state.originalData = { ...payload };
    },
    modifyTeamProfileImageStatus: (state, { payload }: PayloadAction<'unchanged' | 'new' | 'deleted'>) => {
      state.currentData.profileImageState = payload;
    },
    updateIsLoadedTeamProfile: (state, { payload }: PayloadAction<boolean>) => {
      state.isTeamProfileLoaded = payload;
    }
  }
});

export const {
  initializeTeamProfileSliceFromAPI,
  modifyTeamProfileName,
  modifyTeamProfileDescription,
  modifyTeamProfilePhone,
  modifyTeamProfileAddress,
  modifyTeamProfileFacebook,
  modifyTeamProfileOtherOnlineReferrence,
  modifyTeamProfileProfession,
  modifyTeamProfileProfessionObject,
  modifyTeamProfileProfessionObjectForOriginalState,
  modifyTeamProfileProfileImageUrl,
  modifyTeamProfileWebsite,
  modifyTeamProfileEmail,
  modifyTeamProfileYelp,
  resetTeamProfileSlice,
  revertCurrentData,
  setOriginalDataInTeamProfile,
  modifyTeamProfileImageStatus,
  updateIsLoadedTeamProfile
} = teamProfileSlice.actions;

export const selectedTeamProfileDetails = (state: RootState) => state.rootReducer.teamProfile;
export const isTeamProfileFetchLoadingComplete = (state: RootState) =>
  state.rootReducer.teamProfile.isTeamProfileLoaded;
export const selectedListingsForTeamProfileEdit = (state: RootState) =>
  state.rootReducer.teamProfile.currentData.listings;
export const selectedAddressStringForTeamProfileEdit = (stateItr: RootState) => {
  const { street, city, state, zipCode, apartment } = stateItr.rootReducer.teamProfile.currentData.address;
  return `${street} ${apartment} ${city} ${state} ${zipCode}`;
};

export const selectedAddressPreText = (stateItr: RootState) => {
  const { street, apartment } = stateItr.rootReducer.teamProfile.currentData.address;
  let addressString = '';
  if (street !== '') {
    addressString += street;
  }
  if (apartment !== '') {
    addressString += ` ${apartment}`;
  }
  return addressString;
};

export const selectedAddressPostText = (stateItr: RootState) => {
  const { city, state, zipCode } = stateItr.rootReducer.teamProfile.currentData.address;
  let addressString = '';
  if (city !== '') {
    addressString += city;
  }
  if (state !== '') {
    if (addressString !== '') addressString += ', ';
    addressString += state;
  }
  if (zipCode !== '') {
    if (addressString !== '') addressString += ', ';
    addressString += zipCode;
  }
  return addressString;
};

export const isTeamProfileDataChanged = (stateItr: RootState) => {
  const originalData = { ...stateItr.rootReducer.teamProfile.originalData };
  const currentData = { ...stateItr.rootReducer.teamProfile.currentData };

  delete originalData.professionObject;
  delete currentData.professionObject;
  delete originalData.profileImageUrl;
  delete currentData.profileImageUrl;

  const { street, city, state, zipCode, apartment } = stateItr.rootReducer.teamProfile.currentData.address;
  const {
    street: streetOriginal,
    city: cityOriginal,
    state: stateOriginal,
    zipCode: zipCodeOriginal,
    apartment: apartmentOriginal
  } = stateItr.rootReducer.teamProfile.originalData.address;

  if (
    !(
      street === streetOriginal &&
      city === cityOriginal &&
      state === stateOriginal &&
      zipCode === zipCodeOriginal &&
      apartment === apartmentOriginal
    )
  ) {
    return true;
  }
  delete currentData.address;
  delete originalData.address;

  return (
    JSON.stringify(originalData) !== JSON.stringify(currentData) && stateItr.rootReducer.teamProfile.isTeamProfileLoaded
  );
};
export default teamProfileSlice.reducer;
