import { CircleCloseIcon, SelectionCircleBlueIcon, SelectionCircleIcon } from 'assets/icons';
import { CustomButton, CustomModal, If } from 'components';
import { InvoiceSurchargeState, updateIsServiceFeeEnabled } from 'containers/invoices/InvoicesSlice';
import React, { FC, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import ServiceFeeToolTip from './ServiceFeeToolTip';
import { useAppDispatch } from 'hooks';
import { updateIsServiceFeeEnabledEditPage } from 'containers/invoices/edit-invoice/invoicesEditSlice';
import { ButtonType } from 'components/custom-button/CustomButton';

interface AddServiceFeeModalProps {
  isOpen: boolean;
  onClose: VoidFn;
  serviceFee: InvoiceSurchargeState;
  isServiceFeeEnabled: boolean;
  isEdit: boolean;
}

const AddServiceFeeModal: FC<AddServiceFeeModalProps> = ({
  isOpen,
  onClose,
  serviceFee,
  isServiceFeeEnabled,
  isEdit
}) => {
  const { percentage, basePoints } = serviceFee;
  const [serviceFeeEnabled, toggleServiceFeeEnabled] = useState(isServiceFeeEnabled);
  const dispatch = useAppDispatch();

  const onSaveClick = () => {
    if (isEdit) dispatch(updateIsServiceFeeEnabledEditPage(serviceFeeEnabled));
    else dispatch(updateIsServiceFeeEnabled(serviceFeeEnabled));
    onClose();
  };

  const onCancelClick = () => {
    toggleServiceFeeEnabled(isServiceFeeEnabled);
    onClose();
  };

  const disableSaveBtn = serviceFeeEnabled === isServiceFeeEnabled;

  return (
    <CustomModal
      closeModal={onClose}
      open={isOpen}
      backgroundColor="bg-primary"
      width="md:w-[395px]"
      height="max-h-[334px] h-[90%]">
      <div className="absolute flex h-full w-full grow flex-col">
        <CircleCloseIcon className="absolute top-2 right-2 z-[1] shrink-0 cursor-pointer" onClick={onClose} />
        <div className="px-10 py-9">
          <div className="text-xl font-bold text-headingGray">Choose service fee</div>
          <div className="mt-6 text-sbase font-semibold text-primaryText">Set your desired service fee</div>
          <div className="mt-2.5 flex flex-col gap-5">
            <div
              className="flex cursor-pointer justify-between rounded border border-borderGray bg-secondaryBtn p-4"
              role="presentation"
              onClick={() => toggleServiceFeeEnabled(true)}>
              <div className="flex items-center gap-x-2">
                <If condition={!serviceFeeEnabled}>
                  <SelectionCircleIcon className="h-[18px] w-[18px] shrink-0" />
                </If>
                <If condition={serviceFeeEnabled}>
                  <SelectionCircleBlueIcon className="h-[18px] w-[18px] shrink-0" />
                </If>
                <div className="text-sbase font-semibold text-primaryText">
                  {(+percentage ?? 0).toFixed(2)}% {+basePoints > 0 ? ` + ${+basePoints} cents` : ''}
                </div>
              </div>
              <ServiceFeeToolTip width="md:w-[305px]" arrowOffset="right-[25%] md:right-[50%]" offset="-left-[131px]" />
            </div>
            <div
              className="flex cursor-pointer justify-between rounded border border-borderGray bg-secondaryBtn p-4"
              role="presentation"
              onClick={() => toggleServiceFeeEnabled(false)}>
              <div className="flex items-center gap-x-2">
                <If condition={serviceFeeEnabled}>
                  <SelectionCircleIcon className="h-[18px] w-[18px] shrink-0" />
                </If>
                <If condition={!serviceFeeEnabled}>
                  <SelectionCircleBlueIcon className="h-[18px] w-[18px] shrink-0" />
                </If>
                <div className="text-sbase font-semibold text-primaryText">No service fee</div>
              </div>
            </div>
          </div>
          <div className="mt-6 flex flex-row items-center justify-end">
            <CustomButton
              className="mr-5 ml-auto h-[38px] w-[100px]"
              type={ButtonType.SECONDARY}
              onClick={onCancelClick}
              id={'toggle-service-fee-cancel-btn'}>
              Cancel
            </CustomButton>

            <CustomButton
              className="h-[38px] w-[100px]"
              onClick={onSaveClick}
              disabled={disableSaveBtn}
              id={'toggle-service-fee-save-btn'}>
              Save
            </CustomButton>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddServiceFeeModal;
