import React, { FC } from 'react';

interface Tab {
  id: number | string;
  title: string;
}

interface TabsProp {
  tabs: Tab[];
  selectedTab: string;
  onTabSelect: (tab: string) => void;
}

const Tabs: FC<TabsProp> = props => {
  const { tabs, selectedTab, onTabSelect } = props;
  return (
    <div className="flex">
      {tabs.map(tab => (
        <div
          id={`${tab.title}-tab`}
          key={tab.id}
          role="presentation"
          onClick={() => onTabSelect(tab.title)}
          className={`remove-highlight flex min-w-[93px] cursor-pointer justify-center py-1.5 text-17px 
                      font-semibold normal-case leading-5 text-primaryText 
                      ${selectedTab === tab.title ? 'border-b-2 border-secondaryBtnText opacity-100' : 'opacity-75'}`}>
          {tab.title}
        </div>
      ))}
    </div>
  );
};

export default Tabs;
