import { TeamProfileAddress } from 'containers/team-profile/teamProfile.model';
import React, { FC } from 'react';

interface TeamDetailsSectionProps {
  teamName: string;
  teamPhone: string;
  teamEmail: string;
  teamBillingAddress: TeamProfileAddress;
  teamWebsite: string;
}

const TeamDetailsSection: FC<TeamDetailsSectionProps> = ({
  teamName,
  teamPhone,
  teamEmail,
  teamBillingAddress,
  teamWebsite
}) => {
  const { zipCode, street, city, state, apartment } = teamBillingAddress ?? {};

  const getAddress = () => {
    let addressString = '';
    if (apartment && apartment !== '') {
      addressString += ` ${apartment}`;
    }
    if (street && street !== '') {
      addressString += ` ${street}`;
    }

    if (city && city !== '') {
      addressString += ` ${city}`;
    }

    if (state && state !== '') {
      addressString += ` ${state}`;
    }

    addressString += ' US';
    if (zipCode && zipCode?.length > 0) {
      addressString += ` ${zipCode}`;
    }
    return addressString;
  };

  return (
    <div className="rounded-md border border-borderGray bg-white p-10">
      <div className="text-heading-black text-sbase font-semibold">FROM</div>
      <div className="mt-5 flex flex-col gap-2.5 text-sbase font-normal text-primaryText">
        <div>{teamName}</div>
        {teamPhone && <div>{teamPhone}</div>}
        {teamEmail && <div>{teamEmail}</div>}
        {teamBillingAddress && <div className="line-clamp-6">{getAddress()}</div>}
        {teamWebsite && <div>{teamWebsite}</div>}
      </div>
    </div>
  );
};

export default TeamDetailsSection;
