import { LoadingSpinnerAnimation } from 'assets/animations';
import CustomModal from 'components/custom-modal/CustomModal';
import If from 'components/If/If';
import { CustomButton as Button } from 'components';
import Lottie from 'lottie-react';
import React, { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { ButtonType } from 'components/custom-button/CustomButton';

interface GenericModalProps {
  showModal: boolean;
  isActionInProgress: boolean;
  MainIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  CloseIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  title: string;
  subTitle: string;
  primaryBtnLabel: string;
  onPrimaryBtnClick: VoidFn;
  secondaryBtnLabel: string;
  onSecondaryBtnClick: VoidFn;
  primaryBtnId: string;
  secondaryBtnId: string;
  titleStyle?: string;
  subTitleStyle?: string;
  containerWidth?: string;
  height?: string;
  onCloseIconClick?: VoidFn;
}

const GenericModal: FC<GenericModalProps> = ({
  onPrimaryBtnClick,
  onSecondaryBtnClick,
  CloseIcon,
  MainIcon = null,
  primaryBtnLabel,
  secondaryBtnLabel,
  subTitle,
  title,
  showModal,
  isActionInProgress,
  primaryBtnId,
  secondaryBtnId,
  titleStyle = '',
  subTitleStyle = '',
  containerWidth = '',
  height = '',
  onCloseIconClick
}) => {
  return (
    <CustomModal
      closeModal={onSecondaryBtnClick}
      open={showModal}
      backgroundColor="bg-primary"
      widthInPx={containerWidth}
      height={height}
      onOutsideClick={onCloseIconClick}>
      <If condition={!isActionInProgress}>
        <div className="relative flex w-full flex-col items-center p-10">
          <div className="absolute top-2 right-2">
            <CloseIcon onClick={onCloseIconClick ?? onSecondaryBtnClick} className="shrink-0 cursor-pointer" />
          </div>
          {MainIcon && <MainIcon className="mb-5 shrink-0" />}
          <div className={`mb-4 text-17px font-semibold text-primaryText ${titleStyle}`}>{title}</div>
          <div className={`mb-4 whitespace-pre-line text-sbase font-normal text-primaryText ${subTitleStyle}`}>
            {subTitle}
          </div>
          <Button
            id={primaryBtnId}
            className="mb-5 h-[38px] w-full bg-primaryBtn font-lato text-sbase font-semibold text-primary"
            onClick={onPrimaryBtnClick}>
            <div>{primaryBtnLabel}</div>
          </Button>
          <Button
            id={secondaryBtnId}
            className="h-[38px] w-full border-primaryBtn
          font-lato text-sbase font-semibold text-secondaryBtnText hover:bg-secondaryBtn"
            onClick={onSecondaryBtnClick}
            type={ButtonType.SECONDARY}>
            <div className="">{secondaryBtnLabel}</div>
          </Button>
        </div>
      </If>
      <If condition={isActionInProgress}>
        <div className="absolute flex h-full w-full items-center justify-center">
          <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
        </div>
      </If>
    </CustomModal>
  );
};

export default GenericModal;
