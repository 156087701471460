import { ChevronArrowRightIcon } from 'assets/icons';
import React from 'react';
import { FC } from 'react';

export interface Step {
  id: number;
  name: string;
}

interface StepperProps {
  steps: Step[];
  currentStep: Step;
  className?: string;
}

const StepperWithLabel: FC<StepperProps> = ({ steps, currentStep, className }) => {
  const isCompleted = step => step.id <= currentStep.id;
  return (
    <div className={`flex ${className}`}>
      {steps.map((step, index) => (
        <div className="flex" key={`step-${step.name}`}>
          {index > 0 && <ChevronArrowRightIcon className="ml-3 mr-4" />}
          <div className={`${isCompleted(step) ? 'text-tertiaryText' : 'text-accentText'} font-semibold`}>
            {step.name}
          </div>
        </div>
      ))}
    </div>
  );
};

export default StepperWithLabel;
