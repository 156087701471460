/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import SidePanel from 'components/side-panel/SidePanel';
import { SharePendingInvoiceMainSection } from './SharePendingInvoiceMainSection';
import { useLazyDownloadInvoiceAsPDFQuery, useLazyGetInvoiceByIdQuery } from '../api';
import { ArrowRightIcon, CrossIconvector } from 'assets/icons';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE, FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import { SidePanelRouteState } from 'routes/types';
import ManualKeyInModal from '../manual-key-in/ManualKeyInModal';
import { NotifierTypes } from 'constants/notifierConstants';
import { useGetMerchantCustomizationsQuery } from 'containers/discount-tips-fees/api';
import { formatAmount, formatDecimal } from 'utils/amountUtils';
import { PaymentMethods } from 'constants/common';

export const SharePendingInvoice: React.FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { invoiceId, rootInvoiceId, rootReceiptId, rootPage, rootCustomerId, rootDisputeId } = useParams();
  const { handleSidePanelClose } = useOutletContext<any>();

  const [downloadInvoice] = useLazyDownloadInvoiceAsPDFQuery();
  const [getInvoiceByIdQuery, getInvoiceByIdResponse] = useLazyGetInvoiceByIdQuery();
  const { data: invoiceData, isSuccess: invoiceDataSuccess, isFetching: fetchingInvoiceData } = getInvoiceByIdResponse;
  const {
    data: merchantCustomisations,
    isSuccess: merchantCustomisationsSuccess,
    isFetching: merchantCustomisationsFetching
  } = useGetMerchantCustomizationsQuery({
    teamId: currentTeam.id.toString()
  });
  const { manualKeyInTransactionLimit, paymentModes } = merchantCustomisations ?? {};
  const [isRootState, setIsRootState] = useState(true);
  const [openManualKeyInModal, setOpenManualKeyInModal] = useState<boolean>(false);

  useEffect(() => {
    if (invoiceId || rootInvoiceId)
      getInvoiceByIdQuery({ invoiceId: invoiceId ? invoiceId : rootInvoiceId, teamId: currentTeam?.id });
  }, []);

  useEffect(() => {
    let routeStateTimeout;
    const routeState: SidePanelRouteState = location.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  const handlePayWithQRCodeClick = () => {
    if (rootInvoiceId) {
      if (invoiceId) {
        navigate(
          `/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice/${invoiceData?.invoiceId}/share-qr`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice/share-qr`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else if (rootPage) {
      navigate(
        `/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-pending-invoice/${invoiceData?.invoiceId}/share-qr`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    } else if (rootReceiptId) {
      navigate(
        `/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-pending-invoice/${invoiceData?.invoiceId}/share-qr`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    } else if (rootCustomerId) {
      navigate(
        `/customers/${currentTeam?.id}/${rootCustomerId}/share-pending-invoice/${invoiceData?.invoiceId}/share-qr`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    } else if (rootDisputeId) {
      navigate(
        `/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-pending-invoice/${invoiceData?.invoiceId}/share-qr`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    }
  };

  const handleInvoiceShareClick = () => {
    if (rootInvoiceId) {
      if (invoiceId) {
        navigate(
          `/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice/${invoiceData?.invoiceId}/share-url`,
          {
            state: FROM_SIDE_PANEL_ROUTE_STATE
          }
        );
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-pending-invoice/share-url`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else if (rootPage) {
      navigate(
        `/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-pending-invoice/${invoiceData?.invoiceId}/share-url`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    } else if (rootReceiptId) {
      navigate(
        `/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-pending-invoice/${invoiceData?.invoiceId}/share-url`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    } else if (rootCustomerId) {
      navigate(
        `/customers/${currentTeam?.id}/${rootCustomerId}/share-pending-invoice/${invoiceData?.invoiceId}/share-url`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    } else if (rootDisputeId) {
      navigate(
        `/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-pending-invoice/${invoiceData?.invoiceId}/share-url`,
        {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        }
      );
    }
  };

  const handleManualKeyIn = () => {
    const manualKeyIn = paymentModes.find(mode => mode.name === PaymentMethods.MANUAL_KEY_IN);
    if (manualKeyIn && !manualKeyIn.enabled) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage:
              'Manual Card Entry is not enabled on your account yet. Please contact our support team to activate it.'
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
      return;
    }
    if (+invoiceData.payableAmount <= +manualKeyInTransactionLimit) {
      setOpenManualKeyInModal(true);
    } else {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: `Manual card entry can't be used for this payment. 
                            It's available only for amounts up to 
                            $${formatDecimal(formatAmount(+manualKeyInTransactionLimit))}. 
                            Please use a different method or contact support for help.`
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
    }
  };

  const handleMarkAsFullyPaid = () => {
    const routeState = { ...FROM_SIDE_PANEL_ROUTE_STATE, routedFromSecondaryPanel: true };
    if (rootInvoiceId) {
      if (invoiceId) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/mark-as-paid/${invoiceId}`, {
          state: routeState
        });
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/mark-as-paid`, {
          state: routeState
        });
      }
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/mark-as-paid/${invoiceId}`, {
        state: routeState
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/mark-as-paid/${invoiceId}`, {
        state: routeState
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/mark-as-paid/${invoiceId}`, {
        state: routeState
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/mark-as-paid/${invoiceId}`, {
        state: routeState
      });
    }
  };

  const downloadFile = async () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    await downloadInvoice({ invoiceId: invoiceData.invoiceId, teamId: currentTeam.id });
  };

  const handleManualKeyInClose = () => {
    setOpenManualKeyInModal(false);
  };

  return (
    <>
      <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
        <div className="h-full w-96 px-[26px] pt-[30px]">
          {fetchingInvoiceData || merchantCustomisationsFetching ? (
            <div className="flex h-full w-full items-center justify-center">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          ) : (
            invoiceDataSuccess &&
            merchantCustomisationsSuccess && (
              <div>
                <div
                  className="flex cursor-pointer items-center gap-1.5 text-17px font-semibold text-primaryText"
                  onClick={handleSidePanelBack}>
                  {isRootState ? <CrossIconvector className="shrink-0" /> : <ArrowRightIcon className="rotate-180" />}
                  Share invoice
                </div>
                <SharePendingInvoiceMainSection
                  invoiceData={invoiceData}
                  handlePayWithQRCodeClick={handlePayWithQRCodeClick}
                  handleMarkAsFullyPaid={handleMarkAsFullyPaid}
                  handleManualKeyIn={handleManualKeyIn}
                  handleSendInvoice={handleInvoiceShareClick}
                  handleDownloadInvoice={downloadFile}
                />
              </div>
            )
          )}
        </div>
      </SidePanel>
      {openManualKeyInModal && (
        <ManualKeyInModal onClose={handleManualKeyInClose} invoiceData={invoiceData} checkoutUrl={invoiceData.url} />
      )}
    </>
  );
};
