import React, { FC } from 'react';

interface TermsSectionProps {
  terms: string;
}

const TermsSection: FC<TermsSectionProps> = ({ terms }) => (
  <div className="rounded-md border border-borderGray bg-white p-10">
    <div className="text-sbase font-semibold uppercase text-headingGray">Terms & Conditions</div>
    <div className="mt-2.5 whitespace-pre-line break-words text-sbase font-normal text-primaryText">{terms}</div>
  </div>
);

export default TermsSection;
