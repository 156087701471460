import { TopBarIcon } from 'assets/icons';
import { FC, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import TestimonialSection from './components/TestimonialSection';
import SignUpSection from './components/SignUpSection';
import { InviteLinkStatuses, SignInMethodEnum, SignUpPages, signInMethods } from './constants';
import {
  useCancelEmailPromptMutation,
  useCreatePasswordMutation,
  useInitiateSignupMutation,
  useSignupMutation,
  useUpdateEmailMutation
} from './api';
import { If } from 'components';
import { AddEmailPromptSection, CreatePasswordSection, DeviceAuthSection } from './components';
import { SignUpPayload, VerifyOTPPayload } from './auth.model';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  loggedOut,
  selectCurrentSignInMethod,
  selectSignUpSearchParams,
  selectTeamInviteTokens,
  setHasPendingEmailSteps,
  setIsPasswordExpired
} from './authSlice';
import ExistingAccountSection from './components/ExistingAccountSection';
import { APIErrorMsg, APIErrorStatuses } from 'services/type';
import { ErrorResponse, VerificationStatusEnum } from 'containers/app/app.model';
import { NotifierTypes } from 'constants/notifierConstants';
import { showNotifier } from 'containers/app/appSlice';
import { useLazyGetUserDetailsQuery } from 'containers/app/api';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsIAM } from './events';
import { EmailErrorMessages } from 'containers/my-account/types';
import { handleSignUpEventLog } from './utils';
import { API_SOURCE } from 'constants/common';

const SignUp: FC = () => {
  const [
    initiateSignUpMutation,
    {
      isSuccess: initiateSignUpSuccess,
      error: initiateSignUpError,
      isError: isInitiateSignUpError,
      isLoading: isInitiateSignUpLoading
    }
  ] = useInitiateSignupMutation();
  const [
    signUpMutation,
    {
      isLoading: isSignUpInProgress,
      error: signUpError,
      isSuccess: signUpSuccess,
      isError: isSignUpError,
      data: signUpData,
      originalArgs: signUpRequest
    }
  ] = useSignupMutation();
  const [createPasswordMutation, { isSuccess: isCreatePasswordSuccess, isLoading: isCreatePasswordLoading }] =
    useCreatePasswordMutation();

  const [
    getUserDetailsQuery,
    { isFetching: isFetchingUserDetails, isSuccess: isUserDetailsSuccess, data: userDetails }
  ] = useLazyGetUserDetailsQuery();

  const [cancelEmailPromptMutation, { isSuccess: isCancelEmailPromptSuccess, isLoading: isCancelEmailPromptLoading }] =
    useCancelEmailPromptMutation();

  const [
    updateEmailMutation,
    {
      isSuccess: isUpdateEmailSuccess,
      isLoading: isUpdateEmailLoading,
      error: updateEmailError,
      isError: isUpdateEmailError
    }
  ] = useUpdateEmailMutation();

  const currentSignInMethod = useAppSelector(selectCurrentSignInMethod);
  const selectedTeamInviteTokens = useAppSelector(selectTeamInviteTokens);
  const selectedSignUpSearchParams = useAppSelector(selectSignUpSearchParams);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [otpError, setOtpError] = useState('');

  useEffect(() => {
    if (initiateSignUpSuccess) {
      setPage(SignUpPages.DEVICE_AUTH);
    }
  }, [initiateSignUpSuccess]);

  useEffect(() => {
    if (isInitiateSignUpError) {
      const initiateSignUpErrorObj = initiateSignUpError as ErrorResponse;
      if (initiateSignUpErrorObj?.data?.details === APIErrorMsg.ACCOUNT_EXISTS) {
        setPage(SignUpPages.ACCOUNT_ALREADY_EXISTS);
      } else {
        showErrorNotifier();
      }
    }
  }, [isInitiateSignUpError]);

  useEffect(() => {
    if (signUpSuccess) {
      getUserDetailsQuery({ isInitCall: true, onSignUpSuccess: true });
      if (signUpData.inviteStatus === InviteLinkStatuses.EXPIRED) {
        showErrorNotifier('Invite link has expired');
        dispatch(loggedOut());
        navigate(routesPath.SIGN_UP_QR);
        logAnalyticEvent(CleverTapEventsIAM.webSignUpExpiredInviteToken, { phoneNumber: signUpRequest.phone }, false);
      }
      const isDigital = !selectedTeamInviteTokens.inviteToken && !selectedTeamInviteTokens.referrerId;
      logAnalyticEvent(CleverTapEventsIAM.webSignUpSuccess, { isDigital, phoneNumber: signUpRequest.phone }, false);
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'OTP verified successfully'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
    }
  }, [signUpSuccess]);

  useEffect(() => {
    if (isCreatePasswordSuccess) {
      dispatch(setIsPasswordExpired(false));
      getUserDetailsQuery({});
    }
  }, [isCreatePasswordSuccess]);

  useEffect(() => {
    if (isUserDetailsSuccess && !isFetchingUserDetails) {
      if (userDetails.verificationStatus === VerificationStatusEnum.Declined) {
        navigate(routesPath.DECLINED);
      } else if (userDetails.verificationStatus === VerificationStatusEnum.Verified) {
        if (!userDetails.isPasswordCreated) {
          setPage(SignUpPages.CREATE_PASSWORD);
        } else if (userDetails.showEmailPrompt) {
          setPage(SignUpPages.ADD_EMAIL_PROMPT);
        }
      } else if (userDetails.verificationStatus === VerificationStatusEnum.NotVerified) {
        navigate(routesPath.DIGITAL_ONBOARDING_NAME);
      } else setPage(SignUpPages.SIGNUP);
    }
  }, [isUserDetailsSuccess, isFetchingUserDetails]);

  useEffect(() => {
    if (isSignUpError) {
      const signUpErrorObj = signUpError as ErrorResponse;
      if (signUpErrorObj?.data?.details === APIErrorMsg.PHONE_NUMBER_NOT_MATCHING) {
        showErrorNotifier(
          'Phone number is not the same as the Invited number, please use the invited phone number',
          NotifierTypes.INFO
        );
        setPage(SignUpPages.SIGNUP);
      }
      if (signUpErrorObj?.data?.code === APIErrorStatuses.STATUS_500) {
        if (signUpErrorObj?.data?.message === APIErrorMsg.INVALID_OTP) {
          setOtpError('Invalid code');
        }
      }
    }
  }, [isSignUpError]);

  useEffect(() => {
    if (isCancelEmailPromptSuccess || isUpdateEmailSuccess) {
      dispatch(setHasPendingEmailSteps(false));
    }
  }, [isCancelEmailPromptLoading, isCancelEmailPromptSuccess, isUpdateEmailSuccess, isUpdateEmailLoading]);

  useEffect(() => {
    if (isUpdateEmailError) {
      const emailErrorResponse = updateEmailError as ErrorResponse;
      const errormsg = (emailErrorResponse?.data?.message as APIErrorMsg) ?? null;
      if (errormsg === APIErrorMsg.EMAIL_CONFLICT) {
        setEmailError(EmailErrorMessages.ALREADY_EXISTS);
      } else {
        setEmailError(null);
      }
    }
  }, [isUpdateEmailError, isUpdateEmailLoading]);

  const [page, setPage] = useState<SignUpPages>(SignUpPages.SIGNUP);
  const [userPhone, setUserPhone] = useState('');
  const [emailError, setEmailError] = useState<EmailErrorMessages>(null);

  const showErrorNotifier = (message = '', type = NotifierTypes.ERROR) => {
    dispatch(
      showNotifier({
        type: type,
        message: {
          primaryMessage: message || 'Something went wrong, please try again after some time',
          isMessageHtml: false
        }
      })
    );
  };

  const initiateSignUp = ({ value }: { value: string }) => {
    initiateSignUpMutation({ phone: value });
    setUserPhone(value);
  };

  const handleSignUpAndAcceptTeamInvite = (payload: VerifyOTPPayload) => {
    const { otp, phone, authenticateDevice } = payload;
    const signUpPayload: SignUpPayload = {
      otp: otp,
      phone,
      authenticateDevice,
      inviteToken: selectedTeamInviteTokens.inviteToken || '',
      referrerId: selectedTeamInviteTokens.referrerId || '',
      source: API_SOURCE.WEB,
      utmMetadata: selectedSignUpSearchParams
    };
    handleSignUpEventLog(selectedSignUpSearchParams);
    signUpMutation(signUpPayload);
  };

  const handleDeviceAuthBackClick = () => {
    setPage(SignUpPages.SIGNUP);
  };

  const handleCreatePassword = password => {
    createPasswordMutation({ password });
  };

  const handleBackFromCreatePassword = () => {
    setPage(SignUpPages.SIGNUP);
  };

  const handleSignUpWithDiffNum = () => {
    setPage(SignUpPages.SIGNUP);
  };

  const handleLogin = () => {
    navigate(routesPath.SIGNIN);
  };

  const handleSkipAddEmail = () => {
    cancelEmailPromptMutation();
    logAnalyticEvent(CleverTapEventsIAM.webSkipEmail, {}, true);
  };

  const handleAddEmail = (email: string) => {
    if (email) {
      updateEmailMutation({ email: email.toLowerCase() });
      logAnalyticEvent(CleverTapEventsIAM.webAddEmail, { email }, true);
    }
  };

  return (
    <div className="flex h-full w-full flex-row">
      <div className="relative flex w-full flex-col gap-16 bg-primary p-12 pt-16 sm:w-1/2 sm:gap-0 sm:pt-12">
        <TopBarIcon className={`shrink-0 ${isMobile && !isTablet ? 'self-center' : 'absolute'}`} />
        <If condition={page === SignUpPages.SIGNUP}>
          <SignUpSection
            isLoading={isInitiateSignUpLoading}
            onContinue={initiateSignUp}
            selectedSignInMethod={signInMethods.phoneNumber}
            handleLogin={handleLogin}
            userPhoneNumber={currentSignInMethod.id === SignInMethodEnum.PHONE_NUMBER ? currentSignInMethod.value : ''}
          />
        </If>

        <If condition={page === SignUpPages.DEVICE_AUTH}>
          <DeviceAuthSection
            selectedSignInMethod={{ id: SignInMethodEnum.PHONE_NUMBER, value: userPhone, label: '' }}
            handleBackClick={handleDeviceAuthBackClick}
            handleContinueClick={handleSignUpAndAcceptTeamInvite}
            isVerifyOTPAPILoading={isSignUpInProgress}
            otpError={otpError}
          />
        </If>

        <If condition={page === SignUpPages.CREATE_PASSWORD}>
          <CreatePasswordSection
            handleBack={handleBackFromCreatePassword}
            handleContinue={handleCreatePassword}
            isCreatePasswordAPILoading={isCreatePasswordLoading}
            oldPasswordMatchError={false}
          />
        </If>

        <If condition={page === SignUpPages.ACCOUNT_ALREADY_EXISTS}>
          <ExistingAccountSection handleSignUpWithDiffNum={handleSignUpWithDiffNum} userPhoneNumber={userPhone} />
        </If>

        <If condition={page === SignUpPages.ADD_EMAIL_PROMPT}>
          <AddEmailPromptSection
            handleSkip={handleSkipAddEmail}
            handleContinue={handleAddEmail}
            updateEmailError={emailError}
            setUpdateEmailError={setEmailError}
          />
        </If>
      </div>
      <div className="hidden h-full w-1/2 sm:flex">
        <TestimonialSection />
      </div>
    </div>
  );
};

export default SignUp;
