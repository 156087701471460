import { selectCurrentTeam, selectCurrentTeamInvoicePermissions } from 'containers/app/appSlice';
import { useGetTaxesAndFeesQuery } from 'containers/invoices/api';
import BuyersPreview from 'containers/invoices/buyers-preview/BuyersPreview';
import CreateInvoice from 'containers/invoices/create-invoice/CreateInvoice';
import { useLazyGetTeamProfileDataDataQuery } from 'containers/team-profile/api';

import { useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';

enum CreatePageState {
  CREATE = 'CREATE_INVOICE',
  PREVIEW = 'INVOICE_PREVIEW'
}

const CreateInvoicePage: FC = () => {
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);

  const [createPageState, setCreatePageState] = useState<CreatePageState>(CreatePageState.CREATE);

  const currentTeam = useAppSelector(selectCurrentTeam);

  const handlePreviewClick = () => {
    setCreatePageState(CreatePageState.PREVIEW);
  };

  const handleClose = () => {
    setCreatePageState(CreatePageState.CREATE);
  };

  const [getTeamProfileDataDataQuery, getTeamProfileDataDataQueryState] = useLazyGetTeamProfileDataDataQuery();

  useEffect(() => {
    if (currentTeam?.id) {
      getTeamProfileDataDataQuery({ teamId: currentTeam?.id.toString() });
    }
  }, [currentTeam]);

  const { data: taxesAndFeesData, isLoading: isLoadingTaxesAndFees } = useGetTaxesAndFeesQuery({
    teamId: currentTeam?.id
  });

  const {
    data: getTeamProfileData,
    isSuccess: getTeamDataSuccess,
    isFetching: isFetchingTeamProfileData
  } = getTeamProfileDataDataQueryState;

  return invoicePermissions.createInvoice ? (
    <>
      {createPageState === CreatePageState.CREATE && (
        <CreateInvoice
          onPreviewClick={handlePreviewClick}
          taxesAndFeesData={taxesAndFeesData}
          isLoadingTaxesAndFees={isLoadingTaxesAndFees}
        />
      )}
      {createPageState === CreatePageState.PREVIEW && (
        <BuyersPreview getTeamProfileData={getTeamProfileData} goBack={handleClose} taxesAndFees={taxesAndFeesData} />
      )}
    </>
  ) : (
    <Navigate to={routesPath.HOME} replace />
  );
};

export default CreateInvoicePage;
