/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-empty-function */
// to be removed
import { BinIcon, GreenTickIcon, PdfGreyIcon, PrinterIcon } from 'assets/icons';
import { FC } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { VoidFn } from 'types/baseTypes';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsInvoices } from '../events';

import { useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';

interface MoreMenuProps {
  type: string;
  onExportPdf: VoidFn;
  onPrintPdf: VoidFn;
  isModifiable: boolean;
}

const MoreMenu: FC<MoreMenuProps> = props => {
  const { type, onExportPdf, onPrintPdf, isModifiable } = props;

  const navigate = useNavigate();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { rootInvoiceId, invoiceId: invoiceIdFromUrl, rootCustomerId, rootPage, rootReceiptId } = useParams();

  const handleMarkAsPaidRoute = () => {
    if (rootInvoiceId) {
      if (invoiceIdFromUrl) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/mark-as-paid/${invoiceIdFromUrl}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/mark-as-paid`, { state: FROM_SIDE_PANEL_ROUTE_STATE });
      }
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/mark-as-paid/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/mark-as-paid/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/mark-as-paid/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const handleDeleteInvoice = () => {
    if (rootInvoiceId) {
      if (invoiceIdFromUrl) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/delete-invoice/${invoiceIdFromUrl}`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/delete-invoice`, {
          state: FROM_SIDE_PANEL_ROUTE_STATE
        });
      }
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/delete-invoice/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/delete-invoice/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/delete-invoice/${invoiceIdFromUrl}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const menuItems = [
    ...(type === 'unpaid'
      ? [
          {
            id: 'm_1',
            icon: <GreenTickIcon className="w-5 shrink-0" />,
            text: 'Mark as paid',
            onClick: () => {
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailMoreMarkAsPaid);
              if (isModifiable) handleMarkAsPaidRoute();
            }
          }
        ]
      : []),
    {
      id: 'm_2',
      icon: <PdfGreyIcon className="w-5 shrink-0" />,
      text: 'Export PDF',
      onClick: onExportPdf
    },
    {
      id: 'm_3',
      icon: <PrinterIcon className="w-5 shrink-0" />,
      text: 'Print',
      onClick: onPrintPdf
    },
    ...(type === 'unpaid'
      ? [
          {
            id: 'm_4',
            icon: <BinIcon className="w-5 shrink-0" />,
            text: 'Delete',
            onClick: () => {
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailMoreDelete);
              if (isModifiable) handleDeleteInvoice();
            }
          }
        ]
      : [])
  ];

  const isActionAllowed = (actionId: string) => {
    return isModifiable || actionId === 'm_2' || actionId === 'm_3';
  };
  return (
    <div className="flex flex-col space-y-4 text-sbase font-semibold text-primaryText">
      {menuItems.map(menu => (
        <div
          onClick={menu.onClick}
          className={`flex flex-row items-center gap-2 ${
            isActionAllowed(menu.id) ? '' : 'cursor-not-allowed opacity-50'
          }`}
          key={menu.id}>
          {menu.icon}
          <div>{menu.text}</div>
        </div>
      ))}
    </div>
  );
};

export default MoreMenu;
