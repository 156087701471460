import {
  selectCurrentTeam,
  selectCurrentTeamInvoicePermissions,
  selectPaymentSystemConfig
} from 'containers/app/appSlice';
import { useLazyGetPayoutsQuery } from '../api';
import { ConnectPayout } from '../payouts.model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FileTypes } from 'types/baseTypes';
import { useAppSelector } from '../../../hooks/typedHooks';
import { isPermissionDenied } from 'utils/apiUtils';
import { ConnectPayoutStatus, PaymentSystem } from '../constants';

export interface PayoutsQueryParams {
  status: ConnectPayoutStatus[];
  fromTimestamp: string;
  toTimestamp: string;
  search: string;
  type: FileTypes;
  amount: string;
}

export const initialQueryParamsState = {
  status: [],
  fromTimestamp: '',
  toTimestamp: '',
  search: null,
  type: null,
  amount: null
};

export const usePaginatedGetPayoutsQuery = () => {
  const [list, setList] = useState<ConnectPayout[]>([]);
  const [queryParams, setQueryParams] = useState<PayoutsQueryParams>(initialQueryParamsState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);
  const page = useRef(0);
  const [getPayoutsData, payoutsState] = useLazyGetPayoutsQuery();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig) ?? {};
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);

  const isCA = paymentSystem === PaymentSystem.CA;

  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!payoutsState?.isFetching && payoutsState?.isSuccess) {
      setHasPermissionsToView(true);
      const payouts = payoutsState?.data?.data ?? [];
      const updatedList = isInitListCall ? payouts : [...list, ...payouts];
      const payoutsLeftToFetch = payoutsState?.data?.metadata?.total
        ? +payoutsState?.data?.metadata?.total - updatedList.length
        : 0;
      if (payoutsLeftToFetch > 0 && !payoutsState?.data?.data && payoutsState?.data?.data?.length === 0) {
        setHasMore(false);
      } else {
        setHasMore(payoutsLeftToFetch > 0);
      }
      setList(updatedList);
      if (page.current === 1) {
        setTotalListCount(payoutsState?.data?.metadata?.total ?? 0);
      }
      setIsLoading(false);
    }
  }, [payoutsState?.isFetching]);

  useEffect(() => {
    if (!payoutsState?.isFetching && payoutsState?.isError) {
      setIsLoading(false);
      setHasMore(false);
      if (isPermissionDenied(payoutsState.error?.['data']?.code, payoutsState.error?.['data']?.message)) {
        setHasPermissionsToView(false);
      }
    }
  }, [payoutsState?.isError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setList([]);
    if (isCA && !payoutPermissions.viewPayoutsByTeam) {
      setHasPermissionsToView(false);
    } else {
      loadMore();
    }
  };

  useEffect(() => {
    resetListAndLoadMore();
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    if (currentTeam?.id) {
      try {
        getPayoutsData({
          teamId: currentTeam?.id,
          params: {
            limit: 10,
            page: page.current,
            ...queryParams,
            selectedIds: []
          }
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetQuery = () => {
    resetListAndLoadMore();
  };

  return {
    isLoading: isLoading,
    isFetching: payoutsState?.isFetching,
    totalListCount,
    list,
    isError,
    loadMore,
    resetQuery,
    hasMore,
    setQueryParams,
    queryParams,
    isLoadingSuccess: payoutsState.isSuccess,
    hasPermissionsToView
  };
};
