import {
  AccountMenuIcon,
  CircleCloseIcon,
  HamburgerIcon,
  HelpMenuIcon,
  LogoutModalIcon,
  NotificationsMenuIcon,
  TopBarIcon,
  UnreadNotificationIcon
} from 'assets/icons';
import { CustomPopover, GenericModal } from 'components';
import { baseNavTabs } from 'constants/navConstants';
import { selectUserDetails, toggleMenuState } from 'containers/app/appSlice';
import { FC, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { findActiveSubTab, findActiveTab } from 'utils/commonUtils';
import AccountMenu from './components/AccountMenu';
import HelpMenu from './components/HelpMenu';
import { NotificationsDrawer } from 'containers/index';
import { useLogoutMutation } from 'containers/auth/api';
import { useAppSelector } from 'hooks';
import { selectAuthState } from 'containers/auth/authSlice';
import { FirebaseStorage } from 'services/firebase';
import { FirebaseNotification } from 'types/notification';
import routesPath from 'routes/RoutesPath';
import { AccountSettingsContext, IAccountSettingsContext } from 'containers/my-account/MyAccount';
import { clevertapEvents } from 'types/baseTypes';
import { logAnalyticEvent } from 'utils/analytics';
import { openScanpayFAQInNewTab } from 'utils/urlUtils';

interface TopBarProps {
  className?: string;
  isOverlayedSideNavLayout?: boolean;
}

interface MenuState {
  showAccountMenu: boolean;
  showHelpMenu: boolean;
}

// TODO Keep active tab state in app slice
const TopBar: FC<TopBarProps> = ({ className, isOverlayedSideNavLayout }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { setShowOverlayedSideNav } = useContext(AccountSettingsContext) as IAccountSettingsContext;
  const [logout, { isLoading: isLogoutInProgress, isSuccess: isLogoutSuccess, isError: logoutError }] =
    useLogoutMutation();
  const authState = useAppSelector(selectAuthState);
  const [showMenuState, setShowMenuState] = useState<MenuState>({
    showAccountMenu: false,
    showHelpMenu: false
  });
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(findActiveTab(location?.pathname) ?? baseNavTabs[0]);
  const [isNotificationDrawerOpen, setIsNotificationDrawerOpen] = useState(false);
  const [notificationsData, setNotificationsData] = useState<FirebaseNotification[]>([]);
  const [teamInviteData, setTeamInviteData] = useState([]);

  const currentUser = useAppSelector(selectUserDetails);

  useEffect(() => {
    if (isLogoutSuccess) {
      navigate(routesPath.SIGNIN);
    }
  }, [isLogoutInProgress, isLogoutSuccess]);

  useEffect(() => {
    const currentTab = findActiveSubTab(location?.pathname) ?? findActiveTab(location?.pathname) ?? baseNavTabs[0];
    setActiveTab(currentTab);
  }, [location]);

  useEffect(() => {
    if (logoutError) {
      setShowLogoutModal(false);
    }
  }, [logoutError]);

  const onResize = () => {
    dispatch(toggleMenuState());
  };

  const onHamburgerMenuClick = () => {
    if (isOverlayedSideNavLayout) {
      setShowOverlayedSideNav(prev => !prev);
      return;
    }
    onResize();
  };

  // eslint-disable-next-line react/no-multi-comp
  const AccountMenuAnchor: React.FC<{ isPanelOpen: boolean }> = () => {
    return <AccountMenuIcon id="top-bar-account-action-user-icon" className="shrink-0 cursor-pointer" />;
  };

  // eslint-disable-next-line react/no-multi-comp
  const HelpMenuAnchor: React.FC<{ isPanelOpen: boolean }> = () => {
    return <HelpMenuIcon id="top-bar-help-icon" className="shrink-0 cursor-pointer" />;
  };

  const onLogout = () => {
    onCloseAccountMenu();
    setShowLogoutModal(true);
  };

  const onToggleAccountMenu = () => {
    setShowMenuState((state: MenuState) => {
      return { ...state, showAccountMenu: !state.showAccountMenu };
    });
  };

  const onToggleHelpMenu = () => {
    setShowMenuState((state: MenuState) => {
      return { ...state, showHelpMenu: !state.showHelpMenu };
    });
  };

  const onCloseAccountMenu = () => {
    setShowMenuState((state: MenuState) => {
      return { ...state, showAccountMenu: false };
    });
  };

  const onCloseHelpMenu = () => {
    setShowMenuState((state: MenuState) => {
      return { ...state, showHelpMenu: false };
    });
  };

  const onConfirmLogout = () => {
    logout({ idToken: authState?.idToken, refreshToken: authState?.refreshToken, userName: currentUser.name });
  };

  const onCancelLogout = () => {
    setShowLogoutModal(false);
  };

  useEffect(() => {
    if (currentUser?.id) {
      FirebaseStorage.getUnreadNotifsByMerchant(currentUser.id, setNotificationsData);
      FirebaseStorage.getTeamInvitesByMerchant(currentUser.phone, setTeamInviteData);
    }
  }, [currentUser]);

  const onMyAccountClick = () => {
    navigate(routesPath.ACCOUNT_SETTINGS_MY_ACCOUNT);
  };

  const onFAQClick = () => {
    openScanpayFAQInNewTab(clevertapEvents.webTopBarFaqClick);
  };

  const onChatWithUsClick = () => {
    window.fcWidget.open();
    logAnalyticEvent(clevertapEvents.webTopBarChatClick);
    onCloseHelpMenu();
  };

  return (
    <div
      className={`${className} space-between flex min-h-[60px] w-full flex-row items-center justify-between  
      border-b border-borderGray bg-primary px-7`}>
      <div className="flex flex-row items-center gap-2">
        <HamburgerIcon
          onClick={onHamburgerMenuClick}
          className="path-fill-current shrink-0 cursor-pointer text-accentText"
        />
        <div className="text-base font-semibold leading-5 text-primaryText">{activeTab.title}</div>
      </div>
      <TopBarIcon className="screen-width-50 absolute shrink-0" />
      <div className="flex flex-row items-center justify-center gap-[29px]">
        <CustomPopover
          arrowOffset="right-6"
          onTogglePopover={onToggleHelpMenu}
          onClosePopover={onCloseHelpMenu}
          anchorComponent={HelpMenuAnchor}
          show={showMenuState.showHelpMenu}
          showArrow={true}>
          {<HelpMenu onChat={onChatWithUsClick} onFAQ={onFAQClick} />}
        </CustomPopover>

        {notificationsData.length > 0 || teamInviteData.length > 0 ? (
          <UnreadNotificationIcon
            id="top-bar-unread-notifications-icon"
            onClick={() => setIsNotificationDrawerOpen(true)}
            className="shrink-0 cursor-pointer"
          />
        ) : (
          <NotificationsMenuIcon
            id="top-bar-notifications-icon"
            onClick={() => setIsNotificationDrawerOpen(true)}
            className="shrink-0 cursor-pointer"
          />
        )}

        <CustomPopover
          arrowOffset="right-6"
          onTogglePopover={onToggleAccountMenu}
          onClosePopover={onCloseAccountMenu}
          anchorComponent={AccountMenuAnchor}
          show={showMenuState.showAccountMenu}
          showArrow={true}>
          {<AccountMenu onMyAccountClick={onMyAccountClick} onLogout={onLogout} />}
        </CustomPopover>
      </div>
      <NotificationsDrawer isOpen={isNotificationDrawerOpen} setIsOpen={setIsNotificationDrawerOpen} />

      <GenericModal
        primaryBtnId="topbar-confirm-logout-yes-button"
        secondaryBtnId="topbar-confirm-logout-cancel-button"
        showModal={showLogoutModal}
        CloseIcon={CircleCloseIcon}
        MainIcon={LogoutModalIcon}
        isActionInProgress={isLogoutInProgress}
        onPrimaryBtnClick={onConfirmLogout}
        onSecondaryBtnClick={onCancelLogout}
        title="Are you sure, you want to logout?"
        subTitle="You will be returned to the login screen"
        primaryBtnLabel="Yes"
        secondaryBtnLabel="Cancel"
      />
    </div>
  );
};

export default TopBar;
