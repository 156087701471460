import Divider from '@material-ui/core/Divider';
import {
  AccountMenuIcon,
  CircleCloseIcon,
  HamburgerIcon,
  HelpMenuIcon,
  LogoutModalIcon,
  NavCloseIcon,
  NotificationsMenuIcon
} from 'assets/icons';
import classNames from 'classnames';
import { CustomPopover, GenericModal, If } from 'components';
import { baseNavTabs, getNavSectionList } from 'constants/navConstants';
import { selectCurrentTeam, selectPaymentSystemConfig, selectUserDetails } from 'containers/app/appSlice';
import { useLogoutMutation } from 'containers/auth/api';
import { selectAuthState } from 'containers/auth/authSlice';
import { PaymentSystemTransitionType } from 'containers/home/constants';
import { PaymentSystem } from 'containers/payouts/constants';
import NavSection from 'containers/side-nav/components/NavSection';
import TeamSwitcher from 'containers/side-nav/components/TeamSwitcher';
import AccountMenu from 'containers/top-bar/components/AccountMenu';
import HelpMenu from 'containers/top-bar/components/HelpMenu';
import { useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { twMerge } from 'tailwind-merge';
import { NavTab, clevertapEvents } from 'types/baseTypes';
import { logAnalyticEvent } from 'utils/analytics';
import { findActiveTab } from 'utils/commonUtils';
import { openScanpayFAQInNewTab } from 'utils/urlUtils';

interface BottomBarProps {
  className?: string;
}

interface MenuState {
  showAccountMenu: boolean;
  showHelpMenu: boolean;
}

// TODO Keep active tab state in app slice
// TODO Control nav toggle state from app slice
// TODO Scroll down to selected tab on re-open for better UX
// TODO add touch feedback in mobile screens
// TODO Popover in small screens - help menu tile, share popover menu section between topbar and bottombar
// TODO styling in mobile screens
const BottomBar: FC<BottomBarProps> = ({ className }) => {
  const [showMenuState, setShowMenuState] = useState<MenuState>({
    showAccountMenu: false,
    showHelpMenu: false
  });
  const navigate = useNavigate();
  const location = useLocation();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const authState = useAppSelector(selectAuthState);
  const currentUser = useAppSelector(selectUserDetails);
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig) ?? {};

  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [logout, { isLoading: isLogoutInProgress, isError: logoutError }] = useLogoutMutation();
  const [isNavVisible, setIsNavVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(findActiveTab(location?.pathname) ?? baseNavTabs[0]);

  const navSectionList = getNavSectionList(
    paymentSystem === PaymentSystem.LIP,
    currentTeam.paymentSystemTransitionType === PaymentSystemTransitionType.LIPConnectMigration
  );

  useEffect(() => {
    setActiveTab(findActiveTab(location?.pathname) ?? baseNavTabs[0]);
  }, [location]);

  useEffect(() => {
    if (logoutError) {
      setShowLogoutModal(false);
    }
  }, [logoutError]);

  const onToggleNavigation = () => {
    setIsNavVisible(!isNavVisible);
  };

  const onTabClick = (tab: NavTab) => {
    setActiveTab(tab);
    navigate(tab.path);
    if (tab.clevertapEvent) {
      logAnalyticEvent(tab.clevertapEvent);
    }
  };

  const onSubTabClick = (tab: NavTab) => {
    navigate(tab.path);
    if (tab.clevertapEvent) {
      logAnalyticEvent(tab.clevertapEvent);
    }
  };

  const lastIndex = navSectionList.length - 1;

  // eslint-disable-next-line react/no-multi-comp
  const AccountMenuAnchor: React.FC<{ isPanelOpen: boolean }> = () => {
    return <AccountMenuIcon id="bottom-bar-account-action-user-icon" className="shrink-0" />;
  };

  // eslint-disable-next-line react/no-multi-comp
  const HelpMenuAnchor: React.FC<{ isPanelOpen: boolean }> = () => {
    return <HelpMenuIcon id="bottom-bar-help-icon" className="shrink-0" />;
  };

  const onLogout = () => {
    onCloseAccountMenu();
    setShowLogoutModal(true);
  };

  const onToggleAccountMenu = () => {
    setIsNavVisible(false);
    setShowMenuState((state: MenuState) => {
      return { ...state, showAccountMenu: !state.showAccountMenu };
    });
  };

  const onToggleHelpMenu = () => {
    setIsNavVisible(false);
    setShowMenuState((state: MenuState) => {
      return { ...state, showHelpMenu: !state.showHelpMenu };
    });
  };

  const onCloseAccountMenu = () => {
    setShowMenuState((state: MenuState) => {
      return { ...state, showAccountMenu: false };
    });
  };

  const onCloseHelpMenu = () => {
    setShowMenuState((state: MenuState) => {
      return { ...state, showHelpMenu: false };
    });
  };

  const closeNavigationMenu = () => {
    setIsNavVisible(false);
  };

  const onConfirmLogout = () => {
    logout({ idToken: authState?.idToken, refreshToken: authState?.refreshToken, userName: currentUser.name });
  };

  const onCancelLogout = () => {
    setShowLogoutModal(false);
  };

  const onMyAccountClick = () => {
    navigate(routesPath.ACCOUNT_SETTINGS_MY_ACCOUNT);
  };

  const onFAQClick = () => {
    openScanpayFAQInNewTab(clevertapEvents.webBottomBarFaqClick);
  };

  const onChatWithUsClick = () => {
    window.fcWidget.open();
    logAnalyticEvent(clevertapEvents.webBottomBarChatClick);
    onCloseHelpMenu();
  };

  return (
    <div
      className={`${className} z-30 flex max-h-[70%] flex-col 
       bg-primary shadow-[0px_0px_4px_rgba(9,21,63,0.25)]`}>
      <div
        className={`flex flex-row items-center justify-between bg-primary 
      px-3 pb-[.688rem] pt-[.875rem] `}>
        <div className="flex flex-row gap-2">
          <div className="flex" onClick={onToggleNavigation}>
            <If condition={isNavVisible}>
              <NavCloseIcon />
            </If>
            <If condition={!isNavVisible}>
              <HamburgerIcon className="path-fill-current shrink-0 text-accentText" />
            </If>
          </div>
          <div className="font-semibold text-primaryText">{activeTab.title}</div>
        </div>
        <div className="flex flex-row gap-[.938rem]">
          <TeamSwitcher onStartTeamSwitch={closeNavigationMenu} />
          <CustomPopover
            title="Help"
            showDismissTitle={true}
            offset="-right-[122px] bottom-[3.3rem]"
            margin="mt-0"
            width="w-screen"
            onTogglePopover={onToggleHelpMenu}
            onClosePopover={onCloseHelpMenu}
            anchorComponent={HelpMenuAnchor}
            show={showMenuState.showHelpMenu}>
            {<HelpMenu onChat={onChatWithUsClick} onFAQ={onFAQClick} />}
          </CustomPopover>
          <NotificationsMenuIcon id="bottom-bar-notifications-icon" className="shrink-0" />
          {/* popover in small screens */}
          {/* border-right: none;
              border-left: none;
              border-radius: 0; */}
          <CustomPopover
            title="Personal Settings"
            showDismissTitle={true}
            offset="-right-[12px] bottom-[3.3rem]"
            margin="mt-0"
            width="w-screen"
            onTogglePopover={onToggleAccountMenu}
            onClosePopover={onCloseAccountMenu}
            anchorComponent={AccountMenuAnchor}
            show={showMenuState.showAccountMenu}>
            {<AccountMenu onMyAccountClick={onMyAccountClick} onLogout={onLogout} />}
          </CustomPopover>
        </div>
      </div>

      <If condition={isNavVisible}>
        <Divider variant="middle" className="bg-borderGray" />

        <div className="overflow-y-scroll">
          {navSectionList.map((navList, index) => {
            return (
              <NavSection
                isBottomBar={true}
                className={twMerge(
                  'px-4',
                  classNames({
                    'border-b border-borderGray': index !== lastIndex
                  })
                )}
                key={navList.toString() + navList[0].key}
                activeTab={activeTab}
                navList={navList}
                onNavTabClick={onTabClick}
                onSubTabClick={onSubTabClick}
              />
            );
          })}
        </div>
      </If>

      <GenericModal
        primaryBtnId="bottombar-confirm-logout-yes-button"
        secondaryBtnId="bottombar-confirm-logout-cancel-button"
        showModal={showLogoutModal}
        CloseIcon={CircleCloseIcon}
        MainIcon={LogoutModalIcon}
        isActionInProgress={isLogoutInProgress}
        onPrimaryBtnClick={onConfirmLogout}
        onSecondaryBtnClick={onCancelLogout}
        title="Are you sure, you want to logout?"
        subTitle="You will be returned to the login screen"
        primaryBtnLabel="Yes"
        secondaryBtnLabel="Cancel"
      />
    </div>
  );
};

export default BottomBar;
