import { AddImageIcon } from 'assets/icons';
import { CustomButton, CustomInput, CustomModal, CustomTextArea, FileUpload } from 'components';
import { ButtonType, FormButtonType } from 'components/custom-button/CustomButton';
import { FC, useEffect, useRef, useState } from 'react';
import { useController, useForm } from 'react-hook-form';
import { VoidFn } from 'types/baseTypes';
import { string, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { InvoiceAttachment, selectInvoiceAttachments } from 'containers/invoices/InvoicesSlice';
import { useAppSelector } from 'hooks';
import { useUploadFileMutation } from 'containers/invoices/api';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { ECharLimits } from 'constants/common';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface AddAttachmentModalProps {
  className?: string;
  open: boolean;
  toggleOpen: VoidFn;
  onSaveAttachmentClick: (arg: InvoiceAttachment) => void;
  onModifyAttachmentClick?: (arg: InvoiceAttachment) => void;
  attachmentData: InvoiceAttachment;
  isEdit: boolean;
}

interface AttachmentFormState {
  title: string;
  description: string;
}

const initialFormState = {
  title: '',
  description: ''
};

const lineItemFormSchema = z.object({
  title: string()
    .min(2, { message: 'Please enter a valid title' })
    .max(ECharLimits.INPUT_FIELD_DEFAULT, { message: 'Please enter a shorter title' }),
  description: string().optional()
});

const AddAttachmentModal: FC<AddAttachmentModalProps> = ({
  open,
  toggleOpen,
  onSaveAttachmentClick,
  isEdit,
  attachmentData,
  onModifyAttachmentClick
}) => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [uploadFileMutation, { isSuccess: isUploadSuccess, isLoading: isUploadingImage }] = useUploadFileMutation();
  const [imageFile, setImageFile] = useState();
  const InvoiceAttachmentsState = useAppSelector(selectInvoiceAttachments);
  const [imagePreview, setImagePreview] = useState<string>();
  const [hasClosedImagePreview, setHasClosedImagePreview] = useState<boolean>(false);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const currentImageUploadCall = useRef(null);

  const { control, handleSubmit, reset, formState } = useForm<AttachmentFormState>({
    defaultValues: {
      title: '',
      description: ''
    },
    resolver: zodResolver(lineItemFormSchema)
  });

  const { errors, dirtyFields } = formState;
  const hasModifiedFields = Object.keys(dirtyFields).length;

  const { field: titleField } = useController({ name: 'title', control });
  const { field: descriptionField } = useController({ name: 'description', control });

  useEffect(() => {
    if (isEdit) {
      setImagePreview(attachmentData?.imagePreview);
      reset({
        title: attachmentData?.title,
        description: attachmentData?.description
      });
    }
  }, [attachmentData]);

  const handleSave = async (attachmentFormData: AttachmentFormState) => {
    if (!imagePreview) {
      return;
    }
    // Get new image url only if the user doesn't change the image he is editing
    if (isEdit) {
      const imageUrlResponse = hasClosedImagePreview ? await getImageURL() : { imageUrl: null };
      onModifyAttachmentClick({
        ...attachmentFormData,
        imagePreview: imagePreview,
        url: imageUrlResponse.imageUrl ?? attachmentData.url,
        id: attachmentData.id
      });

      onResetState();
      return;
    }

    const imageUrlResponse = await getImageURL();

    onSaveAttachmentClick({
      ...attachmentFormData,
      // id gets assigned in reducer fn
      imagePreview: imagePreview,
      url: imageUrlResponse.imageUrl,
      id: null
    });

    onResetState();
  };

  const handleTitleChange = (titleText: string) => {
    titleField.onChange(titleText);
  };

  const handleDescriptionChange = (additionalDetailsText: number) => {
    descriptionField.onChange(additionalDetailsText);
  };

  const onResetState = () => {
    if (isUploadingImage) {
      currentImageUploadCall.current.abort();
    }
    setImagePreview(null);
    setImageFile(null);
    setHasClosedImagePreview(false);
    reset(initialFormState);
    toggleOpen();
  };

  const getErrorMsg = error => {
    if (error?.type === 'invalid_type') {
      return 'Enter a valid input';
    }
    return error?.message;
  };

  const getImageURL = async () => {
    try {
      const imageUrlFromS3Call = uploadFileMutation({
        file: imageFile,
        teamId: currentTeam.id
      });
      currentImageUploadCall.current = imageUrlFromS3Call;
      const imageUrlFromS3 = imageUrlFromS3Call.unwrap();
      setIsUploadError(false);
      return imageUrlFromS3;
    } catch (err) {
      setIsUploadError(true);
    }
  };

  const removeImagePreview = () => {
    setHasClosedImagePreview(true);
    setImagePreview(null);
  };

  const isSaveButtonDisabled = () => {
    if (hasClosedImagePreview && imagePreview !== null) {
      return false;
    }
    if (isEdit && !hasModifiedFields) {
      return true;
    }
    if (!imagePreview || isUploadingImage) {
      return true;
    }
    return false;
  };

  const fileLoadEndCallback = ({ file }: { file: File }) => {
    handleTitleChange(file?.name);
  };

  return (
    <div>
      <CustomModal width="md:w-[610px]" open={open} closeModal={onResetState}>
        <form onSubmit={handleSubmit(handleSave)} className="w-full">
          <div className="relative flex w-full flex-col py-8 px-10">
            <FormCloseReactIcon
              id="create-invoice-add-photo-close-icon"
              className="absolute top-2 right-2 shrink-0"
              onClick={onResetState}
            />
            <div className="mb-4 text-xl font-bold text-heading">{isEdit ? 'Edit Photo' : 'Add Photo'}</div>

            <FileUpload
              id="create-invoice-add-attachment-card"
              isUploadInProgress={isUploadingImage}
              prefill={isEdit}
              setFile={setImageFile}
              removePreview={removeImagePreview}
              filePreview={imagePreview}
              setPreview={setImagePreview}
              className="mb-5"
              FileUploadIcon={AddImageIcon}
              supportedFileTypes={{
                'image/jpeg': [],
                'image/png': [],
                'image/webp': []
              }}
              allowImageCompression={true}
              onFileLoadEnd={fileLoadEndCallback}
            />

            <CustomInput
              id="create-invoice-photo-title"
              name="title"
              onChange={handleTitleChange}
              value={titleField.value}
              maxLength={ECharLimits.INPUT_FIELD_DEFAULT}
              autoFocus={true}
              placeholder="Add title"
              className="mb-4"
            />
            <div className="-mt-2 mb-2 text-error">{errors.title?.message}</div>

            <CustomTextArea
              id="create-invoice-photo-description"
              name="description"
              maxLength={1000}
              onChange={handleDescriptionChange}
              value={descriptionField.value}
              placeholder="Additional details"
              className="mb-5"
            />

            <div className="flex flex-row justify-end gap-5">
              <CustomButton
                id="create-invoice-add-attachment-cancel"
                className="w-24"
                type={ButtonType.SECONDARY}
                onClick={onResetState}>
                Cancel
              </CustomButton>
              <CustomButton
                id="create-invoice-add-attachment-save"
                onClick={null}
                formType={FormButtonType.submit}
                className="w-24"
                disabled={isSaveButtonDisabled()}>
                Save
              </CustomButton>
            </div>
          </div>
        </form>
      </CustomModal>
    </div>
  );
};

export default AddAttachmentModal;
