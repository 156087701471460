import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import {
  Attachment,
  CustomerByIdPayload,
  CustomerDetails,
  DiscountSettings,
  EditInvoicePayload,
  GetInvoiceCategoryItemListingResponse,
  GetInvoiceCategoryItemListingsPayload,
  GetInvoicesResponse,
  IInvoiceServiceCharge,
  IInvoiceServiceChargeFromResponse,
  ImageUploadResponse,
  InvoiceByIdPayload,
  InvoiceByIdResponse,
  InvoiceDownloadPayload,
  InvoicePayload,
  InvoiceResponse,
  InvoiceStatus,
  LineItem,
  LineItemTaxFromAPIResponse,
  TaxesAndFeesPayload,
  TaxesAndFeesResponse
} from './invoices.model';
import { FileTypes, ForwardFileType } from 'types/baseTypes';
import { downloadFile, printFile } from 'utils/commonUtils';
import { GetCustomersPayload, GetCustomersResponse, GetMerchantsPayload, Merchant } from './invoice.model';
import { InvoiceSurchargeState, TaxesAndFeesPayloadState, updateInvoiceFeeSettings } from './InvoicesSlice';
import {
  InvoiceAttachmentEditPage,
  InvoiceLineItemEditPage,
  InvoicesEditSliceInitPayload,
  TaxesAndFeesPayloadStateEditPage,
  initialiseInvoiceEditSlice,
  updateInvoiceFeeSettingsEditPage
} from './edit-invoice/invoicesEditSlice';
import { updateInvoiceData } from '../payments-side-panel/sidePanelSlice';
import { LineItemAdditionalTax } from './types';
import { getUrlSearchParams } from 'utils/apiUtils';

//TODO move to invoice model
export interface GetInvoicesPayload {
  teamId: number;
  merchantId: string[];
  customerId: string[];
  status: InvoiceStatus[];
  // TODO change to enum while integrating filters
  paymentMethod: string[];
  tipAmountNE: number;
  start: string;
  end: string;
  search: string;
  type: FileTypes;
  limit: number;
  page: number;
  selectedIds: string[];
}

export interface GetInvoiceSearchSuggestionResponse {
  suggestion: string[];
}

export interface GetInvoiceSearchSuggestionPayload {
  teamId: number;
}

export interface ShareReceiptPayload {
  teamId: string;
  invoiceId: string;
  email: string;
  phoneNumber: string;
  updateCustomer: boolean;
  type: ForwardFileType;
}

export interface MarkPaidPayload {
  teamId: number;
  invoiceId: string;
  paymentMethod: string;
}

export interface DeleteInvoicePayload {
  teamId: number;
  invoiceId: string;
}

export interface DownloadInvoicePayload {
  invoiceId: string;
}

// TODO Move these fns to a different file
const parseLineItemTaxesFromResponse = (taxes: LineItemTaxFromAPIResponse[]): LineItemAdditionalTax[] => {
  if (taxes) {
    const lineItemLevelTaxes: LineItemAdditionalTax[] = taxes.map(tax => {
      return {
        id: tax?.catalogueTaxId,
        name: tax?.name,
        percentage: tax?.percentage,
        amount: +tax?.amount
      };
    });
    return lineItemLevelTaxes;
  }

  return [];
};

const prepareInvoiceLineItems = (lineItemList: LineItem[]) => {
  if (lineItemList === null) {
    return null;
  }
  const invoiceLineItemList: InvoiceLineItemEditPage[] = lineItemList.map((lineItem, index) => {
    return {
      id: index.toString(),
      catalogueLineItemId: lineItem.catalogueLineItemId,
      itemDescription: lineItem.name,
      additionalDetails: lineItem.additionalDetails,
      quantity: lineItem.qty.toString(),
      total: +lineItem.unitPrice * +lineItem.qty,
      unitCost: lineItem.unitPrice,
      taxes: parseLineItemTaxesFromResponse(lineItem?.taxes),
      sku: lineItem?.sku ?? ''
    };
  });

  return invoiceLineItemList;
};

const prepareInvoiceAttachments = (attachmentList: Attachment[]) => {
  if (attachmentList === null) {
    return [];
  }
  const invoiceAttachmentList: InvoiceAttachmentEditPage[] = attachmentList?.map((attachment, index) => {
    return {
      id: index.toString(),
      url: attachment.url,
      title: attachment.title,
      imagePreview: attachment.urlLink,
      description: attachment.description
    };
  });

  return invoiceAttachmentList;
};

const prepareInvoiceServiceCharge = ({
  serviceCharge = {
    id: 0,
    name: '',
    amount: '',
    percentage: '',
    taxes: []
  }
}: {
  serviceCharge: IInvoiceServiceChargeFromResponse;
}): IInvoiceServiceCharge => {
  const invoiceServiceCharge: IInvoiceServiceCharge = {
    id: serviceCharge?.id,
    name: serviceCharge?.name,
    amount: serviceCharge?.amount,
    percentage: serviceCharge?.percentage,
    taxes:
      serviceCharge?.taxes?.map(tax => ({ id: tax?.catalogueTaxId, name: tax?.name, percentage: tax?.percentage })) ??
      []
  };
  return invoiceServiceCharge;
};

const InvoicesBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCategoryItemList: builder.query<GetInvoiceCategoryItemListingResponse, GetInvoiceCategoryItemListingsPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/invoice-listings?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      }
    }),

    getInvoices: builder.query<GetInvoicesResponse, GetInvoicesPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/invoices?`;
        const urlParams = new URLSearchParams();
        Object.keys(params).map(key => {
          if (Array.isArray(params[key])) {
            params[key].map(item => {
              urlParams.append(key, item);
            });
          } else {
            if (params[key] !== null) {
              urlParams.append(key, params[key]);
            }
          }
        });
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch invoices data',
        showToast: true
      }
    }),

    downloadInvoiceList: builder.query<any, GetInvoicesPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/invoices?`;
        const urlParams = new URLSearchParams();
        Object.keys(params).map(key => {
          if (Array.isArray(params[key])) {
            params[key].map(item => {
              urlParams.append(key, item);
            });
          } else {
            if (params[key] !== null) {
              urlParams.append(key, params[key]);
            }
          }
        });
        url = url + urlParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: params.type, fileName: 'invoices' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to download invoice list',
        success: 'Invoices exported successfully!',
        showToast: true
      }
    }),

    downloadInvoiceAsPDF: builder.query<any, InvoiceDownloadPayload>({
      query: ({ invoiceId, teamId }) => {
        const url = `${API_VERSION.V3}/team/${teamId}/merchants/invoices/${invoiceId}/download?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.PDF, fileName: 'invoice' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to download invoice pdf',
        success: 'Invoice exported successfully!',
        showToast: true
      }
    }),

    downloadPrintInvoiceAsPDF: builder.query<any, InvoiceDownloadPayload>({
      query: ({ invoiceId, teamId }) => {
        const url = `${API_VERSION.V3}/team/${teamId}/merchants/invoices/${invoiceId}/download?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await printFile({ response });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to download invoice pdf',
        showToast: true
      }
    }),

    getCustomers: builder.query<GetCustomersResponse, GetCustomersPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.V2}/merchants/customers?teamId=${teamId}`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch customer data'
      }
    }),
    // used in filters
    getAllCustomers: builder.query<GetCustomersResponse, GetCustomersPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.V3}/merchants/customers?teamId=${teamId}`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to fetch customer data'
      }
    }),
    getInvoiceSearchSuggestions: builder.query<GetInvoiceSearchSuggestionResponse, GetInvoiceSearchSuggestionPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.WEB_V1}/invoices/search-suggestion?teamId=${teamId}`,
          method: 'GET'
        };
      }
    }),

    shareInvoiceReceipt: builder.mutation<any, ShareReceiptPayload>({
      query: ({ invoiceId, email, phoneNumber, updateCustomer, teamId, type }) => {
        return {
          url: `${API_VERSION.V1}/merchants/invoices/${invoiceId}/forward`,
          method: 'POST',
          body: { email, phoneNumber, updateCustomer, teamId, type }
        };
      },
      extraOptions: {
        failure: 'Something went wrong, please try again.'
      }
    }),

    markPaid: builder.mutation<any, MarkPaidPayload>({
      query: ({ invoiceId, teamId, paymentMethod }) => {
        return {
          url: `${API_VERSION.V1}/merchants/invoices/${invoiceId}/mark-paid`,
          method: 'POST',
          body: { teamId: teamId.toString(), invoiceId, paymentMethod }
        };
      }
    }),

    deleteInvoice: builder.mutation<any, DeleteInvoicePayload>({
      query: ({ invoiceId, teamId }) => {
        return {
          url: `${API_VERSION.V1}/merchants/teams/${teamId}/invoices/${invoiceId}`,
          method: 'DELETE'
        };
      }
    }),
    getInvoiceById: builder.query<InvoiceByIdResponse, InvoiceByIdPayload>({
      query: params => {
        return {
          // eslint-disable-next-line max-len
          url: `${API_VERSION.V4}/merchants/invoices/${params.invoiceId}?includeTransactions=${
            params.includeTransactions ?? true
          }&teamId=${params.teamId}`,
          method: 'GET'
        };
      },

      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const { isFromEdit = false } = params;

        if (isFromEdit) {
          const initialEditSliceState: InvoicesEditSliceInitPayload = {
            invoiceCustomerEditPage: {
              id: data?.customer?.id,
              ID: data?.customer?.ID,
              name: data?.customer?.name,
              phoneNumber: data?.customer?.phoneNumber,
              email: data?.customer?.email,
              merchantId: data?.customer?.merchantId,
              billingAddress: data?.customer?.billingAddress,
              lastName: data?.customer?.lastName,
              street: data?.customer?.street,
              cityStateCountry: data?.customer?.cityStateCountry,
              zipCode: data?.customer?.zipCode
            },
            additionalTaxesEditPage: null,
            additionalTaxEnabledEditPage: null,
            discountSettingsEditPage: { enabled: null },
            invoiceLineItemsEditPage: prepareInvoiceLineItems(data?.lineItems),
            // use surcharge from  taxes and fees api, don't pre-populate
            currentSurchargeEditPage: {
              percentageWithBps: null,
              valueInCurrencyWithBps: null,
              percentage: null,
              enabled: null,
              basePoints: null,
              valueInCurrency: null,
              config: null
            },
            lineItemsTotalEditPage: null,
            invoiceSubTotalEditPage: null,
            lineItemsStateCountEditPage: data?.lineItems?.length,

            invoiceAttachmentIdCountEditPage: data?.attachments?.length,
            discountFormDataEditPage: {
              discountPercent: data?.discountPercentage,
              discountAmount: data?.discount,
              isDiscountAmountSelected: data?.isDiscountAmountSelected,
              discountInCurrency: data?.discount,
              amountAfterDiscount: null
            },
            serviceCharge: prepareInvoiceServiceCharge({ serviceCharge: data?.serviceCharges?.[0] }),
            commentEditPage: data?.comment,
            selectedInvoiceAttachmentsEditPage: prepareInvoiceAttachments(data?.attachments),
            tipEnabledEditPage: data?.hasTip,
            invoiceEditedResponse: null,
            finalAmountEditPage: null,
            invoiceOptionsEditPage: {
              createdAt: data?.createdAt,
              dueDate: data?.dueDate
            },
            invoiceNumber: data?.invoiceNumber,
            isSignatureEnabled: data?.isSignatureEnabled,
            isServiceFeeEnabled: data?.isServiceFeeEnabled
          };
          dispatch(initialiseInvoiceEditSlice(initialEditSliceState));
        }
        dispatch(updateInvoiceData(data));
      },
      extraOptions: {
        failure: 'Something went wrong'
      }
    }),
    getCustomerById: builder.query<CustomerDetails, CustomerByIdPayload>({
      query: params => {
        const { customerId, teamId } = params;
        return {
          url: `${API_VERSION.WEB_V1}/customers/${customerId}?teamId=${teamId}`,
          method: 'GET'
        };
      },

      extraOptions: {
        failure: 'Failed to fetch customer data'
      }
    }),

    getTaxesAndFees: builder.query<TaxesAndFeesResponse, TaxesAndFeesPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.WEB_V1}/teams/taxes-and-fees?teamId=${teamId}`,
          method: 'GET'
        };
      },

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const surchargeState: InvoiceSurchargeState = {
          percentage: data?.surcharge?.percentage,
          valueInCurrency: null,
          percentageWithBps: null,
          valueInCurrencyWithBps: null,
          enabled: data?.surcharge?.enabled,
          basePoints: data?.surcharge?.basePoints,
          config: data?.surcharge?.config
        };
        const discountSettings: DiscountSettings = { enabled: data?.discount.enabled };

        dispatch(
          updateInvoiceFeeSettings({
            surchargeState,
            discountSettings
          } as TaxesAndFeesPayloadState)
        );
        dispatch(
          updateInvoiceFeeSettingsEditPage({
            surchargeState,
            discountSettings
          } as TaxesAndFeesPayloadStateEditPage)
        );
      },
      extraOptions: {
        failure: 'Failed to get taxes and fees'
      }
    }),
    // TODO: Define types for this query
    getInvoicePDFPreview: builder.query<any, any>({
      query: payload => {
        const url = `${API_VERSION.V1}/merchants/invoices/preview/pdf`;
        return {
          url: url,
          method: 'POST',
          body: payload,
          responseHandler: async response => {
            const blob = await response.blob();
            return blob;
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to download invoice preview pdf',
        showToast: true
      }
    }),

    // TODO define payload type
    uploadFile: builder.mutation<ImageUploadResponse, { file: any; teamId: number }>({
      query: ({ teamId, file }) => {
        const formData = new FormData();
        formData.append('teamId', teamId.toString());
        formData.append('image', file);

        return {
          url: `${API_VERSION.V2}/merchants/invoices/upload-images`,
          method: 'POST',
          body: formData,
          headers: {
            // make sure not to set the Content-Type header.
            // The browser will set it for you, including the boundary parameter.
            // 'Content-Type': 'multipart/form-data'
          }
        };
      },

      extraOptions: {
        failure: 'We are unable to upload your image. Try again after sometime.',
        showToast: true
      }
    }),

    createInvoice: builder.mutation<InvoiceResponse, InvoicePayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V6}/merchants/invoices`,
          method: 'POST',
          body: payload
        };
      },
      extraOptions: {
        failure: 'Failed to create invoice',
        showToast: false,
        success: 'Invoice created successfully'
      }
    }),

    editInvoice: builder.mutation<InvoiceResponse, EditInvoicePayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V3}/merchants/invoices/${payload.invoiceId}`,
          method: 'PUT',
          body: payload.invoicePayload
        };
      },
      extraOptions: {
        failure: 'Failed to update invoice',
        showToast: false,
        success: 'Invoice edited successfully'
      }
    }),
    getMerchantsByTeamIdInvoice: builder.query<Merchant[], GetMerchantsPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.WEB_V1}/invoices/filters/team-members?teamId=${teamId}`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to merchant tile data'
      }
    })
  })
});

export const {
  useCreateInvoiceMutation,
  useEditInvoiceMutation,
  useUploadFileMutation,
  useGetTaxesAndFeesQuery,
  useLazyDownloadInvoiceListQuery,
  useLazyDownloadPrintInvoiceAsPDFQuery,
  useLazyDownloadInvoiceAsPDFQuery,
  useLazyGetInvoicesQuery,
  useLazyGetInvoiceByIdQuery,
  useLazyGetCustomerByIdQuery,
  useGetInvoiceSearchSuggestionsQuery,
  useShareInvoiceReceiptMutation,
  useMarkPaidMutation,
  useDeleteInvoiceMutation,
  useLazyGetCustomersQuery,
  useLazyGetInvoicePDFPreviewQuery,
  useLazyGetMerchantsByTeamIdInvoiceQuery,
  useLazyGetAllCustomersQuery,
  useLazyGetCategoryItemListQuery
} = InvoicesBaseApi;
