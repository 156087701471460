import React, { FC, useEffect, useState } from 'react';
import { CircularStepper, CustomButton, CustomInput } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getCurrentData, getUserData, setUserData, updateCurrentData } from '../digitalOnboardingSlice';
import routesPath from 'routes/RoutesPath';
import { isValidEmail } from 'utils/stringUtils';
import { useUpdateBasicDetailsMutation } from '../api';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useNavigate } from 'react-router-dom';
import { APIErrorMsg, APIErrorStatuses } from 'services/type';
import { ErrorResponse } from 'containers/app/app.model';
import DuplicatEmailModal from './DuplicateEmailModal';
import { constructBasicDetails } from '../utils';

const EmailSection: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const userData = useAppSelector(getUserData);
  const currentData = useAppSelector(getCurrentData);
  const [updateBasicDetailsMutation, updateBasicDetailsState] = useUpdateBasicDetailsMutation();
  const {
    isSuccess: isUpdateBasicDetailsSuccess,
    isError: isUpdateEmailError,
    error: updateEmailError,
    isLoading: isUpdateEmailLoading
  } = updateBasicDetailsState;
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { email } = currentData;

  const [emailError, setEmailError] = useState('');
  const [showDuplicateEmailPopup, setShowDuplicateEmailPopup] = useState<boolean>(false);

  useEffect(() => {
    if (isUpdateBasicDetailsSuccess) navigate(routesPath.DIGITAL_ONBOARDING_PROFESSION, { replace: true });
  }, [isUpdateBasicDetailsSuccess]);

  useEffect(() => {
    if (isUpdateEmailError && updateEmailError) {
      const updateEmailErrorObj = updateEmailError as ErrorResponse;
      if (
        updateEmailErrorObj.data?.code === APIErrorStatuses.STATUS_403 &&
        updateEmailErrorObj.data?.message === APIErrorMsg.EMAIL_CONFLICT
      ) {
        setShowDuplicateEmailPopup(true);
      }
    }
  }, [isUpdateEmailError, updateEmailError]);

  const onContinue = () => {
    if (email?.trim() && isValidEmail(email.trim())) {
      const updatedData = {
        ...userData,
        email: email.trim()
      };
      dispatch(setUserData(updatedData));
      updateBasicDetailsMutation({
        payload: constructBasicDetails({
          userData: updatedData,
          teamId: currentTeam.id.toString()
        })
      });
    } else {
      setEmailError('Enter a valid email');
    }
  };

  const onBack = () => {
    navigate(routesPath.DIGITAL_ONBOARDING_NAME, { replace: true });
  };

  const onEmailChange = (value: string) => {
    setEmailError('');
    dispatch(updateCurrentData({ key: 'email', value }));
  };

  return (
    <>
      <div className="flex flex-col self-center lg:min-w-[375px]">
        <CircularStepper currentStep={2} totalSteps={7} />
        <div className="mb-[30px] flex flex-col gap-2.5">
          <div className="w-[90%] text-4xl font-bold leading-[43px] text-headingGray">What’s your work email?</div>
        </div>
        <CustomInput
          id="email"
          onChange={onEmailChange}
          value={email}
          placeholder="Enter email (E.g. joe@theplumber.com)"
          className="px-5 py-4 outline-none placeholder:font-semibold placeholder:tracking-[-0.3px]"
          error={emailError}
        />
        <div className="mt-10 flex flex-col gap-5">
          <CustomButton
            id="continue-sign-up"
            className="h-10 w-full"
            childrenContainerStyles="text-17px tracking-[-0.3px]"
            onClick={onContinue}
            isLoading={isUpdateEmailLoading}
            disabled={!email?.trim()}>
            Continue
          </CustomButton>
          <CustomButton
            id="back-button-sign-up"
            className="h-10 w-full border-none shadow-none hover:bg-primary active:shadow-none"
            type={ButtonType.SECONDARY}
            childrenContainerStyles="text-17px tracking-[-0.3px]"
            onClick={onBack}>
            Back
          </CustomButton>
        </div>
      </div>
      {showDuplicateEmailPopup && (
        <DuplicatEmailModal
          handleClose={() => {
            setShowDuplicateEmailPopup(false);
          }}
        />
      )}
    </>
  );
};

export default EmailSection;
