import {
  ApplicationDeniedIcon,
  EqualToIcon,
  GreaterThanIcon,
  InBetweenIcon,
  LessThanIcon,
  PayoutActionNeededIcon,
  PayoutHalfProgressIcon,
  PayoutInitialIcon,
  PayoutQuarterProgressIcon,
  ScheduledIcon,
  TickWithBlueBorderIcon,
  TxnFailedIcon,
  TxnInTransitIcon,
  TxnPausedIcon,
  TxnPendingIcon,
  TxnSuccessIcon,
  TxnTimedOutIcon
} from 'assets/icons';
import { ConnectPayoutStatus, EnablePayoutStatuses, PAYOUT_TXNS_AMOUNT_FILTERS, PaymentSystem } from './constants';
import DateUtils from 'utils/dateUtils';

export function formatStatus(status) {
  const words = status.toLowerCase().split('_');
  const formattedWords = [words[0].charAt(0).toUpperCase() + words[0].slice(1), ...words.slice(1)];
  const formattedStatus = formattedWords.join(' ');

  return formattedStatus;
}

export function getPayoutStatusData({ payoutStatus }: { payoutStatus: string }) {
  switch (payoutStatus) {
    case ConnectPayoutStatus.INITIATED:
      return {
        Icon: TxnPendingIcon,
        textColor: '#4F4F4F',
        text: 'Initiated'
      };
    case ConnectPayoutStatus.IN_TRANSIT:
      return {
        Icon: TxnInTransitIcon,
        textColor: '#3876BB',
        text: 'In transit'
      };
    case ConnectPayoutStatus.SUCCESS:
      return {
        Icon: TxnSuccessIcon,
        textColor: '#419364',
        text: 'Success'
      };
    case ConnectPayoutStatus.FAILED:
      return {
        Icon: TxnFailedIcon,
        textColor: '#D95945',
        text: 'Failed'
      };
    case ConnectPayoutStatus.CANCELLED:
      return {
        Icon: TxnFailedIcon,
        textColor: '#D95945',
        text: 'Cancelled'
      };
    case ConnectPayoutStatus.RETRYING:
      return {
        Icon: TxnTimedOutIcon,
        textColor: '#800000',
        text: 'Retry in progress'
      };
    case ConnectPayoutStatus.PAUSED:
      return {
        Icon: TxnPausedIcon,
        textColor: '#F2994A',
        text: 'Paused'
      };
    case ConnectPayoutStatus.SCHEDULED:
      return {
        Icon: ScheduledIcon,
        textColor: '#F2994A',
        text: 'Paused'
      };
    default:
      return {
        Icon: TxnPendingIcon,
        textColor: '#4F4F4F',
        text: formatStatus(payoutStatus)
      };
  }
}

export const getSubText = ({
  payoutStatus,
  arrivalAt,
  createdAt,
  updatedAt,
  isLIP
}: {
  payoutStatus: string;
  arrivalAt: string;
  createdAt: string;
  updatedAt: string;
  isLIP: boolean;
}) => {
  switch (payoutStatus) {
    case ConnectPayoutStatus.SUCCESS:
      if (isLIP) return `Completed by ${arrivalAt}`;
      if (arrivalAt === DateUtils.getToday('dd MMM yyyy')) {
        return `Expected today, ${arrivalAt}`;
      } else if (arrivalAt === DateUtils.getTomorrow('dd MMM yyyy')) return `Expected by tomorrow, ${arrivalAt}`;
      return `Completed by ${arrivalAt}`;

    case ConnectPayoutStatus.INITIATED:
    case ConnectPayoutStatus.IN_TRANSIT:
      if (arrivalAt === DateUtils.getToday('dd MMM yyyy')) {
        return `Expected today, ${arrivalAt}`;
      } else if (arrivalAt === DateUtils.getTomorrow('dd MMM yyyy')) return `Expected by tomorrow, ${arrivalAt}`;
      return `Expected by ${arrivalAt}`;

    case ConnectPayoutStatus.FAILED:
    case ConnectPayoutStatus.CANCELLED:
    case ConnectPayoutStatus.RETRYING:
    case ConnectPayoutStatus.PAUSED:
      return `Updated on ${updatedAt}`;

    case ConnectPayoutStatus.SCHEDULED:
      return createdAt;

    default:
      return createdAt;
  }
};

export const checkPermissions = ({
  paymentSystem,
  viewPayoutsBySelf,
  viewPayoutsByTeam
}: {
  paymentSystem: PaymentSystem;
  viewPayoutsBySelf: boolean;
  viewPayoutsByTeam: boolean;
}) => {
  if (!viewPayoutsByTeam) return false;
  if (paymentSystem === PaymentSystem.LIP) {
    if (!viewPayoutsBySelf && !viewPayoutsByTeam) return false;
    return true;
  }
  return true;
};

export const getEnablePayoutsIcon = status => {
  switch (status) {
    case EnablePayoutStatuses.NOT_STARTED:
    case EnablePayoutStatuses.STARTED_IN_PROGRESS:
      return PayoutInitialIcon;

    case EnablePayoutStatuses.SUBMITTED_IN_REVIEW:
      return PayoutQuarterProgressIcon;

    case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED:
    case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED:
      return PayoutActionNeededIcon;

    case EnablePayoutStatuses.APPROVED:
    case EnablePayoutStatuses.APPROVED_CONDITIONAL:
      return PayoutHalfProgressIcon;

    case EnablePayoutStatuses.REJECTED:
      return ApplicationDeniedIcon;

    default:
      return PayoutQuarterProgressIcon;
  }
};

export const getAmountFilterIcon = (filterTtile: PAYOUT_TXNS_AMOUNT_FILTERS) => {
  switch (filterTtile) {
    case PAYOUT_TXNS_AMOUNT_FILTERS.ALL:
      return { Icon: TickWithBlueBorderIcon };
    case PAYOUT_TXNS_AMOUNT_FILTERS.EQUAL_TO:
      return { Icon: EqualToIcon };
    case PAYOUT_TXNS_AMOUNT_FILTERS.GREATER_THAN:
      return { Icon: GreaterThanIcon };
    case PAYOUT_TXNS_AMOUNT_FILTERS.IN_BETWEEN:
      return { Icon: InBetweenIcon };
    case PAYOUT_TXNS_AMOUNT_FILTERS.LESS_THAN:
      return { Icon: LessThanIcon };
    default:
      return { Icon: TickWithBlueBorderIcon };
  }
};
