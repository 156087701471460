import { FC } from 'react';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

interface InputWithPrefixProps extends InputProps {
  containerStyles?: string;
  prefixComponent?: JSX.Element;
  prefixString?: string;
  id: string;
  placeholder?: string;
  prefixStyles?: string;
}

//TODO: Check if this component is needed

const InputWithPrefix: FC<InputWithPrefixProps> = ({
  id,
  placeholder,
  containerStyles,
  prefixComponent,
  prefixString,
  value,
  onChange,
  className,
  prefixStyles = '',
  ...rest
}) => (
  <div
    className={twMerge(
      classnames(
        `flex w-full items-center rounded
        bg-secondaryBtn p-4 text-sbase font-semibold leading-[18px] text-primaryText
        placeholder:text-sbase placeholder:font-normal placeholder:text-accentText 
        focus-within:ring-1 focus-within:ring-blue-500`,
        {
          [containerStyles]: !!containerStyles
        }
      )
    )}>
    {prefixComponent ? (
      prefixComponent
    ) : (
      <span
        className={twMerge(
          classnames('mr-1 text-accentText', {
            [prefixStyles]: !!prefixStyles
          })
        )}>
        {prefixString}
      </span>
    )}
    <input
      id={id}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      className={twMerge(classnames('w-11/12 bg-secondaryBtn outline-none', { [className]: !!className }))}
      {...rest}
    />
  </div>
);

export default InputWithPrefix;
