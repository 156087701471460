import Avatar from '@material-ui/core/Avatar';
import { CaretDownIcon } from 'assets/icons';
import { CustomModal, If } from 'components';
import { NotifierTypes } from 'constants/notifierConstants';
import { MerchantTeam, TeamRole } from 'containers/app/app.model';
import {
  disableHoverState,
  initialBannerState,
  selectCurrentTeam,
  selectHoverState,
  selectMenuState,
  selectMerchantTeams,
  setCurrentTeam,
  showNotifier,
  updateBannersData
} from 'containers/app/appSlice';
import { resetInvoiceState } from 'containers/invoices/InvoicesSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { FC, Fragment, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getInitials } from 'utils/commonUtils';
import ListModal from './ListModal';
import { isTablet } from 'react-device-detect';
import { teamMemberRoles } from 'containers/manage-team/edit-permissions/constants';
import { resetPayoutState } from 'containers/payouts/payoutsSlice';

interface TeamSwitcherProps {
  className?: string;
  showDetails?: boolean;
  onStartTeamSwitch?: VoidFn;
  isOverlayedSideNavLayout?: boolean;
}

const TeamSwitcher: FC<TeamSwitcherProps> = ({
  isOverlayedSideNavLayout,
  className,
  showDetails = false,
  onStartTeamSwitch = () => null
}) => {
  const isMenuActive = useAppSelector(selectMenuState);
  const isHoverActive = useAppSelector(selectHoverState);
  const merchantTeams = useAppSelector(selectMerchantTeams);
  const activeTeam = useAppSelector(selectCurrentTeam);
  const dispatch = useAppDispatch();
  const [open, toggleOpen] = useState<boolean>(false);
  const teamName = activeTeam?.name;

  const onTeamSelect = (item: MerchantTeam) => {
    dispatch(resetInvoiceState());
    dispatch(resetPayoutState());
    dispatch(setCurrentTeam(item));
    dispatch(updateBannersData(initialBannerState));
    showTeamSwitchNotifier(item);
    toggleOpen(false);
  };

  const showTeamSwitchNotifier = team => {
    const message = `<div>
        Viewing team <b>'${team?.name}'</b>
      </div>`;
    dispatch(
      showNotifier({
        message: {
          primaryMessage: message,
          secondaryMessage: team?.role,
          iconSrc: team?.profileImageUrl,
          iconInitials: getInitials(team?.name),
          isMessageHtml: true
        },
        type: NotifierTypes.CUSTOM,
        showClose: false,
        bgStyle: 'bg-[#3E556D] rounded-md',
        fontStyle: 'text-primary font-normal',
        shouldAnimate: true
      })
    );
  };

  const initiateTeamSwitch = () => {
    onStartTeamSwitch();
    dispatch(disableHoverState());
    toggleOpen(true);
  };

  const onExitTeamSwitch = () => {
    toggleOpen(false);
  };

  return (
    <Fragment>
      <div className={`${className} z-30 flex cursor-pointer flex-row items-center gap-2`} onClick={initiateTeamSwitch}>
        <Avatar
          onClick={initiateTeamSwitch}
          src={activeTeam?.profileImageUrl}
          className={`h-10 w-10 bg-gradient-to-r from-[#D8DFE5] to-[#A4D7FA] ${open && 'ring-2 ring-secondary '}`}>
          <div className="mt-0.5 text-px13 font-semibold leading-5 text-[#333333]">{getInitials(teamName)}</div>
        </Avatar>

        <If condition={showDetails}>
          <If condition={isMenuActive || (isHoverActive && !isTablet) || isOverlayedSideNavLayout}>
            <div>
              <div className="max-w-[135px] truncate text-sbase font-semibold leading-5 text-[#333333]">
                {activeTeam?.name}
              </div>
              <div className="mt-0.5 text-px13 capitalize text-primaryText">
                {activeTeam?.role === TeamRole.SUPER_ADMIN ? teamMemberRoles[4].name : activeTeam?.role}
              </div>
            </div>
          </If>
          <CaretDownIcon onClick={initiateTeamSwitch} className="ml-auto cursor-pointer" />
        </If>
      </div>

      <CustomModal closeModal={onExitTeamSwitch} open={open} backgroundColor="bg-primary" width="md:min-w-[375px]">
        <ListModal
          onButtonClick={null}
          onItemSelection={onTeamSelect}
          selectedItem={activeTeam}
          title="Switch Teams"
          // buttonLabel="+ Create new team"
          list={merchantTeams}
          onClose={onExitTeamSwitch}
        />
      </CustomModal>
    </Fragment>
  );
};

export default TeamSwitcher;
