import { FC, useContext } from 'react';
import DateUtils from 'utils/dateUtils';
import { InvoiceTabEnum } from '../constants';

interface InvoiceOptionsSectionProps {
  className?: string;
  context: React.Context<any>;
}

const InvoiceSection: FC<InvoiceOptionsSectionProps> = ({ context }) => {
  const { selectedInvoiceTab } = useContext<any>(context);

  const isTabSelected = selectedInvoiceTab === InvoiceTabEnum.INVOICE_OPTIONS;

  const renderDate = () => {
    let date = DateUtils.getToday();
    date = DateUtils.getDateInFormat({ date: new Date(date), dateFormat: 'dd MMM yyyy' });
    return date;
  };
  return (
    <div>
      <div className={`mb-2 text-xl font-bold text-heading ${isTabSelected ? 'text-primaryBtn' : 'text-heading'}`}>
        Invoice Options
      </div>
      <div
        className={`flex flex-row flex-wrap gap-16 rounded-md border py-5 px-[30px] 
        md:gap-52 lg:gap-24 xl:gap-80 1439px:p-10 2xl:gap-56  
        ${isTabSelected ? 'border-primaryBtn' : 'border-borderGray'}`}>
        <div className="flex flex-row gap-5 text-17px">
          {/* create invoice -> current date, edit invoice -> .createdAt */}
          <div className="whitespace-nowrap font-semibold text-primaryText">Invoice Date</div>
          <div className="whitespace-nowrap font-semibold text-accentText">{renderDate()}</div>
        </div>
        <div className="flex flex-row gap-5 text-17px">
          {/* create invoice -> upon receipt, edit invoice -> .dueDate */}
          <div className="whitespace-nowrap font-semibold text-primaryText">Due Date</div>
          <div className="whitespace-nowrap font-semibold text-accentText">{renderDate()}</div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceSection;
