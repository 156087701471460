/* eslint-disable @typescript-eslint/no-empty-interface */
import { Permission, Permissions, TeamRole } from 'containers/app/app.model';

export enum RoleIdEnum {
  OWNER = 1,
  ADMIN = 2,
  MEMBER = 3
}

export interface ITeamMember {
  id: string; //This is only used to differentiate list items
  merchantId: string;
  inviteId: number;
  inviteLink: string;
  roleId: RoleIdEnum | number;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  phone: string;
  role: TeamRole;
  createdAt: string;
  status: TeamMemberStatus;
  isLoggedInMember: boolean;
  profileImageUrl: string;
  isActive: boolean;
  inviteExpiry: string;
  isExpired: boolean;
  activeSince: string;
  inactiveSince: string;
}

export enum TeamMemberStatus {
  ACTIVE = 'ACTIVE',
  INVITED = 'INVITED',
  INACTIVE = 'INACTIVE'
}

export interface IGetTeamMembers {
  id: number;
  teamMembers: ITeamMember[];
  totalCount: number;
}

export interface GetTeamMembersResponse {
  id: number;
  members: ITeamMember[];
  totalMembers: number;
}

export interface GetTeamMembersPayload {
  status: TeamMemberStatus[];
  search: string;
  role: TeamRole[];
  teamId: number;
  limit: number;
  page: number;
  selectedIds: string[];
}

export interface GetTeamMemberPermissions {
  roleId: number;
  teamId: number;
}

export interface GetTeamMemberPermissionsResponse {
  role: TeamRole;
  permissions: Permissions;
}

export interface CreateInvitePayload {
  teamId: string;
  payload: CreateInviteRequest;
}

export interface CreateInviteRequest {
  firstName: string;
  lastname: string;
  phone: string;
  email: string;
  inviteLink: string;
  inviteToken: string;
  permission: Permission;
}

export interface EditTeamMemberPermissionsPayload {
  role: TeamRole;
  permissions: Permissions;
  teamId: number;
  merchantId: string;
}

export interface MemberActivationPayload {
  teamId: number;
  merchantId: string;
}

export interface TeamMemberById {
  member: ITeamMemberDetails;
  teamMemberPermission: Permission;
  invoiceSummary: InvoiceSummary;
}

export interface ITeamMemberDetails {
  id: number;
  firstName: string;
  middleName: string;
  lastName: string;
  profilePicture: string;
  createdAt: string;
  email: string;
  phone: string;
  status: string;
  isActive: boolean;
  activeSince: string;
  inactiveSince: string;
  roleId: RoleIdEnum | number;
}

export interface InvoiceSummary {
  firstPaymentDate: string;
  paidInvoicesCount: number;
  amountCollected: number;
}

export interface InviteLinkPayload {
  inviteId: number;
  updateMerchantInformation?: boolean;
  email?: string;
  phoneNumber?: string;
  teamId: string;
}

export interface GetIncompleteDocumentsPayload {
  teamId: string;
}

export interface TeamInviteToken {
  inviteToken: string;
}
