import React from 'react';
import { GenericDeleteModal } from 'components';
import { VoidFn } from 'types/baseTypes';

interface DeleteReviewLinkConfirmationModalProps {
  onCloseClick: VoidFn;
  handleDelete: VoidFn;
  processingDelete: boolean;
}

export const DeleteReviewLinkConfirmationModal: React.FC<DeleteReviewLinkConfirmationModalProps> = ({
  onCloseClick,
  handleDelete,
  processingDelete
}) => {
  return (
    <GenericDeleteModal
      onPrimaryBtnClick={handleDelete}
      onSecondaryBtnClick={onCloseClick}
      isActionInProgress={processingDelete}
      primaryBtnId="delete-link-confirm"
      primaryBtnLabel="Yes, Delete"
      primaryBtnStyle="path-stroke-current"
      secondaryBtnId="delete-item-cancel"
      secondaryBtnLabel="No, Cancel"
      showModal={true}
      title="Are you sure you want to delete link?"
      subTitle="Deleting the review link will hide the link to your review page from the invoice."
    />
  );
};
