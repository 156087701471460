import { InvoicePermissionList } from 'containers/app/app.model';

export interface TeamProfileAddress {
  zipCode: string;
  street: string;
  city: string;
  state: string;
  apartment: string;
}

export interface TeamSettingPermissionList {
  viewTeamProfile: boolean;
  editTeamProfile: boolean;
  inviteNewMember: boolean;
  editExistingMember: boolean;
  viewExistingMember: boolean;
}

export interface TeamSettings {
  level: string;
  teamSettingPermissionList: TeamSettingPermissionList;
}

export interface Invoice {
  level: string;
  invoicePermissionList: InvoicePermissionList;
}

export interface FeesPermissionList {
  manageItemsAndCategories: boolean;
  manageTaxes: boolean;
  manageServiceCharges: boolean;
  viewServiceFeeSettings: boolean;
  editServiceFeeSettings: boolean;
  viewTipsSettings: boolean;
  editTipsSettings: boolean;
  viewDiscount: boolean;
  editDiscount: boolean;
}

export interface Fees {
  level: string;
  feesPermissionList: FeesPermissionList;
}

export interface CustomersPermissionList {
  createNewCustomers: boolean;
  editExistingCustomers: boolean;
  viewCustomersCreatedByTeam: boolean;
  editCustomersCreatedByTeam: boolean;
}

export interface Customers {
  level: string;
  customersPermissionList: CustomersPermissionList;
}

export interface BankingPermissionList {
  viewAccountDetails: boolean;
  createACHTransfer: boolean;
  createPayee: boolean;
  editExistingPayee: boolean;
  downloadStatement: boolean;
  viewCardDetails: boolean;
  manageCards: boolean;
  viewCashbackDetails: boolean;
}

export interface Banking {
  level: string;
  bankingPermissionList: BankingPermissionList;
}

export interface RefundPermissionList {
  createRefundsBySelf: boolean;
  createRefundsByTeam: boolean;
}

export interface Refund {
  level: string;
  refundPermissionList: RefundPermissionList;
}

export interface Permissions {
  teamSettings: TeamSettings;
  invoice: Invoice;
  fees: Fees;
  customers: Customers;
  banking: Banking;
  refund: Refund;
}

export interface Permission {
  role: string;
  permissions: Permissions;
}

export interface ActiveMember {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  isLoggedInMember: boolean;
  role: string;
  isActive: boolean;
  profileImageUrl: string;
  email: string;
  phone: string;
  aciveSince: Date;
  permission: Permission;
  status: string;
}

export interface TeamSettingPermissionList2 {
  viewTeamProfile: boolean;
  editTeamProfile: boolean;
  inviteNewMember: boolean;
  editExistingMember: boolean;
  viewExistingMember: boolean;
}

export interface TeamSettings2 {
  level: string;
  teamSettingPermissionList: TeamSettingPermissionList2;
}

export interface InvoicePermissionList2 {
  createInvoice: boolean;
  editInProcessInvoices: boolean;
  viewReceiptsBySelf: boolean;
  viewReceiptsByTeam: boolean;
  createRefundsBySelf: boolean;
  createRefundsByTeam: boolean;
  viewInvoicesBySelf: boolean;
  viewInvoicesByTeam: boolean;
  viewPayoutsBySelf: boolean;
  viewPayoutsByTeam: boolean;
  deleteInvoicesBySelf: boolean;
  deleteInvoicesByTeam: boolean;
  editInvoicesBySelf: boolean;
  editInvoicesByTeam: boolean;
}

export interface Invoice2 {
  level: string;
  invoicePermissionList: InvoicePermissionList2;
}

export interface FeesPermissionList2 {
  viewServiceFeeSettings: boolean;
  editServiceFeeSettings: boolean;
  viewTipsSettings: boolean;
  editTipsSettings: boolean;
  viewDiscount: boolean;
  editDiscount: boolean;
}

export interface Fees2 {
  level: string;
  feesPermissionList: FeesPermissionList2;
}

export interface CustomersPermissionList2 {
  createNewCustomers: boolean;
  editExistingCustomers: boolean;
  viewCustomersCreatedByTeam: boolean;
  editCustomersCreatedByTeam: boolean;
}

export interface Customers2 {
  level: string;
  customersPermissionList: CustomersPermissionList2;
}

export interface BankingPermissionList2 {
  viewAccountDetails: boolean;
  createACHTransfer: boolean;
  createPayee: boolean;
  editExistingPayee: boolean;
  downloadStatement: boolean;
  viewCardDetails: boolean;
  manageCards: boolean;
  viewCashbackDetails: boolean;
}

export interface Banking2 {
  level: string;
  bankingPermissionList: BankingPermissionList2;
}

export enum EditAddressState {
  EDIT = 'EDIT',
  ADD = 'ADD'
}

export interface RefundPermissionList2 {
  createRefundsBySelf: boolean;
  createRefundsByTeam: boolean;
}

export interface Refund2 {
  level: string;
  refundPermissionList: RefundPermissionList2;
}

export interface Permissions2 {
  teamSettings: TeamSettings2;
  invoice: Invoice2;
  fees: Fees2;
  customers: Customers2;
  banking: Banking2;
  refund: Refund2;
}

export interface Permission2 {
  role: string;
  permissions: Permissions2;
}

export interface InactiveMember {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  isLoggedInMember: boolean;
  role: string;
  isActive: boolean;
  profileImageUrl: string;
  email: string;
  phone: string;
  inaciveSince: Date;
  permission: Permission2;
  status: string;
}

export interface Listing {
  yelp: string;
  facebook: string;
  otherOnlineReferrence: string;
  website: string;
}

export interface GetTeamProfileApiResponse {
  id: number;
  name: string;
  description: string;
  statementDescriptor: string;
  phone: string;
  email: string;
  website: string;
  profileImageUrl: string;
  profession: string;
  bucket: string;
  autoPayoutStatus: boolean;
  isUnitEnabled: boolean;
  address: TeamProfileAddress;
  // activeMembers: ActiveMember[];
  // inactiveMembers: InactiveMember[];
  // memberInvites: any[];
  listings: Listing;
}

export interface GetTeamProfileDataPayload {
  teamId: string;
}

export interface GetCitiesApIPayload {
  stateCode: string;
}

export interface Professions {
  ID: number;
  profession: string;
}

export interface StateAPIResponse {
  data: StateAPIResponseData;
  error: boolean;
  msg: string;
}

export interface StateAPIResponseData {
  iso2: string;
  iso3: string;
  name: string;
  states: State[];
}

export interface State {
  name: string;
  state_code: string;
}

export interface StateObject {
  name: string;
  code: string;
}

export interface CityObject {
  city: string;
  State: StateObject;
}

export interface GetAddressByZipcodeAPIRequestData {
  zipCode: string;
}

export interface GetAddressByZipcodeAPIResponseData {
  city: string;
  state: string;
  stateName: string;
  zipCode: string;
}

export interface UpdateTeamProfileRequestData {
  teamId: string;
  teamProfileData: UpdateTeamProfileData;
}

export interface UpdateTeamProfileData {
  id: string;
  teamId: string;
  name: string;
  description: string;
  phone: string;
  email: string;
  website: string;
  profession: string;
  address: TeamProfileAddress;
  listings: Listing;
  profileImageUrl?: string;
}
