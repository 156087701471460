import classnames from 'classnames';
import If from 'components/If/If';
import { nullFn } from 'constants/common';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface CustomInputProps {
  type?: InputType;
  placeholder?: string;
  className?: string;
  autoFocus?: boolean;
  maxLength?: number;
  name?: string;
  value: any;
  onChange: any;
  onBlur?: any;
  hasSuffix?: boolean;
  prefixWrapperStyle?: string;
  suffix?: string | JSX.Element;
  prefix?: string | JSX.Element;
  autoComplete?: string;
  inputStyle?: string;
  disabled?: boolean;
  id: string;
  suffixStyle?: string;
  setPasswordType?: (passwordType) => void;
  isPasswordField?: boolean;
  onCopy?: (event) => void;
  onCut?: (event) => void;
  onKeyPress?: (event) => void;
  step?: string;
  onFocus?: any;
  label?: string;
  isMandatory?: boolean;
  error?: string;
}

export enum InputType {
  TEXT = 'text',
  TEXT_AREA = 'textarea',
  NUMBER = 'number',
  DATE = 'date',
  EMAIL = 'email',
  FILE = 'file',
  PASSWORD = 'password'
}

const CustomInput: FC<CustomInputProps> = ({
  step = undefined,
  maxLength,
  autoFocus = false,
  className,
  onCopy,
  onCut,
  onKeyPress,
  placeholder,
  type = InputType.TEXT,
  onChange,
  onBlur = nullFn,
  value,
  name,
  inputStyle,
  hasSuffix = false,
  suffix,
  prefix,
  prefixWrapperStyle,
  autoComplete = 'off',
  disabled = false,
  suffixStyle = '',
  id,
  setPasswordType,
  isPasswordField = false,
  onFocus = nullFn,
  label = '',
  isMandatory = false,
  error = ''
}) => {
  const togglePassword = () => {
    if (type === InputType.PASSWORD) {
      setPasswordType(InputType.TEXT);
      return;
    }
    setPasswordType(InputType.PASSWORD);
  };

  return (
    <div>
      {label && (
        <div className="pb-2 text-sbase font-semibold leading-[18px] tracking-[-0.3px] text-headingGray">
          {label}
          {isMandatory && <span className="pl-0.5 text-error">*</span>}
        </div>
      )}
      <div className={twMerge(classnames('relative flex flex-row', inputStyle))}>
        <input
          id={id}
          onCopy={onCopy}
          {...(step && { step })}
          onCut={onCut}
          autoComplete={autoComplete}
          name={name}
          value={value}
          maxLength={maxLength}
          onChange={e => onChange(e.target.value)}
          onKeyPress={onKeyPress}
          onBlur={onBlur}
          autoFocus={autoFocus}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onFocus={onFocus}
          className={twMerge(
            classnames(
              `h-[50px] w-full rounded bg-secondaryBtn
            p-4 text-[15px] font-semibold text-heading placeholder:text-[15px] placeholder:font-normal
            placeholder:text-accentText`,
              {
                'pr-6': hasSuffix && value
              }
            ),
            className
          )}
        />
        <If condition={hasSuffix && value}>
          {typeof suffix === 'string' ? (
            <div className={twMerge('absolute right-2 top-4 text-sbase font-semibold text-heading', suffixStyle)}>
              {suffix}
            </div>
          ) : (
            <div className={twMerge('absolute right-0 h-full', suffixStyle)}>{suffix}</div>
          )}
        </If>
        <If condition={hasSuffix && isPasswordField}>
          <div onClick={togglePassword} className={twMerge('absolute right-0 h-full', suffixStyle)}>
            {suffix}
          </div>
        </If>
        {prefix && <div className={twMerge('absolute left-0 self-center', prefixWrapperStyle)}>{prefix}</div>}
      </div>
      {error && (
        <div className="pt-1 text-sbase font-semibold leading-[18px] tracking-[-0.3px] text-error">{error}</div>
      )}
    </div>
  );
};

export default CustomInput;
