/* eslint-disable react/no-multi-comp */
import { Popover, Transition } from '@headlessui/react';
import React, { FC, Fragment, useState } from 'react';
import { DateRangePicker, StaticRange, createStaticRanges, defaultStaticRanges } from 'react-date-range';

import DateUtils from 'utils/dateUtils'; // theme
import './DateRangePicker.css'; // main style file
import './DateRangePickerTheme.css';
// import 'react-date-range/dist/theme/default.css';
import { CrossIcon, DownArrowIcon, PlusIcon } from 'assets/icons';
import { subMonths } from 'date-fns';
import { twMerge } from 'tailwind-merge';
import classnames from 'classnames';

interface CustomDateRangePickerProps {
  startDate: string | null;
  setStartDate: (startDate: string | null) => void;
  endDate: string | null;
  setEndDate: (endDate: string | null) => void;
  customClassName?: string;
  filterId: string;
  placeholder?: string;
  allowFutureDate?: boolean;
  allowPastDate?: boolean;
  hasDefaultRanges?: boolean;
  customRanges?: StaticRange[];
}

const CustomDateRangePicker: FC<CustomDateRangePickerProps> = props => {
  const {
    startDate,
    setStartDate,
    endDate,
    setEndDate,
    customClassName,
    filterId,
    placeholder = 'Date',
    allowFutureDate = false,
    allowPastDate = true,
    hasDefaultRanges = true,
    customRanges = []
  } = props;

  const getDefaultRange = () => {
    const today = new Date();
    return [
      {
        startDate: today,
        endDate: today,
        key: 'rollup'
      }
    ];
  };

  const [ranges, setRanges] = useState(getDefaultRange());

  const checkCustomFieldSelected = (range: any) => {
    return false;
  };

  const getStaticRanges = () => {
    if (hasDefaultRanges) {
      return [
        ...defaultStaticRanges,
        ...createStaticRanges([
          {
            label: 'Last 6 Months',
            isSelected: checkCustomFieldSelected,
            range: () => ({
              startDate: subMonths(new Date(), 6),
              endDate: new Date()
            })
          }
        ])
      ];
    }
    return [...createStaticRanges(customRanges)];
  };

  const applyFilter = () => {
    if (ranges.length) {
      const range = ranges[0];
      if (range.startDate && range.endDate) {
        setStartDate(DateUtils.getDateInFormat({ date: range.startDate, dateFormat: 'yyyy-MM-dd' }));
        setEndDate(DateUtils.getDateInFormat({ date: range.endDate, dateFormat: 'yyyy-MM-dd' }));
      }
    }
  };

  const handleCancel = () => {
    const stDate = new Date(startDate);
    const eDate = new Date(endDate);
    setRanges([
      {
        startDate: stDate,
        endDate: eDate,
        key: 'rollup'
      }
    ]);
  };

  const isDateRangeSelected = startDate && endDate;

  return (
    <div>
      <Popover className="lg:relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={twMerge(
                `mr-1 mb-1  shrink-0 rounded-md border 
                bg-secondaryBtn px-3 py-2 text-base font-semibold text-primaryText  
                shadow outline-none transition-all  duration-150 ease-linear focus:outline-none 
                ${isDateRangeSelected ? ' border-solid' : 'border-dashed'}`,
                classnames({ [customClassName]: customClassName })
              )}
              style={{ borderColor: isDateRangeSelected ? '#3876BB' : '#ACB5BD' }}
              type="button">
              <div id={filterId} className={'flex shrink-0 flex-row items-center'}>
                <div>
                  {startDate !== null && endDate !== null ? (
                    <CrossIcon
                      onClick={e => {
                        e.stopPropagation();
                        setStartDate(null);
                        setEndDate(null);
                        setRanges(getDefaultRange());
                      }}
                      className="fill-current text-[#A4D7FA]"
                    />
                  ) : (
                    <PlusIcon />
                  )}
                </div>
                <div id={`${filterId}-text`} className={'shrink-0 px-3 text-sbase'}>
                  {placeholder}
                  {isDateRangeSelected && (
                    <div className="hidden lg:inline">
                      <span> {' | '} </span>
                      <span className={'text-secondary'}>
                        {startDate === endDate ? startDate : `${startDate} to ${endDate}`}
                      </span>
                    </div>
                  )}
                </div>
                <div>
                  <DownArrowIcon className={open ? 'rotate-180' : ''} />
                </div>
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <Popover.Panel
                className="absolute -left-4 z-40 mt-[2px] min-w-[200px] border border-borderGray bg-primary px-4
                shadow-md sm:px-0 md:left-0">
                <div className="flex flex-col">
                  <div>
                    <DateRangePicker
                      startDatePlaceholder="Start Date"
                      endDatePlaceholder="End Date"
                      rangeColors={['#A4D7FA']}
                      ranges={ranges}
                      {...(!allowFutureDate && { maxDate: new Date() })}
                      onChange={(ranges_: any) => setRanges([ranges_.rollup])}
                      staticRanges={getStaticRanges()}
                      inputRanges={[]}
                      {...(!allowPastDate && { minDate: new Date() })}
                    />
                  </div>
                  <div className="flex justify-end bg-secondaryBtn py-3 px-4">
                    <div
                      id={`${filterId}-cancel-button`}
                      onClick={() => {
                        handleCancel();
                        close();
                      }}
                      className="text-sbase font-bold	 text-accentText hover:cursor-pointer">
                      Cancel
                    </div>
                    <div
                      id={`${filterId}-apply-button`}
                      onClick={() => {
                        applyFilter();
                        close();
                      }}
                      className="ml-5 text-sbase font-bold text-secondary hover:cursor-pointer">
                      Apply
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};

export default CustomDateRangePicker;
