/* eslint-disable react/prop-types */
import React, { FC } from 'react';
import { formatAmount } from 'utils/amountUtils';
import { InvoiceLineItem } from '../InvoicesSlice';

interface ItemCardProps {
  item: InvoiceLineItem;
}

const ItemCard: FC<ItemCardProps> = ({ item }) => (
  <div className="flex justify-between">
    <div className="flex min-w-[350px] flex-col gap-3">
      <div className="text-sbase font-semibold text-primaryText">{item.itemDescription}</div>
      <div className="whitespace-pre-line text-px13 font-normal text-primaryText line-clamp-2">
        {item.additionalDetails}
      </div>
    </div>
    <div className="text-sbase font-normal text-accentText">{`${item.quantity} x $${formatAmount(item.unitCost)}`}</div>
    <div className="text-sbase font-semibold text-primaryText">{`$${formatAmount(item.total)}`}</div>
  </div>
);

export default ItemCard;
