import { WebviewFunctions } from 'constants/common';
import { selectCurrentTeam, selectPaymentSystemConfig } from 'containers/app/appSlice';
import { selectAuthState } from 'containers/auth/authSlice';
import { PaymentSystem } from 'containers/payouts/constants';
import { useAppSelector } from 'hooks';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LocalStorage } from 'services/storage';
import { VoidFn } from 'types/baseTypes';

interface CollectPendingDocumentsProps {
  handleExit: VoidFn;
}

const CollectPendingDocuments: FC<CollectPendingDocumentsProps> = ({ handleExit }) => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const { idToken, refreshToken } = useAppSelector(selectAuthState) ?? {};
  const navigate = useNavigate();

  const getFrameURL = () => {
    let url = process.env.REACT_APP_ONBOARDING_URL;
    const currentIdToken = idToken || LocalStorage.get('idToken');
    const currentRefreshToken = refreshToken || LocalStorage.get('refreshToken');
    const teamId = currentTeam?.id;
    // eslint-disable-next-line max-len
    url = `${url}/#/ca-connect-pending-documents?isIframe=true&idToken=${currentIdToken}&refreshToken=${currentRefreshToken}&teamId=${teamId}&paymentSystem=${
      paymentSystem ?? PaymentSystem.CA
    }`;
    return url;
  };

  useEffect(() => {
    const handleReadMessage = event => {
      if (event.data === WebviewFunctions.CLOSE_WEBVIEW) {
        handleExit();
      }
    };
    window.addEventListener('message', handleReadMessage, false);
    return () => {
      window.removeEventListener('message', handleReadMessage, false);
    };
  }, []);

  return (
    <div className="h-full w-full">
      <iframe src={getFrameURL()} title="stripe-pending-doc" className="h-full w-full" />
    </div>
  );
};

export default CollectPendingDocuments;
