export interface MIGRATION_ROUTE_STATE {
  openConnectBankModal?: boolean;
}

export enum PaymentSystemTransitionType {
  LIPConnectMigration = 'LIP_CONNECT_MIGRATION',
  LIPBankMigration = 'LIP_BANKING_MIGRATION'
}

export enum BANK_ACCOUNT_CONNECTION_SOURCE {
  FC_AUTO = 'FC_AUTO',
  FC_MANUAL = 'FC_MANUAL',
  SP_MANUAL = 'SP_MANUAL'
}
