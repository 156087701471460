import { AccountApprovedIcon, ChevronArrowRightIcon } from 'assets/icons';
import { FC } from 'react';

interface ApplicationApprovedCardProps {
  text: string;
  subtext?: string;
  showArrow?: boolean;
}

const ApplicationApprovedCard: FC<ApplicationApprovedCardProps> = ({ text = '', subtext = '', showArrow = false }) => {
  return (
    <div className="flex w-full items-center rounded-md bg-[#DBEFFD] p-[15px]">
      <AccountApprovedIcon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-semibold text-primaryText">{text}</div>
        {subtext && <div className="text-sbase text-primaryText">{subtext}</div>}
      </div>
      {showArrow && <ChevronArrowRightIcon className="ml-auto shrink-0" />}
    </div>
  );
};

export default ApplicationApprovedCard;
