import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TaxInputSection from '../components/TaxInputSection';
import ApplyTaxSection from '../components/ApplyTaxSection';
import CatalogTopBar from 'containers/product-catalog/components/CatalogTopBar';
import { useAppDispatch, useAppSelector } from 'hooks';
import { resetTaxSlice, selectInitializingTaxSliceInProgress, selectTaxState } from '../taxSlice';
import { useDeleteCatalogTaxMutation, useEditTaxMutation, useGetTaxByIdQuery } from '../api';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { NotifierTypes } from 'constants/notifierConstants';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { EditTaxAPIPayload, ITax } from '../taxes.model';
import { isTaxDataUpdated, prepareTaxPayload } from '../util';
import { CatalogTopBarTaxesButton, emptyTax } from '../constants';
import { GenericDeleteModal, SaveChangesConfirmationPopup } from 'components';
import { TaxInputErrors } from '../type';
import { CleverTapEventsCatalogTaxes } from '../events';
import { constructArrayObjectAsString } from 'utils/commonUtils';
import { logAnalyticEvent } from 'utils/analytics';
import { REGEX } from 'constants/common';

interface ITaxDeleteModalState {
  open: boolean;
  tax: ITax;
}

const EditTax: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { taxId } = useParams();

  const selectedTaxState = useAppSelector(selectTaxState);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const initializingTaxSliceInProgress = useAppSelector(selectInitializingTaxSliceInProgress);

  const errorDefaultState = {
    taxNameError: null,
    taxRateError: null
  };
  const [errors, setErrors] = useState<Record<string, TaxInputErrors>>(errorDefaultState);
  const [showSaveChangesConfirmation, setShowSaveChangesConfirmation] = useState(false);

  const {
    data: taxByIdData,
    isFetching: isFetchingTax,
    isError: isFetchingTaxError
  } = useGetTaxByIdQuery(
    { id: +taxId, isEditFlow: true, teamId: currentTeam?.id },
    { skip: false, refetchOnMountOrArgChange: true }
  );

  const [editTaxMutation, { isSuccess: isEditTaxSuccess, isLoading: isEditTaxLoading, isError }] = useEditTaxMutation();

  const [deleteTaxModalState, setDeleteTaxModalState] = useState<ITaxDeleteModalState>({
    open: false,
    tax: emptyTax
  });

  const [
    deleteTaxMutation,
    { isSuccess: isDeleteTaxSuccess, isLoading: isDeleteTaxMutationInProgress, isError: isDeleteTaxError }
  ] = useDeleteCatalogTaxMutation();

  const disableSaveBtn =
    !isTaxDataUpdated(selectedTaxState, taxByIdData) ||
    !selectedTaxState.taxName ||
    !selectedTaxState.taxRate ||
    +selectedTaxState.taxRate === 0 ||
    selectedTaxState.taxRate.replace(REGEX.nonNumber, '') === '';

  useEffect(() => {
    if (isEditTaxSuccess) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Tax successfully updated'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
      dispatch(resetTaxSlice());
      navigate(-1);
    }
  }, [isEditTaxSuccess]);

  useEffect(() => {
    if (isFetchingTaxError) navigate(-1);
  }, [isFetchingTaxError]);

  useEffect(() => {
    if (isDeleteTaxSuccess) {
      toggleDeleteTaxModal();
      navigate(-1);
    }
  }, [isDeleteTaxSuccess]);

  const handleSave = () => {
    setShowSaveChangesConfirmation(false);
    let isValid = true;
    let errorObject = errorDefaultState;
    if (!selectedTaxState.taxName) {
      isValid = false;
      errorObject = { ...errorObject, taxNameError: TaxInputErrors.Tax_Name_Error };
    }

    if (!selectedTaxState.taxRate || +selectedTaxState.taxRate <= 0) {
      isValid = false;
      errorObject = { ...errorObject, taxRateError: TaxInputErrors.Tax_Rate_Error };
    }

    if (!isValid) {
      setErrors(errorObject);
      return;
    }

    const payload: EditTaxAPIPayload = {
      ...prepareTaxPayload(selectedTaxState, currentTeam?.id.toString()),
      id: selectedTaxState.taxId
    };
    if (payload && selectedTaxState.taxId) {
      editTaxMutation(payload);
    }

    logAnalyticEvent(CleverTapEventsCatalogTaxes.webCatalogTaxesEditTaxSaveClicked, {
      taxId: selectedTaxState.taxId,
      name: selectedTaxState.taxName,
      taxRate: selectedTaxState.taxRate,
      items: constructArrayObjectAsString(selectedTaxState.items, 'item'),
      serviceCharge: constructArrayObjectAsString(selectedTaxState.serviceCharges, 'serviceCharge'),
      teamId: currentTeam.id
    });
  };

  const handleExit = () => {
    if (isTaxDataUpdated(selectedTaxState, taxByIdData)) {
      setShowSaveChangesConfirmation(true);
    } else {
      dispatch(resetTaxSlice());
      navigate(-1);
    }
  };

  const discardSaveChangesConfirmation = () => {
    setShowSaveChangesConfirmation(false);
    navigate(-1);
  };

  const handleDelete = () => {
    toggleDeleteTaxModal();
  };

  const deleteTaxHandler = () => {
    deleteTaxMutation({
      id: selectedTaxState.taxId,
      teamId: currentTeam.id
    });
    logAnalyticEvent(CleverTapEventsCatalogTaxes.webCatalogTaxesEditTaxDeleteClicked, {
      name: selectedTaxState.taxName,
      id: selectedTaxState.taxId
    });
  };

  const toggleDeleteTaxModal = (tax?: ITax) => {
    if (deleteTaxModalState.open) {
      setDeleteTaxModalState({ open: false, tax: emptyTax });
      return;
    }
    setDeleteTaxModalState({ open: true, tax: tax });
  };

  if (initializingTaxSliceInProgress || isFetchingTax) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col">
      <CatalogTopBar
        className="border border-borderGray"
        title="Edit tax"
        onPrimaryClick={handleSave}
        onReturn={handleExit}
        primaryBtnTitle="Update"
        disablePrimaryBtn={disableSaveBtn}
        secondaryBtnTitle="Delete"
        onSecondaryClick={handleDelete}
        primaryBtnId={CatalogTopBarTaxesButton.EditTaxSaveButton}
        secondaryBtnId={CatalogTopBarTaxesButton.EditTaxDeleteButton}
        backButtonId={CatalogTopBarTaxesButton.EditTaxBackButton}
        isPrimaryButtonLoading={isEditTaxLoading}
      />
      <div className="flex h-full w-full flex-col items-center p-8">
        <div className="flex w-full max-w-[720px] basis-[50%] flex-col">
          <TaxInputSection errors={errors} setErrors={setErrors} className="mb-9" />
          <ApplyTaxSection />
        </div>
      </div>
      <GenericDeleteModal
        primaryBtnId="tax-yes-delete-button"
        secondaryBtnId="tax-no-cancel-button"
        showModal={deleteTaxModalState.open}
        isActionInProgress={isDeleteTaxMutationInProgress}
        onPrimaryBtnClick={deleteTaxHandler}
        onSecondaryBtnClick={toggleDeleteTaxModal}
        title="Are you sure you want to delete this Tax?"
        subTitle="Deleting a tax only affects future invoices not past or pending ones."
        primaryBtnLabel="Yes, Delete"
        secondaryBtnLabel="No, Cancel"
      />
      {showSaveChangesConfirmation && (
        <SaveChangesConfirmationPopup
          onSaveChanges={handleSave}
          closeSaveConfirmation={discardSaveChangesConfirmation}
          onCloseIconClick={() => setShowSaveChangesConfirmation(false)}
        />
      )}
    </div>
  );
};

export default EditTax;
