import { baseApi } from 'services/api';
import { API_VERSION, WEB_V1 } from 'constants/common';
import {
  DailyBreakDownSalesPayload,
  DailyBreakDownSalesResponse,
  GetConnectBankAccountResponse,
  MonthlySaleResponse,
  MonthlySalesPayload,
  PaymentMethods,
  PaymentSystemTransitionPayload,
  SalesPayload,
  TeamIdPayload,
  TeamMembersSales,
  TileData,
  TileDataPayload
} from 'containers/home/home.model';

const HomeBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTileData: builder.query<TileData, TileDataPayload>({
      query: ({ teamId, startDate, endDate }) => {
        return {
          url: `${WEB_V1}/tile?teamId=${teamId}&start=${startDate}&end=${endDate}`,
          method: 'GET'
        };
      },
      extraOptions: {
        showToast: true,
        failure: 'Failed to fetch tile data'
      },
      // Why do we use this?
      providesTags: ['GetTileData']
    }),
    getSalesByPaymentMethods: builder.query<PaymentMethods, SalesPayload>({
      query: ({ teamId, startDate, endDate }) => ({
        url: `${WEB_V1}/chart/sales/payment-method?teamId=${teamId}&start=${startDate}&end=${endDate}`,
        method: 'GET'
      }),
      extraOptions: {
        showToast: true,
        failure: 'Failed to fetch payment data'
      }
    }),
    getSalesByTeamMember: builder.query<TeamMembersSales, SalesPayload>({
      query: ({ teamId, startDate, endDate }) => ({
        url: `${WEB_V1}/chart/sales/team-member?teamId=${teamId}&start=${startDate}&end=${endDate}`,
        method: 'GET'
      }),
      extraOptions: {
        showToast: true,
        failure: 'Failed to fetch sales by team members data'
      }
    }),

    getMonthlySalesData: builder.query<MonthlySaleResponse, MonthlySalesPayload>({
      query: ({ teamId, startDate, endDate, isDigitalSales }) => ({
        url: `${WEB_V1}/chart/sales/monthly?teamId=${teamId}&start=${startDate}&end=${endDate}&salesType=${
          isDigitalSales ? 'digital' : 'all'
        }`,
        method: 'GET'
      }),
      extraOptions: {
        failure: 'failed to fetch tile data'
      }
    }),
    getDailyBreakdownSalesData: builder.query<DailyBreakDownSalesResponse, DailyBreakDownSalesPayload>({
      query: ({ teamId, startDate, endDate, isDigitalSales }) => ({
        url: `${WEB_V1}/chart/sales/daily?teamId=${teamId}&start=${startDate}&end=${endDate}&salesType=${
          isDigitalSales ? 'digital' : 'all'
        }`,
        method: 'GET'
      }),
      extraOptions: {
        failure: 'failed to fetch tile data'
      }
    }),
    updatePaymentSystemTransition: builder.mutation<string, PaymentSystemTransitionPayload>({
      query: ({ teamId, body }) => ({
        url: `${API_VERSION.V1}/team/${teamId}/payment-system/transition`,
        method: 'PATCH',
        body: body
      })
    }),
    getTeamConnectBankAccount: builder.query<GetConnectBankAccountResponse, TeamIdPayload>({
      query: ({ teamId }) => ({
        url: `${API_VERSION.V1}/team/${teamId}/connect/bank-account`,
        method: 'GET'
      })
    })
  })
});

export const {
  useLazyGetTileDataQuery,
  useLazyGetSalesByPaymentMethodsQuery,
  useLazyGetSalesByTeamMemberQuery,
  useLazyGetMonthlySalesDataQuery,
  useLazyGetDailyBreakdownSalesDataQuery,
  useUpdatePaymentSystemTransitionMutation,
  useLazyGetTeamConnectBankAccountQuery
} = HomeBaseApi;
