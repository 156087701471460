import React, { FC, useState } from 'react';
import { CustomModal } from 'components';
import ConfirmBankAccountModal from './ConfirmBankAccountModal';
import { MigrationRoutes } from 'containers/home/bank-migration/constants';
import ConnectGetStartedModal from './ConnectGetStartedModal';
import { PaymentSystemTransitionType } from 'containers/home/constants';
import { useUpdatePaymentSystemTransitionMutation } from 'containers/home/api';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import ConnectBankModal from './ConnectBankModal';
import { VoidFn } from 'types/baseTypes';
import MigrationStartModal from './MigrationStartModal';
import { ONBOARDING_URL_SOURCES } from 'constants/common';

interface MigrationModalProps {
  onClose: VoidFn;
  defaultPage: MigrationRoutes;
  onOpenFCModal: VoidFn;
  handleFaqClick: (page: MigrationRoutes) => void;
  isFromFCModal: boolean;
  from: ONBOARDING_URL_SOURCES;
}

const MigrationModal: FC<MigrationModalProps> = ({
  onClose,
  defaultPage,
  onOpenFCModal,
  handleFaqClick,
  isFromFCModal,
  from
}) => {
  const [page, setPage] = useState(defaultPage);

  const currentTeam = useAppSelector(selectCurrentTeam);
  const [updatePaymentSystemMutation, { isLoading, isSuccess }] = useUpdatePaymentSystemTransitionMutation();

  const handleOpenCAGetStartedModal = () => {
    setPage(MigrationRoutes.MIGRATION_CA_GET_STARTED);
  };

  const handleOpenConfirmBankAccountModal = () => {
    updatePaymentSystemMutation({
      teamId: currentTeam.id,
      body: { paymentSystemTransitionType: PaymentSystemTransitionType.LIPConnectMigration }
    });
    setPage(MigrationRoutes.MIGRATION_CONFIRM_BANK_ACCOUNT);
  };

  const handleConfirmBankAccountModalBack = () => {
    setPage(MigrationRoutes.MIGRATION_START);
  };

  const handleCAGetStartedModalBack = () => {
    setPage(MigrationRoutes.MIGRATION_CONFIRM_BANK_ACCOUNT);
  };

  const handleStripeConnectGetStartedClick = () => {
    setPage(MigrationRoutes.MIGRATION_CA);
  };

  const handleConnectBankModalBack = () => {
    setPage(MigrationRoutes.MIGRATION_CA_GET_STARTED);
  };

  return (
    <CustomModal
      open
      width="md:min-w-[375px]"
      height="md:min-h-[775px]"
      className="customTransparentScroll overflow-auto"
      onOutsideClick={onClose}>
      {page === MigrationRoutes.MIGRATION_START && (
        <MigrationStartModal
          onFAQClick={() => handleFaqClick(MigrationRoutes.MIGRATION_START)}
          onUpgradePaymentsClick={handleOpenConfirmBankAccountModal}
          onBack={onClose}
        />
      )}

      {page === MigrationRoutes.MIGRATION_CONFIRM_BANK_ACCOUNT && (
        <ConfirmBankAccountModal
          onFAQClick={() => handleFaqClick(MigrationRoutes.MIGRATION_CONFIRM_BANK_ACCOUNT)}
          openConfirmBankAccount={handleOpenCAGetStartedModal}
          onBack={handleConfirmBankAccountModalBack}
        />
      )}

      {page === MigrationRoutes.MIGRATION_CA_GET_STARTED && (
        <ConnectGetStartedModal
          onBack={handleCAGetStartedModalBack}
          onGetStarted={handleStripeConnectGetStartedClick}
        />
      )}

      {page === MigrationRoutes.MIGRATION_CA && (
        <ConnectBankModal
          onBankCardClick={onOpenFCModal}
          onClose={onClose}
          isRootPage={defaultPage === MigrationRoutes.MIGRATION_CA}
          onBack={handleConnectBankModalBack}
          isFromFCModal={isFromFCModal}
          from={from}
        />
      )}
    </CustomModal>
  );
};

export default MigrationModal;
