import { LoadingSpinnerAnimation } from 'assets/animations';
import { WhiteInfoIcon } from 'assets/icons';
import classnames from 'classnames';
import Lottie from 'lottie-react';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

export enum ButtonType {
  SECONDARY = 'SECONDARY',
  PRIMARY = 'PRIMARY',
  TERTIARY = 'TERTIARY'
}

export enum FormButtonType {
  submit = 'submit',
  button = 'button',
  reset = 'reset'
}

interface ButtonProps {
  id: string;
  children?: ReactNode;
  StartIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string; id?: string }> | null;
  startIconId?: string;
  EndIcon?: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  className?: string;
  type?: ButtonType;
  formType?: FormButtonType;
  disabled?: boolean;
  showDisabledReason?: boolean;
  onClick?: VoidFn;
  primaryButtonColor?: string;
  childrenContainerStyles?: string;
  isLoading?: boolean;
  startIconStyle?: string;
  endIconStyle?: string;
}

/*This component will act as the primary button by default*/
const Button: FC<ButtonProps> = ({
  id,
  disabled = false,
  showDisabledReason = false,
  children,
  StartIcon,
  startIconId,
  EndIcon,
  className,
  type = ButtonType.PRIMARY,
  onClick = null,
  formType = FormButtonType.button,
  childrenContainerStyles = '',
  isLoading = false,
  startIconStyle = '',
  endIconStyle = ''
}) => {
  const isSecondaryBtn = type === ButtonType.SECONDARY;
  const isPrimaryBtn = type === ButtonType.PRIMARY;
  const isTertiaryBtn = type === ButtonType.TERTIARY;

  return (
    <button
      id={id}
      type={formType}
      onClick={onClick}
      className={twMerge(
        classnames(
          `flex h-11 w-fit cursor-pointer flex-row items-center 
           justify-center rounded-md px-[15px] text-sbase font-semibold
           active:shadow-xl disabled:pointer-events-none disabled:cursor-not-allowed
           disabled:opacity-50`,
          {
            'border border-secondary bg-primary text-secondaryBtnText shadow-sm hover:bg-[#ECF1F7]': isSecondaryBtn,
            'bg-primaryBtn text-primaryBtnText hover:bg-primaryButtonHover': isPrimaryBtn,
            'bg-tertiaryText text-primaryBtnText active:shadow-none': isTertiaryBtn,
            'opacity:100 bg-[#9BBADD] hover:bg-[#9BBADD]': showDisabledReason && isPrimaryBtn,
            'opacity:50': showDisabledReason && isTertiaryBtn
          }
        ),
        className
      )}
      disabled={disabled || isLoading}>
      {!isLoading ? (
        <div className={twMerge('flex flex-row gap-2 text-sbase items-center', childrenContainerStyles)}>
          {Boolean(StartIcon) && (
            <StartIcon
              className={twMerge(
                classnames('shrink-0 ', {
                  'opacity-50': showDisabledReason
                }),
                startIconStyle
              )}
              id={startIconId}
            />
          )}
          <div
            className={twMerge(
              classnames({
                'opacity-50': showDisabledReason
              })
            )}>
            {children}
          </div>
          {Boolean(EndIcon) && <EndIcon className={twMerge(classnames('shrink-0 '), endIconStyle)} />}
          {showDisabledReason && isPrimaryBtn && <WhiteInfoIcon />}
        </div>
      ) : (
        <Lottie className="h-20 w-20" animationData={LoadingSpinnerAnimation} loop={true} />
      )}
    </button>
  );
};

export default Button;
