import React, { FC, useEffect, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import BannerCarousel from '../components/BannerCarousel';
import FilterSection from '../components/FilterSection';
import { CTA_TYPES, ConnectPayoutStatus, PaymentSystem, PayoutRootPage } from '../constants';
import { If } from 'components';
import { Divider } from '@material-ui/core';
import { usePaginatedGetPayoutsQuery } from '../hooks/usePaginatedGetPayoutsQuery';
import { ArrowRightIcon, CaretDownIcon, ExportIcon, PayoutsTabIcon } from 'assets/icons';
import { useAppDispatch, useAppSelector } from 'hooks';
import { NotifierTypes } from 'constants/notifierConstants';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamCustomersPermissions,
  selectCurrentTeamInvoicePermissions,
  selectPayoutBanners,
  showNotifier
} from 'containers/app/appSlice';
import { InfoMesssages } from 'types/infoMessages';
import { AmountFilterOperation } from 'constants/common';
import { isEqual } from 'lodash';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import PayoutsListTable from './components/PayoutsListTable';
import { areEqualArrays, constructArrayAsValue, openInNewTab } from 'utils/commonUtils';
import { ConnectPayout } from '../payouts.model';
import { Outlet, useNavigate } from 'react-router-dom';
import { ROOT_ROUTE_STATE } from 'routes/constants';
import { AmountFilter } from 'components/custom-amount-filter/CustomAmountFilter';
import routesPath from 'routes/RoutesPath';
import { logAnalyticEvent } from 'utils/analytics';
import { clevertapEvents } from 'types/baseTypes';
import { useLazyDownloadPayoutsQuery } from '../api';
import classNames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { resetInvoiceState } from 'containers/invoices/InvoicesSlice';
import { PayoutsCleverTapEvents } from '../events';
import DateUtils from 'utils/dateUtils';
import NoPermissions from 'components/no-permissions/NoPermissions';

export const initialQueryParamsState = {
  status: [],
  fromTimestamp: null,
  toTimestamp: null,
  type: null,
  search: null,
  amount: null
};

const PayoutsList: FC = () => {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);
  const [payoutStatusDraft, setPayoutStatusDraft] = useState<string[]>([]);
  const [selectedPayoutStatuses, setSelectedPayoutStatuses] = useState<ConnectPayoutStatus[] | string[]>([]);
  const dispatch = useAppDispatch();
  const notifications = useAppSelector(getNotifications);
  const [showFiltersAndExport, setShowFiltersAndExport] = useState<boolean>(false);
  const [selectedPayouts, setSelectedPayouts] = useState<Array<string>>([]);
  const navigate = useNavigate();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [downloadPayoutsQuery] = useLazyDownloadPayoutsQuery();
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const payoutBanners = useAppSelector(selectPayoutBanners);

  useEffect(() => {
    resetPayoutsQuery();
  }, [payoutPermissions.viewPayoutsBySelf, payoutPermissions.viewPayoutsByTeam]);

  const [amountFilter, setAmountFilter] = useState<AmountFilter>({
    filterOperation: AmountFilterOperation.ALL,
    minAmount: null,
    maxAmount: null
  });

  const {
    list: payoutList,
    isLoading: isLoadingPayouts,
    loadMore,
    resetQuery: resetPayoutsQuery,
    hasMore: hasMorePayouts,
    setQueryParams: setPayoutsQueryParams,
    queryParams: payoutsQueryParams,
    totalListCount: payoutsCount,
    isLoadingSuccess: payoutsListSuccess,
    hasPermissionsToView
  } = usePaginatedGetPayoutsQuery();

  const payoutListLoader = useRef(loadMore);

  useEffect(() => {
    payoutListLoader.current = loadMore;
  }, [loadMore]);

  useEffect(() => {
    if (!isLoadingPayouts && payoutList.length > 0) {
      setShowFiltersAndExport(true);
    }
  }, [isLoadingPayouts]);

  const getAmountParam = () => {
    const { filterOperation, minAmount, maxAmount } = amountFilter;
    if (filterOperation === AmountFilterOperation.ALL) return null;
    if (filterOperation === AmountFilterOperation.BW) return `${filterOperation}:${minAmount}:${maxAmount}`;
    return `${filterOperation}:${minAmount}`;
  };

  useEffect(() => {
    resetPayoutsQuery();
    setStartDate(null);
    setEndDate(null);
    setSelectedPayoutStatuses([]);
    setPayoutStatusDraft([]);
    setShowFiltersAndExport(false);
    setAmountFilter({
      filterOperation: AmountFilterOperation.ALL,
      minAmount: null,
      maxAmount: null
    });
  }, [currentTeam?.id]);

  useEffect(() => {
    const updatedParams = {
      ...payoutsQueryParams,
      fromTimestamp: DateUtils.startDateToUTCString(startDate),
      toTimestamp: DateUtils.endDateToUTCString(endDate),
      status: selectedPayoutStatuses as ConnectPayoutStatus[],
      amount: getAmountParam()
    };
    setPayoutsQueryParams(prevQueryParams => ({
      ...prevQueryParams,
      ...updatedParams
    }));
    setSelectedPayouts([]);
    logAnalyticEvent(PayoutsCleverTapEvents.webPayoutsListFiltersApplied, {
      'Start Date': startDate,
      'End Date': endDate,
      ...constructArrayAsValue('Status', payoutsQueryParams.status),
      amount: getAmountParam()
    });
  }, [selectedPayoutStatuses, startDate, endDate, amountFilter]);

  const isInitialQueryParamsState = () => {
    return isEqual(payoutsQueryParams, initialQueryParamsState);
  };

  const isFilterResultEmpty =
    payoutsCount === 0 && !isLoadingPayouts && !isInitialQueryParamsState() && payoutsQueryParams.search === null;

  const isExportDisabled = isFilterResultEmpty;

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const isPayoutResultEmpty =
    payoutsCount === 0 &&
    !isLoadingPayouts &&
    payoutsQueryParams.search === null &&
    isInitialQueryParamsState() &&
    payoutsListSuccess;

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: payoutListLoader,
    threshold: 0.5
  });

  const onTogglePayoutSelection = (payout: ConnectPayout, isCheckdPayout: boolean) => {
    if (isCheckdPayout) {
      setSelectedPayouts(checkedInvoicesState => checkedInvoicesState.filter(id => id !== payout.id));
      return;
    }
    setSelectedPayouts([...selectedPayouts, payout.id]);
  };

  const isSelectedPayout = (payout: ConnectPayout) => {
    return selectedPayouts.find((id: string) => payout.id === id);
  };

  const extractPayoutIdsFromLoadedPayouts = () => {
    return payoutList.map(payout => payout.id);
  };

  const areAllLoadedPayoutsSelected = () => {
    return areEqualArrays({ primaryArray: extractPayoutIdsFromLoadedPayouts(), secondaryArray: selectedPayouts });
  };

  const onToggleSelectAllLoadedPayouts = () => {
    if (areAllLoadedPayoutsSelected()) {
      setSelectedPayouts([]);
    } else {
      setSelectedPayouts(extractPayoutIdsFromLoadedPayouts());
    }
  };

  const handlePayoutClick = (payoutId, type: PaymentSystem) => {
    const cleverTapParams = {
      'Payout Id': payoutId,
      'Payment System': type
    };
    logAnalyticEvent(clevertapEvents.WebPayoutsListDetails, cleverTapParams);
    const payoutType = type || PaymentSystem.CA;
    navigate(`/payouts/list/${currentTeam?.id}/${PayoutRootPage.LIST}/payout/${payoutId}?type=${payoutType}`, {
      state: ROOT_ROUTE_STATE
    });
  };

  const onDownloadFile = () => {
    if (isExportDisabled) {
      onInfoClick('payouts-export', InfoMesssages.noPayoutsToExport);
      return;
    }
    downloadPayoutsQuery({
      teamId: currentTeam?.id,
      params: {
        ...payoutsQueryParams,
        limit: null,
        page: 0,
        selectedIds: selectedPayouts
      }
    });
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal',
        duration: 2000
      })
    );
    logAnalyticEvent(clevertapEvents.webPayoutsListExport, {
      'Start Date': payoutsQueryParams.fromTimestamp,
      'End Date': payoutsQueryParams.toTimestamp,
      ...constructArrayAsValue('Status', payoutsQueryParams.status)
    });
  };

  const handleCreateInvoiceClick = () => {
    dispatch(resetInvoiceState());
    navigate(routesPath.INVOICES_CREATE);
    logAnalyticEvent(PayoutsCleverTapEvents.webPayoutsListCreateInvoiceClick);
  };

  const handleSidePanelClose = () => {
    navigate(routesPath.PAYOUTS_LIST);
  };

  const handleCarouselButtonClick = (action, type: string) => {
    if (type === CTA_TYPES.URL) {
      openInNewTab(action);
    } else if (type === CTA_TYPES.DEEPLINK) {
      navigate(action);
    }
    logAnalyticEvent(PayoutsCleverTapEvents.webPayoutsBannersClick, { ctaAction: action });
  };

  return (
    <>
      <div className="flex h-full w-full flex-col overflow-auto pt-6 pb-10 pl-7 ">
        {hasPermissionsToView && !isLoadingPayouts && (
          <BannerCarousel handleCarouselButtonClick={handleCarouselButtonClick} banners={payoutBanners} />
        )}
        <div className="flex flex-1 flex-col gap-5 pr-7">
          <div className="text-3xl font-bold text-primaryText ">Payouts</div>
          <div className="flex flex-1 flex-col gap-[15px]">
            <If condition={!isPayoutResultEmpty && hasPermissionsToView && !isLoadingPayouts}>
              <div className="text-17px font-bold text-primaryText">All payouts</div>
            </If>
            <If condition={showFiltersAndExport}>
              <div className="flex">
                <FilterSection
                  startDate={startDate}
                  setStartDate={setStartDate}
                  endDate={endDate}
                  setEndDate={setEndDate}
                  payoutStatusDraft={payoutStatusDraft}
                  setPayoutStatusDraft={setPayoutStatusDraft}
                  selectedPayoutStatuses={selectedPayoutStatuses}
                  setSelectedPayoutStatuses={setSelectedPayoutStatuses}
                  amountFilter={amountFilter}
                  setAmountFilter={setAmountFilter}
                />

                <div className="ml-auto justify-end">
                  <Button
                    className="h-10 bg-[#A4D7FA] px-4 font-lato normal-case
                             text-primaryText shadow-none"
                    endIcon={<CaretDownIcon className="path-stroke-current w-3 shrink-0 text-accentText" />}
                    startIcon={<ExportIcon className="shrink-0" />}
                    disableRipple={isExportDisabled}
                    onClick={onDownloadFile}>
                    <div className="text-17px font-semibold">Export</div>
                  </Button>
                </div>
              </div>
            </If>

            <If condition={payoutList?.length !== 0}>
              <div id="total-payouts-count" className="mb-5 hidden text-sbase text-accentText lg:flex">
                Total {payoutsCount} Payout{payoutsCount !== 1 ? 's' : ''}
              </div>
              <div>
                <Divider className="w-full bg-secondaryBtn" />
              </div>
            </If>
            <div className="mt-top customNormalScroll grow px-6">
              <If condition={payoutList.length !== 0}>
                <PayoutsListTable
                  payoutList={payoutList}
                  areAllLoadedPayoutsSelected={areAllLoadedPayoutsSelected}
                  onTogglePayoutSelection={onTogglePayoutSelection}
                  isSelectedPayout={isSelectedPayout}
                  onToggleSelectAllLoadedPayouts={onToggleSelectAllLoadedPayouts}
                  onPayoutClick={handlePayoutClick}
                />
                <If condition={!isLoadingPayouts && hasMorePayouts}>
                  <div className="h-2" ref={setIntersectionElement}></div>
                </If>
              </If>
              <If condition={isLoadingPayouts}>
                <InfinteLoadingSpinner />
              </If>
              {/* Filter empty state */}
              <If condition={isFilterResultEmpty && hasPermissionsToView}>
                <div className="flex h-full flex-col items-center justify-center gap-5">
                  <div className="flex flex-col items-center gap-5">
                    <PayoutsTabIcon className="h-10 w-10" />
                    <div className="text-sbase text-primaryText">No payouts found for selected filters</div>
                  </div>
                </div>
              </If>
              {/* Payouts empty state */}
              <If condition={isPayoutResultEmpty && hasPermissionsToView}>
                <div className="flex h-full flex-col items-center justify-center gap-2.5 text-center">
                  <PayoutsTabIcon className="path-stroke-current h-10 w-10 shrink-0 text-border" />
                  <div className="text-sbase font-semibold text-primaryText">
                    No payouts to show. <br />
                    Keep collecting payments!
                  </div>
                  <div
                    className={twMerge(
                      'flex cursor-pointer items-center gap-2',
                      classNames({
                        'pointer-events-none opacity-50':
                          !invoicePermissions.createInvoice || !customerPermissions.createNewCustomers
                      })
                    )}
                    onClick={handleCreateInvoiceClick}>
                    <div id="create-new-invoice-this-month-graph" className="text-px13 font-semibold text-secondary">
                      Create new invoice
                    </div>
                    <ArrowRightIcon />
                  </div>
                </div>
              </If>
              <If condition={!isLoadingPayouts && !hasPermissionsToView}>
                <div className="flex h-full flex-col items-center justify-center gap-5">
                  <NoPermissions />
                </div>
              </If>
            </div>
          </div>
        </div>
      </div>
      <Outlet context={{ handleSidePanelClose }} />
    </>
  );
};

export default PayoutsList;
