export enum CleverTapEventsInvoices {
  webInvoicesCreateInvoice = 'Web_Invoice_Create_Invoice',
  webInvoicesCreateInvoiceTipsFaqClick = 'Web_Invoice_Create_Invoice_Tips_Faq_Click',
  webInvoicesExport = 'Web_Invoices_Export',
  webInvoicesPaidView = 'Web_Invoices_Paid_View',
  webInvoicesPendingView = 'Web_Invoices_Pending_View',
  webInvoicesMMShareInvoice = 'Web_Invoices_MM_Share_Invoice',
  webInvoicesMMPrint = 'Web_Invoices_MM_Print',
  webInvoicesMMMarkAsPaid = 'Web_Invoices_MM_Mark_As_Paid',
  webInvoicesMMDelete = 'Web_Invoices_List_MM_Delete',
  webInvoicesMMShareInvoiceSendEmail = 'Web_Invoices_List_MM_Share_Invoice_Send_Email',
  webInvoicesMMShareInvoiceSendSMS = 'Web_Invoices_List_MM_Share_Invoice_Send_SMS',
  webInvoicesMMShareInvoiceCopy = 'Web_Invoices_List_MM_Share_Invoice_Copy',
  webInvoicesMMMarkAsPaidPaymentMethod = 'Web_Invoices_List_MM_Mark_As_Paid_Payment_Method',
  webInvoicesListDetail = 'Web_Invoices_List_Detail',
  webInvoicesListDetailShareReceipt = 'Web_Invoices_List_Detail_Share_Receipt',
  webInvoicesListDetailShareReceiptSendEmail = 'Web_Invoices_List_Detail_Share_Receipt_Send_Email',
  webInvoicesListDetailShareReceiptSendSMS = 'Web_Invoices_List_Detail_Share_Receipt_Send_SMS',
  webInvoicesListDetailShareReceiptCopy = 'Web_Invoices_List_Detail_Share_Receipt_Copy',
  webInvoicesListDetailMoreExportPDF = 'Web_Invoices_List_Detail_More_Export_PDF',
  webInvoicesListDetailMorePrint = 'Web_Invoices_List_Detail_More_Print',
  webInvoicesListDetailReShare = 'Web_Invoices_List_Detail_ReShare',
  webInvoicesListDetailEdit = 'Web_Invoices_List_Detail_Edit',
  webInvoicesListDetailMoreMarkAsPaid = 'Web_Invoices_List_Detail_More_Mark_As_Paid',
  webInvoicesListDetailMoreDelete = 'Web_Invoices_List_Detail_More_Delete',
  webInvoicesListDetailsPanelOpen = 'Web_Invoices_List_Details_Panel_Open',
  webInvoicesCreateInvoiceSave = 'Web_Invoice_Create_Invoice_Save_Clicked',
  webInvoicesEditInvoiceSave = 'Web_Invoice_Edit_Invoice_Save_Clicked',
  webInvoicesEditInvoiceTipsFaqClick = 'Web_Invoice_Edit_Invoice_Tips_Faq_Click'
}
