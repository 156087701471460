import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import { baseApi } from 'services/api';
import { isProduction } from 'utils/envUtils';
import rootReducer from './reducers';

const middlewareGroup = [baseApi.middleware];

if (!isProduction) {
  middlewareGroup.push(createLogger({ collapsed: true, diff: true }));
}

export const store = configureStore({
  reducer: {
    rootReducer,
    [baseApi.reducerPath]: baseApi.reducer
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware().concat(middlewareGroup);
  },
  devTools: !isProduction
});

export type AppDispatch = typeof store.dispatch;
