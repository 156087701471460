import { GreyStrokeInfoIcon } from 'assets/icons';
import { CustomPopover } from 'components';
import { PopoverArrowTypes } from 'constants/common';
import { FC, useState } from 'react';

interface ServiceFeeToolTipProps {
  width?: string;
  arrowOffset?: string;
  offset?: string;
}

const ServiceFeeToolTip: FC<ServiceFeeToolTipProps> = ({
  width = 'w-[235px]',
  arrowOffset = 'right-2 left-[89px]',
  offset = '-left-20'
}) => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleShowToolTip = () => {
    setShowToolTip(true);
  };

  const handleCloseToolTip = () => {
    setShowToolTip(false);
  };

  const toolTipElement = () => (
    <div onMouseEnter={handleShowToolTip} onMouseLeave={handleCloseToolTip} className="cursor-pointer">
      <GreyStrokeInfoIcon className="shrink-0" />
    </div>
  );
  return (
    <CustomPopover
      show={showToolTip}
      onTogglePopover={handleShowToolTip}
      onClosePopover={handleCloseToolTip}
      anchorComponent={toolTipElement}
      highlightAnchor={false}
      offset={offset}
      showArrow={true}
      arrowType={PopoverArrowTypes.TOP}
      arrowOffset={arrowOffset}
      margin="mt-3"
      width={width}>
      <div className="text-sbase text-primaryText">
        {`
        Owners or Admins can control Service fees applied to all invoices.
        Service fee settings can be updated under Team Settings > Invoice Settings
      `}
      </div>
    </CustomPopover>
  );
};

export default ServiceFeeToolTip;
