import { API_VERSION } from 'constants/common';
import { Permission, TeamRole } from 'containers/app/app.model';
import { baseApi } from 'services/api';
import { getUrlSearchParams } from 'utils/apiUtils';
import { initializeEditPermissionsSliceFromAPI } from './edit-permissions/editPermissionsSlice';
import {
  CreateInvitePayload,
  EditTeamMemberPermissionsPayload,
  GetTeamMemberPermissions,
  GetTeamMemberPermissionsResponse,
  GetTeamMembersPayload,
  GetTeamMembersResponse,
  IGetTeamMembers,
  InviteLinkPayload,
  MemberActivationPayload,
  TeamInviteToken,
  TeamMemberById
} from './manageTeam.model';
import { getPermissions } from 'containers/app/util';

const TeamMembersBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getTeamMembers: builder.query<IGetTeamMembers, GetTeamMembersPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/team-members?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      transformResponse: (response: GetTeamMembersResponse) => {
        return { id: response.id, teamMembers: response.members, totalCount: response.totalMembers };
      },
      extraOptions: {
        failure: 'Failed to fetch manage teams data',
        showToast: true
      }
    }),

    getTeamMemberPermissions: builder.query<GetTeamMemberPermissionsResponse, GetTeamMemberPermissions>({
      query: ({ roleId, teamId }) => {
        const url = `${API_VERSION.V2}/team/${teamId}/permissions/${roleId}`;
        return {
          url: url,
          method: 'GET'
        };
      },

      async onQueryStarted(params, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        const editPermissionSliceStateFromAPI: Permission = {
          role: data.role,
          permissions: data.role === TeamRole.CUSTOM ? data.permissions : getPermissions(data.role).permissions
        };
        dispatch(initializeEditPermissionsSliceFromAPI(editPermissionSliceStateFromAPI));
      },
      extraOptions: {
        failure: 'Failed to fetch manage teams data',
        showToast: true
      }
    }),
    createInvite: builder.mutation<any, CreateInvitePayload>({
      query: ({ teamId, payload }) => {
        return {
          url: `${API_VERSION.V1}/team-invites/invite/${teamId}`,
          method: 'POST',
          body: payload
        };
      }
    }),
    getTeamMemberById: builder.query<TeamMemberById, MemberActivationPayload>({
      query: params => {
        const { merchantId, teamId } = params;
        return {
          url: `${API_VERSION.WEB_V1}/team-members/${merchantId}?teamId=${teamId}`,
          method: 'GET'
        };
      },

      extraOptions: {
        failure: 'Failed to fetch team member data'
      }
    }),

    editPermissions: builder.mutation<any, EditTeamMemberPermissionsPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/permissions/${payload.teamId}/${payload.merchantId}`,
          method: 'PUT',
          body: {
            role: payload.role,
            permissions: payload.permissions
          }
        };
      },

      extraOptions: {
        failure: 'Failed to update permissions',
        success: 'Permissions changed successfully',
        showToast: true
      }
    }),
    deactivateMember: builder.mutation<any, MemberActivationPayload>({
      query: ({ merchantId, teamId }) => {
        return {
          url: `${API_VERSION.V1}/team-members/${teamId}/${merchantId}`,
          method: 'DELETE'
        };
      },
      extraOptions: {
        failure: 'Deactivation failed',
        success: 'Member deactivated successfully',
        showToast: true
      }
    }),
    reactivateMember: builder.mutation<any, MemberActivationPayload>({
      query: ({ merchantId, teamId }) => {
        return {
          url: `${API_VERSION.V1}/team-members/${teamId}/${merchantId}`,
          method: 'POST',
          body: { teamId: teamId.toString() }
        };
      },
      extraOptions: {
        failure: 'Reactivation failed',
        success: 'Member reactivated successfully',
        showToast: true
      }
    }),
    shareInviteLink: builder.mutation<any, InviteLinkPayload>({
      query: ({ inviteId, updateMerchantInformation = false, email = '', phoneNumber = '', teamId }) => {
        return {
          url: `${API_VERSION.V2}/team-invites/resend/${inviteId}`,
          method: 'POST',
          body: { updateMerchantInformation, email, phoneNumber, teamId }
        };
      },
      extraOptions: {
        failure: 'Invite link sharing failed',
        success: 'Invite link shared successfully',
        showToast: true
      }
    }),
    deleteInvite: builder.mutation<any, InviteLinkPayload>({
      query: ({ inviteId, teamId }) => {
        return {
          url: `${API_VERSION.V2}/team/${teamId}/team-invites/${inviteId}`,
          method: 'DELETE'
        };
      },
      extraOptions: {
        failure: 'Failed to delete invite',
        success: 'Invite link deleted successfully',
        showToast: true
      }
    }),

    acceptTeamInvite: builder.mutation<any, TeamInviteToken>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/team-invites/accept`,
          method: 'POST',
          body: payload
        };
      },
      extraOptions: {
        failure: 'Invalid invite link and you are redirected to your previous logged in account',
        success: 'Invite accepted successfully',
        showToast: true
      }
    }),
    declineTeamInvite: builder.mutation<any, TeamInviteToken>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/team-invites/reject`,
          method: 'POST',
          body: payload
        };
      },
      extraOptions: {
        failure: 'Failed to decline invite',
        success: 'Invite declined successfully',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetTeamMembersQuery,
  useGetTeamMemberPermissionsQuery,
  useCreateInviteMutation,
  useLazyGetTeamMemberByIdQuery,
  useDeactivateMemberMutation,
  useReactivateMemberMutation,
  useShareInviteLinkMutation,
  useDeleteInviteMutation,
  useEditPermissionsMutation,
  useAcceptTeamInviteMutation,
  useDeclineTeamInviteMutation
} = TeamMembersBaseApi;
