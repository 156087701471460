import { ArrowRightIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';

interface PayoutNotesPanelProps {
  onClose: VoidFn;
  onSave: (data: string) => void;
  payoutNotes: string;
}

const PayoutNotesPanel: FC<PayoutNotesPanelProps> = ({ onClose, onSave, payoutNotes }) => {
  const [notes, setNotes] = useState('');

  useEffect(() => {
    setNotes(payoutNotes);
  }, [payoutNotes]);

  const handleSaveNotes = () => {
    onSave(notes.trim());
  };

  const getRemainingText = () => {
    if (!notes) {
      return '250';
    }
    return 250 - notes.length;
  };

  const isSaveButtonDisabled = () => {
    return payoutNotes === notes.trim() || !notes.trim() || !notes;
  };

  return (
    <div className="h-full w-96">
      <div
        className="flex cursor-pointer items-center gap-3 border-b border-[#D9D9D9] px-3.5 pt-6 pb-4"
        onClick={onClose}>
        <ArrowRightIcon className="rotate-180" />
        <div className="text-17px font-semibold text-headingGray">Notes</div>
      </div>
      <div className="px-[30px] pb-10">
        <div className="relative mt-7 mb-5">
          <textarea
            id="payout-notes-input"
            value={notes}
            onChange={event => setNotes(event.target.value)}
            className={`h-32 w-full rounded-[7px] border border-borderGray p-3 text-primaryText
            focus:border-secondary focus:outline-none`}
            placeholder="Enter notes"
            maxLength={250}
          />
          {notes && (
            <label
              htmlFor="myInput"
              className={'absolute left-3 -top-3 bg-primary px-2  text-sm text-gray-600 transition-all'}>
              Enter notes
            </label>
          )}
          <div className="ml-auto w-fit pt-2.5 text-px13 text-accentText">
            {`${getRemainingText()}/250 characters left`}
          </div>
        </div>

        <div className="space-y-5">
          <CustomButton
            className="w-full "
            id="notes-save-button"
            type={ButtonType.PRIMARY}
            disabled={isSaveButtonDisabled()}
            onClick={handleSaveNotes}>
            Save
          </CustomButton>
          <CustomButton className="w-full " id="notes-cancel-button" type={ButtonType.SECONDARY} onClick={onClose}>
            Cancel
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default PayoutNotesPanel;
