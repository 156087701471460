/* eslint-disable no-unused-vars */

import Logger from 'utils/logger';
import { FireBaseCollections, db } from 'utils/firebase';

export const FirebaseStorage = {
  async markMessageAsRead(id: string): Promise<void> {
    try {
      await db.collection('Notifications').doc(id).update({ isRead: 'true' });
    } catch (error) {
      Logger.logException(error);
    }
  },

  async deleteMessageById(id: string): Promise<void> {
    try {
      await db.collection('Notifications').doc(id).delete();
    } catch (error) {
      Logger.logException(error);
    }
  },

  async markAllMessagesRead(merchantId: string): Promise<void> {
    try {
      await db
        .collection('Notifications')
        .where('merchantId', '==', merchantId)
        .get()
        .then(querySnapshot => {
          querySnapshot.forEach(doc => {
            doc.ref.update({ isRead: 'true' });
          });
        });
    } catch (error) {
      Logger.logException(error);
    }
  },

  async hasSeenWhatsNew({ merchantId }: { merchantId: string }): Promise<boolean> {
    try {
      const merchantDocumentRef = db.collection(FireBaseCollections.Merchants.collectionName).doc(merchantId);
      const merchantDocument = await merchantDocumentRef.get();
      if (merchantDocument.exists) {
        const merchantDocumentData = merchantDocument.data();
        if (merchantDocumentData) {
          const sawWhatsNew: boolean =
            merchantDocumentData[FireBaseCollections.Merchants.fields.seenNewSignatureModalWeb] ?? false;
          return sawWhatsNew;
        }
        Logger.logErrorMessage(
          // eslint-disable-next-line quotes
          "Firebase: couldn't read what's new for the current merchant \n document data couldn't be accessed"
        );
      }
    } catch (error) {
      // eslint-disable-next-line quotes
      Logger.logErrorMessage("Firebase: couldn't read what's new for the current merchant \n couldn't read collection");
      return true;
    }
  },

  async markWhatsNewAsSeen({ merchantId }: { merchantId: string }): Promise<void> {
    try {
      const merchantDocumentRef = db.collection(FireBaseCollections.Merchants.collectionName).doc(merchantId);
      await merchantDocumentRef.set(
        {
          [FireBaseCollections.Merchants.fields.seenNewSignatureModalWeb]: true
        },
        { merge: true }
      );
    } catch (error) {
      // eslint-disable-next-line quotes
      Logger.logErrorMessage("Firebase: couldn't update what's new seen for the current merchant");
    }
  },

  getUnreadNotifsByMerchant: (merchantId, observer) =>
    db
      .collection('Notifications')
      .where('merchantId', '==', merchantId)
      .where('expiry', '>=', Date.now())
      .onSnapshot(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          const notification = doc.data();
          if (notification.isRead === 'false') data.push(notification);
        });
        observer(data);
      }),

  // to test notifs => 'c928523c-b713-4558-af31-a6a179c5b418'
  getNotifsByMerchant: (merchantId, observer) =>
    db
      .collection('Notifications')
      .where('merchantId', '==', merchantId)
      .where('expiry', '>=', Date.now())
      .onSnapshot(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          data.push(doc.data());
        });
        observer(data);
      }),

  getTeamInvitesByMerchant: async (phone, observer) => {
    db.collection('TeamInvites')
      .where('phone', '==', phone)
      .where('status', '==', 'INVITED')
      .onSnapshot(querySnapshot => {
        const data = [];
        querySnapshot.forEach(doc => {
          const docData = doc.data();
          // Convert Firestore Timestamps to JavaScript Date objects
          const inviteDate = docData.inviteDate.toDate();
          const expiryDate = docData.expiryDate.toDate();
          data.push({
            ...docData,
            inviteDate,
            expiryDate
          });
        });
        observer(data);
      });
  },

  async updateMerchantCollectionFieldValue({
    merchantId,
    collectionFieldName,
    value
  }: {
    merchantId: string;
    collectionFieldName: string;
    value: boolean;
  }): Promise<void> {
    try {
      const merchantDocumentRef = db.collection(FireBaseCollections.Merchants.collectionName).doc(merchantId);
      await merchantDocumentRef.set(
        {
          [collectionFieldName]: value
        },
        { merge: true }
      );
    } catch (error) {
      // eslint-disable-next-line quotes
      Logger.logErrorMessage(`Firebase: couldn't update ${collectionFieldName} for the current team`);
    }
  },

  async updateTeamsCollectionFieldValue({
    teamId,
    collectionFieldName,
    value
  }: {
    teamId: string;
    collectionFieldName: string;
    value: boolean;
  }): Promise<void> {
    try {
      const merchantDocumentRef = db.collection(FireBaseCollections.Teams.collectionName).doc(teamId);
      await merchantDocumentRef.set(
        {
          [collectionFieldName]: value
        },
        { merge: true }
      );
    } catch (error) {
      // eslint-disable-next-line quotes
      Logger.logErrorMessage(`Firebase: couldn't update ${collectionFieldName} for the current team`);
    }
  }
};
