import { CustomButton, CustomInput, If } from 'components';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { preventDefaultFn } from 'constants/common';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { EyeCloseIcon, EyeIcon, LockIcon } from 'assets/icons';
import { ButtonType } from 'components/custom-button/CustomButton';
import { PRIVACY_POLICY_LINK, TERMS_LINK } from 'config/index';
import { twMerge } from 'tailwind-merge';

interface PasswordSectionProps {
  className?: string;
  handleContinue: (password: string) => void;
  handleForgotPassword: VoidFn;
  passwordError: string;
  handleBackClick: VoidFn;
  isSignInLoading: boolean;
  setPasswordError: React.Dispatch<React.SetStateAction<string>>;
}

const PasswordSection: FC<PasswordSectionProps> = ({
  className = '',
  handleContinue,
  handleForgotPassword,
  passwordError,
  handleBackClick,
  isSignInLoading = false,
  setPasswordError
}) => {
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState(InputType.PASSWORD);
  const [passwordInputError, setPasswordInputError] = useState('');

  useEffect(() => {
    if (passwordError) {
      setPasswordInputError(passwordError);
    }
  }, [passwordError]);

  useEffect(() => {
    return () => {
      setPasswordError('');
    };
  }, []);

  const handlePasswordChange = (value: string) => {
    setPasswordInputError('');
    setPassword(value);
  };

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleContinue(password);
    }
  };

  return (
    <div
      className={twMerge(
        `  
      my-auto flex max-w-[375px] flex-col self-center 2xl:w-[375px]`,
        className
      )}>
      <div className="mb-4 mt-12 text-2xl font-bold text-heading lg:text-4xl">Enter password</div>
      <div>
        <CustomInput
          id="set-password-input"
          onCopy={preventDefaultFn}
          onCut={preventDefaultFn}
          inputStyle="w-full text-17px"
          type={passwordType}
          prefixWrapperStyle="top-8 left-5"
          prefix={<LockIcon height={20} />}
          hasSuffix={true}
          suffix={passwordType === InputType.PASSWORD ? <EyeIcon /> : <EyeCloseIcon />}
          suffixStyle="top-7 right-4 cursor-pointer"
          autoComplete="off"
          onChange={handlePasswordChange}
          isPasswordField={true}
          setPasswordType={setPasswordType}
          value={password}
          onKeyPress={handleKeyDown}
          className={`mt-4 block h-[50px] rounded border px-12 
            ${passwordInputError && password.length !== 0 ? 'border-error' : ''}
            font-semibold text-primaryText placeholder:text-[17px] 
            placeholder:font-semibold placeholder:text-accentText focus:outline-none`}
          placeholder="Password"
        />
        <If condition={Boolean(passwordInputError)}>
          <div className="mt-1 text-sbase font-semibold text-error">{passwordError}</div>
        </If>
        <div
          onClick={handleForgotPassword}
          className={`w-fit ${!passwordInputError ? 'mt-2' : 'mt-3.5'} 
        cursor-pointer text-sbase font-semibold text-secondary`}>
          Forgot Password?
        </div>
      </div>
      <div>
        <div className={`${!passwordInputError ? 'mt-8' : 'mt-5'} text-px13 text-primaryText`}>
          By signing in, you agree to ScanPay{' '}
          <a id="terms-link" className="text-secondary" href={TERMS_LINK} target="_blank" rel="noreferrer">
            Terms{' '}
          </a>
          and{' '}
          <a
            id="privacy-policy-link"
            className="text-secondary"
            href={PRIVACY_POLICY_LINK}
            target="_blank"
            rel="noreferrer">
            Privacy Policy
          </a>
        </div>
        <CustomButton
          id="set-passowrd-modal-save-button"
          onClick={() => handleContinue(password)}
          disabled={Boolean(passwordInputError) || password.length === 0}
          className={`text-semibold mt-2 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}
          isLoading={isSignInLoading}>
          Continue
        </CustomButton>
        <CustomButton
          id="update-email-modal-cancel-button"
          className="mt-6 h-[38px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
          type={ButtonType.SECONDARY}
          onClick={handleBackClick}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default PasswordSection;
