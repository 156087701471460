import React, { FC } from 'react';
import { AdditionalTax, DiscountSettings, IInvoiceServiceCharge } from '../invoices.model';
import ItemCard from './ItemCard';
import { formatAmount } from 'utils/amountUtils';
import { InvoiceLineItem, selectInvoiceLineItems, selectSelectedServiceCharge } from '../InvoicesSlice';
import { getAggregatedTaxInCurrencyWithSymbol } from '../utils';
import { useAppSelector } from 'hooks';
import {
  selectInvoiceLineItemsEditPage,
  selectSelectedServiceChargeFromEditSlice
} from '../edit-invoice/invoicesEditSlice';
import { Divider } from '@material-ui/core';
import { DiscountFormData } from '../types';

interface InvoiceLineItemsProps {
  items: InvoiceLineItem[];
  billAmount: number;
  surChargeAmount: number;
  serviceFeeEnabled: boolean;
  serviceCharges: IInvoiceServiceCharge;
  taxes: AdditionalTax[];
  discountData: DiscountFormData;
  totalAmount: number;
  selectDiscountSettingsForInvoice: DiscountSettings;
  isEditFlow?: boolean;
}
const InvoiceLineItems: FC<InvoiceLineItemsProps> = ({
  items,
  billAmount,
  surChargeAmount,
  serviceFeeEnabled,
  serviceCharges,
  taxes,
  totalAmount,
  discountData,
  selectDiscountSettingsForInvoice,
  isEditFlow = false
}) => {
  const invoiceLineItemsState = isEditFlow
    ? useAppSelector(selectInvoiceLineItemsEditPage)
    : useAppSelector(selectInvoiceLineItems);
  const selectedServiceCharge = isEditFlow
    ? useAppSelector(selectSelectedServiceChargeFromEditSlice)
    : useAppSelector(selectSelectedServiceCharge);

  return (
    <div className="flex flex-col gap-y-[30px] rounded-md border border-borderGray bg-white p-10">
      <div>
        <div className="flex justify-between text-sbase font-semibold text-headingGray">
          <div>ITEMS</div>
          <div>PRICE</div>
        </div>
        <Divider variant="fullWidth" className="mt-3 bg-borderGray " />
      </div>
      <div className="flex flex-col gap-y-[30px]">
        {items.map(lineItem => (
          <ItemCard key={lineItem.id} item={lineItem} />
        ))}
        {selectDiscountSettingsForInvoice?.enabled === true &&
          discountData.discountPercent &&
          discountData.discountPercent !== '0' && (
            <div className="flex justify-between">
              <div className="min-w-[350px] text-sbase font-semibold text-primaryText">Discount</div>
              <div className="text-sbase font-semibold text-primaryText">
                {`-$${formatAmount(discountData.discountInCurrency)}`}
              </div>
            </div>
          )}
      </div>
      <div>
        <Divider variant="fullWidth" className="bg-borderGray " />
        <div className="flex justify-between pt-[13px] pb-[15px] font-semibold text-headingGray">
          <div>Subtotal</div>
          <div>{`$${formatAmount(billAmount)}`}</div>
        </div>
        <Divider variant="fullWidth" className="bg-borderGray " />
      </div>
      {serviceFeeEnabled && (
        <div className="flex justify-between text-sbase font-normal text-primaryText">
          <div>Service fee</div>
          <div>{`$${formatAmount(surChargeAmount)}`}</div>
        </div>
      )}
      {(+selectedServiceCharge.amount > 0 || +selectedServiceCharge.percentage > 0) && (
        <div className="flex justify-between text-sbase font-normal text-primaryText">
          <div className="line-clamp-3">{`${serviceCharges.name}`}</div>
          <div>{`$${formatAmount(serviceCharges.totalAmount)}`}</div>
        </div>
      )}

      {taxes &&
        taxes?.map(tax => (
          <div key={tax.id} className="flex justify-between text-sbase font-normal text-primaryText">
            <div className="line-clamp-3">{`${tax.name}`}</div>
            <div>{getAggregatedTaxInCurrencyWithSymbol(invoiceLineItemsState, tax, selectedServiceCharge)}</div>
          </div>
        ))}
      <div>
        {(serviceFeeEnabled ||
          +selectedServiceCharge.amount > 0 ||
          +selectedServiceCharge.percentage > 0 ||
          taxes?.length > 0) && <Divider variant="fullWidth" className="mt-3 bg-borderGray " />}

        <div className="mt-5 flex justify-between text-xl font-bold text-headingGray">
          <div>Total Amount</div>
          <div>{`$${totalAmount}`}</div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceLineItems;
