import { FC } from 'react';
import { ReactIconType } from 'types/baseTypes';

const CategoriesTabReactIcon: FC<ReactIconType> = props => (
  <svg
    className={props.className}
    onClick={props.onClick}
    width={props.width}
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      // eslint-disable-next-line max-len
      d="M2.5013 1.66406H8.33464C8.83464 1.66406 9.16797 1.9974 9.16797 2.4974V8.33073C9.16797 8.83073 8.83464 9.16406 8.33464 9.16406H2.5013C2.0013 9.16406 1.66797 8.83073 1.66797 8.33073V2.4974C1.66797 1.9974 2.0013 1.66406 2.5013 1.66406ZM3.33464 7.4974H7.5013V3.33073H3.33464V7.4974ZM17.5013 1.66406H11.668C11.168 1.66406 10.8346 1.9974 10.8346 2.4974V8.33073C10.8346 8.83073 11.168 9.16406 11.668 9.16406H17.5013C18.0013 9.16406 18.3346 8.83073 18.3346 8.33073V2.4974C18.3346 1.9974 18.0013 1.66406 17.5013 1.66406ZM12.5013 7.4974H16.668V3.33073H12.5013V7.4974ZM17.5013 10.8307H11.668C11.168 10.8307 10.8346 11.1641 10.8346 11.6641V17.4974C10.8346 17.9974 11.168 18.3307 11.668 18.3307H17.5013C18.0013 18.3307 18.3346 17.9974 18.3346 17.4974V11.6641C18.3346 11.1641 18.0013 10.8307 17.5013 10.8307ZM12.5013 16.6641H16.668V12.4974H12.5013V16.6641ZM8.33464 10.8307H2.5013C2.0013 10.8307 1.66797 11.1641 1.66797 11.6641V17.4974C1.66797 17.9974 2.0013 18.3307 2.5013 18.3307H8.33464C8.83464 18.3307 9.16797 17.9974 9.16797 17.4974V11.6641C9.16797 11.1641 8.83464 10.8307 8.33464 10.8307ZM3.33464 16.6641H7.5013V12.4974H3.33464V16.6641Z"
      fill="#828282"
    />
    <mask
      id={`categories-tab-icon-mask-${props.id}`}
      style={{ maskType: 'luminance' }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="18"
      height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        // eslint-disable-next-line max-len
        d="M2.5013 1.66406H8.33464C8.83464 1.66406 9.16797 1.9974 9.16797 2.4974V8.33073C9.16797 8.83073 8.83464 9.16406 8.33464 9.16406H2.5013C2.0013 9.16406 1.66797 8.83073 1.66797 8.33073V2.4974C1.66797 1.9974 2.0013 1.66406 2.5013 1.66406ZM3.33464 7.4974H7.5013V3.33073H3.33464V7.4974ZM17.5013 1.66406H11.668C11.168 1.66406 10.8346 1.9974 10.8346 2.4974V8.33073C10.8346 8.83073 11.168 9.16406 11.668 9.16406H17.5013C18.0013 9.16406 18.3346 8.83073 18.3346 8.33073V2.4974C18.3346 1.9974 18.0013 1.66406 17.5013 1.66406ZM12.5013 7.4974H16.668V3.33073H12.5013V7.4974ZM17.5013 10.8307H11.668C11.168 10.8307 10.8346 11.1641 10.8346 11.6641V17.4974C10.8346 17.9974 11.168 18.3307 11.668 18.3307H17.5013C18.0013 18.3307 18.3346 17.9974 18.3346 17.4974V11.6641C18.3346 11.1641 18.0013 10.8307 17.5013 10.8307ZM12.5013 16.6641H16.668V12.4974H12.5013V16.6641ZM8.33464 10.8307H2.5013C2.0013 10.8307 1.66797 11.1641 1.66797 11.6641V17.4974C1.66797 17.9974 2.0013 18.3307 2.5013 18.3307H8.33464C8.83464 18.3307 9.16797 17.9974 9.16797 17.4974V11.6641C9.16797 11.1641 8.83464 10.8307 8.33464 10.8307ZM3.33464 16.6641H7.5013V12.4974H3.33464V16.6641Z"
      />
    </mask>
    <g mask={`url(#categories-tab-icon-mask-${props.id})`}>
      <path fillRule="evenodd" clipRule="evenodd" d="M0 0H20V20H0V0Z" fill="#828282" />
    </g>
  </svg>
);

export default CategoriesTabReactIcon;
