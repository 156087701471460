import { IItemBasic } from '../items/items.model';
import { TaxSliceState } from './taxSlice';
import { AbstractITax, ITax, ITaxBasic, TaxAPIPayload } from './taxes.model';

export const prepareTaxPayload = (taxSlice: TaxSliceState, teamId: string): TaxAPIPayload => {
  if (teamId && taxSlice.taxName && taxSlice.taxRate) {
    const payload: TaxAPIPayload = {
      teamId: teamId,
      name: taxSlice.taxName.trim(),
      percentage: taxSlice.taxRate,
      applyToAllItems: taxSlice.applyToAllItems,
      ...(!taxSlice.applyToAllItems ? { lineItems: taxSlice.items.map(lineItem => lineItem.id) } : { lineItems: [] }),
      applyToAllServiceCharges: taxSlice.applyToAllServiceCharges,
      ...(!taxSlice.applyToAllServiceCharges
        ? { serviceCharges: taxSlice.serviceCharges.map(serviceCharge => serviceCharge.id) }
        : { serviceCharges: [] })
    };
    return payload;
  }

  return null;
};

const getItemIds = itemsArray => {
  return itemsArray.map(item => item.id);
};

const isArrayEqual = (initialArray: IItemBasic[], updatedArray: IItemBasic[]) => {
  if (updatedArray.length !== initialArray.length) return false;
  const initialItemIds = getItemIds(initialArray);
  const updatedItemIds = getItemIds(updatedArray);
  if (JSON.stringify(initialItemIds) === JSON.stringify(updatedItemIds)) {
    return true;
  }
};

export const getFormattedPercentage = (percentage: string) => {
  try {
    if (percentage.includes('.') && percentage.split('.')[1].length > 2) {
      return (Math.round(+percentage * 1000) / 1000).toFixed(3);
    }
    return (Math.round(+percentage * 100) / 100).toFixed(2);
  } catch (e) {
    return percentage.toString();
  }
};

export const isTaxDataUpdated = (
  initialTaxData: TaxSliceState,
  updatedTaxData: ITax = {
    id: null,
    name: '',
    percentage: '',
    lineItems: [],
    serviceCharges: [],
    lineItemsCount: 0,
    serviceChargesCount: null,
    applyToAllItems: true,
    applyToAllServiceCharges: true
  }
) => {
  if (
    initialTaxData.taxName === updatedTaxData.name &&
    initialTaxData.taxRate === updatedTaxData.percentage &&
    isArrayEqual(initialTaxData.items, updatedTaxData.lineItems ?? []) &&
    isArrayEqual(initialTaxData.serviceCharges, updatedTaxData.serviceCharges ?? []) &&
    initialTaxData.applyToAllItems === updatedTaxData.applyToAllItems &&
    initialTaxData.applyToAllServiceCharges === updatedTaxData.applyToAllServiceCharges
  )
    return false;
  return true;
};

export const sortTaxesAlphabetically = (taxList: AbstractITax[]): AbstractITax[] => {
  return taxList.slice().sort((a, b) => a.name.trim().localeCompare(b.name.trim(), undefined, { sensitivity: 'base' }));
};

export const sortSelectedTaxesToStart = ({
  taxList = [],
  currentlySelectedTaxes = []
}: {
  taxList: ITaxBasic[];
  currentlySelectedTaxes: ITaxBasic[];
}) => {
  let sortedTaxList = taxList;
  if (taxList.length > 0) {
    const selectedTaxList = sortedTaxList.filter(tax => {
      return currentlySelectedTaxes.some(selectedTax => selectedTax.id === tax.id);
    });
    const alphabeticallySortedSelectedTaxes = sortTaxesAlphabetically(selectedTaxList);
    const unSelectedCatalogTaxes = sortTaxesAlphabetically(
      sortedTaxList.filter(tax => !currentlySelectedTaxes.some(selectedTax => selectedTax.id === tax.id))
    );
    sortedTaxList = [...alphabeticallySortedSelectedTaxes, ...unSelectedCatalogTaxes];
  }
  return sortedTaxList;
};
