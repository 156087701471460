import { AddCustomerIcon, InvoicesTabIcon } from 'assets/icons';
import {
  getNotifications,
  selectCurrentTeamCustomersPermissions,
  selectCurrentTeamInvoicePermissions,
  showNotifier
} from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { CustomButton } from 'components';
import { logAnalyticEvent } from 'utils/analytics';
import { clevertapEvents } from 'types/baseTypes';
import { InfoMesssages } from 'types/infoMessages';
import { NotifierTypes } from 'constants/notifierConstants';
import { ButtonType } from 'components/custom-button/CustomButton';

interface QuickActionsProps {
  className?: string;
  onPrimaryAction: () => void;
  onSecondaryAction: () => void;
  isTabView?: boolean;
}

const QuickActions: FC<QuickActionsProps> = ({ className = '', onPrimaryAction, onSecondaryAction, isTabView }) => {
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const notifications = useAppSelector(getNotifications);
  const dispatch = useAppDispatch();

  const onPrimaryActionClick = () => {
    logAnalyticEvent(clevertapEvents.WebHomeCreateInvoice);
    if (!invoicePermissions?.createInvoice || !customerPermissions.createNewCustomers) {
      const toastId = isTabView ? 'quick-actions-create-invoice-button' : 'home-quick-actions-create-invoice-button';
      onInfoClick(toastId, InfoMesssages.createInvoiceNoPermissionMessage);
      return;
    }
    onPrimaryAction();
  };

  const onSecondaryActionClick = () => {
    logAnalyticEvent(clevertapEvents.WebHomeCreateCustomer);
    if (!customerPermissions.createNewCustomers) {
      const toastId = isTabView ? 'quick-actions-create-customer-button' : 'home-quick-actions-create-customer-button';
      onInfoClick(toastId, InfoMesssages.createCustomerNoPermissionMessage);
    } else onSecondaryAction();
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  return (
    <div className={twMerge('mt-7 mb-2 flex flex-col', className)}>
      <div className="text-xl font-bold text-primaryText">Quick Actions</div>
      <div className="flex flex-col sm:w-52 ">
        <CustomButton
          id={`${isTabView ? 'quick-actions-create-invoice-button' : 'home-quick-actions-create-invoice-button'}`}
          onClick={onPrimaryActionClick}
          className={'opacity:100 mt-4 h-10 w-52 text-17px text-primaryBtnText'}
          StartIcon={InvoicesTabIcon}
          startIconStyle="path-stroke-current"
          type={ButtonType.PRIMARY}
          showDisabledReason={!invoicePermissions.createInvoice || !customerPermissions.createNewCustomers}>
          <div className="text-sbase font-semibold">Create Invoice</div>
        </CustomButton>

        <CustomButton
          id={`${isTabView ? 'quick-actions-create-customer-button' : 'home-quick-actions-create-customer-button'}`}
          onClick={onSecondaryActionClick}
          className={'opacity:100  mt-4 h-10 w-52 text-17px text-primaryBtnText'}
          type={ButtonType.PRIMARY}
          StartIcon={AddCustomerIcon}
          showDisabledReason={!customerPermissions.createNewCustomers}>
          <div className="text-sbase font-semibold">Create Customer</div>
        </CustomButton>
      </div>
    </div>
  );
};

export default QuickActions;
