import React, { FC, createContext, useCallback, useEffect, useState } from 'react';

import { InvoiceTabEnum, minimumLineItemsError } from 'containers/invoices/create-invoice/constants';
import { GenericError, Loader } from 'components';
import { useEditInvoiceMutation, useLazyGetInvoicePDFPreviewQuery } from 'containers/invoices/api';
import DateUtils from 'utils/dateUtils';
import { useAppDispatch, useAppSelector } from 'hooks';

import { useNavigate, useParams } from 'react-router-dom';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import {
  addInvoiceValidationErrorEditPage,
  enableCustomerNotSelectedErrorEditPage,
  selectAppliedLineItemLevelTaxesEditPage,
  selectDiscountFormDataEditPage,
  selectDiscountSettingsEditPage,
  selectInvoiceAttachmentsEditPage,
  selectInvoiceCustomerEditPage,
  selectInvoiceFinalAmountEditPage,
  selectInvoiceForEditPage,
  selectInvoiceLineItemsEditPage,
  selectInvoiceNotesEditPage,
  selectInvoiceNumber,
  selectInvoiceOptionsEditPage,
  selectInvoiceSubtotalEditPage,
  selectIsServiceFeeEnabledEditPage,
  selectIsSignatureEnabledEditPage,
  selectSelectedServiceChargeFromEditSlice,
  selectTNCEditPage,
  toggleIsFromPreview,
  updateInvoiceEditedResponse
} from 'containers/invoices/edit-invoice/invoicesEditSlice';
import {
  getLineItemsArrayForEvent,
  getServiceChargeEventPayload,
  prepareDiscountRelatedPayload,
  prepareLineItemList,
  prepareServiceChargeListRequestPayload,
  prepareSummatedUniqueTaxList
} from 'containers/invoices/utils';
import { EditInvoicePayload, IInvoiceAttachmentRequest } from 'containers/invoices/invoices.model';
import { ErrorResponse } from 'containers/app/app.model';
import { APIErrorMsg, APIErrorStatuses } from 'services/type';
import { NotifierTypes } from 'constants/notifierConstants';
import { constructArrayObjectAsString, throttle } from 'utils/commonUtils';
import { InvoicesRouteState } from 'routes/types';
import routesPath from 'routes/RoutesPath';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsInvoices } from '../events';
import { InvoiceSourceEnum, InvoiceVersion } from '../constants';
import { invoiceMinimumAmount } from 'constants/common';
import BillToSection from '../components/BillToSection';
import InvoiceLineItems from '../components/InvoiceLineItems';
import NotesSection from '../components/NotesSection';
import TermsSection from '../components/TermsSection';
import AttachmentsSection from '../components/AttachmentsSection';
import TotalSection from '../components/TotalSection';
import { formatAmount } from 'utils/amountUtils';
import TeamDetailsSection from '../components/TeamDetailsSection';
import InvoiceHeader from '../components/InvoiceHeader';
import { GetTeamProfileApiResponse } from 'containers/team-profile/teamProfile.model';

interface ICreateInvoiceContext {
  selectedInvoiceTab: InvoiceTabEnum;
  setSelectedInvoiceTab: React.Dispatch<React.SetStateAction<InvoiceTabEnum>>;
}

export const CreateInvoiceContext = createContext<ICreateInvoiceContext>({
  selectedInvoiceTab: InvoiceTabEnum.BILL_TO,
  setSelectedInvoiceTab: null
});

enum PageState {
  BUYER_VIEW = 'BUYER_VIEW',
  PDF_VIEW = 'PDF_VIEW'
}

interface BuyersPreviewEditProps {
  goBack: () => void;
  getTeamProfileData: GetTeamProfileApiResponse;
}

const BuyersPreviewEdit: FC<BuyersPreviewEditProps> = ({ goBack, getTeamProfileData }) => {
  const [pageState, setPageState] = useState<PageState>(PageState.BUYER_VIEW);

  const [selectedInvoiceTab, setSelectedInvoiceTab] = useState(InvoiceTabEnum.BILL_TO);

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);

  const [getInvoicePDFPreview, getInvoicePDFPreviewQueryState] = useLazyGetInvoicePDFPreviewQuery();
  const {
    data: getInvoicePDFPreviewResponse,
    isSuccess: getPreviewSuccess,
    isFetching: isPDFPreviewFetching,
    isError: isPDFPreviewError
  } = getInvoicePDFPreviewQueryState;

  const selectedServiceCharge = useAppSelector(selectSelectedServiceChargeFromEditSlice);
  const selectedCustomerForInvoice = useAppSelector(selectInvoiceCustomerEditPage);
  const selectedLineItemsForInvoice = useAppSelector(selectInvoiceLineItemsEditPage);
  const selectDiscountSettingsForInvoice = useAppSelector(selectDiscountSettingsEditPage);
  const selectDiscountFormDataForInvoice = useAppSelector(selectDiscountFormDataEditPage);
  const selectInvoiceAttachmentsForInvoice = useAppSelector(selectInvoiceAttachmentsEditPage);
  const selectInvoiceNotesForInvoice = useAppSelector(selectInvoiceNotesEditPage);
  const isServiceFeeEnabled = useAppSelector(selectIsServiceFeeEnabledEditPage);
  const selectedAppliedLineItemLevelTaxes = useAppSelector(selectAppliedLineItemLevelTaxesEditPage);
  const selectedInvoiceOptionsEditPage = useAppSelector(selectInvoiceOptionsEditPage);
  const invoiceNumber = useAppSelector(selectInvoiceNumber);

  const selectInvoiceSubtotalForInvoice = useAppSelector(selectInvoiceSubtotalEditPage);
  const selectInvoiceFinalAmountForInvoice = useAppSelector(selectInvoiceFinalAmountEditPage);
  const selectedInvoice = useAppSelector(selectInvoiceForEditPage);
  const selectedInvoiceTNC = useAppSelector(selectTNCEditPage);
  const selectedIsSignatureEnabled = useAppSelector(selectIsSignatureEnabledEditPage);

  const { invoiceId } = useParams();

  const dispatch = useAppDispatch();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [
    editInvoiceMutation,
    {
      isSuccess: isEditInvoiceSuccess,
      data: invoiceEditedResponse,
      isError: isInvoiceEditError,
      error: invoiceEditError
    }
  ] = useEditInvoiceMutation();

  useEffect(() => {
    dispatch(toggleIsFromPreview(true));
  }, []);

  const getAttachmentArray = () => {
    const attachmentArray: IInvoiceAttachmentRequest[] = selectedInvoice.selectedInvoiceAttachmentsEditPage?.map(
      attachment => {
        return {
          title: attachment.title,
          url: attachment.url,
          description: attachment.description
        };
      }
    );
    return attachmentArray;
  };

  useEffect(() => {
    if (isEditInvoiceSuccess) {
      dispatch(updateInvoiceEditedResponse(invoiceEditedResponse));
      const invoiceRouteState: InvoicesRouteState = {
        showShareModal: true,
        isEditInvoiceShare: true,
        showSidePanel: false
      };
      navigate(routesPath.INVOICES, { replace: true, state: invoiceRouteState });
    }
  }, [isEditInvoiceSuccess]);

  useEffect(() => {
    if (isInvoiceEditError) {
      const error = invoiceEditError as ErrorResponse;

      if (error.status === APIErrorStatuses.STATUS_422) {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: error.data.message,
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      } else if (error.status === APIErrorStatuses.STATUS_429) {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: APIErrorMsg.LIMIT_EXCEEDED,
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      } else {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: 'Failed to update invoice',
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      }
    }
  }, [isInvoiceEditError]);

  const onEditAndShare = () => {
    let isPageIncomplete = false;
    if (selectedInvoice.invoiceLineItemsEditPage.length === 0) {
      isPageIncomplete = true;
      dispatch(addInvoiceValidationErrorEditPage(minimumLineItemsError));
    }

    if (selectedInvoice.invoiceCustomerEditPage === null) {
      isPageIncomplete = true;
      dispatch(enableCustomerNotSelectedErrorEditPage());
    }
    if (selectedInvoice.invoiceSubTotalEditPage < +currentTeam?.minimumAmount ?? invoiceMinimumAmount) {
      isPageIncomplete = true;

      dispatch(
        showNotifier({
          message: {
            primaryMessage: `Total amount must be at least $${currentTeam?.minimumAmount ?? invoiceMinimumAmount}`
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
    }
    if (isPageIncomplete) {
      return;
    }
    const serviceChargeListPayload = prepareServiceChargeListRequestPayload(selectedInvoice.selectedServiceCharge);
    const editInvoicePayload: EditInvoicePayload = {
      invoiceId,
      invoicePayload: {
        version: InvoiceVersion.THREE_POINT_ZERO,
        teamId: currentTeam.id.toString(),
        storeName: currentTeam.storeName,
        // store all level amounts in redux slice eg: subtotal, finalamount etc
        amount: selectedInvoice.invoiceSubTotalEditPage.toString(),
        comment: selectedInvoice.commentEditPage,
        customerId: selectedInvoice?.invoiceCustomerEditPage?.id,
        phoneNumber: selectedInvoice?.invoiceCustomerEditPage?.phoneNumber,
        email: selectedInvoice?.invoiceCustomerEditPage?.email,
        customerName: selectedInvoice?.invoiceCustomerEditPage?.name,
        billingAddress: selectedInvoice?.invoiceCustomerEditPage?.billingAddress,
        lineItems: prepareLineItemList(selectedInvoice.invoiceLineItemsEditPage),
        ...prepareDiscountRelatedPayload(selectedInvoice.discountFormDataEditPage),
        attachments: getAttachmentArray(),
        tipEnabled: selectedInvoice.tipEnabledEditPage,
        surchargePercentage: selectedInvoice.currentSurchargeEditPage.enabled
          ? selectedInvoice.currentSurchargeEditPage.percentageWithBps ?? '0'
          : '0',
        isServiceFeeEnabled: selectedInvoice.isServiceFeeEnabledEditPage,
        tncId: selectedInvoiceTNC?.tncId ? selectedInvoiceTNC?.tncId : 0,
        ...prepareSummatedUniqueTaxList({
          appliedLineItemLevelTaxes: selectedInvoice.appliedLineItemLevelTaxes,
          invoiceLineItems: selectedInvoice.invoiceLineItemsEditPage,
          selectedServiceCharge: selectedInvoice.selectedServiceCharge
        }),
        serviceCharges: serviceChargeListPayload,
        serviceChargeAmount: serviceChargeListPayload?.length > 0 ? serviceChargeListPayload[0].totalAmount : '0',
        source: InvoiceSourceEnum.WEB,
        isSignatureEnabled: selectedIsSignatureEnabled
      }
    };

    const serviceChargeEventPayload = getServiceChargeEventPayload(serviceChargeListPayload);
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesEditInvoiceSave, {
      customerName: selectedInvoice?.invoiceCustomerEditPage?.name,
      customerId: selectedInvoice?.invoiceCustomerEditPage?.ID,
      teamId: currentTeam.id.toString(),
      storeName: currentTeam.storeName,
      amount: selectedInvoice.invoiceSubTotalEditPage.toString(),
      'Line Items': constructArrayObjectAsString(
        getLineItemsArrayForEvent(selectedInvoice.invoiceLineItemsEditPage),
        'Line item'
      ),
      ...prepareDiscountRelatedPayload(selectedInvoice.discountFormDataEditPage),
      surchargePercentage: selectedInvoice.isServiceFeeEnabledEditPage
        ? selectedInvoice.currentSurchargeEditPage.percentageWithBps ?? '0'
        : '0',
      ...(serviceChargeEventPayload?.length > 0 ? { serviceCharges: serviceChargeEventPayload } : {})
    });
    editInvoiceMutation(editInvoicePayload);
  };

  const editAndShareHandler = useCallback(throttle(onEditAndShare, 800), [selectedInvoice]);

  useEffect(() => {
    if (getPreviewSuccess) {
      const pdf = new Blob([getInvoicePDFPreviewResponse], { type: 'application/pdf' });
      const url = URL.createObjectURL(pdf);
      setPreviewUrl(url);
    }
  }, [getPreviewSuccess]);

  const renderDate = dateString => {
    let date = dateString;
    date = DateUtils.getDateInFormat({ date: new Date(date), dateFormat: 'dd MMM yyyy' });
    return date;
  };

  const navigate = useNavigate();

  const onPDFPreviewClick = () => {
    if (!previewUrl) {
      const serviceChargeListPayload = prepareServiceChargeListRequestPayload(selectedInvoice.selectedServiceCharge);
      const invoiceEditPayload = {
        invoiceNumber: invoiceNumber,
        teamId: currentTeam.id.toString(),
        storeName: currentTeam.storeName,
        amount: selectedInvoice.invoiceSubTotalEditPage.toString(),
        comment: selectedInvoice.commentEditPage,
        customerId: selectedInvoice?.invoiceCustomerEditPage?.id,
        lineItems: prepareLineItemList(selectedInvoice.invoiceLineItemsEditPage),
        discount:
          selectedInvoice.discountFormDataEditPage.discountInCurrency === '0'
            ? null
            : selectedInvoice.discountFormDataEditPage.discountInCurrency,
        discountPercentage: selectedInvoice.discountFormDataEditPage.discountPercent,
        attachments: getAttachmentArray(),
        tipEnabled: selectedInvoice.tipEnabledEditPage,
        surchargePercentage: selectedInvoice.isServiceFeeEnabledEditPage
          ? selectedInvoice.currentSurchargeEditPage.percentageWithBps ?? '0'
          : '0',
        timeStamp: new Date(),
        tncID: selectedInvoiceTNC?.tncId ? selectedInvoiceTNC?.tncId : 0,
        createdDate: new Date(selectedInvoiceOptionsEditPage.createdAt),
        ...prepareSummatedUniqueTaxList({
          appliedLineItemLevelTaxes: selectedInvoice.appliedLineItemLevelTaxes,
          invoiceLineItems: selectedInvoice.invoiceLineItemsEditPage,
          selectedServiceCharge: selectedInvoice.selectedServiceCharge
        }),
        serviceCharges: serviceChargeListPayload,
        serviceChargeAmount: serviceChargeListPayload?.length > 0 ? serviceChargeListPayload[0].totalAmount : '0',
        isInvoiceSignatureEnabled: selectedIsSignatureEnabled
      };
      getInvoicePDFPreview(invoiceEditPayload);
    }
    setPageState(PageState.PDF_VIEW);
  };

  return (
    <CreateInvoiceContext.Provider value={{ selectedInvoiceTab, setSelectedInvoiceTab }}>
      <div className="flex h-full w-full flex-col border">
        <InvoiceHeader
          title="Save & Share"
          pageState={pageState}
          setPageState={setPageState}
          onPDFPreviewClick={onPDFPreviewClick}
          editAndShareHandler={editAndShareHandler}
          goBack={goBack}
        />

        {pageState === PageState.BUYER_VIEW && (
          <div className="flex grow gap-8 bg-borderGray px-[149px] pt-8 pb-[102px]">
            <div className="flex w-[675px] flex-col gap-[30px]">
              <BillToSection customer={selectedCustomerForInvoice} />

              <InvoiceLineItems
                items={selectedLineItemsForInvoice}
                billAmount={selectInvoiceSubtotalForInvoice}
                surChargeAmount={+selectedInvoice.currentSurchargeEditPage?.valueInCurrencyWithBps ?? 0}
                serviceFeeEnabled={isServiceFeeEnabled}
                serviceCharges={selectedServiceCharge}
                taxes={selectedAppliedLineItemLevelTaxes}
                discountData={selectDiscountFormDataForInvoice}
                totalAmount={selectInvoiceFinalAmountForInvoice}
                selectDiscountSettingsForInvoice={selectDiscountSettingsForInvoice}
                isEditFlow={true}
              />

              {selectInvoiceNotesForInvoice && <NotesSection notes={selectInvoiceNotesForInvoice} />}

              {selectedInvoiceTNC.tnc && <TermsSection terms={selectedInvoiceTNC.tnc} />}

              {selectInvoiceAttachmentsForInvoice && selectInvoiceAttachmentsForInvoice.length > 0 && (
                <AttachmentsSection attachments={selectInvoiceAttachmentsForInvoice} />
              )}
            </div>
            <div className="flex w-[435px] flex-col gap-[30px]">
              <TotalSection
                total={formatAmount(selectInvoiceFinalAmountForInvoice)}
                dueDate={renderDate(selectedInvoiceOptionsEditPage.dueDate)}
                invoiceDate={renderDate(selectedInvoiceOptionsEditPage.createdAt)}
              />
              <TeamDetailsSection
                teamName={currentTeam.name}
                teamPhone={getTeamProfileData.phone}
                teamEmail={getTeamProfileData.email}
                teamBillingAddress={getTeamProfileData.address}
                teamWebsite={getTeamProfileData.website}
              />
            </div>
          </div>
        )}

        {pageState === PageState.PDF_VIEW && (
          <>
            {previewUrl && (
              <div>
                <object
                  style={{
                    height: window.innerHeight,
                    width: window.innerWidth
                  }}
                  data={`${previewUrl}#toolbar=0`}
                  type="application/pdf"></object>
              </div>
            )}
            {isPDFPreviewFetching && <Loader />}
            {isPDFPreviewError && <GenericError handleErrorBack={() => setPageState(PageState.BUYER_VIEW)} />}
          </>
        )}
      </div>
    </CreateInvoiceContext.Provider>
  );
};

export default BuyersPreviewEdit;
