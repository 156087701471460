import { TeamRole } from 'containers/app/app.model';
import { TeamMemberRoleItems } from './types';

export const teamMemberRoles: TeamMemberRoleItems[] = [
  { name: 'Admin', role: TeamRole.ADMIN, id: TeamRole.ADMIN },
  { name: 'Owner', role: TeamRole.OWNER, id: TeamRole.OWNER },
  { name: 'Member', role: TeamRole.MEMBER, id: TeamRole.MEMBER },
  { name: 'Custom', role: TeamRole.CUSTOM, id: TeamRole.CUSTOM },
  { name: 'Super Admin', role: TeamRole.SUPER_ADMIN, id: TeamRole.SUPER_ADMIN }
];

export const dropDownGenericRoles: TeamMemberRoleItems[] = [
  { name: 'Admin', role: TeamRole.ADMIN, id: TeamRole.ADMIN },
  { name: 'Member', role: TeamRole.MEMBER, id: TeamRole.MEMBER }
];

export enum PermissionSettingType {
  TEAM_SETTINGS = 'Team settings',
  INVOICES_AND_RECEIPTS = 'Invoices & Receipts',
  TIPS_AND_FEES = 'Catalog, Tips & Fees',
  REFUNDS = 'Refunds',
  CUSTOMERS = 'Customers',
  BANKING = 'Banking (Team)'
}

export enum PermissionSettingKeys {
  TEAM_SETTINGS = 'teamSettings',
  INVOICES_AND_RECEIPTS = 'invoice',
  TIPS_AND_FEES = 'fees',
  REFUNDS = 'refund',
  CUSTOMERS = 'customers',
  BANKING = 'banking'
}
