import { Divider } from '@material-ui/core';
import classnames from 'classnames';
import { DeleteIcon, EditIcon, ListActionsIcon, VerticalListActionsIcon } from 'assets/icons';
import { CustomPopover } from 'components';

import React, { FC, useState } from 'react';
import { TaxActionType } from '../type';
import { twMerge } from 'tailwind-merge';
import { ITax } from '../taxes.model';
import { getFormattedPercentage } from '../util';

interface TaxTableProps {
  taxList: ITax[];
  handleMoreActionsClick: ({ taxAction, tax }: { taxAction: TaxActionType; tax: ITax }) => void;
}

const tableHeaders: Record<string, string> = {
  name: 'Name',
  role: 'Rate',
  status: 'Applied to'
};

const TaxTable: FC<TaxTableProps> = ({ taxList, handleMoreActionsClick }) => {
  const [currentActionsPopoverId, setCurrentActionsPopoverId] = useState<number>(null);

  // eslint-disable-next-line react/no-multi-comp
  const ListActionsAnchor: React.FC<{ isPanelOpen: boolean }> = ({ isPanelOpen }) => {
    return (
      <div id="tax-table-more-options" className="cursor-pointer py-2">
        <ListActionsIcon
          className={twMerge(
            'hidden shrink-0 lg:flex',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
        <VerticalListActionsIcon
          className={twMerge(
            'mx-2 flex shrink-0 lg:hidden',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
      </div>
    );
  };

  const onToggleListItemActions = (tax: ITax) => {
    if (tax.id === currentActionsPopoverId) {
      setCurrentActionsPopoverId(null);
    } else {
      setCurrentActionsPopoverId(tax.id);
    }
  };

  const isListItemActionsVisible = (taxId: number) => {
    return taxId === currentActionsPopoverId;
  };

  const onCloseListItemActionsPopover = (taxId: number) => {
    if (currentActionsPopoverId === taxId) {
      setCurrentActionsPopoverId(null);
    }
  };

  const getAppliedText = (name: string, count: number, applyToAll: boolean): string => {
    if (applyToAll) return `All ${name}s`;
    if (count === 1) return `1 ${name}`;
    return `${count} ${name}s`;
  };

  // eslint-disable-next-line react/no-multi-comp
  const ListItemActionsMenu: FC<any> = ({ id, tax }: { id: string; tax: ITax; isModifiable: boolean }) => {
    const handleEdit = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(tax.id);
      handleMoreActionsClick({ taxAction: TaxActionType.EDIT, tax });
    };

    const handleDelete = e => {
      e.stopPropagation();
      onCloseListItemActionsPopover(tax.id);
      handleMoreActionsClick({ taxAction: TaxActionType.DELETE, tax });
    };

    return (
      <div className="space-y-3 text-sbase font-semibold text-primaryText">
        <>
          <div
            id="tax-table-table-3dot-edit"
            className={'flex cursor-pointer flex-row items-center gap-2'}
            onClick={handleEdit}>
            <EditIcon className="path-fill-current path text-secondary" />
            <div>Edit</div>
          </div>
          <div
            id="tax-table-3dot-delete"
            onClick={handleDelete}
            className={'flex cursor-pointer flex-row items-center gap-2'}>
            <DeleteIcon />
            <div className="ml-0.5">Delete</div>
          </div>
        </>
      </div>
    );
  };

  return (
    <table className="relative w-full table-auto">
      <thead className="sticky top-[-4px] z-30 border-borderGray bg-primary">
        <Divider className="absolute top-[3.7rem] w-full bg-borderGray" />
        <tr className="text-slateGrey text-left text-xs lg:text-base [&>*]:py-5">
          {Object.values(tableHeaders).map((header, index) => {
            return (
              <th
                className={`max-w-[120px] text-sbase font-semibold text-primaryText 
                `}
                key={header + index}>
                {header}
              </th>
            );
          })}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {taxList?.map((tax, index) => (
          <tr
            key={tax.id}
            id={`tax-table-row-${index + 1}`}
            className={`remove-highlight align-center border-b 
            border-secondaryBtn [&>*]:py-5 [&>*]:pr-2 `}>
            {/* Name */}
            <td className="w-2/5">
              <td>
                <div className="text-sbase text-primaryText">
                  <div className="truncate sm:max-w-[260px] lg:max-w-none">{tax.name}</div>
                </div>
              </td>
            </td>
            {/* Rate */}
            <td className="max-w-[120px]">
              <div className="text-sbase text-primaryText">
                <div className="truncate">{getFormattedPercentage(tax.percentage)} %</div>
              </div>
            </td>
            {/* Applied to */}
            <td className="max-w-[120px]">
              <div className="text-sbase text-primaryText">
                <div className={'truncate text-px13'}>
                  {`${getAppliedText('item', tax.lineItemsCount, tax.applyToAllItems)} &
                  ${getAppliedText('service charge', tax.serviceChargesCount, tax.applyToAllServiceCharges)}`}
                </div>
              </div>
            </td>
            <td
              className="w-14 pr-2"
              onClick={e => {
                e.stopPropagation();
              }}>
              <div>
                <CustomPopover
                  showArrow={true}
                  minWidth="min-w-[113px]"
                  arrowOffset="right-6"
                  highlightAnchor={false}
                  anchorComponent={ListActionsAnchor}
                  zIndex="z-[101]"
                  offset="-right-6"
                  margin="mt-1"
                  onClosePopover={() => onCloseListItemActionsPopover(tax.id)}
                  show={isListItemActionsVisible(tax.id)}
                  onTogglePopover={() => onToggleListItemActions(tax)}>
                  {<ListItemActionsMenu id={tax.id} tax={tax} />}
                </CustomPopover>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default TaxTable;
