import { AccountFoundIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { VoidFn } from 'types/baseTypes';

interface ExistingAccountSectionProps {
  handleSignUpWithDiffNum: VoidFn;
  userPhoneNumber: string;
}

const ExistingAccountSection: FC<ExistingAccountSectionProps> = ({ handleSignUpWithDiffNum, userPhoneNumber }) => {
  const navigate = useNavigate();

  const handleSignIn = () => {
    navigate(routesPath.SIGNIN);
  };

  return (
    <div className="my-auto flex flex-col self-center lg:min-w-[375px]">
      <div className="flex flex-col items-center justify-center gap-5">
        <AccountFoundIcon />
        <div className="text-xl font-bold text-heading">Existing account found!</div>
        <div className="text-center text-sbase text-primaryText">
          <span className="font-bold">{userPhoneNumber}</span> is associated with an existing ScanPay account. if this
          is you, please sign in.
        </div>
      </div>
      <CustomButton id="sign-in-btn" className="mt-9 w-full" onClick={handleSignIn}>
        Sign in
      </CustomButton>
      <div
        id="login-instead"
        className="my-10 cursor-pointer text-center text-17px font-normal text-secondary"
        onClick={handleSignUpWithDiffNum}>
        Sign up with a different number
      </div>
    </div>
  );
};

export default ExistingAccountSection;
