import { NotifierTypes } from 'constants/notifierConstants';
import {
  selectCurrentTeam,
  selectUserDetails,
  showNotifier,
  updateEmailAndVerificationStatus
} from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { MerchantAddress, MerchantName, ResetPasswordPayload, UpdateMerchantProfilePayload } from '../myAccount.model';
import { selectMyAccountState, updateMerchantName, updateProfileEmail } from '../myAccountSlice';
import EmailModal from './EmailModal';
import SetPasswordModal from './SetPasswordModal';
import {
  useCreatePasswordMutation,
  useRequestOTPMutation,
  useUpdateEmailMutation,
  useVerifyOTPMutation
} from 'containers/auth/api';
import VerificationMethodModal from './VerificationMethodModal';
import { RequestOTPPayload, VerifyOTPPayload } from 'containers/auth/auth.model';
import { AccountSettingsFlowTypes, EmailErrorMessages, VerificationTypes } from '../types';
import VerifyAccountModal from './VerifyAccountModal';
import VerifyEmailModal from './VerifyEmailModal';
import { APIErrorStatuses } from 'services/type';
import { ErrorResponse, TeamRole } from 'containers/app/app.model';
import { GreenFilledTickIcon, OrangeDotIcon } from 'assets/icons';
import NameModal from './NameModal';
import { useUpdateMerchantProfileV3Mutation } from '../api';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsMyAccount } from '../events';
import { useLazyGetMerchantTeamsQuery } from 'containers/app/api';

interface ProfileDetailsSectionProps {
  refetchMerchantProfile: () => void;
  className?: string;
}

const ProfileDetailsSection: FC<ProfileDetailsSectionProps> = ({ refetchMerchantProfile }) => {
  const dispatch = useAppDispatch();
  const selectedMyAccountState = useAppSelector(selectMyAccountState);
  const { merchantAddress, mailingAddress } = selectedMyAccountState;
  const isPrimaryAddressEmpty = !(
    merchantAddress.apartment ||
    merchantAddress.city ||
    merchantAddress.country ||
    merchantAddress.state ||
    merchantAddress.street ||
    merchantAddress.zipCode
  );
  const isMailingAddressEmpty = !(
    mailingAddress.apartment ||
    mailingAddress.city ||
    mailingAddress.country ||
    mailingAddress.state ||
    mailingAddress.street ||
    mailingAddress.zipCode
  );

  const currentTeam = useAppSelector(selectCurrentTeam);
  const currentUser = useAppSelector(selectUserDetails);

  const [showEmailModal, setShowEmailModal] = useState(false);
  const [email, setEmail] = useState('');
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showVerfnMethodModal, setShowVerfnMethodModal] = useState({ open: false, flowType: null });
  const [showVerifyAccountModal, setShowVerifyAccountModal] = useState({ open: false, method: null });
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState({ open: false });
  const [isEmailVerification, setIsEmailVerification] = useState<boolean>(false);
  const [isHistoricPassword, setIsHistoricPassword] = useState<boolean>(false);
  const [currentFlow, setCurrentFlow] = useState<AccountSettingsFlowTypes>();
  const [emailError, setEmailError] = useState<EmailErrorMessages>(null);
  const [otpError, setOtpError] = useState('');
  const [showNameModal, setShowNameModal] = useState(false);
  const [name, setName] = useState<MerchantName>();

  const [
    createPasswordMutation,
    { isSuccess: isCreatePasswordSuccess, isLoading: isCreatePasswordInProgress, error: createPasswordError }
  ] = useCreatePasswordMutation();

  const [
    updateEmailMutation,
    {
      isSuccess: isUpdateEmailSuccess,
      isLoading: isUpdateEmailInProgress,
      isError: isUpdateEmailError,
      error: updateEmailError
    }
  ] = useUpdateEmailMutation();

  const [
    requestOTPMutation,
    { isSuccess: isRequestOTPSuccess, isLoading: isRequestOTPInProgress, isError: isRequestOTPFail }
  ] = useRequestOTPMutation();

  const [
    verifyOTPMutation,
    { isSuccess: isVerifyOTPSuccess, isLoading: isVerifyOTPInProgress, isError: isVerifyOTPFail, error: verifyOTPError }
  ] = useVerifyOTPMutation();

  const [
    updateMerchantProfile,
    {
      isSuccess: isUpdateMerchantProfileSuccess,
      isLoading: isUpdateMerchantProfileLoading,
      originalArgs: updateMerchantProfileOrginalArgs
    }
  ] = useUpdateMerchantProfileV3Mutation();

  const [getMerchantTeams] = useLazyGetMerchantTeamsQuery();

  const isEmailNotSet = selectedMyAccountState.email === '';

  const showGenericError = () => {
    dispatch(
      showNotifier({
        type: NotifierTypes.ERROR,
        message: {
          primaryMessage: 'Something went wrong, please try again after some time',
          isMessageHtml: false
        }
      })
    );
  };

  useEffect(() => {
    if (isUpdateEmailError) {
      if (updateEmailError) {
        const emailErrorResponse = updateEmailError as ErrorResponse;
        if (emailErrorResponse.status === APIErrorStatuses.STATUS_403) {
          setEmailError(EmailErrorMessages.ALREADY_EXISTS);
        }
      } else {
        showGenericError();
      }
    }
  }, [isUpdateEmailError]);

  useEffect(() => {
    if (isUpdateEmailSuccess) {
      const requestOTPPayload: RequestOTPPayload = {
        email: email,
        updateEmail: true
      };
      requestOTPMutation(requestOTPPayload);
      dispatch(
        showNotifier({
          message: {
            primaryMessage: isEmailNotSet ? 'Email added successfully' : 'Email updated successfully',
            isMessageHtml: true
          },
          type: NotifierTypes.SUCCESS,
          showClose: true,
          fontStyle: 'text-primary font-normal'
        })
      );
      dispatch(updateProfileEmail(email));
      dispatch(updateEmailAndVerificationStatus({ email, isEmailVerified: false }));
    }
  }, [isUpdateEmailSuccess]);

  useEffect(() => {
    if (isRequestOTPSuccess) {
      if (isEmailVerification) {
        setCurrentFlow(AccountSettingsFlowTypes.VERIFY_EMAIL);
        setShowVerifyEmailModal({ open: true });
        setShowEmailModal(false);
      } else {
        setShowVerifyAccountModal(prev => ({ ...prev, open: true }));
      }
      setShowVerfnMethodModal(prev => ({ ...prev, open: false }));
    }
  }, [isRequestOTPSuccess]);

  useEffect(() => {
    if (isVerifyOTPSuccess) {
      if (isEmailVerification) {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: 'Email verified successfully',
              isMessageHtml: false
            },
            type: NotifierTypes.SUCCESS,
            showClose: true,
            fontStyle: 'text-primary font-normal'
          })
        );
        setEmail('');
        setShowVerifyEmailModal({ open: false });
        setIsEmailVerification(false);
        dispatch(updateEmailAndVerificationStatus({ isEmailVerified: true }));
      } else {
        if (showVerfnMethodModal.flowType === AccountSettingsFlowTypes.UPDATE_EMAIL) {
          setShowEmailModal(true);
        } else {
          setShowPasswordModal(true);
        }
        setShowVerifyAccountModal({ open: false, method: null });
      }
      setShowVerfnMethodModal({ open: false, flowType: null });
    }
  }, [isVerifyOTPSuccess]);

  useEffect(() => {
    if (isCreatePasswordSuccess) {
      refetchMerchantProfile();
      toggleShowPasswordModal();

      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Password set successfully',
            isMessageHtml: true
          },
          type: NotifierTypes.SUCCESS,
          showClose: true,
          fontStyle: 'text-primary font-normal'
        })
      );
    }
  }, [isCreatePasswordSuccess, isCreatePasswordInProgress]);

  useEffect(() => {
    if (!isCreatePasswordSuccess && createPasswordError) {
      const passwordError = createPasswordError as ErrorResponse;
      if (passwordError.status === APIErrorStatuses.STATUS_403) {
        setIsHistoricPassword(true);
        logAnalyticEvent(CleverTapEventsMyAccount.webMyAccPasswordError, { error: passwordError.data.details });
      }
    }
  }, [isCreatePasswordSuccess, createPasswordError]);

  useEffect(() => {
    if (isVerifyOTPFail) {
      if (verifyOTPError) {
        const verifyOTPErrorResponse = verifyOTPError as ErrorResponse;
        if (verifyOTPErrorResponse.status === APIErrorStatuses.STATUS_500) {
          setOtpError('Invalid code');
        }
      } else {
        showGenericError();
      }
    }
  }, [isVerifyOTPFail]);

  useEffect(() => {
    if (isRequestOTPFail) {
      showGenericError();
    }
  }, [isRequestOTPFail]);

  useEffect(() => {
    if (isUpdateMerchantProfileSuccess) {
      toggleShowNameModal();
      dispatch(updateMerchantName(name));
      if (updateMerchantProfileOrginalArgs.callTeamsOnSuccess) {
        getMerchantTeams();
      }
    }
  }, [isUpdateMerchantProfileSuccess]);

  const displayFullName = () => {
    let fullName = '';
    const firstName = selectedMyAccountState.firstName;
    const middleName = selectedMyAccountState.middleName;
    const lastName = selectedMyAccountState.lastName;
    if (firstName != '') {
      fullName = firstName;
      if (middleName != '') {
        fullName = fullName + ' ' + middleName;
      }
      if (lastName != '') {
        fullName = fullName + ' ' + lastName;
      }
    }
    return fullName;
  };

  const displayAddress = (showAddress: MerchantAddress) => {
    let address = '';
    if (showAddress.apartment) address = showAddress.apartment;
    if (showAddress.street)
      address !== '' ? (address = address + ', ' + showAddress.street) : (address = showAddress.street);
    if (showAddress.city) address !== '' ? (address = address + ', ' + showAddress.city) : (address = showAddress.city);
    if (showAddress.state)
      address !== '' ? (address = address + ', ' + showAddress.state) : (address = showAddress.state);
    if (showAddress.zipCode)
      address !== ''
        ? (address = address + ', ' + ' US - ' + showAddress.zipCode)
        : (address = ' US - ' + showAddress.zipCode);
    return address;
  };

  const toggleShowPasswordModal = () => {
    setShowPasswordModal(prev => !prev);
  };

  const renderLabel = (label: string) => {
    return <div className="text-[15px] font-semibold text-accentText">{label}</div>;
  };

  const renderValue = (value, customClasses?: string) => {
    return (
      <div className={twMerge('text-[15px] text-heading font-semibold flex items-center', customClasses)}>{value}</div>
    );
  };

  const renderEmailCTA = () => {
    return (
      <div
        className="cursor-pointer whitespace-nowrap text-sbase font-semibold text-secondary"
        onClick={handleEmailClick}>
        {isEmailNotSet ? 'Add Email' : 'Update'}
      </div>
    );
  };

  const renderPasswordCTA = () => {
    return (
      <div className="cursor-pointer text-sbase font-semibold text-secondary" onClick={handleResetPasswordClick}>
        Reset
      </div>
    );
  };

  const allowNameEdit =
    (currentTeam.role === TeamRole.OWNER && !currentTeam.isUnitEnabled) || currentTeam.role !== TeamRole.OWNER;

  const renderNameCTA = () => {
    return (
      <div
        className="cursor-pointer whitespace-nowrap text-sbase font-semibold text-secondary"
        onClick={handleNameClick}>
        {isNameNotSet ? 'Add Name' : 'Update'}
      </div>
    );
  };

  const handleNameClick = () => {
    setShowNameModal(true);
    logAnalyticEvent(CleverTapEventsMyAccount.webMyAccUpdateNameClick, {});
  };

  const toggleShowNameModal = () => {
    setShowNameModal(prev => !prev);
  };

  const handleSaveNameClick = (nameValue: MerchantName) => {
    const nameObj: MerchantName = {
      firstName: nameValue.firstName?.trim(),
      middleName: nameValue?.middleName?.trim() ?? '',
      lastName: nameValue?.lastName?.trim()
    };
    setName({ ...nameObj });
    const updateMerchantProfilePayload: UpdateMerchantProfilePayload = {
      ...nameObj,
      teamId: currentTeam.id.toString(),
      callTeamsOnSuccess: true
    };
    updateMerchantProfile(updateMerchantProfilePayload);
  };

  const handleResetPasswordClick = () => {
    if (currentUser?.isEmailVerified) {
      setShowVerfnMethodModal({ open: true, flowType: AccountSettingsFlowTypes.RESET_PASSWORD });
    } else {
      setShowVerifyAccountModal({ open: true, method: VerificationTypes.TEXT });
    }
    setCurrentFlow(AccountSettingsFlowTypes.RESET_PASSWORD);
    setIsHistoricPassword(false);
    setOtpError('');
    logAnalyticEvent(CleverTapEventsMyAccount.webResetPasswordClick, {});
  };

  const handleEmailClick = () => {
    if (currentUser?.isEmailVerified) {
      setShowVerfnMethodModal({ open: true, flowType: AccountSettingsFlowTypes.UPDATE_EMAIL });
    } else {
      setShowEmailModal(true);
    }
    setCurrentFlow(AccountSettingsFlowTypes.UPDATE_EMAIL);
    setEmail('');
    setOtpError('');
    if (isEmailNotSet) {
      logAnalyticEvent(CleverTapEventsMyAccount.webAddEmailClick, {});
    } else {
      logAnalyticEvent(CleverTapEventsMyAccount.webUpdateEmailClick, {});
    }
  };

  const toggleShowEmailModal = () => {
    setShowEmailModal(prev => !prev);
  };

  const handleVerfnMethodModalToggle = () => {
    setShowVerfnMethodModal(prev => ({ ...prev, open: !prev.open }));
  };

  const handleVerifyAccModalToggle = () => {
    setShowVerifyAccountModal(prev => ({ ...prev, open: !prev.open }));
  };

  const handleVerifyEmailModalToggle = () => {
    setShowVerifyEmailModal(prev => ({ ...prev, open: !prev.open }));
    setIsEmailVerification(false);
  };

  const handleRequestOTP = (verfnMethod: VerificationTypes) => {
    const requestOTPPayload: RequestOTPPayload = {
      ...(showVerfnMethodModal.flowType === AccountSettingsFlowTypes.UPDATE_EMAIL
        ? { verifyEmail: true }
        : { resetPassword: true }),
      ...(verfnMethod === VerificationTypes.EMAIL
        ? { email: selectedMyAccountState.email }
        : { phone: selectedMyAccountState.phoneNumber })
    };
    requestOTPMutation(requestOTPPayload);
    setShowVerifyAccountModal(prev => ({ ...prev, method: verfnMethod }));
  };

  const handleVerifyAccount = (otpString: string) => {
    const verifyOTP: VerifyOTPPayload = {
      otp: otpString,
      verifyEmail: false,
      ...(showVerifyAccountModal.method === VerificationTypes.EMAIL
        ? { email: selectedMyAccountState.email }
        : { phone: selectedMyAccountState.phoneNumber })
    };
    verifyOTPMutation(verifyOTP);
  };

  const handleEmailVerification = (otpString: string) => {
    const verifyOTP: VerifyOTPPayload = {
      otp: otpString,
      verifyEmail: true,
      email: selectedMyAccountState.email
    };
    setIsEmailVerification(true);
    verifyOTPMutation(verifyOTP);
  };

  const onUpdateEmail = (emailId: string) => {
    updateEmailMutation({ email: emailId.toLowerCase() });
    setEmail(emailId.toLowerCase());
    setIsEmailVerification(true);
  };

  const onEditEmailFromVerify = () => {
    setShowEmailModal(true);
    setShowVerifyEmailModal({ open: false });
  };

  const onUpdatePassword = (password: string) => {
    if (currentTeam.id) {
      const payload: ResetPasswordPayload = { password };
      createPasswordMutation(payload);
    }
  };

  const isNameNotSet =
    selectedMyAccountState.firstName === '' &&
    selectedMyAccountState.middleName === '' &&
    selectedMyAccountState.lastName === '';

  const onSendVerificationClick = () => {
    const requestOTPPayload: RequestOTPPayload = {
      email: selectedMyAccountState.email,
      updateEmail: true
    };
    requestOTPMutation(requestOTPPayload);
    setIsEmailVerification(true);
    logAnalyticEvent(CleverTapEventsMyAccount.webMyAccSendVerificationEmailClick, {
      email: selectedMyAccountState.email
    });
  };

  const renderEmailField = () => {
    return (
      <div className="flex flex-col">
        <div className="flex items-baseline gap-2.5">
          <div
            className={twMerge(
              'text-[15px] leading-5 text-heading font-semibold flex items-center',
              isEmailNotSet ? 'text-accentText font-medium italic tracking-tight' : 'break-words line-clamp-2'
            )}>
            {isEmailNotSet ? 'Email address not added' : selectedMyAccountState.email}
          </div>
          {!isEmailNotSet && (
            <div className="flex shrink-0 items-baseline gap-0.5">
              {currentUser?.isEmailVerified ? (
                <>
                  <GreenFilledTickIcon /> <div className="text-px13 font-semibold text-tertiaryText">Verified</div>
                </>
              ) : (
                <>
                  <OrangeDotIcon />
                  <div className="text-px13 font-semibold text-accentText">Not verified</div>
                </>
              )}
            </div>
          )}
        </div>
        {!isEmailNotSet && !currentUser?.isEmailVerified && (
          <div
            className="w-fit cursor-pointer text-px13 font-semibold text-primaryBtn"
            onClick={onSendVerificationClick}>
            Send verification email
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="flex max-w-[650px] flex-col">
      <div className="grid grid-cols-4 grid-rows-6 gap-y-5 gap-x-1">
        <div className="col-span-1 row-span-1"> {renderLabel('Name')}</div>
        <div className={`${allowNameEdit ? 'col-span-2' : 'col-span-3'} row-span-1`}>
          {renderValue(
            isNameNotSet ? 'Name not added' : displayFullName(),
            isNameNotSet ? 'text-[15px] text-accentText font-medium italic tracking-tight' : 'text-[17px] leading-5'
          )}
        </div>
        {allowNameEdit && <div className="col-span-1 row-span-1">{renderNameCTA()}</div>}
        <div className="col-span-1 row-span-1">{renderLabel('Phone Number')}</div>
        <div className="col-span-3 row-span-1">{renderValue(selectedMyAccountState.phoneNumber)}</div>
        <div className="col-span-1 row-span-1">{renderLabel('Email')}</div>
        <div className="col-span-2 row-span-1">{renderEmailField()}</div>
        <div className="col-span-1 row-span-1">{renderEmailCTA()}</div>
        <div className="col-span-1 row-span-1">{renderLabel('Password')}</div>
        <div className="col-span-2 row-span-1">{renderValue('', 'invisible lg:w-[257px]')}</div>
        <div className="col-span-1 row-span-1">{renderPasswordCTA()}</div>
        <div className="col-span-1 row-span-1">{renderLabel('Primary address')}</div>
        <div className="col-span-3 row-span-1">
          {isPrimaryAddressEmpty ? (
            <div className="text-sbase font-medium italic text-accentText">Address not set</div>
          ) : (
            <div className="max-w-[240px] text-sbase font-semibold text-primaryText">
              <div>{displayAddress(merchantAddress)}</div>
            </div>
          )}
        </div>
        <div className="col-span-1 row-span-1"> {renderLabel('Mailing address')}</div>
        <div className="col-span-3 row-span-1">
          {isMailingAddressEmpty ? (
            <div className="text-sbase font-medium italic text-accentText">Address not set</div>
          ) : (
            <div className="max-w-[240px] text-sbase font-semibold text-primaryText">
              <div>{displayAddress(mailingAddress)}</div>
            </div>
          )}
        </div>
      </div>
      {showEmailModal && (
        <EmailModal
          isSaveInProgress={isUpdateEmailInProgress}
          handleSave={onUpdateEmail}
          open={showEmailModal}
          toggleOpen={toggleShowEmailModal}
          emailValue={email}
          emailError={emailError}
          setEmailError={setEmailError}
        />
      )}
      {showVerifyEmailModal.open && (
        <VerifyEmailModal
          open={showVerifyEmailModal.open}
          toggleOpen={handleVerifyEmailModalToggle}
          onVerifyOTP={handleEmailVerification}
          otpError={otpError}
          setOtpError={setOtpError}
          handleEditEmail={onEditEmailFromVerify}
        />
      )}
      {showVerfnMethodModal.open && (
        <VerificationMethodModal
          open={showVerfnMethodModal.open}
          toggleOpen={handleVerfnMethodModalToggle}
          handleSave={handleRequestOTP}
          isSaveInProgress={isRequestOTPInProgress}
        />
      )}
      {showVerifyAccountModal.open && (
        <VerifyAccountModal
          open={showVerifyAccountModal.open}
          toggleOpen={handleVerifyAccModalToggle}
          phoneNumber={selectedMyAccountState.phoneNumber}
          email={selectedMyAccountState.email}
          onVerifyOTP={handleVerifyAccount}
          isLoading={isVerifyOTPInProgress}
          otpError={otpError}
          setOtpError={setOtpError}
          methodType={showVerifyAccountModal.method}
          currentFlow={currentFlow}
        />
      )}
      {showPasswordModal && (
        <SetPasswordModal
          handleSave={onUpdatePassword}
          open={showPasswordModal}
          toggleOpen={toggleShowPasswordModal}
          oldPasswordMatchError={isHistoricPassword}
        />
      )}
      {showNameModal && (
        <NameModal
          open={showNameModal}
          onCancelClick={toggleShowNameModal}
          handleSave={handleSaveNameClick}
          isSaveInProgress={isUpdateMerchantProfileLoading}
        />
      )}
    </div>
  );
};

export default ProfileDetailsSection;
