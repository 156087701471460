import { FC, useState } from 'react';
import { EyeCloseIcon, EyeIcon, GreenTickIcon, RedCloseIcon } from 'assets/icons';
import { CustomButton, CustomInput, CustomModal, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { REGEX, preventDefaultFn } from 'constants/common';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsMyAccount } from '../events';
import { PasswordRequirements } from 'containers/auth/constants';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface SetPasswordModalProps {
  className?: string;
  handleSave: (password: string) => void;
  isSaveInProgress?: boolean;
  open: boolean;
  toggleOpen: VoidFn;
  oldPasswordMatchError: boolean;
}
const SetPasswordModal: FC<SetPasswordModalProps> = ({
  isSaveInProgress,
  handleSave,
  toggleOpen,
  open,
  oldPasswordMatchError
}) => {
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState(InputType.PASSWORD);
  const [confirmPasswordType, setConfirmPasswordType] = useState(InputType.PASSWORD);

  const [passwordCheck, setPasswordCheck] = useState({
    length: { show: true, msg: PasswordRequirements.CHARACTER_MSG },
    digit: { show: true, msg: PasswordRequirements.DIGIT_MSG },
    upperCase: { show: true, msg: PasswordRequirements.UPPERCASE_MSG },
    lowerCase: { show: true, msg: PasswordRequirements.LOWERCASE_MSG },
    specialChar: { show: true, msg: PasswordRequirements.SPECIAL_CHAR_MSG },
    validPassword: { show: true, msg: '' }
  });

  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const isContinueDisabled =
    !passwordCheck.validPassword.show || password.length === 0 || confirmPassword.length === 0 || !passwordMatch;

  const onCancelClick = () => {
    setPassword('');
    setConfirmPassword('');
    toggleOpen();
    logAnalyticEvent(CleverTapEventsMyAccount.webMyAccResetPasswordBackClick, {});
  };

  const validateError = (value: string) => {
    if (REGEX.containsSpecialChar.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        specialChar: {
          ...prevState.specialChar,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        specialChar: {
          ...prevState.specialChar,
          show: true
        }
      }));
    }
    if (REGEX.containsUpperCase.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        upperCase: {
          ...prevState.upperCase,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        upperCase: {
          ...prevState.upperCase,
          show: true
        }
      }));
    }

    if (REGEX.containsLowerCase.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        lowerCase: {
          ...prevState.lowerCase,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        lowerCase: {
          ...prevState.lowerCase,
          show: true
        }
      }));
    }

    if (REGEX.containsDigit.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        digit: {
          ...prevState.digit,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        digit: {
          ...prevState.digit,
          show: true
        }
      }));
    }
    if (value.length >= 8) {
      setPasswordCheck(prevState => ({
        ...prevState,
        length: {
          ...prevState.length,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        length: {
          ...prevState.length,
          show: true
        }
      }));
    }
    if (REGEX.password.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        validPassword: {
          ...prevState.validPassword,
          show: true
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        validPassword: {
          ...prevState.validPassword,
          show: false
        }
      }));
    }
  };

  const checkPasswordMatch = (pass: string, confirmPass: string) => {
    if (pass === confirmPass) {
      setPasswordMatch(true);
      return;
    }
    setPasswordMatch(false);
  };

  const handlePasswordChange = (value: string) => {
    setPassword(value);
    validateError(value);
    if (isContinueClicked) {
      checkPasswordMatch(value, confirmPassword);
    }
  };

  const handleConfirmPasswordChange = (value: string) => {
    setConfirmPassword(value);
    setPasswordMatch(true);
    if (isContinueClicked) {
      checkPasswordMatch(password, value);
    }
  };

  const handleContinueClick = () => {
    setIsContinueClicked(true);
    checkPasswordMatch(password, confirmPassword);
    if (password === confirmPassword) {
      handleSave(password);
    }
    logAnalyticEvent(CleverTapEventsMyAccount.webMyAccResetPasswordContinueClick, {});
  };

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleContinueClick();
    }
  };

  return (
    <CustomModal width="md:w-[609px] min-h-[223px]" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded p-10">
        <div className="flex flex-col items-center justify-center">
          <FormCloseReactIcon
            id="set-password-modal-close"
            height="24"
            width="24"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onCancelClick}
          />
          <div className="flex w-full flex-col">
            <div className="mb-4 text-xl font-bold leading-6 text-heading">Create a new password</div>
            <CustomInput
              id="set-password-input"
              onCopy={preventDefaultFn}
              onCut={preventDefaultFn}
              inputStyle="w-full text-17px"
              type={passwordType}
              hasSuffix={true}
              suffix={passwordType === InputType.PASSWORD ? <EyeIcon /> : <EyeCloseIcon />}
              suffixStyle="top-3 right-3"
              autoComplete="off"
              onChange={handlePasswordChange}
              isPasswordField={true}
              setPasswordType={setPasswordType}
              value={password}
              className={`block h-[50px] rounded border px-4 pr-12
               font-semibold text-primaryText placeholder:text-[17px] 
               placeholder:font-semibold placeholder:text-accentText focus:outline-none
               ${oldPasswordMatchError && 'border-error'}`}
              placeholder="Enter password"
            />
            <If condition={!passwordCheck.validPassword.show}>
              <div className=" mt-5 rounded-md border border-borderGray py-4 px-2 text-sbase text-primaryText">
                <p className={`flex ${passwordCheck.length.show ? 'gap-2' : 'gap-3'}`}>
                  {passwordCheck.length.show ? (
                    <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
                  ) : (
                    <GreenTickIcon className="mt-1" />
                  )}
                  {passwordCheck.length.msg}
                </p>
                <p className={`mt-3 flex ${passwordCheck.upperCase.show ? 'gap-2' : 'gap-3'}`}>
                  {passwordCheck.upperCase.show ? (
                    <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
                  ) : (
                    <GreenTickIcon className="mt-1" />
                  )}
                  {passwordCheck.upperCase.msg}
                </p>

                <p className={`mt-3 flex ${passwordCheck.lowerCase.show ? 'gap-2' : 'gap-3'}`}>
                  {passwordCheck.lowerCase.show ? (
                    <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
                  ) : (
                    <GreenTickIcon className="mt-1" />
                  )}
                  {passwordCheck.lowerCase.msg}
                </p>

                <p className={`mt-3 flex ${passwordCheck.digit.show ? 'gap-2' : 'gap-3'}`}>
                  {passwordCheck.digit.show ? (
                    <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
                  ) : (
                    <GreenTickIcon className="mt-1" />
                  )}
                  {passwordCheck.digit.msg}
                </p>
                <p className={`mt-3 flex ${passwordCheck.specialChar.show ? 'gap-2' : 'gap-3'}`}>
                  {passwordCheck.specialChar.show ? (
                    <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
                  ) : (
                    <GreenTickIcon className="mt-1" />
                  )}
                  {passwordCheck.specialChar.msg}
                </p>
              </div>
            </If>
            <CustomInput
              id="confirm-password-input"
              onCopy={preventDefaultFn}
              onCut={preventDefaultFn}
              inputStyle="w-full text-17px"
              autoComplete="off"
              type={confirmPasswordType}
              hasSuffix={true}
              suffix={confirmPasswordType === InputType.PASSWORD ? <EyeIcon /> : <EyeCloseIcon />}
              suffixStyle="top-8 right-3"
              onChange={handleConfirmPasswordChange}
              isPasswordField={true}
              value={confirmPassword}
              setPasswordType={setConfirmPasswordType}
              onKeyPress={handleKeyDown}
              className={`mt-5 block h-[50px] rounded border px-4 pr-12
               font-semibold text-primaryText placeholder:text-[17px] 
               placeholder:font-semibold placeholder:text-accentText focus:outline-none
               ${(oldPasswordMatchError || (!passwordMatch && confirmPassword.length !== 0)) && 'border-error'}`}
              placeholder="Re-enter new password"
            />
            {!passwordMatch && confirmPassword.length !== 0 && (
              <div className="mt-1 text-sbase font-semibold text-error">Passwords do not match</div>
            )}
            <If condition={oldPasswordMatchError && passwordMatch}>
              <div className="mt-1 text-sbase font-semibold text-error">
                New password can not match any of your last 3 passwords
              </div>
            </If>
          </div>
        </div>
        <div className="flex flex-row items-end justify-end gap-5">
          <CustomButton
            id="set-password-modal-cancel-button"
            className="mt-6 h-[38px] w-[100px]"
            type={ButtonType.SECONDARY}
            onClick={onCancelClick}>
            Back
          </CustomButton>
          <CustomButton
            id="set-passowrd-modal-save-button"
            isLoading={isSaveInProgress}
            onClick={handleContinueClick}
            disabled={isContinueDisabled}
            className={`text-semibold mt-5 h-[38px] w-[150px] rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
            Continue
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
export default SetPasswordModal;
