import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import {
  CreatePasswordPayload,
  CreatePasswordResponse,
  DownloadInviteLinkPayload,
  EmailSignInPayload,
  InitiateEmailSignInPayload,
  InitiatePhoneSignInPayload,
  InitiateSignInResponse,
  InitiateSignUpPayload,
  InitiateSignupResponse,
  InviteLinkResponse,
  LogoutPayload,
  PhoneSignInPayload,
  RequestOTPPayload,
  SignInResponse,
  SignUpPayload,
  SignUpResponse,
  Token,
  VerifyOTPPayload,
  sendSMSPayload
} from './auth.model';
import {
  AuthState,
  loggedOut,
  onEnterPasswordSignIn,
  onSignUp,
  resetAuthState,
  setInitialSignIn,
  setTempCredentials,
  setTokens,
  updateInviteResponse
} from './authSlice';
import {
  resetAppState,
  setInitiateSignInUserFlags,
  stopIsLoadingUserDetails,
  updateIsDeviceAuthenticated,
  updateUserDetailsEmail
} from 'containers/app/appSlice';
import { CampaignValues } from './constants';
import { destroyFreshChat } from 'services/freshchat';
import { LocalStorage } from 'services/storage';

// !TODO Refactor
const AuthBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    sendDownloadLinkAsSMS: builder.mutation<any, sendSMSPayload>({
      query: payload => ({
        url: `${API_VERSION.V1}/notifications/sms/onelink`,
        method: 'POST',
        body: payload
      }),

      extraOptions: {
        failure: 'Some error occurred, SMS not sent',
        success: 'SMS successfully sent',
        showToast: true
      }
    }),

    getDownloadLink: builder.query<InviteLinkResponse, DownloadInviteLinkPayload>({
      query: payload => {
        let url = `${API_VERSION.V1}/invite-link`;
        if (payload?.type) {
          const { type, token } = payload;
          if (type === CampaignValues.RESELLER) {
            url = `${url}?resellerToken=${token}`;
          } else if (type === CampaignValues.TEAM_INVITE) {
            url = `${url}?teamInviteToken=${token}`;
          }
        }
        return {
          url: url,
          method: 'GET'
        };
      },

      extraOptions: {
        showToast: false
      }
    }),

    logout: builder.mutation<string, LogoutPayload>({
      query: ({ userName, idToken, refreshToken }) => {
        return {
          url: `${API_VERSION.V1}/auth/logout`,
          method: 'POST',
          body: { userName, refreshToken, idToken }
        };
      },

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data === 'SUCCESS') {
          LocalStorage.clear();
          destroyFreshChat();
          dispatch(resetAppState());
          dispatch(stopIsLoadingUserDetails());
          dispatch(resetAuthState());
          dispatch(loggedOut());
        }
      },
      extraOptions: {
        failure: 'Failed to logout',
        // eslint-disable-next-line quotes
        success: "You've successfully signed out",
        showToast: true
      }
    }),
    skipEmailVerification: builder.mutation<void, void>({
      query: () => {
        return {
          url: `${API_VERSION.V1}/auth/skip-email-verification`,
          method: 'POST'
        };
      }
    }),

    cancelEmailPrompt: builder.mutation<void, void>({
      query: () => {
        return {
          url: `${API_VERSION.V1}/auth/cancel-email-prompt`,
          method: 'POST'
        };
      }
    }),

    updateEmail: builder.mutation<void, { email: string }>({
      query: ({ email }) => {
        return {
          url: `${API_VERSION.V1}/merchants/update-email`,
          method: 'POST',
          body: {
            email
          }
        };
      },

      async onQueryStarted({ email }, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(updateUserDetailsEmail(email));
        } catch (err) {
          /* empty */
        }
      }
    }),

    createPassword: builder.mutation<CreatePasswordResponse, CreatePasswordPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/auth/create-password`,
          method: 'POST',
          body: payload
        };
      },

      async onQueryStarted({ includeToken }, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          // dispatch action to set tokens after creating a new password post expiry or
          //  after creating the first password
          if (data && includeToken) {
            const { token } = data;

            const actionPayload: Token & { isAuthenticated: boolean } = {
              accessToken: token?.accessToken,
              idToken: token?.idToken,
              refreshToken: token?.refreshToken,
              isAuthenticated: true
            };

            dispatch(setTokens(actionPayload));
            dispatch(setInitialSignIn(true));
          }
          // eslint-disable-next-line no-empty
        } catch (err) {}
      }
    }),

    initiateSignIn: builder.mutation<InitiateSignInResponse, InitiateEmailSignInPayload | InitiatePhoneSignInPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/auth/initiate-sign-in`,
          method: 'POST',
          body: payload.body,
          credentials: 'include'
        };
      },

      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          const {
            tempToken,
            isEmailVerified,
            isDeviceAuthenticated,
            isPasswordCreated,
            relatedAccounts,
            email,
            phone
          } = data;

          const creds: AuthState = {
            accessToken: tempToken.accessToken,
            idToken: tempToken.idToken,
            refreshToken: tempToken.refreshToken,
            user: null,
            isAuthenticated: false,
            hasInitiatedSignIn: true,
            currentSignInMethod: payload.signInMethod,
            isEmailVerified: isEmailVerified,
            isDeviceAuthenticated: isDeviceAuthenticated,
            isPasswordCreated: isPasswordCreated,
            relatedAccounts: relatedAccounts,
            currentSignedInEmail: email,
            currentSignedInPhone: phone
          };

          const userDataFlags = {
            isEmailVerified: isEmailVerified,
            isPasswordCreated: isPasswordCreated
          };
          dispatch(resetAppState());
          LocalStorage.clear();
          dispatch(setTempCredentials(creds));
          // TODO Remove user data flags from authSlice if they are unnecessary
          dispatch(setInitiateSignInUserFlags(userDataFlags));
        }
      }
    }),

    signIn: builder.mutation<SignInResponse, EmailSignInPayload | PhoneSignInPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/auth/sign-in`,
          method: 'POST',
          body: payload,
          credentials: 'include'
        };
      },

      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          const { token, isDeviceAuthenticated, ...rest } = data;

          const creds: AuthState = {
            accessToken: token.accessToken,
            idToken: token.idToken,
            refreshToken: token.refreshToken,
            user: { ...rest, isDeviceAuthenticated },
            // Note: allow entry only after user-data api becomes successful
            isAuthenticated: false,
            hasInitiatedSignIn: true,
            isDeviceAuthenticated: isDeviceAuthenticated,
            isInitialSignIn: true
          };
          dispatch(onEnterPasswordSignIn(creds));
          // TODO dispatch skipperAuthentication as well once backend is ready
          dispatch(updateIsDeviceAuthenticated(isDeviceAuthenticated));
        }
      }
    }),

    requestOTP: builder.mutation<void, RequestOTPPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/auth/request-otp`,
          method: 'POST',
          body: payload
        };
      }
    }),

    // This call will be used for device authentication and email verification
    verifyOTP: builder.mutation<void, VerifyOTPPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/auth/verify-otp`,
          method: 'POST',
          body: payload,
          credentials: 'include'
        };
      },
      extraOptions: {
        success: 'OTP verified successfully'
      }
    }),

    initiateSignup: builder.mutation<InitiateSignupResponse, InitiateSignUpPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/auth/initiate-sign-up`,
          method: 'POST',
          body: payload
        };
      },
      async onQueryStarted(payload, { dispatch }) {
        dispatch(resetAppState());
        LocalStorage.clear();
      }
    }),

    signup: builder.mutation<SignUpResponse, SignUpPayload>({
      query: payload => {
        return {
          url: `${API_VERSION.V1}/auth/sign-up`,
          method: 'POST',
          body: payload,
          credentials: 'include'
        };
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          const { token, inviteResponse, userName, name } = data;

          const creds: AuthState = {
            accessToken: token.accessToken,
            idToken: token.idToken,
            refreshToken: token.refreshToken,
            // Note: allow entry only after user-data api becomes successful
            isAuthenticated: false,
            isDeviceAuthenticated: payload.authenticateDevice,
            hasInitiatedSignUp: true,
            user: {
              isDeviceAuthenticated: payload.authenticateDevice,
              isPasswordActive: false,
              skipIamFlow: false,
              isNewUser: true,
              name: name,
              userName: userName
            }
          };
          dispatch(onSignUp(creds));
          dispatch(updateInviteResponse(inviteResponse));
          dispatch(updateIsDeviceAuthenticated(payload.authenticateDevice));
        }
      }
    })
  })
});

export const {
  useLogoutMutation,
  useSendDownloadLinkAsSMSMutation,
  useLazyGetDownloadLinkQuery,
  useSignupMutation,
  useInitiateSignupMutation,
  useVerifyOTPMutation,
  useRequestOTPMutation,
  useSignInMutation,
  useInitiateSignInMutation,
  useCreatePasswordMutation,
  useUpdateEmailMutation,
  useCancelEmailPromptMutation,
  useSkipEmailVerificationMutation
} = AuthBaseApi;
