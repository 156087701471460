import {
  AccountApprovedIcon,
  AccountDeniedIcon,
  ArrowRightIcon,
  CACompletedIcon,
  CircleCloseIcon,
  DetailsSubmittedIcon,
  EnablePayouts75ProgressIcon,
  GreenFilledTickIcon,
  ModalCloseIcon,
  OrangeStrokeInfoIcon,
  RedStrokeInfoIcon,
  SubmitDetailsIcon,
  VerifyingDetailsIcon
} from 'assets/icons';
import { CustomButton } from 'components';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import ActionCard from './ActionCard';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { BankAccountCardType, ConnectBankStatuses, EnablePayoutStatuses } from 'containers/payouts/constants';
import { useAppSelector } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { PayoutsCleverTapEvents } from 'containers/payouts/events';
import routesPath from 'routes/RoutesPath';
import { logAnalyticEvent } from 'utils/analytics';
import AddBankAccountCard from 'containers/payouts/payouts-settings/components/AddBankAccountCard';
import ApplicationApprovedCard from 'containers/payouts/payouts-settings/components/ApplicationApprovedCard';
import { ONBOARDING_URL_SOURCES } from 'constants/common';
import { ButtonType } from 'components/custom-button/CustomButton';
import { getEnablePayoutsIcon } from 'containers/payouts/utils';
import { useLazyGetTeamConnectBankAccountQuery } from 'containers/home/api';
import { LoadingSpinnerAnimation } from 'assets/animations';
import Lottie from 'lottie-react';

interface ConnectBankModalProps {
  onClose: VoidFn;
  onBankCardClick: VoidFn;
  isRootPage: boolean;
  onBack: VoidFn;
  isFromFCModal: boolean;
  from: ONBOARDING_URL_SOURCES;
}

const ConnectBankModal: FC<ConnectBankModalProps> = ({
  onClose,
  onBankCardClick,
  isRootPage,
  onBack,
  isFromFCModal,
  from
}) => {
  const navigate = useNavigate();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { connectApplicationStatus, connectBankingStatus } = currentTeam ?? {};
  const [getTeamConnectBankAccountQuery, getTeamConnectBankAccountState] = useLazyGetTeamConnectBankAccountQuery();
  const { data: teamConnectBankAccount, isLoading } = getTeamConnectBankAccountState;
  const { accountNumber, bankName } = teamConnectBankAccount ?? {};
  const [showToast, setShowToast] = useState(false);

  const isBankConnected =
    (connectApplicationStatus === EnablePayoutStatuses.APPROVED ||
      connectApplicationStatus === EnablePayoutStatuses.APPROVED_CONDITIONAL) &&
    connectBankingStatus === ConnectBankStatuses.SUBMITTED;

  useEffect(() => {
    let timeOut;
    if (connectBankingStatus === ConnectBankStatuses.SUBMITTED) {
      getTeamConnectBankAccountQuery({ teamId: currentTeam.id });
      if (isFromFCModal) {
        setShowToast(true);

        timeOut = setTimeout(() => {
          setShowToast(false);
        }, 5000);
      }
    }
    return () => {
      clearTimeout(timeOut);
    };
  }, [connectBankingStatus, currentTeam]);

  const getKYCKYBCardDetails = () => {
    switch (connectApplicationStatus) {
      case EnablePayoutStatuses.NOT_STARTED:
      case EnablePayoutStatuses.STARTED_IN_PROGRESS:
        return {
          Icon: SubmitDetailsIcon,
          title: 'Confirm your details',
          subtitle: 'Submit your KYC/KYB details for review',
          className: 'cursor-pointer'
        };
      case EnablePayoutStatuses.SUBMITTED_IN_REVIEW:
        return {
          Icon: DetailsSubmittedIcon,
          title: 'Business details submitted',
          subtitle: 'Verifying your details',
          className: 'border border-[#DBEFFD] cursor-pointer'
        };
      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED:
      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED:
        return {
          Icon: VerifyingDetailsIcon,
          title: 'Verifying your details',
          subtitle: (
            <div className="flex items-center gap-1.5 text-sbase text-primaryText">
              <OrangeStrokeInfoIcon />
              Action needed
            </div>
          ),
          className: 'bg-[#FDF9F2] border-2 border-[#F8A547] cursor-pointer'
        };
      case EnablePayoutStatuses.APPROVED:
      case EnablePayoutStatuses.APPROVED_CONDITIONAL:
        return {
          Icon: AccountApprovedIcon,
          title: 'Business details confirmed',
          subtitle: '',
          className: 'border-none'
        };
      case EnablePayoutStatuses.REJECTED:
        return {
          Icon: AccountDeniedIcon,
          title: 'Review complete',
          subtitle: (
            <div className="flex items-center gap-1.5 text-sbase text-error">
              <RedStrokeInfoIcon />
              Application denied
            </div>
          ),
          className: 'bg-[#F7E2C0] border-2 border-[#D95945] cursor-pointer'
        };
      default:
        return null;
    }
  };

  const { Icon: DetailsIcon, title: detailsTitle, subtitle: detailsSubtitle, className } = getKYCKYBCardDetails() ?? {};

  const handleDetailsClick = () => {
    switch (connectApplicationStatus) {
      case EnablePayoutStatuses.NOT_STARTED:
      case EnablePayoutStatuses.STARTED_IN_PROGRESS:
        logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsSubmitDetailsClick);
        return navigate(`${routesPath.CONNECT_ONBOARDING}?from=${from}`);

      case EnablePayoutStatuses.SUBMITTED_IN_REVIEW:
        logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsPopupInReviewClick);
        return navigate(`${routesPath.BANK_APPLICATION_STATUS}?from=${from}`);

      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED:
      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED:
        return navigate(`${routesPath.BANK_APPLICATION_STATUS}?from=${from}`);

      case EnablePayoutStatuses.APPROVED:
      case EnablePayoutStatuses.APPROVED_CONDITIONAL:
        return null;

      case EnablePayoutStatuses.REJECTED:
        logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsPopupRejectedClick);
        return navigate(`${routesPath.BANK_APPLICATION_STATUS}?from=${from}`);

      default:
        return null;
    }
  };

  const getProgressIcon = () => {
    if (isBankConnected) {
      return CACompletedIcon;
    } else if (connectBankingStatus === ConnectBankStatuses.SUBMITTED) {
      return EnablePayouts75ProgressIcon;
    }
    return getEnablePayoutsIcon(connectApplicationStatus);
  };

  const ProgressIcon = getProgressIcon();

  const handleBankCardClick = () => {
    if (isBankCardEnabled) {
      if (connectBankingStatus === ConnectBankStatuses.NOT_SUBMITTED) {
        onBankCardClick();
      } else if (connectBankingStatus === ConnectBankStatuses.SUBMITTED && isBankConnected) {
        navigate(routesPath.PAYOUTS_SETTINGS);
      }
    }
  };

  const isDetailsActionCardClickable =
    connectApplicationStatus !== EnablePayoutStatuses.APPROVED &&
    connectApplicationStatus !== EnablePayoutStatuses.APPROVED_CONDITIONAL;

  const isBankCardEnabled =
    connectApplicationStatus !== EnablePayoutStatuses.STARTED_IN_PROGRESS &&
    connectApplicationStatus !== EnablePayoutStatuses.NOT_STARTED;

  const isBankCardClickable =
    isBankCardEnabled &&
    (connectBankingStatus === ConnectBankStatuses.NOT_SUBMITTED ||
      (connectBankingStatus === ConnectBankStatuses.SUBMITTED && isBankConnected));

  return (
    <>
      <div className="w-full py-4">
        {isRootPage ? (
          <div className="absolute top-3 right-3">
            <CircleCloseIcon className="rotate-180 cursor-pointer" onClick={onClose} width={20} height={20} />
          </div>
        ) : (
          <div className="flex p-5 pb-2.5">
            <ArrowRightIcon className="rotate-180 cursor-pointer" onClick={onBack} width={20} height={20} />
          </div>
        )}
        {isLoading ? (
          <div className="flex h-[570px] w-full items-center justify-center md:min-h-[775px] ">
            <Lottie className="h-48 w-48" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          <div className="px-[30px] pt-16">
            <div className="pb-[30px]">
              {ProgressIcon && <ProgressIcon className="circle-fill-current circle text-secondaryBtn" />}
            </div>
            <div className="text-2xl font-semibold text-primaryText">Connect another bank account</div>
            <div className="pt-1 text-sbase text-primaryText">
              Please complete the following steps to help us keep your payments secure.
            </div>
            <div className="py-5">
              <ActionCard
                Icon={DetailsIcon}
                title={detailsTitle}
                subtitle={detailsSubtitle}
                isClickable={isDetailsActionCardClickable}
                onClick={handleDetailsClick}
                className={className}
              />
              <div className="z-10 ml-10 h-5 w-0.5 border-l border-dashed border-border"></div>
              <div className={isBankCardClickable && 'cursor-pointer'} onClick={handleBankCardClick}>
                {connectBankingStatus === ConnectBankStatuses.SUBMITTED ? (
                  <ApplicationApprovedCard
                    text="Bank account connected"
                    subtext={`${bankName} ${accountNumber}`}
                    showArrow={isBankConnected}
                  />
                ) : (
                  <AddBankAccountCard
                    type={BankAccountCardType.ADD_BANK_ACCOUNT}
                    isEnabled={isBankCardEnabled}
                    showArrow={isBankCardEnabled}
                  />
                )}
              </div>
            </div>
            <div className="pt-10">
              {isBankConnected && (
                <CustomButton
                  type={ButtonType.SECONDARY}
                  className="w-full border-gray-1"
                  id="connect-bank-modal-done"
                  onClick={onClose}>
                  Done
                </CustomButton>
              )}
              {showToast && (
                <div
                  className="my-3 flex gap-2 rounded-[10px] border border-successHighlight
               bg-success px-5 py-4 shadow-md">
                  <GreenFilledTickIcon className="mt-1 shrink-0" width={18} height={18} />
                  <div className="text-sbase text-heading">New bank account successfully linked.</div>
                  <ModalCloseIcon className="mt-1 shrink-0 cursor-pointer" onClick={() => setShowToast(false)} />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ConnectBankModal;
