import { Popover, Transition } from '@headlessui/react';
import { FC, Fragment, useContext, useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import AddItemInput from './AddItemInput';
import ItemSuggestionsPanel from './ItemSuggestionsPanel';
import { useGetCatalogRecentItemsQuery } from 'containers/product-catalog/items/api';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import { IInvoiceSuggestedItem } from 'containers/product-catalog/items/items.model';
import { DraggableItemIcon } from 'assets/icons';
import { EditInvoiceContext } from 'containers/invoices/edit-invoice/EditInvoice';
import { CreateInvoiceContext } from '../CreateInvoice';
import { InvoiceTabEnum } from '../constants';

interface AddLineItemSectionProps {
  searchInputvalue: string;
  onSearchInputChange: (text: string) => void;
  onClearSearchInput: VoidFn;
  wrapperStyle?: string;
  searchId: string;
  showSuggestionList?: boolean;
  onCreateOneTimeItem: () => void;
  isEditInvoiceFlow?: boolean;
  handleAddFromCatalogClick: () => void;
  onSuggestedItemClick: (arg0: IInvoiceSuggestedItem) => void;
}

const AddLineItemSection: FC<AddLineItemSectionProps> = ({
  searchId,
  searchInputvalue,
  onSearchInputChange,
  onClearSearchInput,
  wrapperStyle,
  showSuggestionList = false,
  onCreateOneTimeItem,
  isEditInvoiceFlow = false,
  handleAddFromCatalogClick,
  onSuggestedItemClick
}) => {
  const { setSelectedInvoiceTab } = useContext<any>(isEditInvoiceFlow ? EditInvoiceContext : CreateInvoiceContext);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { data: getCatalogRecentItemsResponse } = useGetCatalogRecentItemsQuery({ teamId: currentTeam?.id });
  const { recentItems: recentItemsResponse = [], libraryItems: libraryItemsResponse = [] } =
    getCatalogRecentItemsResponse ?? {};
  const recentItems = recentItemsResponse ?? [];
  const libraryItems = libraryItemsResponse ?? [];

  const [isSuggestionsPanelVisible, setIsSuggestionsPanelVisible] = useState<boolean>(false);

  const [recentItemsState, setRecentItemsState] = useState<IInvoiceSuggestedItem[]>([]);

  const [libraryItemsState, setLibraryItemsState] = useState<IInvoiceSuggestedItem[]>([]);

  const isSuggestionListAbsent = recentItems.length === 0 && libraryItems.length === 0;

  useEffect(() => {
    setRecentItemsState(recentItems ?? []);
    setLibraryItemsState(libraryItems ?? []);
  }, [getCatalogRecentItemsResponse]);

  const handleSearchInputChange = (text: string) => {
    if (!isSuggestionsPanelVisible && showSuggestionList && (!isSuggestionListAbsent || text.length > 0)) {
      setIsSuggestionsPanelVisible(true);
    }
    if (text.length < 1 && isSuggestionListAbsent) {
      setIsSuggestionsPanelVisible(false);
    }
    onSearchInputChange(text);
    if (text.trim() !== '') {
      const searchFilteredRecentItems =
        recentItems.filter(
          item => item.name.toLowerCase().includes(text.toLowerCase())
          // eslint-disable-next-line function-paren-newline
        ) ?? [];
      const searchFilteredLibraryItems =
        libraryItems.filter(
          item => item.name.toLowerCase().includes(text.trim().toLowerCase())
          // eslint-disable-next-line function-paren-newline
        ) ?? [];
      setRecentItemsState(searchFilteredRecentItems);
      setLibraryItemsState(searchFilteredLibraryItems);
    } else {
      setRecentItemsState(recentItems ?? []);
      setLibraryItemsState(libraryItems ?? []);
    }
  };

  const onClearSearch = () => {
    onClearSearchInput();
    if (isSuggestionListAbsent) {
      setIsSuggestionsPanelVisible(false);
      return;
    }
    setRecentItemsState(recentItems);
    setLibraryItemsState(libraryItems);
  };

  const onSelectSuggestedItem = (item: IInvoiceSuggestedItem) => {
    onSuggestedItemClick(item);
    setIsSuggestionsPanelVisible(false);
    onClearSearch();
  };

  const onOutsideClick = () => {
    setIsSuggestionsPanelVisible(false);
  };

  const onCreateOneTimeItemClick = (): void => {
    onClearSearch();
    setIsSuggestionsPanelVisible(false);
    onCreateOneTimeItem();
  };

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      onCreateOneTimeItemClick();
    }
  };

  const onClickInput = () => {
    setSelectedInvoiceTab(InvoiceTabEnum.LINE_ITEMS_INPUT);
    if (!isSuggestionListAbsent || searchInputvalue.length > 0) {
      setIsSuggestionsPanelVisible(true);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={onOutsideClick}>
      <div className={twMerge('w-full', wrapperStyle)}>
        <Popover className="relative flex w-full">
          {() => (
            <>
              <div className="relative flex w-full">
                <AddItemInput
                  isEditInvoiceFlow={isEditInvoiceFlow}
                  alignRight={true}
                  id={searchId}
                  autoFocus={false}
                  value={searchInputvalue}
                  handleClear={onClearSearch}
                  placeholder={'Add item'}
                  showSearchIcon={true}
                  handleChange={handleSearchInputChange}
                  wrapperStyle="h-[50px] bg-secondaryBtn rounded"
                  showCrossIcon={true}
                  inputWrapperStyle="gap-0"
                  inputStyle="placeholder:text-accentText placeholder:font-normal"
                  handleAddFromCatalogClick={handleAddFromCatalogClick}
                  handleKeyDown={handleKeyDown}
                  onClickInput={onClickInput}
                />
                <Transition
                  show={isSuggestionsPanelVisible}
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1">
                  <Popover.Panel
                    className="absolute top-12 z-40 w-[70%]
                              overflow-y-auto rounded-md border border-borderGray bg-primary shadow-md">
                    <ItemSuggestionsPanel
                      inputText={searchInputvalue.trim()}
                      onSelectListItem={onSelectSuggestedItem}
                      recentItemsList={recentItemsState}
                      libraryItemsList={libraryItemsState}
                      onCreateOneTimeItem={onCreateOneTimeItemClick}
                    />
                  </Popover.Panel>
                </Transition>

                <DraggableItemIcon
                  className="absolute top-1/2 -left-5 my-auto shrink-0 -translate-y-2/4 
                    cursor-not-allowed 1439px:-left-4"
                />
              </div>
            </>
          )}
        </Popover>
      </div>
    </OutsideClickHandler>
  );
};

export default AddLineItemSection;
