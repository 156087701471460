export const isEditInvoiceURL = new RegExp(/^\/invoices\/edit\/([a-z]|-|[0-9])*/);

export const isEditTaxURL = new RegExp(/^\/taxes\/edit\/([a-z]|-|[0-9])*/);

export const isEditItemURL = new RegExp(/^\/items\/edit\/([a-z]|-|[0-9])*/);

export const isDuplicateItemURL = new RegExp(/^\/items\/duplicate\/([a-z]|-|[0-9])*/);

export const isEditCategoryURL = new RegExp(/^\/categories\/edit\/([a-z]|-|[0-9])*/);

export const isEditServiceChargeURL = new RegExp(/^\/service-charges\/edit\/([a-z]|-|[0-9])*/);

export const isRespondToDisputeUrl = new RegExp(/^\/payments\/disputes\/([a-z]|-|[0-9])*\/respond\/([a-z]|-|[0-9])*/);
export const isUploadEvidenceUrl = new RegExp(
  /^\/payments\/disputes\/([a-z]|-|[0-9])*\/update-evidence\/([a-z]|-|[0-9])*/
);
export const isReviewSubmitDisputeUrl = new RegExp(
  /^\/payments\/disputes\/([a-z]|-|[0-9])*\/review-and-submit\/([a-z]|-|[0-9])*/
);
export const isViewDisputeEvidenceUrl = new RegExp(
  /^\/payments\/disputes\/([a-z]|-|[0-9])*\/view-evidence\/([a-z]|-|[0-9])*/
);
