import React, { FC, useEffect, useState } from 'react';
import { useLocation, useOutletContext } from 'react-router';
import Lottie from 'lottie-react';
import SidePanel from 'components/side-panel/SidePanel';
import {
  ArrowRightIcon,
  HeldInReserveAmountIcon,
  NavCloseIcon,
  OnTheWayAmountIcon,
  UpcomingPayoutAmountIcon
} from 'assets/icons';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { Divider } from '@material-ui/core';
import { useLazyGetPayoutsAccountSummaryQuery } from 'containers/payouts/api';
import {
  selectCurrentTeam,
  selectCurrentTeamInvoicePermissions,
  selectPaymentSystemConfig
} from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import { checkPermissions } from 'containers/payouts/utils';
import { If } from 'components';
import { LoadingSpinnerAnimation } from 'assets/animations';
import NoPermissions from 'components/no-permissions/NoPermissions';
import { openScanpayFAQInNewTab } from 'utils/urlUtils';
import { PayoutsCleverTapEvents } from 'containers/payouts/events';

const TotalBalanceDetailsPanel: FC = () => {
  const location = useLocation();
  const { handleSidePanelClose } = useOutletContext<any>();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [getPayoutsAccountSummary, payoutsAccountSummaryState] = useLazyGetPayoutsAccountSummaryQuery();
  const {
    data: payoutsAccountSummary,
    isFetching: isFetchingPayoutsaccountSummary,
    isSuccess: payoutsAccountSummarySuccess
  } = payoutsAccountSummaryState;
  const { balance, inFuture, inTransit, inReserve } = payoutsAccountSummary ?? {};
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const payoutPermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const [hasPermissionsToView, setHasPermissionsToView] = useState(true);

  useEffect(() => {
    if (currentTeam.id && paymentSystem) {
      setHasPermissionsToView(
        checkPermissions({
          paymentSystem,
          viewPayoutsBySelf: payoutPermissions.viewPayoutsBySelf,
          viewPayoutsByTeam: payoutPermissions.viewPayoutsByTeam
        })
      );
    }
  }, [currentTeam.id, paymentSystem]);

  useEffect(() => {
    if (currentTeam.id && hasPermissionsToView) getPayoutsAccountSummary(currentTeam.id);
  }, [currentTeam.id, hasPermissionsToView]);

  useEffect(() => {
    setHasPermissionsToView(
      checkPermissions({
        paymentSystem,
        viewPayoutsBySelf: payoutPermissions.viewPayoutsBySelf,
        viewPayoutsByTeam: payoutPermissions.viewPayoutsByTeam
      })
    );
  }, [payoutPermissions.viewPayoutsBySelf, payoutPermissions.viewPayoutsByTeam]);

  const summaryItems = [
    {
      id: '1',
      title: 'Upcoming payouts (estimated)',
      value: inFuture
    },
    {
      id: '2',
      title: 'On the way to your bank',
      value: inTransit
    },
    {
      id: '3',
      title: 'Held in reserve',
      value: inReserve
    }
  ];

  const onFAQClick = () => {
    openScanpayFAQInNewTab(PayoutsCleverTapEvents.WebPayoutTotalBalanceDetailPanelFaqClick);
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="flex h-full w-96 flex-col">
        <If condition={isFetchingPayoutsaccountSummary && hasPermissionsToView}>
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        </If>
        <If condition={(payoutsAccountSummarySuccess && !isFetchingPayoutsaccountSummary) || !hasPermissionsToView}>
          <div className="flex h-full w-full flex-col gap-[30px] pt-5">
            <div className="flex  w-full items-center gap-2 pl-[14px]">
              <div onClick={handleSidePanelClose} className="cursor-pointer">
                <NavCloseIcon id="payouts-summary-panel-close" />
              </div>
              <div className="text-17px font-semibold text-heading">Total balance</div>
            </div>
            {hasPermissionsToView ? (
              <div className="customNormalScroll flex h-full flex-col gap-[30px] overflow-auto px-[30px]">
                <div className="flex w-full flex-col gap-[15px] rounded-md border border-border p-5">
                  {summaryItems.map(summaryItem => {
                    return summaryItem.id === '3' && summaryItem.value === '0.00' ? (
                      <></>
                    ) : (
                      <div key={summaryItem.id} className="flex justify-between">
                        <div className="text-17px font-normal text-primaryText">{summaryItem.title}</div>
                        <div className="text-17px font-normal text-tertiaryText">
                          {getAmountWithCurrency(summaryItem.value)}
                        </div>
                      </div>
                    );
                  })}
                  <Divider className="w-full bg-borderGray" />
                  <div className="flex justify-between">
                    <div className="text-17px font-bold text-primaryText">Total</div>
                    <div className="text-17px font-bold text-tertiaryText">{getAmountWithCurrency(balance)}</div>
                  </div>
                </div>
                <div className="flex flex-col gap-5">
                  <div className="flex flex-col gap-2.5">
                    <div className="text-2xl font-semibold text-primaryText">How is your balance calculated?</div>
                    <div className="text-sbase font-normal text-primaryText">
                      Your balance is the total amount you should expect to receive in your bank account in the future.
                      It includes the payments already collected, net of any fees or refunds or funds that are held.
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <div className="flex items-center gap-2">
                      <UpcomingPayoutAmountIcon className="shrink-0" />
                      <div className="text-17px font-semibold text-tertiaryText">Upcoming payouts (estimated)</div>
                    </div>
                    <div className="pl-[30px] pr-2.5 text-sbase font-normal text-primaryText">
                      Your active sales and your future scheduled payouts show up here. This amount might change as you
                      collect more sales or process refunds and disputes.
                    </div>
                  </div>
                  <div className="flex flex-col gap-2.5">
                    <div className="flex items-center gap-2">
                      <OnTheWayAmountIcon className="shrink-0" />
                      <div className="text-17px font-semibold text-tertiaryText">On the way to your bank</div>
                    </div>
                    <div className="pl-[30px] pr-2.5 text-sbase font-normal text-primaryText">
                      These are payouts already initiated by ScanPay and should be reaching your bank shortly. Since we
                      do not know when your bank shows it in your balance, some of these payouts might have already
                      reached your bank account.
                    </div>
                  </div>
                  {inReserve !== '0.00' && (
                    <div className="flex flex-col gap-2.5">
                      <div className="flex items-center gap-2">
                        <HeldInReserveAmountIcon className="shrink-0" />
                        <div className="text-17px font-semibold text-tertiaryText">Held in Reserve</div>
                      </div>
                      <div className="pl-[30px] pr-2.5 text-sbase font-normal text-primaryText">
                        Funds held in reserved. They will be part of future scheduled payouts.
                      </div>
                    </div>
                  )}
                  <Divider className="w-full bg-borderGray" />
                  <div
                    className="flex cursor-pointer items-center gap-1 text-sbase font-semibold text-primaryBtn"
                    role="presentation"
                    onClick={onFAQClick}>
                    View FAQs on our website
                    <div className="mt-0.5">
                      <ArrowRightIcon height={10} />
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <NoPermissions />
            )}
          </div>
        </If>
      </div>
    </SidePanel>
  );
};

export default TotalBalanceDetailsPanel;
