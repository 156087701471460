/* eslint-disable react/no-multi-comp */
import React, { useCallback, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCurrentTeam, selectUserDetails, showNotifier } from 'containers/app/appSlice';
import { BackIcon, CrossIconvector, DeleteFailedIcon, DeleteOutlinedIcon } from 'assets/icons';
import Button from '@material-ui/core/Button/Button';
import { useDeleteInvoiceMutation, useLazyGetInvoiceByIdQuery } from 'containers/invoices/api';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { NotifierTypes } from 'constants/notifierConstants';
import { Divider } from '@material-ui/core';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { logAnalyticEvent } from 'utils/analytics';
import { constructArrayObjectAsString, getPageName, throttle } from 'utils/commonUtils';
import SidePanel from 'components/side-panel/SidePanel';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SidePanelRouteState } from 'routes/types';
import { RoutePath } from 'routes/index';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';

interface DeleteInvoiceProps {
  onCancel?: VoidFn;
  onSuccess?: VoidFn;
  resetInvoicesQuery?: VoidFn;
  isRootElement?: boolean;
}

enum PageState {
  MAIN = 'Main',
  FAILED_STATE = 'failedState'
}

const DeleteInvoicePanel: React.FC<DeleteInvoiceProps> = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const currentUser = useAppSelector(selectUserDetails);
  const [pageState, setPageState] = useState<PageState>(PageState.MAIN);
  const dispatch = useAppDispatch();

  const [deleteInvoice, { isSuccess, isError }] = useDeleteInvoiceMutation();

  const [getInvoiceByIdQuery, getInvoiceByIdState] = useLazyGetInvoiceByIdQuery();
  const { data: invoiceData, isSuccess: invoiceDataSuccess, isFetching: fetchingInvoiceData } = getInvoiceByIdState;

  const { rootInvoiceId, invoiceId: invoiceIdFromUrl } = useParams();
  const { handleSidePanelClose, resetInvoicesQuery } = useOutletContext<any>();

  const location = useLocation();
  const navigate = useNavigate();

  const [isRootState, setIsRootState] = useState(true);

  useEffect(() => {
    if (currentTeam?.id) {
      if (invoiceIdFromUrl) {
        getInvoiceByIdQuery({ invoiceId: invoiceIdFromUrl, teamId: currentTeam.id });
      } else if (rootInvoiceId) {
        getInvoiceByIdQuery({ invoiceId: rootInvoiceId, teamId: currentTeam.id });
      }
    }
  }, [invoiceIdFromUrl, rootInvoiceId]);

  useEffect(() => {
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    let routeStateTimeout;
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const onDeleteInvoice = () => {
    deleteInvoice({
      invoiceId: invoiceData.invoiceId,
      teamId: currentTeam.id
    });
  };

  const deleteInvoiceHandler = useCallback(throttle(onDeleteInvoice, 800), [invoiceData, currentTeam]);

  useEffect(() => {
    if (invoiceData?.invoiceId) {
      logAnalyticEvent(clevertapEvents.WebInvoicesListDeletePanelOpen, {
        'Line Items': constructArrayObjectAsString(invoiceData.lineItems, 'Line Item'),
        Page: getPageName(location.pathname)
      });
    }
  }, [invoiceData?.invoiceId]);

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Invoice deleted successfully.',
            isMessageHtml: false
          },
          type: NotifierTypes.SUCCESS,
          showClose: false,
          bgStyle: 'bg-[#3E556D] rounded-md',
          fontStyle: 'text-primary font-normal'
        })
      );
      if (resetInvoicesQuery) {
        resetInvoicesQuery();
        navigate(RoutePath.INVOICES, { replace: true });
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      setPageState(PageState.FAILED_STATE);
    }
  }, [isError]);

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  const MainSection = () => {
    return (
      <div className="flex h-full flex-1 flex-col">
        <div className="flex flex-row items-center p-4">
          <div className="cursor-pointer" onClick={handleSidePanelBack}>
            {isRootState ? <CrossIconvector className="shrink-0" /> : <BackIcon className="shrink-0" />}
          </div>
          <div id="delete-invoice-side-panel-title" className="pl-2 text-base  font-bold text-primaryText">
            {'Delete Invoice'}
          </div>
        </div>

        <Divider variant={'fullWidth'} />

        <div className="flex h-full flex-1 flex-col justify-center px-4">
          <div className="flex flex-col items-center">
            <DeleteOutlinedIcon />
            <div className={'mt-3 text-base font-semibold text-primaryText'}>Confirm deletion</div>
            <div className={'font-normal text-base text-primaryText mt-2 ' + 'text-center mx-4'}>
              Are your sure? Do you want to delete this invoice?
            </div>
          </div>

          <Button
            className="path-stroke-current mt-8
            min-h-[42px] w-full bg-primaryBtn py-2 font-lato normal-case text-primaryBtnText disabled:opacity-25"
            onClick={deleteInvoiceHandler}
            disabled={invoiceData.invoiceStatus === 'PAID' || !invoiceData.isModifiable}
            variant="contained">
            <div id="delete-invoice-sidepanel-yes-button" className="text-base font-semibold">
              Yes, Delete
            </div>
          </Button>

          <button
            id="delete-invoice-sidepanel-cancel-button"
            className="mt-4 min-h-[42px]
              w-full rounded-md border-primaryBtn bg-primaryBtnText py-2 font-lato text-primaryBtn"
            style={{ borderWidth: 1 }}>
            <div className="text-base font-semibold" onClick={handleSidePanelBack}>
              Cancel
            </div>
          </button>
        </div>
      </div>
    );
  };

  const FailedState = () => {
    return (
      <div className="flex h-full flex-1 flex-col">
        <div className="flex flex-row items-center p-4">
          <div className="cursor-pointer" onClick={handleSidePanelBack}>
            {isRootState ? <CrossIconvector className="shrink-0" /> : <BackIcon className="shrink-0" />}
          </div>
          <div className="pl-2 text-base  font-bold text-primaryText">{'Delete Invoice'}</div>
        </div>

        <Divider variant={'fullWidth'} />

        <div className="flex h-full flex-1 flex-col justify-center px-4">
          <div className="flex flex-col items-center">
            <DeleteFailedIcon />
            <div className={'mt-3 text-base font-semibold text-primaryText'}>
              Not allowed - customer payment in progress
            </div>
            <div className={'font-normal text-base text-primaryText mt-2 ' + 'text-center mx-4'}>
              Invoices can not be deleted when a payment is being attempted.
            </div>
          </div>

          <Button
            className="path-stroke-current mt-8
            min-h-[42px] w-full bg-primaryBtn py-2 font-lato normal-case text-primaryBtnText disabled:opacity-25"
            onClick={handleSidePanelBack}
            variant="contained">
            <div className="text-base font-semibold">Okay</div>
          </Button>
        </div>
      </div>
    );
  };
  return (
    <SidePanel isOpen onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="h-full w-96">
        {fetchingInvoiceData ? (
          <div className="flex h-full w-full items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          invoiceDataSuccess && (
            <section
              className={
                ' w-full right-0 ' +
                'absolute bg-white h-full shadow-xl ' +
                'delay-400 duration-500 ease-in-out transition-all transform overflow-auto'
              }>
              {pageState === PageState.MAIN ? <MainSection /> : <FailedState />}
            </section>
          )
        )}
      </div>
    </SidePanel>
  );
};

export default DeleteInvoicePanel;
