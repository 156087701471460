import DropdownWithSearch from 'components/dropdown-with-search/DropdownWithSearch';
import { TeamRole } from 'containers/app/app.model';
import { FC, useState } from 'react';
import { logAnalyticEvent } from 'utils/analytics';
import { constructArrayAsValue, joinTextArray } from 'utils/commonUtils';
import { pluckValuesFromArrayWithSpecificKey } from 'utils/formatters';
import { TeamMemberRoleFilter, TeamMemberStatusFilter } from '../constants';
import { CleverTapEventsManageTeam } from '../events';
import { TeamMemberStatus } from '../manageTeam.model';

interface TeamMemberFilterSectionProps {
  selectedTeamMemberStatuses: string[];
  setSelectedTeamMemberStatuses: (value: TeamMemberStatus[]) => void;
  selectedMemberRoles: string[];
  setSelectedMemberRoles: (value: TeamRole[]) => void;
}

const TeamMemberFilterSection: FC<TeamMemberFilterSectionProps> = ({
  selectedTeamMemberStatuses,
  setSelectedTeamMemberStatuses,
  selectedMemberRoles,
  setSelectedMemberRoles
}) => {
  const [teamMemberStatusDraft, setTeamMemberStatusDraft] = useState<string[]>([]);
  const [selectedMemberRolesDraft, setSelectedMemberRolesDraft] = useState<string[]>([]);

  const getTextForSelectedFilter = (filter: string) => {
    switch (filter) {
      case 'status':
        return joinTextArray(
          pluckValuesFromArrayWithSpecificKey(
            TeamMemberStatusFilter.filter(item => selectedTeamMemberStatuses.includes(item.key)),
            'value'
          )
        );
      case 'role':
        return joinTextArray(
          pluckValuesFromArrayWithSpecificKey(
            TeamMemberRoleFilter.filter(item => selectedMemberRoles.includes(item.key)),
            'value'
          )
        );
    }

    return '';
  };

  const onApplyStatusFilters = statuses => {
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamFilterStatus, {
      ...constructArrayAsValue('statuses', statuses)
    });
    setSelectedTeamMemberStatuses(statuses);
  };

  const onApplyUserRoleFilters = roles => {
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamFilterUserRole, {
      ...constructArrayAsValue('roles', roles)
    });
    setSelectedMemberRoles(roles);
  };

  return (
    <div className="relative flex items-center gap-2.5">
      <DropdownWithSearch
        filterId="manage-team-status-filter"
        data={TeamMemberStatusFilter}
        selectedDraftArray={teamMemberStatusDraft}
        setSelectedDraftArray={setTeamMemberStatusDraft}
        selectedArray={selectedTeamMemberStatuses}
        setSelectedArray={onApplyStatusFilters}
        dataKey="value"
        dataIdKey="key"
        buttonPrimaryText="Status"
        buttonSecondaryText={getTextForSelectedFilter('status')}
        filterPlaceholderText="Filter by Status"
        allowSearch={false}
        customClassName="mb-0"
      />

      <DropdownWithSearch
        filterId="manage-team-role-filter"
        data={TeamMemberRoleFilter}
        selectedDraftArray={selectedMemberRolesDraft}
        setSelectedDraftArray={setSelectedMemberRolesDraft}
        selectedArray={selectedMemberRoles}
        setSelectedArray={onApplyUserRoleFilters}
        dataKey="value"
        dataIdKey="key"
        buttonPrimaryText="User Role"
        buttonSecondaryText={getTextForSelectedFilter('role')}
        filterPlaceholderText="Filter by Role"
        allowSearch={false}
        customClassName="mb-0"
      />
    </div>
  );
};

export default TeamMemberFilterSection;
