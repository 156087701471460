export enum CleverTapEventsManageTeam {
  webManageTeamFilterStatus = 'Web_ManageTeam_Filter_Status',
  webManageTeamFilterUserRole = 'Web_ManageTeam_Filter_UserRole',
  webManageTeamSearch = 'Web_ManageTeam_Search',
  webManageTeamListMMDeactivate = 'Web_ManageTeam_List_MM_Deactivate',
  webManageTeamListMMEditPermissions = 'Web_ManageTeam_List_MM_Edit Permissions',
  webManageTeamListMMShareInviteLink = 'Web_ManageTeam_List_MM_Share_Invite_link',
  webManageTeamListMMDeleteInvite = 'Web_ManageTeam_List_MM_Delete_Invite',
  webManageTeamListMMSendNewInvite = 'Web_ManageTeam_List_MM_Send_New_Invite',
  webManageTeamListMMReactivate = 'Web_ManageTeam_List_MM_Reactivate',
  webManageTeamListDetails = 'Web_ManageTeam_List_details',
  webManageTeamListDetailsRoleChange = 'Web_ManageTeam_List_details_Role_Change',
  webManageTeamListDetailsRoleChangeEditPermissions = 'Web_ManageTeam_List_details_Role_Change_Edit_Permissions',
  webManageTeamListDetailsRoleChangeUpdate = 'Web_ManageTeam_List_details_Role_Change_Update',
  webManageTeamListDetailsEditPermissions = 'Web_ManageTeam_List_details_Edit_Permissions',
  webManageTeamListDetailsDeactivate = 'Web_ManageTeam_List_details_Deactivate',
  webManageTeamListDetailsViewInvoices = 'Web_ManageTeam_List_details_View_Invoices',
  webManageTeamCreateInvite = 'Web_ManageTeam_Create_Invite',
  webManageTeamCreateInviteEditPermissions = 'Web_ManageTeam_Create_Invite_Edit_Permissions',
  webManageTeamCreateInviteSendInvite = 'Web_ManageTeam_Create_Invite_Send_Invite',
  webManageTeamInviteMore = 'Web_ManageTeam_Invite_More',
  webManageTeamListMMSendNewInviteLink = 'Web_ManageTeam_List_MM_Send_New_Invite'
}
