import React from 'react';

interface CircularStepperProps {
  currentStep: number;
  totalSteps: number;
}

const CircularStepper: React.FC<CircularStepperProps> = ({ currentStep, totalSteps }) => {
  return (
    <div className="mb-[30px] flex gap-1.5">
      {[...Array(totalSteps)].map((item, index) => (
        <div
          key={index}
          className={`h-2  ${
            currentStep === index + 1 ? 'w-5 rounded-lg bg-secondary' : 'h-2 w-2 rounded-full bg-border'
          }`}
        />
      ))}
    </div>
  );
};

export default CircularStepper;
