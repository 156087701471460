import { CustomModal, Loader } from 'components';
import { WebviewFunctions } from 'constants/common';

import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { InvoiceByIdResponse, InvoiceResponse } from '../invoices.model';
import { useNavigate } from 'react-router-dom';
import { ROOT_ROUTE_STATE } from 'routes/constants';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';

interface ManualKeyInModalProps {
  onClose: VoidFn;
  checkoutUrl: string;
  invoiceData: InvoiceByIdResponse | InvoiceResponse;
  onRedirect?: VoidFn;
}

const ManualKeyInModal: FC<ManualKeyInModalProps> = ({ onClose, invoiceData, checkoutUrl, onRedirect = undefined }) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const currentTeam = useAppSelector(selectCurrentTeam);

  const navigate = useNavigate();

  const getFrameURL = () => {
    const url = `${checkoutUrl}&paymentType=manual-key-in`;
    return url;
  };

  const handleIframeOnLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    const handleReadMessage = async event => {
      if (event.data === WebviewFunctions.WEBVIEW_LOADED) {
        setIsLoading(false);
      }
      if (event.data === WebviewFunctions.ROUTE_TO_INVOICE_DETAIL) {
        if (onRedirect) onRedirect();
        onClose();
        navigate(`/invoices/${currentTeam.id}/${invoiceData?.invoiceId}/invoice`, { state: ROOT_ROUTE_STATE });
      }
      if (event.data === WebviewFunctions.ROUTE_TO_SHARE_INVOICE) {
        onClose();
        if (onRedirect) onRedirect();
        navigate(`/invoices/${currentTeam?.id}/${invoiceData.invoiceId}/share-invoice`, {
          state: ROOT_ROUTE_STATE
        });
      }
      if (event.data === WebviewFunctions.CLOSE_WEBVIEW_CHECKOUT) {
        onClose();
      }
    };

    window.addEventListener('message', handleReadMessage, false);
    return () => {
      window.removeEventListener('message', handleReadMessage, false);
    };
  }, []);

  return (
    <CustomModal
      open
      width="max-w-[375px] md:min-w-[375px]"
      height="min-h-[655px]"
      className="relative"
      enableOutsideClick={false}>
      {isLoading && (
        <>
          <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <Loader lottieClassName="h-40 w-40" />
            <div className="text-center text-[15px] text-primaryText">Initializing...</div>
          </div>
        </>
      )}
      <iframe src={getFrameURL()} width={375} height={655} onLoad={handleIframeOnLoad} />
    </CustomModal>
  );
};

export default ManualKeyInModal;
