import { CircleCloseIcon, EnablePayouts75ProgressIcon } from 'assets/icons';
import { CustomModal } from 'components';
import { FC } from 'react';
import AddBankAccountCard from './AddBankAccountCard';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam, selectTeamBankDetails } from 'containers/app/appSlice';
import SubmitDetailsCard from './SubmitDetailsCard';
import { VoidFn } from 'types/baseTypes';
import {
  ActionNeededTypes,
  BankAccountCardType,
  ConnectBankStatuses,
  EnablePayoutStatuses,
  InternalBankStatuses
} from 'containers/payouts/constants';
import { getEnablePayoutsIcon } from 'containers/payouts/utils';
import ApplicationDeniedCard from './ApplicationDeniedCard';
import DetailsSubmittedCard from './DetailsSubmittedCard';
import ActionNeededCard from './ActionNeededCard';
import ApplicationApprovedCard from './ApplicationApprovedCard';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { logAnalyticEvent } from 'utils/analytics';
import { PayoutsCleverTapEvents } from 'containers/payouts/events';

interface EnablePayoutsPopupProps {
  onClose: VoidFn;
  onLinkBankAccount: VoidFn;
  onActionNeededClick: VoidFn;
}

const EnablePayoutsPopup: FC<EnablePayoutsPopupProps> = ({ onClose, onLinkBankAccount, onActionNeededClick }) => {
  const navigate = useNavigate();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { connectApplicationStatus, connectBankingStatus, internalBankingStatus } = currentTeam ?? {};
  const { accountNumber, bankName } = useAppSelector(selectTeamBankDetails) ?? {};

  const Icon =
    connectBankingStatus === ConnectBankStatuses.SUBMITTED ||
    internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_SUBMITTED
      ? EnablePayouts75ProgressIcon
      : getEnablePayoutsIcon(connectApplicationStatus);

  const approvedStatuses = [EnablePayoutStatuses.APPROVED, EnablePayoutStatuses.APPROVED_CONDITIONAL];

  const linkBankAccountEnabledStatuses = [
    EnablePayoutStatuses.APPROVED,
    EnablePayoutStatuses.APPROVED_CONDITIONAL,
    EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED,
    EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED,
    EnablePayoutStatuses.SUBMITTED_IN_REVIEW
  ];

  const isLinkBankAccountEnabled = linkBankAccountEnabledStatuses.includes(
    connectApplicationStatus as EnablePayoutStatuses
  );

  const getStatusDetailsCard = () => {
    switch (connectApplicationStatus) {
      case EnablePayoutStatuses.NOT_STARTED:
      case EnablePayoutStatuses.STARTED_IN_PROGRESS:
        return <SubmitDetailsCard />;

      case EnablePayoutStatuses.SUBMITTED_IN_REVIEW:
        return <DetailsSubmittedCard />;

      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED:
      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED:
        return <ActionNeededCard type={ActionNeededTypes.KYC} />;

      case EnablePayoutStatuses.APPROVED:
      case EnablePayoutStatuses.APPROVED_CONDITIONAL:
        return <ApplicationApprovedCard text="Account approved" />;

      case EnablePayoutStatuses.REJECTED:
        return <ApplicationDeniedCard />;

      default:
        return null;
    }
  };

  const handleDetailsClick = () => {
    switch (connectApplicationStatus) {
      case EnablePayoutStatuses.NOT_STARTED:
      case EnablePayoutStatuses.STARTED_IN_PROGRESS:
        logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsSubmitDetailsClick);
        return navigate(routesPath.CONNECT_ONBOARDING);

      case EnablePayoutStatuses.SUBMITTED_IN_REVIEW:
        logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsPopupInReviewClick);
        return navigate(routesPath.BANK_APPLICATION_STATUS);

      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_ALLOWED:
      case EnablePayoutStatuses.SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED:
        return navigate(routesPath.BANK_APPLICATION_STATUS);

      case EnablePayoutStatuses.APPROVED:
      case EnablePayoutStatuses.APPROVED_CONDITIONAL:
        return null;

      case EnablePayoutStatuses.REJECTED:
        logAnalyticEvent(PayoutsCleverTapEvents.webEnablePayoutsPopupRejectedClick);
        return navigate(routesPath.BANK_APPLICATION_STATUS);

      default:
        return null;
    }
  };

  const getBankDetailsCard = () => {
    if (connectBankingStatus === ConnectBankStatuses.SUBMITTED) {
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_VERIFIED)
        return <ApplicationApprovedCard text="Bank account connected" subtext={`${bankName} ${accountNumber}`} />;
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_SUBMITTED)
        return <AddBankAccountCard type={BankAccountCardType.VERIFICATION_IN_PROGRESS} isEnabled />;
      if (
        internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_NOT_SUBMITTED ||
        internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_REJECTED
      )
        return <ActionNeededCard type={ActionNeededTypes.BANK} />;
    } else if (connectBankingStatus === ConnectBankStatuses.NOT_SUBMITTED) {
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_SUBMITTED)
        return <AddBankAccountCard type={BankAccountCardType.VERIFICATION_IN_PROGRESS} isEnabled />;
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_REJECTED)
        return <ActionNeededCard type={ActionNeededTypes.BANK} />;
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_NOT_SUBMITTED)
        return <AddBankAccountCard type={BankAccountCardType.ADD_BANK_ACCOUNT} isEnabled={isLinkBankAccountEnabled} />;
    }
    return <AddBankAccountCard type={BankAccountCardType.VERIFICATION_IN_PROGRESS} isEnabled />;
  };

  const goToStatusPage = () => {
    navigate(routesPath.BANK_APPLICATION_STATUS, { state: { isBank: true } });
  };

  const handleBankCardClick = () => {
    if (connectBankingStatus === ConnectBankStatuses.SUBMITTED) {
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_SUBMITTED) return goToStatusPage();
      if (
        internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_NOT_SUBMITTED ||
        internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_REJECTED
      )
        return onActionNeededClick();
    } else if (connectBankingStatus === ConnectBankStatuses.NOT_SUBMITTED) {
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_SUBMITTED) return goToStatusPage();
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_REJECTED) {
        return onActionNeededClick();
      }
      if (internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_NOT_SUBMITTED) return handleLinkBankAccount();
    }
    return null;
  };

  const handleLinkBankAccount = () => {
    onLinkBankAccount();
    onClose();
  };

  return (
    <CustomModal open={true} width="md:min-w-[390px]" className="" onOutsideClick={onClose}>
      <div className="relative w-full p-9 pt-[50px]">
        <CircleCloseIcon className="absolute right-2.5 top-2.5 cursor-pointer" onClick={onClose} />

        <div className="flex w-full flex-col items-center justify-center">
          <Icon className="circle-fill-current circle text-secondaryBtn" />
          <div className="my-[30px] text-center">
            <div className="pb-2.5 text-2xl font-semibold text-primaryText">Enable Payouts</div>
            <div className="text-sbase text-primaryText">Complete the steps to start receiving your payouts</div>
          </div>
          <div className="w-full">
            <div
              className={`w-full ${
                approvedStatuses.includes(connectApplicationStatus as EnablePayoutStatuses)
                  ? 'pointer-events-none'
                  : 'cursor-pointer'
              }`}
              onClick={handleDetailsClick}>
              {getStatusDetailsCard()}
            </div>
            {connectApplicationStatus !== EnablePayoutStatuses.REJECTED && (
              <>
                <div className="z-10 ml-10 h-5 w-0.5 border-l border-dashed border-border"></div>
                <div
                  className={`w-full ${isLinkBankAccountEnabled ? 'cursor-pointer' : 'pointer-events-none'}`}
                  onClick={handleBankCardClick}>
                  {getBankDetailsCard()}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default EnablePayoutsPopup;
