import {
  CaretDownGreyIcon,
  ExportIcon,
  ItemsEmptyIcon,
  NoSearchResultsIcon,
  OpenNewTabIcon,
  PlusIconWhite,
  SearchIcon
} from 'assets/icons';
import { CustomButton, CustomPopover, If, SearchBar } from 'components';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { debounce } from 'lodash';
import isEqual from 'lodash/isEqual';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { initialItemsQueryParamsState, usePaginatedGetItemsQuery } from './hooks/usePaginatedGetItemsQuery';
import ItemsTable from './components/ItemsTable';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import { Divider } from '@material-ui/core';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamFeePermissions,
  showNotifier
} from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ItemActionType } from './types';
import { DeleteItemPayload, IPaginatedItem } from './items.model';
import DropdownWithSearch from 'components/dropdown-with-search/DropdownWithSearch';
import { PopoverArrowTypes } from 'constants/common';
import { ExportMenu } from 'containers/invoices/components';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { useDeleteItemMutation, useLazyDownloadItemsListQuery } from './api';
import DeleteItemConfirmationPopup from './components/DeleteItemConfirmationPopup';
import { NotifierTypes } from 'constants/notifierConstants';
import { joinTextArray, openInNewTab } from 'utils/commonUtils';
import { pluckValuesFromArrayWithSpecificKey } from 'utils/formatters';
import { InfoMesssages } from 'types/infoMessages';
import { useLazyGetCategoriesQuery } from '../categories/api';
import { resetItemSlice } from './itemsSlice';
import { CleverTapEventsCatalogItems } from './events';
import { logAnalyticEvent } from 'utils/analytics';
import { ItemsExportPopover } from './constants';
import { CATALOG_FAQ_LINK } from 'config/index';

const Items: FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const notifications = useAppSelector(getNotifications);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [isExportPopoverOpen, setIsExportPopoverOpen] = useState<{ open: boolean; id: ItemsExportPopover }>({
    open: false,
    id: null
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IPaginatedItem>(null);
  const [selectedCategoryDraft, setSelectedCategoryDraft] = useState<string[]>([]);
  const [selectedCategory, setSelectedCategory] = useState<string[]>([]);
  const [showHeaderActionButtons, setShowHeaderActionButtons] = useState<boolean>(false);

  const [getCategoryQuery, getCategoryQueryState] = useLazyGetCategoriesQuery();
  const [downloadItemsQuery] = useLazyDownloadItemsListQuery();

  const {
    list: itemsList,
    isLoading: isLoadingItems,
    loadMore,
    resetQuery: resetItemsQuery,
    resetRecallQuery: resetRecallItemsQuery,
    hasMore: hasMoreItems,
    setQueryParams: setItemsQueryParams,
    queryParams: itemsQueryParams,
    totalListCount: itemsCount,
    isLoadingSuccess: itemsListSuccess
  } = usePaginatedGetItemsQuery();

  const [deleteItemMutation, { isSuccess: isDeleteItemSuccess, isLoading: isDeleteItemLoading }] =
    useDeleteItemMutation();

  const hasManageItemsPermissions = catalogSettingsPermissions.manageItemsAndCategories;

  const itemsListLoader = useRef(loadMore);

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: itemsListLoader,
    threshold: 0.5
  });

  useEffect(() => {
    if (hasManageItemsPermissions) {
      resetRecallItemsQuery();
      setShowHeaderActionButtons(false);
      setSelectedCategory([]);
      getCategoryQuery({
        teamId: currentTeam?.id,
        limit: 1000,
        page: 0,
        search: null
      });
    }
  }, [currentTeam?.id, hasManageItemsPermissions]);

  const { data: getCategoryResponse } = getCategoryQueryState;

  const convertCategories = () =>
    getCategoryResponse?.data?.map(category => ({
      id: category.id.toString(),
      name: category.name
    }));

  const convertedCategoryList = convertCategories() ?? [];

  useEffect(() => {
    if (!isLoadingItems && itemsList?.length > 0) {
      setShowHeaderActionButtons(true);
    }
    if (itemsList?.length === 0 && !isInitialQueryParamsState()) {
      setShowHeaderActionButtons(true);
    }
    if (itemsList?.length === 0 && isInitialQueryParamsState()) {
      setShowHeaderActionButtons(false);
    }
  }, [isLoadingItems]);

  useEffect(() => {
    if (hasManageItemsPermissions) {
      let updatedParams = {
        ...itemsQueryParams,
        categoryId: selectedCategory
      };
      updatedParams = { ...updatedParams };
      setItemsQueryParams(prevQueryParams => ({
        ...prevQueryParams,
        ...updatedParams
      }));
    }
  }, [selectedCategory]);

  useEffect(() => {
    itemsListLoader.current = loadMore;
  }, [loadMore]);

  useEffect(() => {
    if (isDeleteItemSuccess) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Item successfully deleted'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
      setSelectedItem(null);
      resetRecallItemsQuery();
    }
  }, [isDeleteItemSuccess]);

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    debouncedSearchHandler(searchTerm);
  };

  const initiateSearch = (term: string) => {
    setItemsQueryParams(prevQueryParams => ({ ...prevQueryParams, search: term?.trim() || null }));
    if (term?.trim()) {
      logAnalyticEvent(CleverTapEventsCatalogItems.webCatalogItemsSearch, { searchTerm: term?.trim() });
    }
  };

  const onClearSearch = () => {
    setSearchText('');
    initiateSearch(null);
  };

  const debouncedSearchHandler = useCallback(debounce(initiateSearch, 500), [itemsQueryParams]);

  const handleSearchIconClick = () => {
    setSearchIconClicked(true);
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: false
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handleItemCreation = () => {
    if (hasManageItemsPermissions) {
      dispatch(resetItemSlice());
      navigate(routesPath.ITEMS_CREATE);
      logAnalyticEvent(CleverTapEventsCatalogItems.webCatalogItemsCreateItem);
    } else {
      onInfoClick('create-item', InfoMesssages.createItemNoPermissionMessage);
    }
  };

  const handleItemsMoreActionsClick = ({ action, item }: { action: ItemActionType; item: IPaginatedItem }) => {
    switch (action) {
      case ItemActionType.EDIT:
        navigate(`/items/edit/${item.id}`);
        logAnalyticEvent(CleverTapEventsCatalogItems.webCatalogItemsEditItem);
        break;
      case ItemActionType.DUPLICATE:
        navigate(`/items/duplicate/${item.id}`);
        logAnalyticEvent(CleverTapEventsCatalogItems.webCatalogItemsDuplicateItem);
        break;
      case ItemActionType.DELETE:
        setSelectedItem(item);
        setShowDeleteConfirmation(true);
        break;
      default:
        break;
    }
  };

  const createButton = (
    <CustomButton
      id="create-item-button"
      onClick={handleItemCreation}
      className={'h-[38px] text-primaryBtnText '}
      StartIcon={PlusIconWhite}
      childrenContainerStyles="mr-1"
      showDisabledReason={!hasManageItemsPermissions}>
      <div className="text-sbase font-semibold">Create Item</div>
    </CustomButton>
  );

  const exportAnchor = () => {
    return (
      <CustomButton
        id="create-item-export-button"
        className={`cursor:pointer h-[38px]
      bg-[#A4D7FA] font-lato font-normal normal-case text-primaryText shadow-none hover:bg-[#80C3F1]`}
        EndIcon={CaretDownGreyIcon}
        StartIcon={ExportIcon}
        childrenContainerStyles="mr-1">
        <div className="text-sbase font-semibold">Export</div>
      </CustomButton>
    );
  };

  const handleExportClick = id => {
    if (isExportDisabled) {
      onInfoClick('items-export', InfoMesssages.noItemsToExport);
      return;
    }
    setIsExportPopoverOpen(prevState => ({ open: !prevState.open, id }));
  };

  const onCloseExportPopover = id => {
    if (id === isExportPopoverOpen.id) {
      setIsExportPopoverOpen({ open: false, id: null });
    }
  };

  const closeDeleteConfirmation = () => {
    setSelectedItem(null);
    setShowDeleteConfirmation(false);
  };

  const onDeleteItem = () => {
    const deleteItemPayload: DeleteItemPayload = {
      id: selectedItem.id,
      teamId: currentTeam.id
    };
    deleteItemMutation(deleteItemPayload);
    setShowDeleteConfirmation(false);
    logAnalyticEvent(CleverTapEventsCatalogItems.webCatalogItemsDeleteItem, {
      name: selectedItem.name,
      id: selectedItem.id
    });
  };

  const isInitialQueryParamsState = () => {
    return isEqual(itemsQueryParams, initialItemsQueryParamsState);
  };

  const isSearchResultEmpty =
    itemsCount === 0 && !isLoadingItems && itemsQueryParams.search !== null && itemsQueryParams.search !== '';

  const isItemsEmpty =
    itemsList?.length === 0 &&
    !isLoadingItems &&
    itemsQueryParams.search === null &&
    isInitialQueryParamsState() &&
    itemsListSuccess;

  const isFilterResultEmpty =
    itemsList?.length === 0 && !isLoadingItems && !isInitialQueryParamsState() && itemsQueryParams.search === null;

  const isExportDisabled = isSearchResultEmpty || isFilterResultEmpty;

  const onDownloadFile = id => {
    downloadItemsQuery({ ...itemsQueryParams, teamId: currentTeam.id, page: 0, limit: null });
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    logAnalyticEvent(CleverTapEventsCatalogItems.webCatalogItemsExport, {
      searchTerm: itemsQueryParams?.search || null
    });
    onCloseExportPopover(id);
  };

  const getTextForFilter = (filter: string) => {
    switch (filter) {
      case 'category': {
        return joinTextArray(
          pluckValuesFromArrayWithSpecificKey(
            convertedCategoryList?.filter(item => selectedCategory.includes(item.id.toString())),
            'name'
          )
        );
      }
    }
    return '';
  };

  const filterButton = (
    <DropdownWithSearch
      filterId="items-category-filter"
      data={convertedCategoryList || []}
      selectedDraftArray={selectedCategoryDraft}
      setSelectedDraftArray={setSelectedCategoryDraft}
      selectedArray={selectedCategory}
      setSelectedArray={setSelectedCategory}
      dataKey="name"
      dataIdKey={'id'}
      buttonPrimaryText="Category"
      buttonSecondaryText={getTextForFilter('category')}
      filterPlaceholderText="Filter by Category"
      allowSearch={false}
      showResultsCount={false}
    />
  );

  return (
    <div className="table h-full px-6 pb-20 pt-6">
      <div className="flex flex-row items-center gap-3">
        <div className="mr-2.5 text-3xl font-bold text-primaryText">Items</div>
        <If condition={!searchIconClicked && hasManageItemsPermissions && showHeaderActionButtons}>
          <div onClick={handleSearchIconClick}>
            <SearchIcon className="mx-2 flex h-10 cursor-pointer lg:hidden" />
          </div>
        </If>
        {hasManageItemsPermissions && showHeaderActionButtons && (
          <SearchBar
            id="catalog-items-search"
            handleClear={onClearSearch}
            handleChange={onSearchTermChange}
            value={searchText}
            placeholder="Search items"
            wrapperStyle={`sm:max-w-48 w-40 shrink-0 h-10 border border-borderGray rounded 
          ${!searchIconClicked ? 'lg:flex hidden' : ''} ${!hasManageItemsPermissions ? 'pointer-events-none' : ''}`}
            showSearchIcon={true}
            showCrossIcon={true}
            autoFocus={false}
          />
        )}
        <div className="hidden xl:flex">{hasManageItemsPermissions && showHeaderActionButtons && filterButton}</div>
        <div className="ml-auto flex gap-5">
          <div className="hidden xl:flex">
            {hasManageItemsPermissions && showHeaderActionButtons && (
              <CustomPopover
                show={isExportPopoverOpen.open && isExportPopoverOpen.id === ItemsExportPopover.WEB_VIEW}
                anchorComponent={exportAnchor}
                anchorComponentId={ItemsExportPopover.WEB_VIEW}
                onTogglePopover={() => handleExportClick(ItemsExportPopover.WEB_VIEW)}
                onClosePopover={() => onCloseExportPopover(ItemsExportPopover.WEB_VIEW)}
                offset="right-0 top-4"
                highlightAnchor={false}
                minWidth="min-w-[160px]"
                showArrow={true}
                arrowType={PopoverArrowTypes.TOP}
                margin="mt-8"
                popoverBodyStyle="p-2">
                <ExportMenu
                  id="items-export-menu"
                  onDownloadFile={() => onDownloadFile(ItemsExportPopover.WEB_VIEW)}
                  showPDF={false}
                />
              </CustomPopover>
            )}
          </div>
          <If condition={!isItemsEmpty || !hasManageItemsPermissions}>{createButton}</If>
        </div>
      </div>

      {/* tab responsive filter and export option */}
      <div className="flex justify-between pt-8 xl:hidden">
        {hasManageItemsPermissions && showHeaderActionButtons && filterButton}
        {hasManageItemsPermissions && showHeaderActionButtons && (
          <CustomPopover
            show={isExportPopoverOpen.open && isExportPopoverOpen.id === ItemsExportPopover.TAB_VIEW}
            anchorComponent={exportAnchor}
            anchorComponentId={ItemsExportPopover.TAB_VIEW}
            onTogglePopover={() => handleExportClick(ItemsExportPopover.TAB_VIEW)}
            onClosePopover={() => onCloseExportPopover(ItemsExportPopover.TAB_VIEW)}
            offset="right-0 top-4"
            highlightAnchor={false}
            minWidth="min-w-[160px]"
            showArrow={true}
            arrowType={PopoverArrowTypes.TOP}
            margin="mt-8"
            popoverBodyStyle="p-2">
            <ExportMenu
              id="export-items-menu"
              onDownloadFile={() => onDownloadFile(ItemsExportPopover.TAB_VIEW)}
              showPDF={false}
            />
          </CustomPopover>
        )}
      </div>

      <If condition={itemsList?.length !== 0 && hasManageItemsPermissions}>
        <div
          id="total-payouts-count"
          className="mb-5 mt-7 text-sbase text-accentText
         lg:flex">
          Total {itemsCount} Item{itemsCount !== 1 ? 's' : ''}
        </div>
        <div>
          <Divider className="w-full bg-secondaryBtn" />
        </div>
      </If>
      <div
        className={`customNormalScroll table-row 
      h-full grow align-middle ${isLoadingItems && hasMoreItems && 'pb-0'}`}>
        <If condition={hasManageItemsPermissions}>
          <If condition={itemsCount > 0}>
            <ItemsTable itemList={itemsList} handleMoreActionsClick={handleItemsMoreActionsClick} />
            <If condition={!isLoadingItems && hasMoreItems}>
              <div className="h-2" ref={setIntersectionElement}></div>
            </If>
          </If>

          <If condition={isLoadingItems}>
            <InfinteLoadingSpinner />
          </If>

          {/* empty state */}
          <If condition={isItemsEmpty}>
            <div className="flex h-full flex-col items-center justify-center gap-10">
              <div className="flex flex-col items-center gap-5">
                <ItemsEmptyIcon />
                <div className="text-center text-sbase font-semibold text-primaryText">
                  Create Items that team members <br />
                  can add to invoices for a faster <br />
                  checkout.{' '}
                  <span className="cursor-pointer text-secondary" onClick={() => openInNewTab(CATALOG_FAQ_LINK)}>
                    Learn more <OpenNewTabIcon className="mb-1 inline" />
                  </span>
                </div>
              </div>
              {createButton}
            </div>
          </If>

          {/* Search empty state */}
          <If condition={isSearchResultEmpty}>
            <div className="flex h-full flex-col items-center justify-center gap-5">
              <NoSearchResultsIcon />
              <div className="text-sbase text-primaryText">No results found!</div>
            </div>
          </If>

          {/* Filter empty state */}
          <If condition={isFilterResultEmpty}>
            <div className="flex h-full flex-col items-center justify-center gap-5">
              <div className="flex flex-col items-center gap-5">
                <ItemsEmptyIcon />
                <div className="text-sbase text-primaryText">No items found for selected filter</div>
              </div>
            </div>
          </If>
        </If>
        <If condition={!isLoadingItems && !hasManageItemsPermissions}>
          <div className="flex h-full flex-col items-center justify-center gap-5">
            <div className="flex flex-col items-center gap-5">
              <ItemsEmptyIcon />
              <div className="text-sbase text-primaryText">You do not have permissions to view items</div>
            </div>
          </div>
        </If>
      </div>
      {showDeleteConfirmation && (
        <DeleteItemConfirmationPopup
          isDeleteItemLoading={isDeleteItemLoading}
          onDeleteItem={onDeleteItem}
          closeDeleteConfirmation={closeDeleteConfirmation}
        />
      )}
    </div>
  );
};

export default Items;
