import { BackIcon, PreviewIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { CreateInvoiceHeaderType } from 'constants/invoicesConstants';

interface PreviewBarProps {
  title: string;
  onReturn: VoidFn;
  primaryBtnTitle: string;
  secondaryBtnTitle: string;
  onPrimaryClick: VoidFn;
  onSecondaryClick: VoidFn;
  disablePrimaryBtn?: boolean;
  className?: string;
  type?: CreateInvoiceHeaderType;
  teritiaryBtnTitle?: string;
  onTeritiaryClick?: VoidFn;
  primaryBtnId: string;
  secondaryBtnId: string;
}

const PreviewBar: FC<PreviewBarProps> = ({
  title,
  onReturn,
  className,
  primaryBtnTitle,
  secondaryBtnTitle,
  onPrimaryClick,
  onSecondaryClick,
  disablePrimaryBtn = false,
  type = CreateInvoiceHeaderType.CREATE,
  teritiaryBtnTitle = '',
  onTeritiaryClick = null,
  primaryBtnId,
  secondaryBtnId
}) => {
  return (
    <div className={twMerge('min-h-[60px] pl-8 pr-10 bg-primary flex flex-row items-center w-full', className)}>
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row items-center gap-5">
          <BackIcon id={`${title}-back-button`} className="shrink-0 cursor-pointer" onClick={onReturn} />
          <div id={`${title}-title`} className="text-xl font-bold text-primaryText">
            {title}
          </div>
        </div>

        <div className="flex flex-row gap-5">
          <CustomButton
            id={secondaryBtnId}
            onClick={onSecondaryClick}
            StartIcon={PreviewIcon}
            className="h-[38px]"
            childrenContainerStyles="items-center"
            type={ButtonType.SECONDARY}>
            {secondaryBtnTitle}
          </CustomButton>
          <CustomButton id={primaryBtnId} className="h-[38px]" disabled={disablePrimaryBtn} onClick={onPrimaryClick}>
            {primaryBtnTitle}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default PreviewBar;
