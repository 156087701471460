import { API_VERSION } from 'constants/common';
import { baseApi } from 'services/api';
import {
  GetWaitingListRankPayload,
  InitDigitalOnboardingPayload,
  InitDigitalOnboardingResponse,
  UpdateBasicDetailsPayload
} from './digitalOnboarding.model';

const DigitalOnboardingBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    initDigitalOnboarding: builder.query<InitDigitalOnboardingResponse, InitDigitalOnboardingPayload>({
      query: params => {
        const url = `${API_VERSION.V2}/merchants/onboarding/init?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      }
    }),
    updateBasicDetails: builder.mutation<any, UpdateBasicDetailsPayload>({
      query: ({ payload }) => {
        return {
          url: `${API_VERSION.V2}/merchants/onboarding/basic-details`,
          method: 'POST',
          body: payload
        };
      }
    }),
    verifyDigitalUser: builder.mutation({
      query: () => {
        return {
          url: `${API_VERSION.V1}/auth/sign-up/verify`,
          method: 'POST'
        };
      }
    }),
    getWaitingListRank: builder.query<any, GetWaitingListRankPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.V1}/merchants/waiting-list-rank?teamId=${teamId}`,
          method: 'GET'
        };
      }
    })
  })
});

export const {
  useLazyInitDigitalOnboardingQuery,
  useUpdateBasicDetailsMutation,
  useVerifyDigitalUserMutation,
  useLazyGetWaitingListRankQuery
} = DigitalOnboardingBaseApi;
