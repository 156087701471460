import { GrossSalesIcon, PendingInvoicesIcon } from 'assets/icons';
import { PaymentTileEnum } from 'constants/homeConstants';
import React, { FC, useMemo } from 'react';
import { TileData } from '../home.model';
import PaymentTile from './PaymentTile';
import { IPaymentTile } from 'containers/home/types';
import { VoidFn } from 'types/baseTypes';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { InvoicesRouteState, ReceiptRoutesState } from 'routes/types';

interface TileSectionProps {
  getTileDataSuccess: boolean;
  tileData: TileData;
  isFetchingTileData: boolean;
  handleReload: VoidFn;
  isPermissionError: boolean;
}

const TileSection: FC<TileSectionProps> = ({
  handleReload,
  getTileDataSuccess,
  tileData,
  isFetchingTileData,
  isPermissionError
}) => {
  const navigate = useNavigate();

  // TODO
  const onTodaySalesClick = () => {
    const receiptRouteState: ReceiptRoutesState = { isToday: true, showSidePanel: false };
    navigate(routesPath.RECEIPTS, { state: receiptRouteState });
  };

  const onPendingInvoicesClick = () => {
    const invoiceRouteState: InvoicesRouteState = {
      pendingInvoicesTab: true,
      showShareModal: false,
      showSidePanel: false
    };
    navigate(routesPath.INVOICES, { replace: false, state: invoiceRouteState });
  };

  const paymentTileList: IPaymentTile[] = useMemo(() => {
    const paymentTilesData = [
      {
        key: PaymentTileEnum.GROSS_SALES,
        TileIcon: GrossSalesIcon,
        // eslint-disable-next-line quotes
        title: "Today's Sales",
        amount: tileData?.grossSales?.amount ?? null,
        onClick: onTodaySalesClick,
        isGrossSaleTile: true,
        id: 'home-todays-sales-card'
      },
      {
        key: PaymentTileEnum.PENDING_INVOICES,
        TileIcon: PendingInvoicesIcon,
        title: 'Pending Invoices',
        amount: tileData?.pendingInvoice?.amount ?? null,
        count: tileData?.pendingInvoice?.count ?? null,
        onClick: onPendingInvoicesClick,
        id: 'home-pending-invoices-card'
      }
    ];
    return paymentTilesData;
  }, [tileData?.pendingInvoice?.amount, tileData?.pendingInvoice?.count, tileData?.grossSales?.amount]);

  return (
    <div className="mt-5 flex min-h-[236px] flex-col rounded bg-[#ecf1f799] pt-5 ">
      <div className="px-7 text-xl font-bold text-primaryText">Payments</div>
      <div className="customTransparentScroll flex flex-row gap-7 overflow-x-scroll px-7 pt-3 pb-5 ">
        {paymentTileList?.map(tile => {
          return (
            <PaymentTile
              id={tile.id}
              handleReload={handleReload}
              isFetchingTileData={isFetchingTileData}
              isFetchSuccess={getTileDataSuccess}
              key={tile.key}
              TileIcon={tile.TileIcon}
              amount={tile.amount}
              title={tile.title}
              count={tile.count}
              onClick={tile.onClick}
              isGrossSaleTile={tile.key === PaymentTileEnum.GROSS_SALES}
              isPermissionError={isPermissionError}
            />
          );
        })}
      </div>
    </div>
  );
};

export default TileSection;
