import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { UserData } from './digitalOnboarding.model';
import { RootState } from 'store/reducers';

interface DigitalOnboardingState {
  userData: UserData;
  currentData: UserData;
  isDigitalOnboardingEnabled: boolean;
}

const initialState: DigitalOnboardingState = {
  userData: {
    profession: '',
    businessName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    numberOfEmployees: '',
    website: '',
    isSalesUser: false,
    phone: '',
    yelp: '',
    facebook: '',
    otherOnlineReferrence: '',
    status: '',
    waitingListRank: 0,
    referralSource: ''
  },
  currentData: {
    profession: '',
    businessName: '',
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    numberOfEmployees: '',
    website: '',
    isSalesUser: false,
    phone: '',
    yelp: '',
    facebook: '',
    otherOnlineReferrence: '',
    status: '',
    waitingListRank: 0,
    referralSource: ''
  },
  isDigitalOnboardingEnabled: false
};

const digitalOnboardingSlice = createSlice({
  name: 'digitalOnboarding',
  initialState,
  reducers: {
    resetDigitalOnboardingState: () => initialState,
    setUserData: (state, { payload }: PayloadAction<UserData>) => {
      state.userData = payload;
    },
    setCurrentData: (state, { payload }: PayloadAction<UserData>) => {
      state.currentData = payload;
    },
    updateCurrentData: (state, { payload }: PayloadAction<{ key: string; value: string }>) => {
      const { key, value } = payload;
      state.currentData[key] = value;
    },
    setWaitingListRank: (state, { payload }) => {
      state.userData.waitingListRank = payload;
    },
    setIsDigitalOnboardingEnabled: (state, { payload }) => {
      state.isDigitalOnboardingEnabled = payload;
    }
  }
});

export const getUserData = (state: RootState) => state.rootReducer.digitalOnboarding.userData;
export const getCurrentData = (state: RootState) => state.rootReducer.digitalOnboarding.currentData;
export const getIsDigitalOnboardingEnabled = (state: RootState) =>
  state.rootReducer.digitalOnboarding.isDigitalOnboardingEnabled;

export const {
  setUserData,
  setCurrentData,
  updateCurrentData,
  resetDigitalOnboardingState,
  setWaitingListRank,
  setIsDigitalOnboardingEnabled
} = digitalOnboardingSlice.actions;
export default digitalOnboardingSlice.reducer;
