/* eslint-disable react/no-multi-comp */
import React, { useCallback, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCurrentTeam, selectUserDetails, showNotifier } from 'containers/app/appSlice';
import { ArrowRightIcon, CrossIconvector, GreenTickAnimatedIcon } from 'assets/icons';
import { formatAmount } from 'utils/amountUtils';
import { ManualPaymentMethods } from 'containers/home/components/constants';
import { useLazyGetInvoiceByIdQuery, useMarkPaidMutation } from 'containers/invoices/api';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { Divider } from '@material-ui/core';
import { NotifierTypes } from 'constants/notifierConstants';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { CustomButton } from 'components';
import { logAnalyticEvent } from 'utils/analytics';
import { constructArrayObjectAsString, getPageName, throttle } from 'utils/commonUtils';
import SidePanel from 'components/side-panel/SidePanel';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE, FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import NoPermissions from 'components/no-permissions/NoPermissions';

interface NotificationDrawerProps {
  onClose?: VoidFn;
  routeToNextPage?: ({ type }: { type: SidePanelTypes; id: string }) => void;
  isRootElement?: boolean;
  resetInvoicesQuery?: VoidFn;
}

enum PageState {
  MAIN = 'Main',
  SHARE_RECEIPT = 'shareReceipt'
}

const MarkPaidPanel: React.FC<NotificationDrawerProps> = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const currentUser = useAppSelector(selectUserDetails);
  const [pageState, setPageState] = useState<PageState>(PageState.MAIN);
  const dispatch = useAppDispatch();

  const [selectedMethodId, setSelectedMethodId] = React.useState<string | null>(ManualPaymentMethods[0].key);
  const [markPaid, { isSuccess: markPaidSuccess, isError: markPaidError, isLoading: markPaidLoading }] =
    useMarkPaidMutation();

  const [getInvoiceByIdQuery, getInvoiceByIdState] = useLazyGetInvoiceByIdQuery();
  const { data: invoiceData, isSuccess: invoiceDataSuccess, isFetching: fetchingInvoiceData } = getInvoiceByIdState;

  const {
    rootInvoiceId,
    invoiceId: invoiceIdFromUrl,
    rootCustomerId,

    rootReceiptId,
    rootPage
  } = useParams();
  const { handleSidePanelClose, resetInvoicesQuery } = useOutletContext<any>();
  const location = useLocation();
  const navigate = useNavigate();

  const [isRootState, setIsRootState] = useState(true);

  useEffect(() => {
    if (currentTeam?.id) {
      if (invoiceIdFromUrl) {
        getInvoiceByIdQuery({ invoiceId: invoiceIdFromUrl, teamId: currentTeam.id });
      } else if (rootInvoiceId) {
        getInvoiceByIdQuery({ invoiceId: rootInvoiceId, teamId: currentTeam.id });
      }
    }
  }, [invoiceIdFromUrl, rootInvoiceId, currentTeam]);

  useEffect(() => {
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    let routeStateTimeout;
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  useEffect(() => {
    if (invoiceData?.invoiceId) {
      logAnalyticEvent(clevertapEvents.WebInvoicesListMarkAsPaidPanelOpen, {
        'Line Items': constructArrayObjectAsString(invoiceData.lineItems, 'Line Item'),
        Page: getPageName(location.pathname)
      });
    }
    if (invoiceData?.isPaid) {
      handleSidePanelBack();
    }
  }, [invoiceData?.invoiceId]);

  const onMarkAsPaid = () => {
    markPaid({
      invoiceId: invoiceData.invoiceId,
      teamId: currentTeam.id,
      paymentMethod: selectedMethodId
    });
  };

  const markPaidHandler = useCallback(throttle(onMarkAsPaid, 500), [
    invoiceData?.invoiceId,
    currentTeam.id,
    selectedMethodId
  ]);

  useEffect(() => {
    if (markPaidError) {
      dispatch(
        showNotifier({
          type: NotifierTypes.ERROR,
          message: {
            primaryMessage: 'Payment Already Initiated',
            isMessageHtml: true
          }
        })
      );
    }
  }, [markPaidError]);

  const getPaymentMethodFromId = (paymentMethodId: string) => {
    const paymentMethod = ManualPaymentMethods.find(item => item.key === paymentMethodId);
    return paymentMethod?.value ?? '';
  };

  useEffect(() => {
    if (markPaidSuccess) {
      setPageState(PageState.SHARE_RECEIPT);
      if (resetInvoicesQuery) {
        resetInvoicesQuery();
      }
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Mark as paid successfully',
            secondaryMessage: '',
            isMessageHtml: true
          },
          type: NotifierTypes.INFO,
          showClose: false,
          fontStyle: 'text-primary font-normal'
        })
      );
      logAnalyticEvent(clevertapEvents.WebInvoicesListMarkAsPaidPaymentMethod, {
        'Payment Method': getPaymentMethodFromId(selectedMethodId),
        'Invoice Id': invoiceData?.invoiceId
      });
    }
  }, [markPaidSuccess]);

  const onShareReceipt = () => {
    if (rootInvoiceId) {
      if (invoiceIdFromUrl) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-invoice/${invoiceIdFromUrl}`, {
          state: DEFAULT_SIDE_PANEL_ROUTE_STATE,
          replace: true
        });
      } else {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-invoice`, {
          state: DEFAULT_SIDE_PANEL_ROUTE_STATE,
          replace: true
        });
      }
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE,
        replace: true
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE,
        replace: true
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-invoice/${invoiceData?.invoiceId}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE,
        replace: true
      });
    }
  };

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  const handleCancelShareInvoice = () => {
    const routeState = location?.state ?? {};
    if (isRootState) {
      handleSidePanelClose();
    } else if (routeState?.routedFromSecondaryPanel) {
      navigate(-2);
    } else navigate(-1);
  };

  const MainSection = () => {
    return (
      <>
        <div className="flex flex-row items-center p-4">
          <div
            id={`${isRootState ? 'mark-as-paid-panel-close-button' : 'mark-as-paid-panel-back-button'}`}
            onClick={handleSidePanelBack}
            className="cursor-pointer">
            {isRootState ? <CrossIconvector className="shrink-0" /> : <ArrowRightIcon className="rotate-180" />}
          </div>
          <div className="pl-2 text-17px font-bold text-primaryText">{'Mark as Paid'}</div>
        </div>
        <Divider variant="fullWidth" className="bg-secondaryBtn" />
        {invoiceData.isModifiable ? (
          <div className={'mt-8 px-4'}>
            <div className="flex flex-col items-center">
              <div id="mark-as-paid-panel-header" className={'text-17px font-semibold text-primaryText'}>
                Mark as fully paid
              </div>
              <div className={'mt-8 text-px13 font-normal text-primaryText'}>Amount</div>
              <div id="mark-as-paid-panel-amount" className={'mt-2 text-3xl font-bold text-tertiaryText'}>
                ${formatAmount(invoiceData?.amount)}
              </div>
            </div>

            <div className="mt-8">
              {ManualPaymentMethods.map((method, index) => {
                return (
                  <>
                    <div className="mb-4 flex items-center ">
                      <input
                        id={`default-checkbox-${method.key}`}
                        type="radio"
                        value=""
                        onChange={() => setSelectedMethodId(method.key)}
                        checked={selectedMethodId == method.key}
                        className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
                      />
                      <label htmlFor="default-checkbox" className="ml-5 text-sbase font-normal text-primaryText">
                        {method.value}
                      </label>
                    </div>
                  </>
                );
              })}
            </div>

            <CustomButton
              id="mark-as-paid-confirm-button"
              className="path-stroke-current mt-4 min-h-[42px]
         w-full bg-primaryBtn py-2 font-lato normal-case text-primaryBtnText disabled:opacity-25"
              onClick={markPaidHandler}
              disabled={!invoiceData.isModifiable}
              isLoading={markPaidLoading}>
              <div className="font-semibold">Confirm</div>
            </CustomButton>

            <button
              id="mark-as-paid-cancel-button"
              className="mt-4 min-h-[42px] w-full rounded-md
         border-primaryBtn bg-primaryBtnText py-2 font-lato text-primaryBtn shadow-sm hover:bg-[#ECF1F7]"
              onClick={handleSidePanelBack}
              style={{ borderWidth: 1 }}>
              <div className="font-semibold">Cancel</div>
            </button>
          </div>
        ) : (
          <NoPermissions />
        )}
      </>
    );
  };

  const SuccessSection = () => {
    return (
      <div className="flex h-full flex-1 flex-col">
        <div className="flex flex-row items-center p-4">
          <div
            id={`${isRootState ? 'mark-as-paid-panel-success-close-button' : 'mark-as-paid-panel-success-back-button'}`}
            onClick={handleSidePanelBack}
            className="cursor-pointer">
            {isRootState ? <CrossIconvector className="shrink-0" /> : <ArrowRightIcon className="rotate-180" />}
          </div>
          <div className="pl-2 text-17px font-bold text-primaryText">{'Mark as Paid'}</div>
        </div>
        <Divider variant="fullWidth" className="bg-secondaryBtn" />

        <div className="flex h-full flex-1 flex-col justify-center px-4">
          <div className="flex flex-col items-center">
            <GreenTickAnimatedIcon />
            <div className={'mt-3 text-17px font-semibold text-successHighlight'}>Payment marked as paid</div>
            <div className={'mt-8 text-17px font-semibold text-primaryText'}>Send Invoice to Customer?</div>
          </div>

          <CustomButton
            id="mark-as-paid-send-receipt-yes"
            className="path-stroke-current mt-8
         min-h-[42px] w-full bg-primaryBtn py-2 font-lato normal-case text-primaryBtnText"
            onClick={() => onShareReceipt()}>
            <div className="text-sbase font-semibold">Yes</div>
          </CustomButton>

          <button
            id="mark-as-paid-send-receipt-cancel"
            className="mt-4 min-h-[42px] w-full rounded-md
         border-primaryBtn bg-primaryBtnText py-2 font-lato text-primaryBtn shadow-sm hover:bg-[#ECF1F7]"
            style={{ borderWidth: 1 }}
            onClick={handleCancelShareInvoice}>
            <div className="text-sbase font-semibold">Cancel</div>
          </button>
        </div>
      </div>
    );
  };

  return (
    <SidePanel isOpen onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="h-full w-96">
        {fetchingInvoiceData ? (
          <div className="flex h-full w-full items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          invoiceDataSuccess && (
            <section
              className={
                'w-full right-0 ' +
                'absolute bg-white h-full shadow-xl ' +
                'delay-400 duration-500 ease-in-out transition-all transform overflow-auto'
              }>
              {pageState === PageState.MAIN ? <MainSection /> : <SuccessSection />}
            </section>
          )
        )}
      </div>
    </SidePanel>
  );
};

export default MarkPaidPanel;
