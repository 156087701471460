import { IInvoiceSuggestedItem } from 'containers/product-catalog/items/items.model';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { getAmountWithCurrency } from 'utils/amountUtils';

interface ItemSuggestionsPanelProps {
  recentItemsList: IInvoiceSuggestedItem[];
  libraryItemsList: IInvoiceSuggestedItem[];
  onSelectListItem: (IInvoiceSuggestedItem) => void;
  inputText: string;
  onCreateOneTimeItem: () => void;
}

const ItemSuggestionsPanel: FC<ItemSuggestionsPanelProps> = ({
  recentItemsList = [],
  onSelectListItem,
  inputText,
  onCreateOneTimeItem,
  libraryItemsList = []
}) => {
  return (
    <div className={twMerge('flex overflow-hidden flex-col gap-5 max-h-[294px] py-[22px]')}>
      {inputText.length > 0 && (
        <div
          onClick={onCreateOneTimeItem}
          className="shrink-0 cursor-pointer truncate px-5 text-17px
           font-semibold text-secondary">{`Add one-time item -  “${inputText}”`}</div>
      )}
      {(recentItemsList.length > 0 || libraryItemsList.length > 0) && (
        <div className="space-y-4 overflow-y-auto">
          {recentItemsList.length > 0 && (
            <div>
              <div className="mb-1 px-5 font-semibold text-accentText">Recent items</div>
              <div className="flex flex-col">
                {recentItemsList?.map((recentItem, index: number) => {
                  return (
                    <div
                      className="cursor-pointer py-[10px] font-semibold
                    text-heading hover:bg-secondaryBtn active:bg-secondaryBtn active:opacity-75"
                      key={recentItem.id + index + recentItem.name}
                      onClick={() => onSelectListItem(recentItem)}>
                      <div className="flex items-center justify-between px-5 text-17px">
                        <div className="w-0 grow break-words line-clamp-3">{recentItem.name}</div>
                        <div className="w-1/4 break-words text-right">
                          {getAmountWithCurrency(recentItem.unitPrice)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          )}

          {libraryItemsList.length > 0 && (
            <>
              <div className="mb-1 px-5 font-semibold text-accentText">All items</div>
              <div className="flex shrink-0 flex-col">
                {libraryItemsList?.map((libraryItem, index: number) => {
                  return (
                    <div
                      className="cursor-pointer py-[10px] font-semibold text-heading
                    hover:bg-secondaryBtn active:bg-secondaryBtn active:opacity-75"
                      key={libraryItem.id + index + libraryItem.name}
                      onClick={() => onSelectListItem(libraryItem)}>
                      <div className="flex items-center justify-between px-5 text-17px">
                        <div className="w-0 grow break-words line-clamp-3">{libraryItem.name}</div>
                        <div className="w-1/4 break-words text-right">
                          {getAmountWithCurrency(libraryItem.unitPrice)}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default ItemSuggestionsPanel;
