import { ArrowRightIcon } from 'assets/icons';
import { InvoiceTabEnum } from 'containers/invoices/create-invoice/constants';
import { TaxesAndFeesResponse } from 'containers/invoices/invoices.model';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useContext, useEffect } from 'react';
import { selectTipEnabledEditPage, toggleTipEnabledEditPage, updateTipEnabledEditPage } from '../invoicesEditSlice';
import { CustomSwitch } from 'components';
import { openScanpayFAQInNewTab } from 'utils/urlUtils';
import { CleverTapEventsInvoices } from 'containers/invoices/events';

interface PaymentOptionsSectionEditProps {
  isInvoiceEdit?: boolean;
  className?: string;
  taxesAndFees?: TaxesAndFeesResponse;
  context: React.Context<any>;
}

const PaymentOptionsSectionEdit: FC<PaymentOptionsSectionEditProps> = ({
  taxesAndFees = null,
  context,
  isInvoiceEdit = false
}) => {
  const tipEnabledState = useAppSelector(selectTipEnabledEditPage);
  const dispatch = useAppDispatch();
  const { selectedInvoiceTab, setSelectedInvoiceTab } = useContext<any>(context);

  const isTabSelected = selectedInvoiceTab === InvoiceTabEnum.PAYMENT_OPTIONS;

  useEffect(() => {
    // TODO api call is unnecessary during edit invoice
    if (taxesAndFees && !isInvoiceEdit) {
      dispatch(updateTipEnabledEditPage(taxesAndFees?.tip?.enabled));
    }
  }, [taxesAndFees]);

  const onTipConfigChange = () => {
    setSelectedInvoiceTab(InvoiceTabEnum.PAYMENT_OPTIONS);
    dispatch(toggleTipEnabledEditPage());
  };

  const onTipsLearnMoreClick = () => {
    openScanpayFAQInNewTab(CleverTapEventsInvoices.webInvoicesEditInvoiceTipsFaqClick);
  };

  return (
    <div>
      <div className={`mb-2 text-xl font-bold ${isTabSelected ? 'text-primaryBtn' : 'text-heading'}`}>
        Payment Options
      </div>
      <div
        className={`flex flex-col rounded-md border px-[30px] py-5 1439px:p-10 
                    ${isTabSelected ? 'border-primaryBtn' : 'border-borderGray'}`}>
        <div className="mb-3 text-17px font-semibold text-heading">Tips</div>
        <div className="flex flex-row items-center gap-3 rounded border border-x-borderGray p-5">
          <CustomSwitch
            enabled={tipEnabledState}
            toggleSwitch={onTipConfigChange}
            id="edit-invoice-allow-tip-toggle-switch"
          />
          <div className="text-17px font-semibold text-primaryText">Allow customers to leave a tip</div>
          <div onClick={onTipsLearnMoreClick} className="ml-auto flex cursor-pointer flex-row items-center">
            <div id="edit-invoice-learn-more" className="mr-2 text-sbase font-semibold text-secondary">
              Learn more
            </div>
            <ArrowRightIcon className="shrink-0" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentOptionsSectionEdit;
