import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { ArrowRightIcon, NavCloseIcon } from 'assets/icons';
import { CustomButton, GenericError } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import CustomInput from 'components/custom-input.tsx/CustomInput';
import { REGEX } from 'constants/common';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { VoidFn, clevertapEvents } from 'types/baseTypes';
import { useConfirmRefundMutation, useLazyGetReceiptByIdQuery } from '../api';
import { selectRefundAmount, selectRefundReason, updateRefundAmount, updateRefundReason } from '../receiptsSlice';
import { isEmpty } from '../util';
import {
  selectCurrentTeam,
  selectCurrentTeamRefundPermissions,
  selectUserDetails,
  showNotifier
} from 'containers/app/appSlice';
import { logAnalyticEvent } from 'utils/analytics';
import { constructArrayObjectAsString, getPageName } from 'utils/commonUtils';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import SidePanel from 'components/side-panel/SidePanel';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import NoPermissions from 'components/no-permissions/NoPermissions';
import { APIErrorStatuses } from 'services/type';
import { NotifierTypes } from 'constants/notifierConstants';

interface CreateRefundPanelProps {
  onClose?: VoidFn;
  routeToNextPage?: ({ type }: { type: SidePanelTypes; id: string }) => void;
  currentTeamId?: number;
}

const CreateRefundPanel: FC<CreateRefundPanelProps> = () => {
  const [amountError, setAmountError] = useState('');
  const dispatch = useAppDispatch();
  const location = useLocation();
  const refundAmount = useAppSelector(selectRefundAmount);
  const refundReason = useAppSelector(selectRefundReason);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [getReceiptByIdQuery, getReceiptByIdState] = useLazyGetReceiptByIdQuery();
  const { isSuccess: receiptDataSuccess, isFetching: fetchingReceiptData, data: receiptData } = getReceiptByIdState;

  const { receiptId, rootReceiptId, rootInvoiceId, rootPage, rootCustomerId, rootDisputeId } = useParams();
  const navigate = useNavigate();

  const { handleSidePanelClose } = useOutletContext<any>();

  const [isRootState, setIsRootState] = useState(true);

  useEffect(() => {
    if ((receiptId || rootReceiptId) && currentTeam?.id) {
      getReceiptByIdQuery({ receiptId: receiptId ? receiptId : rootReceiptId, teamId: currentTeam.id });
    }
  }, [receiptId, currentTeam]);

  useEffect(() => {
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    let routeStateTimeout;
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const [
    confrimRefundQuery,
    {
      isSuccess: confrimRefundSuccess,
      isLoading: refundAPILoading,
      error: confirmRefundError,
      isError: isConfirmRefundError
    }
  ] = useConfirmRefundMutation();

  const showErrorToast = (message: string, duration = 5000) => {
    dispatch(
      showNotifier({
        type: NotifierTypes.ERROR,
        message: {
          primaryMessage: message
        },
        showClose: true,
        duration
      })
    );
  };

  useEffect(() => {
    if (isConfirmRefundError) {
      if (confirmRefundError && confirmRefundError['status'] === APIErrorStatuses.STATUS_400) {
        showErrorToast(confirmRefundError['data']?.details ?? 'Something went wrong', 6000);
      } else {
        showErrorToast('Something went wrong');
      }
    }
  }, [confirmRefundError, isConfirmRefundError]);

  const onCreateRefund = () => {
    confrimRefundQuery({
      teamId: currentTeam.id.toString(),
      parentID: receiptId ? receiptId : rootReceiptId,
      amount: refundAmount
    });
  };

  useEffect(() => {
    if (receiptData) {
      logAnalyticEvent(clevertapEvents.webReceiptsListRefundPanelOpen, {
        'Line Items': constructArrayObjectAsString(receiptData?.lineItems, 'Line Item'),
        Page: getPageName(location.pathname)
      });
    }
  }, [receiptData]);

  useEffect(() => {
    if (confrimRefundSuccess) {
      if (rootReceiptId) {
        if (receiptId) {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/initiate-refund/${receiptId}`, {
            replace: true
          });
        } else {
          navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/initiate-refund`, { replace: true });
        }
      } else if (rootInvoiceId) {
        navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/initiate-refund/${receiptId}`, { replace: true });
      } else if (rootPage) {
        navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/initiate-refund/${receiptId}`, { replace: true });
      } else if (rootCustomerId) {
        navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/initiate-refund/${receiptId}`, { replace: true });
      } else if (rootDisputeId) {
        navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/initiate-refund/${receiptId}`, {
          replace: true
        });
      }
    }
  }, [confrimRefundSuccess]);

  const currentRefundPermissions = useAppSelector(selectCurrentTeamRefundPermissions);
  const currentUser = useAppSelector(selectUserDetails);

  const isCreatedBySelf = () => {
    return receiptData.merchantId === currentUser.id;
  };

  const hasRefundPermissions = () => {
    return (
      currentRefundPermissions.createRefundsByTeam ||
      (isCreatedBySelf() && currentRefundPermissions.createRefundsBySelf)
    );
  };

  const handleRefundAmount = (newAmount: string) => {
    const amount = newAmount.replace(REGEX.nonNumberRegExp, '');
    if (REGEX.amount.test(amount)) {
      setAmountError('');
      if (+amount > +receiptData?.amount) setAmountError('Amount should not exceed collected amount.');
      dispatch(updateRefundAmount(amount));
    }
  };

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  return (
    <SidePanel onClose={handleSidePanelClose} isOpen={true} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="flex h-full w-96 items-center justify-center">
        {fetchingReceiptData ? (
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          receiptDataSuccess && (
            <div className="flex h-full w-full flex-col">
              <div className="flex items-center gap-2 border-b-[1px] py-5 px-4">
                <div onClick={handleSidePanelBack} className="cursor-pointer">
                  {isRootState ? <NavCloseIcon /> : <ArrowRightIcon className="rotate-180" />}
                </div>
                <div className="text-17px font-semibold text-headingGray">Create refund</div>
              </div>
              {hasRefundPermissions() ? (
                <div className="mx-4 mt-16 flex flex-col items-center justify-center">
                  <div className="text-sbase font-semibold text-primaryText">Refund Amount</div>
                  <div className="relative flex w-full flex-col items-center justify-center ">
                    <CustomInput
                      id="refund-receipt-refund-amount"
                      name="refundAmount"
                      placeholder="0.00"
                      value={refundAmount ? `$${refundAmount}` : ''}
                      inputStyle="flex items-center justify-center border-b-gray-1 border-b-[1px] w-40"
                      className="bg-transparent px-0.5 pb-0 text-center text-4xl text-tertiaryText
                      outline-none marker:rounded-none placeholder:text-4xl"
                      onChange={handleRefundAmount}
                    />
                    <div className="text-semibold absolute top-14 text-sbase text-[#D95945]">{amountError}</div>
                  </div>
                  <CustomInput
                    id="refund-receipt-refund-reason"
                    name="refundReason"
                    placeholder="What is the reason for refund?*"
                    value={refundReason}
                    inputStyle="w-full mt-16"
                    className="text-semibold w-full px-6 text-sbase outline-none
                        placeholder:text-sbase placeholder:font-semibold placeholder:text-accentText"
                    onChange={value => dispatch(updateRefundReason(value))}
                  />
                  <div className="mt-12 flex w-full flex-col justify-end gap-5">
                    <CustomButton
                      id="create-refund-continue"
                      onClick={onCreateRefund}
                      className="w-full"
                      disabled={!refundAmount || !refundReason || !isEmpty(amountError)}
                      isLoading={refundAPILoading}>
                      Continue
                    </CustomButton>
                    <CustomButton
                      id="create-refund-cancel"
                      className="w-full"
                      type={ButtonType.SECONDARY}
                      onClick={handleSidePanelBack}>
                      Cancel
                    </CustomButton>
                  </div>
                </div>
              ) : (
                <NoPermissions />
              )}
            </div>
          )
        )}
      </div>
      {!fetchingReceiptData && !receiptDataSuccess && <GenericError handleErrorBack={handleSidePanelBack} />}
    </SidePanel>
  );
};

export default CreateRefundPanel;
