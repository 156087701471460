import { Divider } from '@material-ui/core';
import classnames from 'classnames';
import {
  BinIcon,
  CheckboxTickIcon,
  GreenTickIcon,
  ListActionsIcon,
  PrintIcon,
  ShareIcon,
  VerticalListActionsIcon
} from 'assets/icons';
import { CustomCheckbox, CustomPopover, If } from 'components';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { selectCurrentTeam, selectCurrentTeamCustomersPermissions } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import React, { FC, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { twMerge } from 'tailwind-merge';
import { BoolFn, VoidFn } from 'types/baseTypes';
import { formatAmount, getAmountWithCurrency, roundToTwoDecimals } from 'utils/amountUtils';
import { logAnalyticEvent } from 'utils/analytics';
import { getInitials } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import { useLazyDownloadPrintInvoiceAsPDFQuery } from '../api';
import { CleverTapEventsInvoices } from '../events';
import { IInvoice, InvoiceStatus } from '../invoices.model';
import { ROOT_ROUTE_STATE } from 'routes/constants';

interface InvoicesTableProps {
  invoicesList: IInvoice[];
  onToggleSelectAllLoadedInvoices: VoidFn;
  areAllLoadedInvoicesSelected: BoolFn;
  isSelectedInvoice: (invoice: IInvoice) => string;
  onToggleInvoiceSelection: (invoice: IInvoice, isCheckdInvoice: boolean) => void;
  onInvoiceClick: (invoiceId: string, invoiceStatus: string) => void;
  currentSelectedId: string;
  handleMoreActionsClick: ({ type, invoiceId }: { type: SidePanelTypes; invoiceId: string }) => void;
}

const tableHeaders: Record<string, string> = {
  date: 'Date',
  invoiceNo: 'Invoice No.',
  customer: 'Customer',
  createdBy: 'Created by',
  status: 'Status',
  amount: 'Amount'
};

const isHiddenHeader = (header: string): boolean => {
  if (header === 'Customer') return true;
  return false;
};

const InvoicesTable: FC<InvoicesTableProps> = ({
  invoicesList,
  onToggleSelectAllLoadedInvoices,
  areAllLoadedInvoicesSelected,
  onToggleInvoiceSelection,
  isSelectedInvoice,
  onInvoiceClick,
  currentSelectedId,
  handleMoreActionsClick
}) => {
  const [currentActionsPopoverId, setCurrentActionsPopoverId] = useState<string>(null);

  const [downloadPrintInvoiceAsPDF] = useLazyDownloadPrintInvoiceAsPDFQuery();
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { rootInvoiceId } = useParams();
  const navigate = useNavigate();

  const isShareEnabled = () => {
    return (
      customerPermissions.viewCustomersCreatedByTeam ||
      customerPermissions.editCustomersCreatedByTeam ||
      customerPermissions.editExistingCustomers
    );
  };

  // eslint-disable-next-line react/no-multi-comp
  const ListActionsAnchor: React.FC<{ isPanelOpen: boolean }> = ({ isPanelOpen }) => {
    return (
      <div id="invoice-table-more-options" className="py-2 sm:pl-4">
        <ListActionsIcon
          className={twMerge(
            'hidden shrink-0 lg:flex',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
        <VerticalListActionsIcon
          className={twMerge(
            'mx-2 flex shrink-0 lg:hidden',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
      </div>
    );
  };

  const onToggleListItemActions = (invoiceId: string) => {
    if (invoiceId === currentActionsPopoverId) {
      setCurrentActionsPopoverId(null);
    } else {
      setCurrentActionsPopoverId(invoiceId);
    }
  };

  const isListItemActionsVisible = (invoiceId: string) => {
    return invoiceId === currentActionsPopoverId;
  };

  const onCloseListItemActionsPopover = (invoiceId: string) => {
    if (currentActionsPopoverId === invoiceId) {
      setCurrentActionsPopoverId(null);
    }
  };

  const getInvoiceStatus = (status: InvoiceStatus) => {
    switch (status) {
      case InvoiceStatus.PENDING:
        return 'Pending';
      case InvoiceStatus.PAID:
      case InvoiceStatus.PAID_QR:
        return 'Paid';
      case InvoiceStatus.REFUNDED:
        return 'Refunded';
      case InvoiceStatus.REFUND_INITIATED:
        return 'Refund initiated';
      case InvoiceStatus.PROCESSING:
        return 'Payment processing';
      default:
        return `Payment ${status}`;
    }
  };

  const getInvoiceStatusColor = (status: InvoiceStatus) => {
    switch (status) {
      case InvoiceStatus.PENDING:
      case InvoiceStatus.PROCESSING:
        return '#F2994A';
      case InvoiceStatus.PAID:
      case InvoiceStatus.PAID_QR:
        return '#419364';
      case InvoiceStatus.REFUNDED:
      case InvoiceStatus.REFUND_INITIATED:
        return '#3E556D';
      default:
        return '#419364';
    }
  };

  const getRemainderDaysText = (invoice: IInvoice) => {
    let statusDate: Date;
    if (invoice.status === InvoiceStatus.PENDING) {
      statusDate = new Date(invoice.createdAt);
      const days = DateUtils.getDifferenceInDays({ secondaryDate: statusDate });
      if (days === 0) {
        return 'Since today';
      }
      return `Since ${days} ${days > 1 ? 'Days' : 'Day'}`;
    }
    statusDate = new Date(invoice.lastTrnxDate);
    const days = DateUtils.getDifferenceInDays({ secondaryDate: statusDate });
    if (days === 0) {
      return 'Today';
    }
    return `${days} ${days > 1 ? 'Days' : 'Day'} ago`;
  };

  const onPrintInvoiceAsPDF = (invoiceId: string) => {
    downloadPrintInvoiceAsPDF({ invoiceId, teamId: currentTeam.id });
    onCloseListItemActionsPopover(invoiceId);
  };

  // !TODO change inline functions
  // eslint-disable-next-line react/no-multi-comp
  const ListItemActionsMenu: FC<any> = ({
    invoiceId,
    isModifiable,
    isPaid,
    invoiceStatus
  }: {
    invoiceId: string;
    isModifiable: boolean;
    isPaid: boolean;
    invoiceStatus: InvoiceStatus;
  }) => {
    const isPending = invoiceStatus === InvoiceStatus.PENDING;
    return (
      <div className="space-y-3 text-sbase font-semibold text-primaryText">
        {isShareEnabled() && (
          <div
            id="invoice-table-3dot-share-invoice"
            className={'flex flex-row items-center gap-2'}
            onClick={() => {
              onCloseListItemActionsPopover(invoiceId);
              navigate(
                `/invoices/${currentTeam?.id}/${invoiceId}/${isPending ? 'share-pending-invoice' : 'share-invoice'}`,
                { state: ROOT_ROUTE_STATE }
              );
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesMMShareInvoice, { 'Invoice Id': invoiceId });
            }}>
            <ShareIcon />
            <div>Share Invoice</div>
          </div>
        )}
        <div
          id="invoice-table-3dot-print-invoice"
          onClick={e => {
            onCloseListItemActionsPopover(invoiceId);
            e.stopPropagation();
            onPrintInvoiceAsPDF(invoiceId);
            logAnalyticEvent(CleverTapEventsInvoices.webInvoicesMMPrint, { 'Invoice Id': invoiceId });
          }}
          className="flex flex-row items-center gap-2">
          <PrintIcon />
          <div>Print</div>
        </div>
        {isModifiable && (
          <div
            id="invoice-table-3dot-mark-as-paid"
            className={'flex flex-row items-center gap-2'}
            onClick={() => {
              if (isModifiable) {
                onCloseListItemActionsPopover(invoiceId);
                navigate(`/invoices/${currentTeam?.id}/${invoiceId}/mark-as-paid`, { state: ROOT_ROUTE_STATE });
                logAnalyticEvent(CleverTapEventsInvoices.webInvoicesMMMarkAsPaid, { 'Invoice Id': invoiceId });
              }
            }}>
            <GreenTickIcon />
            <div>Mark as Paid</div>
          </div>
        )}
        {isModifiable && (
          <div
            id="invoice-table-3dot-delete-invoice"
            className={'flex flex-row items-center gap-2'}
            onClick={() => {
              onCloseListItemActionsPopover(invoiceId);
              navigate(`/invoices/${currentTeam?.id}/${invoiceId}/delete-invoice`, { state: ROOT_ROUTE_STATE });
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesMMDelete, { 'Invoice Id': invoiceId });
            }}>
            <BinIcon />
            <div>Delete</div>
          </div>
        )}
      </div>
    );
  };

  return (
    <table className="relative w-full table-auto">
      <thead className="sticky top-[-4px] z-30 border-borderGray bg-primary">
        <Divider className="absolute top-[3.7rem] w-full bg-borderGray" />
        <tr className="text-slateGrey text-left text-xs lg:text-base [&>*]:py-5">
          <th>
            <CustomCheckbox
              id="select-all-invoice-checkbox"
              onClick={onToggleSelectAllLoadedInvoices}
              isChecked={areAllLoadedInvoicesSelected()}>
              {areAllLoadedInvoicesSelected() ? <CheckboxTickIcon /> : ''}
            </CustomCheckbox>
          </th>
          {Object.values(tableHeaders).map((header, index) => {
            return (
              <th
                className={`min-w-[100px] max-w-[120px] text-sbase font-semibold text-primaryText ${
                  isHiddenHeader(header) && 'hidden lg:flex'
                }`}
                key={header + index}>
                {header}
              </th>
            );
          })}
          <th></th>
        </tr>
      </thead>
      <tbody>
        {invoicesList?.map((invoice, index) => (
          <tr
            key={invoice.invoiceId}
            id={`invoice-table-row-${index + 1}`}
            className={`remove-highlight cursor-pointer border-b border-secondaryBtn align-top [&>*]:py-5 [&>*]:pr-2 ${
              isSelectedInvoice(invoice) || rootInvoiceId === invoice.invoiceId ? 'bg-secondaryBtn' : ''
            }`}
            onClick={() => {
              onInvoiceClick(invoice.invoiceId, invoice.status);
              logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetail, { 'Invoice Id': invoice.invoiceId });
            }}>
            {/* checkbox */}
            <td className="w-8">
              <CustomCheckbox
                id={`${invoice.invoiceId}-checkbox`}
                onClick={() => onToggleInvoiceSelection(invoice, Boolean(isSelectedInvoice(invoice)))}
                isChecked={Boolean(isSelectedInvoice(invoice))}>
                <If condition={Boolean(isSelectedInvoice(invoice))}>
                  <CheckboxTickIcon />
                </If>
              </CustomCheckbox>
            </td>
            {/* createdAt */}
            <td className="max-w-[120px]">
              <div className="truncate text-sbase text-primaryText">
                <div>{DateUtils.getDateInFormat({ date: new Date(invoice.createdAt), dateFormat: 'dd MMM yyyy' })}</div>
              </div>
            </td>
            {/* Invoice number */}
            <td className="max-w-[120px]">
              <div className="text-sbase font-semibold text-primaryText">
                <div className="truncate">{invoice.invoiceNumber}</div>
              </div>
            </td>
            {/* customer */}
            <td id={`customer-name-row-${index + 1}`} className="hidden  max-w-[120px] lg:flex">
              <div className="text-sbase text-primaryText">
                <div id={`row-${index + 1}-customer-name`} className="max-w-[120px] truncate">
                  {invoice.customerName || invoice.customerPhone || invoice.customerEmail || '_'}
                </div>
              </div>
            </td>
            {/* created by member */}
            <td className="min-w-[80px] max-w-[120px]">
              <div className="flex flex-row items-center gap-2 pl-6 lg:pl-0">
                <CustomAvatar height="h-6" width="w-6" src={invoice.merchantProfilePicureUrl}>
                  <div
                    className="truncate text-px13 font-semibold
                      text-[#333333]">
                    {getInitials(invoice.merchantName)}
                  </div>
                </CustomAvatar>
                <div className="hidden truncate text-sbase text-primaryText lg:inline-block">
                  {invoice.merchantName}
                </div>
              </div>
            </td>
            {/* invoice status */}
            <td className="min-w-[100px] max-w-[120px]">
              <div className="flex flex-col gap-2">
                <div className="text-sbase font-bold" style={{ color: getInvoiceStatusColor(invoice.status) }}>
                  {getInvoiceStatus(invoice.status)}
                </div>
                <div className="text-px13 font-normal text-primaryText">{getRemainderDaysText(invoice)}</div>
              </div>
            </td>
            {/* amount */}
            <td className="min-w-[80px] max-w-[120px]">
              <div className="flex flex-col gap-2">
                <div
                  className="truncate text-sbase font-semibold
                text-primaryText">
                  {/* Amount excluding tip*/}
                  {getAmountWithCurrency(roundToTwoDecimals(invoice.payableAmount))}
                </div>

                <If condition={+invoice.tipAmount > 0}>
                  <div className="truncate text-px13 text-primaryText">{'+$' + formatAmount(invoice.tipAmount)}</div>
                </If>
              </div>
            </td>

            <td
              className="w-14 pr-2"
              onClick={e => {
                e.stopPropagation();
              }}>
              <CustomPopover
                showArrow={true}
                arrowOffset="right-6"
                highlightAnchor={false}
                anchorComponent={ListActionsAnchor}
                zIndex="z-[101]"
                offset="-right-6"
                margin="mt-1"
                onClosePopover={() => onCloseListItemActionsPopover(invoice.invoiceId)}
                show={isListItemActionsVisible(invoice.invoiceId)}
                onTogglePopover={() => onToggleListItemActions(invoice.invoiceId)}>
                {
                  <ListItemActionsMenu
                    invoiceId={invoice.invoiceId}
                    isModifiable={invoice.isModifiable}
                    isPaid={invoice.isPaid}
                    invoiceStatus={invoice.status}
                  />
                }
              </CustomPopover>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default InvoicesTable;
