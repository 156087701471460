import { LoadingSpinnerAnimation } from 'assets/animations';
import CustomModal from 'components/custom-modal/CustomModal';
import { CustomButton as Button } from 'components';
import Lottie from 'lottie-react';
import React, { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { CircleCloseIcon } from 'assets/icons';

interface GenericDeleteModalProps {
  showModal: boolean;
  isActionInProgress: boolean;
  title: string;
  subTitle: string;
  primaryBtnLabel: string;
  primaryBtnId: string;
  onPrimaryBtnClick: VoidFn;
  primaryBtnStyle?: string;
  secondaryBtnLabel: string;
  secondaryBtnId: string;
  onSecondaryBtnClick: VoidFn;
  titleStyle?: string;
  subTitleStyle?: string;
}

const GenericDeleteModal: FC<GenericDeleteModalProps> = ({
  showModal,
  isActionInProgress,
  title,
  subTitle,
  primaryBtnLabel,
  primaryBtnId,
  onPrimaryBtnClick,
  primaryBtnStyle = '',
  secondaryBtnLabel,
  secondaryBtnId,
  onSecondaryBtnClick,
  titleStyle,
  subTitleStyle
}) => {
  const getLoader = () => <Lottie className="h-12 w-12" animationData={LoadingSpinnerAnimation} loop={true} />;
  return (
    <CustomModal
      closeModal={onSecondaryBtnClick}
      open={showModal}
      backgroundColor="bg-primary"
      className="min-h-[240px] w-[380px] md:w-[380px]">
      <div className="relative flex w-full flex-col items-center px-10 py-9">
        <div className="absolute top-2 right-2">
          <CircleCloseIcon onClick={onSecondaryBtnClick} className="shrink-0 cursor-pointer" />
        </div>
        <div className={`mb-3 text-center text-17px font-semibold text-primaryText ${titleStyle}`}>{title}</div>
        <div className={`mb-7 text-center text-sbase font-normal text-primaryText ${subTitleStyle}`}>{subTitle}</div>
        <Button
          id={primaryBtnId}
          className={`mb-5 h-[38px] w-full bg-error text-sbase font-semibold 
              text-primary shadow-none first-line:font-lato hover:bg-redButtonHover
              ${isActionInProgress ? 'pointer-events-none' : ''} ${primaryBtnStyle}`}
          onClick={onPrimaryBtnClick}>
          {isActionInProgress ? <>{getLoader()}</> : <div>{primaryBtnLabel}</div>}
        </Button>
        <Button
          id={secondaryBtnId}
          className="h-6 w-full border-none bg-transparent font-lato text-sbase font-semibold
          text-secondaryBtnText shadow-none hover:bg-transparent active:shadow-none"
          onClick={onSecondaryBtnClick}>
          <div>{secondaryBtnLabel}</div>
        </Button>
      </div>
    </CustomModal>
  );
};

export default GenericDeleteModal;
