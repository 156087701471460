import {
  AccountsTabIcon,
  BankingTabIcon,
  CatalogTabIcon,
  CategoriesTabIcon,
  CustomersTabIcon,
  DebitCardsTabIcon,
  DisputesTabIcon,
  HomeTabIcon,
  IntegrationsTabIcon,
  InvoiceSettingsIcon,
  InvoicesTabIcon,
  ManageTeamTabIcon,
  MyAccountTabIcon,
  PaymentLimitsTabIcon,
  PaymentsTabIcon,
  PayoutsTabIcon,
  ReceiptsTabIcon,
  ServiceChargeTabIcon,
  TaxesTabIcon,
  TeamProfileTabIcon
} from 'assets/icons';
import CategoriesTabReactIcon from 'assets/react-icons/CategoriesTabReactIcon';
import ItemsTabReactIcon from 'assets/react-icons/ItemsTabReactIcon';
import routesPath from 'routes/RoutesPath';
import { NavTab, clevertapEvents, navTabKeys } from 'types/baseTypes';

export const baseNavTabs: NavTab[] = [
  {
    id: 'home-nav',
    key: navTabKeys.home,
    title: 'Home',
    icon: HomeTabIcon,
    path: routesPath.HOME
  },
  {
    id: 'invoices-nav',
    key: navTabKeys.invoices,
    title: 'Invoices ',
    icon: InvoicesTabIcon,
    path: routesPath.INVOICES,
    clevertapEvent: clevertapEvents.webInvoicesListView
  },
  {
    id: 'payments-nav',
    key: navTabKeys.receiptsSection,
    title: 'Payments',
    icon: PaymentsTabIcon,
    path: routesPath.RECEIPTS,
    clevertapEvent: clevertapEvents.webReceiptsListView,
    hasChildTabs: true,
    childTabs: [
      {
        id: 'receipts-list-nav',
        key: navTabKeys.receipt,
        title: 'Receipts',
        icon: ReceiptsTabIcon,
        path: routesPath.RECEIPTS,
        clevertapEvent: clevertapEvents.webReceiptsListView
      },
      {
        id: 'disputes-nav',
        key: navTabKeys.disputes,
        title: 'Disputes',
        icon: DisputesTabIcon,
        path: routesPath.DISPUTES,
        clevertapEvent: clevertapEvents.webDisputesListView
      }
    ]
  },
  {
    id: 'payouts-nav',
    key: navTabKeys.payouts,
    title: 'Balances & Payouts',
    icon: PayoutsTabIcon,
    path: routesPath.PAYOUTS_BALANCE,
    clevertapEvent: clevertapEvents.webPayoutsBalanceView,
    hasChildTabs: true,
    childTabs: [
      {
        id: 'payouts-balance-nav',
        key: navTabKeys.payoutsBalance,
        title: 'Balance',
        icon: CategoriesTabIcon,
        path: routesPath.PAYOUTS_BALANCE,
        clevertapEvent: clevertapEvents.webPayoutsBalanceView
      },
      {
        id: 'payouts-list-nav',
        key: navTabKeys.payoutsList,
        title: 'Payouts',
        icon: PayoutsTabIcon,
        path: routesPath.PAYOUTS_LIST,
        clevertapEvent: clevertapEvents.webPayoutsListView
      },
      {
        id: 'payouts-settings-nav',
        key: navTabKeys.payoutsSettings,
        title: 'Payout Settings',
        icon: InvoiceSettingsIcon,
        path: routesPath.PAYOUTS_SETTINGS,
        clevertapEvent: clevertapEvents.webPayoutsSettings
      }
    ]
  },
  {
    id: 'customers-nav',
    key: navTabKeys.customers,
    title: 'Customers ',
    icon: CustomersTabIcon,
    path: routesPath.CUSTOMERS,
    clevertapEvent: clevertapEvents.webCustomersListView
  }
];

export const paymentNavTabs: NavTab[] = [
  {
    id: 'invoice-settings-nav',
    key: navTabKeys.invoiceSettings,
    title: 'Invoice Settings',
    icon: InvoiceSettingsIcon,
    path: routesPath.DISCOUNTS_TIPS_FEES,
    clevertapEvent: clevertapEvents.WebDiscountTipsFees
  },
  {
    id: 'catalog-nav',
    key: navTabKeys.catalog,
    title: 'Catalog',
    icon: CatalogTabIcon,
    path: routesPath.CATALOG_ITEMS,
    clevertapEvent: clevertapEvents.webCatalogMenu,
    hasChildTabs: true,
    childTabs: [
      {
        id: 'catalog-items-nav',
        key: navTabKeys.catalogItems,
        title: 'Items',
        icon: ItemsTabReactIcon,
        path: routesPath.CATALOG_ITEMS,
        clevertapEvent: clevertapEvents.webCatalogItemsListView
      },
      {
        id: 'catalog-categories-nav',
        key: navTabKeys.catalogCategories,
        title: 'Categories',
        icon: CategoriesTabReactIcon,
        path: routesPath.CATALOG_CATEGORIES,
        clevertapEvent: clevertapEvents.webCatalogCategoryListView
      },
      {
        id: 'catalog-taxes-nav',
        key: navTabKeys.catalogTaxes,
        title: 'Taxes',
        icon: TaxesTabIcon,
        path: routesPath.CATALOG_TAXES,
        clevertapEvent: clevertapEvents.webCatalogTaxesListView
      },
      {
        id: 'catalog-service-charge-nav',
        key: navTabKeys.catalogServiceCharge,
        title: 'Service charges',
        icon: ServiceChargeTabIcon,
        path: routesPath.CATALOG_SERVICE_CHARGES,
        clevertapEvent: clevertapEvents.webCatalogServiceChargesListView
      }
    ]
  },
  {
    id: 'payments-limit-nav',
    key: navTabKeys.paymentLimits,
    title: 'Payment Limits',
    icon: PaymentLimitsTabIcon,
    path: routesPath.PAYMENT_LIMITS,
    clevertapEvent: clevertapEvents.WebPaymentLimits
  }
];

export const paymentNavTabsWithIntegrations: NavTab[] = [
  {
    id: 'invoice-settings-nav',
    key: navTabKeys.invoiceSettings,
    title: 'Invoice Settings',
    icon: InvoiceSettingsIcon,
    path: routesPath.DISCOUNTS_TIPS_FEES,
    clevertapEvent: clevertapEvents.WebDiscountTipsFees
  },
  {
    id: 'catalog-nav',
    key: navTabKeys.catalog,
    title: 'Catalog',
    icon: CatalogTabIcon,
    path: routesPath.CATALOG_ITEMS,
    clevertapEvent: clevertapEvents.webCatalogMenu,
    hasChildTabs: true,
    childTabs: [
      {
        id: 'catalog-items-nav',
        key: navTabKeys.catalogItems,
        title: 'Items',
        icon: ItemsTabReactIcon,
        path: routesPath.CATALOG_ITEMS,
        clevertapEvent: clevertapEvents.webCatalogItemsListView
      },
      {
        id: 'catalog-categories-nav',
        key: navTabKeys.catalogCategories,
        title: 'Categories',
        icon: CategoriesTabReactIcon,
        path: routesPath.CATALOG_CATEGORIES,
        clevertapEvent: clevertapEvents.webCatalogCategoryListView
      },
      {
        id: 'catalog-taxes-nav',
        key: navTabKeys.catalogTaxes,
        title: 'Taxes',
        icon: TaxesTabIcon,
        path: routesPath.CATALOG_TAXES,
        clevertapEvent: clevertapEvents.webCatalogTaxesListView
      },
      {
        id: 'catalog-service-charge-nav',
        key: navTabKeys.catalogServiceCharge,
        title: 'Service charges',
        icon: ServiceChargeTabIcon,
        path: routesPath.CATALOG_SERVICE_CHARGES,
        clevertapEvent: clevertapEvents.webCatalogServiceChargesListView
      }
    ]
  },
  {
    id: 'payments-limit-nav',
    key: navTabKeys.paymentLimits,
    title: 'Payment Limits',
    icon: PaymentLimitsTabIcon,
    path: routesPath.PAYMENT_LIMITS,
    clevertapEvent: clevertapEvents.WebPaymentLimits
  },
  {
    id: 'integrations-nav',
    key: navTabKeys.integrations,
    title: 'Integrations',
    icon: IntegrationsTabIcon,
    path: routesPath.INTEGRATIONS,
    clevertapEvent: clevertapEvents.webIntegrationsView
  }
];

export const teamNavTabs: NavTab[] = [
  {
    id: 'team-profile-nav',
    key: navTabKeys.teamProfile,
    title: 'Team Profile',
    icon: TeamProfileTabIcon,
    path: routesPath.TEAM_PROFILE
  },
  {
    id: 'manage-team-nav',
    key: navTabKeys.manageTeam,
    title: 'Manage Team',
    icon: ManageTeamTabIcon,
    path: routesPath.MANAGE_TEAM,
    clevertapEvent: clevertapEvents.webManageTeamView
  }
];

export const bankingNavTabs: NavTab[] = [
  {
    id: 'banking-nav',
    key: navTabKeys.banking,
    title: 'Banking',
    icon: BankingTabIcon,
    path: routesPath.BANKING
  },
  {
    id: 'debit-cards-nav',
    key: navTabKeys.debitCards,
    title: 'Debit Cards',
    icon: DebitCardsTabIcon,
    path: routesPath.DEBIT_CARDS
  },
  {
    id: 'accounts-nav',
    key: navTabKeys.accounts,
    title: 'Accounts',
    icon: AccountsTabIcon,
    path: routesPath.ACCOUNTS
  }
];

export const accountSettingsNavTabs: NavTab[] = [
  {
    id: 'my-account-nav',
    key: navTabKeys.myAccount,
    title: 'My Account',
    icon: MyAccountTabIcon,
    path: routesPath.ACCOUNT_SETTINGS_MY_ACCOUNT
  }
  // TODO: Integrate Credits and Rewards once feature is made available in web
  // {
  //   id: 'credits-rewards-nav',
  //   key: navTabKeys.creditsRewards,
  //   title: 'Credits & Rewards',
  //   icon: CreditsRewardsTabIcon,
  //   path: routesPath.ACCOUNT_SETTINGS_CREDITS_REWARDS
  // }
];

export const navTabs: NavTab[] = [
  ...baseNavTabs,
  ...paymentNavTabsWithIntegrations,
  ...teamNavTabs,
  ...bankingNavTabs,
  ...accountSettingsNavTabs
];

export const getNavSectionList = (isLIP: boolean, isTransitionTeam: boolean, showIntegrations = false) => {
  const paymentNavTab = showIntegrations ? paymentNavTabsWithIntegrations : paymentNavTabs;
  return isLIP || isTransitionTeam
    ? [baseNavTabs, paymentNavTab, teamNavTabs, bankingNavTabs]
    : [baseNavTabs, paymentNavTab, teamNavTabs];
};

export const navSectionList: Array<NavTab[]> = [baseNavTabs, paymentNavTabs, teamNavTabs, bankingNavTabs];
