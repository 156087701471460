import { InfoMesssages } from 'types/infoMessages';
import VisaIcon from 'assets/icons/VisaIcon.svg';
import AmexIcon from 'assets/icons/AmexIcon.svg';
import DiscoverIcon from 'assets/icons/DiscoverIcon.svg';
import MasterCardIcon from 'assets/icons/MasterCardIcon.svg';
import OtherPaymentIcon from 'assets/icons/OtherPaymentIcon.svg';
import { ReceiptByIdResponse } from './receipt.model';

export const isEmpty = (value: string) => {
  return !value || value.length === 0;
};

export const getReceiptStatus = (status: string) => {
  switch (status) {
    case 'CANCELLED':
      return 'Cancelled';
    case 'FAILED':
      return 'Failed';
    case 'PENDING':
      return 'Pending';
    case 'SUCCESS':
      return 'Success';
    case 'REFUNDED':
      return 'Refunded';
    case 'BLOCKED':
      return 'Blocked';
    case 'TIMED_OUT':
      return 'Timed Out';
    case 'INITIATED':
      return 'Initiated';
    case 'REFUND_INITIATED':
      return 'Refund Initiated';
    default:
      return status;
  }
};

export const getPayoutStatus = (status: string) => {
  switch (status) {
    case 'ON_HOLD':
      return 'On hold';
    case 'FAILED':
      return 'Failed';
    case 'PENDING':
    case 'IN_TRANSIT':
      return 'In transit';
    case 'SUCCESS':
      return 'Success';
    default:
      return '';
  }
};

export const getReceiptStatusColor = (status: string) => {
  switch (status) {
    case 'CANCELLED':
    case 'FAILED':
      return '#FF7A7A';
    case 'PENDING':
      return '#F2994A';
    case 'SUCCESS':
      return '#419364';
    case 'REFUND_INITIATED':
    case 'REFUNDED':
      return '#3876BB';
    case 'BLOCKED':
      return '#FF0000';
    case 'TIMED_OUT':
      return '#800000';
    case 'INITIATED':
      return '#4F4F4F';
    default:
      return '#4F4F4F';
  }
};

export const getPayoutStatusColor = (status: string) => {
  switch (status) {
    case 'ON_HOLD':
      return '#F2994A';
    case 'FAILED':
      return '#FF7A7A';
    case 'PENDING':
      return '#F2B85C';
    case 'SUCCESS':
      return '#419364';
  }
};

export const getReceiptStatusBackgroundColor = (status: string) => {
  switch (status) {
    case 'CANCELLED':
      return '#F2B85C';
    case 'FAILED':
    case 'BLOCKED':
      return '#F7C0C0';
    case 'PENDING':
      return '#F7E2C0';
    case 'SUCCESS':
      return '#CBF4C9';
    case 'REFUNDED':
    case 'INITIATED':
    case 'REFUND_INITIATED':
      return '#A4D7FA';
    case 'TIMED_OUT':
      return '#ECF1F7';
    default:
      return '#ECF1F7';
  }
};

export const getReceiptDetailStatusColor = (status: string) => {
  switch (status) {
    case 'CANCELLED':
    case 'FAILED':
    case 'PENDING':
    case 'SUCCESS':
    case 'REFUND_INITIATED':
    case 'REFUNDED':
    case 'INITIATED':
      return '#3E556D';
    case 'BLOCKED':
      return '#FF0000';
    case 'TIMED_OUT':
      return '#800000';
    default:
      return '#3E556D';
  }
};

export const getRefundDisabledReason = (isManualPayment, refundPermissions, isCreatedBySelf, isAlreadyRefunded) => {
  if (isManualPayment) return InfoMesssages.refundManualPaymentDisabledMessage;
  if (isAlreadyRefunded) return InfoMesssages.refundAlreadyProcessedDisabledMessage;
  if (!(refundPermissions.createRefundsByTeam || (isCreatedBySelf && refundPermissions.createRefundsBySelf))) {
    return InfoMesssages.refundNoPermissionMessage;
  }
};

export const shouldEnableRefund = (isManualPayment, refundPermissions, isCreatedBySelf, isAlreadyRefunded) => {
  return (
    !isManualPayment &&
    !isAlreadyRefunded &&
    (refundPermissions.createRefundsByTeam || (isCreatedBySelf && refundPermissions.createRefundsBySelf))
  );
};

export function getCardType(cardType) {
  switch (cardType) {
    case 'amex':
      return 'AMEX';
    case 'visa':
      return 'Visa';
    case 'discover':
      return 'Discover';
    case 'mastercard':
      return 'Mastercard';
    default:
      return 'Other';
  }
}

export function getCardIcon(cardType) {
  switch (cardType) {
    case 'amex':
      return AmexIcon;
    case 'visa':
      return VisaIcon;
    case 'discover':
      return DiscoverIcon;
    case 'mastercard':
      return MasterCardIcon;
    default:
      return OtherPaymentIcon;
  }
}

export const getPaymentData = (receiptData: ReceiptByIdResponse) => {
  if (receiptData?.cardDetails?.cardLast4 && receiptData?.cardDetails?.network) {
    return (
      <div className="flex flex-wrap items-center justify-end">
        <img className="mr-1.5" src={getCardIcon(receiptData?.cardDetails?.network)} alt="" />
        <div className="ml-1">{getCardType(receiptData?.cardDetails?.network)}</div>
        <div className="ml-1">{receiptData?.cardDetails?.cardLast4}</div>
        {receiptData.isManualKeyed && <div className="ml-1">(Keyed-in)</div>}
      </div>
    );
  }
  return receiptData?.paymentMethod || '-';
};
