import { FirebaseNotification } from 'types/notification';
import React, { useEffect, useState } from 'react';
import { FirebaseStorage } from 'services/firebase';
import DateUtils from 'utils/dateUtils';
import ScanPayLogoPlaceholder from '../../../assets/icons/ScanPayPlaceholder.svg';
import Divider from '@material-ui/core/Divider';
import { useNavigate } from 'react-router-dom';
import { VoidFn } from 'types/baseTypes';
import routesPath from 'routes/RoutesPath';
import { colorSelectorWithASCII, getInitials } from 'utils/commonUtils';
import CustomAvatar from 'components/avatar/CustomAvatar';
import CrossIconImage from 'assets/icons/CrossIconNotifs.png';
import { MigrationRoutes } from 'containers/home/bank-migration/constants';

interface NotificationItemProps {
  notification: FirebaseNotification;
  onCloseNotificationPanel: VoidFn;
}

export const NotificationItem = ({ notification, onCloseNotificationPanel }: NotificationItemProps) => {
  const navigate = useNavigate();

  const [isRead, setIsRead] = useState<boolean>();
  const { teamId, content } = notification;
  const { action } = content;

  useEffect(() => {
    if (notification.isRead === 'true') {
      setIsRead(true);
    }
  }, [notification.isRead]);

  const readNotification = async () => {
    if (!isRead) {
      await FirebaseStorage.markMessageAsRead(notification.id);
    }
    if (action.hasUrl) {
      onCloseNotificationPanel();
      const { android, web } = action.url;
      const andriodUrl = android.text.split('/')[3];
      const urlString = web ? web.text : `/${andriodUrl}`;
      if (urlString === routesPath.AWAITING_DOCUMENTS || urlString === routesPath.CREDITS_HISTORY) return;

      let redirectUrl = '';
      if (urlString === MigrationRoutes.MIGRATION_START) {
        redirectUrl = `/home?teamId=${teamId}`;
      } else {
        if (urlString.includes('?')) redirectUrl = `${urlString}&teamId=${teamId}`;
        else redirectUrl = `${urlString}?teamId=${teamId}`;
      }
      navigate(redirectUrl);
    }
  };

  const deleteNotification = async e => {
    e.stopPropagation();
    await FirebaseStorage.deleteMessageById(notification.id);
  };

  return (
    <>
      <div
        className={`${isRead ? 'primary' : 'bg-secondaryBtn'} remove-highlight flex cursor-pointer 
        flex-row py-4 pl-4 pr-3`}
        key={notification.id}
        onClick={readNotification}>
        <div className="mr-6 flex flex-row">
          {notification.content.icon.url ? (
            <img
              src={notification.content.icon.url || ScanPayLogoPlaceholder}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = ScanPayLogoPlaceholder;
              }}
              alt="..."
              className="h-10 w-10 max-w-none"
            />
          ) : (
            <CustomAvatar
              src={notification.content.icon.url}
              label={getInitials(notification.content.icon.name)}
              width="w-10"
              height="h-10"
              fontStyle="text-[10px] text-tertiaryText"
            />
          )}
        </div>
        <div className="flex-8 just mr-2 flex grow flex-col justify-start">
          <div className={'flex flex-row justify-between'}>
            <div className="mr-1.5 text-sbase font-semibold text-heading">{notification.content.title.text}</div>
            <div onClick={e => deleteNotification(e)} className="mt-1 shrink-0">
              <img src={CrossIconImage} className={'h-[14px] w-[14px]'} />
            </div>
            {/*<CrossIconvector onClick={e => deleteNotification(e)} />*/}
          </div>

          <div className="mt-2 whitespace-pre-line text-px13 font-normal text-heading">
            {notification.content.message.text}
          </div>
          <div className="mt-2 flex flex-row items-center">
            {notification.tags.map((tag: string, index: number) => {
              return (
                <div
                  className={`rounded py-0.5 px-2 
                  text-px13 font-bold text-tertiaryText`}
                  style={{ backgroundColor: colorSelectorWithASCII(tag.toUpperCase()) }}
                  key={index}>
                  {tag.toUpperCase()}
                </div>
              );
            })}
            <div className="ml-2 text-xs font-normal text-accentText">
              {DateUtils.fromNowRelativeString(parseInt(notification.date))}
            </div>
          </div>
        </div>
      </div>
      <Divider variant="fullWidth" />
    </>
  );
};
