import { PermissionLevels, PermissionTypeEnum } from 'containers/app/app.model';
import {
  getBankingPermissionLevel,
  getCustomerPermissionLevel,
  getInvoicePermissionLevel,
  getRefundPermissionLevel,
  getTeamSettingPermissionLevel,
  getTipAndServicePermissionLevel
} from 'containers/app/util';
import { PaymentSystem } from 'containers/payouts/constants';

export const getPermissionLevel = (
  permissionType: string,
  permissionList,
  paymentSystem: PaymentSystem
): PermissionLevels => {
  switch (permissionType) {
    case PermissionTypeEnum.TEAMSETTINGS:
      return getTeamSettingPermissionLevel(permissionList);

    case PermissionTypeEnum.INVOICE:
      return getInvoicePermissionLevel(permissionList, paymentSystem);

    case PermissionTypeEnum.FEES:
      return getTipAndServicePermissionLevel(permissionList);

    case PermissionTypeEnum.REFUND:
      return getRefundPermissionLevel(permissionList);

    case PermissionTypeEnum.CUSTOMERS:
      return getCustomerPermissionLevel(permissionList);

    case PermissionTypeEnum.BANKING:
      return getBankingPermissionLevel(permissionList);
  }
  return null;
};
