import React, { FC, Fragment, Suspense, lazy, useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import routesPath from './RoutesPath';
import { BottomBar, SideNav, TopBar } from 'containers';
import { LoadingSpinnerAnimation } from 'assets/animations';
import Lottie from 'lottie-react';
import { selectChatIconState, selectCurrentTeam, selectMenuState, selectUserDetails } from 'containers/app/appSlice';
import { twMerge } from 'tailwind-merge';
import classnames from 'classnames';
import { useAppSelector, useScreenDimensions } from 'hooks';
import FreshChat from 'react-freshchat';
import { If, Loader } from 'components';
import CreateInvoicePage from 'pages/CreateInvoicePage';
import { hideChatIcon, showChatIcon } from 'services/freshchat';
import {
  isDuplicateItemURL,
  isEditCategoryURL,
  isEditInvoiceURL,
  isEditItemURL,
  isEditServiceChargeURL,
  isEditTaxURL,
  isRespondToDisputeUrl,
  isReviewSubmitDisputeUrl,
  isUploadEvidenceUrl,
  isViewDisputeEvidenceUrl
} from './URLRegex';
import EditInvoicePage from 'pages/EditInvoicePage';
import TeamMemberSidePanel from 'containers/manage-team/components/TeamMemberSidePanel';
import InvoicePanel from 'containers/invoices/components/InvoicePanel';
import InvoiceDetailsPanel from 'containers/invoice-details/InvoiceDetailsPanel';
import CustomerPanel from 'containers/invoices/components/CustomerPanel';
import ShareReceiptPanel from 'containers/invoices/components/ShareReceiptPanel';
import MarkPaidPanel from 'containers/invoices/components/MarkPaidPanel';
import DeleteInvoicePanel from 'containers/invoices/components/DeleteInvoicePanel';
import ReceiptDetailsPanel from 'containers/receipts/components/ReceiptDetailsPanel';
import CreateRefundPanel from 'containers/receipts/components/CreateRefundPanel';
import InitiateRefundPanel from 'containers/receipts/components/InitiateRefundPanel';
import RefundSuccessPanel from 'containers/receipts/components/RefundSuccessPanel';
import RefundReceiptPanel from 'containers/receipts/components/RefundReceiptPanel';
import RefundFailedPanel from 'containers/receipts/components/RefundFailedPanel';
import MyAccountPage from 'pages/MyAccountPage';
import BankingOnboarding from 'containers/banking/BankingOnboarding';
import ItemsPage from 'pages/ItemsPage';
import CategoriesPage from 'pages/CategoriesPage';
import TaxesPage from 'pages/TaxesPage';
import { chatIconHiddenRoutes, fullScreenRoutes } from './constants';
import DisputeDetailsPanel from 'containers/disputes/components/DisputePanel';
import PayoutSettingsPage from 'containers/payouts/payouts-settings/PayoutsSettingsPage';
import TotalBalanceDetailsPanel from 'containers/payouts/payouts-balance/components/TotalBalanceDetailsPanel';
import UpComingPayoutDetailsPanel from 'containers/payouts/payouts-balance/components/UpcomingPayoutDetailsPanel';
import { useLazyGetPaymentSystemConfigQuery, useLazyGetTeamBankAccountQuery } from 'containers/app/api';
import PayoutDetailsPanel from 'containers/payouts/components/PayoutDetailsPanel';
import PayoutsList from 'containers/payouts/payouts-list/PayoutsList';
import OnTheWayPayoutsListPanel from 'containers/payouts/payouts-balance/components/OnTheWayPayoutsListPanel';
import ApplicationStatusPage from 'containers/payouts/payouts-settings/components/ApplicationStatusPage';
import ConnectOnboarding from 'containers/payouts/payouts-settings/components/ConnectOnboarding';
import { SharePendingInvoice } from 'containers/invoices/components/SharePendingInvoicePanel';
import { ShareQRPanel } from 'containers/invoices/components/ShareQRPanel';
import ShareUrlPanel from 'containers/invoices/components/PendingInvoiceUrlSharePanel';

const ReceiptsPage = lazy(() => import('pages/ReceiptsPage'));
const AccountsPage = lazy(() => import('pages/AccountsPage'));
const BankingPage = lazy(() => import('pages/BankingPage'));
const CustomersPage = lazy(() => import('pages/CustomersPage'));
const DebitCardsPage = lazy(() => import('pages/DebitCardsPage'));
const DiscountTipsFeesPage = lazy(() => import('pages/DiscountTipsFeesPage'));
const ManageTeamPage = lazy(() => import('pages/ManageTeamPage'));
const PaymentLimitsPage = lazy(() => import('pages/PaymentLimitsPage'));
const TeamProfilePage = lazy(() => import('pages/TeamProfilePage'));
const HomePage = lazy(() => import('pages/HomePage'));
const InvoicesPage = lazy(() => import('pages/InvoicesPage'));
const InviteMemberPage = lazy(() => import('pages/InviteMemberPage'));
const CreateTaxPage = lazy(() => import('pages/CreateTaxPage'));
const EditTaxPage = lazy(() => import('pages/EditTaxPage'));
const CreateItemPage = lazy(() => import('pages/CreateItemPage'));
const EditItemPage = lazy(() => import('pages/EditItemPage'));
const DuplicateItemPage = lazy(() => import('pages/DuplicateItemPage'));
const CreateCategoryPage = lazy(() => import('pages/CreateCategoryPage'));
const EditCategoryPage = lazy(() => import('pages/EditCategoryPage'));
const CreateServiceChargePage = lazy(() => import('pages/CreateServiceChargePage'));
const EditServiceChargePage = lazy(() => import('pages/EditServiceChargePage'));
const ServiceChargesPage = lazy(() => import('pages/ServiceChargesPage'));
const DisputesPage = lazy(() => import('pages/DisputesPage'));
const RespondToDisputePage = lazy(() => import('pages/RespondToDisputesPage'));
const DisputeUploadEvidencePage = lazy(() => import('pages/DisputeUploadEvidence'));
const DisputesReviewAndSubmitPage = lazy(() => import('pages/DisputesReviewAndSubmitPage'));
const DisputesViewEvidencePage = lazy(() => import('pages/DisputesViewEvidencePage'));
const IntegrationsPage = lazy(() => import('pages/IntegrationsPage'));
const DeveloperAPIPage = lazy(() => import('pages/DeveloperAPIPage'));
const WebhooksPage = lazy(() => import('pages/WebhooksPage'));
const QuickbooksPage = lazy(() => import('pages/QuickbooksPage'));
const QBOIntuitLoginPage = lazy(() => import('containers/integrations/quikcbooks/components/IntuitLogin'));
const QBOInitialSettingsPage = lazy(() => import('containers/integrations/quikcbooks/components/QBOInitialSettings'));
const PayoutsHomePage = lazy(() => import('pages/PayoutsBalancePage'));
const PayoutTransactionsPanel = lazy(() => import('containers/payouts/components/PayoutTransactionsPanel'));

interface DefaultPrivateLayoutProps {
  isLoadingGetMerchantTeams: boolean;
  isLoadingInitAPI: boolean;
}

const DefaultPrivateLayout: FC<DefaultPrivateLayoutProps> = ({ isLoadingInitAPI, isLoadingGetMerchantTeams }) => {
  const location = useLocation();
  const isMenuActive = useAppSelector(selectMenuState);
  const isChatVisible = useAppSelector(selectChatIconState);
  const [isChatIconVisible, setIsChatIconVisible] = useState(true);
  const isAccountSettingsLayout = location.pathname === routesPath.ACCOUNT_SETTINGS_MY_ACCOUNT;
  const [getPaymentSystemConfigQuery] = useLazyGetPaymentSystemConfigQuery();
  const [getTeamBankAccountQuery] = useLazyGetTeamBankAccountQuery();

  const currentUser = useAppSelector(selectUserDetails);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { viewportHeightInPx } = useScreenDimensions();

  const isEditURL =
    isEditInvoiceURL.test(location.pathname) ||
    isEditTaxURL.test(location.pathname) ||
    isEditItemURL.test(location.pathname) ||
    isDuplicateItemURL.test(location.pathname) ||
    isEditCategoryURL.test(location.pathname) ||
    isEditServiceChargeURL.test(location.pathname) ||
    isRespondToDisputeUrl.test(location.pathname) ||
    isUploadEvidenceUrl.test(location.pathname) ||
    isReviewSubmitDisputeUrl.test(location.pathname) ||
    isViewDisputeEvidenceUrl.test(location.pathname);

  // Move these to a utility method when the number of routes that needs to checked becomes more than 2
  const isChatIconHiddenRoute = chatIconHiddenRoutes.includes(location.pathname) || isEditURL;

  const shouldHideChatIcon = isChatIconHiddenRoute || !isChatVisible;

  const isFullScreenLayout = fullScreenRoutes.includes(location.pathname) || isEditURL;

  useEffect(() => {
    // TODO Create a wrapper for fresh chat and chat functionality instead of using chat apis directly in code
    if (shouldHideChatIcon) {
      hideChatIcon();
      setIsChatIconVisible(false);
    } else {
      if (!isChatIconVisible) {
        showChatIcon();
        setIsChatIconVisible(true);
      }
    }
  }, [location.pathname, shouldHideChatIcon]);

  useEffect(() => {
    if (currentTeam.id) {
      getPaymentSystemConfigQuery(currentTeam.id);
      getTeamBankAccountQuery(currentTeam.id);
    }
  }, [currentTeam.id]);

  const initialiseFreshChat = widget => {
    widget.user.setFirstName(currentUser.name);
    widget.user.setLastName(' ');
    widget.user.setEmail(currentUser.email);
    widget.user.setPhone(currentUser.phone);
    widget.setExternalId(currentUser.id);
    widget.user.setProperties({
      merchantId: currentUser.id,
      currentTeam: currentTeam.name,
      currentTeamId: currentTeam.id
    });
    if (shouldHideChatIcon) {
      widget?.setConfig({
        headerProperty: {
          hideChatButton: true
        }
      });
    }
  };

  return (
    <Fragment>
      {isLoadingInitAPI ? (
        <div className="flex h-screen w-full">
          <Loader />
        </div>
      ) : (
        <div
          style={{ height: viewportHeightInPx }}
          className={'flex w-screen flex-col overflow-hidden bg-primary sm:flex-col'}>
          <If condition={!isFullScreenLayout}>
            <TopBar className="hidden sm:flex" />
          </If>
          <div className="flex h-full flex-row overflow-hidden">
            <If condition={!isFullScreenLayout}>
              <SideNav />
            </If>
            <div
              className={twMerge(
                'flex h-full w-full flex-col ml-0',
                classnames({
                  'ml-[100px] w-[calc(100%_-_100px)]': !isMenuActive && !isFullScreenLayout,
                  'lg:customNormalScroll overflow-y-auto': !isAccountSettingsLayout
                })
              )}>
              {isLoadingGetMerchantTeams ? (
                <div className="flex h-full items-center justify-center">
                  <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
                </div>
              ) : (
                <Suspense
                  fallback={
                    <div className="flex h-full items-center justify-center">
                      <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
                    </div>
                  }>
                  <Routes>
                    <Route path={routesPath.HOME} element={<HomePage />} />
                    <Route path={routesPath.INVOICES} element={<InvoicesPage />}>
                      <Route path={routesPath.INVOICE_BY_ID} element={<InvoicePanel />} />
                      <Route path={routesPath.INVOICE_BY_ID_DETAILS} element={<InvoiceDetailsPanel />} />
                      <Route path={routesPath.INVOICE_INVOICE_DETAILS} element={<InvoiceDetailsPanel />} />
                      <Route path={routesPath.INVOICE_CUSTOMER_BY_ID} element={<CustomerPanel />} />
                      <Route path={routesPath.INVOICE_INVOICE_BY_ID} element={<InvoicePanel />} />
                      <Route path={routesPath.INVOICE_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.INVOICE_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.INVOICE_PENDING_SHARE} element={<SharePendingInvoice />} />
                      <Route path={routesPath.INVOICE_PENDING_SHARE_QR} element={<ShareQRPanel />} />
                      <Route path={routesPath.INVOICE_PENDING_SHARE_URL} element={<ShareUrlPanel />} />
                      <Route path={routesPath.INVOICE_SHARE_PENDING_INVOICE} element={<SharePendingInvoice />} />
                      <Route path={routesPath.INVOICE_SHARE_PENDING_INVOICE_QR} element={<ShareQRPanel />} />
                      <Route path={routesPath.INVOICE_SHARE_PENDING_INVOICE_URL} element={<ShareUrlPanel />} />

                      <Route path={routesPath.INVOICE_RECEIPT_SHARE_INVOICE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.INVOICE_SHARE_INVOICE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.INVOICE_REFUND_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.INVOICE_MARK_PAID} element={<MarkPaidPanel />} />
                      <Route path={routesPath.INVOICE_MARK_PAID_INVOICE} element={<MarkPaidPanel />} />
                      <Route path={routesPath.INVOICE_DELETE} element={<DeleteInvoicePanel />} />
                      <Route path={routesPath.INVOICE_DELETE_INVOICE} element={<DeleteInvoicePanel />} />
                      <Route path={routesPath.INVOICE_TXN_DETAIL} element={<ReceiptDetailsPanel />} />
                      <Route path={routesPath.INVOICE_PAYOUT} element={<PayoutDetailsPanel />} />
                      <Route path={routesPath.INVOICE_RECEIPT_CREATE_REFUND} element={<CreateRefundPanel />} />
                      <Route path={routesPath.INVOICE_RECEIPT_INITIATE_REFUND} element={<InitiateRefundPanel />} />
                      <Route path={routesPath.INVOICE_RECEIPT_REFUND_SUCCESS} element={<RefundSuccessPanel />} />
                      <Route path={routesPath.INVOICE_RECEIPT_REFUND_FAILED} element={<RefundFailedPanel />} />
                      <Route path={routesPath.INVOICE_RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                      <Route path={routesPath.INVOICE_DISPUTE_BY_ID} element={<DisputeDetailsPanel />} />
                      <Route path={routesPath.INVOICE_PAYOUT_TXNS} element={<PayoutTransactionsPanel />} />
                    </Route>
                    <Route path={routesPath.INVOICES_CREATE} element={<CreateInvoicePage />} />
                    <Route path={routesPath.INVOICES_EDIT} element={<EditInvoicePage />} />

                    <Route path={routesPath.RECEIPTS} element={<ReceiptsPage />}>
                      <Route path={routesPath.RECEIPT_BY_ID} element={<ReceiptDetailsPanel />} />
                      <Route path={routesPath.RECEIPT_RECEIPT_BY_ID} element={<ReceiptDetailsPanel />} />
                      <Route path={routesPath.RECEIPT_CUSTOMER_BY_ID} element={<CustomerPanel />} />
                      <Route path={routesPath.RECEIPT_INVOICE} element={<InvoicePanel />} />
                      <Route path={routesPath.RECEIPT_PAYOUT_BY_ID} element={<PayoutDetailsPanel />} />
                      <Route path={routesPath.RECEIPT_INVOICE_DETAILS} element={<InvoiceDetailsPanel />} />
                      <Route path={routesPath.RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.RECEIPT_INVOICE_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.RECEIPT_PENDING_INVOICE_SHARE} element={<SharePendingInvoice />} />
                      <Route path={routesPath.RECEIPT_PENDING_INVOICE_SHARE_QR} element={<ShareQRPanel />} />
                      <Route path={routesPath.RECEIPT_PENDING_INVOICE_SHARE_URL} element={<ShareUrlPanel />} />

                      <Route path={routesPath.RECEIPT_REFUND_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                      <Route path={routesPath.RECEIPT_CREATE_REFUND} element={<CreateRefundPanel />} />
                      <Route path={routesPath.RECEIPT_INITIATE_REFUND} element={<InitiateRefundPanel />} />
                      <Route path={routesPath.RECEIPT_REFUND_SUCCESS} element={<RefundSuccessPanel />} />
                      <Route path={routesPath.RECEIPT_REFUND_FAILED} element={<RefundFailedPanel />} />
                      <Route path={routesPath.RECEIPT_RECEIPT_CREATE_REFUND} element={<CreateRefundPanel />} />
                      <Route path={routesPath.RECEIPT_RECEIPT_INITIATE_REFUND} element={<InitiateRefundPanel />} />
                      <Route path={routesPath.RECEIPT_RECEIPT_REFUND_SUCCESS} element={<RefundSuccessPanel />} />
                      <Route path={routesPath.RECEIPT_RECEIPT_REFUND_FAILED} element={<RefundFailedPanel />} />
                      <Route path={routesPath.RECEIPT_RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                      <Route path={routesPath.RECEIPT_DISPUTE_BY_ID} element={<DisputeDetailsPanel />} />
                      <Route path={routesPath.RECEIPT_PAYOUT_BY_ID_TXNS} element={<PayoutTransactionsPanel />} />
                      <Route path={routesPath.RECEIPT_INVOICE_MARK_PAID} element={<MarkPaidPanel />} />
                    </Route>

                    <Route path={routesPath.PAYOUTS}>
                      <Route path={routesPath.PAYOUTS_BALANCE} element={<PayoutsHomePage />}>
                        <Route path={routesPath.PAYOUTS_BALANCE_TOTAL_DETALS} element={<TotalBalanceDetailsPanel />} />
                        <Route
                          path={routesPath.PAYOUTS_BALANCE_UPCOMING_PAYOUTS}
                          element={<UpComingPayoutDetailsPanel />}
                        />
                        <Route path={routesPath.PAYOUT_BALANCE_PAYOUT_BY_ID} element={<PayoutDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_RECEIPT_DETAIL} element={<ReceiptDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_PAYOUT_BY_ID} element={<PayoutDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_INVOICE} element={<InvoicePanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_CUSTOMER_BY_ID} element={<CustomerPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_INVOICE_SHARE} element={<ShareReceiptPanel />} />
                        <Route
                          path={routesPath.PAYOUT_BALANCE_PENDING_INVOICE_SHARE}
                          element={<SharePendingInvoice />}
                        />
                        <Route path={routesPath.PAYOUT_BALANCE_PENDING_INVOICE_SHARE_QR} element={<ShareQRPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_PENDING_INVOICE_SHARE_URL} element={<ShareUrlPanel />} />

                        <Route path={routesPath.PAYOUT_BALANCE_REFUND_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_RECEIPT_CREATE_REFUND} element={<CreateRefundPanel />} />
                        <Route
                          path={routesPath.PAYOUT_BALANCE_RECEIPT_INITIATE_REFUND}
                          element={<InitiateRefundPanel />}
                        />
                        <Route
                          path={routesPath.PAYOUT_BALANCE_RECEIPT_REFUND_SUCCESS}
                          element={<RefundSuccessPanel />}
                        />
                        <Route path={routesPath.PAYOUT_BALANCE_RECEIPT_REFUND_FAILED} element={<RefundFailedPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_INVOICE_DETAILS} element={<InvoiceDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_DISPUTE_BY_ID} element={<DisputeDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_INVOICE_MARK_PAID} element={<MarkPaidPanel />} />
                        <Route path={routesPath.PAYOUT_BALANCE_TXNS} element={<PayoutTransactionsPanel />} />
                        <Route
                          path={routesPath.PAYOUTS_BALANCE_UPCOMING_PAYOUTS_TXNS}
                          element={<PayoutTransactionsPanel />}
                        />
                        <Route
                          path={routesPath.PAYOUTS_BALANCE_IN_TRANSIT_PAYOUTS}
                          element={<OnTheWayPayoutsListPanel />}
                        />
                      </Route>

                      <Route path={routesPath.PAYOUTS_LIST} element={<PayoutsList />}>
                        <Route path={routesPath.PAYOUT_LIST_PAYOUT_BY_ID} element={<PayoutDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_RECEIPT} element={<ReceiptDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_CUSTOMER} element={<CustomerPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_INVOICE} element={<InvoicePanel />} />
                        <Route path={routesPath.PAYOUT_LIST_INVOICE_DETAILS} element={<InvoiceDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_INVOICE_SHARE} element={<ShareReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_PENDING_INVOICE_SHARE} element={<SharePendingInvoice />} />
                        <Route path={routesPath.PAYOUT_LIST_PENDING_INVOICE_SHARE_QR} element={<ShareQRPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_PENDING_INVOICE_SHARE_URL} element={<ShareUrlPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_REFUND_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_RECEIPT_CREATE_REFUND} element={<CreateRefundPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_DISPUTE} element={<DisputeDetailsPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_INVOICE_DELETE} element={<DeleteInvoicePanel />} />
                        <Route
                          path={routesPath.PAYOUT_LIST_RECEIPT_INITIATE_REFUND}
                          element={<InitiateRefundPanel />}
                        />
                        <Route path={routesPath.PAYOUT_LIST_RECEIPT_REFUND_SUCCESS} element={<RefundSuccessPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_RECEIPT_REFUND_FAILED} element={<RefundFailedPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_TXNS} element={<PayoutTransactionsPanel />} />
                        <Route path={routesPath.PAYOUT_LIST_INVOICE_MARK_PAID} element={<MarkPaidPanel />} />
                      </Route>

                      <Route path={routesPath.PAYOUTS_SETTINGS} element={<PayoutSettingsPage />}></Route>
                      <Route path={routesPath.BANK_APPLICATION_STATUS} element={<ApplicationStatusPage />} />
                      <Route path={routesPath.CONNECT_ONBOARDING} element={<ConnectOnboarding />} />
                    </Route>

                    <Route path={routesPath.CUSTOMERS} element={<CustomersPage />}>
                      <Route path={routesPath.CUSTOMER_BY_ID} element={<CustomerPanel />} />
                      <Route path={routesPath.CUSTOMER_INVOICE} element={<InvoicePanel />} />
                      <Route path={routesPath.CUSTOMER_INVOICE_DETAILS} element={<InvoiceDetailsPanel />} />
                      <Route path={routesPath.CUSTOMER_RECEIPT} element={<ReceiptDetailsPanel />} />
                      <Route path={routesPath.CUSTOMER_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.CUSTOMER_INVOICE_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.CUSTOMER_PENDING_INVOICE_SHARE} element={<SharePendingInvoice />} />
                      <Route path={routesPath.CUSTOMER_PENDING_INVOICE_SHARE_QR} element={<ShareQRPanel />} />
                      <Route path={routesPath.CUSTOMER_PENDING_INVOICE_SHARE_URL} element={<ShareUrlPanel />} />
                      <Route path={routesPath.CUSTOMER_REFUND_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.CUSTOMER_INVOICE_MARK_PAID} element={<MarkPaidPanel />} />
                      <Route path={routesPath.CUSTOMER_INVOICE_DELETE} element={<DeleteInvoicePanel />} />
                      <Route path={routesPath.CUSTOMER_CREATE_REFUND} element={<CreateRefundPanel />} />
                      <Route path={routesPath.CUSTOMER_INITIATE_REFUND} element={<InitiateRefundPanel />} />
                      <Route path={routesPath.CUSTOMER_REFUND_SUCCESS} element={<RefundSuccessPanel />} />
                      <Route path={routesPath.CUSTOMER_REFUND_FAILED} element={<RefundFailedPanel />} />
                      <Route path={routesPath.CUSTOMER_PAYOUT} element={<PayoutDetailsPanel />} />
                      <Route path={routesPath.CUSTOMER_RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                      <Route path={routesPath.CUSTOMER_DISPUTE_BY_ID} element={<DisputeDetailsPanel />} />
                      <Route path={routesPath.CUSTOMER_PAYOUT_TXNS} element={<PayoutTransactionsPanel />} />
                    </Route>
                    <Route path={routesPath.DISCOUNTS_TIPS_FEES} element={<DiscountTipsFeesPage />} />
                    <Route path={routesPath.PAYMENT_LIMITS} element={<PaymentLimitsPage />} />
                    <Route path={routesPath.TEAM_PROFILE} element={<TeamProfilePage />} />
                    <Route path={routesPath.MANAGE_TEAM} element={<ManageTeamPage />}>
                      <Route path={routesPath.TEAM_MEMBER_BY_ID} element={<TeamMemberSidePanel />} />
                    </Route>
                    <Route path={routesPath.CREATE_INVITE} element={<InviteMemberPage />} />
                    <Route path={routesPath.BANKING} element={<BankingPage />} />
                    <Route path={routesPath.DEBIT_CARDS} element={<DebitCardsPage />} />
                    <Route path={routesPath.ACCOUNTS} element={<AccountsPage />} />
                    <Route path={routesPath.ALL} element={<Navigate replace={true} to={routesPath.HOME} />} />
                    <Route
                      path={routesPath.TRANSACTIONS}
                      element={<Navigate replace={true} to={`${routesPath.RECEIPTS}${location?.search}`} />}
                    />
                    <Route
                      path={routesPath.INVOICE}
                      element={<Navigate replace={true} to={`${routesPath.INVOICES}${location?.search}`} />}
                    />
                    <Route
                      path={routesPath.TEAMS_HOME}
                      element={<Navigate replace={true} to={routesPath.MANAGE_TEAM} />}
                    />
                    <Route
                      path={routesPath.BANKING_HOME}
                      element={<Navigate replace={true} to={routesPath.BANKING} />}
                    />
                    <Route
                      path={routesPath.LIMITS}
                      element={<Navigate replace={true} to={routesPath.PAYMENT_LIMITS} />}
                    />

                    <Route path={routesPath.RESPOND_TO_DIPUTES} element={<RespondToDisputePage />} />
                    <Route path={routesPath.DISPUTES_UPDATE_EVIDENCE} element={<DisputeUploadEvidencePage />} />
                    <Route path={routesPath.DISPUTES_REVIEW_SUBMIT} element={<DisputesReviewAndSubmitPage />} />
                    <Route path={routesPath.DISPUTES_VIEW_EVIDENCE} element={<DisputesViewEvidencePage />} />
                    <Route path={routesPath.DISPUTES} element={<DisputesPage />}>
                      <Route path={routesPath.DISPUTE_BY_ID} element={<DisputeDetailsPanel />} />
                      <Route path={routesPath.DISPUTE_RECEIPT_DETAILS} element={<ReceiptDetailsPanel />} />
                      <Route path={routesPath.DISPUTE_CUSTOMER} element={<CustomerPanel />} />
                      <Route path={routesPath.DISPUTE_INVOICE} element={<InvoicePanel />} />
                      <Route path={routesPath.DISPUTE_INVOICE_DETAILS} element={<InvoiceDetailsPanel />} />
                      <Route path={routesPath.DISPUTE_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.DISPUTE_INVOICE_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.DISPUTE_PENDING_INVOICE_SHARE} element={<SharePendingInvoice />} />
                      <Route path={routesPath.DISPUTE_PENDING_INVOICE_SHARE_QR} element={<ShareQRPanel />} />
                      <Route path={routesPath.DISPUTE_PENDING_INVOICE_SHARE_URL} element={<ShareUrlPanel />} />
                      <Route path={routesPath.DISPUTE_REFUND_RECEIPT_SHARE} element={<ShareReceiptPanel />} />
                      <Route path={routesPath.DISPUTE_INVOICE_MARK_PAID} element={<MarkPaidPanel />} />
                      <Route path={routesPath.DISPUTE_INVOICE_DELETE} element={<DeleteInvoicePanel />} />
                      <Route path={routesPath.DISPUTE_CREATE_REFUND} element={<CreateRefundPanel />} />
                      <Route path={routesPath.DISPUTE_INITIATE_REFUND} element={<InitiateRefundPanel />} />
                      <Route path={routesPath.DISPUTE_REFUND_SUCCESS} element={<RefundSuccessPanel />} />
                      <Route path={routesPath.DISPUTE_REFUND_FAILED} element={<RefundFailedPanel />} />
                      <Route path={routesPath.DISPUTE_PAYOUT} element={<PayoutDetailsPanel />} />
                      <Route path={routesPath.DISPUTE_RECEIPT_REFUND} element={<RefundReceiptPanel />} />
                      <Route path={routesPath.DISPUTE_DISPUTE_BY_ID} element={<DisputeDetailsPanel />} />
                      <Route path={routesPath.DISPUTE_PAYOUT_TXNS} element={<PayoutTransactionsPanel />} />
                    </Route>

                    <Route path={routesPath.CARDS} element={<Navigate replace={true} to={routesPath.DEBIT_CARDS} />} />
                    <Route path={routesPath.ACCOUNT_SETTINGS_MY_ACCOUNT} element={<MyAccountPage />} />
                    <Route path={routesPath.ACCOUNT_SETTINGS_CREDITS_REWARDS} element={<MyAccountPage />} />
                    <Route path={routesPath.BANKING_ONBOARDING} element={<BankingOnboarding />} />

                    <Route path={routesPath.CATALOG_ITEMS} element={<ItemsPage />} />
                    <Route path={routesPath.ITEMS_CREATE} element={<CreateItemPage />} />
                    <Route path={routesPath.ITEMS_EDIT} element={<EditItemPage />} />
                    <Route path={routesPath.ITEMS_DUPLICATE} element={<DuplicateItemPage />} />

                    <Route path={routesPath.CATALOG_CATEGORIES} element={<CategoriesPage />} />
                    <Route path={routesPath.CATEGORIES_CREATE} element={<CreateCategoryPage />} />
                    <Route path={routesPath.CATEGORIES_EDIT} element={<EditCategoryPage />} />

                    <Route path={routesPath.CATALOG_TAXES} element={<TaxesPage />} />
                    <Route path={routesPath.TAXES_CREATE} element={<CreateTaxPage />} />
                    <Route path={routesPath.TAXES_EDIT} element={<EditTaxPage />} />

                    <Route path={routesPath.CATALOG_SERVICE_CHARGES} element={<ServiceChargesPage />} />
                    <Route path={routesPath.SERVICE_CHARGES_CREATE} element={<CreateServiceChargePage />} />
                    <Route path={routesPath.SERVICE_CHARGES_EDIT} element={<EditServiceChargePage />} />

                    <Route path={routesPath.INTEGRATIONS} element={<IntegrationsPage />} />
                    <Route path={routesPath.DEVELOPER_API} element={<DeveloperAPIPage />} />
                    <Route path={routesPath.WEBHOOKS} element={<WebhooksPage />} />
                    <Route path={routesPath.QUICKBOOKS} element={<QuickbooksPage />} />
                    <Route path={routesPath.QUICKBOOKS_LOGIN} element={<QBOIntuitLoginPage />} />
                    <Route path={routesPath.QUICKBOOKS_SETTINGS} element={<QBOInitialSettingsPage />} />
                  </Routes>
                </Suspense>
              )}
            </div>
          </div>
          <FreshChat
            token={process.env.REACT_APP_FRESHCHAT_TOKEN}
            externalId={currentUser.id}
            onInit={widget => {
              initialiseFreshChat(widget);
            }}
          />
          <If condition={!isFullScreenLayout}>
            <BottomBar className="fixed bottom-0 flex w-full sm:hidden" />
          </If>
        </div>
      )}
    </Fragment>
  );
};

export default DefaultPrivateLayout;
