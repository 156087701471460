import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';

import {
  GetReceiptsPayload,
  GetReceiptsResponse,
  PostConfirmRefundPayload,
  PostConfirmRefundResponse,
  PostInitiateRefundPayload
} from './receipts.model';
import { downloadFile, printFile } from 'utils/commonUtils';
import { ReceiptByIdPayload, ReceiptByIdResponse, ShareReceiptPayload } from './receipt.model';
import { FileTypes } from 'types/baseTypes';
import { getUrlSearchParams } from 'utils/apiUtils';
import { initializeReceiptData, updateConfrimRefundResponse } from './receiptsSlice';
import { GetMerchantsPayload, Merchant } from 'containers/invoices/invoice.model';

const ReceiptsBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getReceipts: builder.query<GetReceiptsResponse, GetReceiptsPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/transactions?`;
        const urlParams = getUrlSearchParams(params);

        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Something went wrong',
        showToast: true
      }
    }),
    downloadReceipts: builder.query<GetReceiptsResponse, GetReceiptsPayload>({
      query: params => {
        let url = `${API_VERSION.WEB_V1}/transactions?`;
        const urlParams = getUrlSearchParams(params);

        url = url + urlParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: params.type, fileName: 'receipts' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to fetch receipts data',
        success: 'Receipts exported successfully!',
        showToast: true
      }
    }),
    getReceiptById: builder.query<ReceiptByIdResponse, ReceiptByIdPayload>({
      query: ({ receiptId, teamId }) => {
        return {
          url: `${API_VERSION.V2}/transactions/${receiptId}?teamId=${teamId}`,
          method: 'GET'
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(initializeReceiptData(data));
      },
      extraOptions: {
        failure: 'Failed to fetch receipt data'
      }
    }),
    downloadReceiptAsPDF: builder.query<any, ReceiptByIdPayload>({
      query: ({ receiptId, teamId }) => {
        const url = `${API_VERSION.V3}/merchants/transactions/${receiptId}/download?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.PDF, fileName: 'receipt' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to download receipt pdf',
        success: 'Receipts exported successfully!',
        showToast: true
      }
    }),

    downloadPrintReceiptAsPDF: builder.query<any, ReceiptByIdPayload>({
      query: ({ receiptId, teamId }) => {
        const url = `${API_VERSION.V3}/merchants/transactions/${receiptId}/download?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await printFile({ response });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to print receipt pdf',
        showToast: true
      }
    }),
    downloadRefundReceiptAsPDF: builder.query<any, { receiptId: string }>({
      query: ({ receiptId }) => {
        const url = `${API_VERSION.V1}/refund-transaction/${receiptId}/download`;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.PDF, fileName: 'refund receipt' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'failed to download refund receipt pdf',
        success: 'Refund receipt downloaded successfully!',
        showToast: true
      }
    }),
    confirmRefund: builder.mutation<PostConfirmRefundResponse, PostConfirmRefundPayload>({
      query: data => {
        const url = `${API_VERSION.BANK_V1}/refunds/confirm`;
        return {
          url: url,
          method: 'POST',
          body: data
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        dispatch(updateConfrimRefundResponse(data));
      }
    }),
    initiateRefund: builder.mutation<any, PostInitiateRefundPayload>({
      query: data => {
        const url = `${API_VERSION.V2}/merchants/initiate-refund`;
        return {
          url: url,
          method: 'POST',
          body: data
        };
      }
    }),
    shareReceipt: builder.mutation<any, ShareReceiptPayload>({
      query: ({ receiptId, email, phoneNumber, updateCustomer, teamId }) => {
        return {
          url: `${API_VERSION.V1}/merchants/transactions/${receiptId}/forward`,
          method: 'POST',
          body: { email, phoneNumber, updateCustomer, teamId }
        };
      },
      extraOptions: {
        failure: 'Something went wrong, please try again.'
      }
    }),
    getMerchantsByTeamIdReceipts: builder.query<Merchant[], GetMerchantsPayload>({
      query: ({ teamId }) => {
        return {
          url: `${API_VERSION.WEB_V1}/transactions/filters/team-members?teamId=${teamId}`,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'failed to merchant tile data'
      }
    }),
    deleteReceipt: builder.mutation<any, ReceiptByIdPayload>({
      query: ({ receiptId, teamId }) => {
        return {
          url: `${API_VERSION.V1}/merchants/teams/${teamId}/transactions/${receiptId}`,
          method: 'DELETE'
        };
      },
      extraOptions: {
        failure: 'Failed to delete receipt',
        showToast: true,
        success: 'Receipt deleted successfully!'
      }
    })
  })
});

export const {
  useLazyGetReceiptsQuery,
  useLazyDownloadReceiptsQuery,
  useLazyGetReceiptByIdQuery,
  useLazyDownloadRefundReceiptAsPDFQuery,
  useLazyDownloadReceiptAsPDFQuery,
  useLazyDownloadPrintReceiptAsPDFQuery,
  useConfirmRefundMutation,
  useInitiateRefundMutation,
  useShareReceiptMutation,
  useLazyGetMerchantsByTeamIdReceiptsQuery,
  useDeleteReceiptMutation
} = ReceiptsBaseApi;
