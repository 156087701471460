import React, { useEffect, useState } from 'react';
import { Divider } from '@material-ui/core';
import classnames from 'classnames';
import Lottie from 'lottie-react';
import { db } from 'utils/firebase';
import { doc, onSnapshot } from 'firebase/firestore';
import { ArrowRightIcon, DeleteIcon, EditIconBlue, FeatureMovedIcon } from 'assets/icons';
import { CustomSwitch, If } from 'components';
import {
  selectCustomizations,
  updateCustomizations,
  updateSignatureRequestOption,
  updateSurcharge
} from './discountTipsSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  useDeleteReviewLinkMutation,
  useLazyGetInvoiceSummaryQuery,
  useLazyGetMerchantCustomizationsQuery,
  useLazyGetTermsSuggestionsQuery,
  useUpdateMerchantCustomizationsMutation,
  useUpdateReviewLinkMutation,
  useUpdateTNCMutation,
  useUpdateTNCStatusMutation
} from './api';
import { selectCurrentTeam, selectCurrentTeamFeePermissions, showNotifier } from 'containers/app/appSlice';
import PreviewModal from './components/PreviewModal';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { twMerge } from 'tailwind-merge';
import { ListDropDown } from 'components';
import { FeatureToggleOption, ServiceFeeBasePoint } from './type';
import {
  CustomizationsUpdateType,
  defaultTipChoices,
  dropDownServiceFeeBasePoint,
  dropDownSignatureRequestOption
} from './constants';
import { REGEX } from 'constants/common';
import { logAnalyticEvent } from 'utils/analytics';
import { clevertapEvents } from 'types/baseTypes';
import CreateEditTermsModal from './components/CreateEditTermsModal';
import { useLocation, useNavigate } from 'react-router-dom';
import { DiscountAndTipsRouteState } from 'routes/types';
import routesPath from 'routes/RoutesPath';
import LineItemPlusReactIcon from 'assets/react-icons/LineItemPlusReactIcon';
import TipChoices from './components/TipChoices';
import EditTipsModal from './components/EditTipsModal';
import { NotifierTypes } from 'constants/notifierConstants';
import { CleverTapEventsFees } from './events';
import { constructArrayAsValue, getUrlWithProtocol } from 'utils/commonUtils';
import ServiceFeeSection from './components/ServiceFeeSection';
import { AddReviewPageModal } from './components/AddReviewPageModal';
import { ReviewPageSection } from './components/ReviewPageSection';
import { DeleteReviewLinkConfirmationModal } from './components/DeleteReviewLinkConfirmationModal';
import { TeamRole } from 'containers/app/app.model';

const DiscountTipsAndFees = () => {
  const [isTermsEdit, setIsTermsEdit] = useState(false);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const customizations = useAppSelector(selectCustomizations);
  const { discount, tip, surcharge, tnc } = customizations;
  const dispatch = useAppDispatch();
  const [getMerchantCustomizationsQuery, getMerchantCustomizationsState] = useLazyGetMerchantCustomizationsQuery();
  const [getTermsSuggestionsQuery, getTermsSuggestionsState] = useLazyGetTermsSuggestionsQuery();
  const [getInvoicesSummaryQuery, getInvoicesSummaryState] = useLazyGetInvoiceSummaryQuery();
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);
  const [previewModalType, setPreviewModalType] = useState('');
  const [scrollElement, setScrollElement] = useState('');
  const feeSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const [serviceFeeError, setServiceFeeError] = useState('');
  const [lastUpdatedCustomizationsAt, setLastUpdatedCustomizationsAt] = useState<number>(0);
  const [loading, setLoading] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [isEditFromInvoice, setIsEditFromInvoice] = useState(false);
  const [showEditTipsModal, setShowEditTipsModal] = useState<boolean>(false);
  const [showReviewPageModal, setShowReviewPageModal] = useState<boolean>(false);
  const [showDeleteReviewLinkConfirmation, setShowDeleteReviewLinkConfirmation] = useState(false);

  const {
    manageTaxes,
    viewTipsSettings,
    editTipsSettings,
    viewDiscount,
    editDiscount,
    viewServiceFeeSettings,
    editServiceFeeSettings,
    viewTnc,
    editTnc,
    viewSignatureSettings,
    editSignatureSettings
  } = feeSettingsPermissions;
  const [selectedBasePoint, setSelectedBasePoint] = useState<ServiceFeeBasePoint>({ name: '', value: '' });
  const [selectedSignatureRequestOption, setSelectedSignatureRequestOption] = useState<FeatureToggleOption>({
    name: '',
    value: null
  });
  const [selectedServiceFeeOption, setSelectedServiceFeeOption] = useState<FeatureToggleOption>({
    name: '',
    value: null
  });

  const noPermissions =
    !manageTaxes &&
    !viewTipsSettings &&
    !editTipsSettings &&
    !viewDiscount &&
    !editDiscount &&
    !viewServiceFeeSettings &&
    !editServiceFeeSettings &&
    !viewTnc &&
    !editTnc &&
    !viewSignatureSettings &&
    !editSignatureSettings;

  const editReviewPageLink =
    currentTeam?.role === TeamRole.OWNER ||
    currentTeam?.role === TeamRole.SUPER_ADMIN ||
    currentTeam.role === TeamRole.ADMIN;

  const [updateTNCMutation, { isSuccess: isUpdateTermsSuccess }] = useUpdateTNCMutation();
  const [updateReviewLink, { isSuccess: isReviewPageLinkUpdateSuccess, isLoading: reviewPageLinkUpdateLoading }] =
    useUpdateReviewLinkMutation();
  const [deleteReviewLink, { isSuccess: isDeleteReviewLinkSuccess, isLoading: isDeleteReviewLinkLoading }] =
    useDeleteReviewLinkMutation();
  const [updateTNCStatusMutation] = useUpdateTNCStatusMutation();

  useEffect(() => {
    let unsubscribe = () => null;
    if (currentTeam?.id) {
      unsubscribe = onSnapshot(doc(db, 'Teams', currentTeam.id.toString()), document => {
        const lastUpdatedAtFromDoc = document.data()?.['CustomizationLastUpdatedAt'];
        if (lastUpdatedAtFromDoc > lastUpdatedCustomizationsAt) {
          if (
            document.data()?.['CustomizationUpdatedWeb'] === CustomizationsUpdateType.TIPS ||
            document.data()?.['CustomizationUpdatedWeb'] === CustomizationsUpdateType.TNC
          )
            getMerchantCustomizationsQuery({ teamId: currentTeam.id.toString() });
          setLastUpdatedCustomizationsAt(lastUpdatedAtFromDoc);
        }
      });
    }

    return () => {
      unsubscribe();
    };
  }, [currentTeam.id]);

  useEffect(() => {
    const routeState = location.state as DiscountAndTipsRouteState;
    if (routeState?.isTermsEdit) {
      setIsEditFromInvoice(true);
      navigate(routesPath.DISCOUNTS_TIPS_FEES, { replace: true, state: { isTermsEdit: false } });
    }
  }, [location]);

  useEffect(() => {
    if (!viewTnc || !editTnc) resetTermsModalState();
  }, [viewTnc, editTnc]);

  useEffect(() => {
    if (isUpdateTermsSuccess) {
      getMerchantCustomizationsQuery({ teamId: currentTeam.id.toString() });
    }
  }, [isUpdateTermsSuccess]);

  useEffect(() => {
    if (currentTeam.id) {
      setLoading(true);
      getMerchantCustomizationsQuery({ teamId: currentTeam.id.toString() });
      if (editTnc) getTermsSuggestionsQuery({ teamId: currentTeam.id.toString() });
      getInvoicesSummaryQuery({ teamId: currentTeam.id.toString() });
    }
  }, [currentTeam.id]);

  const onPreviewModalClose = () => setIsPreviewModalOpen(false);

  const [
    updateMerchantCustomizationsMutation,
    {
      isSuccess: isUpdateCustomizationsSuccess,
      data: updateCustomizationsResponse,
      isLoading: isUpdateCustomizationsInProgress
    }
  ] = useUpdateMerchantCustomizationsMutation();

  const {
    data: getMerchantsCustomizationsResponse,
    isSuccess: getMerchantsCustomizationsSuccess,
    isFetching: isMerchantCustomizationsFetching
  } = getMerchantCustomizationsState;

  const {
    data: getTermsSuggestionsResponse,
    isSuccess: getTermsSuggestionsSuccess,
    isFetching: isTermsSuggestionsFetching
  } = getTermsSuggestionsState;

  const {
    data: getInvoicesSummaryResponse,
    isSuccess: getInvoicesSummarySuccess,
    isFetching: isGetInvoicesSummaryFetching
  } = getInvoicesSummaryState;

  const onBasePointClick = (serviceFeeBasePoint: ServiceFeeBasePoint) => {
    setLoading(true);
    setSelectedBasePoint(serviceFeeBasePoint);
    const serviceFeeData = { ...surcharge, basePoints: serviceFeeBasePoint.value };
    dispatch(updateSurcharge(serviceFeeData));

    updateMerchantCustomizationsMutation({
      ...customizations,
      surcharge: serviceFeeData,
      teamId: currentTeam.id.toString()
    });

    logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesServiceFeeChange, {
      'Team ID': currentTeam.id.toString()
    });
  };

  const onSignatureRequestOptionClick = (signatureRequestOption: FeatureToggleOption) => {
    setLoading(true);
    setSelectedSignatureRequestOption(signatureRequestOption);
    dispatch(updateSignatureRequestOption(signatureRequestOption.value));

    updateMerchantCustomizationsMutation({
      ...customizations,
      customerSignatureConfig: signatureRequestOption.value,
      teamId: currentTeam.id.toString()
    });

    logAnalyticEvent(CleverTapEventsFees.webDiscountTipsFeesEditSignatureConfig, {
      signatureConfig: selectedSignatureRequestOption.value
    });
  };

  const handleServiceFeeChange = (serviceFeePercentage: string) => {
    if (!REGEX.percentageWithThreeDecimals.test(serviceFeePercentage)) {
      return;
    }
    if (+serviceFeePercentage > 10) {
      setServiceFeeError('Service fee % must be lesser than 10%');
    } else {
      setServiceFeeError('');
      const serviceFeeData = { ...surcharge, percentage: serviceFeePercentage };
      dispatch(updateSurcharge(serviceFeeData));
    }
  };

  const onServiceFeeOptionClick = (serviceFeeOption: FeatureToggleOption) => {
    setLoading(true);
    setSelectedServiceFeeOption(serviceFeeOption);

    updateMerchantCustomizationsMutation({
      ...customizations,
      serviceFeeConfig: serviceFeeOption.value,
      teamId: currentTeam.id.toString()
    });
    logAnalyticEvent(CleverTapEventsFees.webDiscountTipsFeesEditServiceFeeConfig, {
      serviceFeeConfig: selectedServiceFeeOption.value
    });
  };

  const updateServiceFeePercentage = () => {
    setLoading(true);
    updateMerchantCustomizationsMutation({
      ...customizations,
      teamId: currentTeam.id.toString()
    });
    logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesServiceFeeChange, {
      'Team ID': currentTeam.id.toString()
    });
  };

  const resetTermsModalState = () => {
    setIsTermsModalOpen(false);
    setIsTermsEdit(false);
  };

  useEffect(() => {
    if (getMerchantsCustomizationsSuccess && !isMerchantCustomizationsFetching) {
      const serviceFeePercentageWithNoTrailingZeros = getMerchantsCustomizationsResponse.surcharge?.percentage.replace(
        REGEX.replceTrailingZeros,
        '$1$2$3'
      );
      dispatch(
        updateCustomizations({
          ...getMerchantsCustomizationsResponse,
          surcharge: {
            ...getMerchantsCustomizationsResponse.surcharge,
            percentage: serviceFeePercentageWithNoTrailingZeros
          }
        })
      );
      const bps = dropDownServiceFeeBasePoint.find(
        item => item.value === getMerchantsCustomizationsResponse.surcharge.basePoints
      );
      setSelectedBasePoint(bps);

      const customerSignatureConfig = dropDownSignatureRequestOption.find(
        item => item.value === getMerchantsCustomizationsResponse.customerSignatureConfig
      );
      setSelectedSignatureRequestOption(customerSignatureConfig);
      const serviceFeeConfig = dropDownSignatureRequestOption.find(
        item => item.value === getMerchantsCustomizationsResponse.serviceFeeConfig
      );
      setSelectedServiceFeeOption(serviceFeeConfig);

      if (isEditFromInvoice) handlEditTermsClick();
      setLoading(false);
    }
  }, [getMerchantsCustomizationsSuccess, isMerchantCustomizationsFetching]);

  useEffect(() => {
    if (scrollElement && !loading && getMerchantsCustomizationsSuccess) {
      const element = document.getElementById(scrollElement);
      element.scrollIntoView();
      setScrollElement('');
    }
  }, [scrollElement, loading, getMerchantsCustomizationsSuccess]);

  useEffect(() => {
    if (isUpdateCustomizationsSuccess) {
      dispatch(updateCustomizations(updateCustomizationsResponse));
      if (showEditTipsModal) {
        setShowEditTipsModal(false);
        dispatch(
          showNotifier({
            message: { primaryMessage: 'Tip Successfully Updated' },
            type: NotifierTypes.SUCCESS,
            showClose: true
          })
        );
        logAnalyticEvent(CleverTapEventsFees.webDiscountTipsFeesEditTipsChoices, {
          ...constructArrayAsValue('Tips', customizations.teamTips)
        });
      }
    }
  }, [isUpdateCustomizationsSuccess]);

  useEffect(() => {
    if (isReviewPageLinkUpdateSuccess) {
      getMerchantCustomizationsQuery({ teamId: currentTeam.id.toString() });
      setLoading(true);
      dispatch(
        showNotifier({
          message: {
            primaryMessage: currentReviewPageLink
              ? 'Review link updated successfully'
              : 'Review link added successfully'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
    }
  }, [isReviewPageLinkUpdateSuccess]);

  useEffect(() => {
    if (isDeleteReviewLinkSuccess) {
      getMerchantCustomizationsQuery({ teamId: currentTeam.id.toString() });
      setLoading(true);
      setShowDeleteReviewLinkConfirmation(false);
    }
  }, [isDeleteReviewLinkSuccess]);

  const updateSettings = key => {
    const updatedSettings = {
      ...customizations,
      [key]: {
        ...customizations[key],
        enabled: !customizations[key].enabled
      }
    };
    dispatch(updateCustomizations(updatedSettings));
    setLoading(true);

    updateMerchantCustomizationsMutation({
      ...updatedSettings,
      teamId: currentTeam.id.toString()
    });
  };

  const toggleDiscount = () => {
    updateSettings('discount');
    if (!discount?.enabled)
      logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesDiscountEnabled, {
        'Team ID': currentTeam.id.toString()
      });
    else
      logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesDiscountDisabled, {
        'Team ID': currentTeam.id.toString()
      });
  };

  const toggleTerms = () => {
    if (tnc.tnc) {
      setLoading(true);
      const updatedSettings = {
        ...customizations,
        tnc: {
          ...customizations.tnc,
          enabled: !tnc.enabled
        }
      };
      dispatch(updateCustomizations(updatedSettings));
      updateTNCStatusMutation({
        teamId: currentTeam.id.toString(),
        enable: !tnc.enabled
      });
    } else {
      handlAddTermsClick();
    }
  };

  const toggleTips = () => {
    updateSettings('tip');
    if (!tip?.enabled)
      logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesTipsEnabled, {
        'Team ID': currentTeam.id.toString()
      });
    else
      logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesTipsDisabled, {
        'Team ID': currentTeam.id.toString()
      });
  };

  const toggleServiceFee = () => {
    updateSettings('surcharge');
    if (!surcharge?.enabled)
      logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesServiceFeeEnabled, {
        'Team ID': currentTeam.id.toString()
      });
    else
      logAnalyticEvent(clevertapEvents.WebDiscountTipsFeesServiceFeeDisabled, {
        'Team ID': currentTeam.id.toString()
      });
  };

  const handlAddTermsClick = () => {
    setIsTermsModalOpen(true);
    setIsTermsEdit(false);
  };

  const handlEditTermsClick = () => {
    setIsTermsModalOpen(true);
    setIsTermsEdit(true);
  };

  const handleSaveTermsClick = async (terms: string, applyToPendingInvoices: boolean) => {
    setLoading(true);
    resetTermsModalState();
    await updateTNCMutation({
      teamId: currentTeam.id.toString(),
      tnc: terms,
      applyToPendingInvoices: applyToPendingInvoices
    });
    if (isEditFromInvoice) {
      setIsEditFromInvoice(false);
      navigate(-1);
    }
  };

  const onCancelTermsClick = () => {
    resetTermsModalState();
    if (isEditFromInvoice) {
      setIsEditFromInvoice(false);
      navigate(-1);
    }
  };

  const handlDeleteTermsClick = async () => {
    setLoading(true);
    await updateTNCMutation({
      teamId: currentTeam.id.toString(),
      tnc: '',
      applyToPendingInvoices: false
    });
  };

  const navigateToCatalogPage = () => {
    navigate(routesPath.CATALOG_TAXES, { replace: true });
  };

  const toggleEditTipsModal = () => {
    setShowEditTipsModal(prev => !prev);
  };

  const handleSaveTipChoices = (tipChoices: number[]) => {
    updateMerchantCustomizationsMutation({
      ...customizations,
      teamId: currentTeam.id.toString(),
      teamTips: tipChoices
    });
  };

  const getTeamTipChoices = () => {
    if (customizations.teamTips?.length === 3) {
      return customizations.teamTips;
    }
    return defaultTipChoices;
  };

  const currentReviewPageLink = getMerchantsCustomizationsResponse?.websiteConfig?.websiteURL;

  const handleReviewPageLinkUpdate = async (url: string) => {
    const trimmedUrl = url.trim();
    await updateReviewLink({
      teamId: currentTeam?.id.toString(),
      payload: { websiteURL: getUrlWithProtocol(trimmedUrl), isRatingEnabled: true }
    });
    logAnalyticEvent(CleverTapEventsFees.webDiscountTipsFeesUpdateReviewLink, {
      reviewLink: getUrlWithProtocol(trimmedUrl)
    });
    setShowReviewPageModal(false);
  };

  const handleDeleteReviewPageClick = () => {
    deleteReviewLink({
      teamId: currentTeam?.id.toString()
    });
  };

  return noPermissions ? (
    <div className="flex h-full flex-col items-center justify-center gap-5">
      <div className="flex flex-col items-center gap-5">
        <div className="text-xl text-primaryText">You do not have permissions to view invoice settings</div>
      </div>
    </div>
  ) : (
    <>
      <div className="h-full w-full">
        <div className="px-[30px] pb-16 pt-6">
          <div className="text-[30px] font-bold text-primaryText">Invoice settings</div>
          <div className="mt-3 mb-7 text-17px font-normal text-primaryText">
            Configure the default settings for invoices created by everyone on the team.
          </div>
          <Divider className="w-full bg-borderGray" />
          <div className="relative w-[560px] md:w-[490px] lg:w-[820px]">
            {viewDiscount && (
              <>
                <div
                  className={twMerge(
                    'mt-8 mb-7  w-[90%] ',
                    classnames({
                      'pointer-events-none opacity-50': !editDiscount
                    })
                  )}>
                  <div className="flex items-center justify-between lg:w-3/4">
                    <div className="text-xl font-bold text-primaryText">Discount</div>
                    <CustomSwitch enabled={discount?.enabled} toggleSwitch={toggleDiscount} id="discount-switch" />
                  </div>
                  <div className="mt-7 text-17px font-normal text-primaryText">
                    Give customers discounts by % or amount on the overall invoice.
                  </div>
                </div>
                <Divider className="w-full bg-borderGray" />
              </>
            )}
            {viewTipsSettings && (
              <>
                <div
                  className={twMerge(
                    'mt-8 mb-7  w-[90%] ',
                    classnames({
                      'pointer-events-none opacity-50': !editTipsSettings
                    })
                  )}>
                  <div className="flex items-center justify-between lg:w-3/4">
                    <div className="text-xl font-bold text-primaryText">Tips</div>
                    <CustomSwitch enabled={tip.enabled} toggleSwitch={toggleTips} id="tips-switch" />
                  </div>
                  <div className="mt-7 mb-5 text-17px font-normal text-primaryText">
                    Let customers easily reward you for great service.
                  </div>
                  <TipChoices
                    handleEditClick={toggleEditTipsModal}
                    tipChoices={getTeamTipChoices()}
                    isTipEnabled={tip.enabled}
                  />
                </div>
                <Divider className="w-full bg-borderGray" />
              </>
            )}
            {viewServiceFeeSettings && (
              <>
                <div
                  className={twMerge(
                    'mt-8 mb-7  w-[90%] ',
                    classnames({
                      'pointer-events-none opacity-50': !editServiceFeeSettings
                    })
                  )}>
                  <ServiceFeeSection
                    handleServiceFeeChange={handleServiceFeeChange}
                    onBasePointClick={onBasePointClick}
                    onServiceFeeOptionClick={onServiceFeeOptionClick}
                    selectedBasePoint={selectedBasePoint}
                    selectedServiceFeeOption={selectedServiceFeeOption}
                    serviceFeeError={serviceFeeError}
                    surcharge={surcharge}
                    updateServiceFeePercentage={updateServiceFeePercentage}
                    isServiceFeeEnabled={surcharge.enabled}
                    toggleServiceFee={toggleServiceFee}
                  />
                </div>
                <Divider className="w-full bg-borderGray" />
              </>
            )}

            {/* signature */}
            {viewSignatureSettings && (
              <>
                <div
                  className={twMerge(
                    'mt-8 mb-7  w-[90%] ',
                    classnames({
                      'pointer-events-none opacity-50': !editSignatureSettings
                    })
                  )}>
                  <div className="text-xl font-bold text-primaryText">Signatures</div>
                  <div className="mt-7 text-17px font-normal text-primaryText">
                    Your team member has the option to enable or disable a signature at the invoice level while creating
                    each invoice.
                  </div>
                  <div className="mt-4 flex items-center gap-4">
                    <div className="text-17px font-normal text-primaryText">
                      Request a customer signature on invoices
                    </div>
                    <ListDropDown
                      buttonStyles="bg-secondaryBtn h-[50px] text-[17px] font-semibold text-primaryText cursor-pointer"
                      optionStyles="py-2.5 min-w-[180px]"
                      items={dropDownSignatureRequestOption}
                      selectedItem={selectedSignatureRequestOption}
                      setSelectedItem={onSignatureRequestOptionClick}
                      wrapperStyles="bg-primary w-[180px] focus:outline-none"
                      id="signature-request-option"
                    />
                  </div>
                </div>
                <Divider className="w-full bg-borderGray" />
              </>
            )}

            <div className="mt-9 rounded-md bg-secondaryBtn p-8">
              <div className="flex items-center">
                <FeatureMovedIcon />
                <div className="ml-3 text-xl font-bold text-headingGray">
                  Addl. Taxes and Fees have moved to Catalog
                </div>
              </div>
              <div className="mt-3 flex flex-col gap-5">
                <p className="text-sbase font-normal text-primaryText">
                  Catalog lets you setup <span className="font-bold">"Taxes"</span> and
                  <span className="font-bold"> "Service Charges"</span>, giving you more flexibility while creating
                  invoices.
                </p>
                <p className="text-sbase font-normal text-primaryText">
                  Head on over in the main navigation or click the button below
                </p>
              </div>
              <div
                onClick={navigateToCatalogPage}
                className="mt-3 flex cursor-pointer items-center text-sbase font-semibold text-secondary">
                Go to Catalog
                <div className="ml-2">
                  <ArrowRightIcon />
                </div>
              </div>
            </div>
            {viewTnc && (
              <>
                <Divider className="mt-12 mb-9 w-full bg-borderGray" />
                <div
                  className={twMerge(
                    'mt-8 mb-7  w-[90%] ',
                    classnames({
                      'pointer-events-none opacity-50': !editTnc
                    })
                  )}>
                  <div className="mt-9 mb-8 text-xl font-bold text-primaryText">Terms & Conditions</div>
                  <div className="flex items-center">
                    <CustomSwitch enabled={tnc?.enabled} toggleSwitch={() => toggleTerms()} id="tnc-switch" />
                    <div
                      className={twMerge(
                        'ml-5 text-primaryText text-[17px] font-semibold',
                        classnames({
                          'text-gray-1': !tnc?.enabled
                        })
                      )}>
                      Add to all Invoices & Receipts
                    </div>
                  </div>
                  {tnc.tnc ? (
                    <div className="mt-7 rounded-md border border-borderGray py-10 px-5">
                      <div className="flex items-center justify-end">
                        <div className="flex items-center">
                          <div
                            className="flex cursor-pointer items-center text-17px font-semibold text-secondary"
                            role="presentation"
                            onClick={handlEditTermsClick}>
                            <EditIconBlue />
                            <div className="ml-1">Edit</div>
                          </div>
                          <div
                            className="ml-[30px] flex cursor-pointer items-center 
                            text-17px font-semibold text-primaryText"
                            role="presentation"
                            onClick={handlDeleteTermsClick}>
                            <DeleteIcon />
                            <div className="ml-1">Delete</div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="customNormalScroll mt-6 max-h-[160px] overflow-y-auto
                                   whitespace-pre-line break-words text-sbase font-normal text-primaryText">
                        {tnc.tnc}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className="mt-7 text-sbase font-normal text-primaryText">
                        You can add a default text for Terms & Conditions, Return Policy, Support Policy, Cancellation
                        Policy, or any Custom Text with the invoice.
                      </div>
                      <div
                        className="mt-5 flex cursor-pointer items-center"
                        role="presentation"
                        onClick={handlAddTermsClick}>
                        <LineItemPlusReactIcon id="discount-tips-add-text" />
                        <div className="ml-2 text-17px font-semibold text-secondary">Add Additional Text</div>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}
            <>
              <Divider className="w-full bg-borderGray" />
              <div
                className={twMerge(
                  'mt-8 mb-7  w-[90%] flex flex-col gap-6',
                  classnames({
                    'pointer-events-none opacity-50': !editReviewPageLink
                  })
                )}>
                <ReviewPageSection
                  currentReviewPageLink={currentReviewPageLink}
                  handleDeleteReviewPageClick={() => {
                    setShowDeleteReviewLinkConfirmation(true);
                  }}
                  handleReviewPagePopupOpen={() => setShowReviewPageModal(true)}
                />
              </div>
            </>
            <If condition={loading}>
              <div
                className="pointer-event-none absolute left-0 top-0
                            z-[102] flex h-full w-full items-center justify-center bg-[#ffffff] opacity-75">
                <Lottie className="absolute top-[35%] h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
              </div>
            </If>
          </div>
        </div>
      </div>
      {getTermsSuggestionsSuccess &&
        !isTermsSuggestionsFetching &&
        getInvoicesSummarySuccess &&
        !isGetInvoicesSummaryFetching && (
          <CreateEditTermsModal
            suggestions={getTermsSuggestionsResponse}
            open={isTermsModalOpen}
            tncText={tnc.tnc}
            isEdit={isTermsEdit}
            toggleOpen={onCancelTermsClick}
            onSave={handleSaveTermsClick}
            numberOfInvoices={getInvoicesSummaryResponse.pendingInvoiceSummary.count}
          />
        )}
      <PreviewModal open={isPreviewModalOpen} toggleOpen={onPreviewModalClose} type={previewModalType} />
      {showEditTipsModal && (
        <EditTipsModal
          onCancelClick={toggleEditTipsModal}
          tipConfig={getTeamTipChoices()}
          onSaveTipChoices={handleSaveTipChoices}
          isSaveInProgress={isUpdateCustomizationsInProgress}
        />
      )}
      {showReviewPageModal && (
        <AddReviewPageModal
          onClose={() => setShowReviewPageModal(false)}
          showModal={showReviewPageModal}
          savedValue={currentReviewPageLink}
          handleReviewPageLinkUpdate={handleReviewPageLinkUpdate}
          processingSave={reviewPageLinkUpdateLoading}
        />
      )}
      {showDeleteReviewLinkConfirmation && (
        <DeleteReviewLinkConfirmationModal
          onCloseClick={() => setShowDeleteReviewLinkConfirmation(false)}
          handleDelete={handleDeleteReviewPageClick}
          processingDelete={isDeleteReviewLinkLoading}
        />
      )}
    </>
  );
};

export default DiscountTipsAndFees;
