import React, { useCallback } from 'react';
import { Doughnut } from 'react-chartjs-2';
import { pluckIntValuesFromArrayWithSpecificKey, pluckValuesFromArrayWithSpecificKey } from 'utils/formatters';

import { ArcElement, Chart as ChartJS } from 'chart.js';
import { PaymentMethod } from 'containers/home/home.model';
import { debounce } from 'utils/commonUtils';
import { isDesktop, isMobile, isTablet } from 'react-device-detect';

ChartJS.register(ArcElement);

interface PaymentBreakdownDoughnutProps {
  data: PaymentMethod[];
  setSelectedIndex: (index: number) => void;
}

const PaymentBreakdownDoughnut: React.FC<PaymentBreakdownDoughnutProps> = ({ data, setSelectedIndex }) => {
  const dataset = {
    labels: pluckValuesFromArrayWithSpecificKey(data || [], 'paymentMethod'),
    datasets: [
      {
        // label: '# of Votes',
        data: pluckIntValuesFromArrayWithSpecificKey(data || [], 'sales'),
        backgroundColor: pluckValuesFromArrayWithSpecificKey(data || [], 'colorCode'),
        borderColor: pluckValuesFromArrayWithSpecificKey(data || [], 'colorCode'),
        borderWidth: 1
      }
    ]
  };
  const removeSelectedIndex = () => {
    setSelectedIndex(null);
  };

  const debounceDisableHover = useCallback(debounce(removeSelectedIndex, 200), []);

  const options = {
    plugins: {
      legend: {
        display: false
      },
      tooltip: {
        enabled: false // <-- this option disables tooltips
      }
    },
    showTooltips: false,
    tooltips: { enabled: false },
    onHover: (event, activeElements, chart) => {
      if (activeElements.length && activeElements[0]?.index !== undefined && isDesktop) {
        setSelectedIndex(activeElements[0]?.index);
        debounceDisableHover();
      }
    },
    onClick: (event, activeElements, chart) => {
      if (activeElements.length && activeElements[0]?.index !== undefined && (isMobile || isTablet)) {
        setSelectedIndex(activeElements[0]?.index);
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div style={{ userSelect: 'none' }} className="w-full">
      <Doughnut data={dataset} options={options} />
    </div>
  );
};
export default PaymentBreakdownDoughnut;
