import { ChevronArrowRightIcon, DetailsSubmittedIcon } from 'assets/icons';
import { FC } from 'react';

const DetailsSubmittedCard: FC = () => {
  return (
    <div className="border-[rgba(56, 118, 187, 0.30)] flex w-full items-center rounded-md border bg-[#DBEFFD] p-[15px]">
      <DetailsSubmittedIcon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-bold text-heading">Application submitted</div>
        <div className="text-sbase text-primaryText">Verifying your details</div>
      </div>
      <ChevronArrowRightIcon className="ml-auto shrink-0" />
    </div>
  );
};

export default DetailsSubmittedCard;
