import Lottie from 'lottie-react';
import { FC } from 'react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { ITeamMember } from '../manageTeam.model';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface DeactivateTeamMemberModalProps {
  open: boolean;
  toggleOpen: (teamMember: ITeamMember) => void;
  showLoader: boolean;
  teamMember: ITeamMember;
  deactivateMemberHandler: (teamMember: any) => void;
}
const DeactivateTeamMemberModal: FC<DeactivateTeamMemberModalProps> = ({
  deactivateMemberHandler,
  showLoader,
  teamMember,
  open,
  toggleOpen
}) => {
  const onCancelClick = () => {
    toggleOpen(teamMember);
  };

  const handleDeactivateMemberButtonClick = () => {
    deactivateMemberHandler(teamMember);
  };

  const getMerchantName = () => {
    if (teamMember.firstName || teamMember.lastName) {
      return `${teamMember.firstName} ${teamMember.lastName}`;
    }
    return teamMember.phone;
  };

  return (
    <CustomModal width="md:w-96" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded-md p-10">
        <div className="flex flex-col items-center justify-center">
          {showLoader ? (
            <div className="flex w-full flex-col items-center justify-center p-16">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          ) : (
            <>
              <FormCloseReactIcon
                id="deactivate-team-member-modal-close"
                height="24"
                width="24"
                className="absolute top-2 right-2 shrink-0 cursor-pointer"
                onClick={onCancelClick}
              />
              <div className="flex w-full flex-col items-center justify-center">
                <p className="text-xl font-bold leading-6 text-error">Deactivate Team Member?</p>
                <p className="mt-4 text-center text-17px font-normal leading-5 text-primaryText">
                  {`${getMerchantName()} will lose access to your team. You will still be 
                  able to see all their historical data. You can reactivate them at any time`}
                </p>
              </div>
              <CustomButton
                onClick={handleDeactivateMemberButtonClick}
                id="deactivate-member-modal-yes-button"
                className={`text-semibold mt-5 h-[38px] w-full rounded-md bg-error
            text-px13 text-primaryBtnText shadow-none hover:bg-redButtonHover`}>
                Deactivate
              </CustomButton>
              <CustomButton
                id="deactivate-member-modal-no-button"
                className="mt-6 h-[38px] w-full border-accentText text-primaryText"
                type={ButtonType.SECONDARY}
                onClick={onCancelClick}>
                Cancel
              </CustomButton>
            </>
          )}
        </div>
      </div>
    </CustomModal>
  );
};
export default DeactivateTeamMemberModal;
