import { FacebookIcon, WebsiteIcon, YelpIcon } from 'assets/icons';
import { CircularStepper, CustomButton, CustomInput } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { FC, useEffect, useState } from 'react';
import { useLazyGetWaitingListRankQuery, useUpdateBasicDetailsMutation, useVerifyDigitalUserMutation } from '../api';
import {
  getCurrentData,
  getIsDigitalOnboardingEnabled,
  getUserData,
  setUserData,
  setWaitingListRank,
  updateCurrentData
} from '../digitalOnboardingSlice';
import { REGEX } from 'constants/common';
import { useLazyGetUserDetailsQuery } from 'containers/app/api';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { VerificationStatusEnum } from 'containers/app/app.model';
import { setHasPendingEmailSteps, setIsAuthenticated, setIsPasswordExpired } from 'containers/auth/authSlice';
import { constructBasicDetails } from '../utils';

const WebsiteSection: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userData = useAppSelector(getUserData);
  const currentData = useAppSelector(getCurrentData);
  const isDigitalOnboardingEnabled = useAppSelector(getIsDigitalOnboardingEnabled);
  const [updateBasicDetailsMutation, updateBasicDetailsState] = useUpdateBasicDetailsMutation();
  const [getUserDetailsQuery, { data: userDetails, isSuccess: isInitAPISuccess, isFetching }] =
    useLazyGetUserDetailsQuery();
  const [getWaitingListRank, { data: waitingListRank, isSuccess: isFetchWaitingListRankSuccess }] =
    useLazyGetWaitingListRankQuery();
  const { verificationStatus } = userDetails ?? {};
  const [verifyDigitalUserMutation, verifyDigitalUserState] = useVerifyDigitalUserMutation();
  const { isLoading: isVerifyDigitalUserLoading, isSuccess: isVerifyDigitalUserSuccess } = verifyDigitalUserState;
  const { isSuccess: isUpdateBasicDetailsSuccess, isLoading: isUpdateBasicDetailsLoading } = updateBasicDetailsState;
  const isLoading = isUpdateBasicDetailsLoading || isFetching || isVerifyDigitalUserLoading;
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [urlError, setUrlError] = useState({
    website: '',
    yelp: '',
    facebook: '',
    otherOnlineReferrence: ''
  });
  const { website, yelp, facebook, otherOnlineReferrence } = currentData;

  useEffect(() => {
    if (isUpdateBasicDetailsSuccess) {
      if (isDigitalOnboardingEnabled) verifyDigitalUserMutation({});
      else {
        getWaitingListRank({ teamId: currentTeam?.id });
      }
    }
  }, [isUpdateBasicDetailsSuccess]);

  useEffect(() => {
    if (isVerifyDigitalUserSuccess) {
      getUserDetailsQuery({});
    }
  }, [isVerifyDigitalUserSuccess]);

  useEffect(() => {
    if (isFetchWaitingListRankSuccess) {
      navigate(routesPath.WAITING_LIST);
      dispatch(setWaitingListRank(waitingListRank?.rank));
    }
  }, [isFetchWaitingListRankSuccess]);

  useEffect(() => {
    if (isInitAPISuccess && verificationStatus === VerificationStatusEnum.Verified) {
      dispatch(
        setIsAuthenticated({
          isAuthenticated: true,
          writeToLocalStorage: true
        })
      );
      dispatch(setIsPasswordExpired(false));
      dispatch(setHasPendingEmailSteps(false));
      navigate(routesPath.HOME);
    }
  }, [isInitAPISuccess, verificationStatus]);

  const onUrlChange = (key, value) => {
    setUrlError(prev => ({ ...prev, [key]: '' }));
    dispatch(updateCurrentData({ key, value }));
  };

  const hasUrlValue = website?.trim() || yelp?.trim() || facebook?.trim() || otherOnlineReferrence?.trim();

  const hasValidUrl = () => {
    if (!hasUrlValue) {
      return false;
    }
    let isValid = true;
    if (website.trim() && !REGEX.websiteRegex.test(website?.trim())) {
      setUrlError(prev => ({ ...prev, website: 'Enter a valid website' }));
      isValid = false;
    }
    if (yelp.trim() && !REGEX.yelpLinkRegex.test(yelp?.trim())) {
      setUrlError(prev => ({ ...prev, yelp: 'Enter a valid yelp link' }));
      isValid = false;
    }
    if (facebook.trim() && !REGEX.facebookLinkRegex.test(facebook?.trim())) {
      setUrlError(prev => ({ ...prev, facebook: 'Enter a valid facebook link' }));
      isValid = false;
    }
    if (otherOnlineReferrence.trim() && !REGEX.websiteRegex.test(otherOnlineReferrence?.trim())) {
      setUrlError(prev => ({ ...prev, otherOnlineReferrence: 'Enter a valid website' }));
      isValid = false;
    }
    return isValid;
  };

  const onSubmit = () => {
    if (hasValidUrl()) {
      const updatedData = {
        ...userData,
        website: website?.trim(),
        yelp: yelp?.trim(),
        facebook: facebook?.trim(),
        otherOnlineReferrence: otherOnlineReferrence?.trim()
      };
      dispatch(setUserData(updatedData));
      updateBasicDetailsMutation({
        payload: constructBasicDetails({
          userData: updatedData,
          teamId: currentTeam.id.toString(),
          submitRoute: 'P4'
        })
      });
    }
  };

  const onBack = () => {
    if (!isLoading) navigate(routesPath.DIGITAL_ONBOARDING_REFERRAL_SOURCE, { replace: true });
  };

  return (
    <div className="w-full">
      <CircularStepper currentStep={7} totalSteps={7} />
      <div className="mb-[30px] text-4xl font-bold text-headingGray">Where can your customers find you online?</div>
      <div className="flex flex-col gap-4">
        <CustomInput
          id="digital-onboarding-website-url"
          label="Website"
          value={website}
          className="p-4 pl-11 outline-none placeholder:font-semibold"
          onChange={(value: string) => onUrlChange('website', value)}
          prefix={<WebsiteIcon className="shrink-0" />}
          placeholder="Website"
          prefixWrapperStyle="top-[17px] left-[19px]"
          error={urlError.website}
        />
        <CustomInput
          id="digital-onboarding-yelp-url"
          label="Yelp"
          value={yelp}
          className="p-4 pl-11 outline-none placeholder:font-semibold"
          onChange={(value: string) => onUrlChange('yelp', value)}
          prefix={<YelpIcon className="shrink-0" />}
          placeholder="yelp.com/biz/"
          prefixWrapperStyle="top-[17px] left-[19px]"
          error={urlError.yelp}
        />
        <CustomInput
          id="digital-onboarding-facebook-url"
          label="Facebook"
          value={facebook}
          className="p-4 pl-11 outline-none placeholder:font-semibold"
          onChange={(value: string) => onUrlChange('facebook', value)}
          prefix={<FacebookIcon className="shrink-0" />}
          placeholder="facebook.com/"
          prefixWrapperStyle="top-[17px] left-[19px]"
          error={urlError.facebook}
        />
        <CustomInput
          id="digital-onboarding-other-online-referrence-url"
          label="Other"
          value={otherOnlineReferrence}
          className="p-4 pl-11 outline-none placeholder:font-semibold"
          onChange={(value: string) => onUrlChange('otherOnlineReferrence', value)}
          prefix={<WebsiteIcon className="shrink-0" />}
          placeholder="E.g. bing.com/your-business"
          prefixWrapperStyle="top-[17px] left-[19px]"
          error={urlError.otherOnlineReferrence}
        />
      </div>
      <div className="mt-10 flex flex-col gap-5">
        <CustomButton
          id="submit-digital-onboarding"
          className="h-10 w-full"
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onSubmit}
          disabled={!hasUrlValue}
          isLoading={isLoading}>
          Submit
        </CustomButton>
        <CustomButton
          id="back-button-sign-up"
          className="h-10 w-full border-none shadow-none hover:bg-primary active:shadow-none"
          type={ButtonType.SECONDARY}
          childrenContainerStyles="text-17px tracking-[-0.3px]"
          onClick={onBack}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default WebsiteSection;
