import classnames from 'classnames';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface CustomTextAreaProps {
  id: string;
  type?: string;
  placeholder?: string;
  className?: string;
  value: string;
  name: string;
  onChange: any;
  maxLength?: number;
  resizeTextArea?: boolean;
  width?: string;
  isInputValid?: boolean;
  warningMessageText?: string;
  icon: JSX.Element;
  disabled?: boolean;
  onClick?: () => void;
}

const CustomTextAreaWithIcon: FC<CustomTextAreaProps> = ({
  resizeTextArea = false,
  id,
  maxLength,
  onChange,
  className,
  placeholder,
  name,
  value,
  width = null,
  isInputValid = true,
  warningMessageText = '',
  icon,
  disabled = false,
  onClick = null
}) => {
  const handleChange = e => {
    onChange(e.target.value);
  };

  const widthStyles = width ? { width } : {};
  return (
    <div
      className={'relative flex flex-col'}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}>
      <div className="pointer-events-none absolute left-0 z-10 flex h-[50px] items-center pl-5 ">{icon}</div>
      <input
        id={id}
        maxLength={maxLength}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        className={twMerge(
          classnames(
            `relative h-[50px] max-h-[100px] rounded bg-secondaryBtn  ${
              !isInputValid ? 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-500' : ''
            }
            p-4 pl-[46px] text-17px font-semibold ${disabled ? 'text-accentText' : 'text-primaryText'}
             placeholder:text-17px placeholder:font-normal placeholder:text-accentText`
          ),
          className
        )}
        style={{ ...widthStyles, fontSize: '17px' }}
      />
    </div>
  );
};

export default CustomTextAreaWithIcon;
