import { AttentionNeededPageIcon, CircleCloseIcon } from 'assets/icons';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { SUPPORT_MAIL } from 'config/index';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { replaceURL } from 'utils/commonUtils';

interface ActionNeededPopupProps {
  onGetStartedClick: VoidFn;
  onClose: VoidFn;
}

const ActionNeededPopup: FC<ActionNeededPopupProps> = ({ onGetStartedClick, onClose }) => {
  const handleEmailClick = () => {
    replaceURL(`mailto:${SUPPORT_MAIL}`);
  };

  return (
    <CustomModal open width="md:min-w-[390px]" height="min-h-[400px]" onOutsideClick={onClose}>
      <div className="relative flex flex-col items-center justify-center gap-5 p-14 py-16 text-center">
        <CircleCloseIcon className="absolute right-2.5 top-2.5 cursor-pointer" onClick={onClose} />
        <AttentionNeededPageIcon className="shrink-0" />
        <div className="text-xl font-bold text-heading">Attention Needed: Bank account could not be verified</div>
        <div className="text-sbase text-primaryText">
          Please re-check your details and the bank statements and try again.
        </div>
        <div className="text-sbase text-primaryText">
          For further queries, please contact us at{' '}
          <span className="cursor-pointer text-secondary" onClick={handleEmailClick}>
            support@goscanpay.com
          </span>
        </div>
        <CustomButton
          id="application-status-continue-button"
          type={ButtonType.PRIMARY}
          onClick={onGetStartedClick}
          className="mt-5 h-12 w-full">
          Get Started
        </CustomButton>
      </div>
    </CustomModal>
  );
};

export default ActionNeededPopup;
