import React, { FC, useCallback, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import {
  useGetMerchantTeamsQuery,
  useLazyGetMerchantTeamsQuery,
  useLazyGetPaymentSystemConfigQuery,
  useLazyGetTeamBankAccountQuery,
  useLazyGetTeamBannerQuery
} from 'containers/app/api';
import DefaultPrivateLayout from './DefaultPrivateLayout';
import { selectCurrentTeam, selectUserDetails } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import { FireBaseCollections, db } from 'utils/firebase';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { clevertapPushProfile } from 'utils/analytics';
import { isAndroid, isIOS, isTablet } from 'react-device-detect';
import GenericMobileView from 'containers/GenericMobileView';
import { FirebaseStorage } from 'services/firebase';
// import { WhatsNewModal } from 'components';

// Commented out Whats new modal changes for Migration to CA release

// This layer in routing serves to support new layouts as we scale up
const PrivateLayout: FC<{ isLoadingInitAPI: boolean }> = ({ isLoadingInitAPI }) => {
  const { isLoading: isLoadingGetMerchantTeams } = useGetMerchantTeamsQuery();
  const [getMerchantTeamsQuery, getMerchantTeamsQueryState] = useLazyGetMerchantTeamsQuery();
  const [getPayoutsBannerQuery, getPayoutsBannerQueryState] = useLazyGetTeamBannerQuery();
  const [getPaymentSystemConfigQuery, getPaymentSystemConfigState] = useLazyGetPaymentSystemConfigQuery();
  const [getTeamBankAccountQuery, getTeamBankAccountState] = useLazyGetTeamBankAccountQuery();
  const currentUser = useAppSelector(selectUserDetails);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [lastUpdatedPermissionAt, setLastUpdatedPermissionAt] = useState<number>(0);
  // const [showWhatsNewModal, setShowWhatsNewModal] = useState<boolean>(false);
  const [hasConnectStatusChanged, setHasConnectStatusChanged] = useState<boolean>(false);
  const [hasBankStatusChanged, setHasBankStatusChanged] = useState<boolean>(false);
  const [hasPermissionsStateChanged, setHasPermissionsStateChanged] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser) {
      clevertapPushProfile({
        Site: {
          Phone: currentUser?.phone
        }
      });
    }
  }, [currentUser]);

  // const readWhatsNewFromFireStore = async ({ merchantId }: { merchantId: string }) => {
  //   const hasSeenWhatsNew = await FirebaseStorage.hasSeenWhatsNew({ merchantId });
  //   if (!hasSeenWhatsNew) {
  //     setShowWhatsNewModal(true);
  //   }
  // };

  useEffect(() => {
    let unsubscribe = () => null;
    if (currentUser?.id) {
      unsubscribe = onSnapshot(doc(db, FireBaseCollections.Merchants.collectionName, currentUser.id), document => {
        const lastUpdatedAtFromDoc = document.data()?.[FireBaseCollections.Merchants.fields.permissionLastUpdatedAt];
        if (
          document.data()?.[FireBaseCollections.Merchants.fields.permissionUpdatedWeb] ||
          lastUpdatedAtFromDoc > lastUpdatedPermissionAt
        ) {
          getMerchantTeamsQuery();
          setLastUpdatedPermissionAt(lastUpdatedAtFromDoc);
          setHasPermissionsStateChanged(true);
        }
      });
      // readWhatsNewFromFireStore({ merchantId: currentUser.id });
    }
    return () => {
      unsubscribe();
    };
  }, [currentUser?.id]);

  useEffect(() => {
    let unsubscribe = () => null;
    let unsubscribeConnectStatusListener = () => null;
    let unsubscribeBankStatusListener = () => null;
    if (currentTeam?.id) {
      const collectionFieldName = `${currentTeam.id}_banner_web`;
      unsubscribe = onSnapshot(doc(db, FireBaseCollections.Merchants.collectionName, currentUser.id), document => {
        const hasDataChanged = document.data()?.[collectionFieldName];
        if (hasDataChanged) {
          getPayoutsBannerQuery(currentTeam.id);
        }
      });
      const connectStatusCollectionFieldName = `${currentTeam.id}_ca_application_status_change_web`;
      unsubscribeConnectStatusListener = onSnapshot(
        doc(db, FireBaseCollections.Merchants.collectionName, currentUser.id),
        document => {
          const hasDataChanged = document.data()?.[connectStatusCollectionFieldName];
          if (hasDataChanged) {
            getMerchantTeamsQuery();
            setHasConnectStatusChanged(true);
          }
        }
      );
      const bankStatusCollectionFieldName = `${currentTeam.id}_bank_account_update_web`;
      unsubscribeBankStatusListener = onSnapshot(
        doc(db, FireBaseCollections.Merchants.collectionName, currentUser.id),
        document => {
          const hasDataChanged = document.data()?.[bankStatusCollectionFieldName];
          if (hasDataChanged) {
            getPaymentSystemConfigQuery(currentTeam?.id);
            getMerchantTeamsQuery();
            getTeamBankAccountQuery(currentTeam?.id);
            setHasBankStatusChanged(true);
          }
        }
      );
    }
    return () => {
      unsubscribe();
      unsubscribeConnectStatusListener();
      unsubscribeBankStatusListener();
    };
  }, [currentTeam?.id]);

  useEffect(() => {
    if (getPayoutsBannerQueryState.isSuccess) {
      FirebaseStorage.updateMerchantCollectionFieldValue({
        merchantId: currentUser.id,
        collectionFieldName: `${currentTeam.id}_banner_web`,
        value: false
      });
    }
  }, [getPayoutsBannerQueryState.isFetching, currentTeam?.id]);

  useEffect(() => {
    if (getPaymentSystemConfigState.isSuccess && getTeamBankAccountState.isSuccess) {
      if (hasBankStatusChanged) {
        FirebaseStorage.updateMerchantCollectionFieldValue({
          merchantId: currentUser.id,
          collectionFieldName: `${currentTeam.id}_bank_account_update_web`,
          value: false
        });
        setHasBankStatusChanged(false);
      }
    }
  }, [getPaymentSystemConfigState.isFetching, currentTeam?.id, getTeamBankAccountState.isFetching]);

  const updateFirebaseDoc = useCallback(async () => {
    const merchantCollectionRef = doc(db, FireBaseCollections.Merchants.collectionName, currentUser?.id);
    await updateDoc(merchantCollectionRef, {
      PermissionUpdatedWeb: false
    });
  }, [currentUser?.id]);

  useEffect(() => {
    if (getMerchantTeamsQueryState?.isSuccess && currentUser?.id) {
      if (hasPermissionsStateChanged) {
        updateFirebaseDoc();
        setHasPermissionsStateChanged(false);
      }
      if (hasConnectStatusChanged) {
        FirebaseStorage.updateMerchantCollectionFieldValue({
          merchantId: currentUser.id,
          collectionFieldName: `${currentTeam.id}_ca_application_status_change_web`,
          value: false
        });
        setHasConnectStatusChanged(false);
      }
    }
  }, [getMerchantTeamsQueryState?.isFetching, updateFirebaseDoc]);

  const showGenericMobileView = (isAndroid || isIOS) && !isTablet;

  // const onDismissWhatsNewModal = () => {
  //   if (currentUser.id) {
  //     FirebaseStorage.markWhatsNewAsSeen({ merchantId: currentUser.id });
  //   }
  //   setShowWhatsNewModal(false);
  // };

  // TODO Remove Generic Mobile View once mobile responsiveness of the app is complete
  return (
    <div>
      <Routes>
        <Route
          path="*"
          element={
            showGenericMobileView ? (
              <GenericMobileView />
            ) : (
              <DefaultPrivateLayout
                isLoadingInitAPI={isLoadingInitAPI}
                isLoadingGetMerchantTeams={isLoadingGetMerchantTeams}
              />
            )
          }
        />
      </Routes>
      {/* {showWhatsNewModal && !showGenericMobileView && (
        <WhatsNewModal
          config={{ open: showWhatsNewModal }}
          onPrimaryBtnClick={onDismissWhatsNewModal}
          onClose={onDismissWhatsNewModal}
        />
      )} */}
    </div>
  );
};

export default PrivateLayout;
