import { SignInMethods } from './types';

export enum SignInPages {
  SIGNIN = 'Sign in',
  VERIFY_CODE = 'Verify',
  ACCOUNT_NOT_FOUND = 'Account not found',
  DEVICE_AUTH = 'Device Auth',
  CREATE_PASSWORD = 'Create password',
  CREATE_NEW_PASSWORD = 'Create a new password',
  EXPIRED_PASSWORD_ON_LOGIN = 'Expired password on login',
  ENTER_PASSWORD = 'Enter password',
  PASSWORD_EXPIRED = 'Create a new password',
  RELATED_ACCOUNTS = 'Related accounts',
  ADD_EMAIL_PROMPT = 'Add Email Prompt',
  VERFN_METHOD = 'Verification method',
  RESET_PASSWORD = 'Reset password while logging in',
  EMAIL_VERIFICATION = 'Email Verification'
}

export enum AppsFlyerParams {
  CAMPAIGN = 'c',
  CUSTOMER_ID = 'af_referrer_customer_id',
  INVITE_TOKEN = 'inviteToken'
}

export enum CampaignValues {
  RESELLER = 'Reseller',
  TEAM_INVITE = 'TeamInvite'
}

export enum SignInMethodEnum {
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber'
}

export const signInMethods: SignInMethods = {
  phoneNumber: {
    id: SignInMethodEnum.PHONE_NUMBER,
    label: 'Phone number',
    value: ''
  },
  email: {
    id: SignInMethodEnum.EMAIL,
    label: 'Email',
    value: ''
  }
};

export enum PasswordRequirements {
  CHARACTER_MSG = 'Minimum of 8 characters',
  DIGIT_MSG = 'Contains a number (0-9)',
  UPPERCASE_MSG = 'Contains a uppercase letter (A-Z)',
  LOWERCASE_MSG = 'Contains a lowercase character (a-z)',
  SPECIAL_CHAR_MSG = 'Contains special characters (!@#$%^&*)'
}

export enum SignUpPages {
  SIGNUP = 'Sign up',
  ACCOUNT_ALREADY_EXISTS = 'Account already exists',
  DEVICE_AUTH = 'Device Auth',
  CREATE_PASSWORD = 'Create password',
  ADD_EMAIL_PROMPT = 'Add Email Prompt'
}

export enum InviteLinkStatuses {
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  ACCEPTED = 'ACCEPTED'
}
