import React, { FC } from 'react';
import { InvoiceAttachmentEditPage } from '../edit-invoice/invoicesEditSlice';

interface AttachmentCardProps {
  attachment: InvoiceAttachmentEditPage;
}

const AttachmentCard: FC<AttachmentCardProps> = ({ attachment }) => {
  const { imagePreview, title, description } = attachment;

  return (
    <div className="flex flex-col gap-[3px]">
      <div>
        <div className="shrink-0">
          <img src={imagePreview} />
        </div>
      </div>
      <div className="text-sbase font-normal text-headingGray">{title}</div>
      <div className="text-px13 font-normal text-primaryText">{description}</div>
    </div>
  );
};

export default AttachmentCard;
