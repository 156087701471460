import { ArrowRightIcon, FetchingErrorIcon } from 'assets/icons';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface GenericErrorProps {
  handleErrorBack: VoidFn;
}

const GenericError: FC<GenericErrorProps> = ({ handleErrorBack }) => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center gap-2">
        <FetchingErrorIcon />
        <div className="text-base text-accentText">Something went wrong!</div>
        <div className="flex cursor-pointer items-center gap-2" onClick={handleErrorBack}>
          <div className="rotate-180">
            <ArrowRightIcon />
          </div>
          <div className="text-sbase text-accentText">Go back</div>
        </div>
      </div>
    </div>
  );
};

export default GenericError;
