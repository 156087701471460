import React, { useCallback, useEffect, useState } from 'react';
import Lottie from 'lottie-react';
import { twMerge } from 'tailwind-merge';
import classNames from 'classnames';
import { VoidFn } from 'types/baseTypes';
import { CustomButton, CustomInput, CustomModal } from 'components';
import { debounce, getUrlWithProtocol } from 'utils/commonUtils';
import { isWebsiteValid } from 'utils/stringUtils';
import { CircleCloseIcon, RedCrossErrorIcon, VerifiedSuccessTick, WebsiteIcon } from 'assets/icons';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useValidateReviewLinkMutation } from '../api';
import { ValidationStatus } from '../type';

interface ReviewPageModalProps {
  onClose: VoidFn;
  showModal: boolean;
  savedValue: string;
  handleReviewPageLinkUpdate: (url: string) => void;
  processingSave: boolean;
}

export const AddReviewPageModal: React.FC<ReviewPageModalProps> = ({
  onClose,
  showModal,
  savedValue,
  handleReviewPageLinkUpdate,
  processingSave
}) => {
  const [reviewPageLink, setReviewPageLink] = useState('');
  const [validationStatus, setValidationStatus] = useState<ValidationStatus>();

  const [validateReviewLink, { isLoading: isValidationLoading, data: validationData }] =
    useValidateReviewLinkMutation();

  const hasValidationFailed = validationStatus === ValidationStatus.FAILED;
  const hasValidationSucceeded = validationStatus === ValidationStatus.SUCCESS;

  useEffect(() => {
    setReviewPageLink(savedValue);
  }, [savedValue]);

  useEffect(() => {
    if (validationData?.message === ValidationStatus.FAILED) setValidationStatus(ValidationStatus.FAILED);
    else if (validationData?.message === ValidationStatus.SUCCESS) setValidationStatus(ValidationStatus.SUCCESS);
  }, [validationData]);

  const getSuffix = () => {
    if (isValidationLoading)
      return (
        <Lottie
          className="absolute right-0 top-[-11px] h-[72px] w-[72px]"
          animationData={LoadingSpinnerAnimation}
          loop={true}
        />
      );
    if (hasValidationSucceeded) return <VerifiedSuccessTick className="absolute top-3 right-5" />;
    if (hasValidationFailed) return <RedCrossErrorIcon height={24} width={24} className="absolute top-3 right-5" />;
  };

  const verifyReviewLink = (url: string) => {
    const trimmedUrl = url.trim();
    const urlPayload = getUrlWithProtocol(trimmedUrl);
    if (trimmedUrl && isWebsiteValid(trimmedUrl) && urlPayload !== savedValue) {
      validateReviewLink({ websiteURL: urlPayload });
    }
  };

  const debouncedLinkVerifyHandler = useCallback(debounce(verifyReviewLink, 500), []);

  const handleReviewLinkSave = () => {
    if (!isValidationLoading && hasValidationSucceeded) {
      handleReviewPageLinkUpdate(reviewPageLink);
    }
  };

  return (
    <CustomModal open={showModal} width="w-[610px] max-w-[610px] md:w-[610px]" className="relative">
      <div className="absolute right-2.5 top-2.5 cursor-pointer" onClick={onClose}>
        <CircleCloseIcon />
      </div>
      <div className="px-10 pt-9 pb-7">
        <div className="mb-4 text-xl font-bold leading-6 text-headingGray">Add review page link</div>
        <CustomInput
          placeholder="Enter URL"
          value={reviewPageLink}
          onChange={value => {
            setValidationStatus(ValidationStatus.LOADING);
            setReviewPageLink(value);
            debouncedLinkVerifyHandler(value);
          }}
          id="review-page-link"
          className={classNames('p-5 pl-12 font-normal leading-[21px] outline-none', {
            'border border-error': hasValidationFailed && reviewPageLink
          })}
          hasSuffix
          suffixStyle="relative"
          suffix={getSuffix()}
          prefix={<WebsiteIcon />}
          prefixWrapperStyle="left-[22px]"
        />
        <div
          className={twMerge(
            'my-2 text-[15px] font-semibold leading-[18px] tracking-[-0.3px] text-error hidden',
            classNames({
              block: hasValidationFailed && reviewPageLink
            })
          )}>
          Please enter a valid URL
        </div>
        <div className="mt-9 flex justify-end gap-5">
          <CustomButton
            type={ButtonType.SECONDARY}
            onClick={onClose}
            id="close-review-link-popup"
            className="h-10 w-[100px] hover:bg-primary active:shadow-none">
            Cancel
          </CustomButton>
          <CustomButton
            type={ButtonType.PRIMARY}
            onClick={handleReviewLinkSave}
            id="save-review-link"
            className="h-10 w-[100px] hover:bg-primaryBtn active:shadow-none"
            disabled={!hasValidationSucceeded || !reviewPageLink}
            isLoading={processingSave}>
            Save
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
