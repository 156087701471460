import { NotifierTypes } from 'constants/notifierConstants';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import React, { FC, createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Lottie from 'lottie-react';
import routesPath from 'routes/RoutesPath';
import { InvoicesRouteState } from 'routes/types';
import { LoadingSpinnerAnimation } from 'assets/animations';
import GenericError from 'components/genric-error/GenericError';
import { useEditInvoiceMutation, useLazyGetInvoiceByIdQuery } from '../api';
import { PreviewBar } from '../components';
import { InvoiceTabEnum, minimumLineItemsError } from '../create-invoice/constants';
import { EditInvoicePayload, IInvoiceAttachmentRequest, TaxesAndFeesResponse } from '../invoices.model';
import BillToSectionEdit from './components/BillToSectionEdit';
import InvoiceOptionsSectionEdit from './components/InvoiceOptionsSectionEdit';
import InvoicesTabSideNav from './components/InvoicesTabSideNavEdit';
import LineItemsSectionEdit from './components/LineItemsSectionEdit';
import NotesAttachmentsSectionEdit from './components/NotesAttachmentsSectionEdit';
import PaymentOptionsSectionEdit from './components/PaymentOptionsSectionEdit';
import { constructArrayObjectAsString, throttle } from 'utils/commonUtils';
import {
  addInvoiceValidationErrorEditPage,
  enableCustomerNotSelectedErrorEditPage,
  selectInvoiceEditPage,
  selectInvoiceSliceInitializationInProgress,
  selectIsBackFromCreateTaxInEditSlice,
  selectIsFromPreview,
  selectIsSignatureEnabledEditPage,
  toggleIsFromPreview,
  updateInvoiceEditedResponse,
  updateInvoiceInitializationInProgress,
  updateIsSignatureEnabledEditPage,
  updateSelectedTNC
} from './invoicesEditSlice';
import { ErrorResponse } from 'containers/app/app.model';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsInvoices } from '../events';
import {
  getLineItemsArrayForEvent,
  getServiceChargeEventPayload,
  prepareDiscountRelatedPayload,
  prepareLineItemList,
  prepareServiceChargeListRequestPayload,
  prepareSummatedUniqueTaxList
} from '../utils';
import { InvoiceSourceEnum, InvoiceVersion } from '../constants';
import { DispatchDestination, invoiceMinimumAmount } from 'constants/common';
import { useGetItemSuggestionsAndGlobalTaxesAndFeesQuery } from 'containers/product-catalog/api';
import SignatureOptionsSection from '../create-invoice/components/SignatureOptionsSection';
import { useGetMerchantCustomizationsQuery } from 'containers/discount-tips-fees/api';
import { FeatureToggleState } from 'containers/discount-tips-fees/constants';
import { updateCustomizations } from 'containers/discount-tips-fees/discountTipsSlice';

interface IEditInvoiceContext {
  selectedInvoiceTab: InvoiceTabEnum;
  setSelectedInvoiceTab: React.Dispatch<React.SetStateAction<InvoiceTabEnum>>;
}

export const EditInvoiceContext = createContext<IEditInvoiceContext>({
  selectedInvoiceTab: InvoiceTabEnum.BILL_TO,
  setSelectedInvoiceTab: null
});

interface EditInvoiceProps {
  goToPreviewPage: () => void;
  taxesAndFeesData: TaxesAndFeesResponse;
  isLoadingTaxesAndFees: boolean;
}

const EditInvoice: FC<EditInvoiceProps> = ({ goToPreviewPage, taxesAndFeesData, isLoadingTaxesAndFees }) => {
  const invoiceInitInProgress = useAppSelector(selectInvoiceSliceInitializationInProgress);
  const isBackFromCreateTaxRoute = useAppSelector(selectIsBackFromCreateTaxInEditSlice);
  const selectedIsSignatureEnabled = useAppSelector(selectIsSignatureEnabledEditPage);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const selectedInvoice = useAppSelector(selectInvoiceEditPage);
  const isFromPreview = useAppSelector(selectIsFromPreview);
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [getInvoiceQuery, getInvoiceQueryState] = useLazyGetInvoiceByIdQuery();

  useGetItemSuggestionsAndGlobalTaxesAndFeesQuery({
    teamId: currentTeam.id,
    profession: currentTeam.profession,
    dispatchDestination: DispatchDestination.EDIT_INVOICE_SLICE
  });

  const { data: merchantCustomisations, isSuccess: merchantCustomisationsSuccess } = useGetMerchantCustomizationsQuery({
    teamId: currentTeam.id.toString()
  });

  useEffect(() => {
    if (merchantCustomisationsSuccess) {
      dispatch(updateCustomizations(merchantCustomisations));
      if (!invoiceInitInProgress && merchantCustomisations.customerSignatureConfig === FeatureToggleState.ALWAYS_ON) {
        dispatch(updateIsSignatureEnabledEditPage(true));
      }
    }
  }, [merchantCustomisationsSuccess, invoiceInitInProgress]);

  const [
    editInvoiceMutation,
    {
      isSuccess: isEditInvoiceSuccess,
      data: invoiceEditedResponse,
      isError: isInvoiceEditError,
      error: invoiceEditError
    }
  ] = useEditInvoiceMutation();
  const navigate = useNavigate();
  const { invoiceId } = useParams();

  const [selectedInvoiceTab, setSelectedInvoiceTab] = useState(InvoiceTabEnum.BILL_TO);

  const { data: getInvoiceResponse, isSuccess: getInvoiceSuccess, isError: isGetInvoiceErr } = getInvoiceQueryState;
  useEffect(() => {
    if (!isFromPreview && !isBackFromCreateTaxRoute) {
      dispatch(updateInvoiceInitializationInProgress(true));
      getInvoiceQuery({ invoiceId: invoiceId as string, isFromEdit: true, teamId: currentTeam.id });
    } else {
      setIsLoading(false);
      dispatch(toggleIsFromPreview(false));
    }
  }, [invoiceId]);

  useEffect(() => {
    if (getInvoiceResponse) {
      setIsLoading(false);
    }
  }, [getInvoiceResponse]);

  useEffect(() => {
    if (isGetInvoiceErr) {
      dispatch(updateInvoiceInitializationInProgress(false));
      setIsLoading(false);
    }
  }, [isGetInvoiceErr]);

  useEffect(() => {
    if (getInvoiceSuccess && getInvoiceResponse && !getInvoiceResponse?.isModifiable) {
      navigate(-1);
    }
    if (getInvoiceSuccess && getInvoiceResponse) {
      dispatch(updateSelectedTNC(getInvoiceResponse.tnc));
    }
  }, [getInvoiceResponse, getInvoiceSuccess]);

  useEffect(() => {
    if (isEditInvoiceSuccess) {
      dispatch(updateInvoiceEditedResponse(invoiceEditedResponse));
      const invoiceRouteState: InvoicesRouteState = {
        showShareModal: true,
        isEditInvoiceShare: true,
        showSidePanel: false
      };
      navigate(routesPath.INVOICES, { replace: true, state: invoiceRouteState });
    }
  }, [isEditInvoiceSuccess]);

  useEffect(() => {
    const tabElem = document.getElementById(selectedInvoiceTab);
    if (tabElem) {
      tabElem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }, [selectedInvoiceTab]);

  useEffect(() => {
    if (isInvoiceEditError) {
      const error = invoiceEditError as ErrorResponse;

      if (error?.data?.details) {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: error.data.details,
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      } else {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: 'Something went wrong',
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      }
    }
  }, [isInvoiceEditError]);

  const getAttachmentArray = () => {
    const attachmentArray: IInvoiceAttachmentRequest[] = selectedInvoice.selectedInvoiceAttachmentsEditPage?.map(
      attachment => {
        return {
          title: attachment.title,
          url: attachment.url,
          description: attachment.description
        };
      }
    );
    return attachmentArray;
  };

  const onEditAndShare = () => {
    let isPageIncomplete = false;
    if (selectedInvoice.invoiceLineItemsEditPage.length === 0) {
      isPageIncomplete = true;
      dispatch(addInvoiceValidationErrorEditPage(minimumLineItemsError));
    }

    if (selectedInvoice.invoiceCustomerEditPage === null) {
      isPageIncomplete = true;
      dispatch(enableCustomerNotSelectedErrorEditPage());
    }
    if (selectedInvoice.invoiceSubTotalEditPage < +currentTeam?.minimumAmount ?? invoiceMinimumAmount) {
      isPageIncomplete = true;

      dispatch(
        showNotifier({
          message: {
            primaryMessage: `Total amount must be at least $${currentTeam?.minimumAmount ?? invoiceMinimumAmount}`
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
    }
    if (isPageIncomplete) {
      return;
    }

    const serviceChargeListPayload = prepareServiceChargeListRequestPayload(selectedInvoice.selectedServiceCharge);

    const editInvoicePayload: EditInvoicePayload = {
      invoiceId: invoiceId,
      invoicePayload: {
        version: InvoiceVersion.THREE_POINT_ZERO,
        teamId: currentTeam.id.toString(),
        storeName: currentTeam.storeName,
        amount: selectedInvoice.invoiceSubTotalEditPage.toString(),
        comment: selectedInvoice.commentEditPage,
        customerId: selectedInvoice?.invoiceCustomerEditPage?.ID,
        phoneNumber: selectedInvoice?.invoiceCustomerEditPage?.phoneNumber,
        email: selectedInvoice?.invoiceCustomerEditPage?.email,
        customerName: selectedInvoice?.invoiceCustomerEditPage?.name,
        billingAddress: selectedInvoice?.invoiceCustomerEditPage?.billingAddress,
        lineItems: prepareLineItemList(selectedInvoice.invoiceLineItemsEditPage),
        ...prepareDiscountRelatedPayload(selectedInvoice.discountFormDataEditPage),
        surchargePercentage: selectedInvoice.isServiceFeeEnabledEditPage
          ? selectedInvoice.currentSurchargeEditPage.percentageWithBps ?? '0'
          : '0',
        isServiceFeeEnabled: selectedInvoice.isServiceFeeEnabledEditPage,
        attachments: getAttachmentArray(),
        tipEnabled: selectedInvoice.tipEnabledEditPage,
        tncId: getInvoiceResponse?.tnc?.tncId,
        ...prepareSummatedUniqueTaxList({
          appliedLineItemLevelTaxes: selectedInvoice.appliedLineItemLevelTaxes,
          invoiceLineItems: selectedInvoice.invoiceLineItemsEditPage,
          selectedServiceCharge: selectedInvoice.selectedServiceCharge
        }),
        serviceCharges: serviceChargeListPayload,
        serviceChargeAmount: serviceChargeListPayload?.length > 0 ? serviceChargeListPayload[0].totalAmount : '0',
        source: InvoiceSourceEnum.WEB,
        isSignatureEnabled: selectedIsSignatureEnabled
      }
    };
    const serviceChargeEventPayload = getServiceChargeEventPayload(serviceChargeListPayload);
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesEditInvoiceSave, {
      customerName: selectedInvoice?.invoiceCustomerEditPage?.name,
      customerId: selectedInvoice?.invoiceCustomerEditPage?.ID,
      teamId: currentTeam.id.toString(),
      storeName: currentTeam.storeName,
      amount: selectedInvoice.invoiceSubTotalEditPage.toString(),
      'Line Items': constructArrayObjectAsString(
        getLineItemsArrayForEvent(selectedInvoice.invoiceLineItemsEditPage),
        'Line item'
      ),
      ...prepareDiscountRelatedPayload(selectedInvoice.discountFormDataEditPage),
      surchargePercentage: selectedInvoice.currentSurchargeEditPage.enabled
        ? selectedInvoice.currentSurchargeEditPage.percentageWithBps ?? '0'
        : '0',
      ...(serviceChargeEventPayload?.length > 0 ? { serviceCharges: serviceChargeEventPayload } : {})
    });
    editInvoiceMutation(editInvoicePayload);
  };

  const editAndShareHandler = useCallback(throttle(onEditAndShare, 800), [selectedInvoice]);

  const onBackIconClick = () => {
    navigate(-1);
  };

  const handleSignatureStateChange = (value: boolean) => {
    dispatch(updateIsSignatureEnabledEditPage(value));
  };

  const checkIsSignatureDisabled = () => {
    if (merchantCustomisationsSuccess) {
      return merchantCustomisations.customerSignatureConfig === FeatureToggleState.ALWAYS_ON;
    }
    return false;
  };

  const onPreviewButtonClick = () => {
    let isPageIncomplete = false;
    if (selectedInvoice.invoiceLineItemsEditPage.length === 0) {
      isPageIncomplete = true;
      dispatch(addInvoiceValidationErrorEditPage(minimumLineItemsError));
    }

    if (selectedInvoice.invoiceCustomerEditPage === null) {
      isPageIncomplete = true;
      dispatch(enableCustomerNotSelectedErrorEditPage());
    }

    if (isPageIncomplete) {
      return;
    }
    goToPreviewPage();
  };

  if (isLoading || invoiceInitInProgress)
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
      </div>
    );

  if (!isLoading && isGetInvoiceErr)
    return (
      <div className="flex h-full w-full justify-center">
        <GenericError handleErrorBack={() => navigate(-1)} />
      </div>
    );

  return (
    <EditInvoiceContext.Provider value={{ selectedInvoiceTab, setSelectedInvoiceTab }}>
      <div className="flex h-full w-full flex-col border">
        <PreviewBar
          className="border border-borderGray"
          title="Edit Invoice"
          onPrimaryClick={editAndShareHandler}
          onReturn={onBackIconClick}
          primaryBtnTitle="Save & Share"
          secondaryBtnTitle="Preview"
          disablePrimaryBtn={false}
          onSecondaryClick={onPreviewButtonClick}
          primaryBtnId="save-and-share-invoice-button"
          secondaryBtnId="edit-invoice-preview-button"
        />
        <div className="flex h-full w-full justify-center overflow-hidden bg-primary">
          <div
            className="hidden w-1/3 max-w-[31.25rem] flex-col items-center p-12
          pr-[50px] lg:flex lg:w-[24rem] 1439px:w-[31.25rem]">
            <InvoicesTabSideNav context={EditInvoiceContext} />
          </div>

          <div
            id="scrollable div"
            className="customNormalScroll flex grow flex-col space-y-[30px]
           overflow-y-auto p-12 pl-[50px] 1439px:space-y-11">
            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.BILL_TO}>
              <BillToSectionEdit context={EditInvoiceContext} />
            </div>

            {/* TODO invoice options in edit page */}
            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.INVOICE_OPTIONS}>
              <InvoiceOptionsSectionEdit context={EditInvoiceContext} />
            </div>

            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.LINE_ITEMS}>
              <LineItemsSectionEdit
                taxesAndFees={taxesAndFeesData}
                isLoadingTaxesAndFees={isLoadingTaxesAndFees}
                context={EditInvoiceContext}
              />
            </div>

            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.NOTES_ATTACHMENTS}>
              <NotesAttachmentsSectionEdit context={EditInvoiceContext} isLoadingTaxesAndFees={isLoadingTaxesAndFees} />
            </div>

            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.PAYMENT_OPTIONS}>
              <PaymentOptionsSectionEdit isInvoiceEdit={true} context={EditInvoiceContext} />
            </div>
            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.SIGNATURES}>
              <SignatureOptionsSection
                signatureState={selectedIsSignatureEnabled}
                updateSignatureState={handleSignatureStateChange}
                context={EditInvoiceContext}
                isDisabled={checkIsSignatureDisabled()}
                id="edit-invoice-allow-sign-toggle-switch"
              />
            </div>
          </div>
        </div>
      </div>
    </EditInvoiceContext.Provider>
  );
};

export default EditInvoice;
