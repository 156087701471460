const Window = window as any;

export const hideChatIcon = () => {
  // Window?.fcWidget?.hide();

  Window.fcWidget?.setConfig({
    headerProperty: {
      hideChatButton: true
    }
  });
};

export const showChatIcon = () => {
  // Window?.fcWidget?.show();

  Window.fcWidget?.setConfig({
    headerProperty: {
      hideChatButton: false
    }
  });
};

export const destroyFreshChat = () => {
  window.fcWidget?.destroy();
};

export const setFreshChatConfig = ({ name, email, phone, externalId }) => {
  window.fcWidget?.setConfig({
    firstName: name,
    email: email,
    phone: phone,
    externalId: externalId
  });
};
