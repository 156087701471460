import {
  BankingPermissionList,
  CustomersPermissionList,
  FeesPermissionList,
  InvoicePermissionList,
  Permission,
  PermissionLevels,
  Permissions,
  RefundPermissionList,
  TeamRole,
  TeamSettingPermissionList
} from 'containers/app/app.model';
import { PaymentSystem } from 'containers/payouts/constants';

export const getTeamSettingPermissionLevel = (permissions?: TeamSettingPermissionList) => {
  if (permissions) {
    if (
      permissions.editExistingMember &&
      permissions.editTeamProfile &&
      permissions.inviteNewMember &&
      permissions.viewExistingMember &&
      permissions.viewTeamProfile
    ) {
      return PermissionLevels.EDIT_ALL;
    } else if (
      !permissions.editExistingMember &&
      !permissions.editTeamProfile &&
      !permissions.inviteNewMember &&
      permissions.viewExistingMember &&
      permissions.viewTeamProfile
    ) {
      return PermissionLevels.VIEW_ONLY;
    } else if (
      !permissions.editTeamProfile &&
      !permissions.inviteNewMember &&
      !permissions.editExistingMember &&
      !permissions.viewExistingMember &&
      !permissions.viewTeamProfile
    ) {
      return PermissionLevels.NO_ACCESS;
    }
  }
  return PermissionLevels.CUSTOM;
};

export const getInvoicePermissionLevel = (permissions: InvoicePermissionList, paymentSystem: PaymentSystem) => {
  if (
    permissions.createInvoice &&
    permissions.viewReceiptsByTeam &&
    permissions.viewReceiptsBySelf &&
    permissions.viewInvoicesBySelf &&
    permissions.viewInvoicesByTeam &&
    permissions.viewPayoutsBySelf &&
    permissions.viewPayoutsByTeam
  ) {
    return PermissionLevels.EDIT_ALL;
  } else if (
    permissions.createInvoice &&
    !permissions.viewReceiptsByTeam &&
    permissions.viewReceiptsBySelf &&
    permissions.viewInvoicesBySelf &&
    !permissions.viewInvoicesByTeam &&
    permissions.viewPayoutsBySelf &&
    !permissions.viewPayoutsByTeam
  ) {
    return PermissionLevels.EDIT_SELF_ONLY;
  } else if (
    !permissions.createInvoice &&
    !permissions.viewReceiptsByTeam &&
    !permissions.viewReceiptsBySelf &&
    !permissions.viewInvoicesBySelf &&
    !permissions.viewInvoicesByTeam &&
    (paymentSystem === PaymentSystem.LIP ? !permissions.viewPayoutsBySelf : true) &&
    !permissions.viewPayoutsByTeam
  ) {
    return PermissionLevels.NO_ACCESS;
  }
  return PermissionLevels.CUSTOM;
};

export const getRefundPermissionLevel = (permissions?: RefundPermissionList) => {
  if (permissions.createRefundsByTeam && permissions.createRefundsBySelf) {
    return PermissionLevels.EDIT_ALL;
  } else if (!permissions.createRefundsByTeam && permissions.createRefundsBySelf) {
    return PermissionLevels.EDIT_SELF_ONLY;
  } else if (!permissions.createRefundsByTeam && !permissions.createRefundsBySelf) {
    return PermissionLevels.NO_ACCESS;
  }
  return PermissionLevels.CUSTOM;
};

export const getTipAndServicePermissionLevel = (permissions?: FeesPermissionList) => {
  if (
    permissions.editServiceFeeSettings &&
    permissions.viewServiceFeeSettings &&
    permissions.editTipsSettings &&
    permissions.viewTipsSettings &&
    permissions.viewDiscount &&
    permissions.editDiscount &&
    permissions.editTnc &&
    permissions.viewSignatureSettings &&
    permissions.editSignatureSettings &&
    permissions.manageItemsAndCategories &&
    permissions.manageServiceCharges &&
    permissions.manageTaxes
  ) {
    return PermissionLevels.EDIT_ALL;
  } else if (
    !permissions.editServiceFeeSettings &&
    permissions.viewServiceFeeSettings &&
    !permissions.editTipsSettings &&
    permissions.viewTipsSettings &&
    !permissions.editDiscount &&
    permissions.viewDiscount &&
    permissions.viewTnc &&
    !permissions.editTnc &&
    permissions.viewSignatureSettings &&
    !permissions.editSignatureSettings &&
    !permissions.manageItemsAndCategories &&
    !permissions.manageTaxes &&
    !permissions.manageServiceCharges
  ) {
    return PermissionLevels.VIEW_ONLY;
  } else if (
    !permissions.editTipsSettings &&
    !permissions.viewTipsSettings &&
    !permissions.editServiceFeeSettings &&
    !permissions.viewServiceFeeSettings &&
    !permissions.viewDiscount &&
    !permissions.editDiscount &&
    !permissions.viewTnc &&
    !permissions.editTnc &&
    !permissions.manageItemsAndCategories &&
    !permissions.manageServiceCharges &&
    !permissions.manageTaxes
  ) {
    return PermissionLevels.NO_ACCESS;
  }
  return PermissionLevels.CUSTOM;
};

export const getCustomerPermissionLevel = (permissions?: CustomersPermissionList) => {
  if (
    permissions.createNewCustomers &&
    permissions.editExistingCustomers &&
    permissions.editCustomersCreatedByTeam &&
    permissions.viewCustomersCreatedByTeam
  ) {
    return PermissionLevels.EDIT_ALL;
  } else if (
    permissions.createNewCustomers &&
    permissions.editExistingCustomers &&
    !permissions.editCustomersCreatedByTeam &&
    permissions.viewCustomersCreatedByTeam
  ) {
    return PermissionLevels.EDIT_SELF_ONLY;
  } else if (
    !permissions.createNewCustomers &&
    !permissions.editCustomersCreatedByTeam &&
    !permissions.viewCustomersCreatedByTeam &&
    !permissions.editExistingCustomers
  ) {
    return PermissionLevels.NO_ACCESS;
  }
  return PermissionLevels.CUSTOM;
};

export const getBankingPermissionLevel = (permissions?: BankingPermissionList) => {
  if (
    permissions.viewAccountDetails &&
    permissions.viewCashbackDetails &&
    permissions.manageCards &&
    permissions.viewCardDetails &&
    permissions.downloadStatement &&
    permissions.editExistingPayee &&
    permissions.createPayee &&
    permissions.createACHTransfer
  ) {
    return PermissionLevels.FULL_ACCESS;
  } else if (
    permissions.viewAccountDetails &&
    permissions.viewCashbackDetails &&
    !permissions.manageCards &&
    !permissions.viewCardDetails &&
    permissions.downloadStatement &&
    !permissions.editExistingPayee &&
    !permissions.createPayee &&
    !permissions.createACHTransfer
  ) {
    return PermissionLevels.VIEW_ACCOUNT_ONLY;
  } else if (
    !permissions.viewAccountDetails &&
    !permissions.viewCashbackDetails &&
    !permissions.manageCards &&
    !permissions.viewCardDetails &&
    !permissions.downloadStatement &&
    !permissions.editExistingPayee &&
    !permissions.createPayee &&
    !permissions.createACHTransfer
  ) {
    return PermissionLevels.NO_ACCESS;
  } else if (
    permissions.viewAccountDetails &&
    permissions.viewCashbackDetails &&
    !permissions.manageCards &&
    !permissions.viewCardDetails &&
    permissions.downloadStatement &&
    permissions.editExistingPayee &&
    permissions.createPayee &&
    permissions.createACHTransfer
  ) {
    return PermissionLevels.MANAGE_FUNDS;
  }
  return PermissionLevels.CUSTOM;
};

export const getPermissions = (role: TeamRole): Permission => {
  switch (role) {
    case TeamRole.OWNER:
      return {
        role: role,
        permissions: {
          teamSettings: {
            level: PermissionLevels.EDIT_ALL,
            teamSettingPermissionList: {
              viewTeamProfile: true,
              viewExistingMember: true,
              inviteNewMember: true,
              editTeamProfile: true,
              editExistingMember: true
            }
          },
          invoice: {
            level: PermissionLevels.EDIT_ALL,

            invoicePermissionList: {
              createInvoice: true,
              viewReceiptsByTeam: true,
              viewReceiptsBySelf: true,
              viewInvoicesByTeam: true,
              viewInvoicesBySelf: true,
              viewPayoutsBySelf: true,
              viewPayoutsByTeam: true
            }
          },
          fees: {
            level: PermissionLevels.EDIT_ALL,
            feesPermissionList: {
              editServiceFeeSettings: true,
              editTipsSettings: true,
              viewTipsSettings: true,
              viewServiceFeeSettings: true,
              viewDiscount: true,
              editDiscount: true,
              viewTnc: true,
              editTnc: true,
              viewSignatureSettings: true,
              editSignatureSettings: true,
              manageItemsAndCategories: true,
              manageServiceCharges: true,
              manageTaxes: true
            }
          },
          customers: {
            level: PermissionLevels.EDIT_ALL,
            customersPermissionList: {
              createNewCustomers: true,
              editCustomersCreatedByTeam: true,
              editExistingCustomers: true,
              viewCustomersCreatedByTeam: true
            }
          },
          banking: {
            level: PermissionLevels.FULL_ACCESS,
            bankingPermissionList: {
              createPayee: true,
              createACHTransfer: true,
              viewCashbackDetails: true,
              viewCardDetails: true,
              viewAccountDetails: true,
              editExistingPayee: true,
              downloadStatement: true,
              manageCards: true
            }
          },
          refund: {
            level: PermissionLevels.EDIT_ALL,
            refundPermissionList: {
              createRefundsByTeam: true,
              createRefundsBySelf: true
            }
          }
        }
      };

    case TeamRole.ADMIN:
      return {
        role: role,
        permissions: {
          teamSettings: {
            level: PermissionLevels.EDIT_ALL,
            teamSettingPermissionList: {
              viewTeamProfile: true,
              viewExistingMember: true,
              inviteNewMember: true,
              editTeamProfile: true,
              editExistingMember: true
            }
          },
          invoice: {
            level: PermissionLevels.EDIT_ALL,
            invoicePermissionList: {
              createInvoice: true,
              viewReceiptsByTeam: true,
              viewReceiptsBySelf: true,
              viewInvoicesByTeam: true,
              viewInvoicesBySelf: true,
              viewPayoutsBySelf: true,
              viewPayoutsByTeam: true
            }
          },
          fees: {
            level: PermissionLevels.EDIT_ALL,
            feesPermissionList: {
              editServiceFeeSettings: true,
              editTipsSettings: true,
              viewTipsSettings: true,
              viewServiceFeeSettings: true,
              viewDiscount: true,
              editDiscount: true,
              viewTnc: true,
              editTnc: true,
              viewSignatureSettings: true,
              editSignatureSettings: true,
              manageItemsAndCategories: true,
              manageServiceCharges: true,
              manageTaxes: true
            }
          },
          customers: {
            level: PermissionLevels.EDIT_ALL,
            customersPermissionList: {
              createNewCustomers: true,
              editCustomersCreatedByTeam: true,
              editExistingCustomers: true,
              viewCustomersCreatedByTeam: true
            }
          },
          banking: {
            level: PermissionLevels.VIEW_ACCOUNT_ONLY,
            bankingPermissionList: {
              createPayee: false,
              createACHTransfer: false,
              viewCashbackDetails: true,
              viewCardDetails: false,
              viewAccountDetails: true,
              editExistingPayee: false,
              downloadStatement: true,
              manageCards: false
            }
          },
          refund: {
            level: PermissionLevels.EDIT_ALL,
            refundPermissionList: {
              createRefundsByTeam: true,
              createRefundsBySelf: true
            }
          }
        }
      };

    case TeamRole.MEMBER:
      return {
        role: role,
        permissions: {
          teamSettings: {
            level: PermissionLevels.VIEW_ONLY,
            teamSettingPermissionList: {
              viewTeamProfile: true,
              viewExistingMember: true,
              inviteNewMember: false,
              editTeamProfile: false,
              editExistingMember: false
            }
          },
          invoice: {
            level: PermissionLevels.EDIT_SELF_ONLY,
            invoicePermissionList: {
              createInvoice: true,
              viewReceiptsByTeam: false,
              viewReceiptsBySelf: true,
              viewInvoicesByTeam: false,
              viewInvoicesBySelf: true,
              viewPayoutsBySelf: true,
              viewPayoutsByTeam: false
            }
          },
          fees: {
            level: PermissionLevels.VIEW_ONLY,
            feesPermissionList: {
              editServiceFeeSettings: false,
              editTipsSettings: false,
              viewTipsSettings: true,
              viewServiceFeeSettings: true,
              viewDiscount: true,
              editDiscount: false,
              viewTnc: true,
              editTnc: false,
              viewSignatureSettings: true,
              editSignatureSettings: false,
              manageItemsAndCategories: false,
              manageServiceCharges: false,
              manageTaxes: false
            }
          },
          customers: {
            level: PermissionLevels.EDIT_SELF_ONLY,
            customersPermissionList: {
              createNewCustomers: true,
              editCustomersCreatedByTeam: false,
              editExistingCustomers: true,
              viewCustomersCreatedByTeam: true
            }
          },
          banking: {
            level: PermissionLevels.NO_ACCESS,
            bankingPermissionList: {
              createPayee: false,
              createACHTransfer: false,
              viewCashbackDetails: false,
              viewCardDetails: false,
              viewAccountDetails: false,
              editExistingPayee: false,
              downloadStatement: false,
              manageCards: false
            }
          },
          refund: {
            level: PermissionLevels.EDIT_SELF_ONLY,
            refundPermissionList: {
              createRefundsByTeam: false,
              createRefundsBySelf: true
            }
          }
        }
      };
    case TeamRole.SUPER_ADMIN:
      return {
        role: role,
        permissions: {
          teamSettings: {
            level: PermissionLevels.EDIT_ALL,
            teamSettingPermissionList: {
              viewTeamProfile: true,
              viewExistingMember: true,
              inviteNewMember: true,
              editTeamProfile: true,
              editExistingMember: true
            }
          },
          invoice: {
            level: PermissionLevels.EDIT_ALL,
            invoicePermissionList: {
              createInvoice: true,
              viewReceiptsByTeam: true,
              viewReceiptsBySelf: true,
              viewInvoicesByTeam: true,
              viewInvoicesBySelf: true,
              viewPayoutsBySelf: true,
              viewPayoutsByTeam: true
            }
          },
          fees: {
            level: PermissionLevels.EDIT_ALL,
            feesPermissionList: {
              editServiceFeeSettings: true,
              editTipsSettings: true,
              viewTipsSettings: true,
              viewServiceFeeSettings: true,
              viewDiscount: true,
              editDiscount: true,
              viewTnc: true,
              editTnc: true,
              viewSignatureSettings: true,
              editSignatureSettings: true,
              manageItemsAndCategories: true,
              manageServiceCharges: true,
              manageTaxes: true
            }
          },
          customers: {
            level: PermissionLevels.EDIT_ALL,
            customersPermissionList: {
              createNewCustomers: true,
              editCustomersCreatedByTeam: true,
              editExistingCustomers: true,
              viewCustomersCreatedByTeam: true
            }
          },
          banking: {
            level: PermissionLevels.MANAGE_FUNDS,
            bankingPermissionList: {
              createPayee: true,
              createACHTransfer: true,
              viewCashbackDetails: true,
              viewCardDetails: false,
              viewAccountDetails: true,
              editExistingPayee: true,
              downloadStatement: true,
              manageCards: false
            }
          },
          refund: {
            level: PermissionLevels.EDIT_ALL,
            refundPermissionList: {
              createRefundsByTeam: true,
              createRefundsBySelf: true
            }
          }
        }
      };
  }

  return null;
};

export const getTeamRole = (permissions: Permissions, paymentSystem: PaymentSystem) => {
  if (
    getTeamSettingPermissionLevel(permissions.teamSettings.teamSettingPermissionList) === PermissionLevels.EDIT_ALL &&
    getInvoicePermissionLevel(permissions.invoice.invoicePermissionList, paymentSystem) === PermissionLevels.EDIT_ALL &&
    getRefundPermissionLevel(permissions.refund.refundPermissionList) === PermissionLevels.EDIT_ALL &&
    getTipAndServicePermissionLevel(permissions.fees.feesPermissionList) === PermissionLevels.EDIT_ALL &&
    getCustomerPermissionLevel(permissions.customers.customersPermissionList) === PermissionLevels.EDIT_ALL &&
    getBankingPermissionLevel(permissions.banking.bankingPermissionList) === PermissionLevels.VIEW_ACCOUNT_ONLY
  ) {
    return TeamRole.ADMIN;
  } else if (
    getTeamSettingPermissionLevel(permissions.teamSettings.teamSettingPermissionList) === PermissionLevels.VIEW_ONLY &&
    getInvoicePermissionLevel(permissions.invoice.invoicePermissionList, paymentSystem) ===
      PermissionLevels.EDIT_SELF_ONLY &&
    getRefundPermissionLevel(permissions.refund.refundPermissionList) === PermissionLevels.EDIT_SELF_ONLY &&
    getTipAndServicePermissionLevel(permissions.fees.feesPermissionList) === PermissionLevels.VIEW_ONLY &&
    getCustomerPermissionLevel(permissions.customers.customersPermissionList) === PermissionLevels.EDIT_SELF_ONLY &&
    getBankingPermissionLevel(permissions.banking.bankingPermissionList) === PermissionLevels.NO_ACCESS
  ) {
    return TeamRole.MEMBER;
  } else if (
    getTeamSettingPermissionLevel(permissions.teamSettings.teamSettingPermissionList) === PermissionLevels.EDIT_ALL &&
    getInvoicePermissionLevel(permissions.invoice.invoicePermissionList, paymentSystem) === PermissionLevels.EDIT_ALL &&
    getRefundPermissionLevel(permissions.refund.refundPermissionList) === PermissionLevels.EDIT_ALL &&
    getTipAndServicePermissionLevel(permissions.fees.feesPermissionList) === PermissionLevels.EDIT_ALL &&
    getCustomerPermissionLevel(permissions.customers.customersPermissionList) === PermissionLevels.EDIT_ALL &&
    getBankingPermissionLevel(permissions.banking.bankingPermissionList) === PermissionLevels.MANAGE_FUNDS
  ) {
    return TeamRole.SUPER_ADMIN;
  }
  return TeamRole.CUSTOM;
};
