export const ONE_LINK = 'https://scanpay.onelink.me/EUMA/';

export const SPAY_APP_LINK = 'https://scanpay.onelink.me/oAGU/store';

export const SPAY_IOS_LINK = 'https://apps.apple.com/sg/app/scanpay-accept-payments/id1572936786';

export const SPAY_ANDROID_LINK = 'https://play.google.com/store/apps/details?id=tech.scanpay.merchant&hl=en_IN&gl=US';

export const TERMS_LINK = 'https://scanpay.tech/terms';

export const PRIVACY_POLICY_LINK = 'https://scanpay.tech/privacy-policy';

export const GO_SCANPAY_FAQ = 'https://goscanpay.com/faqs';

export const GO_SCANPAY_LINK = 'https://goscanpay.com';

export const SUPPORT_MAIL = 'support@goscanpay.com';

export const HOW_TO_RESPOND_TO_DISPUTE_LINK = 'https://www.goscanpay.com/blogs/How-to-respond-to-a-dispute';

export const HOW_TO_UPLOAD_EVIDENCE_LINK =
  'https://www.goscanpay.com/blogs/Best-practices-for-submitting-evidence-to-challenge-a-dispute';

export const CATALOG_FAQ_LINK = 'https://www.goscanpay.com/faqs?c=Product-Catalog';

export const CATALOG_BLOG_POST_LINK = 'https://www.goscanpay.com/blogs/scanpay-catalog-simplify-invoicing';

export const SCANPAY_CONNECT_DOCUMENTATION = 'https://api-v1.scanpay.tech/connect/docs#/';

export const CUSTOMER_SIGNATURE_BLOG_POST =
  'http://www.goscanpay.com/blogs/now-collect-customer-signatures-directly-with-scanpay';
