import { Loader } from 'components';
import { selectCurrentTeam } from 'containers/app/appSlice';
import TopBar from 'containers/top-bar/TopBar';
import { useAppSelector } from 'hooks';
import { FC, createContext, useState } from 'react';
import { useGetMerchantProfileQuery } from './api';
import AccountSettingsNav from './components/AccountSettingsNav';
import MyTeamsSection from './components/MyTeamsSection';
import OverlayedSideNav from './components/OverlayedSideNav';
import ProfileDetailsSection from './components/ProfileDetailsSection';
import ProfileImageSection from './components/ProfileImageSection';

interface MyAccount {
  className?: string;
}

export interface IAccountSettingsContext {
  showOverlayedSideNav: boolean;
  setShowOverlayedSideNav: React.Dispatch<React.SetStateAction<boolean>>;
}

export const AccountSettingsContext = createContext<IAccountSettingsContext>({
  showOverlayedSideNav: false,
  setShowOverlayedSideNav: () => null
});

const MyAccount: FC<MyAccount> = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { isFetching: isFetchingMerchantProfileQuery, refetch: refetchMerchantProfile } = useGetMerchantProfileQuery({
    teamId: currentTeam?.id
  });

  const [showOverlayedSideNav, setShowOverlayedSideNav] = useState<boolean>(false);

  return (
    <AccountSettingsContext.Provider value={{ showOverlayedSideNav, setShowOverlayedSideNav }}>
      <div className="flex h-full flex-col">
        <TopBar isOverlayedSideNavLayout={true} className="hidden sm:flex" />
        <div className="flex h-full flex-row">
          <AccountSettingsNav />
          <OverlayedSideNav />
          <div
            className="lg:customNormalScroll flex h-full grow flex-col
            overflow-y-auto py-5 px-[30px]">
            {!isFetchingMerchantProfileQuery ? (
              <>
                <div className="mb-[30px] text-3xl font-bold text-primaryText">My Account</div>
                <ProfileImageSection
                  refetchMerchantProfile={refetchMerchantProfile}
                  isFetchingMerchantProfileQuery={isFetchingMerchantProfileQuery}
                  className="mb-[45px]"
                />
                <ProfileDetailsSection refetchMerchantProfile={refetchMerchantProfile} />
                <MyTeamsSection />
              </>
            ) : (
              <Loader />
            )}
          </div>
        </div>
      </div>
    </AccountSettingsContext.Provider>
  );
};

export default MyAccount;
