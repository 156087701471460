import { Loader } from 'components';
import { REDIRECTION_PAGES, RedirectionSearchParams } from 'constants/redirectionConstants';
import { PaymentSystem } from 'containers/payouts/constants';
import { FC, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { generateRedirectionURL } from 'utils/urlUtils';

const Placeholder: FC = () => {
  const [searchParams] = useSearchParams();

  const redirectionURL = generateRedirectionURL(
    searchParams.get(RedirectionSearchParams.PAGE) as REDIRECTION_PAGES,
    searchParams.get(RedirectionSearchParams.ID),
    searchParams.get(RedirectionSearchParams.TEAM_ID),
    (searchParams.get(RedirectionSearchParams.PAYMENT_SYSTEM) as PaymentSystem) ?? null
  );

  useEffect(() => {
    if (redirectionURL) {
      location.replace(redirectionURL);
    }
  }, [redirectionURL]);

  return (
    <div className="h-full w-full">
      <Loader />
    </div>
  );
};

export default Placeholder;
