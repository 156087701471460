import { ArrowRightIcon, BankWithPlusIcon, MigrateAccountsIcon, SendLineIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { getBrbClosureDate } from 'containers/home/utils';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface MigrationStartModalProps {
  onFAQClick: VoidFn;
  onUpgradePaymentsClick: VoidFn;
  onBack: VoidFn;
}

const MigrationStartModal: FC<MigrationStartModalProps> = ({ onBack, onFAQClick, onUpgradePaymentsClick }) => {
  const newFeatureInfoList = [
    {
      text: 'Get payments in the bank of your choice',
      subtext: 'You can now connect any bank account to get your payouts directly. We support all US bank accounts',
      Icon: BankWithPlusIcon
    },
    {
      text: 'ScanPay banking accounts are closing',
      subtext: `Your ScanPay checking account will be closed on ${getBrbClosureDate()}.`,
      Icon: SendLineIcon
    }
  ];

  const renderPointElem = ({ Icon, text, subtext }) => {
    return (
      <div className="flex gap-5">
        <div className="mx-auto flex h-10 w-10 shrink-0 items-center justify-center rounded-full bg-[#3876BB1A]">
          {Icon && <Icon />}
        </div>
        <div>
          <div className="text-17px font-semibold text-primaryText">{text}</div>
          <div className="text-sbase text-primaryText">{subtext}</div>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="w-full">
        <div className="flex p-5 pb-2.5">
          <ArrowRightIcon className="rotate-180 cursor-pointer" onClick={onBack} width={20} height={20} />
        </div>
        <div className="p-[30px]">
          <div className="space-y-5 px-7 text-center">
            <div className="mx-auto flex h-16 w-16 items-center justify-center rounded-full bg-secondaryBtn">
              <MigrateAccountsIcon />
            </div>
            <div className="text-xl font-bold text-headingGray">Say hello to choice</div>
          </div>
          <div className="py-5 text-sbase text-primaryText">
            You can now connect your ScanPay to any bank account. You no longer need to have a ScanPay banking account
            to collect payments with ScanPay.
          </div>
          <div className="space-y-[30px]">
            <div className="text-17px font-semibold text-headingGray">What is changing?</div>
            {newFeatureInfoList.map(item => renderPointElem(item))}
          </div>
          <div className="mt-36 space-y-2.5">
            <CustomButton
              id="upgrade-confirm-button"
              className="w-full"
              onClick={onUpgradePaymentsClick}
              childrenContainerStyles="font-normal text-17px">
              Next: Upgrading payments
            </CustomButton>
            <CustomButton
              id="upgrade-Faq-button"
              className="w-full text-sbase font-semibold text-secondary active:shadow-none"
              type={null}
              onClick={onFAQClick}>
              How does this work?
            </CustomButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default MigrationStartModal;
