import { FC, useState } from 'react';

import { CircleCloseIcon } from 'assets/icons';
import { CustomModal } from 'components';
import { selectInvoiceEditedResponse } from 'containers/invoices/edit-invoice/invoicesEditSlice';
import { selectInvoiceCreatedResponse } from 'containers/invoices/InvoicesSlice';
import { InvoiceSharePageState } from 'containers/invoices/types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { VoidFn } from 'types/baseTypes';

import { useLazyDownloadInvoiceAsPDFQuery, useLazyGetInvoiceByIdQuery } from 'containers/invoices/api';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { SharePendingInvoiceMainSection } from 'containers/invoices/components/SharePendingInvoiceMainSection';
import { NotifierTypes } from 'constants/notifierConstants';
import { CleverTapEventsInvoices } from 'containers/invoices/events';
import { ROOT_ROUTE_STATE } from 'routes/constants';
import { logAnalyticEvent } from 'utils/analytics';
import { useNavigate } from 'react-router-dom';
import InvoiceUrlShare from 'containers/invoices/components/InvoiceUrlShare';
import { ShareQRSection } from 'containers/invoices/components/ShareQRSection';
import ManualKeyInModal from 'containers/invoices/manual-key-in/ManualKeyInModal';
import { selectCustomizations } from 'containers/discount-tips-fees/discountTipsSlice';
import { formatAmount, formatDecimal } from 'utils/amountUtils';
import { PaymentMethods } from 'constants/common';

interface ShareInvoiceModalProps {
  className?: string;
  show: boolean;
  handleClose: VoidFn;
  isEditInvoiceShare: boolean;
}
const ShareInvoiceModal: FC<ShareInvoiceModalProps> = ({ show, handleClose, isEditInvoiceShare }) => {
  const invoiceData = useAppSelector(isEditInvoiceShare ? selectInvoiceEditedResponse : selectInvoiceCreatedResponse);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const customizations = useAppSelector(selectCustomizations);
  const { manualKeyInTransactionLimit, paymentModes } = customizations ?? {};

  const [downloadInvoice] = useLazyDownloadInvoiceAsPDFQuery();
  const [getInvoiceByIdQuery] = useLazyGetInvoiceByIdQuery();

  const [pageState, setPageState] = useState<InvoiceSharePageState>(InvoiceSharePageState.ShareMainPage);
  const [openManualKeyInModal, setOpenManualKeyInModal] = useState<boolean>(false);

  const onCloseShareModal = () => {
    const id = invoiceData?.invoiceId as string;
    if (id) {
      getInvoiceByIdQuery({ invoiceId: id, teamId: currentTeam.id });
    }
    if (pageState === InvoiceSharePageState.ShowQrOnlyPage) {
      setPageState(InvoiceSharePageState.ShowSharePage);
    } else {
      handleClose();
    }
  };

  const handleManualKeyIn = () => {
    const manualKeyIn = paymentModes.find(mode => mode.name === PaymentMethods.MANUAL_KEY_IN);
    if (manualKeyIn && !manualKeyIn.enabled) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage:
              'Manual Card Entry is not enabled on your account yet. Please contact our support team to activate it.'
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
      return;
    }
    if (+invoiceData.payableAmount <= +manualKeyInTransactionLimit) {
      setOpenManualKeyInModal(true);
    } else {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: `Manual card entry can't be used for this payment. 
                            It's available only for amounts up to 
                            $${formatDecimal(formatAmount(+manualKeyInTransactionLimit))}. 
                            Please use a different method or contact support for help.`
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
    }
  };
  const onSendInvoice = () => {
    setPageState(InvoiceSharePageState.ShowSharePage);
  };

  const downloadFile = async () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    await downloadInvoice({ invoiceId: invoiceData.invoiceId, teamId: currentTeam.id });
  };

  const onMarkAsPaid = () => {
    handleClose();
    navigate(`/invoices/${currentTeam?.id}/${invoiceData?.invoiceId}/mark-as-paid`, { state: ROOT_ROUTE_STATE });
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesMMMarkAsPaid, { 'Invoice Id': invoiceData?.invoiceId });
  };

  const onPayWithQRCodeClick = () => {
    setPageState(InvoiceSharePageState.ShowQrOnlyPage);
  };

  const handleShareBack = () => {
    setPageState(InvoiceSharePageState.ShareMainPage);
  };

  const handleManualKeyInClose = () => {
    setOpenManualKeyInModal(false);
  };

  return (
    <>
      <CustomModal
        width="max-w-[375px] md:w-[375px]"
        className="min-h-[228px]"
        open={show && !openManualKeyInModal}
        closeModal={onCloseShareModal}>
        <div className="relative h-full min-h-full w-full">
          {pageState === InvoiceSharePageState.ShareMainPage && (
            <button className="absolute top-[9px] right-[10px]" onClick={onCloseShareModal}>
              <CircleCloseIcon id="share-invoice-modal-close-icon" />
            </button>
          )}
          {pageState === InvoiceSharePageState.ShareMainPage && (
            <div className="p-[30px]">
              <div className="text-17px font-semibold text-primaryText">Share invoice</div>
              <SharePendingInvoiceMainSection
                invoiceData={invoiceData}
                handleDownloadInvoice={downloadFile}
                handleManualKeyIn={handleManualKeyIn}
                handleMarkAsFullyPaid={onMarkAsPaid}
                handleSendInvoice={onSendInvoice}
                handlePayWithQRCodeClick={onPayWithQRCodeClick}
              />
            </div>
          )}
          {pageState === InvoiceSharePageState.ShowSharePage && (
            <InvoiceUrlShare invoiceData={invoiceData} className="p-7" onBack={handleShareBack} />
          )}
          {pageState === InvoiceSharePageState.ShowQrOnlyPage && (
            <div className="p-[30px]">
              <ShareQRSection
                qrData={invoiceData.qr}
                payableAmount={invoiceData.payableAmount}
                onBackClick={handleShareBack}
                className="mb-7"
              />
            </div>
          )}
        </div>
      </CustomModal>
      {openManualKeyInModal && (
        <ManualKeyInModal
          onClose={handleManualKeyInClose}
          invoiceData={invoiceData}
          checkoutUrl={invoiceData.checkoutURL}
          onRedirect={onCloseShareModal}
        />
      )}
    </>
  );
};

export default ShareInvoiceModal;
