import { CustomDisclosure } from 'components';
import { FC } from 'react';
import { PermissionSettingType } from '../constants';
import { PermissionItem } from '../EditPermissionsModal';
import PermissionListItem from './PermissionListItem';

interface PermissionDisclosureProps {
  className?: string;
  title: PermissionSettingType;
  secondaryTitle: string;
  permissionsSettingsList: PermissionItem[];
  onTogglePermission: (teamPermission: PermissionItem) => void;
}

const PermissionDisclosure: FC<PermissionDisclosureProps> = ({
  title,
  onTogglePermission,
  secondaryTitle,
  permissionsSettingsList,
  className = ''
}) => {
  const onScrollSectionIntoView = () => {
    const permissionSectionElement = document.getElementById(title);
    setTimeout(() => {
      permissionSectionElement?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }, 100);
  };

  return (
    <CustomDisclosure
      onContainerClick={onScrollSectionIntoView}
      id={title}
      secondaryTitleStyle="text-tertiaryText"
      title={title}
      titleStyle="text-secondaryBtnText"
      secondaryTitle={secondaryTitle}
      containerStyle="mb-5 border border-dark-gray rounded-md">
      <div className="flex flex-col">
        {permissionsSettingsList.map(permission => {
          return (
            <PermissionListItem
              className={className}
              key={permission.key}
              onTogglePemission={() => onTogglePermission(permission)}
              enabled={permission.enabled}
              title={permission.title}
            />
          );
        })}
      </div>
    </CustomDisclosure>
  );
};

export default PermissionDisclosure;
