import { PaymentTileEnum } from 'constants/homeConstants';
import { VoidFn } from 'types/baseTypes';

export interface IPaymentTile {
  key: PaymentTileEnum;
  TileIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
  title: string;
  amount?: number | string;
  onClick: VoidFn;
  count?: number;
  className?: string;
  isGrossSaleTile?: boolean;
  id: string;
}

export enum SaleTypes {
  ALL_SALES = 'All Sales',
  DIGITAL_SALES = 'Digital Sales'
}

export enum SalesTypeByDuration {
  TODAY = 'Today',
  THIS_MONTH = 'This Month',
  LAST_MONTH = 'Last Month',
  THIS_YEAR = 'This Year'
}
