import { baseApi } from 'services/api';
import { API_VERSION, DispatchDestination } from 'constants/common';
import { CatalogInitPayload, CatalogInitResponse } from './catalog.model';
import { addGlobalLineItemsTaxes, updateInitalizingItemSliceInProgress } from './items/itemsSlice';
import {
  addGlobalServiceChargeTaxes,
  updateInitalizingServiceChargeSliceInProgress
} from './service-charges/serviceChargeSlice';
import { ItemActionType } from './items/types';
import { addGlobalTaxesInInvoiceSlice } from 'containers/invoices/InvoicesSlice';
import { addGlobalTaxesInEditInvoiceSlice } from 'containers/invoices/edit-invoice/invoicesEditSlice';

const CatalogBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getItemSuggestionsAndGlobalTaxesAndFees: builder.query<CatalogInitResponse, CatalogInitPayload>({
      query: ({ teamId, profession }) => {
        return {
          url: `${API_VERSION.V1}/catalogue/init?teamId=${teamId}&profession=${profession}`,
          method: 'GET'
        };
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          if (payload.dispatchDestination === DispatchDestination.ITEMS_SLICE)
            dispatch(updateInitalizingItemSliceInProgress(true));
          else if (payload.dispatchDestination === DispatchDestination.SERVICE_CHARGE_SLICE)
            dispatch(updateInitalizingServiceChargeSliceInProgress(true));

          const { data } = await queryFulfilled;

          if (payload.dispatchDestination === DispatchDestination.ITEMS_SLICE) {
            if (data?.globalLineItemTaxes && payload.flowType !== ItemActionType.EDIT) {
              dispatch(addGlobalLineItemsTaxes(data?.globalLineItemTaxes));
            } else if (!data?.globalLineItemTaxes || payload.flowType === ItemActionType.EDIT) {
              dispatch(updateInitalizingItemSliceInProgress(false));
            }
          } else if (payload.dispatchDestination === DispatchDestination.SERVICE_CHARGE_SLICE) {
            if (data?.globalServiceChargeTaxes && !payload.isEditFlow) {
              dispatch(addGlobalServiceChargeTaxes(data?.globalServiceChargeTaxes));
            } else if (!data?.globalServiceChargeTaxes) dispatch(updateInitalizingServiceChargeSliceInProgress(false));
          } else if (payload.dispatchDestination === DispatchDestination.INVOICE_SLICE) {
            dispatch(
              addGlobalTaxesInInvoiceSlice({
                globalLineItemTaxes: data?.globalLineItemTaxes ?? [],
                globalServiceChargeTaxes: data?.globalServiceChargeTaxes ?? []
              })
            );
          } else if (payload.dispatchDestination === DispatchDestination.EDIT_INVOICE_SLICE) {
            dispatch(
              addGlobalTaxesInEditInvoiceSlice({
                globalLineItemTaxes: data?.globalLineItemTaxes ?? [],
                globalServiceChargeTaxes: data?.globalServiceChargeTaxes ?? []
              })
            );
          }
        } catch (err) {
          if (payload.dispatchDestination === DispatchDestination.ITEMS_SLICE)
            dispatch(updateInitalizingItemSliceInProgress(false));
          else if (payload.dispatchDestination === DispatchDestination.SERVICE_CHARGE_SLICE)
            dispatch(updateInitalizingServiceChargeSliceInProgress(false));
        }
      },
      extraOptions: {
        showToast: false
      }
    })
  })
});

export const { useGetItemSuggestionsAndGlobalTaxesAndFeesQuery } = CatalogBaseApi;
