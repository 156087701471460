import { TeamRole } from 'containers/app/app.model';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useLazyGetTeamMembersQuery } from 'containers/manage-team/api';
import { ITeamMember, TeamMemberStatus } from 'containers/manage-team/manageTeam.model';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useAppSelector } from '../../../hooks/typedHooks';

export interface TeamMembersQueryParams {
  status: TeamMemberStatus[];
  search: string;
  role: TeamRole[];
}

export const initialTeamMembersQueryParamsState: TeamMembersQueryParams = {
  status: [],
  search: null,
  role: []
};

export const usePaginatedGetTeamMembersQuery = () => {
  const [list, setList] = useState<ITeamMember[]>([]);
  const [queryParams, setQueryParams] = useState<TeamMembersQueryParams>(initialTeamMembersQueryParamsState);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [totalListCount, setTotalListCount] = useState<number>(0);
  const page = useRef(0);
  const [getTeamMembersData, teamMembersState] = useLazyGetTeamMembersQuery();
  const currentTeam = useAppSelector(selectCurrentTeam);

  const isInitListCall: boolean = page.current === 0;

  useEffect(() => {
    if (!teamMembersState?.isFetching && teamMembersState.isSuccess) {
      const teamMembers = teamMembersState?.data?.teamMembers ?? [];
      const updatedList = isInitListCall ? teamMembers : [...list, ...teamMembers];
      const teamMembersLeftToFetch = teamMembersState?.data?.totalCount
        ? +teamMembersState?.data?.totalCount - updatedList.length
        : 0;

      setHasMore(teamMembersLeftToFetch > 0);
      setList(updatedList);
      if (page.current === 1) {
        setTotalListCount(teamMembersState?.data?.totalCount ?? 0);
      }
      setIsLoading(false);
    }
  }, [teamMembersState?.isFetching]);

  useEffect(() => {
    if (!teamMembersState?.isFetching && teamMembersState?.isError) {
      setIsLoading(false);
      setHasMore(false);
    }
  }, [teamMembersState?.isError]);

  const resetListAndLoadMore = () => {
    page.current = 0;
    setList([]);
    loadMore();
  };

  useEffect(() => {
    resetListAndLoadMore();
  }, [queryParams]);

  const loadMore = useCallback(() => {
    setIsLoading(true);
    if (currentTeam?.id) {
      const remainingParams = queryParams;

      try {
        getTeamMembersData({
          teamId: currentTeam?.id,
          limit: 10,
          page: page.current,
          ...remainingParams,
          selectedIds: []
        }).unwrap();
      } catch (err) {
        setIsError(true);
      }
    }
    page.current = page.current + 1;
  }, [currentTeam?.id, page.current, queryParams]);

  const resetQuery = () => {
    resetListAndLoadMore();
  };

  return {
    isLoading: isLoading,
    isFetching: teamMembersState?.isFetching,
    totalListCount,
    list,
    isError,
    loadMore,
    resetQuery,
    hasMore,
    setQueryParams,
    queryParams,
    isInitListCall,
    isLoadingSuccess: teamMembersState.isSuccess
  };
};
