import React, { FC } from 'react';
import { CustomModal } from 'components';
import { ServiceFeePreview, TipPreview } from 'assets/icons';
import { PreviewType } from '../constants';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface CustomModalProps {
  open: boolean;
  toggleOpen: () => void;
  type: string;
}

const PreviewModal: FC<CustomModalProps> = ({ open, toggleOpen, type }) => {
  return (
    <div>
      <CustomModal open={open} className="w-[369px] md:w-[369px]" closeModal={toggleOpen}>
        <div className="p-8">
          <FormCloseReactIcon
            id={`${
              type === PreviewType.TIP_PREVIEW ? 'tip-section-preview-close' : 'service-fee-section-preview-close'
            }`}
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={toggleOpen}
            height={24}
            width={24}
          />
          {type === PreviewType.TIP_PREVIEW ? <TipPreview /> : <ServiceFeePreview />}
        </div>
      </CustomModal>
    </div>
  );
};

export default PreviewModal;
