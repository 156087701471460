import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import TaxInputSection from '../components/TaxInputSection';
import ApplyTaxSection from '../components/ApplyTaxSection';
import CatalogTopBar from 'containers/product-catalog/components/CatalogTopBar';
import { resetTaxSlice, selectTaxState } from '../taxSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CreateTaxAPIPayload, ITaxBasic } from '../taxes.model';
import { useCreateTaxMutation } from '../api';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { isTaxDataUpdated, prepareTaxPayload } from '../util';
import { TaxInputErrors } from '../type';
import { TaxRouteState } from 'routes/types';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsCatalogTaxes } from '../events';
import { constructArrayObjectAsString } from 'utils/commonUtils';
import { CatalogTopBarTaxesButton } from '../constants';
import { addNewlyCreatedTaxInItemSlice } from 'containers/product-catalog/items/itemsSlice';
import { SaveChangesConfirmationPopup } from 'components';
import { addNewlyCreatedTaxInServiceChargeSlice } from 'containers/product-catalog/service-charges/serviceChargeSlice';
import { RoutePath } from 'routes/index';
import { isDuplicateItemURL, isEditInvoiceURL, isEditItemURL, isEditServiceChargeURL } from 'routes/URLRegex';
import {
  addCreatedTaxInInvoiceDraftEditSlice,
  selectInvoiceDraftDataFromInvoiceEditSlice,
  updateIsBackFromCreateTaxInEditSlice
} from 'containers/invoices/edit-invoice/invoicesEditSlice';
import {
  addCreatedTaxInInvoiceDraft,
  selectInvoiceDraftDataInInvoiceSlice,
  updateIsBackFromCreateTaxInInvoiceSlice
} from 'containers/invoices/InvoicesSlice';
import { REGEX } from 'constants/common';

const CreateTax: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const routeState = location.state as TaxRouteState;
  const selectedTaxState = useAppSelector(selectTaxState);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const selectedInvoiceDraftData = useAppSelector(selectInvoiceDraftDataInInvoiceSlice);
  const selectedInvoiceDraftDataInEditSlice = useAppSelector(selectInvoiceDraftDataFromInvoiceEditSlice);
  const errorDefaultState = {
    taxNameError: null,
    taxRateError: null
  };
  const [errors, setErrors] = useState<Record<string, TaxInputErrors>>(errorDefaultState);
  const [showSaveChangesConfirmation, setShowSaveChangesConfirmation] = useState(false);
  const disableSaveBtn =
    !selectedTaxState.taxName ||
    !selectedTaxState.taxRate ||
    +selectedTaxState.taxRate === 0 ||
    selectedTaxState.taxRate.replace(REGEX.nonNumber, '') === '';

  const [createTaxMutation, { data: taxData, isSuccess: isCreateTaxSuccess, isLoading: isCreateTaxLoading, isError }] =
    useCreateTaxMutation();

  useEffect(() => {
    if (isCreateTaxSuccess) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Tax successfully created'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
      dispatch(resetTaxSlice());
      if (routeState?.callbackURL) {
        const newlyCreatedTax: ITaxBasic = {
          id: taxData.id,
          name: taxData.name,
          percentage: taxData.percentage
        };
        if (
          routeState.callbackURL === RoutePath.SERVICE_CHARGES_CREATE ||
          isEditServiceChargeURL.test(routeState.callbackURL)
        ) {
          dispatch(addNewlyCreatedTaxInServiceChargeSlice(newlyCreatedTax));
        } else if (
          routeState.callbackURL === RoutePath.ITEMS_CREATE ||
          isEditItemURL.test(routeState.callbackURL) ||
          isDuplicateItemURL.test(routeState.callbackURL)
        ) {
          dispatch(addNewlyCreatedTaxInItemSlice(newlyCreatedTax));
        }

        if (routeState.callbackURL === RoutePath.INVOICES_CREATE) {
          if (selectedInvoiceDraftData) {
            dispatch(addCreatedTaxInInvoiceDraft(newlyCreatedTax));
          }
          dispatch(updateIsBackFromCreateTaxInInvoiceSlice(true));
        } else if (isEditInvoiceURL.test(routeState.callbackURL)) {
          if (selectedInvoiceDraftDataInEditSlice) {
            dispatch(addCreatedTaxInInvoiceDraftEditSlice(newlyCreatedTax));
          }
          dispatch(updateIsBackFromCreateTaxInEditSlice(true));
        }
      }
      navigate(-1);
    }
  }, [isCreateTaxSuccess]);

  const handleSave = () => {
    setShowSaveChangesConfirmation(false);
    let isValid = true;
    let errorObject = errorDefaultState;
    if (!selectedTaxState.taxName) {
      isValid = false;
      errorObject = { ...errorObject, taxNameError: TaxInputErrors.Tax_Name_Error };
    }

    if (!selectedTaxState.taxRate || +selectedTaxState.taxRate <= 0) {
      isValid = false;
      errorObject = { ...errorObject, taxRateError: TaxInputErrors.Tax_Rate_Error };
    }

    if (!isValid) {
      setErrors(errorObject);
      return;
    }

    const payload: CreateTaxAPIPayload = prepareTaxPayload(selectedTaxState, currentTeam?.id.toString());
    if (payload) {
      createTaxMutation(payload);
    }

    logAnalyticEvent(CleverTapEventsCatalogTaxes.webCatalogTaxesCreateTaxSaveClicked, {
      name: selectedTaxState.taxName,
      taxRate: selectedTaxState.taxRate,
      items: constructArrayObjectAsString(selectedTaxState.items, 'item'),
      serviceCharge: constructArrayObjectAsString(selectedTaxState.serviceCharges, 'serviceCharge'),
      teamId: currentTeam.id
    });
  };

  const routeBack = () => {
    if (routeState?.callbackURL === RoutePath.INVOICES_CREATE) {
      dispatch(updateIsBackFromCreateTaxInInvoiceSlice(true));
    } else if (isEditInvoiceURL.test(routeState?.callbackURL)) {
      dispatch(updateIsBackFromCreateTaxInEditSlice(true));
    }
    navigate(-1);
  };

  const handleExit = () => {
    if (isTaxDataUpdated(selectedTaxState)) {
      setShowSaveChangesConfirmation(true);
    } else {
      dispatch(resetTaxSlice());
      routeBack();
    }
  };

  const discardSaveChangesConfirmation = () => {
    setShowSaveChangesConfirmation(false);
    routeBack();
  };

  return (
    <div className="flex h-full w-full flex-col">
      <CatalogTopBar
        className="border border-borderGray"
        title="Create tax"
        onPrimaryClick={handleSave}
        onReturn={handleExit}
        primaryBtnTitle="Save"
        disablePrimaryBtn={disableSaveBtn}
        primaryBtnId={CatalogTopBarTaxesButton.CreateTaxSaveButton}
        backButtonId={CatalogTopBarTaxesButton.CreateTaxBackButton}
        isPrimaryButtonLoading={isCreateTaxLoading}
      />
      <div className="flex h-full w-full flex-col items-center p-8">
        <div className="flex w-full max-w-[720px] basis-[50%] flex-col">
          <TaxInputSection errors={errors} setErrors={setErrors} className="mb-9" />
          <ApplyTaxSection />
        </div>
      </div>
      {showSaveChangesConfirmation && (
        <SaveChangesConfirmationPopup
          onSaveChanges={handleSave}
          closeSaveConfirmation={discardSaveChangesConfirmation}
          onCloseIconClick={() => setShowSaveChangesConfirmation(false)}
        />
      )}
    </div>
  );
};

export default CreateTax;
