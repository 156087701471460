import { CircleCloseIcon } from 'assets/icons';
import { CustomButton, CustomInput, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { REGEX } from 'constants/common';
import { FC, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { TipsError } from '../constants';

interface EditTipsModalProps {
  onCancelClick: VoidFn;
  onSaveTipChoices: (tips: number[]) => void;
  tipConfig: number[];
  isSaveInProgress: boolean;
}

const EditTipsModal: FC<EditTipsModalProps> = ({ onCancelClick, onSaveTipChoices, tipConfig, isSaveInProgress }) => {
  const [tipChoices, setTipChoices] = useState<number[]>(tipConfig);

  const removeEmptyElements = primaryArray => {
    return primaryArray.filter(elem => elem);
  };

  const handleSaveTips = () => {
    onSaveTipChoices(tipChoices);
  };

  const handleTipsChange = (value, position) => {
    if (!REGEX.percentage.test(value) || +value > 99) {
      return;
    }
    const tipsArray = [...tipChoices];
    tipsArray.splice(position, 1, value ? +value : value);
    setTipChoices(tipsArray);
  };

  const checkForErrors = (value, position) => {
    if (!value && value !== 0) {
      return TipsError.EMPTY;
    } else if (value === 0) {
      return TipsError.MINIMUM;
    }
    let isDuplicate = false;
    tipChoices.forEach((tip, index) => {
      if (tip === value && index !== position) {
        isDuplicate = true;
      }
    });
    return isDuplicate ? TipsError.DUPLICATE : '';
  };

  const firstChoiceError = checkForErrors(tipChoices[0], 0);
  const secondChoiceError = checkForErrors(tipChoices[1], 1);
  const thirdChoiceError = checkForErrors(tipChoices[2], 2);

  const areArraysEqual = (primaryArray, secondArray) => {
    let isEqual = false;
    if (primaryArray.length === secondArray.length) {
      isEqual = primaryArray.every((element, index) => element === secondArray[index]);
    }
    return isEqual;
  };

  const isSaveDisabled = () => {
    return (
      removeEmptyElements(tipChoices).length < 3 ||
      areArraysEqual(tipConfig, tipChoices) ||
      Boolean(firstChoiceError) ||
      Boolean(secondChoiceError) ||
      Boolean(thirdChoiceError)
    );
  };

  return (
    <CustomModal open={true} width="w-[356px] max-w-[356px] md:w-[356px]">
      <div>
        <div className="flex cursor-pointer justify-end p-2.5" onClick={onCancelClick}>
          <CircleCloseIcon />
        </div>
        <div className="p-10 pt-2.5">
          <div className="mb-4 text-xl font-bold text-heading">Edit Tip Choice</div>
          <div className="flex flex-col gap-6">
            <div className="relative">
              <CustomInput
                id="tip-choice-1"
                onChange={tipValue => handleTipsChange(tipValue, 0)}
                value={tipChoices[0]}
                hasSuffix={true}
                suffix={'%'}
                suffixStyle="text-accentText mr-7"
                className={`font-normal ${
                  firstChoiceError ? 'border border-error outline-none focus:outline-none' : ''
                }`}
                placeholder="First choice"
              />
              {firstChoiceError && <div className="absolute text-xs text-error">{firstChoiceError}</div>}
            </div>

            <div className="relative">
              <CustomInput
                id="tip-choice-2"
                onChange={tipValue => handleTipsChange(tipValue, 1)}
                value={tipChoices[1]}
                suffix={'%'}
                hasSuffix={true}
                suffixStyle="text-accentText mr-7"
                className={`font-normal ${
                  secondChoiceError ? 'border border-error outline-none focus:outline-none' : ''
                }`}
                placeholder="Second choice"
              />
              {secondChoiceError && <div className="absolute text-xs text-error">{secondChoiceError}</div>}
            </div>

            <div className="relative">
              <CustomInput
                id="tip-choice-3"
                onChange={tipValue => handleTipsChange(tipValue, 2)}
                value={tipChoices[2]}
                suffix={'%'}
                hasSuffix={true}
                suffixStyle="text-accentText mr-7"
                className={`font-normal ${
                  thirdChoiceError ? 'border border-error outline-none focus:outline-none' : ''
                }`}
                placeholder="Third choice"
              />
              {thirdChoiceError && <div className="absolute text-xs text-error">{thirdChoiceError}</div>}
            </div>
          </div>
          <div className="mt-5 ml-14 flex justify-end gap-5">
            <CustomButton
              id="edit-tip-cancel"
              type={ButtonType.SECONDARY}
              className="w-[100px] px-5"
              onClick={onCancelClick}>
              Cancel
            </CustomButton>
            <CustomButton
              id="edit-tip-save"
              type={ButtonType.PRIMARY}
              className="w-[100px] px-5"
              onClick={handleSaveTips}
              isLoading={isSaveInProgress}
              disabled={isSaveDisabled()}>
              Save
            </CustomButton>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default EditTipsModal;
