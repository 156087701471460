import { FC, useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks';
import { CustomButton } from 'components';
import { OpenNewTabIcon, PlusIconBlue, PlusIconWhite } from 'assets/icons';
import Divider from '@material-ui/core/Divider';
import {
  selectCategoryLineItemsCount,
  selectCategoryState,
  selectInitializingCategorySliceInProgress,
  updateDeselectedItems,
  updateSelectedItems
} from '../categorySlice';
import PaginatedListSearchModal from 'containers/product-catalog/components/PaginatedListSearchModal';
import { IItemBasic } from 'containers/product-catalog/items/items.model';
// eslint-disable-next-line max-len
import { initialQueryParamsState } from 'containers/product-catalog/taxes/hooks/usePaginatedFetchItemsBasicQuery';
import routesPath from 'routes/RoutesPath';
import { useNavigate } from 'react-router-dom';
import { ItemsRouteState } from 'routes/types';
import { resetItemSlice } from 'containers/product-catalog/items/itemsSlice';
import { useLazyGetCatalogFetchItemsQuery } from 'containers/product-catalog/items/api';
import { selectCurrentTeam, selectCurrentTeamFeePermissions } from 'containers/app/appSlice';
import { debounce } from 'lodash';
import EmptySearchResultState from 'containers/product-catalog/components/EmptySearchResultState';
import { sortSelectedItemsToStart } from 'containers/product-catalog/items/utils';
import ItemsTabReactIcon from 'assets/react-icons/ItemsTabReactIcon';
import { CATALOG_FAQ_LINK } from 'config/index';
import { openInNewTab } from 'utils/commonUtils';
import { PaginatedListItemNames } from 'containers/product-catalog/constants';

interface ItemsSectionProps {
  className?: string;
}

interface IListItem {
  id: string | number;
  name: string;
}

const ItemsSection: FC<ItemsSectionProps> = () => {
  const dispatch = useAppDispatch();
  const categoryLineItemsCount = useAppSelector(selectCategoryLineItemsCount);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);

  const [showManageItemsModal, setShowManageItemsModal] = useState<boolean>(false);
  const selectedCategoryState = useAppSelector(selectCategoryState);
  const [selectedItemsList, setSelectedItemsList] = useState<Array<IListItem>>([]);
  const [itemSearchTerm, setItemSearchTerm] = useState<string>(null);
  const navigate = useNavigate();
  const isInitializingCategorySliceInProgress = useAppSelector(selectInitializingCategorySliceInProgress);

  const [fetchItemsBasicData, listQueryState] = useLazyGetCatalogFetchItemsQuery();

  const { manageItemsAndCategories } = catalogSettingsPermissions ?? {};

  const {
    data: itemList,
    isSuccess: isItemListLoadingSuccess,
    isError: isItemListLoadingError,
    isFetching: isLoadingItems,
    error: itemsError
  } = listQueryState;

  const initiateSearch = (searchTerm?: string) => {
    setItemSearchTerm(searchTerm);
  };

  const debouncedSearchHandler = useCallback(debounce(initiateSearch, 500), []);

  useEffect(() => {
    if (!isInitializingCategorySliceInProgress) {
      setSelectedItemsList(selectedCategoryState.lineItems);
    }
  }, [isInitializingCategorySliceInProgress, selectedCategoryState.lineItems]);

  useEffect(() => {
    if (findSelectedItems().length >= 0) {
      setSelectedItemsList(findSelectedItems());
    }
  }, [itemList]);

  const findSelectedItems = (): IItemBasic[] => {
    return selectedCategoryState.lineItems;
  };

  const fetchListItems = () => {
    fetchItemsBasicData({
      ...initialQueryParamsState,
      teamId: currentTeam?.id,
      limit: 1000,
      page: 0,
      categoryId: selectedCategoryState.categoryId
    });
  };

  const getListItems = () => {
    if (!isLoadingItems && isItemListLoadingSuccess) {
      const itemsListData = sortSelectedItemsToStart({
        itemsList: itemList.data,
        currentlySelectedItems: findSelectedItems()
      });
      if (itemSearchTerm) {
        return itemsListData.filter(item => item.name.toLowerCase().includes(itemSearchTerm.toLowerCase()));
      }
      return itemsListData;
    }
    return [];
  };

  const isSearchResultEmpty =
    getListItems().length === 0 && !isLoadingItems && itemSearchTerm !== null && itemSearchTerm !== '';

  const isFetchResultEmpty =
    !isLoadingItems && getListItems().length === 0 && !itemSearchTerm && isItemListLoadingSuccess;

  const onCloseItemSelection = (): void => {
    onClearSearch();
    setShowManageItemsModal(false);
  };

  const onClearSearch = (): void => {
    setItemSearchTerm(null);
  };

  const onSaveSelectedItems = ({ selectedItems }: { selectedItems: IItemBasic[] }): void => {
    const unselectedItemIds = itemList?.data
      .filter(item => !selectedItems.some(selectedItem => selectedItem.id === item.id))
      .map(item => item.id);
    dispatch(updateDeselectedItems(unselectedItemIds));
    dispatch(updateSelectedItems(selectedItems));
    onCloseItemSelection();
  };

  const onToggleManageItems = (): void => {
    fetchListItems();
    setShowManageItemsModal(prev => !prev);
  };

  const onCreateItem = (): void => {
    dispatch(resetItemSlice());
    const createItemRouteState: ItemsRouteState = {
      callbackURL: location?.pathname
    };
    navigate(routesPath.ITEMS_CREATE, { replace: false, state: createItemRouteState });
  };

  const renderCreateItemsButton = (): JSX.Element => {
    return (
      <div onClick={onCreateItem} className="flex cursor-pointer items-center">
        <div className="mr-2">
          <PlusIconBlue />
        </div>
        <div className="text-17px font-semibold text-primaryBtn">Create item</div>
      </div>
    );
  };

  const renderEmptyItemState = (): JSX.Element => {
    return (
      <div className="flex h-full flex-col items-center justify-center gap-10">
        <div className="flex flex-col items-center gap-5">
          <ItemsTabReactIcon
            id="items-section-empty-state"
            className="path-fill-current h-16 w-16 shrink-0 text-borderGray"
          />
          <div className="text-center text-sbase font-semibold tracking-[-0.3px] text-primaryText">
            <div className="max-w-[220px]">
              Create Items that team members can add to invoices for a faster checkout.{' '}
              <span className="cursor-pointer text-secondary" onClick={() => openInNewTab(CATALOG_FAQ_LINK)}>
                Learn more <OpenNewTabIcon className="mb-1 inline" />
              </span>
            </div>
          </div>
        </div>
        {manageItemsAndCategories && (
          <CustomButton
            id="select-item-create-item-button"
            onClick={onCreateItem}
            className="h-[38px] text-primaryBtnText"
            StartIcon={PlusIconWhite}
            childrenContainerStyles="mr-1">
            <div className="text-sbase font-semibold">Create item</div>
          </CustomButton>
        )}
      </div>
    );
  };

  return (
    <>
      <div>
        <div className="mt-9 text-sbase font-bold text-headingGray">Items</div>
        <CustomButton
          id="category-manage-items-btn"
          onClick={onToggleManageItems}
          StartIcon={ItemsTabReactIcon}
          startIconId="category-manage-items-btn-start-icon"
          startIconStyle="path-fill-current"
          className="mt-5 h-[38px] border border-secondary 
            bg-transparent text-secondaryBtnText shadow-sm hover:bg-secondaryBtn"
          childrenContainerStyles="items-center">
          Manage items
        </CustomButton>
      </div>
      <div>
        <div className="mt-5 mb-3 text-sbase text-accentText">
          {categoryLineItemsCount} Item{categoryLineItemsCount !== 1 && 's'}
        </div>
        <Divider className="mb-5 w-full bg-borderGray" />
        <div className="flex flex-col gap-5 text-primaryText">
          {selectedItemsList?.map(item => (
            <>
              <div className="flex justify-between" key={item.id}>
                <div className="w-1/2 text-sbase font-semibold text-primaryText">{item.name}</div>
              </div>
              <Divider className="w-full bg-borderGray" />
            </>
          ))}
        </div>
      </div>
      {showManageItemsModal && (
        <PaginatedListSearchModal
          getSelectedItems={findSelectedItems}
          itemType={PaginatedListItemNames.ITEM}
          onClose={onCloseItemSelection}
          onPrimaryBtnClick={onSaveSelectedItems}
          onSecondaryBtnClick={onCloseItemSelection}
          searchInputConfig={{ enable: true, placeholder: 'Search items to add' }}
          title="Manage items"
          open={showManageItemsModal}
          primaryBtnLabel="Done"
          secondaryBtnLabel="Cancel"
          list={getListItems()}
          hasMoreListItems={false}
          isLoadingList={isLoadingItems}
          setIntersectionElement={null}
          handleSearch={debouncedSearchHandler}
          handleClearSearch={onClearSearch}
          renderActionButton={manageItemsAndCategories ? renderCreateItemsButton : null}
          renderEmptyState={renderEmptyItemState}
          isLoadingSuccess={isItemListLoadingSuccess}
          handleReload={fetchListItems}
          apiErrorCode={isItemListLoadingError ? itemsError.code : null}
          isError={isItemListLoadingError}
          isSearchResultEmpty={isSearchResultEmpty}
          renderSearchEmptyState={
            <EmptySearchResultState
              keyword="item"
              onCreateButtonClick={onCreateItem}
              hasCreatePermissions={manageItemsAndCategories}
            />
          }
          isFetchResultEmpty={isFetchResultEmpty}
        />
      )}
    </>
  );
};

export default ItemsSection;
