/* eslint-disable indent */
import { NavTab, navTabKeys } from 'types/baseTypes';

export const getNavSvgHighightStyle = (tab: NavTab) => {
  switch (tab.key) {
    case navTabKeys.receipt:
      return 'path-stroke-current ';

    case navTabKeys.invoices:
      return 'path-stroke-current ';

    case navTabKeys.customers:
      return 'path-stroke-current ';

    case navTabKeys.discounts:
    case navTabKeys.invoiceSettings:
      return 'path-stroke-current circle-stroke-current ';

    case navTabKeys.teamProfile:
      return 'path-stroke-current ';

    case navTabKeys.banking:
      return 'path-stroke-current ';

    case navTabKeys.accounts:
      return 'path-stroke-current ';

    case navTabKeys.catalogServiceCharge:
      return 'path-stroke-current ';

    case navTabKeys.payouts:
    case navTabKeys.payoutsList:
      return 'path-stroke-current ';

    case navTabKeys.payoutsSettings:
      return 'path-stroke-current circle-stroke-current circle';

    case navTabKeys.debitCards:
      return 'path-stroke-current ';

    case navTabKeys.integrations:
      return 'path-stroke-current circle-stroke-current ';

    default:
      return 'path-fill-current rect-stroke-current';
  }
};

export const bgColorSelectorWithASCII = (memberName: string) => {
  let colorCode = 0;
  if (memberName.length !== 0) {
    for (let i = 0; i < memberName.length; i++) colorCode += memberName.charCodeAt(i);
  }
  colorCode = colorCode % 5;
  switch (colorCode) {
    case 0:
      return 'bg-[#ECF1F7]';
    case 1:
      return 'bg-[#A4D7FA]';
    case 2:
      return 'bg-[#EEF5E2]';
    case 3:
      return 'bg-[#F2F0F1]';
    case 4:
      return 'bg-[#F7E2C0]';
    default:
      return 'bg-[#A4D7FA]';
  }
};

export const calculateFontSize = (stringLength: number, maxFontSize: number, minFontSize: number) => {
  const fontSize = maxFontSize - ((stringLength - 1) * (maxFontSize - minFontSize)) / 49;

  return Math.min(Math.max(fontSize, minFontSize), maxFontSize);
};
