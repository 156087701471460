import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TNC } from 'containers/discount-tips-fees/discountTipsFees.model';
import { RootState } from 'store/reducers';
import {
  convertCentsToDollars,
  getPercentageAmount,
  roundToThreeDecimals,
  roundToTwoDecimals
} from 'utils/amountUtils';
import {
  EInvoiceCatalogElementType,
  InvoiceDraftModalData,
  InvoiceGlobalTaxGroups,
  InvoiceValidationError
} from './create-invoice/types';
import { AdditionalTax, Customer, DiscountSettings, IInvoiceServiceCharge, InvoiceResponse } from './invoices.model';
import { DiscountFormData, LineItemAdditionalTax } from './types';
import { initServiceCharge } from './create-invoice/constants';
import { AbstractITax, ITax } from 'containers/product-catalog/taxes/taxes.model';
import {
  calculateInvoiceSubtotalAndNewDiscount,
  calculateLineItemAdditionalTaxInCurrency,
  isSameCatalogLineItem
} from './utils';
import { GlobalServiceChargeTax } from 'containers/product-catalog/catalog.model';
import { FeatureToggleState } from 'containers/discount-tips-fees/constants';

/**
 * Invoices
 *
 * - `Service Charge` : Can be a % or amount ($)
 * let subtotal = 100,  service charge = 10% with (tax 1 (10%), tax 2 (20%))
 * serviceChargeBaseAmount = 10 % of 100  or 10$
 * serviceChargeTaxesAmount = tax1% of serviceChargeBaseAmount + tax2% of serviceChargeBaseAmount
 * serviceChargeTotalAmount = serviceChargeBaseAmount + serviceChargeTaxesAmount
 * final amount = subtotal + serviceChargeTotalAmount
 *
 */

// TODO move exported interfaces outside

export interface InvoiceLineItem {
  catalogueLineItemId?: number;
  id: string; // We assign the new index id to every new line item
  itemDescription: string;
  unitCost: string;
  quantity: string;
  total: number;
  additionalDetails: string;
  taxes?: LineItemAdditionalTax[];
  sku?: string;
}

export interface TaxesAndFeesPayloadState {
  surchargeState: InvoiceSurchargeState;
  discountSettings: DiscountSettings;
}

export interface InvoiceAttachment {
  id: string;
  url: string;
  title: string;
  imagePreview: string;
  description: string;
}

export interface InvoiceSurchargeState {
  enabled: boolean;
  basePoints: string;
  percentage: string;
  percentageWithBps: string;
  valueInCurrency: number;
  valueInCurrencyWithBps: number;
  config: FeatureToggleState;
}

export interface InvoicesState {
  invoiceCustomer: Customer;
  showCustomerNotSelectedError: boolean;
  invoiceLineItems: InvoiceLineItem[];
  lineItemsTotal: number;
  invoiceSubTotal: number;
  lineItemsStateCount: number;
  invoiceAttachmentIdCount: number;
  discountSettings: DiscountSettings;
  discountFormData: DiscountFormData;
  currentSurcharge: InvoiceSurchargeState;
  selectedInvoiceAttachments: InvoiceAttachment[];
  comment: string;
  tipEnabled: boolean;
  invoiceCreatedResponse: InvoiceResponse;
  finalAmount: number; // final amount = subtotal + service fee + service charge + sum of line item level taxes
  invoiceValidationErrors: InvoiceValidationError[];
  tnc: TNC;
  appliedLineItemLevelTaxes: AdditionalTax[];
  selectedServiceCharge: IInvoiceServiceCharge;
  invoiceDraftModalData?: InvoiceDraftModalData;
  isBackFromCreateTaxRoute: boolean;
  globalLineItemTaxes: ITax[];
  globalServiceChargeTaxes: GlobalServiceChargeTax[];
  isSignatureEnabled: boolean;
  isServiceFeeEnabled: boolean;
}

const initialSelectedTNC: TNC = {
  tncId: 0,
  enabled: false,
  tnc: ''
};

const initialSurchargeState: InvoiceSurchargeState = {
  enabled: false,
  basePoints: null,
  percentage: null,
  percentageWithBps: null,
  valueInCurrency: null,
  valueInCurrencyWithBps: null,
  config: null
};

const initialDiscountFormData: DiscountFormData = {
  discountPercent: null,
  discountAmount: null,
  discountInCurrency: null,
  amountAfterDiscount: null,
  isDiscountAmountSelected: false
};

const initialState = {
  invoiceCustomer: null,
  showCustomerNotSelectedError: false,
  invoiceLineItems: [],
  currentSurcharge: initialSurchargeState,
  lineItemsTotal: 0,
  invoiceSubTotal: 0,
  lineItemsStateCount: 0,
  invoiceAttachmentIdCount: 0,
  discountSettings: { enabled: true },
  discountFormData: initialDiscountFormData,
  comment: null,
  selectedInvoiceAttachments: [],
  tipEnabled: true,
  invoiceCreatedResponse: null,
  finalAmount: 0,
  invoiceValidationErrors: [],
  tnc: initialSelectedTNC,
  appliedLineItemLevelTaxes: [],
  selectedServiceCharge: initServiceCharge,
  invoiceDraftModalData: null,
  isBackFromCreateTaxRoute: false,
  globalLineItemTaxes: [],
  globalServiceChargeTaxes: [],
  isSignatureEnabled: false,
  isServiceFeeEnabled: false
} as InvoicesState;

const getLineItemsTotal = (lineItems: InvoiceLineItem[]) => {
  const lineItemsTotal = lineItems?.reduce((accumulator, lineItem) => {
    return accumulator + lineItem.total;
  }, 0);
  return lineItemsTotal;
};

const calculateSurchargeInCurrency = (surchargePercent: string, subTotal: number) => {
  if (subTotal === null) {
    return null;
  }
  const surchargeInCurrency = (+surchargePercent * subTotal) / 100;
  return roundToTwoDecimals(surchargeInCurrency);
};

const calculateSurchargeWithBpsPercentage = (surchargeInCurrencyWithBps: number, subTotal: number) => {
  if (surchargeInCurrencyWithBps === 0) {
    return '0';
  }
  if (subTotal === null) {
    return null;
  }
  const surchargeWithBpsPercent = (surchargeInCurrencyWithBps / subTotal) * 100;
  // TODO check rounding to three decimals
  return roundToThreeDecimals(surchargeWithBpsPercent).toString();
};

const calculateSurchargeInCurrencyWithBps = (surchargeInCurrency: number, basePointsInCents: string) => {
  if (surchargeInCurrency === 0 || surchargeInCurrency === null) {
    return 0;
  }
  return roundToTwoDecimals(surchargeInCurrency + convertCentsToDollars(basePointsInCents));
};

const calculateServiceChargeBaseAmount = ({
  subtotal,
  serviceCharge
}: {
  subtotal: number;
  serviceCharge: IInvoiceServiceCharge;
}): number => {
  let serviceChargeBaseAmount = 0;
  if (+serviceCharge.percentage > 0) {
    serviceChargeBaseAmount = getPercentageAmount(subtotal, serviceCharge.percentage);
  } else if (+serviceCharge.amount > 0) {
    serviceChargeBaseAmount = +serviceCharge.amount;
  }
  return roundToTwoDecimals(serviceChargeBaseAmount);
};

const calculateFinalAmount = ({
  subtotal,
  surcharge = 0,
  // TODO change key to baseAmount
  totalServiceChargeInCurrency = 0,
  summatedLineItemLevelTaxesCurrency = 0
}: {
  subtotal: number;
  surcharge: number;
  totalServiceChargeInCurrency: number;
  summatedLineItemLevelTaxesCurrency?: number;
}) => {
  const final = subtotal + surcharge + totalServiceChargeInCurrency + summatedLineItemLevelTaxesCurrency;
  return roundToTwoDecimals(final);
};

const calculateSummatedLineItemLevelTaxesCurrency = ({
  lineItems,
  selectedServiceCharge = initServiceCharge
}: {
  lineItems: InvoiceLineItem[];
  selectedServiceCharge?: IInvoiceServiceCharge;
}): number => {
  let summatedServiceChargeTaxesCurrency = 0;
  const summatedLineItemLevelTaxesCurrency = lineItems.reduce((sumOfAllItemLevelTaxes, currentLineItem) => {
    const sumOfTaxesAppliedOnSingleLineItem = currentLineItem?.taxes?.reduce(
      (sumOfItemRootLevelTaxes, currentItemLevelTax) => {
        return sumOfItemRootLevelTaxes + currentItemLevelTax?.amount;
      },
      0
    );

    if (sumOfTaxesAppliedOnSingleLineItem) {
      return sumOfAllItemLevelTaxes + sumOfTaxesAppliedOnSingleLineItem;
    }
    return sumOfAllItemLevelTaxes;
  }, 0);

  if (selectedServiceCharge.taxes.length > 0) {
    summatedServiceChargeTaxesCurrency = selectedServiceCharge.taxes.reduce(
      (sumOfAllServiceChargeTaxes, currentTax) => {
        const serviceChargeTaxAmount = getPercentageAmount(selectedServiceCharge.totalAmount, currentTax.percentage);
        return sumOfAllServiceChargeTaxes + serviceChargeTaxAmount;
      },
      0
    );
  }

  return roundToTwoDecimals(summatedLineItemLevelTaxesCurrency + summatedServiceChargeTaxesCurrency) ?? 0;
};

const hasServiceCharge = (serviceCharge: IInvoiceServiceCharge) => {
  return serviceCharge !== initServiceCharge && serviceCharge !== null;
};

const updateServiceChargeWithBaseAmount = ({
  serviceCharge,
  subtotal
}: {
  serviceCharge: IInvoiceServiceCharge;
  subtotal: number;
}): { updatedServiceCharge: IInvoiceServiceCharge; serviceChargeBaseAmount: number } => {
  const serviceChargeBaseAmount = calculateServiceChargeBaseAmount({
    subtotal: subtotal,
    serviceCharge: serviceCharge
  });
  const updatedServiceCharge: IInvoiceServiceCharge = {
    ...serviceCharge,
    totalAmount: serviceChargeBaseAmount.toString()
  };
  return { updatedServiceCharge, serviceChargeBaseAmount };
};

export const invoiceSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    resetInvoiceState: () => initialState,

    updateIsBackFromCreateTaxInInvoiceSlice: (state, { payload }: PayloadAction<boolean>) => {
      state.isBackFromCreateTaxRoute = payload;
    },
    updateInvoiceCustomer: (state, { payload }: PayloadAction<Customer>) => {
      state.invoiceCustomer = payload;
    },

    removeInvoiceCustomer: state => {
      state.invoiceCustomer = null;
    },

    addInvoiceLineItem: (state, { payload }: PayloadAction<InvoiceLineItem>) => {
      const updatedLineItemsList = [
        ...state.invoiceLineItems,
        { ...payload, id: state.lineItemsStateCount.toString(), total: +payload.unitCost * +payload.quantity }
      ];
      state.lineItemsStateCount = state.lineItemsStateCount + 1;
      state.invoiceLineItems = updatedLineItemsList;

      const totalCostOfLineItems = getLineItemsTotal(updatedLineItemsList);
      state.lineItemsTotal = totalCostOfLineItems;
      const { subtotal, discountData: newDiscountData } = calculateInvoiceSubtotalAndNewDiscount({
        totalCostOfItems: totalCostOfLineItems,
        discountData: state.discountFormData
      }) ?? { subtotal: totalCostOfLineItems, discountData: state.discountFormData };

      // Update subtotal state
      state.invoiceSubTotal = subtotal;
      // update discount data
      state.discountFormData = newDiscountData;

      // Update surcharge state

      let surchargeInCurrencyWithBps = 0;
      if (state.isServiceFeeEnabled) {
        const surchargeInCurrency: number = calculateSurchargeInCurrency(state.currentSurcharge.percentage, subtotal);
        surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
          surchargeInCurrency,
          state.currentSurcharge.basePoints
        );

        const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(surchargeInCurrencyWithBps, subtotal);

        state.currentSurcharge = {
          percentage: state.currentSurcharge.percentage,
          valueInCurrency: surchargeInCurrency,
          percentageWithBps: surchargeWithBpsPercentage,
          valueInCurrencyWithBps: surchargeInCurrencyWithBps,
          enabled: state.currentSurcharge.enabled,
          basePoints: state.currentSurcharge.basePoints,
          config: state.currentSurcharge.config
        };
      }

      // update applied line item taxes array
      const itemLevelTaxesInPayload = payload?.taxes ?? [];
      let modifiedAppliedLineItemLevelTaxes: AdditionalTax[] = state.appliedLineItemLevelTaxes;
      if (itemLevelTaxesInPayload.length > 0) {
        itemLevelTaxesInPayload.forEach(itemLevelTaxInPayload => {
          const isLineItemTaxAlreadySelected = modifiedAppliedLineItemLevelTaxes.find(
            tax => tax.id === itemLevelTaxInPayload.id
          );

          if (!isLineItemTaxAlreadySelected) {
            modifiedAppliedLineItemLevelTaxes = [
              ...modifiedAppliedLineItemLevelTaxes,
              itemLevelTaxInPayload as AdditionalTax
            ];
          }
        });
      }
      state.appliedLineItemLevelTaxes = modifiedAppliedLineItemLevelTaxes;

      // calculate service charge base amount
      let updatedServiceCharge = state.selectedServiceCharge;
      let serviceChargeBaseAmount = 0;
      if (hasServiceCharge(updatedServiceCharge)) {
        const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
          updateServiceChargeWithBaseAmount({
            serviceCharge: updatedServiceCharge,
            subtotal: subtotal
          });
        serviceChargeBaseAmount = baseAmount;
        updatedServiceCharge = updatedServiceChargeWithBaseAmount;
        state.selectedServiceCharge = updatedServiceCharge;
      }

      // Update final amount
      const surchargeInCurrencyBpsValue = state.currentSurcharge.enabled ? surchargeInCurrencyWithBps : 0;

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: updatedLineItemsList,
        selectedServiceCharge: updatedServiceCharge
      });

      const finalAmount = calculateFinalAmount({
        subtotal,
        surcharge: surchargeInCurrencyBpsValue,
        totalServiceChargeInCurrency: serviceChargeBaseAmount,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;

      if (state.invoiceLineItems.length === 0) {
        state.discountFormData = initialDiscountFormData;
      }
    },
    modifyLineItem: (state, { payload }: PayloadAction<InvoiceLineItem>) => {
      let lineItemToUpdate: InvoiceLineItem = null;
      const updatedLineItemsList = state.invoiceLineItems.map(lineItem => {
        if (lineItem.id === payload.id) {
          lineItemToUpdate = lineItem;
          return {
            ...lineItem,
            itemDescription: payload.itemDescription,
            unitCost: payload.unitCost,
            quantity: payload.quantity,
            total: +payload.unitCost * +payload.quantity,
            additionalDetails: payload.additionalDetails,
            ...(payload?.taxes ? { taxes: payload.taxes } : { taxes: [] })
          };
        }
        return lineItem;
      });

      state.invoiceLineItems = updatedLineItemsList;

      const totalCostOfLineItems = getLineItemsTotal(updatedLineItemsList);
      state.lineItemsTotal = totalCostOfLineItems;

      const { subtotal, discountData: newDiscountData } = calculateInvoiceSubtotalAndNewDiscount({
        totalCostOfItems: totalCostOfLineItems,
        discountData: state.discountFormData
      }) ?? { subtotal: totalCostOfLineItems, discountData: state.discountFormData };
      // Update subtotal state
      state.invoiceSubTotal = subtotal;
      //  Update discount data
      state.discountFormData = newDiscountData;

      // Update surcharge state
      let surchargeInCurrencyWithBps = 0;
      if (state.isServiceFeeEnabled) {
        const surchargeInCurrency: number = calculateSurchargeInCurrency(state.currentSurcharge.percentage, subtotal);

        surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
          surchargeInCurrency,
          state.currentSurcharge.basePoints
        );

        const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(surchargeInCurrencyWithBps, subtotal);
        state.currentSurcharge = {
          percentageWithBps: surchargeWithBpsPercentage,
          valueInCurrencyWithBps: surchargeInCurrencyWithBps,
          percentage: state.currentSurcharge.percentage,
          valueInCurrency: surchargeInCurrency,
          config: state.currentSurcharge.config,
          enabled: state.currentSurcharge.enabled,
          basePoints: state.currentSurcharge.basePoints
        };
      }

      // calculate service charge base amount
      let updatedServiceCharge = state.selectedServiceCharge;
      let serviceChargeBaseAmount = 0;
      if (hasServiceCharge(updatedServiceCharge)) {
        const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
          updateServiceChargeWithBaseAmount({
            serviceCharge: updatedServiceCharge,
            subtotal: subtotal
          });
        serviceChargeBaseAmount = baseAmount;
        updatedServiceCharge = updatedServiceChargeWithBaseAmount;
        state.selectedServiceCharge = updatedServiceCharge;
      }

      // update applied line item taxes array - currently selected line item level taxes
      const itemLevelTaxesInPayload = payload?.taxes ?? [];
      const taxesInItemBeforeUpdate = lineItemToUpdate?.taxes ?? [];

      if (itemLevelTaxesInPayload.length > 0) {
        itemLevelTaxesInPayload.forEach(itemLevelTaxInPayload => {
          const isLineItemTaxAlreadySelected = state.appliedLineItemLevelTaxes.find(
            tax => tax.id === itemLevelTaxInPayload.id
          );
          if (!isLineItemTaxAlreadySelected) {
            state.appliedLineItemLevelTaxes = [
              ...state.appliedLineItemLevelTaxes,
              itemLevelTaxInPayload as AdditionalTax
            ];
          }
        });
      }

      //    remove the current line item's item level tax from applied item level taxes array
      //    if this tax is not applied on any other line items

      if (taxesInItemBeforeUpdate.length > 0) {
        taxesInItemBeforeUpdate.forEach(taxInItemBeforeUpdate => {
          const isTaxAppliedOnOtherItem = updatedLineItemsList.find(lineItem => {
            return lineItem?.taxes?.find(lineItemTax => lineItemTax.id === taxInItemBeforeUpdate.id);
          });
          const isTaxAppliedOnServiceCharge = state.selectedServiceCharge?.taxes?.find(
            tax => tax.id === taxInItemBeforeUpdate.id
          );
          if (!isTaxAppliedOnOtherItem && !isTaxAppliedOnServiceCharge) {
            state.appliedLineItemLevelTaxes = state.appliedLineItemLevelTaxes.filter(
              tax => tax.id !== taxInItemBeforeUpdate.id
            );
          }
        });
      }

      // Update final amount
      const surchargeInCurrencyBpsValue = state.currentSurcharge.enabled ? surchargeInCurrencyWithBps : 0;

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: updatedLineItemsList,
        selectedServiceCharge: updatedServiceCharge
      });

      const finalAmount = calculateFinalAmount({
        subtotal,
        surcharge: surchargeInCurrencyBpsValue,
        totalServiceChargeInCurrency: serviceChargeBaseAmount,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    },

    incrementCatalogLineItemQty: (state, { payload }: PayloadAction<InvoiceLineItem>) => {
      const updatedLineItemsList = state.invoiceLineItems.map(lineItem => {
        if (isSameCatalogLineItem({ primaryItem: payload, secondaryItem: lineItem })) {
          const newQty = (+lineItem.quantity + 1).toString();
          const totalLineItemCost = +newQty * +lineItem.unitCost;
          let lineItemAdditionalTaxesWithAmount: LineItemAdditionalTax[] = null;
          const lineItemTaxes: LineItemAdditionalTax[] = lineItem?.taxes ?? [];
          if (lineItemTaxes.length > 0) {
            lineItemAdditionalTaxesWithAmount = lineItemTaxes.map(tax => {
              return {
                ...tax,
                amount: calculateLineItemAdditionalTaxInCurrency(tax.percentage, totalLineItemCost)
              };
            });
          }

          return {
            ...lineItem,
            quantity: newQty,
            total: totalLineItemCost,
            ...(lineItemAdditionalTaxesWithAmount ? { taxes: lineItemAdditionalTaxesWithAmount } : {})
          };
        }
        return lineItem;
      });

      state.invoiceLineItems = updatedLineItemsList;

      const totalCostOfLineItems = getLineItemsTotal(updatedLineItemsList);
      state.lineItemsTotal = totalCostOfLineItems;

      const { subtotal, discountData: newDiscountData } = calculateInvoiceSubtotalAndNewDiscount({
        totalCostOfItems: totalCostOfLineItems,
        discountData: state.discountFormData
      }) ?? { subtotal: totalCostOfLineItems, discountData: state.discountFormData };
      // Update subtotal state
      state.invoiceSubTotal = subtotal;
      //  Update discount data
      state.discountFormData = newDiscountData;

      // Update surcharge state
      let surchargeInCurrencyWithBps = 0;
      if (state.isServiceFeeEnabled) {
        const surchargeInCurrency: number = calculateSurchargeInCurrency(state.currentSurcharge.percentage, subtotal);

        surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
          surchargeInCurrency,
          state.currentSurcharge.basePoints
        );

        const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(surchargeInCurrencyWithBps, subtotal);
        state.currentSurcharge = {
          percentageWithBps: surchargeWithBpsPercentage,
          valueInCurrencyWithBps: surchargeInCurrencyWithBps,
          percentage: state.currentSurcharge.percentage,
          valueInCurrency: surchargeInCurrency,
          config: state.currentSurcharge.config,
          enabled: state.currentSurcharge.enabled,
          basePoints: state.currentSurcharge.basePoints
        };
      }

      // calculate service charge base amount
      let updatedServiceCharge = state.selectedServiceCharge;
      let serviceChargeBaseAmount = 0;
      if (hasServiceCharge(updatedServiceCharge)) {
        const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
          updateServiceChargeWithBaseAmount({
            serviceCharge: updatedServiceCharge,
            subtotal: subtotal
          });
        serviceChargeBaseAmount = baseAmount;
        updatedServiceCharge = updatedServiceChargeWithBaseAmount;
        state.selectedServiceCharge = updatedServiceCharge;
      }

      // Update final amount
      const surchargeInCurrencyBpsValue = state.currentSurcharge.enabled ? surchargeInCurrencyWithBps : 0;

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: updatedLineItemsList,
        selectedServiceCharge: updatedServiceCharge
      });

      const finalAmount = calculateFinalAmount({
        subtotal,
        surcharge: surchargeInCurrencyBpsValue,
        totalServiceChargeInCurrency: serviceChargeBaseAmount,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    },

    removeInvoiceLineItem: (state, { payload }: PayloadAction<InvoiceLineItem>) => {
      const updatedLineItemsList = state.invoiceLineItems.filter(lineItem => lineItem.id !== payload.id);

      if (updatedLineItemsList.length > 0) {
        state.invoiceLineItems = updatedLineItemsList;
        const totalCostOfLineItems = getLineItemsTotal(updatedLineItemsList);
        state.lineItemsTotal = totalCostOfLineItems;

        const { subtotal, discountData: newDiscountData } = calculateInvoiceSubtotalAndNewDiscount({
          totalCostOfItems: totalCostOfLineItems,
          discountData: state.discountFormData
        }) ?? { subtotal: totalCostOfLineItems, discountData: state.discountFormData };
        // Update subtotal state
        state.invoiceSubTotal = subtotal;
        //  Update discount data
        state.discountFormData = newDiscountData;

        // Update surcharge state
        let surchargeInCurrencyWithBps = 0;
        if (state.isServiceFeeEnabled) {
          const surchargeInCurrency: number = calculateSurchargeInCurrency(state.currentSurcharge.percentage, subtotal);
          surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
            surchargeInCurrency,
            state.currentSurcharge.basePoints
          );

          const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(surchargeInCurrencyWithBps, subtotal);

          state.currentSurcharge = {
            percentageWithBps: surchargeWithBpsPercentage,
            valueInCurrencyWithBps: surchargeInCurrencyWithBps,
            percentage: state.currentSurcharge.percentage,
            valueInCurrency: surchargeInCurrency,
            config: state.currentSurcharge.config,
            enabled: state.currentSurcharge.enabled,
            basePoints: state.currentSurcharge.basePoints
          };
        }
        // calculate service charge base amount
        let updatedServiceCharge = state.selectedServiceCharge;
        let serviceChargeBaseAmount = 0;
        if (hasServiceCharge(updatedServiceCharge)) {
          const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
            updateServiceChargeWithBaseAmount({
              serviceCharge: updatedServiceCharge,
              subtotal: subtotal
            });
          serviceChargeBaseAmount = baseAmount;
          updatedServiceCharge = updatedServiceChargeWithBaseAmount;
          state.selectedServiceCharge = updatedServiceCharge;
        }

        // update applied line item taxes array
        const singleItemLevelTaxesToRemove: LineItemAdditionalTax[] = payload?.taxes ?? [];
        if (singleItemLevelTaxesToRemove.length > 0) {
          singleItemLevelTaxesToRemove.forEach(itemLevelTaxOnCurrentItem => {
            const isTaxToRemoveAppliedOnOtherItem = updatedLineItemsList.find(lineItem => {
              return lineItem?.taxes?.find(tax => tax?.id === itemLevelTaxOnCurrentItem?.id);
            });

            const isTaxAppliedOnServiceCharge = state.selectedServiceCharge?.taxes?.find(
              tax => tax.id === itemLevelTaxOnCurrentItem.id
            );

            if (!isTaxToRemoveAppliedOnOtherItem && !isTaxAppliedOnServiceCharge) {
              state.appliedLineItemLevelTaxes = state.appliedLineItemLevelTaxes.filter(
                tax => tax.id !== itemLevelTaxOnCurrentItem.id
              );
            }
          });
        }

        // Update final amount

        const surchargeInCurrencyBpsValue = state.currentSurcharge.enabled ? surchargeInCurrencyWithBps : 0;

        // update sum of line item level taxes
        const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
          lineItems: updatedLineItemsList,
          selectedServiceCharge: updatedServiceCharge
        });

        const finalAmount = calculateFinalAmount({
          subtotal,
          totalServiceChargeInCurrency: serviceChargeBaseAmount,
          surcharge: surchargeInCurrencyBpsValue,
          summatedLineItemLevelTaxesCurrency
        });
        state.finalAmount = finalAmount;
      } else {
        state.invoiceLineItems = [];
        state.lineItemsTotal = null;
        state.invoiceSubTotal = null;
        state.currentSurcharge = {
          ...state.currentSurcharge,
          percentageWithBps: null,
          valueInCurrency: null,
          valueInCurrencyWithBps: null
        };
        state.selectedServiceCharge = initServiceCharge;
        state.appliedLineItemLevelTaxes = [];
        state.discountFormData = initialDiscountFormData;
        state.finalAmount = 0;
      }
    },

    updateSelectedDiscountFormData: (state, { payload }: PayloadAction<DiscountFormData>) => {
      state.discountFormData = payload;

      const subtotal = state.lineItemsTotal - +payload.discountInCurrency;

      // Update subtotal state
      state.invoiceSubTotal = subtotal;

      // Update surcharge state
      let surchargeInCurrencyWithBps = 0;
      if (state.isServiceFeeEnabled) {
        const surchargeInCurrency: number = calculateSurchargeInCurrency(state.currentSurcharge.percentage, subtotal);

        surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
          surchargeInCurrency,
          state.currentSurcharge.basePoints
        );
        const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(surchargeInCurrencyWithBps, subtotal);

        state.currentSurcharge = {
          percentageWithBps: surchargeWithBpsPercentage,
          valueInCurrencyWithBps: surchargeInCurrencyWithBps,
          percentage: state.currentSurcharge.percentage,
          valueInCurrency: surchargeInCurrency,
          config: state.currentSurcharge.config,
          enabled: state.currentSurcharge.enabled,
          basePoints: state.currentSurcharge.basePoints
        };
      }

      // calculate service charge base amount
      let updatedServiceCharge = state.selectedServiceCharge;
      let serviceChargeBaseAmount = 0;
      if (hasServiceCharge(updatedServiceCharge)) {
        const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
          updateServiceChargeWithBaseAmount({
            serviceCharge: updatedServiceCharge,
            subtotal: subtotal
          });
        serviceChargeBaseAmount = baseAmount;
        updatedServiceCharge = updatedServiceChargeWithBaseAmount;
        state.selectedServiceCharge = updatedServiceCharge;
      }

      // Update final amount
      const surchargeInCurrencyBpsValue = state.currentSurcharge.enabled ? surchargeInCurrencyWithBps : 0;

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: state.invoiceLineItems,
        selectedServiceCharge: updatedServiceCharge
      });

      const finalAmount = calculateFinalAmount({
        subtotal,
        totalServiceChargeInCurrency: serviceChargeBaseAmount,
        surcharge: surchargeInCurrencyBpsValue,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    },

    removeSelectedDiscountFormData: state => {
      state.discountFormData = initialDiscountFormData;

      const subtotal = state.lineItemsTotal;

      // Update subtotal state
      state.invoiceSubTotal = subtotal;

      // Update surcharge state - re-calculate surcharge in currency
      let surchargeInCurrencyWithBps = 0;
      if (state.isServiceFeeEnabled) {
        const surchargeInCurrency: number = calculateSurchargeInCurrency(state.currentSurcharge.percentage, subtotal);

        surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
          surchargeInCurrency,
          state.currentSurcharge.basePoints
        );
        const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(surchargeInCurrencyWithBps, subtotal);
        state.currentSurcharge = {
          percentageWithBps: surchargeWithBpsPercentage,
          valueInCurrencyWithBps: surchargeInCurrencyWithBps,
          percentage: state.currentSurcharge.percentage,
          valueInCurrency: surchargeInCurrency,
          config: state.currentSurcharge.config,
          enabled: state.currentSurcharge.enabled,
          basePoints: state.currentSurcharge.basePoints
        };
      }
      // calculate service charge base amount
      let updatedServiceCharge = state.selectedServiceCharge;
      let serviceChargeBaseAmount = 0;
      if (hasServiceCharge(updatedServiceCharge)) {
        const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
          updateServiceChargeWithBaseAmount({
            serviceCharge: updatedServiceCharge,
            subtotal: subtotal
          });
        serviceChargeBaseAmount = baseAmount;
        updatedServiceCharge = updatedServiceChargeWithBaseAmount;
        state.selectedServiceCharge = updatedServiceCharge;
      }

      // Update final amount
      const surchargeInCurrencyBpsValue = state.currentSurcharge.enabled ? surchargeInCurrencyWithBps : 0;

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: state.invoiceLineItems,
        selectedServiceCharge: updatedServiceCharge
      });

      const finalAmount = calculateFinalAmount({
        subtotal,
        surcharge: surchargeInCurrencyBpsValue,
        totalServiceChargeInCurrency: serviceChargeBaseAmount,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    },

    // This action is dispatched after the taxes and fees api call
    updateInvoiceFeeSettings: (state, { payload }: PayloadAction<TaxesAndFeesPayloadState>) => {
      if (payload.surchargeState.config === FeatureToggleState.OFF_BY_DEFAULT || !payload.surchargeState.enabled) {
        state.isServiceFeeEnabled = false;
      } else state.isServiceFeeEnabled = true;

      const surchargeInCurrency: number = calculateSurchargeInCurrency(
        payload.surchargeState.percentage,
        state.invoiceSubTotal
      );

      const surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
        surchargeInCurrency,
        state.currentSurcharge.basePoints
      );

      const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(
        surchargeInCurrencyWithBps,
        state.invoiceSubTotal
      );

      state.currentSurcharge = {
        percentageWithBps: surchargeWithBpsPercentage,
        valueInCurrencyWithBps: surchargeInCurrencyWithBps,
        percentage: payload.surchargeState.percentage,
        valueInCurrency: surchargeInCurrency,
        config: payload.surchargeState.config,
        enabled: payload.surchargeState.enabled,
        basePoints: payload.surchargeState.basePoints
      };

      state.discountSettings = payload.discountSettings;

      // calculate service charge base amount
      let updatedServiceCharge = state.selectedServiceCharge;
      let serviceChargeBaseAmount = 0;
      if (hasServiceCharge(updatedServiceCharge)) {
        const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
          updateServiceChargeWithBaseAmount({
            serviceCharge: updatedServiceCharge,
            subtotal: state.invoiceSubTotal
          });
        serviceChargeBaseAmount = baseAmount;
        updatedServiceCharge = updatedServiceChargeWithBaseAmount;
        state.selectedServiceCharge = updatedServiceCharge;
      }

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: state.invoiceLineItems,
        selectedServiceCharge: updatedServiceCharge
      });

      const finalAmount = calculateFinalAmount({
        subtotal: state.invoiceSubTotal,
        surcharge: surchargeInCurrencyWithBps,
        totalServiceChargeInCurrency: serviceChargeBaseAmount,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    },

    // todo: modify final amount calc
    updateSelectedServiceCharge: (state, { payload }: PayloadAction<IInvoiceServiceCharge>) => {
      const currentServiceCharge: IInvoiceServiceCharge = { ...state.selectedServiceCharge };
      const newServiceCharge = payload;
      state.selectedServiceCharge = newServiceCharge;

      // Update final amount

      // calculate service charge base amount
      let updatedServiceCharge = newServiceCharge;
      let serviceChargeBaseAmount = 0;
      if (hasServiceCharge(updatedServiceCharge)) {
        const { updatedServiceCharge: updatedServiceChargeWithBaseAmount, serviceChargeBaseAmount: baseAmount } =
          updateServiceChargeWithBaseAmount({
            serviceCharge: updatedServiceCharge,
            subtotal: state.invoiceSubTotal
          });
        serviceChargeBaseAmount = baseAmount;
        updatedServiceCharge = updatedServiceChargeWithBaseAmount;
        state.selectedServiceCharge = updatedServiceCharge;
      }

      const taxesInNewServiceCharge: AbstractITax[] = newServiceCharge?.taxes ?? [];
      const taxesInCurrentServiceCharge: AbstractITax[] = currentServiceCharge?.taxes ?? [];

      //  add taxes which are in the new service charge if they aren't already present in any of the line items
      if (
        (currentServiceCharge === initServiceCharge ||
          currentServiceCharge === null ||
          taxesInCurrentServiceCharge.length === 0) &&
        taxesInNewServiceCharge.length > 0
      ) {
        taxesInNewServiceCharge.forEach(serviceChargeTax => {
          const isLineItemTaxAlreadySelected = state.appliedLineItemLevelTaxes.find(
            tax => tax.id === serviceChargeTax.id
          );
          if (!isLineItemTaxAlreadySelected) {
            state.appliedLineItemLevelTaxes = [...state.appliedLineItemLevelTaxes, serviceChargeTax as AdditionalTax];
          }
        });
      } else {
        if (taxesInCurrentServiceCharge.length > 0 && taxesInNewServiceCharge.length > 0) {
          // Remove the taxes which were added because of the current service charge
          let modifiedAppliedLineItemLevelTaxes: AdditionalTax[] = [...state.appliedLineItemLevelTaxes];
          taxesInCurrentServiceCharge.forEach(serviceChargeTax => {
            const isTaxAppliedOnAnyLineItem = state.invoiceLineItems.find(lineItem => {
              return lineItem?.taxes?.find(tax => tax.id === serviceChargeTax.id);
            });
            if (!isTaxAppliedOnAnyLineItem) {
              modifiedAppliedLineItemLevelTaxes = modifiedAppliedLineItemLevelTaxes.filter(
                tax => tax.id !== serviceChargeTax.id
              );
            }
          });

          //  add the taxes which are in the new service charge
          taxesInNewServiceCharge.forEach(serviceChargeTax => {
            const isLineItemTaxAlreadySelected = modifiedAppliedLineItemLevelTaxes.find(
              tax => tax.id === serviceChargeTax.id
            );
            if (!isLineItemTaxAlreadySelected) {
              modifiedAppliedLineItemLevelTaxes = [
                ...modifiedAppliedLineItemLevelTaxes,
                serviceChargeTax as AdditionalTax
              ];
            }
          });
          state.appliedLineItemLevelTaxes = modifiedAppliedLineItemLevelTaxes;
        } else if (taxesInCurrentServiceCharge.length > 0 && taxesInNewServiceCharge.length === 0) {
          // Remove the taxes which were added because of the current service charge
          let modifiedAppliedLineItemLevelTaxes: AdditionalTax[] = [...state.appliedLineItemLevelTaxes];
          taxesInCurrentServiceCharge.forEach(serviceChargeTax => {
            const isTaxAppliedOnAnyLineItem = state.invoiceLineItems.find(lineItem => {
              return lineItem?.taxes?.find(tax => tax.id === serviceChargeTax.id);
            });
            if (!isTaxAppliedOnAnyLineItem) {
              modifiedAppliedLineItemLevelTaxes = modifiedAppliedLineItemLevelTaxes.filter(
                tax => tax.id !== serviceChargeTax.id
              );
            }
          });
          state.appliedLineItemLevelTaxes = modifiedAppliedLineItemLevelTaxes;
        }
      }

      const surchargeInCurrencyBpsValue = state.isServiceFeeEnabled ? state.currentSurcharge.valueInCurrencyWithBps : 0;

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: state.invoiceLineItems,
        selectedServiceCharge: updatedServiceCharge
      });

      const finalAmount = calculateFinalAmount({
        subtotal: state.invoiceSubTotal,
        surcharge: surchargeInCurrencyBpsValue,
        totalServiceChargeInCurrency: serviceChargeBaseAmount,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    },

    removeSelectedServiceCharge: state => {
      const currentSelectedServiceCharge = { ...state.selectedServiceCharge };
      state.selectedServiceCharge = initServiceCharge;

      // Update final amount
      const surchargeInCurrencyBpsValue = state.isServiceFeeEnabled ? state.currentSurcharge.valueInCurrencyWithBps : 0;

      // update applied line item taxes array
      const serviceChargeTaxesToRemove: LineItemAdditionalTax[] = currentSelectedServiceCharge?.taxes ?? [];
      let modifiedAppliedLineItemLevelTaxes: AdditionalTax[] = [...state.appliedLineItemLevelTaxes];
      if (serviceChargeTaxesToRemove.length > 0) {
        serviceChargeTaxesToRemove.forEach(taxInCurrentServiceCharge => {
          const isTaxToRemoveAppliedOnAnyLineItem: InvoiceLineItem = state.invoiceLineItems.find(lineItem => {
            return lineItem?.taxes?.find(tax => tax?.id === taxInCurrentServiceCharge?.id);
          });
          if (!isTaxToRemoveAppliedOnAnyLineItem) {
            modifiedAppliedLineItemLevelTaxes = modifiedAppliedLineItemLevelTaxes.filter(
              tax => tax.id !== taxInCurrentServiceCharge.id
            );
          }
        });
        state.appliedLineItemLevelTaxes = modifiedAppliedLineItemLevelTaxes;
      }

      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: state.invoiceLineItems
      });

      const finalAmount = calculateFinalAmount({
        subtotal: state.invoiceSubTotal,
        surcharge: surchargeInCurrencyBpsValue,
        totalServiceChargeInCurrency: 0,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    },

    updateInvoiceNotes: (state, { payload }: PayloadAction<string>) => {
      state.comment = payload;
    },

    addInvoiceAttachment: (state, { payload }: PayloadAction<InvoiceAttachment>) => {
      const updatedAttachmentList = [
        ...state.selectedInvoiceAttachments,
        { ...payload, id: state.invoiceAttachmentIdCount.toString() }
      ];
      state.invoiceAttachmentIdCount = state.invoiceAttachmentIdCount + 1;
      state.selectedInvoiceAttachments = updatedAttachmentList;
    },

    removeInvoiceAttachment: (state, { payload }: PayloadAction<InvoiceAttachment>) => {
      const updatedAttachmentList = state.selectedInvoiceAttachments.filter(attachment => {
        return attachment.url !== payload.url;
      });
      state.selectedInvoiceAttachments = updatedAttachmentList;
    },

    modifyInvoiceAttachment: (state, { payload }: PayloadAction<InvoiceAttachment>) => {
      const updatedAttachmentList = state.selectedInvoiceAttachments.map(attachment => {
        if (attachment.id === payload.id) {
          return {
            ...attachment,
            url: payload.url,
            title: payload.title,
            imagePreview: payload.imagePreview,
            description: payload.description
          };
        }
        return attachment;
      });
      state.selectedInvoiceAttachments = updatedAttachmentList;
    },

    toggleTipEnabled: state => {
      state.tipEnabled = !state.tipEnabled;
    },

    updateTipEnabled: (state, { payload }: PayloadAction<boolean>) => {
      state.tipEnabled = payload;
    },

    updateInvoiceCreatedResponse: (state, { payload }: PayloadAction<InvoiceResponse>) => {
      state.invoiceCreatedResponse = payload;
      //TODO: Add data resetting logics for create response
    },

    addInvoiceValidationError: (state, { payload }: PayloadAction<InvoiceValidationError>) => {
      state.invoiceValidationErrors = [...state.invoiceValidationErrors, payload];
    },

    removeInvoiceValidationError: (state, { payload }: PayloadAction<InvoiceValidationError>) => {
      state.invoiceValidationErrors = state.invoiceValidationErrors.filter(error => error.type !== payload.type);
    },

    resetInvoiceValidationErrors: state => {
      state.invoiceValidationErrors = [];
    },
    enableCustomerNotSelectedError: state => {
      state.showCustomerNotSelectedError = true;
    },

    disableCustomerNotSelectedError: state => {
      state.showCustomerNotSelectedError = false;
    },

    updateInvoiceDraftData: (state, { payload }: PayloadAction<InvoiceDraftModalData>) => {
      state.invoiceDraftModalData = payload;
    },

    resetInvoiceDraftData: state => {
      state.invoiceDraftModalData = null;
    },
    addCreatedTaxInInvoiceDraft: (state, { payload }: PayloadAction<AbstractITax>) => {
      if (state?.invoiceDraftModalData?.modalConfig) {
        const currentModalConfig = state?.invoiceDraftModalData?.modalConfig ?? {};
        const currentModalConfigData = currentModalConfig?.data ?? {};
        if (state.invoiceDraftModalData.invoiceCatalogElementType === EInvoiceCatalogElementType.ITEM) {
          const currentlySelectedTaxes = currentModalConfigData?.selectedTaxes ?? [];
          state.invoiceDraftModalData = {
            ...state.invoiceDraftModalData,
            modalConfig: {
              ...currentModalConfig,
              data: { ...currentModalConfigData, selectedTaxes: [...currentlySelectedTaxes, payload] }
            }
          };
        } else {
          // for service charge draft
          const currentlySelectedTaxes = currentModalConfigData?.taxes ?? [];
          state.invoiceDraftModalData = {
            ...state.invoiceDraftModalData,
            modalConfig: {
              ...currentModalConfig,
              data: { ...currentModalConfigData, taxes: [...currentlySelectedTaxes, payload] }
            }
          };
        }
      }
    },
    addGlobalTaxesInInvoiceSlice: (
      state,
      { payload = { globalLineItemTaxes: [], globalServiceChargeTaxes: [] } }: PayloadAction<InvoiceGlobalTaxGroups>
    ) => {
      state.globalLineItemTaxes = payload.globalLineItemTaxes;
      state.globalServiceChargeTaxes = payload.globalServiceChargeTaxes;
    },

    updateLineItemsOrderInInvoiceSlice: (state, { payload }) => {
      state.invoiceLineItems = payload;
    },

    updateIsSignatureEnabled: (state, { payload }) => {
      state.isSignatureEnabled = payload;
    },
    updateIsServiceFeeEnabled: (state, { payload }) => {
      state.isServiceFeeEnabled = payload;
      let surchargeInCurrencyWithBps = 0;
      if (state.isServiceFeeEnabled) {
        const surchargeInCurrency: number = calculateSurchargeInCurrency(
          state.currentSurcharge.percentage,
          state.invoiceSubTotal
        );

        surchargeInCurrencyWithBps = calculateSurchargeInCurrencyWithBps(
          surchargeInCurrency,
          state.currentSurcharge.basePoints
        );

        const surchargeWithBpsPercentage = calculateSurchargeWithBpsPercentage(
          surchargeInCurrencyWithBps,
          state.invoiceSubTotal
        );

        state.currentSurcharge = {
          percentageWithBps: surchargeWithBpsPercentage,
          valueInCurrencyWithBps: surchargeInCurrencyWithBps,
          percentage: state.currentSurcharge.percentage,
          valueInCurrency: surchargeInCurrency,
          config: state.currentSurcharge.config,
          enabled: state.currentSurcharge.enabled,
          basePoints: state.currentSurcharge.basePoints
        };
      }
      // update sum of line item level taxes
      const summatedLineItemLevelTaxesCurrency: number = calculateSummatedLineItemLevelTaxesCurrency({
        lineItems: state.invoiceLineItems
      });

      const finalAmount = calculateFinalAmount({
        subtotal: state.invoiceSubTotal,
        surcharge: surchargeInCurrencyWithBps,
        totalServiceChargeInCurrency: +state.selectedServiceCharge.totalAmount,
        summatedLineItemLevelTaxesCurrency
      });
      state.finalAmount = finalAmount;
    }
  }
});

export const {
  resetInvoiceState,
  updateInvoiceCreatedResponse,
  toggleTipEnabled,
  updateTipEnabled,
  updateInvoiceNotes,
  addInvoiceAttachment,
  removeInvoiceAttachment,
  modifyInvoiceAttachment,
  addInvoiceLineItem,
  removeInvoiceLineItem,
  modifyLineItem,
  incrementCatalogLineItemQty,
  updateSelectedDiscountFormData,
  removeSelectedDiscountFormData,
  updateInvoiceCustomer,
  removeInvoiceCustomer,
  updateInvoiceFeeSettings,
  addInvoiceValidationError,
  removeInvoiceValidationError,
  resetInvoiceValidationErrors,
  enableCustomerNotSelectedError,
  disableCustomerNotSelectedError,
  updateSelectedServiceCharge,
  removeSelectedServiceCharge,
  updateInvoiceDraftData,
  updateIsBackFromCreateTaxInInvoiceSlice,
  resetInvoiceDraftData,
  addCreatedTaxInInvoiceDraft,
  addGlobalTaxesInInvoiceSlice,
  updateLineItemsOrderInInvoiceSlice,
  updateIsSignatureEnabled,
  updateIsServiceFeeEnabled
} = invoiceSlice.actions;

export const selectInvoiceCustomer = (state: RootState) => state.rootReducer.invoices.invoiceCustomer;
export const selectAppliedLineItemLevelTaxes = (state: RootState) =>
  state.rootReducer.invoices.appliedLineItemLevelTaxes;
export const selectCurrentSurcharge = (state: RootState) => state.rootReducer.invoices.currentSurcharge;
export const selectDiscountSettings = (state: RootState) => state.rootReducer.invoices.discountSettings;
export const selectInvoiceLineItems = (state: RootState) => state.rootReducer.invoices.invoiceLineItems;
export const selectDiscountFormData = (state: RootState) => state.rootReducer.invoices.discountFormData;
export const selectSelectedServiceCharge = (state: RootState) => state.rootReducer.invoices.selectedServiceCharge;
export const selecteLineItemsTotal = (state: RootState) => state.rootReducer.invoices.lineItemsTotal;
export const selectInvoiceAttachments = (state: RootState) => state.rootReducer.invoices.selectedInvoiceAttachments;
export const selectInvoiceNotes = (state: RootState) => state.rootReducer.invoices.comment;
export const selectTipEnabled = (state: RootState) => state.rootReducer.invoices.tipEnabled;
export const selectInvoice = (state: RootState) => state.rootReducer.invoices;
export const selectInvoiceCreatedResponse = (state: RootState) => state.rootReducer.invoices.invoiceCreatedResponse;
export const selectInvoiceFinalAmount = (state: RootState) => state.rootReducer.invoices.finalAmount;
export const selectInvoiceSubtotal = (state: RootState) => state.rootReducer.invoices.invoiceSubTotal;
export const selectInvoiceValidationErrors = (state: RootState) => state.rootReducer.invoices.invoiceValidationErrors;
export const selectShowCustomerNotSelectedError = (state: RootState) =>
  state.rootReducer.invoices.showCustomerNotSelectedError;
export const selectInvoiceDraftDataInInvoiceSlice = (state: RootState) =>
  state.rootReducer.invoices.invoiceDraftModalData;
export const selectIsBackFromCreateTaxFromInvoiceSlice = (state: RootState) =>
  state.rootReducer.invoices.isBackFromCreateTaxRoute;
export const selectGlobalLineItemTaxesInInvoiceSlice = (state: RootState) =>
  state.rootReducer.invoices.globalLineItemTaxes;
export const selectGlobalServiceChargeTaxesInInvoiceSlice = (state: RootState) =>
  state.rootReducer.invoices.globalServiceChargeTaxes;
export const selectIsSignatureEnabled = (state: RootState) => state.rootReducer.invoices.isSignatureEnabled;
export const selectIsServiceFeeEnabled = (state: RootState) => state.rootReducer.invoices.isServiceFeeEnabled;

export default invoiceSlice.reducer;
