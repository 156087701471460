export enum InfoMesssages {
  refundManualPaymentDisabledMessage = 'Refund is not allowed for manual payments.',
  refundAlreadyProcessedDisabledMessage = 'Refund is already initiated/processed.',
  refundNoPermissionMessage = 'You do not have permission to issue refund.',
  shareReceiptNoPermissionMessage = 'You do not have permission to share the receipt.',
  shareInvoiceNoPermissionMessage = 'You do not have permission to share the invoice.',
  editInvoiceNoPermissionMessage = 'You do not have permission to edit an invoice.',
  createInvoiceNoPermissionMessage = 'You do not have permission to create an invoice.',
  createCustomerNoPermissionMessage = 'You do not have permission to create a customer',
  editCustomerNoPermissionMessage = 'You do not have permission to edit a customer.',
  noInvoicesToExport = 'No invoices to export',
  noReceiptsToExport = 'No receipts to export',
  noPayoutsToExport = 'No payouts to export',
  noDisputesToExport = 'No disputes to export',
  inviteTeamMemberNoPermissionMessage = 'You do not have permission to invite team member.',
  editTeamMemberPermissionMessage = 'You do not have permission to edit team member.',
  createItemNoPermissionMessage = 'You do not have permission to create item',
  noItemsToExport = 'No items to export',
  noServiceChargesToExport = 'No service charges to export',
  createCategoriesNoPermissionMessage = 'You do not have permission to create category',
  createTaxesNoPermissionMessage = 'You do not have permission to create tax',
  createServiceChargesNoPermissionMessage = 'You do not have permission to create service charge',
  genericNoPermissionMessage = 'You do not have permission to perform this action'
}
