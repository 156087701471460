import { API_VERSION } from 'constants/common';
import { ImageUploadResponse } from 'containers/invoices/invoices.model';
import { baseApi } from 'services/api';
import {
  IGetMerchantProfile,
  IGetMerchantProfilePayload,
  IGetMerchantProfileResponse,
  ResetPasswordPayload,
  UpdateMerchantProfilePayload
} from './myAccount.model';
import { initializeMyAcountSliceFromAPI } from './myAccountSlice';

const MyAccountBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMerchantProfile: builder.query<IGetMerchantProfile, IGetMerchantProfilePayload>({
      query: payload => {
        const url = `${API_VERSION.V3}/merchants/profile?teamId=${payload.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      transformResponse: (response: IGetMerchantProfileResponse) => {
        return {
          ...response,
          teamDetails: response.TeamDetails
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        const { data } = await queryFulfilled;
        if (data) {
          dispatch(initializeMyAcountSliceFromAPI(data));
        }
      },

      extraOptions: {
        failure: 'We are unable to get your account settings right now',
        showToast: true
      }
    }),

    updateMerchantProfile: builder.mutation<any, UpdateMerchantProfilePayload>({
      query: payload => {
        const url = `${API_VERSION.WEB_V1}/merchants/profile`;
        return {
          url: url,
          method: 'POST',
          body: payload
        };
      }
    }),

    uploadProfileImage: builder.mutation<ImageUploadResponse, { file: any }>({
      query: ({ file }) => {
        const formData = new FormData();
        formData.append('image', file);

        return {
          url: `${API_VERSION.V1}/merchants/web-app/profile-picture`,
          method: 'POST',
          body: formData
        };
      },

      extraOptions: {
        failure: 'We are unable to upload image. Try again after sometime.',
        showToast: true
      }
    }),

    resetPassword: builder.mutation<any, ResetPasswordPayload>({
      query: payload => {
        const url = `${API_VERSION.V2}/merchants/reset-password`;
        return {
          url: url,
          method: 'POST',
          body: payload
        };
      },
      extraOptions: {
        failure: 'We are unable to reset your password right now',
        showToast: true
      }
    }),

    // currently used to update merchant name
    updateMerchantProfileV3: builder.mutation<any, UpdateMerchantProfilePayload>({
      query: payload => {
        const url = `${API_VERSION.V3}/merchants/profile`;
        return {
          url: url,
          method: 'POST',
          body: payload
        };
      },
      extraOptions: {
        failure: 'Something went wrong, try again after some time',
        showToast: true
      }
    })
  })
});

export const {
  useGetMerchantProfileQuery,
  useLazyGetMerchantProfileQuery,
  useUploadProfileImageMutation,
  useUpdateMerchantProfileMutation,
  useResetPasswordMutation,
  useUpdateMerchantProfileV3Mutation
} = MyAccountBaseApi;
