export enum TaxActionType {
  EDIT = 'Edit',
  DELETE = 'Delete'
}

export enum TaxApplyToType {
  ITEM = 'Item',
  SERVICE_CHARGE = 'Service Charge'
}

export enum PaginatedListKeyType {
  PERCENTAGE = 'percentage'
}

export enum TaxInputErrors {
  Tax_Name_Error = 'Please enter a valid name',
  Tax_Rate_Error = 'Please enter a valid rate'
}
