import { FeatureToggleState } from './constants';

export interface ServiceFeeBasePoint {
  name: string;
  value: string;
}

export interface FeatureToggleOption {
  name: string;
  value: FeatureToggleState;
}

export enum ValidationStatus {
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  LOADING = 'LOADING'
}
