import { InfoIcon } from 'assets/icons';
import { CustomPopover } from 'components';
import { PopoverArrowTypes } from 'constants/common';
import { FC, useState } from 'react';

const PayoutSuccessToolTip: FC = () => {
  const [showToolTip, setShowToolTip] = useState(false);

  const handleShowToolTip = () => {
    setShowToolTip(true);
  };

  const handleCloseToolTip = () => {
    setShowToolTip(false);
  };

  const toolTipElement = () => (
    <div onMouseEnter={handleShowToolTip} onMouseLeave={handleCloseToolTip} className="cursor-pointer">
      <InfoIcon className="shrink-0" width={16} height={16} />
    </div>
  );
  return (
    <CustomPopover
      show={showToolTip}
      onTogglePopover={handleShowToolTip}
      onClosePopover={handleCloseToolTip}
      anchorComponent={toolTipElement}
      highlightAnchor={false}
      offset="left-1/2 transform -translate-x-1/2"
      showArrow={true}
      arrowType={PopoverArrowTypes.TOP}
      arrowOffset="left-1/2 transform -translate-x-1/2"
      margin="mt-3"
      width="w-[223px]"
      popoverBodyStyle="px-3 py-4">
      <div className="text-px13 text-primaryText">
        Your bank may follow different policies or times when the amount shows in your bank account. The date only
        refers to when your bank is expected to have received the payout amount from ScanPay.
      </div>
    </CustomPopover>
  );
};

export default PayoutSuccessToolTip;
