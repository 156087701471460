import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamCustomersPermissions,
  selectCurrentTeamInvoicePermissions,
  selectMerchantTeams,
  setCurrentTeam,
  showNotifier
} from 'containers/app/appSlice';
import { initialQueryParamsState, usePaginatedGetInvoicesQuery } from './hooks/usePaginatedGetInvoicesQuery';
import { CustomPopover, If, Tabs } from 'components';
import { IInvoice, InvoiceStatus } from './invoices.model';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import { areEqualArrays, constructArrayAsValue, debounce, joinTextArray } from 'utils/commonUtils';
import InvoicesTable from './components/InvoicesTable';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { useAppDispatch, useAppSelector } from 'hooks';
import { INVOICE_TAB_TITLE, TAB_HEADS as invoiceTabs } from 'constants/invoicesConstants';
// TODO Import properly to reduce bundle size
import { Button, Divider } from '@material-ui/core';
import { CustomButton } from 'components';
import {
  CaretDownIcon,
  ExportIcon,
  InvoicesPageIcon,
  InvoicesTabIcon,
  NoSearchResultsIcon,
  SearchIcon
} from 'assets/icons';
import {
  useGetInvoiceSearchSuggestionsQuery,
  useLazyDownloadInvoiceListQuery,
  useLazyGetAllCustomersQuery,
  useLazyGetInvoiceByIdQuery,
  useLazyGetMerchantsByTeamIdInvoiceQuery
} from './api';
import { pluckValuesFromArrayWithSpecificKey } from 'utils/formatters';
// TODO keep constants which we use in a container in that particular container's constant file
import { PaymentMethodsForInvoiceFilter, TipInvoiceFilter } from 'containers/home/components/constants';
import DropdownWithSearch from 'components/dropdown-with-search/DropdownWithSearch';
import CustomDateRangePicker from 'components/react-date-range-picker/ReactDateRangePicker';
// TODO export generic components from src/component/index.ts
import SearchWithDropdown from 'components/search-with-dropdown/SearchWithDropdown';
import { ExportMenu } from './components';
import { FileTypes } from 'types/baseTypes';
import isEqual from 'lodash/isEqual';
import { Outlet, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { InvoicesRouteState } from 'routes/types';
import { NotifierTypes } from 'constants/notifierConstants';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { selectInvoiceData, selectPaymentStatus, updatePaymentStatus } from '../payments-side-panel/sidePanelSlice';
import ShareInvoiceModal from './create-invoice/components/ShareInvoiceModal';
import { PopoverArrowTypes, searchTypes } from 'constants/common';
import { resetInvoiceState } from './InvoicesSlice';
import classnames from 'classnames';
import { twMerge } from 'tailwind-merge';
import { CleverTapEventsInvoices } from './events';
import { logAnalyticEvent } from 'utils/analytics';
import { InfoMesssages } from 'types/infoMessages';
import { ROOT_ROUTE_STATE } from 'routes/constants';

interface InvoicesProps {
  className?: string;
}

const Invoices: FC<InvoicesProps> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const invoicePermissions = useAppSelector(selectCurrentTeamInvoicePermissions);
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const invoiceData = useAppSelector(selectInvoiceData);
  const invoicePaymentStatus = useAppSelector(selectPaymentStatus);
  const [searchParams] = useSearchParams();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [searchText, setSearchText] = useState('');
  const [isExportPopoverOpen, setIsExportPopoverOpen] = useState<boolean>(false);
  const [selectedInvoices, setSelectedInvoices] = useState<Array<string>>([]);
  const [currentTab, setCurrentTab] = useState(INVOICE_TAB_TITLE.ALL_INVOICES);
  const [currentInvoiceId, setCurrentInvoiceId] = useState('');
  const notifications = useAppSelector(getNotifications);

  const [getInvoiceByIdQuery] = useLazyGetInvoiceByIdQuery();

  const [customerSearchString, setCustomerSearchString] = useState('');
  const [selectedCustomersDraft, setSelectedCustomersDraft] = useState<string[]>([]);
  const [selectedCustomers, selSelectedCustomers] = useState<string[]>([]);
  const [getCustomersQuery, getCustomersQueryState] = useLazyGetAllCustomersQuery();
  const { data: getCustomersResponse, isSuccess: getCustomersSuccess } = getCustomersQueryState;

  const [merchantSearchString, setMerchantSearchString] = useState('');
  const [selectedMerchantsDraft, setSelectedMerchantsDraft] = useState<string[]>([]);
  const [selectedMerchants, selSelectedMerchants] = useState<string[]>([]);
  const [getMerchantsQuery, getMerchantsQueryState] = useLazyGetMerchantsByTeamIdInvoiceQuery();
  const { data: getMerchantsResponse, isSuccess: getMerchantsSuccess } = getMerchantsQueryState;

  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const [paymentMethodsDraft, setPaymentMethodsDraft] = useState<string[]>([]);
  const [paymentMethods, setPaymentMethods] = useState<string[]>([]);

  const [tipIncludedDraft, setTipIncludedDraft] = useState<string[]>([]);
  const [tipIncluded, setTipIncluded] = useState<string[]>([]);

  const [invoiceShareModalInfo, setInvoiceShareModalInfo] = useState<{ show: boolean; isEditInvoiceShare: boolean }>({
    show: false,
    isEditInvoiceShare: false
  });
  const [showFiltersAndExport, setShowFiltersAndExport] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const merchantTeams = useAppSelector(selectMerchantTeams);
  const teamId = searchParams.get('teamId');
  const invoiceDetailsId = searchParams.get('id');
  const [clicked, setClicked] = useState(false);
  const { rootInvoiceId, teamId: teamIdFromUrl } = useParams();

  const {
    list: invoicesList,
    isLoading: isLoadingInvoices,
    loadMore,
    resetQuery: resetInvoicesQuery,
    hasMore: hasMoreInvoices,
    setQueryParams: setInvoicesQueryParams,
    queryParams: invoicesQueryParams,
    totalListCount: invoicesCount,
    isLoadingSuccess: isInvoiceListLoadingSuccess,
    hasPermissionsToView,
    searchType,
    setSearchType
  } = usePaginatedGetInvoicesQuery();

  const invoiceListLoader = useRef(loadMore);

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: invoiceListLoader,
    threshold: 0.5
  });

  useEffect(() => {
    const routeState = location.state as InvoicesRouteState;

    if (routeState?.showShareModal) {
      setInvoiceShareModalInfo({
        show: routeState?.showShareModal,
        isEditInvoiceShare: !!routeState?.isEditInvoiceShare
      });
      navigate(routesPath.INVOICES, { replace: true, state: { showShareModal: false, isEditInvoiceShare: false } });
    }

    if (routeState?.pendingInvoicesTab) {
      setCurrentTab(INVOICE_TAB_TITLE.PENDING);
      setInvoicesQueryParams(prevQueryParams => ({
        ...prevQueryParams,
        status: [InvoiceStatus.PENDING, InvoiceStatus.PROCESSING]
      }));
      navigate(routesPath.INVOICES, { replace: true, state: { pendingInvoiceTab: false } });
    }
    if (routeState?.merchantId) {
      selSelectedMerchants([routeState?.merchantId]);
      setInvoicesQueryParams(prevQueryParams => ({ ...prevQueryParams, merchantId: [routeState?.merchantId] }));
      navigate(routesPath.INVOICES, { replace: true, state: { merchantId: null } });
    }
    const teamIdVal = teamIdFromUrl ? teamIdFromUrl : teamId;
    if (teamIdVal && currentTeam?.id !== +teamIdVal) {
      const merchantTeam = merchantTeams.find(team => team.id === +teamIdVal);
      if (merchantTeam) {
        dispatch(setCurrentTeam(merchantTeam));
        dispatch(resetInvoiceState());
      } else {
        navigate(routesPath.INVOICES, { replace: true });
      }
    }
  }, [location]);

  useEffect(() => {
    resetInvoicesQuery();
  }, [invoicePermissions.viewInvoicesByTeam, invoicePermissions.viewInvoicesBySelf]);

  useEffect(() => {
    if (
      ((invoicePaymentStatus && invoiceData?.invoiceStatus !== invoicePaymentStatus) ||
        invoiceData?.invoiceStatus === InvoiceStatus.PAID ||
        invoiceData?.invoiceStatus === InvoiceStatus.PAID_QR) &&
      !rootInvoiceId
    ) {
      resetInvoicesQuery();
      dispatch(updatePaymentStatus(''));
    }
  }, [invoiceData]);

  useEffect(() => {
    if (invoiceDetailsId && isInvoiceListLoadingSuccess) {
      navigate(routesPath.INVOICES, { replace: true });
      handleInvoiceClick(invoiceDetailsId);
    }
  }, [invoiceDetailsId, isInvoiceListLoadingSuccess]);

  const { isLoading: isLoadingSearchSuggestions, data: suggestionsResponse } = useGetInvoiceSearchSuggestionsQuery({
    teamId: currentTeam?.id
  });

  const [downloadInvoiceListQuery] = useLazyDownloadInvoiceListQuery();

  const invoiceSearchSuggestions: string[] = suggestionsResponse?.suggestion;

  useEffect(() => {
    const routeState = location.state as InvoicesRouteState;

    let updatedParams = {
      ...invoicesQueryParams,
      customerId: selectedCustomers,
      merchantId: selectedMerchants,
      paymentMethod: paymentMethods,
      tipAmountNE: tipIncluded.length ? 0 : null
    };
    updatedParams = { ...updatedParams, start: startDate, end: endDate };

    if (!routeState?.pendingInvoicesTab && !routeState?.merchantId) {
      setInvoicesQueryParams(prevQueryParams => ({
        ...prevQueryParams,
        ...updatedParams
      }));
    }
  }, [selectedCustomers, selectedMerchants, paymentMethods, tipIncluded, startDate, endDate, searchType]);

  useEffect(() => {
    const routeState = location.state as InvoicesRouteState;
    resetInvoicesQuery();
    setShowFiltersAndExport(false);
    selSelectedCustomers([]);
    setPaymentMethods([]);
    setStartDate(null);
    setEndDate(null);
    setTipIncluded([]);
    if (currentTeam?.id) {
      getCustomersQuery({ teamId: currentTeam.id });
      getMerchantsQuery({ teamId: currentTeam.id });
    }
    if (!routeState?.merchantId) {
      selSelectedMerchants([]);
    }
  }, [currentTeam?.id]);

  useEffect(() => {
    invoiceListLoader.current = loadMore;
  }, [loadMore]);

  useEffect(() => {
    setSelectedInvoices([]);
  }, [invoicesQueryParams]);

  useEffect(() => {
    if (!isLoadingInvoices && invoicesList.length > 0) {
      setShowFiltersAndExport(true);
    }
    if (invoicesList.length === 0 && !isInitialQueryParamsState()) {
      setShowFiltersAndExport(true);
    }
  }, [isLoadingInvoices]);

  const isSelectedInvoice = (invoice: IInvoice) => {
    return selectedInvoices.find((id: string) => invoice.invoiceId === id);
  };

  const onToggleInvoiceSelection = (invoice: IInvoice, isCheckdInvoice: boolean) => {
    if (isCheckdInvoice) {
      setSelectedInvoices(checkedInvoicesState => checkedInvoicesState.filter(id => id !== invoice.invoiceId));
      return;
    }
    setSelectedInvoices([...selectedInvoices, invoice.invoiceId]);
  };

  const extractInvoiceIdsFromLoadedInvoices = () => {
    return invoicesList.map(invoice => invoice.invoiceId);
  };

  const areAllLoadedInvoicesSelected = () => {
    return areEqualArrays({ primaryArray: extractInvoiceIdsFromLoadedInvoices(), secondaryArray: selectedInvoices });
  };

  const onToggleSelectAllLoadedInvoices = () => {
    if (areAllLoadedInvoicesSelected()) {
      setSelectedInvoices([]);
    } else {
      setSelectedInvoices(extractInvoiceIdsFromLoadedInvoices());
    }
  };

  const getSecondaryTextForFilter = (filter: string) => {
    if (getCustomersSuccess) {
      switch (filter) {
        case 'customers':
          return joinTextArray(
            pluckValuesFromArrayWithSpecificKey(
              getCustomersResponse?.customers?.filter(item => selectedCustomers.includes(item['ID'])),
              'name',
              'lastName'
            )
          );
        case 'merchants':
          return joinTextArray(
            pluckValuesFromArrayWithSpecificKey(
              getMerchantsResponse?.filter(item => selectedMerchants.includes(item['merchantId'])),
              'firstName',
              'lastName'
            )
          );
        case 'paymentMethods':
          return joinTextArray(
            pluckValuesFromArrayWithSpecificKey(
              PaymentMethodsForInvoiceFilter?.filter(item => paymentMethods.includes(item.key)),
              'value'
            )
          );
        case 'tipIncluded':
          return joinTextArray(
            pluckValuesFromArrayWithSpecificKey(
              TipInvoiceFilter.filter(item => tipIncluded.includes(item.key)),
              'value'
            )
          );
      }
    }
    return '';
  };

  const onTabClick = (tab: INVOICE_TAB_TITLE) => {
    if (tab === INVOICE_TAB_TITLE.PAID) {
      logAnalyticEvent(CleverTapEventsInvoices.webInvoicesPaidView, {
        ...prepareAnalyticsSelectedFiltersObject(),
        ...constructArrayAsValue('Status', [InvoiceStatus.PAID])
      });
    }
    if (tab === INVOICE_TAB_TITLE.PENDING) {
      logAnalyticEvent(CleverTapEventsInvoices.webInvoicesPendingView, {
        ...prepareAnalyticsSelectedFiltersObject(),
        ...constructArrayAsValue('Status', [InvoiceStatus.PENDING])
      });
    }
    let status: InvoiceStatus[] = [];
    setCurrentTab(tab);
    handleSidePanelClose();
    switch (tab) {
      case INVOICE_TAB_TITLE.PAID:
        status = [InvoiceStatus.PAID_QR, InvoiceStatus.PAID, InvoiceStatus.REFUNDED, InvoiceStatus.REFUND_INITIATED];
        break;
      case INVOICE_TAB_TITLE.PENDING:
        status = [InvoiceStatus.PENDING, InvoiceStatus.PROCESSING];
        break;
    }
    setInvoicesQueryParams(prevQueryParams => ({ ...prevQueryParams, status }));
  };

  const handleSidePanelClose = () => {
    navigate(routesPath.INVOICES);
    setCurrentInvoiceId('');
  };

  const handleInvoiceClick = (invoiceId: string, invoiceStatus = '') => {
    onInvoiceClick(invoiceId);
    dispatch(updatePaymentStatus(invoiceStatus));
    navigate(`/invoices/${currentTeam.id}/${invoiceId}/invoice`, { state: ROOT_ROUTE_STATE });
  };

  const onInvoiceClick = (invoiceId: string) => {
    setCurrentInvoiceId(invoiceId);
  };

  const handleInvoiceCreation = (id: string) => {
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesCreateInvoice);
    if (!invoicePermissions.createInvoice || !customerPermissions.createNewCustomers) {
      onInfoClick(id, InfoMesssages.createInvoiceNoPermissionMessage);
      return;
    }
    if (invoicePermissions.createInvoice) {
      dispatch(resetInvoiceState());
      navigate(routesPath.INVOICES_CREATE);
    }
  };

  const initiateSearch = (searchTerm?: string) => {
    setInvoicesQueryParams(prevQueryParams => ({ ...prevQueryParams, search: searchTerm }));
  };

  const debouncedSearchHandler = useCallback(debounce(initiateSearch, 500), [invoicesQueryParams]);

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    debouncedSearchHandler(searchTerm);
  };

  const onClearSearch = () => {
    setSearchText('');
    initiateSearch(null);
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const exportAnchor = () => {
    return (
      <Button
        className="cursor:pointer min-h-[38px]
        bg-[#A4D7FA] font-lato font-normal normal-case text-primaryText shadow-none hover:bg-[#80C3F1]"
        variant="contained"
        endIcon={<CaretDownIcon className="path-stroke-current w-3 shrink-0 text-accentText" />}
        startIcon={<ExportIcon className="shrink-0" />}
        disableRipple={isExportDisabled}>
        <div className="text-sbase font-semibold">Export</div>
      </Button>
    );
  };

  const handleExportClick = () => {
    if (isExportDisabled) {
      onInfoClick('invoices-export', InfoMesssages.noInvoicesToExport);
      return;
    }
    setIsExportPopoverOpen(prevState => !prevState);
  };

  const onCloseExportPopover = () => {
    setIsExportPopoverOpen(false);
  };

  const handleSearchIconClick = () => {
    setClicked(true);
  };

  const prepareAnalyticsSelectedFiltersObject = () => {
    return {
      ...constructArrayAsValue('Customers', invoicesQueryParams.customerId),
      ...constructArrayAsValue('Created by', invoicesQueryParams.merchantId),
      'Start Date': invoicesQueryParams.start,
      'End Date': invoicesQueryParams.end,
      ...constructArrayAsValue(
        'Status',
        invoicesQueryParams.status.includes(InvoiceStatus.PAID_QR) ? [InvoiceStatus.PAID] : invoicesQueryParams.status
      ),
      ...constructArrayAsValue('Payment Method', invoicesQueryParams.paymentMethod),
      'Tip Collected': invoicesQueryParams.tipAmountNE === 0
    };
  };

  const onDownloadFile = (fileType: FileTypes) => {
    downloadInvoiceListQuery({
      teamId: currentTeam?.id,
      ...invoicesQueryParams,
      limit: null,
      page: 0,
      type: fileType,
      ...(selectedInvoices.length > 0 && { selectedIds: selectedInvoices })
    });
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Downloading started in the background',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesExport, prepareAnalyticsSelectedFiltersObject());
    onCloseExportPopover();
  };

  const isInitialQueryParamsState = () => {
    return isEqual(invoicesQueryParams, initialQueryParamsState);
  };

  const handleCloseShareModal = () => {
    setInvoiceShareModalInfo({ show: false, isEditInvoiceShare: false });
  };

  const handleMoreActionsClick = ({ type, invoiceId }: { type: SidePanelTypes; invoiceId: string }) => {
    onInvoiceClick(invoiceId);
    getInvoiceByIdQuery({ invoiceId, teamId: currentTeam.id });
  };

  const isFilterResultEmpty =
    invoicesList?.length === 0 &&
    !isLoadingInvoices &&
    !isInitialQueryParamsState() &&
    invoicesQueryParams.search === null;

  const isSearchResultEmpty =
    invoicesList?.length === 0 &&
    !isLoadingInvoices &&
    invoicesQueryParams.search !== null &&
    invoicesQueryParams.search !== '';

  const isInvoiceResultEmpty =
    invoicesList?.length === 0 &&
    !isLoadingInvoices &&
    invoicesQueryParams.search === null &&
    isInitialQueryParamsState() &&
    isInvoiceListLoadingSuccess;

  const isExportDisabled = isFilterResultEmpty || isSearchResultEmpty;

  return (
    <>
      <div id="invoice-page-container" className="overflow-y flex h-full flex-col px-6 pb-20 pt-6 sm:p-6">
        <div className="mb-5 flex flex-row gap-3">
          <div className="text-3xl font-bold text-primaryText">Invoices</div>
          <If condition={!clicked}>
            <div onClick={handleSearchIconClick}>
              <SearchIcon className="mx-2 flex lg:hidden" />
            </div>
          </If>
          <SearchWithDropdown
            showSuggestionList={true}
            selectedOption={searchType}
            setSelectedOption={setSearchType}
            menuItems={searchTypes}
            searchId="invoice-search"
            suggestionsList={invoiceSearchSuggestions}
            onClearSearchInput={onClearSearch}
            onSearchInputChange={onSearchTermChange}
            searchInputvalue={searchText}
            wrapperStyle={`w-38 h-10 cursor-pointer ${!hasPermissionsToView ? 'pointer-events-none' : ''} 
            ${!clicked ? 'lg:flex sm:hidden' : ''} `}
            autoFocus={!!hasPermissionsToView}
          />
          <div className="ml-auto self-end">
            <CustomButton
              id="create-invoice-button"
              onClick={() => handleInvoiceCreation('create-invoice-button')}
              className={'h-[38px] w-[200px] text-primaryBtnText'}
              showDisabledReason={!invoicePermissions.createInvoice || !customerPermissions.createNewCustomers}
              StartIcon={InvoicesTabIcon}
              startIconStyle="path-stroke-current"
              childrenContainerStyles="mr-1">
              <div className="text-sbase font-semibold">Create Invoice</div>
            </CustomButton>
          </div>
        </div>
        {hasPermissionsToView && (
          <>
            <div className="mb-4 border-b">
              <Tabs tabs={invoiceTabs} selectedTab={currentTab} onTabSelect={onTabClick} />
            </div>
            <div className="flex flex-row items-center justify-between">
              <If condition={showFiltersAndExport}>
                <div className="relative flex flex-wrap">
                  <div className="mb-3 pr-3">
                    <DropdownWithSearch
                      searchFilterId="invoice-customer-name-search-field"
                      filterId="invoice-customer-filter"
                      data={getCustomersResponse?.customers || []}
                      searchText={customerSearchString}
                      setSearchText={setCustomerSearchString}
                      selectedDraftArray={selectedCustomersDraft}
                      setSelectedDraftArray={setSelectedCustomersDraft}
                      selectedArray={selectedCustomers}
                      setSelectedArray={selSelectedCustomers}
                      dataKey="name"
                      secondaryDataKey="lastName"
                      dataIdKey={'ID'}
                      buttonPrimaryText="Customers"
                      buttonSecondaryText={getSecondaryTextForFilter('customers')}
                      filterPlaceholderText="Filter by customers"
                      searchPlaceHolderText="Search customer names"
                      allowSearch={true}
                    />
                  </div>
                  <div className="mb-3 pr-3">
                    <DropdownWithSearch
                      searchFilterId="invoice-merchant-name-search-field"
                      filterId="invoice-createdby-filter"
                      data={getMerchantsResponse || []}
                      searchText={merchantSearchString}
                      setSearchText={setMerchantSearchString}
                      selectedDraftArray={selectedMerchantsDraft}
                      setSelectedDraftArray={setSelectedMerchantsDraft}
                      selectedArray={selectedMerchants}
                      setSelectedArray={selSelectedMerchants}
                      dataKey="firstName"
                      secondaryDataKey="lastName"
                      dataIdKey={'merchantId'}
                      buttonPrimaryText="Created by"
                      buttonSecondaryText={getSecondaryTextForFilter('merchants')}
                      filterPlaceholderText="Filter by Merchants"
                      searchPlaceHolderText="Search merchant names"
                      allowSearch={true}
                    />
                  </div>
                  <div className="mb-3 pr-3">
                    <CustomDateRangePicker
                      filterId="invoice-date-filter"
                      startDate={startDate}
                      setStartDate={setStartDate}
                      endDate={endDate}
                      setEndDate={setEndDate}
                    />
                  </div>
                  <div className="mb-3 pr-3">
                    <DropdownWithSearch
                      filterId="invoice-payment-method-filter"
                      data={PaymentMethodsForInvoiceFilter}
                      selectedDraftArray={paymentMethodsDraft}
                      setSelectedDraftArray={setPaymentMethodsDraft}
                      selectedArray={paymentMethods}
                      setSelectedArray={setPaymentMethods}
                      dataKey="value"
                      dataIdKey="key"
                      buttonPrimaryText="Payment Method"
                      buttonSecondaryText={getSecondaryTextForFilter('paymentMethods')}
                      filterPlaceholderText="Filter by Payment method"
                      allowSearch={false}
                    />
                  </div>
                  <div className="mb-3 pr-3">
                    <DropdownWithSearch
                      filterId="invoice-tip-collected-filter"
                      data={TipInvoiceFilter}
                      selectedDraftArray={tipIncludedDraft}
                      setSelectedDraftArray={setTipIncludedDraft}
                      selectedArray={tipIncluded}
                      setSelectedArray={setTipIncluded}
                      dataKey="value"
                      dataIdKey="key"
                      buttonPrimaryText="Tip Collected"
                      buttonSecondaryText={getSecondaryTextForFilter('tipIncluded')}
                      filterPlaceholderText="Filter by Tip Collected"
                      allowSearch={false}
                      showResultsCount={false}
                    />
                  </div>
                </div>
                <div className="self-start">
                  <CustomPopover
                    show={isExportPopoverOpen}
                    anchorComponent={exportAnchor}
                    anchorComponentId="invoice-page-export-button"
                    onTogglePopover={handleExportClick}
                    onClosePopover={onCloseExportPopover}
                    offset="right-0 top-4"
                    highlightAnchor={false}
                    minWidth="min-w-[160px]"
                    showArrow={true}
                    arrowType={PopoverArrowTypes.TOP}
                    margin="mt-8"
                    popoverBodyStyle="p-2">
                    <ExportMenu id="invoices-export" onDownloadFile={onDownloadFile} />
                  </CustomPopover>
                </div>
              </If>
            </div>
            <div className="">
              <If condition={invoicesList.length !== 0}>
                <If condition={invoicesQueryParams.search !== null && searchText !== ''}>
                  <div id="total-invoices-count" className="py-5 text-sbase text-accentText">
                    {`${invoicesCount} ${invoicesCount <= 1 ? 'result' : 'results'}`}
                  </div>
                </If>
                <If condition={searchText === ''}>
                  <div id="total-invoices-count-without-search" className="pt-2 pb-5 text-sbase text-accentText">
                    {` Total ${invoicesCount} ${invoicesCount === 1 ? 'Invoice' : 'Invoices'} `}
                  </div>
                </If>
                <Divider className="w-full bg-gray-100" />
              </If>
            </div>
          </>
        )}
        <div className={`mt-top customNormalScroll grow ${isLoadingInvoices && hasMoreInvoices ? 'pb-0' : 'pb-20'}`}>
          <If condition={isInvoiceResultEmpty && hasPermissionsToView}>
            <div className="flex h-full flex-col items-center justify-center gap-5">
              <div className="flex flex-col items-center gap-5">
                <InvoicesPageIcon />
                <div className="text-sbase text-primaryText">Start creating your first invoice</div>
              </div>
              <div>
                <Button
                  onClick={() => handleInvoiceCreation('create-invoice-button-tertiary')}
                  id="create-invoice-button-tertiary"
                  className={twMerge(
                    `
                    text-base font-semibold normal-case text-secondary
                    hover:bg-transparent
                    `,
                    classnames({
                      'opacity-50': !invoicePermissions.createInvoice || !customerPermissions.createNewCustomers
                    })
                  )}
                  disableRipple>
                  <div className="text-base font-semibold normal-case">Create Invoice</div>
                </Button>
              </div>
            </div>
          </If>

          <If condition={isFilterResultEmpty && hasPermissionsToView}>
            <div className="flex h-full flex-col items-center justify-center gap-5">
              <div className="flex flex-col items-center gap-5">
                <InvoicesPageIcon />
                <div className="text-sbase text-primaryText">No invoices found for selected filters</div>
              </div>
            </div>
          </If>

          <If condition={isSearchResultEmpty && hasPermissionsToView}>
            <div className="flex h-full flex-col items-center justify-center gap-5">
              <div className="flex flex-col items-center gap-5">
                <NoSearchResultsIcon />
                <div className="text-sbase text-primaryText">No results found!</div>
              </div>
            </div>
          </If>
          <If condition={!isLoadingInvoices && !hasPermissionsToView}>
            <div className="flex h-full flex-col items-center justify-center gap-5">
              <div className="flex flex-col items-center gap-5">
                <InvoicesPageIcon />
                <div className="text-sbase text-primaryText">You do not have permissions to view the Invoices</div>
              </div>
            </div>
          </If>
          <If condition={invoicesList?.length !== 0 && hasPermissionsToView}>
            <InvoicesTable
              areAllLoadedInvoicesSelected={areAllLoadedInvoicesSelected}
              invoicesList={invoicesList}
              isSelectedInvoice={isSelectedInvoice}
              onToggleInvoiceSelection={onToggleInvoiceSelection}
              onToggleSelectAllLoadedInvoices={onToggleSelectAllLoadedInvoices}
              onInvoiceClick={handleInvoiceClick}
              currentSelectedId={currentInvoiceId}
              handleMoreActionsClick={handleMoreActionsClick}
            />
          </If>

          <If condition={!isLoadingInvoices && hasMoreInvoices}>
            <div className="h-2" ref={setIntersectionElement}></div>
          </If>
          <If condition={isLoadingInvoices}>
            <div className={`${invoicesList.length === 0 ? 'pt-10' : 'pt-0'}`}>
              <InfinteLoadingSpinner />
            </div>
          </If>
        </div>
      </div>
      <ShareInvoiceModal
        handleClose={handleCloseShareModal}
        show={invoiceShareModalInfo?.show}
        isEditInvoiceShare={invoiceShareModalInfo?.isEditInvoiceShare}
      />
      <Outlet context={{ resetInvoicesQuery, handleSidePanelClose }} />
    </>
  );
};

export default Invoices;
