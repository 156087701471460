import { AppStoreImage, PlayStoreImage } from 'assets/images';
import QRCode from 'react-qr-code';
import { CustomButton as Button, If } from 'components/index';
import React, { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { ONE_LINK } from 'config/index';
import { REGEX } from 'constants/common';
import { formatPhoneNumber, isNonUsPhoneNumber } from 'utils/phoneNumberUtils';
import { LoadingSpinnerAnimation } from 'assets/animations';
import Lottie from 'lottie-react';

interface AppPageProps {
  userPhone: any;
  handlePhoneNumberChange: (num: number) => void;
  onDownloadClick: VoidFn;
  phoneError: string;
  downloadURL?: string;
  isLoading?: boolean;
}
const DefaultAppPage: FC<AppPageProps> = ({
  userPhone,
  handlePhoneNumberChange,
  onDownloadClick,
  phoneError,
  downloadURL = ONE_LINK,
  isLoading = false
}) => {
  const handlePhoneNumChange = event => {
    let num = event.target.value;
    if (REGEX.phoneNumberInput.test(num)) {
      if (isNonUsPhoneNumber(num)) {
        handlePhoneNumberChange(num);
      } else {
        num = formatPhoneNumber(num);
        handlePhoneNumberChange(num);
      }
    }
  };

  return (
    <div className="w-full rounded-[10px] bg-secondaryBtn">
      <div className="border-b border-borderGray p-5 pb-10">
        <div className="pt-2">Download the ScanPay app to use all features</div>
        <div className="flex flex-col items-center pt-1 md:flex-row">
          <div className="cursor-pointer p-3 md:px-0">
            <a href={downloadURL} target="_blank" rel="noreferrer">
              <img src={AppStoreImage} alt="" />
            </a>
          </div>
          <div className="cursor-pointer">
            <a href={downloadURL} target="_blank" rel="noreferrer">
              <img src={PlayStoreImage} alt="" />
            </a>
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-12 py-10 px-5 md:flex-row">
        <div className="flex w-2/3 flex-col gap-4">
          <div className="text-xl text-secondary">
            Scan this QR code to <br /> download the app now!
          </div>
          <div>
            <ul className="list-inside list-disc text-sbase leading-6">
              <li>Open your mobile camera and point it at the QR code.</li>
              <li>Alternatively, use any QR code scanner to scan.</li>
              <li>Click on the link generated to download ScanPay app.</li>
            </ul>
          </div>
        </div>
        <div className="flex w-1/3 items-center self-center">
          <div className="m-auto h-auto w-full max-w-[140px]">
            {isLoading ? (
              <div className="flex h-full w-full items-center justify-center">
                <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
              </div>
            ) : (
              <QRCode
                size={256}
                style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
                value={downloadURL}
                viewBox={'0 0 256 256'}
              />
            )}
          </div>
        </div>
      </div>
      <div className="px-5 py-10">
        <div className="mb-2 text-base text-primaryText">Or send app download link to mobile</div>
        <div className="relative flex flex-col gap-3 md:flex-row">
          <div className="w-full md:w-1/3">
            <div className="flex h-[38px] w-full items-center rounded-md border border-[#ACB5BD] bg-white p-2">
              <span className="mr-1 text-accentText">+1</span>
              <input
                id="download-link-phone-input-field"
                type="text"
                value={userPhone}
                className="w-full border-none p-0 text-base outline-none focus:border-none focus:outline-none"
                onChange={handlePhoneNumChange}
                maxLength={16}
              />
            </div>
            <If condition={Boolean(phoneError)}>
              <div className="absolute top-9 text-px13 text-red-800">{phoneError}</div>
            </If>
          </div>
          <div className="self-center">
            <Button
              id="get-download-link-button"
              className="h-[38px] bg-primaryBtn text-primaryBtnText hover:bg-primaryButtonHover"
              onClick={onDownloadClick}
              disabled={!userPhone}>
              <span className="text-sbase">Get download link</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefaultAppPage;
