import { baseApi } from 'services/api';
import { API_SOURCE, API_VERSION, LocalStorageKeysEnum } from 'constants/common';
import {
  GetMerchantTeamsResponse,
  GetTeamBannersResponse,
  MerchantTeam,
  PaymentSystemConfigResponse,
  TeamBankAccountDetails,
  UserDetails
} from './app.model';
import {
  initialBannerState,
  initialTeamBankDetails,
  setMerchantTeams,
  setPaymentSystemConfig,
  setTeamBankDetails,
  setUserDetails,
  updateBannersData
} from './appSlice';
import {
  loggedOut,
  setCurrentSignInMethod,
  setHasPendingEmailSteps,
  setIsAuthenticated,
  setIsPasswordExpired
} from 'containers/auth/authSlice';
import { isDevelopment } from 'utils/envUtils';
import { clevertapCreateProfile } from 'utils/analytics';
import { signInMethods } from 'containers/auth/constants';
import { SignInMethod } from 'containers/auth/types';
import { LocalStorage } from 'services/storage';

export interface UserDetailsPayload {
  isInitCall?: boolean;
  onSignInSuccess?: boolean;
  onSignUpSuccess?: boolean;
}

const appBaseAPi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getMerchantTeams: builder.query<MerchantTeam[], void>({
      query: () => ({
        url: `${API_VERSION.WEB_V1}/teams`,
        method: 'GET'
      }),
      transformResponse: (response: GetMerchantTeamsResponse) => response.merchantTeams,
      async onQueryStarted(id, { dispatch, queryFulfilled, getState }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setMerchantTeams(data));
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('getMerchantTeams Failed');
        }
      },
      extraOptions: {
        failure: 'getMerchantTeams failed'
      }
    }),

    // IMPORTANT NOTE: Do not use this api post login as it will
    // respond with 401 error once the user's device token expires
    getUserDetails: builder.query<UserDetails, UserDetailsPayload>({
      query: () => ({
        url: `${API_VERSION.V1}/merchants/user-data`,
        method: 'GET',
        credentials: 'include'
      }),
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setUserDetails(data));

            if (payload?.isInitCall) {
              if (!LocalStorage.get(LocalStorageKeysEnum.CURRENT_SIGN_IN_METHOD)) {
                const phoneSignInMethod: SignInMethod = { ...signInMethods.phoneNumber, value: data?.phone };
                dispatch(setCurrentSignInMethod(phoneSignInMethod));
              }

              if (!data?.isPasswordActive) {
                dispatch(setIsPasswordExpired(true));
              }
              if (data?.isEmailVerified) {
                dispatch(setHasPendingEmailSteps(false));
              } else if (
                (!data?.isEmailVerified && data?.showVerificationPrompt) ||
                (!data.isEmailAdded && data.showEmailPrompt)
              ) {
                dispatch(setHasPendingEmailSteps(true));
              }
            }

            if (payload?.onSignInSuccess || payload?.onSignUpSuccess) {
              clevertapCreateProfile({
                Site: {
                  Phone: data?.phone
                }
              });
              dispatch(setIsAuthenticated({ isAuthenticated: true, writeToLocalStorage: true }));
            }
          }
        } catch (err) {
          // eslint-disable-next-line no-console
          if (isDevelopment) {
            // eslint-disable-next-line no-console
            console.log(err);
          }

          // cookie isn't present in request header
          if (err?.meta?.response?.type === 'cors') dispatch(loggedOut());
        }
      },
      extraOptions: {
        failure: 'Failed to fetch user data'
      }
    }),
    getPaymentSystemConfig: builder.query<PaymentSystemConfigResponse, number>({
      query: teamId => {
        const url = `${API_VERSION.V1}/team/${teamId}/payment-system/config`;
        return {
          url: url,
          method: 'GET'
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setPaymentSystemConfig(data));
          }
        } catch (err) {
          //
        }
      },
      extraOptions: {
        failure: 'Failed to get payment config',
        showToast: true
      }
    }),
    getTeamBankAccount: builder.query<TeamBankAccountDetails, number>({
      query: teamId => {
        const url = `${API_VERSION.V1}/team/${teamId}/bank-account`;
        return {
          url: url,
          method: 'GET'
        };
      },
      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data) {
            dispatch(setTeamBankDetails(data));
          } else {
            dispatch(setTeamBankDetails(initialTeamBankDetails));
          }
        } catch (err) {
          dispatch(setTeamBankDetails(initialTeamBankDetails));
        }
      },
      extraOptions: {
        failure: 'Failed to get bank details',
        showToast: false
      }
    }),
    getTeamBanner: builder.query<GetTeamBannersResponse, number>({
      query: teamId => {
        const url = `${API_VERSION.V2}/team/${teamId}/banners/${API_SOURCE.WEB}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      async onQueryStarted(payload, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          if (data?.data?.home_banners || data?.data?.payout_banners) {
            const { home_banners, payout_banners } = data.data;
            dispatch(
              updateBannersData({
                homeBanners: home_banners,
                payoutBanners: payout_banners
              })
            );
          } else {
            dispatch(updateBannersData(initialBannerState));
          }
        } catch (err) {
          //
        }
      },
      extraOptions: {
        failure: '',
        showToast: false
      }
    })
  })
});

export const {
  useGetMerchantTeamsQuery,
  useLazyGetMerchantTeamsQuery,
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useLazyGetPaymentSystemConfigQuery,
  useLazyGetTeamBankAccountQuery,
  useLazyGetTeamBannerQuery
} = appBaseAPi;
