import { MagnifierIcon, PlusIconWhite } from 'assets/icons';
import { CustomButton } from 'components';

const EmptySearchResultState = ({
  keyword,
  onCreateButtonClick,
  showCreateButton = true,
  hasCreatePermissions = true
}: {
  keyword: string;
  onCreateButtonClick?: () => void;
  showCreateButton?: boolean;
  hasCreatePermissions?: boolean;
}): JSX.Element => {
  return (
    <div className="flex h-full flex-col items-center justify-center gap-4 pb-10">
      <MagnifierIcon className="path-stroke-current path-fill-current h-10 w-10 text-gray-1 " />
      <div className="max-w-[250px] text-center font-lato text-sbase font-semibold text-primaryText">
        {`No results found. Please search with a different keyword or create a new ${keyword}.`}
      </div>
      {showCreateButton && hasCreatePermissions && (
        <div className="pt-2">
          <CustomButton
            id={`search-empty-create-${keyword}-button`}
            onClick={onCreateButtonClick}
            className="h-[38px] text-primaryBtnText"
            StartIcon={PlusIconWhite}
            childrenContainerStyles="mr-1">
            <div className="text-sbase font-semibold">{`Create ${keyword}`}</div>
          </CustomButton>
        </div>
      )}
    </div>
  );
};

export default EmptySearchResultState;
