import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import CatalogTopBar from 'containers/product-catalog/components/CatalogTopBar';
import { useAppDispatch, useAppSelector } from 'hooks';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { resetCategorySlice, selectCategoryState, selectInitializingCategorySliceInProgress } from '../categorySlice';
import { useDeleteCategoryMutation, useEditCategoryMutation, useGetCategoryByIdQuery } from '../api';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { EditCategoryAPIPayload, ICategory } from '../categories.model';
import { isCategoryDataUpdated, prepareCategoryPayload } from '../util';
import ItemsSection from '../components/ItemsSection';
import CategoryNameSection from '../components/CategoryNameSection';
import { GenericDeleteModal, SaveChangesConfirmationPopup } from 'components';
import { CatalogTopBarCategoryButton, emptyCategory } from '../constants';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsCatalogCategory } from '../events';
import { constructArrayObjectAsString } from 'utils/commonUtils';
import routesPath from 'routes/RoutesPath';
import { CategoryInputErrors } from '../type';

interface ICategoryDeleteModalState {
  open: boolean;
  category: ICategory;
}

const EditCategory: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { categoryId } = useParams();

  const selectedCategoryState = useAppSelector(selectCategoryState);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const initializingCategorySliceInProgress = useAppSelector(selectInitializingCategorySliceInProgress);

  const [categoryNameError, setCategoryNameError] = useState('');
  const [showSaveChangesConfirmation, setShowSaveChangesConfirmation] = useState(false);

  const {
    data: categoryByIdData,
    isFetching: isFetchingCategory,
    isError: isFetchingCategoryError
  } = useGetCategoryByIdQuery(
    { id: +categoryId, teamId: currentTeam.id },
    { skip: false, refetchOnMountOrArgChange: true }
  );

  const [deleteCategoryModalState, setDeleteCategoryModalState] = useState<ICategoryDeleteModalState>({
    open: false,
    category: emptyCategory
  });

  const [
    deleteCategoryMutation,
    {
      isSuccess: isDeleteCategorySuccess,
      isLoading: isDeleteCategoryMutationInProgress,
      isError: isDeleteCategoryError
    }
  ] = useDeleteCategoryMutation();

  const [editCategoryMutation, { isSuccess: isEditCategorySuccess, isLoading: isEditCategoryLoading, isError }] =
    useEditCategoryMutation();

  const disableSaveBtn =
    !selectedCategoryState.categoryName || !isCategoryDataUpdated(selectedCategoryState, categoryByIdData);

  useEffect(() => {
    if (isEditCategorySuccess) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Category successfully updated'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
      dispatch(resetCategorySlice());
      navigate(routesPath.CATALOG_CATEGORIES, { replace: true });
    }
  }, [isEditCategorySuccess]);

  useEffect(() => {
    if (isFetchingCategoryError) navigate(-1);
  }, [isFetchingCategoryError]);

  useEffect(() => {
    if (isDeleteCategorySuccess) {
      navigate(-1);
      toggleDeleteCategoryModal();
    }
  }, [isDeleteCategorySuccess]);

  const handleSave = () => {
    setShowSaveChangesConfirmation(false);
    if (!selectedCategoryState.categoryName) {
      setCategoryNameError(CategoryInputErrors.Category_Name_Error);
      return;
    }
    const payload: EditCategoryAPIPayload = {
      ...prepareCategoryPayload(selectedCategoryState, currentTeam?.id.toString()),
      id: selectedCategoryState.categoryId
    };
    if (payload && selectedCategoryState.categoryId) {
      editCategoryMutation(payload);
    }
    logAnalyticEvent(CleverTapEventsCatalogCategory.webCatalogCategoryEditCategorySave, {
      categoryId: selectedCategoryState.categoryId,
      name: selectedCategoryState.categoryName,
      item: constructArrayObjectAsString(selectedCategoryState.lineItems, 'item')
    });
  };

  const handleDelete = () => {
    toggleDeleteCategoryModal();
  };

  const deleteCategoryHandler = () => {
    deleteCategoryMutation({
      id: parseInt(categoryId),
      teamId: currentTeam.id
    });
    logAnalyticEvent(CleverTapEventsCatalogCategory.webCatalogCategoryEditCategoryDelete, {
      name: selectedCategoryState.categoryName,
      id: selectedCategoryState.categoryId
    });
  };

  const toggleDeleteCategoryModal = (category?: ICategory) => {
    if (deleteCategoryModalState.open) {
      setDeleteCategoryModalState({ open: false, category: emptyCategory });
      return;
    }
    setDeleteCategoryModalState({ open: true, category: category });
  };

  const handleExit = () => {
    if (isCategoryDataUpdated(selectedCategoryState, categoryByIdData)) {
      setShowSaveChangesConfirmation(true);
    } else {
      dispatch(resetCategorySlice());
      navigate(-1);
    }
  };

  const discardSaveChangesConfirmation = () => {
    setShowSaveChangesConfirmation(false);
    navigate(-1);
  };

  if (initializingCategorySliceInProgress || isFetchingCategory) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
      </div>
    );
  }

  return (
    <div className="flex h-full w-full flex-col">
      <CatalogTopBar
        className="border border-borderGray"
        title="Edit category"
        onPrimaryClick={handleSave}
        onReturn={handleExit}
        primaryBtnTitle="Update"
        disablePrimaryBtn={disableSaveBtn}
        secondaryBtnTitle="Delete"
        onSecondaryClick={handleDelete}
        primaryBtnId={CatalogTopBarCategoryButton.EditCategorySaveButton}
        secondaryBtnId={CatalogTopBarCategoryButton.EditCategoryDeleteButton}
        backButtonId={CatalogTopBarCategoryButton.EditCategoryBackButton}
        isPrimaryButtonLoading={isEditCategoryLoading}
      />
      <div className="flex h-full w-full flex-col items-center p-8">
        <div className="flex w-full max-w-[720px] basis-[50%] flex-col">
          <CategoryNameSection id={'create-category-name'} error={categoryNameError} setError={setCategoryNameError} />
          <ItemsSection />
        </div>
      </div>
      <GenericDeleteModal
        primaryBtnId="category-yes-delete-button"
        secondaryBtnId="category-no-cancel-button"
        showModal={deleteCategoryModalState.open}
        isActionInProgress={isDeleteCategoryMutationInProgress}
        onPrimaryBtnClick={deleteCategoryHandler}
        onSecondaryBtnClick={toggleDeleteCategoryModal}
        title="Are you sure you want to delete this Category?"
        subTitle="Deleting a category only affects future invoices not past or pending ones."
        primaryBtnLabel="Yes, Delete"
        secondaryBtnLabel="No, Cancel"
      />
      {showSaveChangesConfirmation && (
        <SaveChangesConfirmationPopup
          onSaveChanges={handleSave}
          closeSaveConfirmation={discardSaveChangesConfirmation}
          onCloseIconClick={() => setShowSaveChangesConfirmation(false)}
        />
      )}
    </div>
  );
};

export default EditCategory;
