/* eslint-disable max-len */
/* eslint-disable react/no-multi-comp */
import React from 'react';
import { ArrowRightIcon, CrossIconvector, FailedIcon } from 'assets/icons';
import { VoidFn } from 'types/baseTypes';
import SidePanel from 'components/side-panel/SidePanel';
import { useOutletContext } from 'react-router-dom';

interface RefundFailedProps {
  onClose?: VoidFn;
}

const RefundFailedPanel: React.FC<RefundFailedProps> = () => {
  const { handleSidePanelClose } = useOutletContext<any>();
  const MainSection = () => {
    return (
      <div className="flex h-full flex-col">
        <div className="flex flex-row items-center py-4" onClick={handleSidePanelClose}>
          <CrossIconvector className="shrink-0" />
          <div className={'ml-2 text-17px font-semibold text-headingGray'}>Create Refund</div>
        </div>

        <div className="flex grow flex-col items-center justify-center">
          <div className="flex flex-col items-center gap-8">
            <FailedIcon />
            <div className={'text-17px font-semibold text-headingGray'}>Refund Failed!</div>
            <div
              className="flex cursor-pointer items-center justify-center
              gap-2 rounded-md border border-primaryBtn px-10 py-1"
              onClick={handleSidePanelClose}>
              <div className="rotate-180">
                <ArrowRightIcon />
              </div>
              <div className="text-sbase text-primaryBtn">Go back</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose}>
      <section
        className={
          'w-96 right-0 ' +
          'absolute bg-white h-full shadow-xl ' +
          'delay-400 duration-500 ease-in-out transition-all transform px-4 overflow-auto'
        }>
        <MainSection />
      </section>
    </SidePanel>
  );
};

export default RefundFailedPanel;
