import { If } from 'components';
import { useLazyGetInvoiceByIdQuery } from 'containers/invoices/api';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import AttachmentsPanel from './components/AttachmentsPanel';
import InvoiceDetails from './components/InvoiceDetails';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import SidePanel from 'components/side-panel/SidePanel';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { SidePanelRouteState } from 'routes/types';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';

interface InvoiceDetailsProps {
  onClose?: VoidFn;
  routeToNextPage?: ({ type }: { type: SidePanelTypes; id: string }) => void;
}

const InvoiceDetailsPanel: FC<InvoiceDetailsProps> = () => {
  const [currentPanelType, setCurrentPanelType] = useState<SidePanelTypes>(SidePanelTypes.INVOICE_DETAILS);
  const [showBackArrow, setShowBackArrow] = useState<boolean>(false);

  const [getInvoiceByIdQuery, getInvoiceByIdState] = useLazyGetInvoiceByIdQuery();
  const { data: invoiceData, isSuccess: invoiceDataSuccess, isFetching: fetchingInvoiceData } = getInvoiceByIdState;

  const { rootInvoiceId, invoiceId: invoiceIdFromUrl } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const { handleSidePanelClose } = useOutletContext<any>();

  const currentTeam = useAppSelector(selectCurrentTeam);

  useEffect(() => {
    if (currentTeam?.id) {
      if (invoiceIdFromUrl) {
        getInvoiceByIdQuery({ invoiceId: invoiceIdFromUrl, teamId: currentTeam.id });
      } else if (rootInvoiceId) {
        getInvoiceByIdQuery({ invoiceId: rootInvoiceId, teamId: currentTeam.id });
      }
    }
  }, [invoiceIdFromUrl, rootInvoiceId, currentTeam]);

  useEffect(() => {
    const routeState = location.state as SidePanelRouteState;
    if (routeState?.fromSidePanel) {
      setShowBackArrow(true);
    }
  }, [location]);

  const handleAttachmentsClick = () => {
    setCurrentPanelType(SidePanelTypes.INVOICE_ATTACHMENTS);
  };

  const resetCurrentPanelType = () => {
    setCurrentPanelType(SidePanelTypes.INVOICE_DETAILS);
  };

  const handleDetailsPanelClose = (navigateBack = false) => {
    if (navigateBack) {
      navigate(-1);
    } else {
      handleSidePanelClose();
    }
  };

  return (
    <SidePanel isOpen={true} onClose={handleDetailsPanelClose}>
      <div className="h-full w-96">
        {fetchingInvoiceData ? (
          <div className="flex h-full w-96 items-center justify-center">
            <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        ) : (
          invoiceDataSuccess && (
            <>
              <If condition={currentPanelType === SidePanelTypes.INVOICE_DETAILS}>
                <InvoiceDetails
                  invoiceData={invoiceData}
                  onClose={handleDetailsPanelClose}
                  onAttachmentsClick={handleAttachmentsClick}
                  showBackArrow={showBackArrow}
                />
              </If>
              <If condition={currentPanelType === SidePanelTypes.INVOICE_ATTACHMENTS}>
                <AttachmentsPanel attachments={invoiceData.attachments} onClose={resetCurrentPanelType} />
              </If>
            </>
          )
        )}
      </div>
    </SidePanel>
  );
};

export default InvoiceDetailsPanel;
