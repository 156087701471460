import { CrossIconvector } from 'assets/icons';
import { CustomButton } from 'components';
import React, { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { twMerge } from 'tailwind-merge';
import { ButtonType } from 'components/custom-button/CustomButton';

enum PageState {
  BUYER_VIEW = 'BUYER_VIEW',
  PDF_VIEW = 'PDF_VIEW'
}

interface InvoiceHeaderProps {
  setPageState: React.Dispatch<React.SetStateAction<PageState>>;
  pageState: PageState;
  goBack: VoidFn;
  onPDFPreviewClick: VoidFn;
  editAndShareHandler: VoidFn;
  title: string;
}

const InvoiceHeader: FC<InvoiceHeaderProps> = ({
  setPageState,
  pageState,
  goBack,
  onPDFPreviewClick,
  editAndShareHandler,
  title
}) => {
  return (
    <div className={twMerge('py-4 pl-8 pr-10 bg-primary flex flex-row items-center w-full')}>
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row items-center gap-5">
          <CrossIconvector id="preview-invoice-close-button" className="shrink-0 cursor-pointer" onClick={goBack} />
          <div className="text-xl font-bold text-primaryText">Preview</div>
        </div>

        <div className="flex flex-row gap-5">
          <CustomButton
            id="edit-invoice-buyers-view"
            onClick={() => setPageState(PageState.BUYER_VIEW)}
            className="h-10"
            type={pageState === PageState.BUYER_VIEW ? ButtonType.TERTIARY : ButtonType.SECONDARY}>
            Buyer's View
          </CustomButton>
          <CustomButton
            id="edit-invoice-pdf-preview"
            onClick={onPDFPreviewClick}
            className="h-10"
            type={pageState === PageState.PDF_VIEW ? ButtonType.TERTIARY : ButtonType.SECONDARY}>
            PDF Preview
          </CustomButton>
          <div className="border-r border-border"></div>
          <CustomButton
            id="edit-invoice-preview-create-and-share"
            disabled={false}
            className="h-10"
            onClick={editAndShareHandler}>
            {title}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default InvoiceHeader;
