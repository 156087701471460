import { FC, useEffect, useState } from 'react';
import { CustomButton, GenericOTP, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useCountDownInterval } from 'hooks';
import { SignInMethod } from '../types';
import { SignInMethodEnum } from '../constants';
import { VoidFn } from 'types/baseTypes';
import { RequestOTPPayload, VerifyOTPPayload } from '../auth.model';
import { useRequestOTPMutation } from '../api';

interface VerifyAccountSectionProps {
  className?: string;
  otpError: string;
  onVerifyOTP?: (otp: VerifyOTPPayload) => void;
  isLoading?: boolean;
  selectedSignInMethod?: SignInMethod;
  handleBackClick?: VoidFn;
}

const VerifyAccountSection: FC<VerifyAccountSectionProps> = ({
  className,
  otpError = '',
  onVerifyOTP,
  isLoading,
  selectedSignInMethod,
  handleBackClick
}) => {
  const [otp, setOtp] = useState('');
  const [otpInputError, setOtpInputError] = useState('');
  const [resendEnabled, setResendEnabled] = useState(false);

  const [requestOTPMutation, { isSuccess: isRequestOTPSuccess }] = useRequestOTPMutation();

  const { seconds, resetTimer } = useCountDownInterval({
    countDownCompletedCallback: () => setResendEnabled(true),
    countDownTime: 30
  });

  const resendOTP = () => {
    let payload: RequestOTPPayload = null;
    if (selectedSignInMethod.id === SignInMethodEnum.EMAIL) {
      payload = {
        resetPassword: true,
        email: selectedSignInMethod.value
      };
    } else {
      payload = {
        resetPassword: true,
        phone: selectedSignInMethod.value
      };
    }
    requestOTPMutation(payload);
  };

  useEffect(() => {
    if (isRequestOTPSuccess) {
      setResendEnabled(false);
      resetTimer();
    }
  }, [isRequestOTPSuccess]);

  useEffect(() => {
    if (otpError) {
      setOtpInputError(otpError);
    }
  }, [otpError]);

  const handleOTPChange = otpString => {
    setOtpInputError('');
    setOtp(otpString);
    if (otpString.length === 4) {
      handleContinueClick(otpString);
    }
  };

  const handleContinueClick = (otpVal = otp) => {
    if (otpVal.length === 4) {
      let payload: VerifyOTPPayload = null;
      if (selectedSignInMethod.id === SignInMethodEnum.EMAIL) {
        payload = {
          otp: otpVal,
          email: selectedSignInMethod.value,
          skippedAuthentication: true
        };
      } else {
        payload = {
          otp: otpVal,
          phone: selectedSignInMethod.value,
          skippedAuthentication: true
        };
      }
      onVerifyOTP(payload);
    }
  };

  return (
    <div className={`my-auto flex max-w-[375px] flex-col self-center 2xl:w-[375px] ${className}`}>
      <div className="mt-12 text-2xl font-bold text-heading lg:text-4xl">Verify your account</div>
      <If condition={selectedSignInMethod.id === SignInMethodEnum.EMAIL}>
        <div>
          <div className="mt-3 text-17px font-normal text-primaryText">Enter the code we just sent to your email</div>
          <div className="mb-9 text-xl font-bold text-primaryText">{selectedSignInMethod.value}</div>
        </div>
      </If>
      <If condition={selectedSignInMethod.id === SignInMethodEnum.PHONE_NUMBER}>
        <div className="mt-3 mb-10 text-17px font-normal text-primaryText">
          Enter the code sent to your registered number
        </div>
      </If>
      <div className={`relative flex flex-col gap-10 ${otpInputError ? 'mb-9' : 'mb-5'}`}>
        <GenericOTP
          separator={<span className="w-11"></span>}
          otpInputStyle={`emailOtpStyle !w-[60px] border-none focus:border-none 
                focus:outline-none placeholder:text-accentText placeholder:font-bold placeholder:text-xl
                text-primaryText text-xl font-bold focus-visible:placeholder:text-transparent
                ${otpInputError ? '!border-error !border-[1px] !border-solid focus:border-error' : ''}`}
          onChange={handleOTPChange}
          value={otp}
        />
        <If condition={Boolean(otpInputError)}>
          <div className="absolute top-16 text-sbase text-error">{otpInputError}</div>
        </If>
      </div>
      <div className="flex">
        <If condition={resendEnabled}>
          <div className="text-sbase text-primaryText">Trouble receiving the code?</div>
          <div
            onClick={resendOTP}
            className={`ml-3 cursor-pointer text-sbase text-secondary 
              underline underline-offset-2`}>
            Resend code
          </div>
        </If>
        <If condition={!resendEnabled}>
          <div className="text-sbase text-primaryText">
            {`Resend the code in 
                ${seconds < 10 ? `0${seconds}` : seconds} seconds`}
          </div>
        </If>
      </div>

      <div>
        <CustomButton
          id="verification-method-section-send-code-button"
          onClick={handleContinueClick}
          disabled={!otp || otp.length !== 4}
          className={`text-semibold mt-8 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}
          isLoading={isLoading}>
          Continue
        </CustomButton>
        <CustomButton
          id="verification-method-section-back-button"
          className="mt-4 h-[38px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
          type={ButtonType.SECONDARY}
          onClick={handleBackClick}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default VerifyAccountSection;
