import { Disclosure, Transition } from '@headlessui/react';
import { DisclosureChevronDownIcon } from 'assets/icons';
import classnames from 'classnames';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

interface CustomDisclosureProps {
  onContainerClick?: VoidFn;
  id?: string;
  title: string;
  secondaryTitle?: string;
  children: ReactNode;
  titleStyle?: string;
  className?: string;
  contentStyle?: string;
  containerStyle?: string;
  buttonStyles?: string;
  secondaryTitleStyle?: string;
}

const CustomDisclosure: FC<CustomDisclosureProps> = ({
  onContainerClick = () => null,
  id = '',
  titleStyle = '',
  title,
  secondaryTitle = 'Edit all',
  containerStyle = '',
  children,
  className = '',
  contentStyle = '',
  buttonStyles = '',
  secondaryTitleStyle
}) => {
  return (
    <div id={id} onClick={onContainerClick} className={containerStyle}>
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={twMerge(
                `flex w-full 
                  items-center justify-between rounded-md bg-white py-4 px-4 
                  text-left text-sbase font-medium focus:outline-none 
              `,
                classnames({ [buttonStyles]: buttonStyles })
              )}>
              <div
                className={twMerge(
                  `
                font-semibold text-sbase
                leading-[18px]
                `,
                  classnames({ [titleStyle]: titleStyle })
                )}>
                {title}
              </div>
              <div
                className={twMerge(
                  'ml-auto mr-2 font-semibold text-sbase',
                  classnames({ [secondaryTitleStyle]: secondaryTitleStyle })
                )}>
                {secondaryTitle}
              </div>
              <DisclosureChevronDownIcon
                className={`${open ? 'rotate-180' : ''} h-[10px] w-[10px] transition-transform duration-200`}
              />
            </Disclosure.Button>
            <Transition
              enter="transition ease-out duration-100 transform"
              enterFrom="opacity-0"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-100 transform"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 ">
              <Disclosure.Panel className={`text-sm text-gray-500 ${contentStyle}`}>{children}</Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default CustomDisclosure;
