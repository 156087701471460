import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { IItem, Tax } from './items.model';
import { ItemActionType, ItemFieldNames } from './types';
import { ITaxBasic } from '../taxes/taxes.model';
import { ICategoryBasic } from '../categories/categories.model';

export interface ItemSliceState {
  isEditItemFlow: boolean;
  itemId: number;
  itemName: string;
  itemCategory: ICategoryBasic;
  unitPrice: string;
  itemTaxes: Tax[];
  itemDescription: string;
  itemSku: string;
  initializingItemSliceInProgress: boolean;
  isDuplicateSKU: boolean;
  deselectedTaxList: number[]; // this includes all the unselected taxes from the whole taxList
}

const initialState: ItemSliceState = {
  isEditItemFlow: false,
  itemCategory: { id: null, name: '' },
  itemDescription: '',
  itemId: null,
  itemName: '',
  itemSku: '',
  itemTaxes: [],
  unitPrice: '',
  initializingItemSliceInProgress: false,
  isDuplicateSKU: false,
  deselectedTaxList: []
};

const isDeselectedTax = ({ initialTax, deselectedTaxList }: { initialTax: ITaxBasic; deselectedTaxList: number[] }) => {
  return deselectedTaxList.includes(initialTax.id);
};

const isAlreadyInSliceTaxList = ({
  initialTax,
  currentSliceTaxList
}: {
  initialTax: ITaxBasic;
  currentSliceTaxList: ITaxBasic[];
}) => {
  return currentSliceTaxList.some(currentTax => currentTax.id === initialTax.id);
};

export const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    resetItemSlice: () => initialState,

    initializeItemSliceFromAPI: (state, { payload }: PayloadAction<{ data: IItem; type: ItemActionType }>) => {
      const { data, type } = payload;
      state.isEditItemFlow = true;
      state.itemName = type === ItemActionType.DUPLICATE ? `${data.name} (1)` : data.name;
      state.itemCategory = data.category;
      state.itemDescription = data.description;
      state.itemId = data.id;
      state.itemSku = data.sku;
      if (data?.taxes) {
        const filteredTaxes = data?.taxes.filter(
          initialTax =>
            !isDeselectedTax({ initialTax, deselectedTaxList: state.deselectedTaxList }) &&
            !isAlreadyInSliceTaxList({ initialTax, currentSliceTaxList: state.itemTaxes })
        );
        state.itemTaxes = [...state.itemTaxes, ...filteredTaxes];
      }
      state.unitPrice = data.unitPrice;
      state.initializingItemSliceInProgress = false;
    },

    updateItemDetails: (state, { payload }: PayloadAction<{ fieldName: ItemFieldNames; value }>) => {
      const { fieldName, value } = payload;
      state[fieldName] = value;
    },

    updateInitalizingItemSliceInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.initializingItemSliceInProgress = payload;
    },

    updateSelectedTaxes: (state, { payload }: PayloadAction<ITaxBasic[]>) => {
      state.itemTaxes = payload;
    },

    addGlobalLineItemsTaxes: (state, { payload }: PayloadAction<ITaxBasic[]>) => {
      if (payload) {
        const filteredTaxes = payload.filter(
          globalTax =>
            !state.itemTaxes.some(itemTax => itemTax.id === globalTax.id) &&
            !state.deselectedTaxList.some(deselectedTax => deselectedTax === globalTax.id)
        );

        state.itemTaxes = [...state.itemTaxes, ...filteredTaxes];
      }
      state.initializingItemSliceInProgress = false;
    },

    updateDeselectedTaxes: (state, { payload }: PayloadAction<number[]>) => {
      state.deselectedTaxList = payload;
    },

    addNewlyCreatedTaxInItemSlice: (state, { payload }: PayloadAction<ITaxBasic>) => {
      state.itemTaxes = [...state.itemTaxes, payload];
    }
  }
});

export const {
  resetItemSlice,
  initializeItemSliceFromAPI,
  updateInitalizingItemSliceInProgress,
  updateItemDetails,
  updateSelectedTaxes,
  addGlobalLineItemsTaxes,
  updateDeselectedTaxes,
  addNewlyCreatedTaxInItemSlice
} = itemSlice.actions;

export const selectItemState = (state: RootState) => state.rootReducer.item;
export const selectIsInitializingItemSlice = (state: RootState) =>
  state.rootReducer.item.initializingItemSliceInProgress;
export const selectIsDuplicateSKU = (state: RootState) => state.rootReducer.item.isDuplicateSKU;

export default itemSlice.reducer;
