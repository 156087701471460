import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate, useSearchParams } from 'react-router-dom';
import PrivateLayout from './PrivateLayout';
import routesPath from './RoutesPath';
import { selectAuthState, updateSignUpSearchParams, updateTeamInviteTokens } from 'containers/auth/authSlice';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import NotifierStack from 'containers/notifier-stack/NotifierStack';
import { useAppDispatch, useAppSelector } from 'hooks';
import { useLazyGetMerchantTeamsQuery, useLazyGetUserDetailsQuery } from 'containers/app/api';
import { selectUserDetails, setCurrentTeamAfterInviteAccept, showNotifier } from 'containers/app/appSlice';
import PublicLayout from './PublicLayout';
import { LocalStorage } from 'services/storage';
import PlaceholderPage from 'pages/PlaceholderPage';
import { AppsFlyerParams, CampaignValues } from 'containers/auth/constants';
import { FirebaseStorage } from 'services/firebase';
import { TeamInviteDataType } from 'types/notification';
import { useAcceptTeamInviteMutation } from 'containers/manage-team/api';
import { NotifierTypes } from 'constants/notifierConstants';
import { getInitials } from 'utils/commonUtils';
import { VerificationStatusEnum } from 'containers/app/app.model';
import { useLogoutMutation } from 'containers/auth/api';
import BlockedPage from 'containers/error-screens/BlockedPage';

const RouteLayout = () => {
  const [getUserDetailsQuery, { isSuccess: isInitAPISuccess, isFetching, isError: isInitAPIError }] =
    useLazyGetUserDetailsQuery();

  const [acceptTeamInviteMutation, { isSuccess: teamInviteSuccess }] = useAcceptTeamInviteMutation();
  const [getMerchantTeamsQuery] = useLazyGetMerchantTeamsQuery();
  const [logout] = useLogoutMutation();

  const currentUser = useAppSelector(selectUserDetails);

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { idToken, isAuthenticated, refreshToken, hasPendingEmailSteps, isPasswordExpired } =
    useAppSelector(selectAuthState);

  const [isLoadingInitAPI, setIsLoadingInitAPI] = useState<boolean>(true);
  const [teamInvites, setTeamInvites] = useState<TeamInviteDataType[]>([]);
  const [invitedTeam, setInvitedTeam] = useState<TeamInviteDataType>(null);

  const linkType = searchParams.get(AppsFlyerParams.CAMPAIGN) as CampaignValues;

  const isBlockedPage = location.pathname === routesPath.BLOCKED;

  const isAllowedToEnterApp =
    idToken &&
    refreshToken &&
    isAuthenticated &&
    !isPasswordExpired &&
    !hasPendingEmailSteps &&
    (currentUser.isDeviceAuthenticated || currentUser.skippedAuthentication) &&
    currentUser.verificationStatus === VerificationStatusEnum.Verified;

  useEffect(() => {
    if (currentUser?.id) {
      FirebaseStorage.getTeamInvitesByMerchant(currentUser.phone, setTeamInvites);
    }
  }, [currentUser]);

  useEffect(() => {
    if (teamInviteSuccess) {
      getMerchantTeamsQuery();
      dispatch(setCurrentTeamAfterInviteAccept(invitedTeam.teamId));
      const message = `<div>
        Viewing team <b>'${invitedTeam?.teamName}'</b>
      </div>`;
      dispatch(
        showNotifier({
          message: {
            primaryMessage: message,
            secondaryMessage: '',
            iconSrc: invitedTeam?.profilePictureSignedUrl,
            iconInitials: getInitials(invitedTeam?.teamName),
            isMessageHtml: true
          },
          type: NotifierTypes.CUSTOM,
          showClose: false,
          bgStyle: 'bg-[#3E556D] rounded-md',
          fontStyle: 'text-primary font-normal',
          shouldAnimate: true
        })
      );
    }
  }, [teamInviteSuccess]);

  const logoutFromCurrent = async () => {
    try {
      await logout({
        idToken: idToken,
        refreshToken: refreshToken,
        userName: currentUser.name ?? currentUser.phone
      }).unwrap();
    } catch (err) {
      /* empty */
    }
  };

  const handleInvite = async () => {
    if (linkType) {
      if (isAllowedToEnterApp) {
        if (linkType === CampaignValues.RESELLER) {
          const referrerId = searchParams.get(AppsFlyerParams.CUSTOMER_ID);
          await logoutFromCurrent();
          dispatch(updateTeamInviteTokens({ inviteToken: '', referrerId }));
          dispatch(updateSignUpSearchParams(Object.fromEntries(searchParams.entries())));
          navigate(routesPath.SIGN_UP);
        } else if (linkType === CampaignValues.TEAM_INVITE) {
          const inviteToken = searchParams.get(AppsFlyerParams.INVITE_TOKEN);
          const referrerId = searchParams.get(AppsFlyerParams.CUSTOMER_ID);
          dispatch(updateTeamInviteTokens({ inviteToken, referrerId }));
          if (teamInvites.length > 0) {
            const validInviteToken = teamInvites.find(invite => invite.inviteToken === inviteToken);
            if (validInviteToken?.id) {
              acceptTeamInviteMutation({ inviteToken });
              setInvitedTeam(validInviteToken);
            } else {
              dispatch(
                showNotifier({
                  type: NotifierTypes.ERROR,
                  message: {
                    primaryMessage: 'Invalid invite link and you are redirected to your previous logged in account',
                    isMessageHtml: false
                  }
                })
              );
            }
          }
          dispatch(updateSignUpSearchParams(Object.fromEntries(searchParams.entries())));
        }
      }
    }
  };

  useEffect(() => {
    handleInvite();
  }, [searchParams, teamInvites]);

  useEffect(() => {
    if (isInitAPISuccess) {
      setIsLoadingInitAPI(false);
    }
  }, [isInitAPISuccess, isFetching]);

  useEffect(() => {
    if (isInitAPIError) {
      setIsLoadingInitAPI(false);
    }
  }, [isInitAPIError, isFetching]);

  useEffect(() => {
    if (idToken && refreshToken && isAuthenticated && !linkType && !isBlockedPage) {
      getUserDetailsQuery({ isInitCall: true });
    } else {
      setIsLoadingInitAPI(false);
    }
    return () => {
      if (!isAuthenticated) {
        if (LocalStorage.get('skippedAuthentication')) {
          LocalStorage.remove('skippedAuthentication');
        }
      }
    };
  }, []);

  return (
    <>
      {
        <Routes>
          {/* pages that are given access irrespective of any checks */}
          <Route path={routesPath.RESET} element={<ResetPasswordPage />} />
          <Route path={routesPath.REDIRECT} element={<PlaceholderPage />} />
          <Route path={routesPath.BLOCKED} element={<BlockedPage />} />

          <Route
            path="*"
            element={isAllowedToEnterApp ? <PrivateLayout isLoadingInitAPI={isLoadingInitAPI} /> : <PublicLayout />}
          />
          {/* User who are not logged in will be taken to public layout(Pages that doesnt need to validate user),
          Logged in users will be taken to private layout.  */}
        </Routes>
      }
      <NotifierStack />
    </>
  );
};

export default RouteLayout;
