import { CircleCloseIcon, PlusIconBlue } from 'assets/icons';
import { CustomModal, ErrorScreenWithRetry, If, SearchBar } from 'components';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import EmptySearchResultState from 'containers/product-catalog/components/EmptySearchResultState';
// eslint-disable-next-line max-len
import { usePaginatedGetServiceChargeQuery } from 'containers/product-catalog/service-charges/hooks/usePaginatedGetServiceChargeQuery';
import { IServiceCharge } from 'containers/product-catalog/service-charges/serviceCharge.model';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { checkIsAccessTokenExpiry } from 'utils/apiUtils';
import { debounce } from 'utils/commonUtils';

interface AddFromServiceChargeListModalProps {
  className?: string;
  onClose: VoidFn;
  onServiceChargeClick: (serviceCharge: IServiceCharge) => void;
  open: boolean;
  onCreateOneTimeServiceCharge: VoidFn;
}

const AddFromServiceChargeListModal: FC<AddFromServiceChargeListModalProps> = ({
  onServiceChargeClick,
  className = '',
  onClose,
  open,
  onCreateOneTimeServiceCharge
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const {
    list: serviceChargeList,
    isLoading: isLoadingServiceCharges,
    isLoadingSuccess: isLoadingServiceChargesSuccess,
    loadMore: loadMoreServiceCharges,
    hasMore: hasMoreServiceCharges,
    setQueryParams: setServiceChargeListQueryParams,
    queryParams: serviceChargeListQueryParams,
    totalListCount: totalServiceCharges,
    resetRecallQuery: resetRecallServiceChargeList,
    apiErrorCode: serviceChargeListApiErrorCode
  } = usePaginatedGetServiceChargeQuery();

  const showAPIErrorWithRetryScreen =
    !isLoadingServiceChargesSuccess &&
    !isLoadingServiceCharges &&
    !checkIsAccessTokenExpiry(+serviceChargeListApiErrorCode);

  useEffect(() => {
    resetRecallServiceChargeList();
  }, []);

  const serviceChargeListLoader = useRef(loadMoreServiceCharges);

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: serviceChargeListLoader,
    threshold: 0.5
  });

  const initiateItemSearch = (searchTerm?: string) => {
    setServiceChargeListQueryParams(prevQueryParams => ({
      ...prevQueryParams,
      search: searchTerm === '' ? null : searchTerm
    }));
  };

  const debouncedItemSearchHandler = useCallback(debounce(initiateItemSearch, 500), [serviceChargeListQueryParams]);

  const handleSearch = (value): void => {
    debouncedItemSearchHandler(value);
  };

  const onClearSearch = () => {
    setSearchText('');
    handleSearch(null);
  };

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    handleSearch(searchTerm);
  };

  const handleServiceChargeClick = (serviceCharge: IServiceCharge): void => {
    onServiceChargeClick(serviceCharge);
  };

  const getServiceChargeListCount = (): string => {
    return `(${totalServiceCharges.toString()})`;
  };

  const getServiceChargeEntityValue = (serviceCharge: IServiceCharge): string => {
    if (+serviceCharge.percentage > 0) {
      return `${serviceCharge.percentage}%`;
    } else if (+serviceCharge.amount > 0) {
      return getAmountWithCurrency(serviceCharge.amount);
    }
    return '_';
  };

  const reloadGetServiceChargeListData = (): void => {
    resetRecallServiceChargeList();
  };

  return (
    <CustomModal
      closeModal={onClose}
      open={open}
      backgroundColor="bg-primary"
      width="md:w-[611px]"
      height="max-h-[405px] h-[85%]">
      <div className={`absolute flex h-full w-full grow flex-col py-10 ${className}`}>
        <CircleCloseIcon className="absolute top-2 right-2 shrink-0 cursor-pointer" onClick={onClose} />
        <div className="flex flex-row items-center">
          <div className="mr-5 flex flex-row pl-10 text-xl font-semibold text-heading">
            <div className="mr-1">Service charges</div>
            <div>{getServiceChargeListCount()}</div>
          </div>

          <div onClick={onCreateOneTimeServiceCharge} className="flex cursor-pointer items-center">
            <div className="mr-2 h-6 w-6 shrink-0">
              <PlusIconBlue />
            </div>
            <div className="text-17px font-semibold text-secondary">Create Service charge</div>
          </div>
        </div>

        <If condition={showAPIErrorWithRetryScreen}>
          <ErrorScreenWithRetry handleReload={reloadGetServiceChargeListData} />
        </If>

        <If condition={!showAPIErrorWithRetryScreen}>
          <div className="mt-5 px-10">
            <SearchBar
              id="search-bar"
              handleClear={onClearSearch}
              handleChange={onSearchTermChange}
              value={searchText}
              placeholder="Search"
              wrapperStyle="w-full h-10 border border-borderGray rounded"
              showSearchIcon={true}
              autoFocus={true}
              showCrossIcon={true}
            />
          </div>

          <div className="customNormalScroll mb-5 h-full max-h-[800px] overflow-y-auto pl-10 pr-[32px]">
            {serviceChargeList.map(item => (
              <div
                onClick={() => handleServiceChargeClick(item)}
                className="flex min-h-[77px] w-full cursor-pointer flex-row  items-center
               border-b border-secondaryBtn py-5 pl-7 pr-6 hover:bg-highlightGray"
                key={item.id}>
                <div className="max-w-[50%] basis-1/2 break-words text-sbase font-semibold text-primaryText">
                  {item.name}
                </div>
                <div className="ml-auto basis-1/2 text-right text-sbase font-semibold text-primaryText">
                  {getServiceChargeEntityValue(item)}
                </div>
              </div>
            ))}
            <If condition={serviceChargeList.length === 0 && !isLoadingServiceCharges}>
              <div className="mt-16">
                <EmptySearchResultState keyword="service charge" showCreateButton={false} />
              </div>
            </If>
            <If condition={!isLoadingServiceCharges && hasMoreServiceCharges}>
              <div className="h-2" ref={setIntersectionElement}></div>
            </If>
            <If condition={isLoadingServiceCharges}>
              <div className={`${serviceChargeList.length === 0 ? 'pt-10' : 'pt-0'}`}>
                <InfinteLoadingSpinner />
              </div>
            </If>
          </div>
        </If>
      </div>
    </CustomModal>
  );
};

export default AddFromServiceChargeListModal;
