import { APIErrorMsg, APIErrorStatuses } from 'services/type';

export const getUrlSearchParams = params => {
  const urlParams = new URLSearchParams();
  Object.keys(params).map(key => {
    if (Array.isArray(params[key])) {
      params[key].map(item => {
        urlParams.append(key, item);
      });
    } else {
      if (params[key] !== null) {
        urlParams.append(key, params[key]);
      }
    }
  });
  return urlParams;
};

export const isPermissionDenied = (errorCode: APIErrorStatuses, errorMsg: APIErrorMsg) => {
  if (
    errorCode === APIErrorStatuses.STATUS_403 &&
    (errorMsg === APIErrorMsg.PERMISSION_MISSING ||
      errorMsg === APIErrorMsg.ACCESS_FORBIDDEN ||
      errorMsg === APIErrorMsg.USER_NOT_ALLOWED_TO_ACCESS)
  ) {
    return true;
  }
  return false;
};

export const checkIsAccessTokenExpiry = (errorCode: APIErrorStatuses): boolean => {
  if (errorCode === APIErrorStatuses.STATUS_401) return true;
  return false;
};
