import Divider from '@material-ui/core/Divider';
import { CheckboxTickIcon, CircleCloseIcon, PlusIconBlue, TaxEmptyIcon } from 'assets/icons';
import {
  CustomButton,
  CustomCheckbox,
  CustomInput,
  CustomModal,
  CustomSwitch,
  CustomTextArea,
  DecrementIncrementButton,
  ErrorScreenWithRetry,
  If,
  InputWithPrefix
} from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import { REGEX } from 'constants/common';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamFeePermissions,
  showNotifier
} from 'containers/app/appSlice';
import {
  InvoiceLineItem,
  selectGlobalLineItemTaxesInInvoiceSlice,
  updateInvoiceDraftData
} from 'containers/invoices/InvoicesSlice';
import { LineItemAdditionalTax } from 'containers/invoices/types';
import {
  calculateLineItemAdditionalTaxInCurrency,
  isValidCatalogueLineItemId,
  isValidSku,
  prepareTaxListWithAllGlobalTaxes
} from 'containers/invoices/utils';
import { ItemFieldNames } from 'containers/product-catalog/items/types';
import { useLazyGetCatalogFetchTaxesQuery } from 'containers/product-catalog/taxes/api';
import { AbstractITax, GetCatalogFetchTaxesPayload, ITaxBasic } from 'containers/product-catalog/taxes/taxes.model';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import {
  EInvoiceCatalogElementType,
  EInvoiceModalType,
  EItemModalType,
  ISaveLineItem,
  InvoiceDraftModalData
} from '../types';
import { useCreateCatalogItemMutation } from 'containers/product-catalog/items/api';
import { CreateItemPayload } from 'containers/product-catalog/items/items.model';
import { NotifierTypes } from 'constants/notifierConstants';
import { formattedLineItemQty, getAmountWithCurrency } from 'utils/amountUtils';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { TaxRouteState } from 'routes/types';
import { checkIsAccessTokenExpiry } from 'utils/apiUtils';
import { getFormattedPercentage, sortSelectedTaxesToStart } from 'containers/product-catalog/taxes/util';
import {
  selectGlobalLineItemTaxesInInvoiceEditSlice,
  updateInvoiceDraftDataInEditSlice
} from 'containers/invoices/edit-invoice/invoicesEditSlice';
import { InfoMesssages } from 'types/infoMessages';

export interface IAddItemModalData {
  id: number;
  catalogueLineItemId?: number;
  name: string;
  unitPrice: string;
  sku?: string;
  description: string;
  selectedTaxes: AbstractITax[];
  quantity: string;
}

export interface IAddItemModalConfig {
  open: boolean;
  data: IAddItemModalData;
  editMode: boolean;
  draftFlow?: { isDraftFlow: boolean; saveToCatalog: boolean };
}

interface AddItemModalProps {
  title?: string;
  className?: string;
  onClose: VoidFn;
  config: IAddItemModalConfig;
  onSaveLineItemClick: (arg: ISaveLineItem) => void;
  onModifyLineItemClick?: (arg: InvoiceLineItem) => void;
  isEdit?: boolean;
  enableSaveToCatalog?: boolean;
  resetItemModalConfig?: VoidFn;
  initNameValue?: string;
  shouldInitializeData?: boolean;
  modalType: EItemModalType;
  isEditInvoiceFlow: boolean;
}

interface IFieldError {
  isActive: boolean;
  msg: string;
}

const AddItemModal: FC<AddItemModalProps> = ({
  className = '',
  onClose,
  config,
  onSaveLineItemClick,
  onModifyLineItemClick,
  isEdit = false,
  enableSaveToCatalog = false,
  resetItemModalConfig = undefined,
  initNameValue = '',
  title = 'Add Item',
  shouldInitializeData = false,
  modalType = EItemModalType.ONE_TIME_ADD_ITEM_MODAL,
  isEditInvoiceFlow = false
}) => {
  const dispatch = useAppDispatch();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const globalLineItemTaxList = useAppSelector(
    isEditInvoiceFlow ? selectGlobalLineItemTaxesInInvoiceEditSlice : selectGlobalLineItemTaxesInInvoiceSlice
  );
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const notifications = useAppSelector(getNotifications);

  const navigate = useNavigate();

  const [createCatalogItemMutation, { isLoading: isCreateCatalogItemLoading, isSuccess: isCreateCatalogItemSuccess }] =
    useCreateCatalogItemMutation();
  const [itemName, setItemName] = useState<string>('');
  const [unitPrice, setUnitPrice] = useState<string>('');
  const [sku, setSku] = useState<string>('');
  const [quantity, setQuantity] = useState<string>('1');
  const [saveToCatalog, setSaveToCatalog] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');

  const [fieldErrors, setFieldErrors] = useState<{
    itemName: IFieldError;
    unitPrice: IFieldError;
    quantity: IFieldError;
  }>({
    itemName: {
      isActive: false,
      msg: 'Please enter a valid name'
    },
    unitPrice: {
      isActive: false,
      msg: 'Please enter a valid price'
    },
    quantity: {
      isActive: false,
      msg: 'Please enter a valid quantity'
    }
  });

  const [selectedTaxes, setSelectedTaxes] = useState<ITaxBasic[]>([]);
  const [allTaxes, setAllTaxes] = useState<ITaxBasic[]>([]);

  const { manageItemsAndCategories, manageTaxes } = catalogSettingsPermissions;

  const [
    fetchTaxesBasicDataQuery,
    {
      data: taxesBasicData,
      isFetching: isFetchingTaxes,
      isLoading: isLoadingTaxes,
      isError: isFetchingTaxesError,
      error: fetchTaxError
    }
  ] = useLazyGetCatalogFetchTaxesQuery();
  const taxes = taxesBasicData?.data ?? [];

  const handleFetchTaxQuery = (): void => {
    const payload: GetCatalogFetchTaxesPayload = { limit: 1000, page: 0, search: null, teamId: currentTeam?.id };
    fetchTaxesBasicDataQuery(payload);
  };

  useEffect(() => {
    if (initNameValue) {
      setItemName(initNameValue);
    }
    handleFetchTaxQuery();
  }, []);

  useEffect(() => {
    if (taxes?.length > 0) {
      setAllTaxes(sortSelectedTaxesToStart({ taxList: taxes, currentlySelectedTaxes: selectedTaxes }));
    }
  }, [taxes]);

  useEffect(() => {
    if (config.editMode || shouldInitializeData || config?.draftFlow?.isDraftFlow) {
      setItemName(config.data.name);
      setUnitPrice(config.data.unitPrice);
      setQuantity((+config.data.quantity).toFixed(2).toString());
      setDescription(config.data.description);
      setSelectedTaxes(config.data.selectedTaxes);
      config.data?.sku && setSku(config.data?.sku);
      enableSaveToCatalog && setSaveToCatalog(config?.draftFlow?.saveToCatalog ?? false);
    } else {
      if (modalType === EItemModalType.ONE_TIME_ADD_ITEM_MODAL) {
        setSelectedTaxes(globalLineItemTaxList);
      }
    }
  }, []);

  useEffect(() => {
    if (isCreateCatalogItemSuccess) {
      dispatch(
        showNotifier({
          type: NotifierTypes.SUCCESS,
          message: {
            primaryMessage: 'Item successfully created',
            isMessageHtml: false
          }
        })
      );
      resetItemModalConfig && resetItemModalConfig();
    }
  }, [isCreateCatalogItemSuccess]);

  const onNameChange = (value: string): void => {
    setFieldErrors(prev => ({ ...prev, itemName: { ...prev.itemName, isActive: false } }));
    setItemName(value);
  };

  const onUnitPriceChange = e => {
    setFieldErrors(prev => ({ ...prev, unitPrice: { ...prev.unitPrice, isActive: false } }));
    const unitCostValue = e.target.value;
    const newUnitCostValue = unitCostValue.replace(REGEX.nonNumberRegExp, '');
    if (!REGEX.maximumTwoDecimals.test(newUnitCostValue)) {
      return;
    }
    setUnitPrice(newUnitCostValue);
  };

  const onDescriptionChange = (value: string): void => {
    setDescription(value);
  };

  const findInvoiceModalType = ({ type }: { type: EItemModalType }) => {
    if (type === EItemModalType.CATALOG_ADD_ITEM_MODAL) {
      return EInvoiceModalType.CATALOG_ADD_ITEM_MODAL;
    } else if (type === EItemModalType.EDIT_ITEM_MODAL) {
      return EInvoiceModalType.EDIT_ITEM_MODAL;
    }
    return EInvoiceModalType.ONE_TIME_ADD_ITEM_MODAL;
  };

  const onCreateTaxClick = (): void => {
    const itemModalConfig: IAddItemModalConfig = {
      editMode: config.editMode,
      open: true,
      data: {
        id: config.data.id,
        catalogueLineItemId: config?.data?.catalogueLineItemId ?? 0,
        name: itemName,
        unitPrice: unitPrice,
        sku: sku ?? '',
        description: description,
        selectedTaxes: selectedTaxes,
        quantity: quantity
      },
      draftFlow: { isDraftFlow: true, saveToCatalog: saveToCatalog }
    };

    const invoiceItemModalDraft: InvoiceDraftModalData = {
      invoiceCatalogElementType: EInvoiceCatalogElementType.ITEM,
      invoiceModalType: findInvoiceModalType({ type: modalType }),
      modalConfig: itemModalConfig
    };
    if (isEditInvoiceFlow) {
      dispatch(updateInvoiceDraftDataInEditSlice(invoiceItemModalDraft));
    } else {
      dispatch(updateInvoiceDraftData(invoiceItemModalDraft));
    }

    const createTaxRouteState: TaxRouteState = {
      callbackURL: location?.pathname
    };
    navigate(routesPath.TAXES_CREATE, { replace: false, state: createTaxRouteState });
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: false
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const onToggleSaveToCatalog = (): void => {
    if (manageItemsAndCategories) {
      setSaveToCatalog(prev => !prev);
    } else {
      onInfoClick('item-save-to-catalog', InfoMesssages.createItemNoPermissionMessage);
    }
  };

  const onCancelClick = (): void => {
    onClose();
  };

  const handleSaveLineItem = async (lineItemData: ISaveLineItem) => {
    if (lineItemData.saveToCatalog) {
      const payload: CreateItemPayload = {
        teamId: currentTeam.id.toString(),
        name: lineItemData.invoiceLineItem.itemDescription ?? '',
        description: lineItemData.invoiceLineItem.additionalDetails ?? '',
        // Deselected global taxes are added back before saving the item to catalog
        taxes: prepareTaxListWithAllGlobalTaxes({
          selectedTaxList: lineItemData.invoiceLineItem?.taxes,
          globalTaxes: globalLineItemTaxList
        }),
        unitPrice: lineItemData.invoiceLineItem.unitCost ?? ''
      };
      try {
        const data = await createCatalogItemMutation(payload).unwrap();

        if (data) {
          onSaveLineItemClick({
            saveToCatalog: lineItemData.saveToCatalog,
            invoiceLineItem: lineItemData.invoiceLineItem
          });
        }
      } catch {
        /* empty */
      }
    } else {
      onSaveLineItemClick({ saveToCatalog: lineItemData.saveToCatalog, invoiceLineItem: lineItemData.invoiceLineItem });
    }
  };

  const onSaveClick = (): void => {
    let lineItemAdditionalTaxesWithAmount: LineItemAdditionalTax[] = null;
    if (selectedTaxes.length > 0) {
      lineItemAdditionalTaxesWithAmount = selectedTaxes.map(selectedTax => {
        return {
          ...selectedTax,
          amount: calculateLineItemAdditionalTaxInCurrency(selectedTax.percentage, +quantity * +unitPrice)
        };
      });
    }
    const invoiceLineItem: InvoiceLineItem = {
      additionalDetails: description,
      id: isEdit ? config.data.id.toString() : null,
      catalogueLineItemId: config.data.catalogueLineItemId,
      itemDescription: itemName,
      quantity: formattedLineItemQty({ qtyString: quantity }),
      total: +quantity * +unitPrice,
      unitCost: unitPrice,
      ...(selectedTaxes.length > 0 ? { taxes: lineItemAdditionalTaxesWithAmount } : {}),
      sku: config.data?.sku ?? ''
    };
    if (isEdit) {
      onModifyLineItemClick(invoiceLineItem);
      return;
    }
    handleSaveLineItem({ saveToCatalog: saveToCatalog, invoiceLineItem });
  };

  const handleSave = (): void => {
    let validate = true;
    if (itemName.length < 1) {
      validate = false;
      setFieldErrors(prev => ({ ...prev, itemName: { ...prev.itemName, isActive: true } }));
    }
    if (+unitPrice <= 0) {
      validate = false;
      setFieldErrors(prev => ({ ...prev, unitPrice: { ...prev.unitPrice, isActive: true } }));
    }
    if (+quantity <= 0) {
      validate = false;
      setFieldErrors(prev => ({ ...prev, quantity: { ...prev.quantity, isActive: true } }));
    }
    if (!validate) {
      return;
    }
    onSaveClick();
  };

  const isSelectedTax = (tax: ITaxBasic): boolean => {
    return Boolean(selectedTaxes.find(selectedTaxItem => selectedTaxItem.id === tax.id));
  };

  const onTaxClick = (tax: ITaxBasic): void => {
    if (isSelectedTax(tax)) {
      setSelectedTaxes(selectedTaxes.filter(selectedListItem => selectedListItem.id !== tax.id));
    } else {
      setSelectedTaxes(prev => [...prev, tax]);
    }
  };

  const handleQuantityChange = (qty: string): void => {
    setFieldErrors(prev => ({ ...prev, quantity: { ...prev.quantity, isActive: false } }));
    setQuantity(qty);
  };

  const hasActiveError = () => {
    return Object.values(fieldErrors).some(error => error.isActive === true);
  };

  const getModalHeight = (): string => {
    if (isValidCatalogueLineItemId(config.data.catalogueLineItemId) && isValidSku(sku)) {
      return `${hasActiveError() ? 'max-h-[910px]' : 'max-h-[860px]'} h-[95%]`;
    }
    if (isEdit || (isValidCatalogueLineItemId(config.data.catalogueLineItemId) && !isValidSku(sku))) {
      return `${hasActiveError() ? 'max-h-[810px]' : 'max-h-[759px]'} h-[90%]`;
    }
    return 'max-h-[840px] h-[90%]';
  };

  const calcTotalPrice = ({ price, qty }: { price: string; qty: string | number }): string => {
    const totalPrice = +price * +qty;
    return `Total price ${getAmountWithCurrency(totalPrice)}`;
  };

  return (
    <div className={`${className}`}>
      <CustomModal
        closeModal={onClose}
        open={config.open}
        backgroundColor="bg-primary"
        width="md:w-[611px]"
        height={getModalHeight()}>
        <div className="absolute flex h-full w-full grow flex-col">
          <CircleCloseIcon className="absolute top-2 right-2 z-[1] shrink-0 cursor-pointer" onClick={onClose} />
          <div className="absolute flex h-full flex-col py-9">
            <div className="mb-[10px] px-10 text-xl font-bold text-heading">{title}</div>

            <div className="w-full overflow-auto">
              <div className="w-full px-10">
                <div className="mb-15px flex flex-col">
                  <div className="mb-7px text-17px font-semibold text-heading">Item name*</div>
                  <CustomInput
                    value={itemName}
                    onChange={onNameChange}
                    id={'create-item-item-name'}
                    placeholder="Item name"
                    className={`text-sbase font-normal placeholder:text-accentText 
                    ${
                      isValidCatalogueLineItemId(config.data.catalogueLineItemId)
                        ? `cursor-not-allowed 
                                    border border-borderGray bg-transparent text-accentText`
                        : ''
                    }
                    ${fieldErrors.itemName.isActive ? 'border border-error' : ''}`}
                    maxLength={50}
                    disabled={isValidCatalogueLineItemId(config.data.catalogueLineItemId)}
                  />
                  {fieldErrors.itemName.isActive && (
                    <div id="item-name-error" className="mt-1 text-sbase text-error">
                      {fieldErrors.itemName.msg}
                    </div>
                  )}
                </div>

                <div className="mb-4 flex flex-row gap-5">
                  <div className="flex basis-[255px] flex-col">
                    <div className="mb-7px text-17px font-semibold text-heading">Unit price*</div>

                    <InputWithPrefix
                      onChange={onUnitPriceChange}
                      value={unitPrice}
                      prefixString="$"
                      id="create-item-unit-price"
                      placeholder="0.00"
                      className={'font-normal text-heading placeholder:text-accentText'}
                      autoComplete="disable"
                      prefixStyles={`mr-3 ${unitPrice ? 'text-heading font-normal' : ''}`}
                      containerStyles={`${fieldErrors.unitPrice.isActive ? 'border border-error' : ''}`}
                    />
                    {fieldErrors.unitPrice.isActive && (
                      <div id="unit-price-error" className="mt-1 text-sbase text-error">
                        {fieldErrors.unitPrice.msg}
                      </div>
                    )}
                  </div>

                  <div className="flex basis-[255px] flex-col">
                    <div className="mb-7px text-17px font-semibold text-heading">Quantity*</div>
                    <DecrementIncrementButton
                      overridingValue={quantity}
                      setValueCallback={handleQuantityChange}
                      className={`${fieldErrors.quantity.isActive ? 'border border-error' : ''}`}
                    />

                    {fieldErrors.quantity.isActive && (
                      <div id="quantity-error" className="mt-1 text-sbase text-error">
                        {fieldErrors.quantity.msg}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mb-15px text-sbase font-semibold text-accentText">
                  {calcTotalPrice({ price: unitPrice, qty: quantity })}
                </div>

                <div className="mb-17px flex w-full flex-col gap-2">
                  <div className="text-17px font-semibold text-headingGray">Description</div>
                  <CustomTextArea
                    value={description}
                    onChange={onDescriptionChange}
                    id="create-item-description"
                    name={ItemFieldNames.itemDescription}
                    placeholder="Enter description"
                    className="min-h-[100px] rounded font-normal placeholder:text-accentText"
                    maxLength={1000}
                  />
                </div>

                {/* Add Tax section */}
                <div className="flex flex-col">
                  <div className="mb-[10px] flex items-center gap-5">
                    <div className="text-17px font-semibold text-heading">Add tax</div>

                    {manageTaxes && (
                      <div onClick={onCreateTaxClick} className="flex cursor-pointer items-center self-end">
                        <div className="mr-2">
                          <PlusIconBlue className="h-[18px] w-[18px]" />
                        </div>
                        <div className="text-15px font-semibold text-primaryBtn">Create tax</div>
                      </div>
                    )}
                  </div>

                  <div
                    className={`customNormalScroll mb-5 flex max-h-[190px] min-h-[190px] 
                  flex-col overflow-y-auto ${isFetchingTaxesError ? 'justify-center' : ''} `}>
                    <If condition={isFetchingTaxes}>
                      <InfinteLoadingSpinner />
                    </If>

                    <If
                      condition={
                        isFetchingTaxesError && !isLoadingTaxes && !checkIsAccessTokenExpiry(+fetchTaxError.code)
                      }>
                      <div className="rounded-md border border-borderGray pt-6 pb-5">
                        <ErrorScreenWithRetry handleReload={handleFetchTaxQuery} />
                      </div>
                    </If>

                    {/* Empty tax state */}
                    <If
                      condition={!isLoadingTaxes && allTaxes.length === 0 && !isFetchingTaxes && !isFetchingTaxesError}>
                      <div className="rounded-md border border-borderGray pt-4 pb-3">
                        <div className="flex flex-col items-center gap-1">
                          <TaxEmptyIcon className="path-fill-current h-10 w-10 shrink-0 text-dark-gray" />
                          <div className="text-center text-sbase font-semibold tracking-[-0.3px] text-accentText">
                            {'No taxes created'}
                          </div>
                        </div>
                      </div>
                    </If>

                    <If condition={!isFetchingTaxes && Boolean(taxesBasicData)}>
                      {allTaxes.map((tax, index) => {
                        return (
                          <div key={tax.id + tax.name + index}>
                            <div
                              onClick={() => onTaxClick(tax)}
                              className="flex min-h-[37px] cursor-pointer flex-row items-center 
                            justify-between py-2 px-5">
                              <div
                                className="min-w-[100px] max-w-[75%] basis-9/12 
                           break-words text-primaryText">
                                {tax?.['name']}
                              </div>
                              <div className="flex basis-1/4">
                                <div className="ml-auto pr-5 text-primaryText">
                                  {getFormattedPercentage(tax.percentage)}%
                                </div>
                                <CustomCheckbox
                                  className={`${isSelectedTax(tax) ? '' : 'border-accentText bg-secondaryBtn'} `}
                                  isChecked={isSelectedTax(tax)}
                                  onClick={() => onTaxClick(tax)}
                                  id={tax.id.toString()}>
                                  <If condition={isSelectedTax(tax)}>
                                    <CheckboxTickIcon />
                                  </If>
                                </CustomCheckbox>
                              </div>
                            </div>

                            <Divider className="bg-secondaryBtn" />
                          </div>
                        );
                      })}
                    </If>
                  </div>
                </div>

                {isValidCatalogueLineItemId(config.data.catalogueLineItemId) && isValidSku(sku) && (
                  <div className="mb-5 flex w-full flex-col gap-2">
                    <div className="text-17px font-semibold text-headingGray">
                      SKU <span className="text-17px font-semibold text-accentText">{'(optional)'}</span>
                    </div>
                    <CustomInput
                      value={sku}
                      onChange={() => null}
                      id="add-item-sku"
                      name={ItemFieldNames.itemSku}
                      className="cursor-not-allowed border border-borderGray bg-transparent
                       font-normal text-accentText"
                      disabled={true}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="mt-auto flex flex-col px-10">
              <If condition={enableSaveToCatalog}>
                <div
                  className={`mb-6 flex items-center gap-5 
                  ${manageItemsAndCategories ? '' : 'cursor-not-allowed opacity-75'}`}>
                  <div className="text-sbase font-semibold text-primaryText">Save to catalog</div>
                  <CustomSwitch
                    enabled={saveToCatalog}
                    toggleSwitch={onToggleSaveToCatalog}
                    className={manageItemsAndCategories ? '' : 'cursor-not-allowed'}
                    id="item-modal-switch"
                  />
                </div>
              </If>

              <div className="flex flex-row items-center justify-end">
                <CustomButton
                  className="mr-5 h-[38px] w-[100px]"
                  type={ButtonType.SECONDARY}
                  onClick={onCancelClick}
                  id={'creat-item-cancel-btn'}>
                  Cancel
                </CustomButton>

                <CustomButton
                  className="h-[38px] w-[100px]"
                  onClick={handleSave}
                  isLoading={isCreateCatalogItemLoading}
                  id={'create-item-save-btn'}>
                  {config.editMode ? 'Done' : 'Save'}
                </CustomButton>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default AddItemModal;
