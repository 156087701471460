import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

interface DataListItemProps {
  title: string;
  data: ReactNode;
  isCustom?: boolean;
  listItemStyle?: string;
  itemTitleStyle?: string;
  itemDataStyle?: string;
  onClick?: (string) => void;
  containerStyle?: string;
}

const DataListItem: FC<DataListItemProps> = ({
  title,
  data,
  itemTitleStyle,
  itemDataStyle,
  onClick,
  containerStyle
}) => {
  return (
    <div
      className={twMerge(
        classNames('text-sbase flex items-center justify-between font-semibold leading-5', containerStyle)
      )}>
      <div className={twMerge(classNames('text-accentText', itemTitleStyle))}>{title}</div>
      <div
        className={twMerge(classNames('text-tertiaryText truncate max-w-[75%]', itemDataStyle))}
        onClick={() => onClick(data)}>
        {data}
      </div>
    </div>
  );
};

export default DataListItem;
