import { FC, useCallback, useEffect, useRef, useState } from 'react';
import {
  CategoriesTabIcon,
  CategoryEmptyIcon,
  NoSearchResultsIcon,
  OpenNewTabIcon,
  PlusIconWhite,
  SearchIcon
} from 'assets/icons';
import { CustomButton, GenericDeleteModal, If, SearchBar } from 'components';
import { debounce } from 'lodash';
import CategoryTable from './components/CategoryTable';
import Divider from '@material-ui/core/Divider';
import { usePaginatedGetCategoriesQuery } from './hooks/usePaginatedGetCategoriesQuery';
import { useAppSelector } from 'hooks';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamFeePermissions,
  showNotifier
} from 'containers/app/appSlice';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import { ICategory } from './categories.model';
import { initialCategoryQueryParamsState } from './hooks/usePaginatedGetCategoriesQuery';
import isEqual from 'lodash/isEqual';
import { CategoryActionType } from './type';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import { emptyCategory } from './constants';
import { useDeleteCategoryMutation } from './api';
import routesPath from 'routes/RoutesPath';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetCategorySlice } from './categorySlice';
import { CleverTapEventsCatalogCategory } from './events';
import { logAnalyticEvent } from 'utils/analytics';
import { InfoMesssages } from 'types/infoMessages';
import { NotifierTypes } from 'constants/notifierConstants';
import { CATALOG_FAQ_LINK } from 'config/index';
import { openInNewTab } from 'utils/commonUtils';

interface ICategoryDeleteModalState {
  open: boolean;
  category: ICategory;
}

const Categories: FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const notifications = useAppSelector(getNotifications);

  const [searchIconClicked, setSearchIconClicked] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [showSearchBar, setShowSearchBar] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const {
    list: categoryList,
    isLoading: isLoadingCategories,
    loadMore,
    resetRecallQuery: resetRecallCategoryListQuery,
    hasMore: hasMoreCategories,
    setQueryParams: setCategoriesQueryParams,
    queryParams: categoriesQueryParams,
    totalListCount: categoriesCount,
    isLoadingSuccess: isCategoriesListLoadingSuccess
  } = usePaginatedGetCategoriesQuery();

  const hasManageCategoriesPermissions = catalogSettingsPermissions.manageItemsAndCategories;

  const [deleteCategoryModalState, setDeleteCategoryModalState] = useState<ICategoryDeleteModalState>({
    open: false,
    category: emptyCategory
  });

  const [
    deleteCategoryMutation,
    {
      isSuccess: isDeleteCategorySuccess,
      isLoading: isDeleteCategoryMutationInProgress,
      isError: isDeleteCategoryError
    }
  ] = useDeleteCategoryMutation();

  useEffect(() => {
    if (hasManageCategoriesPermissions) {
      resetRecallCategoryListQuery();
      setShowSearchBar(false);
    }
  }, [currentTeam?.id, hasManageCategoriesPermissions]);

  useEffect(() => {
    if (!isLoadingCategories && categoryList?.length > 0) {
      setShowSearchBar(true);
    }
    if (categoryList?.length === 0 && !isInitialQueryParamsState()) {
      setShowSearchBar(true);
    }
    if (categoryList?.length === 0 && isInitialQueryParamsState()) {
      setShowSearchBar(false);
    }
  }, [isLoadingCategories]);

  useEffect(() => {
    if (isDeleteCategorySuccess) {
      resetRecallCategoryListQuery();
      toggleDeleteCategoryModal();
    }
  }, [isDeleteCategorySuccess]);

  const categoriesListLoader = useRef(loadMore);

  const { setIntersectionElement } = useIntersectionObserver({
    intersectionCallBack: categoriesListLoader,
    threshold: 0.5
  });

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    debouncedSearchHandler(searchTerm);
  };

  const initiateSearch = (searchTerm: string) => {
    setCategoriesQueryParams(prevQueryParams => ({ ...prevQueryParams, search: searchTerm?.trim() || null }));
    if (searchTerm?.trim()) {
      logAnalyticEvent(CleverTapEventsCatalogCategory.webCatalogCategorySearch, { serachTerm: searchTerm?.trim() });
    }
  };

  const onClearSearch = () => {
    setSearchText('');
    initiateSearch(null);
  };

  const debouncedSearchHandler = useCallback(debounce(initiateSearch, 500), [categoriesQueryParams]);

  const handleSearchIconClick = () => {
    setSearchIconClicked(true);
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: false
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handleCreateCategory = () => {
    if (hasManageCategoriesPermissions) {
      dispatch(resetCategorySlice());
      navigate(routesPath.CATEGORIES_CREATE);
      logAnalyticEvent(CleverTapEventsCatalogCategory.webCatalogCategoryCreateCategory);
    } else {
      onInfoClick('create-category', InfoMesssages.createCategoriesNoPermissionMessage);
    }
  };

  const isInitialQueryParamsState = () => {
    return isEqual(categoriesQueryParams, initialCategoryQueryParamsState);
  };

  const deleteCategoryHandler = () => {
    deleteCategoryMutation({
      id: deleteCategoryModalState.category.id,
      teamId: currentTeam.id
    });
    logAnalyticEvent(CleverTapEventsCatalogCategory.webCatalogCategoryDeleteCategory, {
      name: deleteCategoryModalState.category.name,
      id: deleteCategoryModalState.category.id
    });
  };

  const toggleDeleteCategoryModal = (category?: ICategory) => {
    if (deleteCategoryModalState.open) {
      setDeleteCategoryModalState({ open: false, category: emptyCategory });
      return;
    }
    setDeleteCategoryModalState({ open: true, category: category });
  };

  const handleMoreActionsClick = ({
    categoryAction,
    category
  }: {
    categoryAction: CategoryActionType;
    category: ICategory;
  }) => {
    switch (categoryAction) {
      case CategoryActionType.EDIT:
        // eslint-disable-next-line no-case-declarations
        const url = routesPath.CATEGORIES_EDIT.replace(':categoryId', category.id.toString());
        navigate(url);
        logAnalyticEvent(CleverTapEventsCatalogCategory.webCatalogCategoryEditCategory);
        return;
      case CategoryActionType.DELETE:
        toggleDeleteCategoryModal(category);
        break;
      default:
        break;
    }
  };

  const isCategoriesResultEmpty =
    categoryList?.length === 0 &&
    !isLoadingCategories &&
    categoriesQueryParams.search === null &&
    isInitialQueryParamsState() &&
    isCategoriesListLoadingSuccess;

  const isSearchResultEmpty =
    categoryList?.length === 0 &&
    !isLoadingCategories &&
    categoriesQueryParams.search !== null &&
    categoriesQueryParams.search !== '';

  const createButton = ({ className = '' }: { className?: string } = {}): JSX.Element => (
    <CustomButton
      id="create-category-button"
      onClick={handleCreateCategory}
      className={`h-[38px] text-primaryBtnText ${className}`}
      StartIcon={PlusIconWhite}
      childrenContainerStyles="mr-1"
      showDisabledReason={!hasManageCategoriesPermissions}>
      <div className="text-sbase font-semibold">Create Category</div>
    </CustomButton>
  );

  return (
    <div className="table h-full px-6 pb-20 pt-6">
      <div className="flex w-full flex-row flex-wrap items-center gap-3">
        <div className="mr-2.5 text-3xl font-bold text-primaryText">Categories</div>
        <div className="flex grow items-center gap-3">
          <If condition={!searchIconClicked && showSearchBar && hasManageCategoriesPermissions}>
            <div onClick={handleSearchIconClick}>
              <SearchIcon className="mx-2 flex h-10 cursor-pointer lg:hidden" />
            </div>
          </If>
          {showSearchBar && hasManageCategoriesPermissions && (
            <SearchBar
              id="catalog-categories-search"
              handleClear={onClearSearch}
              handleChange={onSearchTermChange}
              value={searchText}
              placeholder="Search categories"
              wrapperStyle={`sm:w-48 h-10 border border-borderGray rounded 
              ${!searchIconClicked ? 'lg:flex hidden' : ''} 
          ${!hasManageCategoriesPermissions ? 'pointer-events-none' : ''}`}
              showSearchIcon={true}
              showCrossIcon={true}
              autoFocus={false}
            />
          )}
          <If condition={!isCategoriesResultEmpty}>{createButton({ className: 'ml-auto min-w-[180px]' })}</If>
        </div>
      </div>
      <div className="customNormalScroll table-row h-full grow align-middle">
        {hasManageCategoriesPermissions ? (
          <>
            {/* empty state */}
            <If condition={isCategoriesResultEmpty}>
              <div className="flex h-full flex-col items-center justify-center gap-10">
                <div className="flex flex-col items-center gap-5">
                  <CategoriesTabIcon height={60} width={60} className="opacity-25" />
                  <div className="text-center text-sbase font-semibold text-primaryText">
                    Categories help to group and <br />
                    organize your items, making <br />
                    invoicing faster and reporting item <br />
                    sales easier.{' '}
                    <span className="cursor-pointer text-secondary" onClick={() => openInNewTab(CATALOG_FAQ_LINK)}>
                      Learn more <OpenNewTabIcon className="mb-1 inline" />
                    </span>
                  </div>
                </div>
                {createButton()}
              </div>
            </If>
            {!isLoadingCategories && (
              <div>
                <If condition={categoriesQueryParams.search !== null && searchText !== ''}>
                  <div className="py-5 text-sbase text-accentText">{`${categoriesCount} ${
                    categoriesCount === 1 ? 'result' : 'results'
                  }`}</div>
                </If>
                <If condition={searchText === '' && !isCategoriesResultEmpty}>
                  <div className="pt-12 pb-4 text-sbase text-accentText">{` Total ${categoriesCount} ${
                    categoriesCount === 1 ? 'category' : 'categories'
                  } `}</div>
                </If>
              </div>
            )}
            <If condition={isSearchResultEmpty}>
              <div className="flex h-full flex-col items-center justify-center gap-5">
                <div className="flex flex-col items-center gap-5">
                  <NoSearchResultsIcon />
                  <div className="text-sbase text-primaryText">No results found!</div>
                </div>
              </div>
            </If>
            <If condition={categoryList?.length !== 0}>
              <>
                <Divider className="w-full bg-gray-100" />
                <CategoryTable categoryList={categoryList} handleMoreActionsClick={handleMoreActionsClick} />
              </>
            </If>
            <If condition={!isLoadingCategories && hasMoreCategories}>
              <div className="h-2" ref={setIntersectionElement}></div>
            </If>
            <If condition={isLoadingCategories}>
              <InfinteLoadingSpinner />
            </If>
          </>
        ) : (
          <div className="flex h-full flex-col items-center justify-center gap-5">
            <div className="flex flex-col items-center gap-5">
              <CategoryEmptyIcon />
              <div className="text-sbase text-primaryText">You do not have permissions to view categories</div>
            </div>
          </div>
        )}
      </div>
      <GenericDeleteModal
        primaryBtnId="category-yes-delete-button"
        secondaryBtnId="category-no-cancel-button"
        showModal={deleteCategoryModalState.open}
        isActionInProgress={isDeleteCategoryMutationInProgress}
        onPrimaryBtnClick={deleteCategoryHandler}
        onSecondaryBtnClick={toggleDeleteCategoryModal}
        title="Are you sure you want to delete this Category?"
        subTitle="Deleting a category only affects future invoices not past or pending ones."
        primaryBtnLabel="Yes, Delete"
        secondaryBtnLabel="No, Cancel"
      />
    </div>
  );
};

export default Categories;
