/* eslint-disable max-len */
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Divider } from '@material-ui/core';
import { useAppDispatch, useAppSelector } from 'hooks';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamTeamSettingsPermissions,
  showNotifier,
  updateTeamProfile
} from 'containers/app/appSlice';
import { CustomButton, CustomTextArea, FileUpload, If, ListDropDown } from 'components';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import {
  useDeleteTeamProfileImageMutation,
  useLazyGetProfessionsListQuery,
  useLazyGetTeamProfileDataDataQuery,
  useUpdateTeamProfileDataMutation,
  useUploadFileForTeamProfileMutation
} from 'containers/team-profile/api';
import { getProfessionsList } from 'containers/team-profile/utils';
import {
  AddImageIcon,
  CrossIconRed,
  EditIconBlue,
  FacebookIcon,
  MailIIcon,
  PhoneIcon,
  PlusIconBlue,
  WebsiteIcon,
  YelpIcon
} from 'assets/icons';
import { ButtonType } from 'components/custom-button/CustomButton';
import {
  isFacebookLinkValid,
  isPhoneNumberValid,
  isValidEmail,
  isWebsiteValid,
  isYelpLinkValid
} from 'utils/stringUtils';
import EditTeamProfileAddressModal from 'containers/team-profile/components/EditTeamProfileAddressModal';
import CustomTextAreaWithIcon from 'components/custom-text-area-with-dropdown/CustomTextAreaWithIcon';
import { throttle } from 'utils/commonUtils';
import { EditAddressState, TeamProfileAddress } from 'containers/team-profile/teamProfile.model';
import { useNavigate } from 'react-router-dom';
import {
  isTeamProfileDataChanged,
  isTeamProfileFetchLoadingComplete,
  modifyTeamProfileAddress,
  modifyTeamProfileDescription,
  modifyTeamProfileEmail,
  modifyTeamProfileFacebook,
  modifyTeamProfileImageStatus,
  modifyTeamProfileName,
  modifyTeamProfileOtherOnlineReferrence,
  modifyTeamProfilePhone,
  modifyTeamProfileProfession,
  modifyTeamProfileProfessionObject,
  modifyTeamProfileProfessionObjectForOriginalState,
  modifyTeamProfileProfileImageUrl,
  modifyTeamProfileWebsite,
  modifyTeamProfileYelp,
  revertCurrentData,
  selectedAddressPostText,
  selectedAddressPreText,
  selectedTeamProfileDetails,
  setOriginalDataInTeamProfile
} from './teamProfileSlice';
import { NotifierTypes } from 'constants/notifierConstants';

interface ReceiptsProps {
  className?: string;
}

const TeamProfile: FC<ReceiptsProps> = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);

  const teamPermissions = useAppSelector(selectCurrentTeamTeamSettingsPermissions);

  const isEditPermission = teamPermissions.editTeamProfile;
  const isViewPermission = teamPermissions.viewTeamProfile;

  const dispatch = useAppDispatch();
  const [openEditAddressModal, setOpenEditAddressModal] = useState(false);
  const [imageFile, setImageFile] = useState();
  const [newImageFile, setNewImageFile] = useState();

  const [imagePreview, setImagePreview] = useState<string>();
  const currentImageUploadCall = useRef(null);
  const [isUploadError, setIsUploadError] = useState<boolean>(false);
  const navigate = useNavigate();

  const [getTeamProfileDataDataQuery, getTeamProfileDataDataQueryState] = useLazyGetTeamProfileDataDataQuery();
  const [getProfessionsListQuery, getProfessionsListQueryState] = useLazyGetProfessionsListQuery();
  const {
    data: getTeamProfileData,
    isSuccess: getTeamDataSuccess,
    isFetching: isFetchingTeamProfileData
  } = getTeamProfileDataDataQueryState;
  const notifications = useAppSelector(getNotifications);

  const [editAddressState, setEditAddressState] = useState<EditAddressState>(EditAddressState.EDIT);

  const {
    data: getProfessionsListData,
    isSuccess: getProfessionsListSuccess,
    isFetching: getProfessionsListFetching,
    isError: getProfessionsListError
  } = getProfessionsListQueryState;

  const [uploadFileMutation, { isSuccess: isUploadSuccess, isLoading: isUploadingImage }] =
    useUploadFileForTeamProfileMutation();

  const [deleteTeamProfile, { isSuccess: deleteTeamProfileSuccess, isLoading: deleteTeamProfileLoading }] =
    useDeleteTeamProfileImageMutation();

  const [
    updateTeamProfileData,
    {
      isSuccess: isUpdateProfileSuccess,
      data: updateProfileData,
      isError: isUpdateProfileError,
      error: updateProfileError
    }
  ] = useUpdateTeamProfileDataMutation();

  const removeImagePreview = async () => {
    if ((selectedTeamProfileEditState.currentData.profileImageUrl || imagePreview) && currentTeam) {
      setImagePreview('');
      if (selectedTeamProfileEditState.currentData.profileImageUrl) {
        dispatch(modifyTeamProfileImageStatus('deleted'));
      } else {
        dispatch(modifyTeamProfileImageStatus('unchanged'));
      }
      dispatch(modifyTeamProfileProfileImageUrl(''));
    }
  };

  useEffect(() => {
    if (imageFile) {
      setNewImageFile(imageFile);
      dispatch(modifyTeamProfileImageStatus('new'));
    }
  }, [imageFile]);

  const onSave = async () => {
    try {
      if (currentTeam) {
        const data = {
          teamId: currentTeam?.id.toString(),
          teamProfileData: {
            teamId: currentTeam?.id.toString(),
            id: selectedTeamProfileEditState.currentData.id.toString(),
            name: selectedTeamProfileEditState.currentData.name,
            description: selectedTeamProfileEditState.currentData.description,
            profileImageUrl: selectedTeamProfileEditState.currentData.profileImageUrl,
            phone: selectedTeamProfileEditState.currentData.phone,
            email: selectedTeamProfileEditState.currentData.email,
            website: selectedTeamProfileEditState.currentData.website,
            profession: selectedTeamProfileEditState.currentData.profession,
            address: selectedTeamProfileEditState.currentData.address,
            listings: selectedTeamProfileEditState.currentData.listings
          }
        };

        if (selectedTeamProfileEditState.currentData.profileImageState === 'new') {
          await getImageURL();
        }
        if (selectedTeamProfileEditState.currentData.profileImageState === 'deleted') {
          await deleteTeamProfile({ teamId: currentTeam.id.toString() });
        }

        dispatch(modifyTeamProfileImageStatus('unchanged'));

        updateTeamProfileData(data);
      }
    } catch (e) {
      showFailedSaveTeamProfileNotification();
    }
  };

  useEffect(() => {
    if (isUpdateProfileSuccess) {
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Team Profile updated successfully',
            secondaryMessage: '',
            isMessageHtml: true
          },
          type: NotifierTypes.INFO,
          showClose: false,
          fontStyle: 'text-primary font-normal'
        })
      );
      dispatch(
        updateTeamProfile({
          teamId: currentTeam?.id,
          name: selectedTeamProfileEditState.currentData.name,
          profession: selectedTeamProfileEditState.currentData.profession,
          profileImageUrl: selectedTeamProfileEditState.currentData.profileImageUrl
        })
      );

      dispatch(setOriginalDataInTeamProfile(selectedTeamProfileEditState.currentData));
      const professionObject = getProfessionObject(selectedTeamProfileEditState.currentData.profession);

      dispatch(modifyTeamProfileProfessionObjectForOriginalState(professionObject));
      dispatch(modifyTeamProfileProfessionObject(professionObject));
    }
  }, [isUpdateProfileSuccess]);

  useEffect(() => {
    if (isUpdateProfileError) {
      showFailedSaveTeamProfileNotification();
    }
  }, [isUpdateProfileError]);

  const showFailedSaveTeamProfileNotification = () => {
    const id = 'failedSaveTeamProfileNotification';
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: 'Failed to update Team Profile',
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.ERROR,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const showPermissionWarning = () => {
    const id = 'teamProfilePermissionWarning';
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: 'Owner or Admin permissions required',
          secondaryMessage: 'Please contact your team owner for permission details.',
          isMessageHtml: true
        },
        type: NotifierTypes.NOTIFYWARNING,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  useEffect(() => {
    if (isUpdateProfileSuccess) {
      getTeamProfileDataDataQuery({ teamId: currentTeam?.id.toString() });
    }
  }, [isUpdateProfileSuccess, currentTeam]);

  const updateAddress = (address: TeamProfileAddress) => {
    dispatch(modifyTeamProfileAddress(address));
    setOpenEditAddressModal(false);
  };

  const removeAddress = () => {
    dispatch(
      modifyTeamProfileAddress({
        zipCode: '',
        street: '',
        city: '',
        state: '',
        apartment: ''
      })
    );
  };

  useEffect(() => {
    if (currentTeam?.id) {
      setImagePreview(null);
      getTeamProfileDataDataQuery({ teamId: currentTeam?.id.toString() });
    }
    getProfessionsListQuery({});
  }, [currentTeam]);

  const selectedTeamProfileEditState = useAppSelector(selectedTeamProfileDetails);
  const selectedTeamProfileEditStateLoadingComplete = useAppSelector(isTeamProfileFetchLoadingComplete);

  const selectedAddressPreString = useAppSelector(selectedAddressPreText);
  const selectedAddressPostString = useAppSelector(selectedAddressPostText);
  const isTeamProfileDataChangedSelector = useAppSelector(isTeamProfileDataChanged);

  const editAndShareHandler = useCallback(throttle(onSave, 800), [selectedTeamProfileEditState]);

  const onNameChange = (value: string) => {
    dispatch(modifyTeamProfileName(value));
  };

  const onDescriptionChange = (value: string) => {
    dispatch(modifyTeamProfileDescription(value));
  };

  const onPhoneChange = (value: string) => {
    if (!value.startsWith('+91') && value.length > 12) return;
    dispatch(modifyTeamProfilePhone(value));
  };

  const onEmailChange = (value: string) => {
    dispatch(modifyTeamProfileEmail(value));
  };

  const onWebsiteChange = (value: string) => {
    dispatch(modifyTeamProfileWebsite(value));
  };

  const onYelpWebsiteChange = (value: string) => {
    dispatch(modifyTeamProfileYelp(value));
  };

  const onFacebookChange = (value: string) => {
    dispatch(modifyTeamProfileFacebook(value));
  };

  const onOtherWebsiteChange = (value: string) => {
    dispatch(modifyTeamProfileOtherOnlineReferrence(value));
  };

  const getProfessionObject = (professionString: string) => {
    const profession = getProfessionsListData?.find(professionItr => professionItr.profession === professionString);
    if (profession) {
      return { id: profession.ID, name: professionString };
    }
    return null;
  };

  const onProfessionChange = (newProfession: { id: number; name: string }) => {
    dispatch(modifyTeamProfileProfessionObject(newProfession));
    dispatch(modifyTeamProfileProfession(newProfession.name));
  };

  const isAddressEmpty = () => {
    return (
      selectedTeamProfileEditState.currentData.address.apartment === '' &&
      selectedTeamProfileEditState.currentData.address.street === '' &&
      selectedTeamProfileEditState.currentData.address.city === '' &&
      selectedTeamProfileEditState.currentData.address.state === '' &&
      selectedTeamProfileEditState.currentData.address.zipCode === ''
    );
  };

  const getImageURL = async () => {
    try {
      const imageUrlFromS3Call = uploadFileMutation({
        file: imageFile,
        teamId: currentTeam.id
      });

      currentImageUploadCall.current = imageUrlFromS3Call;
      const imageUrlFromS3 = await imageUrlFromS3Call.unwrap();
      setIsUploadError(false);
      dispatch(modifyTeamProfileProfileImageUrl(imageUrlFromS3 as unknown as string));
      return imageUrlFromS3;
    } catch (err) {
      setIsUploadError(true);
    }
  };

  useEffect(() => {
    if (selectedTeamProfileEditState.isTeamProfileLoaded)
      setImagePreview(selectedTeamProfileEditState.currentData.profileImageUrl);
  }, [selectedTeamProfileEditState.isTeamProfileLoaded, selectedTeamProfileEditState.currentData.profileImageUrl]);

  const isFormValid =
    isFacebookLinkValid(selectedTeamProfileEditState.currentData.listings.facebook) &&
    isWebsiteValid(selectedTeamProfileEditState.currentData.listings.website) &&
    isYelpLinkValid(selectedTeamProfileEditState.currentData.listings.yelp) &&
    isValidEmail(selectedTeamProfileEditState.currentData.email);

  const isTeamNameValid = selectedTeamProfileEditState.currentData.name.length > 0;

  const isImageUpdated = selectedTeamProfileEditState.currentData.profileImageState !== 'unchanged';

  const isDataEmpty = imagePreview === null;

  return !isViewPermission ? (
    <div className="flex h-full flex-col items-center justify-center gap-5">
      <div className="flex flex-col items-center gap-5">
        <div className="text-xl text-primaryText">You do not have permissions to view Team profile</div>
      </div>
    </div>
  ) : (
    <>
      <If condition={getProfessionsListFetching || isFetchingTeamProfileData || isDataEmpty}>
        <InfinteLoadingSpinner />
      </If>
      <If condition={getProfessionsListSuccess && !getProfessionsListFetching && !isDataEmpty}>
        <div className="mb-18 mx-8 mt-8">
          <div className="text-3xl font-bold text-primaryText">Team Profile</div>
          <div className="lg:w mt-3 text-17px text-primaryText md:w-[610px] lg:w-[805px]">
            Team profile details are displayed to customers on the invoices and receipts generated by all team members.
            Looking for the list of your team members? Go to
            <span
              onClick={e => {
                navigate('/manage-team');
              }}
              className={'cursor-pointer text-secondary'}>
              {' '}
              Manage Team.
            </span>
          </div>
          <div className="mt-[43px]" />
          <Divider className={'mb-[62px]'} />
          <div className={'md:w-[610px] lg:w-[805px]'}>
            <div className={'flex flex-row'}>
              <div className={'flex flex-col md:w-[260px] lg:w-2/5'}>
                <div className="text-xl font-bold leading-4 text-heading">Team Logo</div>
                <div className="mt-[8px] text-sbase font-normal text-primaryText">
                  This logo will be showing in your profile and invoice, etc.
                </div>
              </div>
              <div className="ml-[40px] flex-col md:w-[370px] lg:w-3/5">
                <FileUpload
                  id="edit-invoice-add-attachment-card"
                  isUploadInProgress={isUploadingImage}
                  prefill={false}
                  setFile={setImageFile}
                  removePreview={() => {
                    if (isEditPermission) removeImagePreview();
                    else showPermissionWarning();
                  }}
                  filePreview={imagePreview}
                  setPreview={setImagePreview}
                  className="mb-5"
                  height="h-[150px]"
                  width="w-[270px]"
                  icon="GREY_ICON"
                  allowMultiple={false}
                  FileUploadIcon={AddImageIcon}
                  supportedFileTypes={{
                    'image/jpeg': [],
                    'image/png': [],
                    'image/webp': []
                  }}
                />
              </div>
            </div>
            <Divider className={'my-[30px]'} />
            <div className={'flex flex-row justify-between'}>
              <div className={'flex flex-col md:w-[260px] lg:w-2/5'}>
                <div className="text-xl font-bold leading-4 text-heading">Team Name</div>
                <div className="mt-[8px] text-sbase font-normal text-primaryText">
                  Your team name will be displayed on all invoices, payments, and receipts for your team
                </div>
              </div>
              <div className="ml-[40px] flex-col md:w-[370px] lg:w-3/5">
                <CustomTextArea
                  resizeTextArea={true}
                  id="create-team-name"
                  maxLength={1000}
                  name="team-name"
                  placeholder=""
                  value={selectedTeamProfileEditState.currentData.name}
                  onChange={onNameChange}
                  className="mb-4 py-[15px] text-17px text-primaryText"
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
                {!isTeamNameValid && (
                  <div className="mb-4 mt-[-12px] text-sbase font-semibold text-error">Please enter your team name</div>
                )}
              </div>
            </div>
            <Divider className={'my-[30px]'} />
            <div className={'flex flex-row justify-between'}>
              <div className={'flex flex-col md:w-[260px] lg:w-2/5'}>
                <div className="text-xl font-bold leading-4 text-heading">Statement descriptor</div>
                <div className="mt-[8px] text-sbase font-normal text-primaryText">
                  This is the business name that will show up on your customers’ bank or credit card statements.
                </div>
              </div>
              <div
                className=" ml-[50px] h-[50px] truncate rounded border-2 border-solid
              border-secondaryBtn py-3 px-5 text-17px font-semibold text-accentText md:w-[370px] lg:w-3/5">
                {`SP ${selectedTeamProfileEditState.currentData.name}`}
              </div>
            </div>
            <Divider className={'my-[30px]'} />
            <div className={'flex flex-row justify-between'}>
              <div className={'flex flex-col md:w-[260px] lg:w-2/5'}>
                <div className="text-xl font-bold leading-4 text-heading">Description</div>
                <div className="mt-[8px] text-sbase font-normal text-primaryText">
                  A brief about your team and the services they provide.
                </div>
              </div>
              <div className="ml-[40px] flex-col md:w-[370px] lg:w-3/5">
                <CustomTextArea
                  resizeTextArea={true}
                  id="create-team-description"
                  maxLength={100}
                  name="team-description"
                  placeholder="Description"
                  value={selectedTeamProfileEditState.currentData.description}
                  onChange={onDescriptionChange}
                  className="mb-4 py-[15px]"
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
                <div className="mb-4 mt-[-12px] text-sbase font-normal text-primaryText">
                  {100 - selectedTeamProfileEditState.currentData.description.length} characters left
                </div>
              </div>
            </div>
            <Divider className={'my-[30px]'} />
            <div className={'flex flex-row justify-between'}>
              <div className={'flex flex-col md:w-[260px] lg:w-2/5'}>
                <div className="text-xl font-bold leading-4 text-heading">Profession</div>
                <div className="mt-[8px] text-sbase font-normal text-primaryText">Category of your business</div>
              </div>
              {selectedTeamProfileEditState.currentData.professionObject !== null && (
                <div className="ml-[40px] flex-col md:w-[370px] lg:w-3/5">
                  <ListDropDown
                    buttonStyles="bg-secondaryBtn h-[50px] text-17px font-semibold text-primaryText cursor-pointer"
                    items={getProfessionsList(getProfessionsListData)}
                    selectedItem={selectedTeamProfileEditState.currentData.professionObject}
                    optionsHeight={'h-[300px]'}
                    wrapperStyles="bg-primary"
                    setSelectedItem={onProfessionChange}
                    spanStyles={'text-17px'}
                    disabled={!isEditPermission}
                    onClick={() => {
                      if (!isEditPermission) {
                        showPermissionWarning();
                      }
                    }}
                    id="team-profession-dropdown"
                  />
                </div>
              )}
            </div>
            <Divider className={'my-[30px]'} />
            <div className={'flex flex-row justify-between'}>
              <div className={'flex flex-col md:w-[260px] lg:w-2/5'}>
                <div className="text-xl font-bold leading-4 text-heading">Business address</div>
                <div className="mt-[8px] text-sbase font-normal text-primaryText">Registered business address</div>
              </div>
              <div className="ml-[40px] flex-col md:w-[370px] lg:w-3/5">
                <If condition={isAddressEmpty()}>
                  <div
                    className={`flex h-[70px]  flex-row items-center justify-between rounded border-2 border-solid border-secondaryBtn  px-6
                ${!isEditPermission ? 'opacity-50' : ''}`}>
                    <div className="text-sbase font-medium	italic text-accentText">Address not added</div>
                    <div
                      className="flex flex-row items-center"
                      onClick={() => {
                        if (isEditPermission) {
                          setEditAddressState(EditAddressState.ADD);
                          setOpenEditAddressModal(true);
                        } else {
                          showPermissionWarning();
                        }
                      }}>
                      <PlusIconBlue />
                      <div className="cursor-pointer pl-2 text-[17px] font-semibold text-secondary">Add address</div>
                    </div>
                  </div>
                </If>
                <If condition={!isAddressEmpty()}>
                  <div className={`flex flex-col ${!isEditPermission ? 'opacity-50' : ''}`}>
                    <div className="flex min-h-[70px] flex-row items-center justify-between rounded border-2 border-solid border-secondaryBtn px-6	">
                      <div className="break-words text-sbase font-semibold text-primaryText line-clamp-4">
                        <>
                          {selectedAddressPreString}
                          <br />
                          {selectedAddressPostString}
                        </>
                      </div>
                    </div>
                    <div className="flex cursor-pointer flex-row-reverse pt-3">
                      <div
                        className="flex flex-row items-center"
                        onClick={() => {
                          if (isEditPermission) {
                            removeAddress();
                          } else {
                            showPermissionWarning();
                          }
                        }}>
                        <CrossIconRed />
                        <div className="pl-[7px] text-[17px] font-semibold text-primaryText">Remove</div>
                      </div>
                      <div
                        className="mr-[29px] flex cursor-pointer flex-row items-center"
                        onClick={() => {
                          if (isEditPermission) {
                            setEditAddressState(EditAddressState.EDIT);
                            setOpenEditAddressModal(true);
                          } else {
                            showPermissionWarning();
                          }
                        }}>
                        <EditIconBlue />
                        <div className="pl-[7px] text-[17px] font-semibold text-secondary">Edit</div>
                      </div>
                    </div>
                  </div>
                </If>
              </div>
            </div>
            <Divider className={'my-[30px]'} />
            <div className={'mb-[152px] flex flex-row justify-between'}>
              <div className={'flex flex-col md:w-[260px] lg:w-2/5'}>
                <div className="text-xl font-bold leading-4 text-heading">Contact details</div>
                <div className="mt-[8px] text-sbase font-normal text-primaryText">Online & direct contacts</div>
              </div>
              <div className="ml-[40px] flex-col md:w-[370px] lg:w-3/5">
                <CustomTextAreaWithIcon
                  resizeTextArea={true}
                  id="team-phone"
                  maxLength={13}
                  name="team-phone"
                  icon={<PhoneIcon className={'h-4 w-4'} />}
                  placeholder="Phone Number"
                  value={selectedTeamProfileEditState.currentData.phone}
                  onChange={onPhoneChange}
                  className="mb-4 py-[15px]"
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
                {!isPhoneNumberValid(selectedTeamProfileEditState.currentData.phone) && (
                  <div className="mb-4 mt-[-6px] text-sbase font-semibold text-error">Enter a valid phone number</div>
                )}
                <CustomTextAreaWithIcon
                  resizeTextArea={true}
                  id="team-email"
                  maxLength={100}
                  name="team-email"
                  icon={<MailIIcon className={'h-4 w-4'} />}
                  placeholder="Email"
                  value={selectedTeamProfileEditState.currentData.email}
                  onChange={onEmailChange}
                  className="mb-4 py-[15px]"
                  isInputValid={isValidEmail(selectedTeamProfileEditState.currentData.email)}
                  warningMessageText={'Please enter a valid email address'}
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
                {!isValidEmail(selectedTeamProfileEditState.currentData.email) && (
                  <div className="mb-4 mt-[-6px] text-sbase font-semibold text-error">Enter a valid Email ID</div>
                )}
                <CustomTextAreaWithIcon
                  resizeTextArea={true}
                  id="team-website"
                  maxLength={300}
                  name="team-website"
                  icon={<WebsiteIcon className={'h-4 w-4'} />}
                  placeholder="Website"
                  value={selectedTeamProfileEditState.currentData.website}
                  onChange={onWebsiteChange}
                  isInputValid={isWebsiteValid(selectedTeamProfileEditState.currentData.website)}
                  className="mb-4 py-[15px]"
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
                {!isWebsiteValid(selectedTeamProfileEditState.currentData.website) && (
                  <div className="mb-4 mt-[-6px] text-sbase font-semibold text-error">Enter a valid website</div>
                )}
                <CustomTextAreaWithIcon
                  resizeTextArea={true}
                  id="team-yelp"
                  maxLength={1000}
                  name="team-yelp"
                  placeholder="E.g. yelp.com/your-business"
                  icon={<YelpIcon className={'h-4 w-4'} />}
                  value={selectedTeamProfileEditState.currentData.listings.yelp}
                  isInputValid={isYelpLinkValid(selectedTeamProfileEditState.currentData.listings.yelp)}
                  onChange={onYelpWebsiteChange}
                  className="mb-4 py-[15px]"
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
                {!isYelpLinkValid(selectedTeamProfileEditState.currentData.listings.yelp) && (
                  <div className="mb-4 mt-[-6px] text-sbase font-semibold text-error">Enter a valid yelp link</div>
                )}
                <CustomTextAreaWithIcon
                  resizeTextArea={true}
                  id="team-fb"
                  maxLength={1000}
                  icon={<FacebookIcon className={'h-4 w-4'} />}
                  name="team-fb"
                  placeholder="E.g. facebook.com/your-business"
                  value={selectedTeamProfileEditState.currentData.listings.facebook}
                  onChange={onFacebookChange}
                  className="mb-4 py-[15px]"
                  isInputValid={isFacebookLinkValid(selectedTeamProfileEditState.currentData.listings.facebook)}
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
                {!isFacebookLinkValid(selectedTeamProfileEditState.currentData.listings.facebook) && (
                  <div className="mb-4 mt-[-6px] text-sbase font-semibold text-error">Enter a valid facebook link</div>
                )}
                <CustomTextAreaWithIcon
                  resizeTextArea={true}
                  id="create-business-other"
                  maxLength={1000}
                  name="other-website"
                  icon={<WebsiteIcon className={'h-4 w-4'} />}
                  placeholder="E.g. bing.com/your-business"
                  onChange={onOtherWebsiteChange}
                  value={selectedTeamProfileEditState.currentData.listings.otherOnlineReferrence}
                  className="mb-4 py-[15px]"
                  disabled={!isEditPermission}
                  onClick={() => {
                    if (!isEditPermission) {
                      showPermissionWarning();
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </If>

      {(isTeamProfileDataChangedSelector || isImageUpdated) &&
        selectedTeamProfileEditStateLoadingComplete &&
        !getProfessionsListFetching && (
          <div className={'bg-red fixed bottom-0 z-[11] h-[80px] w-full bg-primary'}>
            <Divider variant="fullWidth" className="bg-borderGray" />

            <div className={'fixed bottom-[22px] right-[80px] bg-primary lg:mr-[360px]'}>
              <div className=" flex h-full flex-row  items-center ">
                <CustomButton
                  id="create-invoice-pdf-preview"
                  onClick={() => {
                    const professionObject = getProfessionObject(selectedTeamProfileEditState.originalData.profession);
                    dispatch(revertCurrentData({ ...selectedTeamProfileEditState.originalData, professionObject }));
                  }}
                  className="h-[38px] px-7"
                  type={ButtonType.SECONDARY}>
                  Cancel
                </CustomButton>
                <CustomButton
                  id="create-invoice-preview-create-and-share"
                  disabled={!isFormValid}
                  className="w-30 ml-[20px] h-[38px] px-10"
                  onClick={() => {
                    if (isTeamNameValid) editAndShareHandler();
                  }}>
                  Save
                </CustomButton>
              </div>
            </div>
          </div>
        )}

      {selectedTeamProfileEditStateLoadingComplete && (
        <EditTeamProfileAddressModal
          open={openEditAddressModal}
          updateAddress={updateAddress}
          initialAddress={selectedTeamProfileEditState.currentData.address}
          onClose={() => setOpenEditAddressModal(false)}
          pageState={editAddressState}
        />
      )}
    </>
  );
};
export default TeamProfile;
