import { MerchantTeam } from 'containers/app/app.model';

export const getOnboardingStatus = (currentTeam: MerchantTeam) => {
  const { unitProvisionStatus, isUnitEnabled } = currentTeam;

  if (unitProvisionStatus === 'APPROVED' && isUnitEnabled === true) {
    return 'APPROVED';
  }
  if (unitProvisionStatus === 'WAITING_FOR_DOCUMENTS') {
    return 'WAITING_FOR_DOCUMENTS';
  } else if ((unitProvisionStatus === 'IN_PROGRESS' && isUnitEnabled === true) || unitProvisionStatus === 'DECLINED') {
    return 'IN_PROGRESS';
  }
  return 'NOT_APPROVED';
};
