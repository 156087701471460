import { PayoutStatus } from './payouts.model';

export const PayoutStatusFilter = [
  {
    key: PayoutStatus.SUCCESS,
    value: 'Success'
  },
  {
    key: PayoutStatus.ON_HOLD,
    value: 'On hold'
  },
  {
    key: PayoutStatus.PENDING,
    value: 'In transit'
  }
];

export enum PayoutScheduleType {
  INSTANT = 'INSTANT',
  DAILY = 'DAILY',
  OFF = 'OFF'
}

export enum ConnectPayoutStatus {
  INITIATED = 'INITIATED',
  IN_TRANSIT = 'IN_TRANSIT',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  RETRYING = 'RETRYING',
  PAUSED = 'PAUSED',
  SCHEDULED = 'SCHEDULED'
}
export const connectPayoutStatusFilter = [
  {
    key: ConnectPayoutStatus.SUCCESS,
    value: 'Success'
  },
  {
    key: ConnectPayoutStatus.INITIATED,
    value: 'Initiated'
  },
  {
    key: ConnectPayoutStatus.IN_TRANSIT,
    value: 'In transit'
  },
  {
    key: ConnectPayoutStatus.CANCELLED,
    value: 'Cancelled'
  },
  {
    key: ConnectPayoutStatus.PAUSED,
    value: 'Paused'
  },
  {
    key: ConnectPayoutStatus.FAILED,
    value: 'Failed'
  }
];

export enum PayoutType {
  AUTO = 'AUTOMATIC',
  MANUAL = 'MANUAL'
}

export enum PaymentSystem {
  LIP = 'LIP',
  LBP = 'LBP',
  CA = 'CA'
}

export enum TIMELINE_EVENTS {
  INITIATED = 'INITIATED',
  IN_TRANSIT = 'IN_TRANSIT',
  SUCCESS = 'SUCCESS',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
  RETRYING = 'RETRYING',
  PAUSED = 'PAUSED',
  EXPECTED_TO_ARRIVE = 'EXPECTED_TO_ARRIVE'
}

export enum PAYOUT_PANEL_PAGES {
  NOTES = 'NOTES',
  CONNECT_PAYOUT = 'CONNECT_PAYOUT',
  INSTANT_PAYOUT = 'INSTANT_PAYOUT'
}

export enum PAYOUT_TXNS_PAGES {
  FILTERS = 'FILTERS',
  PAYOUT_TXNS = 'PAYOUT_TXNS'
}

export enum PAYOUT_TXNS_TABS {
  PAYMENTS = 'PAYMENTS',
  ALL = 'ALL'
}

export enum PAYOUT_TXNS_TYPE_FILTERS {
  COLLECTED = 'COLLECTED',
  REFUNDS = 'REFUNDS',
  ADJUSTMENTS = 'ADJUSTMENTS',
  FEES = 'FEES',
  ALL = 'ALL',
  CREDITS = 'CREDITS'
}

export enum PAYOUT_TXNS_AMOUNT_FILTERS {
  GREATER_THAN = 'Greater than',
  LESS_THAN = 'Less than',
  IN_BETWEEN = 'In between',
  EQUAL_TO = 'Equal to',
  ALL = 'All'
}

export const PAYOUT_TXNS_AMOUNT_FILTERS_ARRAY = [
  { title: PAYOUT_TXNS_AMOUNT_FILTERS.ALL },
  { title: PAYOUT_TXNS_AMOUNT_FILTERS.GREATER_THAN },
  { title: PAYOUT_TXNS_AMOUNT_FILTERS.LESS_THAN },
  { title: PAYOUT_TXNS_AMOUNT_FILTERS.IN_BETWEEN },
  { title: PAYOUT_TXNS_AMOUNT_FILTERS.EQUAL_TO }
];

export enum CTA_TYPES {
  URL = 'URL',
  DEEPLINK = 'DEEPLINK'
}

export enum PayoutRootPage {
  LIST = 'list',
  BALANCE = 'balance'
}

export enum EnablePayoutStatuses {
  NOT_STARTED = 'NOT_STARTED',
  STARTED_IN_PROGRESS = 'STARTED_IN_PROGRESS',
  SUBMITTED_IN_REVIEW = 'SUBMITTED_IN_REVIEW',
  SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED = 'SUBMITTED_NEED_MORE_PAYMENT_NOT_ALLOWED',
  SUBMITTED_NEED_MORE_PAYMENT_ALLOWED = 'SUBMITTED_NEED_MORE_PAYMENT_ALLOWED',
  APPROVED = 'APPROVED',
  APPROVED_CONDITIONAL = 'APPROVED_CONDITIONAL',
  REJECTED = 'REJECTED'
}

export enum ApplicationStatusPages {
  APPLICATION_STATUS = 'APPLICATION_STATUS',
  STRIPE_PENDING_DOC = 'STRIPE_PENDING_DOC'
}

export enum ActionNeededTypes {
  BANK = 'BANK',
  KYC = 'KYC'
}

export enum ConnectBankStatuses {
  SUBMITTED = 'SUBMITTED',
  NOT_SUBMITTED = 'NOT_SUBMITTED'
}

export enum InternalBankStatuses {
  FINANCIAL_DATA_NOT_SUBMITTED = 'FINANCIAL_DATA_NOT_SUBMITTED',
  FINANCIAL_DATA_SUBMITTED = 'FINANCIAL_DATA_SUBMITTED',
  FINANCIAL_DATA_VERIFIED = 'FINANCIAL_DATA_VERIFIED',
  FINANCIAL_DATA_REJECTED = 'FINANCIAL_DATA_REJECTED'
}

export enum BankAccountCardType {
  ADD_BANK_ACCOUNT = ' ADD_BANK_ACCOUNT',
  ACTION_NEEDED = 'ACTION_NEEDED',
  VERIFICATION_IN_PROGRESS = 'VERIFICATION_IN_PROGRESS'
}
