import { DisputeTableHeader } from './disputes.model';

export enum DISPUTE_STATUS {
  IN_REVIEW = 'In Review',
  NEEDS_RESPONSE = 'Needs Response',
  WON = 'Won',
  LOST = 'Lost',
  EVIDENCE_SUBMITTED = 'Evidence Submitted',
  NEEDS_ATTENTION = 'Needs Attention',
  PAST_DUE = 'Past Due'
}

export const disputeSearchTypes = [
  {
    id: 'dispute-search-option-related-transaction',
    text: 'Related transaction',
    searchText: 'Search with related transaction ID',
    type: 'transaction_id'
  },
  {
    id: 'dispute-search-option-invoice-number',
    text: 'Invoice no.',
    searchText: 'Search with invoice number',
    type: 'invoice_number'
  },
  {
    id: 'dispute-search-option-customer',
    text: 'Customer',
    searchText: 'Search with customer name',
    type: 'customer_name'
  }
];

export enum DISPUTE_TAB_TITLE {
  ALL_DISPUTES = 'All disputes',
  NEEDS_RESPONSE = 'Needs response',
  IN_REVIEW = 'In review',
  WON = 'Won',
  LOST = 'Lost'
}

export const DISPUTE_TAB_HEADS = [
  {
    id: 1,
    title: DISPUTE_TAB_TITLE.NEEDS_RESPONSE
  },
  {
    id: 2,
    title: DISPUTE_TAB_TITLE.IN_REVIEW
  },
  {
    id: 3,
    title: DISPUTE_TAB_TITLE.ALL_DISPUTES
  },
  {
    id: 4,
    title: DISPUTE_TAB_TITLE.WON
  },
  {
    id: 5,
    title: DISPUTE_TAB_TITLE.LOST
  }
];

export const disputeReasonFilters = [
  {
    key: 'Duplicate',
    value: 'Duplicate'
  },
  {
    key: 'Fraudulent',
    value: 'Fraudulent'
  },
  {
    key: 'Subscription cancelled',
    value: 'Subscription cancelled'
  },
  {
    key: 'Product unacceptable',
    value: 'Product unacceptable'
  },
  {
    key: 'Product not received',
    value: 'Product not received'
  },
  {
    key: 'Unrecognized',
    value: 'Unrecognized'
  },
  {
    key: 'Credit not processed',
    value: 'Credit not processed'
  },
  {
    key: 'Incorrect account details',
    value: 'Incorrect account details'
  },
  {
    key: 'Insufficient funds',
    value: 'Insufficient funds'
  },
  {
    key: 'Bank cannot process',
    value: 'Bank cannot process'
  },
  {
    key: 'Debit not authorised',
    value: 'Debit not authorised'
  },
  {
    key: 'General',
    value: 'General'
  },
  {
    key: 'Customer initiated',
    value: 'Customer initiated'
  }
];

export enum DISPUTE_TABLE_HEADS {
  DISPUTE_ID = 'id',
  STATUS = 'merchantStatus',
  AMOUNT = 'amount',
  REASON = 'reasonForDispute',
  PAYMENT_DATE = 'formattedPaidAt',
  CUSTOMER = 'customerName',
  PAYMENT_METHOD = 'paymentMethod'
}

//TODO:Update with api model
export const disputeTableHeaders: DisputeTableHeader[] = [
  {
    label: 'Dispute ID',
    value: DISPUTE_TABLE_HEADS.DISPUTE_ID
  },
  {
    label: 'Status',
    value: DISPUTE_TABLE_HEADS.STATUS
  },
  {
    label: 'Amount',
    value: DISPUTE_TABLE_HEADS.AMOUNT
  },
  {
    label: 'Reason',
    value: DISPUTE_TABLE_HEADS.REASON
  },
  {
    label: 'Payment date',
    value: DISPUTE_TABLE_HEADS.PAYMENT_DATE,
    type: 'date'
  },
  {
    label: 'Customer',
    value: DISPUTE_TABLE_HEADS.CUSTOMER,
    columnStyle: 'truncate w-32'
  },
  {
    label: 'Payment method',
    value: DISPUTE_TABLE_HEADS.PAYMENT_METHOD
  }
];

export const respondDisputesSteps = [
  {
    id: 1,
    name: 'Respond to dispute'
  },
  {
    id: 2,
    name: 'Provide evidence'
  },
  {
    id: 3,
    name: 'Review & Submit'
  }
];

export enum DISPUTE_RESPONSE {
  CHALLENGE = 'challenge',
  ACCEPT = 'accept'
}

export enum DISPUTE_REASONS {
  CUSTOMER_WITHDREW_DISPUTE = 'The customer withdrew the dispute',
  CUSTOMER_REFUNDED = 'The customer was refunded',
  TRANSACTION_NON_REFUNDABLE = 'The transaction was non-refundable',
  PAST_DISPUTE_PERIOD = 'The dispute was made past the return or cancellation period of terms',
  CUSTOMER_RECEIVED_CREDIT = 'The customer received a credit or voucher',
  CUSTOMER_RECEIVED_SERVICE = 'The customer received the product or service',
  VALID_PURCHASE = 'The purchase was made by the rightful cardholder',
  OTHER = 'Other'
}

export const disputeReasons = [
  {
    id: 'dispute-reason-1',
    value: DISPUTE_REASONS.CUSTOMER_WITHDREW_DISPUTE
  },
  {
    id: 'dispute-reason-2',
    value: DISPUTE_REASONS.CUSTOMER_REFUNDED
  },
  {
    id: 'dispute-reason-3',
    value: DISPUTE_REASONS.TRANSACTION_NON_REFUNDABLE
  },
  {
    id: 'dispute-reason-4',
    value: DISPUTE_REASONS.PAST_DISPUTE_PERIOD
  },
  {
    id: 'dispute-reason-5',
    value: DISPUTE_REASONS.CUSTOMER_RECEIVED_CREDIT
  },
  {
    id: 'dispute-reason-6',
    value: DISPUTE_REASONS.CUSTOMER_RECEIVED_SERVICE
  },
  {
    id: 'dispute-reason-7',
    value: DISPUTE_REASONS.VALID_PURCHASE
  },
  {
    id: 'dispute-reason-8',
    value: DISPUTE_REASONS.OTHER
  }
];

export enum DISPUTE_RECOMMENDED_DOCUMENT_NAME {
  CUSTOMER_COMMUNICATION = 'Customer communication',
  CUSTOMER_SIGNATURE = 'Customer signature',
  SERVICE_DOCUMENTATION = 'Service documentation',
  OTHER = 'Other',
  INVOICE = 'Invoice',
  RECEIPT = 'Receipt'
}

export enum DISPUTE_RECOMMENDED_DOCUMENT_TYPE {
  CUSTOMER_COMMUNICATION = 'customer_communication',
  CUSTOMER_SIGNATURE = 'customer_signature',
  SERVICE_DOCUMENTATION = 'service_documentation',
  OTHER = 'other',
  INVOICE = 'invoice',
  RECEIPT = 'receipt'
}

export const recommendedDocumentsList = [
  {
    id: 'recomended-document-item-1',
    name: DISPUTE_RECOMMENDED_DOCUMENT_NAME.CUSTOMER_COMMUNICATION,
    type: DISPUTE_RECOMMENDED_DOCUMENT_TYPE.CUSTOMER_COMMUNICATION
  },
  {
    id: 'recomended-document-item-2',
    name: DISPUTE_RECOMMENDED_DOCUMENT_NAME.CUSTOMER_SIGNATURE,
    type: DISPUTE_RECOMMENDED_DOCUMENT_TYPE.CUSTOMER_SIGNATURE
  },
  {
    id: 'recomended-document-item-3',
    name: DISPUTE_RECOMMENDED_DOCUMENT_NAME.SERVICE_DOCUMENTATION,
    type: DISPUTE_RECOMMENDED_DOCUMENT_TYPE.SERVICE_DOCUMENTATION
  },
  {
    id: 'recomended-document-item-4',
    name: DISPUTE_RECOMMENDED_DOCUMENT_NAME.OTHER,
    type: DISPUTE_RECOMMENDED_DOCUMENT_TYPE.OTHER
  },
  {
    id: 'recomended-document-item-5',
    name: DISPUTE_RECOMMENDED_DOCUMENT_NAME.INVOICE,
    type: DISPUTE_RECOMMENDED_DOCUMENT_TYPE.INVOICE
  },
  {
    id: 'recomended-document-item-6',
    name: DISPUTE_RECOMMENDED_DOCUMENT_NAME.RECEIPT,
    type: DISPUTE_RECOMMENDED_DOCUMENT_TYPE.RECEIPT
  }
];

export enum EVIDENCE_DOCUMENT_NAME {
  CUSTOMER_COMMUNICATION = 'Customer communication',
  CUSTOMER_SIGNATURE = 'Customer signature',
  DUPLICATE_CHARGE_DOCUMENTATION = 'Duplicate charge documentation',
  RECEIPT = 'Receipt',
  INVOICE = 'Invoice',
  REFUND_AND_CANCELLATION_POLICY = 'Refund and cancellation policy',
  SERVICE_DOCUMENTATION = 'Service documentation',
  SHIPPING_DOCUMENTATION = 'Shipping documentation',
  CREDIT_VOUCHER = 'Credit voucher',
  GOVERNMENT_ORDER = 'Government order',
  TERMS_DISCLOSURE = 'Terms disclosure',
  OTHER = 'Other'
}

export enum EVIDENCE_DOCUMENT_TYPE {
  CUSTOMER_COMMUNICATION = 'customer_communication',
  CUSTOMER_SIGNATURE = 'customer_signature',
  DUPLICATE_CHARGE_DOCUMENTATION = 'duplicate_charge_documentation',
  RECEIPT = 'receipt',
  INVOICE = 'invoice',
  REFUND_AND_CANCELLATION_POLICY = 'refund_and_cancellation_policy',
  SERVICE_DOCUMENTATION = 'service_documentation',
  SHIPPING_DOCUMENTATION = 'shipping_documentation',
  CREDIT_VOUCHER = 'credit_voucher',
  GOVERNMENT_ORDER = 'government_order',
  TERMS_DISCLOSURE = 'terms_disclosure',
  OTHER = 'other'
}

export const evidenceDocumentTypes = [
  {
    id: 'evidence-document-type-1',
    name: EVIDENCE_DOCUMENT_NAME.CREDIT_VOUCHER,
    type: EVIDENCE_DOCUMENT_TYPE.CREDIT_VOUCHER
  },
  {
    id: 'evidence-document-type-2',
    name: EVIDENCE_DOCUMENT_NAME.CUSTOMER_COMMUNICATION,
    type: EVIDENCE_DOCUMENT_TYPE.CUSTOMER_COMMUNICATION
  },
  {
    id: 'evidence-document-type-3',
    name: EVIDENCE_DOCUMENT_NAME.CUSTOMER_SIGNATURE,
    type: EVIDENCE_DOCUMENT_TYPE.CUSTOMER_SIGNATURE
  },
  {
    id: 'evidence-document-type-4',
    name: EVIDENCE_DOCUMENT_NAME.DUPLICATE_CHARGE_DOCUMENTATION,
    type: EVIDENCE_DOCUMENT_TYPE.DUPLICATE_CHARGE_DOCUMENTATION
  },
  {
    id: 'evidence-document-type-5',
    name: EVIDENCE_DOCUMENT_NAME.GOVERNMENT_ORDER,
    type: EVIDENCE_DOCUMENT_TYPE.GOVERNMENT_ORDER
  },
  {
    id: 'evidence-document-type-6',
    name: EVIDENCE_DOCUMENT_NAME.INVOICE,
    type: EVIDENCE_DOCUMENT_TYPE.INVOICE
  },
  {
    id: 'evidence-document-type-7',
    name: EVIDENCE_DOCUMENT_NAME.RECEIPT,
    type: EVIDENCE_DOCUMENT_TYPE.RECEIPT
  },
  {
    id: 'evidence-document-type-8',
    name: EVIDENCE_DOCUMENT_NAME.REFUND_AND_CANCELLATION_POLICY,
    type: EVIDENCE_DOCUMENT_TYPE.REFUND_AND_CANCELLATION_POLICY
  },
  {
    id: 'evidence-document-type-9',
    name: EVIDENCE_DOCUMENT_NAME.SERVICE_DOCUMENTATION,
    type: EVIDENCE_DOCUMENT_TYPE.SERVICE_DOCUMENTATION
  },
  {
    id: 'evidence-document-type-10',
    name: EVIDENCE_DOCUMENT_NAME.SHIPPING_DOCUMENTATION,
    type: EVIDENCE_DOCUMENT_TYPE.SHIPPING_DOCUMENTATION
  },
  {
    id: 'evidence-document-type-11',
    name: EVIDENCE_DOCUMENT_NAME.TERMS_DISCLOSURE,
    type: EVIDENCE_DOCUMENT_TYPE.TERMS_DISCLOSURE
  },
  {
    id: 'evidence-document-type-12',
    name: EVIDENCE_DOCUMENT_NAME.OTHER,
    type: EVIDENCE_DOCUMENT_TYPE.OTHER
  }
];

export const disputeStatusFilters = [
  { key: DISPUTE_STATUS.NEEDS_RESPONSE },
  { key: DISPUTE_STATUS.IN_REVIEW },
  { key: DISPUTE_STATUS.PAST_DUE },
  { key: DISPUTE_STATUS.EVIDENCE_SUBMITTED },
  { key: DISPUTE_STATUS.NEEDS_ATTENTION },
  { key: DISPUTE_STATUS.WON },
  { key: DISPUTE_STATUS.LOST }
];

export enum DisputeInternalStatus {
  LOST_ACCEPTED = 'Merchant accepted'
}
