import { FC, ReactNode } from 'react';
import { VoidFn } from 'types/baseTypes';
import OutsideClickHandler from 'react-outside-click-handler';

interface OutsideClickHandlerProps {
  onOutsideClick: VoidFn;
  children: ReactNode;
}

const OutsideClickHandle: FC<OutsideClickHandlerProps> = ({ onOutsideClick, children }) => {
  return <OutsideClickHandler onOutsideClick={onOutsideClick}>{children}</OutsideClickHandler>;
};

export default OutsideClickHandle;
