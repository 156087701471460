/* eslint-disable max-len */
/* eslint-disable no-useless-escape */
export const IS_AUTHENTICATED = 'isAuthenticated';

export const DATE_FORMAT = 'dd MMM yyyy';

export const DATE_TIME_FORMAT = 'dd MMM yyyy, hh:mm:ss a';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';

export const DEFAULT_TIME_FORMAT = 'hh:mm:ss a';

export const DATE_TIME_NO_SEC_FORMAT = 'dd MMM yyyy, hh:mm a';

export const REGEX = {
  email:
    /^[a-zA-Z0-9._%+-]+@(?!.*(\.\.|\.con|\.comn|\.cok|\.don|\.cim|gnail\.com|gmal\.com))([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/i,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
  name: /^[a-zA-Z][a-zA-Z .]*$/,
  noWhiteSpace: /^(\S+$)/g,
  amount: /^(\d{0,5}(\.\d{0,2})?)$/,
  replceTrailingZeros: /^([\d,]+)$|^([\d,]+)\.0*$|^([\d,]+\.[0-9]*?)0*$/,

  // maximum 14 digits
  maximumTwoDecimals: /^(\d{0,14}(\.\d{0,2})?)$/,

  positiveWholeNumber: /^(\d{0,14})$/,
  exactlyThreeDecimals: /^[0-9]*\.[0-9]{3}$/,
  percentage: /^(\d{0,2}(\.\d{0,2})?)$|^([1][0][0])$/,
  percentageWithThreeDecimals: /^(\d{0,2}(\.\d{0,3})?)$/,

  decimalPoint: /^\.$/,
  phoneNumber: /^(\+91\d{10})$|^(\+63\d{10})$|^(\+1\d{10})$|^(\d{10})$/,
  usIndianNumber: /^(\+\d{11,12})$|^(\d{10})$/,
  phoneNumberInput:
    /^(\+(\d{1,2})?[\s]?\(?\d{0,3}\)?[\s-]?\d{0,3}[\s-]?\d{0,4})$|^([\s]?\(?\d{0,3}\)?[\s-]?\d{0,3}[\s-]?\d{0,5})$/,
  formatNumber: /^(1|)?(\d{3})(\d{3})(\d{4})$/,
  // detects anything other than a number
  nonNumber: /[^\d]/g,

  // regex for detecting anything other than  a number or dot
  nonNumberRegExp: /[^0-9.]/g,

  //regex for individual password characteristics
  containsUpperCase: /[A-Z]/,
  containsLowerCase: /[a-z]/,
  containsDigit: /[0-9]/,
  containsSpecialChar: /[!@#\$%\^&\*]/,
  facebookLinkRegex:
    /(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/,
  yelpLinkRegex:
    /(?:(?:http|https):\/\/)?(?:www.)?yelp.com\/biz\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/,

  websiteRegex: /(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+/
};

// !TODO replace this
export const WEB_V1 = 'web-app/v1';

export const API_VERSION = {
  WEB_V1: 'web-app/v1',
  BANK_V1: 'banking/master/v1',
  V1: 'v1',
  V2: 'v2',
  V3: 'v3',
  V4: 'v4',
  V5: 'v5',
  V6: 'v6'
};

export const API_SOURCE = {
  WEB: 'Web'
};

export enum PopoverArrowTypes {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left'
}

export enum TxnCardTypes {
  INVOICE = 'invoice',
  PAYOUT = 'payout',
  PAYOUT_DETAILS = 'payout details'
}

export enum TxnCardIconTypes {
  STATUS = 'Status',
  PAYMENT_METHOD = 'Payment Method'
}

export enum PaymentMethods {
  CARD = 'Credit / Debit Card',
  GOOGLEPAY = 'Google Pay',
  APPLEPAY = 'Apple Pay',
  GRABPAY = 'Grab Pay',
  PAYPAL = 'PayPal',
  PAYPAL_SCAN = 'paypal-scan',
  SPLITIT = 'Splitit',
  SGQR = 'SGQR',
  ACH = 'ACH',
  ACHBETA = 'ACH Beta',
  VENMO = 'Venmo',
  VENMO_SCAN = 'venmo-scan',
  CASHAPP = 'Cash App',
  KLARNA = 'Klarna',
  TINK = 'Tink',
  TRUELAYER = 'TrueLayer',
  CLICKTOPAY = 'Click To Pay',
  DEBIT_CARD = 'Credit / Debit Card',
  CREDIT_CARD = 'Credit / Debit Card',
  CASH_MANUAL = 'Cash',
  CHECK_MANUAL = 'Check',
  DIRECT_TRANSFER_MANUAL = 'Direct Transfer',
  OTHER_MANUAL = 'Other',
  MANUAL_KEY_IN = 'manual-key-in'
}

export const searchTypes = [
  {
    id: 'search-option-invoice-no',
    text: 'Invoice no.',
    searchText: 'Search with invoice number',
    type: 'invoiceNumber'
  },
  {
    id: 'search-option-receipt-no',
    text: 'Receipt no.',
    searchText: 'Search with receipt number',
    type: 'receiptNumber'
  },
  {
    id: 'search-option-card-no',
    text: 'Card no.',
    searchText: 'Search with Card no. (Last 4 digits)',
    type: 'cardNumber'
  },
  {
    id: 'search-option-customer',
    text: 'Customer',
    searchText: 'Search with customer',
    type: 'customer'
  }
];

export enum LocalStorageKeysEnum {
  CURRENT_SIGN_IN_METHOD = 'currentSignInMethod',
  USER_DETAILS = 'userDetails'
}

export const inValidDomains = ['..', '.con', '.comn', '.cok', '.don', '.cim', 'gnail.com', 'gmal.com'];

export const preventDefaultFn = (event: Event) => event.preventDefault();

export enum FILE_UPLOAD_SIZE {
  SIZE_8_MB_BYTES = 8388608,
  SIZE_5_MB_BYTES = 5242880,
  SIZE_1_MB = 1048576,
  SIZE_2_MB = 2097152,
  SIZE_1_POINT_5_MB = 1572864
}

export enum FILE_TYPE {
  PNG = 'image/png'
}

export enum DispatchDestination {
  SERVICE_CHARGE_SLICE = 'service_charge_slice',
  ITEMS_SLICE = 'items_slice',
  INVOICE_SLICE = 'invoice_slice',
  EDIT_INVOICE_SLICE = 'edit_invoice_slice'
}

export const nullFn = () => null;

export enum ECharLimits {
  INPUT_FIELD_DEFAULT = 50
}

export enum AmountFilterOperation {
  ALL = 'all',
  EQ = 'eq',
  LT = 'lt',
  GT = 'gt',
  BW = 'bw'
}

export enum WebviewFunctions {
  CLOSE_WEBVIEW = 'closeWebview',
  CLOSE_WEBVIEW_CHECKOUT = 'closeWebView',
  UPDATE_BANK_STATUS = 'updateBankAccountStatus',
  WEBVIEW_LOADED = 'webviewLoaded',
  ROUTE_TO_SHARE_INVOICE = 'routeToShareInvoice',
  ROUTE_TO_INVOICE_DETAIL = 'routeToInvoiceDetail'
}

export const invoiceMinimumAmount = 0.5;

export enum ONBOARDING_URL_SOURCES {
  HOME = 'home',
  PAYOUT_SETTINGS = 'payout-settings'
}

export enum ONBOARDING_URL_PARAMS {
  FROM = 'from'
}
