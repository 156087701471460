import { TNC } from 'containers/discount-tips-fees/discountTipsFees.model';
import { AggregatedLineItemLevelTaxResponse } from './edit-invoice/invoicesEditSlice';
import { GenericPaginationResponse } from 'types/baseTypes';
import { AbstractITax } from 'containers/product-catalog/taxes/taxes.model';
import { InvoiceSourceEnum, InvoiceVersion } from './constants';
import { FeatureToggleState } from 'containers/discount-tips-fees/constants';

export interface GetInvoicesResponse {
  invoices: IInvoice[];
  totalCount: number;
}

export interface ImageUploadResponse {
  imageUrl: string;
}

export interface IInvoice {
  // TODO additionalTax: remove related fields by replacing it with service charge
  invoiceId: string;
  invoiceNumber: string;
  createdAt: string;
  status: InvoiceStatus;
  isPaid: boolean;
  amount: string;
  surchargePercentage: string;
  additionalTaxPercentage: string;
  billAmount: string;
  surchargeAmount: string;
  additionalTaxAmount: string;
  tipAmount: string;
  payableAmount: string;
  paymentMethod: string;
  merchantId: string;
  merchantProfilePicureUrl: string;
  merchantName: string;
  merchantBrandName: string;
  merchantBusinessName: string;
  merchantPhone: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  lastTrnxDate: string;
  isModifiable: boolean;
}

export interface Member {
  id: string;
  name: string;
  profilePicture: string;
  noOfTxns: number;
  totalAmount: string;
  tip: string;
}

export interface InvoiceByIdPayload {
  teamId: number;
  invoiceId: string;
  isFromEdit?: boolean;
  includeTransactions?: boolean;
}

export interface TaxesAndFeesPayload {
  teamId: number;
}

export interface IServiceChargeTaxFromAPIResponse extends AbstractITax {
  id: number; // tax maintained in the invoice table
  catalogueTaxId: number; // the particular tax's id in the catalog
  name: string;
  percentage: string;
  amount?: number;
}

export interface LineItemTaxFromAPIResponse {
  id: number; // tax maintained in the invoice table
  catalogueTaxId: number; // the particular tax's id in the catalog
  name: string;
  percentage: string;
  amount?: number;
}

export interface LineItem {
  catalogueLineItemId: number;
  id: number; // id -> index of a line item that we use in the front-end
  name: string;
  qty: string;
  amount: string;
  unitPrice: string;
  additionalDetails: string;
  taxes?: LineItemTaxFromAPIResponse[];
  sku?: string;
}

export interface IInvoiceServiceCharge {
  id: number;
  name: string;
  amount: string;
  percentage: string;
  taxes: AbstractITax[];
  totalAmount?: string;
}

export interface IInvoiceServiceChargeFromResponse {
  id: number;
  name: string;
  amount: string;
  percentage: string;
  taxes?: IServiceChargeTaxFromAPIResponse[];
  totalAmount?: string;
}

export interface InvoiceByIdResponse {
  invoiceId: string;
  invoiceNumber: string;
  invoiceStatus: InvoiceStatus;
  comment: string;
  customer: Customer;
  createdAt: string;
  createdTime: string;
  paidDate: string;
  paidTime: string;
  currencySymbol: string;
  transactionId: string;
  transactionStatus: string;
  paymentMethod: string;
  amount: string;
  tipAmount: string;
  billAmount: string;
  surchargeAmount: string;
  errorCode: string;
  storeName: string;
  merchantName: string;
  merchantTaxId: string;
  merchantAddress: string;
  merchantPhoneNumber: string;
  isPaid: boolean;
  url: string;
  qr: string;
  hasSurcharge: boolean;
  hasTip: boolean;
  invoiceMessage: string;
  payoutStatus: string;
  payoutCreatedBy: string;
  hasAdditionalTax: boolean; // unused?
  lineItems: LineItem[];
  teamIcon: string;
  surcharge: Surcharge;
  merchantEmail: string;
  merchantWebsite: string;
  tipPercentage: string;
  payableAmount: string;
  discount: string;
  discountPercentage: string;
  attachments: Attachment[];
  merchantProfilePicture: string;
  isEditable: boolean;
  merchantId: string;
  hasDiscount: boolean;
  dueDate: string;
  isManualPayment: boolean;
  createdBy: string;
  receiptQr: string;
  version: string;
  qrId: string;
  transactionDetails: TransactionDetails;
  isModifiable: boolean;
  tnc: TNC;
  taxes: AggregatedLineItemLevelTaxResponse[];
  serviceCharges: IInvoiceServiceChargeFromResponse[];
  isDiscountAmountSelected: boolean;
  isInvoiceSigned: boolean;
  isSignatureEnabled: boolean;
  signatureCustomerName: string;
  signedAt: string;
  signaturePresignedUrl: string;
  isServiceFeeEnabled: boolean;
  timeline: InvoiceTimelineEvent[];
  timelineStatus: InvoiceStatus;
  receiptUrl: string;
}

export interface Customer {
  id: string;
  ID: string;
  name: string;
  phoneNumber: string;
  email: string;
  merchantId: string;
  billingAddress: string;
  lastName: string;
  street: string;
  cityStateCountry: string;
  zipCode: string;
}

export interface AdditionalTax {
  id: number;
  name: string;
  percentage: string;
}

export interface Surcharge {
  enabled: boolean;
  percentage: string;
}

export interface TransactionDetails {
  transactions: Transaction[];
  totalCount: number;
}

export interface Transaction {
  // TODO additionalTax: remove related fields by replacing it with service charge
  id: string;
  status: string;
  amount: string;
  tipAmount: string;
  merchantId: string;
  teamId: number;
  sourceType: string;
  paymentMethod: string;
  reason: string;
  createdAt: string;
  profilePicture: string;
  customerName: string;
  customerPhone: string;
  customerEmail: string;
  merchantRoleFirstName: string;
  merchantRoleLastName: string;
  merchantRolePhone: string;
  merchantBrandName: string;
  merchantName: string;
  merchantBusinessName: string;
  merchantPhone: string;
  parentID: string;
  surchargeAmount: string;
  additionalTaxAmount: string;
  billAmount: string;
  refundTransactionId: string;
  refundTransactions: RefundTransaction[];
  disputeTransactions: DisputeTransaction[];
}

export interface RefundTransaction {
  ID: string;
  paymentMethod: string;
  amount: string;
  status: string;
  createdAt: string;
  reason: string;
  source: string;
  customerName: string;
  profilePicture: string;
  merchantName: string;
  merchantId: string;
  parentID: string;
  refundTransactions: any;
  disputeTransactions: DisputeTransaction[];
}

export interface DisputeTransaction {
  disputeId: string;
  disputeTransactionId: string;
  disputeAmount: string;
  createdAt: string;
  status: string;
}

export interface CustomerByIdPayload {
  customerId: string;
  teamId: number | string;
}

export interface CustomerDetails {
  customer: Customer;
  totalInvoices: number;
  invoices: IInvoice[];
  invoiceSummary: InvoiceSummary;
}

export interface DiscountSettings {
  enabled: boolean;
}

export interface TaxesAndFeesResponse {
  surcharge: Surcharge;
  tip: Tip;
  discount: DiscountSettings;
  tnc: TNC;
}

export interface AdditionalTax {
  id: number;
  name: string;
  percentage: string;
}

export interface Surcharge {
  enabled: boolean;
  percentage: string;
  basePoints: string;
  config: FeatureToggleState;
}

export interface Tip {
  enabled: boolean;
  percentage: string;
}

export interface InvoiceSummary {
  firstPaymentDate: string;
  lastPaymetDate: string;
  amountCollected: number;
  pendingAmount: number;
  paidInvoicesCount: number;
  pendingInvoicesCount: number;
}

export interface Attachment {
  urlLink: string;
  url: string;
  title: string;
  description: string;
}

export enum InvoiceStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  PAID_QR = 'PAID-QR',
  REFUNDED = 'REFUNDED',
  PROCESSING = 'PROCESSING',
  REFUND_INITIATED = 'REFUND_INITIATED'
}

export interface ILineItemTaxRequest {
  catalogueTaxId: number;
  name: string;
  percentage: string;
  amount: string;
}

export interface ILineItemRequest {
  catalogueLineItemId?: number;
  name: string;
  qty: string;
  amount: string;
  unitPrice: string;
  additionalDetails: string;
  taxes?: ILineItemTaxRequest[];
  sku?: string;
}
export interface IInvoiceServiceChargeTaxRequest {
  catalogueTaxId: number;
  name: string;
  percentage: string;
  amount: string;
}

export interface IInvoiceServiceChargeRequest {
  name: string;
  amount: string;
  percentage: string;
  totalAmount: string; // Total service charge excluding the taxes applied on it - base amount
  taxes?: IInvoiceServiceChargeTaxRequest[];
}
export interface IInvoiceAttachmentRequest {
  url: string;
  title: string;
  description?: string;
}

export interface IUniqueTaxRequest {
  name: string;
  percentage: string;
  amount: string;
}

export interface InvoicePayload {
  version: InvoiceVersion;
  teamId: string;
  storeName: string;
  amount: string; //subtotal
  comment: string; //notes
  customerId?: string;
  phoneNumber?: string;
  email?: string;
  customerName?: string;
  billingAddress?: string;
  lineItems: ILineItemRequest[];
  discount?: string;
  discountPercentage?: string;
  isDiscountAmountSelected?: boolean;
  surchargePercentage: string;
  attachments?: IInvoiceAttachmentRequest[];
  tipEnabled: boolean;
  tncId: number;
  taxes?: IUniqueTaxRequest[]; // A Tax could be applied on one or more line item and/or on the service charge
  //  all the items in this list will be unique
  invoiceNumber?: string;
  serviceCharges: IInvoiceServiceChargeRequest[];
  serviceChargeAmount: string;
  source: InvoiceSourceEnum;
  isSignatureEnabled: boolean;
  isServiceFeeEnabled: boolean;
}

export interface EditInvoicePayload {
  invoiceId: string;
  invoicePayload: InvoicePayload;
}

export interface InvoiceResponse {
  qrId: string;
  qr: string;
  url: string;
  invoiceId: string;
  customerId: string;
  isNewCustomer: boolean;
  invoiceMessage: string;
  customer: Customer;
  billAmount: string;
  payableAmount: string;
  checkoutURL: string;
}

export interface GetInvoiceCategoryItemListingsPayload {
  teamId: number;
  search: string;
  limit: number;
  page: number;
}

export interface IInvoiceListingCategory {
  id: number;
  name: string;
  lineItemsCount: number;
}

export interface IInvoiceListingItem {
  id: number;
  name: string;
  category: string;
  unitPrice: string;
  sku: string;
  description: string;
  taxes: AbstractITax[];
}

export interface GetInvoiceCategoryItemListingResponse {
  categories: GenericPaginationResponse<IInvoiceListingCategory>;
  lineItems: GenericPaginationResponse<IInvoiceListingItem>;
}

export interface InvoiceDownloadPayload {
  invoiceId: string;
  teamId: number;
}

export interface InvoiceTimelineEvent {
  title: string;
  subTitle: string;
  timestamp: string;
  doneBy: string;
  colourCode: string;
  webInfo: EventInfo;
  appInfo: EventInfo;
  reason: string;
}

export interface EventInfo {
  summary: boolean;
  cta: Cta;
}

export interface Cta {
  text: string;
  action: string;
  colour: string;
}
