import { ServiceFeeBasePoint } from './type';

export const dropDownServiceFeeBasePoint: ServiceFeeBasePoint[] = [
  { name: '20 cents', value: '20' },
  { name: '10 cents', value: '10' },
  { name: '0 cents', value: '0' }
];

export enum FeatureToggleState {
  ALWAYS_ON = 'always_on',
  ON_BY_DEFAULT = 'on_by_default',
  OFF_BY_DEFAULT = 'off_by_default'
}

export const dropDownSignatureRequestOption: { name: string; value: FeatureToggleState }[] = [
  { name: 'Always On', value: FeatureToggleState.ALWAYS_ON },
  { name: 'On by default', value: FeatureToggleState.ON_BY_DEFAULT },
  { name: 'Off by default', value: FeatureToggleState.OFF_BY_DEFAULT }
];

export const dropDownServiceFeeOption: { name: string; value: FeatureToggleState }[] = [
  { name: 'On by default', value: FeatureToggleState.ON_BY_DEFAULT },
  { name: 'Always On', value: FeatureToggleState.ALWAYS_ON },
  { name: 'Off by default', value: FeatureToggleState.OFF_BY_DEFAULT }
];

export enum CustomizationsUpdateType {
  TIPS = 'tips',
  TNC = 'tnc'
}

export enum PreviewType {
  TIP_PREVIEW = 'TipPreview',
  SURCHARGE_PREVIEW = 'SurchargePreview'
}

export const defaultTerms = 'Thank you for choosing us, please find our Terms & Conditions below-';

export const termsMaxCount = 2000;

export const defaultTipChoices = [10, 20, 30];

export enum TipsError {
  DUPLICATE = 'Tip choice should be unique',
  EMPTY = 'Tip choice cannot be empty',
  MINIMUM = 'Minimum tip choice should be 1%'
}
