import Divider from '@material-ui/core/Divider';
import { CheckboxTickIcon, CircleCloseIcon, DeleteIcon, PlusIconBlue, TaxEmptyIcon } from 'assets/icons';
import {
  CustomButton,
  CustomCheckbox,
  CustomInput,
  CustomModal,
  CustomSwitch,
  ErrorScreenWithRetry,
  If
} from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import { REGEX } from 'constants/common';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamFeePermissions,
  showNotifier
} from 'containers/app/appSlice';
import { IInvoiceServiceCharge } from 'containers/invoices/invoices.model';
import {
  ServiceChargeEntity,
  ServiceChargeEnum,
  ServiceChargeFromInvoiceInputId,
  ServiceChargeInputErrors
} from 'containers/product-catalog/service-charges/types';
import { useLazyGetCatalogFetchTaxesQuery } from 'containers/product-catalog/taxes/api';
import { GetCatalogFetchTaxesPayload, ITaxBasic } from 'containers/product-catalog/taxes/taxes.model';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import {
  EInvoiceCatalogElementType,
  EInvoiceModalType,
  EServiceChargeModalType,
  IInvoiceSaveServiceCharge,
  InvoiceDraftModalData
} from '../types';
import { CreateServiceChargePayload } from 'containers/product-catalog/service-charges/serviceCharge.model';
import { useCreateServiceChargeMutation } from 'containers/product-catalog/service-charges/api';
import { NotifierTypes } from 'constants/notifierConstants';
import { useNavigate } from 'react-router-dom';
import { TaxRouteState } from 'routes/types';
import routesPath from 'routes/RoutesPath';
import { checkIsAccessTokenExpiry } from 'utils/apiUtils';
import { hasValidCatalogueServiceChargeId, prepareTaxListWithAllGlobalTaxes } from 'containers/invoices/utils';
import {
  selectGlobalServiceChargeTaxesInInvoiceSlice,
  updateInvoiceDraftData
} from 'containers/invoices/InvoicesSlice';
import {
  selectGlobalServiceChargeTaxesInInvoiceEditSlice,
  updateInvoiceDraftDataInEditSlice
} from 'containers/invoices/edit-invoice/invoicesEditSlice';
import { getFormattedPercentage, sortSelectedTaxesToStart } from 'containers/product-catalog/taxes/util';
import { InfoMesssages } from 'types/infoMessages';

interface AddServiceChargeModalProps {
  className?: string;
  onClose: VoidFn;
  config: IAddServiceChargeModalConfig;
  onSaveServiceChargeClick: (arg: IInvoiceSaveServiceCharge) => void;
  onModifyServiceChargeClick: (arg: IInvoiceServiceCharge) => void;
  onDeleteFromInvoice: VoidFn;
  isEditInvoiceFlow?: boolean;
}

export interface IAddServiceChargeModalData {
  id: number;
  name: string;
  amount: string;
  percentage: string;
  taxes: ITaxBasic[];
}

export interface IAddServiceChargeModalConfig {
  title: string;
  open: boolean;
  shouldInitializeFields: boolean;
  enableDeleteFromInvoice: boolean;
  enableSaveToCatalog: boolean;
  saveToCatalog?: boolean;
  data: IAddServiceChargeModalData;
  editMode: boolean;
  modalType: EServiceChargeModalType;
  draftFlow?: { isDraftFlow: boolean; serviceChargeType: ServiceChargeEnum };
}

export const serviceChargeModalDataInit: IAddServiceChargeModalData = {
  amount: '',
  percentage: '',
  id: null,
  name: '',
  taxes: []
};

export const addServiceChargeModalConfigInitState: IAddServiceChargeModalConfig = {
  title: 'Add service charge',
  editMode: false,
  open: false,
  shouldInitializeFields: true,
  enableDeleteFromInvoice: false,
  enableSaveToCatalog: false,
  saveToCatalog: false,
  data: serviceChargeModalDataInit,
  modalType: EServiceChargeModalType.ONE_TIME_ADD_SERVICE_CHARGE_MODAL
};

const serviceChargeEntityInitState: ServiceChargeEntity = {
  type: ServiceChargeEnum.CURRENCY,
  value: '',
  id: ServiceChargeFromInvoiceInputId.serviceChargeAmount,
  amount: '',
  percentage: ''
};

const AddServiceChargeModal: FC<AddServiceChargeModalProps> = ({
  config,
  onClose,
  className = '',
  onSaveServiceChargeClick,
  onModifyServiceChargeClick,
  onDeleteFromInvoice,
  isEditInvoiceFlow = false
}) => {
  const dispatch = useAppDispatch();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const globalServiceChargeTaxList = useAppSelector(
    isEditInvoiceFlow ? selectGlobalServiceChargeTaxesInInvoiceEditSlice : selectGlobalServiceChargeTaxesInInvoiceSlice
  );
  const catalogSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);
  const notifications = useAppSelector(getNotifications);

  const navigate = useNavigate();
  const [createServiceChargeMutation, { isLoading: isCreateServiceChargeLoading }] = useCreateServiceChargeMutation();

  const [serviceChargeName, setServiceChargeName] = useState<string>('');
  const [serviceChargeEntity, setServiceChargeEntity] = useState<ServiceChargeEntity>(serviceChargeEntityInitState);
  const [selectedTaxes, setSelectedTaxes] = useState<ITaxBasic[]>([]);
  const [allTaxes, setAllTaxes] = useState<ITaxBasic[]>([]);
  const [saveToCatalog, setSaveToCatalog] = useState<boolean>(false);
  const [isEdited, setIsEdited] = useState<boolean>(false);

  const errorDefaultState = {
    nameError: null,
    amountError: null
  };
  const [errors, setErrors] = useState<Record<string, ServiceChargeInputErrors>>(errorDefaultState);
  const [shouldFocusInput, setShouldFocusInput] = useState<boolean>(false);

  const disableSaveBtn =
    !serviceChargeName ||
    (serviceChargeEntity.type === ServiceChargeEnum.CURRENCY &&
      (!serviceChargeEntity.amount || serviceChargeEntity.amount.replace(REGEX.nonNumber, '') === '')) ||
    (serviceChargeEntity.type === ServiceChargeEnum.PERCENTAGE &&
      (!serviceChargeEntity.percentage || serviceChargeEntity.percentage.replace(REGEX.nonNumber, '') === ''));

  const { manageServiceCharges, manageTaxes } = catalogSettingsPermissions;

  const [
    fetchTaxesBasicDataQuery,
    {
      data: taxesBasicData,
      isFetching: isFetchingTaxes,
      isLoading: isLoadingTaxes,
      isError: isFetchingTaxesError,
      error: fetchTaxError
    }
  ] = useLazyGetCatalogFetchTaxesQuery();

  const taxes = taxesBasicData?.data ?? [];

  useEffect(() => {
    if (config.shouldInitializeFields) {
      setServiceChargeName(config.data.name);
      if (config?.draftFlow?.isDraftFlow) {
        setServiceChargeEntity({
          ...(config?.draftFlow?.serviceChargeType === ServiceChargeEnum.PERCENTAGE
            ? {
                type: ServiceChargeEnum.PERCENTAGE,
                value: config.data.percentage,
                amount: config.data.amount,
                percentage: config.data.percentage,
                id: ServiceChargeFromInvoiceInputId.serviceChargePercentage
              }
            : {
                type: ServiceChargeEnum.CURRENCY,
                value: config.data.amount,
                amount: config.data.amount,
                percentage: config.data.percentage,
                id: ServiceChargeFromInvoiceInputId.serviceChargeAmount
              })
        });
      } else {
        setServiceChargeEntity({
          ...(+config.data.percentage > 0
            ? {
                type: ServiceChargeEnum.PERCENTAGE,
                value: config.data.percentage,
                amount: '',
                percentage: config.data.percentage,
                id: ServiceChargeFromInvoiceInputId.serviceChargePercentage
              }
            : {
                type: ServiceChargeEnum.CURRENCY,
                value: config.data.amount,
                amount: config.data.amount,
                percentage: '',
                id: ServiceChargeFromInvoiceInputId.serviceChargeAmount
              })
        });
      }
      setSelectedTaxes(config.data.taxes ?? []);
      setSaveToCatalog(config.saveToCatalog ?? false);
    } else {
      if (config.modalType === EServiceChargeModalType.ONE_TIME_ADD_SERVICE_CHARGE_MODAL) {
        setSelectedTaxes(globalServiceChargeTaxList);
      }
    }
  }, []);

  useEffect(() => {
    if (shouldFocusInput && serviceChargeEntity.id) {
      const serviceChargeInputElem = document?.getElementById(serviceChargeEntity.id);
      if (serviceChargeInputElem) {
        serviceChargeInputElem.focus();
        setShouldFocusInput(false);
      }
    }
  }, [serviceChargeEntity.id, shouldFocusInput]);

  const handleFetchTaxQuery = (): void => {
    const payload: GetCatalogFetchTaxesPayload = { limit: 1000, page: 0, search: null, teamId: currentTeam?.id };
    fetchTaxesBasicDataQuery(payload);
  };

  useEffect(() => {
    handleFetchTaxQuery();
  }, []);

  useEffect(() => {
    if (taxes?.length > 0) {
      setAllTaxes(sortSelectedTaxesToStart({ taxList: taxes, currentlySelectedTaxes: selectedTaxes }));
    }
  }, [taxes]);

  const isSelectedTax = (tax: ITaxBasic): boolean => {
    return Boolean(selectedTaxes.find(selectedTaxItem => selectedTaxItem.id === tax.id));
  };

  const onTaxClick = (tax: ITaxBasic): void => {
    if (isSelectedTax(tax)) {
      setSelectedTaxes(selectedTaxes.filter(selectedListItem => selectedListItem.id !== tax.id));
    } else {
      setSelectedTaxes(prev => [...prev, tax]);
    }
  };

  const onCancelClick = (): void => {
    onClose();
  };

  const handleSaveServiceCharge = async (serviceChargeData: IInvoiceSaveServiceCharge) => {
    if (serviceChargeData.saveToCatalog) {
      const payload: CreateServiceChargePayload = {
        teamId: currentTeam.id.toString(),
        name: serviceChargeData.invoiceServiceCharge.name ?? '',
        percentage: serviceChargeData.invoiceServiceCharge.percentage ?? '',
        amount: serviceChargeData.invoiceServiceCharge.amount ?? '',
        // Deselected global taxes are added back before saving the item to catalog
        taxes: prepareTaxListWithAllGlobalTaxes({
          selectedTaxList: serviceChargeData.invoiceServiceCharge?.taxes,
          globalTaxes: globalServiceChargeTaxList
        })
      };
      try {
        const data = await createServiceChargeMutation(payload).unwrap();

        if (data) {
          dispatch(
            showNotifier({
              type: NotifierTypes.SUCCESS,
              message: {
                primaryMessage: 'Service charge successfully created',
                isMessageHtml: false
              }
            })
          );
          onSaveServiceChargeClick(serviceChargeData);
        }
      } catch {
        /* empty */
      }
    } else {
      onSaveServiceChargeClick(serviceChargeData);
    }
  };

  const onSaveClick = (): void => {
    const invoiceServiceCharge: IInvoiceServiceCharge = {
      id: config.shouldInitializeFields && config.data.id ? config.data.id : null,
      name: serviceChargeName,
      ...(serviceChargeEntity.type === ServiceChargeEnum.PERCENTAGE
        ? { amount: '', percentage: serviceChargeEntity.value }
        : { percentage: '', amount: serviceChargeEntity.value }),
      taxes: selectedTaxes
    };
    if (config.editMode) {
      onModifyServiceChargeClick(invoiceServiceCharge);
      return;
    }
    handleSaveServiceCharge({ saveToCatalog: saveToCatalog, invoiceServiceCharge });
  };
  const handleSave = (): void => {
    let isValid = true;
    if (serviceChargeName.length === 0) {
      isValid = false;
      setErrors({ ...errors, nameError: ServiceChargeInputErrors.Name_Error });
    }
    if (serviceChargeEntity.type === ServiceChargeEnum.CURRENCY) {
      if (+serviceChargeEntity.value <= 0) {
        isValid = false;
        setErrors({ ...errors, amountError: ServiceChargeInputErrors.Amount_Error });
      }
    }

    if (serviceChargeEntity.type === ServiceChargeEnum.PERCENTAGE) {
      if (+serviceChargeEntity.value <= 0) {
        isValid = false;
        setErrors({ ...errors, amountError: ServiceChargeInputErrors.Percentage_Error });
      }
    }
    if (!isValid) {
      return;
    }
    onSaveClick();
  };

  const onNameChange = (name: string): void => {
    setErrors({ ...errors, nameError: null });
    if (name.length === 0) setErrors({ ...errors, nameError: ServiceChargeInputErrors.Name_Error });
    setServiceChargeName(name);
  };

  const onChangeServiceChargeType = (type: ServiceChargeEnum): void => {
    setShouldFocusInput(true);
    if (serviceChargeEntity.type === type) {
      return;
    }
    setErrors({ ...errors, amountError: null });
    if (type === ServiceChargeEnum.CURRENCY) {
      if (+serviceChargeEntity.amount === 0 && isEdited)
        setErrors({ ...errors, amountError: ServiceChargeInputErrors.Amount_Error });
      const currencyServiceCharge = {
        ...serviceChargeEntity,
        type: ServiceChargeEnum.CURRENCY,
        value: serviceChargeEntity.amount,
        id: ServiceChargeFromInvoiceInputId.serviceChargeAmount
      };
      setServiceChargeEntity(currencyServiceCharge);
    } else {
      if (+serviceChargeEntity.percentage === 0 && isEdited)
        setErrors({ ...errors, amountError: ServiceChargeInputErrors.Percentage_Error });
      const percentageServiceCharge = {
        ...serviceChargeEntity,
        type: ServiceChargeEnum.PERCENTAGE,
        value: serviceChargeEntity.percentage,
        id: ServiceChargeFromInvoiceInputId.serviceChargePercentage
      };
      setServiceChargeEntity(percentageServiceCharge);
    }
  };

  const handleAmountChange = (value: string, type: ServiceChargeEnum): void => {
    if (!isEdited) {
      setIsEdited(true);
    }
    setErrors({ ...errors, amountError: null });
    if (type === ServiceChargeEnum.CURRENCY) {
      const currencyValueWithoutSymbol = value.replace(REGEX.nonNumberRegExp, '');
      if (!REGEX.maximumTwoDecimals.test(currencyValueWithoutSymbol)) {
        return;
      }
      if (currencyValueWithoutSymbol.length === 0) {
        setErrors({ ...errors, amountError: ServiceChargeInputErrors.Amount_Error });
      }
      setServiceChargeEntity(prev => ({
        ...prev,
        value: currencyValueWithoutSymbol,
        amount: currencyValueWithoutSymbol
      }));
    }

    if (type === ServiceChargeEnum.PERCENTAGE) {
      if (!REGEX.percentageWithThreeDecimals.test(value)) {
        return;
      }
      if (value.length === 0) {
        setErrors({ ...errors, amountError: ServiceChargeInputErrors.Percentage_Error });
      }
      setServiceChargeEntity(prev => ({ ...prev, value: value, percentage: value }));
    }
  };

  const getModalHeight = (): string => {
    return `${errors ? 'max-h-[700px]' : 'max-h-[670px]'} h-[85%]`;
  };

  const onInfoClick = (id: string, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: false
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const onToggleSaveToCatalog = (): void => {
    if (manageServiceCharges) {
      setSaveToCatalog(prev => !prev);
    } else {
      onInfoClick('service-charge-save-to-catalog', InfoMesssages.createServiceChargesNoPermissionMessage);
    }
  };

  const findInvoiceModalType = ({ type }: { type: EServiceChargeModalType }) => {
    if (type === EServiceChargeModalType.CATALOG_SERVICE_CHARGE_MODAL) {
      return EInvoiceModalType.CATALOG_SERVICE_CHARGE_MODAL;
    } else if (type === EServiceChargeModalType.EDIT_SERVICE_CHARGE_MODAL) {
      return EInvoiceModalType.EDIT_SERVICE_CHARGE_MODAL;
    }
    return EInvoiceModalType.ONE_TIME_ADD_SERVICE_CHARGE_MODAL;
  };

  const onCreateTaxClick = (): void => {
    const modalConfig: IAddServiceChargeModalConfig = {
      modalType: config.modalType,
      editMode: config.editMode,
      enableDeleteFromInvoice: config.enableDeleteFromInvoice,
      enableSaveToCatalog: config.enableSaveToCatalog,
      saveToCatalog: saveToCatalog,
      open: true,
      shouldInitializeFields: true,
      title: config.title,
      data: {
        id: config.data.id,
        name: serviceChargeName,
        amount: serviceChargeEntity.amount,
        percentage: serviceChargeEntity.percentage,
        taxes: selectedTaxes
      },
      draftFlow: { isDraftFlow: true, serviceChargeType: serviceChargeEntity.type }
    };
    const invoiceServiceChargeModalDraft: InvoiceDraftModalData = {
      invoiceCatalogElementType: EInvoiceCatalogElementType.SERVICE_CHARGE,
      invoiceModalType: findInvoiceModalType({ type: config.modalType }),
      modalConfig: modalConfig
    };
    if (isEditInvoiceFlow) {
      dispatch(updateInvoiceDraftDataInEditSlice(invoiceServiceChargeModalDraft));
    } else {
      dispatch(updateInvoiceDraftData(invoiceServiceChargeModalDraft));
    }

    const createTaxRouteState: TaxRouteState = {
      callbackURL: location?.pathname
    };
    navigate(routesPath.TAXES_CREATE, { replace: false, state: createTaxRouteState });
  };

  const isCurrencyServiceChargeSelected = serviceChargeEntity.type === ServiceChargeEnum.CURRENCY;
  const isPercentageServiceChargeSelected = serviceChargeEntity.type === ServiceChargeEnum.PERCENTAGE;

  return (
    <CustomModal
      closeModal={onClose}
      open={config.open}
      backgroundColor="bg-primary"
      width="md:w-[611px]"
      height={getModalHeight()}>
      <div className={`absolute flex h-full w-full grow flex-col overflow-y-auto py-10 ${className}`}>
        <CircleCloseIcon className="absolute top-2 right-2 shrink-0 cursor-pointer" onClick={onClose} />

        <div className="mb-[10px] px-10 text-xl font-bold text-heading">{config.title ?? 'Add service charge'}</div>

        <div className="w-full overflow-auto">
          <div className="w-full px-10">
            <div className="mb-15px flex flex-col">
              <div className="mb-7px text-17px font-semibold text-heading">Name*</div>
              <CustomInput
                value={serviceChargeName}
                onChange={onNameChange}
                id={'create-service-charge-sc-name'}
                placeholder="Service charge name"
                className={`text-sbase font-normal 
                ${
                  hasValidCatalogueServiceChargeId(config.data.id)
                    ? 'cursor-not-allowed border border-borderGray bg-transparent text-accentText '
                    : ''
                }
                ${errors.nameError ? 'border border-error' : ''}`}
                maxLength={50}
                disabled={hasValidCatalogueServiceChargeId(config.data.id)}
              />
              {errors.nameError && <div className="mt-2 text-sbase text-error">{errors.nameError}</div>}
            </div>

            <div className="mb-5 flex w-full flex-col">
              <div className="mb-1 text-sbase font-semibold text-headingGray">Amount*</div>

              <div className="flex w-full flex-row">
                <div
                  className="flex w-[100px] shrink-0 flex-row items-center rounded-md 
            text-sbase font-semibold text-primaryText">
                  <div
                    onClick={() => onChangeServiceChargeType(ServiceChargeEnum.CURRENCY)}
                    className={`${
                      isCurrencyServiceChargeSelected ? 'bg-[#A4D7FA]' : 'border border-borderGray bg-primary'
                    } flex h-full w-1/2 cursor-pointer items-center justify-center rounded-l-md`}>
                    <div className="">$</div>
                  </div>

                  <div
                    onClick={() => onChangeServiceChargeType(ServiceChargeEnum.PERCENTAGE)}
                    className={`${
                      isPercentageServiceChargeSelected ? 'bg-[#A4D7FA]' : 'border border-borderGray bg-primary'
                    } flex h-full w-1/2 cursor-pointer items-center justify-center rounded-r-md`}>
                    <div className="">%</div>
                  </div>
                </div>
                <If condition={isCurrencyServiceChargeSelected}>
                  <CustomInput
                    value={serviceChargeEntity.value ? `$ ${serviceChargeEntity.value}` : ''}
                    onChange={value => handleAmountChange(value, ServiceChargeEnum.CURRENCY)}
                    id={ServiceChargeFromInvoiceInputId.serviceChargeAmount}
                    placeholder="$ 0.00"
                    className={`ml-5 text-sbase font-normal placeholder:text-accentText ${
                      errors.amountError ? 'border border-error' : ''
                    }`}
                    inputStyle="w-full"
                  />
                </If>

                <If condition={isPercentageServiceChargeSelected}>
                  <CustomInput
                    value={serviceChargeEntity.value}
                    onChange={value => handleAmountChange(value, ServiceChargeEnum.PERCENTAGE)}
                    id={ServiceChargeFromInvoiceInputId.serviceChargePercentage}
                    placeholder="0.000%"
                    className={`ml-5 text-sbase font-normal placeholder:text-accentText ${
                      errors.amountError ? 'border border-error' : ''
                    }`}
                    inputStyle="w-full"
                    hasSuffix={true}
                    suffix="%"
                    suffixStyle="text-heading text-sbase mr-4"
                  />
                </If>
              </div>
              {errors.amountError && <div className="mt-2 text-sbase text-error">{errors.amountError}</div>}
            </div>

            <div className="mb-[10px] flex items-center gap-5">
              <div className="text-17px font-semibold text-heading">Add tax</div>

              {catalogSettingsPermissions.manageTaxes && (
                <div onClick={onCreateTaxClick} className="flex cursor-pointer items-center self-end">
                  <div className="mr-2">
                    <PlusIconBlue className="h-[18px] w-[18px]" />
                  </div>
                  <div className="text-15px font-semibold text-primaryBtn">Create tax</div>
                </div>
              )}
            </div>

            <div className="customNormalScroll mb-5 h-full max-h-[220px] min-h-[220px] overflow-y-auto">
              <If condition={isFetchingTaxes}>
                <InfinteLoadingSpinner />
              </If>

              <If condition={isFetchingTaxesError && !isLoadingTaxes && !checkIsAccessTokenExpiry(+fetchTaxError.code)}>
                <div className="rounded-md border border-borderGray pt-6 pb-5">
                  <ErrorScreenWithRetry handleReload={handleFetchTaxQuery} />
                </div>
              </If>

              {/* Empty tax state */}
              <If condition={!isLoadingTaxes && allTaxes.length === 0 && !isFetchingTaxes && !isFetchingTaxesError}>
                <div className="rounded-md border border-borderGray pt-4 pb-3">
                  <div className="flex flex-col items-center gap-1">
                    <TaxEmptyIcon className="path-fill-current h-10 w-10 shrink-0 text-dark-gray" />
                    <div className="text-center text-sbase font-semibold tracking-[-0.3px] text-accentText">
                      {'No taxes created'}
                    </div>
                  </div>
                </div>
              </If>

              <If condition={!isFetchingTaxes && Boolean(taxesBasicData)}>
                {allTaxes.map((tax, index) => {
                  return (
                    <div key={tax.id + tax.name + index}>
                      <div
                        onClick={() => onTaxClick(tax)}
                        className="flex min-h-[37px] cursor-pointer flex-row items-center
                     justify-between py-2 px-5 hover:bg-secondaryBtn">
                        <div
                          className="min-w-[100px] max-w-[75%] basis-9/12 
                           break-words text-primaryText">
                          {tax?.['name']}
                        </div>
                        <div className="flex basis-1/4">
                          <div className="ml-auto pr-5 text-primaryText">{getFormattedPercentage(tax.percentage)}%</div>
                          <CustomCheckbox
                            className={`${isSelectedTax(tax) ? '' : 'border-accentText bg-secondaryBtn'} `}
                            isChecked={isSelectedTax(tax)}
                            onClick={() => onTaxClick(tax)}
                            id={tax.id.toString()}>
                            <If condition={isSelectedTax(tax)}>
                              <CheckboxTickIcon />
                            </If>
                          </CustomCheckbox>
                        </div>
                      </div>

                      <Divider className="bg-secondaryBtn" />
                    </div>
                  );
                })}
              </If>
            </div>
          </div>
        </div>
        <div className="mt-auto flex flex-col px-10">
          {config.enableSaveToCatalog && (
            <div
              className={`mb-6 flex items-center gap-5 
              ${manageServiceCharges ? '' : 'cursor-not-allowed opacity-75'}`}>
              <div className="text-sbase font-semibold text-primaryText">Save to catalog</div>
              <CustomSwitch
                enabled={saveToCatalog}
                toggleSwitch={onToggleSaveToCatalog}
                className={manageServiceCharges ? '' : 'cursor-not-allowed'}
                id="service-charge-modal-switch"
              />
            </div>
          )}

          <div className="flex flex-row items-center justify-end">
            {config.enableDeleteFromInvoice && (
              <div onClick={onDeleteFromInvoice} className="flex cursor-pointer flex-row items-center">
                <DeleteIcon className="path-fill-current mr-2 shrink-0 text-accentText" />
                <div className="text-17px font-normal text-error">Delete from invoice</div>
              </div>
            )}
            <CustomButton
              className="mr-5 ml-auto h-[38px] w-[100px]"
              type={ButtonType.SECONDARY}
              onClick={onCancelClick}
              id={'creat-item-cancel-btn'}>
              Cancel
            </CustomButton>

            <CustomButton
              className="h-[38px] w-[100px]"
              onClick={handleSave}
              disabled={disableSaveBtn}
              isLoading={isCreateServiceChargeLoading}
              id={'create-item-save-btn'}>
              {config.editMode ? 'Done' : 'Save'}
            </CustomButton>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddServiceChargeModal;
