import { ScanPayLogo } from 'assets/icons';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { GO_SCANPAY_LINK, SPAY_ANDROID_LINK, SPAY_APP_LINK, SPAY_IOS_LINK } from 'config/index';
import { FC } from 'react';
import { replaceURL } from 'utils/commonUtils';

interface GenericMobileViewProps {
  className?: string;
}

const GenericMobileView: FC<GenericMobileViewProps> = () => {
  return (
    <div className="flex h-screen flex-col items-center justify-center overflow-y-auto  p-14">
      <div className="mb-[50px]">
        <ScanPayLogo className="shrink-0" />
      </div>
      <div className="font-17px mb-[21px] max-w-[315px] text-center font-semibold text-primaryText">
        ScanPay is currently only available on a desktop or on one of our mobile applications:&nbsp;
        <span className="cursor-pointer text-secondary" onClick={() => replaceURL(SPAY_IOS_LINK)}>
          iOS
        </span>{' '}
        or{' '}
        <span className="cursor-pointer text-secondary" onClick={() => replaceURL(SPAY_ANDROID_LINK)}>
          Android
        </span>
      </div>
      <CustomButton
        id="open_scanpay_app"
        className="mb-[24px] h-[38px] w-[58%] min-w-[250px] shrink-0"
        type={ButtonType.PRIMARY}
        onClick={() => replaceURL(SPAY_APP_LINK)}>
        Open ScanPay app
      </CustomButton>

      <div className="font-sbase flex flex-row font-semibold">
        <div className="mr-[7px] text-primaryText">Go to</div>
        <div className="cursor-pointer text-secondary" onClick={() => replaceURL(GO_SCANPAY_LINK)}>
          goscanpay.com
        </div>
      </div>
    </div>
  );
};

export default GenericMobileView;
