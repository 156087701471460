import { LoadingSpinnerAnimation } from 'assets/animations';
import { ArrowRightIcon, NavCloseIcon, PencilIcon, PlusIconBlue, RedirectIcon } from 'assets/icons';
import { CustomButton } from 'components';
import Lottie from 'lottie-react';
import { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { SidePanelRouteState } from 'routes/types';
import { getAmountWithCurrency } from 'utils/amountUtils';
import DateUtils from 'utils/dateUtils';
import PayoutsTimeline from './PayoutsTimeline';
import { VoidFn } from 'types/baseTypes';
import { ConnectPayoutByIdResponse } from '../payouts.model';
import { useAppDispatch, useAppSelector } from 'hooks';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { NotifierTypes } from 'constants/notifierConstants';
import { ConnectPayoutStatus } from '../constants';
import NoPermissions from 'components/no-permissions/NoPermissions';
import { FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import { logAnalyticEvent } from 'utils/analytics';
import { PayoutsCleverTapEvents } from '../events';
import { openScanpayFAQInNewTab } from 'utils/urlUtils';

interface ConnectPayoutDetailsProps {
  handleSidePanelClose: VoidFn;
  isFetchingPayoutData: boolean;
  isPayoutDataFetchSuccess: boolean;
  connectPayoutData: ConnectPayoutByIdResponse;
  onNotesClick: VoidFn;
  hasPermission: boolean;
}

const ConnectPayoutDetails: FC<ConnectPayoutDetailsProps> = ({
  handleSidePanelClose,
  connectPayoutData,
  isFetchingPayoutData,
  isPayoutDataFetchSuccess,
  onNotesClick,
  hasPermission
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentTeam = useAppSelector(selectCurrentTeam);
  const dispatch = useAppDispatch();

  const [isRootState, setIsRootState] = useState(true);

  const { data, timeline } = connectPayoutData ?? {};

  const { rootInvoiceId, rootReceiptId, rootPage, rootCustomerId, rootDisputeId } = useParams();

  useEffect(() => {
    let routeStateTimeout;
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  const viewAllTransactions = () => {
    dispatch(
      showNotifier({
        message: {
          primaryMessage: 'Collected & Refunds filter applied',
          secondaryMessage: ''
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    logAnalyticEvent(PayoutsCleverTapEvents.webPayoutsViewAllTransactionsClick, { payoutId: data.ID });
    if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam.id}/${rootPage}/${data.ID}/transactions`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/payout/${data.ID}/transactions`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/payout/${data.ID}/transactions`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/payout/${data.ID}/transactions`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/payout/${data.ID}/transactions`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  const renderDataField = (title: string, fieldValue: string) => {
    return (
      <div className="flex items-start justify-between">
        <div className="shrink-0 text-start text-17px ">{title}</div>
        <div className="text-right text-17px text-tertiaryText">{fieldValue}</div>
      </div>
    );
  };

  const onLearnMoreClick = () => {
    openScanpayFAQInNewTab(PayoutsCleverTapEvents.webConnectPayoutDetailLearnMoreClick);
  };

  return (
    <div className="h-full w-96">
      {isFetchingPayoutData && (
        <div className="flex h-full w-full items-center justify-center">
          <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
        </div>
      )}
      {!isFetchingPayoutData && isPayoutDataFetchSuccess && (
        <div className="flex h-full w-full flex-col">
          <div className="flex w-full items-center gap-2 border-b border-borderGray p-5">
            <div onClick={handleSidePanelClose} className="cursor-pointer">
              {isRootState ? (
                <NavCloseIcon id="dispute-panel-close" />
              ) : (
                <ArrowRightIcon className="rotate-180" id="dispute-panel-back-arrow" />
              )}
            </div>
            <div id="dispute-panel-head" className="text-17px font-semibold text-headingGray">
              {data.ID}
            </div>
          </div>
          {hasPermission ? (
            <div className="customNormalScroll flex w-full grow flex-col overflow-y-auto overflow-x-hidden pb-9">
              <div className="flex cursor-pointer gap-2 bg-secondaryBtn px-7 py-2" onClick={onLearnMoreClick}>
                <RedirectIcon />
                <div className="text-sbase font-semibold text-primaryBtn">Learn about getting paid through ScanPay</div>
              </div>

              <div className="mx-auto space-y-1 py-10 text-center">
                <div className="text-3xl font-bold text-tertiaryText">{getAmountWithCurrency(data.amount)}</div>
                {(data.destinationBank || data.destination) && (
                  <div className="text-sbase text-accentText">
                    To {data.destinationBank} {data.destination}
                  </div>
                )}
              </div>

              <div className="px-[30px]">
                {timeline && timeline.length > 0 && (
                  <PayoutsTimeline
                    timelineData={timeline}
                    arrivalDate={data.arrivalAt}
                    destination={data.destination}
                    destinationBank={data.destinationBank}
                  />
                )}
              </div>

              <div className="my-5 border-b border-borderGray"></div>

              <div className="px-[30px]">
                <div className="pb-4 text-sbase text-accentText">Amount breakdown</div>
                <div className="space-y-[15px] font-semibold">
                  {renderDataField('Collected', getAmountWithCurrency(data.grossAmount))}
                  {renderDataField('Fees', `${getAmountWithCurrency(data.serviceFee)}`)}
                  {renderDataField('Refunds', `${getAmountWithCurrency(data.pastRefundAmount)}`)}
                  {renderDataField('Adjustments', `${getAmountWithCurrency(data.pastChargebackAmount)}`)}
                  {renderDataField('Credits', getAmountWithCurrency(data.creditsApplied))}
                  <div className="border-b border-borderGray"></div>
                  {renderDataField('Payout', getAmountWithCurrency(data.amount))}
                </div>
              </div>

              <div className="my-5 border-b border-borderGray"></div>

              <div className="px-[30px]">
                <div className="pb-4 text-sbase text-accentText">Overview</div>
                <div className="space-y-[15px]">
                  {renderDataField(
                    data.status === ConnectPayoutStatus.SUCCESS ? 'Date paid' : 'Payout date',
                    data.status === ConnectPayoutStatus.SUCCESS
                      ? DateUtils.getDateInFormatWtTimeZone({
                          date: new Date(data.arrivalAt),
                          timeZone: 'UTC',
                          dateFormat: 'dd MMM yyyy'
                        })
                      : DateUtils.getDateInFormat({ date: new Date(data.createdAt), dateFormat: 'dd MMM yyyy' })
                  )}
                  {renderDataField('Payout ID', data.ID)}
                  {renderDataField('Sent to', `${data.destinationBank} ${data.destination}`.trim() || '-')}
                  {renderDataField('Sent via', data.paymentMethod || '-')}
                  {/* TODO: removed to make same as app but may need in future */}
                  {/* {data.paymentTraceId && renderDataField('ACH trace ID', data.paymentTraceId)} */}
                </div>
              </div>

              <div className="my-5 border-b border-borderGray"></div>

              <div className="px-[30px]">
                <div className="flex items-center justify-between pb-4 text-px13 text-accentText">
                  Notes
                  {data.notes && (
                    <div className="flex cursor-pointer items-center gap-1 text-secondary" onClick={onNotesClick}>
                      <PencilIcon className="path-fill-current path text-accentText" height={16} width={16} />
                      Edit
                    </div>
                  )}
                </div>
                {data.notes ? (
                  <div className="whitespace-pre-line break-words text-17px text-tertiaryText">{data.notes}</div>
                ) : (
                  <div
                    className="flex cursor-pointer items-center gap-2 text-17px text-secondary"
                    onClick={onNotesClick}>
                    <PlusIconBlue />
                    Add notes
                  </div>
                )}
              </div>

              <div className="my-5 border-b border-borderGray"></div>

              <div className="px-[30px]">
                <CustomButton
                  onClick={viewAllTransactions}
                  type={null}
                  id="payout-details-view-all-txns"
                  className="w-full border border-gray-1
                  bg-primary text-17px font-normal text-secondary">
                  View all transactions
                </CustomButton>
              </div>
            </div>
          ) : (
            <NoPermissions />
          )}
        </div>
      )}
    </div>
  );
};

export default ConnectPayoutDetails;
