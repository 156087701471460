import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import { getUrlSearchParams } from 'utils/apiUtils';
import { API_RESPONSES, FileTypes, GenericPaginationResponse } from 'types/baseTypes';
import {
  CreateServiceChargePayload,
  CreateServiceChargeResponse,
  DeleteServiceChargePayload,
  EditServiceChargePayload,
  GetCatalogFetchServiceChargePayload,
  GetServiceChargeByIDPayload,
  GetServiceChargesPayload,
  IServiceCharge,
  IServiceChargeBasic
} from './serviceCharge.model';
import {
  initializeServiceChargeSliceFromAPI,
  updateInitalizingServiceChargeSliceInProgress
} from './serviceChargeSlice';
import { downloadFile } from 'utils/commonUtils';

const ServiceCharges = baseApi.injectEndpoints({
  endpoints: builder => ({
    getServiceCharges: builder.query<GenericPaginationResponse<IServiceCharge>, GetServiceChargesPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/service-charges?`;

        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      }
    }),

    getServiceChargeByID: builder.query<IServiceCharge, GetServiceChargeByIDPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/service-charges/${params.id}?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },

      async onQueryStarted({ isEditFlow }, { dispatch, queryFulfilled }) {
        dispatch(updateInitalizingServiceChargeSliceInProgress(true));
        const { data } = await queryFulfilled;
        try {
          if (data && isEditFlow) {
            dispatch(initializeServiceChargeSliceFromAPI(data));
          }
        } catch (err) {
          dispatch(updateInitalizingServiceChargeSliceInProgress(false));
        }
      },

      extraOptions: {
        failure: 'Failed to fetch service charge details',
        showToast: true
      }
    }),

    createServiceCharge: builder.mutation<CreateServiceChargeResponse, CreateServiceChargePayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/service-charges`;
        return {
          url: url,
          method: 'POST',
          body: params
        };
      },

      extraOptions: {
        showToast: true,
        failure: 'Unable to create service charge. Please try again later.'
      }
    }),

    editServiceCharge: builder.mutation<API_RESPONSES, EditServiceChargePayload>({
      query: ({ id, ...rest }) => {
        const url = `${API_VERSION.V1}/catalogue/service-charges/${id}`;
        return {
          url: url,
          method: 'PUT',
          body: rest
        };
      },

      extraOptions: {
        failure: 'Failed to edit service charge',
        showToast: true
      }
    }),

    deleteServiceCharge: builder.mutation<API_RESPONSES, DeleteServiceChargePayload>({
      query: ({ id, teamId }) => {
        return {
          url: `${API_VERSION.V1}/catalogue/service-charges/${id}?teamId=${teamId}`,
          method: 'DELETE'
        };
      },
      extraOptions: {
        failure: 'Failed to delete service charge',
        success: 'Service Charge successfully deleted',
        showToast: true
      }
    }),

    getCatalogFetchServiceCharge: builder.query<
      GenericPaginationResponse<IServiceChargeBasic>,
      GetCatalogFetchServiceChargePayload
    >({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/fetch-service-charges?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch service charge to add',
        showToast: true
      }
    }),

    downloadServiceChargesList: builder.query<any, GetServiceChargesPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/service-charges?`;
        const urlParams = getUrlSearchParams(params);
        urlParams.append('type', 'download');
        url = url + urlParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.CSV, fileName: 'service_charges' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'Failed to download service charges list',
        success: 'Service charges exported successfully!',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetServiceChargesQuery,
  useGetServiceChargeByIDQuery,
  useCreateServiceChargeMutation,
  useEditServiceChargeMutation,
  useDeleteServiceChargeMutation,
  useLazyGetCatalogFetchServiceChargeQuery,
  useLazyDownloadServiceChargesListQuery
} = ServiceCharges;
