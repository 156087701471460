import classnames from 'classnames';
import { If } from 'components';
import { selectHoverState, selectMenuState } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { NavTab, navTabKeys } from 'types/baseTypes';
import { getNavSvgHighightStyle } from 'utils/styleUtils';
import { isTablet } from 'react-device-detect';
import { DownArrowIcon } from 'assets/icons';

interface NavTabProps {
  tab: NavTab;
  activeTab: NavTab;
  isActiveSubTab?: boolean;
  onNavTabClick: (tab: NavTab) => void;
  isBottomBar?: boolean;
  subTabClassname?: string;
}

const SideNavTab: FC<NavTabProps> = ({
  tab,
  activeTab,
  onNavTabClick,
  isBottomBar,
  isActiveSubTab,
  subTabClassname
}) => {
  const isMenuActive = useAppSelector(selectMenuState);
  const isHoverActive = useAppSelector(selectHoverState);
  const isActiveTab = (navTab: NavTab) => activeTab.key === navTab.key;

  const isActiveChild = (navTab: NavTab) => activeTab.childTabs.includes(navTab);

  return (
    <div
      onClick={() => onNavTabClick(tab)}
      key={tab.key}
      className={twMerge(
        'hover:cursor-pointer flex flex-row gap-4 py-3 px-4',
        classnames({
          'min-w-[175px]  rounded-md': isMenuActive || (isHoverActive && !isTablet),
          'bg-secondaryBtn':
            isActiveTab(tab) || (!isMenuActive && !isHoverActive && activeTab.hasChildTabs && isActiveChild(tab)),
          'justify-center': !isBottomBar,
          'items-center justify-start': (isMenuActive || (isHoverActive && !isTablet)) && !isBottomBar,
          [subTabClassname]: subTabClassname && (isMenuActive || isHoverActive)
        })
      )}>
      <div
        className={twMerge(
          classnames({
            'pl-0': !isMenuActive,
            '': (isMenuActive || (isHoverActive && !isTablet)) && !isBottomBar,
            'rounded-md bg-secondary px-8 py-2': !isMenuActive && !isHoverActive && isActiveSubTab
          }),
          'flex flex-row items-center gap-4'
        )}>
        <tab.icon
          className={classnames('shrink-0', {
            [`${getNavSvgHighightStyle(tab)} text-secondaryBtnText`]:
              isActiveTab(tab) || (isActiveSubTab && (isMenuActive || isHoverActive)),
            'mt-1 self-start': tab.key === navTabKeys.discounts,
            [`${getNavSvgHighightStyle(tab)} text-primary`]: !isMenuActive && !isHoverActive && isActiveSubTab
          })}
          id={isBottomBar ? 'bottom-bar' : 'side-nav'}
          width={20}
        />
        <If condition={isMenuActive || isBottomBar || (isHoverActive && !isTablet)}>
          <div
            id={tab.id}
            className={twMerge(
              classnames('max-w-[150px] font-bold text-primaryText duration-300 ease-in-out', {
                'text-secondaryBtnText': isActiveTab(tab) || isActiveSubTab,
                'min-w-[130px]': !tab.hasChildTabs,
                'min-w-[90px]': tab.hasChildTabs
              })
            )}>
            {tab.title}
          </div>
          {tab.hasChildTabs && (
            <DownArrowIcon className={`${activeTab.key === tab.key ? 'rotate-180' : ''} shrink-0`} />
          )}
        </If>
      </div>
    </div>
  );
};

export default SideNavTab;
