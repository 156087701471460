import { FormEditIcon } from 'assets/icons';
import { CustomTextArea } from 'components';
import { selectCurrentTeamFeePermissions } from 'containers/app/appSlice';
import { TaxesAndFeesResponse } from 'containers/invoices/invoices.model';
import {
  InvoiceAttachment,
  addInvoiceAttachment,
  modifyInvoiceAttachment,
  removeInvoiceAttachment,
  selectInvoiceAttachments,
  selectInvoiceNotes,
  updateInvoiceNotes
} from 'containers/invoices/InvoicesSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { DiscountAndTipsRouteState } from 'routes/types';
import { InvoiceTabEnum } from '../constants';
import AddAttachmentModal from './AddAttachmentModal';
import LineItemPlusReactIcon from 'assets/react-icons/LineItemPlusReactIcon';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface NotesAttachmentsSectionProps {
  className?: string;
  taxesAndFees: TaxesAndFeesResponse;
  isLoadingTaxesAndFees: boolean;
  context: React.Context<any>;
}

const NotesAttachmentsSection: FC<NotesAttachmentsSectionProps> = ({
  context,
  taxesAndFees,
  isLoadingTaxesAndFees
}) => {
  const selectedInvoiceAttachments = useAppSelector(selectInvoiceAttachments);
  const selectedInvoiceComment = useAppSelector(selectInvoiceNotes);
  const dispatch = useAppDispatch();
  const { selectedInvoiceTab, setSelectedInvoiceTab } = useContext<any>(context);
  const isTabSelected = selectedInvoiceTab === InvoiceTabEnum.NOTES_ATTACHMENTS;
  const navigate = useNavigate();
  const feeSettingsPermissions = useAppSelector(selectCurrentTeamFeePermissions);

  const [showAttachmentModal, setShowAttachmentModal] = useState<{
    open: boolean;
    attachment: InvoiceAttachment;
    editMode: boolean;
  }>({
    open: false,
    attachment: { id: null, url: null, title: null, imagePreview: null, description: null },
    editMode: false
  });

  const onNotesChange = (value: string) => {
    dispatch(updateInvoiceNotes(value));
  };

  const onToggleAddAttachmentModal = ({ isEdit = false }: { isEdit: boolean }) => {
    if (!isEdit) {
      setShowAttachmentModal(prevModalState => ({ ...prevModalState, open: !prevModalState.open, editMode: false }));
      return;
    }
    setShowAttachmentModal(prevModalState => ({ ...prevModalState, open: !prevModalState.open }));
  };

  const handleAddPhotoClick = () => {
    updateSelectedInvoiceTab();
    onToggleAddAttachmentModal({ isEdit: false });
  };

  const resetAttachmentModalState = () => {
    setShowAttachmentModal(prevModalState => ({ ...prevModalState, open: false, editMode: false, attachment: null }));
  };

  const onSaveAttachmentClick = (attachment: InvoiceAttachment) => {
    dispatch(addInvoiceAttachment(attachment));
    resetAttachmentModalState();
  };

  const onModifyAttachmentClick = (attachment: InvoiceAttachment) => {
    dispatch(modifyInvoiceAttachment(attachment));
    resetAttachmentModalState();
  };

  const onRemoveAttachment = (attachment: InvoiceAttachment) => {
    dispatch(removeInvoiceAttachment(attachment));
  };

  const onEditAttachment = (attachment: InvoiceAttachment) => {
    setShowAttachmentModal({ attachment: attachment, open: true, editMode: true });
  };

  const getAddText = () => {
    const title = selectedInvoiceAttachments?.length > 0 ? 'Add another photo' : 'Add photo';
    return title;
  };

  const goToEditTermsPage = () => {
    const dicountAndTipsRouteState: DiscountAndTipsRouteState = {
      isTermsEdit: true
    };
    navigate(routesPath.DISCOUNTS_TIPS_FEES, { replace: false, state: dicountAndTipsRouteState });
  };

  const updateSelectedInvoiceTab = () => {
    setSelectedInvoiceTab(InvoiceTabEnum.NOTES_ATTACHMENTS);
  };

  return (
    <>
      <div>
        <div className={`mb-2 text-xl font-bold ${isTabSelected ? 'text-primaryBtn' : 'text-heading'}`}>
          Notes & Attachments
        </div>
        <div
          className={`flex flex-col rounded-md  border px-[30px] py-5 1439px:px-10 1439px:pt-9 1439px:pb-10 ${
            isTabSelected ? 'border-primaryBtn' : 'border-borderGray'
          }`}>
          <div className="mb-2 text-17px font-semibold text-heading">Notes</div>
          <CustomTextArea
            resizeTextArea={true}
            id="create-invoice-notes"
            maxLength={1000}
            name="notes"
            placeholder="Enter notes here"
            value={selectedInvoiceComment ?? ''}
            onChange={onNotesChange}
            className="mb-4 py-[15px]"
            onFocus={updateSelectedInvoiceTab}
          />

          {!isLoadingTaxesAndFees && taxesAndFees?.tnc?.enabled && (
            <div className="mt-6 mb-4">
              <div className="mb-4 text-17px font-semibold text-headingGray">Terms & Conditions</div>
              <div className="relative">
                <div
                  className=" h-[100px] overflow-y-hidden rounded border border-borderGray 
                    pt-2.5 text-17px font-normal text-primaryText">
                  <div className="customNormalScroll max-h-[80px] overflow-y-auto whitespace-pre-line break-words px-5">
                    {taxesAndFees?.tnc?.tnc}
                  </div>
                </div>
                {feeSettingsPermissions.editTnc && (
                  // eslint-disable-next-line tailwindcss/enforces-negative-arbitrary-values
                  <div className="absolute -right-[25px] top-[41px] cursor-pointer 1439px:-right-7">
                    <FormEditIcon
                      id="create-invoice-terms-edit-icon"
                      onClick={goToEditTermsPage}
                      className="shrink-0 cursor-pointer"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="mb-2 text-17px font-semibold text-heading">Attachments</div>

          <div className="mb-5 flex flex-col gap-5">
            {selectedInvoiceAttachments?.map(attachment => {
              return (
                <div
                  onClick={() => onEditAttachment(attachment)}
                  key={attachment.url}
                  className="relative flex min-h-[100px] w-full flex-row rounded border border-borderGray py-4 px-5">
                  <div className="relative mr-4 h-16 w-16 rounded-md">
                    <img
                      className="absolute left-1/2 h-full -translate-x-1/2 rounded-md object-cover"
                      src={attachment.imagePreview}
                    />
                  </div>

                  <div className="mt-[2px] flex w-1/2 flex-col">
                    <div className="mb-[6px] break-words text-17px font-semibold text-heading line-clamp-2">
                      {attachment.title}
                    </div>
                    <div className="whitespace-pre-line text-sbase font-normal text-heading line-clamp-3">
                      {attachment?.description}
                    </div>
                  </div>
                  <FormCloseReactIcon
                    id="create-invoice-remove-attachment-close-icon"
                    onClick={e => {
                      e.stopPropagation();
                      onRemoveAttachment(attachment);
                    }}
                    className="absolute top-3 right-[-25px] shrink-0 cursor-pointer 1439px:right-[-30px]"
                  />
                  <FormEditIcon
                    id="create-invoice-attachment-edit-icon"
                    onClick={() => onEditAttachment(attachment)}
                    className="absolute bottom-3 right-[-25px] mt-3 shrink-0 cursor-pointer 1439px:right-[-30px]"
                  />
                </div>
              );
            })}
          </div>

          <div
            onClick={handleAddPhotoClick}
            className="flex w-full cursor-pointer flex-row rounded border border-borderGray py-4 px-5">
            <div className="flex flex-row gap-2">
              <LineItemPlusReactIcon id="create-invoice-add-photo-icon" className="shrink-0" />
              <div id="create-invoice-add-photo" className="text-17px font-semibold text-secondary">
                {getAddText()}
              </div>
            </div>
          </div>
        </div>
      </div>

      <AddAttachmentModal
        onModifyAttachmentClick={onModifyAttachmentClick}
        onSaveAttachmentClick={onSaveAttachmentClick}
        toggleOpen={resetAttachmentModalState}
        open={showAttachmentModal.open}
        isEdit={showAttachmentModal.editMode}
        attachmentData={showAttachmentModal.attachment}
      />
    </>
  );
};

export default NotesAttachmentsSection;
