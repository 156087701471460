import { Divider } from '@material-ui/core';
import { CsvIcon, PdfIcon } from 'assets/icons';
import { FC } from 'react';
import { FileTypes } from 'types/baseTypes';

interface ExportMenuProps {
  onDownloadFile: (FileTypes) => void;
  id: string;
  showPDF?: boolean;
  showCsv?: boolean;
}

const ExportMenu: FC<ExportMenuProps> = ({ onDownloadFile, id, showCsv = true, showPDF = true }) => {
  return (
    <div className="flex flex-col space-y-2 text-sbase font-semibold text-primaryText">
      {showPDF && (
        <div
          onClick={() => onDownloadFile(FileTypes.PDF)}
          className="flex cursor-pointer flex-row items-center gap-2 p-2 hover:rounded-md
         hover:bg-secondaryBtn active:bg-secondaryBtn active:opacity-75">
          <PdfIcon className="w-4 shrink-0" />
          <div id={`${id}-download-pdf`}>Download PDF</div>
        </div>
      )}
      {showCsv && showPDF && <Divider variant="middle" className="bg-secondaryBtn" />}
      {showCsv && (
        <div
          onClick={() => onDownloadFile(FileTypes.CSV)}
          className="flex cursor-pointer flex-row items-center gap-2 p-2
        hover:rounded-md hover:bg-secondaryBtn active:bg-secondaryBtn active:opacity-75">
          <CsvIcon className="w-4 shrink-0" />
          <div id={`${id}-download-csv`}>Download CSV</div>
        </div>
      )}
    </div>
  );
};

export default ExportMenu;
