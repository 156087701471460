import { ArrowRightIcon } from 'assets/icons';
import { CustomModal } from 'components';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import AddFCAccountCard from './AddFCAccountCard';
import AddManualBankAccountCard from './AddManualBankAccountCard';

interface EnablePayoutsPopupProps {
  onClose: VoidFn;
  openFCModal: VoidFn;
  openManualModal: VoidFn;
  handleBackClick: VoidFn;
}

const AddBankAccountPopup: FC<EnablePayoutsPopupProps> = ({
  onClose,
  openFCModal,
  openManualModal,
  handleBackClick
}) => {
  return (
    <CustomModal open={true} width="md:min-w-[390px]" className="" onOutsideClick={onClose}>
      <div className="relative w-full p-9 py-[60px]">
        <ArrowRightIcon className=" rotate-180 cursor-pointer" onClick={handleBackClick} />
        <div className="flex w-full flex-col items-center justify-center">
          <div className="my-[30px] text-center">
            <div className="pb-2.5 text-2xl font-semibold text-primaryText">Add your bank account</div>
            <div className="text-sbase text-primaryText">Tell us where to send your payouts</div>
          </div>
          <div className="space-y-7">
            <div role="presentation" onClick={openFCModal} className="cursor-pointer">
              <AddFCAccountCard />
            </div>
            <div role="presentation" onClick={openManualModal} className="cursor-pointer">
              <AddManualBankAccountCard />
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
};

export default AddBankAccountPopup;
