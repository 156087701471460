import { InfiniteLoadingGIF } from 'assets/animations';
import classnames from 'classnames';
import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface InfiniteLoadingProps {
  customClassNames?: string;
}

const InfinteLoadingSpinner: FC<InfiniteLoadingProps> = ({ customClassNames }) => {
  return (
    <div
      className={twMerge(
        classnames('gap-2 flex flex-row items-center justify-center', { [customClassNames]: customClassNames })
      )}>
      <img src={InfiniteLoadingGIF} />
      <div className="text-sbase text-primaryText font-normal">Loading</div>
    </div>
  );
};

export default InfinteLoadingSpinner;
