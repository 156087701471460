import { FC, useEffect, useState } from 'react';
import { CustomButton, CustomInput, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { useAppSelector } from 'hooks';
import { selectMyAccountState } from '../myAccountSlice';
import { MerchantName } from '../myAccount.model';
import { CleverTapEventsMyAccount } from '../events';
import { logAnalyticEvent } from 'utils/analytics';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface NameModalProps {
  className?: string;
  open: boolean;
  onCancelClick: VoidFn;
  isSaveInProgress: boolean;
  handleSave: (name: MerchantName) => void;
}

const NameModal: FC<NameModalProps> = ({ open, onCancelClick, isSaveInProgress, handleSave }) => {
  const selectedMyAccountState = useAppSelector(selectMyAccountState);
  const [nameValue, setNameValue] = useState<MerchantName>({ firstName: '', middleName: '', lastName: '' });
  const [nameError, setNameError] = useState({ firstName: '', lastName: '' });

  useEffect(() => {
    if (selectedMyAccountState.firstName) {
      setNameValue({
        firstName: selectedMyAccountState.firstName,
        middleName: selectedMyAccountState.middleName,
        lastName: selectedMyAccountState.lastName
      });
    }
  }, [selectedMyAccountState]);

  const handleNameValidation = () => {
    let isValid = true;
    const { firstName, lastName } = nameValue;
    if (!firstName?.trim()) {
      isValid = false;
      setNameError(prev => ({ ...prev, firstName: 'Please enter first name' }));
    }
    if (!lastName?.trim()) {
      isValid = false;
      setNameError(prev => ({ ...prev, lastName: 'Please enter last name' }));
    }
    return isValid;
  };

  const onSave = () => {
    const isNameValid = handleNameValidation();
    if (isNameValid) {
      handleSave(nameValue);
    }
    logAnalyticEvent(CleverTapEventsMyAccount.webAddNameSaveClick, {
      firstName: nameValue.firstName,
      middleName: nameValue.middleName,
      lastName: nameValue.lastName
    });
  };

  const handleNameChange = ({ value, key }) => {
    setNameError(prev => ({ ...prev, [key]: '' }));
    setNameValue(prev => ({ ...prev, [key]: value }));
  };

  const onCancelBtnClick = () => {
    onCancelClick();
    logAnalyticEvent(CleverTapEventsMyAccount.webAddNameBackClick, {});
  };

  return (
    <CustomModal width="md:w-[640px] min-h-[223px]" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded p-10">
        <div className="flex flex-col gap-5">
          <FormCloseReactIcon
            id="my-account-name-modal-close"
            height="24"
            width="24"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onCancelClick}
          />
          <div className="text-xl font-bold text-heading">Add name</div>
          <div className="flex w-full flex-col">
            <div className="text-sbase font-semibold text-heading">First Name</div>
            <CustomInput
              id="update-first-name-input"
              inputStyle="w-full text-17px"
              type={InputType.TEXT}
              hasSuffix={true}
              onChange={value => {
                handleNameChange({ value, key: 'firstName' });
              }}
              value={nameValue.firstName}
              className={`mt-2.5 block h-[50px] rounded border px-4
               font-semibold text-primaryText  ${nameError.firstName ? 'border-error' : ''}
               placeholder:text-[17px] placeholder:font-semibold placeholder:text-accentText focus:outline-none`}
              placeholder="Enter first name"
            />
            {Boolean(nameError.firstName) && (
              <div className="mt-1 text-sbase font-semibold text-error">{nameError.firstName}</div>
            )}
          </div>
          <div className="flex w-full flex-col">
            <div className="text-sbase font-semibold text-heading">Middle Name</div>
            <CustomInput
              id="update-middle-name-input"
              inputStyle="w-full text-17px"
              type={InputType.TEXT}
              hasSuffix={true}
              onChange={value => {
                handleNameChange({ value, key: 'middleName' });
              }}
              value={nameValue.middleName}
              className={`mt-2.5 block h-[50px] rounded border px-4
               font-semibold text-primaryText
               placeholder:text-[17px] placeholder:font-semibold placeholder:text-accentText focus:outline-none`}
              placeholder="Enter middle name"
            />
          </div>
          <div className="flex w-full flex-col">
            <div className="text-sbase font-semibold text-heading">Last Name</div>
            <CustomInput
              id="update-last-name-input"
              inputStyle="w-full text-17px"
              type={InputType.TEXT}
              hasSuffix={true}
              onChange={value => {
                handleNameChange({ value, key: 'lastName' });
              }}
              value={nameValue.lastName}
              className={`mt-2.5 block h-[50px] rounded border px-4
               font-semibold text-primaryText  ${nameError.lastName ? 'border-error' : ''}
               placeholder:text-[17px] placeholder:font-semibold placeholder:text-accentText focus:outline-none`}
              placeholder="Enter last name"
            />
            {Boolean(nameError.lastName) && (
              <div className="mt-1 text-sbase font-semibold text-error">{nameError.lastName}</div>
            )}
          </div>
        </div>
        <div className="flex flex-row items-end justify-end gap-5">
          <CustomButton
            id="update-name-cancel-button"
            className="mt-6 h-[38px] w-[100px]"
            type={ButtonType.SECONDARY}
            onClick={onCancelBtnClick}>
            Cancel
          </CustomButton>
          <CustomButton
            id="update-name-save-button"
            isLoading={isSaveInProgress}
            onClick={onSave}
            className={`text-semibold mt-5 h-[38px] w-[100px] rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
            Save
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};
export default NameModal;
