import { IItem, IItemBasic, Tax } from './items.model';
import { ItemSliceState } from './itemsSlice';

export const prepareTaxArrayForEvent = (itemTaxes: Tax[]) => {
  if (itemTaxes?.length > 0) {
    const taxes = itemTaxes.map(tax => {
      return {
        name: tax.name,
        percentage: tax.percentage,
        taxId: tax.id
      };
    });
    return taxes;
  }
  return [];
};

export const joinItemArray = (array: string[], maxCount?: number) => {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return `'${array[0]}'`;
  } else if (array.length === 2) {
    return array.map(item => `'${item}'`).join(' and ');
  }
  const maxCountToDisplay = maxCount != null ? Math.min(maxCount, array.length) : array.length;
  const itemsArray = array.slice(0, maxCountToDisplay);
  let joinedItemArray = itemsArray.map(item => `'${item}'`).join(', ');

  const remainingItemsCount = array.length - maxCountToDisplay;
  joinedItemArray += ` and ${remainingItemsCount} ${remainingItemsCount === 1 ? 'other' : 'others'}`;

  return joinedItemArray;
};

const getTaxIds = taxArray => {
  return taxArray.map(tax => tax.id);
};

const isTaxArrayEqual = (initialTaxArray: Tax[], updatedTaxArray: Tax[]) => {
  if (updatedTaxArray.length !== initialTaxArray.length) return false;
  const initialTaxIds = getTaxIds(initialTaxArray);
  const updatedTaxIds = getTaxIds(updatedTaxArray);
  if (JSON.stringify(initialTaxIds) === JSON.stringify(updatedTaxIds)) {
    return true;
  }
};

export const isItemDataUpdated = (
  initialItemsData: ItemSliceState,
  updatedItemsData: IItem = {
    id: null,
    name: '',
    category: {
      id: null,
      name: ''
    },
    unitPrice: '',
    sku: '',
    description: '',
    taxes: []
  }
) => {
  if (
    initialItemsData.itemName === updatedItemsData.name &&
    initialItemsData.itemCategory.id === updatedItemsData.category.id &&
    initialItemsData.unitPrice === updatedItemsData.unitPrice &&
    initialItemsData.itemDescription === updatedItemsData.description &&
    initialItemsData.itemSku === updatedItemsData.sku &&
    isTaxArrayEqual(initialItemsData.itemTaxes, updatedItemsData.taxes ?? [])
  )
    return false;
  return true;
};

export const sortItemsAlphabetically = (itemsList: IItemBasic[]): IItemBasic[] => {
  return itemsList
    .slice()
    .sort((a, b) => a.name.trim().localeCompare(b.name.trim(), undefined, { sensitivity: 'base' }));
};

export const sortSelectedItemsToStart = ({
  itemsList = [],
  currentlySelectedItems = []
}: {
  itemsList: IItemBasic[];
  currentlySelectedItems: IItemBasic[];
}) => {
  let sortedItemsList = itemsList;
  if (itemsList.length > 0) {
    const selectedTaxList = sortedItemsList.filter(item => {
      return currentlySelectedItems.some(selectedItem => selectedItem.id === item.id);
    });
    const alphabeticallySortedSelectedItems = sortItemsAlphabetically(selectedTaxList);
    const unSelectedCatalogItems = sortItemsAlphabetically(
      sortedItemsList.filter(item => !currentlySelectedItems.some(selectedItem => selectedItem.id === item.id))
    );
    sortedItemsList = [...alphabeticallySortedSelectedItems, ...unSelectedCatalogItems];
  }
  return sortedItemsList;
};
