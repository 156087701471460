import { navTabs } from 'constants/navConstants';
import { FileTypes, NavTab } from 'types/baseTypes';
import Logger from './logger';
import { SalesTypeByDuration } from 'containers/home/types';
import DateUtils from 'utils/dateUtils';
import { format, subMonths } from 'date-fns';
import { camelCaseToTitleCase } from './stringUtils';
import routesPath from 'routes/RoutesPath';
import { REGEX } from 'constants/common';

export const isSubPathNav = (pathname: string) => {
  return (
    pathname === routesPath.ACCOUNT_SETTINGS_MY_ACCOUNT || pathname === routesPath.ACCOUNT_SETTINGS_CREDITS_REWARDS
  );
};

export const findActiveTab = (pathname: string): NavTab => {
  const validatedPathName = pathname.split('/')?.[1];
  return navTabs.find(tab => {
    return tab.path?.split('/')?.[1] == (isSubPathNav(pathname) ? pathname : `${validatedPathName}`);
  });
};

export const findActiveSubTab = (pathname: string): NavTab => {
  const activeTab = findActiveTab(pathname);
  const pathParams = pathname.split('/');
  const validatedPathname = pathParams.length > 2 ? `/${pathParams[1]}/${pathParams[2]}` : pathname;
  const activeSubTab = activeTab?.childTabs?.find(subTab => subTab.path == validatedPathname);
  return activeSubTab;
};

export const getInitials = fullname => {
  const name = fullname || '';
  let avtName = '';
  const [firstWord, secondWord] = name?.split(' ') ?? [];
  const firstChar = firstWord?.charAt(0).toUpperCase() ?? '';
  const secondChar = secondWord?.charAt(0).toUpperCase() ?? '';
  avtName = firstChar + secondChar;
  return avtName || '';
};

export const joinTextArray = (array: string[]) => {
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else if (array.length === 2) {
    return array.join(' and ');
  }
  const firstItem = array[0];
  return `${firstItem} and ${array.length - 1} more`;
};
export const areEqualArrays = ({
  primaryArray,
  secondaryArray,
  skipOrder = false
}: {
  primaryArray: Array<any>;
  secondaryArray: Array<any>;
  skipOrder?: boolean;
}): boolean => {
  if (primaryArray.length === 0 || secondaryArray.length === 0) {
    return false;
  }
  if (primaryArray.length === secondaryArray.length) {
    if (skipOrder) {
      return (
        primaryArray.every(primaryElement => secondaryArray.includes(primaryElement)) &&
        secondaryArray.every(secondaryElement => primaryArray.includes(secondaryElement))
      );
    }
    return primaryArray.every((element, index) => element === secondaryArray[index]);
  }
  return false;
};

export const debounce = (callBack, delay = 300) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      callBack(...args);
    }, delay);
  };
};

export const getTimestampInSeconds = () => {
  return Math.floor(Date.now() / 1000);
};

export const getBlobType = (fileType: FileTypes) => {
  if (fileType === FileTypes.PDF) {
    return 'application/pdf';
  }
  return 'text/csv';
};

export const createBlobURL = async (response: Response, fileType?: FileTypes) => {
  const blobPart = await response.blob();
  const blob: Blob = new Blob([blobPart], { type: getBlobType(fileType || FileTypes.PDF) });
  const blobURL = window.URL.createObjectURL(blob);
  return blobURL;
};

export const downloadFile = async ({
  response,
  fileType,
  fileName,
  isFileNameWithExtension = false
}: {
  response: Response;
  fileType?: FileTypes;
  fileName: string;
  isFileNameWithExtension?: boolean;
}) => {
  const blobURL = await createBlobURL(response, fileType);
  const hiddenElement = document.createElement('a');
  hiddenElement.href = blobURL;
  hiddenElement.target = '_blank';
  const timeStamp = getTimestampInSeconds();
  const fileNameWithExtension =
    fileType === FileTypes.PDF ? `${fileName + '-' + timeStamp}.pdf` : `${fileName + '-' + timeStamp}.csv`;
  hiddenElement.download = isFileNameWithExtension ? fileName : fileNameWithExtension;
  hiddenElement.click();
  hiddenElement.remove();
};

export const printFile = async ({ response }: { response: Response }) => {
  const blobURL = await createBlobURL(response);

  const newWindow = window.open(blobURL, '_blank');
  newWindow.focus();
  setTimeout(() => {
    try {
      // support for safari browser
      newWindow.document.execCommand('print', false, null);
    } catch {
      newWindow.print();
    }
  }, 500);
};

export const removeUSCountryCode = (phoneNumber: string) => {
  if (phoneNumber.startsWith('+1')) {
    return phoneNumber.replace('+1', '');
  } else if (phoneNumber.startsWith('+911')) {
    return phoneNumber.replace('+91', '');
  }
  return phoneNumber;
};

export const isEmailEndingValid = (email: string) => {
  let isValid = true;
  const inValidDoamins = ['..', '.con', '.comn', '.cok', '.don', '.cim', 'gnail.com', 'gmal.com'];
  inValidDoamins.map(_ => {
    if (email.endsWith(_)) {
      isValid = false;
    }
  });
  return isValid;
};

export const copyToClipBoardWithoutNavigator = (data: string): void => {
  const textField = document.createElement('textarea');
  textField.innerText = data;
  document.body.appendChild(textField);
  textField.select();
  document.execCommand('copy');
  textField.remove();
};

export const copyToClipBoard = (value: string): boolean => {
  try {
    if (navigator?.clipboard) {
      navigator.clipboard.writeText(value);
    } else {
      copyToClipBoardWithoutNavigator(value);
    }
    return true;
  } catch {
    // eslint-disable-next-line quotes
    Logger.logErrorMessage("Can't copy");
    return false;
  }
};

export const openInNewTab = (url: string) => {
  window.open(url, '_blank', 'noopener,noreferrer');
};

export const replaceURL = (url: string) => {
  window.location.href = url;
};

export const handleWindowReload = () => {
  window.location.reload();
};

export const throttle = (callBack, delay = 300) => {
  let inThrottle;
  return (...args) => {
    if (!inThrottle) {
      callBack(...args);
      inThrottle = true;
      setTimeout(() => (inThrottle = false), delay);
    }
  };
};

export const isEqualWithStringify = (primaryElement, secondaryElement) => {
  return JSON.stringify(primaryElement) === JSON.stringify(secondaryElement);
};

export const colorSelectorWithASCII = (name: string) => {
  let colorCode = 0;

  for (let i = 0; i < name.length; i++) colorCode += name.codePointAt(i);

  colorCode = colorCode % 4;

  switch (colorCode) {
    case 0:
      return '#D8DFE5';
    case 1:
      return '#A4D7FA';
    case 2:
      return '#CBF4C9';
    case 3:
      return '#F7E2C0';
  }
};

export const getDatesAsPerFilter = (filter: SalesTypeByDuration) => {
  const today = new Date();
  const lastDateOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
  switch (filter) {
    case SalesTypeByDuration.TODAY:
      return {
        startDate: DateUtils.getToday('yyyy-MM-dd') as string,
        endDate: DateUtils.getToday('yyyy-MM-dd') as string
      };
    case SalesTypeByDuration.THIS_MONTH:
      return {
        startDate: format(today, 'yyyy-MM-01') as string,
        endDate: DateUtils.getToday('yyyy-MM-dd') as string
      };
    case SalesTypeByDuration.LAST_MONTH:
      return {
        startDate: format(subMonths(today, 1), 'yyyy-MM-01') as string,
        endDate: format(lastDateOfLastMonth, 'yyyy-MM-dd') as string
      };
    case SalesTypeByDuration.THIS_YEAR:
      return {
        startDate: format(today, 'yyyy-01-01') as string,
        endDate: DateUtils.getToday('yyyy-MM-dd') as string
      };
  }
};

export const constructArrayObjectAsString = (array, type) => {
  if (!array || array.length === 0) return '';
  let data = '';
  array.forEach((item, index) => {
    let dataItem = '';

    Object.keys(item).forEach(key => {
      dataItem += `${camelCaseToTitleCase(key)}: ${item[key]},\t`;
    });

    data += `${type}-${index + 1}: { ${dataItem} }\n`;
  });
  return data;
};

export const constructArrayAsValue = (key, array) => {
  if (!array || array.length === 0) return '';
  let data = '';
  array.forEach(item => {
    data += `${item}, `;
  });

  return { [key]: data };
};

export const getPageName = page => {
  const pageName = page?.split('/')?.[1];
  return pageName;
};

export const checkEmailValid = (value: string, setIsEmailValid: React.Dispatch<React.SetStateAction<boolean>>) => {
  if (REGEX.email.test(value)) {
    setIsEmailValid(true);
  } else {
    setIsEmailValid(false);
  }
};

export const checkMarkEmailAsValid = ({
  value,
  markEmailAsValid
}: {
  value: string;
  markEmailAsValid: (boolean) => void;
}) => {
  if (REGEX.email.test(value)) {
    markEmailAsValid(true);
  } else {
    markEmailAsValid(false);
  }
};

export const getUrlWithProtocol = (url: string) => {
  if (url.startsWith('http')) return url;
  return `https://${url}`;
};
