import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { ITax } from './taxes.model';
import { IServiceChargeBasic } from '../service-charges/serviceCharge.model';
import { IItemBasic } from '../items/items.model';
import { TaxApplyToType } from './type';

export interface TaxSliceState {
  isEditTaxFlow: boolean;
  taxId: number;
  taxName: string;
  taxRate: string;
  applyToAllItems: boolean;
  lineItemsCount: number;
  applyToAllServiceCharges: boolean;
  serviceChargesCount: number;
  serviceCharges: IServiceChargeBasic[];
  initializingTaxSliceInProgress: boolean;
  items: IItemBasic[];
  deselectedItemList: number[]; // this includes all the unselected items from the whole itemList
  deselectedServiceChargeList: number[]; // this includes all the unselected sc from the whole serviceChargeList
}

const initialState: TaxSliceState = {
  isEditTaxFlow: false,
  taxId: null,
  taxName: '',
  applyToAllItems: true,
  lineItemsCount: 0,
  items: [],
  applyToAllServiceCharges: true,
  serviceChargesCount: 0,
  serviceCharges: [],
  taxRate: '',
  initializingTaxSliceInProgress: false,
  deselectedItemList: [],
  deselectedServiceChargeList: []
};

const isDeselectedItem = ({
  initialItem,
  deselectedItemList
}: {
  initialItem: IItemBasic;
  deselectedItemList: number[];
}) => {
  return deselectedItemList.includes(initialItem.id);
};

const isAlreadyInSliceItemList = ({
  initialItem,
  currentSliceItemList
}: {
  initialItem: IItemBasic;
  currentSliceItemList: IItemBasic[];
}) => {
  return currentSliceItemList.some(currentItem => currentItem.id === initialItem.id);
};

const isDeselectedServiceCharge = ({
  initialServiceCharge,
  deselectedServiceChargeList
}: {
  initialServiceCharge: IServiceChargeBasic;
  deselectedServiceChargeList: number[];
}) => {
  return deselectedServiceChargeList.includes(initialServiceCharge.id);
};

const isAlreadyInSliceServiceChargeList = ({
  initialServiceCharge,
  currentSliceServiceChargeList
}: {
  initialServiceCharge: IServiceChargeBasic;
  currentSliceServiceChargeList: IServiceChargeBasic[];
}) => {
  return currentSliceServiceChargeList.some(
    currentServiceCharge => currentServiceCharge.id === initialServiceCharge.id
  );
};

export const taxSlice = createSlice({
  name: 'tax',
  initialState,
  reducers: {
    resetTaxSlice: () => initialState,

    initializeTaxSliceFromAPI: (state, { payload }: PayloadAction<ITax>) => {
      state.isEditTaxFlow = true;
      state.taxId = payload.id;
      state.taxName = payload.name;
      state.taxRate = payload.percentage;
      state.applyToAllItems = payload.applyToAllItems;
      if (payload.lineItems) {
        const filteredItems = payload.lineItems.filter(
          initialItem =>
            !isDeselectedItem({ initialItem, deselectedItemList: state.deselectedItemList }) &&
            !isAlreadyInSliceItemList({ initialItem, currentSliceItemList: state.items })
        );
        state.items = [...state.items, ...filteredItems];
        state.lineItemsCount = state.items.length;
      }
      state.applyToAllServiceCharges = payload.applyToAllServiceCharges;
      if (payload.serviceCharges) {
        const filteredServiceCharge = payload.serviceCharges.filter(
          initialServiceCharge =>
            !isDeselectedServiceCharge({
              initialServiceCharge,
              deselectedServiceChargeList: state.deselectedServiceChargeList
            }) &&
            !isAlreadyInSliceServiceChargeList({
              initialServiceCharge,
              currentSliceServiceChargeList: state.serviceCharges
            })
        );
        state.serviceCharges = [...state.serviceCharges, ...filteredServiceCharge];
        state.serviceChargesCount = state.serviceCharges.length;
      }
      state.initializingTaxSliceInProgress = false;
    },

    updateTaxName: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.taxName = payload.name;
    },

    updateTaxRate: (state, { payload }: PayloadAction<{ rate: string }>) => {
      state.taxRate = payload.rate;
    },

    updateInitalizingTaxSliceInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.initializingTaxSliceInProgress = payload;
    },

    updateSelectedItems: (state, { payload }: PayloadAction<IItemBasic[]>) => {
      state.items = payload;
      state.lineItemsCount = payload.length;
    },

    updateSelectedServiceCharges: (state, { payload }: PayloadAction<IServiceChargeBasic[]>) => {
      state.serviceCharges = payload;
      state.serviceChargesCount = payload.length;
    },

    updateApplyToAll: (state, { payload }: PayloadAction<{ type: TaxApplyToType; value: boolean }>) => {
      if (payload.type === TaxApplyToType.ITEM) {
        state.applyToAllItems = payload.value;
      } else if (payload.type === TaxApplyToType.SERVICE_CHARGE) {
        state.applyToAllServiceCharges = payload.value;
      }
    },

    updateDeselectedItems: (state, { payload }: PayloadAction<number[]>) => {
      state.deselectedItemList = payload;
    },

    addNewlyCreatedItemInTaxSlice: (state, { payload }: PayloadAction<IItemBasic>) => {
      state.items = [...state.items, payload];
      state.lineItemsCount = state.lineItemsCount + 1;
    },

    updateDeselectedServiceCharges: (state, { payload }: PayloadAction<number[]>) => {
      state.deselectedServiceChargeList = payload;
    },

    addNewlyCreatedServiceChargeInTaxSlice: (state, { payload }: PayloadAction<IServiceChargeBasic>) => {
      state.serviceCharges = [...state.serviceCharges, payload];
      state.serviceChargesCount = state.serviceChargesCount + 1;
    }
  }
});

export const {
  resetTaxSlice,
  updateInitalizingTaxSliceInProgress,
  initializeTaxSliceFromAPI,
  updateTaxName,
  updateTaxRate,
  updateSelectedItems,
  updateApplyToAll,
  updateSelectedServiceCharges,
  updateDeselectedItems,
  addNewlyCreatedItemInTaxSlice,
  updateDeselectedServiceCharges,
  addNewlyCreatedServiceChargeInTaxSlice
} = taxSlice.actions;

export const selectTaxState = (state: RootState) => state.rootReducer.tax;
export const selectInitializingTaxSliceInProgress = (state: RootState) =>
  state.rootReducer.tax.initializingTaxSliceInProgress;

export default taxSlice.reducer;
