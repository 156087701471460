import { IInvoiceServiceCharge } from '../invoices.model';
import { InvoiceValidationError } from './types';

export enum InvoiceTabEnum {
  BILL_TO = 'Bill To',
  INVOICE_OPTIONS = 'Invoice Options',
  LINE_ITEMS = 'Line Items',
  LINE_ITEMS_INPUT = 'LINE_ITEMS_INPUT',
  NOTES_ATTACHMENTS = 'Notes & Attachments',
  PAYMENT_OPTIONS = 'Payment Options',
  SIGNATURES = 'Signatures'
}

export const USPincodeRegex = new RegExp('^\\d{5}(?:[-\\s]\\d{4})?$');
export const emailRegex = new RegExp('^[\\w-\\.]+@([\\w-]+\\.)+[c\\w-]{2,4}$');

export enum InvoiceValidationErrorType {
  MIN_LINE_ITEM = 'minLineItem'
}

export const minimumLineItemsError: InvoiceValidationError = {
  type: InvoiceValidationErrorType.MIN_LINE_ITEM,
  errorMsg: 'Line Item is missing'
};

export const initServiceCharge: IInvoiceServiceCharge = {
  id: null,
  name: '',
  amount: '',
  percentage: '',
  taxes: [],
  totalAmount: ''
};
