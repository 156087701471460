import { BackIcon, DeleteIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { CreateInvoiceHeaderType } from 'constants/invoicesConstants';

interface CatalogTopBarProps {
  title: string;
  onReturn: VoidFn;
  primaryBtnTitle: string;
  secondaryBtnTitle?: string;
  onPrimaryClick: VoidFn;
  onSecondaryClick?: VoidFn;
  disablePrimaryBtn?: boolean;
  className?: string;
  type?: CreateInvoiceHeaderType;
  teritiaryBtnTitle?: string;
  onTeritiaryClick?: VoidFn;
  primaryBtnId: string;
  secondaryBtnId?: string;
  isPrimaryButtonLoading?: boolean;
  backButtonId: string;
}

const CatalogTopBar: FC<CatalogTopBarProps> = ({
  title,
  onReturn,
  className,
  primaryBtnTitle,
  secondaryBtnTitle,
  onPrimaryClick,
  onSecondaryClick = () => null,
  disablePrimaryBtn = false,
  type = CreateInvoiceHeaderType.CREATE,
  teritiaryBtnTitle = '',
  onTeritiaryClick = null,
  primaryBtnId,
  secondaryBtnId,
  isPrimaryButtonLoading,
  backButtonId
}) => {
  return (
    <div className={twMerge('min-h-[60px] pl-8 pr-10 bg-primary flex flex-row items-center w-full', className)}>
      <div className="flex w-full flex-row justify-between">
        <div className="flex flex-row items-center gap-5">
          <BackIcon
            tabIndex={0}
            id={backButtonId}
            className="shrink-0 cursor-pointer focus:outline-none"
            onClick={onReturn}
          />
          <div id={`${title}-title`} className="text-xl font-bold text-primaryText">
            {title}
          </div>
        </div>

        <div className="flex flex-row gap-5">
          {secondaryBtnTitle && (
            <CustomButton
              id={secondaryBtnId}
              onClick={onSecondaryClick}
              startIconStyle="w-4 h-4"
              StartIcon={DeleteIcon}
              className="h-[38px]"
              childrenContainerStyles="items-center"
              type={ButtonType.SECONDARY}>
              <div className="text-sbase font-semibold text-primaryText">{secondaryBtnTitle}</div>
            </CustomButton>
          )}
          <CustomButton
            id={primaryBtnId}
            className="h-[38px] min-w-[100px]"
            disabled={disablePrimaryBtn}
            onClick={onPrimaryClick}
            isLoading={isPrimaryButtonLoading}>
            {primaryBtnTitle}
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default CatalogTopBar;
