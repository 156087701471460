import React from 'react';
import { Divider } from '@material-ui/core';
import {
  CardIcon,
  DownloadIconWithGreyBg,
  PaymentCheckIcon,
  ScanQRIcon,
  SendIconWithGreyBg,
  TTPDisabledIcon
} from 'assets/icons';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { InvoicePaymentMethodTile } from './InvoicePaymentMethodTile';
import { InvoiceByIdResponse, InvoiceResponse } from '../invoices.model';
import { VoidFn } from 'types/baseTypes';

interface SharePendingInvoiceMainSectionProps {
  invoiceData: InvoiceByIdResponse | InvoiceResponse;
  handleManualKeyIn: VoidFn;
  handleMarkAsFullyPaid: VoidFn;
  handleSendInvoice: VoidFn;
  handleDownloadInvoice: VoidFn;
  handlePayWithQRCodeClick: VoidFn;
}

export const SharePendingInvoiceMainSection: React.FC<SharePendingInvoiceMainSectionProps> = ({
  invoiceData,
  handleDownloadInvoice,
  handleManualKeyIn,
  handleSendInvoice,
  handleMarkAsFullyPaid,
  handlePayWithQRCodeClick
}) => {
  const optionsList = [
    {
      id: 'invoice-modal-manual-key-in-btn',
      label: 'Manually enter card details',
      icon: <CardIcon />,
      onClick: handleManualKeyIn,
      highlightOption: true
    },
    {
      id: 'invoice-modal-mark-fully-paid-btn',
      label: 'Mark as fully paid',
      icon: <PaymentCheckIcon />,
      onClick: handleMarkAsFullyPaid,
      highlightOption: false
    },
    {
      id: 'invoice-modal-send-btn',
      label: 'Send invoice',
      icon: <SendIconWithGreyBg />,
      onClick: handleSendInvoice,
      highlightOption: false
    },
    {
      id: 'invoice-modal-download-btn',
      label: 'Download invoice',
      icon: <DownloadIconWithGreyBg />,
      onClick: handleDownloadInvoice,
      highlightOption: false
    }
  ];

  const showAmountSubtext = Number(invoiceData?.payableAmount) - Number(invoiceData?.billAmount) > 0;
  return (
    <>
      <section className="mt-10">
        <div className="flex flex-col items-center gap-2.5 text-[15px] leading-[21px] text-primaryText">
          {(invoiceData as InvoiceByIdResponse)?.invoiceNumber}
          <div className="text-4xl font-bold leading-[43px] text-headingBlack">
            {getAmountWithCurrency(invoiceData?.payableAmount)}
          </div>
          {showAmountSubtext &&
            `${getAmountWithCurrency(invoiceData.billAmount)} + ${getAmountWithCurrency(
              Number(invoiceData?.payableAmount) - Number(invoiceData.billAmount)
            )} Taxes & fees`}
        </div>
        <Divider className="my-5 w-full bg-borderGray" />
      </section>
      <section className="flex flex-col gap-5">
        <div className="flex gap-4">
          <InvoicePaymentMethodTile
            label="Pay with QR code"
            icon={<ScanQRIcon />}
            isDisabled={false}
            onClick={handlePayWithQRCodeClick}
          />
          <InvoicePaymentMethodTile
            label="Tap to Pay on iPhone"
            icon={<TTPDisabledIcon />}
            isDisabled={true}
            onClick={() => null}
          />
        </div>
        {optionsList.map((option, index) => (
          <div
            key={option.id}
            id={option.id}
            className={`flex cursor-pointer items-center gap-[22px] rounded-md border border-borderGray 
                    py-2.5 pl-4 text-17px font-semibold text-primaryText
                    ${option.highlightOption ? 'border-secondaryBtn bg-secondaryBtn' : ''}`}
            onClick={option.onClick}>
            {option.icon}
            {option.label}
          </div>
        ))}
      </section>
    </>
  );
};
