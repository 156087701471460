import { LogoutIcon, MyAccountIcon } from 'assets/icons';
import React, { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface AccountMenuProps {
  onLogout: VoidFn;
  onMyAccountClick: VoidFn;
}

const AccountMenu: FC<AccountMenuProps> = ({ onLogout, onMyAccountClick }) => {
  return (
    <div className="flex flex-col space-y-6 text-sbase font-normal text-primaryText">
      <div
        id="account-action-my-account"
        onClick={onMyAccountClick}
        className="flex cursor-pointer flex-row items-center gap-4">
        <MyAccountIcon className="shrink-0" />
        <div>My Account</div>
      </div>
      {/* TODO: Integrate once feature is available in webApp */}
      {/* <div id="account-action-refer-and-earn" onClick={null} className="flex flex-row items-center gap-4">
        <ReferEarnIcon className="shrink-0" />
        <div>Refer & Earn</div>
      </div>
      <div id="account-action-credits-and-rewards" onClick={null} className="flex flex-row items-center gap-4">
        <CreditsRewardsIcon className="shrink-0" />
        <div>Credits & Rewards</div>
      </div> */}
      <div id="account-action-logout" onClick={onLogout} className="flex cursor-pointer flex-row items-center gap-4">
        <LogoutIcon className="shrink-0" />
        <div>Logout</div>
      </div>
    </div>
  );
};

export default AccountMenu;
