import { CustomPopover } from 'components';
import { PopoverArrowTypes } from 'constants/common';
import React, { FC } from 'react';
import { VoidFn } from 'types/baseTypes';

interface DisputeStatusToolTipProps {
  InfoIcon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }> | null;
  InfoText: string;
  handleShowToolTip: VoidFn;
  handleCloseToolTip: VoidFn;
  showToolTip: boolean;
  widthStyle?: string;
}

const DisputeStatusToolTip: FC<DisputeStatusToolTipProps> = ({
  InfoIcon,
  InfoText,
  handleCloseToolTip,
  handleShowToolTip,
  showToolTip,
  widthStyle = 'w-[270px]'
}) => {
  const toolTipElement = () => (
    <div onMouseEnter={handleShowToolTip} onMouseLeave={handleCloseToolTip} className="cursor-pointer">
      <InfoIcon className="shrink-0" />
    </div>
  );
  return (
    <CustomPopover
      show={showToolTip}
      onTogglePopover={handleShowToolTip}
      onClosePopover={handleCloseToolTip}
      anchorComponent={toolTipElement}
      highlightAnchor={false}
      offset="-left-40"
      showArrow={true}
      arrowType={PopoverArrowTypes.TOP}
      arrowOffset="right-2 left-[168px]"
      margin="mt-3"
      width={widthStyle}>
      <div className="text-px13 font-normal text-primaryText">{InfoText}</div>
    </CustomPopover>
  );
};

export default DisputeStatusToolTip;
