import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import { getUrlSearchParams } from 'utils/apiUtils';
import {
  CategoryAPIPayload,
  CreateCategoryResponse,
  DeleteCategoryPayload,
  EditCategoryAPIPayload,
  GetCategoryPayload,
  ICategory
} from './categories.model';
import { API_RESPONSES, GenericPaginationResponse } from 'types/baseTypes';
import { initializeCategorySliceFromAPI, updateInitalizingCategorySliceInProgress } from './categorySlice';

const CategoriesBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCategories: builder.query<GenericPaginationResponse<ICategory>, GetCategoryPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/categories?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Something went wrong',
        showToast: true
      }
    }),

    deleteCategory: builder.mutation<API_RESPONSES, DeleteCategoryPayload>({
      query: ({ id, teamId }) => {
        return {
          url: `${API_VERSION.V1}/catalogue/categories/${id}?teamId=${teamId}`,
          method: 'DELETE'
        };
      },
      extraOptions: {
        failure: 'Failed to delete category',
        success: 'Category successfully deleted',
        showToast: true
      }
    }),

    getCategoryById: builder.query<ICategory, { id: number; teamId: number }>({
      query: ({ id, teamId }) => {
        const url = `${API_VERSION.V1}/catalogue/categories/${id}?teamId=${teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },

      async onQueryStarted(id, { dispatch, queryFulfilled }) {
        dispatch(updateInitalizingCategorySliceInProgress(true));
        const { data } = await queryFulfilled;
        try {
          if (data) {
            dispatch(initializeCategorySliceFromAPI(data));
          }
        } catch (err) {
          dispatch(updateInitalizingCategorySliceInProgress(false));
        }
      },

      extraOptions: {
        failure: 'Something went wrong',
        showToast: true
      }
    }),

    createCategory: builder.mutation<CreateCategoryResponse, CategoryAPIPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/categories`;
        return {
          url: url,
          method: 'POST',
          body: params
        };
      },

      extraOptions: {
        showToast: true,
        failure: 'Failed to create category'
      }
    }),

    editCategory: builder.mutation<API_RESPONSES, EditCategoryAPIPayload>({
      query: ({ id, ...rest }) => {
        const url = `${API_VERSION.V1}/catalogue/categories/${id}`;
        return {
          url: url,
          method: 'PUT',
          body: rest
        };
      },

      extraOptions: {
        failure: 'Failed to edit category',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetCategoriesQuery,
  useDeleteCategoryMutation,
  useGetCategoryByIdQuery,
  useCreateCategoryMutation,
  useEditCategoryMutation
} = CategoriesBaseApi;
