import { CustomButton, CustomModal, If } from 'components';
import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { useAppSelector } from 'hooks';
import React, { FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { ArrowRightIcon, CrossBoundaryIcon, GreenTickIcon, RedCloseIcon } from 'assets/icons';
import Divider from '@material-ui/core/Divider';
import { useForm } from 'react-hook-form';
import { useCreateCustomerMutation, useUpdateCustomerMutation } from 'containers/customers/api';
import { CreateCustomerModalTypes } from 'constants/CustomerConstants';
import { Customer } from 'containers/invoices/invoice.model';
import { isEmailEndingValid, removeUSCountryCode, throttle } from 'utils/commonUtils';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { ICustomer } from 'containers/customers/customers.model';
import { any, string, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { USPincodeRegex } from 'containers/invoices/create-invoice/constants';
import { REGEX } from 'constants/common';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { ButtonType, FormButtonType } from 'components/custom-button/CustomButton';
import { useDispatch } from 'react-redux';
import { NotifierTypes } from 'constants/notifierConstants';

interface TeamSwitcherProps {
  open: boolean;
  onClose: VoidFn;
  panelType?: CreateCustomerModalTypes;
  customerData?: Customer | null;
  customerDataFetchLoading?: boolean;
  customerDataFetchSuccess?: boolean;
  createOrEditCustomerCallBack?: (customer?: ICustomer) => void;
  defaultName?: string;
}

const lineItemFormSchema = z.object({
  zipCode: string().refine(
    val => {
      return val.length === 0 || USPincodeRegex.test(val);
    },
    { message: 'Enter valid Zip code' }
  ),
  name: string().min(1, { message: 'Please enter first name' }),
  email: string().optional(),
  phoneNumber: any(),
  lastName: string().optional(),
  cityStateCountry: string().optional(),
  street: string().optional()
});

const CreateCustomer: FC<TeamSwitcherProps> = ({
  open,
  onClose,
  panelType = CreateCustomerModalTypes.CREATE_CUSTOMER,
  customerData = null,
  customerDataFetchLoading = false,
  customerDataFetchSuccess = false,
  createOrEditCustomerCallBack,
  defaultName = ''
}) => {
  const activeTeam = useAppSelector(selectCurrentTeam);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);
  const dispatch = useDispatch();
  const [isEmailValid, setIsEmailValid] = useState(true);

  let defaultFirstName = '';
  let defaultLastName = '';
  if (defaultName !== '') {
    const firstSplitIndex = defaultName.indexOf(' ');
    if (firstSplitIndex !== -1) {
      defaultFirstName = defaultName.substring(0, firstSplitIndex);
      defaultLastName = defaultName.substring(firstSplitIndex + 1);
    } else defaultFirstName = defaultName;
  }

  const { register, handleSubmit, reset, formState, setError, clearErrors, getValues } = useForm({
    defaultValues: useMemo(() => {
      return {
        name: customerData?.name || defaultFirstName || '',
        email: customerData?.email || '',
        // phoneNumber: getPhoneDefaultValue(),
        lastName: customerData?.lastName || defaultLastName || '',
        street: customerData?.street || '',
        zipCode: customerData?.zipCode || '',
        cityStateCountry: customerData?.cityStateCountry || ''
      };
    }, [customerData, customerDataFetchLoading]),
    resolver: zodResolver(lineItemFormSchema)
  });

  const { errors } = formState;

  useEffect(() => {
    reset({
      name: customerData?.name || defaultFirstName || '',
      email: customerData?.email || '',
      lastName: customerData?.lastName || defaultLastName || '',
      street: customerData?.street || '',
      zipCode: customerData?.zipCode || '',
      cityStateCountry: customerData?.cityStateCountry || ''
    });

    setPhoneNumber(removeUSCountryCode(customerData?.phoneNumber || ''));
  }, [customerData, customerDataFetchLoading]);

  const panelTypeText =
    panelType === CreateCustomerModalTypes.CREATE_CUSTOMER ? 'Create New Customer' : 'Edit Customer Contact';

  const [createCustomer, { isSuccess: createCustomerSuccess, data: createCustomerData }] = useCreateCustomerMutation();
  const [updateCustomer, { isSuccess: updateCustomerSuccess, data: updatedCustomerData }] = useUpdateCustomerMutation();

  const getPhoneNumberForApi = () => {
    if (phoneNumber.length === 0) return '';
    return phoneNumber.startsWith('+') ? phoneNumber : `+1${phoneNumber}`;
  };
  const createCustomerOnclick = (inputParams: any) => {
    if (REGEX.usIndianNumber.test(phoneNumber) || phoneNumber.length === 0) {
      createCustomer({
        teamId: activeTeam.id,
        name: inputParams.name,
        email: inputParams.email,
        lastName: inputParams.lastName,
        phoneNumber: getPhoneNumberForApi(),
        street: inputParams.street,
        zipCode: inputParams.zipCode,
        cityStateCountry: inputParams.cityStateCountry
      });
    } else setIsPhoneNumberValid(false);
  };

  useEffect(() => {
    if (createCustomerSuccess) {
      createOrEditCustomerCallBack(createCustomerData);
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Customer created successfully',
            secondaryMessage: '',
            isMessageHtml: true
          },
          type: NotifierTypes.SUCCESS,
          showClose: true,
          fontStyle: 'text-primary font-normal'
        })
      );
    } else if (updateCustomerSuccess) {
      createOrEditCustomerCallBack(updatedCustomerData);
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'Customer updated successfully',
            secondaryMessage: '',
            isMessageHtml: true
          },
          type: NotifierTypes.SUCCESS,
          showClose: true,
          fontStyle: 'text-primary font-normal'
        })
      );
    }
  }, [createCustomerSuccess, updateCustomerSuccess]);

  const updateCustomerOnClick = (inputParams: any) => {
    if (REGEX.usIndianNumber.test(phoneNumber) || phoneNumber.length === 0) {
      updateCustomer({
        customerId: customerData?.ID,
        teamId: activeTeam.id,
        name: inputParams.name,
        email: inputParams.email,
        lastName: inputParams.lastName,
        phoneNumber: getPhoneNumberForApi(),
        street: inputParams.street,
        zipCode: inputParams.zipCode,
        cityStateCountry: inputParams.cityStateCountry
      });
    } else setIsPhoneNumberValid(false);
  };

  const formSubmit = (inputParams: any) => {
    if (panelType === CreateCustomerModalTypes.CREATE_CUSTOMER) {
      createCustomerOnclick(inputParams);
    } else {
      updateCustomerOnClick(inputParams);
    }
  };

  const getErrorMsg = error => {
    if (error?.type === 'invalid_type') {
      return 'Enter a valid input';
    }
    return error?.message;
  };

  const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    if (REGEX.phoneNumberInput.test(value)) {
      setPhoneNumber(value);
      setIsPhoneNumberValid(true);
    }
  };

  const formSubmitThrottler = useCallback(throttle(formSubmit, 800), [customerData, phoneNumber]);

  return (
    <Fragment>
      <CustomModal open={open} backgroundColor="bg-primary" widthInPx={'607px'}>
        <If condition={customerDataFetchLoading}>
          <div className="flex w-full items-center justify-center">
            <Lottie className="h-20 w-20" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        </If>

        <If
          condition={
            (customerDataFetchSuccess && !customerDataFetchLoading) ||
            panelType === CreateCustomerModalTypes.CREATE_CUSTOMER
          }>
          <div className="flex w-full flex-col p-2">
            <div className="flex flex-row justify-end	">
              <CrossBoundaryIcon id="create-customer-close-button" className={'cursor-pointer'} onClick={onClose} />
            </div>
            <div className="mb-4 px-5">
              <div
                id={`${panelTypeText}-modal-heading`}
                className={'mb-5 font-lato text-xl font-bold text-headingGray'}>
                {panelTypeText}
              </div>
              <form className="w-full" onSubmit={handleSubmit(data => formSubmitThrottler(data))}>
                <div className="-mx-3 flex flex-wrap ">
                  <div className="w-full px-3 md:mb-0 md:w-1/2">
                    <label
                      className="mb-2 block font-lato text-sbase font-semibold tracking-wide text-headingGray"
                      htmlFor="grid-first-name">
                      First Name
                    </label>
                    <input
                      className="w-full appearance-none
                rounded  bg-secondaryBtn py-3 px-4 leading-tight
                text-gray-700 focus:bg-secondaryBtn focus:outline-none"
                      id="customer-first-name"
                      type="text"
                      placeholder="John"
                      // required
                      {...register('name')}
                    />
                    <div className="mt-1 text-sbase font-semibold text-error">{getErrorMsg(errors.name)}</div>
                  </div>
                  <div className="w-full px-3 md:w-1/2">
                    <label
                      className="mb-2 block font-lato text-sbase font-semibold tracking-wide text-headingGray"
                      htmlFor="grid-last-name">
                      Last Name
                    </label>
                    <input
                      className="w-full  appearance-none rounded
                bg-secondaryBtn  py-3 px-4 leading-tight
                text-gray-700 focus:bg-secondaryBtn focus:outline-none"
                      id="customer-last-name"
                      type="text"
                      placeholder="Doe"
                      {...register('lastName')}
                    />
                  </div>
                </div>
                <div className="-mx-3 mb-3 flex flex-wrap">
                  <div className="w-full px-3">
                    <label
                      className="mb-2 block font-lato text-sbase font-semibold tracking-wide text-headingGray"
                      htmlFor="grid-phone-number">
                      Phone Number
                    </label>
                    <div
                      className="flex w-full
                 appearance-none flex-row rounded bg-secondaryBtn
                 py-3  px-4 leading-tight ">
                      <div className={'mr-1 text-accentText'}>+1</div>
                      <input
                        type={InputType.TEXT}
                        id="customer-phoneNumber"
                        placeholder="(000) 00-0000"
                        className={'appearance-none bg-secondaryBtn outline-none focus:outline-none'}
                        value={phoneNumber}
                        onChange={handleChangePhoneNumber}
                      />
                    </div>
                    <If condition={!isPhoneNumberValid}>
                      <div className="mt-1 text-sbase font-semibold text-error">Please enter a valid phone number</div>
                    </If>
                  </div>
                </div>

                <div className="-mx-3 mb-5 flex flex-wrap">
                  <div className="w-full px-3">
                    <label
                      className="mb-2 block font-lato text-sbase font-semibold tracking-wide text-headingGray"
                      htmlFor="grid-email">
                      Email Id
                    </label>
                    <input
                      className="w-full  appearance-none rounded
                bg-secondaryBtn  py-3 px-4 leading-tight
                text-gray-700 focus:bg-secondaryBtn focus:outline-none"
                      id="customer-email"
                      type="text"
                      {...register('email')}
                      onChange={e => {
                        register('email').onChange(e);
                        const val = e.target.value;
                        const isValid = val.length === 0 || (REGEX.email.test(val) && isEmailEndingValid(val));
                        if (isValid) {
                          clearErrors('email'); // for clear single input error
                          setIsEmailValid(true);
                        } else {
                          setError('email', {
                            type: 'manual',
                            message: 'Please enter valid email'
                          });
                          setIsEmailValid(false);
                        }
                      }}
                      placeholder="abc@example.com"
                    />
                    <If condition={!isEmailValid}>
                      <div className="mt-1 text-sbase font-semibold text-error">Please enter a valid Email Id</div>
                    </If>
                  </div>
                </div>

                <Divider variant="fullWidth" className="bg-borderGray" />

                <div className={'mt-4 font-lato text-base font-bold text-headingGray'}>Billing Address</div>

                <div className="-mx-3 mt-4 flex flex-wrap">
                  <div className="w-full px-3 md:mb-0 md:w-1/2">
                    <div
                      className={`flex items-center rounded bg-secondaryBtn  py-3 px-4  
                    ${errors.zipCode && 'border border-error'}`}>
                      <input
                        className="w-full appearance-none bg-secondaryBtn leading-tight
                      text-gray-700 focus:bg-secondaryBtn focus:outline-none"
                        id="customer-zipcode"
                        type="text"
                        {...register('zipCode')}
                        onChange={e => {
                          register('zipCode').onChange(e);
                          const val = e.target.value;
                          if (val.length === 0 || val.length == 5 || (val.includes('-') && val.length == 10)) {
                            clearErrors('zipCode');
                          } else {
                            setError('zipCode', {
                              type: 'manual',
                              message: 'Please enter a valid zipcode'
                            });
                          }
                        }}
                        placeholder="Zipcode"
                      />
                      {getValues('zipCode') && (errors.zipCode ? <RedCloseIcon /> : <GreenTickIcon />)}
                    </div>
                    <div className="mt-1 text-sbase font-semibold text-error">{getErrorMsg(errors.zipCode)}</div>
                  </div>
                  <div className="w-full px-3 md:w-1/2">
                    <input
                      className="w-full  appearance-none rounded
                bg-secondaryBtn  py-3 px-4 leading-tight
                text-gray-700 focus:bg-secondaryBtn focus:outline-none"
                      id="customer-street-address"
                      type="text"
                      {...register('street')}
                      placeholder="Street Address"
                    />
                  </div>
                </div>

                <div className="-mx-3 my-5 flex flex-wrap">
                  <div className="w-full px-3">
                    <input
                      className="w-full  appearance-none rounded
                bg-secondaryBtn  py-3 px-4 leading-tight
                text-gray-700 focus:bg-secondaryBtn focus:outline-none"
                      id="customer-city"
                      type="text"
                      {...register('cityStateCountry')}
                      placeholder="City/State/Country"
                    />
                  </div>
                </div>

                {panelType === CreateCustomerModalTypes.EDIT_CUSTOMER && (
                  <div
                    className="flex cursor-pointer flex-row items-center"
                    onClick={() => {
                      window.open(`/customers/${activeTeam?.id}/${customerData.ID}/customer`, '_blank');
                    }}>
                    <div id="view-full-profile" className={'text-base font-semibold text-secondary'}>
                      View full profile
                    </div>
                    <div className={'ml-1'}>
                      <ArrowRightIcon />
                    </div>
                  </div>
                )}

                <div className={'mb-7 flex w-full flex-1 flex-row justify-end'}>
                  <CustomButton
                    id="create/edit-customer-cancel-button"
                    type={ButtonType.SECONDARY}
                    className="w-24"
                    onClick={onClose}>
                    <div className="font-semibold">Cancel</div>
                  </CustomButton>

                  <CustomButton
                    id="create/update-customer-button"
                    className=" ml-5 w-24"
                    formType={FormButtonType.submit}
                    disabled={Object.keys(errors).length > 0 || !isEmailValid}>
                    <div className="text-base font-semibold">
                      {panelType === CreateCustomerModalTypes.CREATE_CUSTOMER ? 'Create' : 'Update'}
                    </div>
                  </CustomButton>
                </div>
              </form>
            </div>
          </div>
        </If>
      </CustomModal>
    </Fragment>
  );
};

export default CreateCustomer;
