import { Avatar } from '@material-ui/core';
import { CircleCloseIcon } from 'assets/icons';
import classnames from 'classnames';
import { TeamRole } from 'containers/app/app.model';
import { teamMemberRoles } from 'containers/manage-team/edit-permissions/constants';
import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { getInitials } from 'utils/commonUtils';

interface ParentListObject {
  id: string | number;
  profileImageUrl: string;
  name: string;
  role: string;
}

interface ListModalProps<T extends ParentListObject> {
  title: string;
  buttonLabel?: string;
  onClose: VoidFn;
  onItemSelection: (item: T) => void;
  onButtonClick: VoidFn;
  selectedItem: T;
  list: Array<T>;
}

const ListModal = <T extends ParentListObject>({
  title,
  onClose,
  onItemSelection,
  onButtonClick,
  selectedItem,
  list,
  buttonLabel
}: ListModalProps<T>): ReactElement => {
  const isSelectedItem = (id: string | number) => {
    return id === selectedItem.id;
  };

  return (
    <div className="flex w-full grow flex-col">
      <div className="flex flex-row items-center justify-between border-b border-borderGray py-2.5 pl-4 pr-2.5">
        <div className="text-sbase font-semibold text-headingGray">{title}</div>
        <CircleCloseIcon className="shrink-0 cursor-pointer self-end" onClick={onClose} />
      </div>
      <div className="customNormalScroll h-full max-h-[235px] overflow-y-scroll">
        {list?.map(item => (
          <div key={item.id}>
            <div
              onClick={() => onItemSelection(item)}
              className={twMerge(
                'cursor-pointer border-b h-20 flex flex-row items-center gap-2 px-4',
                classnames({
                  'bg-secondary': isSelectedItem(item.id)
                })
              )}>
              <div>
                <Avatar src={item.profileImageUrl} className="h-10 w-10 bg-gradient-to-r from-[#D8DFE5] to-[#A4D7FA]">
                  <div className="text-px13 font-semibold leading-5 text-[#333333]">{getInitials(item.name)}</div>
                </Avatar>
              </div>
              <div>
                <div
                  className={`max-w-[250px] truncate text-sbase font-semibold text-tertiaryText ${
                    isSelectedItem(item.id) ? 'text-secondaryText' : ''
                  }`}>
                  {item.name}
                </div>
                <div
                  className={`primary-text mt-0.5 text-px13 font-normal text-primaryText ${
                    isSelectedItem(item.id) ? 'text-secondaryText' : ''
                  }`}>
                  {item.role === TeamRole.SUPER_ADMIN ? teamMemberRoles[4].name : item.role}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* Removed cursor-pointer as its a static button for now */}
      {buttonLabel && (
        <div
          onClick={onButtonClick}
          className="mt-auto border-t border-borderGray p-4 text-sbase font-semibold text-primaryButtonHover">
          {buttonLabel}
        </div>
      )}
    </div>
  );
};

export default ListModal;
