import { selectCurrentTeam, showNotifier } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useCreateInvoiceMutation, useLazyGetCustomerByIdQuery } from '../api';
import { PreviewBar } from '../components';
import { IInvoiceAttachmentRequest, InvoicePayload, TaxesAndFeesResponse } from '../invoices.model';
import {
  addInvoiceValidationError,
  enableCustomerNotSelectedError,
  resetInvoiceState,
  selectInvoice,
  selectIsSignatureEnabled,
  updateInvoiceCreatedResponse,
  updateInvoiceCustomer,
  updateIsSignatureEnabled
} from '../InvoicesSlice';
import BillToSection from './components/BillToSection';
import InvoiceOptionsSection from './components/InvoiceOptionsSection';
import InvoicesTabSideNav from './components/InvoicesTabSideNav';
import LineItemsSection from './components/LineItemsSection';
import NotesAttachmentsSection from './components/NotesAttachmentsSection';
import PaymentOptionsSection from './components/PaymentOptionsSection';
import { InvoiceTabEnum, minimumLineItemsError } from './constants';

import routesPath from 'routes/RoutesPath';
import { InvoicesRouteState } from 'routes/types';
import { NotifierTypes } from 'constants/notifierConstants';
import queryString from 'query-string';
import { constructArrayObjectAsString, throttle } from 'utils/commonUtils';
import { ErrorResponse } from 'containers/app/app.model';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsInvoices } from '../events';
import {
  getIsSignatureEnabledState,
  getLineItemsArrayForEvent,
  getServiceChargeEventPayload,
  prepareDiscountRelatedPayload,
  prepareLineItemList,
  prepareServiceChargeListRequestPayload,
  prepareSummatedUniqueTaxList
} from '../utils';
import { InvoiceSourceEnum, InvoiceVersion } from '../constants';
import { DispatchDestination, invoiceMinimumAmount } from 'constants/common';
import { useGetItemSuggestionsAndGlobalTaxesAndFeesQuery } from 'containers/product-catalog/api';
import SignatureOptionsSection from './components/SignatureOptionsSection';
import { useGetMerchantCustomizationsQuery } from 'containers/discount-tips-fees/api';
import { FeatureToggleState } from 'containers/discount-tips-fees/constants';
import { updateCustomizations } from 'containers/discount-tips-fees/discountTipsSlice';

interface ICreateInvoiceContext {
  selectedInvoiceTab: InvoiceTabEnum;
  setSelectedInvoiceTab: React.Dispatch<React.SetStateAction<InvoiceTabEnum>>;
}

interface CreateInvoiceProps {
  onPreviewClick: () => void;
  taxesAndFeesData: TaxesAndFeesResponse;
  isLoadingTaxesAndFees: boolean;
}

export const CreateInvoiceContext = createContext<ICreateInvoiceContext>({
  selectedInvoiceTab: InvoiceTabEnum.BILL_TO,
  setSelectedInvoiceTab: null
});

const CreateInvoice: FC<CreateInvoiceProps> = ({ onPreviewClick, taxesAndFeesData, isLoadingTaxesAndFees }) => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const selectedInvoice = useAppSelector(selectInvoice);
  const selectedIsSignatureEnabled = useAppSelector(selectIsSignatureEnabled);
  const dispatch = useAppDispatch();

  useGetItemSuggestionsAndGlobalTaxesAndFeesQuery({
    teamId: currentTeam.id,
    profession: currentTeam.profession,
    dispatchDestination: DispatchDestination.INVOICE_SLICE
  });

  const { data: merchantCustomisations, isSuccess: merchantCustomisationsSuccess } = useGetMerchantCustomizationsQuery({
    teamId: currentTeam.id.toString()
  });

  useEffect(() => {
    if (merchantCustomisationsSuccess) {
      dispatch(updateIsSignatureEnabled(getIsSignatureEnabledState(merchantCustomisations.customerSignatureConfig)));
      dispatch(updateCustomizations(merchantCustomisations));
    }
  }, [merchantCustomisationsSuccess]);

  const [
    createInvoiceMutation,
    {
      isSuccess: isCreateInvoiceSuccess,
      data: invoiceCreatedResponse,
      isError: isCreateInvoiceError,
      error: createInvoiceError
    }
  ] = useCreateInvoiceMutation();
  const navigate = useNavigate();
  const [selectedInvoiceTab, setSelectedInvoiceTab] = useState(InvoiceTabEnum.BILL_TO);

  const urlQuery = queryString.parse(location.search);

  const [getCustomerByIdQuery, getCustomerByIdState] = useLazyGetCustomerByIdQuery();
  const { data: customerData, isSuccess: customerDataSuccess, isFetching: fetchingCustomerData } = getCustomerByIdState;

  useEffect(() => {
    if (urlQuery.customerId) {
      const { customerId } = urlQuery;
      getCustomerByIdQuery({ teamId: currentTeam.id, customerId: customerId as string });
    }
  }, []);

  useEffect(() => {
    if (customerDataSuccess) {
      dispatch(updateInvoiceCustomer({ ...customerData.customer, id: null }));
    }
  }, [customerDataSuccess]);

  useEffect(() => {
    if (isCreateInvoiceSuccess) {
      dispatch(resetInvoiceState());
      dispatch(updateInvoiceCreatedResponse(invoiceCreatedResponse));
      const invoiceRouteState: InvoicesRouteState = {
        showShareModal: true,
        isEditInvoiceShare: false,
        showSidePanel: false
      };
      navigate(routesPath.INVOICES, { replace: true, state: invoiceRouteState });
    }
  }, [isCreateInvoiceSuccess]);

  useEffect(() => {
    if (isCreateInvoiceError) {
      const error = createInvoiceError as ErrorResponse;
      if (error?.data?.details) {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: error.data.details,
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      } else {
        dispatch(
          showNotifier({
            message: {
              primaryMessage: 'Something went wrong',
              secondaryMessage: '',
              isMessageHtml: true
            },
            type: NotifierTypes.ERROR,
            showClose: false,
            fontStyle: 'text-primary font-normal'
          })
        );
      }
    }
  }, [isCreateInvoiceError]);

  useEffect(() => {
    const tabElem = document.getElementById(selectedInvoiceTab);
    if (tabElem) {
      tabElem.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' });
    }
  }, [selectedInvoiceTab]);

  const onPreviewButtonClick = () => {
    let isPageIncomplete = false;
    if (selectedInvoice.invoiceLineItems.length === 0) {
      isPageIncomplete = true;
      dispatch(addInvoiceValidationError(minimumLineItemsError));
    }
    if (selectedInvoice.invoiceCustomer === null) {
      isPageIncomplete = true;
      dispatch(enableCustomerNotSelectedError());
    }

    if (isPageIncomplete) {
      return;
    }

    onPreviewClick();
  };

  const getAttachmentArray = () => {
    const attachmentArray: IInvoiceAttachmentRequest[] = selectedInvoice.selectedInvoiceAttachments.map(attachment => {
      return {
        title: attachment.title,
        url: attachment.url,
        description: attachment.description
      };
    });
    return attachmentArray;
  };

  const onCreateAndShare = () => {
    let isPageIncomplete = false;
    if (selectedInvoice.invoiceLineItems.length === 0) {
      isPageIncomplete = true;
      dispatch(addInvoiceValidationError(minimumLineItemsError));
    }
    if (selectedInvoice.invoiceCustomer === null) {
      isPageIncomplete = true;
      dispatch(enableCustomerNotSelectedError());
    }
    if (selectedInvoice.invoiceSubTotal < +currentTeam?.minimumAmount ?? invoiceMinimumAmount) {
      isPageIncomplete = true;
      dispatch(
        showNotifier({
          message: {
            primaryMessage: `Total amount must be at least $${currentTeam?.minimumAmount ?? invoiceMinimumAmount}`
          },
          type: NotifierTypes.ERROR,
          showClose: true
        })
      );
    }

    if (isPageIncomplete) {
      return;
    }
    const serviceChargeListPayload = prepareServiceChargeListRequestPayload(selectedInvoice.selectedServiceCharge);

    const invoicePayload: InvoicePayload = {
      version: InvoiceVersion.THREE_POINT_ZERO,
      teamId: currentTeam.id.toString(),
      storeName: currentTeam.storeName,
      // store all level amounts in redux slice eg: subtotal, finalamount etc
      amount: selectedInvoice.invoiceSubTotal.toString(),
      comment: selectedInvoice.comment,
      customerId: selectedInvoice?.invoiceCustomer?.ID,
      phoneNumber: selectedInvoice?.invoiceCustomer?.phoneNumber,
      email: selectedInvoice?.invoiceCustomer?.email,
      customerName: selectedInvoice?.invoiceCustomer?.name,
      billingAddress: selectedInvoice?.invoiceCustomer?.billingAddress,
      lineItems: prepareLineItemList(selectedInvoice.invoiceLineItems),
      ...prepareDiscountRelatedPayload(selectedInvoice.discountFormData),
      surchargePercentage: selectedInvoice.isServiceFeeEnabled
        ? selectedInvoice.currentSurcharge.percentageWithBps ?? '0'
        : '0',
      isServiceFeeEnabled: selectedInvoice.isServiceFeeEnabled,
      attachments: getAttachmentArray(),
      tipEnabled: selectedInvoice.tipEnabled,
      tncId: taxesAndFeesData.tnc.enabled ? taxesAndFeesData.tnc.tncId : 0,
      ...prepareSummatedUniqueTaxList({
        appliedLineItemLevelTaxes: selectedInvoice.appliedLineItemLevelTaxes,
        invoiceLineItems: selectedInvoice.invoiceLineItems,
        selectedServiceCharge: selectedInvoice.selectedServiceCharge
      }),
      serviceCharges: serviceChargeListPayload,
      serviceChargeAmount: serviceChargeListPayload?.length > 0 ? serviceChargeListPayload[0].totalAmount : '0',
      source: InvoiceSourceEnum.WEB,
      isSignatureEnabled: selectedIsSignatureEnabled
    };
    const serviceChargeEventPayload = getServiceChargeEventPayload(serviceChargeListPayload);
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesCreateInvoiceSave, {
      customerName: selectedInvoice?.invoiceCustomer?.name,
      customerId: selectedInvoice?.invoiceCustomer?.ID,
      teamId: currentTeam.id.toString(),
      storeName: currentTeam.storeName,
      amount: selectedInvoice.invoiceSubTotal.toString(),
      'Line Items': constructArrayObjectAsString(
        getLineItemsArrayForEvent(selectedInvoice.invoiceLineItems),
        'Line item'
      ),
      ...prepareDiscountRelatedPayload(selectedInvoice.discountFormData),
      surchargePercentage: selectedInvoice.currentSurcharge.enabled
        ? selectedInvoice.currentSurcharge.percentageWithBps ?? '0'
        : '0',
      ...(serviceChargeEventPayload?.length > 0 ? { serviceCharges: serviceChargeEventPayload } : {})
    });
    createInvoiceMutation(invoicePayload);
  };

  const createAndShareHandler = useCallback(throttle(onCreateAndShare, 800), [selectedInvoice]);

  const onBackIconClick = () => {
    navigate(-1);
    dispatch(resetInvoiceState());
  };

  const handleSignatureStateChange = (value: boolean) => {
    dispatch(updateIsSignatureEnabled(value));
  };

  const checkIsSignatureDisabled = () => {
    if (merchantCustomisationsSuccess) {
      return merchantCustomisations.customerSignatureConfig === FeatureToggleState.ALWAYS_ON;
    }
    return false;
  };

  return (
    <CreateInvoiceContext.Provider value={{ selectedInvoiceTab, setSelectedInvoiceTab }}>
      <div className="flex h-full w-full flex-col border">
        <PreviewBar
          className="border border-borderGray"
          title="New Invoice"
          onPrimaryClick={createAndShareHandler}
          onReturn={onBackIconClick}
          primaryBtnTitle="Create & Share"
          disablePrimaryBtn={false}
          secondaryBtnTitle="Preview"
          onSecondaryClick={onPreviewButtonClick}
          primaryBtnId="create-and-share-invoice-button"
          secondaryBtnId="create-invoice-preview-button"
        />
        <div className="flex h-full w-full flex-row overflow-hidden bg-primary">
          <div
            className="hidden w-1/3 max-w-[31.25rem] flex-col items-center p-12
          lg:flex lg:w-[24rem] 1439px:w-[31.25rem]">
            <InvoicesTabSideNav context={CreateInvoiceContext} />
          </div>

          <div
            id="scrollable div"
            className="customNormalScroll flex grow flex-col space-y-[30px] overflow-y-auto p-12 1439px:space-y-11">
            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.BILL_TO}>
              <BillToSection context={CreateInvoiceContext} />
            </div>

            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.INVOICE_OPTIONS}>
              <InvoiceOptionsSection context={CreateInvoiceContext} />
            </div>

            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.LINE_ITEMS}>
              <LineItemsSection isLoadingTaxesAndFees={isLoadingTaxesAndFees} context={CreateInvoiceContext} />
            </div>

            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.NOTES_ATTACHMENTS}>
              <NotesAttachmentsSection
                context={CreateInvoiceContext}
                isLoadingTaxesAndFees={isLoadingTaxesAndFees}
                taxesAndFees={taxesAndFeesData}
              />
            </div>

            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.PAYMENT_OPTIONS}>
              <PaymentOptionsSection taxesAndFees={taxesAndFeesData} context={CreateInvoiceContext} />
            </div>
            <div className="lg:w-4xl flex max-w-4xl flex-col" id={InvoiceTabEnum.SIGNATURES}>
              <SignatureOptionsSection
                updateSignatureState={handleSignatureStateChange}
                signatureState={selectedIsSignatureEnabled}
                context={CreateInvoiceContext}
                isDisabled={checkIsSignatureDisabled()}
                id="create-invoice-allow-sign-toggle-switch"
              />
            </div>
          </div>
        </div>
      </div>
    </CreateInvoiceContext.Provider>
  );
};

export default CreateInvoice;
