import React from 'react';
import { VoidFn } from 'types/baseTypes';
import { DeleteIcon, EditIconBlue, PlusIconBlue } from 'assets/icons';

interface ReviewPageSectionProps {
  currentReviewPageLink: string;
  handleDeleteReviewPageClick: VoidFn;
  handleReviewPagePopupOpen: VoidFn;
}

export const ReviewPageSection: React.FC<ReviewPageSectionProps> = ({
  currentReviewPageLink,
  handleDeleteReviewPageClick,
  handleReviewPagePopupOpen
}) => {
  return (
    <>
      <div className="text-xl font-bold leading-6 text-headingGray">Request a review</div>
      <div className="text-17px text-primaryText">
        Show a link to your review page to customers when they complete a payment.
      </div>
      <div className="flex items-center justify-between rounded-[4px] border border-borderGray p-5">
        {currentReviewPageLink && (
          <>
            <div className="text-17px font-semibold text-accentText">{currentReviewPageLink}</div>
            <div className="flex gap-[30px] text-17px font-semibold">
              <div
                className="flex cursor-pointer items-center gap-2 text-secondary"
                onClick={handleReviewPagePopupOpen}>
                <EditIconBlue /> Edit
              </div>
              <div
                className="flex cursor-pointer items-center gap-2 text-primaryText"
                onClick={handleDeleteReviewPageClick}>
                <DeleteIcon />
                Delete
              </div>
            </div>
          </>
        )}
        {!currentReviewPageLink && (
          <div
            className="flex cursor-pointer items-center gap-2 text-17px font-semibold text-secondary"
            onClick={handleReviewPagePopupOpen}>
            <PlusIconBlue />
            Add review page link
          </div>
        )}
      </div>
    </>
  );
};
