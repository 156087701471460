import { createSlice } from '@reduxjs/toolkit';
import { TeamRole } from 'containers/app/app.model';
import { RootState } from 'store/reducers';

export interface CreateInviteState {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  role: TeamRole;
}

const initialState: CreateInviteState = {
  firstName: '',
  lastName: '',
  phone: '',
  email: '',
  role: TeamRole.MEMBER
};

export const createInviteSlice = createSlice({
  name: 'createInvite',
  initialState,
  reducers: {
    resetInviteDetails: () => initialState,
    updateBasicDetails: (state, { payload }) => {
      const { value, field } = payload;
      state[field] = value;
    },
    updateMemberRole: (state, { payload }) => {
      state.role = payload;
    }
  }
});

export const { updateBasicDetails, updateMemberRole, resetInviteDetails } = createInviteSlice.actions;

export const selectInviteMemberRole = (state: RootState) => state.rootReducer.createInvite.role;
export const selectInviteMemberFirstName = (state: RootState) => state.rootReducer.createInvite.firstName;
export const selectInviteMemberLastName = (state: RootState) => state.rootReducer.createInvite.lastName;
export const selectInviteMemberPhone = (state: RootState) => state.rootReducer.createInvite.phone;
export const selectInviteMemberEmail = (state: RootState) => state.rootReducer.createInvite.email;

export default createInviteSlice.reducer;
