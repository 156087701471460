import { CaretRightIcon, CategoriesTabIcon, CircleCloseIcon, PlusIconBlue } from 'assets/icons';
import { CustomModal, ErrorScreenWithRetry, If, SearchBar } from 'components';
import InfinteLoadingSpinner from 'components/infinite-loading-spinner/InfiniteLoadingSpinner';
import { ICategoryItemListData } from 'containers/invoices/hooks/usePaginatedCategoryItemListQuery';
import { IInvoiceListingCategory, IInvoiceListingItem } from 'containers/invoices/invoices.model';
import { FC, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import AddItemFromCategoryBody from './AddItemFromCategoryBody';
import { IPaginatedItem } from 'containers/product-catalog/items/items.model';
import { checkIsAccessTokenExpiry } from 'utils/apiUtils';
import EmptySearchResultState from 'containers/product-catalog/components/EmptySearchResultState';
import ItemsTabReactIcon from 'assets/react-icons/ItemsTabReactIcon';

interface AddFromCatalogModalProps {
  className?: string;
  onClose: VoidFn;
  title: string;
  open: boolean;
  handleSearch: (string) => void;
  listData: ICategoryItemListData;
  isLoadingList: boolean;
  isLoadingListSuccess: boolean;
  setIntersectionElement: React.Dispatch<React.SetStateAction<HTMLElement>>;
  hasMoreListItems: boolean;
  totalCategoryListCount: number;
  totalLineItemListCount: number;
  onCreateOneTimeItemFromCatalogPicker: (itemName: string) => void;
  onCatalogItemClick: (catalogItem: IInvoiceListingItem) => void;
  handleReload: VoidFn;
  apiErrorCode: string;
}

const addItemFromCategoryConfigInit = { open: false, data: { id: null, lineItemsCount: 0, name: '' } };

const AddFromCatalogModal: FC<AddFromCatalogModalProps> = ({
  handleSearch,
  onClose,
  open,
  listData,
  isLoadingList,
  isLoadingListSuccess,
  hasMoreListItems,
  setIntersectionElement,
  totalCategoryListCount,
  totalLineItemListCount,
  onCreateOneTimeItemFromCatalogPicker,
  onCatalogItemClick,
  handleReload,
  apiErrorCode
}) => {
  const [searchText, setSearchText] = useState<string>('');

  const [addItemFromCategoryConfig, setAddItemFromCategoryConfig] = useState<{
    open: boolean;
    data: IInvoiceListingCategory;
  }>(addItemFromCategoryConfigInit);

  const showAPIErrorWithRetryScreen =
    !isLoadingList && !isLoadingListSuccess && !checkIsAccessTokenExpiry(+apiErrorCode);

  const onClearSearch = () => {
    setSearchText('');
    handleSearch(null);
  };

  const onSearchTermChange = (searchTerm: string) => {
    setSearchText(searchTerm);
    handleSearch(searchTerm);
  };

  const getItemsTitle = (count: number): string => {
    return `All items (${count})`;
  };

  const getCategoriesTitle = (count: number): string => {
    return `Categories (${count})`;
  };

  const getItemText = (count: number): string => {
    if (count === 1) {
      return '1 item';
    } else if (count > 1) {
      return `${count} items`;
    }
    return '0 items';
  };

  const onItemClick = (catalogItem: IInvoiceListingItem): void => {
    onCatalogItemClick(catalogItem);
  };

  const onCategoryClick = (catalogCategory: IInvoiceListingCategory): void => {
    setAddItemFromCategoryConfig({ open: true, data: catalogCategory });
  };

  const onAddItemFromCategoryBackClick = (): void => {
    setAddItemFromCategoryConfig(addItemFromCategoryConfigInit);
  };

  const onCreateItem = (): void => {
    onCreateOneTimeItemFromCatalogPicker('');
  };

  const onCreateItemFromCategoryBody = (): void => {
    onCreateOneTimeItemFromCatalogPicker('');
  };

  const onItemInCategoryClick = (catalogItem: IPaginatedItem): void => {
    const item: IInvoiceListingItem = {
      id: catalogItem.id,
      name: catalogItem.name,
      category: catalogItem.category ?? '',
      unitPrice: catalogItem.unitPrice,
      sku: catalogItem.sku ?? '',
      description: catalogItem.description ?? '',
      taxes: catalogItem?.taxes ?? []
    };
    onCatalogItemClick(item);
  };

  return (
    <CustomModal
      closeModal={onClose}
      open={open}
      backgroundColor="bg-primary"
      width="md:w-[611px]"
      height="max-h-[800px] h-[85%]">
      {!addItemFromCategoryConfig.open ? (
        <div className="absolute flex h-full w-full grow flex-col py-10">
          <CircleCloseIcon className="absolute top-2 right-2 shrink-0 cursor-pointer" onClick={onClose} />
          <div className="flex flex-row items-center gap-2">
            <div className="mr-[22px] pl-10 text-xl font-semibold text-heading">Items</div>
            <div onClick={onCreateItem} className="flex cursor-pointer items-center">
              <div className="mr-2 h-6 w-6 shrink-0">
                <PlusIconBlue />
              </div>
              <div className="text-17px font-semibold text-secondary">Create item</div>
            </div>
          </div>
          <If condition={!showAPIErrorWithRetryScreen}>
            <div className="mt-5 mb-[10px] px-10">
              <SearchBar
                id="search-bar"
                handleClear={onClearSearch}
                handleChange={onSearchTermChange}
                value={searchText}
                placeholder="Search"
                wrapperStyle="w-full h-10 border border-borderGray rounded"
                showSearchIcon={true}
                autoFocus={true}
                showCrossIcon={true}
              />
            </div>
          </If>

          <If condition={showAPIErrorWithRetryScreen}>
            <ErrorScreenWithRetry handleReload={handleReload} />
          </If>

          <If condition={!showAPIErrorWithRetryScreen}>
            {searchText.length >= 2 && (
              <div className="mt-2 mb-4 flex items-center px-10">
                <div className="mr-2 shrink-0">
                  <PlusIconBlue className="h-[18px] w-[18px]" />
                </div>
                <div
                  onClick={() => onCreateOneTimeItemFromCatalogPicker(searchText)}
                  className="cursor-pointer truncate text-sbase
                font-semibold text-secondary">{`Create one-time item -  “${searchText}”`}</div>
              </div>
            )}

            <div className="customNormalScroll mb-5 h-full max-h-[800px] overflow-y-auto  px-10">
              {totalCategoryListCount > 0 && (
                <>
                  <div className="flex h-[50px] flex-row items-center bg-secondaryBtn py-4 px-7">
                    <CategoriesTabIcon className="mr-2 shrink-0" />
                    <div className="text-sbase font-semibold text-heading">
                      {getCategoriesTitle(totalCategoryListCount)}
                    </div>
                  </div>
                  {listData.categories.map(category => (
                    <div
                      id={`category-${category.id}`}
                      onClick={() => onCategoryClick(category)}
                      className={`flex min-h-[77px] cursor-pointer 
                  flex-row  items-center
                  border-b border-secondaryBtn px-7 py-5 hover:bg-highlightGray`}
                      key={category.id}>
                      <div className="flex max-w-[75%] basis-3/4 flex-col justify-center">
                        <div className="break-words text-sbase font-semibold text-primaryText">{category.name}</div>
                        <div className="text-px13 font-semibold  text-primaryText">
                          {getItemText(category.lineItemsCount)}
                        </div>
                      </div>
                      <div className="basis-1/4">
                        <CaretRightIcon className="ml-auto shrink-0" />
                      </div>
                    </div>
                  ))}
                </>
              )}

              <div className="my-[10px] flex h-[50px] flex-row items-center bg-secondaryBtn py-4 px-7">
                <ItemsTabReactIcon id="catalog-modal" className="mr-2 shrink-0" />
                <div className="text-sbase font-semibold text-heading">{getItemsTitle(totalLineItemListCount)}</div>
              </div>

              {listData.lineItems.map(item => (
                <div
                  onClick={() => onItemClick(item)}
                  className="flex min-h-[77px] cursor-pointer flex-row  items-center
               border-b border-secondaryBtn py-5 pl-7 pr-6 hover:bg-secondaryBtn"
                  key={item.id}>
                  <div className="max-w-[50%] basis-1/2 break-words text-sbase font-semibold text-primaryText">
                    {item.name}
                  </div>
                  <div className="ml-auto basis-1/2 text-right text-sbase font-semibold text-primaryText">
                    {getAmountWithCurrency(item.unitPrice)}
                  </div>
                </div>
              ))}
              <If condition={listData.categories.length === 0 && listData.lineItems.length === 0 && !isLoadingList}>
                <div className="mt-16">
                  <EmptySearchResultState keyword="item" showCreateButton={false} />
                </div>
              </If>
              <If condition={!isLoadingList && hasMoreListItems}>
                <div className="h-2" ref={setIntersectionElement}></div>
              </If>
              <If condition={isLoadingList}>
                <div className={`${listData.categories.length + listData.lineItems.length === 0 ? 'pt-10' : 'pt-0'}`}>
                  <InfinteLoadingSpinner />
                </div>
              </If>
            </div>
          </If>
        </div>
      ) : (
        <AddItemFromCategoryBody
          onClose={onClose}
          category={addItemFromCategoryConfig.data}
          onGoBack={onAddItemFromCategoryBackClick}
          onItemClick={onItemInCategoryClick}
          onCreateItem={onCreateItemFromCategoryBody}
        />
      )}
    </CustomModal>
  );
};

export default AddFromCatalogModal;
