import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { IServiceCharge } from '../service-charges/serviceCharge.model';
import { ITaxBasic } from '../taxes/taxes.model';
import { ServiceChargeEnum } from './types';

export interface ServiceChargeSliceState {
  isEditServiceChargeFlow: boolean;
  serviceChargeId: number;
  serviceChargeName: string;
  serviceChargePercentage: string;
  serviceChargeAmount: string;
  taxes: ITaxBasic[];
  initializingServiceChargeSliceInProgress: boolean;
  serviceChargeType: ServiceChargeEnum;
  deselectedTaxList: number[]; // this includes all the unselected taxes from the whole taxList
}

const initialState: ServiceChargeSliceState = {
  isEditServiceChargeFlow: false,
  serviceChargeId: null,
  serviceChargeName: '',
  serviceChargeAmount: '',
  serviceChargePercentage: '',
  taxes: [],
  initializingServiceChargeSliceInProgress: false,
  serviceChargeType: ServiceChargeEnum.CURRENCY,
  deselectedTaxList: []
};

const isDeselectedTax = ({
  initialTax,
  deselectedTaxList = []
}: {
  initialTax: ITaxBasic;
  deselectedTaxList: number[];
}) => {
  return deselectedTaxList.includes(initialTax.id);
};

const isAlreadyInSliceTaxList = ({
  initialTax,
  currentSliceTaxList = []
}: {
  initialTax: ITaxBasic;
  currentSliceTaxList: ITaxBasic[];
}) => {
  return currentSliceTaxList.some(currentTax => currentTax.id === initialTax.id);
};

export const serviceChargeSlice = createSlice({
  name: 'serviceCharge',
  initialState,
  reducers: {
    resetServiceChargeSlice: () => initialState,

    initializeServiceChargeSliceFromAPI: (state, { payload }: PayloadAction<IServiceCharge>) => {
      state.isEditServiceChargeFlow = true;
      state.serviceChargeId = payload.id;
      state.serviceChargeName = payload.name;
      if (+payload.amount > 0) {
        state.serviceChargeType = ServiceChargeEnum.CURRENCY;
      } else {
        state.serviceChargeType = ServiceChargeEnum.PERCENTAGE;
      }
      state.serviceChargeAmount = +payload.amount > 0 ? payload.amount : '';
      state.serviceChargePercentage = +payload.percentage > 0 ? payload.percentage : '';
      if (payload?.taxes) {
        const filteredTaxes = payload.taxes.filter(
          initialTax =>
            !isDeselectedTax({ initialTax, deselectedTaxList: state.deselectedTaxList }) &&
            !isAlreadyInSliceTaxList({ initialTax, currentSliceTaxList: state.taxes })
        );
        state.taxes = [...state.taxes, ...filteredTaxes];
      }
      state.initializingServiceChargeSliceInProgress = false;
    },

    updateServiceChargeName: (state, { payload }: PayloadAction<{ name: string }>) => {
      state.serviceChargeName = payload.name;
    },

    updateServiceChargeEntity: (state, { payload }: PayloadAction<{ type: ServiceChargeEnum; value: string }>) => {
      state.serviceChargeType = payload.type;
      if (payload.type === ServiceChargeEnum.CURRENCY) {
        state.serviceChargeAmount = payload.value;
      }

      if (payload.type === ServiceChargeEnum.PERCENTAGE) {
        state.serviceChargePercentage = payload.value;
      }
    },

    updateInitalizingServiceChargeSliceInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.initializingServiceChargeSliceInProgress = payload;
    },

    updateSelectedTaxes: (state, { payload }: PayloadAction<ITaxBasic[]>) => {
      state.taxes = payload;
    },

    addGlobalServiceChargeTaxes: (state, { payload }: PayloadAction<ITaxBasic[]>) => {
      if (payload) {
        const filteredTaxes = payload.filter(
          globalTax =>
            !state.taxes.some(serviceChargeTax => serviceChargeTax.id === globalTax.id) &&
            !state.deselectedTaxList.some(deselectedTax => deselectedTax === globalTax.id)
        );
        state.taxes = [...state.taxes, ...filteredTaxes];
      }
      state.initializingServiceChargeSliceInProgress = false;
    },

    updateDeselectedTaxes: (state, { payload }: PayloadAction<number[]>) => {
      state.deselectedTaxList = payload;
    },

    addNewlyCreatedTaxInServiceChargeSlice: (state, { payload }: PayloadAction<ITaxBasic>) => {
      state.taxes = [...state.taxes, payload];
    }
  }
});

export const {
  updateInitalizingServiceChargeSliceInProgress,
  resetServiceChargeSlice,
  initializeServiceChargeSliceFromAPI,
  updateServiceChargeName,
  updateServiceChargeEntity,
  updateSelectedTaxes,
  addGlobalServiceChargeTaxes,
  updateDeselectedTaxes,
  addNewlyCreatedTaxInServiceChargeSlice
} = serviceChargeSlice.actions;

export const selectServiceChargeState = (state: RootState) => state.rootReducer.serviceCharge;
export const selectInitializingServiceChargeSliceInProgress = (state: RootState) =>
  state.rootReducer.serviceCharge.initializingServiceChargeSliceInProgress;

export default serviceChargeSlice.reducer;
