import { AddImageIcon } from 'assets/icons';
import { CustomButton, CustomModal, FileUpload } from 'components';
import { ButtonType, FormButtonType } from 'components/custom-button/CustomButton';
import { FC, useEffect, useRef, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { useUploadProfileImageMutation } from '../api';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface AddProfileImageModalProps {
  className?: string;
  open: boolean;
  toggleOpen: VoidFn;
  onSaveImageClick: (arg) => void;
  onModifyImageClick?: (arg) => void;
  imageData: any;
  isEdit: boolean;
}

const AddProfileImageModal: FC<AddProfileImageModalProps> = ({
  open,
  toggleOpen,
  onSaveImageClick,
  onModifyImageClick,
  isEdit,
  imageData
}) => {
  const [imagePreview, setImagePreview] = useState<string>();
  const [uploadProfileImageMutation, { isSuccess: isUploadSuccess, isLoading: isUploadingImage }] =
    useUploadProfileImageMutation();
  const [imageFile, setImageFile] = useState();
  const [hasClosedImagePreview, setHasClosedImagePreview] = useState<boolean>(false);
  const currentImageUploadCall = useRef(null);

  const [isUploadError, setIsUploadError] = useState<boolean>(false);

  useEffect(() => {
    if (isEdit) {
      setImagePreview(imageData?.imagePreview);
    }
  }, [imageData]);

  const removeImagePreview = () => {
    setHasClosedImagePreview(true);
    setImagePreview(null);
  };

  const onResetState = () => {
    if (isUploadingImage) {
      currentImageUploadCall.current.abort();
    }
    setImagePreview(null);
    setImageFile(null);
    setHasClosedImagePreview(false);
    toggleOpen();
  };

  const isSaveButtonDisabled = () => {
    if (hasClosedImagePreview && imagePreview !== null) {
      return false;
    }
    if (!imagePreview || isUploadingImage) {
      return true;
    }
    return false;
  };

  const getImageURL = async () => {
    try {
      const imageUrlFromS3Call = uploadProfileImageMutation({
        file: imageFile
      });
      currentImageUploadCall.current = imageUrlFromS3Call;
      const imageUrlFromS3 = imageUrlFromS3Call.unwrap();
      setIsUploadError(false);
      return imageUrlFromS3;
    } catch (err) {
      setIsUploadError(true);
    }
  };

  const handleSave = async () => {
    if (!imagePreview) {
      return;
    }
    // Get new image url only if the user doesn't change the image he is editing
    if (isEdit) {
      const imageUrlResponse = hasClosedImagePreview ? await getImageURL() : { imageUrl: null };
      onModifyImageClick({
        imagePreview: imagePreview,
        url: imageUrlResponse.imageUrl ?? imageData.url
      });

      onResetState();
      return;
    }

    const imageUrlResponse = await getImageURL();

    onSaveImageClick({
      imagePreview: imagePreview,
      url: imageUrlResponse.imageUrl
    });

    onResetState();
  };

  return (
    <div>
      <CustomModal width="md:w-[610px]" open={open} closeModal={onResetState}>
        <div className="relative flex w-full flex-col py-8 px-10">
          <FormCloseReactIcon
            id="my-accouunt-add-photo-close-icon"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onResetState}
          />
          <div className="mb-4 text-xl font-bold text-heading">{isEdit ? 'Edit Photo' : 'Add Photo'}</div>

          <FileUpload
            id="create-invoice-add-attachment-card"
            isUploadInProgress={isUploadingImage}
            prefill={isEdit}
            setFile={setImageFile}
            removePreview={removeImagePreview}
            filePreview={imagePreview}
            setPreview={setImagePreview}
            className="mb-5"
            allowMultiple={false}
            FileUploadIcon={AddImageIcon}
            supportedFileTypes={{
              'image/jpeg': [],
              'image/png': [],
              'image/webp': []
            }}
          />

          <div className="flex flex-row justify-end gap-5">
            <CustomButton
              id="create-invoice-add-attachment-cancel"
              className="w-24"
              type={ButtonType.SECONDARY}
              onClick={onResetState}>
              Cancel
            </CustomButton>
            <CustomButton
              id="create-invoice-add-attachment-save"
              onClick={handleSave}
              formType={FormButtonType.submit}
              className="w-24"
              disabled={isSaveButtonDisabled()}>
              Save
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default AddProfileImageModal;
