import { CityObject, Professions, State } from 'containers/team-profile/teamProfile.model';

export const getProfessionsList = (list: Professions[]) => {
  const professionsList = list?.map(item => {
    return {
      name: item.profession,
      id: item.ID
    };
  });
  return professionsList;
};

export const getStatesList = (list: State[]) => {
  const statesList = list?.map(state => {
    return {
      name: state.name,
      id: state.state_code
    };
  });
  return statesList;
};

export const getCitiesList = (list: CityObject[]) => {
  const citiesList = list?.map(city => {
    return {
      name: city.city,
      id: city.city.substring(0, 3) + Math.floor(Math.random() * 1000)
    };
  });
  return citiesList;
};
