import { CircleCloseIcon, EmailErrorIcon } from 'assets/icons';
import { CustomButton, CustomModal } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { VoidFn } from 'types/baseTypes';

interface DuplicatEmailModalProps {
  handleClose: VoidFn;
}

const DuplicatEmailModal: React.FC<DuplicatEmailModalProps> = ({ handleClose }) => {
  const navigate = useNavigate();

  return (
    <>
      <CustomModal open width="md:min-w-[503px]" onOutsideClick={handleClose} className="relative">
        <CircleCloseIcon className="absolute right-2 top-2 cursor-pointer" onClick={handleClose} />
        <div className="flex flex-col items-center p-8">
          <EmailErrorIcon />
          <div className="my-3 text-xl font-bold leading-6 text-primaryText">Email address is already in use</div>
          <div className="flex flex-col gap-2 text-center text-sbase tracking-[-0.3px] text-primaryText">
            <div>The Email address is already in use for a ScanPay account.</div>
            <div>Please login with you existing account or make sure the information you entered is correct.</div>
            <div className="leading-[18px]">
              In case of any issues, reach out to us at{' '}
              <a className="text-secondary" href="mailto:support@scanpay.tech">
                support@scanpay.tech
              </a>
            </div>
          </div>
          <div className="mt-4 flex flex-col items-center gap-5">
            <CustomButton
              id="login-to-existing-account"
              className="h-10 px-20"
              childrenContainerStyles="text-17px tracking-[-0.3px]"
              onClick={() => {
                navigate(routesPath.SIGNIN);
              }}>
              Login to existing acount
            </CustomButton>
            <CustomButton
              id="back-button-sign-up"
              className="h-fit border-none shadow-none hover:bg-primary active:shadow-none"
              type={ButtonType.SECONDARY}
              childrenContainerStyles="text-17px tracking-[-0.3px]"
              onClick={handleClose}>
              Back
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </>
  );
};

export default DuplicatEmailModal;
