import { FC, useEffect, useState } from 'react';
import { REGEX, preventDefaultFn } from 'constants/common';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { EyeCloseIcon, EyeIcon, GreenTickIcon, LockIcon, RedCloseIcon } from 'assets/icons';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import { CustomButton, CustomInput, If } from 'components';
import { PasswordRequirements } from '../constants';

interface CreateNewPasswordSectionProps {
  className?: string;
  handleContinue: (value: string) => void;
  oldPasswordMatchError: boolean;
  isCreateAPILoading?: boolean;
  handleBackClick: VoidFn;
  isExpiredPassword: boolean;
  setIsHistoricPassword: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewPasswordSection: FC<CreateNewPasswordSectionProps> = ({
  className = '',
  handleContinue,
  oldPasswordMatchError,
  isCreateAPILoading = false,
  handleBackClick,
  isExpiredPassword,
  setIsHistoricPassword
}) => {
  const [passwordMatch, setPasswordMatch] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordType, setPasswordType] = useState(InputType.PASSWORD);
  const [confirmPasswordType, setConfirmPasswordType] = useState(InputType.PASSWORD);
  const [passwordCheck, setPasswordCheck] = useState({
    length: { show: true, msg: PasswordRequirements.CHARACTER_MSG },
    digit: { show: true, msg: PasswordRequirements.DIGIT_MSG },
    upperCase: { show: true, msg: PasswordRequirements.UPPERCASE_MSG },
    lowerCase: { show: true, msg: PasswordRequirements.LOWERCASE_MSG },
    specialChar: { show: true, msg: PasswordRequirements.SPECIAL_CHAR_MSG },
    validPassword: { show: true, msg: '' }
  });

  const [isContinueClicked, setIsContinueClicked] = useState(false);
  const isContinueDisabled =
    !passwordCheck.validPassword.show || password.length === 0 || confirmPassword.length === 0 || !passwordMatch;

  useEffect(() => {
    return () => {
      setIsHistoricPassword(false);
    };
  }, []);

  const validateError = (value: string) => {
    if (REGEX.containsSpecialChar.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        specialChar: {
          ...prevState.specialChar,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        specialChar: {
          ...prevState.specialChar,
          show: true
        }
      }));
    }
    if (REGEX.containsUpperCase.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        upperCase: {
          ...prevState.upperCase,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        upperCase: {
          ...prevState.upperCase,
          show: true
        }
      }));
    }

    if (REGEX.containsLowerCase.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        lowerCase: {
          ...prevState.lowerCase,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        lowerCase: {
          ...prevState.lowerCase,
          show: true
        }
      }));
    }
    if (REGEX.containsDigit.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        digit: {
          ...prevState.digit,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        digit: {
          ...prevState.digit,
          show: true
        }
      }));
    }
    if (value.length >= 8) {
      setPasswordCheck(prevState => ({
        ...prevState,
        length: {
          ...prevState.length,
          show: false
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        length: {
          ...prevState.length,
          show: true
        }
      }));
    }
    if (REGEX.password.test(value)) {
      setPasswordCheck(prevState => ({
        ...prevState,
        validPassword: {
          ...prevState.validPassword,
          show: true
        }
      }));
    } else {
      setPasswordCheck(prevState => ({
        ...prevState,
        validPassword: {
          ...prevState.validPassword,
          show: false
        }
      }));
    }
  };

  const checkPasswordMatch = (pass: string, confirmPass: string) => {
    if (pass === confirmPass) {
      setPasswordMatch(true);
      return;
    }
    setPasswordMatch(false);
  };

  const handlePasswordChange = (value: string) => {
    setIsHistoricPassword(false);
    setPassword(value);
    validateError(value);
    if (isContinueClicked) {
      checkPasswordMatch(value, confirmPassword);
    }
  };

  const handleResetPasswordChange = (value: string) => {
    setConfirmPassword(value);
    setPasswordMatch(true);
    if (isContinueClicked) {
      checkPasswordMatch(password, value);
    }
  };

  const handleContinueClick = () => {
    setIsContinueClicked(true);
    checkPasswordMatch(password, confirmPassword);
    if (password === confirmPassword) {
      handleContinue(password);
    }
  };

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleContinueClick();
    }
  };

  return (
    <div className={`my-auto flex max-w-[375px] flex-col self-center ${className}`}>
      <div className={`mb-4 mt-12 text-2xl font-bold text-heading lg:text-4xl ${!isExpiredPassword ? 'mb-8' : ''}`}>
        Create a new password
      </div>
      {isExpiredPassword && (
        <div className="mb-8 text-base font-normal text-primaryText">
          Your password has expired. Let's create a new one to make sure your access is not interrupted.
        </div>
      )}
      <div>
        <CustomInput
          id="set-password-input"
          onCopy={preventDefaultFn}
          onCut={preventDefaultFn}
          inputStyle="w-full text-17px"
          type={passwordType}
          prefixWrapperStyle="top-4 left-5"
          prefix={<LockIcon height={20} />}
          hasSuffix={true}
          suffix={passwordType === InputType.PASSWORD ? <EyeIcon /> : <EyeCloseIcon />}
          suffixStyle="top-3 right-4"
          autoComplete="off"
          onChange={handlePasswordChange}
          isPasswordField={true}
          setPasswordType={setPasswordType}
          value={password}
          className={`block h-[50px] rounded border px-12
               font-semibold text-primaryText placeholder:text-[17px] 
               placeholder:font-semibold placeholder:text-accentText focus:outline-none
               ${oldPasswordMatchError && 'border-error'}`}
          placeholder="Enter password"
        />
        <If condition={!passwordCheck.validPassword.show}>
          <div className=" mt-5 rounded-md border border-borderGray py-4 px-2 text-sbase text-primaryText">
            <p className={`flex ${passwordCheck.length.show ? 'gap-2' : 'gap-3'}`}>
              {passwordCheck.length.show ? (
                <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
              ) : (
                <GreenTickIcon className="mt-1" />
              )}
              {passwordCheck.length.msg}
            </p>
            <p className={`mt-3 flex ${passwordCheck.upperCase.show ? 'gap-2' : 'gap-3'}`}>
              {passwordCheck.upperCase.show ? (
                <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
              ) : (
                <GreenTickIcon className="mt-1" />
              )}
              {passwordCheck.upperCase.msg}
            </p>
            <p className={`mt-3 flex ${passwordCheck.lowerCase.show ? 'gap-2' : 'gap-3'}`}>
              {passwordCheck.lowerCase.show ? (
                <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
              ) : (
                <GreenTickIcon className="mt-1" />
              )}
              {passwordCheck.lowerCase.msg}
            </p>
            <p className={`mt-3 flex ${passwordCheck.digit.show ? 'gap-2' : 'gap-3'}`}>
              {passwordCheck.digit.show ? (
                <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
              ) : (
                <GreenTickIcon className="mt-1" />
              )}
              {passwordCheck.digit.msg}
            </p>
            <p className={`mt-3 flex ${passwordCheck.specialChar.show ? 'gap-2' : 'gap-3'}`}>
              {passwordCheck.specialChar.show ? (
                <RedCloseIcon className="mt-[-3px] ml-[-4px]" />
              ) : (
                <GreenTickIcon className="mt-1" />
              )}
              {passwordCheck.specialChar.msg}
            </p>
          </div>
        </If>
        <CustomInput
          id="confirm-password-input"
          onCopy={preventDefaultFn}
          onCut={preventDefaultFn}
          inputStyle="w-full text-17px"
          autoComplete="off"
          type={confirmPasswordType}
          prefixWrapperStyle="top-9 left-5"
          prefix={<LockIcon height={20} />}
          hasSuffix={true}
          suffix={confirmPasswordType === InputType.PASSWORD ? <EyeIcon /> : <EyeCloseIcon />}
          suffixStyle="top-8 right-4"
          onChange={handleResetPasswordChange}
          isPasswordField={true}
          value={confirmPassword}
          setPasswordType={setConfirmPasswordType}
          onKeyPress={handleKeyDown}
          className={`mt-5 block h-[50px] rounded border px-12 font-semibold
               text-primaryText placeholder:text-[17px] placeholder:font-semibold 
               placeholder:text-accentText focus:outline-none 
               ${(oldPasswordMatchError || (!passwordMatch && confirmPassword.length !== 0)) && 'border-error'}`}
          placeholder="Re-enter new password"
        />
        {!passwordMatch && confirmPassword.length !== 0 && (
          <div className="mt-1 text-sbase font-semibold text-error">Passwords do not match</div>
        )}
        <If condition={oldPasswordMatchError && passwordMatch}>
          <div className="mt-2 text-sbase font-semibold tracking-[-0.3px] text-error">
            New password cannot match any of your last 3 passwords
          </div>
        </If>
      </div>
      <div>
        <CustomButton
          id="set-passowrd-modal-save-button"
          onClick={handleContinueClick}
          disabled={isContinueDisabled}
          className={`text-semibold mt-10 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}
          isLoading={isCreateAPILoading}>
          Continue
        </CustomButton>
        <CustomButton
          id="update-email-modal-cancel-button"
          className="mt-6 h-[38px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
          type={ButtonType.SECONDARY}
          onClick={handleBackClick}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default CreateNewPasswordSection;
