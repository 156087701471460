import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocalStorage } from 'services/storage';
import { RootState } from 'store/reducers';
import {
  AcceptTeamInviteToken,
  DownloadInviteLinkPayload,
  InviteResponse,
  RelatedAccount,
  Token,
  User
} from './auth.model';
import { redirect } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { LocalStorageKeysEnum } from 'constants/common';
import { SignInMethod } from './types';
import { SignInMethodEnum, signInMethods } from './constants';
import { UserDetails } from 'containers/app/app.model';
import { isObject } from 'lodash';

export interface AuthState {
  logoutActionInProgress?: boolean;
  // TODO Remove user field if unnecessary
  user: User | null;
  accessToken: string | null;
  //Here, idToken plays the role of an accessToken in jwt authentication
  idToken: string | null;
  refreshToken: string | null;
  isAuthenticated: boolean | null;
  hasPendingEmailSteps?: boolean;
  hasInitiatedSignIn?: boolean;
  isPasswordExpired?: boolean;
  downloadLinkToken?: DownloadInviteLinkPayload;
  currentSignInMethod?: SignInMethod;
  isEmailVerified?: boolean;
  isDeviceAuthenticated?: boolean;
  isPasswordCreated?: boolean;
  relatedAccounts?: RelatedAccount[];
  currentSignedInEmail?: string;
  currentSignedInPhone?: string;
  isInitialSignIn?: boolean;
  teamInviteTokens?: AcceptTeamInviteToken;
  inviteResponse?: InviteResponse | string;
  hasInitiatedSignUp?: boolean;
  signUpSearchParams?: { [key: string]: string };
}

const getUserPhoneFromLocalStorage = () => {
  const userDetails = LocalStorage.get(LocalStorageKeysEnum.USER_DETAILS) as UserDetails;
  return userDetails?.phone;
};

const teamInviteIconsInitialState = {
  inviteToken: '',
  referrerId: ''
};

const initialState = {
  //!TODO Store AuthState as an object in local storage?
  //!TODO make local storage key constants
  user: LocalStorage.get('user') ?? null,
  accessToken: LocalStorage.get('accessToken') ?? null,
  idToken: LocalStorage.get('idToken') ?? null,
  refreshToken: LocalStorage.get('refreshToken') ?? null,
  isAuthenticated: LocalStorage.get('isAuthenticated') ?? false,
  hasPendingEmailSteps: false,
  hasInitiatedSignIn: LocalStorage.get('hasInitiatedSignIn') ?? false,
  isPasswordExpired: false,
  downloadLinkToken: null,
  currentSignInMethod: LocalStorage.get(LocalStorageKeysEnum.CURRENT_SIGN_IN_METHOD) ?? {
    id: SignInMethodEnum,
    label: signInMethods.phoneNumber.label,
    value: getUserPhoneFromLocalStorage()
  },
  isEmailVerified: false,
  isDeviceAuthenticated: false,
  isPasswordCreated: false,
  relatedAccounts: [],
  currentSignedInEmail: '',
  currentSignedInPhone: '',
  isInitialSignIn: false,
  logoutActionInProgress: false,
  teamInviteTokens: teamInviteIconsInitialState,
  inviteResponse: {
    profileImageUrl: '',
    teamId: null,
    teamName: ''
  },
  hasInitiatedSignUp: LocalStorage.get('hasInitiatedSignUp') ?? false,
  signUpSearchParams: {}
} as AuthState;

const defaultState = {
  user: null,
  accessToken: null,
  idToken: null,
  refreshToken: null,
  isAuthenticated: false,
  hasPendingEmailSteps: false,
  hasInitiatedSignIn: false,
  isPasswordExpired: false,
  downloadLinkToken: null,
  currentSignInMethod: {
    id: SignInMethodEnum.PHONE_NUMBER,
    label: '',
    value: ''
  },
  isEmailVerified: false,
  isDeviceAuthenticated: false,
  isPasswordCreated: false,
  relatedAccounts: [],
  currentSignedInEmail: '',
  currentSignedInPhone: '',
  isInitialSignIn: false,
  logoutActionInProgress: false,
  teamInviteTokens: teamInviteIconsInitialState,
  inviteResponse: {
    profileImageUrl: '',
    teamId: null,
    teamName: ''
  },
  hasInitiatedSignUp: false,
  signUpSearchParams: {}
} as AuthState;

export const authSlice = createSlice({
  //!TODO Store state names in a constant
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => defaultState,
    setCredentials: (
      state,
      {
        payload: {
          user,
          accessToken,
          idToken,
          refreshToken,
          isAuthenticated,
          currentSignInMethod,
          hasInitiatedSignIn = false,
          isDeviceAuthenticated,
          isEmailVerified,
          isPasswordCreated,
          relatedAccounts
        }
      }: PayloadAction<AuthState>
    ) => {
      state.user = user;
      state.accessToken = accessToken;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = isAuthenticated;
      state.hasInitiatedSignIn = hasInitiatedSignIn;
      state.currentSignInMethod = currentSignInMethod ?? state.currentSignInMethod;
      if (currentSignInMethod) {
        LocalStorage.set(LocalStorageKeysEnum.CURRENT_SIGN_IN_METHOD, currentSignInMethod);
      }
      state.isDeviceAuthenticated = isDeviceAuthenticated;
      state.isEmailVerified = isEmailVerified;
      state.isPasswordCreated = isPasswordCreated;
      state.relatedAccounts = relatedAccounts ?? [];

      LocalStorage.set('accessToken', accessToken);
      LocalStorage.set('idToken', idToken);
      LocalStorage.set('refreshToken', refreshToken);
      LocalStorage.set<boolean>('isAuthenticated', isAuthenticated);
      LocalStorage.set<boolean>('hasInitiatedSignIn', hasInitiatedSignIn);
      LocalStorage.set('user', user);
    },

    onEnterPasswordSignIn: (
      state,
      {
        payload: {
          user,
          accessToken,
          idToken,
          refreshToken,
          isAuthenticated,
          currentSignInMethod,
          hasInitiatedSignIn = false,
          isDeviceAuthenticated,
          isEmailVerified,
          isPasswordCreated,
          relatedAccounts,
          isInitialSignIn
        }
      }: PayloadAction<AuthState>
    ) => {
      state.user = user;
      state.accessToken = accessToken;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = isAuthenticated;
      state.hasInitiatedSignIn = hasInitiatedSignIn;
      state.currentSignInMethod = currentSignInMethod ?? state.currentSignInMethod;
      if (currentSignInMethod) {
        LocalStorage.set(LocalStorageKeysEnum.CURRENT_SIGN_IN_METHOD, currentSignInMethod);
      }
      state.isDeviceAuthenticated = isDeviceAuthenticated;
      state.isEmailVerified = isEmailVerified;
      state.isPasswordCreated = isPasswordCreated;
      state.relatedAccounts = relatedAccounts ?? [];

      LocalStorage.set('accessToken', accessToken);
      LocalStorage.set('idToken', idToken);
      LocalStorage.set('refreshToken', refreshToken);
      LocalStorage.set<boolean>('isAuthenticated', isAuthenticated);
      LocalStorage.set<boolean>('hasInitiatedSignIn', hasInitiatedSignIn);
      LocalStorage.set('user', user);
      state.isInitialSignIn = isInitialSignIn;
    },

    setTokens: (
      state,
      {
        payload: { accessToken, idToken, refreshToken, isAuthenticated }
      }: PayloadAction<Token & { isAuthenticated: boolean }>
    ) => {
      state.accessToken = accessToken;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = isAuthenticated;
      LocalStorage.set('accessToken', accessToken);
      LocalStorage.set('idToken', idToken);
      LocalStorage.set('refreshToken', refreshToken);
      LocalStorage.set<boolean>('isAuthenticated', isAuthenticated);
    },

    setTempCredentials: (
      state,
      {
        payload: {
          user,
          accessToken,
          idToken,
          refreshToken,
          isAuthenticated,
          currentSignInMethod,
          hasInitiatedSignIn = false,
          isDeviceAuthenticated,
          isEmailVerified,
          isPasswordCreated,
          relatedAccounts,
          currentSignedInEmail,
          currentSignedInPhone
        }
      }: PayloadAction<AuthState>
    ) => {
      state.user = user;
      state.accessToken = accessToken;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = isAuthenticated;
      state.hasInitiatedSignIn = hasInitiatedSignIn;
      state.currentSignInMethod = currentSignInMethod;
      LocalStorage.set('currentSignInMethod', currentSignInMethod);
      LocalStorage.set('accessToken', accessToken);
      LocalStorage.set('idToken', idToken);
      LocalStorage.set('refreshToken', refreshToken);
      LocalStorage.set('hasInitiatedSignIn', hasInitiatedSignIn);
      state.isDeviceAuthenticated = isDeviceAuthenticated;
      state.isEmailVerified = isEmailVerified;
      state.isPasswordCreated = isPasswordCreated;
      state.relatedAccounts = relatedAccounts ?? [];

      state.currentSignedInEmail = currentSignedInEmail;
      state.currentSignedInPhone = currentSignedInPhone;
    },

    setCurrentSignInMethod: (state, { payload }: PayloadAction<SignInMethod>) => {
      state.currentSignInMethod = payload;
      LocalStorage.set(LocalStorageKeysEnum.CURRENT_SIGN_IN_METHOD, payload);
    },

    setHasPendingEmailSteps: (state, { payload }: PayloadAction<boolean>) => {
      state.hasPendingEmailSteps = payload;
    },

    setInitialSignIn: (state, { payload }: PayloadAction<boolean>) => {
      state.isInitialSignIn = payload;
    },

    setIsPasswordExpired: (state, { payload }: PayloadAction<boolean>) => {
      state.isPasswordExpired = payload;
    },

    setIsAuthenticated: (
      state,
      { payload }: PayloadAction<{ isAuthenticated: boolean; writeToLocalStorage?: boolean }>
    ) => {
      state.isAuthenticated = payload.isAuthenticated;
      if (payload.writeToLocalStorage) {
        LocalStorage.set('isAuthenticated', payload.isAuthenticated);
      }
    },
    updateSTLTokens: (state, { payload: { idToken, accessToken } }) => {
      state.idToken = idToken;
      state.accessToken = accessToken;
    },

    updateDownloadLinkToken: (state, { payload }: PayloadAction<DownloadInviteLinkPayload>) => {
      state.downloadLinkToken = payload;
    },

    updateTeamInviteTokens: (state, { payload }: PayloadAction<AcceptTeamInviteToken>) => {
      state.teamInviteTokens = { ...payload };
    },

    updateSignUpSearchParams: (state, { payload }: PayloadAction<{ [key: string]: string }>) => {
      state.signUpSearchParams = payload;
    },

    onSignUp: (
      state,
      {
        payload: {
          user,
          accessToken,
          idToken,
          refreshToken,
          isAuthenticated,
          hasInitiatedSignUp = false,
          isDeviceAuthenticated
        }
      }: PayloadAction<AuthState>
    ) => {
      state.user = user;
      state.accessToken = accessToken;
      state.idToken = idToken;
      state.refreshToken = refreshToken;
      state.isAuthenticated = isAuthenticated;
      state.isDeviceAuthenticated = isDeviceAuthenticated;
      state.isEmailVerified = false;
      state.isPasswordCreated = false;
      state.hasInitiatedSignUp = true;

      LocalStorage.set('accessToken', accessToken);
      LocalStorage.set('idToken', idToken);
      LocalStorage.set('refreshToken', refreshToken);
      LocalStorage.set<boolean>('isAuthenticated', isAuthenticated);
      LocalStorage.set<boolean>('hasInitiatedSignUp', hasInitiatedSignUp);
      LocalStorage.set('user', user);
    },

    updateInviteResponse: (state, { payload }: PayloadAction<InviteResponse | string>) => {
      state.inviteResponse = isObject(payload) ? { ...payload } : payload;
    },
    loggedOut: state => {
      state.logoutActionInProgress = true;
      //clear local storage, subscriptions
      LocalStorage.clear();
      state.user = null;
      state.accessToken = null;
      state.idToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.hasInitiatedSignIn = false;
      state.isInitialSignIn = false;
      state.hasInitiatedSignUp = false;
      state.teamInviteTokens = teamInviteIconsInitialState;
      state.currentSignInMethod = signInMethods.phoneNumber;
      redirect(routesPath.SIGNIN);
    },

    updateLogoutActionInProgress: (state, { payload }: PayloadAction<boolean>) => {
      state.logoutActionInProgress = payload;
    }
  }
});

export const {
  resetAuthState,
  updateLogoutActionInProgress,
  onEnterPasswordSignIn,
  setInitialSignIn,
  setCredentials,
  setHasPendingEmailSteps,
  setIsPasswordExpired,
  setIsAuthenticated,
  setTokens,
  setTempCredentials,
  updateSTLTokens,
  loggedOut,
  updateDownloadLinkToken,
  setCurrentSignInMethod,
  updateTeamInviteTokens,
  onSignUp,
  updateInviteResponse,
  updateSignUpSearchParams
} = authSlice.actions;

export const selectAuthState = (state: RootState) => state.rootReducer.auth;
export const selectDownloadLinkToken = (state: RootState) => state.rootReducer.auth.downloadLinkToken;
export const selectTeamInviteTokens = (state: RootState) => state.rootReducer.auth.teamInviteTokens;

export const selectCurrentSignInMethod = (state: RootState) => state.rootReducer.auth.currentSignInMethod;
export const selectSignUpSearchParams = (state: RootState) => state.rootReducer.auth.signUpSearchParams;

export default authSlice.reducer;
