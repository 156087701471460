/* eslint-disable indent */
import { CaretDownIcon, DeactivateIcon, NavCloseIcon, ReactivateIcon, WhiteInfoIcon } from 'assets/icons';
import { CustomButton, GenericError, Loader } from 'components';
import CustomAvatar from 'components/avatar/CustomAvatar';
import SidePanel from 'components/side-panel/SidePanel';
import {
  getNotifications,
  selectCurrentTeam,
  selectCurrentTeamTeamSettingsPermissions,
  selectMerchantTeams,
  selectUserDetails,
  setCurrentTeam,
  showNotifier
} from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { InvoicesRouteState } from 'routes/types';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { getInitials } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import {
  useDeactivateMemberMutation,
  useEditPermissionsMutation,
  useLazyGetTeamMemberByIdQuery,
  useReactivateMemberMutation
} from '../api';
import TeamRoleModal from '../edit-permissions/components/TeamRoleModal';
import EditPermissionsModal from '../edit-permissions/EditPermissionsModal';
import { selectEditSlicePermissions, selectTeamMemberRole } from '../edit-permissions/editPermissionsSlice';
import { EditTeamMemberPermissionsPayload, TeamMemberStatus } from '../manageTeam.model';
import DeactivateTeamMemberModal from './DeactivateTeamMemberModal';
import { PermissionLevels, TeamRole } from 'containers/app/app.model';

import PermissionsSection from './PermissionsSection';
import ReactivateMemberModal from './ReactivateMemberModal';
import { NotifierTypes } from 'constants/notifierConstants';
import { InfoMesssages } from 'types/infoMessages';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsManageTeam } from '../events';
import { teamMemberRoles } from '../edit-permissions/constants';

interface TeamMemberSidePanelProps {
  onClose?: VoidFn;
}

const TeamMemberSidePanel: FC<TeamMemberSidePanelProps> = () => {
  const { teamId, memberId } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const currentUser = useAppSelector(selectUserDetails);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const selectedTeamRole = useAppSelector(selectTeamMemberRole);
  const selectedEditSlicePermissions = useAppSelector(selectEditSlicePermissions);
  const merchantTeams = useAppSelector(selectMerchantTeams);
  const currentTeamSettingPermissions = useAppSelector(selectCurrentTeamTeamSettingsPermissions);
  const notifications = useAppSelector(getNotifications);

  const [handleSidePanelClose, resetTeamMembersQuery] = useOutletContext<any>();

  const [deactivateMemberMutation, { isSuccess: isDeactivateSuccess }] = useDeactivateMemberMutation();
  const [reactivateMemberMutation, { isSuccess: isReactivateSuccess }] = useReactivateMemberMutation();

  const [openRoleChangeModal, setOpenRoleChangeModal] = useState(false);

  const [deactivateMemberModalState, setDeactivateMemberModalState] = useState({
    open: false,
    teamMember: null
  });
  const [reactivateMemberModalState, setReactivateMemberModalState] = useState({
    open: false,
    teamMember: null
  });

  const [editPermissionsModalState, setEditPermissionsModalState] = useState({
    open: false,
    teamMember: null
  });

  const [getTeamMemberByIdQuery, getTeamMemberByIdState] = useLazyGetTeamMemberByIdQuery();

  const [
    editPermissionsMutation,
    { isSuccess: isEditedPermissionsSuccess, isLoading: isPermissionsEditMutationInProgress }
  ] = useEditPermissionsMutation();

  const getTeamMemberById = () => {
    if (memberId && teamId) {
      getTeamMemberByIdQuery({ teamId: +teamId, merchantId: memberId });
    }
  };

  useEffect(() => {
    if (teamId) {
      if (+teamId !== currentTeam?.id) {
        const merchantTeam = merchantTeams.find(team => team.id === +teamId);
        if (merchantTeam) {
          dispatch(setCurrentTeam(merchantTeam));
        } else {
          navigate(routesPath.MANAGE_TEAM, { replace: true });
        }
      }
    }
  }, [teamId, location]);

  useEffect(() => {
    if (currentTeamSettingPermissions.viewExistingMember) {
      logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListDetails, { teamId, merchantId: memberId });
      getTeamMemberById();
    } else {
      navigate(routesPath.MANAGE_TEAM, { replace: true });
    }
  }, [memberId, teamId]);

  useEffect(() => {
    if (isReactivateSuccess || isDeactivateSuccess || isEditedPermissionsSuccess) {
      resetTeamMembersQuery();
      getTeamMemberById();
      handleCancelRoleChange();
    }
  }, [isDeactivateSuccess, isReactivateSuccess, isEditedPermissionsSuccess]);

  const {
    isSuccess: teamMemberByIdSuccess,
    isFetching: fetchingTeamMemberById,
    data: teamMemberById,
    isUninitialized
  } = getTeamMemberByIdState;

  const { invoiceSummary, member, teamMemberPermission } = teamMemberById ?? {};

  const { permissions, role } = teamMemberPermission ?? {};

  const isActiveMember = teamMemberByIdSuccess ? member?.status === TeamMemberStatus.ACTIVE : false;

  const handleClose = () => {
    handleSidePanelClose();
    navigate(routesPath.MANAGE_TEAM);
  };

  const onInfoClick = (id, infoMessage) => {
    const existingNotification = notifications.find(notification => notification.id === id);
    if (existingNotification) return;
    dispatch(
      showNotifier({
        id,
        message: {
          primaryMessage: infoMessage,
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.WARNING,
        showClose: true,
        fontStyle: 'text-primary font-normal'
      })
    );
  };

  const handleDeactivateClick = () => {
    if (deactivateMemberModalState.open) {
      setDeactivateMemberModalState({ open: false, teamMember: null });
      return;
    }
    if (!hasPermissionsToEdit) {
      onInfoClick(member.id, InfoMesssages.editTeamMemberPermissionMessage);
      return;
    }
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListDetailsDeactivate);
    setDeactivateMemberModalState({ open: true, teamMember: member });
  };

  const deactivateMemberHandler = () => {
    deactivateMemberMutation({
      merchantId: memberId,
      teamId: currentTeam.id
    });
    handleDeactivateClick();
  };

  const handleReactivateClick = () => {
    if (reactivateMemberModalState.open) {
      setReactivateMemberModalState({ open: false, teamMember: null });
      return;
    }
    if (!hasPermissionsToEdit) {
      onInfoClick(member.id, InfoMesssages.editTeamMemberPermissionMessage);
      return;
    }

    setReactivateMemberModalState({ open: true, teamMember: member });
  };

  const reactivateMemberHandler = () => {
    reactivateMemberMutation({
      merchantId: memberId,
      teamId: currentTeam.id
    });
    handleReactivateClick();
  };

  const handleViewInvoices = () => {
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListDetailsViewInvoices);
    const invoiceRouteState: InvoicesRouteState = {
      merchantId: memberId,
      showSidePanel: false
    };
    navigate(routesPath.INVOICES, { replace: false, state: invoiceRouteState });
  };

  const getTeamMemberName = () => {
    if (member.firstName || member.lastName) {
      return `${member.firstName} ${member.lastName}`;
    }
    return member.phone;
  };

  const invoiceSummaryData = teamMemberByIdSuccess
    ? [
        {
          title: 'Joined Date',
          data: isActiveMember
            ? DateUtils.getDateInFormat({ date: new Date(member.activeSince), dateFormat: 'MMM dd, yyyy' }) || '-'
            : '-'
        },
        {
          title: 'First Payment',
          data: invoiceSummary.firstPaymentDate || '-'
        },
        {
          title: 'Total Invoices',
          data: invoiceSummary.paidInvoicesCount
        },
        {
          title: 'Total Collected',
          data: getAmountWithCurrency(invoiceSummary.amountCollected)
        }
      ]
    : [];

  const getActivityText = () => {
    if (member.status === TeamMemberStatus.ACTIVE) {
      const date = member.activeSince ? new Date(member.activeSince) : new Date();
      const formattedDate = DateUtils.getDateInFormat({ date: date, dateFormat: 'MM/dd/yyyy' });
      return <div>{`Active since ${formattedDate}`}</div>;
    }
    const date = member.inactiveSince ? new Date(member.inactiveSince) : new Date();
    const formattedDate = DateUtils.getDateInFormat({ date: date, dateFormat: 'MM/dd/yyyy' });
    return <div className="text-error">{`Inactive since ${formattedDate}`}</div>;
  };

  const handleRoleChangeClick = () => {
    if (role === TeamRole.OWNER || !hasPermissionsToEdit) {
      return;
    }
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListDetailsRoleChange);
    setOpenRoleChangeModal(true);
  };

  const handleCancelRoleChange = () => {
    setOpenRoleChangeModal(false);
  };

  const toggleEditPermissionsModal = () => {
    if (editPermissionsModalState.open) {
      setEditPermissionsModalState({ open: false, teamMember: null });
      return;
    }
    if (!hasPermissionsToEdit) {
      return;
    }
    setEditPermissionsModalState({ open: true, teamMember: member });
  };

  const onPermissionEditClick = () => {
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListDetailsRoleChangeEditPermissions);
    setOpenRoleChangeModal(false);
    toggleEditPermissionsModal();
  };

  const onEditSuccess = () => {
    getTeamMemberById();
    resetTeamMembersQuery();
  };

  const handleRoleUpdation = () => {
    const editPermissionsPayload: EditTeamMemberPermissionsPayload = {
      role: selectedTeamRole,
      teamId: currentTeam?.id,
      merchantId: member.id.toString(),
      permissions: selectedEditSlicePermissions
    };
    editPermissionsMutation(editPermissionsPayload);
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListDetailsRoleChangeUpdate);
  };

  const onEditClick = () => {
    toggleEditPermissionsModal();
    logAnalyticEvent(CleverTapEventsManageTeam.webManageTeamListDetailsEditPermissions);
  };

  const hasPermissionsToEdit =
    (currentTeamSettingPermissions?.editExistingMember &&
      permissions?.banking.level !== PermissionLevels.MANAGE_FUNDS) ||
    currentTeam?.role === TeamRole.OWNER;

  const allowEditPermissions = member?.id.toString() !== currentUser?.id && role !== TeamRole.OWNER;

  return (
    <SidePanel isOpen={true} onClose={handleClose}>
      <div className="h-full w-96 bg-primary">
        {fetchingTeamMemberById && !teamMemberByIdSuccess && <Loader />}
        {!fetchingTeamMemberById && teamMemberByIdSuccess && (
          <div className="flex h-full w-full flex-col">
            <div className="flex w-full items-center gap-2 px-4 py-5 pb-[15px]">
              <div className="cursor-pointer" onClick={handleClose}>
                <NavCloseIcon />
              </div>
              <div className="text-17px font-semibold text-heading">{getTeamMemberName()}</div>
            </div>
            <div className="customNormalScroll flex grow flex-col gap-5 overflow-y-auto px-4 pb-14">
              <div className="rounded-md border border-border">
                <div className="relative h-36">
                  <div className="h-[89px] rounded-t-md bg-secondaryBtn"></div>
                  <div className="absolute left-1/2 top-4 -translate-x-1/2 rounded-full border-[5px] border-primary">
                    <CustomAvatar
                      src={member.profilePicture}
                      label={getInitials(getTeamMemberName())}
                      width="w-[120px]"
                      height="h-[120px]"
                      fontStyle="text-2xl font-semibold"
                    />
                  </div>
                </div>
                <div className="flex flex-col items-center justify-center gap-3 py-5">
                  <div className="text-xl font-bold text-primaryText">{getTeamMemberName()}</div>
                  <div className="flex flex-col items-center justify-center text-sbase font-semibold text-primaryText">
                    <div>{member.phone}</div>
                    <div>{member.email}</div>
                  </div>
                  <div className="text-accentText">{getActivityText()}</div>
                </div>
              </div>
              <div
                className={`rounded-md border border-border p-[15px] ${
                  (isActiveMember || hasPermissionsToEdit) && allowEditPermissions && 'cursor-pointer'
                } ${!isActiveMember && 'pointer-events-none opacity-50'} ${!hasPermissionsToEdit && 'cursor-default'}`}>
                <div
                  className="flex h-[50px] w-full items-center justify-between rounded
                  bg-secondaryBtn pl-3 text-17px font-semibold capitalize text-accentText"
                  onClick={handleRoleChangeClick}>
                  <div className="px-2">{role === TeamRole.SUPER_ADMIN ? teamMemberRoles[4].name : role}</div>
                  {allowEditPermissions && (
                    <CustomButton
                      id="invite-change-role"
                      type={null}
                      className={`text-[15px] font-semibold text-primaryBtn 
                    hover:text-tertiaryText active:text-primaryButtonHover active:shadow-none
                    ${!hasPermissionsToEdit && 'pointer-events-none opacity-50'}`}>
                      Change
                    </CustomButton>
                  )}
                </div>
              </div>
              <div className={`rounded-md border border-border ${!isActiveMember && 'pointer-events-none opacity-50'}`}>
                <PermissionsSection
                  permissions={permissions}
                  onEditClick={onEditClick}
                  allowEdit={allowEditPermissions}
                />
              </div>
              <div className={`rounded-md border border-border ${!isActiveMember && 'pointer-events-none opacity-50'}`}>
                <div
                  className="border-b border-borderGray p-4 pb-[13px] text-sbase
                  font-semibold text-primaryText">
                  Summary
                </div>
                <div className="grid grid-cols-2 gap-6 p-[15px]">
                  {invoiceSummaryData.map((info, index) => (
                    <div key={info.title + index} className="flex flex-col gap-1.5">
                      <div className="text-sbase text-accentText">{info.title}</div>
                      <div className="truncate text-sbase font-semibold text-primaryText">{info.data}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="cursor-pointer rounded-md border border-border p-[15px]" onClick={handleViewInvoices}>
                <div className="flex items-center justify-between">
                  <div className="text-sbase font-semibold text-primaryBtn">View Invoices</div>
                  <div className="cursor-pointer">
                    <CaretDownIcon className="-rotate-90" />
                  </div>
                </div>
              </div>
            </div>
            {member.status === TeamMemberStatus.ACTIVE
              ? allowEditPermissions && (
                  <div
                    className={`flex cursor-pointer items-center justify-center gap-2 bg-secondaryBtn p-4 ${
                      !hasPermissionsToEdit && 'opacity-50'
                    }`}
                    onClick={handleDeactivateClick}>
                    <div>
                      <DeactivateIcon />
                    </div>
                    <div className="text-sbase font-semibold text-primaryText">Deactivate</div>
                    {!hasPermissionsToEdit && <WhiteInfoIcon />}
                  </div>
                )
              : allowEditPermissions && (
                  <div
                    className={`flex cursor-pointer items-center justify-center gap-2 bg-primaryBtn p-4 ${
                      !hasPermissionsToEdit && 'opacity-50'
                    }`}
                    onClick={handleReactivateClick}>
                    <div>
                      <ReactivateIcon className="path-fill-current path text-primary" />
                    </div>
                    <div className="text-sbase font-semibold text-primary">Re-activate</div>
                    {!hasPermissionsToEdit && <WhiteInfoIcon />}
                  </div>
                )}
          </div>
        )}
        {!fetchingTeamMemberById && !teamMemberByIdSuccess && !isUninitialized && (
          <GenericError handleErrorBack={handleClose} />
        )}
      </div>
      {deactivateMemberModalState.open && (
        <DeactivateTeamMemberModal
          deactivateMemberHandler={deactivateMemberHandler}
          showLoader={false}
          open={deactivateMemberModalState.open}
          teamMember={deactivateMemberModalState.teamMember}
          toggleOpen={handleDeactivateClick}
        />
      )}
      {reactivateMemberModalState.open && (
        <ReactivateMemberModal
          reactivateMemberHandler={reactivateMemberHandler}
          showLoader={false}
          open={reactivateMemberModalState.open}
          teamMember={reactivateMemberModalState.teamMember}
          toggleOpen={handleReactivateClick}
        />
      )}
      {openRoleChangeModal && (
        <TeamRoleModal
          open={openRoleChangeModal}
          onCancel={handleCancelRoleChange}
          teamMemberData={member}
          handlePermissionEdit={onPermissionEditClick}
          onUpdateClick={handleRoleUpdation}
          isUpdating={isPermissionsEditMutationInProgress}
          allowEdit={allowEditPermissions}
        />
      )}
      {editPermissionsModalState.open && (
        <EditPermissionsModal
          open={editPermissionsModalState.open}
          teamMemberData={editPermissionsModalState.teamMember}
          toggleShowEditPermissionsModal={toggleEditPermissionsModal}
          editSuccessCallback={onEditSuccess}
        />
      )}
    </SidePanel>
  );
};

export default TeamMemberSidePanel;
