import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/reducers';
import { ReceiptByIdResponse } from './receipt.model';
import { PostConfirmRefundResponse } from './receipts.model';

const initialState = {
  // TODO: additional tax: remove fields related to invoice level additional tax by replacing it with service charge
  refundAmount: '',
  refundReason: '',
  receiptData: {
    ID: '',
    amount: '',
    tipAmount: '',
    surchargeAmount: '',
    billAmount: '',
    merchantName: '',
    merchantPhoneNumber: '',
    merchantAddress: '',
    storeName: '',
    displayName: '',
    successMessage: '',
    currency: '',
    currencySymbol: '',
    countryCode: '',
    date: '',
    qr: '',
    time: '',
    paymentMethod: '',
    status: '',
    invoiceNumber: '',
    customerName: '',
    customerId: '',
    qrId: '',
    merchantType: '',
    timestamp: '',
    slot: '',
    hours: 0,
    endTime: '',
    hasSurcharge: false,
    hasTip: false,
    isManualKeyed: false,
    websiteConfig: {
      enabled: false,
      websiteURL: ''
    },
    cardDetails: {
      cardLast4: '',
      funding: '',
      network: '',
      networkIcon: ''
    },
    refundTransaction: {
      ID: '',
      id: '',
      merchantId: '',
      storeId: '',
      parentId: '',
      amount: '',
      transactionType: '',
      reason: '',
      status: '',
      date: '',
      time: '',
      timestamp: ''
    },
    refundTransactions: [
      {
        ID: '',
        id: '',
        merchantId: '',
        storeId: '',
        parentId: '',
        amount: '',
        transactionType: '',
        reason: '',
        status: '',
        date: '',
        time: '',
        timestamp: ''
      }
    ],
    payoutStatus: '',
    payoutCreatedBy: '',
    payoutId: '',
    merchantId: '',
    hasAdditionalTax: false,
    additionalTax: '',
    additionalTaxAmount: '',
    lineItems: [],
    profilePicture: '',
    createdBy: '',
    description: '',
    failureMessage: '',
    teamIcon: '',
    invoiceId: '',
    parentTransactionId: '',
    refundReason: '',
    customerAddress: '',
    customerPhone: '',
    customerEmail: '',
    email: '',
    website: '',
    tipPercentage: '',
    surchargePercentage: '',
    additionalTaxPercentage: '',
    payableAmount: '',
    isManualPayment: false,
    hasDiscount: false,
    discount: '',
    discountPercentage: '',
    version: '',
    taxes: [],
    transactionCreatedAt: '',
    paidAt: '',
    invoiceCreatedAt: '',
    teamId: 0,
    receiptUrl: '',
    errorCode: '',
    errorMessage: '',
    teamDetails: {
      address: '',
      phone: '',
      email: '',
      website: ''
    },
    disputeTransactions: [{ disputeId: '', disputeTransactionId: '', disputeAmount: '', createdAt: '', status: '' }],
    tnc: { tncId: 0, tnc: '' },
    serviceCharges: [],
    serviceChargeAmount: '',
    paymentSystem: null
  },
  refundData: {
    teamBankingBalance: '',
    futurePayouts: '',
    pendingPayouts: ''
  }
};

export const receiptSlice = createSlice({
  name: 'receipts',
  initialState,
  reducers: {
    resetReceiptState: () => initialState,
    updateRefundAmount: (state, { payload }: PayloadAction<string>) => {
      state.refundAmount = payload;
    },
    updateRefundReason: (state, { payload }: PayloadAction<string>) => {
      state.refundReason = payload;
    },
    initializeReceiptData: (state, { payload }: PayloadAction<ReceiptByIdResponse>) => {
      state.receiptData = payload;
    },
    updateConfrimRefundResponse: (state, { payload }: PayloadAction<PostConfirmRefundResponse>) => {
      state.refundData = payload;
    }
  }
});

export const {
  resetReceiptState,
  updateRefundAmount,
  initializeReceiptData,
  updateRefundReason,
  updateConfrimRefundResponse
} = receiptSlice.actions;

export const selectRefundAmount = (state: RootState) => state.rootReducer.receipts.refundAmount;
export const selectRefundReason = (state: RootState) => state.rootReducer.receipts.refundReason;
export const selectReceiptData = (state: RootState) => state.rootReducer.receipts.receiptData;
export const selectRefundData = (state: RootState) => state.rootReducer.receipts.refundData;
export default receiptSlice.reducer;
