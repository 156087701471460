import { CopyBlueIcon, DeleteIcon, EditIcon } from 'assets/icons';
import { FC } from 'react';
import { IPaginatedItem } from '../items.model';
import { ItemActionType } from '../types';
import { useDispatch } from 'react-redux';
import { resetItemSlice } from '../itemsSlice';

interface ListActionsInterface {
  item: IPaginatedItem;
  isModifiable: boolean;
  onCloseListItemActionsPopover: (id: number) => void;
  handleMoreActionsClick: ({ action, item }: { action: ItemActionType; item: IPaginatedItem }) => void;
}

const ListItemActionsMenu: FC<ListActionsInterface> = ({
  item,
  onCloseListItemActionsPopover,
  handleMoreActionsClick
}) => {
  const dispatch = useDispatch();

  const handleEdit = e => {
    dispatch(resetItemSlice());
    e.stopPropagation();
    onCloseListItemActionsPopover(item.id);
    handleMoreActionsClick({ action: ItemActionType.EDIT, item });
  };

  const handleDuplicate = e => {
    dispatch(resetItemSlice());
    e.stopPropagation();
    onCloseListItemActionsPopover(item.id);
    handleMoreActionsClick({ action: ItemActionType.DUPLICATE, item });
  };

  const handleDelete = e => {
    dispatch(resetItemSlice());
    e.stopPropagation();
    onCloseListItemActionsPopover(item.id);
    handleMoreActionsClick({ action: ItemActionType.DELETE, item });
  };

  return (
    <div className="space-y-3 text-sbase font-semibold text-primaryText">
      <>
        <div
          id="item-table-table-3dot-edit"
          className={'flex cursor-pointer flex-row items-center gap-2'}
          onClick={handleEdit}>
          <EditIcon className="path-fill-current path text-secondary" />
          <div>Edit</div>
        </div>
        <div
          id="item-table-table-3dot-duplicate"
          className={'flex cursor-pointer flex-row items-center gap-2'}
          onClick={handleDuplicate}>
          <CopyBlueIcon width={24} />
          <div>Duplicate</div>
        </div>
        <div
          id="item-table-3dot-delete"
          onClick={handleDelete}
          className={'flex cursor-pointer flex-row items-center gap-2'}>
          <DeleteIcon />
          <div>Delete</div>
        </div>
      </>
    </div>
  );
};

export default ListItemActionsMenu;
