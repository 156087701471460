import { FC, useEffect, useState } from 'react';
import { GreenTickIcon, MailIIcon, RedCloseIcon } from 'assets/icons';
import { CustomButton, CustomInput, CustomModal, If } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import { VoidFn } from 'types/baseTypes';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { checkEmailValid } from 'utils/commonUtils';
import { EmailErrorMessages } from '../types';
import { useAppSelector } from 'hooks';
import { selectMyAccountState } from '../myAccountSlice';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsMyAccount } from '../events';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';

interface EmailModalProps {
  className?: string;
  handleSave?: (email: string) => void;
  isSaveInProgress?: boolean;
  open?: boolean;
  toggleOpen?: VoidFn;
  resetEmailError?: VoidFn;
  emailValue?: string;
  emailError: EmailErrorMessages;
  setEmailError: React.Dispatch<React.SetStateAction<EmailErrorMessages>>;
}

const EmailModal: FC<EmailModalProps> = ({
  isSaveInProgress,
  toggleOpen,
  open,
  handleSave,
  emailValue,
  emailError,
  setEmailError
}) => {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [email, setEmail] = useState('');
  const [isContinueClicked, setIsContinueClicked] = useState(false);

  const selectedMyAccountState = useAppSelector(selectMyAccountState);

  useEffect(() => {
    return () => {
      setEmailError(null);
    };
  }, []);

  useEffect(() => {
    setEmail(emailValue);
  }, [emailValue]);

  const onCancelClick = () => {
    setIsContinueClicked(false);
    setEmail('');
    setIsEmailValid(true);
    toggleOpen();
    logAnalyticEvent(CleverTapEventsMyAccount.webUpdateEmailCancelClick, {});
  };

  const onChangeEmail = value => {
    setEmailError(null);
    setEmail(value.trim());
    checkEmailValid(value.trim(), setIsEmailValid);
  };

  const onPrimaryButtonClick = () => {
    setIsContinueClicked(true);
    if (email === selectedMyAccountState.email) {
      setEmailError(EmailErrorMessages.ALREADY_ADDED);
    } else if (isEmailValid) {
      handleSave(email);
    }
    logAnalyticEvent(CleverTapEventsMyAccount.webUpdateEmailContinueClick, { email: email });
  };

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      onPrimaryButtonClick();
    }
  };

  return (
    <CustomModal width="md:w-[455px] min-h-[314px]" open={open} closeModal={onCancelClick}>
      <div className="w-full rounded p-10">
        <div className="flex flex-col items-center justify-center">
          <FormCloseReactIcon
            id="my-account-email-modal-close"
            height="24"
            width="24"
            className="absolute top-2 right-2 shrink-0 cursor-pointer"
            onClick={onCancelClick}
          />
          <div className="flex w-full flex-col">
            <div className="text-xl font-bold leading-6 text-heading">What's your email?</div>
            <CustomInput
              id="update-email-input"
              inputStyle="w-full text-17px"
              type={InputType.TEXT}
              onChange={onChangeEmail}
              value={email}
              prefixWrapperStyle="top-11 left-4 path-fill-current text-tertiaryText opacity-30"
              prefix={<MailIIcon height={20} />}
              hasSuffix={true}
              suffix={isEmailValid ? <GreenTickIcon /> : <RedCloseIcon />}
              suffixStyle={isEmailValid ? 'top-12 mr-3' : 'top-[42px] mr-3'}
              className={`mt-7 block h-[50px] rounded border placeholder:text-[17px]
               placeholder:font-semibold placeholder:text-accentText
               ${(isEmailValid || !isContinueClicked) && !emailError ? '' : 'border-error'}
               pl-12 pr-10 font-semibold text-primaryText focus:outline-none`}
              placeholder="Enter email"
              onKeyPress={handleKeyDown}
            />
            {!isEmailValid && isContinueClicked && (
              <div className="mt-1 text-sbase font-semibold text-error">Please enter a valid email id</div>
            )}
            <If condition={Boolean(emailError)}>
              <div id="my-acc-update-email-error" className="mt-1 text-sbase text-error">
                {emailError}
              </div>
            </If>
          </div>
        </div>
        <div>
          <CustomButton
            disabled={email.length === 0 && !isContinueClicked}
            id="update-email-modal-update-button"
            isLoading={isSaveInProgress}
            onClick={onPrimaryButtonClick}
            className={`text-semibold mt-5 h-[38px] w-full rounded-md bg-primaryBtn
            text-px13 text-primaryBtnText shadow-none hover:bg-primaryButtonHover`}>
            Continue
          </CustomButton>
          <CustomButton
            id="update-email-modal-cancel-button"
            className="mt-9 h-[18px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
            type={ButtonType.SECONDARY}
            onClick={onCancelClick}>
            Cancel
          </CustomButton>
        </div>
      </div>
    </CustomModal>
  );
};

export default EmailModal;
