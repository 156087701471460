export enum CleverTapEventsCatalogItems {
  webCatalogItemsCreateItem = 'Web_Catalog_Items_Create_Item',
  webCatalogItemsEditItem = 'Web_Catalog_Items_MM_Edit_Item',
  webCatalogItemsDuplicateItem = 'Web_Catalog_Items_MM_Duplicate_Item',
  webCatalogItemsDeleteItem = 'Web_Catalog_Items_MM_Delete_Item',
  webCatalogItemsCreateItemSaveClicked = 'Web_Catalog_Items_Create_Item_Save_Clicked',
  webCatalogItemsDuplicateItemSaveClicked = 'Web_Catalog_Items_Duplicate_Item_Save_Clicked',
  webCatalogItemsEditItemSaveClicked = 'Web_Catalog_Items_Edit_Item_Save_Clicked',
  webCatalogItemsEditItemDuplicateClicked = 'Web_Catalog_Items_Edit_Item_Duplicate_Clicked',
  webCatalogItemsEditItemDeleteClicked = 'Web_Catalog_Items_Edit_Item_Delete_Clicked',
  webCatalogItemsSearch = 'Web_Catalog_Items_Search',
  webCatalogItemsExport = 'Web_Catalog_Items_Export'
}
