export function formatAmount(num: number | string): string {
  return Math.abs(Number(parseFloat(num?.toString()).toFixed(2))).toLocaleString('en', {
    minimumFractionDigits: 2
  });
}

export const isNegativeAmount = (amount: string) => amount.startsWith('-');

export const getAmountWithCurrency = (amount: number | string, currencySymbol = '$') => {
  const formattedAmount = `${currencySymbol}${formatAmount(amount)}`;
  return +amount < 0 ? `-${formattedAmount}` : formattedAmount;
};

export const roundToTwoDecimals = (value: number | string) => {
  const rounded = Math.round((+value + Number.EPSILON) * 100) / 100;
  return rounded;
};

export const roundToThreeDecimals = (value: number | string) => {
  const rounded = Math.round((+value + Number.EPSILON) * 1000) / 1000;
  return rounded;
};

export const formattedLineItemQty = ({ qtyString }: { qtyString: string }) => {
  return parseFloat(qtyString).toFixed(2);
};

export function formatDecimal(number: string | number) {
  const numberString = number.toString();
  return numberString?.replace(/\.00$/, '');
}
export function formatDecimalThreePlaces(number: string | number) {
  const numberString = number?.toString();
  return numberString?.replace(/\.000$/, '');
}

export function getPercentageAmount(amount: string | number, percentage: string | number) {
  if (amount && percentage) {
    const percentageAmount = (+amount * +percentage) / 100;
    return percentageAmount;
  }
  return 0;
}

export const reverseCalculatePercentage = ({
  value,
  total,
  twoDecimalRounding = true
}: {
  value: string | number;
  total: string | number;
  twoDecimalRounding?: boolean;
}) => {
  if (value && total) {
    const percentage = (+value * 100) / +total;
    if (twoDecimalRounding) {
      return roundToTwoDecimals(percentage);
    }
    return roundToThreeDecimals(percentage);
  }
  return 0;
};

export function formatAmountWithThreeDecimal(num: number | string): string {
  return formatDecimalThreePlaces(
    Number(parseFloat(num?.toString()).toFixed(2)).toLocaleString('en', {
      minimumFractionDigits: 3
    })
  );
}

export const convertCentsToDollars = (cents: string) => {
  const dollars = +cents / 100;

  return roundToTwoDecimals(dollars);
};
