import { PhoneIcon } from 'assets/icons';
import { CustomButton, CustomInput, If } from 'components';
import { REGEX } from 'constants/common';
import React, { FC, useEffect, useState } from 'react';
import {
  appendCountryCode,
  formatPhoneNumber,
  isNonUsPhoneNumber,
  isValidPhoneNumber,
  removeCountryCode
} from 'utils/phoneNumberUtils';
import { SignInMethodEnum, signInMethods } from '../constants';
import { SignInMethod } from '../types';
import { openInNewTab } from 'utils/commonUtils';
import { PRIVACY_POLICY_LINK, TERMS_LINK } from 'config/index';

interface SignUpSectionProps {
  selectedSignInMethod: SignInMethod;
  onContinue: ({ value }: { value: string }) => void;
  className?: string;
  userPhoneNumber?: string;
  isLoading: boolean;
  handleLogin: VoidFunction;
}

const SignUpSection: FC<SignUpSectionProps> = ({
  selectedSignInMethod,
  onContinue,
  userPhoneNumber = '',
  isLoading,
  handleLogin
}) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [isContinueClicked, setIsContinueClicked] = useState(false);

  useEffect(() => {
    if (userPhoneNumber) {
      if (isNonUsPhoneNumber(userPhoneNumber)) {
        setPhoneNumber(userPhoneNumber);
      } else {
        setPhoneNumber(formatPhoneNumber(userPhoneNumber));
      }
    }
  }, [userPhoneNumber]);

  const getPhoneNumberDigits = phoneNumberString => {
    let phoneNo = phoneNumberString;

    if (isNonUsPhoneNumber(phoneNo)) {
      phoneNo = `+${phoneNo.replaceAll(REGEX.nonNumber, '')}`;
    } else {
      phoneNo = removeCountryCode(phoneNo, '+1');
      phoneNo = phoneNo.replaceAll(REGEX.nonNumber, '');
      phoneNo = appendCountryCode(phoneNo, '+1');
    }
    return phoneNo;
  };

  const handleContinueBtnClick = () => {
    setIsContinueClicked(true);
    if (selectedSignInMethod.id === SignInMethodEnum.PHONE_NUMBER) {
      const phoneNo = getPhoneNumberDigits(phoneNumber);
      if (isValidPhoneNumber(phoneNo)) {
        onContinue({ value: phoneNo });
      } else {
        setPhoneNumberError('Enter valid phone number.');
      }
    }
  };

  const handlePhoneNumberInputChange = value => {
    let num = value;
    if (REGEX.phoneNumberInput.test(num)) {
      if (isNonUsPhoneNumber(num)) {
        setPhoneNumber(num);
      } else {
        num = formatPhoneNumber(num);
        setPhoneNumber(num);
      }
      if (isContinueClicked) {
        const phoneNo = getPhoneNumberDigits(num);
        if (isValidPhoneNumber(phoneNo)) {
          setPhoneNumberError('');
        } else {
          setPhoneNumberError('Enter valid phone number.');
        }
      }
    }
  };

  const handleKeyDown = event => {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      handleContinueBtnClick();
    }
  };

  const renderPhoneNumberPrefix = () => {
    return (
      <div className="flex flex-row">
        <PhoneIcon className="path-fill-current mr-2.5 h-5 w-5 shrink-0 text-[#B8C2CE]" />
        <div className="pb-0.5 text-17px font-semibold text-accentText">+1</div>
      </div>
    );
  };

  const isContinueDisabled = (currentSignInMethod: SignInMethod) => {
    if (currentSignInMethod === signInMethods.phoneNumber) {
      return phoneNumber === '' && !isContinueClicked;
    }
  };

  const handleTermsClick = () => {
    openInNewTab(TERMS_LINK);
  };

  const handlePolicyClick = () => {
    openInNewTab(PRIVACY_POLICY_LINK);
  };

  return (
    <div className="my-auto flex flex-col self-center lg:min-w-[375px]">
      <div className="mb-8 mt-12 text-xl font-bold text-heading lg:text-4xl">Sign up to ScanPay</div>

      <div className="relative">
        <CustomInput
          id="signin-number-input"
          placeholder="000 000 0000"
          value={phoneNumber}
          className={`border border-borderGray pl-[72px] pt-[15px]
             text-17px text-primaryText transition duration-300
              placeholder:text-17px placeholder:font-semibold
             focus:border-none focus:outline-none
            focus:ring-1 focus:ring-secondary
            ${phoneNumberError ? 'border-error focus:ring-1 focus:ring-error' : ''}
            `}
          prefixWrapperStyle="left-5"
          prefix={renderPhoneNumberPrefix()}
          onChange={handlePhoneNumberInputChange}
          onKeyPress={handleKeyDown}
        />
        <If condition={Boolean(phoneNumberError)}>
          <div id="sign-in-phone-number-error" className="absolute text-px13 text-error">
            {phoneNumberError}
          </div>
        </If>
      </div>
      <div className="mt-10 mb-2 font-normal">
        By signing up, you agree to ScanPay{' '}
        <span className="cursor-pointer font-semibold text-secondary" onClick={handleTermsClick}>
          Terms
        </span>{' '}
        and{' '}
        <span className="cursor-pointer font-semibold text-secondary" onClick={handlePolicyClick}>
          Privacy Policy
        </span>
      </div>
      <CustomButton
        disabled={isContinueDisabled(selectedSignInMethod)}
        id="sign-in-continue-btn"
        onClick={handleContinueBtnClick}
        isLoading={isLoading}
        className="w-full">
        Continue
      </CustomButton>
      <div id="login-instead" className="my-10  text-center text-17px text-primaryText">
        Already have an account?{' '}
        <span onClick={handleLogin} className="cursor-pointer font-semibold text-secondary">
          Sign in, instead
        </span>
      </div>
    </div>
  );
};

export default SignUpSection;
