import routesPath from './RoutesPath';
import { SidePanelRouteState } from './types';

export const DEFAULT_SIDE_PANEL_ROUTE_STATE: SidePanelRouteState = {
  fromSidePanel: false,
  shouldAnimate: false
};

export const FROM_SIDE_PANEL_ROUTE_STATE: SidePanelRouteState = {
  fromSidePanel: true
};

export const ROOT_ROUTE_STATE: SidePanelRouteState = {
  shouldAnimate: true
};

export const fullScreenRoutes = [
  routesPath.ACCOUNT_SETTINGS_MY_ACCOUNT,
  routesPath.ACCOUNT_SETTINGS_CREDITS_REWARDS,
  routesPath.INVOICES_CREATE,
  routesPath.INVOICES_PREVIEW,
  routesPath.INVOICES_EDIT_PREVIEW,
  routesPath.CREATE_INVITE,
  routesPath.TAXES_CREATE,
  routesPath.CATEGORIES_CREATE,
  routesPath.ITEMS_CREATE,
  routesPath.SERVICE_CHARGES_CREATE,
  routesPath.BANKING_ONBOARDING,
  routesPath.QUICKBOOKS_LOGIN,
  routesPath.QUICKBOOKS_SETTINGS,
  routesPath.BANK_APPLICATION_STATUS,
  routesPath.CONNECT_ONBOARDING
];

export const chatIconHiddenRoutes = [
  routesPath.INVOICES_CREATE,
  routesPath.CREATE_INVITE,
  routesPath.TAXES_CREATE,
  routesPath.ITEMS_CREATE,
  routesPath.SERVICE_CHARGES_CREATE,
  routesPath.CATEGORIES_CREATE,
  routesPath.QUICKBOOKS_LOGIN,
  routesPath.QUICKBOOKS_SETTINGS
];
