import { FC, useEffect, useState } from 'react';
import SignInBgIcon from 'assets/icons/SignInBgIcon.svg';
import { RepairMan300, RepairManLowRes } from 'assets/images';
import { DoubleQuoteIcon } from 'assets/icons';

const TestimonialSection: FC = () => {
  const [imgSrc, setImgSrc] = useState(RepairManLowRes);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = RepairMan300;
    img.onload = () => {
      setImgSrc(RepairMan300);
      setIsLoaded(true);
    };
  }, [RepairMan300]);

  return (
    <div
      className="relative flex h-full w-full overflow-x-hidden bg-secondary bg-cover bg-no-repeat"
      style={{ backgroundImage: `url(${SignInBgIcon})` }}>
      <div
        className="flex h-full w-full shrink-0 grow flex-col items-start
          gap-8 pl-12 pr-[30px] lg:justify-end lg:gap-14 lg:pr-0">
        <div className="pt-36 text-2xl font-bold text-[#A4D7FA] lg:pt-0 lg:text-3xl 2xl:text-4xl">
          THE BEST OF <br />
          <span className="text-[30px] text-primary lg:text-5xl 2xl:text-[64px] 2xl:leading-[62.72px]">
            PAYMENTS &<br />
            INVOICING
          </span>{' '}
          <br />
          IN A SINGLE APP
        </div>
        <div className="relative mb-28 w-full rounded-md bg-primaryButtonHover p-8 pt-16 xl:p-16 xl:pb-11">
          <div className="absolute top-7 left-6">
            <DoubleQuoteIcon />
          </div>
          <div className="text-xl text-primary lg:w-3/5 2xl:text-xl">
            [ScanPay] streamlined my payments, made my customers feel more secure, and{' '}
            <b>reduced the waiting time for crew members by 80%.</b>"
            <br />
            Around The Town Moving & Storage
          </div>
        </div>
      </div>
      <div className="absolute -right-16 bottom-0">
        <img
          src={imgSrc}
          alt=""
          className={`h-[500px] min-h-[350px] lg:min-h-[400px] xl:min-h-[440px] 2xl:min-h-[676px] ${
            isLoaded ? '' : 'blur-sm'
          }`}
        />
      </div>
    </div>
  );
};

export default TestimonialSection;
