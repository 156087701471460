import { CustomButton, CustomInput, If } from 'components';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { MailIIcon } from 'assets/icons';
import { ButtonType } from 'components/custom-button/CustomButton';
import { checkMarkEmailAsValid } from 'utils/commonUtils';
import { REGEX } from 'constants/common';
import { twMerge } from 'tailwind-merge';
import { EmailErrorMessages } from 'containers/my-account/types';

interface AddEmailPromptSectionProps {
  className?: string;
  handleContinue: (email: string) => void;
  handleSkip: VoidFn;
  updateEmailError: EmailErrorMessages;
  setUpdateEmailError: React.Dispatch<React.SetStateAction<EmailErrorMessages>>;
}

const initialEmailErrorState = {
  msg: 'Please enter a valid email',
  isPresent: false
};

const AddEmailPromptSection: FC<AddEmailPromptSectionProps> = ({
  className = '',
  handleContinue,
  handleSkip,
  updateEmailError,
  setUpdateEmailError
}) => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<{ msg: string; isPresent: boolean }>(initialEmailErrorState);
  const [isContinueClicked, setIsContinueClicked] = useState(false);

  useEffect(() => {
    return () => {
      setUpdateEmailError(null);
    };
  }, []);

  useEffect(() => {
    if (updateEmailError) {
      setEmailError({ msg: updateEmailError, isPresent: true });
    }
  }, [updateEmailError]);

  const markEmailAsValidCallback = (isValid: boolean) => {
    if (!isValid) {
      setEmailError(prev => ({ ...prev, isPresent: true }));
    }
  };

  const handleEmailChange = value => {
    if (emailError.isPresent) {
      setEmailError(initialEmailErrorState);
    }
    setEmail(value);
    if (isContinueClicked) checkMarkEmailAsValid({ value, markEmailAsValid: markEmailAsValidCallback });
  };

  const handleContinueBtnClick = () => {
    setIsContinueClicked(true);
    setUpdateEmailError(null);
    if (REGEX.email.test(email)) {
      handleContinue(email);
    } else {
      setEmailError(prev => ({ ...prev, isPresent: true }));
    }
  };

  return (
    <div className={twMerge('my-auto flex max-w-[375px] flex-col self-center lg:w-[375px] 2xl:w-[375px]', className)}>
      <div className="mb-9 mt-12 text-2xl font-bold text-heading lg:text-4xl">What's your email</div>
      <div className="relative">
        <CustomInput
          id="signin-email-input"
          placeholder="Enter email"
          value={email}
          className={`border border-borderGray pl-[50px] text-primaryText transition duration-300
             placeholder:font-semibold focus:outline-none ${emailError.isPresent ? 'border-error' : ''}
            `}
          prefixWrapperStyle="left-5"
          prefix={<MailIIcon className="path-fill-current h-5 w-5 shrink-0 text-[#B8C2CE]" />}
          onChange={handleEmailChange}
        />
        <If condition={emailError.isPresent}>
          <div id="sign-in-phone-number-error" className="mt-1 text-sbase text-error">
            {emailError.msg}
          </div>
        </If>
      </div>
      <CustomButton
        disabled={email === '' && !isContinueClicked}
        id="sign-in-continue-btn"
        onClick={handleContinueBtnClick}
        className="mt-9 w-full">
        Continue
      </CustomButton>
      <CustomButton
        id="update-email-modal-cancel-button"
        className="mt-6 h-[38px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
        type={ButtonType.SECONDARY}
        onClick={handleSkip}>
        Skip
      </CustomButton>
    </div>
  );
};

export default AddEmailPromptSection;
