import { getFaqMigrationMonthAndYear, getFaqMigrationSafeDate } from 'containers/home/utils';

export const bankMigrationFAQList = [
  {
    question: 'What do you mean by daily payouts?',
    answer: `All the transactions in your account - like payments, fees, and refunds - will now be collated together 
      during the day and sent as a single payout to your bank account.`
  },
  {
    question: 'Ok, when will I receive these payouts?',
    answer: `Every payout will be sent securely to your bank account as a standard ACH. 
      It typically takes 2-3 days for an ACH to reach your bank account.`
  },
  {
    question: 'But does this affect how I take payments?',
    answer: 'Not at all. You can continue collecting payments as you do today.'
  },
  {
    question: 'Where will I see if my payments are successful?',
    answer: 'Every successful payment will be reflected in your Balance.'
  },
  {
    question: 'Can I opt out of batch payouts?',
    answer: `We're afraid not. By ${getFaqMigrationMonthAndYear()}, all our partners will start 
      receiving payouts in batches.`
  },
  {
    question: 'What if I want to change my bank account to receive payouts?',
    answer: 'You will be able to connect a different bank account after completing the security upgrades.'
  }
];

export const bankingUpdateFAQList = [
  {
    question: 'Why is this happening?',
    answer: `Based on feedback from our pilot program, we have decided to let all merchants receive 
            payouts directly in the bank of their choice. 
            You no longer need a ScanPay banking account to collect and receive payments.`
  },
  {
    question: 'Ok, do I have to do anything?',
    answer: `You just have to connect another bank account, 
            please follow the steps to confirm your details and link your bank account.`
  },
  {
    question: 'What about my existing ScanPay banking account?',
    answer: `Your ScanPay banking account and your debit cards will be closed on ${getFaqMigrationSafeDate()}.`
  },
  {
    question: 'What if I have balance in my ScanPay banking account?',
    answer: `We encourage you to transfer funds out to an external bank account before ${getFaqMigrationSafeDate()}. 
            When your account closes, any outstanding balance will be sent via paper check to the mailing 
            address we have on file.`
  },
  {
    question: 'Can I opt to just keep my ScanPay banking account?',
    answer: `We're afraid not. 
            By ${getFaqMigrationMonthAndYear()}, all open banking accounts will be automatically closed.`
  }
];
