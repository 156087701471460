import { CustomModal, Loader } from 'components';
import { WebviewFunctions } from 'constants/common';
import {
  useLazyGetMerchantTeamsQuery,
  useLazyGetPaymentSystemConfigQuery,
  useLazyGetTeamBankAccountQuery
} from 'containers/app/api';
import { selectCurrentTeam, selectPaymentSystemConfig } from 'containers/app/appSlice';
import { selectAuthState } from 'containers/auth/authSlice';
import { InternalBankStatuses, PaymentSystem } from 'containers/payouts/constants';
import { useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { LocalStorage } from 'services/storage';
import { VoidFn } from 'types/baseTypes';
import VerifyingState from './VerifyingState';

interface CollectBankStatementProps {
  onClose: VoidFn;
}

enum PAGE_STATES {
  IFRAME,
  VERIFYING
}

const CollectBankStatement: FC<CollectBankStatementProps> = ({ onClose }) => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const { paymentSystem } = useAppSelector(selectPaymentSystemConfig);
  const { idToken, refreshToken } = useAppSelector(selectAuthState) ?? {};
  const [getTeamBankAccountQuery] = useLazyGetTeamBankAccountQuery();
  const [getPaymentSystemConfigQuery] = useLazyGetPaymentSystemConfigQuery();
  const [getMerchantTeamsQuery, { isSuccess: merchantTeamsSuccess, isFetching: merchantTeamsFetching }] =
    useLazyGetMerchantTeamsQuery();

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [page, setPage] = useState<PAGE_STATES>(PAGE_STATES.IFRAME);

  const getFrameURL = () => {
    let url = process.env.REACT_APP_ONBOARDING_URL;
    const currentIdToken = idToken || LocalStorage.get('idToken');
    const currentRefreshToken = refreshToken || LocalStorage.get('refreshToken');
    const teamId = currentTeam?.id;
    // eslint-disable-next-line max-len
    url = `${url}/#/ca-bank-statements?isIframe=true&idToken=${currentIdToken}&refreshToken=${currentRefreshToken}&teamId=${teamId}&paymentSystem=${
      paymentSystem ?? PaymentSystem.CA
    }`;
    return url;
  };

  const handleIframeOnLoad = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (merchantTeamsSuccess && !merchantTeamsFetching) {
      if (currentTeam.internalBankingStatus === InternalBankStatuses.FINANCIAL_DATA_SUBMITTED) {
        setPage(PAGE_STATES.VERIFYING);
        setIsLoading(false);
      } else {
        handleOnDone();
      }
    }
  }, [merchantTeamsFetching, merchantTeamsSuccess]);

  useEffect(() => {
    const handleReadMessage = async event => {
      if (event.data === WebviewFunctions.WEBVIEW_LOADED) {
        setIsLoading(false);
      }
      if (event.data === WebviewFunctions.CLOSE_WEBVIEW) {
        onClose();
      }
      if (event.data === WebviewFunctions.UPDATE_BANK_STATUS) {
        setIsLoading(true);
        setPage(null);
        getMerchantTeamsQuery();
      }
    };

    // Add the event listener.
    window.addEventListener('message', handleReadMessage, false);
    return () => {
      window.removeEventListener('message', handleReadMessage, false);
    };
  }, []);

  const handleOnDone = async () => {
    getPaymentSystemConfigQuery(currentTeam.id);
    await getTeamBankAccountQuery(currentTeam.id);
    onClose();
  };

  return (
    <CustomModal open width="md:min-w-[360px]" height="min-h-[549px]" onOutsideClick={onClose} className="relative">
      {isLoading && (
        <>
          <div className="absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2">
            <Loader />
          </div>
        </>
      )}
      {page === PAGE_STATES.IFRAME && (
        <iframe src={getFrameURL()} width={360} height={549} onLoad={handleIframeOnLoad} />
      )}
      {page === PAGE_STATES.VERIFYING && (
        <>
          <VerifyingState onDone={handleOnDone} className="py-20" />
        </>
      )}
    </CustomModal>
  );
};

export default CollectBankStatement;
