import { ChevronArrowRightIcon, SubmitDetailsIcon } from 'assets/icons';
import { FC } from 'react';

const SubmitDetailsCard: FC = () => {
  return (
    <div className="flex w-full items-center rounded-md border-2 border-secondary bg-[#DBEFFD] p-[15px]">
      <SubmitDetailsIcon className="shrink-0" />
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-bold text-heading">Submit your details</div>
        <div className="text-sbase text-primaryText">
          Submit your KYC/KYB <br />
          details for review
        </div>
      </div>
      <ChevronArrowRightIcon className="ml-auto shrink-0" />
    </div>
  );
};

export default SubmitDetailsCard;
