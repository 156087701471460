import { TopBarIcon } from 'assets/icons';
import DefaultAppPage from 'components/defaultAppPage/DefaultAppPage';
import { ONE_LINK } from 'config/index';
import { NotifierTypes } from 'constants/notifierConstants';
import { showNotifier } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import routesPath from 'routes/RoutesPath';
import { appendCountryCode, isValidPhoneNumber } from 'utils/phoneNumberUtils';
import { useLazyGetDownloadLinkQuery, useSendDownloadLinkAsSMSMutation } from './api';
import { sendSMSPayload } from './auth.model';
import { selectDownloadLinkToken } from './authSlice';
import TestimonialSection from './components/TestimonialSection';
import { CampaignValues } from './constants';

const SignUpQR: FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [qrUrl, setQrUrl] = useState(ONE_LINK);

  const currentDownloadLinkToken = useAppSelector(selectDownloadLinkToken);

  const [sendDownloadLink, { isSuccess: isSMSSentSuccess }] = useSendDownloadLinkAsSMSMutation();
  const [
    getDownloadLink,
    { isSuccess: downloadLinkSuccess, data: downloadLinkData, isFetching: fetchingDownloadLink }
  ] = useLazyGetDownloadLinkQuery();

  useEffect(() => {
    getDownloadLink(currentDownloadLinkToken);
  }, [currentDownloadLinkToken]);

  useEffect(() => {
    if (isSMSSentSuccess) {
      setPhone('');
      dispatch(
        showNotifier({
          message: {
            primaryMessage: 'SMS successfully sent'
          },
          type: NotifierTypes.SUCCESS,
          showClose: true
        })
      );
    }
  }, [isSMSSentSuccess]);

  useEffect(() => {
    if (downloadLinkSuccess) {
      setQrUrl(downloadLinkData.appDownloadLink ?? ONE_LINK);
    }
  }, [downloadLinkSuccess]);

  const handleSignInClick = () => {
    navigate(routesPath.SIGNIN);
  };

  const handlePhoneChange = number => {
    setPhoneError('');
    if (!number) {
      setPhone('');
      return;
    }
    setPhone(number);
  };

  const handleOnDownloadClick = () => {
    if (!isValidPhoneNumber(phone)) {
      setPhoneError('Please enter valid phone number');
    } else {
      const phoneNumberWithCode = appendCountryCode(phone, '+1');
      const smsDownloadLinkParams: sendSMSPayload = {
        phoneNumber: phoneNumberWithCode,
        ...(currentDownloadLinkToken?.type === CampaignValues.RESELLER && {
          resellerToken: currentDownloadLinkToken.token
        }),
        ...(currentDownloadLinkToken?.type === CampaignValues.TEAM_INVITE && {
          teamInviteToken: currentDownloadLinkToken.token
        })
      };
      sendDownloadLink(smsDownloadLinkParams);
    }
  };

  return (
    <div className="flex h-screen w-screen flex-row">
      <div className="relative flex w-full flex-col bg-primary p-12 lg:w-1/2">
        <TopBarIcon className="shrink-0 sm:absolute" />
        <div className="m-auto flex w-full flex-col gap-3">
          <div className="mt-12 text-3xl font-bold text-primaryText">Sign Up</div>
          <div className="text-xl font-bold text-primaryText">
            Sign up is currently only available on the mobile app.
          </div>
          <DefaultAppPage
            userPhone={phone}
            handlePhoneNumberChange={handlePhoneChange}
            onDownloadClick={handleOnDownloadClick}
            phoneError={phoneError}
            downloadURL={qrUrl}
            isLoading={fetchingDownloadLink}
          />
          <div className="m-auto justify-self-center text-17px text-primaryText">
            Already have an account?{' '}
            <span id="signin-instead" className="cursor-pointer text-secondary" onClick={handleSignInClick}>
              Sign in, instead
            </span>
          </div>
        </div>
      </div>
      <div className="hidden w-1/2 bg-secondary lg:flex">
        <TestimonialSection />
      </div>
    </div>
  );
};

export default SignUpQR;
