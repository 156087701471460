import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

interface CustomCheckboxProps {
  isChecked: boolean;
  width?: string;
  height?: string;
  checkedStyle?: string;
  onClick: VoidFn;
  children: ReactNode;
  id: string;
  className?: string;
  disabled?: boolean;
}

const CustomCheckbox: FC<CustomCheckboxProps> = ({
  isChecked = false,
  checkedStyle = 'bg-primaryBtn',
  width = 'w-[1.125rem]',
  height = 'h-[1.125rem]',
  onClick,
  children,
  id,
  className,
  disabled = false
}) => {
  return (
    <div
      onClick={e => {
        e.stopPropagation();
        if (!disabled) {
          onClick();
        }
      }}
      id={id}
      className={twMerge(
        classnames('flex cursor-pointer items-center justify-center rounded border border-borderGray', {
          [width]: width,
          [height]: height,
          [checkedStyle]: isChecked
        }),
        className
      )}>
      {children}
    </div>
  );
};

export default CustomCheckbox;
