import React, { FC, useEffect } from 'react';
import { BlockedIcon, TopBarIcon } from 'assets/icons';
import { SUPPORT_MAIL } from 'config/index';
import TestimonialSection from 'containers/auth/components/TestimonialSection';
import { loggedOut } from 'containers/auth/authSlice';
import { useAppDispatch } from 'hooks';

const BlockedPage: FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loggedOut());
  }, []);

  return (
    <div className="flex w-screen">
      <div className="customNormalScroll h-screen w-full overflow-hidden sm:w-1/2">
        <div className="flex h-full flex-col">
          <TopBarIcon className="mt-12 ml-[75px] shrink-0" />
          <div
            className="mx-auto flex max-w-[375px] grow flex-col items-center justify-center
                       px-2.5 text-center sm:w-[375px]">
            <BlockedIcon className="mb-5 shrink-0" />
            <div className="mb-4 text-xl font-bold text-headingGray">Your account is temporarily on hold.</div>
            <div className="flex flex-col gap-[18px]">
              <div className="text-sbase text-primaryText">
                Our payment processor has put your account on hold due to high risk transactions associated with the
                account.
              </div>
              <div className="text-sbase text-primaryText">
                To re-activate your account, please contact our support team and start accepting payments again.
              </div>
              <div className="text-sbase text-primaryText">
                In case the account is not re-activated within 5 days, all pending payouts will be refunded back to your
                customer's card
              </div>
            </div>
            <div className="mt-[120px] text-sbase text-primaryText">
              For further queries, please contact us at{' '}
              <a className="cursor-pointer" href={`mailto:${SUPPORT_MAIL}`}>
                {SUPPORT_MAIL}
              </a>
            </div>
          </div>
        </div>
      </div>

      <div className="hidden h-screen w-1/2 sm:flex">
        <TestimonialSection />
      </div>
    </div>
  );
};

export default BlockedPage;
