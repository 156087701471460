import { ArrowRightIcon, ExportIcon, NavCloseIcon } from 'assets/icons';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { GenericError, If } from 'components';
import { FC, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { getInitials } from 'utils/commonUtils';
import { ReceiptStatus } from '../receipts.model';
import { getPaymentData, getReceiptStatus } from '../util';
import DataListItem from './DataListComponent';
import { useLazyGetReceiptByIdQuery } from '../api';
import { useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import SidePanel from 'components/side-panel/SidePanel';
import { LoadingSpinnerAnimation } from 'assets/animations';
import Lottie from 'lottie-react';
import { SidePanelRouteState } from 'routes/types';
import { DEFAULT_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';
import { useAppSelector } from 'hooks';
import { selectCurrentTeam } from 'containers/app/appSlice';
import { FROM_SIDE_PANEL_ROUTE_STATE } from 'routes/constants';

interface RefundReceiptPanelProps {
  onClose?: VoidFn;
}

const RefundReceiptPanel: FC<RefundReceiptPanelProps> = () => {
  const [getReceiptByIdQuery, getReceiptByIdState] = useLazyGetReceiptByIdQuery();
  const { isSuccess: receiptDataSuccess, isFetching: fetchingReceiptData, data: receiptData } = getReceiptByIdState;

  const [isRootState, setIsRootState] = useState(true);
  const currentTeam = useAppSelector(selectCurrentTeam);

  const { customerName, invoiceNumber, paymentMethod, createdBy, profilePicture, refundTransaction } =
    receiptData ?? {};
  const { ID, parentId, date, amount, status, reason } = refundTransaction ?? {};

  const { rootReceiptId, receiptId, rootInvoiceId, rootPage, rootCustomerId, rootDisputeId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { handleSidePanelClose } = useOutletContext<any>();

  useEffect(() => {
    const routeState: SidePanelRouteState = location?.state ?? {};
    if (routeState?.fromSidePanel) {
      setIsRootState(false);
    }
    let routeStateTimeout;
    if (routeState?.shouldAnimate) {
      routeStateTimeout = setTimeout(() => {
        navigate(location.pathname, { replace: true, state: DEFAULT_SIDE_PANEL_ROUTE_STATE });
      }, 800);
    }
    return () => {
      clearTimeout(routeStateTimeout);
    };
  }, [location]);

  useEffect(() => {
    if (rootReceiptId || receiptId) {
      getReceiptByIdQuery({ receiptId: receiptId ? receiptId : rootReceiptId, teamId: currentTeam.id });
    }
  }, [rootReceiptId]);

  const handleSidePanelBack = () => {
    if (isRootState) {
      handleSidePanelClose();
    } else {
      navigate(-1);
    }
  };

  const handleRefundReceiptShare = () => {
    if (rootReceiptId) {
      navigate(`/payments/receipts/${currentTeam?.id}/${rootReceiptId}/share-refund-receipt/${ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootInvoiceId) {
      navigate(`/invoices/${currentTeam?.id}/${rootInvoiceId}/share-refund-receipt/${ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootPage) {
      navigate(`/payouts/${rootPage}/${currentTeam?.id}/${rootPage}/share-refund-receipt/${ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootCustomerId) {
      navigate(`/customers/${currentTeam?.id}/${rootCustomerId}/share-refund-receipt/${ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    } else if (rootDisputeId) {
      navigate(`/payments/disputes/${currentTeam?.id}/${rootDisputeId}/share-refund-receipt/${ID}`, {
        state: FROM_SIDE_PANEL_ROUTE_STATE
      });
    }
  };

  return (
    <SidePanel isOpen={true} onClose={handleSidePanelClose} shouldAnimate={location?.state?.shouldAnimate}>
      <div className="h-full w-96">
        <>
          {fetchingReceiptData && (
            <div className="flex h-full w-full items-center justify-center">
              <Lottie className="h-28 w-28" animationData={LoadingSpinnerAnimation} loop={true} />
            </div>
          )}
          {receiptDataSuccess && !fetchingReceiptData && (
            <div className="flex h-full w-full flex-col">
              <div className="flex items-center gap-2 py-5 px-4">
                <div onClick={handleSidePanelBack} className="cursor-pointer">
                  {isRootState ? (
                    <NavCloseIcon id="refund-receipt-panel-close" />
                  ) : (
                    <ArrowRightIcon className="rotate-180" id="refund-receipt-panel-back-arrow" />
                  )}
                </div>
                <div id="refund-receipt-panel-head" className="text-17px font-semibold text-headingGray">
                  Receipt - {customerName ? customerName : invoiceNumber}
                </div>
              </div>
              <div className="m-4 rounded-md border border-dark-gray bg-primary">
                <div className="flex flex-col items-center justify-center gap-2 border-b border-secondaryBtn pb-8">
                  <div className="pt-8 text-center">
                    <div className="text-sbase font-semibold text-accentText">Amount</div>
                    <div
                      id="refund-receipt-panel-total-amount"
                      className="mt-1 text-[30px] font-bold text-tertiaryText">
                      {getAmountWithCurrency(amount)}
                    </div>
                    <div
                      id="refund-receipt-panel-payment-status"
                      className="mt-2 rounded-md px-6 py-1 text-px13 text-tertiaryText"
                      style={{ backgroundColor: '#A4D7FA' }}>
                      {getReceiptStatus(status)}
                    </div>
                  </div>
                  <If condition={Boolean(status === ReceiptStatus.REFUND_INITIATED)}>
                    <div className="my-2 mx-3 rounded-md bg-secondaryBtn p-2 text-primaryText">
                      Refunds may take 5-10 business days to credit back to the original payment method.
                    </div>
                  </If>
                </div>
                <div className="flex w-full flex-col gap-4 border-b p-4">
                  <DataListItem title="Transaction ID" data={ID} />
                  <DataListItem title="Refund Date" data={date} />
                  <DataListItem title="Refund Amount" data={getAmountWithCurrency(amount)} />
                  <DataListItem title="Original Transaction" data={parentId} />
                  <DataListItem title="Customer" data={customerName} />
                  <DataListItem
                    title="Payment Method"
                    data={getPaymentData(receiptData)}
                    itemTitleStyle="shrink-0"
                    containerStyle="items-start"
                  />
                  <DataListItem
                    title="Created by"
                    data={
                      <div className="flex items-center gap-1">
                        <CustomAvatar
                          src={profilePicture}
                          width="w-4"
                          height="h-4"
                          fontStyle="text-[8px] text-tertiaryText">
                          {getInitials(createdBy)}
                        </CustomAvatar>
                        <div className="truncate">{createdBy}</div>
                      </div>
                    }
                  />
                </div>
                <If condition={Boolean(status === ReceiptStatus.REFUND_INITIATED)}>
                  <div className="pb-6 text-primaryText">
                    <div className="mx-4 mt-4 text-[15px] font-semibold">Reason for refund</div>
                    <div className="my-3 mx-4 text-px13">{reason}</div>
                  </div>
                </If>
              </div>
              <div className="mt-auto flex justify-between gap-px justify-self-end">
                <div
                  id="refund-receipt-side-panel-share-receipt-button"
                  onClick={handleRefundReceiptShare}
                  className="flex shrink grow cursor-pointer items-center justify-center
                       gap-2 bg-secondary py-3.5 font-semibold text-primary hover:opacity-95">
                  <div className="path-fill-current path">
                    <ExportIcon className="h-5" />
                  </div>
                  <div className="text-sbase">Share Receipt</div>
                </div>
              </div>
            </div>
          )}
          {!receiptDataSuccess && !fetchingReceiptData && <GenericError handleErrorBack={handleSidePanelClose} />}
        </>
      </div>
    </SidePanel>
  );
};

export default RefundReceiptPanel;
