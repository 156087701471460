import classnames from 'classnames';
import { nullFn } from 'constants/common';
import { FC, useEffect, useRef } from 'react';
import { twMerge } from 'tailwind-merge';

interface CustomTextAreaProps {
  id: string;
  type?: string;
  placeholder?: string;
  className?: string;
  value: string;
  name: string;
  onChange: any;
  maxLength?: number;
  resizeTextArea?: boolean;
  width?: string;
  isInputValid?: boolean;
  warningMessageText?: string;
  disabled?: boolean;
  onClick?: () => void;
  minHeight?: string;
  maxHeight?: number;
  onFocus?: () => void;
}

const CustomTextArea: FC<CustomTextAreaProps> = ({
  resizeTextArea = false,
  id,
  maxLength,
  onChange,
  className,
  placeholder,
  name,
  value,
  width = null,
  isInputValid = true,
  warningMessageText = '',
  disabled = false,
  onClick = null,
  minHeight = '50px',
  maxHeight = 100,
  onFocus = nullFn
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if (resizeTextArea) {
      if (value?.length < 1) {
        textAreaRef.current.style.height = minHeight;
      } else {
        if (textAreaRef.current.scrollHeight <= maxHeight) {
          textAreaRef.current.style.height = `${textAreaRef.current.scrollHeight}px`;
        }
      }
    }
  }, [value]);

  const handleChange = e => {
    onChange(e.target.value);
  };

  const widthStyles = width ? { width } : {};
  return (
    <div
      className={'flex flex-col'}
      onClick={() => {
        if (disabled && onClick) {
          onClick();
        }
      }}>
      <textarea
        id={id}
        ref={textAreaRef}
        maxLength={maxLength}
        name={name}
        value={value}
        onChange={handleChange}
        placeholder={placeholder}
        disabled={disabled}
        className={twMerge(
          classnames(
            `customNormalScroll h-[50px] max-h-[100px]  rounded bg-secondaryBtn ${
              !isInputValid ? 'ring-2 ring-red-500 focus:ring-2 focus:ring-red-500' : ''
            }
            p-4 text-[15px] font-semibold ${disabled ? 'text-accentText' : 'text-primaryText'}
             placeholder:text-[15px] placeholder:font-normal placeholder:text-accentText`
          ),
          className
        )}
        style={{ ...widthStyles }}
        onFocus={onFocus}
      />
    </div>
  );
};

export default CustomTextArea;
