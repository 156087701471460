import { FormEditIcon } from 'assets/icons';
import FormCloseReactIcon from 'assets/react-icons/FormCloseReactIcon';
import LineItemPlusReactIcon from 'assets/react-icons/LineItemPlusReactIcon';
import { IInvoiceServiceCharge } from 'containers/invoices/invoices.model';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';
import { getAmountWithCurrency } from 'utils/amountUtils';

interface AddServiceChargeSectionProps {
  className?: string;
  selectedServiceCharge?: IInvoiceServiceCharge;
  isAddServiceChargeDisabled?: boolean;
  onAddServiceCharge: VoidFn;
  onRemoveServiceCharge: VoidFn;
  onEditServiceCharge: VoidFn;
}

const AddServiceChargeSection: FC<AddServiceChargeSectionProps> = ({
  onAddServiceCharge,
  onRemoveServiceCharge,
  onEditServiceCharge,
  selectedServiceCharge,
  isAddServiceChargeDisabled = false,
  className = ''
}) => {
  const hasSelectedServiceCharge: boolean = +selectedServiceCharge.amount > 0 || +selectedServiceCharge.percentage > 0;

  const getServiceChargeTotalAmountInCurrency = (serviceCharge: IInvoiceServiceCharge): string => {
    return getAmountWithCurrency(serviceCharge.totalAmount);
  };

  const onAddServiceChargeClick = (): void => {
    if (isAddServiceChargeDisabled) {
      return;
    }
    onAddServiceCharge();
  };

  return (
    <div
      id="invoice-add-service-charge"
      className={twMerge(
        `
         border-b border-borderGray
        `,
        className
      )}>
      {hasSelectedServiceCharge ? (
        <div className="relative flex min-h-[75px] w-full flex-row  p-5">
          <div className="my-auto flex w-full flex-row">
            <div
              onClick={onEditServiceCharge}
              className="h-fit w-1/2 cursor-pointer break-words text-17px font-semibold text-secondary">
              {selectedServiceCharge.name}
            </div>
            <div className="h-fit w-1/2 break-words text-right text-17px font-normal text-heading">
              {getServiceChargeTotalAmountInCurrency(selectedServiceCharge)}
            </div>
          </div>

          <FormCloseReactIcon
            id="invoice-service-charge-remove-icon"
            onClick={onRemoveServiceCharge}
            className="absolute  top-3 right-[-25px] shrink-0 cursor-pointer 1439px:right-[-30px]"
          />
          <FormEditIcon
            id="invoice-service-charge-edit-icon"
            onClick={onEditServiceCharge}
            className="absolute bottom-3 right-[-25px] shrink-0 cursor-pointer 1439px:right-[-30px]"
          />
        </div>
      ) : (
        <div
          className={`${
            isAddServiceChargeDisabled ? 'cursor-not-allowed opacity-80' : 'cursor-pointer'
          } flex  items-center gap-2 p-5 text-17px font-semibold text-secondary`}
          onClick={onAddServiceChargeClick}
          id="create-invoice-add-additional-tax-fees-icon">
          <LineItemPlusReactIcon id="create-invoice-add-additional-tax-fees-icon" className="shrink-0" />
          Add service charge
        </div>
      )}
    </div>
  );
};

export default AddServiceChargeSection;
