import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { LocalStorage } from 'services/storage';
import { RootState } from 'store/reducers';
import { isEqualWithStringify } from 'utils/commonUtils';
import {
  Banner,
  MerchantTeam,
  PaymentSystemConfigResponse,
  TeamBankAccountDetails,
  TeamBanners,
  TeamRole,
  ToastNotification,
  UserDetails,
  VerificationStatusEnum
} from './app.model';
import { getPermissions } from './util';
import { isTablet } from 'react-device-detect';
import { LocalStorageKeysEnum } from 'constants/common';

interface AppState {
  merchantTeams: MerchantTeam[];
  currentTeam: MerchantTeam;
  userDetails: UserDetails;
  isLoadingUserDetailsSlice: boolean;
  isMenuActive: boolean;
  isHoverActive: boolean;
  toastNotifications: ToastNotification[];
  isChatIconVisible: boolean;
  paymentSystemConfig: PaymentSystemConfigResponse;
  teamBankAccountDetails: TeamBankAccountDetails;
  homeBanners: Banner[];
  payoutBanners: Banner[];
}

const initialCurrentTeam: MerchantTeam = {
  id: null,
  name: null,
  role: null,
  profileImageUrl: null,
  maximumAmount: null,
  minimumAmount: null,
  isOnboardingCompleted: null,
  isUnitEnabled: null,
  unitProvisionStatus: null,
  isAutoPayoutEnabled: null,
  personaEnquiryStatus: null,
  isBankAdded: null,
  permission: getPermissions(TeamRole.MEMBER),
  storeName: null,
  profession: null,
  integrationsEnabled: false,
  quickBooksIntegrationEnabled: false,
  connectIntegrationsEnabled: false,
  connectApplicationStatus: null,
  connectBankingStatus: null,
  internalBankingStatus: null,
  paymentSystemTransitionType: null,
  teamMerchantName: ''
};

const initialUserDetailsState: UserDetails = {
  id: '',
  name: '',
  email: '',
  isMerchantBlocked: false,
  phone: '',
  earnedCredits: '',
  remainingCredits: '',
  isCustomerChampion: false,
  isSalesFunnelMerchant: false,
  deviceInfoRequired: false,
  preCreditFeature: false,
  defaultLandingPage: '',
  referAndEarnEnabled: false,
  isDeviceAuthenticated: false,
  isPasswordCreated: false,
  isPasswordActive: false,
  isEmailVerified: false,
  isEmailAdded: false,
  showEmailPrompt: false,
  showVerificationPrompt: false,
  skipIamFlow: false,
  skippedAuthentication: false,
  verificationStatus: VerificationStatusEnum.NotVerified,
  brbClosureDate: 'May 3rd, 2024',
  deviceToken: '',
  faqMigrationMonthAndYear: 'May 2024',
  faqMigrationSafeDate: 'April 30th, 2024'
};

const initialPaymentSystemConfig: PaymentSystemConfigResponse = {
  paymentSystem: null,
  payoutType: null,
  payoutSchedule: null,
  bankingEnabled: false
};

export const initialTeamBankDetails: TeamBankAccountDetails = {
  accountNumber: '',
  bankIcon: '',
  bankName: '',
  isScanpayAccount: false,
  routingNumber: '',
  paymentMethod: ''
};

export const initialBannerState: TeamBanners = {
  homeBanners: [],
  payoutBanners: []
};

const initialState = {
  merchantTeams: [],
  currentTeam: initialCurrentTeam,
  isLoadingUserDetailsSlice: false,
  userDetails: LocalStorage.get(LocalStorageKeysEnum.USER_DETAILS) ?? initialUserDetailsState,
  isMenuActive: !isTablet,
  isHoverActive: false,
  toastNotifications: [],
  isChatIconVisible: true,
  paymentSystemConfig: initialPaymentSystemConfig,
  teamBankAccountDetails: initialTeamBankDetails,
  ...initialBannerState
} as AppState;

const defaultState = {
  merchantTeams: [],
  currentTeam: initialCurrentTeam,
  isLoadingUserDetailsSlice: false,
  userDetails: initialUserDetailsState,
  isMenuActive: !isTablet,
  isHoverActive: false,
  toastNotifications: [],
  isChatIconVisible: true,
  paymentSystemConfig: initialPaymentSystemConfig,
  teamBankAccountDetails: initialTeamBankDetails,
  ...initialBannerState
} as AppState;

const getTeamsWithUpdatedPermissions = (teams: MerchantTeam[]) => {
  const teamsWithPermissions = teams?.map(team => {
    if (team.role !== TeamRole.CUSTOM) {
      return {
        ...team,
        permission: getPermissions(team.role)
      };
    }
    return team;
  });

  return teamsWithPermissions;
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    resetAppState: () => defaultState,
    setMerchantTeams: (state, { payload }: PayloadAction<MerchantTeam[]>) => {
      const teamsWithPermissions = getTeamsWithUpdatedPermissions(payload);
      state.merchantTeams = teamsWithPermissions;
      const curTeamInStorage = LocalStorage.get('currentTeam') as MerchantTeam;
      const curTeamFromPayload = teamsWithPermissions.find(team => team.id === curTeamInStorage?.id);
      const preferedTeam = curTeamFromPayload ?? teamsWithPermissions[0];
      LocalStorage.set('currentTeam', preferedTeam);
      const curTeamInState = current(state.currentTeam);
      if (
        !isEqualWithStringify(curTeamInState.permission, preferedTeam.permission) ||
        curTeamInState.id === null ||
        curTeamInState.unitProvisionStatus !== preferedTeam.unitProvisionStatus ||
        !isEqualWithStringify(curTeamInState, preferedTeam)
      ) {
        state.currentTeam = preferedTeam;
      }
    },

    setCurrentTeam: (state, { payload }: PayloadAction<MerchantTeam>) => {
      LocalStorage.set('currentTeam', payload);
      state.currentTeam = payload;
    },

    setCurrentTeamAfterInviteAccept: (state, { payload }: PayloadAction<number>) => {
      const preferredTeam = state.merchantTeams.find(team => (team.id = payload));
      LocalStorage.set('currentTeam', preferredTeam);
      state.currentTeam = preferredTeam;
    },

    setUserDetails: (state, { payload }: PayloadAction<UserDetails>) => {
      state.userDetails = payload;
      state.isLoadingUserDetailsSlice = false;

      LocalStorage.set(LocalStorageKeysEnum.USER_DETAILS, payload);
    },

    setPaymentSystemConfig: (state, { payload }: PayloadAction<PaymentSystemConfigResponse>) => {
      state.paymentSystemConfig = payload;
    },

    updateUserDetailsEmail: (state, { payload }: PayloadAction<string>) => {
      state.userDetails.email = payload;
    },

    updateIsDeviceAuthenticated: (state, { payload }: PayloadAction<boolean>) => {
      state.userDetails.isDeviceAuthenticated = payload;
      LocalStorage.set(LocalStorageKeysEnum.USER_DETAILS, { ...state.userDetails, isDeviceAuthenticated: payload });
    },

    stopIsLoadingUserDetails: state => {
      state.isLoadingUserDetailsSlice = false;
    },

    setInitiateSignInUserFlags: (
      state,
      { payload }: PayloadAction<{ isEmailVerified?: boolean; isPasswordCreated?: boolean }>
    ) => {
      state.userDetails.isEmailVerified = payload.isEmailVerified ?? payload.isEmailVerified;
      state.userDetails.isPasswordCreated = payload.isPasswordCreated ?? payload.isPasswordCreated;
    },

    updateEmailAndVerificationStatus: (
      state,
      { payload }: PayloadAction<{ isEmailVerified: boolean; email?: string }>
    ) => {
      state.userDetails.isEmailVerified = payload.isEmailVerified;
      state.userDetails.email = payload.email ? payload.email : state.userDetails.email;
    },

    toggleMenuState: state => {
      state.isMenuActive = !state.isMenuActive;
    },

    toggleHoverState: state => {
      state.isHoverActive = !state.isHoverActive;
    },

    showNotifier: (state, { payload }: PayloadAction<ToastNotification>) => {
      state.toastNotifications = [...state.toastNotifications, { id: Math.random().toString(), ...payload }];
    },

    hideNotifier: (state, { payload }: PayloadAction<string>) => {
      const notificationsList = state.toastNotifications.filter(notif => notif.id !== payload);
      state.toastNotifications = [...notificationsList];
    },
    disableHoverState: state => {
      state.isHoverActive = false;
    },
    toggleChatIcon: (state, { payload }: PayloadAction<boolean>) => {
      state.isChatIconVisible = payload;
    },
    updateBannersData: (state, { payload }: PayloadAction<TeamBanners>) => {
      state.homeBanners = payload.homeBanners;
      state.payoutBanners = payload.payoutBanners;
    },
    updateTeamProfile: (
      state,
      {
        payload
      }: PayloadAction<{
        teamId: number;
        profileImageUrl: string;
        name: string;
        profession: string;
      }>
    ) => {
      const { teamId, profileImageUrl, name, profession } = payload;
      return {
        ...state,
        merchantTeams: state.merchantTeams.map(team => {
          if (team.id === teamId) {
            return {
              ...team,
              profileImageUrl,
              name,
              profession
            };
          }
          return team;
        }),
        currentTeam: { ...state.currentTeam, name, profileImageUrl, profession }
      };
    },

    setTeamBankDetails: (state, { payload }: PayloadAction<TeamBankAccountDetails>) => {
      state.teamBankAccountDetails = { ...payload };
    }
  }
});

export const {
  updateUserDetailsEmail,
  updateIsDeviceAuthenticated,
  resetAppState,
  toggleHoverState,
  toggleMenuState,
  setUserDetails,
  setPaymentSystemConfig,
  setInitiateSignInUserFlags,
  setMerchantTeams,
  setCurrentTeam,
  showNotifier,
  hideNotifier,
  disableHoverState,
  toggleChatIcon,
  stopIsLoadingUserDetails,
  updateEmailAndVerificationStatus,
  updateTeamProfile,
  setCurrentTeamAfterInviteAccept,
  setTeamBankDetails,
  updateBannersData
} = appSlice.actions;

export const selectMerchantTeams = (state: RootState) => state.rootReducer.app.merchantTeams;

export const selectCurrentTeam = (state: RootState) => state.rootReducer.app.currentTeam;

export const selectPaymentSystemConfig = (state: RootState) => state.rootReducer.app.paymentSystemConfig;

export const selectCurrentTeamPermission = (state: RootState) => state.rootReducer.app.currentTeam.permission;

export const selectCurrentTeamBankingPermissions = (state: RootState) =>
  state.rootReducer.app.currentTeam.permission?.permissions?.banking?.bankingPermissionList;

export const selectCurrentTeamCustomersPermissions = (state: RootState) =>
  state.rootReducer.app.currentTeam.permission?.permissions?.customers?.customersPermissionList;

export const selectCurrentTeamFeePermissions = (state: RootState) =>
  state.rootReducer.app.currentTeam.permission?.permissions?.fees?.feesPermissionList;

export const selectCurrentTeamInvoicePermissions = (state: RootState) =>
  state.rootReducer.app.currentTeam.permission?.permissions?.invoice?.invoicePermissionList;

export const selectCurrentTeamRefundPermissions = (state: RootState) =>
  state.rootReducer.app.currentTeam.permission?.permissions?.refund?.refundPermissionList;

export const selectCurrentTeamTeamSettingsPermissions = (state: RootState) =>
  state.rootReducer.app.currentTeam.permission?.permissions?.teamSettings?.teamSettingPermissionList;

export const selectUserDetails = (state: RootState) => state.rootReducer.app.userDetails;

export const selectMenuState = (state: RootState) => state.rootReducer.app.isMenuActive;

export const selectHoverState = (state: RootState) => state.rootReducer.app.isHoverActive;

export const selectIsLoadingUserDetailsSlice = (state: RootState) => state.rootReducer.app.isLoadingUserDetailsSlice;

export const getNotifications = (state: RootState) => state.rootReducer.app.toastNotifications;

export const selectChatIconState = (state: RootState) => state.rootReducer.app.isChatIconVisible;

export const selectTeamBankDetails = (state: RootState) => state.rootReducer.app.teamBankAccountDetails;

export const selectPayoutBanners = (state: RootState) => state.rootReducer.app.payoutBanners;

export const selectHomeBanners = (state: RootState) => state.rootReducer.app.homeBanners;

export default appSlice.reducer;
