/* eslint-disable react/no-multi-comp */
import { CircleCloseIcon, ExternalLinkIcon, SignatureIcon, WhatsNewIcon } from 'assets/icons';
import { CustomButton } from 'components';
import { ButtonType } from 'components/custom-button/CustomButton';
import CustomModal from 'components/custom-modal/CustomModal';
import { CUSTOMER_SIGNATURE_BLOG_POST } from 'config/index';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { openInNewTab } from 'utils/commonUtils';

interface WhatsNewModalProps {
  config: IWhatsNewConfig;
  onPrimaryBtnClick: VoidFn;
  onClose: VoidFn;
  className?: string;
}

export interface IWhatsNewConfig {
  open: boolean;
}
interface INewFeatureInfo {
  type: NewFeatureListEnum;
  title: string;
  description: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string }>;
}

enum NewFeatureListEnum {
  CUSTOMER_SIGNATURE
}

const newFeatureInfoList: INewFeatureInfo[] = [
  {
    type: NewFeatureListEnum.CUSTOMER_SIGNATURE,
    title: 'Customer signature',
    description: `A customer's signature on an invoice confirms receipt of goods or services and 
    serves as legal documentation in case of disputes.It also establishes trust and agreement on terms, 
    enhancing professionalism in the transaction process.`,
    icon: SignatureIcon
  }
];

interface FeatureInfoItemProps {
  featureInfo: INewFeatureInfo;
  className?: string;
}

const FeatureInfoItem: FC<FeatureInfoItemProps> = ({ featureInfo, className = '' }) => {
  return (
    <div className={`${className} flex`}>
      <featureInfo.icon className="mr-5 shrink-0" />
      <div className="flex flex-col">
        <div className="mb-1 text-17px font-semibold text-heading">{featureInfo.title}</div>
        <div className="text-sbase font-normal text-primaryText">{featureInfo.description}</div>
        <div></div>
      </div>
    </div>
  );
};

const WhatsNewModal: FC<WhatsNewModalProps> = ({ onPrimaryBtnClick, config, onClose = undefined, className = '' }) => {
  return (
    <div className={`${className}`}>
      <CustomModal
        enableOutsideClick={false}
        closeModal={onClose && onClose}
        open={config.open}
        backgroundColor="bg-primary"
        height="max-h-[590px]"
        width="w-[395px] max-w-[395px] w-[90%] md:w-[395px]"
        className="overflow-auto">
        <div className="flex h-full w-full grow flex-col">
          <CircleCloseIcon className="absolute top-2 right-2 z-[1] shrink-0 cursor-pointer" onClick={onClose} />
          <div className="customTransparentScroll flex h-full w-full flex-col overflow-auto py-[50px] px-10">
            <WhatsNewIcon className="mx-auto mb-4 shrink-0" />
            <div className="mx-auto mb-10 text-xl font-bold text-heading">What's New</div>
            <div className="flex flex-col space-y-4">
              {newFeatureInfoList.map(featureInfo => (
                <FeatureInfoItem featureInfo={featureInfo} key={featureInfo.type} />
              ))}
            </div>
            <div
              onClick={() => openInNewTab(CUSTOMER_SIGNATURE_BLOG_POST)}
              className="mx-auto mt-8 flex cursor-pointer items-center">
              <span className="text-sbase font-semibold text-accentText">Read the&nbsp;</span>
              <span className="mr-2 text-sbase font-semibold text-secondary">blog post</span>
              <ExternalLinkIcon className="shrink-0" />
            </div>
            <CustomButton
              className="mt-6 h-[38px] w-full shrink-0"
              id="whats-new-primary-btn"
              type={ButtonType.PRIMARY}
              onClick={onPrimaryBtnClick}>
              Got it
            </CustomButton>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default WhatsNewModal;
