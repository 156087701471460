import Divider from '@material-ui/core/Divider';
import { FC, useState } from 'react';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { IPaginatedItem } from '../items.model';
import { ListActionsIcon, VerticalListActionsIcon } from 'assets/icons';
import classnames from 'classnames';
import { CustomPopover } from 'components';
import { twMerge } from 'tailwind-merge';
import ListItemActionsMenu from './ListItemsActionsMenu';
import { ItemActionType } from '../types';

interface ItemsTableProps {
  itemList: IPaginatedItem[];
  handleMoreActionsClick: ({ action, item }: { action: ItemActionType; item: IPaginatedItem }) => void;
}

const tableHeaders: Record<string, Record<string, string>> = {
  name: { title: 'Name', width: 'w-1/3' },
  category: { title: 'Category', width: 'w-1/6' },
  unitPrice: { title: 'Unit Price', width: 'w-1/6' },
  sku: { title: 'SKU', width: 'w-1/6' },
  popover: { title: '', width: 'w-[3%]' }
};

const ItemsTable: FC<ItemsTableProps> = ({ itemList, handleMoreActionsClick }) => {
  const [currentActionsPopoverId, setCurrentActionsPopoverId] = useState<number>(null);

  // eslint-disable-next-line react/no-multi-comp
  const ListActionsAnchor: FC<{ isPanelOpen: boolean }> = ({ isPanelOpen }) => {
    return (
      <div id="items-table-more-options" className="cursor-pointer py-2">
        <ListActionsIcon
          className={twMerge(
            'hidden shrink-0 lg:flex cursor-pointer',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
        <VerticalListActionsIcon
          className={twMerge(
            'mx-2 flex shrink-0 lg:hidden',
            classnames({
              'fill-current text-secondary': isPanelOpen
            })
          )}
        />
      </div>
    );
  };

  const isListItemActionsVisible = (itemId: number) => {
    return itemId === currentActionsPopoverId;
  };

  const onCloseListItemActionsPopover = (itemId: number) => {
    if (currentActionsPopoverId === itemId) {
      setCurrentActionsPopoverId(null);
    }
  };

  const onToggleListItemActions = (item: IPaginatedItem) => {
    if (item.id === currentActionsPopoverId) {
      setCurrentActionsPopoverId(null);
    } else {
      setCurrentActionsPopoverId(item.id);
    }
  };

  const getCategoryTaxesText = (item: IPaginatedItem) => {
    if (item?.taxCount === 1) return '+1 tax';
    return `+${item?.taxCount} taxes`;
  };

  return (
    <table className="relative w-full table-auto">
      <thead className="sticky top-[-4px] z-30 bg-primary">
        {itemList?.length != 0 && (
          <>
            <Divider className="absolute top-[3.7rem] w-full bg-borderGray" />
            <tr className="text-slateGrey text-left text-xs lg:text-base [&>*]:py-5">
              {Object.values(tableHeaders).map((header, index) => {
                return (
                  <th
                    id={`item-table-${header.title}-head`}
                    className={`max-w-[120px] text-sbase font-semibold text-primaryText ${header.width}`}
                    key={header.title + index}>
                    {header.title}
                  </th>
                );
              })}
            </tr>
          </>
        )}
      </thead>
      <tbody>
        {itemList?.map((item, index) => (
          <tr
            key={item.id + index}
            id={`item-table-row-${index + 1}`}
            className={'remove-highlight border-b border-secondaryBtn [&>*]:py-5 [&>*]:pr-2'}>
            {/* name and description */}
            <td className="max-w-[120px]">
              <div id={`item-table-row-${index + 1}-name-desc`} className="flex flex-col gap-2">
                <div
                  id={`item-table-row-${index + 1}-name`}
                  className="break-words text-sbase font-semibold text-primaryText line-clamp-2">
                  <div>{item.name}</div>
                </div>
                <div
                  id={`item-table-row-${index + 1}-desc`}
                  className="whitespace-pre-line break-words text-sbase text-primaryText line-clamp-3">
                  <div>{item.description}</div>
                </div>
              </div>
            </td>
            {/*item category*/}
            <td className="max-w-[120px]">
              <div id={`item-table-row-${index + 1}-name-desc`} className="flex flex-col gap-2">
                <div className="text-sbase font-semibold text-primaryText">
                  <div className="truncate">{item.category ? item.category : '-'}</div>
                </div>
              </div>
            </td>
            {/* unit price */}
            <td className="max-w-[120px]">
              <div id={`item-table-row-${index + 1}-unit-price`} className="flex flex-col gap-2">
                <div className=" break-words text-sbase text-primaryText">{getAmountWithCurrency(item.unitPrice)}</div>
                <div className="text-sbase text-primaryText">
                  <div className="truncate">{getCategoryTaxesText(item)}</div>
                </div>
              </div>
            </td>
            {/* sku */}
            <td className="max-w-[120px]">
              <div className="truncate text-sbase text-primaryText">{item.sku ? item.sku : '-'}</div>
            </td>
            <td
              onClick={e => {
                e.stopPropagation();
              }}>
              <div>
                <CustomPopover
                  showArrow={true}
                  minWidth="min-w-[113px]"
                  arrowOffset="right-6"
                  highlightAnchor={false}
                  anchorComponent={ListActionsAnchor}
                  zIndex="z-[101]"
                  offset="-right-6"
                  margin="mt-1"
                  onClosePopover={() => onCloseListItemActionsPopover(item.id)}
                  show={isListItemActionsVisible(item.id)}
                  onTogglePopover={() => onToggleListItemActions(item)}>
                  {
                    <ListItemActionsMenu
                      item={item}
                      handleMoreActionsClick={handleMoreActionsClick}
                      isModifiable={true}
                      onCloseListItemActionsPopover={onCloseListItemActionsPopover}
                    />
                  }
                </CustomPopover>
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default ItemsTable;
