import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SidePanelTypes } from 'constants/sidePanelConstants';
import { RootState } from 'store/reducers';
import { InvoiceByIdResponse } from '../invoices/invoices.model';

export interface SidePanelStack {
  panelType: SidePanelTypes;
  id: string | number;
  customerUpdateCallback?: () => void;
}

export interface SidePanelState {
  invoiceByIdData: InvoiceByIdResponse;
  sidePanelStack: SidePanelStack[];
  paymentStatus: string;
  shouldReloadList: boolean;
}

const initialState = {
  invoiceByIdData: {},
  sidePanelStack: [],
  paymentStatus: '',
  shouldReloadList: false
} as SidePanelState;

export const sidePanelSlice = createSlice({
  name: 'sidePanel',
  initialState,
  reducers: {
    updateSidePanelStack: (state, { payload }: PayloadAction<SidePanelStack>) => {
      state.sidePanelStack = [...state.sidePanelStack, payload];
    },

    popSidePanelStack: state => {
      const panelStack: SidePanelStack[] = state.sidePanelStack;
      if (panelStack.length > 0) {
        panelStack.pop();
      }
      state.sidePanelStack = panelStack;
    },

    emptySidePanelStack: state => {
      state.sidePanelStack = [];
    },

    updateInvoiceData: (state, { payload }: PayloadAction<InvoiceByIdResponse>) => {
      state.invoiceByIdData = { ...payload };
    },

    updatePaymentStatus: (state, { payload }: PayloadAction<string>) => {
      state.paymentStatus = payload;
    },

    toggleShouldReloadList: (state, { payload }: PayloadAction<boolean>) => {
      state.shouldReloadList = payload;
    }
  }
});

export const {
  updateSidePanelStack,
  popSidePanelStack,
  emptySidePanelStack,
  updateInvoiceData,
  updatePaymentStatus,
  toggleShouldReloadList
} = sidePanelSlice.actions;

export const selectSidePanelStack = (state: RootState) => state.rootReducer.sidePanel.sidePanelStack;
export const selectInvoiceData = (state: RootState) => state.rootReducer.sidePanel.invoiceByIdData;
export const selectLastStackState = (state: RootState) => {
  const panelStack = state.rootReducer.sidePanel.sidePanelStack;
  if (panelStack.length > 0) {
    const [lastState] = panelStack.slice(-1);
    return lastState;
  }
  return null;
};

export const selectPaymentStatus = (state: RootState) => state.rootReducer.sidePanel.paymentStatus;
export const selectShouldReloadList = (state: RootState) => state.rootReducer.sidePanel.shouldReloadList;

export default sidePanelSlice.reducer;
