/* eslint-disable indent */
/* eslint-disable @typescript-eslint/no-empty-function */
// to be removed
import { DeleteIcon, PdfGreyIcon, PrinterIcon } from 'assets/icons';
import { FC } from 'react';

import { VoidFn } from 'types/baseTypes';

interface MoreMenuProps {
  onExportPdf: VoidFn;
  onPrintPdf: VoidFn;
  showDelete: boolean;
  onDelete: VoidFn;
}

const MoreMenu: FC<MoreMenuProps> = props => {
  const { onExportPdf, onPrintPdf, showDelete = false, onDelete } = props;
  const menuItems = showDelete
    ? [
        {
          id: 'more-button-export-pdf',
          icon: <PdfGreyIcon className="w-5 shrink-0" />,
          text: 'Export PDF',
          onClick: onExportPdf
        },
        {
          id: 'more-button-print',
          icon: <PrinterIcon className="w-5 shrink-0" />,
          text: 'Print',
          onClick: onPrintPdf
        },
        {
          id: 'more-button-delete-payment',
          icon: <DeleteIcon className="path-fill-current path w-5 shrink-0 text-tertiaryText" />,
          text: 'Delete',
          onClick: onDelete
        }
      ]
    : [
        {
          id: 'more-button-export-pdf',
          icon: <PdfGreyIcon className="w-5 shrink-0" />,
          text: 'Export PDF',
          onClick: onExportPdf
        },
        {
          id: 'more-button-print',
          icon: <PrinterIcon className="w-5 shrink-0" />,
          text: 'Print',
          onClick: onPrintPdf
        }
      ];
  return (
    <div className="flex flex-col space-y-4 text-sbase font-semibold text-primaryText">
      {menuItems.map(menu => (
        <div onClick={menu.onClick} className={'flex flex-row items-center gap-2'} key={menu.id}>
          {menu.icon}
          <div>{menu.text}</div>
        </div>
      ))}
    </div>
  );
};

export default MoreMenu;
