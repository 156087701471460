import { CategorySliceState } from './categorySlice';
import { CategoryAPIPayload, ICategory } from './categories.model';
import { IItemBasic } from '../items/items.model';

export const prepareCategoryPayload = (categorySlice: CategorySliceState, teamId: string): CategoryAPIPayload => {
  if (teamId && categorySlice.categoryName) {
    const payload: CategoryAPIPayload = {
      teamId: teamId,
      name: categorySlice.categoryName.trim(),
      lineItems: categorySlice.lineItems.map(lineItem => lineItem.id)
    };
    return payload;
  }

  return null;
};

const getLineItemIds = lineItemsArray => {
  return lineItemsArray.map(item => item.id);
};

const isLineItemsArrayEqual = (initialLineItemsArray: IItemBasic[], updatedLineItemsArray: IItemBasic[]) => {
  if (updatedLineItemsArray.length !== initialLineItemsArray.length) return false;
  const initialLineItemIds = getLineItemIds(initialLineItemsArray);
  const updatedLineItemIds = getLineItemIds(updatedLineItemsArray);
  if (JSON.stringify(initialLineItemIds) === JSON.stringify(updatedLineItemIds)) {
    return true;
  }
};

export const isCategoryDataUpdated = (
  initialCategoryData: CategorySliceState,
  updatedCategoryData: ICategory = {
    id: null,
    name: '',
    lineItems: [],
    lineItemsCount: 0
  }
) => {
  if (
    initialCategoryData.categoryName === updatedCategoryData.name &&
    isLineItemsArrayEqual(initialCategoryData.lineItems, updatedCategoryData.lineItems ?? [])
  )
    return false;
  return true;
};
