import { CustomButton, CustomModal, If } from 'components';
import React, { FC, Fragment, useEffect, useState } from 'react';
import { VoidFn } from 'types/baseTypes';
import { CrossBoundaryIcon, GreenTickIcon, RedCloseIcon } from 'assets/icons';
import { useForm } from 'react-hook-form';
import Lottie from 'lottie-react';
import { LoadingSpinnerAnimation } from 'assets/animations';
import { string, z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { USPincodeRegex } from 'containers/invoices/create-invoice/constants';
import { ButtonType } from 'components/custom-button/CustomButton';
import { useLazyGetAddressObjectByZipcodeQuery, useLazyGetStatesListQuery } from 'containers/team-profile/api';
import ListDropDownWithSearch from 'components/list-dropdown-with-search/ListDropDownWithSearch';
import { getStatesList } from 'containers/team-profile/utils';
import { EditAddressState, TeamProfileAddress } from 'containers/team-profile/teamProfile.model';

interface TeamSwitcherProps {
  open: boolean;
  onClose: VoidFn;
  initialAddress: TeamProfileAddress;
  updateAddress: (address: TeamProfileAddress) => void;
  pageState?: EditAddressState;
}

const lineItemFormSchema = z.object({
  zipCode: string().refine(
    val => {
      return val.length === 0 || USPincodeRegex.test(val);
    },
    { message: 'Enter valid Zip code' }
  )
});

const EditTeamProfileAddressModal: FC<TeamSwitcherProps> = ({
  open,
  onClose,
  initialAddress,
  updateAddress,
  pageState = EditAddressState.EDIT
}) => {
  const [stateSelected, setStateSelected] = useState<any>({
    id: 'US_STATE',
    name: initialAddress.state
  });
  const [citySelected, setCitySelected] = useState<any>(null);

  const [getStatesListQuery, getStatesListQueryState] = useLazyGetStatesListQuery();

  const [getAddressObjectByZipcodeQuery, getAddressObjectByZipcodeQueryState] = useLazyGetAddressObjectByZipcodeQuery();

  const {
    data: getStatesListData,
    isSuccess: getStatesListDataSuccess,
    isFetching: getStatesListFetching,
    isError: getStatesListError
  } = getStatesListQueryState;

  const {
    data: getAddressObjectByZipcodeData,
    isSuccess: getAddressObjectByZipcodeSuccess,
    isFetching: getAddressObjectByZipcodeFetching,
    isError: getAddressObjectByZipcodeError
  } = getAddressObjectByZipcodeQueryState;

  const { register, handleSubmit, reset, formState, setError, clearErrors, getValues } = useForm({
    defaultValues: {
      zipCode: initialAddress?.zipCode,
      street: initialAddress?.street,
      apartment: initialAddress?.apartment,
      city: initialAddress?.city
    },
    resolver: zodResolver(lineItemFormSchema)
  });

  const resetForm = () => {
    reset({
      zipCode: initialAddress?.zipCode,
      street: initialAddress?.street,
      apartment: initialAddress?.apartment,
      city: initialAddress?.city
    });

    if (initialAddress.state === '') {
      setStateSelected(initialAddress.state);
    }
  };

  useEffect(() => {
    resetForm();
  }, [initialAddress]);

  const { errors } = formState;

  useEffect(() => {
    getStatesListQuery({});
  }, []);

  const thisis = () => {
    updateAddress({
      zipCode: getValues('zipCode'),
      street: getValues('street'),
      apartment: getValues('apartment'),
      city: getValues('city'),
      state: stateSelected?.name || ''
    });
  };

  const getErrorMsg = error => {
    if (error?.type === 'invalid_type') {
      return 'Enter a valid input';
    }

    return error?.message;
  };

  useEffect(() => {
    if (getAddressObjectByZipcodeSuccess) {
      const { city, state, stateName } = getAddressObjectByZipcodeData || {};
      reset({
        city: city
      });

      setStateSelected({
        id: state,
        name: stateName
      });
    }
  }, [getAddressObjectByZipcodeData]);

  const statesList = getStatesList(getStatesListData?.data?.states || []);

  const onCancelClick = () => {
    onClose();
    resetForm();
  };
  const isSaveDisabled = !!(errors.apartment || errors.city || errors.street || errors.zipCode);

  return (
    <Fragment>
      <CustomModal open={open} backgroundColor="bg-primary" widthInPx={'607px'} onOutsideClick={onCancelClick}>
        <If condition={getStatesListFetching}>
          <div className="flex w-full items-center justify-center">
            <Lottie className="h-20 w-20" animationData={LoadingSpinnerAnimation} loop={true} />
          </div>
        </If>

        <If condition={getStatesListDataSuccess}>
          <div className="flex w-full flex-col p-2">
            <div className="flex flex-row justify-end	">
              <CrossBoundaryIcon
                id="create-customer-close-button"
                className={'cursor-pointer'}
                onClick={onCancelClick}
              />
            </div>
            <div className="mb-4 px-5">
              <div className={'mb-5 font-lato text-xl font-bold text-headingGray'}>
                {pageState === EditAddressState.EDIT ? 'Edit ' : 'Add '}
                Business Address
              </div>
              <form className="w-full">
                <div className={'mt-4 font-lato text-base font-bold text-headingGray'}>Billing Address</div>

                <div className="-mx-3 mt-4 flex flex-wrap">
                  <div className="w-full px-3 md:mb-0 md:w-1/2">
                    <div
                      className={`flex items-center rounded bg-secondaryBtn  py-3 px-4  
                    ${errors.zipCode && 'border border-error'}`}>
                      <input
                        className="w-full appearance-none bg-secondaryBtn
                      text-17px font-semibold leading-tight text-primaryText focus:bg-secondaryBtn focus:outline-none"
                        id="customer-zipcode"
                        type="text"
                        {...register('zipCode')}
                        onChange={e => {
                          register('zipCode').onChange(e);
                          const val = e.target.value;
                          if (val.length === 0 || val.length == 5 || (val.includes('-') && val.length == 10)) {
                            clearErrors('zipCode');
                            if (val.length !== 0 && !citySelected) {
                              getAddressObjectByZipcodeQuery({ zipCode: val });
                            }
                          } else {
                            setError('zipCode', {
                              type: 'manual',
                              message: 'Please enter a valid zipcode'
                            });
                          }
                        }}
                        placeholder="Zipcode"
                      />
                      {getValues('zipCode') && (errors.zipCode ? <RedCloseIcon /> : <GreenTickIcon />)}
                    </div>
                    <div className="mt-1 text-sbase font-semibold text-error">{getErrorMsg(errors.zipCode)}</div>
                  </div>
                  <div className="w-full px-3 md:w-1/2">
                    <input
                      className="w-full  appearance-none rounded
                bg-secondaryBtn  py-3 px-4
                text-17px font-semibold leading-tight text-primaryText focus:bg-secondaryBtn focus:outline-none"
                      id="customer-street-address"
                      type="text"
                      {...register('street')}
                      placeholder="Street Address"
                    />
                  </div>
                </div>

                <div className="-mx-3 my-5 flex flex-wrap">
                  <div className="w-full px-3">
                    <input
                      className="w-full  appearance-none rounded
                bg-secondaryBtn  py-3 px-4
                text-17px font-semibold leading-tight text-primaryText focus:bg-secondaryBtn focus:outline-none"
                      id="customer-apt"
                      type="text"
                      {...register('apartment')}
                      placeholder="Apt/Unit/Suite"
                    />
                  </div>
                </div>

                <div className="-mx-3 my-5 flex flex-wrap">
                  <div className="w-full px-3">
                    <input
                      className="w-full  appearance-none rounded
                bg-secondaryBtn  py-3 px-4 text-17px font-semibold leading-tight
                 text-primaryText  focus:bg-secondaryBtn focus:outline-none"
                      id="customer-city"
                      type="text"
                      {...register('city')}
                      placeholder="City"
                    />
                  </div>
                </div>

                <div className="-mx-3 my-5 flex flex-wrap">
                  <div className="w-full px-3">
                    <ListDropDownWithSearch
                      selectedItem={stateSelected}
                      setSelectedItem={setStateSelected}
                      items={statesList}
                      inputPlaceholder={'State'}
                    />
                  </div>
                </div>

                {/*<ListDropDownWithSearch />*/}

                <div className={'mb-7 flex w-full flex-1 flex-row justify-end'}>
                  <CustomButton
                    id="create/edit-customer-cancel-button"
                    type={ButtonType.SECONDARY}
                    className="w-24"
                    onClick={onCancelClick}>
                    <div className="font-semibold">Cancel</div>
                  </CustomButton>

                  <CustomButton
                    id="create/update-address-button"
                    className=" ml-5 w-24"
                    disabled={isSaveDisabled}
                    onClick={() => {
                      thisis();
                    }}>
                    <div className="text-base font-semibold">
                      {pageState === EditAddressState.EDIT ? 'Update' : 'Save'}
                    </div>
                  </CustomButton>
                </div>
              </form>
            </div>
          </div>
        </If>
      </CustomModal>
    </Fragment>
  );
};

export default EditTeamProfileAddressModal;
