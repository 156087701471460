import { logAnalyticEvent } from 'utils/analytics';
import { AppsFlyerParams, SignInPages } from './constants';
import { CleverTapEventsIAM } from './events';
import { SignInMethod } from './types';

export const handleCreatePasswordEventLog = (page: SignInPages, currentSignInMethod: SignInMethod) => {
  if (page === SignInPages.CREATE_PASSWORD) {
    logAnalyticEvent(CleverTapEventsIAM.webCreatePasswordContinueClick, { login: currentSignInMethod?.value }, false);
  } else if (page === SignInPages.CREATE_NEW_PASSWORD) {
    logAnalyticEvent(
      CleverTapEventsIAM.webEveryDayOpenCreateANewPasswordContinueClick,
      { login: currentSignInMethod?.value },
      true
    );
  } else if (page === SignInPages.RESET_PASSWORD) {
    logAnalyticEvent(CleverTapEventsIAM.webResetPasswordContinueClick, { login: currentSignInMethod?.value }, false);
  } else if (page === SignInPages.EXPIRED_PASSWORD_ON_LOGIN) {
    logAnalyticEvent(
      CleverTapEventsIAM.webCreateANewPasswordContinueClick,
      { login: currentSignInMethod?.value },
      false
    );
  }
};

export const handleBackClickEventLog = (page: SignInPages, currentSignInMethod: SignInMethod) => {
  if (page === SignInPages.VERIFY_CODE) {
    logAnalyticEvent(
      CleverTapEventsIAM.webVerifyAccountForPasswordResetBackClick,
      { login: currentSignInMethod?.value },
      false
    );
  } else if (page === SignInPages.CREATE_NEW_PASSWORD) {
    logAnalyticEvent(
      CleverTapEventsIAM.webEveryDayOpenCreateANewPasswordBackClick,
      { login: currentSignInMethod?.value },
      true
    );
  } else if (page === SignInPages.RESET_PASSWORD) {
    logAnalyticEvent(CleverTapEventsIAM.webResetPasswordBackClick, { login: currentSignInMethod?.value }, false);
  } else if (page === SignInPages.EXPIRED_PASSWORD_ON_LOGIN) {
    logAnalyticEvent(CleverTapEventsIAM.webCreateANewPasswordBackClick, { login: currentSignInMethod?.value }, false);
  }
};

export const handleOTPVerificationEventLog = (page: SignInPages, params) => {
  if (page === SignInPages.VERIFY_CODE) {
    logAnalyticEvent(CleverTapEventsIAM.webVerifyAccountForPasswordResetContinueClick, { login: params?.value }, false);
  } else if (page === SignInPages.DEVICE_AUTH) {
    logAnalyticEvent(CleverTapEventsIAM.webDeviceAuthenticationContinueClick, { login: params?.value }, false);
  } else if (page === SignInPages.EMAIL_VERIFICATION) {
    logAnalyticEvent(CleverTapEventsIAM.webEmailVerificationContinueClick, params, false);
  }
};

export const handleSignUpEventLog = searchParams => {
  const queryParams = {};
  for (const [key, value] of Object.entries(searchParams)) {
    if (key === AppsFlyerParams.CAMPAIGN) {
      queryParams['campaign'] = value;
    } else queryParams[key] = value;
  }
  logAnalyticEvent(CleverTapEventsIAM.webSignupParameters, queryParams, false);
};
