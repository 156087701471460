import { Avatar } from '@material-ui/core';
import classnames from 'classnames';
import React, { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

interface CustomAvatarProps {
  src: string;
  onClick?: VoidFn;
  label?: string;
  isActive?: boolean;
  height?: string;
  width?: string;
  background?: string;
  children?: ReactNode;
  fontStyle?: string;
  className?: string;
}

const CustomAvatar: FC<CustomAvatarProps> = ({
  children,
  className = '',
  src,
  onClick,
  label = '',
  isActive,
  height = 'h-10',
  width = 'w-10',
  background = 'bg-gradient-to-r from-[#D8DFE5] to-[#A4D7FA]',
  fontStyle = 'text-xl'
}) => {
  return (
    <Avatar
      onClick={onClick}
      src={src}
      className={twMerge(
        classnames('', {
          [height]: height,
          [width]: width,
          'outline-boundary outline outline-2': isActive,
          [background]: background,
          [fontStyle]: fontStyle,
          [className]: className
        })
      )}>
      {children ?? label}
    </Avatar>
  );
};

export default CustomAvatar;
