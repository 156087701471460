import React, { FC, ReactNode } from 'react';
import OtpInput from 'react-otp-input';

interface GenericOTPProps {
  shouldAutoFocus?: boolean;
  placeHolder?: string;
  otpInputStyle?: string;
  onChange: (string) => void;
  numInputs?: number;
  separator?: ReactNode;
  value: string;
  onlyNumber?: boolean;
  containerStyle?: string;
  isInputNum?: boolean;
}

const defaultInputStyle = `otpInputStyle select-none !w-10 lg:!w-20 text-xl border-b border-secondary
   focus-visible:outline-none font-bold text-primaryText 
   focus-visible:placeholder:text-transparent`;

const GenericOTP: FC<GenericOTPProps> = ({
  shouldAutoFocus = true,
  placeHolder = '0000',
  otpInputStyle = defaultInputStyle,
  onChange,
  numInputs = 4,
  separator = <span className="w-7"></span>,
  value,
  onlyNumber = true,
  containerStyle
}) => {
  return (
    <div className="input-selection-reset">
      <OtpInput
        shouldAutoFocus={shouldAutoFocus}
        containerStyle={containerStyle}
        placeholder={placeHolder}
        inputStyle={otpInputStyle}
        isInputNum={onlyNumber}
        value={value}
        onChange={onChange}
        numInputs={numInputs}
        separator={separator}
      />
    </div>
  );
};

export default GenericOTP;
