//!TODO: Local storage keys in constants
//!TODO: Sidenav state change
//!TODO: Mutex locks
//!TODO: Auth UI corner cases
//!TODO: Add custom spinner

import {
  selectCurrentTeam,
  selectHomeBanners,
  selectMerchantTeams,
  selectUserDetails,
  setCurrentTeam
} from 'containers/app/appSlice';
import React, { FC, useEffect, useState } from 'react';
import DateUtils from 'utils/dateUtils';
import {
  useLazyGetDailyBreakdownSalesDataQuery,
  useLazyGetMonthlySalesDataQuery,
  useLazyGetSalesByPaymentMethodsQuery,
  useLazyGetSalesByTeamMemberQuery,
  useLazyGetTileDataQuery
} from './api';
import { SalesDetails, TileSection } from './components';
import { SalesPayload, TileDataPayload } from './home.model';
import { SalesDetailsTypes } from './components/constants';
import DailyBreakdownChart from 'containers/home/components/DailyBreakdownChart';
import MonthlyBreakdownChart from 'containers/home/components/MonthlyBreakdownChart';
import QuickActions from './components/QuickActions';
import { useAppDispatch, useAppSelector } from 'hooks';
import { SaleTypes, SalesTypeByDuration } from 'containers/home/types';
import CreateCustomer from 'containers/create-customer/CreateCustomer';
import { If } from 'components';
import { CreateCustomerModalTypes } from 'constants/CustomerConstants';
import { ICustomer } from 'containers/customers/customers.model';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { getDatesAsPerFilter, handleWindowReload, openInNewTab } from 'utils/commonUtils';
import { isPermissionDenied } from 'utils/apiUtils';
import { updateDownloadLinkToken } from 'containers/auth/authSlice';
import { clevertapEvents } from 'types/baseTypes';
import { logAnalyticEvent } from 'utils/analytics';
import { resetInvoiceState } from 'containers/invoices/InvoicesSlice';
import StripeFCModal from 'containers/payouts/payouts-settings/components/StripeFCModal';
import { MIGRATION_ROUTE_STATE } from './constants';
import MigrationModal from './bank-migration/components/MigrationModal';
import { MigrationRoutes } from './bank-migration/constants';
import routesPath from 'routes/RoutesPath';
import FAQModal from 'components/faq-modal/FAQModal';
import { bankMigrationFAQList, bankingUpdateFAQList } from 'constants/faqs';
import BannerCarousel from 'containers/payouts/components/BannerCarousel';
import { CTA_TYPES } from 'containers/payouts/constants';
import { ONBOARDING_URL_SOURCES } from 'constants/common';

const Home: FC = () => {
  const currentTeam = useAppSelector(selectCurrentTeam);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const startDate = DateUtils.getToday('yyyy-MM-dd') as string;
  const endDate = DateUtils.getToday('yyyy-MM-dd') as string;
  const [getTileDataQuery, getTileDataQueryState] = useLazyGetTileDataQuery();
  const [getSalesByPaymentMethod, getSalesByPaymentMethodState] = useLazyGetSalesByPaymentMethodsQuery();
  const [getSalesByTeamMember, getSalesByTeamMemberState] = useLazyGetSalesByTeamMemberQuery();
  const [faqsList, setFaqsList] = useState([]);
  const [faqsPageTitle, setFaqsPageTitle] = useState('');
  const [openCreateCustomerModal, setOpenCreateCustomerModal] = useState(false);
  const [openFCModal, setOpenFCModal] = useState(false);
  const [openFAQModal, setOpenFAQModal] = useState(false);
  const [isFromFCModal, setIsFromFCModal] = useState(false);

  const [openMigrationModal, setOpenMigrationModal] = useState(false);
  const [migrationPage, setMigrationPage] = useState(MigrationRoutes.MIGRATION_START);
  const homeBanners = useAppSelector(selectHomeBanners);
  const merchantTeams = useAppSelector(selectMerchantTeams);

  const teamId = searchParams.get('teamId');

  const closeCreateCustomerModal = () => {
    setOpenCreateCustomerModal(false);
  };

  const onCustomerCreated = (customer: ICustomer) => {
    setOpenCreateCustomerModal(false);
  };
  const [selectedSalesTypeDailyBreakdown, setSelectedSalesTypeDailyBreakdown] = useState<SaleTypes>(
    SaleTypes.ALL_SALES
  );
  const [selectedSalesTypeMonthlyBreakdown, setSelectedSalesTypeMonthlyBreakdown] = useState<SaleTypes>(
    SaleTypes.ALL_SALES
  );

  const [selectedDurationForSalesByTeamMember, setSelectedDurationForSalesByTeamMember] = useState<SalesTypeByDuration>(
    SalesTypeByDuration.TODAY
  );

  const [selectedDurationForSalesByPaymentMethod, setSelectedDurationForSalesByPaymentMethod] =
    useState<SalesTypeByDuration>(SalesTypeByDuration.TODAY);
  const [getDailyBreakdownSalesDataQuery, getDailyBreakdownSalesDataQueryState] =
    useLazyGetDailyBreakdownSalesDataQuery();

  const [getMonthlySalesDataQuery, getMonthlySalesDataState] = useLazyGetMonthlySalesDataQuery();

  const user = useAppSelector(selectUserDetails);

  const {
    data: getTileDataResponse,
    isSuccess: getTileDataSuccess,
    isFetching: isFetchingTileData,
    error: tileDataError,
    isUninitialized: isTileDataUninitialized
  } = getTileDataQueryState;

  const {
    isFetching: isFetchingPaymentMethods,
    data: paymentMethods,
    isSuccess: loadedPaymentMethods,
    error: paymentMethodSalesError,
    isUninitialized: isSalesByPaymentMethodUninitialized
  } = getSalesByPaymentMethodState;

  const {
    isFetching: isFetchingTeamMemberSales,
    data: teamMemberSales,
    isSuccess: fetchedTeamMemberSales,
    error: teamMemberSalesError,
    isUninitialized: isSalesByTMemberUninitialized
  } = getSalesByTeamMemberState;

  const {
    isFetching: isFetchingMonthlySales,
    data: getMonthlySalesDataResponse,
    isSuccess: getMonthlySalesDataSuccess,
    error: monthlySalesError,
    isUninitialized: isMonthlySalesUninitialized
  } = getMonthlySalesDataState;

  const {
    isFetching: isFetchingDailyBreakdownSales,
    data: getDailyBreakdownSalesDataResponse,
    isSuccess: getDailyBreakdownSalesDataSuccess,
    error: dailyBreakdownSalesError,
    isUninitialized: isDailyBreakDownUninitialized
  } = getDailyBreakdownSalesDataQueryState;

  useEffect(() => {
    dispatch(updateDownloadLinkToken(null));
  }, []);

  useEffect(() => {
    const routeState = (location.state as MIGRATION_ROUTE_STATE) ?? {};
    if (routeState.openConnectBankModal) {
      setOpenMigrationModal(true);
      setMigrationPage(MigrationRoutes.MIGRATION_CA);
      navigate(routesPath.HOME, { replace: true, state: {} });
    }
    if (teamId) {
      const merchantTeam = merchantTeams.find(team => team.id === +teamId);
      if (merchantTeam) {
        dispatch(setCurrentTeam(merchantTeam));
        setOpenMigrationModal(true);
        setMigrationPage(MigrationRoutes.MIGRATION_START);
        navigate(routesPath.HOME, { replace: true });
      } else {
        navigate(routesPath.HOME, { replace: true });
      }
    }
  }, [location]);

  useEffect(() => {
    if (currentTeam?.id) {
      const initialDataParams: TileDataPayload = {
        teamId: currentTeam.id,
        startDate: getDatesAsPerFilter(selectedDurationForSalesByTeamMember).startDate,
        endDate: getDatesAsPerFilter(selectedDurationForSalesByTeamMember).endDate
      };
      getTileDataQuery(initialDataParams);
      logAnalyticEvent(clevertapEvents.webTodaysSalesView, {
        Phone: user?.phone,
        Role: currentTeam?.role,
        'Team Name': currentTeam?.name
      });
      logAnalyticEvent(clevertapEvents.webHomeView, {});
      logAnalyticEvent(clevertapEvents.webPendingInvoicesView);
    }
  }, [currentTeam]);

  useEffect(() => {
    if (currentTeam?.id) {
      const initialDataParams: TileDataPayload = {
        teamId: currentTeam.id,
        startDate: getDatesAsPerFilter(selectedDurationForSalesByTeamMember).startDate,
        endDate: getDatesAsPerFilter(selectedDurationForSalesByTeamMember).endDate
      };
      getSalesByTeamMember(initialDataParams);
    }
  }, [currentTeam, selectedDurationForSalesByTeamMember]);

  useEffect(() => {
    if (currentTeam?.id) {
      const initialDataParams: TileDataPayload = {
        teamId: currentTeam.id,
        startDate: getDatesAsPerFilter(selectedDurationForSalesByPaymentMethod).startDate,
        endDate: getDatesAsPerFilter(selectedDurationForSalesByPaymentMethod).endDate
      };
      getSalesByPaymentMethod(initialDataParams);
    }
  }, [currentTeam, selectedDurationForSalesByPaymentMethod]);

  useEffect(() => {
    if (currentTeam?.id) {
      getDailyBreakdownSalesDataQuery({
        teamId: currentTeam.id,
        startDate,
        endDate,
        isDigitalSales: selectedSalesTypeDailyBreakdown === SaleTypes.DIGITAL_SALES
      });
    }
  }, [currentTeam, selectedSalesTypeDailyBreakdown]);

  useEffect(() => {
    if (currentTeam?.id) {
      getMonthlySalesDataQuery({
        teamId: currentTeam.id,
        startDate,
        endDate,
        isDigitalSales: selectedSalesTypeMonthlyBreakdown === SaleTypes.DIGITAL_SALES
      });
    }
  }, [currentTeam, selectedSalesTypeMonthlyBreakdown]);

  const reloadPaymentMethodsSales = () => {
    if (currentTeam?.id) {
      const payload: SalesPayload = {
        teamId: currentTeam.id,
        startDate: getDatesAsPerFilter(selectedDurationForSalesByPaymentMethod).startDate,
        endDate: getDatesAsPerFilter(selectedDurationForSalesByPaymentMethod).endDate
      };
      getSalesByPaymentMethod(payload);
    } else {
      handleWindowReload();
    }
  };

  const reloadGetTileData = () => {
    if (currentTeam?.id) {
      const payload: SalesPayload = {
        teamId: currentTeam.id,
        startDate,
        endDate
      };
      getTileDataQuery(payload);
    } else {
      handleWindowReload();
    }
  };

  const reloadTeamMembersSales = () => {
    if (currentTeam?.id) {
      const payload: SalesPayload = {
        teamId: currentTeam.id,
        startDate,
        endDate
      };
      getSalesByTeamMember(payload);
    } else {
      handleWindowReload();
    }
  };

  const relaodDailyBreakdownSalesData = () => {
    if (currentTeam?.id) {
      getDailyBreakdownSalesDataQuery({
        teamId: currentTeam.id,
        startDate,
        endDate,
        isDigitalSales: selectedSalesTypeDailyBreakdown === SaleTypes.DIGITAL_SALES
      });
    } else {
      handleWindowReload();
    }
  };

  const reloadMonthlySalesData = () => {
    if (currentTeam?.id) {
      getMonthlySalesDataQuery({
        teamId: currentTeam.id,
        startDate,
        endDate,
        isDigitalSales: selectedSalesTypeMonthlyBreakdown === SaleTypes.DIGITAL_SALES
      });
    } else {
      handleWindowReload();
    }
  };

  const onStartInvoiceCreation = (): void => {
    dispatch(resetInvoiceState());
    navigate('/invoices/create');
  };

  const onCloseMigrationModal = () => {
    setOpenMigrationModal(false);
    if (isFromFCModal) setIsFromFCModal(false);
  };

  const handleOpenFCModal = () => {
    setOpenMigrationModal(false);
    setOpenFCModal(true);
  };

  const handleFCModalClose = () => {
    setOpenFCModal(false);
    setOpenMigrationModal(true);
    setIsFromFCModal(true);
    setMigrationPage(MigrationRoutes.MIGRATION_CA);
  };

  const handleOpenFAQModal = (page: MigrationRoutes) => {
    setOpenMigrationModal(false);
    if (page === MigrationRoutes.MIGRATION_CONFIRM_BANK_ACCOUNT) {
      setFaqsPageTitle('Payout updates');
      setFaqsList(bankMigrationFAQList);
    } else if (page === MigrationRoutes.MIGRATION_START) {
      setFaqsPageTitle('Banking Updates');
      setFaqsList(bankingUpdateFAQList);
    }
    setOpenFAQModal(true);
    setMigrationPage(page);
  };

  const handleFAQModalClose = () => {
    setOpenFAQModal(false);
    setOpenMigrationModal(true);
    setFaqsList([]);
    setFaqsPageTitle('');
  };

  const handleCarouselButtonClick = (action, type: string) => {
    if (type === CTA_TYPES.URL) {
      openInNewTab(action);
    } else if (type === CTA_TYPES.DEEPLINK) {
      setMigrationPage(action);
      setOpenMigrationModal(true);
    }
    logAnalyticEvent(clevertapEvents.webHomePageBannerClick, { ctaAction: action });
  };

  return (
    <div className="pt-6 pb-20 sm:pb-6">
      <div className="ml-7 shrink-0">
        <BannerCarousel handleCarouselButtonClick={handleCarouselButtonClick} banners={homeBanners} />
      </div>
      <div className="ml-7">
        <div id="home-welcome-text" className="text-4xl font-bold text-primaryText">
          Welcome, <span className="capitalize">{currentTeam?.teamMerchantName || user?.name}</span>!
        </div>
        <div className="mt-3 text-17px font-normal text-primaryText">
          Here's how {currentTeam?.name} is doing today.
        </div>
      </div>
      <div className="ml-7 flex xl:hidden">
        <QuickActions
          isTabView={true}
          onPrimaryAction={onStartInvoiceCreation}
          onSecondaryAction={() => setOpenCreateCustomerModal(true)}
        />
      </div>
      <TileSection
        handleReload={reloadGetTileData}
        getTileDataSuccess={getTileDataSuccess}
        tileData={getTileDataResponse}
        isFetchingTileData={isFetchingTileData || isTileDataUninitialized}
        isPermissionError={isPermissionDenied(tileDataError?.['data']?.code, tileDataError?.['data']?.message)}
      />
      <div
        className={`customTransparentScroll flex w-full flex-col gap-5
      overflow-x-scroll px-7 lg:flex-row 1439px:gap-10`}>
        <div className="w-full pt-5 lg:w-1/2 xl:w-[38%] 1439px:pt-10">
          <SalesDetails
            details={teamMemberSales?.teamMembers}
            totalSales={teamMemberSales?.totalSales}
            title="Sales by Team Member"
            selectedDurationForSales={selectedDurationForSalesByTeamMember}
            setSelectedDurationForSales={setSelectedDurationForSalesByTeamMember}
            type={SalesDetailsTypes.BY_TEAM_MEMBERS}
            isSuccess={fetchedTeamMemberSales}
            handleReload={reloadTeamMembersSales}
            isFetching={isFetchingTeamMemberSales || isSalesByTMemberUninitialized}
            isPermissionError={isPermissionDenied(
              teamMemberSalesError?.['data']?.code,
              teamMemberSalesError?.['data']?.message
            )}
          />
        </div>
        <div className="w-full pt-5 lg:w-1/2 xl:w-[42%] 1439px:pt-10">
          <SalesDetails
            details={paymentMethods?.paymentMethods}
            totalSales={paymentMethods?.totalSales}
            selectedDurationForSales={selectedDurationForSalesByPaymentMethod}
            setSelectedDurationForSales={setSelectedDurationForSalesByPaymentMethod}
            title="Sales by Payment Method"
            type={SalesDetailsTypes.BY_PAYMENT_METHODS}
            isSuccess={loadedPaymentMethods}
            handleReload={reloadPaymentMethodsSales}
            isFetching={isFetchingPaymentMethods || isSalesByPaymentMethodUninitialized}
            isPermissionError={isPermissionDenied(
              paymentMethodSalesError?.['data'].code,
              paymentMethodSalesError?.['data'].message
            )}
          />
        </div>
        <QuickActions
          className="ml-auto mt-4 hidden xl:flex 1439px:mt-7"
          onPrimaryAction={onStartInvoiceCreation}
          onSecondaryAction={() => setOpenCreateCustomerModal(true)}
        />
      </div>
      <div className="customTransparentScroll flex flex-col gap-5 overflow-x-scroll px-7 lg:flex-row 1439px:gap-10">
        <div className="w-full pt-5 lg:min-w-[440px] 1439px:pt-10">
          <DailyBreakdownChart
            data={getDailyBreakdownSalesDataResponse}
            isSuccess={getDailyBreakdownSalesDataSuccess}
            handleReload={relaodDailyBreakdownSalesData}
            isFetching={isFetchingDailyBreakdownSales || isDailyBreakDownUninitialized}
            selectedSalesTypeDailyBreakdown={selectedSalesTypeDailyBreakdown}
            setSelectedSalesTypeDailyBreakdown={setSelectedSalesTypeDailyBreakdown}
            isPermissionError={isPermissionDenied(
              dailyBreakdownSalesError?.['data'].code,
              dailyBreakdownSalesError?.['data'].message
            )}
          />
        </div>
        <div className="w-full pt-5 lg:min-w-[440px] 1439px:pt-10">
          <MonthlyBreakdownChart
            data={getMonthlySalesDataResponse}
            isSuccess={getMonthlySalesDataSuccess}
            handleReload={reloadMonthlySalesData}
            isFetching={isFetchingMonthlySales || isMonthlySalesUninitialized}
            selectedSalesTypeMonthlyBreakdown={selectedSalesTypeMonthlyBreakdown}
            setSelectedSalesTypeMonthlyBreakdown={setSelectedSalesTypeMonthlyBreakdown}
            isPermissionError={isPermissionDenied(
              monthlySalesError?.['data'].code,
              monthlySalesError?.['data'].message
            )}
          />
        </div>
      </div>
      <If condition={Boolean(openCreateCustomerModal)}>
        <CreateCustomer
          open={openCreateCustomerModal}
          onClose={closeCreateCustomerModal}
          panelType={CreateCustomerModalTypes.CREATE_CUSTOMER}
          customerData={null}
          customerDataFetchSuccess={true}
          customerDataFetchLoading={false}
          createOrEditCustomerCallBack={onCustomerCreated}
        />
      </If>
      {openMigrationModal && (
        <MigrationModal
          onClose={onCloseMigrationModal}
          defaultPage={migrationPage}
          onOpenFCModal={handleOpenFCModal}
          handleFaqClick={handleOpenFAQModal}
          isFromFCModal={isFromFCModal}
          from={ONBOARDING_URL_SOURCES.HOME}
        />
      )}
      {openFCModal && <StripeFCModal onClose={handleFCModalClose} showSuccessToast={false} />}
      {openFAQModal && <FAQModal onClose={handleFAQModalClose} title={faqsPageTitle} FAQList={faqsList} />}
    </div>
  );
};

export default Home;
