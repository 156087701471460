export enum PayoutsCleverTapEvents {
  webPayoutsListCreateInvoiceClick = 'Web_Payouts_List_Create_Invoice',
  webPayoutsBannersClick = 'Web_Payouts_Banners_Click',
  webUpcomingPayoutsViewDetailsClick = 'Web_Upcoming_Payouts_View_Details_Click',
  webUpcomingPayoutsDetailsLearnMoreClick = 'Web_Upcoming_Payouts_Details_Learn_More_Click',
  webOnTheWayPayoutsViewDetailsClick = 'Web_On_The_Way_Payouts_View_Details_Click',
  webPayoutsTotalBalanceViewDetailsClick = 'Web_Payouts_Total_Balance_View_Details_Click',
  webPayoutsHomeViewAllPayoutsClick = 'Web_Payouts_Home_View_All_Payouts_Click',
  webPayoutsHomePayoutDetailsClick = 'Web_Payouts_Home_Payout_Details_Click',
  webPayoutsHomeGoToSettingsClick = 'Web_Payouts_Home_Go_To_Settings_Click',
  webUpcomingPayoutsViewAllTransactionsClick = 'Web_Upcoming_Payouts_View_All_Transactions_Click',
  webPayoutsViewAllTransactionsClick = 'Web_Payouts_View_All_Transactions_Click',
  webPayoutsTransactionsFiltersApplied = 'Web_Payouts_Transactions_Filters_Applied',
  webPayoutTransactionsExport = 'Web_Payout_Transactions_Export',
  webUpcomingPayoutsTransactionsExport = 'Web_Upcoming_Payouts_Transactions_Export',
  webPayoutsListFiltersApplied = 'Web_Payouts_List_Filters_Applied',
  webPayoutsSettingsFAQClick = 'Web_Payouts_Settings_FAQ_Click',
  webPayoutsEnablePayoutsClick = 'Web_Payouts_Enable_Payouts_Click',
  webEnablePayoutsSubmitDetailsClick = 'Web_Enable_Payouts_Submit_Details_Click',
  webEnablePayoutsAddBankAccountClick = 'Web_Enable_Payouts_Add_Bank_Account_FC_Click',
  webEnablePayoutsAddPendingDocsClick = 'Web_Enable_Payouts_Add_Pending_Docs_Click',
  webPayoutTransactionSearchApplied = 'Web_Payout_Transaction_Search_Applied',
  webEnablePayoutsPopupRejectedClick = 'Web_Enable_Payouts_Popup_Rejected_Click',
  webEnablePayoutsPopupInReviewClick = 'Web_Enable_Payouts_Popup_In_Review_Click',
  WebPayoutTotalBalanceDetailPanelFaqClick = 'Web_Payout_Total_Balance_Detail_Panel_Faq_Click',
  webConnectPayoutDetailLearnMoreClick = 'Web_Connect_Payout_Detail_Learn_More_Click'
}
