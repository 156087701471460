import { baseApi } from 'services/api';
import { API_VERSION } from 'constants/common';
import {
  CreateCatalogItemResponse,
  CreateItemPayload,
  DeleteItemPayload,
  EditItemPayload,
  GetCatalogFetchItemsPayload,
  GetCatalogItemsPayload,
  IGetCatalogRecentItemsPayload,
  IGetCatalogRecentItemsResponse,
  IItem,
  IItemBasic,
  IPaginatedItem,
  LineItemByIdPayload,
  SKUCheckPayload
} from './items.model';
import { getUrlSearchParams } from 'utils/apiUtils';
import { API_RESPONSES, FileTypes, GenericPaginationResponse, SKUCheckResponse } from 'types/baseTypes';
import { initializeItemSliceFromAPI, updateInitalizingItemSliceInProgress } from './itemsSlice';
import { downloadFile } from 'utils/commonUtils';

const ItemsBaseApi = baseApi.injectEndpoints({
  endpoints: builder => ({
    getCatalogItems: builder.query<GenericPaginationResponse<IPaginatedItem>, GetCatalogItemsPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/items?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch catalog items',
        showToast: true
      }
    }),
    createCatalogItem: builder.mutation<CreateCatalogItemResponse, CreateItemPayload>({
      query: body => {
        const url = `${API_VERSION.V1}/catalogue/items`;
        return {
          url: url,
          method: 'POST',
          body: body
        };
      },
      extraOptions: {
        failure: 'Something went wrong',
        showToast: true
      }
    }),
    editCatalogItem: builder.mutation<API_RESPONSES.SUCCESS, EditItemPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/items/${params.itemId}`;
        return {
          url: url,
          method: 'PUT',
          body: params.body
        };
      },
      extraOptions: {
        failure: 'Something went wrong',
        showToast: true
      }
    }),
    checkSKU: builder.query<SKUCheckResponse<IItemBasic>, SKUCheckPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/items/sku-check/${params.sku}?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        showToast: false
      }
    }),
    deleteItem: builder.mutation<API_RESPONSES.SUCCESS, DeleteItemPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/items/${params.id}?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'DELETE'
        };
      },
      extraOptions: {
        failure: 'Something went wrong',
        showToast: true
      }
    }),
    getLineItemById: builder.query<IItem, LineItemByIdPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/item/${params.id}?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      async onQueryStarted({ shouldInitialiseState, flowType }, { dispatch, queryFulfilled }) {
        try {
          if (shouldInitialiseState) {
            dispatch(updateInitalizingItemSliceInProgress(true));
          }
          const { data } = await queryFulfilled;
          if (data && shouldInitialiseState) {
            dispatch(initializeItemSliceFromAPI({ data, type: flowType }));
          }
        } catch (err) {
          if (shouldInitialiseState) {
            dispatch(updateInitalizingItemSliceInProgress(false));
          }
        }
      },
      extraOptions: {
        failure: 'Unable to load item details. Please try again later.',
        showToast: true
      }
    }),

    getCatalogFetchItems: builder.query<GenericPaginationResponse<IItemBasic>, GetCatalogFetchItemsPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/fetch-items?`;
        const urlParams = getUrlSearchParams(params);
        url = url + urlParams;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Failed to fetch items to add',
        showToast: true
      }
    }),
    getCatalogRecentItems: builder.query<IGetCatalogRecentItemsResponse, IGetCatalogRecentItemsPayload>({
      query: params => {
        const url = `${API_VERSION.V1}/catalogue/recent-items?teamId=${params.teamId}`;
        return {
          url: url,
          method: 'GET'
        };
      },
      extraOptions: {
        failure: 'Unable to get suggested items.',
        showToast: true
      }
    }),
    downloadItemsList: builder.query<any, GetCatalogItemsPayload>({
      query: params => {
        let url = `${API_VERSION.V1}/catalogue/items?`;
        const urlParams = getUrlSearchParams(params);
        urlParams.append('type', 'download');
        url = url + urlParams;
        return {
          url: url,
          method: 'GET',
          responseHandler: async response => {
            await downloadFile({ response, fileType: FileTypes.CSV, fileName: 'items' });
          },
          // Do not cache responses for pdf's or csv's as it may lead to crashing the browser
          cache: 'no-cache'
        };
      },
      extraOptions: {
        failure: 'Failed to download items list',
        success: 'Items exported successfully!',
        showToast: true
      }
    })
  })
});

export const {
  useLazyGetCatalogItemsQuery,
  useGetLineItemByIdQuery,
  useLazyGetLineItemByIdQuery,
  useEditCatalogItemMutation,
  useCreateCatalogItemMutation,
  useLazyCheckSKUQuery,
  useDeleteItemMutation,
  useLazyGetCatalogFetchItemsQuery,
  useGetCatalogRecentItemsQuery,
  useLazyDownloadItemsListQuery
} = ItemsBaseApi;
