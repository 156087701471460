import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import routesPath from './RoutesPath';
import SignInPage from 'pages/SignInPage';
import { hideChatIcon } from 'services/freshchat';
import SignUpPage from 'pages/SignUpPage';
import LandingPage from 'containers/auth/LandingPage';
import SignUp from 'containers/auth/SignUp';
import DigitalOnboarding from 'containers/digital-onboarding/digitalOnboarding';
import DeclinedPage from 'containers/error-screens/DeclinedPage';

const PublicLayout = () => {
  useEffect(() => {
    hideChatIcon();
  }, []);

  return (
    <div className={'flex h-screen w-screen flex-row'}>
      <Routes>
        <Route path={routesPath.DEFAULT} element={<LandingPage />} />
        <Route path={routesPath.SIGNIN} element={<SignInPage />} />
        <Route path={routesPath.SIGN_UP_QR} element={<SignUpPage />} />
        <Route path={routesPath.SIGN_UP} element={<SignUp />} />
        <Route path={routesPath.ALL} element={<Navigate to={routesPath.SIGNIN} replace />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_NAME} element={<DigitalOnboarding />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_EMAIL} element={<DigitalOnboarding />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_PROFESSION} element={<DigitalOnboarding />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_BUSINESS_NAME} element={<DigitalOnboarding />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_EMPLOYEES} element={<DigitalOnboarding />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_REFERRAL_SOURCE} element={<DigitalOnboarding />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_WEBSITE} element={<DigitalOnboarding />} />
        <Route path={routesPath.DIGITAL_ONBOARDING_EMAIL_ERROR} element={<DigitalOnboarding />} />
        <Route path={routesPath.DECLINED} element={<DeclinedPage />} />
        <Route path={routesPath.WAITING_LIST} element={<DigitalOnboarding />} />
      </Routes>
    </div>
  );
};

export default PublicLayout;
