import { ArrowRightIcon, AttachmentIcon, MailIcon, MessageIcon } from 'assets/icons';
import { CustomButton, CustomInput } from 'components';
import { InputType } from 'components/custom-input.tsx/CustomInput';
import { REGEX } from 'constants/common';
import { selectCurrentTeam, selectCurrentTeamCustomersPermissions, showNotifier } from 'containers/app/appSlice';
import { useAppDispatch, useAppSelector } from 'hooks';
import { FC, useEffect, useState } from 'react';
import { useShareInvoiceReceiptMutation } from '../api';
import { NotifierTypes } from 'constants/notifierConstants';
import { logAnalyticEvent } from 'utils/analytics';
import { CleverTapEventsInvoices } from '../events';
import { ForwardFileType, VoidFn } from 'types/baseTypes';
import { InvoiceByIdResponse } from '../invoices.model';
import { twMerge } from 'tailwind-merge';
import { removeUSCountryCode } from 'utils/commonUtils';

interface InvoiceUrlShareProps {
  invoiceData: InvoiceByIdResponse | any;
  resetInvoicesQuery?: VoidFn;
  className?: string;
  onBack: VoidFn;
}

const InvoiceUrlShare: FC<InvoiceUrlShareProps> = ({ invoiceData, resetInvoicesQuery, className, onBack }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isLinkCopied, setIsLinkCopied] = useState(false);
  const [updatePhoneNumber, setUpdatePhoneNumber] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [emailUpdateDisabled, setEmailUpdateDisabled] = useState(false);
  const [phoneUpdateDisabled, setPhoneUpdateDisabled] = useState(false);
  const [emailCheckClicked, setEmailCheckClicked] = useState(false);
  const [phoneCheckClicked, setPhoneCheckClicked] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(true);

  const dispatch = useAppDispatch();
  const customerPermissions = useAppSelector(selectCurrentTeamCustomersPermissions);
  const currentTeam = useAppSelector(selectCurrentTeam);
  const [shareInvoiceReceipt, { isSuccess: isInvoiceShareSuccess }] = useShareInvoiceReceiptMutation();

  useEffect(() => {
    const existingEmail = invoiceData.customer?.email === email;
    const existingPhoneNumber = invoiceData.customer?.phoneNumber === phoneNumber;
    setPhoneUpdateDisabled(existingPhoneNumber);
    if (!phoneCheckClicked) setUpdatePhoneNumber(!existingPhoneNumber);
    if (!emailCheckClicked) setUpdateEmail(!existingEmail);
    setEmailUpdateDisabled(existingEmail);
  }, [email, phoneNumber]);

  useEffect(() => {
    setPhoneNumber(removeUSCountryCode(invoiceData.customer?.phoneNumber));
    setEmail(invoiceData.customer?.email);
  }, []);

  useEffect(() => {
    if (isInvoiceShareSuccess) {
      if (resetInvoicesQuery) resetInvoicesQuery();
    }
  }, [isInvoiceShareSuccess]);

  const hasEditCustomerPermissions =
    customerPermissions.editCustomersCreatedByTeam || customerPermissions.editExistingCustomers;

  const isPhoneUpdateDisabled = () => {
    if (hasEditCustomerPermissions) {
      return phoneUpdateDisabled;
    }
    return !hasEditCustomerPermissions;
  };

  const isEmailUpdateDisabled = () => {
    if (hasEditCustomerPermissions) {
      return emailUpdateDisabled;
    }
    return !hasEditCustomerPermissions;
  };

  const checkEmailError = () => {
    if (REGEX.email.test(email) || email.length !== 0) {
      const invalidDomains = ['..', '.con', '.comn', '.cok', '.don', '.cim', 'gnail.com', 'gmal.com'];
      const isValid = !invalidDomains.some(domain => email.endsWith(domain));
      return isValid;
    }
    return false;
  };

  const getPrimaryMessage = (communicationType: string) => {
    let primaryMessage = 'Invoice';
    primaryMessage += ` shared successfully on 
    ${communicationType === 'EMAIL' ? 'email' : 'phone'} `;
    return primaryMessage;
  };

  const shareInvoice = (communicationType: string) => {
    let phoneNumberPayload = '';
    let emailPayload = '';
    const shareViaSMS = communicationType === 'SMS';
    let shouldUpdateCustomer = hasEditCustomerPermissions;
    if (shareViaSMS) {
      phoneNumberPayload = phoneNumber.startsWith('+') ? phoneNumber : `+1${phoneNumber}`;
      shouldUpdateCustomer = hasEditCustomerPermissions && updatePhoneNumber;
    } else {
      emailPayload = email;
      shouldUpdateCustomer = hasEditCustomerPermissions && updateEmail;
    }
    const payload = {
      phoneNumber: phoneNumberPayload,
      email: emailPayload,
      updateCustomer: shouldUpdateCustomer,
      teamId: currentTeam.id.toString()
    };
    dispatch(
      showNotifier({
        message: {
          primaryMessage: getPrimaryMessage(communicationType),
          secondaryMessage: '',
          isMessageHtml: true
        },
        type: NotifierTypes.INFO,
        showClose: false,
        fontStyle: 'text-primary font-normal'
      })
    );
    shareInvoiceReceipt({ ...payload, invoiceId: invoiceData.invoiceId, type: ForwardFileType.INVOICE });
    if (communicationType === 'SMS') {
      logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailShareReceiptSendSMS, {
        'Phone Number': phoneNumberPayload
      });
    }
    if (communicationType === 'EMAIL') {
      logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailShareReceiptSendEmail, {
        'Email ID': email
      });
    }
  };

  const copyLinkHandler = () => {
    const url = invoiceData?.receiptUrl ? invoiceData?.receiptUrl : invoiceData?.url;
    setIsLinkCopied(true);
    navigator.clipboard.writeText(url);
    setTimeout(() => setIsLinkCopied(false), 1000);
    logAnalyticEvent(CleverTapEventsInvoices.webInvoicesListDetailShareReceiptCopy);
  };

  return (
    <div className={`space-y-4 ${className}`}>
      <div className="flex items-center gap-1">
        <ArrowRightIcon
          className="path-fill-current path rotate-180 cursor-pointer text-primaryText"
          onClick={onBack}
          id="invoice-url-share-back-button"
        />
        <div className="text-17px font-semibold text-primaryText">Share Invoice</div>
      </div>
      <div className="text-px13 text-heading">Choose a method to share the invoice with the customer</div>
      <div
        className="flex w-full flex-col rounded-md	
        border-borderGray px-4 pt-4"
        style={{ borderWidth: '1px' }}>
        <div className="flex flex-row items-center">
          <MessageIcon />
          <div className={'ml-2 text-sbase font-semibold text-primaryText'}>Text Message</div>
        </div>

        <div>
          <div
            className={`mt-4 flex w-full
                     flex-row rounded-lg border ${isPhoneNumberValid ? 'border-borderGray' : 'border-error'}
                     bg-primary text-sm font-semibold text-gray-900 
                      focus:border-primaryBtn focus:ring-blue-500`}>
            <div className={'mt-0.5 mr-1 flex items-center justify-center pl-2.5 text-17px text-accentText'}>+1</div>
            <CustomInput
              placeholder="000-000-0000"
              id="share-invoice/receipt-phone-number"
              inputStyle="w-full text-17px"
              type={InputType.TEXT}
              className="h-10 rounded-lg bg-primary p-0 text-primaryText 
                  placeholder:text-sbase placeholder:text-accentText focus:outline-none"
              value={phoneNumber}
              onChange={value => {
                if (REGEX.phoneNumberInput.test(value)) {
                  setIsPhoneNumberValid(true);
                  setPhoneNumber(value);
                }
              }}
            />
          </div>
          {!isPhoneNumberValid && <div className="mt-1 text-red-400">Please enter a valid phone number</div>}

          <CustomButton
            id={'share-invoice-sidepanel-send-sms-button'}
            className="path-stroke-current mt-4 h-[38px] w-full
         bg-primaryBtn px-4 py-2 font-lato normal-case text-primaryBtnText disabled:opacity-70 "
            onClick={() => {
              if (REGEX.usIndianNumber.test(phoneNumber)) {
                shareInvoice('SMS');
              } else setIsPhoneNumberValid(false);
            }}
            StartIcon={MessageIcon}>
            <span className="text-sbase font-semibold">Send SMS</span>
          </CustomButton>

          <div className={`mb-4 mt-2 flex items-center ${isPhoneUpdateDisabled() && 'pointer-events-none opacity-50'}`}>
            <input
              id={'share-invoice-sidepanel-update-cust-info-phno-checkbox'}
              type="checkbox"
              onChange={() => {
                setPhoneCheckClicked(true);
                setUpdatePhoneNumber(!updatePhoneNumber);
              }}
              checked={hasEditCustomerPermissions && updatePhoneNumber}
              className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
            />

            <label
              htmlFor={'share-invoice-sidepanel-update-cust-info-phno-checkbox'}
              className="ml-1 text-sbase font-medium text-primaryText">
              Update customer information
            </label>
          </div>
        </div>
      </div>

      <div
        className="flex w-full flex-col rounded-md border-borderGray
        px-4 pt-4"
        style={{ borderWidth: '1px' }}>
        <div className="flex flex-row items-center">
          <MailIcon />
          <div className={'ml-2 text-sbase font-semibold text-primaryText'}>Email</div>
        </div>

        <div className=" text-gray-600">
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <button type="submit" className="focus:shadow-outline p-1 focus:outline-none"></button>
          </span>
          <CustomInput
            id="share-invoice/receipt-email"
            inputStyle="w-full text-17px"
            type={InputType.TEXT}
            onChange={value => {
              setIsEmailValid(true);
              setEmail(value);
            }}
            value={email}
            className={`mt-4 block h-10 rounded-lg border
               placeholder:text-sbase placeholder:text-accentText ${isEmailValid ? 'border-borderGray' : 'border-error'}
                bg-primary  p-2.5 font-semibold text-primaryText focus:outline-none`}
            placeholder="Enter email id"
          />
          {!isEmailValid && <div className="mt-1 text-red-400">Please enter a valid email</div>}

          <CustomButton
            id={'share-invoice-sidepanel-email-button'}
            className="path-stroke-current mt-4 h-[38px] w-full
         bg-primaryBtn px-4 py-2 font-lato normal-case text-primaryBtnText disabled:opacity-70"
            onClick={() => {
              if (checkEmailError()) {
                shareInvoice('EMAIL');
              } else setIsEmailValid(false);
            }}
            StartIcon={MailIcon}>
            <div className="font-semibold">Send Email</div>
          </CustomButton>

          <div className={`mb-4 mt-2 flex items-center ${isEmailUpdateDisabled() && 'pointer-events-none opacity-50'}`}>
            <input
              id={'share-invoice-sidepanel-update-cust-info-email-checkbox'}
              type="checkbox"
              onChange={() => {
                setEmailCheckClicked(true);
                setUpdateEmail(!updateEmail);
              }}
              checked={hasEditCustomerPermissions && updateEmail}
              className="h-4 w-4 cursor-pointer rounded pl-1 accent-primaryBtn"
            />
            <label
              htmlFor={'share-invoice-sidepanel-update-cust-info-email-checkbox'}
              className="ml-1 text-sbase font-medium text-primaryText">
              Update customer information
            </label>
          </div>
        </div>
      </div>

      <div className="mt-4 flex w-full flex-col rounded-md	border-borderGray p-4" style={{ borderWidth: '1px' }}>
        <div className="flex flex-row items-center">
          <AttachmentIcon />
          <div className={'ml-2 text-sbase font-semibold text-primaryText'}>Copy Link</div>
        </div>
        <div
          className="mt-4 flex rounded-lg 
              border border-borderGray text-gray-600 focus-within:text-gray-400 focus:border-primaryBtn">
          <div
            id="invoice-or-receipt-share-url"
            className="block w-full  truncate rounded-l-lg bg-primary p-2.5 
                     text-sbase text-primaryText focus:ring-blue-500">
            {invoiceData?.receiptUrl || invoiceData?.url}
          </div>
          <div>
            <button
              onClick={copyLinkHandler}
              className={twMerge(`flex h-full flex-1 flex-row items-center justify-center rounded-r-lg border-l
                  border-borderGray bg-secondaryBtn font-semibold
                   px-4 text-sbase text-primaryBtn ${isLinkCopied && 'text-primaryText'}`)}
              style={{ flexShrink: 0, whiteSpace: 'nowrap' }}>
              {isLinkCopied ? 'Link copied' : 'Copy'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceUrlShare;
