import { CustomInput } from 'components';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Dispatch, FC, SetStateAction, useEffect } from 'react';
import { useController, useForm } from 'react-hook-form';
import { selectInitializingTaxSliceInProgress, selectTaxState, updateTaxName, updateTaxRate } from '../taxSlice';
import { TaxInputErrors } from '../type';
import { REGEX } from 'constants/common';

interface TaxInputSectionProps {
  className?: string;
  errors: Record<string, TaxInputErrors>;
  setErrors: Dispatch<SetStateAction<Record<string, TaxInputErrors>>>;
}

const TaxInputSection: FC<TaxInputSectionProps> = ({ className = '', errors, setErrors }) => {
  const { control, reset } = useForm({
    defaultValues: {
      taxName: '',
      taxRate: ''
    }
  });

  const { field: taxNameField } = useController({ name: 'taxName', control });
  const { field: taxRateField } = useController({ name: 'taxRate', control });
  const selectedTaxState = useAppSelector(selectTaxState);
  const initializingTaxSliceInProgress = useAppSelector(selectInitializingTaxSliceInProgress);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (selectedTaxState.isEditTaxFlow && !initializingTaxSliceInProgress) {
      reset({
        taxName: selectedTaxState.taxName,
        taxRate: selectedTaxState.taxRate
      });
    }
  }, [selectedTaxState]);

  const handleTaxNameChange = value => {
    setErrors({ ...errors, taxNameError: null });
    taxNameField.onChange(value);
    if (value.length === 0) setErrors({ ...errors, taxNameError: TaxInputErrors.Tax_Name_Error });
    dispatch(updateTaxName({ name: value }));
  };

  const handleTaxRateChange = (value: string) => {
    setErrors({ ...errors, taxRateError: null });
    if (!REGEX.percentageWithThreeDecimals.test(value)) {
      return;
    }
    taxRateField.onChange(value);
    if (!value || +value === 0) setErrors({ ...errors, taxRateError: TaxInputErrors.Tax_Rate_Error });
    dispatch(updateTaxRate({ rate: value }));
  };

  return (
    <div className={`${className}`}>
      {/* Name */}
      <div className="flex flex-col">
        <div className="text-sbase font-semibold text-headingGray">Name*</div>
        <CustomInput
          value={taxNameField.value}
          onChange={handleTaxNameChange}
          id="invite-first-name"
          name="firstName"
          maxLength={50}
          placeholder="Enter name"
          className="mt-2 text-sbase placeholder:text-accentText"
        />
        {errors.taxNameError && <div className="mt-2 text-sbase text-error">{errors.taxNameError}</div>}
      </div>

      {/* Tax rate */}
      <div className="flex flex-col">
        <div className="mt-4 text-sbase font-semibold text-headingGray">Tax rate (in %)*</div>
        <CustomInput
          value={taxRateField.value}
          onChange={handleTaxRateChange}
          id="tax-name"
          name="taxRate"
          placeholder="0.00"
          className="mt-2 text-sbase placeholder:text-accentText"
        />
        {errors.taxRateError && <div className="mt-2 text-sbase text-error">{errors.taxRateError}</div>}
      </div>
    </div>
  );
};

export default TaxInputSection;
