import { BASE_URL, REDIRECTION_PAGES } from 'constants/redirectionConstants';
import { ONE_LINK_BASE_URL, WEB_BASE_URL } from './envUtils';
import { PaymentSystem } from 'containers/payouts/constants';
import { GO_SCANPAY_FAQ } from 'config/index';
import { logAnalyticEvent } from './analytics';
import { openInNewTab } from './commonUtils';

export const getRoutePath = (page: REDIRECTION_PAGES, id: string, teamId: string, paymentSystem?: PaymentSystem) => {
  switch (page) {
    case REDIRECTION_PAGES.DISPUTE:
      return [`/disputes?id=${id}&teamId=${teamId}`, `payments/disputes/${teamId}/${id}/dispute`];
    case REDIRECTION_PAGES.RECEIPT:
      return [`/transactions?id=${id}&teamId=${teamId}`, `payments/receipts/${teamId}/${id}/receipt`];
    case REDIRECTION_PAGES.PAYOUT:
      // eslint-disable-next-line no-case-declarations
      let appUrl: string = BASE_URL.DEFAULT_APP;
      if (paymentSystem === PaymentSystem.CA) {
        appUrl = `/payoutsV2?id=${id}&teamId=${teamId}`;
      } else if (paymentSystem === PaymentSystem.LIP) {
        appUrl = `/payouts?id=${id}&teamId=${teamId}`;
      }
      return [appUrl, `payouts/list/${teamId}/list/payout/${id}?type=${paymentSystem}`];
    case REDIRECTION_PAGES.ADDITIONAL_DATA:
      return [`/additionalDataRequired?teamId=${teamId}`, `payouts/application-status?teamId=${teamId}`];
    default:
      return [BASE_URL.DEFAULT_APP, WEB_BASE_URL];
  }
};

export const generateRedirectionURL = (
  page: REDIRECTION_PAGES,
  id: string,
  teamId: string,
  paymentSystem?: PaymentSystem
) => {
  //Initializing Smart Script arguments
  const oneLinkURL = ONE_LINK_BASE_URL;

  const [appRoute, webRoute] = getRoutePath(page, id, teamId, paymentSystem);

  const af_dp = {
    paramKey: 'af_dp',
    keys: [],
    defaultValue: BASE_URL.DEFAULT_APP
  };
  const custom_deep_link = {
    paramKey: 'custom_deep_link',
    keys: [],
    defaultValue: appRoute
  };
  const af_web_dp = {
    paramKey: 'af_web_dp',
    keys: [],
    defaultValue: `${WEB_BASE_URL}/${webRoute}`
  };
  const campaign = { keys: [], defaultValue: page };
  const af_force_deeplink = { paramKey: 'af_force_deeplink', keys: [], defaultValue: true };

  //Calling the function after embedding the code will be through a global
  //parameter on the window object called window.AF_SMART_SCRIPT
  //Onelink URL is generated
  const result = window.AF_SMART_SCRIPT.generateOneLinkURL({
    oneLinkURL,
    afParameters: {
      campaign: campaign,
      afCustom: [af_web_dp, custom_deep_link, af_dp, af_force_deeplink]
    }
  });

  let result_url = '';
  if (result) {
    result_url = result.clickURL;
  }
  return result_url;
};

export const openScanpayFAQInNewTab = (event: string) => {
  openInNewTab(GO_SCANPAY_FAQ);
  logAnalyticEvent(event);
};
