import { ChevronArrowRightIcon } from 'assets/icons';
import { FC, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';
import { VoidFn } from 'types/baseTypes';

interface ActionCardProps {
  Icon: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string; id?: string }> | null;
  title: string | ReactNode;
  subtitle?: string | ReactNode;
  className?: string;
  onClick?: VoidFn;
  isClickable?: boolean;
}

const ActionCard: FC<ActionCardProps> = ({
  Icon,
  title,
  subtitle,
  className = '',
  onClick = () => null,
  isClickable = false
}) => {
  return (
    <div
      className={twMerge(
        'flex w-full items-center rounded-md border-2 border-secondary bg-[#DBEFFD] p-[15px]',
        className
      )}
      onClick={onClick}
      role="presentation">
      {Icon && <Icon className="shrink-0" />}
      <div className="ml-3 space-y-2">
        <div className="text-sbase font-bold text-heading">{title}</div>
        {subtitle && <div className="text-sbase text-primaryText">{subtitle}</div>}
      </div>
      {isClickable && <ChevronArrowRightIcon className="ml-auto shrink-0" />}
    </div>
  );
};

export default ActionCard;
