import { CustomButton, If } from 'components';
import CustomAvatar from 'components/avatar/CustomAvatar';
import { ButtonType } from 'components/custom-button/CustomButton';
import { FC } from 'react';
import { VoidFn } from 'types/baseTypes';
import { getInitials } from 'utils/commonUtils';
import DateUtils from 'utils/dateUtils';
import { bgColorSelectorWithASCII } from 'utils/styleUtils';
import { RelatedAccount } from '../auth.model';

interface RelatedAccountsSectionProps {
  className?: string;
  handleSignUp: VoidFn;
  accounts: RelatedAccount[];
  handleBackClick: VoidFn;
  handleAccountClick: ({ value }: { value: string }) => void;
}

const RelatedAccountsSection: FC<RelatedAccountsSectionProps> = ({
  className = '',
  handleSignUp,
  accounts,
  handleBackClick,
  handleAccountClick
}) => {
  return (
    <div className={`my-auto flex max-w-[375px] flex-col self-center ${className}`}>
      <div className="mb-4 mt-12 text-2xl font-bold text-heading lg:text-4xl">Related accounts found</div>
      <div className="mb-5 text-base font-normal text-primaryText">
        Please choose the account you're trying to log in.
      </div>
      <div>
        {accounts?.map((merchantAccount, index) => (
          <div
            id={`related-account-list-${index + 1}`}
            key={merchantAccount.phone}
            onClick={() => handleAccountClick({ value: merchantAccount.phone })}
            className="mb-2.5 flex h-[67px] w-[375px] cursor-pointer 
            items-center rounded-md border border-borderGray px-2 py-3">
            <CustomAvatar
              src={''}
              label={getInitials(merchantAccount.name || merchantAccount.phone)}
              width="w-[40px]"
              height="h-[40px]"
              fontStyle="text-[17px] text-primaryText font-semibold leading-5 font-lato"
              background={bgColorSelectorWithASCII(merchantAccount.name)}
            />
            <div className="ml-5 flex flex-col gap-0.5 text-sbase text-headingGray">
              <div className="flex font-semibold">
                {merchantAccount.name ? merchantAccount.name : merchantAccount.phone}
                <If condition={Boolean(merchantAccount.name)}>
                  <div className="pl-1 font-normal text-primaryText">{`(${merchantAccount.phone})`}</div>
                </If>
              </div>
              <div className="text-px13 font-normal text-accentText">{`Joined on ${DateUtils.getDateInFormat({
                date: new Date(merchantAccount.joiningDate),
                dateFormat: 'dd MMM yyyy'
              })}`}</div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-3 text-sbase">
        <div className="flex justify-between">
          <p className="pr-1 text-primaryText">Not you? </p>
          <div onClick={handleSignUp} className="cursor-pointer font-semibold text-secondary">
            Sign up for a new account
          </div>
        </div>
        <CustomButton
          id="related-account-back-button"
          className="mt-9 h-[38px] w-full border-none shadow-none hover:bg-transparent active:shadow-none"
          type={ButtonType.SECONDARY}
          onClick={handleBackClick}>
          Back
        </CustomButton>
      </div>
    </div>
  );
};

export default RelatedAccountsSection;
