import React from 'react';
import { ArrowRightIcon } from 'assets/icons';
import { getAmountWithCurrency } from 'utils/amountUtils';
import { VoidFn } from 'types/baseTypes';

interface ShareQRSectionProps {
  qrData: string;
  payableAmount: string;
  onBackClick: VoidFn;
  className?: string;
}

export const ShareQRSection: React.FC<ShareQRSectionProps> = ({ qrData, payableAmount, onBackClick, className }) => {
  return (
    <>
      <div
        className={`flex w-fit cursor-pointer items-center gap-2 text-17px font-semibold text-headingGray ${className}`}
        onClick={onBackClick}>
        <ArrowRightIcon className="rotate-180" />
        Pay with QR Code
      </div>
      <div className="flex h-full flex-col items-center justify-center text-center">
        <div className="flex flex-col gap-3.5">
          <div className="text-xl font-bold leading-6 text-primaryText">Scan with phone camera</div>
          <div className="leaidng-[17px] text-[13px] text-headingGray">Or use a QR code scanning app</div>
        </div>
        <img src={qrData} className="mt-8" />
        <div className="text-3xl font-bold text-secondary">{getAmountWithCurrency(payableAmount)}</div>
      </div>
    </>
  );
};
